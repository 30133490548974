/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useQuery } from "@apollo/client";
import { Organization } from "@taketurns/taketurns-graphql-repository";

export const USER_ORGANIZATION_DOMAINS = gql`
  query userOrganizationDomains {
    userOrganization {
      id
      domains {
        name
        status
        verificationCode
      }
    }
  }
`;

export const useFetchUserOrganizationDomains = () => {
  const {
    data,
    loading: loadingUserOrganizationDomains,
    error: errorOnLoadingUserOrganizationDomains,
  } = useQuery<{
    userOrganization: Organization;
  }>(USER_ORGANIZATION_DOMAINS);
  return {
    domains: data?.userOrganization?.domains ?? [],
    loadingUserOrganizationDomains,
    errorOnLoadingUserOrganizationDomains,
  };
};
