/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { ConnectedUser } from "@taketurns/taketurns-graphql-repository";
import {
  ExtendedPasswordFormSchema,
  getExtendedSchemaWithPassword,
  refinePasswordWithForbiddenFields,
} from "@taketurns-rules/commons/passwordValidationRule";

const changePasswordFormFieldSchema = z.object({
  currentPassword: z.string().min(1),
});

const changePasswordFormSchema = getExtendedSchemaWithPassword(changePasswordFormFieldSchema).refine(
  ({ currentPassword, password }) => currentPassword !== password,
  {
    path: ["password"],
    message: "password.passwordMustBeDifferent",
  },
  // forced casting is required until https://github.com/colinhacks/zod/issues/2474 is fixed
) as unknown as ExtendedPasswordFormSchema<typeof changePasswordFormFieldSchema>;

type ChangePasswordFormFields = z.infer<typeof changePasswordFormSchema>;

export const useChangePasswordFormValidationRule = (connectedUser: ConnectedUser) => {
  const refinedChangePasswordFormSchema = refinePasswordWithForbiddenFields(changePasswordFormSchema, {
    forbiddenValues: [
      connectedUser.email.toLowerCase(),
      connectedUser.firstName?.toLowerCase(),
      connectedUser.lastName?.toLowerCase(),
    ],
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    trigger,
    reset,
    setError,
  } = useForm<ChangePasswordFormFields>({
    resolver: zodResolver(refinedChangePasswordFormSchema),
  });
  return { handleSubmit, control, errors, trigger, reset, setError };
};
