/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useRecoilCallback } from "recoil";
import { folderByIdState } from "../../../recoil/collaborationCreation/folderByIdState";
import { parentFolderIdByRequestIdState } from "../../../recoil/collaborationCreation/parentFolderIdByRequestIdState";
import { requestByIdState } from "../../../recoil/collaborationCreation/requestByIdState";

export const useRemoveRequestInCreation = () => {
  return useRecoilCallback(
    ({ set, reset, snapshot }) =>
      (requestId: string) => {
        const parentFolderId = snapshot.getLoadable(parentFolderIdByRequestIdState(requestId)).contents as string;
        if (parentFolderId) {
          set(folderByIdState(parentFolderId), (parentFolder) => ({
            ...parentFolder,
            requests: parentFolder.requests.filter((id) => id !== requestId),
          }));
          reset(parentFolderIdByRequestIdState(requestId));
        }
        reset(requestByIdState(requestId));
      },
    [],
  );
};
