/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useLazyFetchCollaborationName } from "@taketurns-repositories/collaboration/graphql/queries/fields/useFetchCollaborationName";

export const useFetchCollaborationNameByIdRule = () => {
  const fetchCollaborationName = useLazyFetchCollaborationName();
  return async (collaborationId: string): Promise<string> => {
    return await fetchCollaborationName(collaborationId);
  };
};
