/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useValidateCollaborationInvitationsRule } from "@taketurns-rules/collaboration/commands/collaborationCreation/form/mobile/useValidateCollaborationInvitationsRule";
import { useValidateCollaborationInvitedPartyNameRule } from "@taketurns-rules/collaboration/commands/collaborationCreation/form/mobile/useValidateCollaborationInvitedPartyNameRule";

export const useValidateInvitedPartyRule = () => {
  const validateInvitedPartyName = useValidateCollaborationInvitedPartyNameRule();
  const validateCollaborationInvitations = useValidateCollaborationInvitationsRule();

  return () => {
    const isInvitedPartyNameValid = validateInvitedPartyName();
    const isInvitationsListValid = validateCollaborationInvitations();

    return { isValid: isInvitedPartyNameValid && isInvitationsListValid };
  };
};
