/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Request } from "@taketurns/taketurns-graphql-repository";
import { useFetchConnectedUserPartyRule } from "@taketurns-rules/collaboration/queries/context/useFetchConnectedUserPartyRule";

export const useIsRequestMadeByConnectedUserPartyRule = () => {
  const { connectedUserParty } = useFetchConnectedUserPartyRule();

  return (request: Request) => {
    return connectedUserParty === request.party;
  };
};
