/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useGetConnectedUserPartyId } from "@taketurns-repositories/collaboration/context/useGetConnectedUserPartyId";
import { useSubscribeToAddAttachment } from "@taketurns-repositories/collaboration/graphql/subscriptions/content/useSubscribeToAddAttachment";
import { useSubscribeToAddFolder } from "@taketurns-repositories/collaboration/graphql/subscriptions/content/useSubscribeToAddFolder";
import { useSubscribeToAddRequest } from "@taketurns-repositories/collaboration/graphql/subscriptions/content/useSubscribeToAddRequest";
import { useSubscribeToMoveAttachmentFromSourceFolder } from "@taketurns-repositories/collaboration/graphql/subscriptions/content/useSubscribeToMoveAttachmentFromSourceFolder";
import { useSubscribeToMoveAttachmentIntoDestinationFolder } from "@taketurns-repositories/collaboration/graphql/subscriptions/content/useSubscribeToMoveAttachmentIntoDestinationFolder";
import { useSubscribeToMoveFolderFromSourceFolder } from "@taketurns-repositories/collaboration/graphql/subscriptions/content/useSubscribeToMoveFolderFromSourceFolder";
import { useSubscribeToMoveFolderIntoDestinationFolder } from "@taketurns-repositories/collaboration/graphql/subscriptions/content/useSubscribeToMoveFolderIntoDestinationFolder";
import { useSubscribeToMoveRequestFromSourceFolder } from "@taketurns-repositories/collaboration/graphql/subscriptions/content/useSubscribeToMoveRequestFromSourceFolder";
import { useSubscribeToMoveRequestIntoDestinationFolder } from "@taketurns-repositories/collaboration/graphql/subscriptions/content/useSubscribeToMoveRequestIntoDestinationFolder";
import { useSubscribeToRemoveAttachment } from "@taketurns-repositories/collaboration/graphql/subscriptions/content/useSubscribeToRemoveAttachment";
import { useSubscribeToRemoveFolder } from "@taketurns-repositories/collaboration/graphql/subscriptions/content/useSubscribeToRemoveFolder";
import { useSubscribeToRemoveRequest } from "@taketurns-repositories/collaboration/graphql/subscriptions/content/useSubscribeToRemoveRequest";
import { useSubscribeToRestoreAttachment } from "@taketurns-repositories/collaboration/graphql/subscriptions/content/useSubscribeToRestoreAttachment";
import { useSubscribeToRestoreFolder } from "@taketurns-repositories/collaboration/graphql/subscriptions/content/useSubscribeToRestoreFolder";
import { useFetchConnectedUserId } from "@taketurns-repositories/user/graphql/queries/useFetchConnectedUserId";
import { useGetDisplayedCollaborationIdRule } from "@taketurns-rules/collaboration/queries/collaboration/useGetDisplayedCollaborationIdRule";

export const useSubscribeToFolderContentChangesRule = (folderId: string) => {
  const displayedCollaborationId = useGetDisplayedCollaborationIdRule();
  const { userId } = useFetchConnectedUserId();
  const connectedUserPartyId = useGetConnectedUserPartyId();
  const args = { collaborationId: displayedCollaborationId, partyId: connectedUserPartyId, folderId };
  useSubscribeToAddFolder(args);
  useSubscribeToRemoveFolder(args);
  useSubscribeToRestoreFolder(args);
  useSubscribeToAddRequest(args);
  useSubscribeToRemoveRequest(args);
  useSubscribeToAddAttachment(args, userId);
  useSubscribeToRestoreAttachment(args);
  useSubscribeToRemoveAttachment(args);

  useSubscribeToMoveAttachmentFromSourceFolder({
    collaborationId: displayedCollaborationId,
    partyId: connectedUserPartyId,
    sourceFolderId: folderId,
  });
  useSubscribeToMoveAttachmentIntoDestinationFolder({
    collaborationId: displayedCollaborationId,
    partyId: connectedUserPartyId,
    destinationFolderId: folderId,
  });

  useSubscribeToMoveRequestFromSourceFolder({
    collaborationId: displayedCollaborationId,
    partyId: connectedUserPartyId,
    sourceFolderId: folderId,
  });
  useSubscribeToMoveRequestIntoDestinationFolder({
    collaborationId: displayedCollaborationId,
    partyId: connectedUserPartyId,
    destinationFolderId: folderId,
  });

  useSubscribeToMoveFolderFromSourceFolder({
    collaborationId: displayedCollaborationId,
    partyId: connectedUserPartyId,
    sourceFolderId: folderId,
  });
  useSubscribeToMoveFolderIntoDestinationFolder({
    collaborationId: displayedCollaborationId,
    partyId: connectedUserPartyId,
    destinationFolderId: folderId,
  });
};
