/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useQuery } from "@apollo/client";
import { ConnectedUser } from "@taketurns/taketurns-graphql-repository";

export const useFetchUserHasMultiUserOrganizationFeature = () => {
  const USER_FEATURE_MULTI_USER_ORGA = gql`
    query userHasMultiUserOrganizationFeature {
      connectedUser {
        id
        availableFeatures {
          multiUserOrganization
        }
      }
    }
  `;
  const {
    data,
    error: errorOnHasMultiUserOrganizationFeature,
    loading: loadingHasMultiUserOrganizationFeature,
  } = useQuery<{
    connectedUser: ConnectedUser;
  }>(USER_FEATURE_MULTI_USER_ORGA, { fetchPolicy: "cache-and-network" });
  return {
    userHasMultiUserOrganizationFeature: data?.connectedUser?.availableFeatures?.multiUserOrganization ?? false,
    errorOnHasMultiUserOrganizationFeature,
    loadingHasMultiUserOrganizationFeature,
  };
};
