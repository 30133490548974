/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useEditInvitationRoleRule } from "@taketurns-rules/collaboration/commands/collaborationView/participants/useEditInvitationRoleRule";
import { useResendInvitationRule } from "@taketurns-rules/collaboration/commands/collaborationView/participants/useResendInvitationRule";
import { useRevokeInvitationRule } from "@taketurns-rules/collaboration/commands/collaborationView/participants/useRevokeInvitationRule";
import { InvitationItem, InvitationItemComponentProps } from "./InvitationItem";

export const InvitationItemComponent = (props: InvitationItemComponentProps) => {
  const { revokeInvitation, loadingRevokeInvitation } = useRevokeInvitationRule();
  const { editInvitationRole, loadingEditInvitationRole } = useEditInvitationRoleRule();
  const { isAllowedToResendInvitation, resendInvitation, loadingResendInvitation } = useResendInvitationRule();
  return (
    <InvitationItem
      {...props}
      revokeInvitationAction={{ action: revokeInvitation, loading: loadingRevokeInvitation }}
      editInvitationRoleAction={{ action: editInvitationRole, loading: loadingEditInvitationRole }}
      resendInvitationAction={
        isAllowedToResendInvitation
          ? {
              action: resendInvitation,
              loading: loadingResendInvitation,
            }
          : undefined
      }
    />
  );
};
