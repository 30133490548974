/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { ApolloCache } from "@apollo/client";
import { SubscriptionPlan, SubscriptionPlanTier } from "@taketurns/taketurns-graphql-repository";
import { USER_ORGANIZATION_SUBSCRIPTION_TIER } from "@taketurns-repositories/user/graphql/queries/useFetchUserOrganizationSubscriptionTier";

export const updateUserOrganizationSubscriptionTierInCache = (
  cache: ApolloCache<unknown>,
  newPlanTier: SubscriptionPlanTier,
) => {
  cache.updateQuery<{
    userSubscription: SubscriptionPlan;
  }>({ query: USER_ORGANIZATION_SUBSCRIPTION_TIER }, ({ userSubscription }) => {
    return { userSubscription: { ...userSubscription, tier: newPlanTier } };
  });
};
