/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Box, CircularProgress } from "@mui/material";
import { CancelAnnotationsButton } from "@taketurns-components/collaboration/Shared/content/CancelAnnotationsButton";
import { DownloadRevisionButton } from "@taketurns-components/collaboration/Shared/content/DownloadRevisionButton";
import { SaveRevisionButton } from "@taketurns-components/collaboration/Shared/content/SaveRevisionButton";
import { TakeTurnsGreyButton } from "@taketurns-components/commons/button";
import { useAttachmentDetailsTranslation } from "@taketurns-i18n/collaboration/AttachmentDetails/useAttachmentDetailsTranslation";
import { useIsAttachmentRevisionAddInProgressRule } from "@taketurns-rules/collaboration/queries/attachment/pending/useIsAttachmentRevisionAddInProgressRule";
import { useIsOnMobileRule } from "@taketurns-rules/commons/theme/useIsOnMobileRule";

interface AttachmentDetailsHeaderDownloadSaveCancelButtonsProps {
  collaborationId: string;
  documentId: string;
  revisionId: string;
  isPdfUpdated: boolean;
  onSaveRevisionButtonClick: () => void;
  onCancelAnnotationsButtonClick: () => void;
}

export const AttachmentDetailsHeaderDownloadSaveCancelButtons = (
  props: AttachmentDetailsHeaderDownloadSaveCancelButtonsProps,
) => {
  const isAttachmentRevisionAddInProgress = useIsAttachmentRevisionAddInProgressRule();
  if (!props.isPdfUpdated && !isAttachmentRevisionAddInProgress) {
    return (
      <DownloadRevisionButton
        collaborationId={props.collaborationId}
        documentId={props.documentId}
        revisionId={props.revisionId}
      />
    );
  } else if (isAttachmentRevisionAddInProgress) {
    return <LoadingRevisionButton />;
  }
  return (
    <Box>
      <CancelAnnotationsButton onClick={props.onCancelAnnotationsButtonClick} />
      <SaveRevisionButton onClick={props.onSaveRevisionButtonClick} />
    </Box>
  );
};

const LoadingRevisionButton = () => {
  const isOnMobile = useIsOnMobileRule();
  const { t } = useAttachmentDetailsTranslation();

  if (isOnMobile) {
    return <CircularProgress color="secondary" size={20} />;
  }

  return (
    <TakeTurnsGreyButton
      disabled={true}
      startIcon={<CircularProgress color="secondary" size={20} />}
      sx={{ fontSize: 16 }}
    >
      {t("loading")}
    </TakeTurnsGreyButton>
  );
};
