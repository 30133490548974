/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useMutation } from "@apollo/client";
import { InviteToOrganizationInput } from "@taketurns/taketurns-graphql-repository";

export const useResendOrganizationInvitation = () => {
  const mutation = gql`
    mutation resendOrganizationInvitation($inviteToOrganizationInput: InviteToOrganizationInput!) {
      resendOrganizationInvitation(inviteToOrganizationInput: $inviteToOrganizationInput)
    }
  `;
  const [
    resendOrganizationInvitationMutation,
    { error: errorOnResendOrganizationInvitation, loading: loadingResendOrganizationInvitation },
  ] = useMutation(mutation);
  const resendOrganizationInvitation = (inviteToOrganizationInput: InviteToOrganizationInput) => {
    return resendOrganizationInvitationMutation({ variables: { inviteToOrganizationInput } });
  };

  return { resendOrganizationInvitation, errorOnResendOrganizationInvitation, loadingResendOrganizationInvitation };
};
