/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Box, Button, styled } from "@mui/material";
import { Fragment } from "react";
import { SubscriptionPlanPeriod } from "@taketurns/taketurns-graphql-repository";
import { useUserPlanTranslation } from "@taketurns-i18n/user/UserPlans/useUserPlanTranslation";
import { SubscriptionPlanForDisplay } from "@taketurns-repositories/user/local/entities/subscriptionPlanForDisplay";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { useGetSubscriptionPlanPeriodRule } from "@taketurns-rules/user/queries/plan/getSubscriptionPlanPeriodRule";
import { IconGift } from "../../../../commons/icons";
import { LimitedTimeOfferBackgroundColor } from "../SubscriptionPlanElement/SubscriptionPlanElementPricingAndDescription";

interface MobileSubscriptionPlanProps {
  isSelected: boolean;
  setPage: (index: number) => void;
  tabIndex: number;
  subscriptionPlan: SubscriptionPlanForDisplay;
}

export const MobileSubscriptionPlanTabButton = (props: MobileSubscriptionPlanProps) => {
  const { isSelected, setPage, tabIndex, subscriptionPlan } = props;
  const { plan, color, discountedYearly, discountedMonthly } = subscriptionPlan;
  const period = useGetSubscriptionPlanPeriodRule();
  const { t } = useUserPlanTranslation();

  const displayLimitedTimeOfferChip = () => {
    if (discountedYearly && period === SubscriptionPlanPeriod.Year) {
      return true;
    }
    return discountedMonthly && period === SubscriptionPlanPeriod.Month;
  };

  const onClick = () => {
    setPage(tabIndex);
  };

  const componentId = `plan-tab-${props.tabIndex}`;

  return (
    <Box
      role="tab"
      id={componentId}
      sx={{
        flex: 1,
        position: "relative",
      }}
    >
      <MobileSubscriptionPlanTabButton__TabButton
        onClick={onClick}
        backgroundColor={isSelected ? color : undefined}
        aria-describedby={componentId}
      >
        {t(`plans.${plan.planTier}`)}
      </MobileSubscriptionPlanTabButton__TabButton>
      {displayLimitedTimeOfferChip() && <LimitedTimeOfferIcon />}
    </Box>
  );
};

const LimitedTimeOfferIcon = () => (
  <Fragment>
    <Box
      sx={{
        position: "absolute",
        top: 0,
        right: 0,
        width: 0,
        height: 0,
        borderStyle: "inset",
        borderColor: `transparent ${LimitedTimeOfferBackgroundColor} transparent transparent`,
        borderWidth: "0 2rem 2rem 0",
        pointerEvents: "none",
      }}
    ></Box>
    <Box
      sx={{
        position: "absolute",
        fontSize: "12px",
        top: "-2px",
        right: "2px",
        color: "white",
      }}
    >
      <IconGift />
    </Box>
  </Fragment>
);

const MobileSubscriptionPlanTabButton__TabButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "backgroundColor",
})((props: { backgroundColor?: string }) => ({
  position: "relative",
  top: 0,
  left: 0,
  backgroundColor: props.backgroundColor ?? "#CCCCCC",
  borderRadius: 0,
  padding: "22px 0px",
  width: "100%",
  textTransform: "uppercase",
  color: "white",
  fontSize: "16px",
  "&:hover": {
    opacity: "90%",
    backgroundColor: props.backgroundColor ?? TakeTurnsColors.grayButton,
  },
  "&:disabled": {
    backgroundColor: TakeTurnsColors.lightGray,
  },
}));
