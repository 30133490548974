/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Button, DialogActions, Stack, TextField } from "@mui/material";
import { FormEvent, useRef, useState } from "react";
import { useSharedCollaborationTranslation } from "@taketurns-i18n/collaboration/shared/useSharedCollaborationTranslation";
import { DESCRIPTION_FIELD_MAX_LENGTH, TEXT_FIELD_MAX_LENGTH } from "@taketurns-rules/commons/inputConstants";
import { TakeTurnsDefaultButton } from "../../../commons/button";
import { ResponsiveDialog } from "../../../commons/dialog/ResponsiveDialog";

interface EditRequestDialogProps {
  open: boolean;
  onClose: () => void;
  requestName: string;
  requestDescription?: string;
  modifyRequestDescription: (newDescription: string) => void;
  changeRequestName: (newName: string) => void;
}

export const EditRequestDialog = (props: EditRequestDialogProps) => {
  const { open, onClose, requestName, requestDescription, modifyRequestDescription, changeRequestName } = props;

  const { t } = useSharedCollaborationTranslation();

  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);

  const requestNameInputRef = useRef<HTMLInputElement | null>(null);
  const requestDescriptionInputRef = useRef<HTMLInputElement | null>(null);

  const saveRequestModificationsAndCloseEditDialog = (event: FormEvent) => {
    event.preventDefault();
    if (requestNameInputRef.current?.value && requestName !== requestNameInputRef?.current?.value) {
      changeRequestName(requestNameInputRef.current?.value);
    }
    if (
      requestDescription !== requestDescriptionInputRef?.current?.value &&
      requestDescriptionInputRef.current!.value !== requestDescription
    ) {
      modifyRequestDescription(requestDescriptionInputRef.current?.value);
    }
    onClose();
  };

  const disableSubmitButtonIfNoChangeOrNameIsEmpty = () => {
    setSubmitButtonDisabled(
      !requestNameInputRef.current?.value ||
        (requestNameInputRef.current?.value === requestName &&
          requestDescriptionInputRef.current?.value === requestDescription),
    );
  };

  return (
    <ResponsiveDialog
      fullWidth
      open={open}
      onClose={onClose}
      title={t("contextMenu.request.editRequestDialogTitle")}
      content={
        <form onSubmit={saveRequestModificationsAndCloseEditDialog}>
          <Stack spacing={2}>
            <TextField
              autoFocus
              inputProps={{
                maxLength: TEXT_FIELD_MAX_LENGTH,
              }}
              inputRef={requestNameInputRef}
              defaultValue={requestName}
              onChange={disableSubmitButtonIfNoChangeOrNameIsEmpty}
              fullWidth
            />
            <TextField
              inputRef={requestDescriptionInputRef}
              defaultValue={requestDescription}
              onChange={disableSubmitButtonIfNoChangeOrNameIsEmpty}
              fullWidth
              multiline
              inputProps={{
                maxLength: DESCRIPTION_FIELD_MAX_LENGTH,
              }}
              minRows={5}
            />
          </Stack>
        </form>
      }
      footer={
        <DialogActions>
          <Button variant={"text"} onClick={onClose}>
            {t("contextMenu.cancel")}
          </Button>
          <TakeTurnsDefaultButton
            disabled={submitButtonDisabled}
            type="submit"
            onClick={saveRequestModificationsAndCloseEditDialog}
          >
            {t("contextMenu.confirm")}
          </TakeTurnsDefaultButton>
        </DialogActions>
      }
    />
  );
};
