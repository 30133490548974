/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql } from "@apollo/client";
import { ParticipantsAndInvitationsChanged } from "@taketurns/taketurns-graphql-repository";
import { useRefreshableSubscription } from "@taketurns-components/webapp/QueriesAndSubscriptionsRefreshTrigger";
import { GET_PARTICIPANTS_AND_INVITATIONS } from "@taketurns-repositories/collaboration/graphql/queries/participants/useFetchParticipantsAndInvitations";
import { useFetchConnectedUserIdAndEmail } from "@taketurns-repositories/user/graphql/queries/useFetchConnectedUserIdAndEmail";
import { wait } from "@taketurns-rules/commons/util/wait";

const SUBSCRIBE_TO_PARTICIPANTS_INVITATIONS_CHANGED = gql`
  subscription onSetParticipantsAndInvitationsChanged($id: ID!) {
    onSetParticipantsAndInvitationsChanged(id: $id) {
      id
      userId
    }
  }
`;
export const useSubscribeToParticipantsAndInvitationsChanged = (collaborationId: string) => {
  const { connectedUserId } = useFetchConnectedUserIdAndEmail();

  return useRefreshableSubscription<{ onSetParticipantsAndInvitationsChanged: ParticipantsAndInvitationsChanged }>(
    SUBSCRIBE_TO_PARTICIPANTS_INVITATIONS_CHANGED,
    {
      variables: { id: collaborationId },
      onData: async ({
        data: {
          data: {
            onSetParticipantsAndInvitationsChanged: { userId },
          },
        },
        client,
      }) => {
        if (userId !== connectedUserId) {
          await wait(3000);
          client.refetchQueries({ include: [GET_PARTICIPANTS_AND_INVITATIONS] });
        }
      },
    },
  );
};
