/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Box, Divider, List, styled, Typography } from "@mui/material";
import { useCallback } from "react";
import { useCollaborationCreationTranslation } from "@taketurns-i18n/collaboration/CollaborationCreation/useCollaborationCreationTranslation";
import { FolderInCreation } from "@taketurns-repositories/collaboration/models/folderInCreation";
import { useGetFolderInCreation } from "@taketurns-repositories/collaboration/state/read/collaborationCreation/useGetFolderInCreation";
import { useAttachFilesIntoFolderAndMoveIntoItInCreationRule } from "@taketurns-rules/collaboration/commands/collaborationCreation/useAttachFilesIntoFolderAndMoveIntoItInCreationRule";
import { useBrowseContentInCreationRule } from "@taketurns-rules/collaboration/commands/collaborationCreation/useBrowseContentInCreationRule";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { IconDropZoneTarget } from "../../../../commons/icons";
import { AttachmentListItemInCreation } from "./AttachmentListItemInCreation";
import { FolderListItemInCreation } from "./FolderListItemInCreation";
import { RequestListItemInCreation } from "./RequestListItemInCreation";

export const ContentAsList = ({ displayDragAndDropInstructions }: { displayDragAndDropInstructions?: boolean }) => {
  const { isRootFolderSelected, currentFolderId } = useBrowseContentInCreationRule();

  const moveIntoFolderAndAddFiles = useAttachFilesIntoFolderAndMoveIntoItInCreationRule();
  const currentFolder = useGetFolderInCreation(currentFolderId);
  const isRootFolderEmpty =
    isRootFolderSelected &&
    currentFolder.folders.length === 0 &&
    currentFolder.requests.length === 0 &&
    currentFolder.attachments.length === 0;

  const dropZoneConfigBuilder = useCallback(
    (folderId: string) => ({
      handleFilesDropping: (files: File[]) => moveIntoFolderAndAddFiles(files, folderId),
      multiple: true,
      className: "FolderCard__DropZone",
      sx: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
      },
    }),
    [moveIntoFolderAndAddFiles],
  );

  const { t } = useCollaborationCreationTranslation();

  if (isRootFolderEmpty && displayDragAndDropInstructions) {
    return (
      <EmptyContentContainer>
        <EmptyContentIconAndTextContainer>
          <IconDropZoneTarget size={24} color={TakeTurnsColors.darkGray} />
          <Typography fontSize={"0.875rem"} color={TakeTurnsColors.darkGray}>
            {t("dragAndDropInstructions")}
          </Typography>
        </EmptyContentIconAndTextContainer>
      </EmptyContentContainer>
    );
  }

  return (
    <List
      disablePadding
      dense
      sx={{
        paddingTop: "22px",
        paddingX: { xs: "0", md: "24px" },
        paddingBottom: { xs: 2, md: "50px" },
        height: "100%",
        overflow: "auto",
      }}
    >
      {hasFolderAnyContent(currentFolder) && <Divider />}
      {!isRootFolderSelected && (
        <FolderListItemInCreation isDisplayedAsBreadcrumb key={currentFolderId} folderId={currentFolderId} />
      )}
      {currentFolder.folders.map((folderId) => (
        <FolderListItemInCreation key={folderId} folderId={folderId} dropZoneConfig={dropZoneConfigBuilder(folderId)} />
      ))}
      {currentFolder.requests.map((requestId) => (
        <RequestListItemInCreation key={requestId} requestId={requestId} />
      ))}
      {currentFolder.attachments.map((documentId) => (
        <AttachmentListItemInCreation key={documentId} documentId={documentId} />
      ))}
    </List>
  );
};

const EmptyContentContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
});

const EmptyContentIconAndTextContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
});

const hasFolderAnyContent = (folder: FolderInCreation) =>
  folder.folders.length > 0 || folder.requests.length > 0 || folder.attachments.length > 0;
