/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Party } from "@taketurns/taketurns-graphql-repository";
import { useFetchCollaborationPartyNamesRule } from "@taketurns-rules/collaboration/queries/fields/useFetchCollaborationPartyNamesRule";

export function useFetchPartyNameRule(party: Party) {
  const { invitedPartyName, owningPartyName, loadingCollaborationPartyNames, errorOnFetchingCollaborationPartyNames } =
    useFetchCollaborationPartyNamesRule();
  let partyName = owningPartyName;
  if (party == Party.Invited) {
    partyName = invitedPartyName;
  }
  return {
    partyName,
    loadingPartyName: loadingCollaborationPartyNames,
    errorOnFetchingPartyName: errorOnFetchingCollaborationPartyNames,
  };
}
