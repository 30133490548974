/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { gql, useApolloClient } from "@apollo/client";
import { userFragment } from "@taketurns-repositories/user/graphql/fragments/userFragment";
import { OrganizationMember } from "@taketurns/taketurns-graphql-repository";

export const useGetOrganizationMemberInCache = () => {
  const { cache } = useApolloClient();

  const ORGANIZATION_MEMBER_FRAGMENT = gql`
    fragment OrganizationMemberFragmentFromCache on OrganizationMember {
      userId
      user {
        ...UserFields
      }
      role
      supervisorUserId
      supervisor {
        ...UserFields
      }
    }
    ${userFragment}
  `;

  return (userId: string) => {
    return cache.readFragment<OrganizationMember>({
      id: cache.identify({ __typename: "OrganizationMember", userId: userId }),
      fragment: ORGANIZATION_MEMBER_FRAGMENT,
      fragmentName: "OrganizationMemberFragmentFromCache",
      returnPartialData: true,
    });
  };
};
