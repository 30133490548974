/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { gql, useMutation } from "@apollo/client";
import { Folder, MutationMoveRequestIntoFolderArgs } from "@taketurns/taketurns-graphql-repository";

export const moveRequestMutationFragment = gql`
  fragment MoveRequestMutationFragment on MoveOutput {
    collaborationId
    partyId
    sourceFolderId
    destinationFolderId
    sourceFolder {
      id
      requestCount
      updateCount
      prevUpdateCount
      requests {
        name
        description
        party
        status
        prevStatus
      }
    }
    destinationFolder {
      id
      requestCount
      updateCount
      prevUpdateCount
      requests {
        name
        description
        party
        status
        prevStatus
      }
    }
    contentMetadata {
      id
      hasPendingUpdates
    }
  }
`;

export function useMoveRequestIntoFolder({
  collaborationId,
  requestId,
}: {
  collaborationId: string;
  requestId: string;
}) {
  const MOVE_REQUEST_INTO_FOLDER_MUTATION = gql`
    mutation moveRequestIntoFolder($input: MoveRequestIntoFolderInput!) {
      moveRequestIntoFolder(input: $input) {
        ...MoveRequestMutationFragment
      }
    }
    ${moveRequestMutationFragment}
  `;
  const [moveRequestMutation] = useMutation<
    { sourceFolder: Folder; destinationFolder: Folder },
    MutationMoveRequestIntoFolderArgs
  >(MOVE_REQUEST_INTO_FOLDER_MUTATION);
  return async (destinationFolderId: string) => {
    await moveRequestMutation({
      variables: {
        input: {
          collaborationId,
          requestId,
          destinationFolderId,
        },
      },
    });
  };
}
