/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useSubscribeToCollaborationListUpdated } from "@taketurns-repositories/collaboration/graphql/subscriptions/fields/useSubscribeToCollaborationListUpdated";
import { useGetListAppliedFilterRule } from "@taketurns-rules/collaboration/queries/useGetListAppliedFilterRule";
import { useFetchConnectedUserIdAndEmailRule } from "@taketurns-rules/user/queries/useFetchConnectedUserIdAndEmailRule";

export const useSubscribeToCollaborationListUpdatedRule = () => {
  const { connectedUserId } = useFetchConnectedUserIdAndEmailRule();
  const collaborationListAppliedFilter = useGetListAppliedFilterRule();
  useSubscribeToCollaborationListUpdated(connectedUserId, collaborationListAppliedFilter);
};
