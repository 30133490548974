/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useMutation } from "@apollo/client";
import { MarketingDataInput } from "@taketurns/taketurns-graphql-repository";

export const useSaveMarketingData = () => {
  const mutation = gql`
    mutation saveMarketingData($marketingDataInput: MarketingDataInput!) {
      saveMarketingData(marketingData: $marketingDataInput)
    }
  `;
  const [saveMarketingDataMutation] = useMutation(mutation);

  const saveMarketingData = (marketingDataInput: MarketingDataInput) => {
    saveMarketingDataMutation({ variables: { marketingDataInput } });
  };
  return { saveMarketingData };
};
