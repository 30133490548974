/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql } from "@apollo/client";
import {
  MarkUnmarkForReviewAttachmentOutput,
  SubscriptionOnMarkUnmarkForReviewAttachmentArgs,
} from "@taketurns/taketurns-graphql-repository";
import { useRefreshableSubscription } from "@taketurns-components/webapp/QueriesAndSubscriptionsRefreshTrigger";
import { markUnmarkAttachmentForReviewFragment } from "@taketurns-repositories/collaboration/graphql/mutations/content/attachments/useMarkAttachmentForReview";

const SUBSCRIBE_TO_MARK_UNMARK_FOR_REVIEW_ATTACHMENT = gql`
  subscription onMarkUnmarkForReviewAttachment($collaborationId: ID!, $partyId: ID!, $attachmentId: ID!) {
    onMarkUnmarkForReviewAttachment(collaborationId: $collaborationId, partyId: $partyId, attachmentId: $attachmentId) {
      ...MarkUnmarkAttachmentForReviewFragment
    }
  }
  ${markUnmarkAttachmentForReviewFragment}
`;

export const useSubscribeToMarkUnmarkForReviewAttachment = (args: SubscriptionOnMarkUnmarkForReviewAttachmentArgs) => {
  return useRefreshableSubscription<
    { onMarkUnmarkAttachmentForReview: MarkUnmarkForReviewAttachmentOutput },
    SubscriptionOnMarkUnmarkForReviewAttachmentArgs
  >(SUBSCRIBE_TO_MARK_UNMARK_FOR_REVIEW_ATTACHMENT, { variables: args });
};
