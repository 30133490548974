/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { createContext, useContext } from "react";

interface CollaborationRecapContext {
  collaborationId: string;
}

export const CollaborationRecapContext = createContext<CollaborationRecapContext>(null);

export const useCollaborationRecapContext = () => {
  return useContext(CollaborationRecapContext);
};
