/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useMutation } from "@apollo/client";
import { Folder, MutationRemoveRequestArgs, RemoveRequestInput } from "@taketurns/taketurns-graphql-repository";
import { requestFragment } from "../../../fragments/requestFragment";

export const RemoveRequestMutationFragment = gql`
  ${requestFragment}
  fragment RemoveRequestMutationFragment on RemoveRequestOutput {
    collaborationId
    partyId
    folderId
    folder {
      id
      requests {
        ...RequestFragment
      }
    }
    contentMetadata {
      id
      hasPendingUpdates
    }
  }
`;
export const useRemoveRequest = (collaborationId: string, requestId: string) => {
  const REMOVE_REQUEST = gql`
    ${RemoveRequestMutationFragment}
    mutation removeRequest($input: RemoveRequestInput!) {
      removeRequest(input: $input) {
        ...RemoveRequestMutationFragment
      }
    }
  `;
  const [removeRequestMutation, { data, loading, error }] = useMutation<
    { removeRequest: Folder },
    MutationRemoveRequestArgs
  >(REMOVE_REQUEST);
  const removeRequest = () => {
    const input: RemoveRequestInput = {
      collaborationId,
      requestId,
    };
    return removeRequestMutation({ variables: { input } });
  };

  return {
    removeRequest,
    removeRequestResponseData: data?.removeRequest,
    removeRequestLoading: loading,
    errorOnRemovingRequest: error,
  };
};
