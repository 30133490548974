/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useSetRecoilState } from "recoil";
import { openedRequestFulfillmentRequestState } from "../recoil/openedRequestFulfillmentRequestState";

export const useSetOpenedRequestFulfillmentRequest = () => {
  return useSetRecoilState(openedRequestFulfillmentRequestState);
};
