/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Box, IconButton, Slide, Stack, styled } from "@mui/material";
import { useSetSidebarOpenStatusRule } from "@taketurns-rules/webapp/commands/useSetSidebarOpenStatusRule";
import { useGetSidebarOpenedStatusRule } from "@taketurns-rules/webapp/queries/useGetSidebarOpenedStatusRule";
import { ForwardedRef, forwardRef, Fragment, ReactNode, useRef, useState } from "react";
import { IconChevronsLeft, IconChevronsRight } from "../../../../components/commons/icons";
import { SidebarButton, SidebarButtonProps } from "./SidebarButton";
import { SidebarContent } from "./SidebarContent";
import { SidebarHeader } from "./SidebarHeader";

interface SidebarProps {
  buttons: SidebarButtonProps[];
  services: ReactNode[];
}

export const SidebarLayout = (props: SidebarProps) => {
  const [selectService, setSelectedService] = useState(0);

  const isSidebarOpened = useGetSidebarOpenedStatusRule();
  const setSidebarOpenedStatus = useSetSidebarOpenStatusRule();

  const sideBarContainerRef = useRef(null);

  const openSidebarAndSelectService = (selectedService: number) => {
    setSidebarOpenedStatus(true);
    setSelectedService(selectedService);
  };

  const openSidebar = () => {
    setSidebarOpenedStatus(true);
  };

  const closeSidebar = () => {
    setSidebarOpenedStatus(false);
  };

  const SidebarButtonWithRef = forwardRef(
    (innerProps: SidebarButtonProps, ref: ForwardedRef<HTMLDivElement> | undefined) => (
      <div ref={ref} style={{ display: "flex", width: "100%" }}>
        <SidebarButton {...innerProps} />
      </div>
    ),
  );
  SidebarButtonWithRef.displayName = "SidebarButtonWithRef";

  if (isSidebarOpened) {
    return (
      <SidebarBox
        sx={{
          position: {
            xs: "absolute",
            lg: "relative",
          },
          right: {
            xs: 0,
            lg: "auto",
          },
        }}
        extended={isSidebarOpened}
        ref={sideBarContainerRef}
      >
        <Fragment>
          <SidebarHeader>
            <SidebarSizeButtonBox>
              <IconButton size="small" onClick={closeSidebar}>
                <IconChevronsRight size={"20"} />
              </IconButton>
            </SidebarSizeButtonBox>
            {props.buttons.map((button: SidebarButtonProps, index: number) => (
              <Slide
                container={sideBarContainerRef.current}
                direction="left"
                key={index}
                in={isSidebarOpened}
                timeout={50 * (index + 1)}
              >
                <SidebarButtonWithRef
                  icon={button.icon}
                  active={selectService === index}
                  label={button.label}
                  onClick={() => setSelectedService(index)}
                />
              </Slide>
            ))}
          </SidebarHeader>
          <SidebarContent>{props.services[selectService]}</SidebarContent>
        </Fragment>
      </SidebarBox>
    );
  }

  return (
    <SidebarBox
      sx={{
        position: {
          xs: "absolute",
          lg: "relative",
        },
        right: {
          xs: 0,
          lg: "auto",
        },
      }}
      extended={isSidebarOpened}
      ref={sideBarContainerRef}
    >
      <Stack direction="column" spacing={2}>
        <Slide direction="left" in={!isSidebarOpened} timeout={50}>
          <IconButton disableFocusRipple disableTouchRipple disableRipple size="small" onClick={openSidebar}>
            <IconChevronsLeft size={"20"} />
          </IconButton>
        </Slide>
        {props.buttons.map((button: SidebarButtonProps, index: number) => (
          <Slide key={index} timeout={50 * (index + 1)} direction="left" in={!isSidebarOpened}>
            <IconButton
              size="small"
              disableFocusRipple
              disableTouchRipple
              disableRipple
              onClick={() => openSidebarAndSelectService(index)}
            >
              {button.icon}
            </IconButton>
          </Slide>
        ))}
      </Stack>
    </SidebarBox>
  );
};

const SidebarBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "extended",
})((props: { extended: boolean }) => ({
  height: "100%",
  minWidth: props.extended ? "380px" : "50px",
  width: props.extended ? "380px" : "50px",
  backgroundColor: "white",
  boxShadow: "0px 20px 20px 0px rgba(0, 0, 0, 0.05)",
  transition: "min-width 0.05s, width 0.05s",
}));

const SidebarSizeButtonBox = styled(Box)({
  position: "absolute",
  left: "3px",
  top: "3px",
});
