/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { getEnvironmentVariable } from "../../../getEnvironmentVariable";

export const LANGUAGE_PARAMETER = "{{language}}";

export const helpCenterEnvironmentVariables = {
  helpCenterUrl: getEnvironmentVariable("HELP_CENTER_URL"),
  termsUrl: getEnvironmentVariable("HELP_CENTER_TERMS_URL"),
  privacyUrl: getEnvironmentVariable("HELP_CENTER_PRIVACY_URL"),
  chatGptUrl: getEnvironmentVariable("HELP_CENTER_CHATGPT_URL"),
};
