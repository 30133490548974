/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useRecoilCallback } from "recoil";
import { UploadStatus } from "../models/uploadStatus";
import { completedUploadIdsState } from "../recoil/completedUploadIdsState";
import { uploadsByUploadIdState } from "../recoil/uploadsByUploadIdState";

export const useMarkUploadAsComplete = () => {
  return useRecoilCallback(
    ({ set }) =>
      (uploadId: number) => {
        set(uploadsByUploadIdState(uploadId), (upload) => {
          if (upload) {
            const updatedUpload = upload.copy();
            updatedUpload.status = UploadStatus.complete;
            return updatedUpload;
          } else {
            throw new Error(`Cannot mark upload as completed for upload with id : ${uploadId} `);
          }
        });
        set(completedUploadIdsState, (currentCompleteUploadsIds) => new Set([...currentCompleteUploadsIds, uploadId]));
      },
    [],
  );
};
