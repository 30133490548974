/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useEffect } from "react";
import {
  useGetAllPendingCreationAttachments,
} from "@taketurns-repositories/collaboration/state/read/collaborationCreation/useGetAllPendingCreationAttachments";
import {
  useMarkPendingCreationAttachmentAsCancelled,
} from "@taketurns-repositories/collaboration/state/write/collaborationCreation/pendingCreationAttachment/useMarkPendingCreationAttachmentAsCancelled";
import { useGetCancelledUploadIds } from "@taketurns-repositories/document/state/read/useGetCancelledUploadIds";
import {
  useCancelUploadInAttachmentBulkRule,
} from "@taketurns-rules/collaboration/commands/useCancelUploadInAttachmentBulkRule";

export function useListenToCancelledUploadsInCreationToCancelCreateAttachmentRule() {
  const cancelledUploadIds = useGetCancelledUploadIds();
  const pendingCreationAttachments = useGetAllPendingCreationAttachments();
  const markPendingAttachmentAsCancelled = useMarkPendingCreationAttachmentAsCancelled();
  const cancelUploadInAttachmentBulk = useCancelUploadInAttachmentBulkRule();

  useEffect(() => {
    for (const cancelledUploadId of cancelledUploadIds) {
      const pendingAttachmentToUpdate = pendingCreationAttachments.find((p) => p.uploadId === cancelledUploadId);
      if (pendingAttachmentToUpdate && !pendingAttachmentToUpdate.isCancelled()) {
        markPendingAttachmentAsCancelled(pendingAttachmentToUpdate);
        cancelUploadInAttachmentBulk(cancelledUploadId);
      }
    }
  }, [cancelUploadInAttachmentBulk, cancelledUploadIds, markPendingAttachmentAsCancelled, pendingCreationAttachments]);
}
