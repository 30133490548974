/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Box, Stack, styled, Typography } from "@mui/material";
import { useCollaborationListTranslation } from "@taketurns-i18n/collaboration/CollaborationList/useCollaborationListTranslation";
import { useFetchPendingInvitationsCountRule } from "@taketurns-rules/collaboration/queries/useFetchPendingInvitationsCountRule";
import { useSubscribeToAddedOrRevokedInvitationsToCollaborationsRule } from "@taketurns-rules/collaboration/subscriptions/useSubscribeToAddedOrRevokedInvitationsToCollaborationsRule";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { IconBell } from "../../commons/icons";

interface PendingInvitationsProps {
  onClick: () => void;
}

const PendingInvitationStyledButton = styled(Stack)({
  "&:hover": {
    cursor: "pointer",
  },
  fontSize: "12px",
});

export const PendingInvitationsNotification = ({ onClick }: PendingInvitationsProps) => {
  const { pendingInvitationsCount, loadingPendingInvitationsCount, errorOnFetchingPendingInvitationsCount } =
    useFetchPendingInvitationsCountRule();
  useSubscribeToAddedOrRevokedInvitationsToCollaborationsRule();

  const { t } = useCollaborationListTranslation();

  const hasPendingInvitations = pendingInvitationsCount > 0;
  if (loadingPendingInvitationsCount || !hasPendingInvitations) {
    return null;
  }
  if (errorOnFetchingPendingInvitationsCount) {
    console.error(errorOnFetchingPendingInvitationsCount);
    return null;
  }
  return (
    <PendingInvitationStyledButton direction={"row"} spacing={0.5} onClick={onClick} alignItems="center">
      <Box
        sx={{
          backgroundColor: TakeTurnsColors.lightBlue,
          padding: "6px",
          display: "flex",
          borderRadius: "50%",
        }}
      >
        <IconBell size={12} color="white" />
      </Box>
      <Typography variant="body2" fontSize={12} color="secondary">
        {`${pendingInvitationsCount} ${t("pendingInvitationLink.pendingInvitation", { count: pendingInvitationsCount })}`}
      </Typography>
    </PendingInvitationStyledButton>
  );
};
