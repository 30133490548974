/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { IconButton, Stack, Typography } from "@mui/material";
import { TakeTurnsListItem } from "@taketurns-components/collaboration/Shared/list/TakeTurnsListItem";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { IconArrowLeft, IconFolder } from "../../commons/icons";

export const CurrentFolderListItemWithBackButton = ({
  folderName,
  onFolderClick,
}: {
  folderName: string;
  onFolderClick: () => void;
}) => {
  return (
    <TakeTurnsListItem
      titleSlot={
        <Stack
          direction="row"
          alignItems={"center"}
          spacing={1}
          overflow="hidden"
          data-cy={`CurrentFolderListItem.${folderName}`}
        >
          <IconButton size="small" disableRipple>
            <IconArrowLeft size={16} color={TakeTurnsColors.grayButton} />
          </IconButton>
          <IconFolder size={16} color={TakeTurnsColors.grayButton} />
          <Typography variant="body2" fontWeight="600" noWrap>
            {folderName}
          </Typography>
        </Stack>
      }
      onClick={onFolderClick}
    />
  );
};
