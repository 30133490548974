/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

export function useDecodeParamsRule<DecodedParameters>(): (
  URLSearchParams: URLSearchParams,
) => DecodedParameters | undefined {
  return (URLSearchParams: URLSearchParams): DecodedParameters | undefined => {
    const paramsToDecode = URLSearchParams.get("params");
    if (!paramsToDecode) {
      return;
    }
    const paramsAsJSON = Buffer.from(paramsToDecode, "base64").toString();
    return JSON.parse(paramsAsJSON);
  };
}
