/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useCallback } from "react";
import { useMoveFolderIntoFolderInCreationRule } from "@taketurns-rules/collaboration/commands/collaborationCreation/folder/useMoveFolderIntoFolderInCreationRule";
import { MoveDestinationDialogInCreation } from "../MoveDestination/MoveDestinationDialogInCreation";

interface FolderMoveDestinationDialogInCollaborationCreationProps {
  folderId: string;
  open: boolean;
  onClose: () => void;
}

export const FolderMoveDestinationDialogInCollaborationCreation = ({
  folderId,
  open,
  onClose,
}: FolderMoveDestinationDialogInCollaborationCreationProps) => {
  return <FolderMoveDestinationDialogOnSuccess folderId={folderId} onClose={onClose} open={open} />;
};

interface FolderMoveDestinationDialogOnSuccessProps {
  folderId: string;
  onClose: () => void;
  open: boolean;
}

const FolderMoveDestinationDialogOnSuccess = ({
  folderId,
  onClose,
  open,
}: FolderMoveDestinationDialogOnSuccessProps) => {
  const moveFolderIntoFolder = useMoveFolderIntoFolderInCreationRule(folderId);
  const moveFolderIntoFolderAndClose = useCallback(
    (destinationFolderId: string) => {
      moveFolderIntoFolder(destinationFolderId);
      onClose();
    },
    [moveFolderIntoFolder, onClose],
  );
  return (
    <MoveDestinationDialogInCreation
      excludedFolderId={folderId}
      onSubmitDestination={moveFolderIntoFolderAndClose}
      onClose={onClose}
      open={open}
    />
  );
};
