/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { IconButton } from "@mui/material";
import { IconMenu } from "../../../../../components/commons/icons";
interface MobileMenuButtonProps {
  onClick: () => void;
}

export const MobileMenuButton = ({ onClick }: MobileMenuButtonProps) => {
  return (
    <IconButton sx={{ paddingLeft: 0 }} onClick={onClick}>
      <IconMenu />
    </IconButton>
  );
};
