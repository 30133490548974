/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { gql } from "@apollo/client";
import { useRefreshableSubscription } from "@taketurns-components/webapp/QueriesAndSubscriptionsRefreshTrigger";
import { GET_PENDING_INVITATIONS } from "@taketurns-repositories/collaboration/graphql/queries/list/useFetchFilteredCollaborationList";
import { wait } from "@taketurns-rules/commons/util/wait";

const SUBSCRIBE_TO_ADDED_REVOKED_INVITATIONS_TO_COLLABORATIONS = gql`
  subscription subscribeToAddedOrRevokedInvitationsToCollaborations($userId: ID, $email: String) {
    onAddedOrRevokedInvitationsToCollaborations(userId: $userId, email: $email) {
      userId
      email
    }
  }
`;
export const useSubscribeToAddOrRevokedInvitationsToCollaborations = (userId?: string, email?: string) => {
  useRefreshableSubscription(SUBSCRIBE_TO_ADDED_REVOKED_INVITATIONS_TO_COLLABORATIONS, {
    variables: {
      userId,
      email,
    },
    skip: !userId,
    onData: async ({ client }) => {
      //Wait 2s for delay between event and openSearch consumer
      await wait(3000);
      client.query({ query: GET_PENDING_INVITATIONS, fetchPolicy: "network-only" });
    },
  });
};
