/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { SubscriptionPlanTier } from "@taketurns/taketurns-graphql-repository";
import i18n from "@taketurns-i18n/i18n";
import { useGetSubscriptionPlanCurrencyRule } from "../queries/plan/getSubscriptionPlanCurrencyRule";
import { useGetSubscriptionPlanPeriodRule } from "../queries/plan/getSubscriptionPlanPeriodRule";
import { useGetSubscriptionPlanRegionRule } from "../queries/plan/getSubscriptionPlanRegionRule";
import { useRedirectToStripeCheckoutRule } from "./plan/useRedirectToStripeCheckoutRule";

export const useSubscribeToPlanRule = () => {
  const region = useGetSubscriptionPlanRegionRule();
  const currency = useGetSubscriptionPlanCurrencyRule();
  const period = useGetSubscriptionPlanPeriodRule();

  const { redirectToStripeCheckout, loadingStripeCheckoutUrl } = useRedirectToStripeCheckoutRule();

  const subscribeToPlan = (selectedPlanTier: SubscriptionPlanTier) => {
    const stripeCheckoutUrlInput = {
      currency,
      region,
      period,
      language: i18n.language,
      tier: selectedPlanTier,
    };
    redirectToStripeCheckout(stripeCheckoutUrlInput);
  };

  return {
    subscribeToPlan,
    loadingStripeCheckoutUrl,
  };
};
