/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { PropsWithChildren } from "react";
import { Chat as StreamChatWrapper } from "stream-chat-react";
import { useConnectToStreamChatRule } from "@taketurns-rules/chat/commands/useConnectToStreamChatRule";

export const ChatWrapper = ({ userId, children }: PropsWithChildren<{ userId?: string }>) => {
  const { client, error } = useConnectToStreamChatRule(userId);

  if (client === null) {
    return null;
  }

  if (error) {
    console.error("Error connecting to stream chat");
    return children;
  }
  return <StreamChatWrapper client={client}>{children}</StreamChatWrapper>;
};
