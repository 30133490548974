/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { ApolloError } from "@apollo/client";
import { Box, Skeleton, styled, Typography } from "@mui/material";
import { Attachment, Revision } from "@taketurns/taketurns-graphql-repository";
import { AttachmentRevisionListItem } from "@taketurns-components/collaboration/CollaborationView/AttachmentDetails/components/DocumentRevisionList/AttachmentRevisionListItem";
import { useSelectDocumentRevisionRule } from "@taketurns-rules/collaboration/commands/collaborationView/attachment/useSelectDocumentRevisionRule";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { useIsOnMobileRule } from "@taketurns-rules/commons/theme/useIsOnMobileRule";

interface DocumentRevisionListProps {
  attachment: Attachment;
  canEdit?: boolean;
  revisions: Revision[];
  loadingDocumentRevisions: boolean;
  errorOnFetchingDocumentRevisions: ApolloError;
}

export const AttachmentRevisionList = (props: DocumentRevisionListProps) => {
  const isOnMobile = useIsOnMobileRule();

  const { selectRevision, selectedRevisionId } = useSelectDocumentRevisionRule();

  if (props.loadingDocumentRevisions && !props.revisions) {
    return (
      <Box flex={1} p={3}>
        <Skeleton variant={"text"} width={"100%"} />
      </Box>
    );
  }
  if (props.errorOnFetchingDocumentRevisions) {
    return (
      <Box flex={1} p={3}>
        <Typography variant={"body2"}>Something went wrong</Typography>
      </Box>
    );
  }
  const revisionList = (
    <RevisionList__BackgroundVerticalLine>
      {props.revisions.toReversed().map((revision, index) => (
        <AttachmentRevisionListItem
          key={revision.revisionId}
          attachment={props.attachment}
          revision={revision}
          selectRevision={() => selectRevision(revision.revisionId)}
          number={props.revisions.length - index}
          canEdit={props.canEdit}
          isSelected={selectedRevisionId === revision.revisionId}
          isLast={index === 0}
        />
      ))}
    </RevisionList__BackgroundVerticalLine>
  );

  if (isOnMobile) {
    return <MobileRevisionList__Container>{revisionList}</MobileRevisionList__Container>;
  }

  return <DesktopRevisionList__Container>{revisionList}</DesktopRevisionList__Container>;
};

const DesktopRevisionList__Container = styled(Box)({
  flex: 1,
  position: "relative",
  display: "flex",
  flexDirection: "column",
  gap: "18px",
  padding: "24px 24px 0",
  width: "100%",
  overflow: "auto",
});

const MobileRevisionList__Container = styled("div")({
  paddingTop: "24px",
  paddingRight: "18px",
});

export const RevisionList__BackgroundVerticalLine = styled(Box)({
  display: "flex",
  flex: 1,
  flexDirection: "column",
  gap: "12px",
  backgroundImage: `linear-gradient(${TakeTurnsColors.borderColor},${TakeTurnsColors.borderColor})`,
  backgroundPosition: "32px 16px",
  backgroundRepeat: "no-repeat",
  backgroundSize: "1px 100%",
});
