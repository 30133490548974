/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Box } from "@mui/material";
import Dompurify from "dompurify";
import "@taketurns-components/collaboration/Shared/RichTextField/RichTextField.css";

export const RichTextEventNote = ({ note }: { note: string }) => {
  return (
    <Box className="ql-snow">
      <Box className="ql-editor" p={0} dangerouslySetInnerHTML={{ __html: Dompurify.sanitize(note) }} />
    </Box>
  );
};
