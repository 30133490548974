/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Box, Stack, Typography } from "@mui/material";
import { Fragment } from "react";
import { useLocation } from "react-router-dom";
import { ExternalLinkStyled, LinkStyled } from "@taketurns-components/webapp/LinkStyled";
import { useWebAppTranslations } from "@taketurns-i18n/webapp/useWebAppTranslations";
import { HelpCenterSections } from "@taketurns-rules/commons/enum/helpCenterSectionsEnum";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { useIsOnMobileRule } from "@taketurns-rules/commons/theme/useIsOnMobileRule";
import { useOpenHelpCenterSection } from "@taketurns-rules/webapp/commands/useOpenHelpCenterSection";
import { useGetHelpCenterUrls } from "@taketurns-rules/webapp/queries/useGetHelpCenterUrls";
import { PUBLIC_ROUTES } from "../../../../routing/routes/publicRoutes.constants";

export const SignupAndTosSection = () => {
  const isOnMobile = useIsOnMobileRule();
  return isOnMobile ? <MobileSignupAndTosSection /> : <DesktopSignupAndTosSection />;
};

const MobileSignupAndTosSection = () => {
  const { t } = useWebAppTranslations("passwordlessCollaborationAccess");
  const { search } = useLocation();
  const { openHelpCenterSection } = useOpenHelpCenterSection(HelpCenterSections.WHAT_IS_TAKETURNS);

  return (
    <Stack flex={1} spacing={3}>
      <Stack flex={1} flexWrap="wrap" justifyContent="space-between" spacing={1} direction="row">
        <Typography variant="caption" color={TakeTurnsColors.darkGray}>
          {t("orSignUp.begin")} <LinkStyled to={PUBLIC_ROUTES.SIGN_UP + search}>{t("orSignUp.signUpLink")}</LinkStyled>{" "}
          {t("orSignUp.end")}
        </Typography>
        <ExternalLinkStyled
          onClick={openHelpCenterSection}
          target="_blank"
          style={{ lineHeight: "1.7em", fontSize: 12 }}
        >
          {t("whatIsTakeTurns")}
        </ExternalLinkStyled>
      </Stack>
      <TosAndPrivacyParagraph />
    </Stack>
  );
};

const DesktopSignupAndTosSection = () => {
  const { t } = useWebAppTranslations("passwordlessCollaborationAccess");
  const { search } = useLocation();

  return (
    <Fragment>
      <Stack flex={1} alignItems="center">
        <Typography>
          {t("orSignUp.begin")} <LinkStyled to={PUBLIC_ROUTES.SIGN_UP + search}>{t("orSignUp.signUpLink")}</LinkStyled>{" "}
          {t("orSignUp.end")}
        </Typography>
      </Stack>
      <Box flex={0}>
        <TosAndPrivacyParagraph />
      </Box>
    </Fragment>
  );
};

const TosAndPrivacyParagraph = () => {
  const { t } = useWebAppTranslations("passwordlessCollaborationAccess");
  const { termsUrl, privacyUrl } = useGetHelpCenterUrls();
  return (
    <Typography fontSize={"0.75rem"} color={TakeTurnsColors.mediumGray}>
      {t("acceptTosAndPrivacy.begin")}{" "}
      <ExternalLinkStyled href={termsUrl} target="_blank">
        {t("acceptTosAndPrivacy.termOfServiceLink")}
      </ExternalLinkStyled>{" "}
      {t("acceptTosAndPrivacy.middle")}{" "}
      <ExternalLinkStyled href={privacyUrl} target="_blank">
        {t("acceptTosAndPrivacy.privacyPolicyLink")}
      </ExternalLinkStyled>
      {". "}
      {t("acceptTosAndPrivacy.end")}
    </Typography>
  );
};
