/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { gql, useLazyQuery, useQuery } from "@apollo/client";
import { Collaboration } from "@taketurns/taketurns-graphql-repository";

const GET_COLLABORATION_NAME = gql`
  query collaborationName($collaborationId: ID!) {
    getCollaboration(collaborationId: $collaborationId) {
      id
      name
    }
  }
`;

export const useLazyFetchCollaborationName = () => {
  const [fetchCollaborationName] = useLazyQuery(GET_COLLABORATION_NAME);
  return async (collaborationId: string): Promise<string> => {
    const { data } = await fetchCollaborationName({
      variables: {
        collaborationId,
      },
    });
    return data?.getCollaboration?.name;
  };
};

export const useFetchCollaborationName = (collaborationId: string) => {
  const {
    data,
    loading: loadingCollaborationName,
    error: errorOnFetchingCollaborationName,
  } = useQuery<{ getCollaboration: Collaboration }>(GET_COLLABORATION_NAME, { variables: { collaborationId } });
  return {
    name: data?.getCollaboration?.name,
    loadingCollaborationName,
    errorOnFetchingCollaborationName,
  };
};
