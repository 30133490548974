/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { selector } from "recoil";
import { PendingAttachmentRevision } from "@taketurns-repositories/collaboration/models/pendingAttachmentRevision";
import { Upload } from "../../../document/state/models/upload";
import { uploadsByUploadIdState } from "../../../document/state/recoil/uploadsByUploadIdState";
import { selectAllPendingAttachmentRevisionsState } from "./selectAllPendingAttachmentRevisionsState";

export const selectPendingAttachmentRevisionsPendingUpload = selector<PendingAttachmentRevision[]>({
  key: "selectAllPendingAttachmentRevisionsPendingUpload",
  get: ({ get }) => {
    return get(selectAllPendingAttachmentRevisionsState).filter(
      (pendingAttachmentRevision) =>
        get<Upload>(uploadsByUploadIdState(pendingAttachmentRevision.uploadId)).isPending() &&
        !pendingAttachmentRevision.isUploading(),
    );
  },
});
