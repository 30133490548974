/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useLazyQuery } from "@apollo/client";

const GET_DOCUMENT_REVISION_DOWNLOAD_URL = gql`
  query documentRevisionDownloadUrl($documentId: ID!, $revisionId: ID!, $collaborationId: ID!) {
    documentRevisionDownloadUrl(collaborationId: $collaborationId, documentId: $documentId, revisionId: $revisionId)
  }
`;
export const useFetchDocumentRevisionDownloadUrl = () => {
  const [
    fetchDocumentRevisionDownloadUrlQuery,
    { loading: loadingDocumentRevisionDownloadUrl, error: errorOnLoadingDocumentRevisionDownloadUrl, data },
  ] = useLazyQuery<{ documentRevisionDownloadUrl: string }>(GET_DOCUMENT_REVISION_DOWNLOAD_URL, {
    fetchPolicy: "no-cache",
  });
  const fetchDocumentRevisionDownloadUrl = async (collaborationId: string, documentId: string, revisionId: string) => {
    return (await fetchDocumentRevisionDownloadUrlQuery({ variables: { collaborationId, documentId, revisionId } }))
      .data.documentRevisionDownloadUrl;
  };
  return {
    fetchDocumentTurnRevisionDownloadUrl: fetchDocumentRevisionDownloadUrl,
    documentRevisionDownloadUrl: data?.documentRevisionDownloadUrl,
    loadingDocumentRevisionDownloadUrl,
    errorOnLoadingDocumentRevisionDownloadUrl,
  };
};
