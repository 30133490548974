/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Box, styled } from "@mui/material";
import { ReactNode } from "react";
import { BodyLayout } from "../BodyLayout";

interface SingleColumnLayoutProps {
  header: ReactNode;
  content: ReactNode;
  backAction?: () => void;
  wideMargin?: boolean;
}

export const SingleColumnLayout = ({ header, content, backAction, wideMargin }: SingleColumnLayoutProps) => {
  return (
    <BodyLayout
      backAction={backAction}
      header={header}
      content={<SingleColumnBox className="SingleColumnBox">{content}</SingleColumnBox>}
      responsiveMargin={wideMargin}
    />
  );
};

const SingleColumnBox = styled(Box)(() => ({
  borderTopLeftRadius: 20,
  borderTopRightRadius: 20,
  backgroundColor: "white",
  padding: 24,
  boxShadow: "0px 1px 20px 0px rgba(0, 0, 0, 0.05)",
  width: "100%",
  height: "100%",
}));
