/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useMutation } from "@apollo/client";
import { RemoveAuthenticationMethodInput } from "@taketurns/taketurns-graphql-repository";

export const useRemoveAuthenticationMethod = () => {
  const mutation = gql`
    mutation removeAuthenticationMethod($removeAuthenticationMethodInput: RemoveAuthenticationMethodInput!) {
      removeAuthenticationMethod(removeAuthenticationMethodInput: $removeAuthenticationMethodInput) {
        id
        authenticationMethods
      }
    }
  `;
  const [
    removeAuthenticationMethodMutation,
    { error: errorOnRemoveAuthenticationMethod, loading: loadingRemoveAuthenticationMethod },
  ] = useMutation(mutation);
  const removeAuthenticationMethod = (removeAuthenticationMethodInput: RemoveAuthenticationMethodInput) => {
    return removeAuthenticationMethodMutation({ variables: { removeAuthenticationMethodInput } });
  };

  return { removeAuthenticationMethod, errorOnRemoveAuthenticationMethod, loadingRemoveAuthenticationMethod };
};
