/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Box, Stack, styled, Typography } from "@mui/material";
import { Collaboration, CollaborationStatus } from "@taketurns/taketurns-graphql-repository";
import { MobileCollaborationRowName } from "@taketurns-components/collaboration/CollaborationList/MobileCollaborationList/MobileCollaborationListRow/MobileCollaborationRowName";
import { useCollaborationListTranslation } from "@taketurns-i18n/collaboration/CollaborationList/useCollaborationListTranslation";
import { useDurationLabelFromDateRule } from "@taketurns-rules/collaboration/ui/useDurationLabelFromDateRule";
import { subtractNowInDays } from "@taketurns-rules/collaboration/utils/date/DateUtils";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { ChatBubbleWithUnreadMessageBadge } from "../../../../chat/ChatBubbleWithUnreadMessageBadge/ChatBubbleWithUnreadMessageBadge";
import { DayIndicator } from "../../../DayIndicator/DayIndicator";
import { OtherPartyNameCell } from "../../CollaborationListColumns/shared/OtherPartyNameCell";

interface MobileCollaborationRowProps {
  collaboration: Collaboration;
  onClick: (collaborationId: string) => void;
}

const OtherPartyName = (props: { otherPartyName: string }) => {
  return (
    <Box overflow="hidden">
      <OtherPartyNameCell otherPartyName={props.otherPartyName} />
    </Box>
  );
};
export const MobileCollaborationRow = ({ collaboration, onClick }: MobileCollaborationRowProps) => {
  return (
    <RowContainer
      onClick={() => {
        onClick(collaboration.id);
      }}
    >
      <Stack direction="row">
        <Box flex={1} minWidth={0}>
          <MobileCollaborationRowName name={collaboration.name} />
          <OtherPartyName otherPartyName={collaboration.userContext.otherPartyName} />
        </Box>
        <Stack flex={0} alignItems="center" justifyContent="center">
          <ChatBubbleWithUnreadMessageBadge collaborationId={collaboration.id} />
        </Stack>
      </Stack>
      <Box alignSelf="flex-end">
        {collaboration.status === CollaborationStatus.Open && <ToDoAndLastUpdateNotice collaboration={collaboration} />}
        {collaboration.status === CollaborationStatus.Closed && (
          <PendingDeletionNotice wipeDate={collaboration.wipeDate} />
        )}
      </Box>
    </RowContainer>
  );
};

const RowContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
}));

const ToDoAndLastUpdateNotice = (props: { collaboration: Collaboration }) => {
  const { t } = useCollaborationListTranslation();

  const { attachmentToReviewCount, requestToFulfillCount, hasPendingUpdates } = props.collaboration.contentMetadata;
  const { date, partyName, isFromConnectedUserParty } = props.collaboration.lastUpdateEvent;
  const todoCount = attachmentToReviewCount + requestToFulfillCount;

  return (
    <Box>
      {todoCount > 0 && (
        <Typography component="span" fontSize={12} fontWeight="bold" color={TakeTurnsColors.otherParty}>
          {todoCount} {t("toDo") + "."}
        </Typography>
      )}
      {todoCount > 0 && " "}
      {hasPendingUpdates && (
        <Typography component="span" fontSize={12} color={TakeTurnsColors.darkGray}>
          {t("youHave") + " "}
          <Typography component="span" fontSize={12} fontWeight="bold" color={TakeTurnsColors.yellow}>
            {t("MobileCollaborationRow.pendingUpdates") + "."}
          </Typography>
        </Typography>
      )}
      {!hasPendingUpdates && (
        <LastUpdateNotice
          lastUpdateDate={date}
          lastUpdatePartyName={partyName}
          isLastUpdateFromConnectedUserParty={isFromConnectedUserParty}
        />
      )}
    </Box>
  );
};

const LastUpdateNotice = (props: {
  lastUpdateDate: string;
  lastUpdatePartyName: string;
  isLastUpdateFromConnectedUserParty: boolean;
}) => {
  const { t } = useCollaborationListTranslation();
  const getDurationLabelFromDate = useDurationLabelFromDateRule();
  return (
    <Typography display="inline-flex" gap={0.5} fontSize={12} color={TakeTurnsColors.darkGray}>
      {`${t("MobileCollaborationRow.updated")} ${getDurationLabelFromDate(props.lastUpdateDate, true)} ${t("MobileCollaborationRow.by")} `}
      <Typography
        noWrap
        maxWidth={150}
        title={props.lastUpdatePartyName}
        fontSize={12}
        color={props.isLastUpdateFromConnectedUserParty ? TakeTurnsColors.connectedUser : TakeTurnsColors.otherParty}
        component="span"
      >
        {props.lastUpdatePartyName}
      </Typography>
    </Typography>
  );
};

const PendingDeletionNotice = (props: { wipeDate: string }) => {
  const { t } = useCollaborationListTranslation();

  let remainingDays = Math.trunc(subtractNowInDays(props.wipeDate));
  remainingDays = remainingDays < 1 ? 1 : remainingDays;
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Typography fontSize={12} color={TakeTurnsColors.darkGray}>
        {t("removedIn.begin.closedCollaboration", { count: remainingDays })}
      </Typography>
      <DayIndicator>{remainingDays}</DayIndicator>
      <Typography fontSize={12} color={TakeTurnsColors.darkGray}>
        {t("removedIn.day", { count: remainingDays })}
      </Typography>
    </Stack>
  );
};
