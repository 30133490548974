/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useRecoilCallback } from "recoil";
import { attachmentByIdState } from "../../../recoil/collaborationCreation/attachmentByIdState";
import { folderByIdState } from "../../../recoil/collaborationCreation/folderByIdState";
import { parentFolderIdByAttachmentIdState } from "../../../recoil/collaborationCreation/parentFolderIdByAttachmentIdState";

export const useAddAttachmentInCreation = () => {
  return useRecoilCallback(
    ({ set }) =>
      (documentId: string, targetFolderId: string) => {
        set(attachmentByIdState(documentId), { documentId, markedForReview: false });
        set(folderByIdState(targetFolderId), (currVal) => ({
          ...currVal,
          attachments: [...currVal.attachments, documentId],
        }));
        set(parentFolderIdByAttachmentIdState(documentId), targetFolderId);
      },
    [],
  );
};
