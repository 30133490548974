/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Storage } from "aws-amplify";
import { useSnackbar } from "notistack";
import { useGenerateDocumentId } from "@taketurns-repositories/document/graphql/queries/generateDocumentId";
import { Upload } from "@taketurns-repositories/document/state/models/upload";
import { useGetUploadById } from "@taketurns-repositories/document/state/read/useGetUploadById";
import { useMarkUploadAsComplete } from "@taketurns-repositories/document/state/write/markUploadAsComplete";
import { useMarkUploadAsFailed } from "@taketurns-repositories/document/state/write/markUploadAsFailed";
import { useMarkUploadAsStarted } from "@taketurns-repositories/document/state/write/markUploadAsStarted";
import { useRegisterUploadProgress } from "@taketurns-repositories/document/state/write/registerUploadProgress";

export const useStartS3Upload: () => (uploadId: number) => Promise<string> = () => {
  const markUploadAsComplete = useMarkUploadAsComplete();
  const registerUploadProgress = useRegisterUploadProgress();
  const markUploadAsStarted = useMarkUploadAsStarted();

  const getUploadById = useGetUploadById();
  const generateDocumentId = useGenerateDocumentId();

  const { enqueueSnackbar } = useSnackbar();
  const markUploadAsFailed = useMarkUploadAsFailed();

  const notifyFailureAndMarkUploadAsFailed = (upload: Upload): void => {
    enqueueSnackbar(`Failed to upload ${upload.file.name}`, { variant: "error" });
    markUploadAsFailed(upload.uploadId);
  };

  return async (uploadId: number): Promise<string> => {
    const upload = getUploadById(uploadId);
    let documentId = upload.documentId;
    if (!documentId) {
      documentId = (await generateDocumentId()).data!.generateDocumentId;
    }
    const file = upload.file;
    const awsPutOutput = Storage.put(documentId, file, {
      contentType: file.type,
      resumable: true,
      level: "public",
      customPrefix: { public: "uploads/" },
      completeCallback: () => markUploadAsComplete(uploadId),
      progressCallback: (progress: { loaded: number; total: number }) =>
        registerUploadProgress(uploadId, progress.loaded / progress.total),
      errorCallback: () => notifyFailureAndMarkUploadAsFailed(upload),
    });
    markUploadAsStarted(uploadId, documentId, () => Storage.cancel(awsPutOutput));
    return documentId;
  };
};
