/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useQuery } from "@apollo/client";
import { ConnectedUser } from "@taketurns/taketurns-graphql-repository";

export const useFetchUserPreferredCollaborationIds = () => {
  const PREFERRED_COLLABORATION_IDS = gql`
    query PreferredCollaborationIds {
      connectedUser {
        id
        favorites
      }
    }
  `;
  const {
    data,
    error: errorOnFetchingPreferredCollaborationIds,
    loading: loadingPreferredCollaborationIds,
  } = useQuery<{ connectedUser: ConnectedUser }>(PREFERRED_COLLABORATION_IDS);
  return {
    preferredCollaborationIds: data?.connectedUser?.favorites ?? [],
    errorOnFetchingPreferredCollaborationIds,
    loadingPreferredCollaborationIds,
  };
};
