/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useSetRecoilState } from "recoil";
import { RequestInCreation } from "../../../../models/requestInCreation";
import { requestByIdState } from "../../../recoil/collaborationCreation/requestByIdState";
export const useModifyRequestDescription = (requestId: string) => {
  const setRequestDescription = useSetRecoilState(requestByIdState(requestId));
  return (description: string) => {
    setRequestDescription((requestInCreation: RequestInCreation) => ({
      ...requestInCreation,
      description: description,
    }));
  };
};
