/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { atom } from "recoil";
import { CreateCollaborationInput } from "@taketurns/taketurns-graphql-repository";

export const collaborationFormState = atom<Partial<CreateCollaborationInput>>({
  key: "collaborationForm",
  default: { name: "", invitations: [], owningPartyName: "", invitedPartyName: "", deadline: "", note: undefined },
});
