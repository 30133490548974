/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Badge, styled } from "@mui/material";
import { UserAvatar } from "@taketurns-components/user/UserProfile/UserAvatar/UserAvatar";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { useFetchUserProfileIsIncompleteRule } from "@taketurns-rules/user/queries/useFetchUserProfileIsIncompleteRule";

export const AuthAppBarUserAvatar = ({ onClick }: { onClick?: () => void }) => {
  const { userProfileIsIncomplete, errorOnUserHasIncompleteProfile } = useFetchUserProfileIsIncompleteRule();

  if (errorOnUserHasIncompleteProfile) {
    console.error(errorOnUserHasIncompleteProfile);
    return <UserAvatar onAvatarClick={onClick} />;
  }

  return (
    <CustomColoredBadge data-cy="AuthAppBarUserAvatar" invisible={!userProfileIsIncomplete} badgeContent={"!"}>
      <UserAvatar onAvatarClick={onClick} />
    </CustomColoredBadge>
  );
};

const CustomColoredBadge = styled(Badge)({
  "& .MuiBadge-badge": {
    color: "white",
    backgroundColor: TakeTurnsColors.yellow,
  },
});
