/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Container, styled } from "@mui/material";

export const UserAuthenticationLayout = styled(Container)({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  overflow: "auto",
});
