/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Stack, styled, TextField } from "@mui/material";
import { Party } from "@taketurns/taketurns-graphql-repository";
import { useCollaborationTranslation } from "@taketurns-i18n/collaboration/useCollaborationTranslation";
import {
  useGetCollaborationPartyNameErrorByParty
} from "@taketurns-repositories/collaboration/state/read/collaborationCreation/form/error/useGetCollaborationPartyNameErrorByParty";
import { useSetCollaborationFormPartyNameByPartyRule } from "@taketurns-rules/collaboration/commands/collaborationCreation/form/useSetCollaborationFormPartyNameByPartyRule";
import { useGetCollaborationFormPartyNameByPartyRule } from "@taketurns-rules/collaboration/queries/collaborationCreation/form/useGetCollaborationFormPartyNameByPartyRule";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { withMaxLengthControl } from "@taketurns-rules/commons/util/form/formUtils";
import { CollaborationCreationInvitees } from "./CollaborationCreationInvitees";

export interface CollaborationCreationPartyFormProps {
  party: Party;
}

export const CollaborationCreationPartyForm = (props: CollaborationCreationPartyFormProps) => {
  const { party } = props;
  const { t } = useCollaborationTranslation("participantCollaboration");

  const collaborationPartyNameError = useGetCollaborationPartyNameErrorByParty(party);
  const partyName = useGetCollaborationFormPartyNameByPartyRule()(party);
  const setPartyName = useSetCollaborationFormPartyNameByPartyRule()(party);

  return (
    <Stack className="CollaborationCreationPartyForm" useFlexGap flex={1} spacing={{ xs: 3, md: 4 }} minHeight={0}>
      <TextField__ColoredBorder
        variant="outlined"
        error={collaborationPartyNameError}
        placeholder={party === Party.Owning ? t("owningPartyPlaceholder") : t("invitedPartyPlaceholder")}
        tabIndex={party === Party.Owning ? 2 : 3}
        customColor={party === Party.Owning ? TakeTurnsColors.connectedUser : TakeTurnsColors.otherParty}
        value={partyName}
        onChange={(event) => withMaxLengthControl(event, (newPartyName) => setPartyName(newPartyName))}
      />
      <CollaborationCreationInvitees {...props} />
    </Stack>
  );
};

const TextField__ColoredBorder = styled(TextField, { shouldForwardProp: (prop) => prop !== "customColor" })<{
  customColor: string;
  error: boolean;
}>(({ customColor, error, theme }) => ({
  [theme.breakpoints.down("md")]: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  "& .MuiOutlinedInput-root": {
    color: customColor,
    "& .MuiOutlinedInput-input::placeholder": {
      color: !error ? customColor : TakeTurnsColors.error,
      opacity: 1,
    },
    "& fieldset": {
      borderColor: customColor,
    },
    "&:hover fieldset": {
      borderColor: customColor,
    },
    "&.Mui-focused fieldset": {
      borderColor: customColor,
    },
  },
}));
