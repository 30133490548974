/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Avatar } from "@mui/material";

interface UserRoundedAvatarProps {
  user: { firstName?: string; lastName?: string; email: string; avatar?: string };
  onAvatarClick?: () => void;
  size?: number;
}

export const UserRoundedAvatar = (props: UserRoundedAvatarProps) => {
  const {
    user: { firstName, lastName, email, avatar },
    onAvatarClick,
    size,
  } = props;
  const getUserInitials = () => {
    if (firstName && lastName) {
      return firstName[0] + lastName[0];
    }
  };
  const getAvatarAlt = () => {
    if (firstName && lastName) {
      return `Picture of ${firstName} ${lastName}`;
    }
    return `Picture of ${email}`;
  };

  return (
    <Avatar
      onClick={onAvatarClick}
      alt={getAvatarAlt()}
      sx={{
        width: size ? size : 30,
        height: size ? size : 30,
        "&:hover": { cursor: "pointer" },
        fontSize: size ? 16 : 14,
      }}
      src={avatar}
    >
      {getUserInitials()}
    </Avatar>
  );
};
