/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Box } from "@mui/material";
import { PropsWithChildren } from "react";
import { TakeTurnsSidebarContentHeight } from "@taketurns-rules/commons/theme/TakeTurnsTheme";

export const SidebarContent = (props: PropsWithChildren) => {
  return <Box height={TakeTurnsSidebarContentHeight}>{props.children}</Box>;
};
