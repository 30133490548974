/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { gql, useQuery } from "@apollo/client";

export const useFetchIsConnectedUserTipsHidden = () => {
  const GET_CONNECTED_USER_TIPS_DISPLAY = gql`
    query getConnectedUserTipsHidden {
      connectedUser {
        id
        preferences {
          tipsHidden
        }
      }
    }
  `;
  const { data, loading, error } = useQuery<{
    connectedUser: {
      id: string;
      preferences: {
        tipsHidden: boolean;
      };
    };
  }>(GET_CONNECTED_USER_TIPS_DISPLAY);
  return {
    isTipsHidden: data?.connectedUser.preferences.tipsHidden,
    tipsHiddenLoading: loading,
    tipsHiddenError: error,
  };
};
