/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { OrganizationMember, OrganizationUserRole } from "@taketurns/taketurns-graphql-repository";
import { useModifyOrganizationMemberRole } from "@taketurns-repositories/user/graphql/mutations/useModifyOrganizationMemberRole";
import { useDisplayAndLogNotificationErrorRule } from "./useDisplayAndLogNotificationErrorRule";

export const useModifyMemberRoleRule = () => {
  const {
    modifyOrganizationMemberRole: modifyOrganizationMemberRoleCommand,
    errorOnModifyMemberRole,
    loadingModifyMemberRole,
  } = useModifyOrganizationMemberRole();

  const displayNotificationAndLogError = useDisplayAndLogNotificationErrorRule();

  const modifyOrganizationMemberRole = (member: OrganizationMember, newRole: OrganizationUserRole) => {
    modifyOrganizationMemberRoleCommand({
      userId: member.userId,
      role: newRole,
    }).catch((error) => displayNotificationAndLogError(error));
  };

  return { modifyOrganizationMemberRole, errorOnModifyMemberRole, loadingModifyMemberRole };
};
