/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Box, CircularProgress, Stack } from "@mui/material";
import { FetchLoadingManager } from "@taketurns-app/utils/FetchLoadingManager";
import { SomethingWentWrong } from "@taketurns-components/shared/SomethingWentWrong";
import { useFetchParticipantsAndInvitationsRule } from "@taketurns-rules/collaboration/queries/collaborationView/useFetchParticipantsAndInvitationsRule";
import { useFetchConnectedUserPartyRule } from "@taketurns-rules/collaboration/queries/context/useFetchConnectedUserPartyRule";
import { useFetchCollaborationPartyNamesRule } from "@taketurns-rules/collaboration/queries/fields/useFetchCollaborationPartyNamesRule";
import { useSubscribeToParticipantsAndInvitationsChangedRule } from "@taketurns-rules/collaboration/subscriptions/useSubscribeToParticipantsAndInvitationsChangedRule";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { Invitee, Participant, Party } from "@taketurns/taketurns-graphql-repository";
import { useState } from "react";
import { MobileFullPageSheet } from "../../../../../../commons/MobileSheets/MobileFullPageSheet";
import { TakeTurnsMultiSwitch } from "../../../../../../commons/switch/TakeTurnsMultiSwitch";
import { MobileParticipantPanel } from "./MobileParticipantPanel";
import { MobileParticipantsPageHeader } from "./MobileParticipantsPageHeader";

interface MobileParticipantsPageProps {
  isOpen: boolean;
  closeDialog: () => void;
}

export const MobileParticipantsPage = ({ isOpen, closeDialog }: MobileParticipantsPageProps) => (
  <MobileFullPageSheet isOpen={isOpen} header={<MobileParticipantsPageHeader closeDialog={closeDialog} />}>
    <MobileParticipantPageContent />
  </MobileFullPageSheet>
);

const MobileParticipantPageContent = () => {
  const { connectedUserParty, participants, invitations, owningPartyName, invitedPartyName, loading, error } =
    useFetchMobileParticipantsPageData();

  if (error) {
    return <SomethingWentWrong />;
  }

  if (loading) {
    return (
      <Stack flex={1} mx="auto" justifyContent="center">
        <CircularProgress />
      </Stack>
    );
  }

  return (
    <MobileParticipantsContent
      invitedParty={invitedPartyName}
      owningParty={owningPartyName}
      participants={participants}
      invitations={invitations}
      userParty={connectedUserParty}
    />
  );
};

const MobileParticipantsContent = ({
  owningParty,
  invitedParty,
  invitations,
  participants,
  userParty,
}: {
  owningParty: string;
  invitedParty: string;
  invitations: Invitee[];
  participants: Participant[];
  userParty: Party;
}) => {
  const userIsOwner = userParty === Party.Owning;
  const [selectedParty, setSelectedParty] = useState<Party>(userIsOwner ? Party.Invited : Party.Owning);

  const switchOptions = {
    [Party.Owning]: {
      label: owningParty,
      color: userIsOwner ? TakeTurnsColors.connectedUser : TakeTurnsColors.otherParty,
    },
    [Party.Invited]: {
      label: invitedParty,
      color: userIsOwner ? TakeTurnsColors.otherParty : TakeTurnsColors.connectedUser,
    },
  };

  return (
    <Stack>
      <Box sx={{ margin: 3 }}>
        <TakeTurnsMultiSwitch options={switchOptions} selectedOptionId={selectedParty} onChange={setSelectedParty} />
      </Box>
      <MobileParticipantPanel
        invitations={invitations}
        participants={participants}
        userParty={userParty}
        party={selectedParty}
      />
    </Stack>
  );
};

function useFetchMobileParticipantsPageData() {
  const { connectedUserParty, errorOnFetchingConnectedUserParty, loadingConnectedUserParty } =
    useFetchConnectedUserPartyRule();
  const { participants, invitations, loadingParticipantsAndInvitations, errorOnParticipantsAndInvitations } =
    useFetchParticipantsAndInvitationsRule();
  useSubscribeToParticipantsAndInvitationsChangedRule();
  const { owningPartyName, invitedPartyName, loadingCollaborationPartyNames, errorOnFetchingCollaborationPartyNames } =
    useFetchCollaborationPartyNamesRule();

  const fetchLoadingManager = new FetchLoadingManager(
    [loadingConnectedUserParty, loadingParticipantsAndInvitations, loadingCollaborationPartyNames],
    [errorOnFetchingConnectedUserParty, errorOnParticipantsAndInvitations, errorOnFetchingCollaborationPartyNames],
  );
  return {
    connectedUserParty,
    participants,
    invitations,
    owningPartyName,
    invitedPartyName,
    loading: fetchLoadingManager.hasFetchLoading(),
    error: fetchLoadingManager.hasFetchInError(),
  };
}
