/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Box } from "@mui/material";
import { useRef, useState } from "react";
import { NewCollaborationMenu } from "@taketurns-components/collaboration/CollaborationCreation/CreateCollaborationButton/NewCollaborationMenu";
import { UpgradePlanToCreateCollaborationText } from "@taketurns-components/collaboration/Shared/UpgradePlanToCreateCollaborationText";
import { WithOpenUpgradePlanDialogOnClick } from "@taketurns-components/collaboration/Shared/WithOpenUpgradePlanDialogOnClick";
import { TakeTurnsDefaultButton } from "@taketurns-components/commons/button";
import { IconPlus } from "@taketurns-components/commons/icons";
import { useCollaborationCreationTranslation } from "@taketurns-i18n/collaboration/CollaborationCreation/useCollaborationCreationTranslation";
import { useFetchUserHasCreateCollaborationFeatureRule } from "@taketurns-rules/collaboration/queries/useFetchUserHasCreateCollaborationFeatureRule";

export const CreateNewCollaborationButton = ({ beforeClickAction }: { beforeClickAction?: () => void }) => {
  const { hasCreateCollaborationFeature } = useFetchUserHasCreateCollaborationFeatureRule();
  const { t } = useCollaborationCreationTranslation();
  const createCollaborationButtonRef = useRef(null);
  const [createCollaborationMenuOpened, setCreateCollaborationMenuOpened] = useState(false);

  const CreateNewCollaborationButtonWithUpgradeDialog = WithOpenUpgradePlanDialogOnClick(TakeTurnsDefaultButton, {
    UpgradeToPlanDialogContentSlot: <UpgradePlanToCreateCollaborationText />,
    isFeatureAvailable: hasCreateCollaborationFeature,
  });

  const openNewCollaborationMenu = () => {
    setCreateCollaborationMenuOpened(true);
  };

  const closeNewCollaborationMenu = () => {
    beforeClickAction && beforeClickAction();
    setCreateCollaborationMenuOpened(false);
  };

  return (
    <Box ref={createCollaborationButtonRef}>
      <CreateNewCollaborationButtonWithUpgradeDialog startIcon={<IconPlus />} onClick={openNewCollaborationMenu}>
        {t("new")}
      </CreateNewCollaborationButtonWithUpgradeDialog>
      <NewCollaborationMenu
        open={createCollaborationMenuOpened}
        anchorEl={createCollaborationButtonRef.current}
        onClick={closeNewCollaborationMenu}
      />
    </Box>
  );
};
