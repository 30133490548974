/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Box, ButtonProps, IconButton, styled, Typography } from "@mui/material";
import { useCallback } from "react";
import { Attachment } from "@taketurns/taketurns-graphql-repository";
import { IconTrash } from "@taketurns-components/commons/icons";
import { useAttachmentDetailsTranslation } from "@taketurns-i18n/collaboration/AttachmentDetails/useAttachmentDetailsTranslation";
import { useRemoveAttachmentRule } from "@taketurns-rules/collaboration/commands/collaborationView/attachment/useRemoveAttachmentRule";
import { useCloseAttachmentDetailsRule } from "@taketurns-rules/collaboration/commands/collaborationView/useCloseAttachmentDetailsRule";

export const RemoveAttachmentButton = ({
  attachment,
  ...buttonProps
}: {
  attachment: Attachment;
} & Omit<ButtonProps, "onClick" | "size" | "disableRipple">) => {
  const { removeAttachment, removeAttachmentLoading } = useRemoveAttachmentRule(attachment);
  const closeDocumentDetailsRule = useCloseAttachmentDetailsRule();

  const { t } = useAttachmentDetailsTranslation();

  const removeAttachmentAndCloseDetailsDialog = useCallback(() => {
    removeAttachment();
    closeDocumentDetailsRule();
  }, [closeDocumentDetailsRule, removeAttachment]);

  return (
    <RemoveAttachmentButton__DesktopContainer>
      <Typography variant={"body2"}>{t("removeFile")}</Typography>
      <IconButton
        {...buttonProps}
        size="small"
        disabled={removeAttachmentLoading || buttonProps.disabled}
        onClick={removeAttachmentAndCloseDetailsDialog}
        sx={{ padding: 0 }}
        disableRipple
      >
        <IconTrash size={20} color="#CCCCCC" />
      </IconButton>
    </RemoveAttachmentButton__DesktopContainer>
  );
};

const RemoveAttachmentButton__DesktopContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: theme.spacing(1),
  padding: theme.spacing(1, 3),
  height: 40,
}));
