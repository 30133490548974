/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useGetSelectedRevisionId } from "@taketurns-repositories/collaboration/state/read/useGetSelectedRevisionId";
import { useFetchDocumentRevisionsRule } from "@taketurns-rules/document/queries/useFetchDocumentRevisionsRule";

export const useIsFirstRevisionSelectedRule = (documentId: string) => {
  const { revisions } = useFetchDocumentRevisionsRule(documentId);
  const selectedRevisionId = useGetSelectedRevisionId();
  return revisions?.find((r) => r.order === 0).revisionId === selectedRevisionId;
};
