/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Party } from "@taketurns/taketurns-graphql-repository";
import { TakeTurnsColors } from "../../../commons/theme/TakeTurnsTheme";

export const getColorForUserParty = (
  party: Party,
  userParty: Party,
): { variable: string; themeColor: "primary" | "secondary" } => {
  if (
    (party === Party.Owning && userParty === Party.Owning) ||
    (party === Party.Invited && userParty === Party.Invited)
  ) {
    return { variable: TakeTurnsColors.connectedUser, themeColor: "primary" };
  }
  return { variable: TakeTurnsColors.otherParty, themeColor: "secondary" };
};

export const getDefaultColorForParty = (party: Party): { variable: string; themeColor: "primary" | "secondary" } => {
  if (party === Party.Owning) {
    return { variable: TakeTurnsColors.darkBlue, themeColor: "primary" };
  }
  return { variable: TakeTurnsColors.lightBlue, themeColor: "secondary" };
};
