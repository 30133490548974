/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useFetchConnectedUserPartyRule } from "@taketurns-rules/collaboration/queries/context/useFetchConnectedUserPartyRule";
import { useGetCurrentCollaborationParticipantPartyRule } from "../../chat/queries/useGetCurrentCollaborationParticipantPartyRule";
import { TakeTurnsColors } from "../../commons/theme/TakeTurnsTheme";

export const useGetUserColorRule = () => {
  const { connectedUserParty } = useFetchConnectedUserPartyRule();
  const getUserParty = useGetCurrentCollaborationParticipantPartyRule();

  return (userId: string) => {
    const userParty = getUserParty(userId);

    if (!userParty) {
      return TakeTurnsColors.darkGray;
    }

    if (connectedUserParty === userParty) {
      return TakeTurnsColors.connectedUser;
    } else {
      return TakeTurnsColors.otherParty;
    }
  };
};
