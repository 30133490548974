/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useCallback } from "react";
import { CollaborationStatus } from "@taketurns/taketurns-graphql-repository";
import { useFetchCollaborationStatusRule } from "@taketurns-rules/collaboration/queries/fields/useFetchCollaborationStatusRule";
import { CollaborationCommand, useCollaborationPermissions } from "./useCollaborationPermissions";

export const useAddOrManageContentPermission = () => {
  const userCanAddContent = useCollaborationPermissions(CollaborationCommand.ADD_FILES);
  const { status } = useFetchCollaborationStatusRule();

  const isAllowedToAddOrManageContent = useCallback(() => {
    return status === CollaborationStatus.Open && userCanAddContent;
  }, [status, userCanAddContent]);

  return isAllowedToAddOrManageContent();
};
