/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Chat } from "@taketurns-components/chat/Chat/Chat";
import { ChatSidebarButton } from "@taketurns-components/chat/ChatSidebarButton/ChatSidebarButton";
import { ChatWrapper } from "@taketurns-components/chat/ChatWrapper/ChatWrapper";
import { useFetchConnectedUserStatusRule } from "@taketurns-rules/collaboration/queries/context/useFetchConnectedUserStatusRule";
import { useIsOnMobileRule } from "@taketurns-rules/commons/theme/useIsOnMobileRule";
import { useSetSidebarOpenStatusRule } from "@taketurns-rules/webapp/commands/useSetSidebarOpenStatusRule";
import { useCallback, useEffect } from "react";
import { useLoaderData } from "react-router-dom";
import { SidebarButtonProps } from "../../../layouts/authenticated/SidebarLayout/SidebarButton";
import { SidebarLayout } from "../../../layouts/authenticated/SidebarLayout/SidebarLayout";

/**
 * Sidebar displayed on collaboration view details
 * @return {ReactNode} Sidebar in collaboration view
 */
export const CollaborationSidebar = () => {
  const { collaborationId } = useLoaderData() as {
    collaborationId: string;
  };
  const { isParticipant, isSupervisor } = useFetchConnectedUserStatusRule();

  const getSidebarServices = useCallback(() => {
    if (collaborationId) {
      return [<Chat collaborationId={collaborationId} />];
    }
    return [];
  }, [collaborationId]);

  const getSidebarServiceButtons = useCallback((): SidebarButtonProps[] => {
    return [
      {
        icon: <ChatSidebarButton collaborationId={collaborationId} />,
        label: "Chat",
        active: true,
      },
    ];
  }, [collaborationId]);

  const setSidebarOpenedStatus = useSetSidebarOpenStatusRule();
  const isOnMobile = useIsOnMobileRule();
  useEffect(() => {
    if (isOnMobile) {
      setSidebarOpenedStatus(false);
    } else {
      setSidebarOpenedStatus(true);
    }
  }, [isOnMobile, setSidebarOpenedStatus]);

  const shouldConnectToChatAsSupervisor = isSupervisor && !isParticipant;
  return (
    <ChatWrapper userId={shouldConnectToChatAsSupervisor ? collaborationId : undefined}>
      <SidebarLayout buttons={getSidebarServiceButtons()} services={getSidebarServices()} />
    </ChatWrapper>
  );
};
