/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { FetchLoadingManager } from "@taketurns-app/utils/FetchLoadingManager";
import { useFetchUserOrganization } from "@taketurns-repositories/user/graphql/queries/useFetchUserOrganization";
import { useFetchUserPendingOrganizationInvitation } from "@taketurns-repositories/user/graphql/queries/useFetchUserPendingOrganizationInvitation";
import { useOnAddedOrRemovedInvitationToOrganizationRule } from "../subscriptions/useOnAddedOrRemovedInvitationToOrganizationRule";

export const useIsUserOrganizationInvitationNotificationDisplayedRule = () => {
  const { pendingOrganizationInvitation, loadingUserPendingOrganizationInvitation, errorOnUserPendingOrganization } =
    useFetchUserPendingOrganizationInvitation();
  const { userOrganization, loadingUserOrganization, errorOnLoadingUserOrganization } = useFetchUserOrganization();
  useOnAddedOrRemovedInvitationToOrganizationRule();

  const fetchLoadingManager = new FetchLoadingManager(
    [loadingUserPendingOrganizationInvitation, loadingUserOrganization],
    [errorOnLoadingUserOrganization, errorOnUserPendingOrganization],
  );

  if (fetchLoadingManager.hasFetchInError()) {
    fetchLoadingManager.logErrors();
    return { isUserOrganizationInvitationNotificationDisplayed: false, pendingOrganizationInvitation };
  }

  const userHasPendingOrganizationInvitation =
    !loadingUserPendingOrganizationInvitation && !!pendingOrganizationInvitation?.organizationId;
  // The following is checked because it is possible that the user already accepted the invitation while OpenSearch is not yet updated
  const isPendingOrganizationInvitationSameOrganizationThanUser =
    userOrganization?.id === pendingOrganizationInvitation?.organizationId;
  return {
    isUserOrganizationInvitationNotificationDisplayed:
      userHasPendingOrganizationInvitation && !isPendingOrganizationInvitationSameOrganizationThanUser,
    pendingOrganizationInvitation,
    loadingUserPendingOrganizationInvitation,
  };
};
