/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useRecoilValue } from "recoil";
import { isExcludedFromCollaborationState } from "../recoil/excludedFromCollaborationState";

export const useGetIsExcludedFromCollaboration = () => {
  return useRecoilValue(isExcludedFromCollaborationState);
};
