/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useLocation } from "react-router-dom";

export const useEmailFromLocationStateRule = () => {
  const { state } = useLocation();
  return state?.email ?? "";
};
