/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

export enum JobFunction {
  "ACCOUNTING" = "1",
  "ADMINISTRATIVE" = "2",
  "ARTS & DESIGN" = "3",
  "BUSINESS DEVELOPMENT" = "4",
  "COMMUNITY AND SOCIAL SERVICES" = "5",
  "CONSULTING" = "6",
  "EDUCATION" = "7",
  "ENGINEERING" = "8",
  "ENTREPRENEURSHIP" = "9",
  "FINANCE" = "10",
  "HEALTHCARE SERVICES" = "11",
  "HUMAN RESOURCES" = "12",
  "INFORMATION TECHNOLOGY" = "13",
  "LEGAL" = "14",
  "MARKETING" = "15",
  "MEDIA AND COMMUNICATION" = "16",
  "MILITARY AND PROTECTIVE SERVICES" = "17",
  "OPERATIONS" = "18",
  "PRODUCT MANAGEMENT" = "19",
  "PROGRAM AND PROJECT MANAGEMENT" = "20",
  "PURCHASING" = "21",
  "QUALITY ASSURANCE" = "22",
  "REAL ESTATE" = "23",
  "RESEARCH" = "24",
  "SALES" = "25",
  "SUPPORT" = "26",
  "OTHER" = "99",
}
