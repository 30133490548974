/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useAddAttachmentRevision } from "@taketurns-repositories/collaboration/graphql/mutations/content/attachments/useAddAttachmentRevision";

export const useAddAttachmentRevisionRule = () => {
  const {
    addAttachmentRevisionMutation,
    addAttachmentRevisionResponseData,
    addAttachmentRevisionLoading,
    errorOnAddingAttachmentRevision,
  } = useAddAttachmentRevision();
  return {
    addAttachmentRevision: addAttachmentRevisionMutation,
    addAttachmentRevisionResponseData,
    addAttachmentRevisionLoading,
    errorOnAddingAttachmentRevision,
  };
};
