/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { atom } from "recoil";
import { Request } from "../../models/request";

export const openedRequestFulfillmentRequestState = atom<Request>({
  key: "openedRequestFulfillmentRequest",
  default: null,
});
