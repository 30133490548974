/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useQuery } from "@apollo/client";
import { SubscriptionPlan } from "@taketurns/taketurns-graphql-repository";

export const useFetchUserOrganizationSubscriptionRegion = (skipQuery?: boolean) => {
  const USER_ORGANIZATION_SUBSCRIPTION_REGION = gql`
    query userSubscription {
      userSubscription {
        id
        region
      }
    }
  `;
  const {
    data,
    error: errorOnFetchingUserOrganizationSubscriptionRegion,
    loading: loadingUserOrganizationSubscriptionRegion,
  } = useQuery<{
    userSubscription: SubscriptionPlan;
  }>(USER_ORGANIZATION_SUBSCRIPTION_REGION, { fetchPolicy: "cache-and-network", skip: skipQuery });
  const userOrganizationSubscriptionRegion = data?.userSubscription?.region;
  return {
    userOrganizationSubscriptionRegion,
    errorOnFetchingUserOrganizationSubscriptionRegion,
    loadingUserOrganizationSubscriptionRegion,
  };
};
