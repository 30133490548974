/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useEffect } from "react";
import { useChatContext } from "stream-chat-react";
import { useSetChannelUnreadCount } from "@taketurns-repositories/chat/state/write/useSetChannelUnreadCount";

export const useFetchAllUnreadMessageCounts = () => {
  const setChannelUnreadCount = useSetChannelUnreadCount();
  const { client } = useChatContext();

  useEffect(() => {
    async function initUnreadCount() {
      const filter = {
        type: "team",
        members: { $in: [client._user!.id] },
      };
      try {
        const channels = await client.queryChannels(filter);
        for (const channel of channels) {
          setChannelUnreadCount(channel.id, channel.countUnread());
          channel.on("message.new", () => setChannelUnreadCount(channel.id, channel.countUnread()));
          channel.on("message.read", () => setChannelUnreadCount(channel.id, channel.countUnread()));
        }
      } catch (error) {
        console.error(error);
      }
    }

    if (client) {
      initUnreadCount();
    }
  }, [client, setChannelUnreadCount]);
};
