/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useFetchFilteredCollaborationList } from "@taketurns-repositories/collaboration/graphql/queries/list/useFetchFilteredCollaborationList";
import { useSelectedSupervisorFilterItems } from "@taketurns-repositories/collaboration/state/read/useSelectedSupervisorFilterItems";
import { useGetListAppliedFilterRule } from "./useGetListAppliedFilterRule";

export const useFilteredCollaborationListRule = () => {
  const collaborationListAppliedFilter = useGetListAppliedFilterRule();
  const selectedSupervisorFilterItems = useSelectedSupervisorFilterItems();
  return useFetchFilteredCollaborationList(collaborationListAppliedFilter, selectedSupervisorFilterItems);
};
