/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { GridLocaleText } from "@mui/x-data-grid/models/api";
import { useTranslation } from "react-i18next";

export const useMUIDataGridTranslations = (): Partial<GridLocaleText> => {
  const { t } = useTranslation("MUIDataGrid");
  return t("components.MuiDataGrid.defaultProps.localeText", {
    returnObjects: true,
  });
};
