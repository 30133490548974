/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { selector } from "recoil";
import { collaborationFormState } from "./collaborationFormState";

export const selectCollaborationFormDeadline = selector<string>({
  key: "collaborationFormDeadline",
  get: ({ get }) => {
    return get(collaborationFormState).deadline;
  },
  set: ({ set }, deadline) => {
    set(collaborationFormState, (currentCollaborationFormState) => ({
      ...currentCollaborationFormState,
      deadline: deadline as string,
    }));
  },
});
