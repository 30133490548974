/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { CircularProgress, IconButton, Popover, Typography } from "@mui/material";
import { Fragment, useRef, useState } from "react";
import { useDocumentUploadTranslation } from "@taketurns-i18n/document/DocumentUpload/useDocumentUploadTranslation";
import { useHasAnyUploadFailed } from "@taketurns-repositories/document/state/read/useHasAnyUploadFailed";
import { useIsAnyUploadsInProgressOrPending } from "@taketurns-repositories/document/state/read/useIsAnyUploadsInProgressOrPending";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { IconCheckCircle, IconXCircle } from "../../../commons/icons";
import { UploadsProgressList } from "./UploadsProgressList";

export const UploadsProgressIndicator = ({ uploadIds }: { uploadIds: number[] }) => {
  const [isUploadProgressListDisplayed, setUploadProgressListDisplayed] = useState(false);
  const hideUploadProgressList = () => {
    setUploadProgressListDisplayed(false);
  };
  const toggleUploadProgressListDisplay = () => {
    setUploadProgressListDisplayed((currentDisplay) => !currentDisplay);
  };

  const progressButtonRef = useRef(null);

  const isAnyUploadsInProgressOrPending = useIsAnyUploadsInProgressOrPending(uploadIds);
  const hasAnyUploadFailed = useHasAnyUploadFailed(uploadIds);
  const { t } = useDocumentUploadTranslation();

  return (
    <Fragment>
      <IconButton
        ref={progressButtonRef}
        sx={{
          padding: 0,
        }}
        size="small"
        disableRipple
        onClick={toggleUploadProgressListDisplay}
      >
        {isAnyUploadsInProgressOrPending && <CircularProgress size={24} sx={{ color: TakeTurnsColors.darkBlue }} />}
        {!isAnyUploadsInProgressOrPending && hasAnyUploadFailed && (
          <IconXCircle size={24} color={TakeTurnsColors.error} />
        )}
        {!isAnyUploadsInProgressOrPending && !hasAnyUploadFailed && (
          <IconCheckCircle size={24} color={TakeTurnsColors.success} />
        )}
      </IconButton>

      <Popover
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        elevation={2}
        anchorEl={progressButtonRef.current}
        open={isUploadProgressListDisplayed}
        onClose={hideUploadProgressList}
        slotProps={{
          paper: {
            sx: {
              borderTopRightRadius: "10px",
              borderTopLeftRadius: "10px",
              paddingTop: 1,
              paddingLeft: 2,
              paddingRight: 0,
              paddingBottom: 1,
            },
          },
        }}
        keepMounted
      >
        <Typography variant="caption">{t("uploadProgressIndicator.title")}</Typography>
        <UploadsProgressList uploadIds={uploadIds} />
      </Popover>
    </Fragment>
  );
};
