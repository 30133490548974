/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Divider, Skeleton, Stack } from "@mui/material";
import { Fragment } from "react";
import { OrganizationInvitation } from "@taketurns/taketurns-graphql-repository";
import {
  MobileOrganizationInvitationRow,
} from "@taketurns-components/user/Organization/OrganizationMemberAndInvitationList/MobileOrganizationMemberAndInvitationList/MobileOrganizationInvitationRow";
import {
  MobileOrganizationMemberRow,
} from "@taketurns-components/user/Organization/OrganizationMemberAndInvitationList/MobileOrganizationMemberAndInvitationList/MobileOrganizationMemberRow";
import {
  isOrganizationMember,
  OrganizationMemberAndInvitationComponentProps,
} from "@taketurns-components/user/Organization/OrganizationMemberAndInvitationList/shared/SharedComponentTypes";

export const MobileOrganizationMemberAndInvitationList = ({
  membersAndInvitations,
  loading,
  connectedUserId,
}: OrganizationMemberAndInvitationComponentProps) => {
  if (loading) {
    return (
      <Stack spacing={2}>
        <Skeleton variant={"text"} />
        <Skeleton variant={"text"} />
        <Skeleton variant={"text"} />
      </Stack>
    );
  }

  return (
    <Stack spacing={2}>
      <Divider />
      {membersAndInvitations.map((memberOrInvitation) => (
        <Fragment key={memberOrInvitation.userId ?? (memberOrInvitation as OrganizationInvitation).email}>
          {isOrganizationMember(memberOrInvitation) ? (
            <MobileOrganizationMemberRow member={memberOrInvitation} connectedUserId={connectedUserId} />
          ) : (
            <MobileOrganizationInvitationRow invitation={memberOrInvitation} />
          )}
          <Divider />
        </Fragment>
      ))}
    </Stack>
  );
};
