/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useQuery } from "@apollo/client";

const COLLABORATION_EDITABLE_FIELDS = gql`
  query collaborationEditableFields($collaborationId: ID!) {
    getCollaboration(collaborationId: $collaborationId) {
      id
      deadline
      owningPartyName
      invitedPartyName
      description
      name
    }
  }
`;

export const useFetchCollaborationEditableFields = (collaborationId: string) => {
  const {
    data,
    loading: loadingCollaborationEditableFields,
    error: errorOnFetchingCollaborationEditableFields,
  } = useQuery(COLLABORATION_EDITABLE_FIELDS, { variables: { collaborationId } });
  return {
    collaborationWithEditableFields: data?.getCollaboration,
    loadingCollaborationEditableFields,
    errorOnFetchingCollaborationEditableFields,
  };
};
