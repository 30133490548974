/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Avatar, Box, styled } from "@mui/material";
import { PropsWithChildren } from "react";
import { EventOnCollaboration, Party } from "@taketurns/taketurns-graphql-repository";
import { DisplayedUser, EventNoteUserAndHeadline } from "@taketurns-components/collaboration/Shared/EventNote/EventNoteUserAndHeadline";
import { PlainTextEventNoteWithReadMoreButton } from "@taketurns-components/collaboration/Shared/EventNote/PlainTextEventNoteWithReadMoreButton";
import { getColorForUserParty } from "@taketurns-rules/collaboration/utils/color/TakeTurnsBlueColorsUtils";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { getUserPresentationRule } from "@taketurns-rules/user/getUserPresentationRule";

interface EventNoteProps {
  event: EventOnCollaboration;
  connectedUserParty: Party;
  collaborationId: string;
}

export const EventNote = (props: EventNoteProps) => {
  const isInvitedParty = props.event.party === Party.Invited;

  const color = getColorForUserParty(props.event.party, props.connectedUserParty).themeColor;

  const displayedUser: DisplayedUser = {
    avatar: props.event.user.avatar,
    presentation: getUserPresentationRule(props.event.user),
    jobTitle: props.event.user.jobTitle,
  };

  return (
    <EventNote__Container inverseRow={isInvitedParty}>
      <EventNoteSpeechBubble isInvitedParty={isInvitedParty}>
        <EventNoteUserAndHeadline user={displayedUser} color={color} />
        <PlainTextEventNoteWithReadMoreButton
          collaborationId={props.collaborationId}
          eventId={props.event.id}
          note={props.event.note}
          user={displayedUser}
          color={color}
        />
      </EventNoteSpeechBubble>
      <EventNote__Avatar src={props.event.user?.avatar} />
    </EventNote__Container>
  );
};

const EventNote__Container = styled(Box, { shouldForwardProp: (props) => props !== "inverseRow" })(
  ({ inverseRow }: { inverseRow?: boolean }) => ({
    display: "flex",
    alignItems: "flex-end",
    gap: "12px",
    flexDirection: inverseRow ? "row-reverse" : "row",
  }),
);

const EventNoteSpeechBubble = ({ isInvitedParty, children }: PropsWithChildren<{ isInvitedParty?: boolean }>) => {
  return (
    <EventNote__Bubble isInvitedParty={isInvitedParty}>
      <SpeechBubbleComma leftOrRight={isInvitedParty ? "left" : "right"} />
      {children}
    </EventNote__Bubble>
  );
};

const EventNote__Bubble = styled(Box, {
  shouldForwardProp: (props) => props !== "isInvitedParty",
})(({ isInvitedParty }: { isInvitedParty?: boolean }) => ({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  padding: "4px 12px",
  borderRadius: "10px",
  borderBottomLeftRadius: isInvitedParty ? 0 : "10px",
  borderBottomRightRadius: isInvitedParty ? "10px" : 0,
  border: `1px solid ${TakeTurnsColors.lightGray}`,
}));

const SpeechBubbleComma = ({ leftOrRight }: { leftOrRight: "right" | "left" }) => {
  const transform = leftOrRight === "left" ? { transform: "rotateY(180deg)" } : {};
  return (
    <Box
      sx={{
        position: "absolute",
        [leftOrRight]: "-8.5px",
        bottom: "-14.5px",
        ...transform,
      }}
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14">
        <path
          d="M 6.813 0.824 C 6.813 0.824 9.673 11.392 13.351 12.902 C 17.029 14.412 1.364 11.255 1.364 6.863"
          fill="#FFFFFF"
          strokeWidth="1.09"
          stroke="#ddd"
        ></path>
      </svg>
    </Box>
  );
};

const EventNote__Avatar = styled(Avatar)({
  width: 24,
  height: 24,
});
