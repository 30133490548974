/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Divider, Stack, Typography } from "@mui/material";
import { HelpIconWithTooltip } from "@taketurns-components/shared/HelpIconWithTooltip";
import { SomethingWentWrong } from "@taketurns-components/shared/SomethingWentWrong";
import { DesktopOrganizationDomainGrid } from "@taketurns-components/user/Organization/OrganizationAccessPolicies/DesktopOrganizationDomainGrid/DesktopOrganizationDomainGrid";
import { MobileOrganizationDomainList } from "@taketurns-components/user/Organization/OrganizationAccessPolicies/MobileOrganizationDomainList/MobileOrganizationDomainList";
import { OrganizationAddDomainForm } from "@taketurns-components/user/Organization/OrganizationAccessPolicies/OrganizationAddDomainForm";
import { useOrganizationTranslation } from "@taketurns-i18n/user/Organization/useOrganizationTranslation";
import { useIsOnMobileRule } from "@taketurns-rules/commons/theme/useIsOnMobileRule";
import { useFetchUserOrganizationDomainsRule } from "@taketurns-rules/user/queries/useFetchUserOrganizationDomainsRule";

export const OrganizationDomainsList = () => {
  const { domains, loadingUserOrganizationDomains, errorOnLoadingUserOrganizationDomains } =
    useFetchUserOrganizationDomainsRule();
  const { t } = useOrganizationTranslation();

  const isOnMobile = useIsOnMobileRule();

  if (errorOnLoadingUserOrganizationDomains) {
    console.error(errorOnLoadingUserOrganizationDomains);
    return <SomethingWentWrong />;
  }

  const ListComponent = isOnMobile ? MobileOrganizationDomainList : DesktopOrganizationDomainGrid;

  return (
    <Stack spacing={1}>
      <Stack direction="row" alignItems="center">
        <Typography variant="body2">{t("OrganizationDomainList.title")}</Typography>
        <HelpIconWithTooltip helpText={t("OrganizationAddDomainForm.description")} />
      </Stack>
      {isOnMobile && <Divider />}
      <Stack spacing={1}>
        <ListComponent loading={loadingUserOrganizationDomains} organizationDomains={domains} />
        <OrganizationAddDomainForm />
      </Stack>
    </Stack>
  );
};
