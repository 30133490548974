/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useCallback } from "react";
import { useMoveAttachmentIntoFolderRule } from "@taketurns-rules/collaboration/commands/collaborationView/attachment/useMoveAttachmentIntoFolderRule";
import { MoveDestinationDialogInCollaborationView } from "./MoveDestinationDialogInCollaborationView";

interface AttachmentMoveDestinationDialogInCollaborationViewProps {
  attachmentId: string;
  open: boolean;
  onClose: () => void;
}

export const AttachmentMoveDestinationDialogInCollaborationView = ({
  attachmentId,
  open,
  onClose,
}: AttachmentMoveDestinationDialogInCollaborationViewProps) => {
  const moveAttachmentToFolder = useMoveAttachmentIntoFolderRule(attachmentId);
  const moveAttachmentToFolderAndClose = useCallback(
    async (folderPath: string) => {
      await moveAttachmentToFolder(folderPath);
      onClose();
    },
    [moveAttachmentToFolder, onClose],
  );
  return (
    <MoveDestinationDialogInCollaborationView
      onSubmitDestination={moveAttachmentToFolderAndClose}
      onClose={onClose}
      open={open}
    />
  );
};
