/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useEffect } from "react";
import { useWebAppTranslations } from "@taketurns-i18n/webapp/useWebAppTranslations";

export enum PageTitle {
  "SIGNIN" = "signin",
  "SIGNUP_INVITATION" = "signup_invitation",
  "VERIFY" = "verify",
  "PLANS" = "plans",
  "SIGNUP" = "signup",
  "DEFAULT" = "default",
}

export const useSetDocumentTitleRule = (pageTitle: PageTitle) => {
  const { t } = useWebAppTranslations("title-meta");
  useEffect(() => {
    document.title = t(`title.${pageTitle}`);
    return () => {
      document.title = t(`title.${PageTitle.DEFAULT}`);
    };
  }, [pageTitle, t]);
};
