/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useState } from "react";
import { useCloseAttachmentDetailsRule } from "@taketurns-rules/collaboration/commands/collaborationView/useCloseAttachmentDetailsRule";

export const useConfirmCloseDetailsOnAnnotationUnsaved = () => {
  const closeAttachmentDetails = useCloseAttachmentDetailsRule();

  const [isPdfUpdated, setIsPdfUpdated] = useState(false);

  const [isConfirmBeforeClosingDialogOpen, setIsConfirmBeforeClosingDialogOpen] = useState(false);

  const confirmBeforeClose = () => {
    if (isPdfUpdated) {
      setIsConfirmBeforeClosingDialogOpen(true);
    } else {
      closeAttachmentDetails();
    }
  };

  const closeConfirmBeforeClosingDialog = () => {
    setIsConfirmBeforeClosingDialogOpen(false);
  };

  const confirmClosingDialogs = () => {
    setIsConfirmBeforeClosingDialogOpen(false);
    setIsPdfUpdated(false);
    closeAttachmentDetails();
  };
  return {
    isPdfUpdated,
    setIsPdfUpdated,
    isConfirmBeforeClosingDialogOpen,
    confirmBeforeClose,
    closeConfirmBeforeClosingDialog,
    confirmClosingDialogs,
  };
};
