/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { InputBase, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { IconChevronDown } from "@taketurns-components/commons/icons";
import i18n from "@taketurns-i18n/i18n";
import { useWebAppTranslations } from "@taketurns-i18n/webapp/useWebAppTranslations";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";

const AVAILABLE_LOCALES = ["en", "fr"];

interface LanguageSelectorProps {
  shortLabels?: boolean;
}

export const LanguageSelector = ({ shortLabels }: LanguageSelectorProps) => {
  const { t } = useWebAppTranslations("language");

  const changeLanguage = async (event: SelectChangeEvent) => {
    await i18n.changeLanguage(event.target.value as string);
  };

  return (
    <Select
      autoWidth
      onChange={changeLanguage}
      value={i18n.language}
      IconComponent={(iconProps) => <IconChevronDown color={TakeTurnsColors.darkGray} size={14} {...iconProps} />}
      input={
        <InputBase
          sx={{
            fontSize: 14,
            color: TakeTurnsColors.darkGray,
            textTransform: "capitalize",
          }}
        />
      }
    >
      {AVAILABLE_LOCALES.map((locale: string) => (
        <MenuItem key={locale} value={locale}>
          {shortLabels ? t(locale + "-short") : t(locale)}
        </MenuItem>
      ))}
    </Select>
  );
};
