/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Box, styled } from "@mui/material";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";

export const TakeTurnsCardHeader = styled(Box, {
  shouldForwardProp: (prop) => prop !== "backgroundColor",
})((props: { backgroundColor?: string }) => ({
  display: "flex",
  backgroundColor: props.backgroundColor ? props.backgroundColor : TakeTurnsColors.background,
  borderBottom: `1px solid ${TakeTurnsColors.lightGray}`,
  borderRadius: "9px 9px 0 0",
  paddingTop: "1px",
  height: 24,
  minHeight: 24,
  width: "100%",
  fontSize: 12,
}));
