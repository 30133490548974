/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useLocation } from "react-router-dom";
import { setLoggedInReturnPath } from "@taketurns-repositories/webapp/sessionStorage/loggedInReturnPath";

export const useSaveLoggedInReturnPath = () => {
  const { pathname, search } = useLocation();
  return () => {
    setLoggedInReturnPath(pathname + search);
  };
};
