/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql } from "@apollo/client";
import { useRefreshableSubscription } from "@taketurns-components/webapp/QueriesAndSubscriptionsRefreshTrigger";

const ON_MODIFY_ORGANIZATION_MEMBER_ROLE = gql`
  subscription onModifyOrganizationMemberRole($userId: ID!) {
    onModifyOrganizationMemberRole(userId: $userId) {
      userId
      role
    }
  }
`;

export const useSubscribeToModifyOrganizationMemberRole = (userId: string) => {
  return useRefreshableSubscription(ON_MODIFY_ORGANIZATION_MEMBER_ROLE, {
    variables: { userId },
  });
};
