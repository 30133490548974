/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { DialogContent, DialogTitle, Typography } from "@mui/material";
import { Fragment } from "react";
import { Role } from "@taketurns/taketurns-graphql-repository";
import { FetchLoadingManager } from "@taketurns-app/utils/FetchLoadingManager";
import { SomethingWentWrong } from "@taketurns-components/shared/SomethingWentWrong";
import { useCollaborationTranslation } from "@taketurns-i18n/collaboration/useCollaborationTranslation";
import { useFetchConnectedUserId } from "@taketurns-repositories/user/graphql/queries/useFetchConnectedUserId";
import { useLeaveCollaborationRule } from "@taketurns-rules/collaboration/commands/useLeaveCollaborationRule";
import { useFetchParticipantsAndInvitationsRule } from "@taketurns-rules/collaboration/queries/collaborationView/useFetchParticipantsAndInvitationsRule";
import { TakeTurnsDefaultButton } from "../../../../commons/button";
import { DialogFooter } from "../../../../commons/dialog/DialogFooter";
import { SmallDialog } from "../../../../commons/dialog/SmallDialog";

interface LeaveCollaborationDialogProps {
  collaborationId: string;
  open: boolean;
  onClose: () => void;
}

/**
 * Leave collaboration dialog
 * @param {LeaveCollaborationDialogProps} props
 * @returns {ReactNode} close collaboration dialog
 */
export const LeaveCollaborationDialog = (props: LeaveCollaborationDialogProps) => {
  return (
    <SmallDialog open={props.open}>
      <LeaveCollaborationInnerDialog collaborationId={props.collaborationId} onClose={props.onClose} />
    </SmallDialog>
  );
};

const LeaveCollaborationInnerDialog = ({
  collaborationId,
  onClose,
}: {
  collaborationId: string;
  onClose: () => void;
}) => {
  const { leaveCollaborationCommand, result } = useLeaveCollaborationRule(collaborationId);
  const { t } = useCollaborationTranslation("collaborationEdit");

  const { participants, userId, error, loading } = useFetchLeaveCollaborationDialogData(collaborationId);

  const connectedUserParticipant = participants.find((p) => p.userId === userId);

  const canLeaveCollaboration =
    connectedUserParticipant &&
    participants.some(
      (p) => p.userId !== userId && p.role === Role.Owner && p.party === connectedUserParticipant.party,
    );

  const handleLeaveCollaboration = () => {
    leaveCollaborationCommand();
  };

  if (error) {
    return <SomethingWentWrong />;
  }

  const message = canLeaveCollaboration ? t("leave.description") : t("leave.lastLeader");

  return (
    <Fragment>
      <DialogTitle>{t("leave.title")}</DialogTitle>
      <DialogContent>
        <Typography>{message}</Typography>
      </DialogContent>
      <DialogFooter onClose={onClose} hasCancelAction>
        {canLeaveCollaboration && (
          <TakeTurnsDefaultButton disabled={result.loading || loading} onClick={handleLeaveCollaboration}>
            {t("button.confirm")}
          </TakeTurnsDefaultButton>
        )}
      </DialogFooter>
    </Fragment>
  );
};

const useFetchLeaveCollaborationDialogData = (collaborationId: string) => {
  const { participants, loadingParticipantsAndInvitations, errorOnParticipantsAndInvitations } =
    useFetchParticipantsAndInvitationsRule(collaborationId);
  const { userId, loadingConnectedUserId, errorOnConnectedUserId } = useFetchConnectedUserId();

  const fetchLoadingManager = new FetchLoadingManager(
    [loadingParticipantsAndInvitations, loadingConnectedUserId],
    [errorOnParticipantsAndInvitations, errorOnConnectedUserId],
  );

  return {
    participants,
    userId,
    loading: fetchLoadingManager.hasFetchLoading(),
    error: fetchLoadingManager.hasFetchInError(),
  };
};
