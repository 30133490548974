/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { MenuItem, Select, SelectChangeEvent, Stack } from "@mui/material";
import { useEffect } from "react";
import { SubscriptionPlanCurrency, SubscriptionPlanRegion } from "@taketurns/taketurns-graphql-repository";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { useSelectSubscriptionPlanCurrencyRule } from "@taketurns-rules/user/commands/plan/selectSubscriptionPlanCurrencyRule";
import { useGetSubscriptionPlanCurrencyRule } from "@taketurns-rules/user/queries/plan/getSubscriptionPlanCurrencyRule";
import { useGetSubscriptionPlanRegionRule } from "@taketurns-rules/user/queries/plan/getSubscriptionPlanRegionRule";
import { IconChevronDown } from "../../../../commons/icons";

export const PaymentCurrencySelector = () => {
  const region = useGetSubscriptionPlanRegionRule();
  const currency = useGetSubscriptionPlanCurrencyRule();
  const setPaymentCurrency = useSelectSubscriptionPlanCurrencyRule();

  useEffect(() => {
    setPaymentCurrency(getCurrencyFromRegion(region)[0]);
  }, [region]);

  const handleChangeCurrency = (event: SelectChangeEvent) => {
    setPaymentCurrency(event.target.value as SubscriptionPlanCurrency);
  };

  if (getCurrencyFromRegion(region).length === 1) {
    return null;
  }

  return (
    <Stack spacing={2} direction="row" alignItems={"center"}>
      <Select
        value={currency}
        onChange={handleChangeCurrency}
        sx={{ backgroundColor: "white" }}
        renderValue={(value) => value.toUpperCase()}
        IconComponent={(iconProps) => <IconChevronDown size={16} color={TakeTurnsColors.darkGray} {...iconProps} />}
      >
        {getCurrencyFromRegion(region).map((currency) => (
          <MenuItem key={currency} value={currency} sx={{ fontSize: 14, textTransform: "uppercase" }}>
            {currency}
          </MenuItem>
        ))}
      </Select>
    </Stack>
  );
};

const getCurrencyFromRegion = (region: SubscriptionPlanRegion) => {
  if (region === SubscriptionPlanRegion.Row) {
    return [SubscriptionPlanCurrency.Eur, SubscriptionPlanCurrency.Usd];
  }
  return [SubscriptionPlanCurrency.Usd];
};
