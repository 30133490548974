/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useQuery } from "@apollo/client";
import { Organization } from "@taketurns/taketurns-graphql-repository";

const USER_ORGANIZATION_NAME = gql`
  query userOrganization {
    userOrganization {
      id
      name
    }
  }
`;

export const useFetchUserOrganizationName = () => {
  const {
    data,
    error: errorOnFetchingUserOrganizationName,
    loading: loadingUserOrganizationName,
  } = useQuery<{
    userOrganization: Organization;
  }>(USER_ORGANIZATION_NAME);
  return {
    userOrganizationName: data?.userOrganization?.name,
    errorOnFetchingUserOrganizationName,
    loadingUserOrganizationName,
  };
};
