/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useMediaQuery, useTheme } from "@mui/material";

export const useIsOnMobileRule = () => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down("md"));
};
