/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { InputBase, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { i18nService } from "@taketurns-i18n/commons/i18nService";
import i18n from "@taketurns-i18n/i18n";
import { useUserSharedTranslation } from "@taketurns-i18n/user/useUserSharedTranslation";
import { availableLocales } from "@taketurns-repositories/user/local/availableLocales";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { useChangePreferredLanguageRule } from "@taketurns-rules/user/commands/useChangePreferredLanguageRule";
import { IconChevronDown } from "../../commons/icons";

export const LanguageSelector = () => {
  const { t } = useUserSharedTranslation();

  const { changePreferredLanguage } = useChangePreferredLanguageRule();

  const selectLanguage = (event: SelectChangeEvent) => {
    i18nService.changeLanguage(event.target.value as string);
    changePreferredLanguage(event.target.value as string);
  };

  return (
    <Select
      onChange={selectLanguage}
      value={i18n.language}
      autoWidth
      IconComponent={(iconProps) => <IconChevronDown color={TakeTurnsColors.darkGray} size={14} {...iconProps} />}
      input={
        <InputBase
          sx={{
            fontSize: 14,
            color: TakeTurnsColors.darkGray,
            textTransform: "capitalize",
          }}
        />
      }
    >
      {availableLocales.map((locale) => (
        <MenuItem key={locale} value={locale}>
          {t(`LanguageSelector.${locale}`)}
        </MenuItem>
      ))}
    </Select>
  );
};
