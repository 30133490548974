/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Stack, Typography } from "@mui/material";
import { Folder } from "@taketurns/taketurns-graphql-repository";
import { AttachmentsCounter } from "@taketurns-components/collaboration/Shared/content/AttachmentsCounter";
import { RequestsCounter } from "@taketurns-components/collaboration/Shared/content/RequestsCounter";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { useIsOnMobileRule } from "@taketurns-rules/commons/theme/useIsOnMobileRule";

interface FolderStatsProps {
  folder: Folder;
}

export const FolderStats = ({ folder }: FolderStatsProps) => {
  const isOnMobile = useIsOnMobileRule();
  const shouldDisplayRequestCount = folder.requestCount > 0;
  return (
    <Stack direction={{ xs: "row", md: "column" }}>
      <AttachmentsCounter attachmentsCount={folder.attachmentCount} />
      {isOnMobile && shouldDisplayRequestCount && <CommaSeparator />}
      {shouldDisplayRequestCount && <RequestsCounter requestsCount={folder.requestCount} />}
    </Stack>
  );
};

const CommaSeparator = () => (
  <Typography color={TakeTurnsColors.mediumGray} fontSize={10} fontWeight={700} whiteSpace="pre">
    {", "}
  </Typography>
);
