/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useResetCollaborationForm } from "@taketurns-repositories/collaboration/state/write/collaborationCreation/form/useResetCollaborationForm";
import { useResetCollaborationFormErrors } from "@taketurns-repositories/collaboration/state/write/collaborationCreation/form/useResetCollaborationFormErrors";

export const useResetCollaborationFormAndSetDefaultCurrentTurnRule = () => {
  const resetCollaborationForm = useResetCollaborationForm();
  const resetCollaborationFormErrors = useResetCollaborationFormErrors();
  return () => {
    resetCollaborationForm();
    resetCollaborationFormErrors();
  };
};
