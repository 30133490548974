/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { selector } from "recoil";
import { collaborationFormErrorsState } from "@taketurns-repositories/collaboration/state/recoil/collaborationCreation/form/errors/collaborationFormErrorsState";

export const selectCollaborationFormNameFieldError = selector<boolean>({
  key: "collaborationFormNameFieldError",
  get: ({ get }) => {
    return get(collaborationFormErrorsState).name;
  },
  set: ({ set }, isNameInError) => {
    set(collaborationFormErrorsState, (previousState) => ({
      ...previousState,
      name: isNameInError as boolean,
    }));
  },
});
