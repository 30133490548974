/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { selectorFamily } from "recoil";
import { CreateInviteeInput, Party } from "@taketurns/taketurns-graphql-repository";
import { collaborationFormState } from "./collaborationFormState";

export const selectCollaborationFormInvitationsByParty = selectorFamily<CreateInviteeInput[], Party>({
  key: "collaborationFormInvitations",
  get:
    (party) =>
    ({ get }) => {
      return get(collaborationFormState)?.invitations?.filter((invitation) => invitation.party === party);
    },
});
