/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { ApolloError, gql, useQuery } from "@apollo/client";
import { Invitee, Participant, ParticipantsAndInvitations } from "@taketurns/taketurns-graphql-repository";

const ParticipantsAndInvitationsCommonFields = `
          userId
          party
          role
          user {
              id
              firstName
              lastName
              avatar
              jobTitle
              email
          }
`;

export const GET_PARTICIPANTS_AND_INVITATIONS = gql`
  query participantsAndInvitations($collaborationId: ID!) {
    getParticipantsAndInvitations(collaborationId: $collaborationId) {
      id
      participants {
        ${ParticipantsAndInvitationsCommonFields}
      }
      invitations {
        email
        ${ParticipantsAndInvitationsCommonFields}
      }
    }
  }
`;

export const useFetchParticipantsAndInvitations: (
  collaborationId: string,
  cacheOnly?: boolean,
) => {
  participants: Participant[];
  invitations: Invitee[];
  loadingParticipantsAndInvitations: boolean;
  errorOnParticipantsAndInvitations?: ApolloError;
} = (collaborationId: string, cacheOnly?: boolean) => {
  const {
    data,
    loading: loadingCollaborationParticipantsAndInvitations,
    error: errorOnParticipantsAndInvitations,
  } = useQuery<{
    getParticipantsAndInvitations: ParticipantsAndInvitations;
  }>(GET_PARTICIPANTS_AND_INVITATIONS, {
    variables: { collaborationId: collaborationId },
    fetchPolicy: cacheOnly ? "cache-only" : "cache-and-network",
  });
  const participants = data?.getParticipantsAndInvitations?.participants ?? [];
  const invitations = data?.getParticipantsAndInvitations?.invitations ?? [];
  return {
    participants,
    invitations,
    loadingParticipantsAndInvitations: loadingCollaborationParticipantsAndInvitations,
    errorOnParticipantsAndInvitations,
  };
};
