/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Button } from "@mui/material";
import * as React from "react";
import { useSharedCollaborationTranslation } from "@taketurns-i18n/collaboration/shared/useSharedCollaborationTranslation";

interface CancelAnnotationsProps {
  onClick: () => void;
}

export const CancelAnnotationsButton = ({ onClick }: CancelAnnotationsProps) => {
  const { t } = useSharedCollaborationTranslation();

  return (
    <Button variant={"text"} onClick={onClick} sx={{ fontSize: { xs: 14, sm: 16 } }}>
      {t("PdfViewer.annotationsButtons.cancel")}
    </Button>
  );
};
