/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Typography } from "@mui/material";
import { useSharedCollaborationTranslation } from "@taketurns-i18n/collaboration/shared/useSharedCollaborationTranslation";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";

export const AttachmentsCounter = (props: { attachmentsCount: number }) => {
  const { t } = useSharedCollaborationTranslation();

  return (
    <Typography color={TakeTurnsColors.grayButton} fontSize={{ xs: 10, md: 9 }} fontWeight={700}>
      {t("AttachmentsCounter.file", { count: props.attachmentsCount })}
    </Typography>
  );
};
