/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useState } from "react";

export function useEditDialogRule() {
  const [isEditDialogOpened, setIsEditDialogOpened] = useState(false);
  const openEditDialog = () => {
    setIsEditDialogOpened(true);
  };
  const closeEditDialog = () => {
    setIsEditDialogOpened(false);
  };
  return { isEditDialogOpened, openEditDialog, closeEditDialog: closeEditDialog };
}
