/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Box } from "@mui/material";
import { Fragment } from "react";
import { ContentStatus, Folder } from "@taketurns/taketurns-graphql-repository";
import { SelectionWrapperForListItem } from "@taketurns-components/collaboration/CollaborationView/CollaborationContent/shared/components/SelectionComponentWrapper";
import { FolderMoveDestinationDialogInCollaborationView } from "@taketurns-components/collaboration/CollaborationView/MoveDestinationDialog/FolderMoveDestinationDialogInCollaborationView";
import { EditFolderNameDialog } from "@taketurns-components/collaboration/Shared/content/EditFolderDialog";
import {
  FilesDragAndDropWithOverlay,
  FilesDragAndDropWithOverlayConfig,
} from "@taketurns-components/collaboration/Shared/FilesDragAndDropWithOverlay";
import { useRenameFolderRule } from "@taketurns-rules/collaboration/commands/collaborationView/folder/useRenameFolderRule";
import { useBrowseContentRule } from "@taketurns-rules/collaboration/commands/collaborationView/useBrowseContentRule";
import { useMoveDestinationDialogRule } from "@taketurns-rules/collaboration/commands/useMoveDestinationDialogRule";
import { useGetFolderSelectionStateInCurrentFolderByIdRule } from "@taketurns-rules/collaboration/queries/collaborationView/contentSelection/useGetFolderSelectionStateInCurrentFolderByIdRule";
import { useIsContentSelectionStateActiveRule } from "@taketurns-rules/collaboration/queries/collaborationView/contentSelection/useIsContentSelectionStateActiveRule";
import { useGetSubFolderRule } from "@taketurns-rules/collaboration/queries/collaborationView/useGetSubFolderRule";
import { useIsCollaborationClosedRule } from "@taketurns-rules/collaboration/queries/collaborationView/useIsCollaborationClosedRule";
import { useGetFolderContextMenuConfigurationRule } from "@taketurns-rules/collaboration/queries/folder/useGetFolderContextMenuConfigurationRule";
import { useEditDialogRule } from "@taketurns-rules/collaboration/queries/useEditDialogRule";
import { useSubscribeToFolderChangesRule } from "@taketurns-rules/collaboration/subscriptions/useSubscribeToFolderChangesRule";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { useIsOnMobileRule } from "@taketurns-rules/commons/theme/useIsOnMobileRule";
import { DesktopFolderListItem } from "./DesktopFolderListItem";
import { MobileFolderListItem } from "./MobileFolderListItem";

interface FolderListItemProps {
  folderId: string;
  editable: boolean;
  dropZoneConfig: FilesDragAndDropWithOverlayConfig;
}

export const FolderListItem = ({ folderId, editable, dropZoneConfig }: FolderListItemProps) => {
  const folder = useGetSubFolderRule(folderId);
  useSubscribeToFolderChangesRule(folderId);

  const { isContentSelectionActive, isFolderSelected, toggleSelection } =
    useGetFolderSelectionStateInCurrentFolderByIdRule(folderId);

  const isFolderRemoved = hasFolderBeenRemoved(folder);

  if (!folder) {
    return null;
  }

  if (isContentSelectionActive) {
    return (
      <SelectionWrapperForListItem checked={isFolderSelected} onClick={toggleSelection}>
        <InnerComponent folder={folder} editable={editable} />
      </SelectionWrapperForListItem>
    );
  }
  if (!editable || isFolderRemoved) {
    return <InnerComponent folder={folder} editable={editable} />;
  }
  return (
    <FilesDragAndDropWithOverlay ContainerComponent={Box} dropZoneConfig={dropZoneConfig}>
      <InnerComponent folder={folder} editable={editable} />
    </FilesDragAndDropWithOverlay>
  );
};

const hasFolderBeenRemoved = (folder: Folder) =>
  folder.status === ContentStatus.Removed || folder.prevStatus === ContentStatus.Removed;
const hasFolderBeenRemovedInPreviousTurn = (folder: Folder) => folder.prevStatus === ContentStatus.Removed;

interface InnerComponentProps {
  folder: Folder;
  editable: boolean;
}
const InnerComponent = (props: InnerComponentProps) => {
  const isOnMobile = useIsOnMobileRule();
  const ItemComponent = isOnMobile ? MobileFolderListItem : DesktopFolderListItem;
  const isContentSelectionActive = useIsContentSelectionStateActiveRule();

  const { moveIntoFolder } = useBrowseContentRule();
  const moveIntoListItemFolder = () => {
    moveIntoFolder(props.folder.id);
  };
  const { isCollaborationClosed } = useIsCollaborationClosedRule();
  const shouldDisplayPendingUpdatesMarker =
    !isCollaborationClosed && (props.folder.status !== ContentStatus.Unchanged || !!props.folder.updateCount);
  const isFolderRemoved = hasFolderBeenRemoved(props.folder);
  const shouldDisplayLastUpdatesMarker =
    !isCollaborationClosed &&
    ((props.folder.prevStatus && props.folder.prevStatus !== ContentStatus.Unchanged) ||
      !!props.folder.prevUpdateCount);
  const lastUpdateStatus = !!props.folder.prevUpdateCount ? ContentStatus.Updated : props.folder.prevStatus;

  const { renameFolder } = useRenameFolderRule(props.folder.id);

  const { isEditDialogOpened, openEditDialog, closeEditDialog } = useEditDialogRule();
  const { closeMoveDestinationDialog, isMoveDestinationDialogOpen, openMoveDestinationDialog } =
    useMoveDestinationDialogRule();
  const folderContextMenuConfiguration = useGetFolderContextMenuConfigurationRule(
    props.folder,
    openEditDialog,
    openMoveDestinationDialog,
  );

  return (
    <Fragment>
      <ItemComponent
        backgroundColor={shouldDisplayPendingUpdatesMarker ? TakeTurnsColors.updatedElementBackground : undefined}
        folder={props.folder}
        isFolderRemoved={isFolderRemoved}
        shouldDisplayLastUpdate={shouldDisplayLastUpdatesMarker}
        lastUpdateStatus={lastUpdateStatus}
        shouldDisplayUpdatesMarker={shouldDisplayPendingUpdatesMarker}
        shouldDisplayReviewChip={props.folder.attachmentToReviewCount > 0}
        hasFolderBeenRemovedInPreviousTurn={hasFolderBeenRemovedInPreviousTurn(props.folder)}
        moveIntoListItemFolder={isContentSelectionActive ? undefined : moveIntoListItemFolder}
        contextMenuConfiguration={props.editable && !isContentSelectionActive ? folderContextMenuConfiguration : null}
      />
      {!isContentSelectionActive && props.editable && (
        <Fragment>
          <EditFolderNameDialog
            open={isEditDialogOpened}
            onClose={closeEditDialog}
            folderName={props.folder.name}
            renameFolder={renameFolder}
          />
          <FolderMoveDestinationDialogInCollaborationView
            folder={props.folder}
            open={isMoveDestinationDialogOpen}
            onClose={closeMoveDestinationDialog}
          />
        </Fragment>
      )}
    </Fragment>
  );
};
