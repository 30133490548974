/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Box, styled, Typography } from "@mui/material";
import { DataGrid, GridRow } from "@mui/x-data-grid";
import { OrganizationDomainsComponentProps } from "@taketurns-components/user/Organization/OrganizationDomainsComponentProps";
import { useMUIDataGridTranslations } from "@taketurns-i18n/MUIDataGrid/useMUIDataGridTranslations";
import { useOrganizationTranslation } from "@taketurns-i18n/user/Organization/useOrganizationTranslation";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { OrganizationDomainDataGridColumns, organizationDomainsDataGridMaxWidth } from "./OrganizationDomainDataGridColumns";

export const DesktopOrganizationDomainGrid = ({ organizationDomains, loading }: OrganizationDomainsComponentProps) => {
  const dataGridTranslation = useMUIDataGridTranslations();
  return (
    <Box width={1} maxWidth={organizationDomainsDataGridMaxWidth} minHeight={"100px"}>
      <CustomStyledDataGrid
        disableRowSelectionOnClick
        hideFooterPagination
        hideFooterSelectedRowCount
        hideFooter
        disableColumnSelector
        localeText={dataGridTranslation}
        getRowId={(organizationDomain) => organizationDomain.name}
        loading={loading}
        isCellEditable={() => false}
        columns={OrganizationDomainDataGridColumns()}
        rowHeight={50}
        rows={organizationDomains}
        slots={{
          noRowsOverlay: NoDomainLabel,
          row: DomainGridRow,
        }}
      />
    </Box>
  );
};

const CustomStyledDataGrid = styled(DataGrid)({
  border: 0,
  minHeight: 14,
  "& .MuiDataGrid-iconSeparator": {
    display: "none",
  },
  "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
    fontSize: 12,
    color: TakeTurnsColors.darkGray,
    ":focus": {
      outline: "none !important",
    },
    ":focus-within": {
      outline: "none",
    },
  },
  "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
    borderBottom: "1px solid #f0f0f0",
    fontSize: 14,
  },
  "& .MuiDataGrid-cell": {
    color: TakeTurnsColors.darkGray,
    ":hover": {
      cursor: "default",
    },
  },
  "& .MuiDataGrid-row:hover": {
    cursor: "default",
    backgroundColor: "transparent",
  },
  "& .textBlackColor": {
    color: "black",
  },
}) as typeof DataGrid;

const DomainGridRow = (props) => {
  return <GridRow {...props} data-cy={`DomainRow.${props.rowId}`} />;
};

const VerticallyAndHorizontallyCenteredItemsContainer = styled("div")({
  display: "flex",
  position: "relative",
  zIndex: 5,
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
});
export const NoDomainLabel = () => {
  const { t } = useOrganizationTranslation();

  return (
    <VerticallyAndHorizontallyCenteredItemsContainer>
      <Typography fontSize={14}>{t("OrganizationDomainList.noDomainRowLabel")}</Typography>
    </VerticallyAndHorizontallyCenteredItemsContainer>
  );
};
