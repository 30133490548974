/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useMutation } from "@apollo/client";
import { CollaborationUserContext, MutationUnhideCollaborationArgs } from "@taketurns/taketurns-graphql-repository";

export const useUnhideCollaboration = () => {
  const mutation = gql`
    mutation unhideCollaboration($collaborationId: ID!) {
      unhideCollaboration(collaborationId: $collaborationId) {
        id
        isHidden
      }
    }
  `;
  return useMutation<{ unhideCollaboration: CollaborationUserContext }, MutationUnhideCollaborationArgs>(mutation);
};
