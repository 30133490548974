/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useSetRecoilState } from "recoil";
import {
  AttachmentBulk,
  attachmentBulksState,
} from "@taketurns-repositories/collaboration/state/recoil/attachmentBulks";

export const useAddAttachmentBulk = () => {
  const setAttachmentBulk = useSetRecoilState(attachmentBulksState);
  return (attachmentBulk: AttachmentBulk) => {
    setAttachmentBulk((currVal) => [...currVal, attachmentBulk]);
  };
};
