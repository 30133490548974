/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useQuery } from "@apollo/client";
import { ConnectedUser } from "@taketurns/taketurns-graphql-repository";

const AVAILABLE_FEATURES = gql`
  query availableFeatures {
    connectedUser {
      id
      availableFeatures {
        ghost
        turnRecall
        createCollaboration
        totalSpaceInMB
        maxFileSizeInMB
      }
    }
  }
`;

export const useFetchAvailableFeatures = () => {
  const {
    data,
    error: errorOnFetchingAvailableFeatures,
    loading: loadingAvailableFeatures,
  } = useQuery<{
    connectedUser: ConnectedUser;
  }>(AVAILABLE_FEATURES, { fetchPolicy: "cache-and-network" });
  return {
    availableFeatures: data?.connectedUser?.availableFeatures,
    errorOnFetchingAvailableFeatures,
    loadingAvailableFeatures,
  };
};
