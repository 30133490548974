/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql } from "@apollo/client";
import { useRefreshableSubscription } from "@taketurns-components/webapp/QueriesAndSubscriptionsRefreshTrigger";

const ON_MODIFY_ORGANIZATION_INVITATION_ROLE = gql`
  subscription onModifyOrganizationInvitationRole($email: String, $userId: String) {
    onModifyOrganizationInvitationRole(email: $email, userId: $userId) {
      email
      userId
      role
    }
  }
`;

export const useSubscribeToInvitationToOrganizationRoleModified = (email?: string | null, userId?: string | null) => {
  return useRefreshableSubscription(ON_MODIFY_ORGANIZATION_INVITATION_ROLE, {
    variables: { email, userId },
  });
};
