/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useSetRecoilState } from "recoil";
import { contentSelectionState } from "@taketurns-repositories/collaboration/state/recoil/contentSelectionState";

export const useEmptyContentSelectionState = () => {
  const setState = useSetRecoilState(contentSelectionState);
  return () => {
    setState((prevState) => {
      return {
        ...prevState,
        attachmentIds: [],
        folderIds: [],
      };
    });
  };
};
