/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { SubscribeToPlanWithTrialInput } from "@taketurns/taketurns-graphql-repository";
import { SUBSCRIPTION_PLAN_WITH_TRIAL_SESSION_STORAGE_KEY } from "../entities/selectedSubscriptionPlanWithTrial";

export const setSelectedSubscriptionPlanWithTrialInSessionStorage = (
  selectedPlanWithTrial: SubscribeToPlanWithTrialInput,
) => {
  sessionStorage.setItem(SUBSCRIPTION_PLAN_WITH_TRIAL_SESSION_STORAGE_KEY, JSON.stringify(selectedPlanWithTrial));
};
