/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { useCollaborationArchiveDownloadTranslation } from "@taketurns-i18n/collaboration/CollaborationArchiveDownload/useCollaborationArchiveDownloadTranslation";
import { ArchiveDownloadOption } from "./ArchiveDownloadOption";

export enum TurnContentOptions {
  ALL_VERSIONS = "allVersions",
  ONLY_LAST_VERSION = "onlyLastVersion",
}

interface TurnContentOptionProps {
  defaultSelectedOption: TurnContentOptions;
  onSelectTurnContentOption: (value: TurnContentOptions) => void;
}

export const TurnContentOption = (props: TurnContentOptionProps) => {
  const { defaultSelectedOption, onSelectTurnContentOption } = props;

  const { t } = useCollaborationArchiveDownloadTranslation();

  const TurnContentRadioButtonOptions = (
    <RadioGroup
      row
      value={defaultSelectedOption}
      onChange={(event) => onSelectTurnContentOption(event.target.value as TurnContentOptions)}
    >
      <FormControlLabel
        sx={{ color: "black" }}
        value={TurnContentOptions.ONLY_LAST_VERSION}
        control={<Radio size="small" />}
        label={t("options.onlyLastVersion")}
      />
      <FormControlLabel
        sx={{ color: "black" }}
        value={TurnContentOptions.ALL_VERSIONS}
        control={<Radio size="small" />}
        label={t("options.allVersions")}
      />
    </RadioGroup>
  );

  return <ArchiveDownloadOption optionLabel={t("options.content")} option={TurnContentRadioButtonOptions} />;
};
