/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useQuery } from "@apollo/client";
import { ConnectedUser } from "@taketurns/taketurns-graphql-repository";

export const useFetchConnectedUser = () => {
  const GET_CONNECTED_USER = gql`
    query connectedUser {
      connectedUser {
        id
        firstName
        lastName
        email
        avatar
        function
        jobTitle
        availableFeatures {
          createCollaboration
          collaborationTemplate
          ghost
          maxFileSizeInMB
          totalSpaceInMB
          multiUserOrganization
          organizationAdmin
          turnRecall
        }
        preferences {
          id
          language
          tipsHidden
          hiddenTips
        }
        integrations {
          id
          chatGptApiKey
        }
        isSupervisor
      }
    }
  `;
  const { data, loading, error } = useQuery<{ connectedUser: ConnectedUser }>(GET_CONNECTED_USER);
  return {
    connectedUser: data?.connectedUser,
    loadingConnectedUser: loading,
    errorOnFetchingConnectedUser: error,
  };
};
