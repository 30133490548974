/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useSnackbar } from "notistack";
import { ChangeMemberSupervisorInput } from "@taketurns/taketurns-graphql-repository";
import { useChangeMemberSupervisor } from "@taketurns-repositories/user/graphql/mutations/useChangeMemberSupervisor";

export const useChangeMemberSupervisorRule = () => {
  const {
    errorWhenChangingMemberSupervisor,
    changeMemberSupervisor: changeMemberSupervisorCommand,
    loadingWhenChangingMemberSupervisor,
  } = useChangeMemberSupervisor();

  const { enqueueSnackbar } = useSnackbar();

  const changeMemberSupervisor = async ({ subordinateUserId, newSupervisorUserId }: ChangeMemberSupervisorInput) => {
    try {
      await changeMemberSupervisorCommand({ subordinateUserId, newSupervisorUserId });
    } catch (error) {
      console.error(error);
      enqueueSnackbar("An error occurred while changing supervisor", { variant: "error" });
    }
  };

  return {
    changeMemberSupervisor,
    errorWhenChangingMemberSupervisor,
    loadingWhenChangingMemberSupervisor,
  };
};
