/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useMutation } from "@apollo/client";
import { Folder, MutationRemoveFolderArgs, RemoveFolderInput } from "@taketurns/taketurns-graphql-repository";
import { requestFragment } from "../../../fragments/requestFragment";
import { subFolderFragment } from "../../../fragments/subFolderFragment";

export const removeFolderMutationFragment = gql`
  fragment RemoveFolderMutationFragment on RemoveFolderOutput {
    collaborationId
    partyId
    folderId
    folder {
      id
      folders {
        ...SubFolderFragment
      }
      requests {
        ...RequestFragment
      }
    }
    contentMetadata {
      id
      hasPendingUpdates
      todoCount
      requestToFulfillCount
      hasNoContent
    }
  }
  ${subFolderFragment}
  ${requestFragment}
`;

export const useRemoveFolder = (collaborationId: string, folderId: string) => {
  const REMOVE_FOLDER = gql`
    ${removeFolderMutationFragment}
    mutation removeFolder($input: RemoveFolderInput!) {
      removeFolder(input: $input) {
        ...RemoveFolderMutationFragment
      }
    }
  `;
  const [removeFolderMutation, { data, loading, error }] = useMutation<
    { removeFolder: Folder },
    MutationRemoveFolderArgs
  >(REMOVE_FOLDER);

  const removeFolder = () => {
    const input: RemoveFolderInput = {
      collaborationId,
      folderId,
    };
    return removeFolderMutation({
      variables: { input },
    });
  };

  return {
    removeFolder,
    removeFolderResponseData: data?.removeFolder,
    removeFolderLoading: loading,
    errorOnRenamingFolder: error,
  };
};
