/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useCallback } from "react";
import { useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { USER_SETTINGS_SEARCH_PARAM, UserSettingsSection } from "@taketurns-app/routing/routes/userSettingsSections";
import { CollaborationFilterType } from "@taketurns-components/collaboration/CollaborationList/collaborationFilterType";
import { collaborationListAppliedFilterState } from "@taketurns-repositories/collaboration/state/recoil/collaborationListAppliedFilterState";
import { AUTHENTICATED_ROUTES } from "../../../app/routing/routes/authenticatedRoutes.constants";
import { HelpCenterSections } from "../../commons/enum/helpCenterSectionsEnum";

export const useGetHelpCenterSectionFromUserNavContext = () => {
  const collaborationListAppliedFilter = useRecoilValue(collaborationListAppliedFilterState);
  const { pathname, search } = useLocation();

  const mapCollaborationListAppliedFilterToHelpCenterSection = useCallback(() => {
    switch (collaborationListAppliedFilter) {
      case CollaborationFilterType.activeCollaborations:
        return HelpCenterSections.ACTIVE_COLLABORATIONS;
      case CollaborationFilterType.closedCollaborations:
        return HelpCenterSections.CLOSED_COLLABORATIONS;
      case CollaborationFilterType.starredCollaborations:
        return HelpCenterSections.STARRED_COLLABORATIONS;
      case CollaborationFilterType.pendingInvitations:
        return HelpCenterSections.PENDING_INVITATIONS;
      default:
        return HelpCenterSections.ACTIVE_COLLABORATIONS;
    }
  }, [collaborationListAppliedFilter]);

  const mapUserSettingsSectionToHelpCenterSection = useCallback(() => {
    const userSettingsSearchParams = new URLSearchParams(search);
    const userSettingsCurrentSection = userSettingsSearchParams.get(USER_SETTINGS_SEARCH_PARAM) as UserSettingsSection;
    if (userSettingsCurrentSection === "plan") {
      return HelpCenterSections.MANAGE_YOUR_PLAN;
    }
    return HelpCenterSections.PROFILE;
  }, [search]);

  const getHelpCenterSectionFromUserNavContext = () => {
    switch (pathname) {
      case AUTHENTICATED_ROUTES.COLLABORATION_LIST:
        return mapCollaborationListAppliedFilterToHelpCenterSection();
      case AUTHENTICATED_ROUTES.USER_SETTINGS:
        return mapUserSettingsSectionToHelpCenterSection();
      case AUTHENTICATED_ROUTES.COLLABORATION_CREATE.FULL:
        return HelpCenterSections.CREATE_COLLABORATION;
      default:
        return HelpCenterSections.COLLABORATION_CONTENT;
    }
  };

  return { getHelpCenterSectionFromUserNavContext };
};
