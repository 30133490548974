/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Request } from "@taketurns/taketurns-graphql-repository";
import {
  CollaborationCommand,
  useCollaborationPermissions,
} from "@taketurns-rules/collaboration/permissions/useCollaborationPermissions";
import { useFetchConnectedUserPartyRule } from "@taketurns-rules/collaboration/queries/context/useFetchConnectedUserPartyRule";

export const useCanConnectedUserEditRequestRule = () => {
  const { connectedUserParty } = useFetchConnectedUserPartyRule();
  const canUserRequestFiles = useCollaborationPermissions(CollaborationCommand.REQUEST_FILES);

  return (request: Request) => {
    return connectedUserParty === request.party && canUserRequestFiles;
  };
};
