/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { CollaborationStatus } from "@taketurns/taketurns-graphql-repository";
import {
  CollaborationCommand,
  useCollaborationPermissions,
} from "@taketurns-rules/collaboration/permissions/useCollaborationPermissions";
import {
  useFetchCollaborationStatusRule,
} from "@taketurns-rules/collaboration/queries/fields/useFetchCollaborationStatusRule";

export const useShouldDisplayInvitationFormRule = () => {
  const { status } = useFetchCollaborationStatusRule();

  return (
    useCollaborationPermissions(CollaborationCommand.MANAGE_PARTICIPANTS) &&
    status !== CollaborationStatus.Closed &&
    status !== CollaborationStatus.Wiped
  );
};
