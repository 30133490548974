/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Box, Skeleton, Typography } from "@mui/material";
import { TranslationLanguages } from "stream-chat";
import { StreamMessage, useMessageContext, useChatContext as useStreamChatContext } from "stream-chat-react";
import { Party } from "@taketurns/taketurns-graphql-repository";
import { ChatAnnouncerUserId } from "@taketurns-repositories/chat/model/chatAnnouncerUserId";
import { useChatContext } from "@taketurns-repositories/chat/state/context/ChatContext";
import { useFetchConnectedUserPartyRule } from "@taketurns-rules/collaboration/queries/context/useFetchConnectedUserPartyRule";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";

type AnnouncementMessage = StreamMessage & {
  "tt-i18n-text": { [key in TranslationLanguages]: string };
};

export const ChatAnnouncementMessage = () => {
  const { message } = useMessageContext();
  const { client } = useStreamChatContext();
  const clientCurrentLanguage = client.user.language;

  const { collaborationId } = useChatContext();
  const { connectedUserParty, loadingConnectedUserParty } = useFetchConnectedUserPartyRule(collaborationId);

  const color = getMessageColorForConnectedUserParty();

  return (
    <Box sx={{ display: "flex", justifyContent: "center", padding: "8px 0" }}>
      {loadingConnectedUserParty ? (
        <Skeleton variant={"text"} sx={{ flex: 1 }} />
      ) : (
        <Typography fontWeight={600} textAlign={"center"} variant={"caption"} color={color}>
          {(message as AnnouncementMessage)["tt-i18n-text"][clientCurrentLanguage]}
        </Typography>
      )}
    </Box>
  );

  function getMessageColorForConnectedUserParty() {
    const { id: messageUserId } = message.user;
    switch (messageUserId) {
      case ChatAnnouncerUserId.OWNING_PARTY_ANNOUNCER:
        return connectedUserParty === Party.Owning ? "primary" : "secondary";
      case ChatAnnouncerUserId.INVITED_PARTY_ANNOUNCER:
        return connectedUserParty === Party.Invited ? "primary" : "secondary";
      case ChatAnnouncerUserId.COLLABORATION_ANNOUNCER:
        return TakeTurnsColors.darkGray;
    }
  }
};
