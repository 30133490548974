/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { selectorFamily } from "recoil";
import { Party } from "@taketurns/taketurns-graphql-repository";
import { selectCollaborationFormInvitedParty } from "./selectCollaborationFormInvitedParty";
import { selectCollaborationFormOwningParty } from "./selectCollaborationFormOwningParty";

export const selectCollaborationFormPartyNameByParty = selectorFamily<string, Party>({
  key: "collaborationFormPartyNameByParty",
  get:
    (party) =>
    ({ get }) => {
      if (party === Party.Owning) {
        return get(selectCollaborationFormOwningParty);
      }
      return get(selectCollaborationFormInvitedParty);
    },
  set:
    (party) =>
    ({ set }, newPartyName) => {
      if (party === Party.Owning) {
        set(selectCollaborationFormOwningParty, newPartyName);
      } else {
        set(selectCollaborationFormInvitedParty, newPartyName);
      }
    },
});
