/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Avatar, IconButton, Skeleton, Stack, Typography } from "@mui/material";
import { Fragment, useState } from "react";
import { Attachment } from "@taketurns/taketurns-graphql-repository";
import { FetchLoadingManager } from "@taketurns-app/utils/FetchLoadingManager";
import { SomethingWentWrong } from "@taketurns-components/shared/SomethingWentWrong";
import { useAttachmentDetailsTranslation } from "@taketurns-i18n/collaboration/AttachmentDetails/useAttachmentDetailsTranslation";
import { useSelectDocumentRevisionRule } from "@taketurns-rules/collaboration/commands/collaborationView/attachment/useSelectDocumentRevisionRule";
import { useFetchConnectedUserPartyRule } from "@taketurns-rules/collaboration/queries/context/useFetchConnectedUserPartyRule";
import { getColorForUserParty } from "@taketurns-rules/collaboration/utils/color/TakeTurnsBlueColorsUtils";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { useFetchDocumentRevisionRule } from "@taketurns-rules/document/queries/useFetchDocumentRevisionRule";
import { useFetchDocumentRevisionsRule } from "@taketurns-rules/document/queries/useFetchDocumentRevisionsRule";
import { IconChevronDown } from "../../../../../commons/icons";
import { MobileAttachmentRevisionListDrawer } from "./MobileAttachmentRevisionListDrawer";

interface MobileAttachmentRevisionListButtonProps {
  attachment: Attachment;
  canEdit?: boolean;
}

export const MobileAttachmentRevisionListButton = ({
  attachment,
  canEdit,
}: MobileAttachmentRevisionListButtonProps) => {
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  return (
    <Fragment>
      <Stack direction="row" alignItems="center" onClick={() => setDrawerOpen(true)}>
        <SelectedRevisionIndicator attachment={attachment} />
        <IconButton size="small">
          <IconChevronDown size={20} />
        </IconButton>
      </Stack>
      <MobileAttachmentRevisionListDrawer
        attachment={attachment}
        canEdit={canEdit}
        isOpen={isDrawerOpen}
        setIsOpen={setDrawerOpen}
      />
    </Fragment>
  );
};

function useSelectedRevisionIndicatorData(documentId: string, revisionId: string) {
  const { connectedUserParty, loadingConnectedUserParty, errorOnFetchingConnectedUserParty } =
    useFetchConnectedUserPartyRule();

  const { documentRevision, loadingDocumentRevision, errorOnDocumentRevision } = useFetchDocumentRevisionRule(
    documentId,
    revisionId,
  );

  const fetchLoadingManager = new FetchLoadingManager(
    [loadingDocumentRevision, loadingConnectedUserParty],
    [errorOnDocumentRevision, errorOnFetchingConnectedUserParty],
  );

  return {
    connectedUserParty,
    revision: documentRevision,
    loading: fetchLoadingManager.hasFetchLoading(),
    error: fetchLoadingManager.hasFetchInError(),
  };
}

const SelectedRevisionIndicator = (props: { attachment: Attachment }) => {
  const { t } = useAttachmentDetailsTranslation();
  const { selectRevision, selectedRevisionId } = useSelectDocumentRevisionRule();
  const { revisions, loadingDocumentRevisions, errorOnFetchingDocumentRevisions } = useFetchDocumentRevisionsRule(
    props.attachment.id,
  );

  if (!selectedRevisionId) {
    selectRevision(props.attachment.revisionId);
  }

  const {
    connectedUserParty,
    revision,
    loading: loadingSelectedRevision,
    error: errorOnSelectedRevision,
  } = useSelectedRevisionIndicatorData(props.attachment.id, selectedRevisionId);

  if (loadingDocumentRevisions || loadingSelectedRevision) {
    return <Skeleton variant="text" width={125} />;
  }

  if (errorOnFetchingDocumentRevisions || errorOnSelectedRevision) {
    return <SomethingWentWrong />;
  }

  const partyColor = getColorForUserParty(revision.party, connectedUserParty);
  const revisionNumber = revisions.findIndex((r) => r.revisionId === selectedRevisionId) + 1;

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Avatar
        sx={{
          height: 22,
          width: 22,
          fontSize: 14,
          bgcolor: partyColor.variable,
        }}
      >
        {revisionNumber}
      </Avatar>
      <Typography variant="body2" textAlign="center" color={TakeTurnsColors.darkBlue}>
        {t("revision.history")}
      </Typography>
    </Stack>
  );
};
