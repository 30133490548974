/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { PendingAttachmentRevision } from "@taketurns-repositories/collaboration/models/pendingAttachmentRevision";
import { PendingAttachmentRevisionStatus } from "@taketurns-repositories/collaboration/models/pendingAttachmentRevisionStatus";
import { useSetPendingAttachmentRevisionStatus } from "./useSetPendingAttachmentRevisionStatus";

export const useMarkPendingAttachmentRevisionAsAdded = () => {
  const setPendingAttachmentRevisionStatus = useSetPendingAttachmentRevisionStatus();
  return (pendingAttachment: PendingAttachmentRevision) =>
    setPendingAttachmentRevisionStatus(pendingAttachment, PendingAttachmentRevisionStatus.added);
};
