/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Typography } from "@mui/material";
import { useUserPlanTranslation } from "@taketurns-i18n/user/UserPlans/useUserPlanTranslation";
import { useFetchIsUserOrganizationPlanFreePlanRule } from "@taketurns-rules/user/queries/plan/useFetchIsUserOrganizationPlanFreePlanRule";
import { useLazyFetchUserOrganizationSubscriptionRegionRule } from "@taketurns-rules/user/queries/useLazyFetchUserOrganizationSubscriptionRegionRule";

export const AuthenticatedSubscriptionPlansPageTitle = () => {
  const { isUserOrganizationPlanFreePlan, errorOnFetchingIsUserOrganizationPlanFreePlan } =
    useFetchIsUserOrganizationPlanFreePlanRule();
  const { errorOnFetchingUserOrganizationSubscriptionRegion } = useLazyFetchUserOrganizationSubscriptionRegionRule();
  const { t } = useUserPlanTranslation();

  if (errorOnFetchingIsUserOrganizationPlanFreePlan || errorOnFetchingUserOrganizationSubscriptionRegion) {
    return (
      <Typography align="center" color={"error"}>
        {t("header.error")}
      </Typography>
    );
  }

  if (isUserOrganizationPlanFreePlan) {
    return (
      <Typography variant="h6" align="center">
        {t("header.choosePlan")}
      </Typography>
    );
  }
  return (
    <Typography variant="h6" align="center">
      {t("header.upgradeYourPlan")}
    </Typography>
  );
};
