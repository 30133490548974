/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useEffect, useState } from "react";

const defaultCountryCode = "FR";
const GEOLOCATION_API_URL = "/";

export const useGetUserCountryCodeFromGeolocation = () => {
  const [state, setState] = useState<{
    geolocatedCountryCode: string;
    loading: boolean;
  }>({ geolocatedCountryCode: defaultCountryCode, loading: true });

  useEffect(() => {
    const getUserCountryCode = () => {
      try {
        const request = new XMLHttpRequest();
        request.open("GET", GEOLOCATION_API_URL, false);
        request.send(null);
        const countryCode = request.getResponseHeader("Cloudfront-Viewer-Country");
        if (countryCode) {
          setState({ loading: false, geolocatedCountryCode: countryCode });
        } else {
          setState({ loading: false, geolocatedCountryCode: defaultCountryCode });
        }
      } catch (error) {
        console.error(error);
        setState({ loading: false, geolocatedCountryCode: defaultCountryCode });
      }
    };
    getUserCountryCode();
  }, []);

  return state;
};
