/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Fragment, useRef, useState } from "react";
import { MobileSupervisorFilterDialog } from "@taketurns-components/collaboration/CollaborationList/SupervisorFilter/MobileSupervisorFilterDialog";
import { SupervisorFilterButton } from "@taketurns-components/collaboration/CollaborationList/SupervisorFilter/SupervisorFilterButton";
import { SupervisorFilterItemListRef } from "@taketurns-components/collaboration/CollaborationList/SupervisorFilter/SupervisorFilterItemList";
import { SupervisorFilterPopover } from "@taketurns-components/collaboration/CollaborationList/SupervisorFilter/SupervisorFilterPopover";
import { useIsOnMobileRule } from "@taketurns-rules/commons/theme/useIsOnMobileRule";

export const SupervisorFilter = () => {
  const supervisorFilterAnchorRef = useRef<HTMLDivElement>(null);
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
  const supervisorFilterItemListRef = useRef<SupervisorFilterItemListRef>(null);
  const isOnMobile = useIsOnMobileRule();

  const openFilterMenu = () => {
    setIsFilterOpen(true);
  };
  const closeFilterMenu = () => {
    setIsFilterOpen(false);
  };

  const unselectAllClicked = () => {
    supervisorFilterItemListRef.current.unselectAllSupervisorFilterItems();
  };

  if (isOnMobile) {
    return (
      <Fragment>
        <SupervisorFilterButton
          onClick={openFilterMenu}
          unselectAllClicked={unselectAllClicked}
          ref={supervisorFilterAnchorRef}
        />
        <MobileSupervisorFilterDialog
          isOpen={isFilterOpen}
          closeMenu={closeFilterMenu}
          ref={supervisorFilterItemListRef}
        />
      </Fragment>
    );
  }

  return (
    <Fragment>
      <SupervisorFilterButton
        onClick={openFilterMenu}
        unselectAllClicked={unselectAllClicked}
        ref={supervisorFilterAnchorRef}
      />
      <SupervisorFilterPopover
        isOpen={isFilterOpen}
        closeMenu={closeFilterMenu}
        supervisorFilterAnchorRef={supervisorFilterAnchorRef}
        ref={supervisorFilterItemListRef}
      />
    </Fragment>
  );
};
