/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Box, Checkbox, Stack, styled } from "@mui/material";
import { PropsWithChildren } from "react";
import { IconCheck } from "@taketurns-components/commons/icons";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";

interface SelectionComponentWrapperProps {
  checked: boolean;
  onClick: () => void;
}

export const SelectionWrapperForListItem = (props: PropsWithChildren<SelectionComponentWrapperProps>) => {
  return (
    <Stack direction="row" onClick={props.onClick} sx={{ cursor: "pointer" }}>
      <Checkbox disableRipple checked={props.checked} icon={<UncheckedIcon />} checkedIcon={<CheckedIcon />} />
      {props.children}
    </Stack>
  );
};

export const EmptySelectionWrapperForListItem = ({ children }: PropsWithChildren) => {
  return (
    <Stack direction="row" pl="42px">
      {children}
    </Stack>
  );
};

export const SelectionWrapperForCard = (props: PropsWithChildren<SelectionComponentWrapperProps>) => {
  return (
    <Box position="relative" onClick={props.onClick} sx={{ cursor: "pointer" }}>
      {props.children}
      <Checkbox
        disableRipple
        checked={props.checked}
        icon={<UncheckedIcon />}
        checkedIcon={<CheckedIcon />}
        sx={{
          position: "absolute",
          top: "-18px",
          left: "-18px",
        }}
      />
    </Box>
  );
};

const CheckIcon__CircleBackground = styled(Box, {
  shouldForwardProp: (prop) => prop !== "checked",
})<{ checked?: boolean }>(({ checked }) => ({
  backgroundColor: checked ? TakeTurnsColors.darkBlue : "white",
  border: `1px solid ${TakeTurnsColors.darkBlue}`,
  borderRadius: "50%",
  padding: "2px",
  height: "24px",
  width: "24px",
}));

const UncheckedIcon = () => <CheckIcon__CircleBackground />;

const CheckedIcon = () => (
  <CheckIcon__CircleBackground checked data-cy="ContentSelection.CheckedIcon">
    <IconCheck size={18} color="white" />
  </CheckIcon__CircleBackground>
);
