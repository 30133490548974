/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Dialog, styled } from "@mui/material";

export const SmallDialog = styled(Dialog)({
  "& .MuiDialog-paper": {
    minHeight: "auto",
  },
});
