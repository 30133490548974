/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useRecoilValue } from "recoil";
import { selectAllPendingAttachmentsState } from "../recoil/selectAllPendingAttachmentsState";

export const useGetAllPendingAttachments = () => {
  return useRecoilValue(selectAllPendingAttachmentsState);
};
