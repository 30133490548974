/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Stack, styled, Typography } from "@mui/material";
import { AttachmentPreview } from "@taketurns-components/collaboration/CollaborationView/AttachmentDetails/components/AttachmentPreview";
import { useSharedCollaborationTranslation } from "@taketurns-i18n/collaboration/shared/useSharedCollaborationTranslation";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { useEffect, useRef, useState } from "react";

export const ImagePreview = ({ imageUrl }: { imageUrl: string }) => {
  const { t } = useSharedCollaborationTranslation();
  const { imageRef, error } = useImageError();

  if (error)
    return (
      <Stack alignItems="center">
        <Typography>{t("ImagePreview.failedToLoadImage")}</Typography>
      </Stack>
    );

  return (
    <AttachmentPreview>
      <ImagePreviewContainer>
        <img
          ref={imageRef}
          src={imageUrl}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "scale-down",
            overflow: "hidden",
          }}
          width={"100%"}
          alt={"Preview"}
        />
      </ImagePreviewContainer>
    </AttachmentPreview>
  );
};

const ImagePreviewContainer = styled("div")({
  backgroundColor: TakeTurnsColors.background,
  padding: 24,
  height: "100%",
  display: "flex",
  overflow: "auto",
});

const useImageError = () => {
  const imageRef = useRef<HTMLImageElement>(null);
  const [error, setError] = useState(false);

  const _handleError = () => {
    setError(true);
  };

  useEffect(() => {
    const img = imageRef.current;
    img.addEventListener("error", _handleError);

    return () => {
      img.removeEventListener("error", _handleError);
    };
  }, []);

  return {
    imageRef,
    error,
  };
};
