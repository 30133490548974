/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useSnackbar } from "notistack";
import { SubscribeToPlanWithTrialInput, SubscriptionPlanTier } from "@taketurns/taketurns-graphql-repository";
import { useUserPlanTranslation } from "@taketurns-i18n/user/UserPlans/useUserPlanTranslation";
import { useSubscribeToPlanWithTrial } from "@taketurns-repositories/user/graphql/mutations/useSubscribeToPlanWithTrial";
import { useGetSubscriptionPlanCurrencyRule } from "../queries/plan/getSubscriptionPlanCurrencyRule";
import { useGetSubscriptionPlanPeriodRule } from "../queries/plan/getSubscriptionPlanPeriodRule";
import { useGetSubscriptionPlanRegionRule } from "../queries/plan/getSubscriptionPlanRegionRule";

export const useSubscribeToPlanWithTrialRule = () => {
  const region = useGetSubscriptionPlanRegionRule();
  const currency = useGetSubscriptionPlanCurrencyRule();
  const period = useGetSubscriptionPlanPeriodRule();

  const { subscribeToPlanWithTrialCommand, loadingSubscribeToPlanWithTrial, errorOnSubscribeToPlanWithTrial } =
    useSubscribeToPlanWithTrial();

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useUserPlanTranslation();

  const subscribeToPlanWithTrial = (subscribeToPlanWithTrialInput: SubscribeToPlanWithTrialInput) => {
    return subscribeToPlanWithTrialCommand(subscribeToPlanWithTrialInput)
      .then(() => {
        enqueueSnackbar(t("subscribeToPlanWithTrialSuccess"), { variant: "info" });
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar(t("error"), { variant: "error" });
        throw error;
      });
  };
  const subscribeToPlanWithTrialByPlanTier = (tier: SubscriptionPlanTier) => {
    const selectedPlanTierWithTrial: SubscribeToPlanWithTrialInput = { tier, region, period, currency };
    return subscribeToPlanWithTrial(selectedPlanTierWithTrial);
  };

  return {
    subscribeToPlanWithTrial,
    subscribeToPlanWithTrialByPlanTier,
    loadingSubscribeToPlanWithTrial,
    errorOnSubscribeToPlanWithTrial,
  };
};
