/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { CollaborationCommand, useCollaborationPermissions } from "@taketurns-rules/collaboration/permissions/useCollaborationPermissions";
import { useIsCollaborationClosedRule } from "@taketurns-rules/collaboration/queries/collaborationView/useIsCollaborationClosedRule";

export const useCanEditCollaborationRule = (collaborationId: string): boolean => {
  const { isCollaborationClosed } = useIsCollaborationClosedRule(collaborationId);
  const isAllowedToEdit = useCollaborationPermissions(CollaborationCommand.EDIT_COLLABORATION, collaborationId);
  return !isCollaborationClosed && isAllowedToEdit;
};
