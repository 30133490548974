/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { TipKey } from "@taketurns-i18n/commons/en/tips";
import { useFetchIsTipHiddenByKey } from "@taketurns-repositories/user/graphql/queries/useFetchIsTipHiddenByKey";

export const useFetchIsTipHiddenRule = (tipKey: TipKey) => {
  return useFetchIsTipHiddenByKey(tipKey);
};
