/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql } from "@apollo/client";
import { AddFolderOutput, SubscriptionOnAddFolderArgs } from "@taketurns/taketurns-graphql-repository";
import { useRefreshableSubscription } from "@taketurns-components/webapp/QueriesAndSubscriptionsRefreshTrigger";
import { AddFolderMutationFragment } from "../../mutations/content/folders/useAddFolder";

const SUBSCRIBE_TO_ADD_FOLDER = gql`
  subscription onAddFolder($collaborationId: ID!, $partyId: ID!, $folderId: ID!) {
    onAddFolder(collaborationId: $collaborationId, partyId: $partyId, folderId: $folderId) {
      ...AddFolderMutationFragment
    }
  }
  ${AddFolderMutationFragment}
`;

export const useSubscribeToAddFolder = (args: SubscriptionOnAddFolderArgs) => {
  return useRefreshableSubscription<{ onAddFolder: AddFolderOutput }, SubscriptionOnAddFolderArgs>(
    SUBSCRIBE_TO_ADD_FOLDER,
    {
      variables: args,
    },
  );
};
