/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Navigate, Outlet } from "react-router-dom";
import { useAuthContext } from "../AuthProvider";
import { AUTHENTICATED_ROUTES } from "../routes/authenticatedRoutes.constants";

export const AlreadyLoggedInGuard = () => {
  const { isAuthenticated } = useAuthContext();
  if (isAuthenticated) {
    return <Navigate to={AUTHENTICATED_ROUTES.COLLABORATION_LIST} />;
  }
  return <Outlet />;
};
