/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useGetDisplayedCollaborationId } from "@taketurns-repositories/collaboration/context/useGetDisplayedCollaborationId";
import { CollaborationCommand, useCollaborationPermissions } from "@taketurns-rules/collaboration/permissions/useCollaborationPermissions";
import { useIsAttachmentRevisionAddInProgressRule } from "@taketurns-rules/collaboration/queries/attachment/pending/useIsAttachmentRevisionAddInProgressRule";
import { Attachment } from "@taketurns/taketurns-graphql-repository";
import { useIsCollaborationClosedRule } from "../collaborationView/useIsCollaborationClosedRule";

interface AttachmentAvailableActions {
  canDelete: boolean;
  canEditDocumentDetails: boolean;
  canUploadRevision: boolean;
  canMarkForReview: boolean;
  isUploadRevisionDisabled: boolean;
  isDeleteAttachmentDisabled: boolean;
}

export const useFetchAttachmentAvailableActionsRule = (attachment: Attachment): AttachmentAvailableActions => {
  const allowedToAddFiles = useCollaborationPermissions(CollaborationCommand.ADD_FILES);
  const allowedToMarkForReview = useCollaborationPermissions(CollaborationCommand.MARK_FOR_REVIEW);
  const collaborationId = useGetDisplayedCollaborationId();
  const { isCollaborationClosed, loadingCollaborationStatus, errorOnFetchingCollaborationStatus } =
    useIsCollaborationClosedRule(collaborationId);
  const isAttachmentRevisionAddInProgress = useIsAttachmentRevisionAddInProgressRule();

  if (!attachment || isCollaborationClosed) {
    return {
      canEditDocumentDetails: false,
      canUploadRevision: false,
      canMarkForReview: false,
      canDelete: false,
      isDeleteAttachmentDisabled: true,
      isUploadRevisionDisabled: true,
    };
  }

  return {
    canDelete: allowedToAddFiles,
    canEditDocumentDetails: allowedToAddFiles && !attachment.locked,
    canUploadRevision: allowedToAddFiles,
    canMarkForReview: allowedToMarkForReview,
    isUploadRevisionDisabled: attachment.locked || isAttachmentRevisionAddInProgress,
    isDeleteAttachmentDisabled: attachment.locked,
  };
};
