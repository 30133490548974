/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Box, ListItem, styled, SxProps, Typography } from "@mui/material";
import { PropsWithChildren } from "react";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";

interface FormListItemProps {
  label: string;
  error?: boolean;
  sx?: SxProps;
}

export const FormListItem = (props: PropsWithChildren<FormListItemProps>) => {
  const { label, children, error, sx } = props;
  return (
    <FormListItem__Container className="FormListItem" divider disablePadding>
      <FormListItem__TypoContainer sx={sx}>
        <Typography
          fontSize="0.875rem"
          lineHeight="0.875rem"
          color={error ? TakeTurnsColors.error : TakeTurnsColors.darkGray}
        >
          {label}
        </Typography>
      </FormListItem__TypoContainer>
      <FomListItem__Content>{children}</FomListItem__Content>
    </FormListItem__Container>
  );
};

const FormListItem__Container = styled(ListItem)(({ theme }) => ({
  display: "flex",
  [theme.breakpoints.up("sm")]: {
    flexDirection: "row",
    alignItems: "center",
    minHeight: 50,
  },
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    alignItems: "flex-start",
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    gap: theme.spacing(1),
  },
}));

const FormListItem__TypoContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up("sm")]: {
    flex: "0 0 200px",
  },
}));

const FomListItem__Content = styled(Box)(({ theme }) => ({
  flex: 1,
  display: "flex",
  alignItems: "center",
  [theme.breakpoints.down("sm")]: {
    flex: 0,
    width: "100%",
  },
}));
