/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useEffect } from "react";
import { useGetPendingAttachmentsPendingUpload } from "@taketurns-repositories/collaboration/state/read/useGetPendingAttachmentsPendingUpload";
import { useMarkPendingAttachmentAsUploading } from "@taketurns-repositories/collaboration/state/write/pendingAttachment/useMarkPendingAttachmentAsUploading";
import { useSetPendingAttachmentDocumentId } from "@taketurns-repositories/collaboration/state/write/pendingAttachment/useSetPendingAttachmentDocumentId";
import { useStartUploadRule } from "../../../document/commands/useStartUploadRule";

export const useListenToPendingUploadsRule = () => {
  const startUpload = useStartUploadRule();
  const pendingAttachmentsPendingUpload = useGetPendingAttachmentsPendingUpload();
  const markPendingAttachmentAsUploading = useMarkPendingAttachmentAsUploading();
  const setPendingAttachmentDocumentId = useSetPendingAttachmentDocumentId();

  useEffect(() => {
    for (const pendingAttachment of pendingAttachmentsPendingUpload) {
      markPendingAttachmentAsUploading(pendingAttachment);
      startUpload(pendingAttachment.uploadId).then((documentId) => {
        setPendingAttachmentDocumentId(pendingAttachment, documentId);
      });
    }
  }, [pendingAttachmentsPendingUpload]);
};
