/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useFetchDocument } from "@taketurns-repositories/document/graphql/queries/useFetchDocument";
import { useGetDisplayedCollaborationIdRule } from "@taketurns-rules/collaboration/queries/collaboration/useGetDisplayedCollaborationIdRule";

export const useFetchDocumentRule = (documentId: string, collaborationId?: string) => {
  const displayedCollaborationId = useGetDisplayedCollaborationIdRule();
  return useFetchDocument(collaborationId ?? displayedCollaborationId, documentId);
};
