/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { ApolloClient, gql } from "@apollo/client";
import { useRefreshableSubscription } from "@taketurns-components/webapp/QueriesAndSubscriptionsRefreshTrigger";
import { wait } from "@taketurns-rules/commons/util/wait";
import { USER_PENDING_ORGANIZATION_INVITATION } from "../queries/useFetchUserPendingOrganizationInvitation";

const ON_ADDED_REMOVED_INVITATION_TO_ORGANIZATION = gql`
  subscription onAddedOrRemovedInvitationToOrganization($userId: ID!) {
    onAddedOrRemovedInvitationToOrganization(userId: $userId) {
      userId
    }
  }
`;

export const useSubscribeToAddOrRemoveInvitationToOrganization = (userId?: string) => {
  async function refetchUserPendingOrganizationInvitationQuery({ client }: { client: ApolloClient<unknown> }) {
    //Wait 3s to prevent delay between event and openSearch update
    await wait(3000);
    client.query({ query: USER_PENDING_ORGANIZATION_INVITATION, fetchPolicy: "network-only" });
  }

  return useRefreshableSubscription(ON_ADDED_REMOVED_INVITATION_TO_ORGANIZATION, {
    variables: { userId },
    skip: !userId,
    onData: refetchUserPendingOrganizationInvitationQuery,
  });
};
