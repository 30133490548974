/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Box, styled } from "@mui/material";
import { ReactNode } from "react";
import { BodyLayout } from "../BodyLayout";

interface SingleCenteredColumnLayoutProps {
  header: ReactNode;
  content: ReactNode;
}

export const SingleCenteredColumnLayout = ({ content, header }: SingleCenteredColumnLayoutProps) => {
  return (
    <BodyLayout
      content={<SingleColumnContent>{content}</SingleColumnContent>}
      header={<SingleColumnHeader>{header}</SingleColumnHeader>}
    />
  );
};

const SingleColumnContent = styled(Box)(({ theme }) => ({
  margin: "0 auto",
  boxShadow: "0px 1px 20px 0px rgba(0, 0, 0, 0.05)",
  borderTopLeftRadius: 20,
  borderTopRightRadius: 20,
  padding: "24px 24px 0",
  width: "50%",
  height: "100%",
  backgroundColor: "white",

  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

const SingleColumnHeader = styled(Box)(({ theme }) => ({
  margin: "0 auto",
  width: "50%",

  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));
