/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useSetRecoilState } from "recoil";
import { attachmentBulksState } from "@taketurns-repositories/collaboration/state/recoil/attachmentBulks";

export const useCancelUploadIdInAttachmentBulk = () => {
  const setAttachmentBulks = useSetRecoilState(attachmentBulksState);
  return (uploadIdToRemove: number) => {
    setAttachmentBulks((previousAttachmentBulks) => {
      return previousAttachmentBulks.map((attachmentBulksState) => {
        if (attachmentBulksState.uploadIds.includes(uploadIdToRemove)) {
          return {
            numberOfAttachments: attachmentBulksState.numberOfAttachments - 1,
            uploadIds: attachmentBulksState.uploadIds.filter((uploadId) => uploadId !== uploadIdToRemove),
          };
        }
        return { ...attachmentBulksState };
      });
    });
  };
};
