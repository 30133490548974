/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql } from "@apollo/client";
import { userFragment } from "@taketurns-repositories/user/graphql/fragments/userFragment";

export const participantFragment = gql`
  fragment ParticipantFields on Participant {
    userId
    role
    party
    user {
      ...UserFields
    }
  }
  ${userFragment}
`;
