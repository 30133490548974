/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useGetDisplayedCollaborationId } from "@taketurns-repositories/collaboration/context/useGetDisplayedCollaborationId";
import { useFetchCollaborationLastUpdateEvent } from "@taketurns-repositories/collaboration/graphql/queries/content/useFetchCollaborationLastUpdateEvent";

export const useFetchCollaborationLastUpdateEventRule = () => {
  const collaborationId = useGetDisplayedCollaborationId();
  return useFetchCollaborationLastUpdateEvent(collaborationId);
};
