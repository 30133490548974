/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useSetRecoilState } from "recoil";
import { Party } from "@taketurns/taketurns-graphql-repository";
import { selectCollaborationFormPartyNameByParty } from "../../../recoil/collaborationCreation/form/values/selectCollaborationFormPartyNameByParty";

export const useSetCollaborationFormPartyNameByParty = (party: Party) => {
  return useSetRecoilState(selectCollaborationFormPartyNameByParty(party));
};
