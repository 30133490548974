/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { MobileStepper, Stack, styled, Typography } from "@mui/material";
import { useState } from "react";
import { CreateCollaborationInput } from "@taketurns/taketurns-graphql-repository";
import { MobileBodyLayout } from "@taketurns-app/layouts/body/MobileBodyLayout";
import { CollaborationCreationInvitedPartyColumn } from "@taketurns-app/pages/authenticated/CollaborationCreation/CollaborationCreationPages/CollaborationCreationInvitedPartyColumn";
import { CollaborationCreationMainFormAndContentColumn } from "@taketurns-app/pages/authenticated/CollaborationCreation/CollaborationCreationPages/CollaborationCreationMainFormAndContentColumn";
import { CollaborationCreationOwningPartyColumn } from "@taketurns-app/pages/authenticated/CollaborationCreation/CollaborationCreationPages/CollaborationCreationOwningPartyColumn";
import { CollaborationCreationStartNoteColumn } from "@taketurns-app/pages/authenticated/CollaborationCreation/CollaborationCreationPages/CollaborationCreationStartNoteColumn";
import { TakeTurnsDefaultButton } from "@taketurns-components/commons/button";
import { MobileBackButton } from "@taketurns-components/webapp/MobileBackButton";
import { useWebAppTranslations } from "@taketurns-i18n/webapp/useWebAppTranslations";
import { useGetCollaborationForm } from "@taketurns-repositories/collaboration/state/read/collaborationCreation/form/useGetCollaborationForm";
import { useValidateInvitedPartyRule } from "@taketurns-rules/collaboration/commands/collaborationCreation/form/mobile/useValidateInvitedPartyRule";
import { useValidateMainFormRule } from "@taketurns-rules/collaboration/commands/collaborationCreation/form/mobile/useValidateMainFormRule";
import { useValidateOwningPartyRule } from "@taketurns-rules/collaboration/commands/collaborationCreation/form/mobile/useValidateOwningPartyRule";
import { useValidateCollaborationFormAndGetIsValidFormRule } from "@taketurns-rules/collaboration/commands/collaborationCreation/form/useValidateCollaborationFormAndGetIsValidFormRule";
import { useCreateCollaborationRule } from "@taketurns-rules/collaboration/commands/collaborationCreation/useCreateCollaborationRule";

const STEPS = ["content", "invitedParty", "owningParty", "note"];
const MAIN_FORM_STEP_NUMBER = 0;
const INVITED_PARTY_STEP_NUMBER = 1;
const OWNING_PARTY_STEP_NUMBER = 2;
const NOTE_STEP_NUMBER = 3;

export const MobileCollaborationCreation = () => {
  const [step, setStep] = useState(0);
  const { t } = useWebAppTranslations("collaboration-creation");

  const validateMainForm = useValidateMainFormRule();
  const validateInvitedPartyForm = useValidateInvitedPartyRule();
  const validateOwningPartyForm = useValidateOwningPartyRule();
  const validateCollaborationFormAndGetValidationStatus = useValidateCollaborationFormAndGetIsValidFormRule();
  const { createCollaboration, createCollaborationIsLoading } = useCreateCollaboration();

  const isLastStep = step === STEPS.length - 1;

  const handleNextAction = () => {
    let isStepValid = false;
    switch (step) {
      case MAIN_FORM_STEP_NUMBER:
        isStepValid = validateMainForm().isValid;
        break;
      case INVITED_PARTY_STEP_NUMBER:
        isStepValid = validateInvitedPartyForm().isValid;
        break;
      case OWNING_PARTY_STEP_NUMBER:
        isStepValid = validateOwningPartyForm().isValid;
        break;
      case NOTE_STEP_NUMBER:
        isStepValid = validateCollaborationFormAndGetValidationStatus().isValid;
        break;
      default:
        throw new Error("Invalid step");
    }
    if (isStepValid && !isLastStep) {
      setStep(step + 1);
    }
    if (isStepValid && isLastStep) {
      createCollaboration();
    }
  };

  const nextButtonLabel = isLastStep ? t("navigation.send") : t("navigation.next");

  return (
    <MobileBodyLayout
      disablePadding
      header={<MobileCollaborationCreationHeader step={step} />}
      LeftButton={
        <div style={{ flex: 1 }}>
          <MobileBackButton />
        </div>
      }
      content={
        <MobileCollaborationCreation__SafeAreaBox>
          <MobileCollaborationCreationContent step={step} />
        </MobileCollaborationCreation__SafeAreaBox>
      }
      RightButton={
        <div style={{ flex: 1, textAlign: "right" }}>
          <TakeTurnsDefaultButton onClick={handleNextAction} disabled={createCollaborationIsLoading}>
            {nextButtonLabel}
          </TakeTurnsDefaultButton>
        </div>
      }
    />
  );
};

const useCreateCollaboration = () => {
  const { createCollaborationCommand, createCollaborationResult } = useCreateCollaborationRule();
  const createCollaborationInput = useGetCollaborationForm();

  return {
    createCollaboration: () => createCollaborationCommand(createCollaborationInput as CreateCollaborationInput),
    createCollaborationIsLoading: createCollaborationResult.loading,
  };
};

const MobileCollaborationCreationHeader = ({ step }: { step: number }) => {
  const { t } = useWebAppTranslations("collaboration-creation");

  const stepTitle = t(`mobileSection.${STEPS[step]}`);

  return (
    <Stack sx={{ alignItems: "center" }}>
      <Typography>{stepTitle}</Typography>
      <MobileStepper
        variant="dots"
        backButton={null}
        nextButton={null}
        steps={4}
        activeStep={step}
        sx={{ position: "relative" }}
      />
    </Stack>
  );
};

const MobileCollaborationCreationContent = ({ step }: { step: number }) => {
  switch (step) {
    case MAIN_FORM_STEP_NUMBER:
      return <CollaborationCreationMainFormAndContentColumn />;
    case INVITED_PARTY_STEP_NUMBER:
      return <CollaborationCreationInvitedPartyColumn />;
    case OWNING_PARTY_STEP_NUMBER:
      return <CollaborationCreationOwningPartyColumn />;
    case NOTE_STEP_NUMBER:
      return <CollaborationCreationStartNoteColumn />;
    default:
      throw new Error("Invalid step");
  }
};

const MobileCollaborationCreation__SafeAreaBox = styled(Stack)({
  flex: 1,
  marginTop: "env(safe-area-inset-top)",
  marginBottom: "env(safe-area-inset-bottom)",
});
