/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Storage } from "aws-amplify";

export const configureS3Storage = (bucket: string) => {
  Storage.configure({
    bucket: bucket,
    level: "public",
    resumable: true,
  });
};
