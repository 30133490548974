/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { IconButton, Stack, Typography } from "@mui/material";

import { useState } from "react";
import { useVisualViewportHeight } from "@taketurns-rules/chat/commands/useVisualViewportHeight";
import { useGetUnreadMessageCountByCollaborationIdRule } from "@taketurns-rules/chat/queries/useGetUnreadMessageByCollaborationIdRule";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { IconChevronLeft, IconMessageCircle } from "../../commons/icons";
import { MobileFullPageSheet } from "../../commons/MobileSheets/MobileFullPageSheet";
import { Chat } from "../Chat/Chat";
import { UnreadMessagesBadge } from "../ChatBubbleWithUnreadMessageBadge/UnreadMessagesBadge";
import { ChatWrapper } from "../ChatWrapper/ChatWrapper";

interface MobileChatDrawerProps {
  collaborationId: string;
}

export const MobileChatDrawerAndButton = ({ collaborationId }: MobileChatDrawerProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const { viewportHeight } = useVisualViewportHeight();

  return (
    <ChatWrapper>
      <IconButton sx={{ paddingRight: 0 }} onClick={() => setIsOpen(true)}>
        <ChatIcon collaborationId={collaborationId} />
      </IconButton>
      <MobileFullPageSheet
        isOpen={isOpen}
        header={<PageHeader closeDialog={() => setIsOpen(false)} />}
        sx={{
          top: "initial",
          bottom: 0,
          maxHeight: `${viewportHeight}px`,
        }}
      >
        <Chat collaborationId={collaborationId} />
      </MobileFullPageSheet>
    </ChatWrapper>
  );
};

const ChatIcon = ({ collaborationId }: { collaborationId: string }) => {
  const { unreadCount } = useGetUnreadMessageCountByCollaborationIdRule(collaborationId);

  const noIndicatorToDisplay = !unreadCount || unreadCount === 0;
  const icon = <IconMessageCircle size={24} color={TakeTurnsColors.darkGray} />;

  if (noIndicatorToDisplay) {
    return icon;
  }

  return <UnreadMessagesBadge unreadCount={unreadCount}>{icon}</UnreadMessagesBadge>;
};

const PageHeader = ({ closeDialog }: { closeDialog: () => void }) => {
  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between" padding="16px 24px">
      <IconButton onClick={closeDialog} disableRipple sx={{ justifyContent: "flex-start", width: "50px" }}>
        <IconChevronLeft color={TakeTurnsColors.darkGray} size={24} />
      </IconButton>

      <Typography textAlign="center">Chat</Typography>

      <div style={{ width: "50px" }} />
    </Stack>
  );
};
