/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useQuery } from "@apollo/client";
import { ConnectedUser, SubscriptionPlanPeriod, SubscriptionPlanTier } from "@taketurns/taketurns-graphql-repository";

export const useFetchUserSubscriptionPlanTierAndPeriod = () => {
  const USER_PLAN_TIER_AND_PERIOD = gql`
    query userOrganizationPlanTierAndPeriod {
      connectedUser {
        id
        organization {
          id
          subscription {
            id
            tier
            period
          }
        }
      }
    }
  `;
  const {
    data,
    error: errorOnFetchingUserPlanTier,
    loading: loadingUserPlanTier,
  } = useQuery<{
    connectedUser: ConnectedUser;
  }>(USER_PLAN_TIER_AND_PERIOD);
  return {
    planTier: data?.connectedUser?.organization?.subscription?.tier ?? SubscriptionPlanTier.T0,
    period: data?.connectedUser?.organization?.subscription?.period ?? SubscriptionPlanPeriod.Year,
    errorOnFetchingUserPlanTier,
    loadingUserPlanTier,
  };
};
