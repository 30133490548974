/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { atom } from "recoil";
import { SubscriptionPlanRegion } from "@taketurns/taketurns-graphql-repository";

export const subscriptionPlanRegionState = atom<SubscriptionPlanRegion>({
  key: "subscriptionPlanRegion",
  default: SubscriptionPlanRegion.Na,
});
