/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useCollaborationListTranslation } from "@taketurns-i18n/collaboration/CollaborationList/useCollaborationListTranslation";
import { CollaborationListRowActionButton } from "../shared/CollaborationListRowActionButton";

interface AcceptInvitationButtonProps {
  shortLabel?: boolean;
}

export const AcceptInvitationButton = ({ shortLabel }: AcceptInvitationButtonProps) => {
  const { t } = useCollaborationListTranslation();
  const translationLabel = shortLabel ? "accept" : "acceptInvitation";
  return <CollaborationListRowActionButton label={t(translationLabel)} />;
};
