/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Button, styled, Typography } from "@mui/material";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";

interface NoCollaborationRowsLabelAndOptionalProps {
  noCollaborationRowsLabel: string;
  buttonLabel?: string;
  onClick?: () => void;
}

const VerticallyAndHorizontallyCenteredItemsContainer = styled("div")({
  display: "flex",
  position: "relative",
  zIndex: 5,
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
});
export const NoCollaborationRowsLabelAndOptionalAction = (props: NoCollaborationRowsLabelAndOptionalProps) => {
  const { noCollaborationRowsLabel, buttonLabel, onClick } = props;
  if (onClick && buttonLabel) {
    return (
      <VerticallyAndHorizontallyCenteredItemsContainer>
        <Typography fontSize={14}>{noCollaborationRowsLabel},</Typography>
        <Button
          onClick={onClick}
          variant="text"
          sx={{ color: TakeTurnsColors.darkBlue, padding: 0, paddingLeft: "4px", fontWeight: "600" }}
        >
          {buttonLabel}
        </Button>
      </VerticallyAndHorizontallyCenteredItemsContainer>
    );
  }
  return (
    <VerticallyAndHorizontallyCenteredItemsContainer>
      <Typography fontSize={14}>{noCollaborationRowsLabel}</Typography>
    </VerticallyAndHorizontallyCenteredItemsContainer>
  );
};
