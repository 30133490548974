/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql } from "@apollo/client";
import {
  RestoreAttachmentOutput,
  SubscriptionOnRemoveAttachmentArgs,
  SubscriptionOnRestoreAttachmentArgs,
} from "@taketurns/taketurns-graphql-repository";
import { useRefreshableSubscription } from "@taketurns-components/webapp/QueriesAndSubscriptionsRefreshTrigger";
import { restoreAttachmentMutationFragment } from "@taketurns-repositories/collaboration/graphql/mutations/content/attachments/useRestoreAttachment";

const SUBSCRIBE_TO_RESTORE_ATTACHMENT = gql`
  subscription onRestoreAttachment($collaborationId: ID!, $partyId: ID!, $folderId: ID!) {
    onRestoreAttachment(collaborationId: $collaborationId, partyId: $partyId, folderId: $folderId) {
      ...RestoreAttachmentMutationFragment
    }
  }
  ${restoreAttachmentMutationFragment}
`;

export const useSubscribeToRestoreAttachment = (args: SubscriptionOnRemoveAttachmentArgs) => {
  return useRefreshableSubscription<
    { onRestoreAttachment: RestoreAttachmentOutput },
    SubscriptionOnRestoreAttachmentArgs
  >(SUBSCRIBE_TO_RESTORE_ATTACHMENT, { variables: args });
};
