/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useSharedCollaborationTranslation } from "@taketurns-i18n/collaboration/shared/useSharedCollaborationTranslation";
import { useFetchDocumentRevisionDownloadUrl } from "@taketurns-repositories/document/graphql/queries/useFetchDocumentRevisionDownloadUrl";

export const useFetchDocumentRevisionUrlRule = () => {
  const {
    fetchDocumentTurnRevisionDownloadUrl,
    documentRevisionDownloadUrl,
    loadingDocumentRevisionDownloadUrl,
    errorOnLoadingDocumentRevisionDownloadUrl,
  } = useFetchDocumentRevisionDownloadUrl();
  const { t } = useSharedCollaborationTranslation();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (errorOnLoadingDocumentRevisionDownloadUrl) {
      console.error(errorOnLoadingDocumentRevisionDownloadUrl);
      enqueueSnackbar(t("DownloadTurnRevisionButton.error"), { variant: "error" });
    }
  }, [errorOnLoadingDocumentRevisionDownloadUrl]);
  return {
    fetchDocumentTurnRevisionDownloadUrl,
    documentTurnRevisionDownloadUrl: documentRevisionDownloadUrl,
    loadingDocumentRevisionDownloadUrl,
  };
};
