/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Auth } from "aws-amplify";
import { useCallback, useState } from "react";

export const useResendVerificationCodeRule = (email: string) => {
  const [error, setError] = useState<null | unknown>(null);
  const resendVerificationCode = useCallback(async () => {
    try {
      await Auth.resendSignUp(email.toLowerCase());
    } catch (error: unknown) {
      console.log(error);
      setError(error);
    }
  }, [email]);

  return { resendVerificationCode, resendVerificationCodeError: error };
};
