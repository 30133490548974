/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Box, Button, styled, Typography } from "@mui/material";
import { IconArrowLeft, IconFolder } from "@taketurns-components/commons/icons";
import { useBrowseContentRule } from "@taketurns-rules/collaboration/commands/collaborationView/useBrowseContentRule";
import { useIsContentSelectionStateActiveRule } from "@taketurns-rules/collaboration/queries/collaborationView/contentSelection/useIsContentSelectionStateActiveRule";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";

export const CardViewBreadcrumb = (props: { backBreadcrumbLabel: string }) => {
  const { moveBackward, isCurrentFolderRootFolder } = useBrowseContentRule();
  const isContentSelectionActive = useIsContentSelectionStateActiveRule();

  if (isCurrentFolderRootFolder) return null;

  return (
    <CardView__Breadcrumb className="CardView__Breadcrumb">
      <Breadcrumb__BackButton
        disableRipple
        disabled={isContentSelectionActive}
        variant="text"
        startIcon={
          <>
            {!isContentSelectionActive && <IconArrowLeft size={16} />}
            <IconFolder style={{ margin: "0 5px" }} size={16} />
          </>
        }
        onClick={moveBackward}
        data-cy="BreadcrumbBackButton"
      >
        <Typography color={TakeTurnsColors.veryDarkGray} variant="body2">
          {props.backBreadcrumbLabel}
        </Typography>
      </Breadcrumb__BackButton>
    </CardView__Breadcrumb>
  );
};

const CardView__Breadcrumb = styled(Box)({
  minHeight: 24,
  height: 24,
});

const Breadcrumb__BackButton = styled(Button)({
  minWidth: "auto",
  padding: 0,
  paddingLeft: 1,
  color: TakeTurnsColors.darkGray,
  "&:hover": {
    backgroundColor: "unset",
  },
  "& .MuiButton-startIcon": {
    marginRight: "2px",
  },
});
