/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Typography } from "@mui/material";
import { useMessageContext } from "stream-chat-react";
import { isNote } from "@taketurns-components/chat/Chat/Messages/ChatMessage";
import { DisplayedUser } from "@taketurns-components/collaboration/Shared/EventNote/EventNoteUserAndHeadline";
import { PlainTextEventNoteWithReadMoreButton } from "@taketurns-components/collaboration/Shared/EventNote/PlainTextEventNoteWithReadMoreButton";
import { useChatContext } from "@taketurns-repositories/chat/state/context/ChatContext";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { useGetUserColorRule } from "@taketurns-rules/user/queries/useGetUserColorRule";

export const ChatUserMessage = () => {
  const { message } = useMessageContext();
  const { collaborationId } = useChatContext();
  const noteId = isNote(message) && message["tt-note-id"];
  const getUserColor = useGetUserColorRule();

  const displayedUser: DisplayedUser = {
    avatar: message.user.image,
    presentation: message.user.name,
  };

  if (!!noteId) {
    return (
      <PlainTextEventNoteWithReadMoreButton
        collaborationId={collaborationId}
        eventId={noteId}
        note={message.text}
        user={displayedUser}
        color={getUserColor(message.user.id)}
      />
    );
  }

  return (
    <Typography fontSize={"0.875rem"} color={TakeTurnsColors.veryDarkGray} sx={{ wordBreak: "break-word" }}>
      {message.text}
    </Typography>
  );
};
