/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Skeleton, Typography } from "@mui/material";
import { useGetOpenedAttachmentRule } from "@taketurns-rules/collaboration/queries/attachment/useGetOpenedAttachmentRule";
import { useFetchDocumentRule } from "@taketurns-rules/document/queries/useFetchDocumentRule";

export const MobileAttachmentDetailsDocumentName = () => {
  const attachment = useGetOpenedAttachmentRule();

  const { document, errorOnFetchingDocument, loadingDocument } = useFetchDocumentRule(attachment.id);

  if (loadingDocument) {
    return <Skeleton width={"100%"} />;
  }

  if (errorOnFetchingDocument) {
    console.error(errorOnFetchingDocument);
    return <Typography>Something went wrong</Typography>;
  }

  return (
    <Typography variant="h6" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap" fontSize={18}>
      {document?.name}
    </Typography>
  );
};
