/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Button } from "@mui/material";
import { SubscriptionPlanTier } from "@taketurns/taketurns-graphql-repository";
import { useUserPlanTranslation } from "@taketurns-i18n/user/UserPlans/useUserPlanTranslation";
import { useClearSelectedSubscriptionPlanRule } from "@taketurns-rules/user/commands/useClearSelectedSubscriptionPlanRule";
import { useSaveSelectedPlanWithTrialAndRedirectToSignUpRule } from "@taketurns-rules/user/commands/useSaveSelectedPlanWithTrialAndRedirectToSignUpRule";

export const SubscribeToPlanWithTrialWithoutAuthentication = () => {
  const { saveSelectedPlanWithTrialAndRedirectToSignUp } = useSaveSelectedPlanWithTrialAndRedirectToSignUpRule();
  const clearSelectedSubscriptionPlanRule = useClearSelectedSubscriptionPlanRule();
  const { t } = useUserPlanTranslation();
  const saveSelectedPlanWithTrialAndClearSubscriptionPlanThenRedirectToSignUp = () => {
    clearSelectedSubscriptionPlanRule();
    saveSelectedPlanWithTrialAndRedirectToSignUp(SubscriptionPlanTier.T2);
  };

  return (
    <Button color={"secondary"} onClick={saveSelectedPlanWithTrialAndClearSubscriptionPlanThenRedirectToSignUp}>
      {t("buttons.freeTrial")}
    </Button>
  );
};
