/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Button, DialogActions, TextField } from "@mui/material";
import { FormEvent, useRef, useState } from "react";
import { useSharedCollaborationTranslation } from "@taketurns-i18n/collaboration/shared/useSharedCollaborationTranslation";
import { TEXT_FIELD_MAX_LENGTH } from "@taketurns-rules/commons/inputConstants";
import { TakeTurnsDefaultButton } from "../../../commons/button";
import { ResponsiveDialog } from "../../../commons/dialog/ResponsiveDialog";

interface EditFolderDialogProps {
  open: boolean;
  onClose: () => void;
  folderName: string;
  renameFolder: (newFolderName: string) => void;
}

export const EditFolderNameDialog = (props: EditFolderDialogProps) => {
  const { open, onClose, folderName, renameFolder } = props;

  const { t } = useSharedCollaborationTranslation();

  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);
  const folderNameInputRef = useRef<HTMLInputElement | null>(null);

  const saveNewFolderNameAndCloseEditDialog = (event: FormEvent) => {
    event.preventDefault();
    if (folderNameInputRef.current?.value) {
      renameFolder(folderNameInputRef.current?.value);
    }
    onClose();
  };

  const preventForbiddenCharacters = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const forbiddenCharacters = ["/"];
    if (forbiddenCharacters.includes(event.key)) {
      event.preventDefault();
    }
  };

  const disableSubmitButtonIfNoChangeOrNameIsEmpty = () => {
    setSubmitButtonDisabled(!folderNameInputRef.current?.value || folderNameInputRef.current?.value === folderName);
  };

  return (
    <ResponsiveDialog
      fullWidth
      open={open}
      onClose={onClose}
      title={t("contextMenu.folder.editFolderNameDialogTitle")}
      content={
        <form onSubmit={saveNewFolderNameAndCloseEditDialog}>
          <TextField
            autoFocus
            inputProps={{
              maxLength: TEXT_FIELD_MAX_LENGTH,
            }}
            inputRef={folderNameInputRef}
            onKeyDown={preventForbiddenCharacters}
            onChange={disableSubmitButtonIfNoChangeOrNameIsEmpty}
            defaultValue={folderName}
            fullWidth
          />
        </form>
      }
      footer={
        <DialogActions>
          <Button variant={"text"} onClick={onClose}>
            {t("contextMenu.cancel")}
          </Button>
          <TakeTurnsDefaultButton
            disabled={submitButtonDisabled}
            type="submit"
            onClick={saveNewFolderNameAndCloseEditDialog}
          >
            {t("contextMenu.confirm")}
          </TakeTurnsDefaultButton>
        </DialogActions>
      }
    />
  );
};
