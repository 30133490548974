/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Box, Stack, styled, Typography } from "@mui/material";
import { PropsWithChildren, ReactNode } from "react";
import { FetchLoadingManager } from "@taketurns-app/utils/FetchLoadingManager";
import { TakeTurnsSwitch } from "@taketurns-components/commons/switch/TakeTurnsSwitch";
import { useSharedCollaborationTranslation } from "@taketurns-i18n/collaboration/shared/useSharedCollaborationTranslation";
import { useMarkAttachmentAsReviewed } from "@taketurns-repositories/collaboration/graphql/mutations/content/attachments/useMarkAttachmentAsReviewed";
import { useUnmarkAttachmentAsReviewed } from "@taketurns-repositories/collaboration/graphql/mutations/content/attachments/useUnmarkAttachmentAsReviewed";
import { useGetOpenedAttachmentRule } from "@taketurns-rules/collaboration/queries/attachment/useGetOpenedAttachmentRule";
import { useGetDisplayedCollaborationIdRule } from "@taketurns-rules/collaboration/queries/collaboration/useGetDisplayedCollaborationIdRule";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";

interface AttachmentPreviewProps {
  pdfPreviewOptionsSlot?: ReactNode;
}

export const AttachmentPreview = (props: PropsWithChildren<AttachmentPreviewProps>) => {
  const attachment = useGetOpenedAttachmentRule();
  return (
    <Stack className="AttachmentPreview__Container" sx={{ height: "100%", minWidth: 0 }}>
      <Stack
        className="AttachmentPreview__ActionsHeader"
        direction={{ xs: "column", sm: "row" }}
        alignItems="center"
        justifyContent="space-between"
      >
        <Box width={{ xs: "100%", sm: "auto" }}>
          {(attachment.toReview || attachment.markedAsReviewed) && <MarkAsReviewedSwitch />}
        </Box>
        {props.pdfPreviewOptionsSlot && <Stack width={{ xs: "100%", sm: "auto" }}>{props.pdfPreviewOptionsSlot}</Stack>}
      </Stack>
      {props.children}
    </Stack>
  );
};

const MarkAsReviewedSwitch = () => {
  const { t } = useSharedCollaborationTranslation();
  const { markedAsReviewed, toggleAttachmentReviewedState, loading } = useToggleAttachmentReviewedStateRule();

  return (
    <Stack direction="row" height={40}>
      <Typography
        component="div"
        display="inline-flex"
        alignItems="center"
        justifyContent="center"
        bgcolor={TakeTurnsColors.otherParty}
        pl={{ xs: 3, sm: 2 }}
        pr={2}
        height={40}
        color={TakeTurnsColors.white}
        fontSize="0.875rem"
      >
        {t("MarkAsReviewedSwitch.pleaseReview")}
      </Typography>
      <AttachmentPreview__MarkAsReviewedBox>
        <Typography component="span" color={TakeTurnsColors.veryDarkGray} fontSize="0.875rem">
          {t("MarkAsReviewedSwitch.markAsReviewed")}
        </Typography>
        <TakeTurnsSwitch checked={markedAsReviewed} onClick={toggleAttachmentReviewedState} disabled={loading} />
      </AttachmentPreview__MarkAsReviewedBox>
    </Stack>
  );
};

const useToggleAttachmentReviewedStateRule = () => {
  const attachment = useGetOpenedAttachmentRule();
  const collaborationId = useGetDisplayedCollaborationIdRule();
  const { markAttachmentAsReviewedMutation, loadingMarkAttachmentAsReviewed, errorOnMarkingAttachmentAsReviewed } =
    useMarkAttachmentAsReviewed({ collaborationId, attachmentId: attachment.id });
  const {
    unmarkAttachmentAsReviewedMutation,
    loadingUnmarkAttachmentAsReviewed,
    errorOnUnmarkingAttachmentAsReviewed,
  } = useUnmarkAttachmentAsReviewed({ collaborationId, attachmentId: attachment.id });

  const fetchLoadingManager = new FetchLoadingManager(
    [loadingMarkAttachmentAsReviewed, loadingUnmarkAttachmentAsReviewed],
    [errorOnMarkingAttachmentAsReviewed, errorOnUnmarkingAttachmentAsReviewed],
  );

  if (fetchLoadingManager.hasFetchInError()) {
    fetchLoadingManager.logErrors();
  }
  const toggleAttachmentReviewedState = async () => {
    if (attachment.markedAsReviewed) {
      await unmarkAttachmentAsReviewedMutation();
    } else {
      await markAttachmentAsReviewedMutation();
    }
  };
  return {
    markedAsReviewed: attachment.markedAsReviewed,
    toggleAttachmentReviewedState,
    loading: fetchLoadingManager.hasFetchLoading(),
    error: fetchLoadingManager.hasFetchInError(),
  };
};

const AttachmentPreview__MarkAsReviewedBox = styled(Box)(({ theme }) => ({
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "8px",
  border: `1px solid ${TakeTurnsColors.otherParty}`,
  padding: theme.spacing(0, 2),
  height: 40,

  [theme.breakpoints.down("sm")]: {
    flex: 1,
    justifyContent: "space-between",
    padding: theme.spacing(0, 3, 0, 2),
  },
}));
