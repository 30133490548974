/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { gql, useQuery } from "@apollo/client";
import { ConnectedUser } from "@taketurns/taketurns-graphql-repository";

export const useFetchUserProfileIsIncomplete = () => {
  const USER_PROFILE_IS_INCOMPLETE = gql`
    query userProfileIsIncomplete {
      connectedUser {
        id
        firstName
        lastName
        function
      }
    }
  `;

  const {
    data,
    error: errorOnUserHasIncompleteProfile,
    loading: loadingUserHasIncompleteProfile,
  } = useQuery<{
    connectedUser: ConnectedUser;
  }>(USER_PROFILE_IS_INCOMPLETE);

  let userProfileIsIncomplete = false;
  if (data?.connectedUser) {
    const { firstName, lastName, function: userFunction } = data!.connectedUser;
    userProfileIsIncomplete = firstName === null || lastName === null || userFunction === null;
  }

  return {
    userProfileIsIncomplete,
    loadingUserHasIncompleteProfile,
    errorOnUserHasIncompleteProfile,
  };
};
