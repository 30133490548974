/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { IconButton, styled } from "@mui/material";
import { IconX } from "@taketurns-components/commons/icons";
import { useResetContentSelectionStateRule } from "@taketurns-rules/collaboration/commands/collaborationView/contentSelection/useResetContentSelectionStateRule";

export const DisableContentSelectionButton = () => {
  const resetContentSelection = useResetContentSelectionStateRule();

  return (
    <IconButtonWithTransparencyOnHover onClick={resetContentSelection} data-cy="ContentSelection.DisableButton">
      <IconX color="white" size={24} />
    </IconButtonWithTransparencyOnHover>
  );
};

const IconButtonWithTransparencyOnHover = styled(IconButton)({
  "&:hover": {
    opacity: 0.8,
  },
});
