/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useQuery } from "@apollo/client";
import { Collaboration, QueryGetCollaborationArgs } from "@taketurns/taketurns-graphql-repository";

export const COLLABORATION_CONTENT_METADATA_QUERY = gql`
  query collaborationContentMetadata($collaborationId: ID!) {
    getCollaboration(collaborationId: $collaborationId) {
      id
      contentMetadata {
        id
        partyId
        hasPendingUpdates
        attachmentToReviewCount
        requestToFulfillCount
        todoCount
        lastUpdateCount
        hasNoContent
      }
    }
  }
`;
export const useFetchCollaborationContentMetadata = (collaborationId: string) => {
  const { data, error, loading } = useQuery<{ getCollaboration: Collaboration }, QueryGetCollaborationArgs>(
    COLLABORATION_CONTENT_METADATA_QUERY,
    {
      variables: { collaborationId },
    },
  );
  return {
    contentMetadata: data?.getCollaboration?.contentMetadata,
    loading,
    error,
  };
};
