/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useMutation } from "@apollo/client";
import { useGetOrganizationMemberInCache } from "@taketurns-repositories/user/graphql/cache/useGetOrganizationMemberInCache";
import { ModifyOrganizationMemberRoleInput, MutationModifyOrganizationMemberRoleArgs, OrganizationMember } from "@taketurns/taketurns-graphql-repository";

export const useModifyOrganizationMemberRole = () => {
  const getOrganizationMemberInCache = useGetOrganizationMemberInCache();

  const mutation = gql`
    mutation modifyOrganizationMemberRole($modifyOrganizationMemberRoleInput: ModifyOrganizationMemberRoleInput!) {
      modifyOrganizationMemberRole(modifyOrganizationMemberRoleInput: $modifyOrganizationMemberRoleInput) {
        userId
        role
      }
    }
  `;
  const [modifyOrganizationMemberRoleMutation, { error: errorOnModifyMemberRole, loading: loadingModifyMemberRole }] =
    useMutation<{ modifyOrganizationMemberRole: OrganizationMember }, MutationModifyOrganizationMemberRoleArgs>(
      mutation,
    );
  const modifyOrganizationMemberRole = (input: ModifyOrganizationMemberRoleInput) => {
    return modifyOrganizationMemberRoleMutation({
      variables: { modifyOrganizationMemberRoleInput: input },
      optimisticResponse: {
        modifyOrganizationMemberRole: {
          ...getOrganizationMemberInCache(input.userId),
          userId: input.userId,
          role: input.role,
        },
      },
    });
  };
  return { modifyOrganizationMemberRole, errorOnModifyMemberRole, loadingModifyMemberRole };
};
