/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { selector, useRecoilValue } from "recoil";
import { pendingCreationAttachmentsState } from "../../recoil/collaborationCreation/pendingCreationAttachmentsState";

export const selectIsAnyUploadingPendingCreationAttachmentsState = selector({
  key: "selectIsAnyUploadingPendingCreationAttachments",
  get: ({ get }) =>
    get(pendingCreationAttachmentsState).some((pendingCreationAttachment) => pendingCreationAttachment.isUploading()),
});

export const useIsAnyUploadingPendingCreationAttachments = () => {
  return useRecoilValue(selectIsAnyUploadingPendingCreationAttachmentsState);
};
