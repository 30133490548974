/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useMutation } from "@apollo/client";
import { Collaboration, MutationCloseCollaborationArgs } from "@taketurns/taketurns-graphql-repository";

export const useCloseCollaboration = () => {
  const mutation = gql`
    mutation closeCollaboration($input: CloseCollaborationInput!) {
      closeCollaboration(input: $input) {
        id
        name
        closingDate
        status
        contentMetadata {
          id
          hasPendingUpdates
        }
      }
    }
  `;
  return useMutation<{ closeCollaboration: Collaboration }, MutationCloseCollaborationArgs>(mutation);
};
