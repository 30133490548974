/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { gql, useMutation } from "@apollo/client";
import { Organization } from "@taketurns/taketurns-graphql-repository";
import { wait } from "@taketurns-rules/commons/util/wait";
import { USER_ORGANIZATION } from "../queries/useFetchUserOrganization";
import { USER_PENDING_ORGANIZATION_INVITATION } from "../queries/useFetchUserPendingOrganizationInvitation";

export const useAcceptOrganizationInvitation = () => {
  const ACCEPT_ORGANIZATION_INVITATION = gql`
    mutation acceptOrganizationInvitation($organizationId: ID!) {
      acceptOrganizationInvitation(organizationId: $organizationId) {
        id
        name
        invitations {
          userId
          email
          role
          user {
            id
            email
            lastName
            firstName
            avatar
          }
        }
        members {
          role
          userId
          user {
            id
            email
            lastName
            firstName
            avatar
          }
        }
      }
    }
  `;
  const [
    acceptOrganizationInvitationMutation,
    { error: errorOnAcceptOrganizationInvitation, loading: loadingAcceptOrganizationInvitation },
  ] = useMutation<{
    acceptOrganizationInvitation: Organization;
  }>(ACCEPT_ORGANIZATION_INVITATION);

  const acceptOrganizationInvitation = (organizationId: string) => {
    return acceptOrganizationInvitationMutation({
      variables: { organizationId },
      refetchQueries: [USER_PENDING_ORGANIZATION_INVITATION, USER_ORGANIZATION],
      onQueryUpdated: (observableQuery) => {
        wait(3000).then(() => observableQuery.refetch());
      },
    });
  };

  return {
    acceptOrganizationInvitation,
    errorOnAcceptOrganizationInvitation,
    loadingAcceptOrganizationInvitation,
  };
};
