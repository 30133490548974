/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useQuery } from "@apollo/client";
import { Revision } from "@taketurns/taketurns-graphql-repository";

export const useFetchDocumentRevisionUser = (collaborationId: string, documentId: string, revisionId: string) => {
  const DOCUMENT_REVISION_USER = gql`
    query documentRevisionUser($collaborationId: ID!, $documentId: ID!, $revisionId: ID!) {
      documentRevision(collaborationId: $collaborationId, documentId: $documentId, revisionId: $revisionId) {
        revisionId
        uploadingUserId
        uploadingUser {
          firstName
          lastName
          avatar
          email
        }
      }
    }
  `;

  const {
    data,
    loading: loadingDocumentRevisionUser,
    error: errorOnDocumentRevisionUser,
  } = useQuery<{ documentRevision: Revision }>(DOCUMENT_REVISION_USER, {
    variables: {
      collaborationId,
      documentId,
      revisionId,
    },
  });
  return {
    documentRevisionUser: data?.documentRevision?.uploadingUser,
    loadingDocumentRevisionUser,
    errorOnDocumentRevisionUser,
  };
};
