/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useCallback } from "react";
import { Folder } from "@taketurns/taketurns-graphql-repository";
import { useMoveFolderIntoFolderRule } from "@taketurns-rules/collaboration/commands/collaborationView/folder/useMoveFolderIntoFolderRule";
import { MoveDestinationDialogInCollaborationView } from "./MoveDestinationDialogInCollaborationView";

interface FolderMoveDestinationDialogInCollaborationViewProps {
  folder: Folder;
  open: boolean;
  onClose: () => void;
}

export const FolderMoveDestinationDialogInCollaborationView = ({
  folder,
  open,
  onClose,
}: FolderMoveDestinationDialogInCollaborationViewProps) => {
  const moveFolderIntoFolder = useMoveFolderIntoFolderRule(folder.id);
  const moveFolderIntoFolderAndClose = useCallback(
    async (destinationFolderPath: string) => {
      await moveFolderIntoFolder(destinationFolderPath);
      onClose();
    },
    [moveFolderIntoFolder, onClose],
  );
  return (
    <MoveDestinationDialogInCollaborationView
      excludedFolderId={folder.id}
      onSubmitDestination={moveFolderIntoFolderAndClose}
      onClose={onClose}
      open={open}
    />
  );
};
