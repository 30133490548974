/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Outlet, RouteObject } from "react-router-dom";
import { CollaborationCreation } from "@taketurns-app/pages/authenticated/CollaborationCreation/CollaborationCreation";
import { CollaborationRecapPage } from "@taketurns-app/pages/authenticated/CollaborationRecap/CollaborationRecapPage";
import { QueriesAndSubscriptionsRefreshTrigger } from "@taketurns-components/webapp/QueriesAndSubscriptionsRefreshTrigger";
import { CollaborationCreationProvider } from "../../../components/collaboration/CollaborationCreation/CollaborationCreationProvider";
import { AuthenticatedLayout } from "../../layouts/authenticated/AuthenticatedLayout/AuthenticatedLayout";
import { Checkout } from "../../pages/authenticated/Checkout";
import { CollaborationInvitationEmailMismatch } from "../../pages/authenticated/CollaborationInvitationEmailMismatch";
import { CollaborationListPage } from "../../pages/authenticated/CollaborationListPage";
import { CollaborationView } from "../../pages/authenticated/CollaborationView/CollaborationView";
import { CompleteProfile } from "../../pages/authenticated/CompleteProfile";
import { OrganizationInvitationEmailMismatchPage } from "../../pages/authenticated/OrganizationInvitationEmailMismatchPage";
import { Plans } from "../../pages/authenticated/Plans";
import { SimpleFileRequestCreation } from "../../pages/authenticated/SimpleFileRequestCreation";
import { SimpleFileSharingCreation } from "../../pages/authenticated/SimpleFileSharingCreation";
import { UserSettingsPage } from "../../pages/authenticated/UserSettings/UserSettingsPage";
import { AcceptInvitationToCollaborationGuard } from "../guards/AcceptInvitationToCollaborationGuard";
import { AcceptOrganizationInvitationGuard } from "../guards/AcceptOrganizationInvitationGuard";
import { AuthGuard } from "../guards/AuthGuard";
import { CreateCollaborationFeatureGuard } from "../guards/CreateCollaborationFeatureGuard";
import { ExcludedFromCollaborationGuard } from "../guards/ExcludedFromCollaborationGuard";
import { InvitationToCollaborationEmailMismatchGuard } from "../guards/InvitationToCollaborationEmailMismatchGuard";
import { PersistMarketingDataGuard } from "../guards/marketingData/PersistMarketingDataGuard";
import { RedirectToPlansIfFirstAuthGuard } from "../guards/RedirectToPlansIfFirstAuthGuard";
import { SubscribeGuard } from "../guards/SubscribeGuard";
import { SubscribeWithTrialGuard } from "../guards/SubscribeWithTrialGuard";
import { AUTHENTICATED_ROUTES } from "./authenticatedRoutes.constants";

const CommonsAuthenticatedGuards = () => {
  return (
    <AuthGuard>
      <SubscribeGuard>
        <SubscribeWithTrialGuard>
          <Outlet />
          <QueriesAndSubscriptionsRefreshTrigger />
        </SubscribeWithTrialGuard>
      </SubscribeGuard>
    </AuthGuard>
  );
};

const PersistMarketingDataGuardAndAuthenticatedLayout = () => (
  <PersistMarketingDataGuard>
    <AuthenticatedLayout />
  </PersistMarketingDataGuard>
);

const completeProfileRoute: RouteObject = {
  path: AUTHENTICATED_ROUTES.COMPLETE_PROFILE,
  element: <CompleteProfile />,
};
const checkoutRoute: RouteObject = {
  path: AUTHENTICATED_ROUTES.CHECKOUT,
  element: <Checkout />,
};
const plansRoute: RouteObject = {
  path: AUTHENTICATED_ROUTES.PLANS,
  element: <Plans />,
};

const createFullCollaborationRoute: RouteObject = {
  path: AUTHENTICATED_ROUTES.COLLABORATION_CREATE.FULL,
  element: <CollaborationCreation />,
};

const createSimpleFileSharingCollaborationRoute: RouteObject = {
  path: AUTHENTICATED_ROUTES.COLLABORATION_CREATE.SIMPLE_FILE_SHARING,
  element: <SimpleFileSharingCreation />,
};

const createSimpleFileRequestCollaborationRoute: RouteObject = {
  path: AUTHENTICATED_ROUTES.COLLABORATION_CREATE.SIMPLE_FILE_REQUEST,
  element: <SimpleFileRequestCreation />,
};

const createCollaborationRoute: RouteObject = {
  element: (
    <CreateCollaborationFeatureGuard>
      <CollaborationCreationProvider>
        <Outlet />
      </CollaborationCreationProvider>
    </CreateCollaborationFeatureGuard>
  ),
  children: [
    createSimpleFileSharingCollaborationRoute,
    createFullCollaborationRoute,
    createSimpleFileRequestCollaborationRoute,
  ],
};
const collaborationListRoute: RouteObject = {
  path: AUTHENTICATED_ROUTES.COLLABORATION_LIST,
  element: <CollaborationListPage />,
};
const invitationToCollaborationEmailMismatchRoute: RouteObject = {
  path: AUTHENTICATED_ROUTES.COLLABORATION_INVITATION_EMAIL_MISMATCH,
  element: <CollaborationInvitationEmailMismatch />,
};
const collaborationViewRoute: RouteObject = {
  loader: async ({ params }) => ({
    collaborationId: params.collaborationId,
  }),
  path: AUTHENTICATED_ROUTES.COLLABORATION_VIEW,
  element: (
    <ExcludedFromCollaborationGuard>
      <InvitationToCollaborationEmailMismatchGuard>
        <AcceptInvitationToCollaborationGuard>
          <CollaborationView />
        </AcceptInvitationToCollaborationGuard>
      </InvitationToCollaborationEmailMismatchGuard>
    </ExcludedFromCollaborationGuard>
  ),
};
const collaborationRecapRoute: RouteObject = {
  loader: async ({ params }) => ({
    collaborationId: params.collaborationId,
  }),
  path: AUTHENTICATED_ROUTES.COLLABORATION_RECAP,
  element: <CollaborationRecapPage />,
};
const userSettingsRoute: RouteObject = {
  path: AUTHENTICATED_ROUTES.USER_SETTINGS,
  element: <UserSettingsPage />,
};
const invitationToOrganizationEmailMismatchRoute: RouteObject = {
  path: AUTHENTICATED_ROUTES.ORGANIZATION_INVITATION_EMAIL_MISMATCH,
  element: <OrganizationInvitationEmailMismatchPage />,
};

export const authenticatedRoutes: RouteObject[] = [
  {
    element: <CommonsAuthenticatedGuards />,
    children: [
      {
        element: <PersistMarketingDataGuardAndAuthenticatedLayout />,
        children: [completeProfileRoute, checkoutRoute, plansRoute],
      },
      {
        element: <RedirectToPlansIfFirstAuthGuard />,
        children: [
          {
            element: (
              <AcceptOrganizationInvitationGuard>
                <PersistMarketingDataGuardAndAuthenticatedLayout />
              </AcceptOrganizationInvitationGuard>
            ),
            children: [
              createCollaborationRoute,
              collaborationListRoute,
              invitationToCollaborationEmailMismatchRoute,
              collaborationViewRoute,
              collaborationRecapRoute,
              userSettingsRoute,
            ],
          },
          {
            element: <PersistMarketingDataGuardAndAuthenticatedLayout />,
            children: [invitationToOrganizationEmailMismatchRoute],
          },
        ],
      },
    ],
  },
];
