/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { atom } from "recoil";
import { SubscriptionPlanCurrency } from "@taketurns/taketurns-graphql-repository";

export const subscriptionPlanCurrencyState = atom<SubscriptionPlanCurrency>({
  key: "subscriptionPlanCurrency",
  default: SubscriptionPlanCurrency.Usd,
});
