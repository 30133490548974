/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { DialogTitle, Stack, Typography } from "@mui/material";
import { UserTip } from "@taketurns-components/user/UserTip/UserTip";
import { useCollaborationTranslation } from "@taketurns-i18n/collaboration/useCollaborationTranslation";
import { TipKey } from "@taketurns-i18n/commons/en/tips";
import { useIsOnMobileRule } from "@taketurns-rules/commons/theme/useIsOnMobileRule";

interface DialogTitleProps {
  title: string;
  tipKey?: TipKey;
}

export const DefaultDialogTitle = (props: DialogTitleProps) => {
  const isOnMobile = useIsOnMobileRule();
  const { t: tips } = useCollaborationTranslation("tips");
  return (
    <DialogTitle>
      <Stack direction="row" spacing={1} alignItems={"center"}>
        <Typography variant="h6" flex={1} textAlign={{ xs: "center", md: "start" }}>
          {props.title}
        </Typography>
        {props.tipKey && !isOnMobile && (
          <UserTip style={{ alignSelf: "end" }} message={tips(props.tipKey)} tipKey={props.tipKey} />
        )}
      </Stack>
    </DialogTitle>
  );
};
