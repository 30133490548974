/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useFetchCollaborationFeatures } from "@taketurns-repositories/collaboration/graphql/queries/features/useFetchCollaborationFeatures";

export const useFetchCollaborationDefaultGracePeriod = (collaborationId: string) => {
  const {
    collaborationFeatures,
    loadingCollaborationFileUploadFeatures,
    errorOnFetchingCollaborationFileUploadFeatures,
  } = useFetchCollaborationFeatures(collaborationId);

  return {
    defaultGracePeriodInDays: collaborationFeatures?.defaultGracePeriodInDays ?? 7,
    loadingDefaultGracePeriod: loadingCollaborationFileUploadFeatures,
    errorOnFetchingDefaultGracePeriod: errorOnFetchingCollaborationFileUploadFeatures,
  };
};
