/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { selector } from "recoil";
import { PendingAttachment } from "@taketurns-repositories/collaboration/models/pendingAttachment";
import { collaborationIdsLinkedToPendingAttachmentsState } from "./collaborationIdsLinkedToPendingAttachmentsState";
import { pendingAttachmentsByCollaborationIdState } from "./pendingAttachmentsByCollaborationIdState";

export const selectAllPendingAttachmentsState = selector<PendingAttachment[]>({
  key: "selectAllPendingAttachments",
  get: ({ get }) => {
    const allPendingAttachments: PendingAttachment[] = [];
    for (const collaborationId of get(collaborationIdsLinkedToPendingAttachmentsState)) {
      allPendingAttachments.push(...get(pendingAttachmentsByCollaborationIdState(collaborationId)));
    }
    return allPendingAttachments;
  },
});
