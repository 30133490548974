/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql } from "@apollo/client";
import {
  MarkUnmarkAsReviewedAttachmentOutput,
  SubscriptionOnMarkUnmarkAsReviewedAttachmentArgs,
} from "@taketurns/taketurns-graphql-repository";
import { useRefreshableSubscription } from "@taketurns-components/webapp/QueriesAndSubscriptionsRefreshTrigger";
import { markUnmarkAttachmentAsReviewedFragment } from "@taketurns-repositories/collaboration/graphql/mutations/content/attachments/useMarkAttachmentAsReviewed";

const SUBSCRIBE_TO_MARK_UNMARK_AS_REVIEWED_ATTACHMENT = gql`
  subscription onMarkUnmarkAsReviewedAttachment($collaborationId: ID!, $partyId: ID!, $attachmentId: ID!) {
    onMarkUnmarkAsReviewedAttachment(
      collaborationId: $collaborationId
      partyId: $partyId
      attachmentId: $attachmentId
    ) {
      ...MarkUnmarkAttachmentAsReviewedFragment
    }
  }
  ${markUnmarkAttachmentAsReviewedFragment}
`;

export const useSubscribeToMarkUnmarkAsReviewedAttachment = (
  args: SubscriptionOnMarkUnmarkAsReviewedAttachmentArgs,
) => {
  return useRefreshableSubscription<
    { onMarkUnmarkAttachmentAsReviewed: MarkUnmarkAsReviewedAttachmentOutput },
    SubscriptionOnMarkUnmarkAsReviewedAttachmentArgs
  >(SUBSCRIBE_TO_MARK_UNMARK_AS_REVIEWED_ATTACHMENT, { variables: args });
};
