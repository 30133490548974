/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Stack, Typography } from "@mui/material";
import { IconExclamationCircle } from "@taketurns-components/commons/icons";
import { useSharedCollaborationTranslation } from "@taketurns-i18n/collaboration/shared/useSharedCollaborationTranslation";
import { useFetchOtherPartyNameRule } from "@taketurns-rules/collaboration/queries/context/useFetchOtherPartyNameRule";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { useIsOnMobileRule } from "@taketurns-rules/commons/theme/useIsOnMobileRule";

export const SendUpdateButtonWarnMessage = () => {
  const { t } = useSharedCollaborationTranslation();
  const isOnMobile = useIsOnMobileRule();
  const { otherPartyName } = useFetchOtherPartyNameRule();
  const warnMessage = isOnMobile
    ? t("SendUpdates.warnMessageShort")
    : t(`SendUpdates.warnMessage`, { name: otherPartyName });
  return (
    <Stack direction="row" gap={1} flex={1} alignItems="center">
      <IconExclamationCircle style={{ flex: "0 0 auto" }} color={TakeTurnsColors.yellow} size={24} strokeWidth={0.4} />
      <Typography
        fontSize={{ xs: 14, md: 16 }}
        color={{ xs: TakeTurnsColors.veryDarkGray, md: "black" }}
        lineHeight={"1.8rem"}
      >
        {warnMessage}
      </Typography>
    </Stack>
  );
};
