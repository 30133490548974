/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
export const IMAGE_FORMAT = ["svg", "png", "jpg", "jpeg", "jfif", "pjpeg", "pjp", "ico", "cur", "gif", "apng", "webp"];
export const PDF_FORMAT = ["pdf"];

// prettier-ignore
const FORMAT_SUPPORTED_FOR_PDF_PREVIEW = [
  "pdf", "bib", "doc", "docx", "fodt", "ltx", "txt", "odt", "ott", "pdb", "psw", "rtf", "sdw", "stw", "sxw", "uot", "vor", "wps", "epub", "bmp", "emf", "eps", "fodg", "met",
  "odd", "otg", "pbm", "pct", "pgm", "ppm", "ras", "std", "svg", "svm", "swf", "sxd", "tiff", "xpm", "fodp", "potm", "pot", "pptx", "pps", "ppt", "pwp", "sda", "sdd", "sti",
  "sxi", "uop", "wmf", "csv", "dbf", "dif", "fods", "ods", "ots", "pxl", "sdc", "slk", "stc", "sxc", "uos", "xls", "xlt", "xlsx", "tif", "odp", "odg", "dotx", "xltx",
];

export const useIsExtensionSupportedForPreview = (extension: string) => {
  return (
    IMAGE_FORMAT.includes(extension?.toLowerCase()) ||
    FORMAT_SUPPORTED_FOR_PDF_PREVIEW.includes(extension?.toLowerCase())
  );
};
