/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Attachment } from "@taketurns/taketurns-graphql-repository";
import { PendingAttachmentRevision } from "@taketurns-repositories/collaboration/models/pendingAttachmentRevision";
import { useStorePendingAttachmentRevision } from "@taketurns-repositories/collaboration/state/write/useStorePendingAttachmentRevision";
import { Upload } from "@taketurns-repositories/document/state/models/upload";
import { useStoreNewUpload } from "@taketurns-repositories/document/state/write/useStoreNewUpload";
import { useGetDisplayedCollaborationIdRule } from "@taketurns-rules/collaboration/queries/collaboration/useGetDisplayedCollaborationIdRule";
import { useFetchDocumentRule } from "@taketurns-rules/document/queries/useFetchDocumentRule";
import { useCheckAvailableSpaceAndNotifyInExistingCollaborationRule } from "../collaborationView/useCheckAvailableSpaceAndNotifyInExistingCollaborationRule";
import { useFilterFilesTooBigAndNotifyInExistingCollaborationRule } from "../collaborationView/useFilterFilesTooBigAndNotifyInExistingCollaborationRule";

export const useUploadAttachmentRevisionRule = (attachment: Attachment) => {
  const storePendingAttachmentRevision = useStorePendingAttachmentRevision();
  const storeNewUpload = useStoreNewUpload();
  const collaborationId = useGetDisplayedCollaborationIdRule();
  const filterFilesTooBigAndNotify = useFilterFilesTooBigAndNotifyInExistingCollaborationRule();
  const checkAvailableSpaceAndNotify = useCheckAvailableSpaceAndNotifyInExistingCollaborationRule();
  const { document } = useFetchDocumentRule(attachment.id);

  return async (file: File) => {
    const filesToUpload = filterFilesTooBigAndNotify([file]);
    if (!filesToUpload.length) return;
    const hasEnoughSpaceAvailable = await checkAvailableSpaceAndNotify(filesToUpload);
    if (!hasEnoughSpaceAvailable) return;

    const upload: Upload = storeNewUpload(file, attachment.id);
    const fileExtension = file.name.substring(file.name.lastIndexOf(".") + 1);
    const pendingAttachmentRevision: PendingAttachmentRevision = new PendingAttachmentRevision(
      attachment.id,
      collaborationId,
      document.name,
      fileExtension,
      file.size,
      upload.uploadId,
    );
    storePendingAttachmentRevision(pendingAttachmentRevision);
  };
};
