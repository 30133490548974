/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import {
  useGetUnreadMessageCountByCollaborationIdRule,
} from "@taketurns-rules/chat/queries/useGetUnreadMessageByCollaborationIdRule";
import {
  useFetchConnectedUserStatusRule,
} from "@taketurns-rules/collaboration/queries/context/useFetchConnectedUserStatusRule";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { IconMessageCircle } from "../../commons/icons";
import { UnreadMessagesBadge } from "./UnreadMessagesBadge";

export const ChatBubbleWithUnreadMessageBadge = ({ collaborationId }: { collaborationId: string }) => {
  const { unreadCount } = useGetUnreadMessageCountByCollaborationIdRule(collaborationId);
  const { isParticipant, loadingConnectedUserStatus } = useFetchConnectedUserStatusRule(collaborationId);

  const noIndicatorToDisplay = !unreadCount || unreadCount === 0 || loadingConnectedUserStatus || !isParticipant;

  if (noIndicatorToDisplay) {
    return null;
  }

  return (
    <UnreadMessagesBadge unreadCount={unreadCount}>
      <IconMessageCircle size={24} color={TakeTurnsColors.grayButton} />
    </UnreadMessagesBadge>
  );
};
