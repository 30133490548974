/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useHavePlansBeenSeen } from "@taketurns-repositories/webapp/localStorage/read/useHavePlansBeenSeen";
import { useIsComingFromInvitationToCollaborationLink } from "./useIsComingFromInvitationToCollaborationLink";
import { useIsComingFromInvitationToOrganizationLink } from "./useIsComingFromInvitationToOrganizationLink";
import { useIsUserFirstVisitRule } from "./useIsUserFirstVisitRule";

export const useShouldDisplayPlansRule = () => {
  const hasPlanBeenSeen = useHavePlansBeenSeen();
  const isUserFirstAuth = useIsUserFirstVisitRule();
  const isComingFromInvitationToCollaborationLink = useIsComingFromInvitationToCollaborationLink();
  const isComingFromInvitationToOrganizationLink = useIsComingFromInvitationToOrganizationLink();

  return (
    !isComingFromInvitationToCollaborationLink &&
    !hasPlanBeenSeen &&
    isUserFirstAuth &&
    !isComingFromInvitationToOrganizationLink
  );
};
