/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { MenuItem, Select, SelectChangeEvent, SxProps, Theme } from "@mui/material";
import { useState } from "react";
import jobFunctionsTranslations from "@taketurns-i18n/commons/jobFunctions.json";
import { useCommonsTranslation } from "@taketurns-i18n/commons/useCommonsTranslation";
import i18n from "@taketurns-i18n/i18n";
import { JobFunction } from "@taketurns-rules/commons/enum/jobFunctionEnum";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { IconChevronDown } from "../icons";

interface JobFunctionSelectorProps {
  sx?: SxProps<Theme>;
  error?: boolean;
  defaultValue?: JobFunction;
  onChange: (jobFunction: JobFunction) => void;
  errorOnRequired?: boolean;
}

export const JobFunctionSelector = (props: JobFunctionSelectorProps) => {
  const { defaultValue, sx, onChange, errorOnRequired, error } = props;
  const [selectedFunction, setSelectedFunction] = useState<JobFunction | "">(defaultValue ?? "");
  const language = i18n.language;

  const translations: { [language: string]: { [jobFunction: string]: string } } = jobFunctionsTranslations;
  const Value = () => {
    const { t } = useCommonsTranslation("form");
    if (errorOnRequired) {
      return <span style={{ color: TakeTurnsColors.error }}>{t("required")}</span>;
    }
    if (!selectedFunction) {
      return (
        <span style={{ color: TakeTurnsColors.placeholder }}>{`${translations[language]["SELECT_FUNCTION"]} *`}</span>
      );
    }
    const index = Object.values(JobFunction).indexOf(selectedFunction as JobFunction);
    return <span>{translations[language][Object.keys(JobFunction)[index]]}</span>;
  };
  return (
    <Select
      autoWidth
      displayEmpty
      data-cy="jobFunctionSelector"
      sx={sx}
      error={error}
      defaultValue={selectedFunction}
      onChange={(event: SelectChangeEvent<JobFunction | "">) => {
        setSelectedFunction(event.target.value as JobFunction);
        onChange(event.target.value as JobFunction);
      }}
      renderValue={() => <Value />}
      IconComponent={(iconProps) => <IconChevronDown size={16} color={TakeTurnsColors.darkGray} {...iconProps} />}
    >
      <MenuItem disabled sx={{ color: TakeTurnsColors.darkGray }}>
        {translations[language]["SELECT_FUNCTION"]}
      </MenuItem>
      {Object.entries(JobFunction).map(([key, value]) => (
        <MenuItem key={key} value={value} data-cy={`jobFunctionSelector.${key}`}>
          {translations[language][key]}
        </MenuItem>
      ))}
    </Select>
  );
};
