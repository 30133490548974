/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { CollaborationStatus } from "@taketurns/taketurns-graphql-repository";
import { useCollaborationTranslation } from "@taketurns-i18n/collaboration/useCollaborationTranslation";
import { useGetDisplayedCollaborationIdRule } from "@taketurns-rules/collaboration/queries/collaboration/useGetDisplayedCollaborationIdRule";
import { useFetchCollaborationStatusRule } from "@taketurns-rules/collaboration/queries/fields/useFetchCollaborationStatusRule";
import { useFetchConnectedUserIdAndEmailRule } from "@taketurns-rules/user/queries/useFetchConnectedUserIdAndEmailRule";
import { useGetCollaborationFormInvitationsRule } from "./collaborationCreation/form/useGetCollaborationFormInvitationsRule";
import { useFetchParticipantsAndInvitationsRule } from "./collaborationView/useFetchParticipantsAndInvitationsRule";

export const useNewEmailAlreadyUsedRule = (newEmail: string) => {
  const { t } = useCollaborationTranslation("notifications");
  const isUserEmail = useIsUserEmail(newEmail);
  const isNewEmailAlreadyInInvitationsInCreation = useIsNewEmailAlreadyInInvitationsInCreation(newEmail);
  const isNewEmailAlreadyInInvitationsInCollaborationView =
    useIsNewEmailAlreadyInInvitationsInCollaborationView(newEmail);

  if (isUserEmail) {
    return {
      errorMessage: t("collaboration.userEmailAlreadyUsed"),
      emailAlreadyUsed: true,
    };
  }

  if (isNewEmailAlreadyInInvitationsInCreation || isNewEmailAlreadyInInvitationsInCollaborationView) {
    return {
      errorMessage: t("collaboration.invitationEmailAlreadyUsed"),
      emailAlreadyUsed: true,
    };
  }

  return {
    emailAlreadyUsed: false,
  };
};

function useIsUserEmail(newEmail: string): boolean {
  const { connectedUserEmail } = useFetchConnectedUserIdAndEmailRule();
  return connectedUserEmail?.toUpperCase() === newEmail.toUpperCase();
}

function useIsNewEmailAlreadyInInvitationsInCreation(newEmail: string): boolean {
  const emailList = useGetCollaborationFormInvitationsRule().map((invitation) => invitation.email);
  return isNewEmailAlreadyInList({ emailList, newEmail });
}

function isNewEmailAlreadyInList({ emailList, newEmail }: { emailList: string[]; newEmail: string }): boolean {
  return emailList.some((usedEmail) => usedEmail?.toUpperCase() === newEmail.toUpperCase());
}

function useIsNewEmailAlreadyInInvitationsInCollaborationView(newEmail: string): boolean {
  const { status } = useFetchCollaborationStatusRule();
  const collaborationId = useGetDisplayedCollaborationIdRule();
  const { invitations } = useFetchParticipantsAndInvitationsRule(collaborationId, true);
  if (status !== CollaborationStatus.Open) {
    return;
  }
  return isNewEmailAlreadyInList({
    emailList: invitations.map((i) => i.email),
    newEmail,
  });
}
