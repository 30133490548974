/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { gql } from "@apollo/client";
import { ParticipantsAndInvitationsChanged } from "@taketurns/taketurns-graphql-repository";
import { useRefreshableSubscription } from "@taketurns-components/webapp/QueriesAndSubscriptionsRefreshTrigger";

const ON_REMOVED_PARTICIPANT_FROM_COLLABORATION = gql`
  subscription onRemovedParticipantFromCollaboration($collaborationId: ID!) {
    onRemovedParticipantFromCollaboration(id: $collaborationId) {
      id
      userId
    }
  }
`;

export const useOnRemovedParticipantFromCollaboration = (collaborationId: string, userId?: string) => {
  const { data } = useRefreshableSubscription<{
    onRemovedParticipantFromCollaboration: ParticipantsAndInvitationsChanged;
  }>(ON_REMOVED_PARTICIPANT_FROM_COLLABORATION, {
    variables: { collaborationId },
    skip: !userId,
  });

  let isExcluded = false;
  const removedUserId = data?.onRemovedParticipantFromCollaboration?.userId;
  if (removedUserId && userId) {
    isExcluded = removedUserId === userId;
  }
  return { isExcluded };
};
