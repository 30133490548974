/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useRecoilValue } from "recoil";
import { attachmentBulksState } from "../recoil/attachmentBulks";

export const useGetAttachmentBulkByUploadId = () => {
  const attachmentBulkStateValue = useRecoilValue(attachmentBulksState);
  return (uploadId: number) => {
    return attachmentBulkStateValue.find((attachmentBulk) => attachmentBulk.uploadIds.includes(uploadId as number));
  };
};
