/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Box, styled } from "@mui/material";
import { forwardRef, Fragment } from "react";
import { ContentStatus as ContentStatusEnum, Request } from "@taketurns/taketurns-graphql-repository";
import { ContentElementLastUpdateInCard } from "@taketurns-components/collaboration/CollaborationView/CollaborationContent/shared/components/ContentElementLastUpdateInCard";
import { RequestMoveDestinationDialogInCollaborationView } from "@taketurns-components/collaboration/CollaborationView/MoveDestinationDialog/RequestMoveDestinationDialogInCollaborationView";
import { WithContextMenu } from "@taketurns-components/collaboration/ContextMenu/WithContextMenu";
import { EditRequestDialog } from "@taketurns-components/collaboration/Shared/content/EditRequestDialog";
import {
  RequestIconMarker,
  RequestUpdateMarker,
  RequestWordMarker,
} from "@taketurns-components/collaboration/Shared/content/Markers";
import { useModifyRequestDescriptionRule } from "@taketurns-rules/collaboration/commands/collaborationView/request/useModifyRequestDescriptionRule";
import { useRenameRequestRule } from "@taketurns-rules/collaboration/commands/collaborationView/request/useRenameRequestRule";
import { useMoveDestinationDialogRule } from "@taketurns-rules/collaboration/commands/useMoveDestinationDialogRule";
import { useIsContentSelectionStateActiveRule } from "@taketurns-rules/collaboration/queries/collaborationView/contentSelection/useIsContentSelectionStateActiveRule";
import { useGetRequestRule } from "@taketurns-rules/collaboration/queries/collaborationView/useGetRequestRule";
import { useCanConnectedUserEditRequestRule } from "@taketurns-rules/collaboration/queries/permissions/useCanConnectedUserEditRequestRule";
import { useGetRequestContextMenuConfigurationRule } from "@taketurns-rules/collaboration/queries/request/useGetRequestContextMenuConfigurationRule";
import { useShouldDisplayUpdateMarkerForRequestRule } from "@taketurns-rules/collaboration/queries/request/useShouldDisplayUpdateMarkerForRequestRule";
import { useEditDialogRule } from "@taketurns-rules/collaboration/queries/useEditDialogRule";
import { useSubscribeToEditRequestRule } from "@taketurns-rules/collaboration/subscriptions/useSubscribeToEditRequestRule";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { CardTitle } from "../shared/components/CardTitle";
import { TakeTurnsCard } from "../shared/style/TakeTurnsCard";
import { TakeTurnsCardContent } from "../shared/style/TakeTurnsCardContent";
import { TakeTurnsCardFooter } from "../shared/style/TakeTurnsCardFooter";

interface RequestCardProps {
  requestId: string;
  editable: boolean;
}

const hasRequestBeenRemoved = (request: Request) =>
  request.status === ContentStatusEnum.Removed || request.prevStatus === ContentStatusEnum.Removed;

export const RequestCard = ({ requestId, editable }: RequestCardProps) => {
  const request = useGetRequestRule(requestId);
  useSubscribeToEditRequestRule(requestId);
  const { modifyRequestDescription } = useModifyRequestDescriptionRule(request.id);
  const canConnectedUserEditRequest = useCanConnectedUserEditRequestRule();

  const { renameRequest } = useRenameRequestRule(request.id);
  const { closeMoveDestinationDialog, isMoveDestinationDialogOpen, openMoveDestinationDialog } =
    useMoveDestinationDialogRule();
  const { isEditDialogOpened, openEditDialog, closeEditDialog } = useEditDialogRule();
  const requestContextMenuConfiguration = useGetRequestContextMenuConfigurationRule(
    request.id,
    openEditDialog,
    openMoveDestinationDialog,
  );

  const isContentSelectionActive = useIsContentSelectionStateActiveRule();

  if (
    !isContentSelectionActive &&
    !hasRequestBeenRemoved(request) &&
    editable &&
    canConnectedUserEditRequest(request)
  ) {
    const CardWithContextMenu = WithContextMenu<RequestCardProps>(Card, requestContextMenuConfiguration);
    return (
      <Fragment>
        <CardWithContextMenu requestId={request.id} editable={editable} />
        <EditRequestDialog
          open={isEditDialogOpened}
          onClose={closeEditDialog}
          requestName={request.name}
          requestDescription={request.description as string}
          modifyRequestDescription={modifyRequestDescription}
          changeRequestName={renameRequest}
        />
        <RequestMoveDestinationDialogInCollaborationView
          requestId={request.id}
          open={isMoveDestinationDialogOpen}
          onClose={closeMoveDestinationDialog}
        />
      </Fragment>
    );
  }

  return <Card requestId={request.id} />;
};

interface InnerCardProps {
  requestId: string;
}
const Card = forwardRef<HTMLDivElement | null, InnerCardProps>((props, ref) => {
  const request = useGetRequestRule(props.requestId);
  const shouldDisplayLastUpdatesMarker = request.prevStatus && request.prevStatus !== ContentStatusEnum.Unchanged;
  const shouldDisplayUpdateMarker = useShouldDisplayUpdateMarkerForRequestRule(request);
  const shouldStrikeTitle = hasRequestBeenRemoved(request);

  return (
    <TakeTurnsCard ref={ref} bgcolor={shouldDisplayUpdateMarker ? TakeTurnsColors.updatedElementBackground : undefined}>
      <TakeTurnsCardContent>
        <CardTitle title={request.name} shouldStrikeTitle={shouldStrikeTitle} />
      </TakeTurnsCardContent>
      {shouldDisplayLastUpdatesMarker && <ContentElementLastUpdateInCard status={request.prevStatus} />}
      <TakeTurnsCardRequestFooter>
        <RequestWordMarker />
        <TakeTurnsCardRequestActionAndYellowChipContainer>
          <RequestIconMarker />
          {shouldDisplayUpdateMarker && <RequestUpdateMarker request={request} />}
        </TakeTurnsCardRequestActionAndYellowChipContainer>
      </TakeTurnsCardRequestFooter>
    </TakeTurnsCard>
  );
});
Card.displayName = "InnerRequestCard";

const TakeTurnsCardRequestFooter = styled(TakeTurnsCardFooter)({
  alignItems: "center",
  justifyContent: "space-between",
  padding: "0 5px 0 10px",
});

const TakeTurnsCardRequestActionAndYellowChipContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: 2,
});
