/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useQuery } from "@apollo/client";
import { Collaboration } from "@taketurns/taketurns-graphql-repository";

const CONNECTED_USER_PARTY = gql`
  query connectedUserParty($collaborationId: ID!) {
    getCollaboration(collaborationId: $collaborationId) {
      id
      userContext {
        id
        party
        partyId
      }
    }
  }
`;

export const useFetchConnectedUserParty = (collaborationId: string) => {
  const {
    data,
    error: errorOnFetchingConnectedUserParty,
    loading: loadingConnectedUserParty,
  } = useQuery<{
    getCollaboration: Collaboration;
  }>(CONNECTED_USER_PARTY, { variables: { collaborationId } });
  return {
    connectedUserParty: data?.getCollaboration.userContext.party,
    connectedUserPartyId: data?.getCollaboration.userContext.partyId,
    errorOnFetchingConnectedUserParty,
    loadingConnectedUserParty,
  };
};
