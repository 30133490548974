/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useFetchCollaborationPartyNames } from "@taketurns-repositories/collaboration/graphql/queries/fields/useFetchCollaborationPartyNames";
import { useGetDisplayedCollaborationIdRule } from "@taketurns-rules/collaboration/queries/collaboration/useGetDisplayedCollaborationIdRule";

export const useFetchCollaborationPartyNamesRule = (collaborationId?: string) => {
  const displayedCollaborationId = useGetDisplayedCollaborationIdRule();
  return useFetchCollaborationPartyNames(collaborationId ?? displayedCollaborationId);
};
