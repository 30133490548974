/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Stack, styled, Typography } from "@mui/material";
import { Fragment } from "react";
import { InvitationResendButton } from "@taketurns-components/user/Organization/OrganizationMemberAndInvitationList/shared/InvitationResendButton";
import { InvitationRevokeButtonAndDialog } from "@taketurns-components/user/Organization/OrganizationMemberAndInvitationList/shared/InvitationRevokeButtonAndDialog";
import {
  MemberOrInvitationFirstAndLastNameOrEmail,
} from "@taketurns-components/user/Organization/OrganizationMemberAndInvitationList/shared/MemberOrInvitationFirstAndLastNameOrEmail";
import { MemberRemoveButtonAndDialog } from "@taketurns-components/user/Organization/OrganizationMemberAndInvitationList/shared/MemberRemoveButtonAndDialog";
import { isOrganizationInvitation, OrganizationMemberOrInvitation } from "@taketurns-components/user/Organization/OrganizationMemberAndInvitationList/shared/SharedComponentTypes";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";

interface MobileOrganizationMemberUserInfoProps {
  memberOrInvitation: OrganizationMemberOrInvitation;
  isConnectedUserRow: boolean;
}

export const MobileOrganizationMemberUserOrInvitationInfo = ({
  memberOrInvitation,
  isConnectedUserRow,
}: MobileOrganizationMemberUserInfoProps) => {
  const isEmailDisplayedAsASecondRow =
    memberOrInvitation.user && memberOrInvitation.user.firstName && memberOrInvitation.user.lastName;

  const Actions = () => {
    if (isOrganizationInvitation(memberOrInvitation)) {
      return (
        <Fragment>
          <InvitationResendButton invitation={memberOrInvitation} />
          <InvitationRevokeButtonAndDialog invitation={memberOrInvitation} />
        </Fragment>
      );
    }
    if (!isConnectedUserRow) {
      return <MemberRemoveButtonAndDialog memberUserId={memberOrInvitation.userId} />;
    }
  };

  return (
    <Stack direction="row" className="MobileOrganizationMemberUserOrInvitationInfo__Container">
      <Stack flex={1} overflow="hidden" className="MobileOrganizationMemberUserOrInvitationInfo__InfoContainer">
        <MemberOrInvitationFirstAndLastNameOrEmail memberOrInvitation={memberOrInvitation} />
        {isEmailDisplayedAsASecondRow && (
          <MobileOrganizationInvitationRow__Email noWrap>
            {memberOrInvitation.user.email}
          </MobileOrganizationInvitationRow__Email>
        )}
      </Stack>
      <Actions />
    </Stack>
  );
};

const MobileOrganizationInvitationRow__Email = styled(Typography)({
  color: TakeTurnsColors.darkGray,
  fontSize: "0.875rem",
});
