/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Checkbox, FormControlLabel, Stack, Typography } from "@mui/material";
import { AuthenticationMethod } from "@taketurns/taketurns-graphql-repository";
import { HelpIconWithTooltip } from "@taketurns-components/shared/HelpIconWithTooltip";
import { SomethingWentWrong } from "@taketurns-components/shared/SomethingWentWrong";
import { useOrganizationTranslation } from "@taketurns-i18n/user/Organization/useOrganizationTranslation";
import { useFetchUserOrganizationAuthenticationMethods } from "@taketurns-repositories/user/graphql/queries/useFetchUserOrganizationAuthenticationMethods";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { useAddAuthenticationMethodRule } from "@taketurns-rules/user/commands/useAddAuthenticationMethodRule";
import { useRemoveAuthenticationMethodRule } from "@taketurns-rules/user/commands/useRemoveAuthenticationMethodRule";

export const AuthenticationMethodsSelector = () => {
  const { t } = useOrganizationTranslation();

  const { authenticationMethods, errorOnLoadingAuthenticationMethods } =
    useFetchUserOrganizationAuthenticationMethods();

  const { addAuthenticationMethod } = useAddAuthenticationMethodRule();

  const { removeAuthenticationMethod } = useRemoveAuthenticationMethodRule();

  const activeAuthenticationMethods = Object.values(AuthenticationMethod).filter(
    (method) => authenticationMethods.length === 0 || authenticationMethods.includes(method),
  );

  const addOrRemoveAuthenticationMethod = (addElseRemove: boolean, method: AuthenticationMethod) => {
    if (addElseRemove) {
      addAuthenticationMethod(method);
    } else {
      if (authenticationMethods.length === 0) {
        activeAuthenticationMethods.filter((m) => m !== method).forEach((m) => addAuthenticationMethod(m));
      } else {
        removeAuthenticationMethod(method);
      }
    }
  };

  if (errorOnLoadingAuthenticationMethods) {
    return <SomethingWentWrong />;
  }

  return (
    <Stack spacing={1}>
      <Typography variant="body2">{t("AuthenticationMethodsSelector.title")}</Typography>
      <Stack direction={{ md: "row" }}>
        {Object.values(AuthenticationMethod).map((method) => (
          <Stack direction="row">
            <FormControlLabel
              label={
                <Typography variant="body2" textAlign="justify">
                  {t(`AuthenticationMethodsSelector.methods.${method}.label`)}
                </Typography>
              }
              control={
                <Checkbox
                  data-cy={`AuthenticationMethodsSelector.${method}`}
                  disableRipple
                  disableFocusRipple
                  checked={activeAuthenticationMethods.includes(method)}
                  disabled={activeAuthenticationMethods.length === 1 && activeAuthenticationMethods.at(0) === method}
                  onChange={(_, checked) => addOrRemoveAuthenticationMethod(checked, method)}
                  size="small"
                  style={{
                    color: TakeTurnsColors.darkGray,
                  }}
                />
              }
              sx={{ mr: 0, ml: 0 }}
            />
            <HelpIconWithTooltip helpText={t(`AuthenticationMethodsSelector.methods.${method}.description`)} />
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};
