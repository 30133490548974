/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { styled, Typography } from "@mui/material";
import { ReactNode } from "react";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";

export interface SidebarButtonProps {
  icon: ReactNode;
  label: string;
  onClick?: () => void;
  active?: boolean;
}

export const SidebarButton = (props: SidebarButtonProps) => {
  return (
    <SidebarButtonStyle {...props}>
      {props.icon}
      <Typography noWrap fontSize={12}>
        {props.label}
      </Typography>
    </SidebarButtonStyle>
  );
};

const SidebarButtonStyle = styled("button", {
  shouldForwardProp(propName: PropertyKey): propName is keyof SidebarButtonProps {
    return propName !== "active";
  },
})((props: SidebarButtonProps) => ({
  display: "flex",
  color: props.active ? TakeTurnsColors.darkBlue : TakeTurnsColors.darkGray,
  flex: 1,
  flexDirection: "column",
  alignItems: "center",
  border: "none",
  background: "none",
  "&:hover": {
    cursor: "pointer",
  },
}));
