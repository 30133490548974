/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useSetCollaborationFormInvitedPartyNameFieldError } from "@taketurns-repositories/collaboration/state/write/collaborationCreation/form/error/useSetCollaborationFormInvitedPartyNameFieldError";
import { useGetCollaborationFormInvitedPartyNameRule } from "@taketurns-rules/collaboration/queries/collaborationCreation/form/useGetCollaborationFormInvitedPartyNameRule";

export const useValidateCollaborationInvitedPartyNameRule = () => {
  const invitedPartyName = useGetCollaborationFormInvitedPartyNameRule();
  const setCollaborationFormInvitedPartyNameFieldError = useSetCollaborationFormInvitedPartyNameFieldError();

  return () => {
    const isNameValid = invitedPartyName.length > 0;
    setCollaborationFormInvitedPartyNameFieldError(!isNameValid);
    return isNameValid;
  };
};
