/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { TextField } from "@mui/material";
import { useCollaborationCreationTranslation } from "@taketurns-i18n/collaboration/CollaborationCreation/useCollaborationCreationTranslation";
import { useSetCollaborationFormNameRule } from "@taketurns-rules/collaboration/commands/collaborationCreation/form/useSetCollaborationFormNameRule";
import { useGetCollaborationFormErrorsRule } from "@taketurns-rules/collaboration/queries/collaborationCreation/form/useGetCollaborationFormErrorsRule";
import { useGetCollaborationFormNameRule } from "@taketurns-rules/collaboration/queries/collaborationCreation/form/useGetCollaborationFormNameRule";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { withMaxLengthControl } from "@taketurns-rules/commons/util/form/formUtils";

export const CollaborationNameField = () => {
  const collaborationFormErrors = useGetCollaborationFormErrorsRule();
  const collaborationName = useGetCollaborationFormNameRule();
  const setCollaborationName = useSetCollaborationFormNameRule();

  const { t } = useCollaborationCreationTranslation();

  const isInputInErrorState = collaborationFormErrors.name;

  return (
    <TextField
      tabIndex={1}
      error={isInputInErrorState}
      placeholder={t("namePlaceholder")}
      sx={{
        "& .MuiOutlinedInput-input::placeholder": {
          color: isInputInErrorState ? TakeTurnsColors.error : TakeTurnsColors.placeholder,
          opacity: 1,
        },
      }}
      type="text"
      value={collaborationName}
      onChange={(event) => withMaxLengthControl(event, (value) => setCollaborationName(value))}
    />
  );
};
