/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Stack, Typography } from "@mui/material";
import { UserAvatarAndNameOrEmailAndCatchPhrase } from "@taketurns-components/user/UserProfile/UserAvatar/UserAvatarAndNameOrEmailAndCatchPhrase";
import { useWebAppTranslations } from "@taketurns-i18n/webapp/useWebAppTranslations";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { useDecodeParamsInCurrentUrlRule } from "@taketurns-rules/webapp/queries/useDecodeParamsInCurrentUrlRule";
import { PasswordlessCollaborationAccessParameters } from "../PasswordlessCollaborationAccess";

export const PasswordlessCollaborationAccessTitle = () => {
  const { t } = useWebAppTranslations("passwordlessCollaborationAccess");
  const { isInvitationLink } = useDecodeParamsInCurrentUrlRule<PasswordlessCollaborationAccessParameters>()!;
  if (isInvitationLink) {
    return <InvitingUserInvitesYou />;
  }
  return <Typography>{t("accessToCollaboration.accessToCollaboration")}</Typography>;
};

const InvitingUserInvitesYou = () => {
  const { t } = useWebAppTranslations("passwordlessCollaborationAccess");
  const { invitingUser } = useDecodeParamsInCurrentUrlRule<PasswordlessCollaborationAccessParameters>()!;
  const invitingUserColor = useInvitingUserColor();

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <UserAvatarAndNameOrEmailAndCatchPhrase
        user={invitingUser}
        userIdentityColor={invitingUserColor}
        catchPhrase={t("inviteToCollaborate.inviteToCollaborate")}
      />
    </Stack>
  );
};

const useInvitingUserColor = () => {
  const {
    recipient: { party: recipientParty },
    invitingUser,
  } = useDecodeParamsInCurrentUrlRule<PasswordlessCollaborationAccessParameters>()!;
  if (invitingUser.party === recipientParty) {
    return TakeTurnsColors.darkBlue;
  }
  return TakeTurnsColors.lightBlue;
};
