/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { Invitee, Role } from "@taketurns/taketurns-graphql-repository";
import { useCollaborationTranslation } from "@taketurns-i18n/collaboration/useCollaborationTranslation";
import { useChangeInvitationRole } from "@taketurns-repositories/collaboration/graphql/mutations/participants/useChangeInvitationRole";
import { useGetDisplayedCollaborationIdRule } from "@taketurns-rules/collaboration/queries/collaboration/useGetDisplayedCollaborationIdRule";

export const useEditInvitationRoleRule = () => {
  const collaborationId = useGetDisplayedCollaborationIdRule();
  const { loadingEditInvitationRole, errorOnEditInvitationRole, editParticipantRoleCommand } =
    useChangeInvitationRole();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useCollaborationTranslation("notifications");

  useEffect(() => {
    if (errorOnEditInvitationRole) {
      enqueueSnackbar(errorOnEditInvitationRole.message, { variant: "error" });
    }
  }, [enqueueSnackbar, errorOnEditInvitationRole]);

  const editInvitationRole = (invitation: Invitee, role: Role) => {
    const notifyOnRoleEditionSucess = () => {
      enqueueSnackbar(t("user.roleChanged"), {
        variant: "info",
      });
    };

    const { email, userId } = invitation;
    editParticipantRoleCommand({ collaborationId, role, userId, email }, notifyOnRoleEditionSucess);
  };

  return { editInvitationRole, loadingEditInvitationRole };
};
