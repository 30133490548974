/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import * as Sentry from "@sentry/react";
import { getEnvironmentVariable } from "../getEnvironmentVariable";

let environment = "production";
if (getEnvironmentVariable("ENVIRONMENT")) {
  environment = getEnvironmentVariable("ENVIRONMENT");
}

getEnvironmentVariable("SENTRY_ACTIVATED") === "true" &&
  Sentry.init({
    environment,
    dsn: getEnvironmentVariable("SENTRY_DSN"),
    integrations: [],
  });

const originalConsoleError = console.error;
if (getEnvironmentVariable("SENTRY_ACTIVATED") === "true") {
  console.error = function (...args: unknown[]) {
    if (args.length > 0 && args[0] instanceof Error) {
      const SentryEventId = Sentry.captureException(args[0]);
      logError(SentryEventId, ...args);
    } else {
      const error = new Error(args.join(" "));
      const SentryEventId = Sentry.captureException(error);
      logError(SentryEventId, ...args);
    }
  };
}

function logError(sentryEventId: string, ...args: unknown[]) {
  if (environment === "production") {
    originalConsoleError.apply(console, [
      "An error occurred rendering the page, an error report has been communicated to our support team for further analysis with report id %s",
      sentryEventId,
    ]);
  } else {
    originalConsoleError.apply(console, args);
  }
}
