/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Box, Stack } from "@mui/material";
import { Outlet } from "react-router-dom";
import { useFathomScript } from "../../tracing/useFathomScript";
import { PublicAppBar } from "./PublicAppBar";

export const PublicLayout = () => {
  useFathomScript();
  return (
    <Stack className="PublicLayout" height="100%">
      <PublicAppBar />
      <Box flex={1} overflow="hidden">
        <Outlet />
      </Box>
    </Stack>
  );
};
