/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { gql, useQuery } from "@apollo/client";
import { StreamChatConnectionParameters } from "@taketurns/taketurns-graphql-repository";

const STREAM_CHAT_CONNECTION_PARAMETERS = gql`
  query getStreamChatConnectionParameters($userId: ID) {
    streamChatConnectionParameters(userId: $userId) {
      id
      token
      apiKey
    }
  }
`;

export const useFetchStreamChatConnectionParameters = (userId?: string) => {
  const {
    data,
    loading: loadingStreamChatToken,
    error: errorOnStreamChatToken,
  } = useQuery<{ streamChatConnectionParameters: StreamChatConnectionParameters }>(STREAM_CHAT_CONNECTION_PARAMETERS, {
    variables: { userId },
  });
  return {
    streamChatConnectionParameters: data?.streamChatConnectionParameters,
    loadingStreamChatToken,
    errorOnStreamChatToken,
  };
};
