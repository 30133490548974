/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useMutation } from "@apollo/client";
import {
  CreateInviteeInput,
  MutationInviteToCollaborationArgs,
  ParticipantsAndInvitations,
} from "@taketurns/taketurns-graphql-repository";
import { collaborationParticipantsFragment } from "@taketurns-repositories/collaboration/graphql/fragments/collaborationParticipantsFragment";

export const useAddInvitation = () => {
  const mutation = gql`
    mutation inviteToCollaboration($collaborationId: ID!, $invitee: CreateInviteeInput!) {
      inviteToCollaboration(collaborationId: $collaborationId, invitee: $invitee) {
        ...CollaborationParticipantsFields
      }
    }
    ${collaborationParticipantsFragment}
  `;
  const [
    inviteToCollaborationMutation,
    { error: errorOnInviteToCollaboration, loading: loadingInviteToCollaboration },
  ] = useMutation<
    {
      inviteToCollaboration: ParticipantsAndInvitations;
    },
    MutationInviteToCollaborationArgs
  >(mutation);

  const inviteToCollaborationCommand = (
    collaborationId: string,
    invitee: CreateInviteeInput,
    onInvitationSent?: () => void,
  ) => {
    inviteToCollaborationMutation({ variables: { collaborationId, invitee }, onCompleted: onInvitationSent });
  };

  return { inviteToCollaborationCommand, errorOnInviteToCollaboration, loadingInviteToCollaboration };
};
