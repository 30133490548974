/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useSharedCollaborationTranslation } from "@taketurns-i18n/collaboration/shared/useSharedCollaborationTranslation";
import { useHideCollaboration } from "@taketurns-repositories/collaboration/graphql/mutations/hide/useHideCollaboration";
import { useFetchCollaborationName } from "@taketurns-repositories/collaboration/graphql/queries/fields/useFetchCollaborationName";
import { useNavigateToCollaborationListRule } from "@taketurns-rules/collaboration/commands/navigation/useNavigateToCollaborationListRule";

export const useHideCollaborationRule = (collaborationId: string) => {
  const [hideCollaborationMutation, hideCollaborationMutationResult] = useHideCollaboration();
  const { enqueueSnackbar } = useSnackbar();
  const { name } = useFetchCollaborationName(collaborationId);
  const { t } = useSharedCollaborationTranslation();

  useEffect(() => {
    if (hideCollaborationMutationResult.error) {
      enqueueSnackbar(hideCollaborationMutationResult.error.message, {
        variant: "error",
      });
      console.error(hideCollaborationMutationResult.error);
      hideCollaborationMutationResult.reset();
    }
  }, [hideCollaborationMutationResult.error]);

  const navigateToCollaborationList = useNavigateToCollaborationListRule();
  const hideCollaborationCommand = () => {
    hideCollaborationMutation({
      variables: { collaborationId: collaborationId },
      onCompleted: () => {
        enqueueSnackbar(t("CollaborationMenu.hide.successMessage", { name }), {
          variant: "info",
        });
        navigateToCollaborationList();
      },
    });
  };

  return {
    hideCollaborationCommand,
  };
};
