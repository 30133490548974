/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useFetchUserHasMultiUserOrganizationFeatureRule } from "@taketurns-rules/user/queries/useFetchUserHasMultiUserOrganizationFeatureRule";
import { useFetchUserHasOrganizationAdminFeatureRule } from "@taketurns-rules/user/queries/useFetchUserHasOrganizationAdminFeatureRule";

export function useShouldDisplayOrganizationAccessPoliciesRule() {
  const { userHasOrganizationAdminFeature } = useFetchUserHasOrganizationAdminFeatureRule();
  const { userHasMultiUserOrganizationFeature } = useFetchUserHasMultiUserOrganizationFeatureRule();

  return userHasOrganizationAdminFeature && userHasMultiUserOrganizationFeature;
}
