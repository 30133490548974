/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { selector, useRecoilValue } from "recoil";
import { CreateFolderInput } from "@taketurns/taketurns-graphql-repository";
import { FolderInCreation } from "../../../models/folderInCreation";
import { attachmentByIdState } from "../../recoil/collaborationCreation/attachmentByIdState";
import { folderByIdState, ROOT_FOLDER_ID } from "../../recoil/collaborationCreation/folderByIdState";
import { requestByIdState } from "../../recoil/collaborationCreation/requestByIdState";

const denormalizedRootFolderSelectorState = selector({
  key: "denormalizedRootFolderSelector",
  get: ({ get }) => {
    const denormalizedFolder = (folder: FolderInCreation): object => {
      return {
        name: folder.name,
        folders: folder.folders.map((folderInCreationId) =>
          denormalizedFolder(get(folderByIdState(folderInCreationId))),
        ),
        attachments: folder.attachments.map((attachmentId) => get(attachmentByIdState(attachmentId))),
        requests: folder.requests.map((requestId) => get(requestByIdState(requestId))),
      };
    };
    return denormalizedFolder(get(folderByIdState(ROOT_FOLDER_ID)));
  },
});

export const useGetRootFolder = (): CreateFolderInput => {
  return useRecoilValue(denormalizedRootFolderSelectorState) as CreateFolderInput;
};
