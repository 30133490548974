/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Navigate, useSearchParams } from "react-router-dom";
import { useFetchUserHasOrganizationAdminAndMultiUserFeaturesRule } from "@taketurns-rules/webapp/queries/useFetchUserHasOrganizationAdminAndMultiUserFeaturesRule";
import { useShouldDisplayProfilePageRule } from "@taketurns-rules/webapp/queries/useShouldDisplayProfilePageRule";
import { AUTHENTICATED_ROUTES } from "../../routing/routes/authenticatedRoutes.constants";
import {
  getUserSettingsSectionUrl,
  MANAGE_PLAN_SECTION,
  ORGANIZATION_SECTION,
} from "../../routing/routes/userSettingsSections";

export enum CheckOutStatus {
  "SUCCESS" = "success",
}

export const CHECKOUT_STATUS_SEARCH_PARAM = "status";

export const Checkout = () => {
  const [searchParams] = useSearchParams();
  const shouldDisplayProfilePage = useShouldDisplayProfilePageRule();
  const { hasOrganizationAdmin, hasMultiUserOrganization, loadingUserHasOrganizationAdminAndMultiUserFeatures } =
    useFetchUserHasOrganizationAdminAndMultiUserFeaturesRule();

  if (loadingUserHasOrganizationAdminAndMultiUserFeatures) {
    return null;
  }

  if (searchParams.get(CHECKOUT_STATUS_SEARCH_PARAM) === CheckOutStatus.SUCCESS) {
    if (shouldDisplayProfilePage) {
      return <Navigate to={AUTHENTICATED_ROUTES.COMPLETE_PROFILE} />;
    } else {
      if (hasOrganizationAdmin && hasMultiUserOrganization) {
        return <Navigate to={getUserSettingsSectionUrl(ORGANIZATION_SECTION)} />;
      }
      return <Navigate to={getUserSettingsSectionUrl(MANAGE_PLAN_SECTION)} />;
    }
  }
  return <Navigate to={AUTHENTICATED_ROUTES.PLANS} />;
};
