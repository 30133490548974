/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { gql, useQuery } from "@apollo/client";
import { AddOrRemoveOrganizationMemberInvoice } from "@taketurns/taketurns-graphql-repository";

export const useFetchAddOrganizationMemberInvoice = () => {
  const ADD_ORGANIZATION_MEMBER_INVOICE = gql`
    query addOrganizationMemberInvoice {
      addOrganizationMemberInvoice {
        amountDueToday
        balance
        nextPeriodStartingDate
        currency
        paymentCardLastFourDigits
        unitPrice
        quantity
      }
    }
  `;

  const {
    data,
    loading: loadingAddOrganizationMemberInvoice,
    error: errorOnAddOrganizationMemberInvoice,
  } = useQuery<{
    addOrganizationMemberInvoice: AddOrRemoveOrganizationMemberInvoice;
  }>(ADD_ORGANIZATION_MEMBER_INVOICE, { fetchPolicy: "network-only" });
  return {
    invoice: data?.addOrganizationMemberInvoice,
    loadingAddOrganizationMemberInvoice,
    errorOnAddOrganizationMemberInvoice,
  };
};
