/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Card, Fade } from "@mui/material";
import { PropsWithChildren } from "react";

export const MobileCardLayout = ({ children }: PropsWithChildren) => {
  return (
    <Fade in={true}>
      <Card
        style={{
          height: "100%",
          overflow: "auto",
          borderBottomRightRadius: 0,
          borderBottomLeftRadius: 0,
          padding: 24,
          paddingBottom: 40,
        }}
      >
        {children}
      </Card>
    </Fade>
  );
};
