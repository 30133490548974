/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

export const removeHtmlTagsRule = (textWithPotentialHtmlTags: string) => {
  const div = document.createElement("div");
  div.innerHTML = textWithPotentialHtmlTags || "";
  const text = div.textContent || div.innerText || "";
  div.remove();
  return text;
};
