/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Dialog } from "@mui/material";
import { PropsWithChildren } from "react";
import { useIsOnMobileRule } from "@taketurns-rules/commons/theme/useIsOnMobileRule";

interface CollaborationDialogProps {
  open: boolean;
  onClose: () => void;
}

export const CollaborationDialog = ({ open, onClose, children }: PropsWithChildren<CollaborationDialogProps>) => {
  const isOnMobile = useIsOnMobileRule();

  return (
    <Dialog
      data-cy="CollaborationDialog"
      open={open}
      PaperProps={{
        sx: {
          borderRadius: { xs: 0, md: "20px" },
          minHeight: "80%",

          "& .MuiDialogActions-root": {
            paddingBottom: "calc(24px + env(safe-area-inset-bottom))",
          },
        },
      }}
      onClose={onClose}
      fullWidth
      maxWidth={"lg"}
      fullScreen={isOnMobile}
    >
      {children}
    </Dialog>
  );
};
