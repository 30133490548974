/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { gql } from "@apollo/client";
import { usePollGenerateAiAssistantResponseFileUrl } from "@taketurns-repositories/document/graphql/queries/usePollGenerateAiAssistantResponseFileUrl";

export const useFetchComparisonUrl = (variables: {
  documentId: string;
  revisionId: string;
  language: string;
  collaborationId: string;
}) => {
  const query = gql`
    query generateComparisonUrl($documentId: ID!, $revisionId: ID!, $language: String!, $collaborationId: ID!) {
      generateComparisonUrl(
        documentId: $documentId
        revisionId: $revisionId
        language: $language
        collaborationId: $collaborationId
      ) {
        status
        downloadUrl
        errorCode
      }
    }
  `;
  const { downloadUrl, error, errorCode, loading, retryFetchQuery } = usePollGenerateAiAssistantResponseFileUrl(
    "generateComparisonUrl",
    query,
    variables,
  );
  return {
    retryFetchingComparison: retryFetchQuery,
    errorCode,
    downloadUrl,
    loading,
    error,
  };
};
