/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useQuery } from "@apollo/client";
import { SubscriptionPlan } from "@taketurns/taketurns-graphql-repository";

export const USER_SUBSCRIPTION_PLAN_TRIAL = gql`
  query userSubscriptionPlanTrial {
    userSubscription {
      id
      status
      trialEndDate
    }
  }
`;
export const useFetchUserSubscriptionPlanTrial = () => {
  const {
    data,
    error: errorOnUserSubscriptionPlanTrial,
    loading: loadingUserSubscriptionPlanTrial,
  } = useQuery<{
    userSubscription: SubscriptionPlan;
  }>(USER_SUBSCRIPTION_PLAN_TRIAL);

  return {
    userSubscriptionPlanTrial: data?.userSubscription,
    errorOnUserSubscriptionPlanTrial,
    loadingUserSubscriptionPlanTrial,
  };
};
