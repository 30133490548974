/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Attachment } from "@taketurns/taketurns-graphql-repository";
import { useFetchDocumentRevisionsRule } from "@taketurns-rules/document/queries/useFetchDocumentRevisionsRule";
import { MobileBottomSheet } from "../../../../../commons/MobileSheets/MobileBottomSheet";
import { AttachmentRevisionList } from "./AttachmentRevisionList";

interface MobileDocumentRevisionDrawerProps {
  attachment: Attachment;
  canEdit?: boolean;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

export const MobileAttachmentRevisionListDrawer = ({
  attachment,
  canEdit,
  isOpen,
  setIsOpen,
}: MobileDocumentRevisionDrawerProps) => {
  const { revisions, loadingDocumentRevisions, errorOnFetchingDocumentRevisions } = useFetchDocumentRevisionsRule(
    attachment.id,
  );

  return (
    <MobileBottomSheet isOpen={isOpen} setIsOpen={setIsOpen}>
      <AttachmentRevisionList
        attachment={attachment}
        canEdit={canEdit}
        revisions={revisions}
        loadingDocumentRevisions={loadingDocumentRevisions}
        errorOnFetchingDocumentRevisions={errorOnFetchingDocumentRevisions}
      />
    </MobileBottomSheet>
  );
};
