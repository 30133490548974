/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Box, Stack, styled, Typography } from "@mui/material";
import { FC } from "react";
import { useSearchParams } from "react-router-dom";
import { IntegrationSettings } from "@taketurns-components/user/IntegrationSettings/IntegrationSettings";
import { OrganizationAccessPoliciesSettings } from "@taketurns-components/user/Organization/OrganizationAccessPolicies/OrganizationAccessPoliciesSettings";
import { UserOrganizationSettings } from "@taketurns-components/user/Organization/UserOrganizationSettings/UserOrganizationSettings";
import { BillingPortal } from "@taketurns-components/user/UserPlans/BillingPortal/BillingPortal";
import { UserPreferences } from "@taketurns-components/user/UserProfile/UserPreferences";
import { UserSettings } from "@taketurns-components/user/UserProfile/UserSettings";
import { useWebAppTranslations } from "@taketurns-i18n/webapp/useWebAppTranslations";
import { useIsOnMobileRule } from "@taketurns-rules/commons/theme/useIsOnMobileRule";
import { useNavigateToUserSettingsPreviousPathRule } from "@taketurns-rules/webapp/commands/useNavigateToUserSettingsPreviousPathRule";
import { TwoColumnsLayout } from "../../../layouts/body/content/TwoColumnsLayout";
import { MobileBodyLayout } from "../../../layouts/body/MobileBodyLayout";
import {
  ACCESS_POLICIES_SECTION,
  INTEGRATIONS_SECTION,
  MANAGE_PLAN_SECTION,
  ORGANIZATION_SECTION,
  PREFERENCES_SECTION,
  USER_SETTINGS_SEARCH_PARAM,
} from "../../../routing/routes/userSettingsSections";
import { MobileUserSettingsHeader } from "./MobileUserSettingsHeader";
import { SettingSectionTabs } from "./SettingSectionTabs";

export const UserSettingsPage = () => {
  const { t } = useWebAppTranslations("settings");

  const [searchParam] = useSearchParams();
  const activeSection = searchParam.get(USER_SETTINGS_SEARCH_PARAM);

  const goBack = useNavigateToUserSettingsPreviousPathRule();

  const SettingsContent = () => {
    let Section: FC;
    switch (activeSection) {
      case MANAGE_PLAN_SECTION:
        Section = BillingPortal;
        break;
      case PREFERENCES_SECTION:
        Section = UserPreferences;
        break;
      case ORGANIZATION_SECTION:
        Section = UserOrganizationSettings;
        break;
      case INTEGRATIONS_SECTION:
        Section = IntegrationSettings;
        break;
      case ACCESS_POLICIES_SECTION:
        Section = OrganizationAccessPoliciesSettings;
        break;
      default:
        Section = () => (
          <Stack spacing={3}>
            <UserSettings userCanChangePassword useDefaultHeader={!isOnMobile} />
          </Stack>
        );
    }

    return (
      <Section__Container>
        <Section />
      </Section__Container>
    );
  };

  const isOnMobile = useIsOnMobileRule();
  if (isOnMobile) {
    return (
      <MobileBodyLayout
        disablePadding
        header={<MobileUserSettingsHeader />}
        content={
          <MobileUserSettings__SafeAreaBox>
            <SettingsContent />
          </MobileUserSettings__SafeAreaBox>
        }
      />
    );
  }

  return (
    <TwoColumnsLayout
      header={<Typography variant="h6">{t("settings")}</Typography>}
      backAction={goBack}
      leftColumn={<SettingSectionTabs />}
      content={<SettingsContent />}
    />
  );
};

const Section__Container = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: theme.spacing(3),
  height: "100%",
}));

const MobileUserSettings__SafeAreaBox = styled(Box)({
  marginBottom: "env(safe-area-inset-bottom)",
});
