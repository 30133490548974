/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { selector } from "recoil";
import { PendingAttachmentRevision } from "@taketurns-repositories/collaboration/models/pendingAttachmentRevision";
import { collaborationIdsLinkedToPendingAttachmentRevisionsState } from "./collaborationIdsLinkedToPendingAttachmentRevisionsState";
import { pendingAttachmentRevisionsByCollaborationIdState } from "./pendingAttachmentRevisionsByCollaborationIdState";

export const selectAllPendingAttachmentRevisionsState = selector<PendingAttachmentRevision[]>({
  key: "selectAllPendingAttachmentRevisions",
  get: ({ get }) => {
    const allPendingAttachmentRevisions: PendingAttachmentRevision[] = [];
    for (const collaborationId of get(collaborationIdsLinkedToPendingAttachmentRevisionsState)) {
      allPendingAttachmentRevisions.push(...get(pendingAttachmentRevisionsByCollaborationIdState(collaborationId)));
    }
    return allPendingAttachmentRevisions;
  },
});
