/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Box, DialogContent, DialogTitle, Divider, Skeleton, Stack } from "@mui/material";
import { Fragment } from "react";
import { EventOnCollaboration } from "@taketurns/taketurns-graphql-repository";
import { DisplayedUser, EventNoteUserAndHeadline } from "@taketurns-components/collaboration/Shared/EventNote/EventNoteUserAndHeadline";
import { useEventNoteDialogTitleAndContentDataRule } from "@taketurns-components/collaboration/Shared/EventNote/useEventNoteDialogTitleAndContentDataRule";
import { DialogFooter } from "@taketurns-components/commons/dialog/DialogFooter";
import { SomethingWentWrong } from "@taketurns-components/shared/SomethingWentWrong";
import { getUserPresentationRule } from "@taketurns-rules/user/getUserPresentationRule";
import { RichTextEventNote } from "./RichTextEventNote";

interface MobileEventNoteDialogContentProps {
  onClose: () => void;
  collaborationId: string;
  eventId: string;
}

export const MobileEventNoteDialogContent = (props: MobileEventNoteDialogContentProps) => {
  const { event, loadingEventNoteDialogTitleAndContentData, errorOnFetchingEventNoteDialogTitleAndContentData } =
    useEventNoteDialogTitleAndContentDataRule(props.collaborationId, props.eventId);

  if (loadingEventNoteDialogTitleAndContentData || !event) {
    return (
      <Fragment>
        <DialogTitle sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Skeleton variant="text" width={100} />
        </DialogTitle>
        <Divider />
        <DialogContent sx={{ display: "flex", flexDirection: "column", gap: 2, paddingX: 8 }}>
          <Skeleton variant="text" width={100} />
        </DialogContent>
        <DialogFooter onClose={props.onClose} />
      </Fragment>
    );
  }

  if (errorOnFetchingEventNoteDialogTitleAndContentData) {
    return <SomethingWentWrong />;
  }

  const color = event.isFromConnectedUserParty ? "primary" : "secondary";

  const displayedUser: DisplayedUser = {
    avatar: event.user.avatar,
    presentation: getUserPresentationRule(event?.user),
    jobTitle: event.user.jobTitle,
  };

  return (
    <Fragment>
      <Box p={3}>
        <EventNoteUserAndHeadline user={displayedUser} color={color} />
      </Box>
      <Divider />
      <EventNoteContent event={event} />
    </Fragment>
  );
};

const EventNoteContent = (props: { event: EventOnCollaboration }) => {
  return (
    <Stack spacing={2} padding={3}>
      <RichTextEventNote note={props.event.note} />
    </Stack>
  );
};
