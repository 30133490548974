/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useMoveRequestIntoFolderInCreation } from "@taketurns-repositories/collaboration/state/write/useMoveRequestIntoFolderInCreation";

export function useMoveRequestIntoFolderInCreationRule(folderId: string) {
  const moveRequestIntoFolder = useMoveRequestIntoFolderInCreation(folderId);
  return (destinationFolderId: string) => {
    moveRequestIntoFolder(destinationFolderId);
  };
}
