/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import i18n from "@taketurns-i18n/i18n";
import { collaborationInvitationWelcomeDialogUrl } from "@taketurns-repositories/webapp/environmentVariables/collaborationInvitationWelcomeDialogUrl";
import { LANGUAGE_PARAMETER } from "@taketurns-repositories/webapp/environmentVariables/helpCenterUrl";

export const getCollaborationInvitationWelcomeDialogUrlRule = () => {
  return collaborationInvitationWelcomeDialogUrl.replace(LANGUAGE_PARAMETER, i18n.language);
};
