/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useAttachFilesInCurrentFolderRule } from "../attachment/useAttachFilesInCurrentFolderRule";

export const useFulfillRequestRule = (requestName: string) => {
  const attachFiles = useAttachFilesInCurrentFolderRule();

  return async (files: File[]) => {
    await attachFiles(files, requestName);
  };
};
