/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useMutation } from "@apollo/client";
import {
  Folder,
  MutationRestoreFolderArgs,
  RestoreFolderInput,
  RestoreFolderOutput,
} from "@taketurns/taketurns-graphql-repository";
import { FolderFragment } from "@taketurns-repositories/collaboration/graphql/queries/folder/useFetchFolder";

export const restoreFolderMutationFragment = gql`
  fragment RestoreFolderMutationFragment on RestoreFolderOutput {
    collaborationId
    partyId
    folderId
    folder {
      id
      status
      folders {
        ...FolderFragment
        updateCount
      }
      folderCount
      updateCount
    }
    contentMetadata {
      id
      hasPendingUpdates
    }
  }
  ${FolderFragment}
`;

export const useRestoreFolder = (collaborationId: string, folder: Folder) => {
  const RESTORE_ATTACHMENT = gql`
    ${restoreFolderMutationFragment}
    mutation restoreFolder($input: RestoreFolderInput!) {
      restoreFolder(input: $input) {
        ...RestoreFolderMutationFragment
      }
    }
  `;

  const [restoreMutation, { data, loading, error }] = useMutation<
    { restoreFolder: RestoreFolderOutput },
    MutationRestoreFolderArgs
  >(RESTORE_ATTACHMENT);

  const restoreFolderMutation = () => {
    const input: RestoreFolderInput = {
      collaborationId,
      folderId: folder.id,
    };
    return restoreMutation({ variables: { input } });
  };

  return {
    restoreFolderMutation,
    restoreFolderResponseData: data?.restoreFolder,
    restoreFolderLoading: loading,
    errorOnRestoringFolder: error,
  };
};
