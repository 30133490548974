/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
export const TIP_KEYS = {
  PARTY_NAME: "partyName",
  END_TURN: "endTurn",
  YOUR_TURN: "yourTurn",
  FILES: "files",
  UPLOAD_REVISION_OR_DOWNLOAD_FILE: "uploadRevisionOrDownloadFile",
  UPLOAD_FILE: "uploadFile",
  SEND_UPDATE_DESCRIPTION: "sendUpdateDescription",
  WRAP_UP_DESCRIPTION: "wrapUpDescription",
  RECALL_TURN: "recallTurn",
} as const;
