/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useRecoilValue } from "recoil";
import { contentSelectionState } from "@taketurns-repositories/collaboration/state/recoil/contentSelectionState";

export const useGetFolderSelectionStateById = (folderId: string) => {
  const contentSelectionStateValue = useRecoilValue(contentSelectionState);
  return contentSelectionStateValue.folderIds.includes(folderId);
};
