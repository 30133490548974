/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useQuery } from "@apollo/client";
import { Collaboration } from "@taketurns/taketurns-graphql-repository";

export const useFetchCollaborationRecap = (collaborationId: string) => {
  const query = gql`
    query collaborationRecap($collaborationId: ID!) {
      getCollaboration(collaborationId: $collaborationId) {
        id
        name
        owningPartyName
        invitedPartyName
        deadline
        events {
          id
          eventType
          isFromConnectedUserParty
          party
          partyName
          date
          note
          userId
          user {
            id
            avatar
            firstName
            lastName
            email
            jobTitle
          }
          participantsWhenEventOccurs {
            userId
            party
            role
            user {
              id
              firstName
              lastName
              jobTitle
              avatar
              email
            }
          }
          updatedContent {
            addedFiles {
              id
              attachmentId
              name
            }
            updatedFiles {
              id
              attachmentId
              name
            }
            addedRequests {
              name
            }
            fulfilledRequests {
              id
              name
              attachedRevisions {
                id
                attachmentId
                name
              }
            }
          }
        }
        userContext {
          party
        }
      }
    }
  `;

  const { data, loading, error } = useQuery<{ getCollaboration: Collaboration }, { collaborationId: string }>(query, {
    variables: {
      collaborationId,
    },
  });
  return {
    collaborationRecap: data?.getCollaboration,
    loadingCollaborationRecap: loading,
    errorWhileLoadingCollaborationRecap: error,
  };
};
