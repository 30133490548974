/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useQuery } from "@apollo/client";

const GET_CONNECTED_USER = gql`
  query GetConnectedUser {
    connectedUser {
      id
      email
      avatar
      firstName
      lastName
      jobTitle
    }
  }
`;

interface CollaborationUser {
  id: string;
  email: string;
  avatar?: string;
  firstName: string;
  lastName: string;
  jobTitle?: string;
}

export const useFetchConnectedUserForCollaborationCreation = () => {
  const { data, loading, error } = useQuery<{ connectedUser: CollaborationUser }>(GET_CONNECTED_USER);
  return {
    user: data?.connectedUser,
    loadingUserForCollaboration: loading,
    errorOnGettingUserForCollaboration: error,
  };
};
