/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Box, Chip, CircularProgress, Dialog, DialogContent, DialogTitle, Divider, Skeleton, Stack, styled, TextField, TextFieldProps, Typography } from "@mui/material";
import { Fragment, useState } from "react";
import {
  Contact,
  InviteToOrganizationInput,
  OrganizationInvitation,
  OrganizationMember,
  OrganizationUserRole,
  SubscriptionPlanCurrency,
} from "@taketurns/taketurns-graphql-repository";
import { UserContactsAutoComplete } from "@taketurns-components/user/UserContact/UserContactsAutoComplete";
import { useOrganizationTranslation } from "@taketurns-i18n/user/Organization/useOrganizationTranslation";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { useIsOnMobileRule } from "@taketurns-rules/commons/theme/useIsOnMobileRule";
import { validateEmail } from "@taketurns-rules/commons/util/form/formUtils";
import { useInviteToOrganizationRule } from "@taketurns-rules/user/commands/useInviteToOrganizationRule";
import { getFormattedNextPaymentDateRule } from "@taketurns-rules/user/queries/getFormattedNextPaymentDateRule";
import { useFetchAddOrganizationMemberInvoiceRule } from "@taketurns-rules/user/queries/useFetchAddOrganizationMemberInvoiceRule";
import { useFetchIsUserOrganizationHasNameRule } from "@taketurns-rules/user/queries/useFetchIsUserOrganizationHasNameRule";
import { useFetchUserOrganizationMembersRule } from "@taketurns-rules/user/queries/useFetchUserOrganizationMembersRule";
import { useFetchUserSubscriptionPlanTierAndPeriodRule } from "@taketurns-rules/user/queries/useFetchUserSubscriptionPlanTierAndPeriodRule";
import { TakeTurnsDefaultButton } from "../../../commons/button";
import { DialogFooter } from "../../../commons/dialog/DialogFooter";
import { UpgradePayment } from "../../shared/UpgradePayment";
import { OrganizationUserRoleSelector } from "../shared/OrganizationUserRoleSelector";

const invitationInitialState: InviteToOrganizationInput = {
  role: OrganizationUserRole.User,
  email: "",
};
export const OrganizationInviteMemberForm = () => {
  const { isUserOrganizationHasName, loadingIsUserOrganizationHasName } = useFetchIsUserOrganizationHasNameRule();
  const [invitation, setInvitation] = useState<InviteToOrganizationInput>(invitationInitialState);

  const { t } = useOrganizationTranslation();
  const isOnMobile = useIsOnMobileRule();

  const clearInvitation = () => {
    setInvitation(invitationInitialState);
  };

  if (loadingIsUserOrganizationHasName) {
    return <Skeleton variant={"text"} />;
  }

  if (!isUserOrganizationHasName) {
    return (
      <Fragment>
        <Typography>{t("OrganizationInviteMemberForm.addUser")}</Typography>
        <Typography variant={"body2"} color={TakeTurnsColors.darkGray}>
          {t("OrganizationInviteMemberForm.setOrganizationName")}
        </Typography>
      </Fragment>
    );
  }

  if (isOnMobile) {
    return (
      <Fragment>
        <Typography>{t("OrganizationInviteMemberForm.addUser")}</Typography>
        <Stack direction="column" spacing={2}>
          <NewInvitationInput invitation={invitation} setInvitation={setInvitation} />
          <Stack direction="row" spacing={2}>
            <OrganizationUserRoleSelector
              sx={{ flex: 1 }}
              onRoleSelected={(role) => setInvitation({ ...invitation, role })}
              role={invitation.role}
            />
            <InviteButtonWithLoadingIndicator invitation={invitation} handleAfterInvitationSent={clearInvitation} />
          </Stack>
        </Stack>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Typography>{t("OrganizationInviteMemberForm.addUser")}</Typography>
      <Stack direction="row" spacing={2}>
        <NewInvitationInput invitation={invitation} setInvitation={setInvitation} />
        <OrganizationUserRoleSelector
          onRoleSelected={(role) => setInvitation({ ...invitation, role })}
          role={invitation.role}
        />
        <InviteButtonWithLoadingIndicator invitation={invitation} handleAfterInvitationSent={clearInvitation} />
      </Stack>
    </Fragment>
  );
};

const getOrganizationInvitationsAndMembersAsDisabledOptionsForAutoComplete = (
  members: OrganizationMember[],
  invitations: OrganizationInvitation[],
) => {
  const organizationMemberUserIds = members.map((member) => member.userId);
  const invitationEmails = invitations.filter((invitation) => !!invitation.email).map((invitation) => invitation.email);
  const invitationUserIds = invitations
    .filter((invitation) => !!invitation.userId)
    .map((invitation) => invitation.userId);
  return [...organizationMemberUserIds, ...invitationEmails, ...invitationUserIds];
};

const NewInvitationInput = ({
  invitation,
  setInvitation,
}: {
  invitation: InviteToOrganizationInput;
  setInvitation: (value: InviteToOrganizationInput) => void;
}) => {
  const { t } = useOrganizationTranslation();
  const { invitations, members } = useFetchUserOrganizationMembersRule();

  const setInvitationFromContact = (contact?: Contact) => {
    if (contact?.user) {
      setInvitation({ ...invitation, userId: contact.user.id, email: contact.email });
    } else {
      setInvitation({ ...invitation, email: contact?.email });
    }
  };

  return (
    <UserContactsAutoComplete
      sx={(theme) => ({
        [theme.breakpoints.up("md")]: {
          flex: "0 0 300px",
        },
      })}
      renderInput={(params) => (
        <TextField
          {...(params as unknown as TextFieldProps)}
          variant="outlined"
          placeholder={t("OrganizationInviteMemberForm.typeEmail")}
          value={invitation.email}
          onChange={(event) => setInvitation({ ...invitation, email: event.target.value })}
          type="email"
          InputProps={{ ...params.InputProps, sx: { fontSize: 12 } }}
        />
      )}
      onContactChange={setInvitationFromContact}
      disabledUserEmailsAndIds={
        getOrganizationInvitationsAndMembersAsDisabledOptionsForAutoComplete(members, invitations) as string[]
      }
    />
  );
};

const InviteButtonWithLoadingIndicator = ({
  invitation,
  handleAfterInvitationSent,
}: {
  invitation: InviteToOrganizationInput;
  handleAfterInvitationSent: () => void;
}) => {
  const { invitations, members } = useFetchUserOrganizationMembersRule();
  const [confirmationDialogIsOpened, setConfirmationDialogIsOpened] = useState(false);

  const openConfirmationDialog = () => {
    setConfirmationDialogIsOpened(true);
  };

  const invitationAlreadyExist =
    members.some((member) => member.userId === invitation.userId || member.user?.email === invitation.email) ||
    invitations.some(
      (existingInvitation) =>
        existingInvitation.userId === invitation.userId || existingInvitation.email === invitation.email,
    );
  const disabledIfNotValidEmailOrInvitationAlreadyExist =
    !validateEmail(invitation.email as string) || invitationAlreadyExist;
  const { t } = useOrganizationTranslation();

  return (
    <Fragment>
      <InviteButtonWithLoadingIndicator__Container>
        <TakeTurnsDefaultButton
          sx={{ fontSize: 12, lineHeight: 0 }}
          disabled={disabledIfNotValidEmailOrInvitationAlreadyExist}
          onClick={openConfirmationDialog}
        >
          {t("OrganizationInviteMemberForm.invite")}
        </TakeTurnsDefaultButton>
        {invitationAlreadyExist && (
          <Typography variant={"caption"} color={"error"}>
            {t("OrganizationInviteMemberForm.userAlreadyInOrganization")}
          </Typography>
        )}
      </InviteButtonWithLoadingIndicator__Container>
      <InviteToOrganizationConfirmationDialog
        open={confirmationDialogIsOpened}
        onClose={() => setConfirmationDialogIsOpened(false)}
        invitation={invitation}
        handleAfterInvitationSent={handleAfterInvitationSent}
      />
    </Fragment>
  );
};

const InviteButtonWithLoadingIndicator__Container = styled(Box)({
  display: "flex",
  gap: "12px",
  alignItems: "center",
});

const InviteToOrganizationConfirmationDialog = ({
  open,
  onClose,
  invitation,
  handleAfterInvitationSent,
}: {
  open: boolean;
  onClose: () => void;
  invitation: InviteToOrganizationInput;
  handleAfterInvitationSent: () => void;
}) => {
  const isOnMobile = useIsOnMobileRule();
  const { inviteToOrganization, loadingInviteToOrganization } = useInviteToOrganizationRule();
  const { t } = useOrganizationTranslation();

  const sendInvitationToOrganizationAndCloseConfirmationDialog = () => {
    inviteToOrganization(invitation);
    handleAfterInvitationSent();
    onClose();
  };

  return (
    <Dialog
      open={open}
      PaperProps={{
        sx: {
          borderRadius: { xs: 0, md: "20px" },
          minHeight: "80%",
          "& .MuiDialogActions-root": {
            paddingBottom: "calc(24px + env(safe-area-inset-bottom))",
          },
        },
      }}
      onClose={onClose}
      fullWidth
      maxWidth={"lg"}
      fullScreen={isOnMobile}
    >
      <DialogTitle>{t("OrganizationInviteMemberForm.InviteToOrganizationConfirmationDialog.title")}</DialogTitle>
      <Divider />
      <InviteToOrganizationConfirmationDialogContent>
        <AddOrganizationMemberInvoice />
      </InviteToOrganizationConfirmationDialogContent>
      <DialogFooter onClose={onClose} hasCancelAction>
        <TakeTurnsDefaultButton
          startIcon={loadingInviteToOrganization && <CircularProgress size={16} color={"secondary"} />}
          disabled={loadingInviteToOrganization}
          onClick={sendInvitationToOrganizationAndCloseConfirmationDialog}
        >
          {t("OrganizationInviteMemberForm.InviteToOrganizationConfirmationDialog.confirm")}
        </TakeTurnsDefaultButton>
      </DialogFooter>
    </Dialog>
  );
};

const InviteToOrganizationConfirmationDialogContent = styled(DialogContent)({
  display: "flex",
  flexDirection: "column",
  paddingTop: "24px",
});

const AddOrganizationMemberInvoice = () => {
  const { planTierName, planPeriod } = useFetchUserSubscriptionPlanTierAndPeriodRule();
  const { invoice, loadingAddOrganizationMemberInvoice, errorOnAddOrganizationMemberInvoice } =
    useFetchAddOrganizationMemberInvoiceRule();
  const { t, i18n } = useOrganizationTranslation();

  if (loadingAddOrganizationMemberInvoice) {
    return <CircularProgress sx={{ alignSelf: "center" }} />;
  }

  if (errorOnAddOrganizationMemberInvoice) {
    console.error(errorOnAddOrganizationMemberInvoice);
    return <Typography>{t("OrganizationInviteMemberForm.InviteToOrganizationConfirmationDialog.error")}</Typography>;
  }

  const currencyLogo = invoice!.currency === SubscriptionPlanCurrency.Eur ? "€" : "$";
  const unitPrice = Number(invoice!.unitPrice) / 100;
  const amountDueToday = Number(invoice!.amountDueToday) / 100;
  const translatedPeriod = t(
    `OrganizationInviteMemberForm.InviteToOrganizationConfirmationDialog.period.${planPeriod}`,
  );
  const nextPaymentDate = getFormattedNextPaymentDateRule(i18n.language, invoice!.nextPeriodStartingDate as string);

  return (
    <Fragment>
      <UserPlanInvoiceTitle>
        <UserPlanTypography>
          {t("OrganizationInviteMemberForm.InviteToOrganizationConfirmationDialog.currentPlan")}
          <UserPlanChip color={"primary"} label={planTierName} />
        </UserPlanTypography>
      </UserPlanInvoiceTitle>
      <UpComingInvoiceContainer>
        <Divider />
        <UpComingPayment__Element>
          <Typography>
            {t("OrganizationInviteMemberForm.InviteToOrganizationConfirmationDialog.nextPayment", {
              period: translatedPeriod,
              nextPaymentDate,
            })}
          </Typography>
          <Typography>{`${unitPrice * invoice!.quantity!}${currencyLogo}`}</Typography>
        </UpComingPayment__Element>
        <Divider />
        <UpComingPayment__Element>
          <Typography>
            {t("OrganizationInviteMemberForm.InviteToOrganizationConfirmationDialog.amountDueToday")}
          </Typography>
          <Typography>{`${amountDueToday}${currencyLogo}`}</Typography>
        </UpComingPayment__Element>
        <Typography variant={"body2"} color={TakeTurnsColors.darkGray}>
          {t("OrganizationInviteMemberForm.InviteToOrganizationConfirmationDialog.amountDueTodayExplanation", {
            price: `${unitPrice}${currencyLogo}`,
            nextPaymentDate,
          })}
        </Typography>
        <Divider />
        <UpgradePayment paymentCardLastFourDigits={invoice!.paymentCardLastFourDigits!} loading={false} error={false} />
      </UpComingInvoiceContainer>
    </Fragment>
  );
};

const UserPlanInvoiceTitle = styled(Box)({
  margin: "24px 0",
});

const UserPlanTypography = styled(Typography)({
  display: "flex",
  alignItems: "center",
  gap: "16px",
});

const UserPlanChip = styled(Chip)({
  height: "24px",
  width: "80px",
  fontSize: "16px",
  fontWeight: "600",
});

const UpComingInvoiceContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "12px",
});

const UpComingPayment__Element = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});
