/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Box, Skeleton, styled } from "@mui/material";
import { Fragment } from "react";
import { useRecoilState } from "recoil";
import { Folder } from "@taketurns/taketurns-graphql-repository";
import { FetchLoadingManager } from "@taketurns-app/utils/FetchLoadingManager";
import { AttachmentDetails } from "@taketurns-components/collaboration/CollaborationView/AttachmentDetails/components/AttachmentDetails";
import { CardView } from "@taketurns-components/collaboration/CollaborationView/CollaborationContent/CardView/CardView";
import { ContentListView } from "@taketurns-components/collaboration/CollaborationView/CollaborationContent/ListView/ContentListView";
import { ResponsiveRequestFulfillmentDialogOrDrawer } from "@taketurns-components/collaboration/CollaborationView/CollaborationContent/RequestFulfillement/ResponsiveRequestFulfillmentDialogOrDrawer";
import { SomethingWentWrong } from "@taketurns-components/shared/SomethingWentWrong";
import { useGetCurrentFolderIdInCollaborationView } from "@taketurns-repositories/collaboration/state/read/collaborationView/useGetCurrentFolderIdInCollaborationView";
import { displayContentViewAsCardState } from "@taketurns-repositories/collaboration/state/recoil/displayContentViewAsCardState";
import { useAttachFilesIntoFolderAndMoveIntoItRule } from "@taketurns-rules/collaboration/commands/collaborationView/attachment/useAttachFilesIntoFolderAndMoveIntoItRule";
import { useAddOrManageContentPermission } from "@taketurns-rules/collaboration/permissions/useAddOrManageContentPermission";
import { useFetchCollaborationContentMetadataRule } from "@taketurns-rules/collaboration/queries/fields/useFetchCollaborationContentMetadataRule";
import { useFetchFolderRule } from "@taketurns-rules/collaboration/queries/folder/useFetchFolderRule";
import { useSubscribeToRebasedContentRule } from "@taketurns-rules/collaboration/subscriptions/updates/useSubscribeToRebasedContentRule";
import { useSubscribeToFolderContentChangesRule } from "@taketurns-rules/collaboration/subscriptions/useSubscribeToFolderContentChangesRule";
import { useIsOnMobileRule } from "@taketurns-rules/commons/theme/useIsOnMobileRule";
import { CollaborationFilesTip } from "./CollaborationFilesTip";
import { ContentFooterMenuProvider } from "./ContentFooterMenu/ContentFooterMenuProvider";
import { ContentWithDropZone } from "./ContentWithDropZone";

interface ContentBrowserProps {
  displayUpdatedOnly?: boolean;
}

export const ContentBrowser = (props: ContentBrowserProps) => {
  const [isContentDisplayedAsCards, setIsContentDisplayedAsCards] = useRecoilState(displayContentViewAsCardState);
  const toggleViewMode = () => {
    setIsContentDisplayedAsCards((currentDisplay) => !currentDisplay);
  };

  const isOnMobile = useIsOnMobileRule();
  const isUserAllowedToEditContent = useAddOrManageContentPermission();

  const folderId = useGetCurrentFolderIdInCollaborationView();
  const { folder, contentMetadata, loading, error } = useFetchContentBrowserDataRule(folderId);

  useSubscribeToFolderContentChangesRule(folderId);
  useSubscribeToRebasedContentRule();

  const isFirstLoading = loading && !folder;

  if (error) {
    return <SomethingWentWrong />;
  }

  const shouldDisplayCollaborationFilesTip = !!(folder?.attachmentCount && isUserAllowedToEditContent && !isOnMobile);
  return (
    <Fragment>
      <TurnContent__Container>
        {shouldDisplayCollaborationFilesTip && <CollaborationFilesTip style={{ alignSelf: "flex-start" }} />}
        {isUserAllowedToEditContent && (
          <ContentWithDropZone>
            <Content
              isFirstLoading={isFirstLoading}
              isContentDisplayedAsCards={isContentDisplayedAsCards}
              displayUpdatedOnly={props.displayUpdatedOnly}
              folder={folder}
            />
          </ContentWithDropZone>
        )}
        {!isUserAllowedToEditContent && (
          <Content
            isFirstLoading={isFirstLoading}
            isContentDisplayedAsCards={isContentDisplayedAsCards}
            displayUpdatedOnly={props.displayUpdatedOnly}
            folder={folder}
          />
        )}
      </TurnContent__Container>
      <ContentFooterMenuProvider
        editable={isUserAllowedToEditContent}
        displayContentAsCard={isContentDisplayedAsCards}
        onToggleViewModeClick={toggleViewMode}
        isEmptyContent={contentMetadata?.hasNoContent}
      />
      <AttachmentDetails />
      <ResponsiveRequestFulfillmentDialogOrDrawer />
    </Fragment>
  );
};

const useFetchContentBrowserDataRule = (folderId: string) => {
  const { folder, loadingFolder, errorOnFetchingFolder } = useFetchFolderRule(folderId);
  const {
    contentMetadata,
    loading: loadingContentMetadata,
    error: errorWhileFetchingContentMetadata,
  } = useFetchCollaborationContentMetadataRule();

  const fetchLoadingManager = new FetchLoadingManager(
    [loadingFolder, loadingContentMetadata],
    [errorOnFetchingFolder, errorWhileFetchingContentMetadata],
  );

  const loading = fetchLoadingManager.hasFetchLoading();
  const error = fetchLoadingManager.hasFetchInError();
  if (error) {
    fetchLoadingManager.logErrors();
  }

  return {
    folder,
    loadingFolder,
    errorOnFetchingFolder,
    contentMetadata,
    loadingContentMetadata,
    errorWhileFetchingContentMetadata,
    loading,
    error,
  };
};

interface ContentProps {
  isFirstLoading: boolean;
  isContentDisplayedAsCards: boolean;
  displayUpdatedOnly: boolean;
  folder: Folder;
}

const Content = ({ isFirstLoading, isContentDisplayedAsCards, displayUpdatedOnly, folder }: ContentProps) => {
  const isOnMobile = useIsOnMobileRule();
  const isUserAllowedToEditContent = useAddOrManageContentPermission();

  const moveIntoFolderAndAddFiles = useAttachFilesIntoFolderAndMoveIntoItRule();
  const dropZoneConfigBuilder = (folderId: string) => ({
    handleFilesDropping: (files: File[]) => moveIntoFolderAndAddFiles(files, folderId),
    multiple: true,
    className: "FolderCard__DropZone",
    sx: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
    },
  });

  if (isFirstLoading) {
    return (
      <Box sx={{ paddingX: { xs: 0, md: "24px" }, margin: { xs: "16px auto", md: 0 } }}>
        <Skeleton width="100%" />
      </Box>
    );
  }
  if (!isOnMobile && isContentDisplayedAsCards) {
    return (
      <CardView
        backBreadcrumbLabel={folder.name}
        attachments={folder.attachments}
        requests={folder.requests}
        folders={folder.folders}
        displayUpdatedOnly={displayUpdatedOnly}
        editable={isUserAllowedToEditContent}
        dropZoneConfigBuilder={dropZoneConfigBuilder}
      />
    );
  }
  return (
    <ContentListView
      backBreadcrumbLabel={folder.name}
      attachments={folder.attachments}
      requests={folder.requests}
      folders={folder.folders}
      displayUpdatedOnly={displayUpdatedOnly}
      editable={isUserAllowedToEditContent}
      dropZoneConfigBuilder={dropZoneConfigBuilder}
    />
  );
};

const TurnContent__Container = styled(Box)(({ theme }) => ({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  gap: 24,
  marginBottom: "50px !important",
  padding: "2px 24px",
  height: "100%",
  minWidth: 0,
  overflow: "auto",
  [theme.breakpoints.down("md")]: {
    padding: "0 0 env(safe-area-inset-bottom)",
    height: "auto",
    overflow: "initial",
  },
}));
