/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useSetRecoilState } from "recoil";
import { RequestInCreation } from "../../../../models/requestInCreation";
import { requestByIdState } from "../../../recoil/collaborationCreation/requestByIdState";

export const useRenameRequestInCreation = (requestId: string) => {
  const setRequestName = useSetRecoilState(requestByIdState(requestId));
  return (name: string) => {
    setRequestName((requestInCreation: RequestInCreation) => ({ ...requestInCreation, name }));
  };
};
