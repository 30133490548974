/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { gql, useApolloClient } from "@apollo/client";
import { CollaborationContentMetadata } from "@taketurns/taketurns-graphql-repository";

export const useGetContentMetadataInCache = () => {
  const { cache } = useApolloClient();

  const CONTENT_METADATA_FRAGMENT = gql`
    fragment ContentMetadataFromCache on CollaborationContentMetadata {
      id
      partyId
      hasPendingUpdates
      todoCount
      requestToFulfillCount
      attachmentToReviewCount
      lastUpdateCount
    }
  `;

  return (collaborationId: string) => {
    return cache.readFragment<CollaborationContentMetadata>({
      id: cache.identify({ __typename: "CollaborationContentMetadata", id: collaborationId }),
      fragment: CONTENT_METADATA_FRAGMENT,
      fragmentName: "ContentMetadataFromCache",
      returnPartialData: true,
    });
  };
};
