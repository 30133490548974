/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Skeleton, Stack, Typography } from "@mui/material";
import { EventOnCollaboration } from "@taketurns/taketurns-graphql-repository";
import { FetchLoadingManager } from "@taketurns-app/utils/FetchLoadingManager";
import { FilterToggle } from "@taketurns-components/collaboration/CollaborationView/CollaborationContent/ContentHeader/FilterToggle";
import { RecallUpdatesButton } from "@taketurns-components/collaboration/CollaborationView/CollaborationContent/ContentHeader/RecallUpdates/RecallUpdatesButton";
import { EventNoteButtonWithDialog } from "@taketurns-components/shared/EventNoteButtonWithDialog";
import { SomethingWentWrong } from "@taketurns-components/shared/SomethingWentWrong";
import { useSharedCollaborationTranslation } from "@taketurns-i18n/collaboration/shared/useSharedCollaborationTranslation";
import { useGetDisplayedCollaborationIdRule } from "@taketurns-rules/collaboration/queries/collaboration/useGetDisplayedCollaborationIdRule";
import { useFetchActionsPermissionsOnUpdatesRule } from "@taketurns-rules/collaboration/queries/collaborationView/useFetchActionsPermissionsOnUpdatesRule";
import { useFetchCollaborationContentMetadataRule } from "@taketurns-rules/collaboration/queries/fields/useFetchCollaborationContentMetadataRule";
import { useFetchCollaborationLastUpdateEventRule } from "@taketurns-rules/collaboration/queries/fields/useFetchCollaborationLastUpdateEventRule";
import { useDurationLabelFromDateRule } from "@taketurns-rules/collaboration/ui/useDurationLabelFromDateRule";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { useIsOnMobileRule } from "@taketurns-rules/commons/theme/useIsOnMobileRule";
import { useGetUserColorRule } from "@taketurns-rules/user/queries/useGetUserColorRule";

interface ContentHeaderLineProps {
  displayUpdatedOnly: boolean;
  setDisplayUpdatedOnly: (displayUpdatedOnly: boolean) => void;
}

export const ContentHeadline = (props: ContentHeaderLineProps) => {
  const { collaborationId, lastUpdateCount, lastUpdateEvent, canRecallUpdates, loading, error } =
    useFetchContentHeadlineDataRule();

  const getUserColor = useGetUserColorRule();

  const isOnMobile = useIsOnMobileRule();
  if (loading) {
    return <Skeleton width={1} />;
  }
  if (error) {
    return <SomethingWentWrong />;
  }

  return (
    <Stack
      direction={isOnMobile ? "column" : "row"}
      spacing={{ xs: 1.5, md: 2 }}
      minWidth={0}
      width={isOnMobile ? "100%" : "auto"}
    >
      <LastUpdateNotice lastUpdateEvent={lastUpdateEvent} />
      <Stack direction="row" spacing={2} alignItems="center" flex="0 0 auto">
        {lastUpdateCount > 0 && (
          <UpdateFilterToggle
            isFromConnectedUserParty={lastUpdateEvent.isFromConnectedUserParty}
            updateCount={lastUpdateCount}
            isToggled={props.displayUpdatedOnly}
            toggle={() => props.setDisplayUpdatedOnly(!props.displayUpdatedOnly)}
          />
        )}
        {lastUpdateEvent.note && (
          <EventNoteButtonWithDialog
            collaborationId={collaborationId}
            eventId={lastUpdateEvent.id}
            color={getUserColor(lastUpdateEvent.userId)}
          />
        )}
        {canRecallUpdates && isOnMobile && (
          <Stack flex={1} direction="row" justifyContent="flex-end">
            <RecallUpdatesButton />
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

interface LastUpdateNoticeProps {
  lastUpdateEvent: EventOnCollaboration;
}

const LastUpdateNotice = (props: LastUpdateNoticeProps) => {
  const computeDurationLabelFromDate = useDurationLabelFromDateRule();
  const { t } = useSharedCollaborationTranslation();

  const blueColor = props.lastUpdateEvent.isFromConnectedUserParty
    ? TakeTurnsColors.connectedUser
    : TakeTurnsColors.otherParty;
  return (
    <Typography noWrap color={TakeTurnsColors.darkGray} fontSize="0.875rem">
      {`${t("ContentHeadline.lastUpdate")} ${computeDurationLabelFromDate(props.lastUpdateEvent.date, true)} ${t("ContentHeadline.by")} `}
      <Typography component="span" noWrap color={blueColor} title={props.lastUpdateEvent.partyName} fontSize="0.875rem">
        {props.lastUpdateEvent.partyName}
      </Typography>
    </Typography>
  );
};

interface UpdateFilterToggleProps {
  updateCount: number;
  isFromConnectedUserParty: boolean;
  isToggled: boolean;
  toggle: () => void;
}

const UpdateFilterToggle = (props: UpdateFilterToggleProps) => {
  const { t } = useSharedCollaborationTranslation();
  const blueColor = props.isFromConnectedUserParty ? TakeTurnsColors.connectedUser : TakeTurnsColors.otherParty;

  return (
    <FilterToggle isToggled={props.isToggled} toggle={props.toggle} color={blueColor}>
      {t("ContentHeadline.update", { count: props.updateCount })}
    </FilterToggle>
  );
};

function useFetchContentHeadlineDataRule(): {
  collaborationId: string;
  lastUpdateEvent: EventOnCollaboration;
  lastUpdateCount: number;
  canRecallUpdates: boolean;
  loading: boolean;
  error: boolean;
} {
  const collaborationId = useGetDisplayedCollaborationIdRule();
  const {
    contentMetadata,
    loading: loadingContentMetadata,
    error: errorWhileFetchingContentMetadata,
  } = useFetchCollaborationContentMetadataRule();
  const { lastUpdateEvent, recallLastUpdatesAvailable, loadingLastUpdateEvent, errorWhileFetchingLastUpdateEvent } =
    useFetchCollaborationLastUpdateEventRule();

  const { canRecallUpdates, loadingActionsPermissionsOnUpdates, errorOnFetchingActionsPermissionsOnUpdates } =
    useFetchActionsPermissionsOnUpdatesRule();

  const fetchLoadingManager = new FetchLoadingManager(
    [loadingContentMetadata, loadingLastUpdateEvent, loadingActionsPermissionsOnUpdates],
    [errorWhileFetchingContentMetadata, errorWhileFetchingLastUpdateEvent, errorOnFetchingActionsPermissionsOnUpdates],
  );

  if (fetchLoadingManager.hasFetchInError()) {
    fetchLoadingManager.logErrors();
  }

  return {
    collaborationId,
    lastUpdateCount: contentMetadata?.lastUpdateCount,
    lastUpdateEvent: lastUpdateEvent,
    canRecallUpdates: canRecallUpdates && recallLastUpdatesAvailable,
    loading: fetchLoadingManager.hasFetchLoading(),
    error: fetchLoadingManager.hasFetchInError(),
  };
}
