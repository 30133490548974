/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useGetCurrentFolderIdInCollaborationView } from "@taketurns-repositories/collaboration/state/read/collaborationView/useGetCurrentFolderIdInCollaborationView";
import { useGetFolderSelectionStateById } from "@taketurns-repositories/collaboration/state/read/contentSelection/useGetFolderSelectionStateById";
import { useToggleFolderSelectionStateRule } from "@taketurns-rules/collaboration/commands/collaborationView/contentSelection/useToggleFolderSelectionStateRule";
import { useIsContentSelectionStateActiveRule } from "@taketurns-rules/collaboration/queries/collaborationView/contentSelection/useIsContentSelectionStateActiveRule";

export const useGetFolderSelectionStateInCurrentFolderByIdRule = (folderId: string) => {
  const isContentSelectionActive = useIsContentSelectionStateActiveRule();
  const isFolderSelected = useGetFolderSelectionStateById(folderId);
  const toggleFolderSelection = useToggleFolderSelectionStateRule(folderId);

  const currentFolderId = useGetCurrentFolderIdInCollaborationView();
  const isCurrentFolderSelected = useGetFolderSelectionStateById(currentFolderId);

  const toggleSelection = () => {
    if (isCurrentFolderSelected) return;
    return toggleFolderSelection();
  };

  return {
    isContentSelectionActive,
    isFolderSelected: isFolderSelected || isCurrentFolderSelected,
    toggleSelection,
  };
};
