/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useRecoilValue } from "recoil";
import { Party } from "@taketurns/taketurns-graphql-repository";
import { selectCollaborationFormPartyNameByParty } from "../../../recoil/collaborationCreation/form/values/selectCollaborationFormPartyNameByParty";

export const useGetCollaborationFormPartyNameByParty = (party: Party) => {
  return useRecoilValue(selectCollaborationFormPartyNameByParty(party));
};
