/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { registerLicense } from "@syncfusion/ej2-base";
import { StrictMode } from "react";
import * as ReactDOM from "react-dom/client";
import { registerSW } from "virtual:pwa-register";
import { App } from "./app/App";
import "./i18n/i18n";
import "./sentry/sentryConfiguration";
import { getEnvironmentVariable } from "./getEnvironmentVariable";

registerSW({ immediate: true });
registerLicense(getEnvironmentVariable("SYNCFUSION_LICENSE_KEY"));

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <StrictMode>
    <App />
  </StrictMode>,
);
