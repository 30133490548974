/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useAddFolder } from "@taketurns-repositories/collaboration/graphql/mutations/content/folders/useAddFolder";
import { useGetCurrentFolderIdInCollaborationView } from "@taketurns-repositories/collaboration/state/read/collaborationView/useGetCurrentFolderIdInCollaborationView";
import { useGetDisplayedCollaborationIdRule } from "@taketurns-rules/collaboration/queries/collaboration/useGetDisplayedCollaborationIdRule";

export const useAddFolderRule = () => {
  const collaborationId = useGetDisplayedCollaborationIdRule();
  const folderId = useGetCurrentFolderIdInCollaborationView();

  const { addFolderMutation, addFolderResponseData, addFolderLoading, errorOnAddingFolder } =
    useAddFolder(collaborationId);
  return {
    addFolder: (name: string) => addFolderMutation(folderId, name),
    addFolderResponseData: addFolderResponseData,
    addFolderLoading: addFolderLoading,
    errorOnAddingFolder: errorOnAddingFolder,
  };
};
