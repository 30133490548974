/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Box, Button, IconButton, Stack, styled } from "@mui/material";
import { FunctionComponent, KeyboardEvent, useRef, useState } from "react";
import { IconFolderPlus } from "@taketurns-components/commons/icons";
import { useSharedCollaborationTranslation } from "@taketurns-i18n/collaboration/shared/useSharedCollaborationTranslation";
import { HelpCenterSections } from "@taketurns-rules/commons/enum/helpCenterSectionsEnum";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { useIsOnMobileRule } from "@taketurns-rules/commons/theme/useIsOnMobileRule";
import { TakeTurnsDefaultButton } from "../../commons/button";
import { DialogFooter } from "../../commons/dialog/DialogFooter";
import { ResponsiveDialogContent, ResponsiveDialogV2 } from "../../commons/dialog/ResponsiveDialog";
import { HelpCenterTextButton } from "../../webapp/HelpCenterTextButton";

export interface MoveDestinationDialogProps {
  rootFolderId: string;
  onSubmitDestination: (folderId: string) => void;
  open: boolean;
  onClose: () => void;
  excludedFolderId?: string;
  movingElementSourceFolderId: string;
  DestinationFolderListSlot: FunctionComponent<DestinationFolderListSlotProps>;
}

interface DestinationFolderListSlotProps {
  excludedFolderId?: string;
  onSelectedFolderChange: (selectedFolderId: string) => void;
  isAddFolderInputVisible: boolean;
  cancelAddFolder: () => void;
}

export const MoveDestinationDialog = (props: MoveDestinationDialogProps) => {
  const closeDialogOnEscapeWhenNotInInput = (event: KeyboardEvent<HTMLDivElement>) => {
    const ESCAPE_KEY = "Escape";
    if (event.key === ESCAPE_KEY) {
      closeDialogWhenNotInInput();
    }

    function closeDialogWhenNotInInput() {
      if ("tagName" in event.target && event.target.tagName === "INPUT") {
        return;
      }
      props.onClose();
    }
  };

  return (
    <MoveDestination__Dialog
      disableEscapeKeyDown
      onKeyDown={closeDialogOnEscapeWhenNotInInput}
      open={props.open}
      onClose={props.onClose}
      maxWidth="md"
      fullWidth
    >
      <MoveDestinationDialogContent {...props} />
    </MoveDestination__Dialog>
  );
};

export interface MoveDestinationDialogContentProps {
  DestinationFolderListSlot: FunctionComponent<DestinationFolderListSlotProps>;
  excludedFolderId?: string;
  movingElementSourceFolderId: string;
  rootFolderId: string;
  onClose: () => void;
  onSubmitDestination: (folderId: string) => void;
}

export const MoveDestinationDialogContent = (props: MoveDestinationDialogContentProps) => {
  const DestinationFolderListSlot = props.DestinationFolderListSlot;
  const [isAddFolderInputVisible, setIsAddFolderInputVisible] = useState(false);
  const [isSelectedPathValid, setIsSelectedPathValid] = useState(
    props.movingElementSourceFolderId !== props.rootFolderId,
  );

  const selectedFolderPathRef = useRef<string>(props.rootFolderId);
  const onSelectedFolderChange = (selectedFolderPath: string) => {
    if (selectedFolderPathRef.current === selectedFolderPath) return;
    selectedFolderPathRef.current = selectedFolderPath;
    setIsAddFolderInputVisible(false);
    setIsSelectedPathValid(selectedFolderPath !== props.movingElementSourceFolderId);
  };

  const closeDialog = () => {
    selectedFolderPathRef.current = undefined;
    setIsAddFolderInputVisible(false);
    props.onClose();
  };

  const { t } = useSharedCollaborationTranslation();

  return (
    <ResponsiveDialogContent
      title={
        <MoveDestinationDialogTitle
          hideButton={isAddFolderInputVisible}
          onAddFolderButtonClick={() => setIsAddFolderInputVisible(true)}
        />
      }
      body={
        <DestinationFolderListSlot
          excludedFolderId={props.excludedFolderId}
          onSelectedFolderChange={onSelectedFolderChange}
          isAddFolderInputVisible={isAddFolderInputVisible}
          cancelAddFolder={() => setIsAddFolderInputVisible(false)}
        />
      }
      footer={
        <DialogFooter
          helpSection={<HelpCenterTextButton helpCenterSection={HelpCenterSections.MOVE} />}
          onClose={closeDialog}
        >
          <TakeTurnsDefaultButton
            data-cy="MoveDestinationDialog.MoveButton"
            disabled={!isSelectedPathValid}
            onClick={() => props.onSubmitDestination(selectedFolderPathRef.current)}
          >
            {t("MoveDestinationDialog.moveButton")}
          </TakeTurnsDefaultButton>
        </DialogFooter>
      }
    />
  );
};

interface MoveDestinationDialogTitleProps {
  hideButton?: boolean;
  onAddFolderButtonClick: () => void;
}

const MoveDestinationDialogTitle = (props: MoveDestinationDialogTitleProps) => {
  const isOnMobile = useIsOnMobileRule();
  const { t } = useSharedCollaborationTranslation();

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center" height="40px">
      {isOnMobile && <EmptyBox />}
      {t("MoveDestinationDialog.title")}
      {props.hideButton ? <EmptyBox /> : <AddFolderButton onClick={props.onAddFolderButtonClick} />}
    </Stack>
  );
};

const EmptyBox = styled(Box)(({ theme }) => ({
  width: "24px",
  padding: theme.spacing(2.5),
}));

const AddFolderButton = (props: { onClick: () => void }) => {
  const isOnMobile = useIsOnMobileRule();
  const { t } = useSharedCollaborationTranslation();

  if (isOnMobile) {
    return (
      <IconButton
        disableFocusRipple
        disableTouchRipple
        disableRipple
        onClick={props.onClick}
        data-cy="MoveDestinationDialog.AddFolderButton"
      >
        <IconFolderPlus size={24} color={TakeTurnsColors.grayButton} />
      </IconButton>
    );
  }

  return (
    <Button
      startIcon={<IconFolderPlus size={24} />}
      variant={"text"}
      onClick={props.onClick}
      sx={{ "& .MuiButton-startIcon": { mr: { xs: "3px", lg: "6px" } } }}
      data-cy="MoveDestinationDialog.AddFolderButton"
    >
      {t("MoveDestinationDialog.newFolder")}
    </Button>
  );
};

const MoveDestination__Dialog = styled(ResponsiveDialogV2)(({ theme }) => ({
  "& .MuiDialog-paper": {
    [theme.breakpoints.up("md")]: {
      height: "533px",
    },
  },
  "& .MuiDialogContent-root": {
    padding: 0,
  },
}));
