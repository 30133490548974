/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { List, ListItem, ListItemIcon, ListItemText, Stack, Typography } from "@mui/material";
import { ReactNode } from "react";
import { HelpIconWithTooltip } from "@taketurns-components/shared/HelpIconWithTooltip";
import { useUserPlanTranslation } from "@taketurns-i18n/user/UserPlans/useUserPlanTranslation";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { IconCheck } from "../../../../commons/icons";

export const SubscriptionPlanElementFeatures = ({
  planFeatureDescription,
  featureKeys,
  comingSoonFeatureKeys,
}: {
  featureKeys: string[];
  planFeatureDescription?: string;
  comingSoonFeatureKeys?: string[];
}) => {
  const { t } = useUserPlanTranslation();

  return (
    <Stack padding={3} spacing={2}>
      <FeatureList
        featureKeys={featureKeys}
        ListSubHeader={
          planFeatureDescription ? (
            <Typography fontSize="0.875rem" lineHeight="1.8">
              {t(`descriptions.${planFeatureDescription}`)}:
            </Typography>
          ) : null
        }
      />
      {comingSoonFeatureKeys?.length > 0 && (
        <FeatureList
          featureKeys={comingSoonFeatureKeys!}
          notYetAvailable={true}
          ListSubHeader={
            <Typography fontSize="0.875rem" lineHeight="1.8" color={TakeTurnsColors.darkGray}>
              {t("comingSoon")}:
            </Typography>
          }
        />
      )}
    </Stack>
  );
};

const FeatureList = ({
  featureKeys,
  ListSubHeader,
  notYetAvailable,
}: {
  featureKeys: string[];
  ListSubHeader?: ReactNode;
  notYetAvailable?: boolean;
}) => {
  return (
    <List dense disablePadding subheader={ListSubHeader}>
      {featureKeys.map((featureKey) => (
        <ListItem key={featureKey} disablePadding sx={{ gap: "10px", padding: "4px 0" }}>
          <ListItemIcon sx={{ minWidth: "initial" }}>
            <IconCheck color={TakeTurnsColors.darkGray} size={18} />
          </ListItemIcon>
          <ListItemText
            primary={<FeatureTitleWithOptionalTooltip featureKey={featureKey} notYetAvailable={notYetAvailable} />}
            sx={{ margin: 0 }}
          />
        </ListItem>
      ))}
    </List>
  );
};

const FeatureTitleWithOptionalTooltip = ({
  featureKey,
  notYetAvailable,
}: {
  featureKey: string;
  notYetAvailable?: boolean;
}) => {
  const { t, i18n } = useUserPlanTranslation();
  return (
    <Stack direction="row">
      <Typography color={notYetAvailable && TakeTurnsColors.darkGray} fontSize="0.875rem" lineHeight={1.8}>
        {t(`features.${featureKey}`)}
      </Typography>
      {i18n.exists(`featureTooltip.${featureKey}`, {
        ns: "userPlan",
      }) && <HelpIconWithTooltip helpText={t(`featureTooltip.${featureKey}`)} />}
    </Stack>
  );
};
