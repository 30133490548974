/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { ContentStatus as ContentStatusEnum, Folder } from "@taketurns/taketurns-graphql-repository";
import { IconArrowRightCircle, IconChevronsLeft, IconRename, IconTrash } from "@taketurns-components/commons/icons";
import { useRestoreFolderRule } from "@taketurns-rules/collaboration/commands/collaborationView/folder/useRestoreFolderRule";
import { useRemoveFolderRule } from "../../commands/collaborationView/folder/useRemoveFolderRule";

export const useGetFolderContextMenuConfigurationRule = (
  folder: Folder,
  openEditFolder: () => void,
  openMoveFolderDialog: (folderId: string) => void,
) => {
  const { removeFolder } = useRemoveFolderRule(folder.id);
  const { restoreFolder } = useRestoreFolderRule(folder);
  const ICON_SIZE = 14;

  if (folder.status === ContentStatusEnum.Removed) {
    return {
      contextMenuItems: [
        {
          labelKey: "contextMenu.restore",
          icon: <IconChevronsLeft size={ICON_SIZE} />,
          action: restoreFolder,
        },
      ],
    };
  }

  return {
    contextMenuItems: [
      {
        labelKey: "contextMenu.rename",
        icon: <IconRename size={ICON_SIZE} />,
        action: openEditFolder,
      },
      {
        labelKey: "contextMenu.move",
        icon: <IconArrowRightCircle size={ICON_SIZE} />,
        action: openMoveFolderDialog,
      },
      {
        labelKey: "contextMenu.remove",
        icon: <IconTrash size={ICON_SIZE} />,
        action: removeFolder,
      },
    ],
  };
};
