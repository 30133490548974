/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Chip, Grid, Skeleton, Stack, Typography } from "@mui/material";
import { Invitee, Participant, Party } from "@taketurns/taketurns-graphql-repository";
import { InvitationForm } from "@taketurns-components/collaboration/Shared/ParticipantInviteeList/InvitationForm";
import { InvitationItemComponent } from "@taketurns-components/collaboration/Shared/ParticipantInviteeList/InvitationItemComponent";
import { ParticipantInvitationList } from "@taketurns-components/collaboration/Shared/ParticipantInviteeList/ParticipantInvitationList";
import { ParticipantItemComponent } from "@taketurns-components/collaboration/Shared/ParticipantInviteeList/ParticipantItemComponent";
import { SomethingWentWrong } from "@taketurns-components/shared/SomethingWentWrong";
import { useCollaborationTranslation } from "@taketurns-i18n/collaboration/useCollaborationTranslation";
import { useInviteToCollaborationRule } from "@taketurns-rules/collaboration/commands/collaborationView/participants/useInviteToCollaborationRule";
import { useShouldDisplayInvitationFormRule } from "@taketurns-rules/collaboration/queries/collaborationView/useShouldDisplayInvitationFormRule";
import { useFetchConnectedUserPartyRule } from "@taketurns-rules/collaboration/queries/context/useFetchConnectedUserPartyRule";
import { useFetchCollaborationPartyNamesRule } from "@taketurns-rules/collaboration/queries/fields/useFetchCollaborationPartyNamesRule";
import { getColorForUserParty } from "@taketurns-rules/collaboration/utils/color/TakeTurnsBlueColorsUtils";

interface ParticipantPanelProps {
  invitations: Invitee[];
  participants: Participant[];
  userParty: Party;
  party: Party;
  addBorder?: boolean;
}

export const ParticipantPanel = (props: ParticipantPanelProps) => {
  const { t } = useCollaborationTranslation("participantCollaboration");

  const { owningPartyName, invitedPartyName, loadingCollaborationPartyNames, errorOnFetchingCollaborationPartyNames } =
    useFetchCollaborationPartyNamesRule();

  const { connectedUserParty, errorOnFetchingConnectedUserParty, loadingConnectedUserParty } =
    useFetchConnectedUserPartyRule();

  const { inviteToCollaboration, loadingInviteToCollaboration } = useInviteToCollaborationRule();

  const filteredParticipants = props.participants.filter((participant) => participant.party === props.party);

  const filteredInvitees = props.invitations.filter((invitee) => invitee.party === props.party);
  const shouldDisplayInvitationForm = useShouldDisplayInvitationFormRule();
  const PartyName = () => {
    if (errorOnFetchingCollaborationPartyNames) {
      return <SomethingWentWrong />;
    }
    if (loadingCollaborationPartyNames) {
      return <Skeleton variant="text" width={200} />;
    }
    return (
      <Typography noWrap fontSize={14}>
        {props.party === Party.Owning ? owningPartyName : invitedPartyName}
      </Typography>
    );
  };

  if (errorOnFetchingConnectedUserParty) {
    return <SomethingWentWrong />;
  }

  if (loadingConnectedUserParty) {
    return (
      <Grid item xs={12} md={6} lg={6}>
        <Stack
          component="div"
          padding={"24px"}
          spacing={3}
          height={"100%"}
          sx={props.addBorder && { borderRight: "1px solid #DDDDDD" }}
        >
          <Stack direction="row" justifyContent={"center"}>
            <Skeleton variant="circular" width={40} height={40} />
          </Stack>
        </Stack>
      </Grid>
    );
  }

  return (
    <Grid item xs={12} md={6} lg={6}>
      <Stack
        component="div"
        padding={"24px"}
        spacing={3}
        height={"100%"}
        sx={props.addBorder && { borderRight: "1px solid #DDDDDD" }}
      >
        <Stack direction="row" justifyContent={"center"}>
          <Chip color={getColorForUserParty(props.party, props.userParty).themeColor} label={<PartyName />} />
        </Stack>

        {shouldDisplayInvitationForm && (
          <InvitationForm
            buttonLabel={t("invite")}
            onButtonClick={inviteToCollaboration}
            partyToInviteTo={props.party}
            connectedUserParty={connectedUserParty}
          />
        )}

        <ParticipantInvitationList
          connectedUserParty={connectedUserParty}
          ParticipantItemComponent={ParticipantItemComponent}
          InvitationItemComponent={InvitationItemComponent}
          color={getColorForUserParty(props.party, props.userParty)}
          participants={filteredParticipants}
        />
        {filteredInvitees.length !== 0 && (
          <Typography>{t("pendingInvitation", { count: filteredInvitees.length })}</Typography>
        )}
        <ParticipantInvitationList
          connectedUserParty={connectedUserParty}
          ParticipantItemComponent={ParticipantItemComponent}
          InvitationItemComponent={InvitationItemComponent}
          color={getColorForUserParty(props.party, props.userParty)}
          participants={filteredParticipants}
          invitees={filteredInvitees}
          addElementLoading={loadingInviteToCollaboration}
        />
      </Stack>
    </Grid>
  );
};
