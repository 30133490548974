/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Stack } from "@mui/material";
import { Fragment } from "react";
import { Request } from "@taketurns/taketurns-graphql-repository";
import { ContentElementLastUpdate } from "@taketurns-components/collaboration/CollaborationView/CollaborationContent/shared/components/ContentElementLastUpdate";
import { ContextMenuOnButton } from "@taketurns-components/collaboration/ContextMenu/ContextMenuOnButton";
import {
  RequestIconMarker,
  RequestUpdateMarker,
  RequestWordMarker,
} from "@taketurns-components/collaboration/Shared/content/Markers";
import { MobileListItem } from "@taketurns-components/collaboration/Shared/list/MobileListItem";
import { useGetRequestContextMenuConfigurationRule } from "@taketurns-rules/collaboration/queries/request/useGetRequestContextMenuConfigurationRule";
import { ListItemTitle } from "../ListItemTitle";
import { RequestUploadChip } from "./RequestUploadChip";

interface MobileRequestListItemProps {
  request: Request;
  shouldDisplayLastUpdate: boolean;
  shouldDisplayRequestMarker: boolean;
  shouldDisplayUploadChip: boolean;
  shouldDisplayUpdateMarker: boolean;
  shouldStrikeTitle: boolean;
  openRequestFulfillment: () => void;
  canConnectedUserFulFillRequest: boolean;
  contextMenuConfiguration: ReturnType<typeof useGetRequestContextMenuConfigurationRule> | null;
  backgroundColor: string;
}

export const MobileRequestListItem = (props: MobileRequestListItemProps) => (
  <MobileListItem
    centerRightEndSlotVertically
    sx={{ backgroundColor: props.backgroundColor }}
    titleSlot={<ListItemTitle title={props.request.name} shouldStrikeTitle={props.shouldStrikeTitle} />}
    subtitleSlot={
      <Fragment>
        <RequestWordMarker />
        {props.shouldDisplayRequestMarker && <RequestIconMarker />}
        {props.shouldDisplayUpdateMarker && <RequestUpdateMarker request={props.request} />}
        {props.shouldDisplayLastUpdate && <ContentElementLastUpdate status={props.request.prevStatus} />}
      </Fragment>
    }
    rightEndSlot={
      <Stack direction="row" alignItems="center">
        {props.shouldDisplayUploadChip && <RequestUploadChip />}
        {props.contextMenuConfiguration && (
          <ContextMenuOnButton contextMenuConfiguration={props.contextMenuConfiguration} />
        )}
      </Stack>
    }
    onClick={props.canConnectedUserFulFillRequest ? props.openRequestFulfillment : undefined}
  />
);
