/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Stack } from "@mui/material";
import { Attachment, ContentStatus } from "@taketurns/taketurns-graphql-repository";
import {
  AttachmentPendingUpdatesMarker,
  LockMarker,
  MarkedForReviewMarker,
  ReviewListItemChip,
} from "@taketurns-components/collaboration/Shared/content/Markers";
import { useIsOnMobileRule } from "@taketurns-rules/commons/theme/useIsOnMobileRule";

export const AttachmentMarkersInList = ({ attachment }: { attachment: Attachment }) => {
  const isOnMobile = useIsOnMobileRule();
  const shouldDisplayReviewChip = !isOnMobile && attachment.toReview;
  const shouldDisplayLock = attachment.locked;
  const shouldDisplayMarkedForReviewIcon = attachment.markedForReview;
  const shouldDisplayYellowChip = attachment.status !== ContentStatus.Unchanged;

  if (!shouldDisplayReviewChip && !shouldDisplayLock && !shouldDisplayYellowChip && !shouldDisplayMarkedForReviewIcon) {
    return null;
  }

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      {shouldDisplayReviewChip && <ReviewListItemChip />}
      {shouldDisplayLock && <LockMarker />}
      {shouldDisplayYellowChip && <AttachmentPendingUpdatesMarker attachment={attachment} />}
      {shouldDisplayMarkedForReviewIcon && <MarkedForReviewMarker />}
    </Stack>
  );
};
