/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useRecoilCallback } from "recoil";
import { CancelUploadFunction } from "../models/upload";
import { UploadStatus } from "../models/uploadStatus";
import { uploadsByUploadIdState } from "../recoil/uploadsByUploadIdState";

export const useMarkUploadAsStarted = () => {
  return useRecoilCallback(
    ({ set }) =>
      (uploadId: number, documentId: string, cancelUploadFunction: CancelUploadFunction) => {
        set(uploadsByUploadIdState(uploadId), (upload) => {
          if (upload) {
            const updatedUpload = upload.copy();
            updatedUpload.documentId = documentId;
            updatedUpload.progress = 0;
            updatedUpload.status = UploadStatus.inProgress;
            updatedUpload.cancelUploadFunction = cancelUploadFunction;
            return updatedUpload;
          } else {
            throw new Error(`Cannot mark upload as started for upload with id : ${uploadId} `);
          }
        });
      },
    [],
  );
};
