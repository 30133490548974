/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Container, Stack, styled } from "@mui/material";
import { memo, ReactNode } from "react";
import { PlansFooter } from "./PlansFooter";

interface MaxWidthLayoutProps {
  header: ReactNode;
  content: ReactNode;
}

//Using Memo to prevent re-rendering on drawer opens
export const PlansLayout = memo(({ content, header }: MaxWidthLayoutProps) => {
  return (
    <PlansLayout__Container className="PlansLayout">
      <PlansLayout__Main className="PlansLayout__Main" sx={{ paddingX: { xs: 0, md: 3 } }}>
        <PlansLayout__Header>{header}</PlansLayout__Header>
        <PlansLayout__Content className="PlansLayout__Content">{content}</PlansLayout__Content>
      </PlansLayout__Main>
      <PlansFooter />
    </PlansLayout__Container>
  );
});

const PlansLayout__Container = styled(Stack)({
  height: "100%",
});

const PlansLayout__Main = styled(Container)(({ theme }) => ({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  gap: "24px",
  minHeight: 0,
  [theme.breakpoints.down("md")]: {
    gap: "12px",
  },
}));

const PlansLayout__Header = styled(Stack)(({ theme }) => ({
  justifyContent: "center",
  paddingTop: "24px",
  [theme.breakpoints.down("md")]: {
    paddingTop: "6px",
  },
}));

const PlansLayout__Content = styled(Stack)({
  flex: 1,
  alignItems: "stretch",
  /*minHeight 0 is a flex hack for children overflow to work properly*/
  minHeight: 0,
  overflow: "auto",
});
