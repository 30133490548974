/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Chip } from "@mui/material";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";

export const CollaborationListRowActionButton = (props: { label: string; disabled?: boolean }) => {
  return (
    <Chip
      size="small"
      label={props.label}
      disabled={props.disabled}
      sx={{
        height: "26px",
        padding: "0 5px",
        overflow: "hidden",
        fontSize: {
          xs: "14px",
          md: "12px",
        },
        bgcolor: TakeTurnsColors.yellow,
        color: "white",
      }}
    />
  );
};
