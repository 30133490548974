/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useSetRecoilState } from "recoil";
import { isExcludedFromCollaborationState } from "../recoil/excludedFromCollaborationState";

export const useSetIsExcludedFromCollaborationState = () => {
  return useSetRecoilState(isExcludedFromCollaborationState);
};
