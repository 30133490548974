/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { selectorFamily } from "recoil";
import { Upload } from "../models/upload";
import { uploadsByUploadIdState } from "../recoil/uploadsByUploadIdState";

export const selectUploadsByIdsState = selectorFamily<Upload[], number[]>({
  key: "selectUploadsByIds",
  get:
    (uploadIds: number[]) =>
    ({ get }) => {
      return uploadIds.map((id) => get(uploadsByUploadIdState(id)));
    },
});
