/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useFetchUserProfileIsIncompleteRule } from "@taketurns-rules/user/queries/useFetchUserProfileIsIncompleteRule";
import { useIsTrialRemainingTimeNotificationDisplayedRule } from "@taketurns-rules/user/queries/useIsTrialRemainingTimeNotificationDisplayedRule";
import { useIsUserOrganizationInvitationNotificationDisplayedRule } from "@taketurns-rules/user/queries/useIsUserOrganizationInvitationNotificationDisplayedRule";
import { IncompleteUserProfileNotification } from "./IncompleteUserProfileNotification/IncompleteUserProfileNotification";
import { OrganizationInvitationNotificationAndDialog } from "./OrganizationInvitationNotificationAndDialog/OrganizationInvitationNotificationAndDialog";
import { TrialRemainingTimeNotification } from "./TrialRemainingTimeNotification/TrialRemainingTimeNotification";

export const UserNotificationCenter = () => {
  const {
    userSubscriptionPlanTrial,
    isTrialRemainingTimeNotificationDisplayed,
    loadingIsTrialRemainingTimeNotificationDisplayed,
  } = useIsTrialRemainingTimeNotificationDisplayedRule();
  const {
    isUserOrganizationInvitationNotificationDisplayed,
    pendingOrganizationInvitation,
    loadingUserPendingOrganizationInvitation,
  } = useIsUserOrganizationInvitationNotificationDisplayedRule();
  const { userProfileIsIncomplete } = useFetchUserProfileIsIncompleteRule();

  if (loadingUserPendingOrganizationInvitation) {
    return;
  }

  if (isUserOrganizationInvitationNotificationDisplayed) {
    return (
      <OrganizationInvitationNotificationAndDialog pendingOrganizationInvitation={pendingOrganizationInvitation} />
    );
  }

  if (loadingIsTrialRemainingTimeNotificationDisplayed) {
    return;
  }

  if (isTrialRemainingTimeNotificationDisplayed) {
    return <TrialRemainingTimeNotification userSubscriptionPlanTrial={userSubscriptionPlanTrial} />;
  }

  if (userProfileIsIncomplete) {
    return <IncompleteUserProfileNotification />;
  }
};
