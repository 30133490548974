/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { IconArrowRightCircle, IconEdit, IconTrash } from "@taketurns-components/commons/icons";
import { useRemoveRequestRule } from "../../commands/collaborationView/request/useRemoveRequestRule";

export const useGetRequestContextMenuConfigurationRule = (
  requestId: string,
  editRequest: () => void,
  openMoveRequestDialog: () => void,
) => {
  const { removeRequest } = useRemoveRequestRule(requestId);
  const ICON_SIZE = 14;

  return {
    contextMenuItems: [
      {
        labelKey: "contextMenu.edit",
        icon: <IconEdit size={ICON_SIZE} />,
        action: editRequest,
      },
      {
        labelKey: "contextMenu.move",
        icon: <IconArrowRightCircle size={ICON_SIZE} />,
        action: openMoveRequestDialog,
      },
      {
        labelKey: "contextMenu.remove",
        icon: <IconTrash size={ICON_SIZE} />,
        action: removeRequest,
      },
    ],
  };
};
