/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Box, styled } from "@mui/material";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";

export const TakeTurnsCardFooter = styled(Box, {
  shouldForwardProp: (prop) => prop !== "backgroundColor",
})((props: { backgroundColor?: string }) => ({
  display: "flex",
  backgroundColor: props.backgroundColor ? props.backgroundColor : TakeTurnsColors.background,
  borderTop: `1px solid ${TakeTurnsColors.lightGray}`,
  borderRadius: "0 0 9px 9px",
  height: 24,
  minHeight: 24,
  width: "100%",
  fontSize: 9,
}));
