/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Avatar, Stack, Typography } from "@mui/material";
import { PendingInvitation, PendingInvitationInvitingUser } from "@taketurns/taketurns-graphql-repository";
import { useCollaborationListTranslation } from "@taketurns-i18n/collaboration/CollaborationList/useCollaborationListTranslation";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";

export const MobileCollaborationInvitationUserInfo = ({
  pendingInvitation,
}: {
  pendingInvitation: PendingInvitation;
}) => {
  const invitingUser = pendingInvitation.invitingUser;
  if (!invitingUser) {
    return null;
  }

  return <MobileInvitingUserInformation invitingUser={invitingUser} />;
};

interface MobileInvitingUserInformationProps {
  invitingUser: PendingInvitationInvitingUser;
}

const MobileInvitingUserInformation = ({ invitingUser }: MobileInvitingUserInformationProps) => {
  const { firstName, lastName, email, avatar, isInInviteeParty } = invitingUser;
  const color = isInInviteeParty ? TakeTurnsColors.connectedUser : TakeTurnsColors.otherParty;
  const { t } = useCollaborationListTranslation();
  const definedFirstNameAndLastName = firstName && lastName;
  const firstNameLastNameOrEmail = definedFirstNameAndLastName ? `${firstName} ${lastName}` : email;
  const userInitials = avatar ? undefined : definedFirstNameAndLastName ? firstName[0] + lastName[0] : null;

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Typography variant="caption" color={TakeTurnsColors.darkGray} sx={{ display: "inline" }}>
        {t("fromInviter")}
      </Typography>
      <Avatar
        sx={{
          width: 24,
          height: 24,
          fontSize: 14,
        }}
        src={avatar}
      >
        {userInitials}
      </Avatar>
      <Typography variant="caption" color={color} sx={{ display: "inline" }}>
        {firstNameLastNameOrEmail}
      </Typography>
    </Stack>
  );
};
