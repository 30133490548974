/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useFetchUserOrganizationNameRule } from "./useFetchUserOrganizationNameRule";

export const useFetchHasDefinedDefaultPartyNameRule = () => {
  const { userOrganizationName, loadingUserOrganizationName } = useFetchUserOrganizationNameRule();

  return {
    hasDefinedDefaultPartyName: !!userOrganizationName && userOrganizationName.trim().length !== 0,
    loadingHasDefinedDefaultPartyName: loadingUserOrganizationName,
  };
};
