/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { selector } from "recoil";
import { collaborationFormState } from "./collaborationFormState";

export const selectCollaborationFormOwningParty = selector({
  key: "collaborationCreationOwningParty",
  get: ({ get }) => {
    return get(collaborationFormState)?.owningPartyName;
  },
  set: ({ set }, owningParty) => {
    set(collaborationFormState, (currentCollaborationForm) => ({
      ...currentCollaborationForm,
      owningPartyName: owningParty as string,
    }));
  },
});
