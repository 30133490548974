/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useMutation } from "@apollo/client";
import {
  AddOrganizationDomainInput,
  MutationAddOrganizationDomainArgs,
  Organization,
} from "@taketurns/taketurns-graphql-repository";

export const useAddOrganizationDomain = () => {
  const ADD_ORGANIZATION_DOMAIN = gql`
    mutation addOrganizationDomain($addOrganizationDomainInput: AddOrganizationDomainInput!) {
      addOrganizationDomain(addOrganizationDomainInput: $addOrganizationDomainInput) {
        id
        domains {
          name
          status
          verificationCode
        }
      }
    }
  `;
  const [
    addOrganizationDomainMutation,
    { error: errorOnAddOrganizationDomain, loading: loadingAddOrganizationDomain },
  ] = useMutation<{ addOrganizationDomain: Organization }, MutationAddOrganizationDomainArgs>(ADD_ORGANIZATION_DOMAIN);
  const addOrganizationDomain = (addOrganizationDomainInput: AddOrganizationDomainInput) => {
    console.log(addOrganizationDomainInput);
    return addOrganizationDomainMutation({ variables: { addOrganizationDomainInput } });
  };
  return { addOrganizationDomain, errorOnAddOrganizationDomain, loadingAddOrganizationDomain };
};
