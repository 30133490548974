/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useGetIsExpiredTrialNotificationDisplayed } from "@taketurns-repositories/user/state/read/useGetIsExpiredTrialNotificationDisplayed";
import { useSetIsExpiredTrialNotificationDisplayed } from "@taketurns-repositories/user/state/write/useSetIsExpiredTrialNotificationDisplayed";

export const useDisplayExpiredTrialNotificationRule = () => {
  const isExpiredTrialNotificationDisplayed = useGetIsExpiredTrialNotificationDisplayed();
  const setIsExpiredTrialNotificationDisplayed = useSetIsExpiredTrialNotificationDisplayed();
  const displayExpiredTrialNotification = () => {
    setIsExpiredTrialNotificationDisplayed(true);
  };
  const hideExpiredTrialNotification = () => {
    setIsExpiredTrialNotificationDisplayed(false);
  };
  return { isExpiredTrialNotificationDisplayed, displayExpiredTrialNotification, hideExpiredTrialNotification };
};
