/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import {
  SubscriptionPlanCurrency,
  SubscriptionPlanPeriod,
  SubscriptionPlanRegion,
  SubscriptionPlanTier,
} from "@taketurns/taketurns-graphql-repository";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { Plan } from "./entities/plan";
import { SubscriptionPlanForDisplay } from "./entities/subscriptionPlanForDisplay";

const takeTurnsPlans: Plan[] = [
  {
    planTier: SubscriptionPlanTier.T1,
    pricing: [
      {
        region: SubscriptionPlanRegion.Row,
        currency: SubscriptionPlanCurrency.Eur,
        period: SubscriptionPlanPeriod.Year,
        amount: "12000",
      },
      {
        region: SubscriptionPlanRegion.Row,
        currency: SubscriptionPlanCurrency.Eur,
        period: SubscriptionPlanPeriod.Month,
        amount: "1500",
      },
      {
        region: SubscriptionPlanRegion.Row,
        currency: SubscriptionPlanCurrency.Usd,
        period: SubscriptionPlanPeriod.Year,
        amount: "12000",
      },
      {
        region: SubscriptionPlanRegion.Row,
        currency: SubscriptionPlanCurrency.Usd,
        period: SubscriptionPlanPeriod.Month,
        amount: "1500",
      },
      {
        region: SubscriptionPlanRegion.Na,
        currency: SubscriptionPlanCurrency.Usd,
        period: SubscriptionPlanPeriod.Year,
        amount: "12000",
      },
      {
        region: SubscriptionPlanRegion.Na,
        currency: SubscriptionPlanCurrency.Usd,
        period: SubscriptionPlanPeriod.Month,
        amount: "1500",
      },
    ],
  },
  {
    planTier: SubscriptionPlanTier.T2,
    pricing: [
      {
        region: SubscriptionPlanRegion.Row,
        currency: SubscriptionPlanCurrency.Eur,
        period: SubscriptionPlanPeriod.Year,
        amount: "36000",
        specialDiscount: "18000",
      },
      {
        region: SubscriptionPlanRegion.Row,
        currency: SubscriptionPlanCurrency.Eur,
        period: SubscriptionPlanPeriod.Month,
        amount: "4500",
      },
      {
        region: SubscriptionPlanRegion.Row,
        currency: SubscriptionPlanCurrency.Usd,
        period: SubscriptionPlanPeriod.Year,
        amount: "36000",
        specialDiscount: "18000",
      },
      {
        region: SubscriptionPlanRegion.Row,
        currency: SubscriptionPlanCurrency.Usd,
        period: SubscriptionPlanPeriod.Month,
        amount: "4500",
      },
      {
        region: SubscriptionPlanRegion.Na,
        currency: SubscriptionPlanCurrency.Usd,
        period: SubscriptionPlanPeriod.Year,
        amount: "36000",
        specialDiscount: "18000",
      },
      {
        region: SubscriptionPlanRegion.Na,
        currency: SubscriptionPlanCurrency.Usd,
        period: SubscriptionPlanPeriod.Month,
        amount: "4500",
      },
    ],
  },
  {
    planTier: SubscriptionPlanTier.T3,
  },
];

const planDescriptionList = {
  T1: {
    color: TakeTurnsColors.darkBlue,
    tagLineKey: "T1",
    featureKeys: [
      "unlimitedCollaboration",
      "unlimitedParticipants",
      "uploadFileAndRevision",
      "request",
      "manageParticipants",
      "chat",
      "downloadArchive",
      "100gbStorage",
    ],
    monoUser: true,
  },
  T2: {
    color: TakeTurnsColors.darkBlue,
    tagLineKey: "T2",
    planFeatureDescription: "allFreeFeatures",
    pricingDescription: "perMonthSingleUser",
    featureKeys: ["turnRecall", "ghost", "organizationManagement", "teamSupervisions", "500gbStorage", "upTo50Users"],
    monoUser: false,
    mostPopularOffer: true,
  },
  T3: {
    color: TakeTurnsColors.darkBlue,
    tagLineKey: "T3",
    featureKeys: ["dedicatedCSM", "advancedAccessManagement", "customStorage", "uncappedUserNumber"],
    monoUser: false,
    onDemandPricing: true,
    planFeatureDescription: "allPlusFeatures",
  },
};

export const mapToSubscriptionPlans: (plans: Plan[]) => SubscriptionPlanForDisplay[] = (plans: Plan[]) => {
  return Object.entries(planDescriptionList).map(([key, value]) => ({
    plan: { ...plans.find((p) => p.planTier === key) },
    ...value,
  })) as SubscriptionPlanForDisplay[];
};

export const subscriptionPlans = mapToSubscriptionPlans(takeTurnsPlans);
