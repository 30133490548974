/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useSnackbar } from "notistack";
import { useSharedCollaborationTranslation } from "@taketurns-i18n/collaboration/shared/useSharedCollaborationTranslation";
import { useIsCollaborationFromUserOrganizationRule } from "@taketurns-rules/collaboration/queries/collaboration/useIsCollaborationFromUserOrganizationRule";

export const useNotifyNotEnoughSpaceAvailableRule = () => {
  const { t } = useSharedCollaborationTranslation();
  const isCollaborationFromUserOrganizationRule = useIsCollaborationFromUserOrganizationRule();
  const { enqueueSnackbar } = useSnackbar();

  return async (totalSpaceInMB: number) => {
    const isCollaborationFromUserOrganization = await isCollaborationFromUserOrganizationRule();
    if (isCollaborationFromUserOrganization) {
      enqueueSnackbar(
        t("addAttachment.notEnoughSpaceAvailable.userCollaboration", { totalSpaceInGB: totalSpaceInMB / 1000 }),
        { variant: "error" },
      );
    } else {
      enqueueSnackbar(t("addAttachment.notEnoughSpaceAvailable.notUserCollaboration"), { variant: "error" });
    }
  };
};
