/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Dialog } from "@mui/material";
import { useCloseRequestFulfillmentDialogRule } from "@taketurns-rules/collaboration/commands/collaborationView/request/useCloseRequestFulfillmentDialogRule";
import { useIsRequestFulfillmentOpenRule } from "@taketurns-rules/collaboration/queries/collaborationView/useIsRequestFulfillmentOpenRule";
import { DesktopRequestFulfillmentDialogContent } from "./DesktopRequestFulfillmentDialogContent";

export const DesktopRequestFulfillmentDialog = () => {
  const isRequestFulfillmentOpen = useIsRequestFulfillmentOpenRule();
  const closeRequestFulfillment = useCloseRequestFulfillmentDialogRule();
  return (
    <Dialog
      PaperProps={{ sx: { minHeight: "60%" } }}
      open={isRequestFulfillmentOpen}
      onClose={closeRequestFulfillment}
      fullWidth
      maxWidth={"md"}
    >
      {isRequestFulfillmentOpen && <DesktopRequestFulfillmentDialogContent />}
    </Dialog>
  );
};
