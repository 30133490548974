/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Popover, styled } from "@mui/material";
import { forwardRef, RefObject } from "react";
import { SupervisorFilterItemList, SupervisorFilterItemListRef } from "@taketurns-components/collaboration/CollaborationList/SupervisorFilter/SupervisorFilterItemList";

interface SupervisorFilterPopoverProps {
  isOpen: boolean;
  closeMenu: () => void;
  supervisorFilterAnchorRef: RefObject<HTMLDivElement>;
}

export const SupervisorFilterPopover = forwardRef<SupervisorFilterItemListRef, SupervisorFilterPopoverProps>(
  ({ isOpen, closeMenu, supervisorFilterAnchorRef }, supervisorFilterItemListRef) => {
    return (
      <SupervisorFilter__Popover
        keepMounted
        anchorEl={supervisorFilterAnchorRef.current}
        open={isOpen}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <SupervisorFilterItemList ref={supervisorFilterItemListRef} closeMenu={closeMenu} />
      </SupervisorFilter__Popover>
    );
  },
);

const SupervisorFilter__Popover = styled(Popover)({
  "& .MuiPaper-root": {
    display: "flex",
    flexDirection: "column",
    borderRadius: "10px",
    width: "300px",
    minHeight: "320px",
  },
});
