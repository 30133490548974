/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Chip, Stack, styled, Typography } from "@mui/material";
import { Party } from "@taketurns/taketurns-graphql-repository";
import { getColorForUserParty } from "@taketurns-rules/collaboration/utils/color/TakeTurnsBlueColorsUtils";
import { useIsOnMobileRule } from "@taketurns-rules/commons/theme/useIsOnMobileRule";

export interface CollaborationTitleAndPartiesProps {
  name: string;
  owningPartyName: string;
  invitedPartyName: string;
  userParty: Party;
}

export const CollaborationTitle = (props: CollaborationTitleAndPartiesProps) => {
  const isOnMobile = useIsOnMobileRule();
  const chipSize = isOnMobile ? "small" : "medium";

  return (
    <CollaborationTitleAndParties__MainContainer>
      <CollaborationTitleAndParties__Title noWrap variant="h6" title={props.name}>
        {props.name}
      </CollaborationTitleAndParties__Title>

      <CollaborationTitleAndParties__PartiesContainer>
        <CollaborationTitleAndParties__PartyChip
          color={getColorForUserParty(Party.Owning, props.userParty).themeColor}
          label={props.owningPartyName}
          title={props.owningPartyName}
          size={chipSize}
        />
        <Typography variant="h6" fontSize={{ xs: 12, md: 18 }}>
          &
        </Typography>
        <CollaborationTitleAndParties__PartyChip
          color={getColorForUserParty(Party.Invited, props.userParty).themeColor}
          label={props.invitedPartyName}
          title={props.invitedPartyName}
          size={chipSize}
        />
      </CollaborationTitleAndParties__PartiesContainer>
    </CollaborationTitleAndParties__MainContainer>
  );
};

const CollaborationTitleAndParties__MainContainer = styled(Stack)(({ theme }) => ({
  flex: 1,
  flexDirection: "row",
  gap: theme.spacing(2),
  overflow: "hidden",
  [theme.breakpoints.down("md")]: {
    flexGrow: 1,
    flexDirection: "column",
    alignItems: "center",
    gap: theme.spacing(0.5),
  },
}));

const CollaborationTitleAndParties__Title = styled(Typography)({
  flex: "0 1 auto",
  minWidth: 0,
  maxWidth: "100%",
  fontSize: 18,
});

const CollaborationTitleAndParties__PartiesContainer = styled(Stack)(({ theme }) => ({
  flex: "0 2 auto",
  flexDirection: "row",
  gap: theme.spacing(2),
  alignItems: "center",
  minWidth: 166,
  [theme.breakpoints.down("md")]: {
    justifyContent: "center",
    gap: theme.spacing(1),
    width: "100%",
  },
}));

const CollaborationTitleAndParties__PartyChip = styled(Chip)(({ theme }) => ({
  minWidth: 60,
  overflow: "hidden",
  textOverflow: "ellipsis",
  [theme.breakpoints.down("md")]: {
    fontSize: 12,
  },
}));
