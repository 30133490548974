/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { DocumentInCreation } from "@taketurns-repositories/collaboration/models/documentInCreation";
import { PendingCreationAttachment } from "@taketurns-repositories/collaboration/models/pendingCreationAttachment";
import { useAddAttachmentInCreation } from "@taketurns-repositories/collaboration/state/write/collaborationCreation/attachment/useAddAttachmentInCreation";
import { useSetDocumentInCreation } from "@taketurns-repositories/collaboration/state/write/collaborationCreation/useSetDocumentInCreation";

export const useAddAttachmentInCreationRule = () => {
  const addAttachment = useAddAttachmentInCreation();
  const addDocument = useSetDocumentInCreation();

  return (pendingCreationAttachment: PendingCreationAttachment) => {
    addAttachment(pendingCreationAttachment.documentId, pendingCreationAttachment.parentFolderPath);
    const documentToAdd: DocumentInCreation = {
      id: pendingCreationAttachment.documentId,
      name: pendingCreationAttachment.documentName,
      extension: pendingCreationAttachment.documentExtension,
      size: pendingCreationAttachment.documentSize.toString(),
      description: "",
    };
    addDocument(documentToAdd);
  };
};
