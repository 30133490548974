/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Button, Stack, Typography } from "@mui/material";
import { Fragment, useState } from "react";
import { AddFilesButton } from "@taketurns-components/collaboration/Shared/content/AddFilesButton";
import { AddFolderButton } from "@taketurns-components/collaboration/Shared/content/AddFolderButton";
import { AddRequestButton } from "@taketurns-components/collaboration/Shared/content/AddRequestButton";
import { useCollaborationTranslation } from "@taketurns-i18n/collaboration/useCollaborationTranslation";
import { useAddFolderRule } from "@taketurns-rules/collaboration/commands/collaborationView/folder/useAddFolderRule";
import { useAddRequestRule } from "@taketurns-rules/collaboration/commands/collaborationView/request/useAddRequestRule";
import { IconPlusCircle } from "../../../commons/icons";
import { MobileBottomSheet } from "../../../commons/MobileSheets/MobileBottomSheet";

interface AddAnyContentButtonProps {
  canUserAddFiles: boolean;
  canUserAddFolders: boolean;
  canUserAddRequests: boolean;
  attachFiles: (files: FileList) => Promise<void>;
  addFolder: ReturnType<typeof useAddFolderRule>["addFolder"];
  addRequest: ReturnType<typeof useAddRequestRule>["addRequest"];
}

export const AddAnyContentButton = (props: AddAnyContentButtonProps) => {
  const { t } = useCollaborationTranslation("collaborationDocument");
  const [isMobileBottomSheetOpen, setIsMobileBottomSheetOpen] = useState(false);
  const { canUserAddFiles, canUserAddFolders, canUserAddRequests, attachFiles, addFolder, addRequest } = props;

  function closeSheet() {
    setIsMobileBottomSheetOpen(false);
  }

  function attachFilesAndCloseSheet(files: FileList) {
    closeSheet();
    return attachFiles(files);
  }

  return (
    <Fragment>
      <Button
        onClick={() => setIsMobileBottomSheetOpen(true)}
        variant={"text"}
        startIcon={<IconPlusCircle size={24} />}
        disableRipple
        data-cy="AddAnyContentButton"
      >
        {t("addContent")}
      </Button>
      <MobileBottomSheet isOpen={isMobileBottomSheetOpen} setIsOpen={setIsMobileBottomSheetOpen} hidePuller>
        <Stack m={3} spacing={4} mb={6} useFlexGap>
          <Typography>{t("addContent")}</Typography>
          <Stack direction="row" flexWrap="wrap" justifyContent="flex-start" spacing={1} useFlexGap>
            {canUserAddFiles && <AddFilesButton processFiles={attachFilesAndCloseSheet} style={{ paddingLeft: 0 }} />}
            {canUserAddFolders && <AddFolderButton addFolder={addFolder} onDialogOpen={closeSheet} />}
            {canUserAddRequests && <AddRequestButton addRequest={addRequest} onDialogOpen={closeSheet} />}
          </Stack>
        </Stack>
      </MobileBottomSheet>
    </Fragment>
  );
};
