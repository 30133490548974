/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { WelcomeDialog } from "@taketurns-components/webapp/WelcomeDialogs/WelcomeDialog";
import { getCollaborationInvitationWelcomeDialogUrlRule } from "@taketurns-rules/webapp/queries/getCollaborationInvitationWelcomeDialogUrlRule";

export const CollaborationInvitationWelcomeDialog = () => {
  const dialogUrl = getCollaborationInvitationWelcomeDialogUrlRule();
  return <WelcomeDialog dialogUrl={dialogUrl} />;
};
