/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql } from "@apollo/client";
import { MoveOutput, SubscriptionOnMoveRequestFromSourceFolderArgs } from "@taketurns/taketurns-graphql-repository";
import { useRefreshableSubscription } from "@taketurns-components/webapp/QueriesAndSubscriptionsRefreshTrigger";
import { moveRequestMutationFragment } from "@taketurns-repositories/collaboration/graphql/mutations/content/requests/useMoveRequestIntoFolder";

const SUBSCRIBE_TO_MOVE_REQUEST_FROM_SOURCE_FOLDER = gql`
  subscription onMoveRequestFromSourceFolder($collaborationId: ID!, $partyId: ID!, $sourceFolderId: ID!) {
    onMoveRequestFromSourceFolder(
      collaborationId: $collaborationId
      partyId: $partyId
      sourceFolderId: $sourceFolderId
    ) {
      ...MoveRequestMutationFragment
    }
  }
  ${moveRequestMutationFragment}
`;

export const useSubscribeToMoveRequestFromSourceFolder = (args: SubscriptionOnMoveRequestFromSourceFolderArgs) => {
  return useRefreshableSubscription<
    { onMoveRequestFromSourceFolder: MoveOutput },
    SubscriptionOnMoveRequestFromSourceFolderArgs
  >(SUBSCRIBE_TO_MOVE_REQUEST_FROM_SOURCE_FOLDER, { variables: args });
};
