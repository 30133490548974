/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useSnackbar } from "notistack";
import { CreateCollaborationInput, DocumentToRegisterInput } from "@taketurns/taketurns-graphql-repository";
import { useCollaborationTranslation } from "@taketurns-i18n/collaboration/useCollaborationTranslation";
import { useCreateCollaboration } from "@taketurns-repositories/collaboration/graphql/mutations/useCreateCollaboration";
import { useGetAddedPendingCreationAttachments } from "@taketurns-repositories/collaboration/state/read/collaborationCreation/useGetAddedPendingCreationAttachments";
import { useGetRootFolder } from "@taketurns-repositories/collaboration/state/read/collaborationCreation/useGetRootFolder";
import { useNavigateToCollaborationListRule } from "@taketurns-rules/collaboration/commands/navigation/useNavigateToCollaborationListRule";

/**
 * Create new collaboration
 * @returns create collaboration command and mutation result
 */
export const useCreateCollaborationRule = () => {
  const { createCollaborationResult, createCollaboration } = useCreateCollaboration();
  const addedPendingCreationAttachments = useGetAddedPendingCreationAttachments();
  const rootFolder = useGetRootFolder();
  const documentToRegisterInputs: DocumentToRegisterInput[] = addedPendingCreationAttachments.map(
    (addedPendingCreationAttachment) => ({
      id: addedPendingCreationAttachment.documentId,
      name: addedPendingCreationAttachment.documentName,
      extension: addedPendingCreationAttachment.documentExtension,
    }),
  );
  const navigateToCollaborationList = useNavigateToCollaborationListRule();

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useCollaborationTranslation("notifications");

  const displayCollaborationCreationSuccessNotification = () => {
    enqueueSnackbar(t("collaboration.collaborationStarted"), {
      variant: "info",
    });
  };

  const displayCollaborationCreationErrorNotification = () => {
    enqueueSnackbar(createCollaborationResult.error.message, {
      variant: "error",
    });
  };

  const createCollaborationCommand = (createCollaborationInput: CreateCollaborationInput) => {
    return createCollaboration(
      {
        ...(createCollaborationInput as CreateCollaborationInput),
        rootFolder,
      },
      documentToRegisterInputs,
    )
      .then(() => {
        navigateToCollaborationList();
        displayCollaborationCreationSuccessNotification();
      })
      .catch((error) => {
        console.error(error);
        displayCollaborationCreationErrorNotification();
        throw error;
      });
  };
  return { createCollaborationCommand, createCollaborationResult };
};
