/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

export enum PendingCreationAttachmentStatus {
  initiated = "initiated",
  uploading = "uploading",
  added = "attached",
  cancelled = "cancelled",
}
