/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql } from "@apollo/client";
import { useRefreshableSubscription } from "@taketurns-components/webapp/QueriesAndSubscriptionsRefreshTrigger";

const ON_INVITE_TO_ORGANIZATION = gql`
  subscription onInviteToOrganization($id: ID!) {
    onInviteToOrganization(id: $id) {
      id
      invitations {
        userId
        email
        role
        user {
          id
          email
          lastName
          firstName
          avatar
        }
      }
    }
  }
`;

export const useSubscribeToAddInvitationToOrganization = (id?: string) => {
  return useRefreshableSubscription(ON_INVITE_TO_ORGANIZATION, { variables: { id }, skip: !id });
};
