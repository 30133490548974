/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useAuthContext } from "@taketurns-app/routing/AuthProvider";
import { useUserSharedTranslation } from "@taketurns-i18n/user/useUserSharedTranslation";
import { useChangePassword } from "@taketurns-repositories/user/graphql/mutations/useChangePassword";
import { useDisplayAndLogNotificationErrorRule } from "./useDisplayAndLogNotificationErrorRule";
import { useDisplayNotificationSuccessRule } from "./useDisplayNotificationSuccessRule";

export const useChangePasswordRule = () => {
  const { changeUserPassword: changeUserPasswordCommand, loadingChangeUserPassword } = useChangePassword();
  const { t } = useUserSharedTranslation();

  const displayNotificationSuccess = useDisplayNotificationSuccessRule();
  const displayAndLogNotificationErrorRule = useDisplayAndLogNotificationErrorRule();
  const { getAccessToken } = useAuthContext();

  const changePassword = async (previousPassword: string, newPassword: string) => {
    const accessToken = await getAccessToken();
    try {
      await changeUserPasswordCommand({ accessToken, previousPassword, newPassword });
      displayNotificationSuccess(t("UserSettings.passwordChanged"));
    } catch (error) {
      displayAndLogNotificationErrorRule(error);
    }
  };

  return { changePassword, loadingChangeUserPassword };
};
