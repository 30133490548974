/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useQuery } from "@apollo/client";
import { PropsWithChildren } from "react";
import { SomethingWentWrong } from "@taketurns-components/shared/SomethingWentWrong";
import { CollaborationViewContext } from "@taketurns-repositories/collaboration/context/CollaborationViewContext";
import { FolderFragment } from "@taketurns-repositories/collaboration/graphql/queries/folder/useFetchFolder";
import { userFragment } from "@taketurns-repositories/user/graphql/fragments/userFragment";
import { useSubscribeToCollaborationStatusChangesRule } from "@taketurns-rules/collaboration/subscriptions/useSubscribeToCollaborationStatusChangesRule";

type CollaborationViewProviderProps = PropsWithChildren<{
  collaborationId: string;
}>;

export const CollaborationViewProvider = (props: CollaborationViewProviderProps) => {
  useSubscribeToCollaborationStatusChangesRule(props.collaborationId);
  const { collaboration, error, loading } = useFetchCollaborationViewProviderDataRule(props.collaborationId);

  if (loading) {
    return;
  }

  if (error) {
    return <SomethingWentWrong />;
  }

  return (
    <CollaborationViewContext.Provider
      value={{
        collaborationId: props.collaborationId,
        connectedUserPartyId: collaboration.userContext.partyId,
        rootFolderId: collaboration.rootFolderId,
      }}
    >
      {props.children}
    </CollaborationViewContext.Provider>
  );
};

const useFetchCollaborationViewProviderDataRule = (collaborationId: string) => {
  const { collaboration, error, loading } = useFetchCollaborationView(collaborationId);
  return { collaboration, error, loading };
};

const useFetchCollaborationView = (collaborationId: string) => {
  const query = gql`
    query getCollaboration($collaborationId: ID!) {
      getCollaboration(collaborationId: $collaborationId) {
        id
        name
        description
        invitedPartyName
        owningPartyName
        deadline
        startDate
        status
        rootFolderId
        rootFolder {
          ...FolderFragment
        }
        userContext {
          id
          role
          isInvitee
          isParticipant
          isSupervisor
          party
          partyId
          otherPartyName
        }
        contentMetadata {
          id
          partyId
          hasPendingUpdates
          attachmentToReviewCount
          requestToFulfillCount
          todoCount
          lastUpdateCount
          recallLastUpdatesAvailable
          hasNoContent
        }
        lastUpdateEvent {
          id
          userId
          user {
            ...UserFields
          }
          date
          isFromConnectedUserParty
          partyId
          party
          partyName
          eventType
          note
        }
        availableFeatures {
          totalSpaceInMB
          maxFileSizeInMB
          maxGracePeriodInDays
          defaultGracePeriodInDays
        }
      }
    }
    ${FolderFragment}
    ${userFragment}
  `;
  const { data, error, loading } = useQuery(query, { variables: { collaborationId } });
  return { collaboration: data?.getCollaboration, error, loading };
};
