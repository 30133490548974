/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import {
  EmailVerificationData,
  verifyEmailVerificationData,
} from "@taketurns-rules/webapp/validation/emailVerificationDataValidationRule";

const EMAIL_VERIFICATION_DATA = "email_verification_data";

export const setEmailVerificationData = (emailVerificationData: EmailVerificationData) => {
  sessionStorage.setItem(EMAIL_VERIFICATION_DATA, JSON.stringify(emailVerificationData));
};

export const clearEmailVerificationData = () => {
  sessionStorage.removeItem(EMAIL_VERIFICATION_DATA);
};

export const getEmailVerificationData = (): EmailVerificationData | null => {
  const emailVerificationData = sessionStorage.getItem(EMAIL_VERIFICATION_DATA);
  if (!emailVerificationData) return null;
  const data = JSON.parse(emailVerificationData);
  return verifyEmailVerificationData(data);
};
