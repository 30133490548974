/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { IconButton, Menu, MenuItem, Stack, Typography } from "@mui/material";
import { Fragment, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { IconChevronDown, IconChevronUp } from "@taketurns-components/commons/icons";
import { useWebAppTranslations } from "@taketurns-i18n/webapp/useWebAppTranslations";
import {
  ACCESS_POLICIES_SECTION,
  INTEGRATIONS_SECTION,
  MANAGE_PLAN_SECTION,
  ORGANIZATION_SECTION,
  PREFERENCES_SECTION,
  PROFILE_SECTION,
  USER_SETTINGS_SEARCH_PARAM,
} from "../../../routing/routes/userSettingsSections";

const EXISTING_TABS_VALUES = [
  PROFILE_SECTION,
  MANAGE_PLAN_SECTION,
  ORGANIZATION_SECTION,
  ACCESS_POLICIES_SECTION,
  PREFERENCES_SECTION,
  INTEGRATIONS_SECTION,
];

export const MobileUserSettingsHeader = () => {
  const { t } = useWebAppTranslations("settings");
  const menuAnchorRef = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const [searchParam, setSearchParam] = useSearchParams();
  const activeSectionValue = searchParam.get(USER_SETTINGS_SEARCH_PARAM);
  const activeSection =
    activeSectionValue !== null && EXISTING_TABS_VALUES.includes(activeSectionValue)
      ? activeSectionValue
      : EXISTING_TABS_VALUES[0];

  const openTabSelectionMenu = () => {
    setIsMenuOpen(true);
  };
  const closeTabSelectionMenu = () => {
    setIsMenuOpen(false);
  };

  const selectTabAndCloseMenu = (section: string) => {
    setSearchParam({ section });
    closeTabSelectionMenu();
  };

  return (
    <Fragment>
      <Stack ref={menuAnchorRef} direction="row" alignItems="center">
        <Typography sx={{ cursor: "pointer" }} onClick={openTabSelectionMenu}>
          {t(activeSection)}
        </Typography>
        <IconButton size="small" onClick={openTabSelectionMenu}>
          {isMenuOpen ? <IconChevronUp size={18} /> : <IconChevronDown size={18} />}
        </IconButton>
      </Stack>
      <Menu
        id="basic-menu"
        anchorEl={menuAnchorRef.current}
        open={isMenuOpen}
        onClose={closeTabSelectionMenu}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {EXISTING_TABS_VALUES.map((tab) => (
          <MenuItem disabled={activeSection === tab} key={tab} onClick={() => selectTabAndCloseMenu(tab)}>
            {t(tab)}
          </MenuItem>
        ))}
      </Menu>
    </Fragment>
  );
};
