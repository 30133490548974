/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Stack, Typography } from "@mui/material";
import { ComponentProps, ForwardedRef, forwardRef, Fragment } from "react";
import { ContextMenuOnButton } from "@taketurns-components/collaboration/ContextMenu/ContextMenuOnButton";
import { ContextMenuConfiguration } from "@taketurns-components/collaboration/ContextMenu/models/contextMenuConfiguration";
import { MobileListItem } from "@taketurns-components/collaboration/Shared/list/MobileListItem";
import { TakeTurnsListItem } from "@taketurns-components/collaboration/Shared/list/TakeTurnsListItem";
import { AttachmentInCreation } from "@taketurns-repositories/collaboration/models/attachmentInCreation";
import { useGetAttachmentInCreation } from "@taketurns-repositories/collaboration/state/read/collaborationCreation/useGetAttachmentInCreation";
import { useGetDocumentInCreation } from "@taketurns-repositories/collaboration/state/read/collaborationCreation/useGetDocumentInCreation";
import { useMoveDestinationDialogRule } from "@taketurns-rules/collaboration/commands/useMoveDestinationDialogRule";
import { useGetAttachmentContextMenuConfigurationInCreationRule } from "@taketurns-rules/collaboration/ui/useGetAttachmentContextMenuConfigurationInCreationRule";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { useIsOnMobileRule } from "@taketurns-rules/commons/theme/useIsOnMobileRule";
import { IconReview } from "../../../../commons/icons";
import { DocumentExtension } from "../../../../document/DocumentView/DocumentExtension";
import { WithContextMenu } from "../../../ContextMenu/WithContextMenu";
import { AttachmentMoveDestinationDialogInCollaborationCreation } from "../AttachmentMoveDestinationDialogInCollaborationCreation";

interface AttachmentListItemProps {
  documentId: string;
}

interface ListItemProps extends AttachmentListItemProps {
  contextMenuConfiguration: ContextMenuConfiguration;
}

const ListItem = forwardRef(
  (
    { documentId, contextMenuConfiguration }: ListItemProps,
    attachmentListItemRef: ForwardedRef<HTMLLIElement | null>,
  ) => {
    const attachment = useGetAttachmentInCreation(documentId);
    const document = useGetDocumentInCreation(documentId);
    const isOnMobile = useIsOnMobileRule();

    if (isOnMobile) {
      return (
        <MobileListItem
          centerRightEndSlotVertically
          ref={attachmentListItemRef}
          titleSlot={
            <Typography variant="body2" noWrap>
              {document.name}
            </Typography>
          }
          subtitleSlot={
            <Fragment>
              <DocumentExtension extension={document.extension} size={document.size} />
              <AttachmentInCreationMarkersInList attachment={attachment} />
            </Fragment>
          }
          rightEndSlot={<ContextMenuOnButton contextMenuConfiguration={contextMenuConfiguration} />}
        />
      );
    }

    const ListItemWithContextMenu = WithContextMenu<ComponentProps<typeof TakeTurnsListItem>>(
      TakeTurnsListItem,
      contextMenuConfiguration,
    );

    return (
      <ListItemWithContextMenu
        hasContextMenu
        ref={attachmentListItemRef}
        titleSlot={
          <Typography variant="body2" noWrap>
            {document.name}
          </Typography>
        }
        middleSlot={<AttachmentInCreationMarkersInList attachment={attachment} />}
        rightEndSlot={<DocumentExtension extension={document.extension} size={document.size} />}
      />
    );
  },
);
ListItem.displayName = "AttachmentListItemInCreation";

export const AttachmentListItemInCreation = ({ documentId }: AttachmentListItemProps) => {
  const attachment = useGetAttachmentInCreation(documentId);

  const { closeMoveDestinationDialog, isMoveDestinationDialogOpen, openMoveDestinationDialog } =
    useMoveDestinationDialogRule();

  const attachmentContextMenuConfigurationInCreation = useGetAttachmentContextMenuConfigurationInCreationRule(
    documentId,
    openMoveDestinationDialog,
  );

  return (
    <Fragment>
      <ListItem
        documentId={attachment.documentId}
        contextMenuConfiguration={attachmentContextMenuConfigurationInCreation}
      />
      <AttachmentMoveDestinationDialogInCollaborationCreation
        documentId={attachment.documentId}
        open={isMoveDestinationDialogOpen}
        onClose={closeMoveDestinationDialog}
      />
    </Fragment>
  );
};

const AttachmentInCreationMarkersInList = ({ attachment }: { attachment: AttachmentInCreation }) => {
  const shouldDisplayMarkedForReviewIcon = attachment.markedForReview;

  if (!shouldDisplayMarkedForReviewIcon) {
    return null;
  }

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      {shouldDisplayMarkedForReviewIcon && <IconReview color={TakeTurnsColors.darkBlue} size={16} />}
    </Stack>
  );
};
