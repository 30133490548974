/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useRecoilCallback } from "recoil";
import { folderByIdState } from "../recoil/collaborationCreation/folderByIdState";
import { parentFolderIdByAttachmentIdState } from "../recoil/collaborationCreation/parentFolderIdByAttachmentIdState";

export function useMoveAttachmentIntoFolderInCreation(movingAttachmentId: string) {
  return useRecoilCallback(
    ({ set, snapshot }) =>
      (destinationFolderId: string) => {
        removeMovingAttachmentFromPreviousParentFolder();
        addMovingAttachmentToDestinationFolder();

        function removeMovingAttachmentFromPreviousParentFolder() {
          const previousParentFolderId: string = snapshot.getLoadable(
            parentFolderIdByAttachmentIdState(movingAttachmentId),
          ).contents;
          set(folderByIdState(previousParentFolderId), (previousParentFolder) => ({
            ...previousParentFolder,
            attachments: previousParentFolder.attachments.filter((attachmentId) => attachmentId !== movingAttachmentId),
          }));
        }

        function addMovingAttachmentToDestinationFolder() {
          set(parentFolderIdByAttachmentIdState(movingAttachmentId), destinationFolderId);
          set(folderByIdState(destinationFolderId), (destinationFolder) => ({
            ...destinationFolder,
            attachments: [...destinationFolder.attachments, movingAttachmentId],
          }));
        }
      },
    [movingAttachmentId],
  );
}
