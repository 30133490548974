/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { RemoveOrganizationMemberInput } from "@taketurns/taketurns-graphql-repository";
import { useOrganizationTranslation } from "@taketurns-i18n/user/Organization/useOrganizationTranslation";
import { useRemoveOrganizationMember } from "@taketurns-repositories/user/graphql/mutations/useRemoveOrganizationMember";
import { useDisplayAndLogNotificationErrorRule } from "./useDisplayAndLogNotificationErrorRule";
import { useDisplayNotificationSuccessRule } from "./useDisplayNotificationSuccessRule";

export const useRemoveOrganizationMemberRule = () => {
  const {
    removeOrganizationMember: removeOrganizationMemberCommand,
    errorOnRemoveOrganizationMember,
    loadingRemoveOrganizationMember,
  } = useRemoveOrganizationMember();
  const { t } = useOrganizationTranslation();

  const displayNotificationSuccess = useDisplayNotificationSuccessRule();
  const displayAndLogNotificationError = useDisplayAndLogNotificationErrorRule();
  const removeOrganizationMember = ({
    removeOrganizationMemberInput,
  }: {
    removeOrganizationMemberInput: RemoveOrganizationMemberInput;
  }) => {
    removeOrganizationMemberCommand(removeOrganizationMemberInput)
      .then(() => displayNotificationSuccess(t("Notifications.removeOrganizationMemberSuccess")))
      .catch(() => displayAndLogNotificationError(t("error")));
  };

  return { removeOrganizationMember, errorOnRemoveOrganizationMember, loadingRemoveOrganizationMember };
};
