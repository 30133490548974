/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useQuery } from "@apollo/client";
import { Collaboration } from "@taketurns/taketurns-graphql-repository";

const LAST_UPDATE_PARTY_ID = gql`
  query lastUpdatePartyId($collaborationId: ID!) {
    getCollaboration(collaborationId: $collaborationId) {
      id
      lastUpdateEvent {
        id
        partyId
      }
    }
  }
`;

export const useFetchLastUpdatePartyId = (collaborationId: string) => {
  const {
    data,
    loading: loadingLastUpdatePartyId,
    error: errorOnFetchingLastUpdatePartyId,
  } = useQuery<{
    getCollaboration: Collaboration;
  }>(LAST_UPDATE_PARTY_ID, { variables: { collaborationId } });
  return {
    lastUpdatePartyId: data?.getCollaboration.lastUpdateEvent.partyId,
    loadingLastUpdatePartyId,
    errorOnFetchingLastUpdatePartyId,
  };
};
