/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { SendButtonProps } from "stream-chat-react";
import { DefaultStreamChatGenerics } from "stream-chat-react/dist/types/types";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { IconSend } from "../../commons/icons";

export const MobileSendButton = <StreamChatGenerics extends DefaultStreamChatGenerics = DefaultStreamChatGenerics>({
  sendMessage,
  ...otherProps
}: SendButtonProps<StreamChatGenerics>) => {
  return (
    <button
      aria-label="Send"
      className="str-chat__send-button"
      data-testid="send-button"
      onClick={sendMessage}
      type="button"
      {...otherProps}
    >
      <IconSend size={20} color={TakeTurnsColors.lightBlue} />
    </button>
  );
};
