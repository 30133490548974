/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql } from "@apollo/client";
import { RemoveRequestOutput, SubscriptionOnRemoveRequestArgs } from "@taketurns/taketurns-graphql-repository";
import { useRefreshableSubscription } from "@taketurns-components/webapp/QueriesAndSubscriptionsRefreshTrigger";
import { RemoveRequestMutationFragment } from "../../mutations/content/requests/useRemoveRequest";

const SUBSCRIBE_TO_REMOVE_REQUEST = gql`
  subscription onRemoveRequest($collaborationId: ID!, $partyId: ID!, $folderId: ID!) {
    onRemoveRequest(collaborationId: $collaborationId, partyId: $partyId, folderId: $folderId) {
      ...RemoveRequestMutationFragment
    }
  }
  ${RemoveRequestMutationFragment}
`;

export const useSubscribeToRemoveRequest = (args: SubscriptionOnRemoveRequestArgs) => {
  return useRefreshableSubscription<{ onRemoveRequest: RemoveRequestOutput }, SubscriptionOnRemoveRequestArgs>(
    SUBSCRIBE_TO_REMOVE_REQUEST,
    { variables: args },
  );
};
