/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { CollaborationCommand, useCollaborationPermissions } from "@taketurns-rules/collaboration/permissions/useCollaborationPermissions";
import { useIsCollaborationClosedRule } from "@taketurns-rules/collaboration/queries/collaborationView/useIsCollaborationClosedRule";
import { useFetchConnectedUserPartyRule } from "@taketurns-rules/collaboration/queries/context/useFetchConnectedUserPartyRule";
import { Party } from "@taketurns/taketurns-graphql-repository";

export const useCanReopenCollaborationRule = (collaborationId: string): boolean => {
  const { isCollaborationClosed } = useIsCollaborationClosedRule(collaborationId);
  const allowReopen = useCollaborationPermissions(CollaborationCommand.REOPEN_COLLABORATION, collaborationId);
  const { connectedUserParty } = useFetchConnectedUserPartyRule(collaborationId);
  return isCollaborationClosed && allowReopen && connectedUserParty === Party.Owning;
};
