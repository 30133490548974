/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Box, IconButton, Stack } from "@mui/material";
import { memo, ReactNode } from "react";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { IconArrowLeft } from "../../../components/commons/icons";

interface BodyLayoutProps {
  header: ReactNode;
  backAction?: () => void;
  content: ReactNode;
  collapsableRightBar?: ReactNode;
  responsiveMargin?: boolean;
}

export const BodyLayout = memo(
  ({ backAction, collapsableRightBar, content, header, responsiveMargin }: BodyLayoutProps) => {
    const bodyHorizontalPadding = computeBodyHorizontalPadding(!!collapsableRightBar);

    return (
      <Stack
        className="BodyLayout__Container"
        direction="row"
        height="100%"
        marginX={responsiveMargin ? { xs: 2, sm: 4, md: "auto", lg: 25, xl: "auto" } : undefined}
        width={responsiveMargin ? { xs: "auto", sm: "auto", md: 840, lg: "auto", xl: 1040 } : undefined}
      >
        <Stack
          className="BodyLayout__Main"
          direction="column"
          minWidth={0}
          width="100%"
          flex={1}
          paddingY={0}
          {...bodyHorizontalPadding}
        >
          <Header backAction={backAction} header={header} />
          {/*minHeight 0 is a flex hack for children overflow to work properly*/}
          <Box className="BodyLayout__Content" flex={1} minHeight={0}>
            {content}
          </Box>
        </Stack>
        {collapsableRightBar ? collapsableRightBar : null}
      </Stack>
    );
  },
);

const Header = ({ backAction, header }: { header: ReactNode; backAction?: () => void }) => {
  const HEADER_HEIGHT = "110px";
  const HEADER_HEIGHT_SMALLER = "90px";
  return (
    <Stack
      className="BodyLayout__Header"
      justifyContent={"center"}
      position="relative"
      sx={{
        minHeight: {
          xs: HEADER_HEIGHT_SMALLER,
          xl: HEADER_HEIGHT,
        },
        height: {
          xs: HEADER_HEIGHT_SMALLER,
          xl: HEADER_HEIGHT,
        },
        px: {
          xs: 3,
          md: 0,
        },
      }}
    >
      {backAction && (
        <IconButton
          onClick={backAction}
          size="small"
          disableRipple
          sx={{
            position: "absolute",
            left: `${-(18 + 10)}px`,
          }}
        >
          <IconArrowLeft color={TakeTurnsColors.grayButton} size={18} />
        </IconButton>
      )}
      {header}
    </Stack>
  );
};

const computeBodyHorizontalPadding = (hasCollapsableRightBar: boolean) => {
  const XS_PADDING_X_IN_PX = 0;
  const MD_PADDING_X_IN_PX = 24;
  const LG_PADDING_X_IN_PX = 70;
  const COLLAPSABLE_RIGHT_BAR_WIDTH_IN_PX = 50;
  const XS_SPACE_BETWEEN_RIGHT_BAR_AND_CONTENT_IN_PX = 20;
  const LG_SPACE_BETWEEN_RIGHT_BAR_AND_CONTENT_IN_PX = 24;

  const defaultBodyHorizontalPadding = {
    paddingX: {
      xs: `${XS_PADDING_X_IN_PX}px`,
      md: `${MD_PADDING_X_IN_PX}px`,
      xl: `${LG_PADDING_X_IN_PX}px`,
    },
  };

  if (hasCollapsableRightBar) {
    // If there is a collapsable right bar, and the screen is not very large,
    // the collapsable right bar will be displayed on top of the content,
    // so we have to add a padding including the width of the collapsable right bar on the right
    // otherwise, we want a smaller padding on the right
    return {
      ...defaultBodyHorizontalPadding,
      paddingRight: {
        xs: `${COLLAPSABLE_RIGHT_BAR_WIDTH_IN_PX + XS_SPACE_BETWEEN_RIGHT_BAR_AND_CONTENT_IN_PX}px`,
        md: `${COLLAPSABLE_RIGHT_BAR_WIDTH_IN_PX + LG_SPACE_BETWEEN_RIGHT_BAR_AND_CONTENT_IN_PX}px`,
        lg: `${LG_SPACE_BETWEEN_RIGHT_BAR_AND_CONTENT_IN_PX}px`,
      },
    };
  }

  return defaultBodyHorizontalPadding;
};
