/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { Invitee } from "@taketurns/taketurns-graphql-repository";
import { useCollaborationTranslation } from "@taketurns-i18n/collaboration/useCollaborationTranslation";
import { useRevokeInvitationMutation } from "@taketurns-repositories/collaboration/graphql/mutations/participants/useRevokeInvitationMutation";
import { useGetDisplayedCollaborationIdRule } from "@taketurns-rules/collaboration/queries/collaboration/useGetDisplayedCollaborationIdRule";

export const useRevokeInvitationRule = () => {
  const collaborationId = useGetDisplayedCollaborationIdRule();
  const { errorOnRevokingInvitation, loadingRevokeInvitation, revokeInvitationCommand } = useRevokeInvitationMutation();
  const { enqueueSnackbar } = useSnackbar();

  const { t } = useCollaborationTranslation("notifications");

  useEffect(() => {
    if (errorOnRevokingInvitation) {
      enqueueSnackbar(errorOnRevokingInvitation.message, { variant: "error" });
    }
  }, [errorOnRevokingInvitation]);

  const revokeInvitation = ({ email, user, userId }: Invitee) => {
    const notifyRevokeInvitationSuccess = () => {
      enqueueSnackbar(
        t("user.revokeInvitation", {
          email: user?.firstName && user?.lastName ? user.firstName + " " + user.lastName : email,
        }),
        {
          variant: "info",
        },
      );
    };
    revokeInvitationCommand({ collaborationId, email, userId }, notifyRevokeInvitationSuccess);
  };
  return { revokeInvitation, loadingRevokeInvitation };
};
