/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Stack, Typography } from "@mui/material";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { LanguageSelector } from "../LanguageSelector";
import { UserRoundedAvatar } from "./UserRoundedAvatar";

interface UserAvatarExtendedProps {
  user: { firstName?: string; lastName?: string; email: string; avatar?: string };
  onAvatarClick?: () => void;
}

export const UserAvatarExtended = (props: UserAvatarExtendedProps) => {
  const { user, onAvatarClick } = props;

  const getUserEmailOrFirstnameAndLastName = () => {
    const { firstName, lastName, email } = user;
    if (firstName && lastName) {
      return `${firstName} ${lastName}`;
    }
    return email;
  };

  return (
    <Stack spacing={2} direction="row">
      <UserRoundedAvatar size={60} user={user} onAvatarClick={onAvatarClick} />
      <Stack direction="column" alignItems={"flex-start"}>
        <Typography fontSize={16} color={TakeTurnsColors.darkBlue}>
          {getUserEmailOrFirstnameAndLastName()}
        </Typography>
        <LanguageSelector />
      </Stack>
    </Stack>
  );
};
