/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Box, Checkbox, Divider, FormControlLabel, Stack, TextField, Typography } from "@mui/material";
import { BaseSyntheticEvent, Fragment, useState } from "react";
import { Controller } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthenticationButtons } from "@taketurns-app/pages/public/components/AuthenticationButtons";
import { useUserSharedTranslation } from "@taketurns-i18n/user/useUserSharedTranslation";
import { setEmailVerificationData } from "@taketurns-repositories/webapp/sessionStorage/emailVerificationData";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { useIsOnMobileRule } from "@taketurns-rules/commons/theme/useIsOnMobileRule";
import { useResolveErrorMessageToBeDisplayedRule } from "@taketurns-rules/commons/util/useResolveErrorMessageToBeDisplayedRule";
import { PageTitle, useSetDocumentTitleRule } from "@taketurns-rules/webapp/commands/useSetDocumentTitleRule";
import { useSignUpRule } from "@taketurns-rules/webapp/commands/useSignUpRule";
import { getInvitationToOrganizationRule } from "@taketurns-rules/webapp/queries/getInvitationToOrganizationRule";
import { useGetHelpCenterUrls } from "@taketurns-rules/webapp/queries/useGetHelpCenterUrls";
import { useInvitationEmailRule } from "@taketurns-rules/webapp/queries/useInvitationEmailRule";
import {
  SignUpFormFields,
  SignUpUserFormFields,
  useSignUpFormValidationRule,
} from "@taketurns-rules/webapp/validation/useSignUpFormValidationRule";
import { TakeTurnsDefaultButton } from "../../../components/commons/button";
import { JobFunctionSelector } from "../../../components/commons/select/JobFunctionSelector";
import { SignInSignUpOrganizationInvitationHeader } from "../../../components/user/Organization/SignInSignUpOrganizationInvitationHeader/SignInSignUpOrganizationInvitationHeader";
import { ExternalLinkStyled, LinkStyled } from "../../../components/webapp/LinkStyled";
import { MobileCardLayout } from "../../layouts/public/MobileCardLayout";
import { UserAuthenticationLayout } from "../../layouts/public/UserAuthenticationLayout";
import { PUBLIC_ROUTES } from "../../routing/routes/publicRoutes.constants";
import { RegisterOrLoginCard } from "./components/RegisterOrLoginCard";
import { RegisterOrLoginHeader } from "./components/RegisterOrLoginHeader";

export const SignUp = () => {
  const { t } = useUserSharedTranslation();
  const isOnMobile = useIsOnMobileRule();
  const invitationEmail = useInvitationEmailRule();

  useSetDocumentTitleRule(invitationEmail ? PageTitle.SIGNUP_INVITATION : PageTitle.SIGNUP);

  const LinkToSignInPage = () => {
    const { search, state } = useLocation();
    return (
      <Typography variant="caption" color={TakeTurnsColors.darkGray}>
        {t("SignUp.alreadyHaveAccount") + " "}
        <LinkStyled to={PUBLIC_ROUTES.SIGN_IN + search} state={state}>
          {t("SignUp.secondaryText")}
        </LinkStyled>
      </Typography>
    );
  };

  if (isOnMobile) {
    return (
      <MobileCardLayout>
        <RegisterOrLoginHeader text={t("SignUp.text")} secondaryText={<LinkToSignInPage />} />
        <SignUpContent />
      </MobileCardLayout>
    );
  }

  const invitationToOrganization = getInvitationToOrganizationRule();
  return (
    <Fragment>
      {invitationToOrganization && (
        <SignInSignUpOrganizationInvitationHeader organizationName={invitationToOrganization.organization.name} />
      )}
      <UserAuthenticationLayout maxWidth={"sm"}>
        <RegisterOrLoginHeader text={t("SignUp.text")} secondaryText={<LinkToSignInPage />} />
        <RegisterOrLoginCard>
          <SignUpContent />
        </RegisterOrLoginCard>
      </UserAuthenticationLayout>
    </Fragment>
  );
};

const SignUpContent = () => {
  const { t } = useUserSharedTranslation();
  const signUpCommand = useSignUpRule();
  const [errorOnSignUp, setError] = useState(null);
  const isOnMobile = useIsOnMobileRule();
  const onSignUpSuccess = useOnSignUpSuccess();

  const SignUpForm = () => {
    const invitationEmail = useInvitationEmailRule();
    const { handleSubmit, control, errors, trigger } = useSignUpFormValidationRule(invitationEmail || "");
    const { termsUrl, privacyUrl } = useGetHelpCenterUrls();

    async function signUpUser(userFormValues: SignUpFormFields, formEvent?: BaseSyntheticEvent) {
      setError(null);
      formEvent?.preventDefault();
      try {
        await signUpCommand(userFormValues);
      } catch (signUpError) {
        console.error(signUpError);
        setError((signUpError as { message: string }).message);
        return;
      }
      onSignUpSuccess({ email: userFormValues.email, password: userFormValues.password });
    }

    return (
      <form onSubmit={handleSubmit(signUpUser)}>
        <Stack direction="column" spacing={2.5}>
          <Stack direction={{ xs: "column", md: "row" }} spacing={2.5}>
            <Controller
              name={SignUpUserFormFields.name}
              control={control}
              render={({ field, fieldState: { error: nameFieldError } }) => (
                <TextField
                  {...field}
                  data-cy="signUpForm.firstName"
                  error={!!nameFieldError}
                  fullWidth
                  placeholder={t("SignUp.firstname")}
                />
              )}
            />
            <Controller
              name={SignUpUserFormFields.family_name}
              control={control}
              render={({ field, fieldState: { error: familyNameFieldError } }) => (
                <TextField
                  {...field}
                  data-cy="signUpForm.lastName"
                  error={!!familyNameFieldError}
                  fullWidth
                  placeholder={t("SignUp.lastname")}
                />
              )}
            />
          </Stack>
          <Controller
            name={SignUpUserFormFields.function}
            control={control}
            render={({ field, fieldState: { error: functionFieldError } }) => (
              <JobFunctionSelector onChange={field.onChange} error={!!functionFieldError} />
            )}
          />
          <Controller
            name={SignUpUserFormFields.job_title}
            control={control}
            render={({ field }) => (
              <TextField {...field} name={SignUpUserFormFields.job_title} placeholder={t("SignUp.jobTitle")} />
            )}
          />
          <Controller
            name={SignUpUserFormFields.email}
            control={control}
            defaultValue={invitationEmail ? invitationEmail : undefined}
            render={({ field, fieldState: { error: emailFieldError } }) => (
              <TextField
                {...field}
                data-cy="signUpForm.email"
                error={!!emailFieldError}
                placeholder={t("SignUp.email")}
                autoComplete="username"
                type="email"
              />
            )}
          />
          <Stack direction={{ xs: "column", md: "row" }} spacing={2.5}>
            <Controller
              name={SignUpUserFormFields.password}
              control={control}
              render={({ field, fieldState: { error: passwordFieldError } }) => (
                <TextField
                  {...field}
                  data-cy="signUpForm.password"
                  error={!!passwordFieldError}
                  placeholder={t("SignUp.password")}
                  autoComplete="current-password"
                  type="password"
                  helperText={!!passwordFieldError && t(passwordFieldError.message)}
                  fullWidth
                />
              )}
            />
            <Controller
              name={SignUpUserFormFields.passwordConfirmation}
              control={control}
              render={({ field, fieldState: { error: passwordConfirmationFieldError } }) => (
                <TextField
                  {...field}
                  data-cy={"signUpForm.confirmPassword"}
                  error={!!passwordConfirmationFieldError}
                  placeholder={t("SignUp.confirmPassword")}
                  autoComplete="current-password"
                  type="password"
                  helperText={!!passwordConfirmationFieldError && t(passwordConfirmationFieldError.message)}
                  fullWidth
                  onBlur={() => trigger("password")}
                />
              )}
            />
          </Stack>
          {errorOnSignUp && <SignUpError errorMessage={errorOnSignUp} />}
          <FormControlLabel
            sx={{
              gap: "12px",
              color: errors.isTosAndPrivacyPolicyAccepted ? TakeTurnsColors.error : null,
            }}
            label={
              <Box fontSize={10}>
                {t("SignUp.acceptTosAndPrivacy.begin")}{" "}
                <ExternalLinkStyled href={termsUrl} target="_blank">
                  {t("SignUp.acceptTosAndPrivacy.termOfServiceLink")}
                </ExternalLinkStyled>{" "}
                {t("SignUp.acceptTosAndPrivacy.middle")}{" "}
                <ExternalLinkStyled href={privacyUrl} target="_blank">
                  {t("SignUp.acceptTosAndPrivacy.privacyPolicyLink")}
                </ExternalLinkStyled>
                {". "}
                {t("SignUp.acceptTosAndPrivacy.end")}
              </Box>
            }
            control={
              <Controller
                name={SignUpUserFormFields.isTosAndPrivacyPolicyAccepted}
                control={control}
                render={({ field, fieldState: { error: isTosAndPrivacyPolicyAcceptedFieldError } }) => (
                  <Checkbox
                    {...field}
                    disableRipple
                    disableFocusRipple
                    data-cy="signUpForm.termsAndConditions"
                    color="secondary"
                    size="small"
                    style={{
                      color: isTosAndPrivacyPolicyAcceptedFieldError ? TakeTurnsColors.error : TakeTurnsColors.darkGray,
                      padding: 0,
                    }}
                  />
                )}
              />
            }
          />
          <Stack direction="row" justifyContent={{ xs: "center", md: "flex-end" }} alignItems="center">
            <TakeTurnsDefaultButton
              data-cy="signUpForm.submit"
              type="submit"
              fullWidth={isOnMobile}
              sx={{ maxWidth: { xs: "350px", md: "initial" } }}
            >
              {t("SignUp.signUp")}
            </TakeTurnsDefaultButton>
          </Stack>
        </Stack>
      </form>
    );
  };

  return (
    <Stack component="div" direction="column" spacing={2.5}>
      <SignUpFormHeaderMessage />
      <SignUpForm />
      <Divider />
      <AuthenticationButtons textSlot={<SignUpWithProviderMessage />} />
    </Stack>
  );
};

const SignUpFormHeaderMessage = () => {
  const { t } = useUserSharedTranslation();
  const invitationEmail = useInvitationEmailRule();
  if (invitationEmail) {
    return (
      <Typography data-cy="signUpInvitationEmailInstruction" fontSize={14}>
        {t("SignUp.invitationSentTo.message")}{" "}
        <span style={{ color: TakeTurnsColors.darkBlue, fontWeight: "bold" }}>{invitationEmail}</span>
        {". "}
        {t("SignUp.invitationSentTo.signup")}
      </Typography>
    );
  }
  return (
    <Typography fontSize={14} color={TakeTurnsColors.darkGray}>
      {t("SignUp.createAccountDescription")}
    </Typography>
  );
};

const SignUpWithProviderMessage = () => {
  const { t } = useUserSharedTranslation();
  const invitationEmail = useInvitationEmailRule();

  if (invitationEmail) {
    return (
      <Typography color={TakeTurnsColors.darkGray} fontSize={14}>
        {t("SignUp.invitationSentTo.signUpWithProvider")}{" "}
        <span style={{ color: TakeTurnsColors.darkBlue, fontWeight: "bold" }}>{invitationEmail}</span>.
      </Typography>
    );
  }
  return (
    <Typography variant={"caption"} color={TakeTurnsColors.darkGray}>
      {t("SignUp.signUpWith")}
    </Typography>
  );
};

const SignUpError = (props: { errorMessage: string }) => {
  return <Typography color="red">{useResolveErrorMessageToBeDisplayedRule(props.errorMessage)}</Typography>;
};

const useOnSignUpSuccess = () => {
  const navigate = useNavigate();
  return ({ email, password }: { email: string; password: string }) => {
    setEmailVerificationData({
      email: email,
      password: password,
      origin: "signup",
    });
    navigate(PUBLIC_ROUTES.VERIFY_ACCOUNT);
  };
};
