/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { TakeTurnsTruncatedTypography } from "@taketurns-components/commons/message/TakeTurnsTruncatedTypography";

export const CardTitle = ({ title, shouldStrikeTitle }: { title: string; shouldStrikeTitle?: boolean }) => {
  return (
    <TakeTurnsTruncatedTypography
      variant="body2"
      sx={shouldStrikeTitle ? { textDecoration: "line-through" } : undefined}
    >
      {title}
    </TakeTurnsTruncatedTypography>
  );
};
