/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { PendingCreationAttachment } from "@taketurns-repositories/collaboration/models/pendingCreationAttachment";
import {
  useStorePendingCreationAttachment,
} from "@taketurns-repositories/collaboration/state/write/collaborationCreation/pendingCreationAttachment/useStorePendingCreationAttachment";
import { Upload } from "@taketurns-repositories/document/state/models/upload";
import { useStoreNewUpload } from "@taketurns-repositories/document/state/write/useStoreNewUpload";
import {
  useCheckAvailableSpaceAndNotifyInCreationRule,
} from "@taketurns-rules/collaboration/commands/collaborationCreation/useCheckAvailableSpaceAndNotifyInCreationRule";
import {
  useFilterFilesTooBigAndNotifyInCreationRule,
} from "@taketurns-rules/collaboration/commands/collaborationCreation/useFilterFilesTooBigAndNotifyInCreationRule";
import { useAddAttachmentBulkRule } from "@taketurns-rules/collaboration/commands/useAddAttachmentBulkRule";

export const useAddFilesInCreationRule = () => {
  const filterTooBigFilesAndNotify = useFilterFilesTooBigAndNotifyInCreationRule();
  const checkAvailableSpaceAndNotify = useCheckAvailableSpaceAndNotifyInCreationRule();
  const storeNewUpload = useStoreNewUpload();
  const storePendingCreationAttachment = useStorePendingCreationAttachment();
  const addAttachmentBulk = useAddAttachmentBulkRule();

  return async (files: File[], targetFolderId: string) => {
    const filesToUpload = filterTooBigFilesAndNotify(files);
    const hasEnoughSpaceAvailable = await checkAvailableSpaceAndNotify(files);
    if (!hasEnoughSpaceAvailable) return;
    const numberOfAttachments = filesToUpload.length;
    const uploadIds = [];
    for (const file of filesToUpload) {
      const upload: Upload = await storeNewUpload(file);
      const pendingCreationAttachment: PendingCreationAttachment = new PendingCreationAttachment(
        upload.documentId,
        file.name.substring(0, file.name.lastIndexOf(".")),
        file.name.substring(file.name.lastIndexOf(".") + 1),
        file.size,
        targetFolderId,
        upload.uploadId,
      );
      uploadIds.push(upload.uploadId);
      storePendingCreationAttachment(pendingCreationAttachment);
    }
    addAttachmentBulk({ numberOfAttachments, uploadIds });
  };
};
