/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Stack, Typography } from "@mui/material";
import { useSharedCollaborationTranslation } from "@taketurns-i18n/collaboration/shared/useSharedCollaborationTranslation";

export const UpgradePlanToCreateCollaborationText = () => {
  const { t } = useSharedCollaborationTranslation();
  return (
    <Stack spacing={1.5}>
      <Typography variant={"body2"}>{t("upgradeDialog.createNewCollaboration.description")}</Typography>
      <Typography variant={"body2"}>{t("upgradeDialog.createNewCollaboration.upgradeDescription")}</Typography>
    </Stack>
  );
};
