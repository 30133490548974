/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useGetOpenedAttachmentId } from "@taketurns-repositories/collaboration/state/read/useGetOpenedAttachmentId";
import { useGetAttachmentRule } from "../collaborationView/useGetAttachmentRule";

export const useGetOpenedAttachmentRule = () => {
  const attachmentId = useGetOpenedAttachmentId();
  return useGetAttachmentRule(attachmentId);
};
