/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { atomFamily } from "recoil";
import { PendingAttachment } from "@taketurns-repositories/collaboration/models/pendingAttachment";

export const pendingAttachmentsByCollaborationIdState = atomFamily<PendingAttachment[], string>({
  key: "pendingAttachmentsByCollaborationId",
  default: [],
});
