/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Button, CircularProgress, Tooltip } from "@mui/material";
import { Fragment, useRef } from "react";
import { IconUploadCloud } from "@taketurns-components/commons/icons";
import { useAttachmentDetailsTranslation } from "@taketurns-i18n/collaboration/AttachmentDetails/useAttachmentDetailsTranslation";
import { useUploadAttachmentRevisionRule } from "@taketurns-rules/collaboration/commands/attachmentRevision/useUploadAttachmentRevisionRule";
import { useIsAttachmentRevisionAddInProgressRule } from "@taketurns-rules/collaboration/queries/attachment/pending/useIsAttachmentRevisionAddInProgressRule";
import { useFetchAttachmentAvailableActionsRule } from "@taketurns-rules/collaboration/queries/attachment/useFetchAttachmentAvailableActionsRule";
import { useGetOpenedAttachmentRule } from "@taketurns-rules/collaboration/queries/attachment/useGetOpenedAttachmentRule";

export const ReplaceAttachmentRevisionButton = () => {
  const { t } = useAttachmentDetailsTranslation();
  const attachment = useGetOpenedAttachmentRule();

  const isAttachmentRevisionAddInProgress = useIsAttachmentRevisionAddInProgressRule();
  const { isUploadRevisionDisabled } = useFetchAttachmentAvailableActionsRule(attachment);

  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const addAttachmentRevisionRule = useUploadAttachmentRevisionRule(attachment);
  const uploadAttachmentRevision = async () => {
    const file = fileInputRef.current.files[0];
    await addAttachmentRevisionRule(file);
    fileInputRef.current.value = "";
  };

  return (
    <Fragment>
      <input onChange={uploadAttachmentRevision} ref={fileInputRef} type="file" hidden />
      <Tooltip
        title={t("cannotUploadRevisionOnLockedAttachment")}
        placement="bottom-end"
        disableFocusListener={!attachment.locked}
        disableHoverListener={!attachment.locked}
        disableTouchListener={!attachment.locked}
      >
        <Button
          disabled={isUploadRevisionDisabled}
          onClick={() => fileInputRef.current.click()}
          startIcon={
            isAttachmentRevisionAddInProgress ? <CircularProgress color={"secondary"} size={12} /> : <IconUploadCloud />
          }
          sx={{ fontSize: { xs: 12, sm: 14 } }}
        >
          {isAttachmentRevisionAddInProgress ? t("loading") : t("replaceRevisionButton")}
        </Button>
      </Tooltip>
    </Fragment>
  );
};
