/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useSnackbar } from "notistack";
import { useCancelPendingUpdates } from "@taketurns-repositories/collaboration/graphql/mutations/content/updates/useCancelPendingUpdates";
import { useGetDisplayedCollaborationIdRule } from "@taketurns-rules/collaboration/queries/collaboration/useGetDisplayedCollaborationIdRule";

export const useCancelPendingUpdatesRule = () => {
  const { cancelPendingUpdates, cancelPendingUpdatesResult } = useCancelPendingUpdates();
  const { enqueueSnackbar } = useSnackbar();
  const collaborationId = useGetDisplayedCollaborationIdRule();

  const cancelPendingUpdatesWithSnackbar = () => {
    cancelPendingUpdates({ collaborationId }).catch((error) => {
      console.error(error);
      enqueueSnackbar(cancelPendingUpdatesResult.error.message, { variant: "error" });
    });
  };
  return { cancelPendingUpdates: cancelPendingUpdatesWithSnackbar, cancelPendingUpdatesResult };
};
