/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { gql, useLazyQuery } from "@apollo/client";
import { StripeCheckoutUrlInput } from "@taketurns/taketurns-graphql-repository";

export const useFetchStripeCheckoutUrl = () => {
  const GET_STRIPE_CHECKOUT_URL = gql`
    query stripeCheckoutUrl($stripeCheckoutUrlInput: StripeCheckoutUrlInput!) {
      stripeCheckoutUrl(stripeCheckoutUrlInput: $stripeCheckoutUrlInput)
    }
  `;
  const [getStripeCheckoutUrlQuery, { data, loading: loadingStripeCheckoutUrl }] = useLazyQuery<{
    stripeCheckoutUrl: string;
  }>(GET_STRIPE_CHECKOUT_URL, {
    fetchPolicy: "no-cache",
  });

  const getStripeCheckoutUrl = async (stripeCheckoutUrlInput: StripeCheckoutUrlInput): Promise<string> => {
    const queryResult = await getStripeCheckoutUrlQuery({ variables: { stripeCheckoutUrlInput } });
    return queryResult.data.stripeCheckoutUrl;
  };

  return { getStripeCheckoutUrl, stripeCheckoutUrl: data?.stripeCheckoutUrl, loadingStripeCheckoutUrl };
};
