/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useQuery } from "@apollo/client";
import { attachmentFragment } from "@taketurns-repositories/collaboration/graphql/fragments/attachmentFragment";
import { requestFragment } from "@taketurns-repositories/collaboration/graphql/fragments/requestFragment";
import { Todos } from "@taketurns/taketurns-graphql-repository";

export const GET_TODOS = gql`
  query todos($collaborationId: ID!) {
    todos(collaborationId: $collaborationId) {
      attachments {
        ...AttachmentFragment
      }
      requests {
        ...RequestFragment
      }
    }
  }
  ${attachmentFragment}
  ${requestFragment}
`;

export const useFetchTodos = (collaborationId: string) => {
  const {
    data,
    loading: loadingTodos,
    error: errorOnFetchingTodos,
  } = useQuery<{ todos: Todos }>(GET_TODOS, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only",
    variables: { collaborationId },
  });
  return {
    attachments: data?.todos?.attachments,
    requests: data?.todos?.requests,
    loadingTodos,
    errorOnFetchingTodos,
  };
};
