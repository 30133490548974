/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useNavigate } from "react-router-dom";
import { getUserSettingsSectionUrl, MANAGE_PLAN_SECTION } from "@taketurns-app/routing/routes/userSettingsSections";

export const useNavigateToUserPlanPageRule = () => {
  const navigate = useNavigate();
  return () => {
    navigate(getUserSettingsSectionUrl(MANAGE_PLAN_SECTION));
  };
};
