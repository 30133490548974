/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { RemoveAttachmentRevisionOutput } from "@taketurns/taketurns-graphql-repository";
import { useAttachmentDetailsTranslation } from "@taketurns-i18n/collaboration/AttachmentDetails/useAttachmentDetailsTranslation";
import { useGetDisplayedCollaborationId } from "@taketurns-repositories/collaboration/context/useGetDisplayedCollaborationId";
import { useRemoveRevision } from "@taketurns-repositories/collaboration/graphql/mutations/content/attachments/useRemoveAttachmentRevision";
import { useSelectDocumentRevisionRule } from "@taketurns-rules/collaboration/commands/collaborationView/attachment/useSelectDocumentRevisionRule";

export const useRemoveAttachmentRevisionRule = () => {
  const { removeRevision, loadingRemoveRevision, errorOnRemovingRevision } = useRemoveRevision();
  const { t } = useAttachmentDetailsTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const collaborationId = useGetDisplayedCollaborationId();

  useEffect(() => {
    if (errorOnRemovingRevision) {
      enqueueSnackbar(t("revision.error"), { variant: "error" });
    }
  }, [errorOnRemovingRevision]);

  const { selectRevision } = useSelectDocumentRevisionRule();
  const displaySnackbarMessageOnRevisionRemoveSuccessAndSelectLatestRevision = (
    removeAttachmentRevisionOutput: RemoveAttachmentRevisionOutput,
  ) => {
    enqueueSnackbar(t("revision.removed"), { variant: "info" });
    selectRevision(removeAttachmentRevisionOutput.attachment.revisionId);
  };

  const removeRevisionCommand = (attachmentId: string) => {
    removeRevision(collaborationId, attachmentId, displaySnackbarMessageOnRevisionRemoveSuccessAndSelectLatestRevision);
  };

  return { removeRevisionCommand, loadingRemoveRevision };
};
