/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { TIP_KEYS } from "../TIP_KEYS";

type TipKeysKey = keyof typeof TIP_KEYS;
export type TipKey = (typeof TIP_KEYS)[TipKeysKey];

export const tips = {
  [TIP_KEYS.FILES]: "To upload a file revision, click on it or drag & drop a new one on it.",
  [TIP_KEYS.UPLOAD_REVISION_OR_DOWNLOAD_FILE]:
    "Use the buttons below to download or upload a new version (revision) of the file.",
  [TIP_KEYS.PARTY_NAME]: "Name both parties after the organizations or individuals who will be collaborating.",
  [TIP_KEYS.UPLOAD_FILE]: {
    begin: "If you need to upload a new version (revision) of an existing file,",
    closeDialogLink: "close this window",
    end: " and click on the file.",
  },
  [TIP_KEYS.SEND_UPDATE_DESCRIPTION]: "By sending updates, you make them accessible to every participant in the Flow.",
  [TIP_KEYS.WRAP_UP_DESCRIPTION]:
    "Wrapping-up sets the Flow to read-only. All files will be deleted after the expiration date.",
  [TIP_KEYS.RECALL_TURN]: "Recalling your updates might cancel other party's updates.",
};
