/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Typography } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { Fragment } from "react";
import { OrganizationDomain, OrganizationDomainStatus } from "@taketurns/taketurns-graphql-repository";
import { DomainRemoveButtonAndDialog } from "@taketurns-components/user/Organization/OrganizationAccessPolicies/DomainRemoveButtonAndDialog";
import { DomainVerifyButtonAndDialog } from "@taketurns-components/user/Organization/OrganizationAccessPolicies/DomainVerifyButtonAndDialog";
import { useOrganizationTranslation } from "@taketurns-i18n/user/Organization/useOrganizationTranslation";

const NAME_COLUMN_MAX_WIDTH = 400;
const STATUS_COLUMN_WIDTH = 160;

const numberOfActions = 2;
const actionButtonWidth = 34;
const margin = 10;
const numberOfExternalMargins = 2;
const actionsColumnWidth = numberOfActions * actionButtonWidth + numberOfExternalMargins * margin;

export const organizationDomainsDataGridMaxWidth = NAME_COLUMN_MAX_WIDTH + STATUS_COLUMN_WIDTH + actionsColumnWidth;

export function OrganizationDomainDataGridColumns(): GridColDef<OrganizationDomain>[] {
  return [useDomainNameColumn(), useStatusColumn(), actionsColumn()];
}

type DomainDataGridRenderCellParams = GridRenderCellParams<OrganizationDomain, OrganizationDomain, unknown>;

const useDomainNameColumn: () => GridColDef = () => {
  const { t } = useOrganizationTranslation();

  return {
    field: "name",
    cellClassName: "textBlackColor",
    headerName: t("OrganizationDomainList.DataGridColumns.name"),
    flex: 1.5,
    minWidth: 180,
    maxWidth: NAME_COLUMN_MAX_WIDTH,
    valueGetter: (params: DomainDataGridRenderCellParams) => params.row.name,
  };
};

const useStatusColumn: () => GridColDef = () => {
  const { t } = useOrganizationTranslation();

  return {
    field: "status",
    cellClassCode: "textBlackColor",
    headerName: t("OrganizationDomainList.DataGridColumns.status"),
    flex: 1.5,
    minWidth: 140,
    maxWidth: STATUS_COLUMN_WIDTH,
    valueGetter: (params: DomainDataGridRenderCellParams) => t(`OrganizationDomain.status.${params.row.status}`),
    renderCell: (params) => (
      <Typography variant="body2" data-cy={`Status.${params.value}`}>
        {params.value}
      </Typography>
    ),
  };
};

const actionsColumn: () => GridColDef = () => {
  function domainActions(domain: OrganizationDomain) {
    if (domain.status === OrganizationDomainStatus.Unverified) {
      return (
        <Fragment>
          <DomainVerifyButtonAndDialog domain={domain} />
          <DomainRemoveButtonAndDialog domain={domain} />
        </Fragment>
      );
    } else {
      return <DomainRemoveButtonAndDialog domain={domain} />;
    }
  }

  return {
    width: actionsColumnWidth,
    field: "actions",
    headerName: "",
    align: "right",
    filterable: false,
    disableColumnMenu: true,
    hideSortIcons: true,
    renderCell: (params) => domainActions(params.row),
  };
};
