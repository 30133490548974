/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useGetCollaborationFormName } from "@taketurns-repositories/collaboration/state/read/collaborationCreation/form/useGetCollaborationFormName";
import { useSetCollaborationFormNameFieldError } from "@taketurns-repositories/collaboration/state/write/collaborationCreation/form/error/useSetCollaborationFormNameFieldError";

export const useValidateCollaborationNameRule = () => {
  const collaborationName = useGetCollaborationFormName();
  const setCollaborationFormNameFieldError = useSetCollaborationFormNameFieldError();

  return () => {
    const isNameValid = collaborationName.length > 0;
    setCollaborationFormNameFieldError(!isNameValid);
    return isNameValid;
  };
};
