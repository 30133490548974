/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useRecoilCallback } from "recoil";
import { useRemoveAttachmentInCreationRule } from "@taketurns-rules/collaboration/commands/collaborationCreation/attachment/useRemoveAttachmentInCreationRule";
import { FolderInCreation } from "../../../../models/folderInCreation";
import { folderByIdState } from "../../../recoil/collaborationCreation/folderByIdState";
import { parentFolderIdByFolderIdState } from "../../../recoil/collaborationCreation/parentFolderIdByFolderIdState";
import { pathInCreationState } from "../../../recoil/collaborationCreation/pathInCreationState";
import { useRemoveRequestInCreation } from "../request/useRemoveRequestInCreation";

export const useRemoveFolderInCreation = () => {
  const removeAttachment = useRemoveAttachmentInCreationRule();
  const removeRequest = useRemoveRequestInCreation();

  const removeFolder = useRecoilCallback(
    ({ reset, snapshot, set }) =>
      (folderId: string) => {
        const folder = snapshot.getLoadable(folderByIdState(folderId)).contents as FolderInCreation;
        const path = snapshot.getLoadable(pathInCreationState).contents as string[];
        folder.attachments.forEach((attachmentId) => removeAttachment(attachmentId));
        folder.folders.forEach((folderId) => removeFolder(folderId));
        folder.requests.forEach((requestId) => removeRequest(requestId));
        const parentFolderId = snapshot.getLoadable(parentFolderIdByFolderIdState(folderId)).contents as string;
        if (parentFolderId) {
          set(folderByIdState(parentFolderId), (parentFolder) => ({
            ...parentFolder,
            folders: parentFolder.folders.filter((id) => id !== folderId),
          }));
          reset(parentFolderIdByFolderIdState(folderId));
        }
        reset(folderByIdState(folderId));
        if (path.at(-1) === folderId) {
          set(pathInCreationState, (path) => path.slice(0, -1));
        }
      },
    [removeAttachment, removeRequest],
  );
  return removeFolder;
};
