/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useEffect } from "react";
import {
  useMarkPendingAttachmentRevisionAsCancelled,
} from "@taketurns-repositories/collaboration/state/write/pendingAttachmentRevision/useMarkPendingAttachmentRevisionAsCancelled";
import { useGetCancelledUploadIds } from "@taketurns-repositories/document/state/read/useGetCancelledUploadIds";
import {
  useGetAllPendingAttachmentRevisionsRule,
} from "@taketurns-rules/collaboration/queries/attachment/pending/useGetAllPendingAttachmentRevisionsRule";

export const useListenToCancelledUploadsToCancelAddAttachmentRevisionRule = () => {
  const cancelledUploadIds = useGetCancelledUploadIds();
  const pendingAttachmentRevisions = useGetAllPendingAttachmentRevisionsRule();
  const markPendingAttachmentAsCancelled = useMarkPendingAttachmentRevisionAsCancelled();

  useEffect(() => {
    for (const cancelledUploadId of cancelledUploadIds) {
      const pendingAttachmentRevisionToUpdate = pendingAttachmentRevisions.find(
        (p) => p.uploadId === cancelledUploadId,
      );
      if (pendingAttachmentRevisionToUpdate && !pendingAttachmentRevisionToUpdate.isCancelled()) {
        markPendingAttachmentAsCancelled(pendingAttachmentRevisionToUpdate);
      }
    }
  }, [cancelledUploadIds]);
};
