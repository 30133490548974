/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useMutation } from "@apollo/client";

export const useAddOrRemoveToFavorite = () => {
  const mutation = gql`
    mutation addOrRemoveFavorite($input: AddOrRemoveFavoriteInput!) {
      addOrRemoveFavorite(input: $input) {
        id
        favorites
      }
    }
  `;
  const [addToFavoriteMutation, addToFavoriteResult] = useMutation(mutation);

  const addOrRemoveToFavoriteCommand = (favorites: string[], collaborationId: string) => {
    const addFavorite = !favorites.includes(collaborationId);
    addToFavoriteMutation({
      variables: { input: { id: collaborationId, add: addFavorite } },
    });
  };

  return {
    addOrRemoveToFavoriteCommand,
    errorOnAddOrRemoveToFavorite: addToFavoriteResult.error,
    loadingAddOrRemoveToFavorite: addToFavoriteResult.loading,
  };
};
