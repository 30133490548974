/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Button, Stack, styled } from "@mui/material";
import { ComponentProps } from "react";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";

export const ContentFooterMenu__Container = styled(Stack)({
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
});

export const ContentFooterMenu__ButtonsContainer = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  gap: theme.spacing(1.5),
  alignItems: "center",

  [theme.breakpoints.up("lg")]: {
    gap: theme.spacing(3),
  },
}));

export const ContentSelectionFooterTextButton = (props: ComponentProps<typeof Button>) => (
  <Button
    variant="text"
    disableRipple
    sx={{
      color: TakeTurnsColors.white,
      "&:hover": {
        color: TakeTurnsColors.white,
        opacity: 0.8,
      },
      "&:disabled": {
        color: TakeTurnsColors.mediumGray,
      },
    }}
    {...props}
  />
);
