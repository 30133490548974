/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Box, DialogContent, DialogTitle, Divider, IconButton, Stack, Typography } from "@mui/material";
import { AddFilesButton } from "@taketurns-components/collaboration/Shared/content/AddFilesButton";
import { TakeTurnsDialogFooter } from "@taketurns-components/collaboration/Shared/content/TakeTurnsDialogFooter";
import { DropZoneConfig, FilesDragAndDrop } from "@taketurns-components/collaboration/Shared/FilesDragAndDrop";
import { TakeTurnsListItem } from "@taketurns-components/collaboration/Shared/list/TakeTurnsListItem";
import { TakeTurnsDefaultButton } from "@taketurns-components/commons/button";
import { IconX } from "@taketurns-components/commons/icons";
import { FileExtension } from "@taketurns-components/document/DocumentView/FileExtension";
import { useCollaborationViewTranslation } from "@taketurns-i18n/collaboration/CollaborationView/useCollaborationViewTranslation";
import { Request } from "@taketurns-repositories/collaboration/models/request";
import { useGetOpenedRequestFulfillmentRequest } from "@taketurns-repositories/collaboration/state/read/collaborationView/useGetOpenedRequestFulfillmentRequest";
import { useCloseRequestFulfillmentDialogRule } from "@taketurns-rules/collaboration/commands/collaborationView/request/useCloseRequestFulfillmentDialogRule";
import { useRequestFulfillmentContentRules } from "@taketurns-rules/collaboration/commands/collaborationView/request/useRequestFulfillmentContentRules";
import { CollaborationCommand, useCollaborationPermissions } from "@taketurns-rules/collaboration/permissions/useCollaborationPermissions";
import { HelpCenterSections } from "@taketurns-rules/commons/enum/helpCenterSectionsEnum";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { Fragment } from "react";

export const DesktopRequestFulfillmentDialogContent = () => {
  const { t } = useCollaborationViewTranslation();

  const openedRequestFulfillmentRequest: Request = useGetOpenedRequestFulfillmentRequest();
  const closeRequestFulfillmentDialog = useCloseRequestFulfillmentDialogRule();
  const allowedToAddFiles = useCollaborationPermissions(CollaborationCommand.ADD_FILES);
  const { pendingFiles, addPendingFiles, addPendingFileList, removePendingFile, fulfillRequestAndCloseDialog } =
    useRequestFulfillmentContentRules();

  return (
    <Fragment>
      <DialogTitle
        sx={{
          display: "flex",
          flexFlow: "row nowrap",
          paddingY: 3,
        }}
      >
        <Stack direction="column" flex="1 1 auto">
          <Typography variant="h6">
            <span style={{ color: TakeTurnsColors.lightBlue }}>{t("requestFulfillment.dialog.titlePrefix")}</span>&nbsp;
            <span>{openedRequestFulfillmentRequest.requestName}</span>
          </Typography>
          <Typography fontSize={"0.875rem"} color={TakeTurnsColors.darkGray}>
            {openedRequestFulfillmentRequest.requestDescription}
          </Typography>
        </Stack>
        {allowedToAddFiles && (
          <Box component="span" flex="0 0 auto">
            <AddFilesButton processFiles={addPendingFileList} longWordingMode />
          </Box>
        )}
      </DialogTitle>
      <Divider />
      <RequestContentWithDropZone />
      <TakeTurnsDialogFooter
        closeDialog={closeRequestFulfillmentDialog}
        hasCancelAction
        helpCenterSection={HelpCenterSections.FULFILL_REQUEST}
      >
        <TakeTurnsDefaultButton onClick={fulfillRequestAndCloseDialog} disabled={!pendingFiles.length}>
          {t("requestFulfillment.dialog.submitButton.label")}
        </TakeTurnsDefaultButton>
      </TakeTurnsDialogFooter>
    </Fragment>
  );

  function RequestContentWithDropZone() {
    const dropZoneConfig: DropZoneConfig = {
      handleFilesDropping: addPendingFiles,
      className: "RequestContentWithDropZone__DropZoneContainer",
      sx: {
        flex: 1,
        position: "relative",
        display: "flex",
        flexFlow: "column nowrap",
        py: 0,
        px: 3,
      },
    };
    return (
      <FilesDragAndDrop ContainerComponent={DialogContent} dropZoneConfig={dropZoneConfig}>
        {!pendingFiles.length && <EmptyContent />}
        {!!pendingFiles.length && <PendingFilesList />}
      </FilesDragAndDrop>
    );
  }

  function EmptyContent() {
    return (
      <Stack flex={1} spacing={1} alignItems="center" justifyContent="center">
        <AddFilesButton processFiles={addPendingFileList} variant={"text"} />
        <Typography fontSize="0.875rem" color={TakeTurnsColors.mediumGray}>
          {t("requestFulfillment.dialog.instruction.message")}
        </Typography>
      </Stack>
    );
  }

  function PendingFilesList() {
    return (
      <Fragment>
        {pendingFiles.map((file) => (
          <TakeTurnsListItem
            key={file.name}
            titleSlot={<Typography variant="body2"> {file.name}</Typography>}
            rightEndSlot={
              <Stack direction={"row"} alignItems={"center"}>
                <FileExtension
                  fileExtension={file.name.substring(file.name.lastIndexOf(".") + 1)}
                  sizeInBytes={file.size}
                />
                <IconButton size="small" disableRipple onClick={() => removePendingFile(file.name)}>
                  <IconX size={20} />
                </IconButton>
              </Stack>
            }
          />
        ))}
      </Fragment>
    );
  }
};
