/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useMutation } from "@apollo/client";
import {
  MutationPrepareArchiveDownloadArgs,
  PrepareArchiveDownloadInput,
  PrepareContentDownloadResponse,
} from "@taketurns/taketurns-graphql-repository";

export const usePrepareDownloadCollaborationArchive = () => {
  const mutation = gql`
    mutation prepareArchiveDownload($input: PrepareArchiveDownloadInput!) {
      prepareArchiveDownload(input: $input) {
        downloadId
      }
    }
  `;
  const [
    prepareArchiveDownloadMutation,
    { data, error: errorOnPrepareArchiveDownload, loading: loadingPrepareArchiveDownload },
  ] = useMutation<
    {
      prepareArchiveDownload: PrepareContentDownloadResponse;
    },
    MutationPrepareArchiveDownloadArgs
  >(mutation);
  const prepareArchiveDownload = (input: PrepareArchiveDownloadInput) => {
    prepareArchiveDownloadMutation({ variables: { input } });
  };
  return {
    prepareArchiveDownload,
    downloadId: data?.prepareArchiveDownload?.downloadId,
    errorOnPrepareArchiveDownload,
    loadingPrepareArchiveDownload,
  };
};
