/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useEffect, useState } from "react";
import { APIErrorResponse, Channel } from "stream-chat";
import { useChatContext as useStreamChatContext } from "stream-chat-react";
import { DefaultStreamChatGenerics } from "stream-chat-react/dist/types/types";
import { wait } from "@taketurns-rules/commons/util/wait";

export const useJoinAndGetStreamChatChannel = (collaborationId: string) => {
  const [channel, setChannel] = useState<Channel<DefaultStreamChatGenerics> | null>(null);
  const [loadingStreamChatWatchChannel, setLoadingStreamChatWatchChannel] = useState(true);
  const [errorOnStreamChatWatchChannel, setErrorOnStreamChatWatchChannel] = useState<APIErrorResponse | null>(null);

  const { client } = useStreamChatContext();
  useEffect(() => {
    const joinAndGetStreamChatChannel = async () => {
      const MAX_RETRY_ATTEMPT = 10;
      for (
        let retryCount = 1, chatSuccessfullyConnected = false;
        !chatSuccessfullyConnected && retryCount <= MAX_RETRY_ATTEMPT;
        retryCount++
      ) {
        try {
          const channel = client.channel("team", collaborationId, {
            name: collaborationId,
          });
          await channel.watch();
          setChannel(channel);
          setErrorOnStreamChatWatchChannel(null);
          setLoadingStreamChatWatchChannel(false);
          chatSuccessfullyConnected = true;
        } catch (error: unknown) {
          if (retryCount < MAX_RETRY_ATTEMPT) {
            wait(1000);
          } else {
            console.error(error);
            setErrorOnStreamChatWatchChannel(error as APIErrorResponse);
            setLoadingStreamChatWatchChannel(false);
          }
        }
      }
    };

    if (client) {
      joinAndGetStreamChatChannel();
    }
  }, [client]);

  return {
    channel,
    loading: loadingStreamChatWatchChannel,
    technicalError: errorOnStreamChatWatchChannel,
    notAuthorizedError: errorOnStreamChatWatchChannel,
  };
};
