/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Box } from "@mui/material";
import {
  ContentFooterMenu__ButtonsContainer,
  ContentFooterMenu__Container,
} from "@taketurns-components/collaboration/CollaborationView/CollaborationContent/ContentBrowser/ContentFooterMenu/shared";
import { EnableContentSelectionButton } from "@taketurns-components/collaboration/CollaborationView/CollaborationContent/ContentBrowser/ContentSelectionButtons/EnableContentSelectionButton";
import { ChangeContentViewModeButton } from "@taketurns-components/collaboration/Shared/button/ChangeContentViewModeButton";
import { AddAnyContentButton } from "@taketurns-components/collaboration/Shared/MobileAddContent/AddAnyContentButton";
import { useAddFolderRule } from "@taketurns-rules/collaboration/commands/collaborationView/folder/useAddFolderRule";
import { useAddRequestRule } from "@taketurns-rules/collaboration/commands/collaborationView/request/useAddRequestRule";
import { useIsOnMobileRule } from "@taketurns-rules/commons/theme/useIsOnMobileRule";
import { UploadsProgressIndicator } from "../../../../../document/DocumentUpload/UploadsProgressIndicator/UploadsProgressIndicator";

interface DefaultContentFooterMenuProps {
  canUserAddFiles: boolean;
  canUserAddFolders: boolean;
  canUserAddRequests: boolean;
  displayContentAsCard: boolean;
  onToggleViewModeClick: () => unknown;
  isEmptyContent?: boolean;
  uploadIds: number[];
  attachFiles: (files: FileList) => Promise<void>;
  addFolder: ReturnType<typeof useAddFolderRule>["addFolder"];
  addRequest: ReturnType<typeof useAddRequestRule>["addRequest"];
}

export const DefaultContentFooterMenu = (props: DefaultContentFooterMenuProps) => {
  const {
    canUserAddFiles,
    canUserAddFolders,
    canUserAddRequests,
    displayContentAsCard,
    onToggleViewModeClick,
    isEmptyContent,
    uploadIds,
    attachFiles,
    addFolder,
    addRequest,
  } = props;

  const canUserAddAnyContent = canUserAddFiles || canUserAddFolders || canUserAddRequests;
  const isOnMobile = useIsOnMobileRule();

  return (
    <ContentFooterMenu__Container>
      <ContentFooterMenu__ButtonsContainer>
        {canUserAddAnyContent && (
          <AddAnyContentButton
            canUserAddFiles={canUserAddFiles}
            canUserAddFolders={canUserAddFolders}
            canUserAddRequests={canUserAddRequests}
            attachFiles={attachFiles}
            addFolder={addFolder}
            addRequest={addRequest}
          />
        )}
        {!isEmptyContent && <EnableContentSelectionButton />}
      </ContentFooterMenu__ButtonsContainer>
      <Box display="flex">
        {uploadIds.length > 0 && <UploadsProgressIndicator uploadIds={uploadIds} />}
        {!isEmptyContent && !isOnMobile && (
          <ChangeContentViewModeButton onClick={onToggleViewModeClick} cardView={displayContentAsCard} />
        )}
      </Box>
    </ContentFooterMenu__Container>
  );
};
