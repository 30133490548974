/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useSetOpenedAttachmentDetailsDocumentId } from "@taketurns-repositories/collaboration/state/write/useSetOpenedAttachmentDetailsDocumentId";

export const useOpenAttachmentDetailsRule = (attachmentId: string) => {
  const setOpenedDocumentDetailsDocumentId = useSetOpenedAttachmentDetailsDocumentId();

  return () => {
    setOpenedDocumentDetailsDocumentId(attachmentId);
  };
};
