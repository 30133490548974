/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Button, Tooltip } from "@mui/material";
import { Fragment, useCallback, useState } from "react";
import { useSharedCollaborationTranslation } from "@taketurns-i18n/collaboration/shared/useSharedCollaborationTranslation";
import { IconRequest } from "../../../commons/icons";
import { AddRequestDialog } from "./AddRequestDialog";

interface AddRequestButtonProps {
  addRequest: (requestName: string, requestDescription?: string) => unknown;
  longWordingMode?: boolean;
  onDialogOpen?: () => void;
}

export const AddRequestButton = ({ addRequest, longWordingMode, onDialogOpen }: AddRequestButtonProps) => {
  const [isAddRequestDialogOpen, setIsAddRequestDialogOpen] = useState(false);

  const closeAddRequestDialog = useCallback(() => {
    setIsAddRequestDialogOpen(false);
  }, []);

  const openAddRequestDialog = useCallback(() => {
    onDialogOpen?.();
    setIsAddRequestDialogOpen(true);
  }, []);

  const { t } = useSharedCollaborationTranslation();
  const wording = longWordingMode ? t("AddRequestButton.label") : t("AddRequestButton.shortLabel");
  return (
    <Fragment>
      <Tooltip
        title={t("AddRequestButton.tooltip")}
        disableFocusListener={longWordingMode}
        disableHoverListener={longWordingMode}
        disableTouchListener={longWordingMode}
      >
        <Button
          startIcon={<IconRequest size={24} />}
          variant={"text"}
          onClick={openAddRequestDialog}
          sx={{ "& .MuiButton-startIcon": { mr: { xs: "3px", lg: "6px" } } }}
        >
          {wording}
        </Button>
      </Tooltip>
      <AddRequestDialog open={isAddRequestDialogOpen} onClose={closeAddRequestDialog} addRequest={addRequest} />
    </Fragment>
  );
};
