/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { SubscriptionPlan, SubscriptionPlanStatus } from "@taketurns/taketurns-graphql-repository";
import { useUserNotificationCenterTranslation } from "@taketurns-i18n/user/UserNotificationCenter/useUserNotificationCenterTranslation";
import { useOpenStripePortalUrlRule } from "@taketurns-rules/user/commands/useOpenStripePortalUrlRule";
import { computeTrialRemainingDaysRule } from "@taketurns-rules/user/queries/computeTrialRemainingDaysRule";
import { TrialExpiredNotification } from "./TrialExpiredNotification";
import { TrialRemainingTimeNotificationChip } from "./TrialRemainingTimeNotificationChip";

export const TrialRemainingTimeNotification = ({
  userSubscriptionPlanTrial,
}: {
  userSubscriptionPlanTrial: SubscriptionPlan;
}) => {
  const { openStripePortal, loadingStripePortal } = useOpenStripePortalUrlRule();
  const { t } = useUserNotificationCenterTranslation();

  const buyPlanInStripe = () => {
    openStripePortal();
  };

  if (userSubscriptionPlanTrial.status === SubscriptionPlanStatus.TrialExpired) {
    return <TrialExpiredNotification />;
  }

  return (
    <TrialRemainingTimeNotificationChip
      label={t("TrialRemainingTimeNotification.trialWillExpire", {
        count: computeTrialRemainingDaysRule(userSubscriptionPlanTrial.trialEndDate),
      })}
      buttonLabel={t("TrialRemainingTimeNotification.subscribe")}
      loading={loadingStripePortal}
      onClick={buyPlanInStripe}
    />
  );
};
