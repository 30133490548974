/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useFetchConnectedUserId } from "@taketurns-repositories/user/graphql/queries/useFetchConnectedUserId";
import { useSubscribeToAddOrRemoveInvitationToOrganization } from "@taketurns-repositories/user/graphql/subscriptions/useSubscribeToAddOrRemoveInvitationToOrganization";

export const useOnAddedOrRemovedInvitationToOrganizationRule = () => {
  const { userId, errorOnConnectedUserId } = useFetchConnectedUserId();
  if (errorOnConnectedUserId) {
    console.error(errorOnConnectedUserId);
  }
  useSubscribeToAddOrRemoveInvitationToOrganization(userId);
};
