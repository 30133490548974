/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useRenameFolder } from "@taketurns-repositories/collaboration/graphql/mutations/content/folders/useRenameFolder";
import { useGetDisplayedCollaborationIdRule } from "@taketurns-rules/collaboration/queries/collaboration/useGetDisplayedCollaborationIdRule";

export const useRenameFolderRule = (folderId: string) => {
  const collaborationId = useGetDisplayedCollaborationIdRule();

  const { renameFolder, renameFolderResponseData, renameFolderLoading, errorOnRenamingFolder } = useRenameFolder(
    collaborationId,
    folderId,
  );
  return {
    renameFolder,
    renameFolderResponseData: renameFolderResponseData,
    renameFolderLoading: renameFolderLoading,
    errorOnRenamingFolder: errorOnRenamingFolder,
  };
};
