/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useQuery } from "@apollo/client";
import { Organization } from "@taketurns/taketurns-graphql-repository";

const USER_FIELD = `id firstName lastName email avatar`;

export const USER_ORGANIZATION_MEMBERS = gql`
  query userOrganizationMembers {
    userOrganization {
      id
      invitations {
        email
        userId
        user {
          ${USER_FIELD}
        }
        role
      }
      members {
        userId
        user {
          ${USER_FIELD}
        }
        role
        supervisor {
          ${USER_FIELD}
        }
      }
    }
  }
`;

export const useFetchUserOrganizationMembers = () => {
  const {
    data,
    loading: loadingUserOrganizationMembers,
    error: errorOnLoadingUserOrganizationMembers,
  } = useQuery<{
    userOrganization: Organization;
  }>(USER_ORGANIZATION_MEMBERS);
  return {
    members: data?.userOrganization?.members ?? [],
    invitations: data?.userOrganization?.invitations ?? [],
    loadingUserOrganizationMembers,
    errorOnLoadingUserOrganizationMembers,
  };
};
