/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { ApolloCache, gql, useMutation } from "@apollo/client";
import { IS_PASSWORD_ALREADY_SET } from "../queries/useFetchIsConnectedUserPasswordAlreadySet";

interface ChangeUserPasswordInput {
  accessToken: string;
  previousPassword: string;
  newPassword: string;
}

export const useChangePassword = () => {
  const CHANGE_PASSWORD = gql`
    mutation changePassword($accessToken: String!, $previousPassword: String!, $newPassword: String!) {
      changePassword(accessToken: $accessToken, previousPassword: $previousPassword, newPassword: $newPassword)
    }
  `;
  const [changePasswordMutation, { loading: loadingChangeUserPassword }] = useMutation<{
    changePassword: boolean;
  }>(CHANGE_PASSWORD);

  const changeUserPassword = ({ accessToken, previousPassword, newPassword }: ChangeUserPasswordInput) => {
    return changePasswordMutation({
      variables: { accessToken, previousPassword, newPassword },
      update: (cache, { data: { changePassword: isPasswordSet } }) =>
        updateIsPasswordAlreadySetQuery(cache, isPasswordSet),
    });
  };
  return { changeUserPassword, loadingChangeUserPassword };
};

export const updateIsPasswordAlreadySetQuery = (cache: ApolloCache<unknown>, isPasswordSet: boolean) => {
  if (isPasswordSet) {
    cache.updateQuery({ query: IS_PASSWORD_ALREADY_SET }, () => ({
      isConnectedUserPasswordAlreadySet: isPasswordSet,
    }));
  }
};
