/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useSetRecoilState } from "recoil";
import { PendingCreationAttachment } from "../../../../models/pendingCreationAttachment";
import { PendingCreationAttachmentStatus } from "../../../../models/pendingCreationAttachmentStatus";
import { pendingCreationAttachmentsState } from "../../../recoil/collaborationCreation/pendingCreationAttachmentsState";

export function useSetPendingCreationAttachmentStatus() {
  const setPendingCreationAttachments = useSetRecoilState(pendingCreationAttachmentsState);
  return (pendingCreationAttachment: PendingCreationAttachment, status: PendingCreationAttachmentStatus) => {
    const updatedPendingCreationAttachment = pendingCreationAttachment.copy();
    updatedPendingCreationAttachment.status = status;
    setPendingCreationAttachments((pendingCreationAttachments) => [
      ...pendingCreationAttachments.filter((p) => p.documentId !== pendingCreationAttachment.documentId),
      updatedPendingCreationAttachment,
    ]);
  };
}
