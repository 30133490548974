/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useSnackbar } from "notistack";
import { useSharedCollaborationTranslation } from "@taketurns-i18n/collaboration/shared/useSharedCollaborationTranslation";
import { useIsCollaborationFromUserOrganizationRule } from "@taketurns-rules/collaboration/queries/collaboration/useIsCollaborationFromUserOrganizationRule";

export const useNotifyMaxFileSizeReachedRule = (maxFileSizeInMB: number) => {
  const { t } = useSharedCollaborationTranslation();
  const isCollaborationFromUserOrganizationRule = useIsCollaborationFromUserOrganizationRule();
  const { enqueueSnackbar } = useSnackbar();

  return async (fileName: string) => {
    const isCollaborationFromUserOrganization = await isCollaborationFromUserOrganizationRule();

    if (isCollaborationFromUserOrganization) {
      enqueueSnackbar(t("addAttachment.maxFileSizeReached.userCollaboration", { fileName, maxFileSizeInMB }), {
        variant: "error",
      });
    } else {
      enqueueSnackbar(t("addAttachment.maxFileSizeReached.notUserCollaboration", { fileName, maxFileSizeInMB }), {
        variant: "error",
      });
    }
  };
};
