/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useMutation } from "@apollo/client";
import {
  MarkUnmarkAsReviewedAttachmentOutput,
  MutationUnmarkAttachmentAsReviewedArgs,
  UnmarkAttachmentAsReviewedInput,
} from "@taketurns/taketurns-graphql-repository";
import { useGetConnectedUserPartyId } from "@taketurns-repositories/collaboration/context/useGetConnectedUserPartyId";
import { useGetContentMetadataInCache } from "@taketurns-repositories/collaboration/graphql/cache/useGetContentMetadataInCache";
import { markUnmarkAttachmentAsReviewedFragment } from "@taketurns-repositories/collaboration/graphql/mutations/content/attachments/useMarkAttachmentAsReviewed";
import { useGetAttachmentRule } from "@taketurns-rules/collaboration/queries/collaborationView/useGetAttachmentRule";

export const useUnmarkAttachmentAsReviewed = (input: UnmarkAttachmentAsReviewedInput) => {
  const connectedUserPartyId = useGetConnectedUserPartyId();
  const attachment = useGetAttachmentRule(input.attachmentId);
  const getContentMetadataInCache = useGetContentMetadataInCache();

  const mutation = gql`
    mutation unmarkAttachmentAsReviewed($input: UnmarkAttachmentAsReviewedInput!) {
      unmarkAttachmentAsReviewed(input: $input) {
        ...MarkUnmarkAttachmentAsReviewedFragment
      }
    }
    ${markUnmarkAttachmentAsReviewedFragment}
  `;
  const [unmarkAttachmentAsReviewedMutationFromGraphQL, { data, loading, error }] = useMutation<
    { unmarkAttachmentAsReviewed: MarkUnmarkAsReviewedAttachmentOutput },
    MutationUnmarkAttachmentAsReviewedArgs
  >(mutation);
  const unmarkAttachmentAsReviewedMutation = () => {
    const contentMetadata = getContentMetadataInCache(input.collaborationId);
    return unmarkAttachmentAsReviewedMutationFromGraphQL({
      variables: { input },
      optimisticResponse: {
        unmarkAttachmentAsReviewed: {
          collaborationId: input.collaborationId,
          partyId: connectedUserPartyId,
          attachmentId: input.attachmentId,
          attachment: {
            ...attachment,
            markedAsReviewed: false,
            toReview: true,
          },
          contentMetadata: {
            ...contentMetadata,
            todoCount: contentMetadata.todoCount - 1,
          },
        },
      },
    });
  };
  return {
    unmarkAttachmentAsReviewedMutation,
    unmarkAttachmentAsReviewedResponseData: data?.unmarkAttachmentAsReviewed,
    loadingUnmarkAttachmentAsReviewed: loading,
    errorOnUnmarkingAttachmentAsReviewed: error,
  };
};
