/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { gql, useFragment } from "@apollo/client";

export const requestFragment = gql`
  fragment RequestFragment on Request {
    id
    name @nonreactive
    description @nonreactive
    party @nonreactive
    status @nonreactive
    prevStatus @nonreactive
  }
`;

export const useGetRequestFragment = (requestId: string) => {
  return useFragment({
    fragment: requestFragment,
    from: { id: requestId, __typename: "Request" },
  });
};
