/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Box, debounce, Divider, InputAdornment, styled, TextField } from "@mui/material";
import { forwardRef, Fragment, useCallback, useEffect, useImperativeHandle, useMemo, useState } from "react";
import { OrganizationMemberSubordinate } from "@taketurns/taketurns-graphql-repository";
import { SupervisorFilterFooter } from "@taketurns-components/collaboration/CollaborationList/SupervisorFilter/SupervisorFilterFooter";
import { SupervisorFilterItem } from "@taketurns-components/collaboration/CollaborationList/SupervisorFilter/SupervisorFilterItem";
import { IconSearch } from "@taketurns-components/commons/icons";
import { useSetDefaultSelectedSupervisorFilterItemsRule } from "@taketurns-rules/collaboration/commands/useSetDefaultSelectedSupervisorFilterItemsRule";
import { useApplySupervisorFilterRule } from "@taketurns-rules/collaboration/queries/useApplySupervisorFilterRule";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { useFetchConnectedUserRule } from "@taketurns-rules/user/queries/useFetchConnectedUserRule";
import { useSearchSubordinatesRule } from "@taketurns-rules/user/queries/useSearchSubordinatesRule";

export interface SupervisorFilterItemListRef {
  unselectAllSupervisorFilterItems: () => void;
}

interface SupervisorFilterItemListProps {
  closeMenu: () => void;
}

export const SupervisorFilterItemList = forwardRef<SupervisorFilterItemListRef, SupervisorFilterItemListProps>(({ closeMenu }, ref) => {
  const { connectedUser, errorOnFetchingConnectedUser, loadingConnectedUser } = useFetchConnectedUserRule();
  useSetDefaultSelectedSupervisorFilterItemsRule();
  const [checkedFilterItemByUserId, setCheckedFilterItemByUserId] = useState(new Map<string, boolean>([[connectedUser.id, true]]));

  useImperativeHandle(
    ref,
    () => ({
      unselectAllSupervisorFilterItems: () => {
        setCheckedFilterItemByUserId(new Map<string, boolean>([[connectedUser.id, true]]));
      },
    }),
    [connectedUser.id],
  );

  const { subordinates, searchSubordinates } = useSearchSubordinatesRule();
  const updateCheckedBySubordinateUserIdWithSearchResult = useCallback(
    (foundSubordinates: OrganizationMemberSubordinate[]) => {
      setCheckedFilterItemByUserId((prevState) => {
        const updatedCheckedBySubordinateUserId = new Map<string, boolean>();
        updatedCheckedBySubordinateUserId.set(connectedUser.id, prevState.get(connectedUser.id));
        for (const subordinate of foundSubordinates) {
          updatedCheckedBySubordinateUserId.set(subordinate.userId, prevState.get(subordinate.userId) ?? false);
        }
        return updatedCheckedBySubordinateUserId;
      });
    },
    [connectedUser?.id],
  );
  useEffect(() => {
    searchSubordinates("").then((foundSubordinates) => {
      updateCheckedBySubordinateUserIdWithSearchResult(foundSubordinates);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const debouncedSearchSubordinates = useMemo(
    () =>
      debounce(async (query) => {
        await searchSubordinates(query).then((foundSubordinates) => {
          updateCheckedBySubordinateUserIdWithSearchResult(foundSubordinates);
        });
      }, 400),
    [searchSubordinates, updateCheckedBySubordinateUserIdWithSearchResult],
  );

  const toggleSubordinate = (subordinateUserId: string) => {
    setCheckedFilterItemByUserId((prevState) => {
      const updatedCheckedBySubordinateUserId = new Map(prevState);
      updatedCheckedBySubordinateUserId.set(subordinateUserId, !prevState.get(subordinateUserId));
      return updatedCheckedBySubordinateUserId;
    });
  };

  const applySupervisorFilter = useApplySupervisorFilterRule();
  const applyFilter = () => {
    const hasNoSelectedFilterItemChecked = [...checkedFilterItemByUserId.values()].every((value) => !value);
    if (hasNoSelectedFilterItemChecked) {
      toggleSubordinate(connectedUser.id);
      applySupervisorFilter([connectedUser.id]);
    } else {
      const selectedFilterItemUserIds = [...checkedFilterItemByUserId].filter(([_userId, checked]) => checked).map(([userId]) => userId);
      applySupervisorFilter(selectedFilterItemUserIds);
    }
    closeMenu();
  };

  if (loadingConnectedUser || errorOnFetchingConnectedUser) {
    return null;
  }
  return (
    <Fragment>
      <SupervisorFilterItem
        key={connectedUser.id}
        organizationMember={{
          firstName: connectedUser.firstName,
          lastName: connectedUser.lastName,
          email: connectedUser.email,
          avatar: connectedUser.avatar,
          userId: connectedUser.id,
        }}
        checked={checkedFilterItemByUserId.get(connectedUser.id)}
        onItemClicked={() => toggleSubordinate(connectedUser.id)}
      />
      <Divider sx={{ mb: "16px" }} />
      <Box sx={{ px: 3 }}>
        <TextField
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconSearch size={18} color={TakeTurnsColors.mediumGray} />
              </InputAdornment>
            ),
          }}
          onChange={(event) => debouncedSearchSubordinates((event.target as HTMLInputElement).value)}
        />
      </Box>
      <SearchResult__Container>
        {subordinates?.map((subordinate) => (
          <SupervisorFilterItem
            key={subordinate.userId}
            organizationMember={subordinate}
            checked={checkedFilterItemByUserId.get(subordinate.userId) || false}
            onItemClicked={() => toggleSubordinate(subordinate.userId)}
          />
        ))}
      </SearchResult__Container>
      <SupervisorFilterFooter setCheckedFilterItemByUserId={setCheckedFilterItemByUserId} applyFilter={applyFilter} closeMenu={closeMenu} />
    </Fragment>
  );
});

const SearchResult__Container = styled(Box)({
  flex: 1,
  overflowY: "auto",
});
