/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Folder } from "@taketurns/taketurns-graphql-repository";
import { ContentSelectionFooterTextButton } from "@taketurns-components/collaboration/CollaborationView/CollaborationContent/ContentBrowser/ContentFooterMenu/shared";
import { useSharedCollaborationTranslation } from "@taketurns-i18n/collaboration/shared/useSharedCollaborationTranslation";
import { useGetCurrentFolderSelectionStateRule } from "@taketurns-rules/collaboration/queries/collaborationView/contentSelection/useGetCurrentFolderSelectionStateRule";

export const SelectAllButton = () => {
  const { t } = useSharedCollaborationTranslation();
  const { folder, errorOnFetchingFolder, isAllContentFolderSelected, toggleSelection } =
    useGetCurrentFolderSelectionStateRule();

  if (errorOnFetchingFolder) {
    console.log(errorOnFetchingFolder);
    return null;
  }

  const isFolderEmpty = !folder || checkIfFolderIsEmpty(folder);

  return (
    <ContentSelectionFooterTextButton
      onClick={toggleSelection}
      disabled={isFolderEmpty}
      data-cy={`ContentSelection.${isAllContentFolderSelected ? "UnselectAllButton" : "SelectAllButton"}`}
    >
      {t(isAllContentFolderSelected ? "SelectContent.unselectAll" : "SelectContent.selectAll")}
    </ContentSelectionFooterTextButton>
  );

  function checkIfFolderIsEmpty(folder: Folder) {
    if (folder.attachments.length > 0) return false;
    for (const subFolder of folder.folders) {
      if (subFolder.attachmentCount > 0) return false;
    }
    return true;
  }
};
