/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useGetRootFolder } from "@taketurns-repositories/collaboration/state/read/collaborationCreation/useGetRootFolder";
import { useSetCollaborationFormErrorsRule } from "./useSetCollaborationFormErrorsRule";
import { useValidateCollaborationFormAndGetIsValidFormRule } from "./useValidateCollaborationFormAndGetIsValidFormRule";

export const useValidateSimpleFileRequestFormRule = () => {
  const validateAndSetCollaborationFormError = useValidateCollaborationFormAndGetIsValidFormRule();
  const rootFolder = useGetRootFolder();
  const setCollaborationFormError = useSetCollaborationFormErrorsRule();

  return () => {
    const collaborationFormIsValid = validateAndSetCollaborationFormError().isValid;
    const hasFileRequest = rootFolder.requests.length !== 0;
    if (!hasFileRequest) {
      setCollaborationFormError((currentCollaborationFormError) => ({
        ...currentCollaborationFormError,
        requests: true,
      }));
    }
    return { isValid: collaborationFormIsValid && hasFileRequest };
  };
};
