/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { gql, useLazyQuery } from "@apollo/client";
import { Contact } from "@taketurns/taketurns-graphql-repository";

export const useSearchUserContacts = () => {
  const SEARCH_USER_CONTACT_QUERY = gql`
    query searchUserContacts($query: String!) {
      searchUserContacts(query: $query) {
        contacts {
          email
          user {
            id
            firstName
            lastName
            email
            avatar
            jobTitle
          }
        }
      }
    }
  `;
  const [searchUserContacts, { data, error: errorOnSearchingUserContacts, loading: loadingUserContacts }] =
    useLazyQuery<{
      searchUserContacts: { contacts: Contact[] };
    }>(SEARCH_USER_CONTACT_QUERY);

  const searchUserContactsQuery = (query: string) => {
    return searchUserContacts({ variables: { query } }).then((result) => result.data.searchUserContacts.contacts);
  };

  return {
    searchUserContactsQuery,
    contacts: data?.searchUserContacts?.contacts ?? [],
    errorOnSearchingUserContacts,
    loadingUserContacts,
  };
};
