/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { ApolloProvider } from "@apollo/client";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { Amplify } from "aws-amplify";
import { SnackbarProvider } from "notistack";
import { PropsWithChildren, useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import { RecoilRoot } from "recoil";
import { AttachmentManager } from "@taketurns-components/collaboration/AttachmentManager/AttachmentManager";
import { useApolloClient } from "@taketurns-repositories/webapp/aws/apolloClient";
import { awsConfiguration } from "@taketurns-repositories/webapp/aws/awsConfiguration";
import { TakeTurnsTheme } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { useSetMetaDescriptionRule } from "@taketurns-rules/webapp/commands/useSetMetaDescriptionRule";
import { router } from "./routing/router";
import "./styles/globals.css";
import { RecoilDebugger } from "./tracing/RecoilDebugger";

Amplify.configure(awsConfiguration);
export const App = () => {
  useSetVhCustomProperties();
  useSetMetaDescriptionRule();

  const apolloClient = useApolloClient();

  return (
    <AppTheme>
      <ApolloProvider client={apolloClient}>
        <RecoilRoot>
          <AppSnackbarProvider>
            <AttachmentManager>
              <RouterProvider router={router} />
            </AttachmentManager>
          </AppSnackbarProvider>
          <RecoilDebugger />
        </RecoilRoot>
      </ApolloProvider>
    </AppTheme>
  );
};

const useSetVhCustomProperties = () => {
  //Calculate windows size for correct height on all platform
  useEffect(() => {
    const appHeight = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    };
    window.addEventListener("resize", appHeight);
    appHeight();
    return () => {
      window.removeEventListener("resize", appHeight);
    };
  }, []);
};

const AppTheme = ({ children }: PropsWithChildren) => {
  return (
    <ThemeProvider theme={TakeTurnsTheme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

const AppSnackbarProvider = ({ children }: PropsWithChildren) => {
  return (
    <SnackbarProvider
      dense
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      {children}
    </SnackbarProvider>
  );
};
