/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useFetchHasCollaborationEnoughSpaceAvailable } from "@taketurns-repositories/collaboration/graphql/queries/features/useFetchHasCollaborationEnoughSpaceAvailable";
import { useGetDisplayedCollaborationIdRule } from "./useGetDisplayedCollaborationIdRule";

export const useFetchHasCollaborationEnoughSpaceAvailableRule = () => {
  const hasCollaborationEnoughSpaceAvailable = useFetchHasCollaborationEnoughSpaceAvailable();
  const collaborationId = useGetDisplayedCollaborationIdRule();
  return async (files: File[]): Promise<boolean> => {
    const neededSpaceInBytes = files.reduce((totalSpace, file) => totalSpace + file.size, 0);
    return await hasCollaborationEnoughSpaceAvailable(collaborationId, neededSpaceInBytes);
  };
};
