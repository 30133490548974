/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql } from "@apollo/client";
import { SubscriptionOnCancelPendingUpdatesArgs } from "@taketurns/taketurns-graphql-repository";
import { useRefreshableSubscription } from "@taketurns-components/webapp/QueriesAndSubscriptionsRefreshTrigger";
import { COLLABORATION_CONTENT_METADATA_QUERY } from "@taketurns-repositories/collaboration/graphql/queries/content/useFetchCollaborationContentMetadata";
import { GET_FOLDER } from "@taketurns-repositories/collaboration/graphql/queries/folder/useFetchFolder";
import { wait } from "@taketurns-rules/commons/util/wait";

const SUBSCRIBE_TO_CANCEL_PENDING_UPDATES = gql`
  subscription onCancelPendingUpdates($collaborationId: ID!, $partyId: ID!) {
    onCancelPendingUpdates(collaborationId: $collaborationId, partyId: $partyId) {
      collaborationId
      partyId
      contentMetadata {
        id
        todoCount
        requestToFulfillCount
        attachmentToReviewCount
        hasPendingUpdates
      }
    }
  }
`;

export function useSubscribeToCancelPendingUpdates(
  args: SubscriptionOnCancelPendingUpdatesArgs,
  onPendingUpdatesCancelled?: () => void,
) {
  return useRefreshableSubscription(SUBSCRIBE_TO_CANCEL_PENDING_UPDATES, {
    variables: { ...args },
    onData: ({ client }) => {
      wait(3000).then(() => {
        client.refetchQueries({ include: [GET_FOLDER, COLLABORATION_CONTENT_METADATA_QUERY] });
        if (typeof onPendingUpdatesCancelled === "function") {
          onPendingUpdatesCancelled();
        }
      });
    },
  });
}
