/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useGetConnectedUserPartyId } from "@taketurns-repositories/collaboration/context/useGetConnectedUserPartyId";
import { useFetchLastUpdatePartyIdRule } from "@taketurns-rules/collaboration/queries/content/useFetchLastUpdatePartyIdRule";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";

export function useFetchLastUpdateColor() {
  const { lastUpdatePartyId, loadingLastUpdatePartyId, errorOnFetchingLastUpdatePartyId } =
    useFetchLastUpdatePartyIdRule();
  const connectedUserPartyId = useGetConnectedUserPartyId();
  let color = TakeTurnsColors.mediumGray;
  if (lastUpdatePartyId) {
    if (lastUpdatePartyId === connectedUserPartyId) {
      color = TakeTurnsColors.connectedUser;
    } else {
      color = TakeTurnsColors.otherParty;
    }
  }
  return {
    color,
    loadingLastUpdatesColor: loadingLastUpdatePartyId,
    errorOnFetchingLastUpdatesColor: errorOnFetchingLastUpdatePartyId,
  };
}
