/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import * as z from "zod";

enum PasswordFields {
  password = "password",
  passwordConfirmation = "passwordConfirmation",
}

const buildPasswordFormSchema = () => {
  const message = { message: "password.passwordInstructions" };
  const passwordValidation = z
    .string()
    .min(10, message)
    .regex(/(?=.*[a-z])/, message)
    .regex(/(?=.*[A-Z])/, message)
    .regex(/(?=.*\d)/, message)
    .regex(/(?=.*\W)/, message);

  return z.object({
    [PasswordFields.password]: passwordValidation,
    [PasswordFields.passwordConfirmation]: z.string().min(1, { message: "password.noPasswordConfirmation" }),
  });
};
type PasswordFormSchema = ReturnType<typeof buildPasswordFormSchema>;

export const passwordFormSchema = refinePassword(buildPasswordFormSchema()) as unknown as PasswordFormSchema;

export type ExtendedPasswordFormSchema<T extends z.AnyZodObject> = z.ZodObject<
  z.objectUtil.extendShape<T["shape"], PasswordFormSchema["shape"]>
>;

export const getExtendedSchemaWithPassword = <T extends z.AnyZodObject>(schemaToExtends: T) =>
  // forced casting is required until https://github.com/colinhacks/zod/issues/2474 is fixed
  refinePassword(schemaToExtends.merge(buildPasswordFormSchema())) as unknown as ExtendedPasswordFormSchema<T>;

export const refinePasswordWithForbiddenFields = <T extends z.AnyZodObject>(
  passwordSchema: ExtendedPasswordFormSchema<T>,
  options: {
    forbiddenFields?: (keyof ExtendedPasswordFormSchema<T>["shape"])[];
    forbiddenValues?: string[];
  },
) =>
  passwordSchema.refine(
    (values) => {
      const lowercasedPassword = values.password.toLowerCase();
      let forbiddenValues: string[] = [];
      if (options.forbiddenValues) {
        forbiddenValues = options.forbiddenValues
          .filter((value) => value !== undefined)
          .map((value) => value.toLowerCase());
      }
      if (options.forbiddenFields) {
        forbiddenValues = forbiddenValues.concat(
          options.forbiddenFields.map((field) => (values[field] as string).toLowerCase()),
        );
      }
      return !forbiddenValues.some(
        (forbiddenValue) =>
          lowercasedPassword.includes(forbiddenValue) || lowercasedPassword.includes(reverseString(forbiddenValue)),
      );
    },
    {
      path: [PasswordFields.password],
      message: "password.cannotContainOtherFields",
    },
  );

function refinePassword(passwordSchemaObject: z.AnyZodObject) {
  return passwordSchemaObject.refine(
    ({ password, passwordConfirmation }) => !password || passwordConfirmation === password,
    {
      path: [PasswordFields.passwordConfirmation],
      message: "password.passwordConfirmationNotMatchingPassword",
    },
  );
}

const reverseString = (str: string) => [...str].reverse().join("");
