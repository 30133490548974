/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useSetRecoilState } from "recoil";
import { contentSelectionState } from "@taketurns-repositories/collaboration/state/recoil/contentSelectionState";

export const useToggleAttachmentSelectionState = (attachmentId: string) => {
  const setState = useSetRecoilState(contentSelectionState);
  return () => {
    setState((prevState) => {
      const attachmentIsSelected = prevState.attachmentIds.includes(attachmentId);

      if (attachmentIsSelected) {
        return {
          ...prevState,
          attachmentIds: prevState.attachmentIds.filter((id) => id !== attachmentId),
        };
      } else {
        return {
          ...prevState,
          attachmentIds: [...prevState.attachmentIds, attachmentId],
        };
      }
    });
  };
};
