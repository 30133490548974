/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql } from "@apollo/client";
import { MoveOutput, SubscriptionOnMoveFolderIntoDestinationFolderArgs } from "@taketurns/taketurns-graphql-repository";
import { useRefreshableSubscription } from "@taketurns-components/webapp/QueriesAndSubscriptionsRefreshTrigger";
import { moveFolderMutationFragment } from "@taketurns-repositories/collaboration/graphql/mutations/content/folders/useMoveFolderIntoFolderMutation";

const SUBSCRIBE_TO_MOVE_FOLDER_INTO_DESTINATION_FOLDER = gql`
  subscription onMoveFolderIntoDestinationFolder($collaborationId: ID!, $partyId: ID!, $destinationFolderId: ID!) {
    onMoveFolderIntoDestinationFolder(
      collaborationId: $collaborationId
      partyId: $partyId
      destinationFolderId: $destinationFolderId
    ) {
      ...MoveFolderMutationFragment
    }
  }
  ${moveFolderMutationFragment}
`;

export const useSubscribeToMoveFolderIntoDestinationFolder = (
  args: SubscriptionOnMoveFolderIntoDestinationFolderArgs,
) => {
  return useRefreshableSubscription<
    { onMoveFolderIntoDestinationFolder: MoveOutput },
    SubscriptionOnMoveFolderIntoDestinationFolderArgs
  >(SUBSCRIBE_TO_MOVE_FOLDER_INTO_DESTINATION_FOLDER, { variables: args });
};
