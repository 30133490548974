/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useSnackbar } from "notistack";
import { useOrganizationTranslation } from "@taketurns-i18n/user/Organization/useOrganizationTranslation";

export const useDisplayAndLogNotificationErrorRule = () => {
  const { t } = useOrganizationTranslation();
  const { enqueueSnackbar } = useSnackbar();

  return (error: unknown) => {
    console.error(error);
    enqueueSnackbar(t("error"), { variant: "error" });
  };
};
