/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { selectorFamily, useRecoilValue } from "recoil";
import { selectUploadsByIdsState } from "./selectUploadsByIdsState";

const selectHasAnyUploadFailedState = selectorFamily({
  key: "hasAnyUploadFailed",
  get:
    (uploadIds: number[]) =>
    ({ get }) => {
      const uploads = get(selectUploadsByIdsState(uploadIds));
      return uploads.some((upload) => upload!.hasFailed());
    },
});
export const useHasAnyUploadFailed = (uploadIds: number[]) => {
  return useRecoilValue<boolean>(selectHasAnyUploadFailedState(uploadIds));
};
