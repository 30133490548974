/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql } from "@apollo/client";
import {
  RemoveAttachmentRevisionOutput,
  SubscriptionOnRemoveAttachmentRevisionArgs,
} from "@taketurns/taketurns-graphql-repository";
import { useRefreshableSubscription } from "@taketurns-components/webapp/QueriesAndSubscriptionsRefreshTrigger";
import { removeAttachmentRevisionMutationFragment } from "@taketurns-repositories/collaboration/graphql/mutations/content/attachments/useRemoveAttachmentRevision";
import { GET_ATTACHMENT_REVISION_STATUS } from "@taketurns-repositories/collaboration/graphql/queries/attachment/useFetchAttachmentRevisionStatus";
import { COLLABORATION_CONTENT_METADATA_QUERY } from "@taketurns-repositories/collaboration/graphql/queries/content/useFetchCollaborationContentMetadata";
import { wait } from "@taketurns-rules/commons/util/wait";

const SUBSCRIBE_ON_REMOVE_ATTACHMENT_REVISION = gql`
  subscription onRemoveAttachmentRevision($collaborationId: ID!, $partyId: ID!, $attachmentId: ID!) {
    onRemoveAttachmentRevision(collaborationId: $collaborationId, partyId: $partyId, attachmentId: $attachmentId) {
      ...RemoveAttachmentRevisionMutationFields
    }
  }
  ${removeAttachmentRevisionMutationFragment}
`;

export const useSubscribeToRemoveAttachmentRevision = (args: SubscriptionOnRemoveAttachmentRevisionArgs) => {
  return useRefreshableSubscription<
    { onRemoveAttachmentRevision: RemoveAttachmentRevisionOutput },
    SubscriptionOnRemoveAttachmentRevisionArgs
  >(SUBSCRIBE_ON_REMOVE_ATTACHMENT_REVISION, {
    variables: args,
    onData: async ({ client }) => {
      await wait(3000);
      client.query({
        query: COLLABORATION_CONTENT_METADATA_QUERY,
        fetchPolicy: "network-only",
        variables: { collaborationId: args.collaborationId },
      });
      client.query({
        query: GET_ATTACHMENT_REVISION_STATUS,
        variables: {
          collaborationId: args.collaborationId,
          attachmentId: args.attachmentId,
        },
      });
    },
    onError: (error) => {
      console.error(error);
    },
  });
};
