/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { PendingAttachmentRevisionStatus } from "./pendingAttachmentRevisionStatus";

// TODO FJS : Is there any inheritance between PendingAttachment, PendingAttachmentRevision and PendingCreationAttachment ?
export class PendingAttachmentRevision {
  status: PendingAttachmentRevisionStatus = PendingAttachmentRevisionStatus.initiated;

  constructor(
    public attachmentId: string,
    public collaborationId: string,
    public documentName: string,
    public documentExtension: string,
    public documentSize: number,
    public uploadId: number,
  ) {}

  copy(): PendingAttachmentRevision {
    const copy: PendingAttachmentRevision = new PendingAttachmentRevision(
      this.attachmentId,
      this.collaborationId,
      this.documentName,
      this.documentExtension,
      this.documentSize,
      this.uploadId,
    );
    copy.status = this.status;
    return copy;
  }

  isUploading(): boolean {
    return this.status === PendingAttachmentRevisionStatus.uploading;
  }

  isAdding(): boolean {
    return this.status === PendingAttachmentRevisionStatus.adding;
  }

  isAdded(): boolean {
    return this.status === PendingAttachmentRevisionStatus.added;
  }

  // TODO FJS : We need to handle PendingAttachmentRevision cancellation
  isCancelled(): boolean {
    return this.status === PendingAttachmentRevisionStatus.cancelled;
  }
}
