/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Chip, styled, Typography } from "@mui/material";
import { useState } from "react";
import { useUserNotificationCenterTranslation } from "@taketurns-i18n/user/UserNotificationCenter/useUserNotificationCenterTranslation";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { useNavigateToUserProfilePageRule } from "@taketurns-rules/user/commands/useNavigateToUserProfilePageRule";
import { useFetchUserProfileIsIncompleteRule } from "@taketurns-rules/user/queries/useFetchUserProfileIsIncompleteRule";
import { IconUserCheck, IconXCircle } from "../../../commons/icons";

export const IncompleteUserProfileNotification = () => {
  const { userProfileIsIncomplete, errorOnUserHasIncompleteProfile, loadingUserHasIncompleteProfile } =
    useFetchUserProfileIsIncompleteRule();
  const [notificationIsDisplayed, setNotificationIsDisplayed] = useState(true);
  const navigateToUserProfilePage = useNavigateToUserProfilePageRule();

  const { t } = useUserNotificationCenterTranslation();

  if (errorOnUserHasIncompleteProfile) {
    console.error(errorOnUserHasIncompleteProfile);
  }

  if (loadingUserHasIncompleteProfile) {
    return;
  }

  if (userProfileIsIncomplete && notificationIsDisplayed) {
    return (
      <Chip
        variant={"outlined"}
        icon={<IconUserCheck size={18} color={TakeTurnsColors.darkGray} />}
        onClick={navigateToUserProfilePage}
        sx={{ padding: "0 4px" }}
        label={
          <Label__Container>
            {t("UserProfileIncompleteNotification.description")}
            <LineDivider__Container>&#8226;</LineDivider__Container>
            <BoldTextWithLightBlueColor>
              {t("UserProfileIncompleteNotification.completeProfile")}
            </BoldTextWithLightBlueColor>
          </Label__Container>
        }
        deleteIcon={<IconXCircle size={18} />}
        onDelete={() => setNotificationIsDisplayed(false)}
      />
    );
  }
};

const Label__Container = styled(Typography)({
  fontSize: "0.75rem",
  lineHeight: "0.75rem",
});

const BoldTextWithLightBlueColor = styled("b")({
  color: TakeTurnsColors.lightBlue,
});

const LineDivider__Container = styled("span")({
  padding: "0 4px",
});
