/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Box, Stack, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTime } from "luxon";
import { useCollaborationCreationTranslation } from "@taketurns-i18n/collaboration/CollaborationCreation/useCollaborationCreationTranslation";
import { useGetCollaborationMainFormErrors } from "@taketurns-repositories/collaboration/state/read/collaborationCreation/form/error/useGetCollaborationMainFormErrors";
import { useSetCollaborationFormDeadlineRule } from "@taketurns-rules/collaboration/commands/collaborationCreation/form/useSetCollaborationFormDeadlineRule";
import { useSetCollaborationFormNameRule } from "@taketurns-rules/collaboration/commands/collaborationCreation/form/useSetCollaborationFormNameRule";
import { useGetCollaborationFormDeadlineRule } from "@taketurns-rules/collaboration/queries/collaborationCreation/form/useGetCollaborationFormDeadlineRule";
import { useGetCollaborationFormNameRule } from "@taketurns-rules/collaboration/queries/collaborationCreation/form/useGetCollaborationFormNameRule";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { withMaxLengthControl } from "@taketurns-rules/commons/util/form/formUtils";
import { IconCalendar } from "../../commons/icons";
import { FilledLocalizationProvider } from "../Shared/FilledLocalizationProvider";

export const CollaborationCreationMainForm = () => {
  const collaborationName = useGetCollaborationFormNameRule();
  const collaborationDeadline = useGetCollaborationFormDeadlineRule();
  const setCollaborationName = useSetCollaborationFormNameRule();
  const setCollaborationDeadline = useSetCollaborationFormDeadlineRule();
  const collaborationMainFormErrors = useGetCollaborationMainFormErrors();

  const { t } = useCollaborationCreationTranslation();

  const deadline = collaborationDeadline ? DateTime.fromISO(collaborationDeadline) : null;

  return (
    <Stack className="CollaborationCreationMainForm" spacing={{ xs: 2, md: 4 }} direction={"column"}>
      <TextField
        tabIndex={1}
        error={collaborationMainFormErrors.name}
        placeholder={t("namePlaceholder")}
        sx={{
          "& .MuiOutlinedInput-input::placeholder": {
            color: collaborationMainFormErrors?.name ? TakeTurnsColors.error : TakeTurnsColors.placeholder,
            opacity: 1,
          },
        }}
        type="text"
        value={collaborationName}
        onChange={(event) => withMaxLengthControl(event, (value) => setCollaborationName(value))}
      />
      <Box>
        <FilledLocalizationProvider>
          <DatePicker
            disablePast
            closeOnSelect
            slots={{
              openPickerIcon: () => <IconCalendar size={20} color={TakeTurnsColors.grayButton} />,
            }}
            slotProps={{
              textField: {
                tabIndex: 4,
                sx: {
                  width: "50%",
                  minWidth: "230px",
                  "& .MuiOutlinedInput-input::placeholder": {
                    fontSize: "12px",
                  },
                },
                inputProps: {
                  placeholder: t("deadlinePlaceholder"),
                  sx: { height: "18.25px", py: "8px" },
                },
              },
              actionBar: { actions: ["clear"] },
            }}
            value={deadline}
            onChange={(date: DateTime) => setCollaborationDeadline(date)}
          />
        </FilledLocalizationProvider>
      </Box>
    </Stack>
  );
};
