/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { IconButton, Menu, MenuItem, Stack, Typography } from "@mui/material";
import { Fragment, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import { SupervisorFilter } from "@taketurns-components/collaboration/CollaborationList/SupervisorFilter/SupervisorFilter";
import { IconChevronDown, IconChevronUp } from "@taketurns-components/commons/icons";
import { useCollaborationListTranslation } from "@taketurns-i18n/collaboration/CollaborationList/useCollaborationListTranslation";
import { collaborationListAppliedFilterState } from "@taketurns-repositories/collaboration/state/recoil/collaborationListAppliedFilterState";
import { useShouldDisplaySupervisorFilterRule } from "@taketurns-rules/collaboration/queries/useShouldDisplaySupervisorFilterRule";
import { useIsOnMobileRule } from "@taketurns-rules/commons/theme/useIsOnMobileRule";
import { CollaborationFilterType } from "../collaborationFilterType";

export const CollaborationListHeader = () => {
  const shouldDisplaySupervisorFilter = useShouldDisplaySupervisorFilterRule();
  const isOnMobile = useIsOnMobileRule();

  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <StatusFilter />
      {shouldDisplaySupervisorFilter && !isOnMobile && <SupervisorFilter />}
    </Stack>
  );
};

const StatusFilter = () => {
  const [collaborationListAppliedFilter, setCollaborationListAppliedFilter] = useRecoilState(
    collaborationListAppliedFilterState,
  );
  const menuAnchorRef = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const openFilterMenu = () => {
    setIsMenuOpen(true);
  };
  const closeFilterMenu = () => {
    setIsMenuOpen(false);
  };

  const selectFilterAndCloseMenu = (filter: CollaborationFilterType) => {
    setCollaborationListAppliedFilter(filter);
    closeFilterMenu();
  };

  const { t } = useCollaborationListTranslation();
  return (
    <Fragment>
      <Stack direction={"row"} alignItems={"center"} data-cy={"CollaborationFilter"}>
        <Typography
          data-cy={`AppliedFilter-${collaborationListAppliedFilter}`}
          sx={{ cursor: "pointer" }}
          onClick={openFilterMenu}
        >
          {t(`filters.${collaborationListAppliedFilter}`)}
        </Typography>
        <IconButton ref={menuAnchorRef} size="small" onClick={openFilterMenu}>
          {isMenuOpen ? <IconChevronUp size={18} /> : <IconChevronDown size={18} />}
        </IconButton>
      </Stack>
      <Menu
        anchorEl={menuAnchorRef.current}
        open={isMenuOpen}
        onClose={closeFilterMenu}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {Object.keys(CollaborationFilterType).map((query: string) => (
          <MenuItem
            disabled={collaborationListAppliedFilter === query}
            key={query}
            onClick={() => selectFilterAndCloseMenu(query as CollaborationFilterType)}
            data-cy={`CollaborationFilter.${query}`}
          >
            {t(`filters.${query}`)}
          </MenuItem>
        ))}
      </Menu>
    </Fragment>
  );
};
