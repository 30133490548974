/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { CSSProperties, FC } from "react";
import { TipKey } from "@taketurns-i18n/commons/en/tips";
import { useHideTipRule } from "@taketurns-rules/user/commands/useHideTipRule";
import { useFetchIsTipHiddenRule } from "@taketurns-rules/user/queries/useFetchIsTipDisplayedRule";
import { useFetchIsTipsHiddenRule } from "@taketurns-rules/user/queries/useFetchIsTipsHiddenRule";
import { Tip, TipVariant } from "../../commons/message/Tip";

interface UserTipProps {
  message: string | JSX.Element;
  tipKey: TipKey;
  style?: CSSProperties;
  variant?: TipVariant;
}

export const UserTip: FC<UserTipProps> = ({ style, message, tipKey, variant = TipVariant.info }: UserTipProps) => {
  const { isTipsHidden, tipsHiddenLoading, tipsHiddenError } = useFetchIsTipsHiddenRule();
  const { isTipHidden, tipHiddenLoading, tipHiddenError } = useFetchIsTipHiddenRule(tipKey);
  const { hideTipCommand } = useHideTipRule();

  if (tipsHiddenError || tipHiddenError) {
    return <Tip style={style} message={message} canDisableTip={false} />;
  }

  if (tipsHiddenLoading || tipHiddenLoading) {
    return null;
  }
  if (isTipsHidden || isTipHidden) {
    return null;
  }
  return (
    <Tip
      variant={variant}
      style={style}
      message={message}
      canDisableTip={true}
      onClose={() => hideTipCommand(tipKey)}
    />
  );
};
