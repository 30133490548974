/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { AppBar, Box, styled, Toolbar, Typography } from "@mui/material";
import { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { IconGlobe } from "@taketurns-components/commons/icons";
import { useWebAppTranslations } from "@taketurns-i18n/webapp/useWebAppTranslations";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { useIsOnMobileRule } from "@taketurns-rules/commons/theme/useIsOnMobileRule";
import { getTakeTurnsHomepageLink } from "@taketurns-rules/webapp/queries/getTakeTurnsHomepageLink";
import { PUBLIC_ROUTES } from "../../routing/routes/publicRoutes.constants";
import { LanguageSelector } from "./LanguageSelector";

export const PublicAppBar: FunctionComponent = () => {
  const isOnMobile = useIsOnMobileRule();
  const { t, i18n } = useWebAppTranslations("public-appbar");
  const takeTurnsHomepageLink = getTakeTurnsHomepageLink(i18n.language);
  return (
    <AppBar__Container>
      <Toolbar sx={{ flexWrap: "wrap" }}>
        <ToolBar__LeftContainer>
          <Link to={takeTurnsHomepageLink} target={"_blank"}>
            {isOnMobile ? (
              <IconGlobe color={TakeTurnsColors.lightBlue} />
            ) : (
              <Typography color={"secondary"}>
                {t("visit")} <span style={{ textDecoration: "underline" }}>{t("taketurns")}</span>
              </Typography>
            )}
          </Link>
        </ToolBar__LeftContainer>
        <AppBarLogo__Container>
          <Link to={PUBLIC_ROUTES.SIGN_IN}>
            <img width={180} height={70} src={"/images/logos/LogoImageWithText.svg"} alt="TakeTurns logo" />
          </Link>
        </AppBarLogo__Container>
        <ToolBar__RightContainer>
          <LanguageSelector shortLabels={isOnMobile} />
        </ToolBar__RightContainer>
      </Toolbar>
    </AppBar__Container>
  );
};

const AppBar__Container = styled(AppBar)({
  flex: "0 0 70px",
  display: "flex",
  position: "relative",
});

const AppBarLogo__Container = styled(Box)({
  flex: "1 1 auto",
  display: "flex",
  justifyContent: "center",
});

const ToolBar__LeftContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up("md")]: { flex: "0 0 200px" },
  [theme.breakpoints.down("md")]: { flex: "0 0 50px", display: "flex", justifyContent: "center" },
}));

const ToolBar__RightContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up("md")]: { flex: "0 0 200px" },
  [theme.breakpoints.down("md")]: { flex: "0 0 50px" },
  display: "flex",
  justifyContent: "flex-end",
}));
