/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
const TAKETURNS_IS_COMING_FROM_INVITATION_TO_COLLABORATION_LINK =
  "taketurns_is_coming_from_invitation_to_collaboration_link";

export const setIsComingFromInvitationToCollaborationLink = () => {
  sessionStorage.setItem(TAKETURNS_IS_COMING_FROM_INVITATION_TO_COLLABORATION_LINK, "true");
};

export const clearIsComingFromInvitationToCollaborationLink = () => {
  sessionStorage.removeItem(TAKETURNS_IS_COMING_FROM_INVITATION_TO_COLLABORATION_LINK);
};

export const isComingFromInvitationToCollaborationLink = () => {
  const isComingFromInvitationToCollaborationLink = sessionStorage.getItem(
    TAKETURNS_IS_COMING_FROM_INVITATION_TO_COLLABORATION_LINK,
  );
  if (isComingFromInvitationToCollaborationLink === "true") {
    return true;
  }
  if (isComingFromInvitationToCollaborationLink === "false") {
    return false;
  }
};
