/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useQuery } from "@apollo/client";
import { Collaboration } from "@taketurns/taketurns-graphql-repository";

const COLLABORATION_FEATURES = gql`
  query collaborationFeatures($collaborationId: ID!) {
    getCollaboration(collaborationId: $collaborationId) {
      id
      availableFeatures {
        totalSpaceInMB
        maxFileSizeInMB
        maxGracePeriodInDays
        defaultGracePeriodInDays
      }
    }
  }
`;

export const useFetchCollaborationFeatures = (collaborationId: string) => {
  const {
    data,
    error: errorOnFetchingCollaborationFileUploadFeatures,
    loading: loadingCollaborationFileUploadFeatures,
  } = useQuery<{ getCollaboration: Collaboration }>(COLLABORATION_FEATURES, {
    variables: { collaborationId },
  });

  return {
    collaborationFeatures: data?.getCollaboration?.availableFeatures,
    errorOnFetchingCollaborationFileUploadFeatures,
    loadingCollaborationFileUploadFeatures,
  };
};
