/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Box, styled } from "@mui/material";
import { useCallback, useRef } from "react";
import {
  Attachment,
  MessageOptions as DefaultMessageOptions,
  EditMessageForm,
  Message,
  MessageDeleted,
  messageHasReactions,
  MessageInput,
  Modal,
  ReactionSelector,
  SimpleReactionsList,
  StreamMessage,
  useActionHandler,
  useComponentContext,
  useMessageContext,
} from "stream-chat-react";
import { Role } from "@taketurns/taketurns-graphql-repository";
import { useSharedCollaborationTranslation } from "@taketurns-i18n/collaboration/shared/useSharedCollaborationTranslation";
import { ChatAnnouncerUserId } from "@taketurns-repositories/chat/model/chatAnnouncerUserId";
import { useFetchConnectedUserRoleRule } from "@taketurns-rules/collaboration/queries/context/useFetchConnectedUserRoleRule";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { ChatAnnouncementMessage } from "./ChatAnnouncementMessage";
import { ChatUserMessage } from "./ChatUserMessage";
import { ColoredUserFullNameWithAvatar } from "./ColoredUserFullName";

export const ChatMessage = () => {
  const { t } = useSharedCollaborationTranslation();

  const { isReactionEnabled, message, reactionSelectorRef, showDetailedReactions, editing, clearEditingState } =
    useMessageContext();

  const { MessageOptions = DefaultMessageOptions, EditMessageInput = EditMessageForm } =
    useComponentContext("MessageSimple");

  const { connectedUserRole } = useFetchConnectedUserRoleRule();

  const messageWrapperRef = useRef(null);
  const handleAction = useActionHandler(message);

  const hasReactions = messageHasReactions(message);

  const EditingMessage = useCallback(() => {
    if (editing) {
      return (
        <Modal onClose={clearEditingState} open={editing}>
          <MessageInput clearEditingState={clearEditingState} Input={EditMessageInput} message={message} noFiles />
        </Modal>
      );
    }
    return null;
  }, [editing, EditMessageInput, message, clearEditingState]);

  const MessageOptionsView = () => {
    if (!message.deleted_at && connectedUserRole !== Role.Ghost) {
      return <MessageOptions messageWrapperRef={messageWrapperRef} />;
    }
    return null;
  };

  const isAnnouncementMessage = Object.values(ChatAnnouncerUserId).includes(message.user!.id as ChatAnnouncerUserId);
  if (isAnnouncementMessage) {
    return <ChatAnnouncementMessage />;
  }

  return (
    <Message__Container>
      <Message__UserNameAndContent>
        <Message__Headline>
          <ColoredUserFullNameWithAvatar
            userId={message.user.id}
            userAvatar={message.user.image}
            userPresentation={message.user.name}
            suffix={isNote(message) ? t("EventNote.addedANote") : undefined}
            fontSize="0.75rem"
            fontWeight="600"
          />
        </Message__Headline>
        {!isNote(message) && <EditingMessage />}
        {message.quoted_message && (
          <Message__Quote>
            {message.quoted_message.deleted_at ? (
              <MessageDeleted message={message.quoted_message} />
            ) : (
              <Message Message={ChatMessage} message={message.quoted_message} />
            )}
          </Message__Quote>
        )}
        {message.deleted_at ? <MessageDeleted message={message} /> : !editing && <ChatUserMessage />}
        {showDetailedReactions && isReactionEnabled && <ReactionSelector ref={reactionSelectorRef} />}
        {!message.deleted_at && message.attachments && (
          <Attachment actionHandler={handleAction} attachments={message.attachments} />
        )}
        {hasReactions && isReactionEnabled && !message.deleted_at && <SimpleReactionsList />}
        {!isNote(message) && <MessageOptionsView />}
      </Message__UserNameAndContent>
    </Message__Container>
  );
};

export function isNote(
  message: StreamMessage | (StreamMessage & { "tt-note-id"?: string }),
): message is StreamMessage & { "tt-note-id": string } {
  return message["tt-note-id"] !== undefined;
}

const Message__Headline = styled(Box)({
  display: "flex",
  flexDirection: "row",
  fontWeight: 600,
});

const Message__Container = styled(Box)({
  display: "flex",
  flexDirection: "row",
  marginBottom: 10,
});

const Message__UserNameAndContent = styled(Box)({
  flex: 1,
  display: "flex",
  flexDirection: "column",
});

const Message__Quote = styled(Box)({
  borderRadius: "10px 10px 10px 0",
  backgroundColor: TakeTurnsColors.background,
  paddingTop: 10,
  paddingLeft: 10,
});
