/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Typography } from "@mui/material";
import { PropsWithChildren, ReactNode, useEffect } from "react";
import { Navigate, useLoaderData } from "react-router-dom";
import { CollaborationStatus } from "@taketurns/taketurns-graphql-repository";
import { InvitationToClosedCollaborationPage } from "@taketurns-app/pages/authenticated/CollaborationView/InvitationToClosedCollaborationPage";
import { useAcceptInvitationRule } from "@taketurns-rules/collaboration/commands/useAcceptInvitationRule";
import { useFetchConnectedUserStatusRule } from "@taketurns-rules/collaboration/queries/context/useFetchConnectedUserStatusRule";
import { AUTHENTICATED_ROUTES } from "../routes/authenticatedRoutes.constants";

export const AcceptInvitationToCollaborationGuard = ({ children }: { children: ReactNode }) => {
  const { collaborationId } = useLoaderData() as {
    collaborationId: string;
  };
  const {
    collaborationStatus,
    isInvitee,
    isParticipant,
    isSupervisor,
    loadingConnectedUserStatus,
    errorOnConnectedUserStatus,
  } = useFetchConnectedUserStatusRule(collaborationId);

  if (loadingConnectedUserStatus) {
    return null;
  }
  // TODO FJS TT-2680: Handle this part differently
  if (
    errorOnConnectedUserStatus &&
    errorOnConnectedUserStatus.message !== "User is not allowed to access collaboration"
  ) {
    return <Typography>Something went wrong. Try again later.</Typography>;
  }

  if (isInvitee && collaborationStatus !== CollaborationStatus.Open) {
    return <InvitationToClosedCollaborationPage />;
  }

  return (
    <AcceptInvitationAndAccessCollaboration
      isInvitee={isInvitee}
      isParticipant={isParticipant}
      isSupervisor={isSupervisor}
    >
      {children}
    </AcceptInvitationAndAccessCollaboration>
  );
};

const AcceptInvitationAndAccessCollaboration = ({
  isInvitee,
  isParticipant,
  isSupervisor,
  children,
}: PropsWithChildren<{
  isInvitee: boolean;
  isParticipant: boolean;
  isSupervisor: boolean;
}>) => {
  if (!isParticipant && !isInvitee && !isSupervisor) {
    return <Navigate to={AUTHENTICATED_ROUTES.COLLABORATION_LIST} />;
  }
  if (isInvitee) {
    return <CollaborationInvitationAccepter />;
  }
  return children;
};

const CollaborationInvitationAccepter = () => {
  const { collaborationId } = useLoaderData() as {
    collaborationId: string;
  };
  const { acceptInvitation } = useAcceptInvitationRule();

  useEffect(() => {
    // this action can be called twice due to strict mode causing an error log in development that can be ignored.
    acceptInvitation(collaborationId);
    // ignoring deps as we are sure they won't change
    // this will avoid accepting the invitation multiple times
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return null;
};
