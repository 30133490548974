/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { OrganizationInvitation, OrganizationUserRole } from "@taketurns/taketurns-graphql-repository";
import { useModifyOrganizationInvitationRole } from "@taketurns-repositories/user/graphql/mutations/useModifyOrganizationInvitationRole";
import { useDisplayAndLogNotificationErrorRule } from "./useDisplayAndLogNotificationErrorRule";

export const useModifyInvitationRoleRule = () => {
  const {
    modifyOrganizationInvitationRole: modifyOrganizationInvitationRoleCommand,
    loadingModifyInvitationRole,
    errorOnModifyInvitationRole,
  } = useModifyOrganizationInvitationRole();

  const displayNotificationAndLogError = useDisplayAndLogNotificationErrorRule();

  const modifyOrganizationInvitationRole = (invitation: OrganizationInvitation, newRole: OrganizationUserRole) => {
    const { email, userId } = invitation;
    modifyOrganizationInvitationRoleCommand({
      email,
      userId,
      role: newRole,
    }).catch((error) => displayNotificationAndLogError(error));
  };
  return { modifyOrganizationInvitationRole, loadingModifyInvitationRole, errorOnModifyInvitationRole };
};
