/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Button, Stack, styled, Typography } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { TakeTurnsDefaultButton, TakeTurnsTextButton } from "@taketurns-components/commons/button";
import { useCollaborationListTranslation } from "@taketurns-i18n/collaboration/CollaborationList/useCollaborationListTranslation";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { useIsOnMobileRule } from "@taketurns-rules/commons/theme/useIsOnMobileRule";

interface SupervisorFilterFooterProps {
  setCheckedFilterItemByUserId: Dispatch<SetStateAction<Map<string, boolean>>>;
  applyFilter: () => void;
  closeMenu: () => void;
}

export const SupervisorFilterFooter = ({ setCheckedFilterItemByUserId, applyFilter, closeMenu }: SupervisorFilterFooterProps) => {
  const { t } = useCollaborationListTranslation();
  const isOnMobile = useIsOnMobileRule();

  const selectAll = () => {
    setAllCheckedFilterItem(true);
  };

  const selectNone = () => {
    setAllCheckedFilterItem(false);
  };

  const setAllCheckedFilterItem = (newValue: boolean) => {
    setCheckedFilterItemByUserId((prevState) => {
      const updatedCheckedBySubordinateUserId = new Map(prevState);
      updatedCheckedBySubordinateUserId.forEach((_, subordinateUserId) => {
        updatedCheckedBySubordinateUserId.set(subordinateUserId, newValue);
      });
      return updatedCheckedBySubordinateUserId;
    });
  };

  return (
    <SupervisorFilterFooter__Container className="SupervisorFilter__Footer">
      <SupervisorFilterFooter__ActionsGroup>
        <Typography fontSize="0.75rem" color={TakeTurnsColors.darkGray}>
          Select
        </Typography>
        <SelectFilterButton label={t("SupervisorFilter.all")} onClick={() => selectAll()} />
        <SelectFilterButton label={t("SupervisorFilter.none")} onClick={() => selectNone()} />
      </SupervisorFilterFooter__ActionsGroup>
      <SupervisorFilterFooter__ActionsGroup>
        {isOnMobile && <CancelButton onClick={closeMenu} />}
        <TakeTurnsDefaultButton sx={{ paddingX: "10px" }} onClick={() => applyFilter()}>
          {t("SupervisorFilter.applyFilter")}
        </TakeTurnsDefaultButton>
      </SupervisorFilterFooter__ActionsGroup>
    </SupervisorFilterFooter__Container>
  );
};

const SupervisorFilterFooter__Container = styled(Stack)({
  alignItems: "center",
  justifyContent: "space-between",
  flexDirection: "row",
  backgroundColor: TakeTurnsColors.background,
  padding: "0 24px",
  minHeight: "48px",
});

const SupervisorFilterFooter__ActionsGroup = styled(Stack)({
  flexDirection: "row",
  alignItems: "center",
  gap: "8px",
});

const SelectFilterButton = ({ label, onClick }: { label: string; onClick: () => void }) => {
  return (
    <TakeTurnsTextButton disableFocusRipple disableTouchRipple disableRipple sx={{ minWidth: "auto", "&:hover": { background: "unset" } }} onClick={onClick}>
      {label}
    </TakeTurnsTextButton>
  );
};

const CancelButton = ({ onClick }: { onClick: () => void }) => {
  const { t } = useCollaborationListTranslation();
  return (
    <Button variant={"text"} onClick={onClick}>
      {t("SupervisorFilter.cancel")}
    </Button>
  );
};
