/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useMutation } from "@apollo/client";
import {
  MarkAttachmentForReviewInput,
  MarkUnmarkForReviewAttachmentOutput,
  MutationMarkAttachmentForReviewArgs,
} from "@taketurns/taketurns-graphql-repository";
import { useGetConnectedUserPartyId } from "@taketurns-repositories/collaboration/context/useGetConnectedUserPartyId";
import { useGetContentMetadataInCache } from "@taketurns-repositories/collaboration/graphql/cache/useGetContentMetadataInCache";
import { useGetAttachmentRule } from "@taketurns-rules/collaboration/queries/collaborationView/useGetAttachmentRule";

export const markUnmarkAttachmentForReviewFragment = gql`
  fragment MarkUnmarkAttachmentForReviewFragment on MarkUnmarkForReviewAttachmentOutput {
    collaborationId
    partyId
    attachmentId
    attachment {
      id
      markedForReview
      status
    }
    contentMetadata {
      id
      hasPendingUpdates
    }
  }
`;

export const useMarkAttachmentForReview = (input: MarkAttachmentForReviewInput) => {
  const connectedUserPartyId = useGetConnectedUserPartyId();
  const attachment = useGetAttachmentRule(input.attachmentId);
  const getContentMetadataInCache = useGetContentMetadataInCache();

  const mutation = gql`
    mutation markAttachmentForReview($input: MarkAttachmentForReviewInput!) {
      markAttachmentForReview(input: $input) {
        ...MarkUnmarkAttachmentForReviewFragment
      }
    }
    ${markUnmarkAttachmentForReviewFragment}
  `;
  const [markAttachmentForReviewMutationFromGraphQL, { data, loading, error }] = useMutation<
    { markAttachmentForReview: MarkUnmarkForReviewAttachmentOutput },
    MutationMarkAttachmentForReviewArgs
  >(mutation);
  const markAttachmentForReviewMutation = () => {
    const contentMetadata = getContentMetadataInCache(input.collaborationId);
    return markAttachmentForReviewMutationFromGraphQL({
      variables: { input },
      optimisticResponse: {
        markAttachmentForReview: {
          collaborationId: input.collaborationId,
          partyId: connectedUserPartyId,
          attachmentId: input.attachmentId,
          attachment: {
            ...attachment,
            markedForReview: true,
          },
          contentMetadata,
        },
      },
    });
  };
  return {
    markAttachmentForReviewMutation,
    markAttachmentForReviewResponseData: data?.markAttachmentForReview,
    markAttachmentForReviewLoading: loading,
    errorOnMarkingAttachmentForReview: error,
  };
};
