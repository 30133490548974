/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useEffect } from "react";
import {
  useMarkPendingAttachmentAsAttached,
} from "@taketurns-repositories/collaboration/state/write/pendingAttachment/useMarkPendingAttachmentAsAttached";
import {
  useMarkPendingAttachmentAsAttaching,
} from "@taketurns-repositories/collaboration/state/write/pendingAttachment/useMarkPendingAttachmentAsAttaching";
import { useGetCompletedUploadIds } from "@taketurns-repositories/document/state/read/useGetCompletedUploadIds";
import {
  useGetAllPendingAttachmentsRule,
} from "@taketurns-rules/collaboration/queries/attachment/pending/useGetAllPendingAttachmentsRule";
import { useAddAttachmentRule } from "./useAddAttachmentRule";

export const useListenToCompletedUploadsToAddAttachmentRule = () => {
  const completedUploadIds = useGetCompletedUploadIds();
  const pendingAttachments = useGetAllPendingAttachmentsRule();
  const markPendingAttachmentAsAttaching = useMarkPendingAttachmentAsAttaching();
  const markPendingAttachmentAsAttached = useMarkPendingAttachmentAsAttached();
  const { addAttachmentRule } = useAddAttachmentRule();

  useEffect(() => {
    for (const completedUploadId of completedUploadIds) {
      const pendingAttachmentToUpdate = pendingAttachments.find((p) => p.uploadId === completedUploadId);
      if (
        pendingAttachmentToUpdate &&
        !(pendingAttachmentToUpdate.isAttaching() || pendingAttachmentToUpdate.isAttached())
      ) {
        markPendingAttachmentAsAttaching(pendingAttachmentToUpdate);
        addAttachmentRule(pendingAttachmentToUpdate).then(() => {
          markPendingAttachmentAsAttached(pendingAttachmentToUpdate);
        });
      }
    }
  }, [completedUploadIds]);
};
