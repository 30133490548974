/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useQuery } from "@apollo/client";
import { revisionFragment } from "@taketurns-repositories/document/graphql/fragments/revisionFragment";
import { Document } from "@taketurns/taketurns-graphql-repository";

export const DOCUMENT_REVISIONS = gql`
  query documentRevisions($collaborationId: ID!, $documentId: ID!) {
    document(collaborationId: $collaborationId, documentId: $documentId) {
      id
      revisions {
        ...RevisionFields
      }
    }
  }
  ${revisionFragment}
`;

export const useFetchDocumentRevisions = (collaborationId: string, documentId: string) => {
  const {
    data,
    error: errorOnFetchingDocumentRevisions,
    loading: loadingDocumentRevisions,
  } = useQuery<{ document: Document }>(DOCUMENT_REVISIONS, {
    variables: { collaborationId, documentId },
  });

  return { revisions: data?.document.revisions, errorOnFetchingDocumentRevisions, loadingDocumentRevisions };
};
