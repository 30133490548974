/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { CollaborationFilterType } from "@taketurns-components/collaboration/CollaborationList/collaborationFilterType";
import { useGetListAppliedFilter } from "@taketurns-repositories/collaboration/state/read/useGetListAppliedFilter";
import { useFetchConnectedUserRule } from "@taketurns-rules/user/queries/useFetchConnectedUserRule";

export const useShouldDisplaySupervisorFilterRule = () => {
  const listAppliedFilter = useGetListAppliedFilter();
  const shouldCollaborationStatusDisplaySupervisorFilter =
    listAppliedFilter === CollaborationFilterType.activeCollaborations ||
    listAppliedFilter === CollaborationFilterType.allCollaborations ||
    listAppliedFilter === CollaborationFilterType.starredCollaborations ||
    listAppliedFilter === CollaborationFilterType.closedCollaborations;
  const { connectedUser, loadingConnectedUser } = useFetchConnectedUserRule();

  if (loadingConnectedUser) {
    return false;
  }

  return shouldCollaborationStatusDisplaySupervisorFilter && connectedUser?.isSupervisor;
};
