/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { gql, useMutation } from "@apollo/client";
import {
  CancelPendingUpdatesInput,
  CollaborationContentMetadata,
  MutationCancelPendingUpdatesArgs,
} from "@taketurns/taketurns-graphql-repository";
import { GET_FOLDER } from "@taketurns-repositories/collaboration/graphql/queries/folder/useFetchFolder";
import { useGetCurrentFolderIdInCollaborationView } from "@taketurns-repositories/collaboration/state/read/collaborationView/useGetCurrentFolderIdInCollaborationView";
import { useGetDisplayedCollaborationIdRule } from "@taketurns-rules/collaboration/queries/collaboration/useGetDisplayedCollaborationIdRule";
import { wait } from "@taketurns-rules/commons/util/wait";

export function useCancelPendingUpdates() {
  const mutation = gql`
    mutation cancelPendingUpdates($input: CancelPendingUpdatesInput!) {
      cancelPendingUpdates(input: $input) {
        collaborationId
        partyId
        contentMetadata {
          id
          todoCount
          requestToFulfillCount
          attachmentToReviewCount
          hasPendingUpdates
          hasNoContent
        }
      }
    }
  `;
  const [cancelPendingUpdatesMutation, cancelPendingUpdatesResult] = useMutation<
    { cancelPendingUpdates: CollaborationContentMetadata },
    MutationCancelPendingUpdatesArgs
  >(mutation);

  const collaborationId = useGetDisplayedCollaborationIdRule();
  const folderId = useGetCurrentFolderIdInCollaborationView();

  const cancelPendingUpdates = async (input: CancelPendingUpdatesInput) => {
    return cancelPendingUpdatesMutation({
      variables: { input },
      refetchQueries: [{ query: GET_FOLDER, variables: { collaborationId, folderId } }],
      awaitRefetchQueries: true,
      onQueryUpdated: (observableQuery) => {
        observableQuery.refetch();
        wait(3000).then(() => observableQuery.refetch());
      },
    });
  };
  return { cancelPendingUpdates, cancelPendingUpdatesResult };
}
