/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Stack } from "@mui/material";
import { Fragment, useState } from "react";
import { FetchLoadingManager } from "@taketurns-app/utils/FetchLoadingManager";
import { DefaultActionWithNoteDialog } from "@taketurns-components/collaboration/Shared/dialog/DefaultActionWithNoteDialog";
import { useSharedCollaborationTranslation } from "@taketurns-i18n/collaboration/shared/useSharedCollaborationTranslation";
import { TIP_KEYS } from "@taketurns-i18n/commons/TIP_KEYS";
import { useSendUpdatesRule } from "@taketurns-rules/collaboration/commands/collaborationView/updates/useSendUpdatesRule";
import { useFetchOtherPartyNameRule } from "@taketurns-rules/collaboration/queries/context/useFetchOtherPartyNameRule";
import { HelpCenterSections } from "@taketurns-rules/commons/enum/helpCenterSectionsEnum";
import { useIsOnMobileRule } from "@taketurns-rules/commons/theme/useIsOnMobileRule";
import { TakeTurnsDefaultButton } from "../../../../../commons/button";
import { IconSend } from "../../../../../commons/icons";

export const SendUpdatesButton = () => {
  const { t } = useSharedCollaborationTranslation();
  const { otherPartyName, loadingOtherPartyName, errorOnFetchingOtherPartyName } = useFetchOtherPartyNameRule();

  const [openSendUpdatesDialog, setOpenSendUpdatesDialog] = useState(false);
  const { sendUpdates, sendUpdatesResult } = useSendUpdatesRule();
  const isOnMobile = useIsOnMobileRule();

  const fetchLoadingManager = new FetchLoadingManager([loadingOtherPartyName], [errorOnFetchingOtherPartyName]);

  if (fetchLoadingManager.hasFetchInError()) {
    fetchLoadingManager.logErrors();
    return null;
  }

  if (fetchLoadingManager.hasFetchLoading()) {
    return null;
  }

  return (
    <Fragment>
      <Stack direction="row" gap={2} alignItems="center" justifyContent={"space-between"}>
        <TakeTurnsDefaultButton
          startIcon={<IconSend size={16} />}
          variant={"contained"}
          onClick={() => setOpenSendUpdatesDialog(true)}
          data-cy="SendUpdatesButton"
        >
          {t(`SendUpdates.buttonLabel${isOnMobile ? "Short" : ""}`)}
        </TakeTurnsDefaultButton>
      </Stack>
      <DefaultActionWithNoteDialog
        title={t("SendUpdates.dialogTitle")}
        onClose={() => setOpenSendUpdatesDialog(false)}
        otherPartyName={otherPartyName}
        executeAction={(message: string) => sendUpdates(message)}
        openDialog={openSendUpdatesDialog}
        actionLoading={sendUpdatesResult.loading}
        placeholder={t("SendUpdates.notePlaceholder")}
        actionButtonLabel={t("SendUpdates.buttonLabelShort")}
        actionButtonIcon={<IconSend size={16} />}
        help={HelpCenterSections.SEND_UPDATES}
        tipKey={TIP_KEYS.SEND_UPDATE_DESCRIPTION}
      />
    </Fragment>
  );
};
