/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Typography } from "@mui/material";
import { useUserPlanTranslation } from "@taketurns-i18n/user/UserPlans/useUserPlanTranslation";

export const PublicSubscriptionPlansPageTitle = () => {
  const { t } = useUserPlanTranslation();
  return (
    <Typography variant="h6" align="center">
      {t("header.choosePlan")}
    </Typography>
  );
};
