/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { ForwardedRef, forwardRef } from "react";
import { PdfViewer, PdfViewerExposedFunction } from "@taketurns-components/document/FilePreview/PdfViewer/PdfViewer";
import { IMAGE_FORMAT, PDF_FORMAT } from "@taketurns-rules/document/queries/useIsExtensionSupportedForPreview";
import { ImagePreview } from "./ImagePreview";

interface FilePreviewProps {
  fileDownloadUrl: string;
  fileExtension: string;
  canEditRevision: boolean;
  jwtToken: string;
  onPdfUpdatedChange: (updated: boolean) => void;
}

export const FilePreview = forwardRef((props: FilePreviewProps, ref: ForwardedRef<PdfViewerExposedFunction>) => {
  if (IMAGE_FORMAT.includes(props.fileExtension?.toLowerCase()) && props.fileDownloadUrl) {
    return <ImagePreview imageUrl={props.fileDownloadUrl} />;
  }
  if (PDF_FORMAT.includes(props.fileExtension?.toLowerCase()) && props.fileDownloadUrl) {
    return (
      <PdfViewer
        ref={ref}
        documentRevisionUrl={props.fileDownloadUrl}
        jwtToken={props.jwtToken}
        onPdfUpdatedChange={props.onPdfUpdatedChange}
        enableSignatures={props.canEditRevision}
        enableAnnotations={props.canEditRevision}
      />
    );
  }
  return (
    <PdfViewer
      ref={ref}
      documentRevisionUrl={props.fileDownloadUrl}
      jwtToken={props.jwtToken}
      onPdfUpdatedChange={props.onPdfUpdatedChange}
      enableSignatures={props.canEditRevision}
      enableAnnotations={false}
    />
  );
});
