/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Box, Stack, SwipeableDrawer } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { UserDrawer } from "@taketurns-app/layouts/authenticated/AuthenticatedLayout/components/UserDrawer";
import { MobileProfileButton } from "@taketurns-app/layouts/body/components/MobileTopButtons/MobileProfileButton";
import { PlansLayout } from "@taketurns-app/layouts/PlansLayout";
import { SubscriptionPlans } from "@taketurns-components/user/UserPlans/SubscriptionPlans/SubscriptionPlans";
import { AuthenticatedSubscriptionPlansHeader } from "@taketurns-components/user/UserPlans/SubscriptionPlansHeader/AuthenticatedSubscriptionPlansHeader";
import { AuthenticatedSubscriptionPlansPageTitle } from "@taketurns-components/user/UserPlans/SubscriptionPlansHeader/AuthenticatedSubscriptionPlansPageTitle";
import { MobileBackButton } from "@taketurns-components/webapp/MobileBackButton";
import { useIsOnIOSRule } from "@taketurns-rules/commons/theme/useIsOnIOSRule";
import { useIsOnMobileRule } from "@taketurns-rules/commons/theme/useIsOnMobileRule";
import { useSetPlansHaveBeenSeenRule } from "@taketurns-rules/webapp/commands/useSetPlansHaveBeenSeenRule";

export const Plans = () => {
  const isOnMobile = useIsOnMobileRule();

  const setPlansHaveBeenSeen = useSetPlansHaveBeenSeenRule();
  useEffect(() => {
    setPlansHaveBeenSeen();
  }, [setPlansHaveBeenSeen]);

  const Header = isOnMobile ? MobileAuthenticatedPlansHeaderAndDrawer : AuthenticatedSubscriptionPlansHeader;

  return <PlansLayout header={<Header />} content={<SubscriptionPlans isUserAuthenticated />} />;
};

const MobileAuthenticatedPlansHeaderAndDrawer = () => {
  const [openRightDrawer, setOpenRightDrawer] = useState(false);
  const transitionDuration = 300;
  const isOnIOS = useIsOnIOSRule();

  const AVATAR_PADDING_RIGHT = 24;
  const AVATAR_WIDTH = 30;
  const AVATAR_PADDING_LEFT = 8;
  const AVATAR_BOX_WIDTH = AVATAR_PADDING_LEFT + AVATAR_WIDTH + AVATAR_PADDING_RIGHT;
  const BACK_BUTTON_WIDTH = AVATAR_BOX_WIDTH;
  const BACK_BUTTON_PADDING_LEFT = 8;

  return (
    <Fragment>
      <Stack spacing={2} alignItems="center">
        <Stack direction="row" alignItems="center" position="relative" width="100%" justifyContent="space-between">
          <Box flex={`0 1 ${BACK_BUTTON_WIDTH}px`} paddingLeft={`${BACK_BUTTON_PADDING_LEFT}px`}>
            <MobileBackButton />
          </Box>
          <Box flex={1}>
            <AuthenticatedSubscriptionPlansPageTitle />
          </Box>
          <Box flex={`0 0 ${AVATAR_BOX_WIDTH}px`} padding={`0 ${AVATAR_PADDING_RIGHT}px 0 ${AVATAR_PADDING_LEFT}px`}>
            <MobileProfileButton onClick={() => setOpenRightDrawer(true)} />
          </Box>
        </Stack>
        <AuthenticatedSubscriptionPlansHeader />
      </Stack>

      <SwipeableDrawer
        transitionDuration={transitionDuration}
        anchor="right"
        open={openRightDrawer}
        onOpen={() => setOpenRightDrawer(true)}
        onClose={() => setOpenRightDrawer(false)}
        disableBackdropTransition={!isOnIOS}
        disableDiscovery={isOnIOS}
      >
        <UserDrawer closeDrawer={() => setOpenRightDrawer(false)} />
      </SwipeableDrawer>
    </Fragment>
  );
};
