/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useEffect } from "react";
import { useGetCompletedUploadIds } from "@taketurns-repositories/document/state/read/useGetCompletedUploadIds";
import {
  useGetAttachmentBulkByUploadIdRule,
} from "@taketurns-rules/collaboration/queries/attachment/pending/useGetAttachmentBulkByUploadIdRule";
import { useCompleteUploadInAttachmentBulkRule } from "../useCompleteUploadInAttachmentBulkRule";
import { useNotifyAttachmentAddedRule } from "./useNotifyAttachmentAddedRule";

export const useListenAndNotifyToCompletedUploadsRule = () => {
  const completedUploadIds = useGetCompletedUploadIds();
  const getAttachmentBulkByUploadId = useGetAttachmentBulkByUploadIdRule();
  const completeUploadInAttachmentBulkRule = useCompleteUploadInAttachmentBulkRule();
  const notifyAttachmentAdded = useNotifyAttachmentAddedRule();

  useEffect(() => {
    for (const uploadId of completedUploadIds) {
      const attachmentBulk = getAttachmentBulkByUploadId(uploadId);
      completeUploadInAttachmentBulkRule(uploadId, () => notifyAttachmentAdded(attachmentBulk.numberOfAttachments));
    }
  }, [completedUploadIds]);
};
