/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

export const useEncodeParamsRule = () => {
  return (params: unknown) => {
    const paramsAsJsonString = JSON.stringify(params);
    return Buffer.from(paramsAsJsonString).toString("base64");
  };
};
