/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useFetchConnectedUserParty } from "@taketurns-repositories/collaboration/graphql/queries/context/useFetchConnectedUserParty";
import { useGetDisplayedCollaborationIdRule } from "@taketurns-rules/collaboration/queries/collaboration/useGetDisplayedCollaborationIdRule";

export const useFetchConnectedUserPartyRule = (collaborationId?: string) => {
  const displayedCollaborationId = useGetDisplayedCollaborationIdRule();
  return useFetchConnectedUserParty(collaborationId ?? displayedCollaborationId);
};
