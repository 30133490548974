/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Box, styled } from "@mui/material";
import { SupervisorFilter } from "@taketurns-components/collaboration/CollaborationList/SupervisorFilter/SupervisorFilter";
import { useShouldDisplaySupervisorFilterRule } from "@taketurns-rules/collaboration/queries/useShouldDisplaySupervisorFilterRule";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";

export const MobileSupervisorFooter = () => {
  const shouldDisplaySupervisorFilter = useShouldDisplaySupervisorFilterRule();

  if (!shouldDisplaySupervisorFilter) return null;

  return (
    <MobileSupervisorFooter__Container>
      <SupervisorFilter />
    </MobileSupervisorFooter__Container>
  );
};

const MobileSupervisorFooter__Container = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "end",
  marginBottom: "env(safe-area-inset-bottom)",
  backgroundColor: "white",
  borderTop: `1px solid ${TakeTurnsColors.lightGray}`,
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  width: "100%",
}));
