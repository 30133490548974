/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Box, IconButton, List, Stack, styled, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { CreateNewCollaborationButton } from "@taketurns-components/collaboration/CollaborationCreation/CreateCollaborationButton/CreateNewCollaborationButton";
import { CollaborationFilterType } from "@taketurns-components/collaboration/CollaborationList/collaborationFilterType";
import { useWebAppTranslations } from "@taketurns-i18n/webapp/useWebAppTranslations";
import { collaborationListAppliedFilterState } from "@taketurns-repositories/collaboration/state/recoil/collaborationListAppliedFilterState";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { useGetHelpCenterUrls } from "@taketurns-rules/webapp/queries/useGetHelpCenterUrls";
import {
  IconActivity,
  IconArchive,
  IconClipboard,
  IconHelpCircle,
  IconList,
  IconMail,
  IconShield,
  IconStar,
  IconX,
} from "../../../../../components/commons/icons";
import { AUTHENTICATED_ROUTES } from "../../../../routing/routes/authenticatedRoutes.constants";
import { DrawerItem } from "./DrawerItem";

interface ApplicationDrawerProps {
  onClose: () => void;
}

export const ApplicationDrawer = (props: ApplicationDrawerProps) => {
  return (
    <Box width={{ xs: 280, md: 380 }} maxWidth="100vw">
      <CloseButtonContainer>
        <IconButton size="small" onClick={props.onClose}>
          <IconX color="#999999" />
        </IconButton>
      </CloseButtonContainer>
      <Stack component="div" direction="column" spacing={{ xs: 1, md: 2 }} padding={"30px"}>
        <CollaborationSection {...props} />
        <HelpSection {...props} />
      </Stack>
    </Box>
  );
};

const CloseButtonContainer = styled("div")(({ theme }) => ({
  display: "flex",
  height: 70,
  alignItems: "center",
  justifyContent: "flex-end",
  paddingRight: 10,
  color: "#999999",
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

//TODO move it in collaboration webapp
const CollaborationSection = (props: ApplicationDrawerProps) => {
  const { t } = useWebAppTranslations("collaboration-list");
  const setCollaborationListAppliedFilter = useSetRecoilState<CollaborationFilterType>(
    collaborationListAppliedFilterState,
  );
  const navigate = useNavigate();

  const setCollaborationFilterAndNavigateToList = (filter: CollaborationFilterType) => {
    setCollaborationListAppliedFilter(filter);
    navigate(AUTHENTICATED_ROUTES.COLLABORATION_LIST);
    props.onClose();
  };

  const getCorrespondingIconForFilter = (filter: CollaborationFilterType) => {
    switch (filter) {
      case CollaborationFilterType.starredCollaborations:
        return <IconStar size={24} color={TakeTurnsColors.grayButton} />;
      case CollaborationFilterType.closedCollaborations:
        return <IconArchive size={24} color={TakeTurnsColors.grayButton} />;
      case CollaborationFilterType.pendingInvitations:
        return <IconMail size={24} color={TakeTurnsColors.grayButton} />;
      case CollaborationFilterType.activeCollaborations:
        return <IconActivity size={24} color={TakeTurnsColors.grayButton} />;
      default:
        return <IconList size={24} color={TakeTurnsColors.grayButton} />;
    }
  };

  return (
    <Box>
      <Stack component="div" direction="row" justifyContent={"space-between"}>
        <Typography color={TakeTurnsColors.darkGray}>{t("title")}</Typography>
        <Box display={{ xs: "none", md: "initial" }}>
          <CreateNewCollaborationButton beforeClickAction={props.onClose} />
        </Box>
      </Stack>
      <List>
        {Object.keys(CollaborationFilterType).map((filter: string) => (
          <DrawerItem
            key={filter}
            label={t(filter)}
            onClick={() => {
              setCollaborationFilterAndNavigateToList(filter as CollaborationFilterType);
            }}
            icon={getCorrespondingIconForFilter(filter as CollaborationFilterType)}
          />
        ))}
      </List>
    </Box>
  );
};

const HelpSection = (props: ApplicationDrawerProps) => {
  const { t } = useWebAppTranslations("helpSection");
  const { helpCenterUrl, privacyUrl, termsUrl } = useGetHelpCenterUrls();

  const openUrlInNewTab = (url: string) => {
    window.open(url);
    props.onClose();
  };

  return (
    <Box>
      <Typography color={TakeTurnsColors.darkGray}>{t("help")}</Typography>
      <List>
        <DrawerItem
          label={t("getHelp")}
          onClick={() => openUrlInNewTab(helpCenterUrl)}
          icon={<IconHelpCircle size={24} color={TakeTurnsColors.grayButton} />}
        />
        <DrawerItem
          label={t("terms")}
          onClick={() => openUrlInNewTab(termsUrl)}
          icon={<IconClipboard size={24} color={TakeTurnsColors.grayButton} />}
        />
        <DrawerItem
          label={t("privacy")}
          onClick={() => openUrlInNewTab(privacyUrl)}
          icon={<IconShield size={24} color={TakeTurnsColors.grayButton} />}
        />
      </List>
    </Box>
  );
};
