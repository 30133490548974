/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Divider, Stack, styled } from "@mui/material";
import {
  Column,
  ColumnPaper,
  HeaderChip,
} from "@taketurns-app/pages/authenticated/CollaborationCreation/CollaborationCreationPages/commons";
import { CollaborationCreationContent } from "@taketurns-components/collaboration/CollaborationCreation/CollaborationCreationContent/CollaborationCreationContent";
import { CollaborationCreationMainForm } from "@taketurns-components/collaboration/CollaborationCreation/CollaborationCreationMainForm";
import { useWebAppTranslations } from "@taketurns-i18n/webapp/useWebAppTranslations";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { useIsOnMobileRule } from "@taketurns-rules/commons/theme/useIsOnMobileRule";

export const CollaborationCreationMainFormAndContentColumn = () => {
  const { t } = useWebAppTranslations("collaboration-creation");
  const isOnMobile = useIsOnMobileRule();

  if (isOnMobile) {
    return (
      <Column>
        <CollaborationCreationMainFormAndContent__Container className="CollaborationCreation__MainFormAndContent">
          <Stack className="CollaborationCreation__MainForm" useFlexGap spacing={3} flex={1} minHeight={0}>
            <CollaborationCreationMainFormAndContent__MobilePaddingContainer>
              <CollaborationCreationMainForm />
            </CollaborationCreationMainFormAndContent__MobilePaddingContainer>
            <Divider />
            <CollaborationCreationMainFormAndContent__MobilePaddingContainer flex={1}>
              <CollaborationCreationContent showAddFile showAddRequest />
            </CollaborationCreationMainFormAndContent__MobilePaddingContainer>
          </Stack>
        </CollaborationCreationMainFormAndContent__Container>
      </Column>
    );
  }

  return (
    <Column>
      <HeaderChip backgroundColor={TakeTurnsColors.mediumGray}>{t("headerChip.contentShared")}</HeaderChip>
      <CollaborationCreationMainFormAndContent__Container className="CollaborationCreation__MainFormAndContent">
        <Stack className="CollaborationCreation__MainForm" useFlexGap spacing={4} flex={1} minHeight={0}>
          <CollaborationCreationMainForm />
          <CollaborationCreationContent showAddFile showAddRequest />
        </Stack>
      </CollaborationCreationMainFormAndContent__Container>
    </Column>
  );
};

const CollaborationCreationMainFormAndContent__Container = styled(ColumnPaper)({
  borderTop: `5px solid ${TakeTurnsColors.mediumGray}`,
  paddingBottom: 0,
});

const CollaborationCreationMainFormAndContent__MobilePaddingContainer = styled(Stack)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    padding: `0 ${theme.spacing(3)}`,
  },
}));
