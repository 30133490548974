/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const TakeTurnsCardContent = styled(Box)({
  display: "flex",
  padding: "32px 10px 5px",
  lineHeight: "1.2em !important",
  flexDirection: "column",
  height: "100%",
  justifyContent: "space-between",
  width: "100%",
  overflow: "hidden",
  overflowWrap: "anywhere",
});
