/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { RichTextField } from "@taketurns-components/collaboration/Shared/RichTextField/RichTextField";
import { useCollaborationCreationTranslation } from "@taketurns-i18n/collaboration/CollaborationCreation/useCollaborationCreationTranslation";
import { useSetCollaborationFormStartNoteRule } from "@taketurns-rules/collaboration/commands/collaborationCreation/form/useSetCollaborationFormStartNoteRule";
import { useGetCollaborationFormStartNoteRule } from "@taketurns-rules/collaboration/queries/collaborationCreation/form/useGetCollaborationFormStartNoteRule";

export const CollaborationCreationStartNote = () => {
  const startNote = useGetCollaborationFormStartNoteRule();
  const setCollaborationStartNote = useSetCollaborationFormStartNoteRule();
  const { t } = useCollaborationCreationTranslation();

  return <RichTextField onChange={setCollaborationStartNote} placeholder={t("placeholderStartNote")} value={startNote} />;
};
