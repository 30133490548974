/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useSetRecoilState } from "recoil";
import { requestFilesToAddOnRequestFulfillmentOpeningState } from "../../recoil/requestFilesToAddOnRequestFulfillmentOpeningState";

export const useSetRequestFilesToAddOnRequestFulfillmentOpening = () => {
  const setRequestFilesToAddOnRequestFulfillmentOpening = useSetRecoilState(
    requestFilesToAddOnRequestFulfillmentOpeningState,
  );

  return (files: File[]) => {
    return setRequestFilesToAddOnRequestFulfillmentOpening(files);
  };
};
