/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

/*
 * Filter type applied to collaboration list view
 * Must match graphQL query names
 */
export enum CollaborationFilterType {
  activeCollaborations = "activeCollaborations",
  starredCollaborations = "starredCollaborations",
  pendingInvitations = "pendingInvitations",
  closedCollaborations = "closedCollaborations",
  allCollaborations = "allCollaborations",
}
