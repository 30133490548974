/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { InviteToOrganizationInput } from "@taketurns/taketurns-graphql-repository";
import { useOrganizationTranslation } from "@taketurns-i18n/user/Organization/useOrganizationTranslation";
import { useResendOrganizationInvitation } from "@taketurns-repositories/user/graphql/mutations/useResendOrganizationInvitation";
import { useDisplayAndLogNotificationErrorRule } from "./useDisplayAndLogNotificationErrorRule";
import { useDisplayNotificationSuccessRule } from "./useDisplayNotificationSuccessRule";

export const useResendInvitationRule = () => {
  const {
    resendOrganizationInvitation: resendOrganizationInvitationCommand,
    loadingResendOrganizationInvitation,
    errorOnResendOrganizationInvitation,
  } = useResendOrganizationInvitation();

  const displayNotificationAndLogError = useDisplayAndLogNotificationErrorRule();

  const displaySuccessNotification = useDisplayNotificationSuccessRule();
  const { t } = useOrganizationTranslation();
  const resendOrganizationInvitation = (invitation: InviteToOrganizationInput) => {
    const { email, userId, role } = invitation;
    resendOrganizationInvitationCommand({
      email,
      userId,
      role,
    })
      .then(() => displaySuccessNotification(t("Notifications.resendInvitationSuccess")))
      .catch((error) => displayNotificationAndLogError(error));
  };

  return { loadingResendOrganizationInvitation, errorOnResendOrganizationInvitation, resendOrganizationInvitation };
};
