/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { WelcomeDialog } from "@taketurns-components/webapp/WelcomeDialogs/WelcomeDialog";
import { getDefaultWelcomeDialogUrlRule } from "@taketurns-rules/webapp/queries/getDefaultWelcomeDialogUrlRule";

export const DefaultWelcomeDialog = () => {
  const dialogUrl = getDefaultWelcomeDialogUrlRule();
  return <WelcomeDialog dialogUrl={dialogUrl} />;
};
