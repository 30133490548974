/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Avatar, CircularProgress, Stack, Typography } from "@mui/material";
import { RevisionActions } from "@taketurns-components/collaboration/CollaborationView/AttachmentDetails/components/DocumentRevisionList/RevisionActions";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { useFetchDocumentRevisionUserRule } from "@taketurns-rules/document/queries/useFetchDocumentRevisionUserRule";

interface RevisionUserAndActionsProps {
  attachmentId: string;
  documentId: string;
  revisionId: string;
  revisionNumber: number;
}

export const RevisionUserAndActions = (props: RevisionUserAndActionsProps) => {
  const { documentRevisionUser, loadingDocumentRevisionUser, errorOnDocumentRevisionUser } =
    useFetchDocumentRevisionUserRule(props.documentId, props.revisionId);

  if (loadingDocumentRevisionUser || !documentRevisionUser) {
    return <CircularProgress />;
  }

  if (errorOnDocumentRevisionUser) {
    return <Typography color={"error"}>Something went wrong on getting user revision</Typography>;
  }

  const { avatar, firstName, lastName, email } = documentRevisionUser;

  return (
    <Stack spacing={1}>
      <AvatarWithUserName avatarUrl={avatar} firstName={firstName} lastName={lastName} email={email} />
      <RevisionActions attachmentId={props.attachmentId} revisionNumber={props.revisionNumber} />
    </Stack>
  );
};

const AvatarWithUserName = ({
  avatarUrl,
  firstName,
  lastName,
  email,
}: {
  avatarUrl: string;
  firstName: string;
  lastName: string;
  email: string;
}) => {
  const userName = firstName && lastName ? `${firstName} ${lastName}` : email;
  return (
    <Stack direction={"row"} spacing={1}>
      <Avatar src={avatarUrl} sx={{ width: 24, height: 24 }} />
      <Typography variant={"body2"} color={TakeTurnsColors.connectedUser}>
        {userName}
      </Typography>
    </Stack>
  );
};
