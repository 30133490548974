/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useFetchTodos } from "@taketurns-repositories/collaboration/graphql/queries/content/useFetchTodos";
import { useGetDisplayedCollaborationIdRule } from "@taketurns-rules/collaboration/queries/collaboration/useGetDisplayedCollaborationIdRule";

export const useFetchTodosRule = () => {
  const displayedCollaborationId = useGetDisplayedCollaborationIdRule();
  return useFetchTodos(displayedCollaborationId);
};
