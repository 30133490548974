/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Box, Chip, CircularProgress, styled } from "@mui/material";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { IconCalendar, IconClock, IconXCircle } from "../../../commons/icons";

interface TrialRemainingTimeNotificationChipProps {
  label: string;
  buttonLabel: string;
  onClick: () => void;
  loading?: boolean;
  expired?: boolean;
  onClose?: () => void;
}

export const TrialRemainingTimeNotificationChip = ({
  label,
  buttonLabel,
  onClick,
  loading,
  expired = false,
  onClose,
}: TrialRemainingTimeNotificationChipProps) => {
  return (
    <TrialRemainingTimeNotification__Container
      variant={"outlined"}
      onClick={onClick}
      icon={
        expired ? (
          <IconClock size={18} color={TakeTurnsColors.error} />
        ) : (
          <IconCalendar size={18} color={TakeTurnsColors.mediumGray} />
        )
      }
      expired={expired}
      onDelete={onClose}
      label={
        <Label__Container>
          <p>{label}</p>
          <DotSeparator />
          {loading ? <CircularProgress size={12} color={"secondary"} /> : <Action>{buttonLabel}</Action>}
        </Label__Container>
      }
      deleteIcon={<IconXCircle />}
    />
  );
};

const TrialRemainingTimeNotification__Container = styled(Chip, {
  shouldForwardProp: (props) => props !== "expired",
})(({ expired }: { expired?: boolean }) => ({
  border: `1px solid ${expired ? TakeTurnsColors.error : TakeTurnsColors.lightGray}`,
  paddingLeft: 6,
  backgroundColor: expired ? "#FFF0F0" : "transparent",
  "& .MuiChip-deleteIcon": {
    marginLeft: 4,
  },
}));

const Label__Container = styled(Box)({
  display: "flex",
  flexDirection: "row",
  gap: 6,
  alignItems: "center",
});
const DotSeparator = () => <span style={{ color: TakeTurnsColors.mediumGray }}>●</span>;

const Action = styled("span")({
  color: TakeTurnsColors.lightBlue,
  fontWeight: "bold",
});
