/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

export enum HelpCenterSections {
  CREATE_COLLABORATION = "create-collaborations",
  ACTIVE_COLLABORATIONS = "active-collaborations",
  CLOSED_COLLABORATIONS = "closed-collaborations",
  STARRED_COLLABORATIONS = "starred-collaborations",
  PENDING_INVITATIONS = "pending-invitations",
  COLLABORATION_CONTENT = "participate-in-collaborations",
  ADD_FILES = "add-files-and-folders",
  ADD_REQUESTS = "add-requests",
  FULFILL_REQUEST = "fulfill-requests",
  EDIT_COLLABORATION = "edit-collaboration-details",
  PARTICIPANTS = "participant-roles",
  CLOSE_REOPEN_COLLABORATION = "close-and-reopen-collaborations",
  SEND_UPDATES = "end-turn",
  CANCEL_PENDING_UPDATES = "skip-turn",
  PROFILE = "edit-your-profile",
  MANAGE_YOUR_PLAN = "your-plan",
  RECALL_UPDATES = "recall-turn",
  MOVE = "move",
  WHAT_IS_TAKETURNS = "what-is-taketurns",
}
