/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { atom } from "recoil";

export interface ContentSelectionState {
  isActive: boolean;
  attachmentIds: string[];
  folderIds: string[];
}

export const contentSelectionState = atom<ContentSelectionState>({
  key: "ContentSelection",
  default: {
    isActive: false,
    attachmentIds: [],
    folderIds: [],
  },
});
