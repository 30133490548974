/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Box, ClickAwayListener, Divider, IconButton, Popper, Stack, styled, Tooltip, Typography } from "@mui/material";
import { createRef, PropsWithChildren, ReactNode, RefObject, useRef, useState } from "react";
import { getUserSettingsSectionUrl, INTEGRATIONS_SECTION } from "@taketurns-app/routing/routes/userSettingsSections";
import { AttachmentAiAssistantSummary } from "@taketurns-components/collaboration/CollaborationView/AttachmentDetails/components/AttachmentAiAssistant/AttachmentAiAssistantSummary";
import { AttachmentAiAssistantComparison } from "@taketurns-components/collaboration/CollaborationView/AttachmentDetails/components/AttachmentAiAssistant/AttachmentRevisionAiAssistantComparison";
import { AiAssistantButton } from "@taketurns-components/collaboration/CollaborationView/AttachmentDetails/components/DesktopAttachmentDetails/AttachmentAiAssistant/AiAssistantButton";
import { AiAssistantContent } from "@taketurns-components/collaboration/CollaborationView/AttachmentDetails/components/DesktopAttachmentDetails/AttachmentAiAssistant/AiAssistantContent";
import { IconCompare, IconSummary, IconX } from "@taketurns-components/commons/icons";
import { LinkStyled } from "@taketurns-components/webapp/LinkStyled";
import { useSharedCollaborationTranslation } from "@taketurns-i18n/collaboration/shared/useSharedCollaborationTranslation";
import { useIsFirstRevisionSelectedRule } from "@taketurns-rules/collaboration/queries/collaborationView/useIsFirstRevisionSelectedRule";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";

interface AiAssistantOverlayProps {
  open: boolean;
  onClose: () => void;
  buttonRef: HTMLButtonElement;
  documentId: string;
  collaborationId: string;
  revisionId: string;
}

export const AiAssistantOverlay = (props: AiAssistantOverlayProps) => {
  return (
    <AiAssistantPopper onClose={props.onClose} open={props.open} buttonRef={props.buttonRef}>
      <ClickAwayListener onClickAway={props.onClose}>
        <AiAssistantOverlayContainer>
          <CloseButton onClick={props.onClose} />
          <AiAssistantOverlayContent {...props} />
          <AiAssistantDisclaimer />
        </AiAssistantOverlayContainer>
      </ClickAwayListener>
    </AiAssistantPopper>
  );
};

interface AiAssistantPopperProps {
  open: boolean;
  onClose: () => void;
  buttonRef: HTMLButtonElement;
}

const AiAssistantPopper = (props: PropsWithChildren<AiAssistantPopperProps>) => {
  const BORDER_WIDTH = 1;
  const buttonTopPosition = props.buttonRef?.getBoundingClientRect().top;
  const buttonHeight = props.buttonRef?.getBoundingClientRect().height;
  const DIALOG_FOOTER_HEIGHT = 60;
  const DIALOG_MARGIN = 32;
  const heightToRemoveFromTopToBottom =
    BORDER_WIDTH + buttonTopPosition + buttonHeight + DIALOG_FOOTER_HEIGHT + BORDER_WIDTH + DIALOG_MARGIN;

  const buttonRightPosition = props.buttonRef?.getBoundingClientRect().right;
  const POPPER_MARGIN_LEFT = 24;

  const ABOVE_DIALOG_Z_INDEX = 1301;
  return (
    <Popper
      disablePortal
      open={props.open}
      anchorEl={props.buttonRef}
      placement={"bottom-end"}
      slotProps={{
        root: {
          style: {
            zIndex: ABOVE_DIALOG_Z_INDEX,
            width: buttonRightPosition - POPPER_MARGIN_LEFT - BORDER_WIDTH - DIALOG_MARGIN,
            height: `calc(100vh - ${heightToRemoveFromTopToBottom}px)`,
          },
        },
      }}
      popperOptions={{
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, 0],
            },
          },
        ],
        strategy: "fixed",
      }}
    >
      {props.children}
    </Popper>
  );
};

const AiAssistantOverlayContainer = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(3),
  position: "relative",
  border: `1px solid ${TakeTurnsColors.aiAssistant}`,
  borderRadius: 1,
  borderTopRightRadius: 0,
  backgroundColor: TakeTurnsColors.white,
  padding: theme.spacing(3, 3, 2),
  height: "100%",
}));

interface AiAssistantOverlayContentProps {
  documentId: string;
  collaborationId: string;
  revisionId: string;
  sectionsByName?: { name: string; component: ReactNode }[];
  setSectionsByName?: (sections: { name: string; component: ReactNode }[]) => void;
}

const AiAssistantOverlayContent = (props: AiAssistantOverlayContentProps) => {
  const [sectionsByName, setSectionsByName] = useState<{ name: string; component: ReactNode }[]>([]);
  const sectionAnchorRefsRef = useRef<Map<string, RefObject<HTMLAnchorElement>>>(new Map());

  const addSection = (sectionName: string, component: ReactNode) => {
    if (sectionsByName.some((section) => section.name === sectionName)) {
      sectionAnchorRefsRef.current.get(sectionName)?.current?.scrollIntoView({ behavior: "smooth" });
      return;
    }
    sectionAnchorRefsRef.current.set(sectionName, createRef());
    setSectionsByName([...sectionsByName, { name: sectionName, component }]);
  };

  const addSummarySection = () => {
    const SUMMARY_SECTION_NAME = "summary";
    addSection(SUMMARY_SECTION_NAME, <AttachmentAiAssistantSummary {...props} />);
  };
  const addComparisonSection = () => {
    const COMPARISON_SECTION_NAME = "comparison";
    addSection(COMPARISON_SECTION_NAME, <AttachmentAiAssistantComparison {...props} />);
  };

  return (
    <Stack flex={1} minHeight={0} spacing={3} divider={<Divider flexItem sx={{ marginX: -3 }} />}>
      <AiAssistantFeatureButtons
        addSummarySection={addSummarySection}
        addComparisonSection={addComparisonSection}
        documentId={props.documentId}
      />
      <Box sx={{ minHeight: 0, flex: 1, overflow: "auto" }}>
        <AiAssistantContent
          documentId={props.documentId}
          collaborationId={props.collaborationId}
          revisionId={props.revisionId}
        >
          {sectionsByName.map((section) => (
            <Box key={section.name} ref={sectionAnchorRefsRef.current.get(section.name)}>
              {section.component}
            </Box>
          ))}
        </AiAssistantContent>
      </Box>
    </Stack>
  );
};

const CloseButton = (props: { onClick: () => void }) => {
  return (
    <IconButton
      sx={{ position: "absolute", top: 16, right: 16, cursor: "pointer", padding: 0 }}
      onClick={props.onClick}
    >
      <IconX size={24} color={TakeTurnsColors.mediumGray} />
    </IconButton>
  );
};

interface AiAssistantFeatureButtonsProps {
  addSummarySection: () => void;
  addComparisonSection: () => void;
  documentId: string;
}

const AiAssistantFeatureButtons = (props: AiAssistantFeatureButtonsProps) => {
  const isFirstRevisionSelected = useIsFirstRevisionSelectedRule(props.documentId);
  const { t } = useSharedCollaborationTranslation();
  return (
    <Stack direction={"row"} spacing={1}>
      <AiAssistantButton
        onClick={props.addSummarySection}
        label={t("AttachmentAiAssistant.summary")}
        Icon={IconSummary}
        dataCy="SummaryButton"
      />
      <Tooltip
        title={t("AttachmentAiAssistant.errors.CANNOT_COMPARE_FIRST_REVISION")}
        placement="bottom-end"
        disableFocusListener={!isFirstRevisionSelected}
        disableHoverListener={!isFirstRevisionSelected}
        disableTouchListener={!isFirstRevisionSelected}
      >
        <span>
          <AiAssistantButton
            disabled={isFirstRevisionSelected}
            onClick={props.addComparisonSection}
            label={t("AttachmentAiAssistant.compare")}
            Icon={IconCompare}
            dataCy="ComparisonButton"
          />
        </span>
      </Tooltip>
    </Stack>
  );
};

const AiAssistantDisclaimer = () => {
  const { t } = useSharedCollaborationTranslation();
  return (
    <Stack direction="row">
      <Typography variant="caption">
        {t("AttachmentAiAssistant.AiAssistantDisclaimer.disclaimer")}{" "}
        <LinkStyled sx={{ fontSize: "0.75rem" }} to={getUserSettingsSectionUrl(INTEGRATIONS_SECTION)}>
          {t("AttachmentAiAssistant.AiAssistantDisclaimer.showSettings")}
        </LinkStyled>
      </Typography>
    </Stack>
  );
};
