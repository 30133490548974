/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Box } from "@mui/material";
import { Fragment } from "react";
import { ContentStatus as ContentStatusEnum, Request } from "@taketurns/taketurns-graphql-repository";
import { EmptySelectionWrapperForListItem } from "@taketurns-components/collaboration/CollaborationView/CollaborationContent/shared/components/SelectionComponentWrapper";
import { RequestMoveDestinationDialogInCollaborationView } from "@taketurns-components/collaboration/CollaborationView/MoveDestinationDialog/RequestMoveDestinationDialogInCollaborationView";
import { EditRequestDialog } from "@taketurns-components/collaboration/Shared/content/EditRequestDialog";
import {
  FilesDragAndDropWithOverlay,
  FilesDragAndDropWithOverlayConfig,
} from "@taketurns-components/collaboration/Shared/FilesDragAndDropWithOverlay";
import { useGetCurrentFolderIdInCollaborationView } from "@taketurns-repositories/collaboration/state/read/collaborationView/useGetCurrentFolderIdInCollaborationView";
import { useModifyRequestDescriptionRule } from "@taketurns-rules/collaboration/commands/collaborationView/request/useModifyRequestDescriptionRule";
import { useOpenRequestFulfillmentRule } from "@taketurns-rules/collaboration/commands/collaborationView/request/useOpenRequestFulfillmentRule";
import { useOpenRequestFulfillmentWithFilesRule } from "@taketurns-rules/collaboration/commands/collaborationView/request/useOpenRequestFulfillmentWithFilesRule";
import { useRenameRequestRule } from "@taketurns-rules/collaboration/commands/collaborationView/request/useRenameRequestRule";
import { useMoveDestinationDialogRule } from "@taketurns-rules/collaboration/commands/useMoveDestinationDialogRule";
import { useIsContentSelectionStateActiveRule } from "@taketurns-rules/collaboration/queries/collaborationView/contentSelection/useIsContentSelectionStateActiveRule";
import { useGetRequestRule } from "@taketurns-rules/collaboration/queries/collaborationView/useGetRequestRule";
import { useIsRequestMadeByConnectedUserPartyRule } from "@taketurns-rules/collaboration/queries/context/useIsRequestMadeByConnectedUserPartyRule";
import { useCanConnectedUserEditRequestRule } from "@taketurns-rules/collaboration/queries/permissions/useCanConnectedUserEditRequestRule";
import { useCanConnectedUserFulFillRequestRule } from "@taketurns-rules/collaboration/queries/permissions/useCanConnectedUserFulFillRequestRule";
import { useGetRequestContextMenuConfigurationRule } from "@taketurns-rules/collaboration/queries/request/useGetRequestContextMenuConfigurationRule";
import { useShouldDisplayUpdateMarkerForRequestRule } from "@taketurns-rules/collaboration/queries/request/useShouldDisplayUpdateMarkerForRequestRule";
import { useEditDialogRule } from "@taketurns-rules/collaboration/queries/useEditDialogRule";
import { useSubscribeToEditRequestRule } from "@taketurns-rules/collaboration/subscriptions/useSubscribeToEditRequestRule";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { useIsOnMobileRule } from "@taketurns-rules/commons/theme/useIsOnMobileRule";
import { DesktopRequestListItem } from "./DesktopRequestListItem";
import { MobileRequestListItem } from "./MobileRequestListItem";

interface RequestListItemProps {
  requestId: string;
  editable: boolean;
}

export const RequestListItem = (props: RequestListItemProps) => {
  const request = useGetRequestRule(props.requestId);
  useSubscribeToEditRequestRule(props.requestId);

  if (hasRequestBeenRemoved(request)) {
    props.editable = false;
  }

  const isContentSelectionActive = useIsContentSelectionStateActiveRule();

  const folderId = useGetCurrentFolderIdInCollaborationView();
  const requestFulfillmentInput = {
    folderId,
    requestName: request.name,
    requestDescription: request.description as string,
  };
  const canConnectedUserFulFillRequest = useCanConnectedUserFulFillRequestRule();
  const openRequestFulfillmentWithFiles = useOpenRequestFulfillmentWithFilesRule(requestFulfillmentInput);

  if (isContentSelectionActive) {
    return (
      <EmptySelectionWrapperForListItem>
        <InnerComponent request={request} editable={props.editable} requestFulfillmentInput={requestFulfillmentInput} />
      </EmptySelectionWrapperForListItem>
    );
  }

  if (props.editable && canConnectedUserFulFillRequest(request)) {
    const dropZoneConfig: FilesDragAndDropWithOverlayConfig = {
      handleFilesDropping: openRequestFulfillmentWithFiles,
      multiple: true,
      className: "RequestCard__DropZone",
      sx: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
      },
    };

    return (
      <FilesDragAndDropWithOverlay ContainerComponent={Box} dropZoneConfig={dropZoneConfig}>
        <InnerComponent request={request} editable={props.editable} requestFulfillmentInput={requestFulfillmentInput} />
      </FilesDragAndDropWithOverlay>
    );
  }
  return (
    <InnerComponent request={request} editable={props.editable} requestFulfillmentInput={requestFulfillmentInput} />
  );
};

const hasRequestBeenRemoved = (request: Request) =>
  request.status === ContentStatusEnum.Removed || request.prevStatus === ContentStatusEnum.Removed;

interface InnerComponentProps {
  request: Request;
  editable: boolean;
  requestFulfillmentInput: Parameters<typeof useOpenRequestFulfillmentWithFilesRule>[0];
}

const InnerComponent = (props: InnerComponentProps) => {
  const isOnMobile = useIsOnMobileRule();
  const ItemComponent = isOnMobile ? MobileRequestListItem : DesktopRequestListItem;
  const isContentSelectionActive = useIsContentSelectionStateActiveRule();

  const openRequestFulfillment = useOpenRequestFulfillmentRule(props.requestFulfillmentInput);
  const canConnectedUserFulFillRequest = useCanConnectedUserFulFillRequestRule();
  const canConnectedUserEditRequest = useCanConnectedUserEditRequestRule();

  const shouldDisplayLastUpdatesMarker =
    props.request.prevStatus && props.request.prevStatus !== ContentStatusEnum.Unchanged;
  const shouldDisplayRequestMarker = useIsRequestMadeByConnectedUserPartyRule()(props.request);
  const shouldDisplayUploadChip = !shouldDisplayRequestMarker;
  const shouldDisplayUpdateMarker = useShouldDisplayUpdateMarkerForRequestRule(props.request);
  const shouldStrikeTitle = hasRequestBeenRemoved(props.request);

  const userCanEditRequest = !isContentSelectionActive && props.editable && canConnectedUserEditRequest(props.request);
  const { modifyRequestDescription } = useModifyRequestDescriptionRule(props.request.id);
  const { renameRequest } = useRenameRequestRule(props.request.id);
  const { closeMoveDestinationDialog, isMoveDestinationDialogOpen, openMoveDestinationDialog } =
    useMoveDestinationDialogRule();
  const { isEditDialogOpened, openEditDialog, closeEditDialog } = useEditDialogRule();

  const requestContextMenuConfiguration = useGetRequestContextMenuConfigurationRule(
    props.request.id,
    openEditDialog,
    openMoveDestinationDialog,
  );

  return (
    <Fragment>
      <ItemComponent
        request={props.request}
        openRequestFulfillment={isContentSelectionActive ? undefined : openRequestFulfillment}
        canConnectedUserFulFillRequest={props.editable && canConnectedUserFulFillRequest(props.request)}
        shouldDisplayLastUpdate={shouldDisplayLastUpdatesMarker}
        shouldDisplayRequestMarker={shouldDisplayRequestMarker}
        shouldDisplayUploadChip={shouldDisplayUploadChip}
        shouldDisplayUpdateMarker={shouldDisplayUpdateMarker}
        shouldStrikeTitle={shouldStrikeTitle}
        contextMenuConfiguration={
          userCanEditRequest && !isContentSelectionActive ? requestContextMenuConfiguration : null
        }
        backgroundColor={shouldDisplayUpdateMarker ? TakeTurnsColors.updatedElementBackground : undefined}
      />
      {userCanEditRequest && (
        <Fragment>
          <EditRequestDialog
            open={isEditDialogOpened}
            onClose={closeEditDialog}
            requestName={props.request.name}
            requestDescription={props.request.description}
            modifyRequestDescription={modifyRequestDescription}
            changeRequestName={renameRequest}
          />
          <RequestMoveDestinationDialogInCollaborationView
            requestId={props.request.id}
            open={isMoveDestinationDialogOpen}
            onClose={closeMoveDestinationDialog}
          />
        </Fragment>
      )}
    </Fragment>
  );
};
