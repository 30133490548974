/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

// This type MUST NOT change because it matches
// the exact needed Amplify.configure config object
import { getEnvironmentVariable } from "../../../getEnvironmentVariable";

type AwsConfiguration = {
  Auth: {
    identityPoolId: string;
    region: string;
    userPoolWebClientId: string;
    userPoolId: string;
  };
  oauth: {
    domain: string;
    redirectSignIn: string;
    redirectSignOut: string;
    responseType: string;
    scope: string[];
  };
  aws_appsync_graphqlEndpoint: string;
  aws_appsync_region: string;
  aws_appsync_authenticationType: string;
  Storage: {
    AWSS3: {
      bucket: string;
      region: string;
    };
  };
};

const OAUTH_RESPONSE_TYPE = "code";
const OAUTH_SCOPE = ["email", "openid", "profile"];

export const awsConfiguration: AwsConfiguration = {
  Auth: {
    identityPoolId: getEnvironmentVariable("COGNITO_IDENTITY_POOL_ID"),
    region: getEnvironmentVariable("COGNITO_REGION"),
    userPoolWebClientId: getEnvironmentVariable("COGNITO_USER_POOL_WEB_CLIENT_ID"),
    userPoolId: getEnvironmentVariable("COGNITO_USER_POOL_ID"),
  },
  oauth: {
    domain: getEnvironmentVariable("COGNITO_OAUTH_DOMAIN"),
    redirectSignIn: getEnvironmentVariable("COGNITO_OAUTH_REDIRECT_SIGNIN"),
    redirectSignOut: getEnvironmentVariable("COGNITO_OAUTH_REDIRECT_SIGNOUT"),
    responseType: OAUTH_RESPONSE_TYPE,
    scope: OAUTH_SCOPE,
  },
  aws_appsync_graphqlEndpoint: getEnvironmentVariable("APP_SYNC_GRAPHQL_ENDPOINT"),
  aws_appsync_region: getEnvironmentVariable("APP_SYNC_REGION"),
  aws_appsync_authenticationType: getEnvironmentVariable("APP_SYNC_AUTHENTICATION_TYPE"),
  Storage: {
    AWSS3: {
      bucket: getEnvironmentVariable("AWS_S3_BUCKET"),
      region: getEnvironmentVariable("AWS_S3_REGION"),
    },
  },
};
