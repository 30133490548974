/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useSubscribeToModifyDocumentDescription } from "@taketurns-repositories/document/graphql/subscriptions/useSubscribeToModifyDocumentDescription";
import { useSubscribeToModifyDocumentName } from "@taketurns-repositories/document/graphql/subscriptions/useSubscribeToModifyDocumentName";
import { useSubscribeToAddOrRemoveAttachmentRevisionRule } from "@taketurns-rules/collaboration/subscriptions/useSubscribeToAddOrRemoveAttachmentRevisionRule";

export const useSubscribeOnDocumentChangedRule = (documentId: string) => {
  useSubscribeToModifyDocumentName(documentId);
  useSubscribeToModifyDocumentDescription(documentId);
  useSubscribeToAddOrRemoveAttachmentRevisionRule(documentId);
};
