/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useRecallUpdates } from "@taketurns-repositories/collaboration/graphql/mutations/content/updates/useRecallUpdates";
import { useGetDisplayedCollaborationIdRule } from "@taketurns-rules/collaboration/queries/collaboration/useGetDisplayedCollaborationIdRule";
import { enqueueSnackbar } from "notistack";
import { useRef, useState } from "react";

export const useRecallUpdatesRule = () => {
  const recallNoteRef = useRef("");
  const [recallUpdatesConfirmed, setRecallUpdatesConfirmed] = useState(false);
  const { recallUpdates, recallUpdatesResult } = useRecallUpdates();
  const collaborationId = useGetDisplayedCollaborationIdRule();

  const setRecallNote = (note: string) => {
    recallNoteRef.current = note;
  };

  const recallUpdatesCommand = async () => {
    return recallUpdates({ collaborationId, note: recallNoteRef.current }).catch((error) => {
      console.error(error);
      enqueueSnackbar(error.message, { variant: "error" });
    });
  };

  return {
    recallUpdatesCommand,
    recallUpdatesResult,
    recallUpdatesConfirmed,
    setRecallUpdatesConfirmed,
    setRecallNote,
  };
};
