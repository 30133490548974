/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useResetSelectedRevisionId } from "@taketurns-repositories/collaboration/state/write/useResetSelectedRevisionId";
import { useSetOpenedAttachmentDetailsDocumentId } from "@taketurns-repositories/collaboration/state/write/useSetOpenedAttachmentDetailsDocumentId";

export const useCloseAttachmentDetailsRule = () => {
  const setOpenedAttachmentDetailsDocumentId = useSetOpenedAttachmentDetailsDocumentId();
  const resetSelectedRevisionId = useResetSelectedRevisionId();

  return () => {
    setOpenedAttachmentDetailsDocumentId(null);
    resetSelectedRevisionId();
  };
};
