/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { RouteObject } from "react-router-dom";
import { PasswordlessSignIn } from "@taketurns-app/pages/public/PasswordlessSignIn";
import { VerifyAccount } from "@taketurns-app/pages/public/VerifyAccount";
import { PublicLayout } from "../../layouts/public/PublicLayout";
import { PasswordlessCollaborationAccess } from "../../pages/public/PasswordlessCollaborationAccess/PasswordlessCollaborationAccess";
import { Plans } from "../../pages/public/Plans";
import { ResetPassword } from "../../pages/public/ResetPassword";
import { SignIn } from "../../pages/public/SignIn";
import { SignUp } from "../../pages/public/SignUp";
import { AlreadyLoggedInGuard } from "../guards/AlreadyLoggedInGuard";
import { PUBLIC_ROUTES } from "./publicRoutes.constants";

const guardRoutesWithAlreadyLoggedInGuarded: (guardedRoutes: RouteObject[]) => RouteObject = (
  guardedRoutes: RouteObject[],
) => ({
  element: <AlreadyLoggedInGuard />,
  children: guardedRoutes,
});

const SignInRoute: RouteObject = {
  path: PUBLIC_ROUTES.SIGN_IN,
  element: <SignIn />,
};

const SignUpRoute: RouteObject = {
  path: PUBLIC_ROUTES.SIGN_UP,
  element: <SignUp />,
};

const VerifyAccountRoute: RouteObject = {
  path: PUBLIC_ROUTES.VERIFY_ACCOUNT,
  element: <VerifyAccount />,
};

const ResetPasswordRoute: RouteObject = {
  path: PUBLIC_ROUTES.RESET_PASSWORD,
  element: <ResetPassword />,
};

const PublicPlanRoute = {
  path: PUBLIC_ROUTES.PLANS,
  element: <Plans />,
};

const accessCollaborationRoute = {
  path: PUBLIC_ROUTES.ACCESS_COLLABORATION,
  element: <PasswordlessCollaborationAccess />,
};

const PasswordlessSignInRoute: RouteObject = {
  path: PUBLIC_ROUTES.PASSWORDLESS_SIGNIN,
  element: <PasswordlessSignIn />,
};

export const publicRoutes: RouteObject = {
  element: <PublicLayout />,
  children: [
    guardRoutesWithAlreadyLoggedInGuarded([SignInRoute, SignUpRoute, VerifyAccountRoute, PasswordlessSignInRoute]),
    PublicPlanRoute,
    accessCollaborationRoute,
    ResetPasswordRoute,
  ],
};
