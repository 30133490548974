/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useEffect } from "react";

export function useFathomScript() {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://cdn.usefathom.com/script.js";
    script.setAttribute("data-site", "MGOSLNYI");
    script.defer = true;

    document.body.appendChild(script);
  }, []);
}
