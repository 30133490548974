/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { atomFamily } from "recoil";
import { PendingAttachmentRevision } from "@taketurns-repositories/collaboration/models/pendingAttachmentRevision";

export const pendingAttachmentRevisionsByCollaborationIdState = atomFamily<PendingAttachmentRevision[], string>({
  key: "pendingAttachmentRevisionsByCollaborationId",
  default: [],
});
