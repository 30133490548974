/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { styled } from "@mui/material";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";

export const DayIndicator = styled("span")({
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: TakeTurnsColors.yellow,
  width: "21px",
  height: "22px",
  lineHeight: "22px",
  borderRadius: "5px",
  fontWeight: "bold",
  color: "#FFFFFF",
});
