/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useSnackbar } from "notistack";

export const useDisplayNotificationSuccessRule = () => {
  const { enqueueSnackbar } = useSnackbar();
  return (successMessage: string) => {
    enqueueSnackbar(successMessage, { variant: "info", className: "snackbar-success" });
  };
};
