/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useMutation } from "@apollo/client";
import {
  CollaborationContentMetadata,
  MutationSendUpdatesArgs,
  SendUpdatesInput,
} from "@taketurns/taketurns-graphql-repository";
import { GET_FOLDER } from "@taketurns-repositories/collaboration/graphql/queries/folder/useFetchFolder";
import { useGetCurrentFolderIdInCollaborationView } from "@taketurns-repositories/collaboration/state/read/collaborationView/useGetCurrentFolderIdInCollaborationView";
import { useGetDisplayedCollaborationIdRule } from "@taketurns-rules/collaboration/queries/collaboration/useGetDisplayedCollaborationIdRule";
import { wait } from "@taketurns-rules/commons/util/wait";

export const useSendUpdates = () => {
  const mutation = gql`
    mutation sendUpdates($input: SendUpdatesInput!) {
      sendUpdates(input: $input) {
        id
        partyId
        hasPendingUpdates
        todoCount
        requestToFulfillCount
        attachmentToReviewCount
        hasNoContent
      }
    }
  `;

  const [sendUpdatesMutation, sendUpdatesResult] = useMutation<
    { sendUpdates: CollaborationContentMetadata },
    MutationSendUpdatesArgs
  >(mutation);
  const collaborationId = useGetDisplayedCollaborationIdRule();
  const folderId = useGetCurrentFolderIdInCollaborationView();
  const sendUpdates = async (input: SendUpdatesInput) => {
    return sendUpdatesMutation({
      variables: {
        input,
      },
      refetchQueries: [{ query: GET_FOLDER, variables: { collaborationId, folderId } }],
      awaitRefetchQueries: true,
      onQueryUpdated: (observableQuery) => {
        observableQuery.refetch();
        wait(3000).then(() => observableQuery.refetch());
      },
    });
  };

  return { sendUpdates, sendUpdatesResult };
};
