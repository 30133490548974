/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { CreateInviteeInput } from "@taketurns/taketurns-graphql-repository";
import { useCollaborationTranslation } from "@taketurns-i18n/collaboration/useCollaborationTranslation";
import { useAddInvitation } from "@taketurns-repositories/collaboration/graphql/mutations/participants/useAddInvitation";
import { useGetDisplayedCollaborationIdRule } from "@taketurns-rules/collaboration/queries/collaboration/useGetDisplayedCollaborationIdRule";

export const useInviteToCollaborationRule = () => {
  const collaborationId = useGetDisplayedCollaborationIdRule();
  const { inviteToCollaborationCommand, loadingInviteToCollaboration, errorOnInviteToCollaboration } =
    useAddInvitation();

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useCollaborationTranslation("notifications");

  useEffect(() => {
    if (errorOnInviteToCollaboration) {
      enqueueSnackbar(errorOnInviteToCollaboration.message, { variant: "error" });
    }
  }, [enqueueSnackbar, errorOnInviteToCollaboration]);

  const inviteToCollaboration = (invitee: CreateInviteeInput) => {
    inviteToCollaborationCommand(collaborationId, invitee, () => {
      enqueueSnackbar(
        t("user.invitationSent", {
          email: invitee.email,
        }),
        {
          variant: "info",
        },
      );
    });
  };

  return { inviteToCollaboration, loadingInviteToCollaboration };
};
