/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Stack, Typography } from "@mui/material";
import { EventNoteDialog } from "@taketurns-components/collaboration/Shared/EventNote/EventNoteDialog";
import { IconNote } from "@taketurns-components/commons/icons";
import { useSharedCollaborationTranslation } from "@taketurns-i18n/collaboration/shared/useSharedCollaborationTranslation";
import { useIsOnMobileRule } from "@taketurns-rules/commons/theme/useIsOnMobileRule";
import { Fragment, MouseEvent, useState } from "react";

interface EventNoteButtonWithDialogProps {
  collaborationId: string;
  eventId: string;
  color: string;
}

export const EventNoteButtonWithDialog = (props: EventNoteButtonWithDialogProps) => {
  const [isEventDialogOpen, setIsEventDialogOpen] = useState(false);
  const openLastUpdateNoteDialog = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setIsEventDialogOpen(true);
  };

  const { t } = useSharedCollaborationTranslation();
  const isOnMobile = useIsOnMobileRule();
  return (
    <Fragment>
      <Stack
        direction="row"
        alignItems="center"
        spacing={0.5}
        sx={{ cursor: "pointer" }}
        onClick={(event: MouseEvent<HTMLDivElement>) => openLastUpdateNoteDialog(event)}
      >
        <Stack alignItems="center">
          <IconNote size={24} color={props.color} />
        </Stack>
        <Typography color={props.color} fontSize={"0.875rem"}>
          {t(`ContentHeadline.readNote${isOnMobile ? "Short" : ""}`)}
        </Typography>
      </Stack>
      <EventNoteDialog
        open={isEventDialogOpen}
        onClose={() => setIsEventDialogOpen(false)}
        collaborationId={props.collaborationId}
        eventId={props.eventId}
      />
    </Fragment>
  );
};
