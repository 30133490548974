/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Avatar, CircularProgress, Stack, Typography } from "@mui/material";
import { Fragment, useRef, useState } from "react";
import { Cropper, ReactCropperElement } from "react-cropper";
import { ConnectedUser } from "@taketurns/taketurns-graphql-repository";
import { useUserSharedTranslation } from "@taketurns-i18n/user/useUserSharedTranslation";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { resizeImage } from "@taketurns-rules/commons/util/file/fileUtils";
import { useUpdateAvatarRule } from "@taketurns-rules/user/commands/useUpdateAvatarRule";
import { TakeTurnsDefaultButton } from "../../commons/button";
import { DialogFooter } from "../../commons/dialog/DialogFooter";
import { ResponsiveDialogContent, ResponsiveDialogV2 } from "../../commons/dialog/ResponsiveDialog";
import "./cropper.css";
import "./UserPictureEdit_CropperOverride.css";

interface UserPictureEditProps {
  connectedUser: ConnectedUser;
}

export const UserPictureEdit = (props: UserPictureEditProps) => {
  const { t } = useUserSharedTranslation();
  const [edit, setEdit] = useState<boolean>(false);

  const onEditEnded = () => setEdit(false);

  return (
    <Fragment>
      <Stack alignItems="center">
        <Avatar sx={{ width: "100px", height: "100px", margin: "auto" }} src={props.connectedUser.avatar} />
        <Typography
          variant="caption"
          color={TakeTurnsColors.darkGray}
          sx={{ "&:hover": { cursor: "pointer" } }}
          onClick={() => setEdit(true)}
        >
          {t("UserSettings.changePicture")}
        </Typography>
      </Stack>
      <ResponsiveDialogV2 fullWidth open={edit}>
        <UserPictureEditDialogContent connectedUser={props.connectedUser} onEditEnded={onEditEnded} />
      </ResponsiveDialogV2>
    </Fragment>
  );
};

interface UserPictureEditDialogContentProps {
  connectedUser: ConnectedUser;
  onEditEnded: () => void;
}
const UserPictureEditDialogContent = (props: UserPictureEditDialogContentProps) => {
  const [isProcessingImage, setIsProcessingImage] = useState(false);
  const { saveAvatar } = useUpdateAvatarRule(props.connectedUser);
  const avatarFileInputRef = useRef<HTMLInputElement>(null);
  const [imageToCrop, setImageToCrop] = useState<string>(null);
  const cropperRef = useRef<ReactCropperElement>(null);
  const { t } = useUserSharedTranslation();

  function chooseAvatarFile(file: File): void {
    if (!file.type.startsWith("image/")) {
      avatarFileInputRef.current.value = null;
      return;
    }
    const fileReader = new FileReader();
    fileReader.onload = () => {
      setImageToCrop(fileReader.result as string);
    };
    fileReader.readAsDataURL(file);
  }

  const closeEdit = () => {
    props.onEditEnded();
  };

  const modifyPictureUrl = async () => {
    if (cropperRef?.current?.cropper) {
      setIsProcessingImage(true);
      const canvasElement = cropperRef.current.cropper.getCroppedCanvas();
      if (canvasElement) {
        const fileToSave = await saveCanvasToBlob(canvasElement, `${props.connectedUser.id}.jpeg`);
        const resizedImage = await resizeImage(fileToSave, 512);
        await saveAvatar(resizedImage);
        props.onEditEnded();
      }
      setIsProcessingImage(false);
    }
  };

  return (
    <ResponsiveDialogContent
      title={t("UserSettings.changePicture")}
      body={
        <Stack spacing={2} alignItems="center" justifyItems="center">
          {imageToCrop && (
            <Cropper
              src={imageToCrop}
              style={{ maxWidth: 400, width: "100%", aspectRatio: "1" }}
              aspectRatio={1}
              viewMode={2}
              autoCropArea={1}
              ref={cropperRef}
              dragMode="move"
            ></Cropper>
          )}
          <Stack direction="row">
            <input
              accept="image/*"
              style={{ display: "none" }}
              id="file-input"
              type="file"
              ref={avatarFileInputRef}
              value=""
              onChange={(e) => chooseAvatarFile(e.currentTarget.files[0])}
            />
            <TakeTurnsDefaultButton disabled={isProcessingImage} onClick={() => avatarFileInputRef.current.click()}>
              {t(imageToCrop ? "UserSettings.changeAvatarButton" : "UserSettings.chooseAvatarButton")}
            </TakeTurnsDefaultButton>
          </Stack>
        </Stack>
      }
      footer={
        <DialogFooter hasCancelAction onClose={closeEdit}>
          <TakeTurnsDefaultButton
            disabled={!imageToCrop || isProcessingImage}
            onClick={modifyPictureUrl}
            startIcon={isProcessingImage ? <CircularProgress size={16} color="secondary" /> : undefined}
          >
            {t("UserSettings.submit")}
          </TakeTurnsDefaultButton>
        </DialogFooter>
      }
    />
  );
};

async function saveCanvasToBlob(canvasElement: HTMLCanvasElement, filename: string): Promise<File> {
  return new Promise((resolve) => {
    canvasElement.toBlob((blob) => {
      resolve(new File([blob], filename, { type: "image/jpeg" }));
    }, "image/jpeg");
  });
}
