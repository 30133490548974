/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { OrganizationDomainStatus, VerifyOrganizationDomainInput } from "@taketurns/taketurns-graphql-repository";
import { useOrganizationTranslation } from "@taketurns-i18n/user/Organization/useOrganizationTranslation";
import { useVerifyOrganizationDomain } from "@taketurns-repositories/user/graphql/mutations/useVerifyOrganizationDomain";
import { useDisplayNotificationFailureRule } from "@taketurns-rules/user/commands/useDisplayNotificationFailureRule";
import { useDisplayAndLogNotificationErrorRule } from "./useDisplayAndLogNotificationErrorRule";
import { useDisplayNotificationSuccessRule } from "./useDisplayNotificationSuccessRule";

export const useVerifyOrganizationDomainRule = () => {
  const {
    verifyOrganizationDomain: verifyOrganizationDomainCommand,
    errorOnVerifyOrganizationDomain,
    loadingVerifyOrganizationDomain,
  } = useVerifyOrganizationDomain();
  const { t } = useOrganizationTranslation();

  const displayNotificationSuccess = useDisplayNotificationSuccessRule();
  const displayNotificationFailure = useDisplayNotificationFailureRule();
  const displayAndLogNotificationError = useDisplayAndLogNotificationErrorRule();

  const verifyOrganizationDomain = ({
    verifyOrganizationDomainInput,
  }: {
    verifyOrganizationDomainInput: VerifyOrganizationDomainInput;
  }) => {
    verifyOrganizationDomainCommand(verifyOrganizationDomainInput)
      .then((domain) => {
        if (domain.status === OrganizationDomainStatus.Verified) {
          displayNotificationSuccess(t("Notifications.verifyOrganizationDomainSuccess"));
        } else {
          displayNotificationFailure(t("Notifications.verifyOrganizationDomainFailure"));
        }
      })
      .catch(() => displayAndLogNotificationError(t("error")));
  };

  return { verifyOrganizationDomain, errorOnVerifyOrganizationDomain, loadingVerifyOrganizationDomain };
};
