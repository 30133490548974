/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql } from "@apollo/client";
import { CollaborationFilterType } from "@taketurns-components/collaboration/CollaborationList/collaborationFilterType";
import { useRefreshableSubscription } from "@taketurns-components/webapp/QueriesAndSubscriptionsRefreshTrigger";
import { COLLABORATION_LIST_QUERIES } from "@taketurns-repositories/collaboration/graphql/queries/list/useFetchFilteredCollaborationList";
import { wait } from "@taketurns-rules/commons/util/wait";

const SUBSCRIBE_TO_COLLABORATION_LIST_UPDATED = gql`
  subscription onUserCollaborationListUpdated($userId: ID!) {
    onUserCollaborationListUpdated(userId: $userId) {
      userId
    }
  }
`;

export const useSubscribeToCollaborationListUpdated = (userId: string, appliedFilter: CollaborationFilterType) => {
  const relatedQuery = COLLABORATION_LIST_QUERIES[appliedFilter];
  useRefreshableSubscription(SUBSCRIBE_TO_COLLABORATION_LIST_UPDATED, {
    variables: {
      userId,
    },
    onData: async ({ client }) => {
      await wait(2000);
      await client.refetchQueries({
        include: [relatedQuery],
      });
    },
  });
};
