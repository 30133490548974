/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Box, styled, Typography } from "@mui/material";
import { useState } from "react";
import { EventNoteDialog } from "@taketurns-components/collaboration/Shared/EventNote/EventNoteDialog";
import { DisplayedUser, EventNoteUserAndHeadline } from "@taketurns-components/collaboration/Shared/EventNote/EventNoteUserAndHeadline";
import { EventNoteButtonWithDialog } from "@taketurns-components/shared/EventNoteButtonWithDialog";
import { removeHtmlTagsRule } from "@taketurns-rules/collaboration/queries/removeHtmlTagsRule";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";

interface PlainTextEventNoteWithReadMoreButtonProps {
  collaborationId: string;
  eventId: string;
  note: string;
  user: DisplayedUser;
  color: string;
}

export const PlainTextEventNoteWithReadMoreButton = (props: PlainTextEventNoteWithReadMoreButtonProps) => {
  const [isEventNoteDialogOpened, setIsEventNoteDialogOpened] = useState(false);

  return (
    <EventNote__Container>
      <EventNoteWithPlainText__Container className={"EventNoteWithPlainText__Container"}>
        <Typography fontSize={"0.875rem"} color={TakeTurnsColors.veryDarkGray} sx={{ wordBreak: "break-word" }}>
          {removeHtmlTagsRule(props.note)}
        </Typography>
      </EventNoteWithPlainText__Container>
      <ReadMoreTextButton__Container>
        <EventNoteButtonWithDialog
          collaborationId={props.collaborationId}
          eventId={props.eventId}
          color={props.color}
        />
      </ReadMoreTextButton__Container>
      <EventNoteDialog
        open={isEventNoteDialogOpened}
        onClose={() => setIsEventNoteDialogOpened(false)}
        collaborationId={props.collaborationId}
        eventId={props.eventId}
      >
        <EventNoteUserAndHeadline user={props.user} color={props.color} />
      </EventNoteDialog>
    </EventNote__Container>
  );
};

const EventNote__Container = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "6px",
});

const EventNoteWithPlainText__Container = styled(Box)`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  overflow-wrap: anywhere;
  font-size: 0.875rem;
`;

const ReadMoreTextButton__Container = styled(Box)({
  flex: "0 0 auto",
  alignSelf: "flex-end",
});
