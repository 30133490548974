/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { CircularProgress, Stack, styled, TextField, Typography } from "@mui/material";
import { FormEvent, PropsWithChildren, useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { CodeInput } from "@taketurns-app/pages/public/components/CodeInput";
import { useAuthContext } from "@taketurns-app/routing/AuthProvider";
import { TakeTurnsDefaultButton, TakeTurnsTextButton } from "@taketurns-components/commons/button";
import { useUserSharedTranslation } from "@taketurns-i18n/user/useUserSharedTranslation";
import { useSharedWebappTranslation } from "@taketurns-i18n/webapp/shared/useSharedWebappTranslation";
import { useWebAppTranslations } from "@taketurns-i18n/webapp/useWebAppTranslations";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { validateEmail } from "@taketurns-rules/commons/util/form/formUtils";
import { useResetPasswordFormValidationRule } from "@taketurns-rules/user/validation/useResetPasswordValidationRule";
import { useEmailFromLocationStateRule } from "@taketurns-rules/webapp/queries/useEmailFromLocationStateRule";

export const ResetPasswordForm = () => {
  const {
    resetPasswordProcess: { emailAddressWhereCodeWasSent },
  } = useAuthContext();

  if (!emailAddressWhereCodeWasSent) {
    return <ResetPasswordEmailForm />;
  }
  return <ResetPasswordNewPasswordForm />;
};

const ResetPasswordEmailForm = () => {
  const {
    resetPasswordProcess: { sendAuthenticationCode, error, loading },
  } = useAuthContext();
  const emailFromLocationState: string = useEmailFromLocationStateRule();
  const [email, setEmail] = useState(emailFromLocationState);

  const { t } = useWebAppTranslations("passwordless-signin");

  const onFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    await sendAuthenticationCode(email);
  };

  return (
    <Stack component="form" direction="column" spacing={2.5} onSubmit={onFormSubmit}>
      <Typography color={TakeTurnsColors.darkGray} variant="body2">
        {t("enterEmail")}
      </Typography>
      <TextField
        data-cy="ResetPasswordForm.emailInput"
        value={email}
        placeholder={t("email")}
        autoComplete="username"
        type="email"
        onChange={(e) => setEmail(e.target.value)}
      />
      <SubmitButton disabled={loading || !validateEmail(email)} loading={loading}>
        {t("sendCodeButton")}
      </SubmitButton>
      {error && <Typography color={"error"}>{t("error")}</Typography>}
    </Stack>
  );
};

const ResetPasswordNewPasswordForm = () => {
  const {
    resetPasswordProcess: {
      sendAuthenticationCode,
      sendNewPasswordAndAuthenticationCode,
      emailAddressWhereCodeWasSent: email,
      error,
      loading,
    },
  } = useAuthContext();
  const { handleSubmit, control, reset, trigger, setValue } = useResetPasswordFormValidationRule(email);
  const [resendCodeButtonDisabled, setResendCodeButtonDisabled] = useState(true);

  const { t } = useSharedWebappTranslation();

  useEffect(() => {
    setTimeout(() => setResendCodeButtonDisabled(false), 2000);
  }, []);

  const resendCode = async () => {
    setResendCodeButtonDisabled(true);
    await sendAuthenticationCode(email);
    reset();
    setTimeout(() => setResendCodeButtonDisabled(false), 2000);
  };

  const onFormSubmit = handleSubmit(async (data) => {
    await sendNewPasswordAndAuthenticationCode({
      email,
      newPassword: data.password,
      authenticationCode: data.code,
    });
    reset();
  });

  function getErrorMessage(error: unknown) {
    if (error === null || typeof error !== "object" || !("code" in error) || typeof error.code !== "string") {
      return "error.unknown";
    }
    switch (error.code) {
      case "CodeMismatchException":
        return "error.codeMismatch";
      case "ExpiredCodeException":
        return "error.expiredCode";
      case "UserNotFoundException":
        return "error.userNotFound";
      default:
        return "error.unknown";
    }
  }

  return (
    <Stack component="form" direction="column" spacing={2.5} alignItems={"center"} onSubmit={onFormSubmit}>
      <Typography color={TakeTurnsColors.darkGray} variant={"body2"}>
        {t("resetPassword.enterVerificationCode.begin")} <b>{email}</b> {t("resetPassword.enterVerificationCode.end")}
      </Typography>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <CodeInput length={6} onComplete={(code) => setValue("code", code)} />
          <Typography
            sx={{ display: "flex", alignItems: "baseline", gap: "4px" }}
            fontSize={12}
            color={TakeTurnsColors.darkGray}
          >
            {t("resetPassword.resendCode.didNotReceiveCode")}
            <TakeTurnsTextButton
              disabled={resendCodeButtonDisabled}
              variant={"text"}
              fontSize="12px"
              onClick={resendCode}
              type="reset"
            >
              {t("resetPassword.resendCode.resendCodeLink")}
            </TakeTurnsTextButton>
          </Typography>
          <NewPasswordInputs control={control} trigger={trigger} />
        </>
      )}
      <SubmitButton disabled={loading} loading={loading}>
        {t("resetPassword.sendNewPasswordButton")}
      </SubmitButton>
      {error && <Typography color={"error"}>{t(`resetPassword.${getErrorMessage(error)}`)}</Typography>}
    </Stack>
  );
};

type NewPasswordInputsProps = Pick<ReturnType<typeof useResetPasswordFormValidationRule>, "control" | "trigger">;
const NewPasswordInputs = (props: NewPasswordInputsProps) => {
  const { t } = useUserSharedTranslation();
  return (
    <Stack direction={{ xs: "column", md: "row" }} gap={2}>
      <Controller
        control={props.control}
        render={({ field, fieldState: { error: passwordFieldError } }) => (
          <UserPassword__Field
            {...field}
            data-cy="ResetPasswordForm.newPassword"
            error={!!passwordFieldError}
            helperText={!!passwordFieldError && t(passwordFieldError.message)}
            autoComplete="new-password"
            type="password"
            placeholder={t("UserSettings.newPassword")}
            onBlur={() => props.trigger("password")}
          />
        )}
        name={"password"}
      />
      <Controller
        control={props.control}
        render={({ field, fieldState: { error: passwordConfirmationFieldError } }) => (
          <UserPassword__Field
            {...field}
            data-cy="ResetPasswordForm.confirmPassword"
            error={!!passwordConfirmationFieldError}
            helperText={!!passwordConfirmationFieldError && t(passwordConfirmationFieldError.message)}
            autoComplete="new-password"
            type="password"
            placeholder={t("UserSettings.confirmPassword")}
            onBlur={() => props.trigger("passwordConfirmation")}
          />
        )}
        name={"passwordConfirmation"}
      />
    </Stack>
  );
};

const SubmitButton = (props: PropsWithChildren<{ disabled: boolean; loading: boolean }>) => (
  <TakeTurnsDefaultButton
    type="submit"
    data-cy="ResetPasswordForm.submitButton"
    disabled={props.disabled}
    startIcon={props.loading ? <CircularProgress size={16} color={"secondary"} /> : undefined}
    color="secondary"
    fullWidth
    sx={{ maxWidth: { xs: "350px", md: "initial" }, margin: "auto" }}
  >
    {props.children}
  </TakeTurnsDefaultButton>
);

const UserPassword__Field = styled(TextField)(({ theme }) => ({
  flex: 1,
  minWidth: "150px",
  maxWidth: "200px",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    maxWidth: "100%",
  },
}));
