/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Avatar, Typography } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { Fragment } from "react";
import { OrganizationInvitation, OrganizationMember } from "@taketurns/taketurns-graphql-repository";
import {
  MemberOrInvitationFirstAndLastNameOrEmail
} from "@taketurns-components/user/Organization/OrganizationMemberAndInvitationList/shared/MemberOrInvitationFirstAndLastNameOrEmail";
import { isOrganizationInvitation, OrganizationMemberOrInvitation } from "@taketurns-components/user/Organization/OrganizationMemberAndInvitationList/shared/SharedComponentTypes";
import { useOrganizationTranslation } from "@taketurns-i18n/user/Organization/useOrganizationTranslation";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { useSubscribeToOrganizationMemberRoleChangeRule } from "@taketurns-rules/user/subscriptions/useSubscribeToOrganizationMemberRoleChangeRule";
import { InvitationResendButton } from "../shared/InvitationResendButton";
import { InvitationRevokeButtonAndDialog } from "../shared/InvitationRevokeButtonAndDialog";
import { MemberRemoveButtonAndDialog } from "../shared/MemberRemoveButtonAndDialog";
import { OrganizationInvitationRoleSelector } from "../shared/OrganizationInvitationRoleSelector";
import { OrganizationMemberRoleSelector } from "../shared/OrganizationMemberRoleSelector";
import { SupervisorSelector } from "../shared/SupervisorSelector";

const AVATAR_COLUMN_WIDTH = 40;
const FIRST_AND_LAST_NAME_OR_EMAIL_COLUMN_MAX_WIDTH = 400;
const EMAIL_COLUMN_MAX_WIDTH = 400;
const ROLE_WIDTH = 160;
const SUPERVISOR_COLUMN_WIDTH = 250;

const numberOfActions = 2;
const actionButtonWidth = 34;
const margin = 10;
const numberOfExternalMargins = 2;
const actionsColumnWidth = numberOfActions * actionButtonWidth + numberOfExternalMargins * margin;

export const organizationMembersAndInvitationsDataGridMaxWidth =
  AVATAR_COLUMN_WIDTH +
  FIRST_AND_LAST_NAME_OR_EMAIL_COLUMN_MAX_WIDTH +
  EMAIL_COLUMN_MAX_WIDTH +
  ROLE_WIDTH +
  SUPERVISOR_COLUMN_WIDTH +
  actionsColumnWidth;

export function OrganizationMemberAndInvitationDataGridColumns(
  connectedUserId: string,
): GridColDef<OrganizationMemberOrInvitation>[] {
  return [
    avatarColumn(),
    useCivilDetailsOrEmailColumn(),
    useEmailColumn(),
    useRoleColumn(connectedUserId),
    useSupervisorColumn(),
    actionsColumn(connectedUserId),
  ];
}

type MemberDataGridRenderCellParams = GridRenderCellParams<
  OrganizationMemberOrInvitation,
  OrganizationMemberOrInvitation,
  unknown
>;

const avatarColumn: () => GridColDef = () => {
  function renderAvatarCell(params: MemberDataGridRenderCellParams) {
    const { user } = params.row;
    const userInitials = user?.firstName && user?.lastName ? user.firstName[0] + user.lastName[0] : null;
    return (
      <Avatar alt={"userAvatar"} sx={{ width: 30, height: 30, fontSize: 14 }} src={params.row.user?.avatar}>
        {userInitials}
      </Avatar>
    );
  }

  return {
    field: "avatar",
    headerName: "",
    width: AVATAR_COLUMN_WIDTH,
    filterable: false,
    disableColumnMenu: true,
    hideSortIcons: true,
    renderCell: renderAvatarCell,
  };
};

const useCivilDetailsOrEmailColumn: () => GridColDef = () => {
  const { t } = useOrganizationTranslation();

  return {
    field: "firstAndLastName",
    cellClassName: "textBlackColor",
    headerName: t("OrganizationMemberAndInvitationList.OrganizationMemberAndInvitationDataGridColumns.name"),
    flex: 1.5,
    minWidth: 180,
    maxWidth: FIRST_AND_LAST_NAME_OR_EMAIL_COLUMN_MAX_WIDTH,
    renderCell: (params) => <MemberOrInvitationFirstAndLastNameOrEmail memberOrInvitation={params.row} />,
  };
};

const useEmailColumn: () => GridColDef = () => {
  function getMemberOrInvitationEmail(params: MemberDataGridRenderCellParams) {
    const organizationMemberOrInvitation = params.row;
    if (organizationMemberOrInvitation.user && organizationMemberOrInvitation.user.email) {
      return organizationMemberOrInvitation.user.email;
    }
    return (organizationMemberOrInvitation as OrganizationInvitation).email;
  }

  const { t } = useOrganizationTranslation();
  return {
    field: "email",
    headerName: t("OrganizationMemberAndInvitationList.OrganizationMemberAndInvitationDataGridColumns.primaryEmail"),
    flex: 1.5,
    minWidth: 210,
    maxWidth: EMAIL_COLUMN_MAX_WIDTH,
    valueGetter: getMemberOrInvitationEmail,
  };
};

const useRoleColumn: (connectedUserId: string) => GridColDef = (connectedUserId) => {
  const { t } = useOrganizationTranslation();
  useSubscribeToOrganizationMemberRoleChangeRule(connectedUserId);

  function memberOrInvitationRoleWithSelectorIfAvailable(memberOrInvitation: OrganizationMemberOrInvitation) {
    const isConnectedUserRow = connectedUserId === memberOrInvitation?.userId;
    if (isOrganizationInvitation(memberOrInvitation)) {
      return <OrganizationInvitationRoleSelector invitation={memberOrInvitation} />;
    }
    return isConnectedUserRow ? (
      <Typography paddingLeft={"10px"} variant={"body2"} color={TakeTurnsColors.darkGray}>
        {t(`OrganizationUserRole.${memberOrInvitation.role}`)}
      </Typography>
    ) : (
      <OrganizationMemberRoleSelector member={memberOrInvitation as OrganizationMember} />
    );
  }

  return {
    field: "role",
    headerName: t("OrganizationMemberAndInvitationList.OrganizationMemberAndInvitationDataGridColumns.role"),
    width: ROLE_WIDTH,
    disableColumnMenu: true,
    hideSortIcons: false,
    renderCell: (params) => memberOrInvitationRoleWithSelectorIfAvailable(params.row),
  };
};

const useSupervisorColumn: () => GridColDef = () => {
  const { t } = useOrganizationTranslation();
  return {
    field: "supervisor",
    headerName: t("OrganizationMemberAndInvitationList.OrganizationMemberAndInvitationDataGridColumns.supervisor"),
    width: SUPERVISOR_COLUMN_WIDTH,
    disableColumnMenu: true,
    hideSortIcons: false,
    cellClassName: "textBlackColor",
    renderCell: (params) => (params.row.isInvitation ? undefined : <SupervisorSelector member={params.row} />),
  };
};

const actionsColumn: (connectedUserId: string) => GridColDef = (connectedUserId) => {
  function memberOrInvitationActionIfAvailable(memberOrInvitation: OrganizationMemberOrInvitation) {
    if (isOrganizationInvitation(memberOrInvitation)) {
      return (
        <Fragment>
          <InvitationResendButton invitation={memberOrInvitation} />
          <InvitationRevokeButtonAndDialog invitation={memberOrInvitation} />
        </Fragment>
      );
    }
    const isConnectedUserRow = connectedUserId === memberOrInvitation.userId;
    if (!isConnectedUserRow) {
      return <MemberRemoveButtonAndDialog memberUserId={memberOrInvitation.userId!} />;
    }
  }

  return {
    width: actionsColumnWidth,
    field: "actions",
    headerName: "",
    align: "right",
    filterable: false,
    disableColumnMenu: true,
    hideSortIcons: true,
    renderCell: (params) => memberOrInvitationActionIfAvailable(params.row),
  };
};
