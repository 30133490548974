/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { ReopenCollaborationInput } from "@taketurns/taketurns-graphql-repository";
import { useReopenCollaboration } from "@taketurns-repositories/collaboration/graphql/mutations/useReopenCollaboration";
import { GET_FOLDER } from "@taketurns-repositories/collaboration/graphql/queries/folder/useFetchFolder";
import { useBrowseContentRule } from "@taketurns-rules/collaboration/commands/collaborationView/useBrowseContentRule";
import { wait } from "@taketurns-rules/commons/util/wait";

export const useReopenCollaborationByIdRule = () => {
  const [reopenCollaborationMutation, reopenCollaborationMutationResult] = useReopenCollaboration();

  const { currentFolderId } = useBrowseContentRule();

  const reopenCollaborationCommand = (input: ReopenCollaborationInput, onComplete?: () => void) => {
    reopenCollaborationMutation({
      variables: { input },
      onCompleted: onComplete,
      refetchQueries: [
        { query: GET_FOLDER, variables: { collaborationId: input.collaborationId, folderId: currentFolderId } },
      ],
      onQueryUpdated: (observableQuery) => {
        wait(3000).then(() => observableQuery.refetch());
      },
    });
  };
  return {
    reopenCollaborationCommand,
    result: reopenCollaborationMutationResult,
  };
};
