/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { ApolloError } from "@apollo/client";

export class FetchLoadingManager {
  loadings: boolean[];
  errors: ApolloError[];

  constructor(loadings: boolean[], errors: ApolloError[]) {
    this.loadings = loadings;
    this.errors = errors;
  }

  hasFetchLoading() {
    return this.loadings.some((loading) => loading);
  }

  hasFetchInError() {
    return this.errors.some((error) => error);
  }

  logErrors(errorMessage?: string) {
    for (const error of this.errors.filter((e) => !!e)) {
      console.error(`${errorMessage ? errorMessage : ""} ${error.message}`);
    }
  }

  getFirstError() {
    return this.errors.find((error) => !!error);
  }
}
