/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Box, DialogContent, styled } from "@mui/material";
import { PropsWithChildren } from "react";
import {
  RichTextField,
  RichTextFieldProps,
} from "@taketurns-components/collaboration/Shared/RichTextField/RichTextField";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";

export const DialogContentWithRichTextField = ({
  onChange,
  placeholder,
  value,
  children,
}: PropsWithChildren<RichTextFieldProps>) => {
  return (
    <DialogContentWithRichTextField__Container>
      <DialogContentWithRichTextFieldChildren>{children}</DialogContentWithRichTextFieldChildren>
      <RichTextField onChange={onChange} placeholder={placeholder} value={value} />
    </DialogContentWithRichTextField__Container>
  );
};

const DialogContentWithRichTextField__Container = styled(DialogContent)({
  display: "flex",
  flexDirection: "column",
  minHeight: "50vh",
  padding: 0,
});

const DialogContentWithRichTextFieldChildren = styled(Box)({
  padding: "12px",
  backgroundColor: TakeTurnsColors.background,
});
