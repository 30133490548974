/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { FilterToggle } from "@taketurns-components/collaboration/CollaborationView/CollaborationContent/ContentHeader/FilterToggle";
import { useSharedCollaborationTranslation } from "@taketurns-i18n/collaboration/shared/useSharedCollaborationTranslation";

interface ToDoFilterToggleProps {
  todoCount: number;
  isToggled: boolean;
  toggle: () => void;
}

export const ToDoFilterToggle = (props: ToDoFilterToggleProps) => {
  const { t } = useSharedCollaborationTranslation();

  return (
    <FilterToggle isToggled={props.isToggled} toggle={props.toggle}>
      {t("ContentHeadline.toDo", { count: props.todoCount })}
    </FilterToggle>
  );
};
