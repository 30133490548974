/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { CircularProgress, Stack, styled, Typography } from "@mui/material";
import { Auth } from "aws-amplify";
import { Fragment, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { MobileCardLayout } from "@taketurns-app/layouts/public/MobileCardLayout";
import { UserAuthenticationLayout } from "@taketurns-app/layouts/public/UserAuthenticationLayout";
import { CodeInput } from "@taketurns-app/pages/public/components/CodeInput";
import { RegisterOrLoginCard } from "@taketurns-app/pages/public/components/RegisterOrLoginCard";
import { RegisterOrLoginHeader } from "@taketurns-app/pages/public/components/RegisterOrLoginHeader";
import { useAuthContext } from "@taketurns-app/routing/AuthProvider";
import { PUBLIC_ROUTES } from "@taketurns-app/routing/routes/publicRoutes.constants";
import { TakeTurnsTextButton } from "@taketurns-components/commons/button";
import { LinkStyled } from "@taketurns-components/webapp/LinkStyled";
import { useSharedWebappTranslation } from "@taketurns-i18n/webapp/shared/useSharedWebappTranslation";
import { useWebAppTranslations } from "@taketurns-i18n/webapp/useWebAppTranslations";
import {
  clearEmailVerificationData,
  getEmailVerificationData,
} from "@taketurns-repositories/webapp/sessionStorage/emailVerificationData";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { useIsOnMobileRule } from "@taketurns-rules/commons/theme/useIsOnMobileRule";
import { wait } from "@taketurns-rules/commons/util/wait";
import { useResendVerificationCodeRule } from "@taketurns-rules/webapp/commands/useResendVerificationCodeRule";
import { PageTitle, useSetDocumentTitleRule } from "@taketurns-rules/webapp/commands/useSetDocumentTitleRule";
import { EmailVerificationData } from "@taketurns-rules/webapp/validation/emailVerificationDataValidationRule";

export const VerifyAccount = () => {
  const isOnMobile = useIsOnMobileRule();
  const emailVerificationData = getEmailVerificationData();

  useSetDocumentTitleRule(PageTitle.VERIFY);

  if (emailVerificationData === null) {
    return <Navigate to={PUBLIC_ROUTES.SIGN_UP} />;
  }

  if (isOnMobile) {
    return (
      <MobileCardLayout>
        <PageHeader />
        <VerifyAccountContent verificationData={emailVerificationData} />
      </MobileCardLayout>
    );
  }

  return (
    <UserAuthenticationLayout maxWidth={"sm"}>
      <PageHeader />
      <RegisterOrLoginCard>
        <VerifyAccountContent verificationData={emailVerificationData} />
      </RegisterOrLoginCard>
    </UserAuthenticationLayout>
  );
};

const PageHeader = () => {
  const { t } = useSharedWebappTranslation();
  return <RegisterOrLoginHeader text={t("verifyAccount.verify")} secondaryText={<LinkToSignUpPage />} />;
};

const LinkToSignUpPage = () => {
  const { search, state } = useLocation();
  const { t } = useWebAppTranslations("signin");
  return (
    <Typography variant="caption" color={TakeTurnsColors.darkGray}>
      {t("or") + " "}
      <LinkStyled to={PUBLIC_ROUTES.SIGN_UP + search} state={state}>
        {t("secondaryText")}
      </LinkStyled>
    </Typography>
  );
};

const VerifyAccountContent = (props: { verificationData: EmailVerificationData }) => {
  const { t } = useSharedWebappTranslation();
  const descriptionTranslationKey =
    props.verificationData.origin === "signin"
      ? "verifyAccount.descriptionAfterSignIn"
      : "verifyAccount.descriptionAfterSignUp";
  const [descriptionTextBegin, descriptionTextEnd] = t(descriptionTranslationKey).split("%s");

  const Description = () => (
    <Typography fontSize={14} textAlign={"center"} variant="caption" color={TakeTurnsColors.darkGray}>
      {descriptionTextBegin}
      <UserEmailAddress__Text>{props.verificationData.email}</UserEmailAddress__Text>
      {descriptionTextEnd}
    </Typography>
  );

  return (
    <Stack spacing={2.5} alignItems="center">
      <Description />
      <VerifyAccountForm email={props.verificationData.email} password={props.verificationData.password} />
    </Stack>
  );
};

const UserEmailAddress__Text = styled("span")({
  color: TakeTurnsColors.darkBlue,
  fontWeight: "bold",
});

interface VerifyAccountFormProps {
  email: string;
  password: string;
}

const VerifyAccountForm = (props: VerifyAccountFormProps) => {
  const { email, password } = props;

  const [loading, setLoading] = useState(false);
  const [confirmationError, setConfirmationError] = useState<null | unknown>(null);
  const { login } = useAuthContext();

  const { t } = useSharedWebappTranslation();

  const { resendVerificationCode, resendVerificationCodeError } = useResendVerificationCodeRule(email);
  const error = confirmationError || resendVerificationCodeError;

  const fullCodeLength = 6;

  const confirmAccountAndSignIn = async (code: string) => {
    if (code.length !== fullCodeLength) {
      return;
    }
    setLoading(true);
    try {
      await Auth.confirmSignUp(email.toLowerCase(), code);
      clearEmailVerificationData();
      // FJS: Add a waiting time to avoid the organization not to be populated in OpenSearch when logging in
      // This avoid the user seeing the plan selection page with errors because the organization is not yet populated
      await wait(4000);
      await login(email.toLowerCase(), password);
    } catch (error) {
      console.error(error);
      setConfirmationError(error);
      setLoading(false);
    }
  };

  const VerifyCodeInput = () => (
    <Fragment>
      <Typography align="center">{t("verifyAccount.enterCode")}</Typography>
      <Stack component="main" justifyContent={"center"}>
        {loading ? <CircularProgress /> : <CodeInput length={fullCodeLength} onComplete={confirmAccountAndSignIn} />}
      </Stack>
      {error && !loading && (
        <Typography variant="caption" color="error">
          {(error as { message: string }).message}
        </Typography>
      )}
    </Fragment>
  );

  const ResendCodeButton = () => (
    <Typography
      sx={{ display: "flex", alignItems: "baseline", gap: "4px" }}
      fontSize={12}
      color={TakeTurnsColors.darkGray}
    >
      {t("verifyAccount.didNotReceiveCode")}
      <TakeTurnsTextButton fontSize="12px" onClick={resendVerificationCode}>
        {t("verifyAccount.resendCode")}
      </TakeTurnsTextButton>
    </Typography>
  );

  return (
    <Fragment>
      <VerifyCodeInput />
      <ResendCodeButton />
    </Fragment>
  );
};
