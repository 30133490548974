/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useSetRecoilState } from "recoil";
import { attachmentByIdState } from "../../../recoil/collaborationCreation/attachmentByIdState";

export const useMarkAttachmentForReview = (attachmentId: string) => {
  const setMarkAttachmentForReview = useSetRecoilState(attachmentByIdState(attachmentId));
  return () => {
    setMarkAttachmentForReview((attachment) => ({ ...attachment, markedForReview: true }));
  };
};
