/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { CloseCollaborationInput } from "@taketurns/taketurns-graphql-repository";
import { useCollaborationTranslation } from "@taketurns-i18n/collaboration/useCollaborationTranslation";
import { useCloseCollaboration } from "@taketurns-repositories/collaboration/graphql/mutations/useCloseCollaboration";
import { GET_FOLDER } from "@taketurns-repositories/collaboration/graphql/queries/folder/useFetchFolder";
import { useBrowseContentRule } from "@taketurns-rules/collaboration/commands/collaborationView/useBrowseContentRule";
import { wait } from "@taketurns-rules/commons/util/wait";

export const useCloseCollaborationByIdRule = () => {
  const [closeMutation, closeMutationResult] = useCloseCollaboration();
  const { enqueueSnackbar } = useSnackbar();

  const { currentFolderId } = useBrowseContentRule();

  const { t } = useCollaborationTranslation("notifications");

  useEffect(() => {
    if (closeMutationResult.error) {
      enqueueSnackbar(t("error"), {
        variant: "error",
      });
      console.error(closeMutationResult.error);
      closeMutationResult.reset();
    }
  }, [closeMutationResult, enqueueSnackbar, t]);

  const closeCollaborationByIdCommand = (input: CloseCollaborationInput, onComplete?: () => void) => {
    closeMutation({
      variables: { input },
      onCompleted: (data: { closeCollaboration: { name: string } }) => {
        enqueueSnackbar(t("collaboration.closed", { name: data.closeCollaboration.name }), {
          variant: "info",
        });
        onComplete();
      },
      refetchQueries: [
        { query: GET_FOLDER, variables: { collaborationId: input.collaborationId, folderId: currentFolderId } },
      ],
      onQueryUpdated: (observableQuery) => {
        wait(3000).then(() => observableQuery.refetch());
      },
    });
  };

  return {
    closeCollaborationByIdCommand,
    closeCollaborationError: closeMutationResult.error,
    closeCollaborationLoading: closeMutationResult.loading,
  };
};
