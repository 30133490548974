/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useSetRecoilState } from "recoil";
import { subscriptionRefreshCountState } from "@taketurns-repositories/webapp/state/recoil/subscriptionRefreshCountState";

export const useSetSubscriptionRefreshCount = () => {
  return useSetRecoilState(subscriptionRefreshCountState);
};
