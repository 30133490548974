/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useMutation } from "@apollo/client";
import { CollaborationUserContext, MutationAcceptInvitationArgs } from "@taketurns/taketurns-graphql-repository";
import { PENDING_INVITATIONS_OBSERVABLE_QUERY } from "@taketurns-repositories/collaboration/graphql/queries/participants/useFetchPendingInvitationsCount";
import { wait } from "@taketurns-rules/commons/util/wait";

export const useAcceptInvitation = () => {
  const ACCEPT_INVITATION_MUTATION = gql`
    mutation acceptInvitation($collaborationId: ID!) {
      acceptInvitation(collaborationId: $collaborationId) {
        id
        isInvitee
        isParticipant
      }
    }
  `;

  const [acceptInvitationMutation, { called }] = useMutation<
    { acceptInvitation: CollaborationUserContext },
    MutationAcceptInvitationArgs
  >(ACCEPT_INVITATION_MUTATION);
  const acceptInvitation = (collaborationId: string) => {
    if (!called) {
      acceptInvitationMutation({
        variables: { collaborationId },
        refetchQueries: [PENDING_INVITATIONS_OBSERVABLE_QUERY],
        onQueryUpdated: (observableQuery) => {
          wait(3000).then(() => observableQuery.refetch());
        },
      });
    }
  };
  return { acceptInvitation };
};
