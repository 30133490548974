/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  ServerError,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { Auth } from "aws-amplify";
import { AuthOptions, createAuthLink } from "aws-appsync-auth-link";
import { createSubscriptionHandshakeLink } from "aws-appsync-subscription-link";
import { useMemo } from "react";
import { typePolicies } from "@taketurns-repositories/webapp/aws/typePolicies";
import {
  generateXb3SampledHeader,
  generateXb3SpanIdHeader,
  generateXb3TraceIdHeader,
} from "../../../app/tracing/tracingUtils";
import { getEnvironmentVariable } from "../../../getEnvironmentVariable";

export const useApolloClient = () => {
  return useMemo(() => getOrCreateApolloClient(), []);
};

let apolloClient: ApolloClient<NormalizedCacheObject> | null = null;
const getOrCreateApolloClient = () => {
  const _apolloClient = apolloClient ? apolloClient : createApolloClient();
  if (!apolloClient) {
    apolloClient = _apolloClient;
  }
  return _apolloClient;
};

const createApolloClient = () => {
  return new ApolloClient({
    link: createApolloLink(),
    connectToDevTools: true,
    cache: new InMemoryCache({
      typePolicies,
    }),
  });
};

const createApolloLink = () => {
  // Prevent an issue from AppSync with extra attribute __typename
  const removeTypeNameLink = new ApolloLink((operation, forward) => {
    if (operation.variables) {
      const omitTypename = (key: string, value: string) => (key === "__typename" ? undefined : value);
      operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename);
    }
    return forward(operation).map((data) => data);
  });

  const addTraceIdHeaderLink = new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        ...generateXb3TraceIdHeader(),
        ...generateXb3SpanIdHeader(),
        ...generateXb3SampledHeader(),
      },
    }));
    return forward(operation);
  });

  const redirectOn401Link = onError(({ networkError }) => {
    const UNAUTHORIZED_ERROR = 401;

    if (networkError) {
      const serverError = networkError as ServerError;
      if (serverError.statusCode === UNAUTHORIZED_ERROR) {
        //TODO replace with our router system
        //Router.push(SIGN_IN);
      }
    }
  });

  const url = getEnvironmentVariable("APP_SYNC_GRAPHQL_ENDPOINT");
  const region = getEnvironmentVariable("APP_SYNC_REGION");
  const authOptions: AuthOptions = {
    type: getEnvironmentVariable("APP_SYNC_AUTHENTICATION_TYPE") as "AMAZON_COGNITO_USER_POOLS",
    jwtToken: async () => {
      try {
        const currentSession = await Auth.currentSession();
        if (currentSession) {
          return currentSession.getIdToken().getJwtToken();
        }
        return "";
      } catch (error) {
        console.error(error);
      }
      return "";
    },
  };
  const httpLink = createHttpLink({
    uri: url,
  });

  return ApolloLink.from([
    removeTypeNameLink,
    addTraceIdHeaderLink,
    redirectOn401Link,
    createAuthLink({ url, region, auth: authOptions }),
    createSubscriptionHandshakeLink({ url, region, auth: authOptions }, httpLink),
  ]);
};
