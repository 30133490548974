/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useCallback, useState } from "react";

export const useMoveDestinationDialogRule = () => {
  const [isMoveDestinationDialogOpen, setIsMoveDestinationDialogOpen] = useState(false);

  const openMoveDestinationDialog = useCallback(() => {
    setIsMoveDestinationDialogOpen(true);
  }, []);
  const closeMoveDestinationDialog = useCallback(() => {
    setIsMoveDestinationDialogOpen(false);
  }, []);

  return {
    isMoveDestinationDialogOpen,
    openMoveDestinationDialog,
    closeMoveDestinationDialog,
  };
};
