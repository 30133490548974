/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useMutation } from "@apollo/client";
import {
  Attachment,
  MutationRestoreAttachmentArgs,
  RestoreAttachmentInput,
  RestoreAttachmentOutput,
} from "@taketurns/taketurns-graphql-repository";
import { attachmentFragment } from "../../../fragments/attachmentFragment";

export const restoreAttachmentMutationFragment = gql`
  fragment RestoreAttachmentMutationFragment on RestoreAttachmentOutput {
    collaborationId
    partyId
    folderId
    folder {
      id
      attachments {
        ...AttachmentFragment
      }
      attachmentCount
      attachmentToReviewCount
      folderCount
      updateCount
    }
    contentMetadata {
      id
      hasPendingUpdates
      attachmentToReviewCount
    }
  }
  ${attachmentFragment}
`;

export const useRestoreAttachment = (collaborationId: string, attachment: Attachment) => {
  const RESTORE_ATTACHMENT = gql`
    ${restoreAttachmentMutationFragment}
    mutation restoreAttachment($input: RestoreAttachmentInput!) {
      restoreAttachment(input: $input) {
        ...RestoreAttachmentMutationFragment
      }
    }
  `;

  const [restoreMutation, { data, loading, error }] = useMutation<
    { restoreAttachment: RestoreAttachmentOutput },
    MutationRestoreAttachmentArgs
  >(RESTORE_ATTACHMENT);

  const restoreAttachmentMutation = () => {
    const input: RestoreAttachmentInput = {
      collaborationId,
      attachmentId: attachment.id,
    };
    return restoreMutation({ variables: { input } });
  };

  return {
    restoreAttachmentMutation,
    restoreAttachmentResponseData: data?.restoreAttachment,
    restoreAttachmentLoading: loading,
    errorOnRestoringAttachment: error,
  };
};
