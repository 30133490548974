/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useQuery } from "@apollo/client";
import { Document } from "@taketurns/taketurns-graphql-repository";

const GET_DOCUMENT = gql`
  query document($collaborationId: ID!, $documentId: ID!) {
    document(collaborationId: $collaborationId, documentId: $documentId) {
      id
      name
      description
      extension
      size
    }
  }
`;

export const useFetchDocument = (collaborationId: string, documentId: string) => {
  const {
    data,
    error: errorOnFetchingDocument,
    loading: loadingDocument,
  } = useQuery<{ document: Document }>(GET_DOCUMENT, { variables: { collaborationId, documentId } });
  return { document: data?.document, errorOnFetchingDocument, loadingDocument };
};
