/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { tips } from "../../commons/fr/tips";
import collaborationDocument from "./collaboration-document.json";
import collaborationEdit from "./collaboration-edit.json";
import collaborationExclusion from "./collaboration-exclusion.json";
import collaborationView from "./collaboration-view.json";
import durationLabels from "./duration-labels.json";
import notifications from "./notifications.json";
import participantCollaboration from "./participant-collaboration.json";

export const TranslationsFR = {
  participantCollaboration,
  collaborationView,
  collaborationDocument,
  notifications,
  collaborationEdit,
  collaborationExclusion,
  durationLabels,
  tips,
};
