/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { CircularProgress, Divider, Stack, Typography } from "@mui/material";
import { Fragment } from "react";
import { useLocation } from "react-router-dom";
import { TakeTurnsTextButton } from "@taketurns-components/commons/button";
import { LinkStyled } from "@taketurns-components/webapp/LinkStyled";
import { useWebAppTranslations } from "@taketurns-i18n/webapp/useWebAppTranslations";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { useDecodeParamsInCurrentUrlRule } from "@taketurns-rules/webapp/queries/useDecodeParamsInCurrentUrlRule";
import { useAuthContext, USER_NOT_CONFIRMED_ERROR_CODE } from "../../../../routing/AuthProvider";
import { AccessMode } from "../../../../routing/guards/RedirectFromParamsGuard";
import { PUBLIC_ROUTES } from "../../../../routing/routes/publicRoutes.constants";
import { CodeInput } from "../../components/CodeInput";
import { PasswordlessCollaborationAccessParameters } from "../PasswordlessCollaborationAccess";
import { SendCodeSection } from "./SendCodeSection";
import { SignupAndTosSection } from "./SignupAndTosSection";

export const SendOrVerifyCodeSection = () => {
  const params = useDecodeParamsInCurrentUrlRule<
    PasswordlessCollaborationAccessParameters & { accessMode: AccessMode }
  >()!;
  const {
    passwordLessProcess: {
      authenticationCodeSent,
      loginWithAuthenticationCode,
      createAccountOrUseExistingAndSendAuthenticationCode,
      resendAuthenticationCode,
      loading,
      error,
    },
  } = useAuthContext();
  const { search } = useLocation();

  const resendCode = async () => await resendAuthenticationCode(params.recipient.email);
  const verifyAccount = async () => await createAccountOrUseExistingAndSendAuthenticationCode(params.recipient.email);

  const { t } = useWebAppTranslations("passwordlessCollaborationAccess");
  if (error && (error as { code: string }).code === USER_NOT_CONFIRMED_ERROR_CODE) {
    return (
      <Stack direction="row" spacing={8} flex={1} alignItems="flex-start" justifyContent="flex-start">
        <Typography>
          {t("userNotConfirmed.begin")}{" "}
          <LinkStyled to={PUBLIC_ROUTES.SIGN_IN + search}>{t("userNotConfirmed.signInLink")}</LinkStyled>{" "}
          {t("userNotConfirmed.end")}
        </Typography>
      </Stack>
    );
  }
  if (error) {
    return (
      <Stack spacing={8} flex={1} alignItems="center" justifyContent="flex-start">
        <Typography>{t("errorTryAgainLater")}</Typography>
      </Stack>
    );
  }

  if (authenticationCodeSent) {
    return (
      <VerifyCodeSection
        recipientEmail={params.recipient.email}
        verifyCode={loginWithAuthenticationCode}
        resendCode={resendCode}
        loading={loading}
      />
    );
  }
  return (
    <Fragment>
      <SendCodeSection recipientEmail={params.recipient.email} verifyAccount={verifyAccount} loading={loading} />
      <Divider sx={{ width: "100%" }} />
      <SignupAndTosSection />
    </Fragment>
  );
};

const VerifyCodeSection = ({
  verifyCode,
  recipientEmail,
  resendCode,
  loading,
}: {
  verifyCode: (verificationCode: string) => void;
  recipientEmail: string;
  resendCode: () => void;
  loading: boolean;
}) => {
  const { t } = useWebAppTranslations("passwordless-signin");

  const VERIFICATION_CODE_LENGTH = 6;

  return (
    <Stack spacing={8} flex={1} alignItems="center" justifyContent="flex-start">
      <Typography variant="body1">
        {t("enterVerificationCode.begin")}{" "}
        <Typography component={"span"} color={TakeTurnsColors.darkBlue} fontWeight={600}>
          {recipientEmail}
        </Typography>{" "}
        {t("enterVerificationCode.end")}
      </Typography>
      <Stack direction="row" justifyContent={"center"} spacing={2}>
        {loading ? <CircularProgress /> : <CodeInput length={VERIFICATION_CODE_LENGTH} onComplete={verifyCode} />}
      </Stack>
      <Typography
        sx={{ display: "flex", alignItems: "baseline", gap: "4px" }}
        fontSize={12}
        color={TakeTurnsColors.darkGray}
      >
        {t("resendCode.didNotReceiveCode")}
        <TakeTurnsTextButton fontSize="12px" onClick={resendCode}>
          {t("resendCode.resendCodeLink")}
        </TakeTurnsTextButton>
      </Typography>
    </Stack>
  );
};
