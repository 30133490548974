/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Divider, Skeleton, Stack, Typography } from "@mui/material";
import { Fragment } from "react";
import { LabelWithEditButton } from "@taketurns-components/commons/form/LabelWithEditButton";
import { IconAiAssistant } from "@taketurns-components/commons/icons";
import { FormListItem } from "@taketurns-components/user/shared/FormListItem";
import { ExternalLinkStyled } from "@taketurns-components/webapp/LinkStyled";
import { useUserSharedTranslation } from "@taketurns-i18n/user/useUserSharedTranslation";
import { useModifyChatGptApiKey } from "@taketurns-repositories/user/graphql/mutations/useModifyChatGptApiKey";
import { useFetchChatGptApiKey } from "@taketurns-repositories/user/graphql/queries/useFetchChatGptApiKey";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { useGetHelpCenterUrls } from "@taketurns-rules/webapp/queries/useGetHelpCenterUrls";

export const IntegrationSettings = () => {
  return (
    <Fragment>
      <PageHeader />
      <AiAssistantSection />
    </Fragment>
  );
};

const PageHeader = () => {
  const { t } = useUserSharedTranslation();
  return (
    <Typography sx={{ mb: 3 }} fontSize={16} data-cy="integration-settings-title">
      {t("IntegrationSettings.sectionTitle")}
    </Typography>
  );
};

const AiAssistantSection = () => {
  return (
    <Fragment>
      <AiAssistantSectionHeader />
      <Divider />
      <ChatGPTSection />
    </Fragment>
  );
};

const AiAssistantSectionHeader = () => {
  const { t } = useUserSharedTranslation();
  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between" flexWrap="wrap" paddingBottom={2}>
      <Stack direction="row" alignItems="center" gap={1}>
        <IconAiAssistant size={24} color={TakeTurnsColors.aiAssistant} />
        <Typography color={TakeTurnsColors.aiAssistant}>AI Assistant</Typography>
      </Stack>
      <Typography variant="caption" lineHeight="1.3rem">
        {t("IntegrationSettings.AiAssistant.description")}
      </Typography>
    </Stack>
  );
};

const ChatGPTSection = () => {
  const { t } = useUserSharedTranslation();
  const { chatGptApiKey, loading, modifyChatGptApiKey } = useChatGptSectionDataRule();
  const { chatGptUrl } = useGetHelpCenterUrls();
  return (
    <FormListItem label={"ChatGPT"}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        flex={1}
        flexWrap="wrap"
        data-cy="chatGPTField__container"
        spacing={{ xs: 1, md: 0 }}
      >
        {loading && <Skeleton width={200} />}
        {!loading && (
          <LabelWithEditButton
            maxLength={500}
            currentLabel={chatGptApiKey}
            onEditEnded={modifyChatGptApiKey}
            hideValuePart
          />
        )}
        <Typography variant="caption" lineHeight="1.3rem">
          {t("IntegrationSettings.ChatGPTSection.description") + " "}
          <ExternalLinkStyled target="_blank" href={chatGptUrl}>
            {t("IntegrationSettings.ChatGPTSection.descriptionLink")}
          </ExternalLinkStyled>
        </Typography>
      </Stack>
    </FormListItem>
  );
};

const useChatGptSectionDataRule = () => {
  const { chatGptApiKey, loading } = useFetchChatGptApiKey();
  const { modifyChatGptApiKey } = useModifyChatGptApiKey();

  return {
    chatGptApiKey,
    loading,
    modifyChatGptApiKey,
  };
};
