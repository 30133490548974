/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useWebAppTranslations } from "@taketurns-i18n/webapp/useWebAppTranslations";
import { HelpCenterSections } from "@taketurns-rules/commons/enum/helpCenterSectionsEnum";
import { useOpenHelpCenterSection } from "@taketurns-rules/webapp/commands/useOpenHelpCenterSection";
import { TakeTurnsTextButton } from "../commons/button";

interface HelpCenterTextButtonProps {
  helpCenterSection: HelpCenterSections;
  fontSize?: string;
  label?: string;
}

export const HelpCenterTextButton = (props: HelpCenterTextButtonProps) => {
  const { fontSize, label } = props;
  const { openHelpCenterSection } = useOpenHelpCenterSection(props.helpCenterSection);
  const { t } = useWebAppTranslations("helpSection");
  return (
    <TakeTurnsTextButton fontSize={fontSize} onClick={openHelpCenterSection}>
      {label ?? t("needHelp")}
    </TakeTurnsTextButton>
  );
};
