/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Button, Skeleton } from "@mui/material";
import { Dispatch, Fragment, SetStateAction, useState } from "react";
import { useFetchParticipantsAndInvitationsRule } from "@taketurns-rules/collaboration/queries/collaborationView/useFetchParticipantsAndInvitationsRule";
import { useSubscribeToParticipantsAndInvitationsChangedRule } from "@taketurns-rules/collaboration/subscriptions/useSubscribeToParticipantsAndInvitationsChangedRule";
import { useIsOnMobileRule } from "@taketurns-rules/commons/theme/useIsOnMobileRule";
import { IconUsers } from "../../../../../commons/icons";
import { ParticipantsDialog } from "./DesktopParticipants/ParticipantsDialog";
import { MobileParticipantsPage } from "./MobileParticipants/MobileParticipantsPage";

export const Participants = () => {
  const [isOpen, setOpen] = useState(false);

  const isOnMobile = useIsOnMobileRule();
  const ParticipantsList = isOnMobile ? MobileParticipantsPage : ParticipantsDialog;

  return (
    <Fragment>
      <ParticipantsButton setOpen={setOpen} />
      <ParticipantsList isOpen={isOpen} closeDialog={() => setOpen(false)} />
    </Fragment>
  );
};

const ParticipantsButton = ({ setOpen }: { setOpen: Dispatch<SetStateAction<boolean>> }) => {
  const { participants } = useFetchParticipantsAndInvitationsRule();
  useSubscribeToParticipantsAndInvitationsChangedRule();

  if (!participants) {
    return <Skeleton width={35} />;
  }

  return (
    <Button
      variant={"text"}
      sx={{ padding: 0, minWidth: "auto", "& .MuiButton-startIcon": { marginLeft: 0 } }}
      onClick={() => setOpen(true)}
      startIcon={<IconUsers size={24} />}
    >
      {participants.length}
    </Button>
  );
};
