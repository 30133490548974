/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useSetCollaborationFormOwningPartyNameFieldError } from "@taketurns-repositories/collaboration/state/write/collaborationCreation/form/error/useSetCollaborationFormOwningPartyNameFieldError";
import { useGetCollaborationFormOwningPartyNameRule } from "@taketurns-rules/collaboration/queries/collaborationCreation/form/useGetCollaborationFormOwningPartyNameRule";

export const useValidateCollaborationOwningPartyNameRule = () => {
  const owningPartyName = useGetCollaborationFormOwningPartyNameRule();
  const setCollaborationFormOwningPartyNameFieldError = useSetCollaborationFormOwningPartyNameFieldError();

  return () => {
    const isNameValid = owningPartyName.length > 0;
    setCollaborationFormOwningPartyNameFieldError(!isNameValid);
    return isNameValid;
  };
};
