/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { UploadStatus } from "./uploadStatus";

export type CancelUploadFunction = () => Promise<boolean>;

export class Upload {
  status: UploadStatus = UploadStatus.pending;
  progress = 0;
  cancelUploadFunction?: CancelUploadFunction;

  constructor(
    public uploadId: number,
    public documentId: string,
    public documentName: string,
    public isUploadedFromPdfViewer?: boolean,
    public startFromPdfViewer?: (uploadId: number) => Promise<string>,
    public file?: File,
  ) {}

  copy(): Upload {
    const copy: Upload = new Upload(
      this.uploadId,
      this.documentId,
      this.documentName,
      this.isUploadedFromPdfViewer,
      this.startFromPdfViewer,
      this.file,
    );
    copy.status = this.status;
    copy.progress = this.progress;
    copy.cancelUploadFunction = this.cancelUploadFunction;
    return copy;
  }

  isComplete(): boolean {
    return this.status === UploadStatus.complete;
  }

  hasFailed(): boolean {
    return this.status === UploadStatus.failed;
  }

  isInProgress(): boolean {
    return this.status === UploadStatus.inProgress;
  }

  isPending(): boolean {
    return this.status === UploadStatus.pending;
  }

  isCancelled(): boolean {
    return this.status === UploadStatus.cancelled;
  }
}
