/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Box, styled } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { OrganizationInvitation } from "@taketurns/taketurns-graphql-repository";
import { OrganizationMemberAndInvitationComponentProps } from "@taketurns-components/user/Organization/OrganizationMemberAndInvitationList/shared/SharedComponentTypes";
import { useMUIDataGridTranslations } from "@taketurns-i18n/MUIDataGrid/useMUIDataGridTranslations";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import {
  OrganizationMemberAndInvitationDataGridColumns,
  organizationMembersAndInvitationsDataGridMaxWidth,
} from "./OrganizationMemberAndInvitationDataGridColumns";

export const DesktopOrganizationMemberAndInvitationGrid = ({
  membersAndInvitations,
  loading,
  connectedUserId,
}: OrganizationMemberAndInvitationComponentProps) => {
  const dataGridTranslation = useMUIDataGridTranslations();
  return (
    <Box width={1} maxWidth={organizationMembersAndInvitationsDataGridMaxWidth}>
      <CustomStyledDataGrid
        disableRowSelectionOnClick
        hideFooterPagination
        hideFooterSelectedRowCount
        hideFooter
        disableColumnSelector
        localeText={dataGridTranslation}
        getRowId={(organizationMemberOrInvitation) =>
          organizationMemberOrInvitation.userId
            ? organizationMemberOrInvitation.userId
            : (organizationMemberOrInvitation as OrganizationInvitation).email
        }
        loading={loading}
        isCellEditable={() => false}
        columns={OrganizationMemberAndInvitationDataGridColumns(connectedUserId)}
        rowHeight={50}
        rows={membersAndInvitations}
      />
    </Box>
  );
};

const CustomStyledDataGrid = styled(DataGrid)({
  border: 0,
  "& .MuiDataGrid-iconSeparator": {
    display: "none",
  },
  "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
    fontSize: 12,
    color: TakeTurnsColors.darkGray,
    ":focus": {
      outline: "none !important",
    },
    ":focus-within": {
      outline: "none",
    },
  },
  "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
    borderBottom: "1px solid #f0f0f0",
    fontSize: 14,
  },
  "& .MuiDataGrid-cell": {
    color: TakeTurnsColors.darkGray,
    ":hover": {
      cursor: "default",
    },
  },
  "& .MuiDataGrid-row:hover": {
    cursor: "default",
    backgroundColor: "transparent",
  },
  "& .textBlackColor": {
    color: "black",
  },
}) as typeof DataGrid;
