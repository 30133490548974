/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Typography } from "@mui/material";

export const MobileCollaborationRowName = ({ name }: { name: string }) => (
  <Typography variant="body2" flex={1} overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
    {name}
  </Typography>
);
