/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Skeleton, Stack, Typography } from "@mui/material";
import React, { PropsWithChildren } from "react";
import { FetchLoadingManager } from "@taketurns-app/utils/FetchLoadingManager";
import { AiAssistantNotConfigured } from "@taketurns-components/collaboration/CollaborationView/AttachmentDetails/components/DesktopAttachmentDetails/AttachmentAiAssistant/AiAssistantNotConfigured";
import { SomethingWentWrongRetryLater } from "@taketurns-components/shared/SomethingWentWrong";
import { useSharedCollaborationTranslation } from "@taketurns-i18n/collaboration/shared/useSharedCollaborationTranslation";
import { useFetchChatGptApiKey } from "@taketurns-repositories/user/graphql/queries/useFetchChatGptApiKey";
import { useFetchIsRevisionExtensionSupportedForSummaryOrCompare } from "@taketurns-rules/document/queries/useFetchIsRevisionExtensionSupportedForSummaryOrCompare";

interface AiAssistantContentProps {
  documentId: string;
  collaborationId: string;
  revisionId: string;
  shouldShowSummary?: boolean;
}

export const AiAssistantContent = (props: PropsWithChildren<AiAssistantContentProps>) => {
  const { t } = useSharedCollaborationTranslation();
  const { chatGptApiKey, loading, error } = useFetchAiAssistantContentDataRule();

  const { documentId, collaborationId, revisionId } = props;
  const { isExtensionSupportedForSummaryOrCompare, loadingDocumentRevisions, errorOnFetchingDocumentRevisions } =
    useFetchIsRevisionExtensionSupportedForSummaryOrCompare({ collaborationId, revisionId, documentId });

  if (error || errorOnFetchingDocumentRevisions) {
    return <SomethingWentWrongRetryLater />;
  }
  if (loading || loadingDocumentRevisions) {
    return <Skeleton width={"100%"} />;
  }
  if (!chatGptApiKey) {
    return <AiAssistantNotConfigured />;
  }
  if (!isExtensionSupportedForSummaryOrCompare) {
    return (
      <Stack padding={3} alignItems="center" data-cy="AiAssistantUnsupportedExtension">
        <Typography>{t(`AttachmentAiAssistant.errors.NOT_SUPPORTED`)}</Typography>
      </Stack>
    );
  }
  return <Stack spacing={3}>{props.children}</Stack>;
};

const useFetchAiAssistantContentDataRule = () => {
  const {
    chatGptApiKey,
    loading: loadingChatGptApiKey,
    error: errorWhileFetchingChatGptApiKey,
  } = useFetchChatGptApiKey();
  const fetchLoadingManager = new FetchLoadingManager([loadingChatGptApiKey], [errorWhileFetchingChatGptApiKey]);

  if (fetchLoadingManager.hasFetchInError()) {
    fetchLoadingManager.logErrors();
  }

  return {
    chatGptApiKey,
    loading: fetchLoadingManager.hasFetchLoading(),
    error: fetchLoadingManager.hasFetchInError(),
  };
};
