/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { AppBar, Box, IconButton, Stack, Toolbar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { CollaborationFilterType } from "@taketurns-components/collaboration/CollaborationList/collaborationFilterType";
import { PendingInvitationsNotification } from "@taketurns-components/collaboration/PendingInvitationsNotification/PendingInvitationsNotification";
import { UserNotificationCenter } from "@taketurns-components/user/UserNotificationCenter/UserNotificationCenter";
import { HelpCenterIconButton } from "@taketurns-components/webapp/HelpCenterIconButton";
import { collaborationListAppliedFilterState } from "@taketurns-repositories/collaboration/state/recoil/collaborationListAppliedFilterState";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { useGetHelpCenterSectionFromUserNavContext } from "@taketurns-rules/webapp/queries/useGetHelpCenterSectionFromUserNavContext";
import { IconMenu } from "../../../../../components/commons/icons";
import { AUTHENTICATED_ROUTES } from "../../../../routing/routes/authenticatedRoutes.constants";
import { AuthAppBarUserAvatar } from "./AuthAppBarUserAvatar";

interface AuthAppBarProps {
  handleClickOnMenuButton?: () => void;
  handleClickOnProfileButton?: () => void;
}

export const AuthAppBar = (props: AuthAppBarProps) => {
  const { handleClickOnMenuButton, handleClickOnProfileButton: openRightDrawerMenu } = props;
  const goToPendingInvitations = useGoToPendingInvitations();
  const goToHome = useGoToHome();

  const { getHelpCenterSectionFromUserNavContext } = useGetHelpCenterSectionFromUserNavContext();

  return (
    <AppBar position="static" sx={{ borderBottom: `1px solid ${TakeTurnsColors.borderColor}` }}>
      <Toolbar
        disableGutters
        sx={{
          "@media (max-width: 1500px)": { paddingLeft: "22px" },
          paddingLeft: "62px",
          paddingRight: "24px",
        }}
      >
        <IconButton onClick={handleClickOnMenuButton}>
          <IconMenu />
        </IconButton>
        <Box onClick={goToHome} sx={{ "&:hover": { cursor: "pointer" }, display: "flex" }}>
          <img width={126} height={27} src={"/images/logos/LogoImageWithText.svg"} alt="TakeTurns logo" />
        </Box>
        <Box paddingLeft={2}>
          <UserNotificationCenter />
        </Box>
        <Box style={{ flexGrow: 1 }} />
        <Stack direction="row" spacing={1} alignItems="center">
          <PendingInvitationsNotification onClick={goToPendingInvitations} />
          <HelpCenterIconButton size="large" helpCenterSection={getHelpCenterSectionFromUserNavContext()} />
          <AuthAppBarUserAvatar onClick={openRightDrawerMenu} />
        </Stack>
      </Toolbar>
    </AppBar>
  );
};

function useGoToPendingInvitations() {
  const navigate = useNavigate();
  const setCollaborationListAppliedFilter = useSetRecoilState(collaborationListAppliedFilterState);
  return () => {
    setCollaborationListAppliedFilter(CollaborationFilterType.pendingInvitations);
    navigate(AUTHENTICATED_ROUTES.COLLABORATION_LIST);
  };
}

function useGoToHome() {
  const navigate = useNavigate();
  return () => {
    navigate(AUTHENTICATED_ROUTES.COLLABORATION_LIST);
  };
}
