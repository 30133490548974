/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Avatar, Box, Typography } from "@mui/material";
import { PendingInvitationInvitingUser } from "@taketurns/taketurns-graphql-repository";
import { useCollaborationListTranslation } from "@taketurns-i18n/collaboration/CollaborationList/useCollaborationListTranslation";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";

interface InvitingUserInformationProps {
  invitingUser: PendingInvitationInvitingUser;
}

export const InvitingUserInformation = ({ invitingUser }: InvitingUserInformationProps) => {
  const { firstName, lastName, email, avatar, isInInviteeParty } = invitingUser;
  const color = isInInviteeParty ? TakeTurnsColors.connectedUser : TakeTurnsColors.otherParty;
  const { t } = useCollaborationListTranslation();
  const definedFirstNameAndLastName = firstName && lastName;
  const firstNameLastNameOrEmail = definedFirstNameAndLastName ? `${firstName} ${lastName}` : email;
  const userInitials = avatar ? undefined : definedFirstNameAndLastName ? firstName[0] + lastName[0] : null;

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "row",
        gap: 2,
      }}
    >
      <Avatar
        sx={{
          width: 30,
          height: 30,
          fontSize: 14,
        }}
        src={avatar}
      >
        {userInitials}
      </Avatar>
      <Box>
        <Typography variant="caption" sx={{ display: "inline" }} color={color}>
          {firstNameLastNameOrEmail}
        </Typography>{" "}
        <Typography variant="caption" color={TakeTurnsColors.darkGray} sx={{ display: "inline" }}>
          {t("invitesYou")}
        </Typography>
      </Box>
    </Box>
  );
};
