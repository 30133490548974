/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useIsComingFromInvitationToCollaborationLink } from "./useIsComingFromInvitationToCollaborationLink";

export const useShouldDisplayProfilePageRule = () => {
  const isComingFromInvitationToCollaborationLink = useIsComingFromInvitationToCollaborationLink();
  return (isUserFirstVisit: boolean) => {
    return !isComingFromInvitationToCollaborationLink && isUserFirstVisit;
  };
};
