/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { CollaborationStatus } from "@taketurns/taketurns-graphql-repository";
import { FetchLoadingManager } from "@taketurns-app/utils/FetchLoadingManager";
import {
  CollaborationCommand,
  useCollaborationPermissions,
} from "@taketurns-rules/collaboration/permissions/useCollaborationPermissions";
import { useGetDisplayedCollaborationIdRule } from "@taketurns-rules/collaboration/queries/collaboration/useGetDisplayedCollaborationIdRule";
import { useFetchConnectedUserPartyHasPendingUpdatesRule } from "@taketurns-rules/collaboration/queries/context/useFetchConnectedUserPartyHasPendingUpdatesRule";
import { useIsLastUpdateMadeByConnectedUserPartyRule } from "@taketurns-rules/collaboration/queries/context/useIsLastUpdateMadeByConnectedUserPartyRule";
import { useFetchCollaborationStatusRule } from "@taketurns-rules/collaboration/queries/fields/useFetchCollaborationStatusRule";

export const useFetchActionsPermissionsOnUpdatesRule = () => {
  const collaborationId = useGetDisplayedCollaborationIdRule();
  const {
    connectedUserPartyHasPendingUpdates,
    loadingConnectedUserPartyHasPendingUpdates,
    errorOnFetchingConnectedUserPartyHasPendingUpdates,
  } = useFetchConnectedUserPartyHasPendingUpdatesRule();
  const { status, loadingCollaborationStatus, errorOnFetchingCollaborationStatus } = useFetchCollaborationStatusRule();

  const {
    isLastUpdateMadeByConnectedUserParty,
    loadingIsLastUpdateMadeByConnectedUserParty,
    errorOnFetchingIsLastUpdateMadeByConnectedUserParty,
  } = useIsLastUpdateMadeByConnectedUserPartyRule();

  const fetchLoadingManager = new FetchLoadingManager(
    [
      loadingCollaborationStatus,
      loadingConnectedUserPartyHasPendingUpdates,
      loadingIsLastUpdateMadeByConnectedUserParty,
    ],
    [
      errorOnFetchingCollaborationStatus,
      errorOnFetchingConnectedUserPartyHasPendingUpdates,
      errorOnFetchingIsLastUpdateMadeByConnectedUserParty,
    ],
  );

  const hasUserRoleSendUpdatePermission = useCollaborationPermissions(
    CollaborationCommand.SEND_UPDATES,
    collaborationId,
  );
  return {
    canCancelPendingUpdates:
      status === CollaborationStatus.Open && hasUserRoleSendUpdatePermission && connectedUserPartyHasPendingUpdates,
    canSendPendingUpdates:
      status === CollaborationStatus.Open && hasUserRoleSendUpdatePermission && connectedUserPartyHasPendingUpdates,
    canRecallUpdates:
      status === CollaborationStatus.Open &&
      isLastUpdateMadeByConnectedUserParty &&
      !connectedUserPartyHasPendingUpdates,
    loadingActionsPermissionsOnUpdates: fetchLoadingManager.hasFetchLoading(),
    errorOnFetchingActionsPermissionsOnUpdates: fetchLoadingManager.getFirstError(),
  };
};
