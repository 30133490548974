/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { ReactNode } from "react";

interface DrawerItemProps {
  onClick: () => void;
  icon: ReactNode;
  label: string;
  "data-cy"?: string;
}

export const DrawerItem = (props: DrawerItemProps) => {
  return (
    <ListItemButton onClick={props.onClick} data-cy={props["data-cy"]}>
      <ListItemIcon sx={{ minWidth: "40px" }}>{props.icon}</ListItemIcon>
      <ListItemText primary={<Typography fontSize={14}>{props.label}</Typography>} />
    </ListItemButton>
  );
};
