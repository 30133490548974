/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Stack } from "@mui/material";
import { PropsWithChildren } from "react";
import { IconXCircle } from "@taketurns-components/commons/icons";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";

interface FilterToggleProps {
  color?: string;
  isToggled: boolean;
  toggle: () => void;
}

export const FilterToggle = (props: PropsWithChildren<FilterToggleProps>) => {
  const sxWhenToggled = {
    backgroundColor: props.color ?? TakeTurnsColors.lightBlue,
    color: TakeTurnsColors.white,
  };
  const sxWhenNotToggled = {
    backgroundColor: TakeTurnsColors.white,
    color: props.color ?? TakeTurnsColors.lightBlue,
  };
  const sxBasedOnToggleState = props.isToggled ? sxWhenToggled : sxWhenNotToggled;
  const sx = {
    borderRadius: "5px",
    border: "1px solid",
    lineHeight: "1.2em",
    py: "3px",
    px: "10px",
    cursor: "pointer",
    ...sxBasedOnToggleState,
  };
  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={1}
      sx={sx}
      onClick={props.toggle}
      fontSize={{ xs: "0.875rem", md: "1rem" }}
    >
      {props.children}
      {props.isToggled && <IconXCircle size={16} color={TakeTurnsColors.white} />}
    </Stack>
  );
};
