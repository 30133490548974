/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { PendingCreationAttachmentStatus } from "./pendingCreationAttachmentStatus";

export class PendingCreationAttachment {
  status: PendingCreationAttachmentStatus = PendingCreationAttachmentStatus.initiated;

  constructor(
    public documentId: string,
    public documentName: string,
    public documentExtension: string,
    public documentSize: number,
    public parentFolderPath: string,
    public uploadId: number,
  ) {}

  copy(): PendingCreationAttachment {
    const copy: PendingCreationAttachment = new PendingCreationAttachment(
      this.documentId,
      this.documentName,
      this.documentExtension,
      this.documentSize,
      this.parentFolderPath,
      this.uploadId,
    );
    copy.status = this.status;
    return copy;
  }

  isUploading(): boolean {
    return this.status === PendingCreationAttachmentStatus.uploading;
  }

  isAdded(): boolean {
    return this.status === PendingCreationAttachmentStatus.added;
  }

  isCancelled(): boolean {
    return this.status === PendingCreationAttachmentStatus.cancelled;
  }
}
