/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { ListItemButton, ListItemIcon, ListItemText, styled } from "@mui/material";
import { ReactNode } from "react";
import { UserSettingsSection } from "@taketurns-app/routing/routes/userSettingsSections";

interface SettingsListItemProps {
  icon: ReactNode;
  label: string;
  section: UserSettingsSection;
  active?: boolean;
  onClick: () => void;
}

export const SettingsListItem = (props: SettingsListItemProps) => {
  const { icon, label } = props;
  return (
    <SettingsListItemButton disableRipple divider {...props} data-cy={`SettingsListItem-${props.section}`}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={label} />
    </SettingsListItemButton>
  );
};

const SettingsListItemButton = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== "active",
})((props: SettingsListItemProps) => ({
  minHeight: 80,
  maxHeight: 80,
  padding: "0px 24px",
  backgroundColor: props.active ? "#f5f5f5" : "transparent",
}));
