/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Avatar,
  debounce,
  ListItem,
  ListItemAvatar,
  ListItemText,
  SxProps,
  Theme,
} from "@mui/material";
import { Fragment, ReactNode, useMemo } from "react";
import { Contact } from "@taketurns/taketurns-graphql-repository";
import { validateEmail } from "@taketurns-rules/commons/util/form/formUtils";
import { useFetchUserContactsRule } from "@taketurns-rules/user/queries/useFetchUserContactsRule";

export interface UserSearchAutoCompleteProps {
  renderInput: (params: AutocompleteRenderInputParams) => ReactNode;
  onContactChange: (contact: Contact) => void;
  disabledUserEmailsAndIds?: string[];
  sx?: SxProps<Theme>;
}

export const UserContactsAutoComplete = (props: UserSearchAutoCompleteProps) => {
  const { renderInput, onContactChange, disabledUserEmailsAndIds, sx } = props;
  const { errorOnSearchingUserContacts, searchUserContactsQuery, contacts } = useFetchUserContactsRule();
  if (errorOnSearchingUserContacts) {
    console.error(errorOnSearchingUserContacts);
  }

  const searchUserContactsOrSetInputValue = useMemo(
    () =>
      debounce(async (query) => {
        let retrievedContacts: Contact[] = [];
        if (query.trim().length !== 0) {
          retrievedContacts = await searchUserContactsQuery(query);
        }
        if (!validateEmail(query)) {
          onContactChange({ email: "" });
        } else {
          if (retrievedContacts.length === 0 || query !== retrievedContacts[0].email) {
            onContactChange({ email: query });
          } else if (query === retrievedContacts[0].email) {
            onContactChange(retrievedContacts[0]);
          }
        }
      }, 400),
    [],
  );
  const retrieveOrCreateContact = (contactOrInputValue: string | Contact | null) => {
    if (contactOrInputValue === null) {
      onContactChange({ email: "" });
    }
    if (typeof contactOrInputValue === "string") {
      onContactChange({ email: contactOrInputValue });
    } else {
      onContactChange(contactOrInputValue);
    }
  };

  //FilterOption is set according to MUI documentation for search as you type
  //https://mui.com/material-ui/react-autocomplete/#search-as-you-type
  return (
    <Autocomplete
      freeSolo
      selectOnFocus
      handleHomeEndKeys
      size={"small"}
      sx={sx}
      multiple={false}
      onChange={(_, contactOrInputValue: string | Contact | null) => {
        retrieveOrCreateContact(contactOrInputValue);
      }}
      getOptionDisabled={(contact) => {
        const isNewAddressEmail = typeof contact === "string";
        if (isNewAddressEmail) {
          return false;
        }
        return (
          disabledUserEmailsAndIds?.includes(contact.email) || disabledUserEmailsAndIds?.includes(contact.user?.id)
        );
      }}
      componentsProps={{ popper: { style: { width: "fit-content" }, placement: "bottom-start" } }}
      onInputChange={(_, searchValue) => searchUserContactsOrSetInputValue(searchValue)}
      filterOptions={(x) => x}
      getOptionLabel={contactOptionLabel}
      renderInput={renderInput}
      renderOption={(props, option) => (
        <ListItem {...props}>
          <ContactOption contact={option as Contact} />
        </ListItem>
      )}
      options={contacts}
    />
  );
};

function contactOptionLabel(contactOption: Contact | string): string {
  if (typeof contactOption === "string") {
    return contactOption;
  }
  return contactOption.email;
}

const ContactOption = ({ contact }: { contact: Contact }) => {
  const { user } = contact;
  let userEmailOrFirstNameAndLastName;
  if (user) {
    userEmailOrFirstNameAndLastName =
      user?.firstName && user?.lastName ? `${user.firstName} ${user.lastName}` : user?.email;
  } else {
    userEmailOrFirstNameAndLastName = contact.email;
  }

  return (
    <Fragment>
      <ListItemAvatar>
        <Avatar alt={contact.email} src={contact.user?.avatar} />
      </ListItemAvatar>
      <ListItemText
        primary={userEmailOrFirstNameAndLastName}
        secondary={contact.user ? contact.user.email : undefined}
      />
    </Fragment>
  );
};
