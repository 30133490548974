/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useEffect } from "react";
import {
  useMarkPendingAttachmentAsUploading,
} from "@taketurns-repositories/collaboration/state/write/pendingAttachment/useMarkPendingAttachmentAsUploading";
import { useGetRetriedUploadIds } from "@taketurns-repositories/document/state/read/useGetRetriedUploadIds";
import {
  useGetAllPendingAttachmentsRule,
} from "@taketurns-rules/collaboration/queries/attachment/pending/useGetAllPendingAttachmentsRule";
import { useAddAttachmentBulkRule } from "../useAddAttachmentBulkRule";

export const useListenToRetriedUploadsToMarkPendingAttachmentAsUploadingRule = () => {
  const retriedUploadIds = useGetRetriedUploadIds();
  const pendingAttachments = useGetAllPendingAttachmentsRule();
  const markPendingAttachmentAsUploading = useMarkPendingAttachmentAsUploading();
  const addAttachmentBulkRule = useAddAttachmentBulkRule();

  useEffect(() => {
    for (const retriedUploadId of retriedUploadIds) {
      const pendingAttachmentToUpdate = pendingAttachments.find((p) => p.uploadId === retriedUploadId);
      if (pendingAttachmentToUpdate && !pendingAttachmentToUpdate.isUploading()) {
        markPendingAttachmentAsUploading(pendingAttachmentToUpdate);
        addAttachmentBulkRule({ numberOfAttachments: 1, uploadIds: [retriedUploadId] });
      }
    }
  }, [retriedUploadIds]);
};
