/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useRecoilCallback } from "recoil";
import { attachmentByIdState } from "../../../recoil/collaborationCreation/attachmentByIdState";
import { folderByIdState } from "../../../recoil/collaborationCreation/folderByIdState";
import { parentFolderIdByAttachmentIdState } from "../../../recoil/collaborationCreation/parentFolderIdByAttachmentIdState";

export const useRemoveAttachmentInCreation = () => {
  return useRecoilCallback(
    ({ set, reset, snapshot }) =>
      (attachmentId: string) => {
        const targetFolderId = snapshot.getLoadable(parentFolderIdByAttachmentIdState(attachmentId)).contents as string;
        if (targetFolderId) {
          set(folderByIdState(targetFolderId), (targetFolder) => ({
            ...targetFolder,
            attachments: targetFolder.attachments.filter((id) => id !== attachmentId),
          }));
          reset(parentFolderIdByAttachmentIdState(attachmentId));
        }
        reset(attachmentByIdState(attachmentId));
      },
    [],
  );
};
