/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Box, styled, SwipeableDrawer, SxProps } from "@mui/material";
import { PropsWithChildren } from "react";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { useIsOnIOSRule } from "@taketurns-rules/commons/theme/useIsOnIOSRule";

interface MobileBottomSheetProps {
  setIsOpen: (isOpen: boolean) => void;
  isOpen: boolean;
  hidePuller?: boolean;
  sx?: SxProps;
}

export const MobileBottomSheet = ({
  setIsOpen,
  isOpen,
  children,
  hidePuller,
  sx,
}: PropsWithChildren<MobileBottomSheetProps>) => {
  const isOnIOS = useIsOnIOSRule();

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={isOpen}
      onClose={() => setIsOpen(false)}
      onOpen={() => setIsOpen(true)}
      disableBackdropTransition={!isOnIOS}
      disableDiscovery={true}
      disableSwipeToOpen={true}
      PaperProps={{
        sx: {
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
          paddingBottom: "env(safe-area-inset-bottom)",
          maxHeight: "80vh",
          ...sx,
        },
      }}
    >
      {!hidePuller && <MobileBottomSheet__Puller />}
      {children}
    </SwipeableDrawer>
  );
};

const MobileBottomSheet__Puller = styled(Box)({
  margin: "8px auto",
  backgroundColor: TakeTurnsColors.grayButton,
  borderRadius: 3,
  height: 6,
  width: 35,
});
