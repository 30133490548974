/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { ObservableQuery } from "@apollo/client";
import { wait } from "@taketurns-rules/commons/util/wait";

export const delayRefetchQueryDueToLatencyBetweenOpenSearchAndEvent = async (observableQuery: ObservableQuery) => {
  await wait(3000);
  await observableQuery.refetch();
};
