/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { PendingAttachmentStatus } from "./pendingAttachmentStatus";

// TODO FJS : Is there any inheritance between PendingAttachment, PendingAttachmentRevision and PendingCreationAttachment ?
export class PendingAttachment {
  requestName?: string;
  status: PendingAttachmentStatus = PendingAttachmentStatus.initiated;

  public documentId?: string;

  constructor(
    public collaborationId: string,
    public documentName: string,
    public documentExtension: string,
    public documentSize: number,
    public folderId: string,
    public uploadId: number,
  ) {}

  copy(): PendingAttachment {
    const copy: PendingAttachment = new PendingAttachment(
      this.collaborationId,
      this.documentName,
      this.documentExtension,
      this.documentSize,
      this.folderId,
      this.uploadId,
    );
    copy.requestName = this.requestName;
    copy.status = this.status;
    copy.documentId = this.documentId;
    return copy;
  }

  isUploading(): boolean {
    return this.status === PendingAttachmentStatus.uploading;
  }

  isAttaching(): boolean {
    return this.status === PendingAttachmentStatus.attaching;
  }

  isAttached(): boolean {
    return this.status === PendingAttachmentStatus.attached;
  }

  isCancelled(): boolean {
    return this.status === PendingAttachmentStatus.cancelled;
  }
}
