/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import {
  useFetchConnectedUserRoleRule,
} from "@taketurns-rules/collaboration/queries/context/useFetchConnectedUserRoleRule";

export const useCollaborationPermissions = (action: CollaborationCommand, collaborationId?: string): boolean => {
  const { connectedUserRole } = useFetchConnectedUserRoleRule(collaborationId);
  return !!(connectedUserRole && COLLABORATION_PERMISSION[connectedUserRole].includes(action));
};

export enum CollaborationCommand {
  SEND_UPDATES = "SEND_UPDATES",
  ADD_FILES = "ADD_FILES",
  REQUEST_FILES = "REQUEST_FILES",
  UPLOAD_REVISIONS = "UPLOAD_REVISIONS",
  CREATE_FOLDER = "CREATE_FOLDER",
  MANAGE_PARTICIPANTS = "MANAGE_PARTICIPANTS",
  EDIT_COLLABORATION = "EDIT_COLLABORATION",
  CLOSE_COLLABORATION = "CLOSE_COLLABORATION",
  REOPEN_COLLABORATION = "REOPEN_COLLABORATION",
  MARK_FOR_REVIEW = "MARK_FOR_REVIEW",
  RESEND_INVITATION = "RESEND_INVITATION",
  TURN_RECALL = "TURN_RECALL",
}

const COLLABORATION_PERMISSION: {
  [role: string]: CollaborationCommand[];
} = {
  owner: [
    CollaborationCommand.SEND_UPDATES,
    CollaborationCommand.ADD_FILES,
    CollaborationCommand.REQUEST_FILES,
    CollaborationCommand.UPLOAD_REVISIONS,
    CollaborationCommand.CREATE_FOLDER,
    CollaborationCommand.MANAGE_PARTICIPANTS,
    CollaborationCommand.EDIT_COLLABORATION,
    CollaborationCommand.CLOSE_COLLABORATION,
    CollaborationCommand.REOPEN_COLLABORATION,
    CollaborationCommand.MARK_FOR_REVIEW,
    CollaborationCommand.RESEND_INVITATION,
    CollaborationCommand.TURN_RECALL,
  ],
  follower: [],
  editor: [
    CollaborationCommand.ADD_FILES,
    CollaborationCommand.REQUEST_FILES,
    CollaborationCommand.UPLOAD_REVISIONS,
    CollaborationCommand.CREATE_FOLDER,
    CollaborationCommand.MARK_FOR_REVIEW,
  ],
  ghost: [],
};
