/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { TypedDocumentNode, useSubscription } from "@apollo/client";
import type { OperationVariables } from "@apollo/client/core";
import type { NoInfer, SubscriptionHookOptions } from "@apollo/client/react/types/types";
import type { DocumentNode } from "graphql/index";
import { DateTime } from "luxon";
import { MutableRefObject, useCallback, useEffect, useRef } from "react";
import { useApolloClient } from "@taketurns-repositories/webapp/aws/apolloClient";
import { useGetSubscriptionRefreshCount } from "@taketurns-repositories/webapp/state/read/useGetSubscriptionRefreshCount";
import { useSetSubscriptionRefreshCount } from "@taketurns-repositories/webapp/state/write/useSetSubscriptionRefreshCount";

const OFFLINE_TIMEOUT_IN_MIN = 0;
const VISIBILITY_TIMEOUT_IN_MIN = 0;

export const QueriesAndSubscriptionsRefreshTrigger = () => {
  const startTimer = (timerRef: MutableRefObject<DateTime | undefined>) => {
    timerRef.current = DateTime.now();
  };

  const setSubscriptionRefreshCount = useSetSubscriptionRefreshCount();
  const client = useApolloClient();
  const stopTimerAndEventuallyTriggerSubscriptionRefresh = useCallback(
    (timerRef: MutableRefObject<DateTime | undefined>, durationBeforeWarnInMin = 4) => {
      if (timerRef.current) {
        const elapsedTimeSinceTimerStartsInMin = DateTime.now().diff(timerRef.current, "minutes").minutes;
        const shouldTriggerRefresh = elapsedTimeSinceTimerStartsInMin > durationBeforeWarnInMin;
        if (shouldTriggerRefresh) {
          triggerSubscriptionRefresh();
          client.refetchQueries({ include: "active" });
        }
        timerRef.current = undefined;
      }

      function triggerSubscriptionRefresh() {
        setSubscriptionRefreshCount((count) => count + 1);
      }
    },
    [client, setSubscriptionRefreshCount],
  );

  const offlineTimeRef = useRef<DateTime | undefined>(undefined);
  useEffect(() => {
    const startOfflineTimer = () => {
      startTimer(offlineTimeRef);
    };
    window.addEventListener("offline", startOfflineTimer);
    return () => {
      window.removeEventListener("offline", startOfflineTimer);
    };
  }, []);
  useEffect(() => {
    const handleOnline = () => {
      stopTimerAndEventuallyTriggerSubscriptionRefresh(offlineTimeRef, OFFLINE_TIMEOUT_IN_MIN);
    };
    window.addEventListener("online", handleOnline);
    return () => {
      window.removeEventListener("online", handleOnline);
    };
  }, [setSubscriptionRefreshCount, stopTimerAndEventuallyTriggerSubscriptionRefresh]);

  const hiddenTimeRef = useRef<DateTime | undefined>(undefined);
  useEffect(() => {
    const visibilityChangeHandler = () => {
      if (document.hidden) {
        startTimer(hiddenTimeRef);
      } else {
        stopTimerAndEventuallyTriggerSubscriptionRefresh(hiddenTimeRef, VISIBILITY_TIMEOUT_IN_MIN);
      }
    };
    document.addEventListener("visibilitychange", visibilityChangeHandler);

    return () => {
      document.removeEventListener("visibilitychange", visibilityChangeHandler);
    };
  }, [setSubscriptionRefreshCount, stopTimerAndEventuallyTriggerSubscriptionRefresh]);

  return null;
};

export const useRefreshableSubscription = <TData = unknown, TVariables extends OperationVariables = OperationVariables>(
  subscription: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: SubscriptionHookOptions<NoInfer<TData>, NoInfer<TVariables>>,
) => {
  const subscriptionRefreshCount = useGetSubscriptionRefreshCount();

  return useSubscription<TData, TVariables>(subscription, {
    ...options,
    variables: { ...options.variables, subscriptionRefreshCount },
  });
};
