/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Stack, Typography } from "@mui/material";
import { useUserPlanTranslation } from "@taketurns-i18n/user/UserPlans/useUserPlanTranslation";
import { useIsOnMobileRule } from "@taketurns-rules/commons/theme/useIsOnMobileRule";
import { PaymentPeriodSwitch } from "../shared/PaymentPeriodSwitch";

export const PaidPlanSubscriptionPlansHeader = () => {
  const { t } = useUserPlanTranslation();
  const isOnMobile = useIsOnMobileRule();

  if (isOnMobile) {
    return (
      <Stack
        className="MobilePaidPlanSubscriptionPlansHeader"
        alignItems="center"
        justifyContent="space-between"
        spacing={2}
        px={2}
        pb={2}
        width="100%"
      >
        <Stack width="100%">
          <PaymentPeriodSwitch />
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack
      className="PaidPlanSubscriptionPlansHeader"
      direction="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <Typography variant="h6" align="center">
        {t("header.upgradeYourPlan")}
      </Typography>
      <Stack>
        <PaymentPeriodSwitch />
      </Stack>
    </Stack>
  );
};
