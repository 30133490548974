/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { styled, TextField } from "@mui/material";
import { Party } from "@taketurns/taketurns-graphql-repository";
import { useCollaborationCreationTranslation } from "@taketurns-i18n/collaboration/CollaborationCreation/useCollaborationCreationTranslation";
import { useSetCollaborationFormPartyNameByPartyRule } from "@taketurns-rules/collaboration/commands/collaborationCreation/form/useSetCollaborationFormPartyNameByPartyRule";
import { useGetCollaborationFormErrorsRule } from "@taketurns-rules/collaboration/queries/collaborationCreation/form/useGetCollaborationFormErrorsRule";
import { useGetCollaborationFormPartyNameByPartyRule } from "@taketurns-rules/collaboration/queries/collaborationCreation/form/useGetCollaborationFormPartyNameByPartyRule";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { useIsOnMobileRule } from "@taketurns-rules/commons/theme/useIsOnMobileRule";
import { withMaxLengthControl } from "@taketurns-rules/commons/util/form/formUtils";

export const InvitedOrganizationNameField = () => {
  const collaborationFormErrors = useGetCollaborationFormErrorsRule();
  const partyName = useGetCollaborationFormPartyNameByPartyRule()(Party.Invited);
  const setPartyName = useSetCollaborationFormPartyNameByPartyRule()(Party.Invited);

  const isOnMobile = useIsOnMobileRule();
  const { t } = useCollaborationCreationTranslation();
  const placeholderKey = isOnMobile ? "InvitedOrganizationNameField.shortPlaceholder" : "InvitedOrganizationNameField.placeholder";

  const isInputInErrorState = collaborationFormErrors.invitedPartyName;

  return (
    <OrganizationNameTextField
      variant="outlined"
      error={isInputInErrorState}
      placeholder={t(placeholderKey)}
      value={partyName}
      onChange={(event) => withMaxLengthControl(event, (newPartyName) => setPartyName(newPartyName))}
    />
  );
};

const OrganizationNameTextField = styled(TextField)(({ error }: { error: boolean }) => ({
  "& .MuiOutlinedInput-root": {
    color: TakeTurnsColors.otherParty,
    "& .MuiOutlinedInput-input::placeholder": {
      color: !error ? TakeTurnsColors.darkGray : TakeTurnsColors.error,
      opacity: 1,
    },
    "& fieldset": {
      borderColor: TakeTurnsColors.otherParty,
    },
    "&:hover fieldset": {
      borderColor: TakeTurnsColors.otherParty,
    },
    "&.Mui-focused fieldset": {
      borderColor: TakeTurnsColors.otherParty,
    },
  },
}));
