/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Badge, styled } from "@mui/material";
import { ReactNode } from "react";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";

interface UnreadMessagesBadgeProps {
  unreadCount: number;
  invisible?: boolean;
  children: ReactNode;
}

export const UnreadMessagesBadge = (props: UnreadMessagesBadgeProps) => {
  const { unreadCount, invisible, children } = props;

  return (
    <CustomBadge badgeContent={unreadCount} invisible={invisible}>
      {children}
    </CustomBadge>
  );
};

const CustomBadge = styled(Badge)({
  ".MuiBadge-badge": {
    backgroundColor: TakeTurnsColors.yellow,
    color: "white",
  },
});
