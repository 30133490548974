/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useMutation } from "@apollo/client";
import { collaborationParticipantsFragment } from "@taketurns-repositories/collaboration/graphql/fragments/collaborationParticipantsFragment";
import { ChangeInviteeRoleInput, MutationChangeInviteeRoleArgs, ParticipantsAndInvitations } from "@taketurns/taketurns-graphql-repository";

export const useChangeInvitationRole = () => {
  const mutation = gql`
    mutation changeInviteeRole($input: ChangeInviteeRoleInput!) {
      changeInviteeRole(input: $input) {
        ...CollaborationParticipantsFields
      }
    }
    ${collaborationParticipantsFragment}
  `;
  const [editInvitationRoleMutation, { error: errorOnEditInvitationRole, loading: loadingEditInvitationRole }] =
    useMutation<
      {
        changeInviteeRole: ParticipantsAndInvitations;
      },
      MutationChangeInviteeRoleArgs
    >(mutation);
  const editParticipantRoleCommand = (input: ChangeInviteeRoleInput, onRoleEditionSuccess?: () => void) => {
    editInvitationRoleMutation({
      variables: { input },
      onCompleted: onRoleEditionSuccess,
    });
  };

  return { editParticipantRoleCommand, errorOnEditInvitationRole, loadingEditInvitationRole };
};
