/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useQuery } from "@apollo/client";
import { ConnectedUser } from "@taketurns/taketurns-graphql-repository";

export const useFetchUserHasOrganizationAdminFeature = () => {
  const USER_FEATURE_ORGANIZATION_ADMINISTRATOR = gql`
    query userHasOrganizationAdministratorRole {
      connectedUser {
        id
        availableFeatures {
          organizationAdmin
        }
      }
    }
  `;
  const {
    data,
    error: errorOnHasOrganizationAdminFeature,
    loading: loadingHasOrganizationAdminFeature,
  } = useQuery<{
    connectedUser: ConnectedUser;
  }>(USER_FEATURE_ORGANIZATION_ADMINISTRATOR, { fetchPolicy: "cache-and-network" });
  return {
    userHasOrganizationAdminFeature: data?.connectedUser?.availableFeatures?.organizationAdmin ?? false,
    errorOnHasOrganizationAdminFeature,
    loadingHasOrganizationAdminFeature,
  };
};
