/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useFetchAvailableFeatures } from "./useFetchAvailableFeatures";

export const useFetchUserHasGhostFeature = () => {
  const { availableFeatures, loadingAvailableFeatures, errorOnFetchingAvailableFeatures } = useFetchAvailableFeatures();
  return {
    loadingUserHasGhostFeature: loadingAvailableFeatures,
    hasGhostFeature: availableFeatures?.ghost ?? false,
    errorOnFetchingActiveFeatures: errorOnFetchingAvailableFeatures,
  };
};
