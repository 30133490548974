/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Button, styled } from "@mui/material";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";

export const SubscribeToPlanButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "backgroundColor",
})((props: { backgroundColor?: string }) => ({
  backgroundColor: props.backgroundColor ?? TakeTurnsColors.lightBlue,
  color: "white",
  "&:hover": {
    opacity: "90%",
    backgroundColor: props.backgroundColor ?? TakeTurnsColors.lightBlue,
  },
  "&:focus": {
    backgroundColor: props.backgroundColor ?? TakeTurnsColors.lightBlue,
  },
  "&:disabled": {
    backgroundColor: TakeTurnsColors.lightGray,
  },
}));
