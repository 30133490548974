/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useRecoilCallback } from "recoil";
import { UploadStatus } from "../models/uploadStatus";
import { uploadsByUploadIdState } from "../recoil/uploadsByUploadIdState";

export const useMarkUploadAsFailed = () => {
  return useRecoilCallback(
    ({ set }) =>
      (uploadId: number) => {
        console.log(`Reporting failure in atom family uploadsByUploadIdState with id : ${uploadId}`);
        set(uploadsByUploadIdState(uploadId), (upload) => {
          if (upload) {
            const updatedUpload = upload.copy();
            updatedUpload.status = UploadStatus.failed;
            return updatedUpload;
          }
        });
      },
    [],
  );
};
