/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useFetchAvailableFeatures } from "./useFetchAvailableFeatures";

export const useFetchUserHasCreateCollaborationFeature = () => {
  const { availableFeatures, loadingAvailableFeatures, errorOnFetchingAvailableFeatures } = useFetchAvailableFeatures();
  return {
    hasCreateCollaborationFeature: availableFeatures?.createCollaboration ?? false,
    loadingCreateCollaborationUserFeature: loadingAvailableFeatures,
    errorOnFetchingCreateCollaborationUserFeature: errorOnFetchingAvailableFeatures,
  };
};
