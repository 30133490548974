/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { AuthenticationMethod } from "@taketurns/taketurns-graphql-repository";
import { useAddAuthenticationMethod } from "@taketurns-repositories/user/graphql/mutations/useAddAuthenticationMethod";
import { useDisplayAndLogNotificationErrorRule } from "./useDisplayAndLogNotificationErrorRule";

export const useAddAuthenticationMethodRule = () => {
  const {
    addAuthenticationMethod: addAuthenticationMethodCommand,
    loadingAddAuthenticationMethod,
    errorOnAddAuthenticationMethod,
  } = useAddAuthenticationMethod();

  const displayNotificationAndLogError = useDisplayAndLogNotificationErrorRule();

  const addAuthenticationMethod = (authenticationMethod: AuthenticationMethod) => {
    addAuthenticationMethodCommand({ authenticationMethod }).catch((error) => displayNotificationAndLogError(error));
  };
  return { addAuthenticationMethod, errorOnAddAuthenticationMethod, loadingAddAuthenticationMethod };
};
