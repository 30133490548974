/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Button, DialogActions, DialogContentText, IconButton } from "@mui/material";
import { Fragment, useState } from "react";
import { OrganizationInvitation } from "@taketurns/taketurns-graphql-repository";
import { TakeTurnsDefaultButton } from "@taketurns-components/commons/button";
import { ResponsiveDialog } from "@taketurns-components/commons/dialog/ResponsiveDialog";
import { IconX } from "@taketurns-components/commons/icons";
import { useOrganizationTranslation } from "@taketurns-i18n/user/Organization/useOrganizationTranslation";
import { useRevokeInvitationRule } from "@taketurns-rules/user/commands/useRevokeInvitationRule";

export const InvitationRevokeButtonAndDialog = ({ invitation }: { invitation: OrganizationInvitation }) => {
  const [isConfirmationDialogOpened, setIsConfirmationDialogOpened] = useState(false);

  const openInvitationRevokeConfirmationDialog = () => {
    setIsConfirmationDialogOpened(true);
  };

  const closeInvitationRevokeConfirmationDialog = () => {
    setIsConfirmationDialogOpened(false);
  };
  return (
    <Fragment>
      <IconButton onClick={openInvitationRevokeConfirmationDialog}>
        <IconX size={18} />
      </IconButton>
      <InvitationRevokeConfirmationDialog
        invitation={invitation}
        open={isConfirmationDialogOpened}
        onClose={closeInvitationRevokeConfirmationDialog}
      />
    </Fragment>
  );
};

const InvitationRevokeConfirmationDialog = ({
  invitation,
  open,
  onClose,
}: {
  invitation: OrganizationInvitation;
  open: boolean;
  onClose: () => void;
}) => {
  const { revokeOrganizationInvitation, loadingRevokeOrganizationInvitation } = useRevokeInvitationRule();

  const confirmRevokeInvitation = () => {
    revokeOrganizationInvitation(invitation);
  };

  const { t } = useOrganizationTranslation();

  const getUserFullNameOrEmail = () => {
    if (invitation.user?.firstName && invitation.user?.lastName) {
      return `${invitation.user.firstName} ${invitation.user.lastName}`;
    }
    return invitation.email;
  };
  return (
    <ResponsiveDialog
      open={open}
      onClose={onClose}
      title={t("InvitationRevokeButtonAndDialog.title")}
      content={
        <DialogContentText>
          {t("InvitationRevokeButtonAndDialog.description", { fullNameOrEmail: getUserFullNameOrEmail() })}
        </DialogContentText>
      }
      footer={
        <DialogActions>
          <Button variant={"text"} onClick={onClose}>
            {t("InvitationRevokeButtonAndDialog.cancel")}
          </Button>
          <TakeTurnsDefaultButton disabled={loadingRevokeOrganizationInvitation} onClick={confirmRevokeInvitation}>
            {t("InvitationRevokeButtonAndDialog.confirm")}
          </TakeTurnsDefaultButton>
        </DialogActions>
      }
    />
  );
};
