/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { selector, useRecoilValue } from "recoil";
import { pendingCreationAttachmentsState } from "../../recoil/collaborationCreation/pendingCreationAttachmentsState";

const selectUploadIdsForCollaborationCreationState = selector<number[]>({
  key: "selectUploadIdsForCollaborationCreationState",
  get: ({ get }) => {
    const pendingCreationAttachments = get(pendingCreationAttachmentsState);
    return pendingCreationAttachments.map((pendingCreationAttachment) => pendingCreationAttachment.uploadId);
  },
});
export const useGetUploadIdsForCollaborationCreation = () => {
  return useRecoilValue(selectUploadIdsForCollaborationCreationState);
};
