/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useMutation } from "@apollo/client";

export const useActivateDisplayedTips = () => {
  const mutation = gql`
    mutation activateDisplayedTips {
      activateDisplayedTips {
        id
        tipsHidden
        hiddenTips
      }
    }
  `;
  return useMutation(mutation);
};
