/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useIsRequestMadeByConnectedUserPartyRule } from "@taketurns-rules/collaboration/queries/context/useIsRequestMadeByConnectedUserPartyRule";
import { AttachmentCard } from "./AttachmentCard/AttachmentCard";
import { FolderCard } from "./FolderCard/FolderCard";
import { Card } from "./models/card";
import { CardType } from "./models/cardType";
import { RequestCard } from "./RequestCard/RequestCard";
import { RequestFulfillmentCard } from "./RequestCard/RequestFulFillmentCard";

export const GenericCard = ({ card }: { card: Card }) => {
  const isRequestMadeByConnectedUserPartyRule = useIsRequestMadeByConnectedUserPartyRule();

  if (card.type === CardType.folder) {
    return <FolderCard folderId={card.folderId} editable={card.editable} dropZoneConfig={card.dropZoneConfig} />;
  }

  if (card.type === CardType.attachment) {
    return <AttachmentCard attachmentId={card.attachmentId} editable={card.editable} />;
  }

  if (card.type === CardType.request) {
    const isRequestMadeByConnectedUserParty = isRequestMadeByConnectedUserPartyRule(card.request);
    if (isRequestMadeByConnectedUserParty) {
      return <RequestCard requestId={card.request.id} editable={card.editable} />;
    } else {
      return <RequestFulfillmentCard requestId={card.request.id} editable={card.editable} />;
    }
  }
};
