/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useMutation } from "@apollo/client";
import {
  ModifyRequestDescriptionInput,
  MutationModifyRequestDescriptionArgs,
} from "@taketurns/taketurns-graphql-repository";
import { requestFragment } from "../../../fragments/requestFragment";

export const requestEditMutationFragment = gql`
  ${requestFragment}
  fragment RequestEditMutationFragment on EditRequestOutput {
    collaborationId
    partyId
    requestId
    request {
      id
      name
      description
      status
    }
    contentMetadata {
      id
      hasPendingUpdates
    }
  }
`;
export const useModifyRequestDescription = (collaborationId: string, requestId: string) => {
  const MODIFY_REQUEST = gql`
    ${requestEditMutationFragment}
    mutation modifyRequestDescription($input: ModifyRequestDescriptionInput!) {
      modifyRequestDescription(input: $input) {
        ...RequestEditMutationFragment
      }
    }
  `;

  const [modifyRequestDescriptionMutation, { data, loading, error }] = useMutation<
    { modifyRequestDescription: Request },
    MutationModifyRequestDescriptionArgs
  >(MODIFY_REQUEST);
  const modifyRequestDescription = (newDescription: string) => {
    const input: ModifyRequestDescriptionInput = {
      collaborationId,
      requestId,
      newDescription,
    };
    return modifyRequestDescriptionMutation({ variables: { input } });
  };

  return {
    modifyRequestDescription,
    modifyRequestDescriptionResponseData: data?.modifyRequestDescription,
    modifyRequestDescriptionLoading: loading,
    errorOnModifyingRequestDescription: error,
  };
};
