/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Box, styled, SwipeableDrawer } from "@mui/material";
import { Fragment, memo, ReactNode, useState } from "react";
import { useIsOnIOSRule } from "@taketurns-rules/commons/theme/useIsOnIOSRule";
import { ApplicationDrawer } from "../authenticated/AuthenticatedLayout/components/ApplicationDrawer";
import { UserDrawer } from "../authenticated/AuthenticatedLayout/components/UserDrawer";
import { MobileBodyLayoutHeader } from "./components/MobileBodyLayoutHeader";
import { MobileMenuButton } from "./components/MobileTopButtons/MobileMenuButton";
import { MobileProfileButton } from "./components/MobileTopButtons/MobileProfileButton";

interface BodyLayoutProps {
  header: ReactNode;
  LeftButton?: ReactNode;
  content: ReactNode;
  RightButton?: ReactNode;
  disablePadding?: boolean;
}

//Using Memo to prevent re-rendering on drawer opens
export const MobileBodyLayout = memo(
  ({ LeftButton, content, header, RightButton, disablePadding }: BodyLayoutProps) => {
    const [openLeftDrawer, setOpenLeftDrawer] = useState(false);
    const [openRightDrawer, setOpenRightDrawer] = useState(false);
    const transitionDuration = 300;

    const isOnIOS = useIsOnIOSRule();

    const LeftComponent = LeftButton || <MobileMenuButton onClick={() => setOpenLeftDrawer(true)} />;
    const RightComponent = RightButton || <MobileProfileButton onClick={() => setOpenRightDrawer(true)} />;

    const Header = (
      <MobileBodyLayoutHeader leftComponent={LeftComponent} header={header} rightComponent={RightComponent} />
    );

    return (
      <Fragment>
        <BodyLayout__PageContainer className="MobileBodyLayout__PageContainer">
          {Header}
          <BodyLayout__InnerBox className="MobileBodyLayout__InnerBox" disablePadding={disablePadding}>
            {content}
          </BodyLayout__InnerBox>
        </BodyLayout__PageContainer>

        {!LeftButton && (
          <SwipeableDrawer
            transitionDuration={transitionDuration}
            anchor="left"
            open={openLeftDrawer}
            onOpen={() => setOpenLeftDrawer(true)}
            onClose={() => setOpenLeftDrawer(false)}
            disableBackdropTransition={!isOnIOS}
            disableDiscovery={isOnIOS}
          >
            <ApplicationDrawer onClose={() => setOpenLeftDrawer(false)} />
          </SwipeableDrawer>
        )}

        {!RightButton && (
          <SwipeableDrawer
            transitionDuration={transitionDuration}
            anchor="right"
            open={openRightDrawer}
            onOpen={() => setOpenRightDrawer(true)}
            onClose={() => setOpenRightDrawer(false)}
            disableBackdropTransition={!isOnIOS}
            disableDiscovery={isOnIOS}
          >
            <UserDrawer closeDrawer={() => setOpenRightDrawer(false)} />
          </SwipeableDrawer>
        )}
      </Fragment>
    );
  },
);

const BodyLayout__PageContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  width: "100%",
});

const BodyLayout__InnerBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "disablePadding",
})<{ disablePadding?: boolean }>(({ disablePadding }) => ({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  boxShadow: "0px 1px 20px 0px rgba(0, 0, 0, 0.05)",
  backgroundColor: "white",
  borderTopLeftRadius: 20,
  borderTopRightRadius: 20,
  padding: disablePadding ? 0 : 24,
  minHeight: 0,
  width: "100%",
  overflow: "auto",
}));
