/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useSetIsComingFromInvitationToCollaborationLinkRule } from "@taketurns-rules/webapp/commands/useSetIsComingFromInvitationToCollaborationLinkRule";

export const SetComingFromInvitationLinkGuard = ({ children }: { children: JSX.Element }) => {
  const setComingFromInvitationLink = useSetIsComingFromInvitationToCollaborationLinkRule();
  setComingFromInvitationLink();
  return children;
};
