/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { SUBSCRIPTION_PLAN_SESSION_STORAGE_KEY } from "../entities/selectedSubscriptionPlan";

export const getSelectedSubscriptionPlanFromSessionStorage = () => {
  const selectedPlan = sessionStorage.getItem(SUBSCRIPTION_PLAN_SESSION_STORAGE_KEY);
  if (selectedPlan?.length !== 0) {
    return selectedPlan;
  }
  return null;
};
