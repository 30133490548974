/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useSnackbar } from "notistack";
import {
  useSharedCollaborationTranslation,
} from "@taketurns-i18n/collaboration/shared/useSharedCollaborationTranslation";
import {
  useSubscribeToRecallUpdates,
} from "@taketurns-repositories/collaboration/graphql/subscriptions/updates/useSubscribeToRecallUpdates";
import {
  useGetDisplayedCollaborationIdRule,
} from "@taketurns-rules/collaboration/queries/collaboration/useGetDisplayedCollaborationIdRule";

export const useSubscribeToRecallUpdatesRule = (collaborationId?: string) => {
  const displayedCollaborationId = useGetDisplayedCollaborationIdRule();

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useSharedCollaborationTranslation();

  const displayUpdatesRecalledNotification = () => {
    enqueueSnackbar(t("RecallUpdates.snackbar"), { variant: "info" });
  };

  useSubscribeToRecallUpdates(collaborationId ?? displayedCollaborationId, displayUpdatesRecalledNotification);
};
