/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Request } from "@taketurns-repositories/collaboration/models/request";
import { useSetOpenedRequestFulfillmentRequest } from "@taketurns-repositories/collaboration/state/write/useSetOpenedRequestFulfillmentRequest";

export const useOpenRequestFulfillmentRule = (request: Request) => {
  const setOpenedRequestFulfillmentRequest = useSetOpenedRequestFulfillmentRequest();

  return () => {
    setOpenedRequestFulfillmentRequest(request);
  };
};
