/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useRecoilCallback } from "recoil";
import { DocumentInCreation } from "@taketurns-repositories/collaboration/models/documentInCreation";
import { documentByIdState } from "@taketurns-repositories/collaboration/state/recoil/collaborationCreation/documentByIdState";

export const useSetDocumentInCreation = () => {
  return useRecoilCallback(
    ({ set }) =>
      (document: DocumentInCreation) => {
        set(documentByIdState(document.id), document);
      },
    [],
  );
};
