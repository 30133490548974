/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Button, Stack, Typography } from "@mui/material";
import { Fragment, useState } from "react";
import { SubscriptionPlanTier } from "@taketurns/taketurns-graphql-repository";
import { FetchLoadingManager } from "@taketurns-app/utils/FetchLoadingManager";
import { useSharedCollaborationTranslation } from "@taketurns-i18n/collaboration/shared/useSharedCollaborationTranslation";
import { useFetchIsRecallUpdatesFeatureAvailable } from "@taketurns-repositories/collaboration/graphql/queries/features/useFetchIsRecallUpdatesFeatureAvailable";
import { useGetDisplayedCollaborationIdRule } from "@taketurns-rules/collaboration/queries/collaboration/useGetDisplayedCollaborationIdRule";
import { useFetchActionsPermissionsOnUpdatesRule } from "@taketurns-rules/collaboration/queries/collaborationView/useFetchActionsPermissionsOnUpdatesRule";
import { useFetchUserPlanTierRule } from "@taketurns-rules/collaboration/queries/useFetchUserPlanTierRule";
import { HelpCenterSections } from "@taketurns-rules/commons/enum/helpCenterSectionsEnum";
import { useIsOnMobileRule } from "@taketurns-rules/commons/theme/useIsOnMobileRule";
import { HelpCenterTextButton } from "../../../../../../old/../components/webapp/HelpCenterTextButton";
import { WithOpenUpgradePlanDialogOnClick } from "../../../../Shared/WithOpenUpgradePlanDialogOnClick";
import { RecallUpdatesDialog } from "./RecallUpdatesDialog";

export const RecallUpdatesButton = () => {
  const [isRecallTurnDialogOpen, setIsRecallTurnDialogOpen] = useState(false);
  const closeRecallTurnDialog = () => {
    setIsRecallTurnDialogOpen(false);
  };
  const openRecallTurnDialog = () => setIsRecallTurnDialogOpen(true);
  const { t } = useSharedCollaborationTranslation();

  const {
    collaborationId,
    planTier,
    isRecallUpdatesFeatureAvailable,
    errorOnFetchingUpdatesRecallFeatureAvailable,
    loading,
    error,
  } = useRecallUpdatesButtonDataRule();
  const isOnMobile = useIsOnMobileRule();
  if (error) {
    console.error(errorOnFetchingUpdatesRecallFeatureAvailable);
  }

  if (loading) {
    return null;
  }

  const TurnRecallButtonWithOpenUpgradePlanDialog =
    planTier === SubscriptionPlanTier.T0 || planTier === SubscriptionPlanTier.T1
      ? WithOpenUpgradePlanDialogOnClick(Button, {
          UpgradeToPlanDialogContentSlot: (
            <Stack spacing={1.5} alignItems={"flex-start"}>
              <Typography variant={"body2"}>
                {t("RecallUpdates.upgradeDialog.description")}{" "}
                <HelpCenterTextButton
                  label={t("RecallUpdates.upgradeDialog.learnMore")}
                  fontSize={"14px"}
                  helpCenterSection={HelpCenterSections.RECALL_UPDATES}
                />
              </Typography>
              <Typography variant={"body2"}>
                {t(`RecallUpdates.upgradeDialog.upgradeDescription.${planTier}`)}
              </Typography>
            </Stack>
          ),
          isFeatureAvailable: isRecallUpdatesFeatureAvailable,
        })
      : Button;

  return (
    <Fragment>
      <TurnRecallButtonWithOpenUpgradePlanDialog variant={"text"} onClick={openRecallTurnDialog}>
        {t(`RecallUpdates.button${isOnMobile ? "Short" : ""}`)}
      </TurnRecallButtonWithOpenUpgradePlanDialog>
      {isRecallUpdatesFeatureAvailable && (
        <RecallUpdatesDialog
          open={isRecallTurnDialogOpen}
          onClose={closeRecallTurnDialog}
          collaborationId={collaborationId}
        />
      )}
    </Fragment>
  );
};

function useRecallUpdatesButtonDataRule() {
  const collaborationId = useGetDisplayedCollaborationIdRule();
  const { planTier, errorOnFetchingUserPlanTier, loadingUserPlanTier } = useFetchUserPlanTierRule();

  const {
    isRecallUpdatesFeatureAvailable,
    loadingRecallUpdatesFeatureAvailable,
    errorOnFetchingUpdatesRecallFeatureAvailable,
  } = useFetchIsRecallUpdatesFeatureAvailable();

  const { canRecallUpdates, errorOnFetchingActionsPermissionsOnUpdates, loadingActionsPermissionsOnUpdates } =
    useFetchActionsPermissionsOnUpdatesRule();

  const fetchLoadingManager = new FetchLoadingManager(
    [loadingRecallUpdatesFeatureAvailable, loadingActionsPermissionsOnUpdates, loadingUserPlanTier],
    [
      errorOnFetchingUpdatesRecallFeatureAvailable,
      errorOnFetchingActionsPermissionsOnUpdates,
      errorOnFetchingUserPlanTier,
    ],
  );

  if (fetchLoadingManager.hasFetchInError()) {
    fetchLoadingManager.logErrors();
  }

  return {
    collaborationId,
    planTier,
    isRecallUpdatesFeatureAvailable,
    errorOnFetchingUpdatesRecallFeatureAvailable,
    canRecallUpdates,
    loading: fetchLoadingManager.hasFetchLoading(),
    error: fetchLoadingManager.hasFetchInError(),
  };
}
