/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useMutation } from "@apollo/client";
import { AddRequestInput, Folder, MutationAddRequestArgs } from "@taketurns/taketurns-graphql-repository";
import { requestFragment } from "../../../fragments/requestFragment";

export const AddRequestMutationFragment = gql`
  ${requestFragment}
  fragment AddRequestMutationFragment on AddRequestOutput {
    collaborationId
    partyId
    folderId
    folder {
      id
      requests {
        ...RequestFragment
      }
    }
    contentMetadata {
      id
      hasPendingUpdates
    }
  }
`;
export const useAddRequest = (collaborationId: string, folderId: string) => {
  const ADD_REQUEST = gql`
    ${AddRequestMutationFragment}
    mutation addRequest($input: AddRequestInput!) {
      addRequest(input: $input) {
        ...AddRequestMutationFragment
      }
    }
  `;

  const [addRequestMutation, { data, loading, error }] = useMutation<{ addRequest: Folder }, MutationAddRequestArgs>(
    ADD_REQUEST,
  );
  const addRequest = (name: string, description?: string) => {
    const input: AddRequestInput = {
      collaborationId,
      folderId,
      name,
      description,
    };
    return addRequestMutation({ variables: { input } });
  };

  return {
    addRequest,
    addRequestResponseData: data?.addRequest,
    addRequestLoading: loading,
    errorOnAddingRequest: error,
  };
};
