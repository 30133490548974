/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useRecoilValue } from "recoil";
import { contentSelectionState } from "@taketurns-repositories/collaboration/state/recoil/contentSelectionState";

export const useGetAttachmentSelectionStateById = (attachmentId: string) => {
  const contentSelectionStateValue = useRecoilValue(contentSelectionState);
  return contentSelectionStateValue.attachmentIds.includes(attachmentId);
};
