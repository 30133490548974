/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Request } from "@taketurns-repositories/collaboration/models/request";
import { useSetRequestFilesToAddOnRequestFulfillmentOpening } from "@taketurns-repositories/collaboration/state/write/requestFulfillment/useSetRequestFilesToAddOnRequestFulfillmentOpening";
import { useSetOpenedRequestFulfillmentRequest } from "@taketurns-repositories/collaboration/state/write/useSetOpenedRequestFulfillmentRequest";

export const useOpenRequestFulfillmentWithFilesRule = (request: Request) => {
  const setOpenedRequestFulfillmentRequest = useSetOpenedRequestFulfillmentRequest();
  const setRequestFilesToAddOnRequestFulfillmentOpening = useSetRequestFilesToAddOnRequestFulfillmentOpening();

  return (files: File[]) => {
    setRequestFilesToAddOnRequestFulfillmentOpening(files);
    return setOpenedRequestFulfillmentRequest(request);
  };
};
