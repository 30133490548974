/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { selector } from "recoil";
import { PendingAttachment } from "@taketurns-repositories/collaboration/models/pendingAttachment";
import { Upload } from "../../../document/state/models/upload";
import { uploadsByUploadIdState } from "../../../document/state/recoil/uploadsByUploadIdState";
import { selectAllPendingAttachmentsState } from "./selectAllPendingAttachmentsState";

export const selectPendingAttachmentsPendingUpload = selector<PendingAttachment[]>({
  key: "selectAllPendingAttachmentsPendingUpload",
  get: ({ get }) => {
    return get(selectAllPendingAttachmentsState).filter(
      (pendingAttachment) =>
        get<Upload>(uploadsByUploadIdState(pendingAttachment.uploadId)).isPending() && !pendingAttachment.isUploading(),
    );
  },
});
