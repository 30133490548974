/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Auth } from "aws-amplify";
import i18n from "@taketurns-i18n/i18n";
import { SignUpFormFields } from "../validation/useSignUpFormValidationRule";

export const useSignUpRule = () => {
  async function signUpCommand(userSignUpForm: SignUpFormFields) {
    const { email, password, name, family_name, function: jobFunction, job_title } = userSignUpForm;

    try {
      await Auth.signUp({
        username: email.toLowerCase(),
        password: password,
        attributes: {
          given_name: name,
          family_name: family_name,
          "custom:function": jobFunction,
          "custom:language": i18n.language,
          "custom:job_title": job_title,
        },
      });
    } catch (error) {
      const errorName = (error as { name: string }).name;
      if (errorName === "UsernameExistsException") {
        throw new Error("An account with the given email already exists. (error TT_10002)", error);
      }
      throw error;
    }
  }

  return signUpCommand;
};
