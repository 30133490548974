/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { styled } from "@mui/material";
import { Party } from "@taketurns/taketurns-graphql-repository";
import {
  Column,
  ColumnPaper,
  HeaderChip,
} from "@taketurns-app/pages/authenticated/CollaborationCreation/CollaborationCreationPages/commons";
import { CollaborationCreationPartyForm } from "@taketurns-components/collaboration/CollaborationCreation/CollaborationCreationPartyForm/CollaborationCreationPartyForm";
import { useWebAppTranslations } from "@taketurns-i18n/webapp/useWebAppTranslations";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { useIsOnMobileRule } from "@taketurns-rules/commons/theme/useIsOnMobileRule";

export const CollaborationCreationInvitedPartyColumn = () => {
  const isOnMobile = useIsOnMobileRule();
  const { t } = useWebAppTranslations("collaboration-creation");

  return (
    <Column>
      {!isOnMobile && (
        <HeaderChip backgroundColor={TakeTurnsColors.lightBlue}>{t("headerChip.invitedParty")}</HeaderChip>
      )}
      <CollaborationCreationInvitedParty__Container className="CollaborationCreation__InvitedParty">
        <CollaborationCreationPartyForm party={Party.Invited} />
      </CollaborationCreationInvitedParty__Container>
    </Column>
  );
};

const CollaborationCreationInvitedParty__Container = styled(ColumnPaper)({
  borderTop: `5px solid ${TakeTurnsColors.lightBlue}`,
});
