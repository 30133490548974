/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Dialog, DialogContent, DialogTitle, Divider } from "@mui/material";
import { DialogFooter } from "@taketurns-components/commons/dialog/DialogFooter";
import { useWebAppTranslations } from "@taketurns-i18n/webapp/useWebAppTranslations";
import { useCloseExcludedFromCollaborationDialogRule } from "@taketurns-rules/webapp/commands/useCloseExcludedFromCollaborationDialogRule";
import { useIsExcludedFromCollaborationRule } from "@taketurns-rules/webapp/queries/useIsExcludedFromCollaborationRule";

export const ExcludedFromCollaborationDialog = () => {
  const { t } = useWebAppTranslations("collaboration");
  const { closeExcludedFromCollaborationDialog } = useCloseExcludedFromCollaborationDialogRule();
  const isExcluded = useIsExcludedFromCollaborationRule();

  return (
    <Dialog open={isExcluded} onClose={closeExcludedFromCollaborationDialog}>
      <DialogTitle>{t("excludedFromCollaboration.title")}</DialogTitle>
      <Divider />
      <DialogContent>{t("excludedFromCollaboration.description")}</DialogContent>
      <Divider />
      <DialogFooter onClose={closeExcludedFromCollaborationDialog} />
    </Dialog>
  );
};
