/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Typography } from "@mui/material";
import { PropsWithChildren } from "react";
import { Navigate } from "react-router-dom";
import { useFetchConnectedUserIdAndEmailRule } from "@taketurns-rules/user/queries/useFetchConnectedUserIdAndEmailRule";
import { useDecodeParamsInCurrentUrlRule } from "@taketurns-rules/webapp/queries/useDecodeParamsInCurrentUrlRule";
import { AUTHENTICATED_ROUTES } from "../routes/authenticatedRoutes.constants";

export const InvitationToCollaborationEmailMismatchGuard = ({ children }: PropsWithChildren) => {
  const { recipientEmail, isInvitationLink } = useInvitationToCollaborationEmailMatchingParams();
  const { connectedUserEmail, loadingConnectedUserId, errorOnLoadingConnectedUserId } =
    useFetchConnectedUserIdAndEmailRule();

  if (loadingConnectedUserId) {
    return null;
  }
  if (errorOnLoadingConnectedUserId) {
    return <Typography>Something went wrong. Try again later.</Typography>;
  }
  if (isInvitationLink && connectedUserEmail !== recipientEmail) {
    return (
      <Navigate
        to={AUTHENTICATED_ROUTES.COLLABORATION_INVITATION_EMAIL_MISMATCH}
        state={{ invitationEmail: recipientEmail }}
      />
    );
  }

  return children;
};

interface InvitationToCollaborationEmailMatchingParams {
  isInvitationLink: boolean;
  recipient?: {
    email: string;
  };
}

export const useInvitationToCollaborationEmailMatchingParams = () => {
  const params = useDecodeParamsInCurrentUrlRule<InvitationToCollaborationEmailMatchingParams>();
  return {
    isInvitationLink: params?.isInvitationLink,
    recipientEmail: params?.recipient?.email,
  };
};
