/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { PropsWithChildren, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { clearInvitationToOrganization } from "@taketurns-repositories/webapp/sessionStorage/write/clearInvitationToOrganization";
import { useFetchConnectedUserIdAndEmailRule } from "@taketurns-rules/user/queries/useFetchConnectedUserIdAndEmailRule";
import { useAcceptOrganizationInvitationAndGetRedirectDestinationRule } from "@taketurns-rules/webapp/commands/useAcceptOrganizationInvitationAndGetRedirectDestinationRule";
import { getInvitationToOrganizationRule } from "@taketurns-rules/webapp/queries/getInvitationToOrganizationRule";
import { useIsUserFirstVisitRule } from "@taketurns-rules/webapp/queries/useIsUserFirstVisitRule";
import { AUTHENTICATED_ROUTES } from "../routes/authenticatedRoutes.constants";

export const AcceptOrganizationInvitationGuard = ({ children }: PropsWithChildren) => {
  const invitationToOrganization = getInvitationToOrganizationRule();
  const { connectedUserEmail } = useFetchConnectedUserIdAndEmailRule();
  const navigate = useNavigate();
  const [isReadyToInvite, setIsReadyToInvite] = useState<boolean>(false);

  useEffect(() => {
    if (invitationToOrganization && connectedUserEmail) {
      if (invitationToOrganization.recipientEmail !== connectedUserEmail) {
        navigate(AUTHENTICATED_ROUTES.ORGANIZATION_INVITATION_EMAIL_MISMATCH, {
          state: { invitationEmail: invitationToOrganization.recipientEmail },
        });
      } else {
        setIsReadyToInvite(true);
      }
    }
  }, [invitationToOrganization, connectedUserEmail, navigate]);

  if (invitationToOrganization) {
    return (
      <OrganizationInvitationAccepter isReadyToInvite={isReadyToInvite}>{children}</OrganizationInvitationAccepter>
    );
  }
  return children;
};

const OrganizationInvitationAccepter = ({
  isReadyToInvite,
  children,
}: PropsWithChildren<{
  isReadyToInvite: boolean;
}>) => {
  const invitationToOrganization = getInvitationToOrganizationRule();
  const acceptInvitationAndRedirect = useAcceptOrganizationInvitationAndRedirectUserInGuardRule();

  useEffect(() => {
    if (isReadyToInvite && invitationToOrganization?.organization?.id) {
      clearInvitationToOrganization();
      acceptInvitationAndRedirect(invitationToOrganization.organization.id);
    }
  }, [acceptInvitationAndRedirect, invitationToOrganization, isReadyToInvite]);

  if (!isReadyToInvite) {
    return null;
  }
  return children;
};

const useAcceptOrganizationInvitationAndRedirectUserInGuardRule = () => {
  const isUserFirstAuth = useIsUserFirstVisitRule();
  const acceptInvitationAndGetRedirectDestination = useAcceptOrganizationInvitationAndGetRedirectDestinationRule();
  const navigate = useNavigate();
  return async (organizationId: string) => {
    const destination = await acceptInvitationAndGetRedirectDestination(organizationId, isUserFirstAuth);
    navigate(destination);
  };
};
