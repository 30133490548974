/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { atom } from "recoil";
import { SubscriptionPlanPeriod } from "@taketurns/taketurns-graphql-repository";

export const subscriptionPlanPeriodState = atom<SubscriptionPlanPeriod>({
  key: "subscriptionPlanPeriod",
  default: SubscriptionPlanPeriod.Year,
});
