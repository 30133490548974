/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useFetchDestinationFolder } from "@taketurns-repositories/collaboration/graphql/queries/folder/useFetchDestinationFolder";
import { useGetDisplayedCollaborationIdRule } from "@taketurns-rules/collaboration/queries/collaboration/useGetDisplayedCollaborationIdRule";

export const useFetchDestinationFolderRule = (folderId: string) => {
  const displayedCollaborationId = useGetDisplayedCollaborationIdRule();
  return useFetchDestinationFolder(displayedCollaborationId, folderId);
};
