/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useFetchAvailableFeatures } from "./useFetchAvailableFeatures";

export const useFetchUserTotalSpaceFeature = () => {
  const { availableFeatures, loadingAvailableFeatures, errorOnFetchingAvailableFeatures } = useFetchAvailableFeatures();
  return {
    totalSpaceInMB: availableFeatures?.totalSpaceInMB ?? 0,
    loadingTotalSpaceInMB: loadingAvailableFeatures,
    errorOnFetchingTotalSpaceInMB: errorOnFetchingAvailableFeatures,
  };
};
