/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Stack, Typography } from "@mui/material";
import { OrganizationMember } from "@taketurns/taketurns-graphql-repository";
import {
  MobileOrganizationMemberUserOrInvitationInfo,
} from "@taketurns-components/user/Organization/OrganizationMemberAndInvitationList/MobileOrganizationMemberAndInvitationList/MobileOrganizationMemberUserOrInvitationInfo";
import { OrganizationMemberRoleSelector } from "@taketurns-components/user/Organization/OrganizationMemberAndInvitationList/shared/OrganizationMemberRoleSelector";
import { SupervisorSelector } from "@taketurns-components/user/Organization/OrganizationMemberAndInvitationList/shared/SupervisorSelector";
import { UserRoundedAvatar } from "@taketurns-components/user/UserProfile/UserAvatar/UserRoundedAvatar";
import { useOrganizationTranslation } from "@taketurns-i18n/user/Organization/useOrganizationTranslation";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { useSubscribeToOrganizationMemberRoleChangeRule } from "@taketurns-rules/user/subscriptions/useSubscribeToOrganizationMemberRoleChangeRule";

interface MobileOrganizationMemberRowProps {
  member: OrganizationMember & { isInvitation: false };
  connectedUserId: string;
}

export const MobileOrganizationMemberRow = ({ member, connectedUserId }: MobileOrganizationMemberRowProps) => {
  useSubscribeToOrganizationMemberRoleChangeRule(connectedUserId);
  const isConnectedUserRow = connectedUserId === member.userId;

  return (
    <Stack direction="row" spacing={1.5}>
      <UserRoundedAvatar user={member.user} />
      <Stack flex={1} spacing={1} overflow="hidden">
        <MobileOrganizationMemberUserOrInvitationInfo
          memberOrInvitation={member}
          isConnectedUserRow={isConnectedUserRow}
        />
        <MemberRoleSelectorOrLabel member={member} isConnectedUserRow={isConnectedUserRow} />
        <SupervisorLabel />
        <SupervisorSelector member={member} />
      </Stack>
    </Stack>
  );
};

const MemberRoleSelectorOrLabel = ({
  member,
  isConnectedUserRow,
}: {
  member: OrganizationMember;
  isConnectedUserRow: boolean;
}) => {
  const { t } = useOrganizationTranslation();
  if (isConnectedUserRow) {
    return <Typography color={TakeTurnsColors.darkGray}>{t(`OrganizationUserRole.${member.role}`)}</Typography>;
  }

  return <OrganizationMemberRoleSelector member={member} />;
};

const SupervisorLabel = () => {
  const { t } = useOrganizationTranslation();
  return (
    <Typography color={TakeTurnsColors.darkGray}>
      {t("OrganizationMemberAndInvitationList.OrganizationMemberAndInvitationDataGridColumns.supervisor")}
    </Typography>
  );
};
