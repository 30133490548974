/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useState } from "react";

export const useOpenUpgradePlanDialogRule = () => {
  const [isUpgradeDialogDisplayed, setIsUpgradeDialogDisplayed] = useState(false);

  const openUpgradePlanDialog = () => {
    setIsUpgradeDialogDisplayed(true);
  };
  const cancelUpgradePlan = () => {
    setIsUpgradeDialogDisplayed(false);
  };

  return { isUpgradeDialogDisplayed, openUpgradePlanDialog, cancelUpgradePlan };
};
