/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useRecoilCallback } from "recoil";
import { PendingAttachment } from "@taketurns-repositories/collaboration/models/pendingAttachment";
import { collaborationIdsLinkedToPendingAttachmentsState } from "../recoil/collaborationIdsLinkedToPendingAttachmentsState";
import { pendingAttachmentsByCollaborationIdState } from "../recoil/pendingAttachmentsByCollaborationIdState";

export const useStorePendingAttachment = () => {
  return useRecoilCallback(
    ({ set }) =>
      (pendingAttachment: PendingAttachment) => {
        set(
          collaborationIdsLinkedToPendingAttachmentsState,
          (collaborationIdsLinkedToPendingAttachments) =>
            new Set([...collaborationIdsLinkedToPendingAttachments, pendingAttachment.collaborationId]),
        );
        set(
          pendingAttachmentsByCollaborationIdState(pendingAttachment.collaborationId),
          (currentPendingAttachments) => [...currentPendingAttachments, pendingAttachment],
        );
      },
    [],
  );
};
