/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Box, Chip, styled } from "@mui/material";
import { Party } from "@taketurns/taketurns-graphql-repository";
import { useRevokeInvitationInCollaborationCreation } from "@taketurns-repositories/collaboration/state/write/collaborationCreation/form/invitation/useRevokeInvitationInCollaborationCreation";
import { useGetCollaborationFormInvitationsByPartyRule } from "@taketurns-rules/collaboration/queries/collaborationCreation/form/useGetCollaborationFormInvitationsByPartyRule";

export const RecipientList = () => {
  const getCollaborationFormInvitationsByParty = useGetCollaborationFormInvitationsByPartyRule();
  const recipients = getCollaborationFormInvitationsByParty(Party.Invited);
  const revokeInvitation = useRevokeInvitationInCollaborationCreation();

  if (recipients.length === 0) {
    return;
  }

  return (
    <RecipientList__Container>
      {recipients.map((recipient) => (
        <Chip
          variant={"outlined"}
          color={"secondary"}
          key={recipient.email}
          label={recipient.email}
          onDelete={() => revokeInvitation(recipient)}
        />
      ))}
    </RecipientList__Container>
  );
};

const RecipientList__Container = styled(Box)({
  display: "flex",
  gap: "12px",
  flexWrap: "wrap",
});
