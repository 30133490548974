/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Avatar, Skeleton, styled, Typography } from "@mui/material";
import { Attachment, Party, Revision } from "@taketurns/taketurns-graphql-repository";
import { FetchLoadingManager } from "@taketurns-app/utils/FetchLoadingManager";
import { RevisionUserAndActions } from "@taketurns-components/collaboration/CollaborationView/AttachmentDetails/components/DocumentRevisionList/RevisionUserAndActions";
import { SomethingWentWrong } from "@taketurns-components/shared/SomethingWentWrong";
import { useAttachmentDetailsTranslation } from "@taketurns-i18n/collaboration/AttachmentDetails/useAttachmentDetailsTranslation";
import i18n from "@taketurns-i18n/i18n";
import { useFetchConnectedUserPartyRule } from "@taketurns-rules/collaboration/queries/context/useFetchConnectedUserPartyRule";
import { useFetchPartyNameRule } from "@taketurns-rules/collaboration/queries/UseFetchPartyNameRule";
import { getColorForUserParty } from "@taketurns-rules/collaboration/utils/color/TakeTurnsBlueColorsUtils";
import { getSmallDate } from "@taketurns-rules/collaboration/utils/date/DateUtils";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { IconChevronRight } from "../../../../../commons/icons";
import { useHover } from "../../hooks/useHover";

interface DocumentRevisionListItemProps {
  attachment: Attachment;
  revision: Revision;
  selectRevision: () => void;
  number: number;
  canEdit: boolean;
  isSelected: boolean;
  isLast: boolean;
}

export const AttachmentRevisionListItem = (props: DocumentRevisionListItemProps) => {
  const { isHovered, hoverEventHandlers } = useHover();
  const { t } = useAttachmentDetailsTranslation();
  const { partyName, partyColor, loading, error } = useFetchAttachmentListItemData(props.revision.party);

  if (loading) {
    return <Skeleton variant={"text"} />;
  }

  if (error) {
    return <SomethingWentWrong />;
  }

  const RevisionSelector = () => {
    if (props.isSelected) {
      return <IconChevronRight color={partyColor.themeColor} size={16} viewBox={"0 0 20 20"} />;
    }
    if (isHovered) {
      return <IconChevronRight color={TakeTurnsColors.lightGray} size={16} viewBox={"0 0 20 20"} />;
    }
    return null;
  };
  return (
    <DocumentRevisionItemContainer {...hoverEventHandlers} onClick={props.selectRevision}>
      <div className={"chevron"}>
        <RevisionSelector />
      </div>
      <div className={"revisionId"}>
        <Avatar
          sx={{
            width: "18px",
            height: "18px",
            bgcolor: partyColor.variable,
            fontSize: "12px",
          }}
        >
          {props.number}
        </Avatar>
      </div>
      <div className={"revisionName"}>
        <Typography noWrap variant={"body1"} color={partyColor.themeColor}>
          {partyName}
        </Typography>
      </div>
      <div className={"revisionDescription"}>
        <Typography color={TakeTurnsColors.darkGray} variant={"caption"}>
          {props.isLast ? t("revision.updated") : t("revision.added")}
        </Typography>
      </div>
      <div className={"revisionDate"}>
        <Typography color={TakeTurnsColors.darkGray} variant={"caption"}>
          {getSmallDate(props.revision.uploadDate, i18n.language)}
        </Typography>
      </div>
      {props.isLast && props.attachment.hasAttachmentPendingRevision && props.canEdit && (
        <div className={"revisionUser"}>
          <RevisionUserAndActions
            attachmentId={props.attachment.id}
            documentId={props.attachment.document?.id}
            revisionId={props.revision.revisionId}
            revisionNumber={props.number}
          />
        </div>
      )}
    </DocumentRevisionItemContainer>
  );
};

const useFetchAttachmentListItemData = (party: Party) => {
  const { connectedUserParty, loadingConnectedUserParty, errorOnFetchingConnectedUserParty } =
    useFetchConnectedUserPartyRule();
  const { partyName, loadingPartyName, errorOnFetchingPartyName } = useFetchPartyNameRule(party);

  const fetchLoadingManager = new FetchLoadingManager(
    [loadingConnectedUserParty, loadingPartyName],
    [errorOnFetchingConnectedUserParty, errorOnFetchingPartyName],
  );

  const partyColor = getColorForUserParty(party, connectedUserParty);

  return {
    partyName,
    partyColor,
    loading: fetchLoadingManager.hasFetchLoading(),
    error: fetchLoadingManager.hasFetchInError(),
  };
};

const DocumentRevisionItemContainer = styled("div")({
  display: "grid",
  gridTemplateAreas: `"slotChevron  slotRevisionId  slotPartyName slotPartyName" ". . slotDescription slotDate" ". . slotRevisionUser slotRevisionUser"`,
  gridTemplateColumns: "16px auto 1fr auto",
  gridTemplateRows: "20px 20px",
  minHeight: "50px",
  alignItems: "center",
  "&:hover": {
    cursor: "pointer",
    ".hoveredChevronOnly": {
      display: "flex",
    },
  },
  gap: "4px 8px",
  "& .chevron": {
    gridArea: "slotChevron",
  },
  "& .hoveredChevronOnly": {
    gridArea: "slotChevron",
    display: "none",
  },
  "& .revisionId": {
    gridArea: "slotRevisionId",
  },
  "& .revisionName": {
    gridArea: "slotPartyName",
  },
  "& .revisionDescription": {
    gridArea: "slotDescription",
  },
  "& .revisionDate": {
    gridArea: "slotDate",
  },
  "& .revisionUser": {
    gridArea: "slotRevisionUser",
  },
});
