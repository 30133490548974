/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { ImportMetaEnv, TaketurnsEnvironmentVariableKey } from "./env";

declare global {
  interface Window {
    __RUNTIME_ENV__: ImportMetaEnv;
  }
}
export const getEnvironmentVariable = (environmentVariableKey: TaketurnsEnvironmentVariableKey): string => {
  if (import.meta.env.DEV) {
    return import.meta.env[`TAKETURNS_${environmentVariableKey}`];
  }
  return window.__RUNTIME_ENV__[`TAKETURNS_${environmentVariableKey}`];
};
