/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useSetRecoilState } from "recoil";
import { attachmentBulksState } from "@taketurns-repositories/collaboration/state/recoil/attachmentBulks";

export const useRemoveUploadIdInAttachmentBulk = () => {
  const setAttachmentBulks = useSetRecoilState(attachmentBulksState);
  return (uploadIdToRemove: number, onAllUploadCompleted: () => void) => {
    setAttachmentBulks((previousAttachmentBulks) => {
      return previousAttachmentBulks.map((attachmentBulksState) => {
        if (attachmentBulksState.uploadIds.includes(uploadIdToRemove)) {
          const isLastUploadIdToRemove = attachmentBulksState.uploadIds.length === 1;
          if (isLastUploadIdToRemove) {
            onAllUploadCompleted();
          }
          return {
            ...attachmentBulksState,
            uploadIds: attachmentBulksState.uploadIds.filter((uploadId) => uploadId !== uploadIdToRemove),
          };
        }
        return { ...attachmentBulksState };
      });
    });
  };
};
