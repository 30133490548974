/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { atom } from "recoil";

export const isExcludedFromCollaborationState = atom({
  key: "isExcludedFromCollaborationState",
  default: false,
});
