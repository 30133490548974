/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Collaboration, CollaborationStatus } from "@taketurns/taketurns-graphql-repository";
import { ToDoCell } from "@taketurns-components/collaboration/CollaborationList/CollaborationListColumns/active/ToDoCell";
import { RemovalDateCell } from "../closed/RemovalDateCell";

interface ToDoAndRemovalDateCellProps {
  collaboration: Collaboration;
}

export const ToDoAndRemovalDateCell = ({ collaboration }: ToDoAndRemovalDateCellProps) => {
  if (collaboration.status === CollaborationStatus.Open) {
    return (
      <ToDoCell
        attachmentToReviewCount={collaboration.contentMetadata.attachmentToReviewCount}
        requestToFulfillCount={collaboration.contentMetadata.requestToFulfillCount}
        hasPendingUpdates={collaboration.contentMetadata.hasPendingUpdates}
      />
    );
  } else {
    return <RemovalDateCell collaborationWipeDate={collaboration.wipeDate} />;
  }
};
