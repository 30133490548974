/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { ConnectedUser, ModifyUserInput } from "@taketurns/taketurns-graphql-repository";
import { useModifyConnectedUser } from "@taketurns-repositories/user/graphql/mutations/useModifyConnectedUser";

export const useModifyConnectedUserRule = () => {
  const { modifyUser } = useModifyConnectedUser();

  const getUserEditableFields = (user: ConnectedUser): ModifyUserInput => {
    return {
      firstName: user.firstName,
      lastName: user.lastName,
      function: user.function,
      avatar: user.avatar,
      jobTitle: user.jobTitle,
    };
  };

  return { modifyUser, getUserEditableFields };
};
