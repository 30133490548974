/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useQuery } from "@apollo/client";
import { EventOnCollaboration, QueryEventOnCollaborationArgs } from "@taketurns/taketurns-graphql-repository";

interface FetchEventNoteResponse {
  eventOnCollaboration: EventOnCollaboration;
}

export const useFetchEventOnCollaboration = (collaborationId: string, eventId: string) => {
  const query = gql`
    query eventNote($collaborationId: ID!, $eventId: ID!) {
      eventOnCollaboration(collaborationId: $collaborationId, eventId: $eventId) {
        id
        eventType
        isFromConnectedUserParty
        date
        note
        partyName
        party
        userId
        user {
          id
          firstName
          lastName
          email
          avatar
          jobTitle
        }
      }
    }
  `;
  const { data, loading, error } = useQuery<FetchEventNoteResponse, QueryEventOnCollaborationArgs>(query, {
    variables: {
      collaborationId,
      eventId,
    },
  });
  return {
    eventOnCollaboration: data?.eventOnCollaboration,
    loadingEventOnCollaboration: loading,
    errorOnFetchingEventOnCollaboration: error,
  };
};
