/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Divider, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Skeleton, Stack } from "@mui/material";
import { ComponentType, Fragment } from "react";
import { Invitee, Participant, Party, Role } from "@taketurns/taketurns-graphql-repository";
import { InvitationItemComponentProps } from "@taketurns-components/collaboration/Shared/ParticipantInviteeList/InvitationItem";
import { ParticipantItem, ParticipantItemComponentProps } from "@taketurns-components/collaboration/Shared/ParticipantInviteeList/ParticipantItem";
import { getDefaultColorForParty } from "@taketurns-rules/collaboration/utils/color/TakeTurnsBlueColorsUtils";
import { useFetchConnectedUserIdAndEmailRule } from "@taketurns-rules/user/queries/useFetchConnectedUserIdAndEmailRule";

interface ParticipantInviteesListProps {
  participants?: Participant[];
  invitees?: Invitee[];
  connectedUserParty: Party;
  ParticipantItemComponent: ComponentType<ParticipantItemComponentProps>;
  InvitationItemComponent: ComponentType<InvitationItemComponentProps>;
  color?: { variable: string; themeColor: "primary" | "secondary" };
  addElementLoading?: boolean;
  creator?: Participant;
}

export const ParticipantInvitationList = (props: ParticipantInviteesListProps) => {
  return (
    <Stack className="ParticipantInviteesList" spacing={2.5} overflow="auto">
      <List disablePadding>
        {props.invitees && (
          <InvitationList
            connectedUserParty={props.connectedUserParty}
            participants={props.participants}
            invitees={props.invitees}
            color={props.color}
            InvitationItemComponent={props.InvitationItemComponent}
            creator={props.creator}
          />
        )}
        {!props.invitees && props.participants && (
          <ParticipantList
            connectedUserParty={props.connectedUserParty}
            participants={props.participants}
            color={props.color}
            ParticipantItemComponent={props.ParticipantItemComponent}
          />
        )}
        {props.addElementLoading && <AddUserSkeleton />}
      </List>
    </Stack>
  );
};

const InvitationList = (props: {
  connectedUserParty: Party;
  participants: Participant[];
  invitees: Invitee[];
  color: { variable: string; themeColor: "primary" | "secondary" };
  InvitationItemComponent: ComponentType<InvitationItemComponentProps>;
  creator?: Participant;
}) => {
  const InvitationItemComponent = props.InvitationItemComponent;
  return (
    <Fragment>
      {(props.invitees.length > 0 || props.creator) && <Divider />}
      {props.creator && <CreatorItem creator={props.creator} />}
      {props.invitees.map((invitee: Invitee) => (
        <Fragment key={invitee.email}>
          <InvitationItemComponent
            color={props.color}
            invitee={invitee}
            connectedUserParty={props.connectedUserParty}
            canEdit={isInviteeNotOnlyLeaderInHisParty(props.participants, props.invitees, invitee)}
          />
        </Fragment>
      ))}
    </Fragment>
  );
};

const ParticipantList = (props: {
  connectedUserParty: Party;
  participants: Participant[];
  color: { variable: string; themeColor: "primary" | "secondary" };
  ParticipantItemComponent: ComponentType<ParticipantItemComponentProps>;
}) => {
  const ParticipantItemComponent = props.ParticipantItemComponent;
  const { connectedUserId } = useFetchConnectedUserIdAndEmailRule();

  const canEditParticipant = (participant: Participant) => {
    return (
      participant.userId !== connectedUserId && isParticipantNotOnlyLeaderInHisParty(props.participants, participant)
    );
  };

  return (
    <Fragment>
      {props.participants.length > 0 && <Divider />}
      {props.participants.map((participant: Participant) => (
        <Fragment key={participant.userId}>
          <ParticipantItemComponent
            connectedUserParty={props.connectedUserParty}
            color={props.color}
            participant={participant}
            canEdit={canEditParticipant(participant)}
          />
        </Fragment>
      ))}
    </Fragment>
  );
};

const isParticipantNotOnlyLeaderInHisParty = (participants: Participant[], participant: Participant) => {
  return (
    participant.role !== Role.Owner ||
    participants.some((p) => p.userId !== participant.userId && p.party === participant.party && p.role === Role.Owner)
  );
};

const isInviteeNotOnlyLeaderInHisParty = (participants: Participant[], invitees: Invitee[], invitee: Invitee) => {
  return (
    invitee.role !== Role.Owner ||
    participants?.some((p) => p.party === invitee.party && p.role === Role.Owner) ||
    invitees?.some(
      (i) =>
        ((i.userId && i.userId !== invitee.userId) || (!i.userId && i.email !== invitee.email)) &&
        i.role === Role.Owner,
    )
  );
};

const CreatorItem = (props: { creator: Participant }) => {
  return (
    <ParticipantItem
      connectedUserParty={Party.Owning}
      color={getDefaultColorForParty(Party.Owning)}
      participant={props.creator}
      canEdit={false}
    />
  );
};

const AddUserSkeleton = () => {
  return (
    <ListItem disablePadding divider sx={{ px: { xs: 3, md: 0 }, minHeight: "50px", height: "50px" }}>
      <ListItemAvatar sx={{ minWidth: 40 }}>
        <Skeleton variant="circular" width={30} height={30} />
      </ListItemAvatar>
      <ListItemText primary={<Skeleton width={100} />} />
      <ListItemSecondaryAction sx={{ right: { xs: "24px", md: 0 } }}>
        <Skeleton width={30} />
      </ListItemSecondaryAction>
    </ListItem>
  );
};
