/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useAddAttachment } from "@taketurns-repositories/collaboration/graphql/mutations/content/attachments/useAddAttachment";

export const useAddAttachmentRule = () => {
  const { addAttachment, addAttachmentResponseData, addAttachmentLoading, errorOnAddingAttachment } =
    useAddAttachment();
  return {
    addAttachmentRule: addAttachment,
    addAttachmentResponseData: addAttachmentResponseData,
    addAttachmentLoading: addAttachmentLoading,
    errorOnAddingAttachment: errorOnAddingAttachment,
  };
};
