/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useEffect } from "react";
import {
  useGetAllPendingCreationAttachments,
} from "@taketurns-repositories/collaboration/state/read/collaborationCreation/useGetAllPendingCreationAttachments";
import {
  useMarkPendingCreationAttachmentAsAdded,
} from "@taketurns-repositories/collaboration/state/write/collaborationCreation/pendingCreationAttachment/useMarkPendingCreationAttachmentAsAdded";
import { useGetCompletedUploadIds } from "@taketurns-repositories/document/state/read/useGetCompletedUploadIds";
import {
  useAddAttachmentInCreationRule,
} from "@taketurns-rules/collaboration/commands/collaborationCreation/attachment/pending/useAddAttachmentInCreationRule";

export const useListenToCompletedUploadsInCreationToCreateAttachmentRule = () => {
  const completedUploadIds = useGetCompletedUploadIds();
  const pendingCreationAttachments = useGetAllPendingCreationAttachments();
  const markPendingCreationAttachmentAsAdded = useMarkPendingCreationAttachmentAsAdded();
  const addCreatedAttachment = useAddAttachmentInCreationRule();

  useEffect(() => {
    for (const completedUploadId of completedUploadIds) {
      const pendingCreationAttachment = pendingCreationAttachments.find((p) => p.uploadId === completedUploadId);
      if (pendingCreationAttachment && !pendingCreationAttachment.isAdded()) {
        markPendingCreationAttachmentAsAdded(pendingCreationAttachment);
        addCreatedAttachment(pendingCreationAttachment);
      }
    }
  }, [completedUploadIds]);
};
