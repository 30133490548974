/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

export const PUBLIC_ROUTES = {
  SIGN_IN: "/signin",
  SIGN_UP: "/signup",
  VERIFY_ACCOUNT: "/verify",
  PLANS: "/plans",
  RESET_PASSWORD: "/reset-password",
  ACCESS_COLLABORATION: "/access-collaboration",
  PASSWORDLESS_SIGNIN: "/passwordless",
};
