/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Party, Role } from "@taketurns/taketurns-graphql-repository";

export const useGetRoleListRule = (party: Party, connectedUserParty: Party) => {
  const getRoleList: () => (keyof typeof Role)[] = () => {
    const roleEnumKeys = Object.keys(Role) as (keyof typeof Role)[];
    if (connectedUserParty === party) {
      return roleEnumKeys;
    }
    return roleEnumKeys.filter((roleEnumKey) => Role[roleEnumKey] !== Role.Ghost);
  };
  return getRoleList();
};
