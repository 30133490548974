/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useFetchDocumentRevision } from "@taketurns-repositories/document/graphql/queries/useFetchDocumentRevision";
import { useGetDisplayedCollaborationIdRule } from "@taketurns-rules/collaboration/queries/collaboration/useGetDisplayedCollaborationIdRule";

export const useFetchDocumentRevisionRule = (documentId: string, revisionId: string) => {
  const displayedCollaborationId = useGetDisplayedCollaborationIdRule();
  return useFetchDocumentRevision(displayedCollaborationId, documentId, revisionId);
};
