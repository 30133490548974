/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useNavigate } from "react-router-dom";
import { getRecapPath } from "@taketurns-app/routing/routes/authenticatedRoutes.constants";

export const useNavigateToRecapRule = () => {
  const navigate = useNavigate();
  return (collaborationId: string) => {
    navigate(getRecapPath(collaborationId));
  };
};
