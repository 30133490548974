/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useEditParticipantRoleRule } from "@taketurns-rules/collaboration/commands/collaborationView/participants/useEditParticipantRoleRule";
import { useRemoveParticipantRule } from "@taketurns-rules/collaboration/commands/collaborationView/participants/useRemoveParticipantRule";
import { ParticipantItem, ParticipantItemComponentProps } from "./ParticipantItem";

export const ParticipantItemComponent = (props: ParticipantItemComponentProps) => {
  const { removeParticipant, loadingRemoveParticipant } = useRemoveParticipantRule();
  const { editParticipantRole, loadingEditParticipantRole } = useEditParticipantRoleRule();
  return (
    <ParticipantItem
      {...props}
      removeParticipantAction={{ action: removeParticipant, loading: loadingRemoveParticipant }}
      editParticipantRoleAction={{
        action: editParticipantRole,
        loading: loadingEditParticipantRole,
      }}
    />
  );
};
