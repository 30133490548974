/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { gql, useLazyQuery } from "@apollo/client";
import { SearchSupervisorInput, SearchSupervisorResultItem } from "@taketurns/taketurns-graphql-repository";

export const useSearchSupervisors = () => {
  const SEARCH_SUPERVISORS_QUERY = gql`
    query searchSupervisors($searchSupervisorInput: SearchSupervisorInput!) {
      searchSupervisors(searchSupervisorInput: $searchSupervisorInput) {
        userId
        email
        firstName
        lastName
        avatar
        canBeSupervisor
      }
    }
  `;
  const [searchSupervisorsQuery, searchSupervisorsResponse] = useLazyQuery<{
    searchSupervisors: SearchSupervisorResultItem[];
  }>(SEARCH_SUPERVISORS_QUERY, {
    fetchPolicy: "network-only",
  });

  const searchSupervisors = async (searchSupervisorInput: SearchSupervisorInput) => {
    const result = await searchSupervisorsQuery({ variables: { searchSupervisorInput } });
    return result.data.searchSupervisors;
  };
  return {
    searchSupervisors,
    supervisors: searchSupervisorsResponse.data?.searchSupervisors,
    errorOnSearchingSupervisors: searchSupervisorsResponse.error,
  };
};
