/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as z from "zod";
import {
  getExtendedSchemaWithPassword,
  refinePasswordWithForbiddenFields,
} from "@taketurns-rules/commons/passwordValidationRule";

const resetPasswordFormFieldSchema = getExtendedSchemaWithPassword(
  z.object({
    code: z.string().length(6),
  }),
);

type ResetPasswordFormFields = z.infer<typeof resetPasswordFormFieldSchema>;

export const useResetPasswordFormValidationRule = (email: string) => {
  const refinedResetPasswordFormSchema = refinePasswordWithForbiddenFields(resetPasswordFormFieldSchema, {
    forbiddenValues: [email],
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    trigger,
    reset,
    setValue,
  } = useForm<ResetPasswordFormFields>({
    resolver: zodResolver(refinedResetPasswordFormSchema),
  });
  return { handleSubmit, control, errors, trigger, reset, setValue };
};
