/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
const TAKETURNS_PATH_BEFORE_SIGNIN = "taketurns_signin_return_path";

export const setLoggedInReturnPath = (path: string) => {
  sessionStorage.setItem(TAKETURNS_PATH_BEFORE_SIGNIN, path);
};

export const clearLoggedInReturnPath = () => {
  sessionStorage.removeItem(TAKETURNS_PATH_BEFORE_SIGNIN);
};

export const getLoggedInReturnPath = () => {
  return sessionStorage.getItem(TAKETURNS_PATH_BEFORE_SIGNIN);
};
