/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { getEnvironmentVariable } from "../../../getEnvironmentVariable";

export const getTakeTurnsHomepageLink = (language: string = "en") => {
  const TAKETURNS_HOMEPAGE = getEnvironmentVariable("HOMEPAGE");
  return language === "en" ? TAKETURNS_HOMEPAGE : `${TAKETURNS_HOMEPAGE}/${language}`;
};
