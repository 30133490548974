/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useQuery } from "@apollo/client";

export const useFetchIsUserAllowedToSubscribeWithTrial = () => {
  const USER_IS_ALLOWED_TO_SUBSCRIBE_WITH_TRIAL = gql`
    query userIsAllowedToTrial {
      isAllowedToSubscribeWithTrial
    }
  `;
  const {
    data,
    loading: loadingIsAllowedToSubscribeWithTrial,
    error: errorOnIsAllowedToSubscribeWithTrial,
  } = useQuery<{
    isAllowedToSubscribeWithTrial: boolean;
  }>(USER_IS_ALLOWED_TO_SUBSCRIBE_WITH_TRIAL, { fetchPolicy: "network-only" });

  return {
    isAllowedToSubscribeWithTrial: data?.isAllowedToSubscribeWithTrial,
    loadingIsAllowedToSubscribeWithTrial,
    errorOnIsAllowedToSubscribeWithTrial,
  };
};
