/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { selector } from "recoil";
import { pathInCreationState } from "./pathInCreationState";

export const selectCurrentFolderIdInCreationState = selector({
  key: "selectCurrentFolderIdInCreation",
  get: ({ get }) => {
    const path = get(pathInCreationState);
    return path.at(-1);
  },
});
