/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useFetchCollaborationFileUploadFeatures } from "@taketurns-repositories/collaboration/graphql/queries/features/useFetchCollaborationFileUploadFeatures";
import { useFetchHasCollaborationEnoughSpaceAvailableRule } from "@taketurns-rules/collaboration/queries/collaboration/useFetchHasCollaborationEnoughSpaceAvailableRule";
import { useGetDisplayedCollaborationIdRule } from "@taketurns-rules/collaboration/queries/collaboration/useGetDisplayedCollaborationIdRule";
import { useCheckAvailableSpaceAndNotifyRule } from "../useCheckAvailableSpaceAndNotifyRule";

export const useCheckAvailableSpaceAndNotifyInExistingCollaborationRule = () => {
  const collaborationId = useGetDisplayedCollaborationIdRule();
  const { totalSpaceInMB } = useFetchCollaborationFileUploadFeatures(collaborationId);
  const fetchCollaborationHasEnoughSpaceAvailable = useFetchHasCollaborationEnoughSpaceAvailableRule();

  return useCheckAvailableSpaceAndNotifyRule(fetchCollaborationHasEnoughSpaceAvailable, totalSpaceInMB);
};
