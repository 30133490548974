/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql } from "@apollo/client";

export const revisionFragment = gql`
  fragment RevisionFields on Revision {
    revisionId
    order
    size
    extension
    uploadDate
    uploadingUserId
    partyId
    party
  }
`;
