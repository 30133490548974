/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { getHasWelcomeDialogBeenSeen } from "@taketurns-repositories/webapp/localStorage/read/getHasWelcomeDialogBeenSeen";
import { useIsUserFirstVisitRule } from "./useIsUserFirstVisitRule";

export const useShouldDisplayDefaultWelcomeDialogRule = () => {
  const isUserFirstAuth = useIsUserFirstVisitRule();
  const welcomeDialogHasBeenSeen = getHasWelcomeDialogBeenSeen();
  return isUserFirstAuth && !welcomeDialogHasBeenSeen;
};
