/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useSetRecoilState } from "recoil";
import { CreateInviteeInput } from "@taketurns/taketurns-graphql-repository";
import { selectCollaborationFormInvitations } from "../../../../recoil/collaborationCreation/form/values/selectCollaborationFormInvitations";

export const useRevokeInvitationInCollaborationCreation = () => {
  const setCollaborationFormInvitations = useSetRecoilState(selectCollaborationFormInvitations);
  return (invitee: CreateInviteeInput) => {
    setCollaborationFormInvitations((existingInvitations) =>
      existingInvitations.filter((invitation) => invitation.email !== invitee.email),
    );
  };
};
