/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Box, Stack } from "@mui/material";
import aiAssistantLoadingImageUrl from "@taketurns-components/collaboration/CollaborationView/AttachmentDetails/components/AttachmentAiAssistant/aiAssistantLoading.gif";
import { useSharedCollaborationTranslation } from "@taketurns-i18n/collaboration/shared/useSharedCollaborationTranslation";

export const AiAssistantLoadingIndicator = () => {
  const { t } = useSharedCollaborationTranslation();
  return (
    <Stack flex={1} gap={6} alignItems="center" justifyContent="center">
      <Box
        component="img"
        sx={{
          height: 76,
          width: 76,
        }}
        alt={t("AttachmentAiAssistant.loadingAltText")}
        src={aiAssistantLoadingImageUrl}
      />
    </Stack>
  );
};
