/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useQuery } from "@apollo/client";
import { Collaboration } from "@taketurns/taketurns-graphql-repository";

const CLOSED_COLLABORATION = gql`
  query getClosedCollaboration($collaborationId: ID!) {
    getCollaboration(collaborationId: $collaborationId) {
      id
      closingDate
      closingUserId
      closingUser {
        id
        firstName
        lastName
        avatar
        email
        jobTitle
      }
      wipeDate
      status
    }
  }
`;

export const useFetchClosedCollaboration = (collaborationId: string) => {
  const {
    data,
    error: errorOnFetchingClosedCollaboration,
    loading: loadingClosedCollaboration,
  } = useQuery<{
    getCollaboration: Collaboration;
  }>(CLOSED_COLLABORATION, {
    variables: { collaborationId },
  });
  return {
    closedCollaboration: data?.getCollaboration,
    errorOnFetchingClosedCollaboration,
    loadingClosedCollaboration,
  };
};
