/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { setInvitationToOrganization } from "@taketurns-repositories/webapp/sessionStorage/write/setInvitationToOrganization";
import { OrganizationInvitation } from "../models/organizationInvitation";
import { useDecodeParamsInCurrentUrlRule } from "../queries/useDecodeParamsInCurrentUrlRule";

export const useSetInvitationToOrganizationRule = () => {
  const decodedParametersInCurrentUrl = useDecodeParamsInCurrentUrlRule<OrganizationInvitation>();

  const hasInvitationToOrganizationData =
    decodedParametersInCurrentUrl &&
    decodedParametersInCurrentUrl.recipientEmail &&
    decodedParametersInCurrentUrl.recipientEmail.length !== 0 &&
    decodedParametersInCurrentUrl.organization &&
    decodedParametersInCurrentUrl.organization.id &&
    decodedParametersInCurrentUrl.organization.id.length !== 0 &&
    decodedParametersInCurrentUrl.organization.name &&
    decodedParametersInCurrentUrl.organization.name.length !== 0;
  if (hasInvitationToOrganizationData) {
    const { recipientEmail, organization } = decodedParametersInCurrentUrl;
    setInvitationToOrganization(
      JSON.stringify({
        recipientEmail,
        organization,
      }),
    );
    // setInvitationToOrganization(JSON.stringify(decodedParametersInCurrentUrl));
  }
};
