/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { StyledComponent } from "@emotion/styled";
import { Box, Stack, styled, Typography } from "@mui/material";
import { FC, PropsWithChildren } from "react";
import { IconPenTool, IconPlusSquare, IconRequest } from "@taketurns-components/commons/icons";
import { useCollaborationListTranslation } from "@taketurns-i18n/collaboration/CollaborationList/useCollaborationListTranslation";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";

interface ToDoCellProps {
  attachmentToReviewCount: number;
  requestToFulfillCount: number;
  hasPendingUpdates: boolean;
}

export const ToDoCell = (props: ToDoCellProps) => {
  const { t } = useCollaborationListTranslation();
  return (
    <Stack spacing={1}>
      {props.hasPendingUpdates && <YellowIcon Icon={IconPenTool} text={t("todoCell.youHavePendingUpdates")} />}
      {props.attachmentToReviewCount > 0 && (
        <BlueIcon Icon={IconPlusSquare} text={t("todoCell.filesToReview", { count: props.attachmentToReviewCount })} />
      )}
      {props.requestToFulfillCount > 0 && (
        <BlueIcon Icon={IconRequest} text={t("todoCell.requestsToFulfill", { count: props.requestToFulfillCount })} />
      )}
    </Stack>
  );
};

const BlueIcon = (props: { Icon: FC<{ size: number; color: string }>; text: string }) => {
  return <IconWithColoredBackground Icon={props.Icon} ColoredContainer={BlueIconContainer} text={props.text} />;
};

const YellowIcon = (props: { Icon: FC<{ size: number; color: string }>; text: string }) => {
  return <IconWithColoredBackground Icon={props.Icon} ColoredContainer={YellowIconContainer} text={props.text} />;
};

const IconWithColoredBackground = (props: {
  Icon: FC<{ size: number; color: string }>;
  ColoredContainer: StyledComponent<PropsWithChildren>;
  text: string;
}) => {
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <props.ColoredContainer>
        <props.Icon size={12} color={TakeTurnsColors.white} />{" "}
      </props.ColoredContainer>
      <Typography fontSize="0.75rem" color={TakeTurnsColors.darkGray}>
        {props.text}
      </Typography>
    </Stack>
  );
};

const IconContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "24px",
  height: "24px",
  borderRadius: "5px",
});

const BlueIconContainer = styled(IconContainer)({
  backgroundColor: TakeTurnsColors.otherParty,
});

const YellowIconContainer = styled(IconContainer)({
  backgroundColor: TakeTurnsColors.yellow,
});
