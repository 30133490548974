/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { TextField } from "@mui/material";
import { useSharedCollaborationTranslation } from "@taketurns-i18n/collaboration/shared/useSharedCollaborationTranslation";
import { useSetCollaborationFormStartNoteRule } from "@taketurns-rules/collaboration/commands/collaborationCreation/form/useSetCollaborationFormStartNoteRule";
import { useGetCollaborationFormStartNoteRule } from "@taketurns-rules/collaboration/queries/collaborationCreation/form/useGetCollaborationFormStartNoteRule";
import { removeHtmlTagsRule } from "@taketurns-rules/collaboration/queries/removeHtmlTagsRule";

export const StartNoteField = () => {
  const startNote = useGetCollaborationFormStartNoteRule();
  const setCollaborationStartNote = useSetCollaborationFormStartNoteRule();
  const { t } = useSharedCollaborationTranslation();

  return (
    <TextField
      multiline
      rows={4}
      placeholder={t("CollaborationCreation.startNodePlaceHolder")}
      value={removeHtmlTagsRule(startNote)}
      onChange={(event) => setCollaborationStartNote(event.target.value)}
    />
  );
};
