/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useQuery } from "@apollo/client";
import { Collaboration } from "@taketurns/taketurns-graphql-repository";

const CONNECTED_USER_ROLE_IN_COLLABORATION = gql`
  query connectedUserRole($collaborationId: ID!) {
    getCollaboration(collaborationId: $collaborationId) {
      id
      userContext {
        id
        role
      }
    }
  }
`;

export const useFetchConnectedUserRole = (collaborationId: string) => {
  const {
    data,
    error: errorOnConnectedUserRole,
    loading: loadingConnectedUserRole,
  } = useQuery<{
    getCollaboration: Collaboration;
  }>(CONNECTED_USER_ROLE_IN_COLLABORATION, { variables: { collaborationId } });
  return {
    connectedUserRole: data?.getCollaboration.userContext.role,
    errorOnConnectedUserRole,
    loadingConnectedUserRole,
  };
};
