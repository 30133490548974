/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useQuery } from "@apollo/client";
import { ConnectedUser } from "@taketurns/taketurns-graphql-repository";

export const useFetchUserHasOrganizationAdminAndMultiUserFeatures = () => {
  const USER_HAS_ORGANIZATION_ADMIN_MULTI_USER_FEATURE = gql`
    query userHasOrganizationAdminAndMultiUserFeatures {
      connectedUser {
        id
        availableFeatures {
          organizationAdmin
          multiUserOrganization
        }
      }
    }
  `;
  const {
    data,
    loading: loadingUserHasOrganizationAdminAndMultiUserFeatures,
    error: errorOnUserHasOrganizationAdminAndMultiUserFeatures,
  } = useQuery<{
    connectedUser: ConnectedUser;
  }>(USER_HAS_ORGANIZATION_ADMIN_MULTI_USER_FEATURE, { fetchPolicy: "network-only" });
  return {
    hasOrganizationAdmin: data?.connectedUser?.availableFeatures?.organizationAdmin,
    hasMultiUserOrganization: data?.connectedUser?.availableFeatures?.multiUserOrganization,
    loadingUserHasOrganizationAdminAndMultiUserFeatures,
    errorOnUserHasOrganizationAdminAndMultiUserFeatures,
  };
};
