/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Divider, ListItemIcon, ListItemText, Menu, MenuItem, MenuProps, Typography } from "@mui/material";
import { FC, PropsWithChildren } from "react";
import { IconFilePlus, IconPlusCircle, IconPlusSquare } from "@taketurns-components/commons/icons";
import { useCollaborationCreationTranslation } from "@taketurns-i18n/collaboration/CollaborationCreation/useCollaborationCreationTranslation";
import { useNavigateToCollaborationCreationRule } from "@taketurns-rules/collaboration/commands/navigation/useNavigateToCollaborationCreationRule";
import { useNavigateToCreateSimpleFileRequestPageRule } from "@taketurns-rules/collaboration/commands/navigation/useNavigateToCreateSimpleFileRequestPageRule";
import { useNavigateToCreateSimpleFileSharingPageRule } from "@taketurns-rules/collaboration/commands/navigation/useNavigateToCreateSimpleFileSharingPageRule";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";

export const NewCollaborationMenu = ({ open, anchorEl, onClick }: PropsWithChildren<MenuProps>) => {
  const { t } = useCollaborationCreationTranslation();
  const navigateToCreateCollaborationPage = useNavigateToCollaborationCreationRule();
  const navigateToCreateSimpleFileSharingPage = useNavigateToCreateSimpleFileSharingPageRule();
  const navigateToCreateSimpleFileRequestPage = useNavigateToCreateSimpleFileRequestPageRule();
  return (
    <Menu
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={open}
      anchorEl={anchorEl}
      onClick={onClick}
      slotProps={{ paper: { sx: { borderRadius: "10px", border: `1px solid ${TakeTurnsColors.lightGray}` } } }}
    >
      <NewCollaborationMenuItem
        Icon={IconPlusCircle}
        text={t("collaboration")}
        onItemClicked={navigateToCreateCollaborationPage}
      />
      <Divider />
      <NewCollaborationMenuItem
        Icon={IconFilePlus}
        text={t("simpleFileSharing")}
        onItemClicked={navigateToCreateSimpleFileSharingPage}
      />
      <NewCollaborationMenuItem
        Icon={IconPlusSquare}
        text={t("simpleFileRequest")}
        onItemClicked={navigateToCreateSimpleFileRequestPage}
      />
      {/*
        TODO FJS TT-2943 add this back when simpleDocSignature feature is developped
        <NewCollaborationMenuItem icon={<IconSignature />} text={t("simpleDocSignature")} />
        */}
    </Menu>
  );
};

const NewCollaborationMenuItem = ({
  Icon,
  text,
  onItemClicked,
}: {
  Icon: FC<{ size: number }>;
  text: string;
  onItemClicked?: () => void;
}) => {
  return (
    <MenuItem onClick={onItemClicked} disabled={!onItemClicked}>
      <ListItemIcon>
        <Icon size={24} />
      </ListItemIcon>
      <ListItemText>
        <Typography variant={"body2"}>{text}</Typography>
      </ListItemText>
    </MenuItem>
  );
};
