/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql } from "@apollo/client";
import { useRefreshableSubscription } from "@taketurns-components/webapp/QueriesAndSubscriptionsRefreshTrigger";

const ON_ACCEPT_ORGANIZATION_INVITATION = gql`
  subscription onAcceptOrganizationInvitation($id: ID!) {
    onAcceptOrganizationInvitation(id: $id) {
      id
      invitations {
        userId
        email
        role
        user {
          id
          email
          lastName
          firstName
          avatar
        }
      }
      members {
        role
        userId
        user {
          id
          email
          lastName
          firstName
          avatar
        }
      }
    }
  }
`;

export const useSubscribeToAcceptInvitationToOrganization = (id?: string) => {
  return useRefreshableSubscription(ON_ACCEPT_ORGANIZATION_INVITATION, { variables: { id }, skip: !id });
};
