/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useQuery } from "@apollo/client";
import { Collaboration } from "@taketurns/taketurns-graphql-repository";

const IS_COLLABORATION_HIDDEN = gql`
  query isCollaborationHidden($collaborationId: ID!) {
    getCollaboration(collaborationId: $collaborationId) {
      id
      userContext {
        id
        isHidden
      }
    }
  }
`;

export const useFetchIsCollaborationHidden = (collaborationId: string) => {
  const {
    data,
    error: errorOnFetchingIsCollaborationHidden,
    loading: loadingIsCollaborationHidden,
  } = useQuery<{
    getCollaboration: Collaboration;
  }>(IS_COLLABORATION_HIDDEN, { variables: { collaborationId } });
  return {
    isCollaborationHidden: data?.getCollaboration.userContext.isHidden,
    loadingIsCollaborationHidden,
    errorOnFetchingIsCollaborationHidden,
  };
};
