/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { SubscribeToPlanWithTrialInput, SubscriptionPlanTier } from "@taketurns/taketurns-graphql-repository";
import { useNavigateToSignUpRule } from "@taketurns-rules/user/commands/useNavigateToSignUpRule";
import { useGetSubscriptionPlanCurrencyRule } from "../queries/plan/getSubscriptionPlanCurrencyRule";
import { useGetSubscriptionPlanPeriodRule } from "../queries/plan/getSubscriptionPlanPeriodRule";
import { useGetSubscriptionPlanRegionRule } from "../queries/plan/getSubscriptionPlanRegionRule";
import { storeSelectedSubscriptionPlanWithTrialRule } from "./storeSelectedSubscriptionPlanWithTrialRule";

export const useSaveSelectedPlanWithTrialAndRedirectToSignUpRule = () => {
  const region = useGetSubscriptionPlanRegionRule();
  const storeSelectedSubscriptionPlanWithTrial = storeSelectedSubscriptionPlanWithTrialRule();
  const currency = useGetSubscriptionPlanCurrencyRule();
  const period = useGetSubscriptionPlanPeriodRule();
  const navigateToSignUp = useNavigateToSignUpRule();

  const saveSelectedPlanWithTrialAndRedirectToSignUp = (tier: SubscriptionPlanTier) => {
    const selectedPlanTierWithTrial: SubscribeToPlanWithTrialInput = { tier, region, period, currency };
    storeSelectedSubscriptionPlanWithTrial(selectedPlanTierWithTrial);
    navigateToSignUp();
  };
  return { saveSelectedPlanWithTrialAndRedirectToSignUp };
};
