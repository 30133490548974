/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useMutation } from "@apollo/client";

export const useHideTip = () => {
  const mutation = gql`
    mutation hideTip($tipKey: String!) {
      hideTip(tipKey: $tipKey) {
        id
        hiddenTips
      }
    }
  `;
  return useMutation(mutation);
};
