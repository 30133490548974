/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Box, Stack, Typography } from "@mui/material";
import { useCallback } from "react";
import { SubscriptionPlanPeriod } from "@taketurns/taketurns-graphql-repository";
import { useUserPlanTranslation } from "@taketurns-i18n/user/UserPlans/useUserPlanTranslation";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { useIsOnMobileRule } from "@taketurns-rules/commons/theme/useIsOnMobileRule";
import { useSelectSubscriptionPlanPeriodRule } from "@taketurns-rules/user/commands/plan/selectSubscriptionPlanPeriodRule";
import { useGetSubscriptionPlanPeriodRule } from "@taketurns-rules/user/queries/plan/getSubscriptionPlanPeriodRule";
import { TakeTurnsSwitch } from "../../../../commons/switch/TakeTurnsSwitch";

/**
 * Payment frequency switch selector
 * @returns {ReactNode} component for displaying payment frequency switch selector
 */
export const PaymentPeriodSwitch = () => {
  const { t } = useUserPlanTranslation();
  const isOnMobile = useIsOnMobileRule();

  const paymentPeriod = useGetSubscriptionPlanPeriodRule();
  const selectPaymentPeriod = useSelectSubscriptionPlanPeriodRule();

  const handleChangePaymentPeriod = useCallback((checked: boolean) => {
    selectPaymentPeriod(checked ? SubscriptionPlanPeriod.Year : SubscriptionPlanPeriod.Month);
  }, []);

  const HighlightableText = (props: { highlight?: boolean; text: string }) => {
    const { highlight, text } = props;
    return (
      <Typography fontSize={{ xs: "0.75rem", md: "1rem" }} color={highlight ? "primary" : TakeTurnsColors.darkGray}>
        {text}
      </Typography>
    );
  };

  const MonthPeriod = () => (
    <HighlightableText highlight={paymentPeriod === SubscriptionPlanPeriod.Month} text={t("header.monthly")} />
  );

  const YearPeriod = () => (
    <HighlightableText highlight={paymentPeriod === SubscriptionPlanPeriod.Year} text={t("header.annually")} />
  );

  const SaveMoneyIndicator = () => {
    if (isOnMobile) {
      return (
        <Typography
          variant="caption"
          color={"secondary"}
          sx={{
            flex: 1,
            minWidth: 50,
            textAlign: "center",
            textTransform: "uppercase",
            fontSize: 9,
            fontWeight: 600,
          }}
        >
          {t("header.saveUpShort")}
        </Typography>
      );
    }
    return (
      <Typography variant="caption" color={"secondary"}>
        {t("header.saveUp")}
      </Typography>
    );
  };

  const SwitchPeriod = useCallback(
    ({ paymentPeriod }: { paymentPeriod: SubscriptionPlanPeriod }) => (
      <TakeTurnsSwitch
        checked={paymentPeriod === SubscriptionPlanPeriod.Year}
        sx={{
          "& .MuiSwitch-switchBase": {
            "& + .MuiSwitch-track": {
              backgroundColor: TakeTurnsColors.darkBlue,
            },
          },
        }}
        onChange={(value) => handleChangePaymentPeriod(value.target.checked)}
      />
    ),
    [],
  );

  if (isOnMobile) {
    return (
      <Stack className="MobilePaymentPeriodSwitch" sx={{ flexFlow: "row wrap", alignItems: "center", width: "100%" }}>
        <Box sx={{ flex: 1 }} />
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }} mx={1}>
          <MonthPeriod />
          <SwitchPeriod paymentPeriod={paymentPeriod} />
          <YearPeriod />
        </Box>
        <SaveMoneyIndicator />
      </Stack>
    );
  }

  return (
    <Stack direction={"column"} alignItems="flex-end">
      <Box sx={{ display: "flex", gap: 2 }}>
        <MonthPeriod />
        <SwitchPeriod paymentPeriod={paymentPeriod} />
        <YearPeriod />
      </Box>
      <SaveMoneyIndicator />
    </Stack>
  );
};
