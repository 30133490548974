/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Fragment, useRef, useState } from "react";
import { AiAssistantButton } from "@taketurns-components/collaboration/CollaborationView/AttachmentDetails/components/DesktopAttachmentDetails/AttachmentAiAssistant/AiAssistantButton";
import { AiAssistantOverlay } from "@taketurns-components/collaboration/CollaborationView/AttachmentDetails/components/DesktopAttachmentDetails/AttachmentAiAssistant/AiAssistantOverlay";
import { IconAiAssistant } from "@taketurns-components/commons/icons";
import { useSharedCollaborationTranslation } from "@taketurns-i18n/collaboration/shared/useSharedCollaborationTranslation";

interface AiAssistantButtonAndPopperProps {
  documentId: string;
  collaborationId: string;
  revisionId: string;
}

export const AttachmentAiAssistant = (props: AiAssistantButtonAndPopperProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const anchorRef = useRef<HTMLButtonElement>();
  const { t } = useSharedCollaborationTranslation();
  return (
    <Fragment>
      <AiAssistantButton
        ref={anchorRef}
        active={open}
        label={t("AttachmentAiAssistant.AiAssistant")}
        Icon={IconAiAssistant}
        dataCy="AIAssistantButton"
        sx={{
          borderBottomRightRadius: open && 0,
          borderBottomLeftRadius: open && 0,
        }}
        onClick={() => setOpen((currentOpenValue) => !currentOpenValue)}
      />
      <AiAssistantOverlay {...props} open={open} onClose={() => setOpen(false)} buttonRef={anchorRef.current} />
    </Fragment>
  );
};
