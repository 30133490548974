/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { PropsWithChildren, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useClearSelectedSubscriptionPlanRule } from "@taketurns-rules/user/commands/useClearSelectedSubscriptionPlanRule";
import { useGetSelectedSubscriptionPlanRule } from "@taketurns-rules/user/queries/useGetSelectedSubscriptionPlanRule";
import { useGetStripeCheckoutUrlRule } from "@taketurns-rules/user/queries/useGetStripeCheckoutUrlRule";
import { AUTHENTICATED_ROUTES } from "../routes/authenticatedRoutes.constants";

/*
 * This guard is meant to intercept the user that selected a plan on the public plans page.
 * Because of it, he had to sign up to the platform.
 * We previously stored the selected plan in the session storage before redirecting him to the sign up page.
 * See SubscriptionPlanElementPricingAndDescription.tsx, on the selectPaidPlan function.
 */
export const SubscribeGuard = ({ children }: PropsWithChildren) => {
  const selectedSubscriptionPlan = useGetSelectedSubscriptionPlanRule();
  const clearSelectedSubscriptionPlan = useClearSelectedSubscriptionPlanRule();
  const { loadingStripeCheckoutUrl, getStripeCheckoutUrl } = useGetStripeCheckoutUrlRule();
  const navigate = useNavigate();

  useEffect(() => {
    const redirectToStripeOrPlanPage = async () => {
      if (selectedSubscriptionPlan) {
        try {
          const stripeCheckOutUrl = await getStripeCheckoutUrl(selectedSubscriptionPlan);
          clearSelectedSubscriptionPlan();
          window.location.replace(stripeCheckOutUrl);
        } catch (errorOnStripCheckoutUrl) {
          console.error(errorOnStripCheckoutUrl);
          clearSelectedSubscriptionPlan();
          navigate(AUTHENTICATED_ROUTES.PLANS);
        }
      }
    };
    redirectToStripeOrPlanPage();
  }, [selectedSubscriptionPlan, getStripeCheckoutUrl, clearSelectedSubscriptionPlan, navigate]);

  if (selectedSubscriptionPlan || loadingStripeCheckoutUrl) {
    return null;
  }
  return children;
};
