/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { IconButton } from "@mui/material";
import { Fragment, useState } from "react";
import { IconX } from "@taketurns-components/commons/icons";
import { MemberRemoveDialog } from "./MemberRemoveDialog";

interface MemberRemoveButtonAndDialogProps {
  memberUserId: string;
}

export const MemberRemoveButtonAndDialog = (props: MemberRemoveButtonAndDialogProps) => {
  const [isMemberReplacementSelectorDialogOpened, setMemberReplacementSelectorDialogOpenStatus] = useState(false);

  return (
    <Fragment>
      <IconButton onClick={() => setMemberReplacementSelectorDialogOpenStatus(true)}>
        <IconX size={18} />
      </IconButton>
      <MemberRemoveDialog
        memberUserIdToDelete={props.memberUserId}
        open={isMemberReplacementSelectorDialogOpened}
        closeDialog={() => setMemberReplacementSelectorDialogOpenStatus(false)}
      />
    </Fragment>
  );
};
