/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Box, Chip, Skeleton, styled } from "@mui/material";
import { EventTypeOnCollaboration, Party } from "@taketurns/taketurns-graphql-repository";
import { FetchLoadingManager } from "@taketurns-app/utils/FetchLoadingManager";
import { EventBody } from "@taketurns-components/collaboration/Recap/Recap/EventBody";
import { EventHeader } from "@taketurns-components/collaboration/Recap/Recap/EventHeader";
import { SomethingWentWrong } from "@taketurns-components/shared/SomethingWentWrong";
import { useFetchCollaborationRecapRule } from "@taketurns-rules/collaboration/queries/Recap/useFetchCollaborationRecapRule";
import { useGetDisplayedRecapCollaborationIdRule } from "@taketurns-rules/collaboration/queries/Recap/useGetDisplayedRecapCollaborationIdRule";
import { getColorForUserParty } from "@taketurns-rules/collaboration/utils/color/TakeTurnsBlueColorsUtils";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";

export const Recap = () => {
  const {
    owningPartyName,
    invitedPartyName,
    connectedUserParty,
    events,
    collaborationId,
    loadingRecapData,
    errorWhileFetchingRecapData,
  } = useFetchRecapDataRule();

  if (errorWhileFetchingRecapData) {
    return <SomethingWentWrong />;
  }

  if (loadingRecapData) {
    return <Skeleton variant={"rounded"} height={"100%"} width={"100%"} />;
  }

  return (
    <Recap__Container>
      <CollaborationPartyNames
        connectedUserParty={connectedUserParty}
        owningParty={owningPartyName}
        invitedParty={invitedPartyName}
      />
      {events.map((event, index) => (
        <Box key={index}>
          <EventHeader event={event} order={index + 1} />
          <EventBody event={event} collaborationId={collaborationId} connectedUserParty={connectedUserParty} />
        </Box>
      ))}
    </Recap__Container>
  );
};

const useFetchRecapDataRule = () => {
  const collaborationId = useGetDisplayedRecapCollaborationIdRule();
  const { collaborationRecap, loadingCollaborationRecap, errorWhileLoadingCollaborationRecap } =
    useFetchCollaborationRecapRule(collaborationId);

  const fetchLoadingManager = new FetchLoadingManager(
    [loadingCollaborationRecap],
    [errorWhileLoadingCollaborationRecap],
  );

  // TODO FJS TT-3136: Replace the following when the evolution to handle other content than added and updated files and added and fulfilled requests is done
  const events = collaborationRecap?.events.filter((event) => {
    return (
      event.eventType === EventTypeOnCollaboration.Start ||
      (event.eventType === EventTypeOnCollaboration.SendUpdates &&
        (event.updatedContent.addedFiles.length > 0 ||
          event.updatedContent.updatedFiles.length > 0 ||
          event.updatedContent.addedRequests.length > 0 ||
          event.updatedContent.fulfilledRequests.length > 0)) ||
      event.eventType === EventTypeOnCollaboration.Close ||
      event.eventType === EventTypeOnCollaboration.Reopen
    );
  });

  return {
    collaborationId,
    events,
    owningPartyName: collaborationRecap?.owningPartyName,
    invitedPartyName: collaborationRecap?.invitedPartyName,
    connectedUserParty: collaborationRecap?.userContext.party,
    loadingRecapData: fetchLoadingManager.hasFetchLoading(),
    errorWhileFetchingRecapData: fetchLoadingManager.hasFetchInError(),
  };
};

const Recap__Container = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: 12,
  backgroundImage: `linear-gradient(${TakeTurnsColors.lightGray},${TakeTurnsColors.lightGray})`,
  backgroundSize: "1px 100%",
  backgroundRepeat: "no-repeat",
  backgroundPositionX: "center",
  height: "100%",
  overflow: "auto",
});

const CollaborationPartyNames = ({
  connectedUserParty,
  owningParty,
  invitedParty,
}: {
  connectedUserParty: Party;
  owningParty: string;
  invitedParty: string;
}) => {
  return (
    <CollaborationPartyNames__Container>
      <CollaborationPartyNames__ChipContainer>
        <Chip label={owningParty} color={getColorForUserParty(Party.Owning, connectedUserParty).themeColor} />
      </CollaborationPartyNames__ChipContainer>
      <CollaborationPartyNames__ChipContainer>
        <Chip label={invitedParty} color={getColorForUserParty(Party.Invited, connectedUserParty).themeColor} />
      </CollaborationPartyNames__ChipContainer>
    </CollaborationPartyNames__Container>
  );
};

const CollaborationPartyNames__Container = styled(Box)({
  display: "flex",
});

const CollaborationPartyNames__ChipContainer = styled(Box)({
  flex: 1,
  display: "flex",
  justifyContent: "center",
});
