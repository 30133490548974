/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { getAndClearMarketingData } from "@taketurns-repositories/webapp/sessionStorage/marketingData";
import { useAuthContext } from "../../../app/routing/AuthProvider";
import { useSaveMarketingDataRule } from "../../user/commands/useSaveMarketingDataRule";

export const usePersistMarketingData = () => {
  const { isUserFirstVisit } = useAuthContext();
  let marketingData = null;
  if (isUserFirstVisit) {
    marketingData = getAndClearMarketingData();
  }
  useSaveMarketingDataRule(marketingData);
};
