/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Divider, Stack, styled } from "@mui/material";
import { SubscriptionPlanForDisplay } from "@taketurns-repositories/user/local/entities/subscriptionPlanForDisplay";
import { SubscriptionPlanElementFeatures } from "../SubscriptionPlanElement/SubscriptionPlanElementFeatures";
import { SubscriptionPlanElementPricingAndDescription } from "../SubscriptionPlanElement/SubscriptionPlanElementPricingAndDescription";

interface MobileSubscriptionPlanProps {
  subscriptionPlan: SubscriptionPlanForDisplay;
  isUserAuthenticated?: boolean;
  hidden: boolean;
}

export const MobileSubscriptionPlanElement = (props: MobileSubscriptionPlanProps) => {
  const { subscriptionPlan, isUserAuthenticated, hidden } = props;

  const showDivider =
    (subscriptionPlan.featureKeys?.length ?? 0) + (subscriptionPlan.comingSoonFeatureKeys?.length ?? 0) > 0;

  return (
    <MobileSubscriptionPlanElement__CardStyle sx={{ display: hidden ? "none" : "flex" }}>
      <Stack sx={{ width: "100%" }}>
        <SubscriptionPlanElementPricingAndDescription
          subscriptionPlan={subscriptionPlan}
          isUserAuthenticated={isUserAuthenticated}
        />
        {showDivider && <Divider />}
        <SubscriptionPlanElementFeatures
          planFeatureDescription={subscriptionPlan.planFeatureDescription}
          featureKeys={subscriptionPlan.featureKeys}
          comingSoonFeatureKeys={subscriptionPlan.comingSoonFeatureKeys}
        />
      </Stack>
    </MobileSubscriptionPlanElement__CardStyle>
  );
};

const MobileSubscriptionPlanElement__CardStyle = styled("div")({
  backgroundColor: "white",
  height: "100%",
  overflow: "hidden",
  display: "flex",
  flexDirection: "column",
  flex: 1,
  overflowY: "auto",
  alignItems: "center",
});
