/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { HelpCenterSections } from "../../commons/enum/helpCenterSectionsEnum";
import { useGetHelpCenterUrls } from "../queries/useGetHelpCenterUrls";

export const useOpenHelpCenterSection = (helpCenterSection: HelpCenterSections) => {
  const { helpCenterUrl } = useGetHelpCenterUrls();
  const popUpDimensions = "menubar=1,resizable=1,width=800,height=1000";
  const openHelpCenterSection = () => {
    window.open(`${helpCenterUrl}${helpCenterSection}`, "TakeTurns Help Center", popUpDimensions);
  };
  return { openHelpCenterSection };
};
