/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Attachment, ContentStatus as ContentStatusEnum } from "@taketurns/taketurns-graphql-repository";
import { IconArrowRightCircle, IconCancelReview, IconChevronsLeft, IconReview, IconTrash } from "@taketurns-components/commons/icons";
import { useRestoreAttachmentRule } from "@taketurns-rules/collaboration/commands/collaborationView/attachment/useRestoreAttachmentRule";
import { useMarkAttachmentForReviewRule } from "../../commands/collaborationView/attachment/useMarkAttachmentForReviewRule";
import { useRemoveAttachmentRule } from "../../commands/collaborationView/attachment/useRemoveAttachmentRule";
import { useUnmarkAttachmentForReviewRule } from "../../commands/collaborationView/attachment/useUnmarkAttachmentForReviewRule";

export const useGetAttachmentContextMenuConfigurationRule = (
  attachment: Attachment,
  openMoveAttachmentDialog: () => void,
) => {
  const { markAttachmentForReview } = useMarkAttachmentForReviewRule(attachment.id);
  const { unmarkAttachmentForReview } = useUnmarkAttachmentForReviewRule(attachment.id);
  const { removeAttachment } = useRemoveAttachmentRule(attachment);
  const { restoreAttachment } = useRestoreAttachmentRule(attachment);

  const contextMenuItems = [];

  const ICON_SIZE = 14;

  if (attachment.status === ContentStatusEnum.Removed) {
    contextMenuItems.push({
      labelKey: "contextMenu.restore",
      icon: <IconChevronsLeft size={ICON_SIZE} />,
      action: restoreAttachment,
    });
  } else {
    if (attachment.markedForReview) {
      contextMenuItems.push({
        labelKey: "contextMenu.attachment.unmarkForReview",
        icon: <IconCancelReview size={ICON_SIZE} />,
        action: unmarkAttachmentForReview,
      });
    } else {
      contextMenuItems.push({
        labelKey: "contextMenu.attachment.markForReview",
        icon: <IconReview size={ICON_SIZE} />,
        action: markAttachmentForReview,
      });
    }
    contextMenuItems.push({
      labelKey: "contextMenu.move",
      icon: <IconArrowRightCircle size={ICON_SIZE} />,
      action: openMoveAttachmentDialog,
    });

    contextMenuItems.push({
      labelKey: "contextMenu.remove",
      icon: <IconTrash size={ICON_SIZE} />,
      action: removeAttachment,
    });
  }

  return { contextMenuItems };
};
