/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useQuery } from "@apollo/client";
import { SubscriptionPlan } from "@taketurns/taketurns-graphql-repository";

const SUBSCRIPTION_PLAN_STATUS = gql`
  query subscriptionPlanStatus {
    userSubscription {
      id
      status
    }
  }
`;
export const useFetchUserSubscriptionPlanStatus = () => {
  const {
    data,
    error: errorOnSubscriptionPlanStatus,
    loading: loadingSubscriptionPlanStatus,
  } = useQuery<{
    userSubscription: SubscriptionPlan;
  }>(SUBSCRIPTION_PLAN_STATUS);

  return {
    userSubscriptionPlanStatus: data?.userSubscription?.status,
    errorOnSubscriptionPlanStatus,
    loadingSubscriptionPlanStatus,
  };
};
