/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useGetUploadById } from "@taketurns-repositories/document/state/read/useGetUploadById";
import { useStartS3Upload } from "@taketurns-rules/document/commands/useStartS3Upload";

export const useStartUploadRule: () => (uploadId: number) => Promise<string> = () => {
  const getUploadById = useGetUploadById();
  const startS3Upload = useStartS3Upload();

  return async (uploadId: number): Promise<string> => {
    const upload = getUploadById(uploadId);
    if (upload.isUploadedFromPdfViewer) {
      return upload.startFromPdfViewer(uploadId);
    }
    return startS3Upload(uploadId);
  };
};
