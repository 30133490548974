/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Button, DialogActions, Stack, styled } from "@mui/material";
import { PropsWithChildren, ReactElement } from "react";
import { useCommonsTranslation } from "@taketurns-i18n/commons/useCommonsTranslation";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";

const CustomDialogAction = styled(DialogActions)({
  justifyContent: "initial",
  height: "60px",
});

interface DialogFooterProps {
  onClose?: () => void;
  helpSection?: ReactElement;
  hasCancelAction?: boolean;
}

export const DialogFooter = (props: PropsWithChildren<DialogFooterProps>) => {
  const { helpSection, children, onClose, hasCancelAction } = props;
  const { t } = useCommonsTranslation("button");
  return (
    <CustomDialogAction>
      <Stack direction={"row"} width={1} justifyContent={"space-between"}>
        {helpSection ? helpSection : <span />}
        <Stack direction={"row"} spacing={1.5}>
          {onClose && (
            <Button
              sx={{
                color: TakeTurnsColors.darkGray,
              }}
              variant={"text"}
              onClick={onClose}
            >
              {hasCancelAction ? t("cancel") : t("close")}
            </Button>
          )}
          {children}
        </Stack>
      </Stack>
    </CustomDialogAction>
  );
};
