/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useCallback } from "react";
import { useMoveAttachmentIntoFolderInCreationRule } from "@taketurns-rules/collaboration/commands/collaborationCreation/attachment/useMoveAttachmentIntoFolderInCreationRule";
import { MoveDestinationDialogInCreation } from "../MoveDestination/MoveDestinationDialogInCreation";

interface AttachmentMoveDestinationDialogInCollaborationCreationProps {
  documentId: string;
  open: boolean;
  onClose: () => void;
}

export const AttachmentMoveDestinationDialogInCollaborationCreation = ({
  documentId,
  open,
  onClose,
}: AttachmentMoveDestinationDialogInCollaborationCreationProps) => {
  return <AttachmentMoveDestinationDialogOnSuccess documentId={documentId} onClose={onClose} open={open} />;
};

interface AttachmentMoveDestinationDialogOnSuccessProps {
  documentId: string;
  onClose: () => void;
  open: boolean;
}

const AttachmentMoveDestinationDialogOnSuccess = ({
  documentId,
  onClose,
  open,
}: AttachmentMoveDestinationDialogOnSuccessProps) => {
  const moveAttachmentToFolder = useMoveAttachmentIntoFolderInCreationRule(documentId);
  const moveAttachmentToFolderAndClose = useCallback(
    (folderPath: string) => {
      moveAttachmentToFolder(folderPath);
      onClose();
    },
    [moveAttachmentToFolder, onClose],
  );
  return (
    <MoveDestinationDialogInCreation
      onSubmitDestination={moveAttachmentToFolderAndClose}
      onClose={onClose}
      open={open}
    />
  );
};
