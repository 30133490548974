/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { DateTime } from "luxon";
import { useSetRecoilState } from "recoil";
import { formatToLongDate } from "@taketurns-rules/collaboration/utils/date/DateUtils";
import { selectCollaborationFormDeadline } from "../../../recoil/collaborationCreation/form/values/selectCollaborationFormDeadline";

export const useSetCollaborationFormDeadline = () => {
  const setCollaborationFormDeadline = useSetRecoilState(selectCollaborationFormDeadline);
  return (newDeadline: DateTime) => {
    const formattedDeadline = newDeadline !== null ? formatToLongDate(newDeadline) : null;
    setCollaborationFormDeadline(formattedDeadline);
  };
};
