/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql } from "@apollo/client";
import { RemoveFolderOutput, SubscriptionOnRemoveFolderArgs } from "@taketurns/taketurns-graphql-repository";
import { useRefreshableSubscription } from "@taketurns-components/webapp/QueriesAndSubscriptionsRefreshTrigger";
import { removeFolderMutationFragment } from "../../mutations/content/folders/useRemoveFolder";

const SUBSCRIBE_TO_REMOVE_FOLDER = gql`
  subscription onRemoveFolder($collaborationId: ID!, $partyId: ID!, $folderId: ID!) {
    onRemoveFolder(collaborationId: $collaborationId, partyId: $partyId, folderId: $folderId) {
      ...RemoveFolderMutationFragment
    }
  }
  ${removeFolderMutationFragment}
`;

export const useSubscribeToRemoveFolder = (args: SubscriptionOnRemoveFolderArgs) => {
  return useRefreshableSubscription<{ onRemoveFolder: RemoveFolderOutput }, SubscriptionOnRemoveFolderArgs>(
    SUBSCRIBE_TO_REMOVE_FOLDER,
    { variables: args },
  );
};
