/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql } from "@apollo/client";
import {
  RestoreFolderOutput,
  SubscriptionOnRemoveFolderArgs,
  SubscriptionOnRestoreFolderArgs,
} from "@taketurns/taketurns-graphql-repository";
import { useRefreshableSubscription } from "@taketurns-components/webapp/QueriesAndSubscriptionsRefreshTrigger";
import { restoreFolderMutationFragment } from "@taketurns-repositories/collaboration/graphql/mutations/content/folders/useRestoreFolder";

const SUBSCRIBE_TO_RESTORE_FOLDER = gql`
  subscription onRestoreFolder($collaborationId: ID!, $partyId: ID!, $folderId: ID!) {
    onRestoreFolder(collaborationId: $collaborationId, partyId: $partyId, folderId: $folderId) {
      ...RestoreFolderMutationFragment
    }
  }
  ${restoreFolderMutationFragment}
`;

export const useSubscribeToRestoreFolder = (args: SubscriptionOnRemoveFolderArgs) => {
  return useRefreshableSubscription<{ onRestoreFolder: RestoreFolderOutput }, SubscriptionOnRestoreFolderArgs>(
    SUBSCRIBE_TO_RESTORE_FOLDER,
    { variables: args },
  );
};
