/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { selector } from "recoil";
import { Upload } from "../../../document/state/models/upload";
import { uploadsByUploadIdState } from "../../../document/state/recoil/uploadsByUploadIdState";
import { PendingCreationAttachment } from "../../models/pendingCreationAttachment";
import { pendingCreationAttachmentsState } from "./collaborationCreation/pendingCreationAttachmentsState";

export const selectPendingCreationAttachmentsPendingUpload = selector<PendingCreationAttachment[]>({
  key: "selectAllPendingCreationAttachmentsPendingUpload",
  get: ({ get }) => {
    return get(pendingCreationAttachmentsState).filter(
      (pendingAttachment) =>
        get<Upload>(uploadsByUploadIdState(pendingAttachment.uploadId)).isPending() && !pendingAttachment.isUploading(),
    );
  },
});
