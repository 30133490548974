/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

export enum PendingAttachmentRevisionStatus {
  initiated = "initiated",
  uploading = "uploading",
  adding = "attaching",
  added = "attached",
  cancelled = "cancelled",
}
