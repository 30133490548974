/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { selectorFamily, useRecoilValue } from "recoil";
import { pendingAttachmentRevisionsByCollaborationIdState } from "../../recoil/pendingAttachmentRevisionsByCollaborationIdState";
import { pendingAttachmentsByCollaborationIdState } from "../../recoil/pendingAttachmentsByCollaborationIdState";

const selectUploadIdsByCollaborationIdState = selectorFamily<number[], string>({
  key: "selectUploadIdsByCollaborationId",
  get:
    (collaborationId: string) =>
    ({ get }) => {
      const pendingAttachmentsUploadIds = get(pendingAttachmentsByCollaborationIdState(collaborationId)).map(
        (p) => p.uploadId,
      );
      const pendingAttachmentRevisionsUploadIds = get(
        pendingAttachmentRevisionsByCollaborationIdState(collaborationId),
      ).map((p) => p.uploadId);
      return [...pendingAttachmentsUploadIds, ...pendingAttachmentRevisionsUploadIds].sort((a, b) => a - b);
    },
});
export const useGetUploadIdsByCollaborationId = (collaborationId: string) => {
  return useRecoilValue(selectUploadIdsByCollaborationIdState(collaborationId));
};
