/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Request } from "@taketurns/taketurns-graphql-repository";
import {
  CollaborationCommand,
  useCollaborationPermissions,
} from "@taketurns-rules/collaboration/permissions/useCollaborationPermissions";
import {
  useIsRequestMadeByConnectedUserPartyRule,
} from "@taketurns-rules/collaboration/queries/context/useIsRequestMadeByConnectedUserPartyRule";

export const useCanConnectedUserFulFillRequestRule = () => {
  const isRequestMadeByConnectedUserPartyRule = useIsRequestMadeByConnectedUserPartyRule();
  const canUserAddFiles = useCollaborationPermissions(CollaborationCommand.ADD_FILES);

  return (request: Request): boolean => {
    return !isRequestMadeByConnectedUserPartyRule(request) && canUserAddFiles;
  };
};
