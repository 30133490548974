/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Box, IconButton, Stack, Typography } from "@mui/material";
import { ComponentProps, ForwardedRef, forwardRef, Fragment } from "react";
import { ContextMenuOnButton } from "@taketurns-components/collaboration/ContextMenu/ContextMenuOnButton";
import { ContextMenuConfiguration } from "@taketurns-components/collaboration/ContextMenu/models/contextMenuConfiguration";
import { WithContextMenu } from "@taketurns-components/collaboration/ContextMenu/WithContextMenu";
import { EditFolderNameDialog } from "@taketurns-components/collaboration/Shared/content/EditFolderDialog";
import { MobileListItem } from "@taketurns-components/collaboration/Shared/list/MobileListItem";
import { TakeTurnsListItem } from "@taketurns-components/collaboration/Shared/list/TakeTurnsListItem";
import { useRenameFolderInCreationRule } from "@taketurns-rules/collaboration/commands/collaborationCreation/folder/useRenameFolderInCreationRule";
import { useBrowseContentInCreationRule } from "@taketurns-rules/collaboration/commands/collaborationCreation/useBrowseContentInCreationRule";
import { useMoveDestinationDialogRule } from "@taketurns-rules/collaboration/commands/useMoveDestinationDialogRule";
import { useGetFolderInCreationRule } from "@taketurns-rules/collaboration/queries/collaborationCreation/useGetFolderInCreationRule";
import { useEditDialogRule } from "@taketurns-rules/collaboration/queries/useEditDialogRule";
import { useGetFolderContextMenuConfigurationInCreationRule } from "@taketurns-rules/collaboration/ui/useGetFolderContextMenuConfigurationInCreationRule";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { useIsOnMobileRule } from "@taketurns-rules/commons/theme/useIsOnMobileRule";
import { IconArrowLeft, IconFolder } from "../../../../commons/icons";
import {
  FilesDragAndDropWithOverlay,
  FilesDragAndDropWithOverlayConfig,
} from "../../../Shared/FilesDragAndDropWithOverlay";
import { FolderMoveDestinationDialogInCollaborationCreation } from "../FolderMoveDestinationDialogInCollaborationCreation";
import { FolderStatsInCreation } from "./FolderStatsInCreation";

interface FolderListItemProps {
  folderId: string;
  isDisplayedAsBreadcrumb?: boolean;
  dropZoneConfig?: FilesDragAndDropWithOverlayConfig;
}

export const FolderListItemInCreation = ({
  folderId,
  isDisplayedAsBreadcrumb,
  dropZoneConfig,
}: FolderListItemProps) => {
  const folder = useGetFolderInCreationRule(folderId);

  const renameFolder = useRenameFolderInCreationRule(folderId);

  const { closeEditDialog, isEditDialogOpened, openEditDialog } = useEditDialogRule();
  const { closeMoveDestinationDialog, isMoveDestinationDialogOpen, openMoveDestinationDialog } =
    useMoveDestinationDialogRule();

  const folderContextMenuConfigurationInCreation = useGetFolderContextMenuConfigurationInCreationRule(
    folderId,
    openEditDialog,
    openMoveDestinationDialog,
  );

  return (
    <Fragment>
      <ListItem
        folderId={folderId}
        isDisplayedAsBreadcrumb={isDisplayedAsBreadcrumb}
        dropZoneConfig={dropZoneConfig}
        contextMenuConfiguration={folderContextMenuConfigurationInCreation}
      />
      <EditFolderNameDialog
        open={isEditDialogOpened}
        onClose={closeEditDialog}
        folderName={folder.name}
        renameFolder={renameFolder}
      />
      <FolderMoveDestinationDialogInCollaborationCreation
        folderId={folderId}
        open={isMoveDestinationDialogOpen}
        onClose={closeMoveDestinationDialog}
      />
    </Fragment>
  );
};

interface ListItemProps extends FolderListItemProps {
  contextMenuConfiguration: ContextMenuConfiguration;
}

const ListItem = forwardRef(
  (
    { folderId, isDisplayedAsBreadcrumb, dropZoneConfig, contextMenuConfiguration }: ListItemProps,
    folderListItemRef: ForwardedRef<HTMLLIElement | null>,
  ) => {
    const folder = useGetFolderInCreationRule(folderId);
    const { moveBackward, moveIntoFolder } = useBrowseContentInCreationRule();
    const isOnMobile = useIsOnMobileRule();

    const moveIntoCurrentFolder = () => {
      moveIntoFolder(folderId);
    };

    if (isDisplayedAsBreadcrumb) {
      return (
        <TakeTurnsListItem
          hasContextMenu
          ref={folderListItemRef}
          onClick={moveBackward}
          titleSlot={
            <Stack direction="row" alignItems={"center"} spacing={1} overflow="hidden">
              <IconButton size="small" disableRipple>
                <IconArrowLeft size={16} color={TakeTurnsColors.grayButton} />
              </IconButton>
              <IconFolder size={16} color={TakeTurnsColors.grayButton} />
              <Typography variant="body2" noWrap>
                {folder.name}
              </Typography>
            </Stack>
          }
          rightEndSlot={!isOnMobile && <FolderStatsInCreation folder={folder} />}
        />
      );
    }

    if (isOnMobile) {
      return (
        <FilesDragAndDropWithOverlay ContainerComponent={Box} dropZoneConfig={dropZoneConfig}>
          <MobileListItem
            centerRightEndSlotVertically
            ref={folderListItemRef}
            onClick={moveIntoCurrentFolder}
            titleSlot={
              <Stack direction="row" alignItems={"center"} spacing={1} overflow="hidden">
                <IconFolder size={16} color={TakeTurnsColors.grayButton} />
                <Typography variant="body2" noWrap>
                  {folder.name}
                </Typography>
              </Stack>
            }
            subtitleSlot={<FolderStatsInCreation folder={folder} />}
            rightEndSlot={<ContextMenuOnButton contextMenuConfiguration={contextMenuConfiguration} />}
          />
        </FilesDragAndDropWithOverlay>
      );
    }

    const ListItemWithContextMenu = WithContextMenu<ComponentProps<typeof TakeTurnsListItem>>(
      TakeTurnsListItem,
      contextMenuConfiguration,
    );

    return (
      <FilesDragAndDropWithOverlay ContainerComponent={Box} dropZoneConfig={dropZoneConfig}>
        <ListItemWithContextMenu
          ref={folderListItemRef}
          onClick={moveIntoCurrentFolder}
          titleSlot={
            <Stack direction="row" alignItems={"center"} spacing={1} overflow="hidden">
              <IconFolder size={16} color={TakeTurnsColors.grayButton} />
              <Typography variant="body2" noWrap>
                {folder.name}
              </Typography>
            </Stack>
          }
          rightEndSlot={<FolderStatsInCreation folder={folder} />}
        />
      </FilesDragAndDropWithOverlay>
    );
  },
);
ListItem.displayName = "FolderListItemInCreation";
