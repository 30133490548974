/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

export const COLLABORATION_PATH = "collaboration";
export const COLLABORATION_ID = ":collaborationId";
export const AUTHENTICATED_ROUTES = {
  COLLABORATION_LIST: "/",
  COLLABORATION_CREATE: {
    SIMPLE_FILE_SHARING: `/${COLLABORATION_PATH}/create/simple-file-sharing`,
    SIMPLE_FILE_REQUEST: `/${COLLABORATION_PATH}/create/simple-file-request`,
    FULL: `/${COLLABORATION_PATH}/create/full`,
  },
  COLLABORATION_VIEW: `/${COLLABORATION_PATH}/${COLLABORATION_ID}`,
  COLLABORATION_RECAP: `/${COLLABORATION_PATH}/${COLLABORATION_ID}/recap`,
  PLANS: "/select-plan",
  USER_SETTINGS: "/user/settings",
  COMPLETE_PROFILE: "/complete-profile",
  CHECKOUT: "/checkout",
  ACCEPT_ORGANIZATION_INVITATION: "/accept-organization-invitation",
  ORGANIZATION_INVITATION_EMAIL_MISMATCH: "/organization-invitation-email-mismatch",
  COLLABORATION_INVITATION_EMAIL_MISMATCH: "/collaboration-invitation-email-mismatch",
};
export const getRecapPath = (collaborationId: string) => {
  return AUTHENTICATED_ROUTES.COLLABORATION_RECAP.replace(COLLABORATION_ID, collaborationId);
};
export const getCollaborationViewPath = (collaborationId: string) => {
  return AUTHENTICATED_ROUTES.COLLABORATION_VIEW.replace(COLLABORATION_ID, collaborationId);
};
