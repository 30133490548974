/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { CircularProgress, Stack, Typography } from "@mui/material";
import { Auth } from "aws-amplify";
import { ForwardedRef, forwardRef, useEffect, useState } from "react";
import { Revision } from "@taketurns/taketurns-graphql-repository";
import { FetchLoadingManager } from "@taketurns-app/utils/FetchLoadingManager";
import { FilePreview } from "@taketurns-components/document/FilePreview/FilePreview";
import { PdfViewerExposedFunction } from "@taketurns-components/document/FilePreview/PdfViewer/PdfViewer";
import { useAttachmentDetailsTranslation } from "@taketurns-i18n/collaboration/AttachmentDetails/useAttachmentDetailsTranslation";
import { useFetchDocumentRevisionPreviewUrl } from "@taketurns-repositories/document/graphql/queries/useFetchDocumentRevisionPreviewUrl";
import { useFetchDocumentRevisions } from "@taketurns-repositories/document/graphql/queries/useFetchDocumentRevisions";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { useIsExtensionSupportedForPreview } from "@taketurns-rules/document/queries/useIsExtensionSupportedForPreview";

interface DocumentPreviewProps {
  collaborationId: string;
  documentId: string;
  revisionId: string;
  canEditRevision: boolean;
  loadingRemoveRevision?: boolean;
  onPdfUpdatedChange?: (updated: boolean) => void;
}

export const DocumentRevisionPreview = forwardRef(
  (props: DocumentPreviewProps, ref: ForwardedRef<PdfViewerExposedFunction>) => {
    const { documentRevisionPreviewUrl, errorOnDocumentRevisionPreviewUrl, loadingDocumentRevisionPreviewUrl } =
      useFetchDocumentRevisionPreviewUrl({
        collaborationId: props.collaborationId,
        documentId: props.documentId,
        revisionId: props.revisionId,
      });
    const { revisions, loadingDocumentRevisions, errorOnFetchingDocumentRevisions } = useFetchDocumentRevisions(
      props.collaborationId,
      props.documentId,
    );
    const documentRevision = revisions?.find((revision: Revision) => revision.revisionId === props.revisionId);
    const documentRevisionExtension = documentRevision?.extension;
    const isExtensionSupportedForPreview = useIsExtensionSupportedForPreview(documentRevisionExtension);

    const fetchLoadingManager = new FetchLoadingManager(
      [loadingDocumentRevisions, loadingDocumentRevisionPreviewUrl],
      [errorOnFetchingDocumentRevisions, errorOnDocumentRevisionPreviewUrl],
    );

    if (fetchLoadingManager.hasFetchInError()) {
      fetchLoadingManager.logErrors();
    }

    const loading = fetchLoadingManager.hasFetchLoading();
    const error = fetchLoadingManager.hasFetchInError();

    const { t } = useAttachmentDetailsTranslation();

    const [jwtToken, setJwtToken] = useState(null);

    useEffect(() => {
      const fetchToken = async () => {
        try {
          const currentSession = await Auth.currentSession();
          if (currentSession) {
            const jwtToken = currentSession.getIdToken().getJwtToken();
            setJwtToken(jwtToken);
          }
        } catch (error) {
          console.error("Error fetching Cognito token:", error);
        }
      };
      fetchToken();
    }, []);

    if (loading || props.loadingRemoveRevision || !jwtToken) {
      return (
        <Stack
          sx={{
            backgroundColor: TakeTurnsColors.background,
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
            gap: "12px",
          }}
        >
          <CircularProgress variant={"indeterminate"} />
          <Typography color={TakeTurnsColors.grayButton}>{t("DocumentPreview.loadingPreview")}</Typography>
        </Stack>
      );
    }
    if (!isExtensionSupportedForPreview || error) {
      return (
        <Stack
          sx={{
            backgroundColor: TakeTurnsColors.background,
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
            gap: "12px",
          }}
        >
          <Typography color={TakeTurnsColors.grayButton}>{t("DocumentPreview.notAvailable")}</Typography>
        </Stack>
      );
    }

    return (
      <FilePreview
        ref={ref}
        fileDownloadUrl={documentRevisionPreviewUrl}
        fileExtension={documentRevisionExtension}
        canEditRevision={props.canEditRevision}
        jwtToken={jwtToken}
        onPdfUpdatedChange={props.onPdfUpdatedChange}
      />
    );
  },
);
