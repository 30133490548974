/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Chip, styled, Typography } from "@mui/material";
import { Fragment, useState } from "react";
import { PendingOrganizationInvitation } from "@taketurns/taketurns-graphql-repository";
import { useOrganizationInvitationNotificationTranslation } from "@taketurns-i18n/user/UserNotificationCenter/OrganizationInvitationNotificationAndDialog/useOrganizationInvitationNotificationTranslation";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { IconArrowRightCircle } from "../../../commons/icons";
import { OrganizationInvitationDialog } from "./OrganizationInvitationDialog";

export const OrganizationInvitationNotificationAndDialog = ({
  pendingOrganizationInvitation,
}: {
  pendingOrganizationInvitation: PendingOrganizationInvitation;
}) => {
  const { t } = useOrganizationInvitationNotificationTranslation();
  const [isInvitationDialogOpened, setOpenInvitationDialog] = useState(false);

  const openOrganizationInvitationDialog = () => {
    setOpenInvitationDialog(true);
  };

  const closeOrganizationInvitationDialog = () => {
    setOpenInvitationDialog(false);
  };

  return (
    <Fragment>
      <Chip
        color={"primary"}
        icon={<IconArrowRightCircle size={18} />}
        onClick={openOrganizationInvitationDialog}
        sx={{ padding: "0 4px" }}
        label={
          <Label__Container>
            {t("OrganizationInvitationNotificationAndDialog.notification") + " "}
            <b>{pendingOrganizationInvitation?.organizationName}</b>
            <LineDivider__Container>&#8226;</LineDivider__Container>
            <BoldTextWithYellowColor>
              {t("OrganizationInvitationNotificationAndDialog.viewInvitation")}
            </BoldTextWithYellowColor>
          </Label__Container>
        }
      />
      <OrganizationInvitationDialog
        pendingOrganizationInvitation={pendingOrganizationInvitation}
        open={isInvitationDialogOpened}
        closeDialog={closeOrganizationInvitationDialog}
      />
    </Fragment>
  );
};

const Label__Container = styled(Typography)({
  fontSize: "0.75rem",
  lineHeight: "0.75rem",
});

const BoldTextWithYellowColor = styled("b")({
  color: TakeTurnsColors.yellow,
});

const LineDivider__Container = styled("span")({
  padding: "0 4px",
});
