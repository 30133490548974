/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { DefaultValue, selector } from "recoil";
import { collaborationFormState } from "./collaborationFormState";

export const selectCollaborationFormStartNote = selector<string>({
  key: "collaborationFormStartNote",
  get: ({ get }) => {
    return get(collaborationFormState).note;
  },
  set: ({ set }, startNote) => {
    set(collaborationFormState, (currentCollaborationFormState) => ({
      ...currentCollaborationFormState,
      note: startNote instanceof DefaultValue ? undefined : startNote,
    }));
  },
});
