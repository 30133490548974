/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useRecoilCallback } from "recoil";
import { folderByIdState } from "../recoil/collaborationCreation/folderByIdState";
import { parentFolderIdByRequestIdState } from "../recoil/collaborationCreation/parentFolderIdByRequestIdState";

export function useMoveRequestIntoFolderInCreation(movingRequestId: string) {
  return useRecoilCallback(
    ({ set, snapshot }) =>
      (destinationFolderId: string) => {
        removeMovingRequestFromPreviousParentFolder();
        addMovingRequestToDestinationFolder();

        function removeMovingRequestFromPreviousParentFolder() {
          const previousParentFolderId: string = snapshot.getLoadable(
            parentFolderIdByRequestIdState(movingRequestId),
          ).contents;
          set(folderByIdState(previousParentFolderId), (previousParentFolder) => ({
            ...previousParentFolder,
            requests: previousParentFolder.requests.filter((folder) => folder !== movingRequestId),
          }));
        }

        function addMovingRequestToDestinationFolder() {
          set(parentFolderIdByRequestIdState(movingRequestId), destinationFolderId);
          set(folderByIdState(destinationFolderId), (destinationFolder) => ({
            ...destinationFolder,
            requests: [...destinationFolder.requests, movingRequestId],
          }));
        }
      },
    [movingRequestId],
  );
}
