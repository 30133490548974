/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Participant } from "@taketurns/taketurns-graphql-repository";
import { useFetchParticipantsAndInvitationsRule } from "../../collaboration/queries/collaborationView/useFetchParticipantsAndInvitationsRule";

export const useGetCurrentCollaborationParticipantPartyRule = () => {
  const { participants } = useFetchParticipantsAndInvitationsRule();

  return (userId: string) => {
    return participants?.find((participant: Participant) => participant.userId === userId)?.party;
  };
};
