/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useFetchAvailableFeatures } from "./useFetchAvailableFeatures";

export const useFetchUserMaxFileSizeUserFeature = () => {
  const { availableFeatures, loadingAvailableFeatures, errorOnFetchingAvailableFeatures } = useFetchAvailableFeatures();
  return {
    maxFileSizeInMB: availableFeatures?.maxFileSizeInMB ?? 0,
    loadingMaxFileSizeInMB: loadingAvailableFeatures,
    errorOnFetchingMaxFileSizeInMB: errorOnFetchingAvailableFeatures,
  };
};
