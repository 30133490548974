/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { TIP_KEYS } from "../TIP_KEYS";

export const tips = {
  [TIP_KEYS.FILES]:
    "Pour déposer une révision d'un fichier, cliquez dessus ou glissez/déposez un nouveau fichier dessus.",
  [TIP_KEYS.UPLOAD_REVISION_OR_DOWNLOAD_FILE]:
    "Utilisez les boutons ci-dessous pour télécharger le fichier ou envoyer une nouvelle version (révision).",
  [TIP_KEYS.PARTY_NAME]: "Nommez les deux parties d’après les organisations ou les individus qui collaboreront.",
  [TIP_KEYS.UPLOAD_FILE]: {
    begin: "Si vous devez déposer une nouvelle version (révision) d’un fichier existant,",
    closeDialogLink: "fermez cette fenêtre",
    end: "et cliquez sur le fichier.",
  },
  [TIP_KEYS.SEND_UPDATE_DESCRIPTION]:
    "L'envoi de vos modifications les rend visibles par tous les participants du Flow.",
  [TIP_KEYS.WRAP_UP_DESCRIPTION]:
    "Conclure un Flow le passe en lecture seule. Tous les fichiers seront supprimés après une date d’expiration.",
  [TIP_KEYS.RECALL_TURN]: "Reprendre le tour annulera le tour de l’autre partie.",
};
