/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useGetDisplayedCollaborationIdRule } from "./useGetDisplayedCollaborationIdRule";

export const useIsInCollaborationViewRule = () => {
  const displayedCollaborationId = useGetDisplayedCollaborationIdRule();
  return !!displayedCollaborationId;
};
