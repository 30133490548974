/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Stack } from "@mui/material";
import { forwardRef } from "react";
import { Attachment } from "@taketurns/taketurns-graphql-repository";
import { ContentElementLastUpdate } from "@taketurns-components/collaboration/CollaborationView/CollaborationContent/shared/components/ContentElementLastUpdate";
import { ContextMenuOnButton } from "@taketurns-components/collaboration/ContextMenu/ContextMenuOnButton";
import { WithContextMenu } from "@taketurns-components/collaboration/ContextMenu/WithContextMenu";
import { TakeTurnsListItem } from "@taketurns-components/collaboration/Shared/list/TakeTurnsListItem";
import { DocumentExtension } from "@taketurns-components/document/DocumentView/DocumentExtension";
import { useGetAttachmentContextMenuConfigurationRule } from "@taketurns-rules/collaboration/queries/attachment/useGetAttachmentContextMenuConfigurationRule";
import { useFetchLastUpdateColor } from "@taketurns-rules/collaboration/queries/content/UseFetchLastUpdateColor";
import { ListItemTitle } from "../ListItemTitle";
import { AttachmentMarkersInList } from "./AttachmentMarkersInList";

interface DesktopAttachmentListItemProps {
  attachment: Attachment;
  isAttachmentRemoved: boolean;
  shouldDisplayLastUpdate: boolean;
  openAttachmentDetails: () => void;
  contextMenuConfiguration: ReturnType<typeof useGetAttachmentContextMenuConfigurationRule> | null;
  color: string;
  backgroundColor: string;
}

export const DesktopAttachmentListItem = (props: DesktopAttachmentListItemProps) => {
  if (!props.contextMenuConfiguration) {
    return <InnerListItem {...props} />;
  }

  const ListItemWithContextMenu = WithContextMenu<DesktopAttachmentListItemProps>(
    InnerListItem,
    props.contextMenuConfiguration,
  );
  return <ListItemWithContextMenu {...props} />;
};

const InnerListItem = forwardRef<HTMLLIElement | null, DesktopAttachmentListItemProps>(
  (props: DesktopAttachmentListItemProps, ref) => {
    const { color: lastUpdateColor } = useFetchLastUpdateColor();
    const borderColor = props.shouldDisplayLastUpdate ? lastUpdateColor : props.backgroundColor;

    return (
      <TakeTurnsListItem
        ref={ref}
        titleSlot={
          <ListItemTitle title={props.attachment.document.name} shouldStrikeTitle={props.isAttachmentRemoved} />
        }
        subtitleSlot={<AttachmentMarkersInList attachment={props.attachment} />}
        rightEndSlot={
          <Stack direction="row" alignItems="center">
            <DocumentExtension extension={props.attachment.document.extension} size={props.attachment.document.size} />
            {props.contextMenuConfiguration && (
              <ContextMenuOnButton contextMenuConfiguration={props.contextMenuConfiguration} />
            )}
          </Stack>
        }
        middleSlot={props.shouldDisplayLastUpdate && <ContentElementLastUpdate status={props.attachment.prevStatus} />}
        onClick={props.isAttachmentRemoved ? undefined : props.openAttachmentDetails}
        sx={{
          borderRight: `8px solid ${borderColor}`,
          paddingRight: borderColor ? undefined : "18px",
          backgroundColor: props.backgroundColor,
          "&:hover": {
            borderRight: `8px solid ${borderColor}`,
          },
        }}
      />
    );
  },
);

InnerListItem.displayName = "DesktopAttachmentListItem__InnerListItem";
