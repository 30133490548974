/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import * as React from "react";
import { TakeTurnsDefaultButton } from "@taketurns-components/commons/button";
import { useSharedCollaborationTranslation } from "@taketurns-i18n/collaboration/shared/useSharedCollaborationTranslation";

interface SaveRevisionButtonProps {
  onClick: () => void;
}

export const SaveRevisionButton = ({ onClick }: SaveRevisionButtonProps) => {
  const { t } = useSharedCollaborationTranslation();

  return (
    <TakeTurnsDefaultButton onClick={onClick} sx={{ fontSize: { xs: 14, sm: 16 } }}>
      {t("PdfViewer.annotationsButtons.save")}
    </TakeTurnsDefaultButton>
  );
};
