/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { setIsComingFromInvitationToCollaborationLink } from "@taketurns-repositories/webapp/sessionStorage/isComingFromInvitationToCollaborationLink";
import { useDecodeParamsInCurrentUrlRule } from "../queries/useDecodeParamsInCurrentUrlRule";

export const useSetIsComingFromInvitationToCollaborationLinkRule = () => {
  const params = useDecodeParamsInCurrentUrlRule<{ isInvitationLink: boolean }>();
  return () => {
    if (params?.isInvitationLink) {
      setIsComingFromInvitationToCollaborationLink();
    }
  };
};
