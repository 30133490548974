/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

// eslint-disable-next-line import/no-unresolved
import { useBrowseContentRule } from "../useBrowseContentRule";
import { useAttachFilesInFolderRule } from "./useAttachFilesInFolderRule";

export const useAttachFilesIntoFolderAndMoveIntoItRule = () => {
  const { moveIntoFolder } = useBrowseContentRule();
  const attachFilesInFolder = useAttachFilesInFolderRule();

  return async (files: File[], folderId: string) => {
    moveIntoFolder(folderId);
    await attachFilesInFolder(files, folderId);
  };
};
