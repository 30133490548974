/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Avatar, AvatarGroup, Box, styled, Tooltip, Typography } from "@mui/material";
import { EventOnCollaboration, EventTypeOnCollaboration, Participant, Party } from "@taketurns/taketurns-graphql-repository";
import { useRecapTranslation } from "@taketurns-i18n/collaboration/Recap/useRecapTranslation";
import { useCollaborationTranslation } from "@taketurns-i18n/collaboration/useCollaborationTranslation";
import { getSmallDate } from "@taketurns-rules/collaboration/utils/date/DateUtils";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";

export const EventHeader = (props: { event: EventOnCollaboration; order: number }) => {
  return (
    <EventHeader__Container>
      <ParticipantAvatars
        justifyContent={"flex-end"}
        participants={props.event.participantsWhenEventOccurs.filter(
          (participant) => participant.party === Party.Owning,
        )}
      />
      <EventDateWithLabel event={props.event} order={props.order} />
      <ParticipantAvatars
        participants={props.event.participantsWhenEventOccurs.filter(
          (participant) => participant.party === Party.Invited,
        )}
      />
    </EventHeader__Container>
  );
};

const EventHeader__Container = styled(Box)({
  display: "flex",
  flexDirection: "row",
  gap: 12,
  justifyContent: "center",
  alignItems: "center",
  backgroundImage: `linear-gradient(${TakeTurnsColors.lightGray},${TakeTurnsColors.lightGray})`,
  backgroundSize: "100% 1px",
  backgroundRepeat: "no-repeat",
  backgroundPositionY: "center",
});

const ParticipantAvatars = ({
  participants,
  justifyContent,
}: {
  participants: Participant[];
  justifyContent?: string;
}) => {
  const getAvatarTooltipText = useGetAvatarTooltipText();
  return (
    <Box display={"flex"} flex={1} justifyContent={justifyContent ?? "none"}>
      <ParticipantAvatarGroup>
        {participants.map((participant) => (
          <Tooltip arrow key={participant.userId} title={getAvatarTooltipText(participant)}>
            <Avatar src={participant.user?.avatar} alt={participant.user?.firstName} />
          </Tooltip>
        ))}
      </ParticipantAvatarGroup>
    </Box>
  );
};

const ParticipantAvatarGroup = styled(AvatarGroup)({
  ".MuiAvatarGroup-avatar": {
    width: 24,
    height: 24,
    fontSize: "12px",
  },
});

const useGetAvatarTooltipText = () => {
  const { t } = useCollaborationTranslation("participantCollaboration");

  return (participant: Participant) => {
    const namesOrEmail =
      participant.user?.firstName && participant.user?.lastName
        ? `${participant.user?.firstName} ${participant.user?.lastName}`
        : participant.user?.email;
    return `${namesOrEmail} ${participant.user?.jobTitle ? participant.user?.jobTitle : ""} ( ${t(participant.role)} )`;
  };
};
const EventDateWithLabel = ({ event, order }: { event: EventOnCollaboration; order: number }) => {
  const { t, i18n } = useRecapTranslation();

  const getUpdateDateAndLabel = () => {
    const updateDate = getSmallDate(event.date, i18n.language, {
      day: "2-digit",
      month: "short",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
    });
    if (event.eventType === EventTypeOnCollaboration.Start) {
      return `${updateDate} - ${t("Recap.flowStarted")}`;
    }
    if (event.eventType === EventTypeOnCollaboration.SendUpdates) {
      return `${updateDate} - ${t("Recap.update")}`;
    }
    if (event.eventType === EventTypeOnCollaboration.RecallUpdates) {
      return `${updateDate} - ${t("Recap.recallUpdates")}`;
    }
    if (event.eventType === EventTypeOnCollaboration.Close) {
      return `${updateDate} - ${t("Recap.flowEnded")}`;
    }
    if (event.eventType === EventTypeOnCollaboration.Reopen) {
      return `${updateDate} - ${t("Recap.flowReopened")}`;
    }
  };

  const color = event.isFromConnectedUserParty ? TakeTurnsColors.connectedUser : TakeTurnsColors.otherParty;
  return (
    <EventOrderAndLabel__Container inverseContent={event.party === Party.Invited}>
      <EventOrderCircularBox backgroundColor={color} sx={{ width: 24, height: 24 }}>
        {order}
      </EventOrderCircularBox>
      <Typography variant={"body2"} padding={"0 12px"}>
        {getUpdateDateAndLabel()}
      </Typography>
    </EventOrderAndLabel__Container>
  );
};

const EventOrderAndLabel__Container = styled(Box, {
  shouldForwardProp: (props) => props !== "inverseContent",
})(({ inverseContent }: { inverseContent?: boolean }) => ({
  display: "flex",
  flexDirection: inverseContent ? "row-reverse" : "row",
  alignItems: "center",
  backgroundColor: TakeTurnsColors.background,
  border: `1px solid ${TakeTurnsColors.lightGray}`,
  borderRadius: 50,
  color: TakeTurnsColors.darkGray,
}));

const EventOrderCircularBox = styled(Box, {
  shouldForwardProp: (props) => props !== "backgroundColor",
})(({ backgroundColor }: { backgroundColor: string }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: backgroundColor,
  borderRadius: "50px",
  fontSize: "0.8rem",
  color: "white",
}));
