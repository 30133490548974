/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useSetPlansHaveBeenSeenRule } from "@taketurns-rules/webapp/commands/useSetPlansHaveBeenSeenRule";
import { AUTHENTICATED_ROUTES } from "../../../app/routing/routes/authenticatedRoutes.constants";
import { useAcceptOrganizationInvitationRule } from "../../user/commands/useAcceptOrganizationInvitationRule";
import { useShouldDisplayProfilePageRule } from "../queries/useShouldDisplayProfilePageRule";

export const useAcceptOrganizationInvitationAndGetRedirectDestinationRule = () => {
  const { acceptOrganizationInvitation } = useAcceptOrganizationInvitationRule();
  const shouldDisplayProfilePage = useShouldDisplayProfilePageRule();
  const setPlansHaveBeenSeen = useSetPlansHaveBeenSeenRule();

  const destinationAfterAcceptOrganizationInvitationSuccess = (isUserFirstVisit: boolean) => {
    if (shouldDisplayProfilePage(isUserFirstVisit)) {
      return AUTHENTICATED_ROUTES.COMPLETE_PROFILE;
    }
    return AUTHENTICATED_ROUTES.USER_SETTINGS;
  };

  return async (organizationId: string, isUserFirstAuth: boolean) => {
    try {
      await acceptOrganizationInvitation(organizationId);
      setPlansHaveBeenSeen();
      return destinationAfterAcceptOrganizationInvitationSuccess(isUserFirstAuth);
    } catch (e) {
      return AUTHENTICATED_ROUTES.USER_SETTINGS;
    }
  };
};
