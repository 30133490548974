/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { IconButton, Stack } from "@mui/material";
import { MouseEventHandler } from "react";
import { MobileAiAssistantDrawerAndButton } from "@taketurns-components/collaboration/CollaborationView/AttachmentDetails/components/MobileAttachmentDetails/MobileAiAssistantDrawerAndButton";
import { MobileAttachmentDetailsDocumentName } from "@taketurns-components/collaboration/CollaborationView/AttachmentDetails/components/MobileAttachmentDetails/MobileAttachmentDetailsDocumentName";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { IconChevronLeft } from "../../../../../commons/icons";

interface PageHeaderProps {
  openAiAssistant: MouseEventHandler<SVGElement>;
  closeDialog: () => void;
}

export const MobileAttachmentDetailsPageHeader = (props: PageHeaderProps) => {
  return (
    <Stack direction="row" gap={1.5} alignItems="center" justifyContent="space-between" padding="16px 24px">
      <IconButton onClick={props.closeDialog} disableRipple sx={{ justifyContent: "flex-start" }}>
        <IconChevronLeft color={TakeTurnsColors.darkGray} size={24} />
      </IconButton>
      <MobileAttachmentDetailsDocumentName />
      <MobileAiAssistantDrawerAndButton />
    </Stack>
  );
};
