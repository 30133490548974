/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useRemoveRequest } from "@taketurns-repositories/collaboration/graphql/mutations/content/requests/useRemoveRequest";
import { useGetDisplayedCollaborationIdRule } from "@taketurns-rules/collaboration/queries/collaboration/useGetDisplayedCollaborationIdRule";

export const useRemoveRequestRule = (requestId: string) => {
  const collaborationId = useGetDisplayedCollaborationIdRule();

  const { removeRequest, removeRequestResponseData, removeRequestLoading, errorOnRemovingRequest } = useRemoveRequest(
    collaborationId,
    requestId,
  );
  return {
    removeRequest,
    removeRequestResponseData: removeRequestResponseData,
    removeRequestLoading: removeRequestLoading,
    errorOnRemovingRequest: errorOnRemovingRequest,
  };
};
