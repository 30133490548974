/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Folder } from "@taketurns/taketurns-graphql-repository";
import { useGetDisplayedCollaborationId } from "@taketurns-repositories/collaboration/context/useGetDisplayedCollaborationId";
import { useRestoreFolder } from "@taketurns-repositories/collaboration/graphql/mutations/content/folders/useRestoreFolder";

export const useRestoreFolderRule = (folder: Folder) => {
  const collaborationId = useGetDisplayedCollaborationId();
  const { restoreFolderMutation, restoreFolderResponseData, restoreFolderLoading, errorOnRestoringFolder } =
    useRestoreFolder(collaborationId, folder);
  return {
    restoreFolder: restoreFolderMutation,
    restoreFolderResponseData,
    restoreFolderLoading,
    errorOnRestoringFolder,
  };
};
