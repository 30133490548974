/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useEffect } from "react";
import {
  useMarkPendingAttachmentAsCancelled,
} from "@taketurns-repositories/collaboration/state/write/pendingAttachment/useMarkPendingAttachmentAsCancelled";
import { useGetCancelledUploadIds } from "@taketurns-repositories/document/state/read/useGetCancelledUploadIds";
import {
  useGetAllPendingAttachmentsRule,
} from "@taketurns-rules/collaboration/queries/attachment/pending/useGetAllPendingAttachmentsRule";
import { useCancelUploadInAttachmentBulkRule } from "../useCancelUploadInAttachmentBulkRule";

export const useListenToCancelledUploadsToCancelAddAttachmentRule = () => {
  const cancelledUploadIds = useGetCancelledUploadIds();
  const pendingAttachments = useGetAllPendingAttachmentsRule();
  const markPendingAttachmentAsCancelled = useMarkPendingAttachmentAsCancelled();
  const cancelUploadInAttachmentBulk = useCancelUploadInAttachmentBulkRule();

  useEffect(() => {
    for (const cancelledUploadId of cancelledUploadIds) {
      const pendingAttachmentToUpdate = pendingAttachments.find((p) => p.uploadId === cancelledUploadId);
      if (pendingAttachmentToUpdate && !pendingAttachmentToUpdate.isCancelled()) {
        markPendingAttachmentAsCancelled(pendingAttachmentToUpdate);
        cancelUploadInAttachmentBulk(cancelledUploadId);
      }
    }
  }, [cancelUploadInAttachmentBulk, cancelledUploadIds, markPendingAttachmentAsCancelled, pendingAttachments]);
};
