/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

export enum UploadStatus {
  pending = "pending",
  inProgress = "inProgress",
  complete = "complete",
  failed = "failed",
  cancelled = "cancelled",
}
