/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useQuery } from "@apollo/client";
import { Collaboration } from "@taketurns/taketurns-graphql-repository";

const CONNECTED_USER_STATUS_IN_COLLABORATION = gql`
  query connectedUserStatus($collaborationId: ID!) {
    getCollaboration(collaborationId: $collaborationId) {
      id
      status
      userContext {
        id
        isInvitee
        isParticipant
        isSupervisor
      }
    }
  }
`;

export const useFetchConnectedUserStatus = (collaborationId: string) => {
  const {
    data,
    loading: loadingConnectedUserStatus,
    error: errorOnConnectedUserStatus,
  } = useQuery<{
    getCollaboration: Collaboration;
  }>(CONNECTED_USER_STATUS_IN_COLLABORATION, { variables: { collaborationId } });
  return {
    collaborationStatus: data?.getCollaboration.status,
    isInvitee: data?.getCollaboration.userContext.isInvitee,
    isParticipant: data?.getCollaboration.userContext.isParticipant,
    isSupervisor: data?.getCollaboration.userContext.isSupervisor,
    loadingConnectedUserStatus,
    errorOnConnectedUserStatus,
  };
};
