/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

export const resizeImage = (file: File, maxHeightAndWidthInPx: number): Promise<File> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const img = new Image();
      img.onload = () => {
        const imgRatio = img.width / img.height;
        let canvasWidthInPx: number;
        let canvasHeightInPx: number;
        if (imgRatio > 1) {
          canvasWidthInPx = Math.min(img.width, maxHeightAndWidthInPx);
          canvasHeightInPx = Math.min(img.height, maxHeightAndWidthInPx / imgRatio);
        } else {
          canvasWidthInPx = Math.min(img.width, maxHeightAndWidthInPx * imgRatio);
          canvasHeightInPx = Math.min(img.height, maxHeightAndWidthInPx);
        }
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        canvas.width = canvasWidthInPx;
        canvas.height = canvasHeightInPx;
        ctx.drawImage(img, 0, 0, canvasWidthInPx, canvasHeightInPx);
        canvas.toBlob((blob) => {
          resolve(new File([blob], file.name, { type: "image/jpeg" }));
        }, "image/jpeg");
      };
      img.onerror = (ev) => {
        reject(ev);
      };
      img.src = event.target.result as string;
    };
    reader.readAsDataURL(file);
  });
};
