/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useEffect } from "react";
import {
  useGetAllPendingCreationAttachments,
} from "@taketurns-repositories/collaboration/state/read/collaborationCreation/useGetAllPendingCreationAttachments";
import {
  useMarkPendingCreationAttachmentAsUploading,
} from "@taketurns-repositories/collaboration/state/write/collaborationCreation/pendingCreationAttachment/useMarkPendingCreationAttachmentAsUploading";
import { useGetRetriedUploadIds } from "@taketurns-repositories/document/state/read/useGetRetriedUploadIds";
import { useAddAttachmentBulkRule } from "@taketurns-rules/collaboration/commands/useAddAttachmentBulkRule";

export function useListenToRetriedUploadsInCreationToMarkAttachmentAsUploadingRule() {
  const retriedUploadIds = useGetRetriedUploadIds();
  const pendingAttachments = useGetAllPendingCreationAttachments();
  const markPendingAttachmentAsUploading = useMarkPendingCreationAttachmentAsUploading();
  const addAttachmentBulkRule = useAddAttachmentBulkRule();

  useEffect(() => {
    for (const retriedUploadId of retriedUploadIds) {
      const pendingAttachmentToUpdate = pendingAttachments.find((p) => p.uploadId === retriedUploadId);
      if (pendingAttachmentToUpdate && !pendingAttachmentToUpdate.isUploading()) {
        markPendingAttachmentAsUploading(pendingAttachmentToUpdate);
        addAttachmentBulkRule({ numberOfAttachments: 1, uploadIds: [retriedUploadId] });
      }
    }
  }, [retriedUploadIds]);
}
