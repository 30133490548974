/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { ConnectedUser } from "@taketurns/taketurns-graphql-repository";

export const useUserSettingsFormValidationRule = () => {
  const validateUserSettingsForm = (user: ConnectedUser) => {
    return !!user.function;
  };
  return { validateUserSettingsForm };
};
