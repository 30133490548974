/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useGetConnectedUserPartyId } from "@taketurns-repositories/collaboration/context/useGetConnectedUserPartyId";
import { useSubscribeToMarkUnmarkForReviewAttachment } from "@taketurns-repositories/collaboration/graphql/subscriptions/content/useSubscribeToMarkUnmarkForReviewAttachment";
import { useGetDisplayedCollaborationIdRule } from "@taketurns-rules/collaboration/queries/collaboration/useGetDisplayedCollaborationIdRule";

export const useSubscribeToMarkUnmarkForReviewAttachmentRule = (attachmentId: string) => {
  const displayedCollaborationId = useGetDisplayedCollaborationIdRule();
  const connectedUserPartyId = useGetConnectedUserPartyId();
  return useSubscribeToMarkUnmarkForReviewAttachment({ collaborationId: displayedCollaborationId, partyId: connectedUserPartyId, attachmentId });
};
