/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Folder } from "@taketurns/taketurns-graphql-repository";
import { useGetSubFolderFragment } from "@taketurns-repositories/collaboration/graphql/fragments/subFolderFragment";

export const useGetSubFolderRule = (folderId: string): Folder | undefined => {
  const { data: folder } = useGetSubFolderFragment(folderId);
  return folder;
};
