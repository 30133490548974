/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { SubscriptionPlanTier } from "@taketurns/taketurns-graphql-repository";
import { useFetchHasMultiUserAndOrganizationAdminFeatures } from "@taketurns-repositories/user/graphql/queries/useFetchHasMultiUserAndOrganizationAdminFeatures";
import { useFetchHasUserOrganizationOnlyOneMember } from "@taketurns-repositories/user/graphql/queries/useFetchHasUserOrganizationOnlyOneMember";

export const useFetchIsMultiUserOrganizationAllowedToSwitchPlanRule = (nextPlanTier: SubscriptionPlanTier) => {
  const ignoreQueryIfPlanTierIsNotT1 = nextPlanTier !== SubscriptionPlanTier.T1;
  const {
    multiUserOrganization,
    organizationAdmin,
    loadingMultiUserAndOrganizationAdminFeatures,
    errorOnFetchingMultiUserAndOrganizationAdminFeatures,
  } = useFetchHasMultiUserAndOrganizationAdminFeatures(ignoreQueryIfPlanTierIsNotT1);
  const {
    hasUserOrganizationOnlyOneMember,
    loadingUserOrganizationIsSingleMember,
    errorOnUserOrganizationIsSingleMember,
  } = useFetchHasUserOrganizationOnlyOneMember(ignoreQueryIfPlanTierIsNotT1);
  const loadingIsMultiUserOrganizationAllowedToSwitchPlan =
    loadingMultiUserAndOrganizationAdminFeatures || loadingUserOrganizationIsSingleMember;
  const errorOnFetchingIsMultiUserOrganizationAllowedToSwitchPlan =
    errorOnFetchingMultiUserAndOrganizationAdminFeatures || errorOnUserOrganizationIsSingleMember;

  if (nextPlanTier === SubscriptionPlanTier.T1) {
    return {
      isAllowedToSwitchPlan: multiUserOrganization && organizationAdmin && hasUserOrganizationOnlyOneMember,
      loadingIsMultiUserOrganizationAllowedToSwitchPlan,
      errorOnFetchingIsMultiUserOrganizationAllowedToSwitchPlan,
    };
  }
  return {
    isAllowedToSwitchPlan: true,
    loadingIsMultiUserOrganizationAllowedToSwitchPlan,
    errorOnFetchingIsMultiUserOrganizationAllowedToSwitchPlan,
  };
};
