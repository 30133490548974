/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Request } from "@taketurns/taketurns-graphql-repository";
import { useGetRequestFragment } from "@taketurns-repositories/collaboration/graphql/fragments/requestFragment";

export const useGetRequestRule = (requestId: string): Request | undefined => {
  const { data: request } = useGetRequestFragment(requestId);
  return request;
};
