/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useRecoilValue } from "recoil";
import { Party } from "@taketurns/taketurns-graphql-repository";
import { selectCollaborationFormInvitationsByParty } from "../../../recoil/collaborationCreation/form/values/selectCollaborationFormInvitationsByParty";

export const useGetCollaborationFormInvitationsByParty = (party: Party) => {
  return useRecoilValue(selectCollaborationFormInvitationsByParty(party));
};
