/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Divider, Stack, styled, Typography } from "@mui/material";
import { useUserPlanTranslation } from "@taketurns-i18n/user/UserPlans/useUserPlanTranslation";
import { SubscriptionPlanForDisplay } from "@taketurns-repositories/user/local/entities/subscriptionPlanForDisplay";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { SubscriptionPlanElementFeatures } from "./SubscriptionPlanElementFeatures";

import { SubscriptionPlanElementPricingAndDescription } from "./SubscriptionPlanElementPricingAndDescription";

export const SubscriptionPlanElement = (props: {
  subscriptionPlan: SubscriptionPlanForDisplay;
  isUserAuthenticated?: boolean;
}) => {
  const { subscriptionPlan, isUserAuthenticated } = props;

  const { t } = useUserPlanTranslation();

  const PlanName = () => (
    <Typography fontWeight={600} textTransform={"uppercase"}>
      {t(`plans.${subscriptionPlan.plan.planTier}`)}
    </Typography>
  );

  return (
    <PlanCardContainer>
      <PlanCardHeader backgroundColor={subscriptionPlan.color}>
        <PlanName />
      </PlanCardHeader>
      <Stack>
        <SubscriptionPlanElementPricingAndDescription
          subscriptionPlan={subscriptionPlan}
          isUserAuthenticated={isUserAuthenticated}
        />
        <Divider flexItem />
        <SubscriptionPlanElementFeatures
          planFeatureDescription={subscriptionPlan.planFeatureDescription}
          featureKeys={subscriptionPlan.featureKeys}
          comingSoonFeatureKeys={subscriptionPlan.comingSoonFeatureKeys}
        />
      </Stack>
    </PlanCardContainer>
  );
};

const PlanCardContainer = styled("div")({
  borderTopLeftRadius: 20,
  borderTopRightRadius: 20,
  backgroundColor: "white",
  display: "flex",
  flexDirection: "column",
  boxShadow: "0px 1px 20px 0px rgba(0, 0, 0, 0.05)",
  flex: "1 1 350px",
  overflowY: "auto",
});

const PlanCardHeader = styled("div", {
  shouldForwardProp: (prop) => prop !== "backgroundColor",
})((props: { backgroundColor?: string }) => ({
  fontSize: 20,
  fontWeight: 600,
  height: 48,
  minHeight: 48,
  textAlign: "center",
  color: "white",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderTopLeftRadius: 20,
  borderTopRightRadius: 20,
  backgroundColor: props.backgroundColor ?? TakeTurnsColors.grayButton,
}));
