/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useApolloClient } from "@apollo/client";

interface ReturnType {
  id: string;
  folders: { id: string }[];
}

export const useSubfolderIdsInFolderInCache = () => {
  const { cache } = useApolloClient();
  const fragment = gql`
    fragment SubfolderIdsInFolder on Folder {
      id
      folders {
        id
      }
    }
  `;
  return (parentFolderId: string) => {
    return cache.readFragment<ReturnType>({
      id: cache.identify({ __typename: "Folder", id: parentFolderId }),
      fragment,
      fragmentName: "SubfolderIdsInFolder",
    });
  };
};
