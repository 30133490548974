/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useQuery } from "@apollo/client";
import { Organization } from "@taketurns/taketurns-graphql-repository";

const USER_ORGANIZATION_AUTH_METHODS = gql`
  query userOrganization {
    userOrganization {
      id
      authenticationMethods
    }
  }
`;

export const useFetchUserOrganizationAuthenticationMethods = () => {
  const {
    data,
    loading: loadingAuthenticationMethods,
    error: errorOnLoadingAuthenticationMethods,
  } = useQuery<{
    userOrganization: Organization;
  }>(USER_ORGANIZATION_AUTH_METHODS);
  return {
    authenticationMethods: data?.userOrganization.authenticationMethods,
    loadingAuthenticationMethods,
    errorOnLoadingAuthenticationMethods,
  };
};
