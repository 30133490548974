/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useRecoilCallback } from "recoil";
import {
  folderByIdState,
  ROOT_FOLDER_ID,
} from "@taketurns-repositories/collaboration/state/recoil/collaborationCreation/folderByIdState";
import { pathInCreationState } from "@taketurns-repositories/collaboration/state/recoil/collaborationCreation/pathInCreationState";

export const useResetCollaborationContent = () => {
  return useRecoilCallback(
    ({ reset }) =>
      () => {
        reset(folderByIdState(ROOT_FOLDER_ID));
        reset(pathInCreationState);
      },
    [],
  );
};
