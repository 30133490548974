/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { atomFamily } from "recoil";
import { Upload } from "../models/upload";

export const uploadsByUploadIdState = atomFamily<Upload | undefined, number>({
  key: "uploadsByUploadId",
  default: undefined,
});
