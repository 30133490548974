/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql } from "@apollo/client";
import {
  AddAttachmentRevisionOutput,
  SubscriptionOnAddAttachmentRevisionArgs,
} from "@taketurns/taketurns-graphql-repository";
import { useRefreshableSubscription } from "@taketurns-components/webapp/QueriesAndSubscriptionsRefreshTrigger";
import { updateCacheOnAttachmentRevisionsAdded } from "@taketurns-repositories/collaboration/graphql/cache/updateCacheOnAttachmentRevisionAdded";
import { addAttachmentRevisionMutationFragment } from "@taketurns-repositories/collaboration/graphql/mutations/content/attachments/useAddAttachmentRevision";
import { COLLABORATION_CONTENT_METADATA_QUERY } from "@taketurns-repositories/collaboration/graphql/queries/content/useFetchCollaborationContentMetadata";
import { wait } from "@taketurns-rules/commons/util/wait";

const SUBSCRIBE_ON_ADD_ATTACHMENT_REVISION = gql`
  subscription onAddAttachmentRevision($collaborationId: ID!, $partyId: ID!, $attachmentId: ID!) {
    onAddAttachmentRevision(collaborationId: $collaborationId, partyId: $partyId, attachmentId: $attachmentId) {
      ...AddAttachmentRevisionMutationFragment
    }
  }
  ${addAttachmentRevisionMutationFragment}
`;

export const useSubscribeToAddAttachmentRevision = (args: SubscriptionOnAddAttachmentRevisionArgs) => {
  return useRefreshableSubscription<
    { onAddAttachmentRevision: AddAttachmentRevisionOutput },
    SubscriptionOnAddAttachmentRevisionArgs
  >(SUBSCRIBE_ON_ADD_ATTACHMENT_REVISION, {
    variables: args,
    onData: async ({ client, data }) => {
      await wait(3000);
      updateCacheOnAttachmentRevisionsAdded(client.cache, data.data.onAddAttachmentRevision);
      client.query({
        query: COLLABORATION_CONTENT_METADATA_QUERY,
        fetchPolicy: "network-only",
        variables: { collaborationId: args.collaborationId },
      });
    },
    onError: (error) => {
      console.error(error);
    },
  });
};
