/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Box, CircularProgress, IconButton, List, ListItem, Typography } from "@mui/material";
import { useEffect, useRef } from "react";
import { Upload } from "@taketurns-repositories/document/state/models/upload";
import { useGetUploadById } from "@taketurns-repositories/document/state/read/useGetUploadById";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { useCancelDocumentUploadRule } from "@taketurns-rules/document/commands/useCancelDocumentUploadRule";
import { useRetryDocumentUploadRule } from "@taketurns-rules/document/commands/useRetryDocumentUploadRule";
import { IconCheckCircle, IconRefreshCcw, IconX } from "../../../commons/icons";

const LI_HEIGHT_IN_PX = 40;
export const UploadsProgressList = ({ uploadIds }: { uploadIds: number[] }) => {
  const getUploadById = useGetUploadById();
  const latestUploadId = uploadIds[uploadIds.length - 1];

  return (
    <List
      disablePadding
      sx={{ paddingRight: 2, minWidth: 200, overflow: "auto", maxHeight: `${LI_HEIGHT_IN_PX * 5}px` }}
    >
      {[...uploadIds]
        .sort((a, b) => a - b)
        .map((uId) => (
          <UploadProgressItem key={uId} upload={getUploadById(uId)} />
        ))}
      <BottomScrollAnchor latestUploadId={latestUploadId} />
    </List>
  );
};

const BottomScrollAnchor = ({ latestUploadId }: { latestUploadId: number }) => {
  const bottomAnchorRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (bottomAnchorRef.current) {
      bottomAnchorRef.current.scrollIntoView(false);
    }
  }, [latestUploadId]);

  return <Box ref={bottomAnchorRef} />;
};

const UploadProgressItem = ({ upload }: { upload: Upload }) => {
  return (
    <ListItem
      disablePadding
      sx={{
        height: `${LI_HEIGHT_IN_PX}px`,
        display: "flex",
        direction: "row",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Typography
        sx={{ textDecoration: upload.isCancelled() ? "line-through" : "none" }}
        color={upload.hasFailed() ? "error" : "default"}
        variant="body2"
      >
        {upload.documentName}
      </Typography>
      <ProgressIcon upload={upload} />
    </ListItem>
  );
};

const ProgressIcon = ({ upload }: { upload: Upload }) => {
  const cancelDocumentUpload = useCancelDocumentUploadRule();
  const retryDocumentUpload = useRetryDocumentUploadRule();

  if (upload.hasFailed() || upload.isCancelled()) {
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {/*is the Box really necessary ?*/}
        <IconButton disableRipple onClick={() => retryDocumentUpload(upload)} sx={{ padding: 0 }}>
          <IconRefreshCcw color={TakeTurnsColors.error} size={20} />
        </IconButton>
      </Box>
    );
  }
  if (upload.isComplete()) {
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <IconCheckCircle color={TakeTurnsColors.success} size={20} />
      </Box>
    );
  }
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <IconButton size="small" disableRipple onClick={() => cancelDocumentUpload(upload)}>
        <IconX size={20} />
      </IconButton>
      <Box sx={{ position: "relative", display: "flex" }}>
        <CircularProgress variant="determinate" size={20} value={100} />
        <CircularProgress
          sx={{
            color: TakeTurnsColors.darkBlue,
            position: "absolute",
            left: 0,
          }}
          size={20}
          variant="determinate"
          value={upload.progress * 100}
        />
      </Box>
    </Box>
  );
};
