/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { CircularProgress, Divider, Stack, styled } from "@mui/material";
import { Fragment, useEffect } from "react";
import { PendingInvitation } from "@taketurns/taketurns-graphql-repository";
import { MobileCreateCollaborationFAB } from "@taketurns-components/collaboration/CollaborationCreation/CreateCollaborationButton/MobileCreateCollaborationFAB";
import { useNavigateToCollaborationCreationRule } from "@taketurns-rules/collaboration/commands/navigation/useNavigateToCollaborationCreationRule";
import { useNavigateToCollaborationViewRule } from "@taketurns-rules/collaboration/commands/navigation/useNavigateToCollaborationViewRule";
import { useSetListAppliedFilterRule } from "@taketurns-rules/collaboration/commands/useSetListAppliedFilterRule";
import { useFilteredCollaborationListRule } from "@taketurns-rules/collaboration/queries/useFilteredCollaborationListRule";
import { useGetListAppliedFilterRule } from "@taketurns-rules/collaboration/queries/useGetListAppliedFilterRule";
import { CollaborationFilterType } from "../collaborationFilterType";
import { NoCollaborationRowsOverlay } from "../NoCollaborationRowsOverlay";
import { MobileCollaborationInvitationRow } from "./MobileCollaborationListRow/MobileCollaborationInvitationRow";
import { MobileCollaborationRow } from "./MobileCollaborationListRow/MobileCollaborationRow";
import { MobileCollaborationPendingInvitationsNotification } from "./MobileCollaborationPendingInvitationsNotification";

export const MobileCollaborationList = () => {
  const { collaborations, errorOnCollaborationList, loadingCollaborationList } = useFilteredCollaborationListRule();

  const collaborationListAppliedFilter = useGetListAppliedFilterRule();
  const setAppliedCollaborationListFilter = useSetListAppliedFilterRule();

  const navigateToCollaborationView = useNavigateToCollaborationViewRule();
  const accessToSelectedCollaboration = (collaborationId: string) => {
    navigateToCollaborationView(collaborationId);
  };

  const navigateToCollaborationCreation = useNavigateToCollaborationCreationRule();

  const redirectToActiveCollaborations = () => {
    setAppliedCollaborationListFilter(CollaborationFilterType.activeCollaborations);
  };

  const redirectToPendingInvitations = () => {
    setAppliedCollaborationListFilter(CollaborationFilterType.pendingInvitations);
  };

  useEffect(() => {
    if (errorOnCollaborationList) {
      console.error(errorOnCollaborationList.message);
    }
  }, [errorOnCollaborationList]);

  if (loadingCollaborationList) {
    return (
      <CollaborationList__CollaborationsStack className="MobileCollaborationList">
        <MobileCollaborationPendingInvitationsNotification onClick={redirectToPendingInvitations} />
        <Stack flex={1} mx="auto" justifyContent="center">
          <CircularProgress />
        </Stack>
      </CollaborationList__CollaborationsStack>
    );
  }

  if (collaborations.length === 0) {
    return (
      <CollaborationList__CollaborationsStack className="MobileCollaborationList">
        <MobileCollaborationPendingInvitationsNotification onClick={redirectToPendingInvitations} />
        <NoCollaborationRowsOverlay
          createNewCollaborationAction={navigateToCollaborationCreation}
          displayActiveCollaborationAction={redirectToActiveCollaborations}
        />
      </CollaborationList__CollaborationsStack>
    );
  }

  if (collaborationListAppliedFilter === CollaborationFilterType.pendingInvitations) {
    return (
      <CollaborationList__CollaborationsStack className="MobileCollaborationList">
        <MobileCollaborationPendingInvitationsNotification onClick={redirectToPendingInvitations} />
        <Divider />
        {collaborations.map((collaboration) => (
          <Fragment>
            <MobileCollaborationInvitationRow
              collaboration={collaboration as unknown as PendingInvitation}
              key={collaboration.id}
              onClick={() => accessToSelectedCollaboration(collaboration.id)}
            />
            <Divider />
          </Fragment>
        ))}
        <MobileCreateCollaborationFAB />
      </CollaborationList__CollaborationsStack>
    );
  }

  const sortedCollaborations = [...collaborations].sort((a, b) => {
    return a.lastUpdateEvent.date > b.lastUpdateEvent.date ? -1 : 1;
  });

  return (
    <CollaborationList__CollaborationsStack className="MobileCollaborationList">
      <MobileCollaborationPendingInvitationsNotification onClick={redirectToPendingInvitations} />
      <Divider />
      {sortedCollaborations.map((collaboration) => (
        <Fragment key={collaboration.id}>
          <MobileCollaborationRow
            collaboration={collaboration}
            onClick={() => accessToSelectedCollaboration(collaboration.id)}
          />
          <Divider />
        </Fragment>
      ))}
      <MobileCreateCollaborationFAB />
    </CollaborationList__CollaborationsStack>
  );
};

const CollaborationList__CollaborationsStack = styled(Stack)({
  flex: 1,
  overflow: "auto",
});
