/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Upload } from "@taketurns-repositories/document/state/models/upload";
import { useAddUploadIdToRetriedUploadIds } from "@taketurns-repositories/document/state/write/useAddUploadIdToRetriedUploadIds";
import { useStartUploadRule } from "./useStartUploadRule";

export const useRetryDocumentUploadRule = () => {
  const startUpload = useStartUploadRule();
  const addUploadIdToRetriedUploadIds = useAddUploadIdToRetriedUploadIds();

  return (upload: Upload) => {
    addUploadIdToRetriedUploadIds(upload.uploadId);
    startUpload(upload.uploadId);
  };
};
