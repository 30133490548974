/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useEffect, useState } from "react";

export function useVisualViewportHeight() {
  const [viewportHeight, setViewportHeight] = useState<number | undefined>(undefined);
  useEffect(() => {
    function handleResize() {
      setViewportHeight(window.visualViewport?.height);
    }

    window.visualViewport?.addEventListener("resize", handleResize);
    handleResize();
    return () => window.visualViewport?.removeEventListener("resize", handleResize);
  }, []);
  return { viewportHeight, isVirtualKeyboardOpen: viewportHeight < 500 };
}
