/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Stack } from "@mui/material";
import { forwardRef } from "react";
import { Request } from "@taketurns/taketurns-graphql-repository";
import { ContentElementLastUpdate } from "@taketurns-components/collaboration/CollaborationView/CollaborationContent/shared/components/ContentElementLastUpdate";
import { ContextMenuOnButton } from "@taketurns-components/collaboration/ContextMenu/ContextMenuOnButton";
import { WithContextMenu } from "@taketurns-components/collaboration/ContextMenu/WithContextMenu";
import {
  RequestIconMarker,
  RequestUpdateMarker,
  RequestWordMarker,
} from "@taketurns-components/collaboration/Shared/content/Markers";
import { TakeTurnsListItem } from "@taketurns-components/collaboration/Shared/list/TakeTurnsListItem";
import { useFetchLastUpdateColor } from "@taketurns-rules/collaboration/queries/content/UseFetchLastUpdateColor";
import { useGetRequestContextMenuConfigurationRule } from "@taketurns-rules/collaboration/queries/request/useGetRequestContextMenuConfigurationRule";
import { ListItemTitle } from "../ListItemTitle";
import { RequestUploadChip } from "./RequestUploadChip";

interface DesktopRequestListItemProps {
  request: Request;
  shouldDisplayLastUpdate: boolean;
  shouldDisplayRequestMarker: boolean;
  shouldDisplayUploadChip: boolean;
  shouldDisplayUpdateMarker: boolean;
  shouldStrikeTitle: boolean;
  openRequestFulfillment: () => void;
  canConnectedUserFulFillRequest: boolean;
  contextMenuConfiguration: ReturnType<typeof useGetRequestContextMenuConfigurationRule> | null;
  backgroundColor: string;
}

export const DesktopRequestListItem = (props: DesktopRequestListItemProps) => {
  if (!props.contextMenuConfiguration) {
    return <InnerListItem {...props} />;
  }

  const ListItemWithContextMenu = WithContextMenu<DesktopRequestListItemProps>(
    InnerListItem,
    props.contextMenuConfiguration,
  );
  return <ListItemWithContextMenu {...props} />;
};

const InnerListItem = forwardRef<HTMLLIElement | null, DesktopRequestListItemProps>((props, ref) => {
  const { color: lastUpdateColor } = useFetchLastUpdateColor();
  const borderColor = props.shouldDisplayLastUpdate ? lastUpdateColor : props.backgroundColor;

  return (
    <TakeTurnsListItem
      ref={ref}
      onClick={props.canConnectedUserFulFillRequest ? props.openRequestFulfillment : undefined}
      titleSlot={
        <Stack direction="row" alignItems={"center"} spacing={1} overflow="hidden">
          <ListItemTitle title={props.request.name} shouldStrikeTitle={props.shouldStrikeTitle} />
          {props.shouldDisplayRequestMarker && <RequestIconMarker />}
          {props.shouldDisplayUpdateMarker && <RequestUpdateMarker request={props.request} />}
          {props.shouldDisplayUploadChip && <RequestUploadChip />}
        </Stack>
      }
      middleSlot={props.shouldDisplayLastUpdate && <ContentElementLastUpdate status={props.request.prevStatus} />}
      rightEndSlot={
        <Stack direction="row" alignItems="center">
          <RequestWordMarker />
          {props.contextMenuConfiguration && (
            <ContextMenuOnButton contextMenuConfiguration={props.contextMenuConfiguration} />
          )}
        </Stack>
      }
      sx={{
        borderRight: `8px solid ${borderColor}`,
        paddingRight: borderColor ? undefined : "18px",
        backgroundColor: props.backgroundColor,
        "&:hover": {
          borderRight: `8px solid ${borderColor}`,
        },
      }}
    />
  );
});

InnerListItem.displayName = "DesktopRequestListItem__InnerListItem";
