/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useCallback } from "react";
import { useGetCurrentFolderIdInCreation } from "@taketurns-repositories/collaboration/state/read/collaborationCreation/useGetCurrentFolderIdInCreation";
import { ROOT_FOLDER_ID } from "@taketurns-repositories/collaboration/state/recoil/collaborationCreation/folderByIdState";
import { useSetPathInCreation } from "@taketurns-repositories/collaboration/state/write/collaborationCreation/useSetPathInCreation";

export const useBrowseContentInCreationRule = () => {
  const setPathInCreation = useSetPathInCreation();
  const currentFolderId = useGetCurrentFolderIdInCreation();

  const moveIntoFolder = useCallback((folderId: string) => {
    setPathInCreation((path) => [...path, folderId]);
  }, []);

  const moveBackward = useCallback(() => {
    setPathInCreation((path) => path.slice(0, -1));
  }, []);

  return { isRootFolderSelected: currentFolderId === ROOT_FOLDER_ID, moveIntoFolder, moveBackward, currentFolderId };
};
