/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { selectorFamily, useRecoilValue } from "recoil";
import { selectUploadsByIdsState } from "./selectUploadsByIdsState";

const isAnyUploadsInProgressOrPendingState = selectorFamily({
  key: "isAnyUploadsInProgressOrPending",
  get:
    (uploadIds: number[]) =>
    ({ get }) => {
      const uploads = get(selectUploadsByIdsState(uploadIds));
      return uploads.some((upload) => upload!.isInProgress() || upload!.isPending());
    },
});
export const useIsAnyUploadsInProgressOrPending = (uploadIds: number[]) => {
  return useRecoilValue<boolean>(isAnyUploadsInProgressOrPendingState(uploadIds));
};
