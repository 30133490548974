/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useGetAttachmentInCreation } from "@taketurns-repositories/collaboration/state/read/collaborationCreation/useGetAttachmentInCreation";
import { IconArrowRightCircle, IconCancelReview, IconReview, IconTrash } from "../../../components/commons/icons";
import { useMarkAttachmentForReviewInCreationRule } from "../commands/collaborationCreation/attachment/useMarkAttachmentForReviewInCreationRule";
import { useRemoveAttachmentInCreationRule } from "../commands/collaborationCreation/attachment/useRemoveAttachmentInCreationRule";
import { useUnmarkAttachmentForReviewInCreationRule } from "../commands/collaborationCreation/attachment/useUnmarkAttachmentForReviewInCreationRule";

export const useGetAttachmentContextMenuConfigurationInCreationRule = (
  attachmentId: string,
  openMoveAttachmentDialog: () => void,
) => {
  const attachment = useGetAttachmentInCreation(attachmentId);
  const markAttachmentForReview = useMarkAttachmentForReviewInCreationRule(attachmentId);
  const unmarkAttachmentForReview = useUnmarkAttachmentForReviewInCreationRule(attachmentId);
  const removeAttachment = useRemoveAttachmentInCreationRule();

  const removeCurrentAttachment = () => {
    removeAttachment(attachmentId);
  };

  const contextMenuItems = [];
  const ICON_SIZE = 14;

  if (attachment.markedForReview) {
    contextMenuItems.push({
      labelKey: "contextMenu.attachment.unmarkForReview",
      icon: <IconCancelReview size={ICON_SIZE} />,
      action: unmarkAttachmentForReview,
    });
  } else {
    contextMenuItems.push({
      labelKey: "contextMenu.attachment.markForReview",
      icon: <IconReview size={ICON_SIZE} />,
      action: markAttachmentForReview,
    });
  }
  contextMenuItems.push({
    labelKey: "contextMenu.move",
    icon: <IconArrowRightCircle size={ICON_SIZE} />,
    action: openMoveAttachmentDialog,
  });

  contextMenuItems.push({
    labelKey: "contextMenu.remove",
    icon: <IconTrash size={ICON_SIZE} />,
    action: removeCurrentAttachment,
  });

  return { contextMenuItems };
};
