/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { MenuItem, Select, SelectChangeEvent, Stack, Typography } from "@mui/material";
import { useCountriesTranslation } from "@taketurns-i18n/user/UserPlans/useCountriesTranslation";
import { useUserPlanTranslation } from "@taketurns-i18n/user/UserPlans/useUserPlanTranslation";
import { availableCountries } from "@taketurns-repositories/user/local/countries";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { IconChevronDown } from "../../../../commons/icons";

interface RegionSelectorProps {
  selectedCountryCode: string | undefined;
  loading: boolean;
  selectRegion: (event: SelectChangeEvent) => void;
}

export const RegionSelector = (props: RegionSelectorProps) => {
  const { selectedCountryCode, loading, selectRegion } = props;

  const { t: countriesTranslations } = useCountriesTranslation();
  const { t: userPlanTranslations } = useUserPlanTranslation();

  const SelectedCountryRenderView = ({
    countryCode,
    image,
    name,
  }: {
    countryCode: string;
    image: string;
    name: string;
  }) => {
    return (
      <Stack direction="row" spacing={1} alignItems={"center"}>
        <img width={20} height={20} src={image} alt={name} />
        <Typography lineHeight={1} variant="body2">
          {countriesTranslations(countryCode)}
        </Typography>
      </Stack>
    );
  };

  const renderRegionSelectorValue = (value: string) => {
    const userCountryFoundInAvailableCountries = availableCountries.find((country) => country.code === value);
    if (loading) {
      return <Typography variant="caption">{userPlanTranslations("regionSelector.loadingRegion")}</Typography>;
    }
    if (!selectedCountryCode) {
      return (
        <Typography variant="caption" color={"error"}>
          {userPlanTranslations("regionSelector.selectRegion")}
        </Typography>
      );
    }
    if (!userCountryFoundInAvailableCountries) {
      return (
        <Typography variant="caption" color={"error"}>
          {userPlanTranslations("regionSelector.notAvailable")}
        </Typography>
      );
    }
    return (
      <SelectedCountryRenderView
        countryCode={userCountryFoundInAvailableCountries.code}
        image={userCountryFoundInAvailableCountries.image}
        name={userCountryFoundInAvailableCountries.name}
      />
    );
  };

  return (
    <Select
      value={selectedCountryCode}
      onChange={selectRegion}
      displayEmpty
      error={!availableCountries.some((country) => country.code === selectedCountryCode) && !loading}
      sx={{ backgroundColor: "white", flex: { xs: 1, md: "initial" } }}
      IconComponent={(iconProps) => <IconChevronDown size={16} color={TakeTurnsColors.darkGray} {...iconProps} />}
      renderValue={renderRegionSelectorValue}
    >
      {availableCountries.map((country) => (
        <MenuItem key={country.code} value={country.code} sx={{ fontSize: 14 }}>
          <SelectedCountryRenderView countryCode={country.code} image={country.image} name={country.name} />
        </MenuItem>
      ))}
    </Select>
  );
};
