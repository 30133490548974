/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useGetUnreadMessageCountByCollaborationIdRule } from "@taketurns-rules/chat/queries/useGetUnreadMessageByCollaborationIdRule";
import { useGetSidebarOpenedStatusRule } from "@taketurns-rules/webapp/queries/useGetSidebarOpenedStatusRule";
import { IconMessageCircle } from "../../commons/icons";
import { UnreadMessagesBadge } from "../ChatBubbleWithUnreadMessageBadge/UnreadMessagesBadge";

export const ChatSidebarButton = ({ collaborationId }: { collaborationId: string }) => {
  const { unreadCount } = useGetUnreadMessageCountByCollaborationIdRule(collaborationId);
  const isSidebarOpened = useGetSidebarOpenedStatusRule();
  return (
    <UnreadMessagesBadge unreadCount={unreadCount} invisible={isSidebarOpened}>
      <IconMessageCircle size={24} />
    </UnreadMessagesBadge>
  );
};
