/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { atom, selector, useRecoilCallback } from "recoil";
import { Upload } from "../models/upload";
import { uploadsByUploadIdState } from "../recoil/uploadsByUploadIdState";

const uploadIdsState = atom<Set<number>>({
  key: "uploadIds",
  default: new Set<number>(),
});

const selectNextAvailableIdState = selector({
  key: "nextAvailableId",
  get: ({ get }) => {
    const uploadIds = get(uploadIdsState);
    return [...uploadIds].reduce((currentId, maxId) => (currentId > maxId ? currentId : maxId), 0) + 1;
  },
});
export const useStoreNewUpload = (
  isUploadedFromPdfViewer?: boolean,
  uploadMethod?: (uploadId: number) => Promise<string>,
) => {
  return useRecoilCallback(
    ({ snapshot, set }) =>
      (file?: File, documentId?: string, documentName?: string): Upload => {
        if (!documentName && file) {
          documentName = file.name;
        }
        const nextAvailableId = snapshot.getLoadable(selectNextAvailableIdState).contents;
        const newUpload: Upload = new Upload(
          nextAvailableId,
          documentId!,
          documentName,
          isUploadedFromPdfViewer,
          uploadMethod,
          file,
        );
        set(uploadIdsState, (currentUploadIds) => new Set([...currentUploadIds, nextAvailableId]));
        set(uploadsByUploadIdState(nextAvailableId), newUpload);
        return newUpload;
      },
    [uploadMethod, isUploadedFromPdfViewer],
  );
};
