/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Box, styled } from "@mui/material";
import { SubscriptionPlanTier } from "@taketurns/taketurns-graphql-repository";
import { FetchLoadingManager } from "@taketurns-app/utils/FetchLoadingManager";
import { SomethingWentWrong } from "@taketurns-components/shared/SomethingWentWrong";
import { UpgradePlanProposition } from "@taketurns-components/shared/UpgradePlanProposition";
import { useOrganizationTranslation } from "@taketurns-i18n/user/Organization/useOrganizationTranslation";

import { useFetchUserHasMultiUserOrganizationFeatureRule } from "@taketurns-rules/user/queries/useFetchUserHasMultiUserOrganizationFeatureRule";
import { useFetchUserHasOrganizationAdminFeatureRule } from "@taketurns-rules/user/queries/useFetchUserHasOrganizationAdminFeatureRule";
import { OrganizationMemberAndInvitationList } from "../OrganizationMemberAndInvitationList/OrganizationMemberAndInvitationList";
import { OrganizationInviteMemberForm } from "./OrganizationInviteMemberForm";
import { OrganizationMainInformation } from "./OrganizationMainInformation";

export const UserOrganizationSettings = () => {
  const { t } = useOrganizationTranslation();

  const { userHasOrganizationAdminFeature, userHasMultiUserOrganizationFeature, loading, error } =
    useFetchUserOrganizationSettingsData();

  if (error) {
    console.error(error);
    return <SomethingWentWrong />;
  }

  const isAllowedToManageUsers = userHasMultiUserOrganizationFeature && userHasOrganizationAdminFeature;
  const userHasToUpgradePlanForMultiUserOrganization = !userHasMultiUserOrganizationFeature;

  if (loading) {
    return (
      <UserOrganizationSettings__Container className={"UserOrganizationSettings__Container"}>
        <OrganizationMainInformation />
      </UserOrganizationSettings__Container>
    );
  }

  if (userHasToUpgradePlanForMultiUserOrganization) {
    return (
      <UserOrganizationSettings__Container className={"UserOrganizationSettings__Container"}>
        <OrganizationMainInformation />
        <UpgradePlanProposition
          proposition={t("OrganizationUserListOrUpgradePlan.upgradeToPlusPlanDescription")}
          planTier={SubscriptionPlanTier.T2}
        />
      </UserOrganizationSettings__Container>
    );
  }

  if (isAllowedToManageUsers) {
    return (
      <UserOrganizationSettings__Container className={"UserOrganizationSettings__Container"}>
        <OrganizationMainInformation />
        <OrganizationInviteMemberForm />
        <OrganizationMemberAndInvitationList />
      </UserOrganizationSettings__Container>
    );
  }

  return (
    <UserOrganizationSettings__Container className={"UserOrganizationSettings__Container"}>
      <OrganizationMainInformation />
    </UserOrganizationSettings__Container>
  );
};

function useFetchUserOrganizationSettingsData() {
  const { userHasOrganizationAdminFeature, errorOnHasOrganizationAdminFeature, loadingHasOrganizationAdminFeature } =
    useFetchUserHasOrganizationAdminFeatureRule();

  const {
    userHasMultiUserOrganizationFeature,
    loadingHasMultiUserOrganizationFeature,
    errorOnHasMultiUserOrganizationFeature,
  } = useFetchUserHasMultiUserOrganizationFeatureRule();

  const fetchLoadingManager = new FetchLoadingManager(
    [loadingHasOrganizationAdminFeature, loadingHasMultiUserOrganizationFeature],
    [errorOnHasOrganizationAdminFeature, errorOnHasMultiUserOrganizationFeature],
  );
  return {
    userHasOrganizationAdminFeature,
    userHasMultiUserOrganizationFeature,
    loading: fetchLoadingManager.hasFetchLoading(),
    error: fetchLoadingManager.hasFetchInError(),
  };
}

const UserOrganizationSettings__Container = styled(Box)({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  gap: "16px",
  overflow: "auto",
});
