/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { gql, useLazyQuery } from "@apollo/client";

const IS_COLLABORATION_FROM_USER_ORGANIZATION = gql`
  query isCollaborationFromUserOrganization($collaborationId: ID!) {
    isCollaborationFromUserOrganization(collaborationId: $collaborationId)
  }
`;
export const useFetchIsCollaborationFromUserOrganization = () => {
  const [fetchIsCollaborationFromUserOrganizationQuery] = useLazyQuery<{
    isCollaborationFromUserOrganization: boolean;
  }>(IS_COLLABORATION_FROM_USER_ORGANIZATION, {
    fetchPolicy: "no-cache",
  });
  return async (collaborationId: string) => {
    return (await fetchIsCollaborationFromUserOrganizationQuery({ variables: { collaborationId } })).data
      .isCollaborationFromUserOrganization;
  };
};
