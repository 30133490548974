/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Box } from "@mui/material";
import { PropsWithChildren, useMemo } from "react";
import { useAttachFilesInCurrentFolderRule } from "@taketurns-rules/collaboration/commands/collaborationView/attachment/useAttachFilesInCurrentFolderRule";
import { DropZoneConfig, FilesDragAndDrop } from "../../../Shared/FilesDragAndDrop";

export const ContentWithDropZone = ({ children }: PropsWithChildren) => {
  const attachFilesRule = useAttachFilesInCurrentFolderRule();

  const dropZoneWithBackdropConfig: DropZoneConfig = useMemo(
    () => ({
      handleFilesDropping: async (files: File[]) => {
        await attachFilesRule(Array.from(files));
      },
      className: "ContentWithDropZone__DropZoneContainer",
      sx: {
        flex: 1,
        position: "relative",
        display: "flex",
        flexDirection: "column",
      },
    }),
    [attachFilesRule],
  );

  return (
    <FilesDragAndDrop ContainerComponent={Box} dropZoneConfig={dropZoneWithBackdropConfig}>
      {children}
    </FilesDragAndDrop>
  );
};
