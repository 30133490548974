/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useQuery } from "@apollo/client";
import { Collaboration } from "@taketurns/taketurns-graphql-repository";

const CLOSING_USER = gql`
  query getClosingUser($collaborationId: ID!) {
    getCollaboration(collaborationId: $collaborationId) {
      id
      closingUser {
        id
        firstName
        lastName
        email
        avatar
        jobTitle
      }
    }
  }
`;

export const useFetchCollaborationClosingUser = (collaborationId: string) => {
  const {
    data,
    error: errorOnFetchingClosingUser,
    loading: loadingClosingUser,
  } = useQuery<{
    getCollaboration: Collaboration;
  }>(CLOSING_USER, {
    variables: { collaborationId },
  });
  return { closingUser: data?.getCollaboration.closingUser, errorOnFetchingClosingUser, loadingClosingUser };
};
