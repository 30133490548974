/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { CSSProperties } from "react";
import { useCollaborationTranslation } from "@taketurns-i18n/collaboration/useCollaborationTranslation";

import { TIP_KEYS } from "@taketurns-i18n/commons/TIP_KEYS";
import { TipVariant } from "../../../../commons/message/Tip";
import { UserTip } from "../../../../user/UserTip/UserTip";

export const WrapUpDescriptionTip = ({ style }: { style?: CSSProperties }) => {
  const { t } = useCollaborationTranslation("tips");
  return (
    <UserTip
      style={style}
      variant={TipVariant.warning}
      message={t(TIP_KEYS.WRAP_UP_DESCRIPTION)}
      tipKey={TIP_KEYS.WRAP_UP_DESCRIPTION}
    />
  );
};
