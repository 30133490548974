/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql } from "@apollo/client";
import {
  MoveOutput,
  SubscriptionOnMoveAttachmentIntoDestinationFolderArgs,
} from "@taketurns/taketurns-graphql-repository";
import { useRefreshableSubscription } from "@taketurns-components/webapp/QueriesAndSubscriptionsRefreshTrigger";
import { moveAttachmentMutationFragment } from "@taketurns-repositories/collaboration/graphql/mutations/content/attachments/useMoveAttachmentIntoFolderMutation";

const SUBSCRIBE_TO_MOVE_ATTACHMENT_INTO_DESTINATION_FOLDER = gql`
  subscription onMoveAttachmentIntoDestinationFolder($collaborationId: ID!, $partyId: ID!, $destinationFolderId: ID!) {
    onMoveAttachmentIntoDestinationFolder(
      collaborationId: $collaborationId
      partyId: $partyId
      destinationFolderId: $destinationFolderId
    ) {
      ...MoveAttachmentMutationFragment
    }
  }
  ${moveAttachmentMutationFragment}
`;

export const useSubscribeToMoveAttachmentIntoDestinationFolder = (
  args: SubscriptionOnMoveAttachmentIntoDestinationFolderArgs,
) => {
  return useRefreshableSubscription<
    { onMoveAttachmentIntoDestinationFolder: MoveOutput },
    SubscriptionOnMoveAttachmentIntoDestinationFolderArgs
  >(SUBSCRIBE_TO_MOVE_ATTACHMENT_INTO_DESTINATION_FOLDER, { variables: args });
};
