/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { ListItem, Stack, styled, Typography } from "@mui/material";
import { PropsWithChildren } from "react";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { TakeTurnsSwitch } from "../../commons/switch/TakeTurnsSwitch";

interface FormListSwitchItemProps {
  label: string;
  checked: boolean;
  onClick: () => void;
}

export const FormListSwitchItem = ({
  label,
  checked,
  onClick,
  children,
}: PropsWithChildren<FormListSwitchItemProps>) => {
  return (
    <FormListSwitchItem__Container divider disablePadding>
      <FormListSwitchItem__LabelAndSwitchContainer>
        <FormListSwitchItem__Typography>{label}</FormListSwitchItem__Typography>
        <TakeTurnsSwitch checked={checked} onClick={onClick} />
      </FormListSwitchItem__LabelAndSwitchContainer>
      {children}
    </FormListSwitchItem__Container>
  );
};

const FormListSwitchItem__Container = styled(ListItem)(({ theme }) => ({
  [theme.breakpoints.up("sm")]: {
    flexDirection: "row",
    justifyContent: "space-between",
    minHeight: 50,
  },
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    gap: theme.spacing(1),
    padding: "5px",
    minHeight: 65,
  },
}));

const FormListSwitchItem__LabelAndSwitchContainer = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  [theme.breakpoints.down("sm")]: {
    justifyContent: "space-between",
    width: "100%",
  },
}));

const FormListSwitchItem__Typography = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  color: TakeTurnsColors.veryDarkGray,
  [theme.breakpoints.up("sm")]: {
    flex: "0 0 200px",
    color: TakeTurnsColors.darkGray,
  },
}));
