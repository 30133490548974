/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useMoveAttachmentIntoFolderInCreation } from "@taketurns-repositories/collaboration/state/write/useMoveAttachmentIntoFolderInCreation";

export function useMoveAttachmentIntoFolderInCreationRule(attachmentId: string) {
  const moveAttachmentIntoFolder = useMoveAttachmentIntoFolderInCreation(attachmentId);
  return (destinationFolderId: string) => {
    moveAttachmentIntoFolder(destinationFolderId);
  };
}
