/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { CreateInviteeInput, Party, Role } from "@taketurns/taketurns-graphql-repository";
import { useGetCollaborationForm } from "@taketurns-repositories/collaboration/state/read/collaborationCreation/form/useGetCollaborationForm";
import { CollaborationFormErrors } from "@taketurns-repositories/collaboration/state/recoil/collaborationCreation/form/errors/collaborationFormErrorsState";
import { useSetCollaborationFormErrorsRule } from "./useSetCollaborationFormErrorsRule";

export const useValidateCollaborationFormAndGetIsValidFormRule = () => {
  const setCollaborationFormErrors = useSetCollaborationFormErrorsRule();
  const collaborationForm = useGetCollaborationForm();

  return () => {
    const collaborationFormErrorsValidation: CollaborationFormErrors = {
      name: false,
      owningPartyName: false,
      invitedPartyName: false,
      invitations: false,
      files: false,
      requests: false,
    };

    if (collaborationForm.name.length === 0) {
      collaborationFormErrorsValidation.name = true;
    }
    if (collaborationForm.owningPartyName.length === 0) {
      collaborationFormErrorsValidation.owningPartyName = true;
    }
    if (collaborationForm.invitedPartyName.length === 0) {
      collaborationFormErrorsValidation.invitedPartyName = true;
    }
    collaborationFormErrorsValidation.invitations = !collaborationForm.invitations.some(
      (i: CreateInviteeInput) => i.party === Party.Invited && i.role === Role.Owner,
    );
    setCollaborationFormErrors(collaborationFormErrorsValidation);
    return { isValid: Object.values(collaborationFormErrorsValidation).every((errorValue) => !errorValue) };
  };
};
