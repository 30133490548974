/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Stack, TextField } from "@mui/material";
import { DomainValidator } from "commons-validator-js";
import { useState } from "react";
import { useOrganizationTranslation } from "@taketurns-i18n/user/Organization/useOrganizationTranslation";
import { useAddOrganizationDomainRule } from "@taketurns-rules/user/commands/useAddOrganizationDomainRule";
import { useFetchUserOrganizationDomainsRule } from "@taketurns-rules/user/queries/useFetchUserOrganizationDomainsRule";
import { TakeTurnsDefaultButton } from "../../../commons/button";

export const OrganizationAddDomainForm = () => {
  const { domains } = useFetchUserOrganizationDomainsRule();
  const { addOrganizationDomain } = useAddOrganizationDomainRule();
  const [domainName, setDomainName] = useState<string>("");

  const { t } = useOrganizationTranslation();

  const clearInput = () => {
    setDomainName("");
  };

  const addDomain = () => {
    addOrganizationDomain({ name: domainName });
    clearInput();
  };

  const domainAlreadyAdded = domains.some((d) => d.name === domainName);
  const disabledIfNotValidEmailOrInvitationAlreadyExist = domainAlreadyAdded || !validateDomain(domainName);

  return (
    <Stack spacing={2}>
      <Stack direction="row" spacing={2} alignItems="center">
        <TextField
          value={domainName}
          placeholder={t("OrganizationAddDomainForm.placeholder")}
          onChange={(e) => setDomainName(e.target.value)}
          data-cy="organizationAddDomainForm__nameField"
        />
        <TakeTurnsDefaultButton
          sx={{ fontSize: 12, lineHeight: 0 }}
          disabled={disabledIfNotValidEmailOrInvitationAlreadyExist}
          onClick={addDomain}
          data-cy="organizationAddDomainForm__addButton"
        >
          {t("OrganizationAddDomainForm.button")}
        </TakeTurnsDefaultButton>
      </Stack>
    </Stack>
  );
};

const validateDomain = (domain: string | undefined): boolean => {
  if (!domain) return false;
  return new DomainValidator({ allowLocal: false }).isValid(domain);
};
