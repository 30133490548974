/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

export enum PendingAttachmentStatus {
  initiated = "initiated",
  uploading = "uploading",
  attaching = "attaching",
  attached = "attached",
  cancelled = "cancelled",
}
