/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useMutation } from "@apollo/client";
import { Collaboration, MutationEditCollaborationArgs } from "@taketurns/taketurns-graphql-repository";

export const useEditCollaboration = () => {
  const mutation = gql`
    mutation editCollaboration($collaborationId: ID!, $input: EditCollaborationInput!) {
      editCollaboration(collaborationId: $collaborationId, input: $input) {
        id
        description
        name
        deadline
        invitedPartyName
        owningPartyName
      }
    }
  `;
  return useMutation<{ editCollaboration: Collaboration }, MutationEditCollaborationArgs>(mutation);
};
