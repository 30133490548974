/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useFetchCollaborationClosingUser } from "@taketurns-repositories/collaboration/graphql/queries/fields/useFetchCollaborationClosingUser";
import { useGetDisplayedCollaborationIdRule } from "@taketurns-rules/collaboration/queries/collaboration/useGetDisplayedCollaborationIdRule";

export const useFetchCollaborationClosingUserRule = () => {
  const collaborationId = useGetDisplayedCollaborationIdRule();
  return useFetchCollaborationClosingUser(collaborationId);
};
