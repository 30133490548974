/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { MarketingData } from "@taketurns/taketurns-graphql-repository";

const TAKETURNS_MARKETING_DATA = "taketurns_marketing_data";

export const setMarketingData = (marketingData: string) => {
  sessionStorage.setItem(TAKETURNS_MARKETING_DATA, marketingData);
};

export const clearMarketingData = () => {
  sessionStorage.removeItem(TAKETURNS_MARKETING_DATA);
};

export const getMarketingData = () => {
  return sessionStorage.getItem(TAKETURNS_MARKETING_DATA);
};

export const getAndClearMarketingData: () => null | MarketingData = () => {
  const marketingData = getMarketingData();
  clearMarketingData();
  return marketingData ? JSON.parse(marketingData) : null;
};
