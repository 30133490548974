/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { PendingInvitation } from "@taketurns/taketurns-graphql-repository";
import { InvitingUserInformation } from "./InvitingUserInformation";

export const InvitingUserCell = ({ pendingInvitation }: { pendingInvitation: PendingInvitation }) => {
  const invitingUser = pendingInvitation.invitingUser;
  if (!invitingUser) {
    return null;
  }

  return <InvitingUserInformation invitingUser={invitingUser} />;
};
