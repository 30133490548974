/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useSharedTranslation } from "@taketurns-i18n/shared/useSharedTranslation";

export const useResolveErrorMessageToBeDisplayedRule = (errorMessage: string) => {
  const { t } = useSharedTranslation();
  let message = t("error.technical");
  const errorCodes = errorMessage.match(/TT_[0-9]{5}/);
  if (errorCodes) {
    message = t(`error.${errorCodes[0]}`);
  }
  return message;
};
