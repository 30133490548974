/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { CircularProgress, Stack, styled, Typography } from "@mui/material";
import { TakeTurnsDefaultButton } from "@taketurns-components/commons/button";
import { useWebAppTranslations } from "@taketurns-i18n/webapp/useWebAppTranslations";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { useIsOnMobileRule } from "@taketurns-rules/commons/theme/useIsOnMobileRule";
import { useDecodeParamsInCurrentUrlRule } from "@taketurns-rules/webapp/queries/useDecodeParamsInCurrentUrlRule";
import { PasswordlessCollaborationAccessParameters } from "../PasswordlessCollaborationAccess";

export const SendCodeSection = ({
  recipientEmail,
  verifyAccount,
  loading,
}: {
  recipientEmail: string;
  verifyAccount: () => void;
  loading: boolean;
}) => {
  const { t } = useWebAppTranslations("passwordlessCollaborationAccess");
  const { isInvitationLink } = useDecodeParamsInCurrentUrlRule<PasswordlessCollaborationAccessParameters>()!;
  const isOnMobile = useIsOnMobileRule();

  return (
    <SendCodeSection__VerifyStack>
      <Typography variant="body2" textAlign="center">
        {isInvitationLink ? t("inviteToCollaborate.invitationSentTo") : t("accessToCollaboration.accessingAs")}{" "}
        <Typography variant="body2" component="span" color={TakeTurnsColors.darkBlue} fontWeight={600}>
          {recipientEmail}
        </Typography>
      </Typography>
      <Typography variant="body2">{`${t("verifyYourIdentity")}`}</Typography>
      <TakeTurnsDefaultButton
        disabled={loading}
        sx={{ mt: { xs: 0, md: 8 } }}
        onClick={verifyAccount}
        startIcon={loading ? <CircularProgress size={16} color={"secondary"} /> : undefined}
        fullWidth={isOnMobile}
      >
        {t("sendVerificationCode")}
      </TakeTurnsDefaultButton>
    </SendCodeSection__VerifyStack>
  );
};

const SendCodeSection__VerifyStack = styled(Stack)(({ theme }) => ({
  flex: 2,
  alignItems: "center",
  justifyContent: "flex-start",
  [theme.breakpoints.down("md")]: {
    flexGrow: 0,
    gap: theme.spacing(3),
  },
}));
