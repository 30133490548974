/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Stack, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import { SingleColumnLayout } from "@taketurns-app/layouts/body/content/SingleColumnLayout";
import { useAuthContext } from "@taketurns-app/routing/AuthProvider";
import { TakeTurnsDefaultButton } from "@taketurns-components/commons/button";
import { ExternalLinkStyled } from "@taketurns-components/webapp/LinkStyled";
import { useWebAppTranslations } from "@taketurns-i18n/webapp/useWebAppTranslations";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { useGetHelpCenterUrls } from "@taketurns-rules/webapp/queries/useGetHelpCenterUrls";

export const CollaborationInvitationEmailMismatch = () => {
  const { t } = useWebAppTranslations("collaboration");

  return (
    <SingleColumnLayout
      header={<Typography variant="h6">{t("checkEmailAddress")}</Typography>}
      content={<MismatchBetweenCollaborationMailViewContent />}
    />
  );
};

const MismatchBetweenCollaborationMailViewContent = () => {
  const { t } = useWebAppTranslations("collaboration");
  const {
    state: { invitationEmail },
  } = useLocation();
  const { logout } = useAuthContext();
  const { helpCenterUrl } = useGetHelpCenterUrls();
  return (
    <Stack direction="row" height={1} width={1} alignItems="center" justifyContent="center" spacing={2.5}>
      <Stack
        direction="column"
        height={1}
        width={1}
        maxWidth={"800px"}
        alignItems="center"
        justifyContent="center"
        spacing={2.5}
      >
        <Stack height={1} alignItems="center" justifyContent="center" spacing={2.5} width={"70%"}>
          <Typography textAlign="center">
            {t("checkEmailMessage.textBeforeEmail")} <b>{invitationEmail}</b> {t("checkEmailMessage.textAfterEmail")}
          </Typography>
          <TakeTurnsDefaultButton onClick={() => logout()}>
            {t("checkEmailMessage.useAnotherAccountButton")}
          </TakeTurnsDefaultButton>
        </Stack>
        <Typography color={TakeTurnsColors.darkGray}>
          {t("checkEmailMessage.helperCenterBeforeLink")}{" "}
          <ExternalLinkStyled href={helpCenterUrl} target="_blank">
            {t("checkEmailMessage.helpCenterLink")}{" "}
          </ExternalLinkStyled>
          {t("checkEmailMessage.helpCenterAfterLink")}
        </Typography>
      </Stack>
    </Stack>
  );
};
