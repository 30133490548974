/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Attachment } from "@taketurns/taketurns-graphql-repository";
import { useGetAttachmentFragment } from "@taketurns-repositories/collaboration/graphql/fragments/attachmentFragment";

export const useGetAttachmentRule = (attachmentId: string) => {
  const { data: attachment } = useGetAttachmentFragment(attachmentId);
  return attachment as Attachment;
};
