/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useMutation } from "@apollo/client";

export const useModifyConnectedUserOrganizationName = () => {
  const MODIFY_USER_ORGANIZATION_NAME = gql`
    mutation modifyConnectedUserOrganizationName($name: String!) {
      modifyConnectedUserOrganizationName(name: $name) {
        id
        name
      }
    }
  `;
  const [modifyConnectedUserOrganizationNameMutation, modifyConnectedUserOrganizationNameMutationResult] =
    useMutation(MODIFY_USER_ORGANIZATION_NAME);

  const modifyConnectedUserOrganizationName = (name: string, organizationId?: string) => {
    modifyConnectedUserOrganizationNameMutation({
      variables: { name },
      optimisticResponse: organizationId && {
        modifyConnectedUserOrganizationName: {
          id: organizationId,
          name: name,
          __typename: "Organization",
        },
      },
    });
  };

  return {
    modifyConnectedUserOrganizationName,
    loadingModifyConnectedUserOrganizationName: modifyConnectedUserOrganizationNameMutationResult.loading,
  };
};
