/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useSetRecoilState } from "recoil";
import { CreateInviteeInput } from "@taketurns/taketurns-graphql-repository";
import { selectCollaborationFormInvitations } from "../../../../recoil/collaborationCreation/form/values/selectCollaborationFormInvitations";

export const useInviteToCollaborationInCreation = () => {
  const setCollaborationFormInvitations = useSetRecoilState(selectCollaborationFormInvitations);

  return (invitation: CreateInviteeInput) => {
    setCollaborationFormInvitations((existingInvitations) => [...existingInvitations, invitation]);
  };
};
