/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Box, styled } from "@mui/material";
import { ContentStatus } from "@taketurns/taketurns-graphql-repository";
import { ContentElementLastUpdate } from "@taketurns-components/collaboration/CollaborationView/CollaborationContent/shared/components/ContentElementLastUpdate";
import { useFetchLastUpdateColor } from "@taketurns-rules/collaboration/queries/content/UseFetchLastUpdateColor";

export const ContentElementLastUpdateInCard = ({ status }: { status: ContentStatus }) => {
  const { color } = useFetchLastUpdateColor();

  return (
    <ContentElementLastUpdate__Container color={color}>
      <ContentElementLastUpdate status={status} />
    </ContentElementLastUpdate__Container>
  );
};

export const ContentElementLastUpdate__Container = styled(Box, {
  shouldForwardProp: (prop) => prop !== "color",
})(({ color }: { color: string }) => ({
  borderTop: "none",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  borderBottom: `2px solid ${color}`,
  padding: "0 10px",
  height: 20,
  minHeight: 20,
  width: "100%",
  color,
}));
