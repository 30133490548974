/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useRecoilCallback, useRecoilValue } from "recoil";
import { RequestInCreation } from "../../../../models/requestInCreation";
import { folderByIdState } from "../../../recoil/collaborationCreation/folderByIdState";
import { parentFolderIdByRequestIdState } from "../../../recoil/collaborationCreation/parentFolderIdByRequestIdState";
import { pathInCreationState } from "../../../recoil/collaborationCreation/pathInCreationState";
import { requestByIdState } from "../../../recoil/collaborationCreation/requestByIdState";

export const useAddRequestInCreation = () => {
  const path = useRecoilValue(pathInCreationState);

  return useRecoilCallback(
    ({ set }) =>
      (requestName: string, requestDescription?: string) => {
        const targetFolderId = path.at(-1);
        const requestId = `${targetFolderId}/${requestName}_request`;
        const requestInCreation: RequestInCreation = {
          name: requestName,
          description: requestDescription,
        };
        set(requestByIdState(requestId), requestInCreation);
        set(folderByIdState(targetFolderId), (currentFolder) => ({
          ...currentFolder,
          requests: [...currentFolder.requests, requestId],
        }));
        set(parentFolderIdByRequestIdState(requestId), targetFolderId);
      },
    [path],
  );
};
