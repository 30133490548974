/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Box, Stack, styled } from "@mui/material";

export const ColumnPaper = styled(Stack)(({ theme }) => ({
  flex: 1,
  boxShadow: "0 1px 20px 0 rgba(0, 0, 0, 0.05)",
  borderTopLeftRadius: 20,
  borderTopRightRadius: 20,
  backgroundColor: "white",
  padding: 24,
  paddingTop: 32,
  width: "100%",
  overflow: "hidden",

  [theme.breakpoints.down("md")]: {
    boxShadow: "initial",
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: 0,
  },
}));

export const Column = styled(Stack)({
  flex: 1,
  position: "relative",
  alignItems: "center",
});

export const HeaderChip = styled(Box)(({ backgroundColor }: { backgroundColor: string }) => ({
  position: "absolute",
  top: "-12px",
  backgroundColor: backgroundColor,
  borderRadius: "10px",
  padding: "0 12px",
  width: "fit-content",
  color: "white",
  fontSize: 14,
  fontWeight: 600,
}));
