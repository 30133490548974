/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql } from "@apollo/client";

export const userFragment = gql`
  fragment UserFields on User {
    id
    firstName
    lastName
    email
    avatar
    jobTitle
  }
`;
