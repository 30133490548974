/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useRecoilCallback } from "recoil";
import { unreadCountByChannelIdState } from "../recoil/unreadCountByChannelIdState";

export const useSetChannelUnreadCount = () => {
  return useRecoilCallback(
    ({ set }) =>
      (channelId: string, count: number) => {
        set(unreadCountByChannelIdState(channelId), count);
      },
    [],
  );
};
