/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useFetchUserHasOrganizationAdminFeatureRule } from "./useFetchUserHasOrganizationAdminFeatureRule";
import { useFetchUserOrganizationMembersRule } from "./useFetchUserOrganizationMembersRule";

export const useFetchUserIsAllowedToJoinOtherOrganizationRule = () => {
  const { userHasOrganizationAdminFeature, loadingHasOrganizationAdminFeature, errorOnHasOrganizationAdminFeature } =
    useFetchUserHasOrganizationAdminFeatureRule();
  const { members, loadingUserOrganizationMembers, errorOnLoadingUserOrganizationMembers } =
    useFetchUserOrganizationMembersRule();
  const loadingUserIsAllowedToJoinOtherOrganization =
    loadingUserOrganizationMembers || loadingHasOrganizationAdminFeature;
  const errorOnFetchUserIsAllowedToJoinOtherOrganization =
    errorOnHasOrganizationAdminFeature || errorOnLoadingUserOrganizationMembers;
  const userIsAllowedToJoinOtherOrganization = userHasOrganizationAdminFeature && members.length === 1;

  return {
    userIsAllowedToJoinOtherOrganization,
    loadingUserIsAllowedToJoinOtherOrganization,
    errorOnFetchUserIsAllowedToJoinOtherOrganization,
  };
};
