/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useEffect, useState } from "react";
import { useDownloadContent } from "@taketurns-repositories/document/graphql/mutations/useDownloadContent";
import { useContentDownloadStatus } from "@taketurns-repositories/document/graphql/queries/useContentDownloadStatus";

export const useDownloadContentRule = () => {
  const [loading, setLoading] = useState(false);

  const { getContentDownloadStatus, contentDownloadUrl, contentDownloadStatusError } = useContentDownloadStatus();
  useEffect(() => {
    if (contentDownloadUrl || contentDownloadStatusError) {
      setLoading(false);
    }
  }, [contentDownloadStatusError, contentDownloadUrl]);

  const {
    downloadContent: downloadContentMutation,
    downloadContentError,
    downloadContentLoading,
    downloadId,
  } = useDownloadContent();

  useEffect(() => {
    if (downloadContentLoading) {
      setLoading(true);
    }
    if (downloadContentError) {
      console.error(downloadContentError);
      setLoading(false);
    }
    if (downloadId) {
      getContentDownloadStatus(downloadId);
    }
  }, [downloadContentLoading, downloadContentError, downloadId, getContentDownloadStatus]);

  const downloadContent = async (
    collaborationId: string,
    selection?: { folderIds: string[]; attachmentIds: string[] },
  ) => {
    return downloadContentMutation({
      collaborationId,
      includedFolderIds: selection?.folderIds,
      includedDocumentIds: selection?.attachmentIds,
    });
  };

  return { downloadContent, loading, contentDownloadUrl };
};
