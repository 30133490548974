/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Button, Tooltip } from "@mui/material";
import { CSSProperties, useRef } from "react";
import { useSharedCollaborationTranslation } from "@taketurns-i18n/collaboration/shared/useSharedCollaborationTranslation";
import { TakeTurnsTextButton } from "../../../commons/button";
import { IconFilePlus } from "../../../commons/icons";

interface AddFilesButtonProps {
  processFiles: (files: FileList) => void;
  longWordingMode?: boolean;
  variant?: "text" | "button";
  style?: CSSProperties;
}

export const AddFilesButton = ({ processFiles, longWordingMode, variant = "button", style }: AddFilesButtonProps) => {
  const { t } = useSharedCollaborationTranslation();

  const fileInputRef = useRef<HTMLInputElement>(null);

  const wording = longWordingMode ? t("AddFilesButton.label") : t("AddFilesButton.shortLabel");

  if (variant === "text") {
    return (
      <div>
        <input
          onChange={() => {
            processFiles(fileInputRef.current.files);
            fileInputRef.current.value = "";
          }}
          ref={fileInputRef}
          type="file"
          multiple
          hidden
        />
        <TakeTurnsTextButton type="button" fontSize={"14px"} onClick={() => fileInputRef.current.click()} style={style}>
          {t("AddFilesButton.textLabel")}
        </TakeTurnsTextButton>
      </div>
    );
  }

  return (
    <div>
      <input
        onChange={() => {
          processFiles(fileInputRef.current.files);
          fileInputRef.current.value = "";
        }}
        ref={fileInputRef}
        type="file"
        data-cy="AddFilesButton.HiddenFileInput"
        multiple
        hidden
      />
      <Tooltip
        title={t("AddFilesButton.tooltip")}
        disableFocusListener={longWordingMode}
        disableHoverListener={longWordingMode}
        disableTouchListener={longWordingMode}
      >
        <Button
          variant={"text"}
          onClick={() => fileInputRef.current.click()}
          startIcon={<IconFilePlus size={24} />}
          disableRipple
          sx={{ "& .MuiButton-startIcon": { mr: { xs: "3px", lg: "6px" } }, ...style }}
        >
          {wording}
        </Button>
      </Tooltip>
    </div>
  );
};
