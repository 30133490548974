/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useEffect } from "react";
import { useGetPendingAttachmentRevisionsPendingUpload } from "@taketurns-repositories/collaboration/state/read/useGetPendingAttachmentRevisionsPendingUpload";
import { useMarkPendingAttachmentRevisionAsUploading } from "@taketurns-repositories/collaboration/state/write/pendingAttachmentRevision/useMarkPendingAttachmentRevisionAsUploading";
import { useStartUploadRule } from "../../../document/commands/useStartUploadRule";

export const useListenToPendingRevisionUploadsRule = () => {
  const startUpload = useStartUploadRule();
  const pendingAttachmentRevisionsPendingUpload = useGetPendingAttachmentRevisionsPendingUpload();
  const markPendingAttachmentRevisionAsUploading = useMarkPendingAttachmentRevisionAsUploading();

  useEffect(() => {
    for (const pendingAttachmentRevision of pendingAttachmentRevisionsPendingUpload) {
      markPendingAttachmentRevisionAsUploading(pendingAttachmentRevision);
      startUpload(pendingAttachmentRevision.uploadId);
    }
  }, [pendingAttachmentRevisionsPendingUpload]);
};
