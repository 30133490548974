/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { Collaboration, EditCollaborationInput } from "@taketurns/taketurns-graphql-repository";
import { useCollaborationTranslation } from "@taketurns-i18n/collaboration/useCollaborationTranslation";
import { useEditCollaboration } from "@taketurns-repositories/collaboration/graphql/mutations/useEditCollaboration";

export const useEditCollaborationRule = (collaborationId: string) => {
  const [editMutation, editMutationResult] = useEditCollaboration();

  const { t } = useCollaborationTranslation("notifications");

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (editMutationResult.error) {
      enqueueSnackbar(t("error"), {
        variant: "error",
      });
      console.error(editMutationResult.error);
      editMutationResult.reset();
    }
  }, [editMutationResult, enqueueSnackbar, t]);

  const editCollaborationCommand = (input: EditCollaborationInput) => {
    const optimisticResponse = {
      editCollaboration: {
        __typename: "Collaboration",
        id: collaborationId,
        ...input,
      } as unknown as Collaboration,
    };
    editMutation({
      variables: { collaborationId: collaborationId, input: input },
      optimisticResponse,
    });
  };
  return { editCollaborationCommand, result: editMutationResult };
};
