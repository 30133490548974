/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useSubscribeToAddOrRevokedInvitationsToCollaborations } from "@taketurns-repositories/collaboration/graphql/subscriptions/participants/useSubscribeToAddOrRevokedInvitationsToCollaborations";
import { useFetchConnectedUserIdAndEmailRule } from "@taketurns-rules/user/queries/useFetchConnectedUserIdAndEmailRule";

export const useSubscribeToAddedOrRevokedInvitationsToCollaborationsRule = () => {
  const { connectedUserId, connectedUserEmail } = useFetchConnectedUserIdAndEmailRule();
  useSubscribeToAddOrRevokedInvitationsToCollaborations(connectedUserId, connectedUserEmail);
};
