/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useApolloClient } from "@apollo/client";

export const useSetAttachmentLock = () => {
  const { cache } = useApolloClient();
  return (attachmentId: string, locked: boolean) => {
    cache.modify({
      id: cache.identify({ __typename: "Attachment", id: attachmentId }),
      fields: {
        locked() {
          return locked;
        },
      },
    });
  };
};
