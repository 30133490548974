/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useRecoilCallback } from "recoil";
import { PendingAttachmentRevision } from "@taketurns-repositories/collaboration/models/pendingAttachmentRevision";
import { PendingAttachmentRevisionStatus } from "@taketurns-repositories/collaboration/models/pendingAttachmentRevisionStatus";
import { pendingAttachmentRevisionsByCollaborationIdState } from "../../recoil/pendingAttachmentRevisionsByCollaborationIdState";

export const useSetPendingAttachmentRevisionStatus = () =>
  useRecoilCallback(
    ({ set }) =>
      (pendingAttachmentRevision: PendingAttachmentRevision, status: PendingAttachmentRevisionStatus) => {
        const updatedPendingAttachmentRevision = pendingAttachmentRevision.copy();
        updatedPendingAttachmentRevision.status = status;
        set(
          pendingAttachmentRevisionsByCollaborationIdState(pendingAttachmentRevision.collaborationId),
          (currentPendingAttachmentRevisions) => [
            ...currentPendingAttachmentRevisions.filter(
              (currentPendingAttachmentRevision) =>
                currentPendingAttachmentRevision.uploadId !== pendingAttachmentRevision.uploadId,
            ),
            updatedPendingAttachmentRevision,
          ],
        );
      },
    [],
  );
