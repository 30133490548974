/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Stack, Typography } from "@mui/material";
import { ContextMenuOnButton } from "@taketurns-components/collaboration/ContextMenu/ContextMenuOnButton";
import { ContextMenuConfiguration } from "@taketurns-components/collaboration/ContextMenu/models/contextMenuConfiguration";
import { EditRequestDialog } from "@taketurns-components/collaboration/Shared/content/EditRequestDialog";
import { RequestIconMarker, RequestWordMarker } from "@taketurns-components/collaboration/Shared/content/Markers";
import { RequestTag } from "@taketurns-components/collaboration/Shared/content/RequestTag";
import { MobileListItem } from "@taketurns-components/collaboration/Shared/list/MobileListItem";
import { TakeTurnsListItem } from "@taketurns-components/collaboration/Shared/list/TakeTurnsListItem";
import { useGetRequestInCreation } from "@taketurns-repositories/collaboration/state/read/collaborationCreation/useGetRequestInCreation";
import { useModifyRequestDescriptionInCreationRule } from "@taketurns-rules/collaboration/commands/collaborationCreation/request/useModifyRequestDescriptionInCreationRule";
import { useRenameRequestInCreationRule } from "@taketurns-rules/collaboration/commands/collaborationCreation/request/useRenameRequestInCreationRule";
import { useMoveDestinationDialogRule } from "@taketurns-rules/collaboration/commands/useMoveDestinationDialogRule";
import { useEditDialogRule } from "@taketurns-rules/collaboration/queries/useEditDialogRule";
import { useGetRequestContextMenuConfigurationInCreationRule } from "@taketurns-rules/collaboration/ui/useGetRequestContextMenuConfigurationInCreationRule";
import { useIsOnMobileRule } from "@taketurns-rules/commons/theme/useIsOnMobileRule";
import { Party } from "@taketurns/taketurns-graphql-repository";
import { ComponentProps, ForwardedRef, forwardRef, Fragment } from "react";
import { WithContextMenu } from "../../../ContextMenu/WithContextMenu";
import { RequestMoveDestinationDialogInCollaborationCreation } from "../RequestMoveDestinationDialogInCollaborationCreation";

interface RequestListItemProps {
  requestId: string;
}

interface ListItemProps extends RequestListItemProps {
  contextMenuConfiguration: ContextMenuConfiguration;
}

const ListItem = forwardRef(
  ({ requestId, contextMenuConfiguration }: ListItemProps, requestListItemRef: ForwardedRef<HTMLLIElement | null>) => {
    const request = useGetRequestInCreation(requestId);
    const isOnMobile = useIsOnMobileRule();

    if (isOnMobile) {
      return (
        <MobileListItem
          centerRightEndSlotVertically
          ref={requestListItemRef}
          titleSlot={
            <Typography variant="body2" noWrap>
              {request.name}
            </Typography>
          }
          subtitleSlot={
            <Stack direction="row" alignItems={"center"} spacing={1} overflow="hidden">
              <RequestWordMarker />
              <RequestIconMarker />
            </Stack>
          }
          rightEndSlot={<ContextMenuOnButton contextMenuConfiguration={contextMenuConfiguration} />}
        />
      );
    }

    const ListItemWithContextMenu = WithContextMenu<ComponentProps<typeof TakeTurnsListItem>>(
      TakeTurnsListItem,
      contextMenuConfiguration,
    );

    return (
      <ListItemWithContextMenu
        hasContextMenu
        ref={requestListItemRef}
        titleSlot={
          <Typography variant="body2" noWrap>
            {request.name}
          </Typography>
        }
        rightEndSlot={<RequestTag party={Party.Owning} />}
      />
    );
  },
);
ListItem.displayName = "RequestListItemInCreation";

export const RequestListItemInCreation = ({ requestId }: RequestListItemProps) => {
  const request = useGetRequestInCreation(requestId);
  const modifyRequestDescription = useModifyRequestDescriptionInCreationRule(requestId);
  const renameRequest = useRenameRequestInCreationRule(requestId);

  const { closeEditDialog, isEditDialogOpened, openEditDialog } = useEditDialogRule();
  const { closeMoveDestinationDialog, isMoveDestinationDialogOpen, openMoveDestinationDialog } =
    useMoveDestinationDialogRule();
  const requestContextMenuConfigurationInCreation = useGetRequestContextMenuConfigurationInCreationRule(
    requestId,
    openEditDialog,
    openMoveDestinationDialog,
  );

  return (
    <Fragment>
      <ListItem requestId={requestId} contextMenuConfiguration={requestContextMenuConfigurationInCreation} />
      <EditRequestDialog
        open={isEditDialogOpened}
        onClose={closeEditDialog}
        requestName={request.name}
        requestDescription={request.description}
        modifyRequestDescription={modifyRequestDescription}
        changeRequestName={renameRequest}
      />
      <RequestMoveDestinationDialogInCollaborationCreation
        requestId={requestId}
        open={isMoveDestinationDialogOpen}
        onClose={closeMoveDestinationDialog}
      />
    </Fragment>
  );
};
