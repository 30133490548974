/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Box, Divider, Skeleton, styled, Typography } from "@mui/material";
import { Fragment } from "react";
import { PlanSwitchingInvoice, SubscriptionPlanCurrency } from "@taketurns/taketurns-graphql-repository";
import { useUserPlanTranslation } from "@taketurns-i18n/user/UserPlans/useUserPlanTranslation";
import { getFormattedNextPaymentDateRule } from "@taketurns-rules/user/queries/getFormattedNextPaymentDateRule";
import { useGetSubscriptionPlanPeriodRule } from "@taketurns-rules/user/queries/plan/getSubscriptionPlanPeriodRule";

interface UpcomingInvoiceProps {
  planSwitchingInvoice?: PlanSwitchingInvoice;
  loading?: boolean;
  error?: unknown;
}

export const UpcomingInvoice = ({ planSwitchingInvoice, loading, error }: UpcomingInvoiceProps) => {
  const { t, i18n } = useUserPlanTranslation();
  const period = useGetSubscriptionPlanPeriodRule();

  if (error) {
    console.error(error);
    return (
      <Typography variant={"caption"} color={"error"}>
        Something went wrong. We are working on it.
      </Typography>
    );
  }
  if (loading) {
    return (
      <Fragment>
        <Skeleton />
        <Divider />
        <Skeleton />
        <Divider />
      </Fragment>
    );
  }
  const currencyLogo = planSwitchingInvoice!.currency === SubscriptionPlanCurrency.Eur ? "€" : "$";
  return (
    <Fragment>
      <UpcomingInvoiceLine__Element>
        <Typography>
          {t("upgradePlanDialog.totalAmount", {
            frequency: t(`upgradePlanDialog.${period}`),
            date: getFormattedNextPaymentDateRule(i18n.language, planSwitchingInvoice!.nextPeriodStartingDate),
          })}
        </Typography>
        <Typography>
          {(planSwitchingInvoice?.unitPrice as unknown as number) / 100}
          {currencyLogo}
        </Typography>
      </UpcomingInvoiceLine__Element>
      <Divider />
      <UpcomingInvoiceLine__Element>
        <Typography>{t("upgradePlanDialog.dueAmount")}</Typography>
        <Typography fontWeight={600}>
          {(planSwitchingInvoice?.amountDueToday as unknown as number) / 100}
          {currencyLogo}
        </Typography>
      </UpcomingInvoiceLine__Element>
      <Divider />
    </Fragment>
  );
};

const UpcomingInvoiceLine__Element = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
});
