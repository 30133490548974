/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useQuery } from "@apollo/client";
import { Collaboration } from "@taketurns/taketurns-graphql-repository";

const COLLABORATION_STATUS = gql`
  query collaborationStatus($collaborationId: ID!) {
    getCollaboration(collaborationId: $collaborationId) {
      id
      status
    }
  }
`;

export const useFetchCollaborationStatus = (collaborationId: string) => {
  const {
    data,
    loading: loadingCollaborationStatus,
    error: errorOnFetchingCollaborationStatus,
  } = useQuery<{ getCollaboration: Collaboration }>(COLLABORATION_STATUS, {
    variables: { collaborationId },
  });
  return {
    status: data?.getCollaboration?.status,
    loadingCollaborationStatus,
    errorOnFetchingCollaborationStatus,
  };
};
