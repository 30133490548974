/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql } from "@apollo/client";
import { useRefreshableSubscription } from "@taketurns-components/webapp/QueriesAndSubscriptionsRefreshTrigger";

const ON_DOCUMENT_NAME_CHANGED = gql`
  subscription onDocumentNameChanged($id: ID!) {
    onDocumentNameChanged(id: $id) {
      id
      name
    }
  }
`;

export const useSubscribeToModifyDocumentName = (documentId: string) => {
  return useRefreshableSubscription(ON_DOCUMENT_NAME_CHANGED, {
    variables: { id: documentId },
    onError: (error) => {
      console.error(error);
    },
  });
};
