/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { PropsWithChildren } from "react";
import { Navigate, useLoaderData } from "react-router-dom";
import { useSubscribeOnExcludedFromCollaborationRule } from "@taketurns-rules/webapp/subscriptions/useSubscribeOnExcludedFromCollaborationRule";
import { AUTHENTICATED_ROUTES } from "../routes/authenticatedRoutes.constants";

export const ExcludedFromCollaborationGuard = ({ children }: PropsWithChildren) => {
  const { collaborationId } = useLoaderData() as {
    collaborationId: string;
  };
  const isExcluded = useSubscribeOnExcludedFromCollaborationRule(collaborationId);
  if (isExcluded) {
    return <Navigate to={AUTHENTICATED_ROUTES.COLLABORATION_LIST} />;
  }
  return children;
};
