/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useMemo, useState } from "react";

export const useHover = () => {
  const [isHovered, setHovered] = useState(false);

  const hoverEventHandlers = useMemo(
    () => ({
      onMouseOver() {
        setHovered(true);
      },
      onMouseOut() {
        setHovered(false);
      },
    }),
    [],
  );

  return { isHovered, hoverEventHandlers };
};
