/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Box, Chip, Stack, styled, Typography } from "@mui/material";
import { Fragment } from "react";
import { SubscriptionPlanPeriod } from "@taketurns/taketurns-graphql-repository";
import { useUserPlanTranslation } from "@taketurns-i18n/user/UserPlans/useUserPlanTranslation";
import { SubscriptionPlanForDisplay } from "@taketurns-repositories/user/local/entities/subscriptionPlanForDisplay";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { useGetSubscriptionPlanPeriodRule } from "@taketurns-rules/user/queries/plan/getSubscriptionPlanPeriodRule";
import { useFetchIsUserAllowedToSubscribeWithTrialRule } from "@taketurns-rules/user/queries/useFetchIsUserAllowedToSubscribeWithTrialRule";
import { SubscribeToPlan } from "../../SubscribeToPlan/SubscribeToPlan";
import { SubscribeToPlanWithoutAuthentication } from "../../SubscribeToPlan/SubscribeToPlanWithoutAuthentication";
import { SubscribeToPlanWithTrial } from "../../SubscribeToPlanWithTrial/SubscribeToPlanWithTrial";
import { SubscribeToPlanWithTrialWithoutAuthentication } from "../../SubscribeToPlanWithTrial/SubscribeToPlanWithTrialWithoutAuthentication";
import { ContactSalesButtonAndDialog } from "../ContactSalesButtonAndDialog";
import { SubscriptionPlanElementPrice } from "./SubscriptionPlanElementPrice";

export const SubscriptionPlanElementPricingAndDescription = ({
  subscriptionPlan,
  isUserAuthenticated,
}: {
  subscriptionPlan: SubscriptionPlanForDisplay;
  isUserAuthenticated?: boolean;
}) => {
  const { t } = useUserPlanTranslation();
  const { monoUser, tagLineKey, color, discountedMonthly, discountedYearly, onDemandPricing, mostPopularOffer } =
    subscriptionPlan;
  const { isAllowedToSubscribeWithTrial } = useFetchIsUserAllowedToSubscribeWithTrialRule();
  const shouldDisplayTrial = !isUserAuthenticated || isAllowedToSubscribeWithTrial;

  const period = useGetSubscriptionPlanPeriodRule();
  const displayLimitedTimeOfferChip = () => {
    if (discountedYearly && period === SubscriptionPlanPeriod.Year) {
      return true;
    }
    return discountedMonthly && period === SubscriptionPlanPeriod.Month;
  };

  return (
    <Stack alignItems={"center"} padding={3} flexDirection={"column"}>
      <ResponsiveBox
        sx={{
          height: "20px",
        }}
      >
        <Typography variant={"body1"} color={color} textAlign={"center"} lineHeight="1.2em">
          {t(`tagLines.${tagLineKey}`)}
        </Typography>
      </ResponsiveBox>
      <ResponsiveBox sx={{ height: "12px", marginTop: "10px" }}>
        {displayLimitedTimeOfferChip() && <LimitedTimeOfferChip label={t("pricing.limitedTimeOffer")} />}
        {mostPopularOffer && <MostPopularOfferChip label={t("pricing.mostPopularOffer")} />}
      </ResponsiveBox>
      <ResponsiveBox sx={{ height: 30, marginTop: "10px" }}>
        <SubscriptionPlanPriceOrOnDemandPricingText />
      </ResponsiveBox>
      <ResponsiveBox sx={{ height: "16px", marginTop: "6px" }}>
        {!onDemandPricing && (
          <Typography fontSize={"0.875rem"} color={TakeTurnsColors.darkGray}>
            {monoUser ? t("pricing.singleUser") : t("pricing.perMonthSingleUser")}
          </Typography>
        )}
      </ResponsiveBox>
      {shouldDisplayTrial && (
        <ResponsiveBox rowGap={1} flexDirection={"column"} sx={{ height: "64px", marginTop: "18px" }}>
          <SubscribeToPlanWithTrialAction />
        </ResponsiveBox>
      )}
      <ResponsiveBox sx={{ height: "32px", marginTop: "18px" }}>{<SubscribeToPlanAction />}</ResponsiveBox>
    </Stack>
  );

  function SubscriptionPlanPriceOrOnDemandPricingText() {
    if (onDemandPricing) {
      return (
        <Typography fontSize={"0.875rem"} color={TakeTurnsColors.darkGray} textAlign={"center"}>
          {t("pricing.customPrice")}
        </Typography>
      );
    }
    return <SubscriptionPlanElementPrice subscriptionPlan={subscriptionPlan} />;
  }

  function SubscribeToPlanAction() {
    if (onDemandPricing) {
      if (!shouldDisplayTrial) {
        return <ContactSalesButtonAndDialog />;
      }
      return;
    }
    if (isUserAuthenticated) {
      return <SubscribeToPlan subscriptionPlan={subscriptionPlan} />;
    }
    return <SubscribeToPlanWithoutAuthentication subscriptionPlan={subscriptionPlan} />;
  }

  function SubscribeToPlanWithTrialAction() {
    if (onDemandPricing) {
      return <ContactSalesButtonAndDialog />;
    }
    return (
      <Fragment>
        {(isUserAuthenticated && <SubscribeToPlanWithTrial />) || <SubscribeToPlanWithTrialWithoutAuthentication />}
        <Typography fontWeight={500} fontSize={"0.75rem"} color={TakeTurnsColors.darkGray}>
          {`(${t("pricing.noCreditCardRequired")})`}
        </Typography>
      </Fragment>
    );
  }
};

const ResponsiveBox = styled(Box)({
  display: "flex",
  alignItems: "center",
});

export const LimitedTimeOfferBackgroundColor = "#FF7744";

const LimitedTimeOfferChip = styled(Chip)({
  backgroundColor: LimitedTimeOfferBackgroundColor,
  color: "white",
  borderRadius: "5px",
  padding: "3px 8px 3px 8px",
  fontSize: 10,
  height: "auto",
  "& .MuiChip-label": {
    lineHeight: "initial",
    padding: 0,
    fontWeight: 600,
  },
});

const MostPopularOfferChip = styled(Chip)({
  backgroundColor: TakeTurnsColors.yellow,
  color: "white",
  borderRadius: "5px",
  padding: "3px 8px 3px 8px",
  fontSize: 10,
  height: "auto",
  "& .MuiChip-label": {
    lineHeight: "initial",
    padding: 0,
    fontWeight: 600,
  },
});
