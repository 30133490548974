/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { PropsWithChildren, useEffect } from "react";
import { useResetPendingCreationAttachmentsRule } from "@taketurns-rules/collaboration/commands/collaborationCreation/attachment/pending/useResetPendingCreationAttachmentsRule";
import { useResetCollaborationContent } from "@taketurns-rules/collaboration/commands/collaborationCreation/useResetCollaborationContent";
import {
  useResetCollaborationFormAndSetDefaultCurrentTurnRule,
} from "@taketurns-rules/collaboration/commands/collaborationCreation/useResetCollaborationFormAndSetDefaultCurrentTurnRule";
import { useSetDefaultOwningPartyRule } from "@taketurns-rules/collaboration/commands/collaborationCreation/useSetDefaultOwningPartyRule";

export const CollaborationCreationProvider = ({ children }: PropsWithChildren) => {
  const resetCollaborationFormAndSetDefaultCurrentTurn = useResetCollaborationFormAndSetDefaultCurrentTurnRule();
  useResetPendingCreationAttachmentsOnUnmount();
  useResetCollaborationContentOnUnmount();
  useSetDefaultOwningPartyRule();

  useEffect(() => {
    return () => {
      resetCollaborationFormAndSetDefaultCurrentTurn();
    };
  }, [resetCollaborationFormAndSetDefaultCurrentTurn]);

  return children;
};

function useResetPendingCreationAttachmentsOnUnmount() {
  const resetPendingCreationAttachments = useResetPendingCreationAttachmentsRule();
  useEffect(() => {
    return () => {
      resetPendingCreationAttachments();
    };
  });
}

function useResetCollaborationContentOnUnmount() {
  const resetCollaborationContent = useResetCollaborationContent();
  useEffect(() => {
    return () => {
      resetCollaborationContent();
    };
  });
}
