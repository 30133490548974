/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import {
  useFetchDocumentRevisionUser,
} from "@taketurns-repositories/document/graphql/queries/useFetchDocumentRevisionUser";
import {
  useGetDisplayedCollaborationIdRule,
} from "@taketurns-rules/collaboration/queries/collaboration/useGetDisplayedCollaborationIdRule";

export const useFetchDocumentRevisionUserRule = (documentId: string, revisionId: string) => {
  const displayedCollaborationId = useGetDisplayedCollaborationIdRule();
  return useFetchDocumentRevisionUser(displayedCollaborationId, documentId, revisionId);
};
