/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { matchPath, Navigate, useLocation } from "react-router-dom";
import { useSaveLoggedInReturnPath } from "@taketurns-rules/webapp/commands/useSaveLoggedInReturnPath";
import { useDecodeParamsInCurrentUrlRule } from "@taketurns-rules/webapp/queries/useDecodeParamsInCurrentUrlRule";
import { useAuthContext } from "../AuthProvider";
import { AUTHENTICATED_ROUTES } from "../routes/authenticatedRoutes.constants";
import { PUBLIC_ROUTES } from "../routes/publicRoutes.constants";

export enum AccessMode {
  VERIFICATION_CODE = "VerificationCode",
}

export const RedirectFromParamsGuard = ({ children }: { children: JSX.Element }) => {
  const saveLoggedInReturnPath = useSaveLoggedInReturnPath();
  const { pathname, search } = useLocation();
  const params = useDecodeParamsInCurrentUrlRule<{ accessMode: AccessMode }>();
  const isTryingToAccessCollaborationView = matchPath(AUTHENTICATED_ROUTES.COLLABORATION_VIEW, pathname);
  const { isAuthenticated } = useAuthContext();
  if (!isAuthenticated && isTryingToAccessCollaborationView && params?.accessMode === AccessMode.VERIFICATION_CODE) {
    saveLoggedInReturnPath();
    return <Navigate to={PUBLIC_ROUTES.ACCESS_COLLABORATION + search} />;
  }
  return children;
};
