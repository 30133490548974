/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useEffect } from "react";
import { OrganizationInvitation } from "@taketurns/taketurns-graphql-repository";
import { useOrganizationTranslation } from "@taketurns-i18n/user/Organization/useOrganizationTranslation";
import { useRevokeOrganizationInvitation } from "@taketurns-repositories/user/graphql/mutations/useRevokeOrganizationInvitation";
import { useDisplayAndLogNotificationErrorRule } from "./useDisplayAndLogNotificationErrorRule";
import { useDisplayNotificationSuccessRule } from "./useDisplayNotificationSuccessRule";

export const useRevokeInvitationRule = () => {
  const {
    revokeOrganizationInvitation: revokeOrganizationInvitationCommand,
    loadingRevokeOrganizationInvitation,
    errorOnRevokeOrganizationInvitation,
  } = useRevokeOrganizationInvitation();

  const displayNotificationAndLogError = useDisplayAndLogNotificationErrorRule();
  useEffect(() => {
    if (errorOnRevokeOrganizationInvitation) {
      displayNotificationAndLogError(errorOnRevokeOrganizationInvitation);
    }
  }, [errorOnRevokeOrganizationInvitation]);

  const displaySuccessNotification = useDisplayNotificationSuccessRule();
  const { t } = useOrganizationTranslation();
  const revokeOrganizationInvitation = (invitation: OrganizationInvitation) => {
    const { userId, email } = invitation;
    revokeOrganizationInvitationCommand({
      userId,
      email,
    })
      .then(() => displaySuccessNotification(t("Notifications.revokeOrganizationInvitationSuccess")))
      .catch((error) => displayNotificationAndLogError(error));
  };

  return { revokeOrganizationInvitation, loadingRevokeOrganizationInvitation, errorOnRevokeOrganizationInvitation };
};
