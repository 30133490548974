/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Box, Skeleton, Typography } from "@mui/material";
import { SomethingWentWrong } from "@taketurns-components/shared/SomethingWentWrong";
import { useRecapTranslation } from "@taketurns-i18n/collaboration/Recap/useRecapTranslation";
import { useFetchCollaborationNameRule } from "@taketurns-rules/collaboration/queries/fields/useFetchCollaborationNameRule";
import { useGetDisplayedRecapCollaborationIdRule } from "@taketurns-rules/collaboration/queries/Recap/useGetDisplayedRecapCollaborationIdRule";

export const RecapHeader = () => {
  const collaborationId = useGetDisplayedRecapCollaborationIdRule();
  const { name, loadingCollaborationName, errorOnFetchingCollaborationName } =
    useFetchCollaborationNameRule(collaborationId);
  const { t } = useRecapTranslation();

  if (errorOnFetchingCollaborationName) {
    console.error("Error while fetching collaboration name", errorOnFetchingCollaborationName);
    return <SomethingWentWrong />;
  }

  if (loadingCollaborationName) {
    return <Skeleton variant={"text"} />;
  }
  return (
    <Box>
      <Typography>
        {t("RecapHeader.title")} | {name}
      </Typography>
    </Box>
  );
};
