/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { PendingCreationAttachment } from "../../../../models/pendingCreationAttachment";
import { PendingCreationAttachmentStatus } from "../../../../models/pendingCreationAttachmentStatus";
import { useSetPendingCreationAttachmentStatus } from "./useSetPendingCreationAttachmentStatus";

export const useMarkPendingCreationAttachmentAsAdded = () => {
  const setPendingCreationAttachmentStatus = useSetPendingCreationAttachmentStatus();
  return (pendingCreationAttachment: PendingCreationAttachment) =>
    setPendingCreationAttachmentStatus(pendingCreationAttachment, PendingCreationAttachmentStatus.added);
};
