/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Box, styled, Typography } from "@mui/material";
import { CSSProperties } from "react";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { IconBulbOutline, IconExclamationSquare, IconXCircle } from "../icons";

export enum TipVariant {
  info = "info",
  warning = "warning",
}

interface TipProps {
  message: string | JSX.Element;
  style?: CSSProperties;
  canDisableTip: boolean;
  onClose?: () => void;
  variant?: TipVariant;
}

const TipContainer = styled("div")({
  display: "inline-flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "6px",
  borderRadius: "10px",
  padding: "6px 10px",
  backgroundColor: TakeTurnsColors.tipBackground,
  color: TakeTurnsColors.darkGray,
});

const WarningTipContainer = styled(TipContainer)({
  backgroundColor: TakeTurnsColors.warningTipBackground,
});

const CloseTipButton = styled(IconXCircle)({
  ":hover": {
    cursor: "pointer",
  },
  color: TakeTurnsColors.mediumGray,
});

export const Tip = ({ message, style, canDisableTip, onClose, variant = TipVariant.info }: TipProps) => {
  if (variant === TipVariant.warning) {
    return (
      <WarningTipContainer style={style}>
        <Box display="flex" alignItems="center">
          <IconExclamationSquare color={TakeTurnsColors.yellow} size={18} />
        </Box>
        <Typography variant="caption">{message}</Typography>
        {canDisableTip && (
          <Box display="flex" alignItems="center">
            <CloseTipButton size={18} onClick={onClose} />
          </Box>
        )}
      </WarningTipContainer>
    );
  }
  return (
    <TipContainer style={style}>
      <Box display="flex" alignItems="center">
        <IconBulbOutline color={TakeTurnsColors.lightBlue} size={18} />
      </Box>
      <Typography variant="caption">{message}</Typography>
      {canDisableTip && (
        <Box display="flex" alignItems="center">
          <CloseTipButton size={18} onClick={onClose} />
        </Box>
      )}
    </TipContainer>
  );
};
