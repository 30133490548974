/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { gql, useQuery } from "@apollo/client";
import { Collaboration } from "@taketurns/taketurns-graphql-repository";

const COLLABORATION_PARTY_NAMES = gql`
  query collaborationPartyNames($collaborationId: ID!) {
    getCollaboration(collaborationId: $collaborationId) {
      id
      invitedPartyName
      owningPartyName
    }
  }
`;

export const useFetchCollaborationPartyNames = (collaborationId: string) => {
  const {
    data,
    error: errorOnFetchingCollaborationPartyNames,
    loading: loadingCollaborationPartyNames,
  } = useQuery<{
    getCollaboration: Collaboration;
  }>(COLLABORATION_PARTY_NAMES, { variables: { collaborationId } });
  return {
    owningPartyName: data?.getCollaboration?.owningPartyName,
    invitedPartyName: data?.getCollaboration?.invitedPartyName,
    errorOnFetchingCollaborationPartyNames,
    loadingCollaborationPartyNames,
  };
};
