/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Auth } from "aws-amplify";

export const getConnectedUserAttributes = async () => {
  const currentSession = await Auth.currentSession();
  const decodedIdToken = currentSession.getIdToken().decodePayload();
  const IS_USER_FIRST_AUTH_COGNITO_ATTRIBUTE = "custom:first_auth";
  const TAKETURNS_CUSTOM_ATTRIBUTE_TTID = "custom:ttid";
  const USER_EMAIL_ATTRIBUTE = "email";

  const userId = decodedIdToken[TAKETURNS_CUSTOM_ATTRIBUTE_TTID];
  // // /!\ Comparing with a boolean string, as cognito returns it as a string and not a boolean
  const isUserFirstAuth = decodedIdToken[IS_USER_FIRST_AUTH_COGNITO_ATTRIBUTE] === "true";
  const email = decodedIdToken[USER_EMAIL_ATTRIBUTE];

  return { userId, isUserFirstAuth, email };
};
