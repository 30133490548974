/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useGetCurrentPathAsFolderIdsInCollaborationView } from "@taketurns-repositories/collaboration/state/read/collaborationView/useGetCurrentPathAsFolderIdsInCollaborationView";
import { useSetPathAsFolderIdsInCollaborationView } from "@taketurns-repositories/collaboration/state/write/useSetPathAsFolderIdsInCollaborationView";

export const useBrowseContentRule = () => {
  const pathInCollaborationView = useGetCurrentPathAsFolderIdsInCollaborationView();
  const setPathAsFolderIds = useSetPathAsFolderIdsInCollaborationView();

  const initPath = (rootFolderId: string) => {
    setPathAsFolderIds([rootFolderId]);
  };

  const moveIntoFolder = (folderId: string) => {
    setPathAsFolderIds((path) => [...path, folderId]);
  };
  const moveBackward = () => {
    setPathAsFolderIds((path) => path.slice(0, -1));
  };

  return {
    initPath,
    isCurrentFolderRootFolder: pathInCollaborationView.length === 1,
    currentFolderId: pathInCollaborationView.at(-1),
    moveIntoFolder,
    moveBackward,
  };
};
