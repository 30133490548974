/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useActivateDisplayedTips } from "@taketurns-repositories/user/graphql/mutations/useActivateDisplayedTips";
import { useDeactivateDisplayedTips } from "@taketurns-repositories/user/graphql/mutations/useDeactivateDisplayedTips";

export const useSwitchTipsDisplayRule = () => {
  const [activateDisplayedTips] = useActivateDisplayedTips();
  const [deactivateDisplayedTipsMutation] = useDeactivateDisplayedTips();
  const switchDisplayTipsRule = (isTipsCurrentlyHidden: boolean) => {
    if (isTipsCurrentlyHidden) {
      activateDisplayedTips();
    } else {
      deactivateDisplayedTipsMutation();
    }
  };
  return { switchDisplayTipsRule };
};
