/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Box, styled } from "@mui/material";
import { SubscriptionPlanTier } from "@taketurns/taketurns-graphql-repository";
import { useUserPlanTranslation } from "@taketurns-i18n/user/UserPlans/useUserPlanTranslation";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";

export const AvailablePlansHorizontalList = ({
  userPlanTier,
  onClick,
}: {
  userPlanTier: SubscriptionPlanTier;
  onClick: () => void;
}) => {
  const { t } = useUserPlanTranslation();

  const AvailablePlanListConfigurations: {
    planTier: string;
    leftTopBottomRadius?: boolean;
    rightTopBottomRadius?: boolean;
    color: string;
  }[] = [
    { planTier: SubscriptionPlanTier.T1, leftTopBottomRadius: true, color: TakeTurnsColors.darkBlue },
    { planTier: SubscriptionPlanTier.T2, color: TakeTurnsColors.darkBlue },
    { planTier: SubscriptionPlanTier.T3, rightTopBottomRadius: true, color: TakeTurnsColors.darkBlue },
  ];
  const isLastPlanTierAvailable = userPlanTier === SubscriptionPlanTier.T2;

  return (
    <Box
      sx={{ display: "flex", justifyContent: "center", gap: "2px" }}
      onClick={isLastPlanTierAvailable ? undefined : onClick}
    >
      {AvailablePlanListConfigurations.map((planConfiguration) => (
        <PlanListItem
          key={planConfiguration.planTier}
          color={planConfiguration.color}
          isActive={userPlanTier === planConfiguration.planTier}
          leftTopBottomRadius={planConfiguration.leftTopBottomRadius}
          rightTopBottomRadius={planConfiguration.rightTopBottomRadius}
          isClickEnabled={isLastPlanTierAvailable}
        >
          {t(`plans.${planConfiguration.planTier}`)}
        </PlanListItem>
      ))}
    </Box>
  );
};

interface PlanListItemProps {
  leftTopBottomRadius?: boolean;
  rightTopBottomRadius?: boolean;
  color: string;
  isActive: boolean;
  isClickEnabled: boolean;
}

const PlanListItem = styled(Box, {
  shouldForwardProp: (prop: string) =>
    prop !== "leftTopBottomRadius" &&
    prop !== "rightTopBottomRadius" &&
    prop !== "color" &&
    prop !== "isActive" &&
    prop !== "isClickEnabled",
})<PlanListItemProps>(({ leftTopBottomRadius, rightTopBottomRadius, color, isActive, isClickEnabled, theme }) => ({
  backgroundColor: isActive ? color : TakeTurnsColors.lightGray,
  width: "125px",
  padding: "2px 24px",
  textTransform: "uppercase",
  textAlign: "center",
  color: "white",
  borderRadius: leftTopBottomRadius ? "10px 0px 0px 10px" : rightTopBottomRadius ? "0px 10px 10px 0px" : "unset",
  cursor: isClickEnabled ? "default" : "pointer",

  [theme.breakpoints.down("sm")]: {
    padding: "2px 12px",
  },
}));
