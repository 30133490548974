/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Box } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { FetchLoadingManager } from "@taketurns-app/utils/FetchLoadingManager";
import { TakeTurnsDialogFooter } from "@taketurns-components/collaboration/Shared/content/TakeTurnsDialogFooter";
import { useCollaborationTranslation } from "@taketurns-i18n/collaboration/useCollaborationTranslation";
import { useFetchConnectedUserPartyRule } from "@taketurns-rules/collaboration/queries/context/useFetchConnectedUserPartyRule";
import { useFetchCollaborationEditableFieldsRule } from "@taketurns-rules/collaboration/queries/fields/useFetchCollaborationEditableFieldsRule";
import { useEditCollaborationRule } from "@taketurns-rules/collaboration/queries/useEditCollaborationRule";
import { formatToLongDate } from "@taketurns-rules/collaboration/utils/date/DateUtils";
import { HelpCenterSections } from "@taketurns-rules/commons/enum/helpCenterSectionsEnum";
import { TEXT_FIELD_MAX_LENGTH } from "@taketurns-rules/commons/inputConstants";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { EditCollaborationInput, Party } from "@taketurns/taketurns-graphql-repository";
import { DateTime } from "luxon";
import { ResponsiveDialog } from "../../../../commons/dialog/ResponsiveDialog";
import { LabelWithEditButton } from "../../../../commons/form/LabelWithEditButton";
import { TakeTurnsFormLabel } from "../../../../commons/form/TakeTurnsFormLabel";
import { IconCalendar } from "../../../../commons/icons";
import { FilledLocalizationProvider } from "../../../Shared/FilledLocalizationProvider";

interface EditCollaborationDialogProps {
  collaborationId: string;
  open: boolean;
  onClose: () => void;
}

function useFetchEditCollaborationDialogData(collaborationId: string) {
  const {
    collaborationWithEditableFields,
    loadingCollaborationEditableFields,
    errorOnFetchingCollaborationEditableFields,
  } = useFetchCollaborationEditableFieldsRule(collaborationId);
  const { connectedUserParty, loadingConnectedUserParty, errorOnFetchingConnectedUserParty } =
    useFetchConnectedUserPartyRule(collaborationId);

  const fetchLoadingManager = new FetchLoadingManager(
    [loadingCollaborationEditableFields, loadingConnectedUserParty],
    [errorOnFetchingCollaborationEditableFields, errorOnFetchingConnectedUserParty],
  );
  return {
    collaborationWithEditableFields,
    connectedUserParty,
    loading: fetchLoadingManager.hasFetchLoading(),
    error: fetchLoadingManager.hasFetchInError(),
  };
}

export const EditCollaborationDialog = (props: EditCollaborationDialogProps) => {
  const { t } = useCollaborationTranslation("collaborationEdit");
  const { editCollaborationCommand } = useEditCollaborationRule(props.collaborationId);

  const { collaborationWithEditableFields, connectedUserParty, loading, error } = useFetchEditCollaborationDialogData(
    props.collaborationId,
  );

  if (loading || error) {
    return null;
  }

  const deadline = collaborationWithEditableFields?.deadline
    ? DateTime.fromISO(collaborationWithEditableFields?.deadline)
    : null;

  //Handle value change on form
  const editCollaboration = (value: string, key: keyof EditCollaborationInput) => {
    const { name, owningPartyName, invitedPartyName, description, deadline } = collaborationWithEditableFields;
    const editCollaborationInput: EditCollaborationInput = {
      name,
      owningPartyName,
      invitedPartyName,
      description,
      deadline,
    };
    editCollaborationInput[key as keyof EditCollaborationInput] = value;
    editCollaborationCommand(editCollaborationInput);
  };

  const setCollaborationDeadline = (date: DateTime | null) => {
    const newDeadline = date ? formatToLongDate(date) : "";
    editCollaboration(newDeadline, "deadline");
  };

  return (
    <ResponsiveDialog
      open={props.open}
      onClose={props.onClose}
      fullWidth
      maxWidth="sm"
      title={t("edit.title")}
      content={
        <Box
          display={"grid"}
          gridTemplateRows={"repeat(4,40px)"}
          gridTemplateColumns={"max-content 1fr"}
          rowGap={2}
          columnGap={4}
        >
          <TakeTurnsFormLabel label={t("edit.name")} />
          <LabelWithEditButton
            required
            currentLabel={collaborationWithEditableFields.name}
            onEditEnded={(value) => editCollaboration(value, "name")}
            maxLength={TEXT_FIELD_MAX_LENGTH}
          />
          <TakeTurnsFormLabel label={t("edit.deadline")} />
          <Box sx={{ flex: 0.5 }}>
            <FilledLocalizationProvider>
              <DatePicker
                disablePast
                slots={{
                  openPickerIcon: () => <IconCalendar size={20} color={TakeTurnsColors.grayButton} />,
                }}
                slotProps={{
                  textField: {
                    tabIndex: 4,
                  },
                }}
                value={deadline}
                onChange={setCollaborationDeadline}
              />
            </FilledLocalizationProvider>
          </Box>
          <TakeTurnsFormLabel label={t("edit.yourParty")} />
          <LabelWithEditButton
            required
            currentLabel={
              connectedUserParty === Party.Owning
                ? collaborationWithEditableFields.owningPartyName
                : collaborationWithEditableFields.invitedPartyName
            }
            onEditEnded={(value) =>
              editCollaboration(value, connectedUserParty === Party.Owning ? "owningPartyName" : "invitedPartyName")
            }
            maxLength={TEXT_FIELD_MAX_LENGTH}
          />
          <TakeTurnsFormLabel label={t("edit.otherParty")} />
          <LabelWithEditButton
            required
            currentLabel={
              connectedUserParty === Party.Owning
                ? collaborationWithEditableFields.invitedPartyName
                : collaborationWithEditableFields.owningPartyName
            }
            onEditEnded={(value) =>
              editCollaboration(value, connectedUserParty === Party.Owning ? "invitedPartyName" : "owningPartyName")
            }
            maxLength={TEXT_FIELD_MAX_LENGTH}
          />
        </Box>
      }
      footer={
        <TakeTurnsDialogFooter closeDialog={props.onClose} helpCenterSection={HelpCenterSections.EDIT_COLLABORATION} />
      }
    />
  );
};
