/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Button, Tooltip } from "@mui/material";
import { Fragment, useCallback, useState } from "react";
import { useSharedCollaborationTranslation } from "@taketurns-i18n/collaboration/shared/useSharedCollaborationTranslation";
import { IconFolderPlus } from "../../../commons/icons";
import { AddFolderDialog } from "./AddFolderDialog";

interface AddFolderButtonProps {
  addFolder: (folderName: string) => unknown;
  longWordingMode?: boolean;
  onDialogOpen?: () => void;
}

export const AddFolderButton = ({ addFolder, longWordingMode, onDialogOpen }: AddFolderButtonProps) => {
  const [isAddFolderDialogOpened, setIsAddFolderDialogOpened] = useState(false);

  const closeAddFolderDialog = useCallback(() => {
    setIsAddFolderDialogOpened(false);
  }, []);

  const openAddFolderDialog = useCallback(() => {
    onDialogOpen?.();
    setIsAddFolderDialogOpened(true);
  }, []);

  const { t } = useSharedCollaborationTranslation();
  const wording = longWordingMode ? t("AddFolderButton.label") : t("AddFolderButton.shortLabel");

  return (
    <Fragment>
      <Tooltip
        title={t("AddFolderButton.tooltip")}
        disableFocusListener={longWordingMode}
        disableHoverListener={longWordingMode}
        disableTouchListener={longWordingMode}
      >
        <Button
          startIcon={<IconFolderPlus size={24} />}
          variant={"text"}
          onClick={openAddFolderDialog}
          sx={{ "& .MuiButton-startIcon": { mr: { xs: "3px", lg: "6px" } } }}
          data-cy="AddFolderButton"
        >
          {wording}
        </Button>
      </Tooltip>
      <AddFolderDialog open={isAddFolderDialogOpened} onClose={closeAddFolderDialog} addFolder={addFolder} />
    </Fragment>
  );
};
