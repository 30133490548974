/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useSnackbar } from "notistack";
import {
  useSharedCollaborationTranslation,
} from "@taketurns-i18n/collaboration/shared/useSharedCollaborationTranslation";
import { useSendUpdates } from "@taketurns-repositories/collaboration/graphql/mutations/content/updates/useSendUpdates";
import {
  useNavigateToCollaborationListRule,
} from "@taketurns-rules/collaboration/commands/navigation/useNavigateToCollaborationListRule";
import {
  useGetDisplayedCollaborationIdRule,
} from "@taketurns-rules/collaboration/queries/collaboration/useGetDisplayedCollaborationIdRule";

export const useSendUpdatesRule = () => {
  const { sendUpdates: sendUpdatesCommand, sendUpdatesResult } = useSendUpdates();
  const { enqueueSnackbar } = useSnackbar();
  const collaborationId = useGetDisplayedCollaborationIdRule();
  const { t } = useSharedCollaborationTranslation();
  const navigateToCollaborationList = useNavigateToCollaborationListRule();

  const sendUpdate = (note?: string) => {
    sendUpdatesCommand({ collaborationId, note })
      .then(() => {
        enqueueSnackbar(t("SendUpdates.snackbar"), {
          variant: "info",
        });
        navigateToCollaborationList();
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar(error.message, { variant: "error" });
      });
  };
  return { sendUpdates: sendUpdate, sendUpdatesResult };
};
