/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import i18n from "@taketurns-i18n/i18n";
import {
  helpCenterEnvironmentVariables,
  LANGUAGE_PARAMETER,
} from "@taketurns-repositories/webapp/environmentVariables/helpCenterUrl";

export const useGetHelpCenterUrls = () => {
  const language = i18n.language;

  const { helpCenterUrl, termsUrl, privacyUrl, chatGptUrl } = helpCenterEnvironmentVariables;
  return {
    helpCenterUrl: helpCenterUrl.replace(LANGUAGE_PARAMETER, language),
    termsUrl: termsUrl.replace(LANGUAGE_PARAMETER, language),
    privacyUrl: privacyUrl.replace(LANGUAGE_PARAMETER, language),
    chatGptUrl: chatGptUrl.replace(LANGUAGE_PARAMETER, language),
  };
};
