/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { DateTime, Interval } from "luxon";
import i18n from "@taketurns-i18n/i18n";

/**
 * Get date in small format
 * @param date
 * @param language
 * @param formatOptions
 * @returns Date in small format, null if invalid date
 */
export const getSmallDate = (date: string, language: string, formatOptions?: Intl.DateTimeFormatOptions) => {
  const dt = DateTime.fromISO(date, { zone: "utc" });

  if (dt.isValid) {
    if (formatOptions) {
      return dt.setZone("local").setLocale(language).toLocaleString(formatOptions, {});
    }
    return dt.setZone("local").setLocale(language).toLocaleString({ month: "short", day: "numeric" }, {});
  }
  return null;
};

/**
 * Get percentage of time elapsed between
 * collaboration start date and deadline.
 *
 * @param deadline
 * @param startDate
 * @returns Percentage of time elapsed between
 */
export const getDeadlineProgressValue = (deadline: string, startDate: string) => {
  const dtNow = DateTime.now();
  const dtDeadline = DateTime.fromISO(deadline);
  dtDeadline.setZone("local");
  const dtStartDate = DateTime.fromISO(startDate);
  dtStartDate.setZone("local");

  const diffNowAndStartDate = Interval.fromDateTimes(dtStartDate, dtNow);
  const diffDeadlineAndStartDate = Interval.fromDateTimes(dtStartDate, dtDeadline);
  const diffDeadlineAndNow = Interval.fromDateTimes(dtNow, dtDeadline);

  return diffDeadlineAndNow.isValid
    ? (diffNowAndStartDate.length("seconds") * 100) / diffDeadlineAndStartDate.length("seconds")
    : 100;
};

/**
 * Compare date to allow sort
 * Date are in string format, formatted in
 * datetime + local zone and finally compared
 * @param {string} date1
 * @param {string} date2
 * @returns -1 if date1 < date2 or 1
 */
export const compareDateFromString = (date1: string, date2: string) => {
  const dt1 = DateTime.fromISO(date1);
  dt1.setZone("local");
  const dt2 = DateTime.fromISO(date2);
  dt2.setZone("local");
  return dt1 < dt2 ? -1 : 1;
};

export const compareDaysFromDates = (date1: string | DateTime, date2: string | DateTime) => {
  const dt1 = (typeof date1 === "string" ? DateTime.fromISO(date1) : date1).startOf("day");
  const dt2 = (typeof date2 === "string" ? DateTime.fromISO(date2) : date2).startOf("day");
  return dt1.diff(dt2, "days").days;
};

export const getLocalizedSmallDateString = (date: DateTime) => {
  return date
    .setZone("local")
    .setLocale(i18n.language)
    .toLocaleString({ month: "short", day: "numeric", year: "numeric" }, {});
};

export const formatToLongDate = (date: string | DateTime) => {
  const LONG_DATE_FORMAT = "yyyy-MM-dd";

  if (date instanceof DateTime) {
    return date.toFormat(LONG_DATE_FORMAT);
  }
  return DateTime.fromISO(date).toFormat(LONG_DATE_FORMAT);
};

export const subtractNowInDays = (dateToSubtractFrom: string) => {
  return DateTime.fromISO(dateToSubtractFrom).diffNow("days").days;
};
