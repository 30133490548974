/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import { ResetPasswordForm } from "@taketurns-app/pages/public/components/ResetPasswordForm";
import { useAuthContext } from "@taketurns-app/routing/AuthProvider";
import { PUBLIC_ROUTES } from "@taketurns-app/routing/routes/publicRoutes.constants";
import { getUserSettingsSectionUrl, PROFILE_SECTION } from "@taketurns-app/routing/routes/userSettingsSections";
import { LinkStyled } from "@taketurns-components/webapp/LinkStyled";
import { useSharedWebappTranslation } from "@taketurns-i18n/webapp/shared/useSharedWebappTranslation";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { useIsOnMobileRule } from "@taketurns-rules/commons/theme/useIsOnMobileRule";
import { MobileCardLayout } from "../../layouts/public/MobileCardLayout";
import { UserAuthenticationLayout } from "../../layouts/public/UserAuthenticationLayout";
import { RegisterOrLoginCard } from "./components/RegisterOrLoginCard";
import { RegisterOrLoginHeader } from "./components/RegisterOrLoginHeader";

export const ResetPassword = () => {
  const { t } = useSharedWebappTranslation();
  const isOnMobile = useIsOnMobileRule();

  if (isOnMobile) {
    return (
      <MobileCardLayout>
        <RegisterOrLoginHeader text={t("resetPassword.title")} secondaryText={<LinkToSignInOrHomePage />} />
        <ResetPasswordForm />
      </MobileCardLayout>
    );
  }

  return (
    <UserAuthenticationLayout maxWidth={"sm"}>
      <RegisterOrLoginHeader text={t("resetPassword.title")} secondaryText={<LinkToSignInOrHomePage />} />
      <RegisterOrLoginCard>
        <ResetPasswordForm />
      </RegisterOrLoginCard>
    </UserAuthenticationLayout>
  );
};

const LinkToSignInOrHomePage = () => {
  const { t } = useSharedWebappTranslation();
  const { isAuthenticated } = useAuthContext();
  const { state, search } = useLocation();

  const PageLink = isAuthenticated ? (
    <LinkStyled to={getUserSettingsSectionUrl(PROFILE_SECTION)}>{t("resetPassword.backToUserProfilePage")}</LinkStyled>
  ) : (
    <LinkStyled to={PUBLIC_ROUTES.SIGN_IN + search} state={state}>
      {t("resetPassword.signIn")}
    </LinkStyled>
  );

  return (
    <Typography variant="caption" color={TakeTurnsColors.darkGray}>
      {t("resetPassword.or") + " "}
      {PageLink}
    </Typography>
  );
};
