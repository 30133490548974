/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export const PasswordlessIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox={"0 0 30 30"}>
      <path d="M 0 0 L 30 0 L 30 30 L 0 30 Z" fill="transparent"></path>
      <path
        d="M 20.848 7.382 C 22.64 7.382 24.102 8.844 24.102 10.636 L 24.102 23.827 C 24.102 25.619 22.64 27.081 20.848 27.081 L 3.26 27.081 C 1.467 27.081 0.006 25.619 0.006 23.827 L 0.006 10.636 C 0.006 8.844 1.467 7.382 3.26 7.382 Z M 20.848 9.492 L 3.26 9.492 C 2.633 9.493 2.116 10.009 2.116 10.636 L 2.116 23.827 C 2.116 24.454 2.633 24.971 3.26 24.971 L 20.848 24.971 C 21.475 24.971 21.992 24.454 21.992 23.827 L 21.992 10.636 C 21.992 10.009 21.475 9.493 20.848 9.492 Z"
        fill="rgb(152,152,152)"
      ></path>
      <path
        d="M 22.442 9.771 L 23.652 11.5 L 12.659 19.195 L 12.054 19.619 L 11.449 19.195 L 0.456 11.5 L 1.666 9.771 L 12.054 17.043 Z"
        fill="rgb(152,152,152)"
      ></path>
      <path
        d="M 22.904 17.1 C 18.988 17.1 15.813 13.925 15.813 10.009 C 15.813 6.093 18.988 2.919 22.904 2.919 C 26.82 2.919 29.994 6.093 29.994 10.009 C 29.994 13.925 26.82 17.1 22.904 17.1 Z"
        fill="rgb(29,148,253)"
      ></path>
      <path
        d="M 25.927 7.279 L 27.228 8.59 L 23.046 12.739 L 22.393 13.387 L 19.602 10.596 L 20.908 9.29 L 22.398 10.781 Z"
        fill="rgb(255,255,255)"
      ></path>
    </SvgIcon>
  );
};
