/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Chip, Dialog, DialogContent, DialogTitle, Divider, Skeleton, Stack, Typography } from "@mui/material";
import { Fragment, PropsWithChildren } from "react";
import { EventOnCollaboration } from "@taketurns/taketurns-graphql-repository";
import { DisplayedUser, EventNoteUserAndHeadline } from "@taketurns-components/collaboration/Shared/EventNote/EventNoteUserAndHeadline";
import { MobileEventNoteDialogContent } from "@taketurns-components/collaboration/Shared/EventNote/MobileEventNoteDialog";
import { MobileEventNoteDialogHeader } from "@taketurns-components/collaboration/Shared/EventNote/MobileEventNoteDialogHeader";
import { DialogFooter } from "@taketurns-components/commons/dialog/DialogFooter";
import { MobileFullPageSheet } from "@taketurns-components/commons/MobileSheets/MobileFullPageSheet";
import { SomethingWentWrong } from "@taketurns-components/shared/SomethingWentWrong";
import { useSharedCollaborationTranslation } from "@taketurns-i18n/collaboration/shared/useSharedCollaborationTranslation";
import i18n from "@taketurns-i18n/i18n";
import { useFetchEventOnCollaboration } from "@taketurns-repositories/collaboration/graphql/queries/fields/useFetchEventOnCollaboration";
import { getSmallDate } from "@taketurns-rules/collaboration/utils/date/DateUtils";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { useIsOnMobileRule } from "@taketurns-rules/commons/theme/useIsOnMobileRule";
import { getUserPresentationRule } from "@taketurns-rules/user/getUserPresentationRule";
import { RichTextEventNote } from "./RichTextEventNote";

interface EventNoteDialogProps {
  open: boolean;
  onClose: () => void;
  collaborationId: string;
  eventId: string;
}

export const EventNoteDialog = (props: PropsWithChildren<EventNoteDialogProps>) => {
  const isOnMobile = useIsOnMobileRule();

  if (isOnMobile) {
    return (
      <MobileFullPageSheet
        isOpen={props.open}
        header={
          <MobileEventNoteDialogHeader
            collaborationId={props.collaborationId}
            closePage={props.onClose}
            eventId={props.eventId}
          />
        }
      >
        <MobileEventNoteDialogContent
          collaborationId={props.collaborationId}
          onClose={props.onClose}
          eventId={props.eventId}
        />
      </MobileFullPageSheet>
    );
  }

  return (
    <Dialog
      fullWidth
      maxWidth={"md"}
      PaperProps={{ sx: { borderRadius: "20px" } }}
      open={props.open}
      onClose={props.onClose}
    >
      <EventNoteDialogTitleAndContent
        onClose={props.onClose}
        collaborationId={props.collaborationId}
        eventId={props.eventId}
      />
    </Dialog>
  );
};

interface EventNoteDialogTitleAndContentProps {
  onClose: () => void;
  collaborationId: string;
  eventId: string;
}

const EventNoteDialogTitleAndContent = (props: PropsWithChildren<EventNoteDialogTitleAndContentProps>) => {
  const { eventOnCollaboration, loadingEventOnCollaboration, errorOnFetchingEventOnCollaboration } =
    useFetchEventOnCollaboration(props.collaborationId, props.eventId);
  if (loadingEventOnCollaboration) {
    return (
      <Fragment>
        <DialogTitle sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Skeleton variant="text" width={100} />
        </DialogTitle>
        <Divider />
        <DialogContent sx={{ display: "flex", flexDirection: "column", gap: 2, paddingX: 8 }}>
          <Skeleton variant="text" width={100} />
        </DialogContent>
        <DialogFooter onClose={props.onClose} />
      </Fragment>
    );
  }
  if (errorOnFetchingEventOnCollaboration) {
    return <SomethingWentWrong />;
  }

  return (
    <Fragment>
      <EventNoteDialogTitle
        isEventFromUserParty={eventOnCollaboration.isFromConnectedUserParty}
        partyName={eventOnCollaboration.partyName}
        date={eventOnCollaboration.date}
      />
      <Divider />
      <EventNoteDialogContent event={eventOnCollaboration} />
      <DialogFooter onClose={props.onClose} />
    </Fragment>
  );
};

interface EventNoteDialogTitleProps {
  partyName: string;
  isEventFromUserParty: boolean;
  date: string;
}

const EventNoteDialogTitle = (props: EventNoteDialogTitleProps) => {
  const { t } = useSharedCollaborationTranslation();

  return (
    <DialogTitle sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
      <Stack direction={"row"} spacing={1}>
        <Typography variant={"h6"}>{t("EventNoteDialog.noteFrom")}</Typography>
        <Chip color={props.isEventFromUserParty ? "primary" : "secondary"} label={props.partyName} />
      </Stack>
      <Typography variant={"caption"} color={TakeTurnsColors.darkGray}>
        {getSmallDate(props.date, i18n.language, {
          dateStyle: "long",
        })}
      </Typography>
    </DialogTitle>
  );
};

const EventNoteDialogContent = (props: { event: EventOnCollaboration }) => {
  const color = props.event.isFromConnectedUserParty ? "primary" : "secondary";
  const displayedUser: DisplayedUser = {
    avatar: props.event.user.avatar,
    presentation: getUserPresentationRule(props.event.user),
    jobTitle: props.event.user.jobTitle,
  };
  return (
    <DialogContent sx={{ display: "flex", flexDirection: "column", gap: 2, paddingX: 8 }}>
      <EventNoteUserAndHeadline user={displayedUser} color={color} />
      <RichTextEventNote note={props.event.note} />
    </DialogContent>
  );
};
