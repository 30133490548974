/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Stack, styled, Typography } from "@mui/material";
import { UserRoundedAvatar } from "./UserRoundedAvatar";

interface UserAvatarAndNameOrEmailProps {
  user: { firstName?: string; lastName?: string; email: string; avatar?: string };
  userIdentityColor: string;
  catchPhrase: string;
}

export const UserAvatarAndNameOrEmailAndCatchPhrase = ({
  user,
  userIdentityColor,
  catchPhrase,
}: UserAvatarAndNameOrEmailProps) => {
  const hasName = user.firstName && user.lastName;
  const label = hasName ? `${user.firstName} ${user.lastName}` : user.email;
  return (
    <AvatarAndNameOrEmailAndCatchPhrase__Container>
      <UserRoundedAvatar user={user} />
      <NameOrEmailAndCatchPhrase__Container>
        <UserNameOrEmail__Text color={userIdentityColor}>{label}</UserNameOrEmail__Text>
        <CatchPhrase__Text>{catchPhrase}</CatchPhrase__Text>
      </NameOrEmailAndCatchPhrase__Container>
    </AvatarAndNameOrEmailAndCatchPhrase__Container>
  );
};

const AvatarAndNameOrEmailAndCatchPhrase__Container = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  alignItems: "center",
  gap: theme.spacing(1),
  maxWidth: "100%",
}));

const NameOrEmailAndCatchPhrase__Container = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  gap: theme.spacing(0.5),
  overflow: "hidden",
  [theme.breakpoints.down("md")]: {
    gap: 0,
    flexDirection: "column",
  },
}));

const UserNameOrEmail__Text = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  [theme.breakpoints.down("md")]: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    fontSize: theme.typography.body2.fontSize,
  },
}));

const CatchPhrase__Text = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.body1.fontSize,
  [theme.breakpoints.down("md")]: {
    fontSize: theme.typography.body2.fontSize,
  },
}));
