/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useLocation } from "react-router-dom";
import { getInvitationToOrganizationRule } from "./getInvitationToOrganizationRule";
import { useRecipientEmailFromParamsIfInvitationRule } from "./useRecipientEmailFromParamsIfInvitationRule";

export const useInvitationEmailRule = (): string => {
  const invitationEmailInLocationState = useLocation().state?.invitationEmail;
  const recipientEmailInParams = useRecipientEmailFromParamsIfInvitationRule();
  const invitationToOrganization = getInvitationToOrganizationRule();
  return invitationEmailInLocationState || recipientEmailInParams || invitationToOrganization?.recipientEmail;
};
