/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { selector } from "recoil";
import { collaborationFormState } from "./collaborationFormState";

export const selectCollaborationFormName = selector({
  key: "collaborationFormName",
  get: ({ get }) => {
    return get(collaborationFormState)?.name;
  },
  set: ({ set }, name) => {
    set(collaborationFormState, (currentCollaborationForm) => ({
      ...currentCollaborationForm,
      name: name as string,
    }));
  },
});
