/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { enUS as dataGridEN, frFR as dataGridFR } from "@mui/x-data-grid/locales";
import i18n, { InitOptions, Resource } from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import sharedCollaborationEN from "@taketurns-i18n/collaboration/shared/en.json";
import sharedCollaborationFR from "@taketurns-i18n/collaboration/shared/fr.json";
import sharedEN from "@taketurns-i18n/shared/en.json";
import sharedFR from "@taketurns-i18n/shared/fr.json";
import sharedWebappTranslationEN from "@taketurns-i18n/webapp/shared/en.json";
import sharedWebappTranslationFR from "@taketurns-i18n/webapp/shared/fr.json";
import { TranslationsEN as chatEN } from "./chat/en";
import { TranslationsFR as chatFR } from "./chat/fr";
import AttachmentDetailsEN from "./collaboration/AttachmentDetails/en.json";
import AttachmentDetailsFR from "./collaboration/AttachmentDetails/fr.json";
import AttachmentManagerEN from "./collaboration/AttachmentManager/en.json";
import AttachmentManagerFR from "./collaboration/AttachmentManager/fr.json";
import CollaborationArchiveDownloadEN from "./collaboration/CollaborationArchiveDownload/en.json";
import CollaborationArchiveDownloadFR from "./collaboration/CollaborationArchiveDownload/fr.json";
import CollaborationCreationEN from "./collaboration/CollaborationCreation/en.json";
import CollaborationCreationFR from "./collaboration/CollaborationCreation/fr.json";
import CollaborationListEN from "./collaboration/CollaborationList/en.json";
import CollaborationListFR from "./collaboration/CollaborationList/fr.json";
import CollaborationViewEN from "./collaboration/CollaborationView/en.json";
import CollaborationViewFR from "./collaboration/CollaborationView/fr.json";
import { TranslationsEN as collaborationEN } from "./collaboration/en";
import { TranslationsFR as collaborationFR } from "./collaboration/fr";
import RecapEN from "./collaboration/Recap/en.json";
import RecapFR from "./collaboration/Recap/fr.json";
import { TranslationsEN as CommonsEN } from "./commons/en";
import { TranslationsFR as CommonsFR } from "./commons/fr";
import DocumentUploadEN from "./document/DocumentUpload/en.json";
import DocumentUploadFR from "./document/DocumentUpload/fr.json";
import DocumentViewEN from "./document/DocumentView/en.json";
import DocumentViewFR from "./document/DocumentView/fr.json";
import { TranslationsEN as documentEN } from "./document/en/index";
import FilePreviewEN from "./document/FilePreview/en.json";
import FilePreviewFR from "./document/FilePreview/fr.json";
import { TranslationsFR as documentFR } from "./document/fr/index";
import userSharedEN from "./user/en.json";
import userSharedFR from "./user/fr.json";
import organizationEN from "./user/Organization/en.json";
import organizationFR from "./user/Organization/fr.json";
import UserNotificationCenterEN from "./user/UserNotificationCenter/en.json";
import UserNotificationCenterFR from "./user/UserNotificationCenter/fr.json";
import organizationInvitationNotificationEN from "./user/UserNotificationCenter/OrganizationInvitationNotificationAndDialog/en.json";
import organizationInvitationNotificationFR from "./user/UserNotificationCenter/OrganizationInvitationNotificationAndDialog/fr.json";
import countriesEN from "./user/UserPlans/countries_en.json";
import countriesFR from "./user/UserPlans/countries_fr.json";
import userPlanEN from "./user/UserPlans/en.json";
import userPlanFR from "./user/UserPlans/fr.json";
import { TranslationsEN as webappEN } from "./webapp/en/index";
import { TranslationsFR as webappFR } from "./webapp/fr/index";

const DEFAULT_I18N_OPTIONS: InitOptions = {
  debug: import.meta.env.DEV,
  fallbackLng: "en",
  supportedLngs: ["en", "fr"],
  interpolation: {
    escapeValue: false,
  },
  detection: {
    order: ["localStorage"],
  },
  resources: {},
};
const resources: Resource = {
  en: {
    AttachmentDetails: AttachmentDetailsEN,
    AttachmentManager: AttachmentManagerEN,
    chat: chatEN,
    collaboration: collaborationEN,
    CollaborationArchiveDownload: CollaborationArchiveDownloadEN,
    CollaborationCreation: CollaborationCreationEN,
    CollaborationList: CollaborationListEN,
    CollaborationView: CollaborationViewEN,
    Recap: RecapEN,
    commons: CommonsEN,
    countries: countriesEN,
    MUIDataGrid: dataGridEN,
    document: documentEN,
    DocumentUpload: DocumentUploadEN,
    DocumentView: DocumentViewEN,
    FilePreview: FilePreviewEN,
    organization: organizationEN,
    organizationInvitationNotification: organizationInvitationNotificationEN,
    sharedCollaboration: sharedCollaborationEN,
    sharedWebapp: sharedWebappTranslationEN,
    UserNotificationCenter: UserNotificationCenterEN,
    userPlan: userPlanEN,
    userShared: userSharedEN,
    webapp: webappEN,
    shared: sharedEN,
  },
  fr: {
    AttachmentDetails: AttachmentDetailsFR,
    AttachmentManager: AttachmentManagerFR,
    chat: chatFR,
    collaboration: collaborationFR,
    CollaborationArchiveDownload: CollaborationArchiveDownloadFR,
    CollaborationCreation: CollaborationCreationFR,
    CollaborationList: CollaborationListFR,
    Recap: RecapFR,
    CollaborationView: CollaborationViewFR,
    commons: CommonsFR,
    countries: countriesFR,
    MUIDataGrid: dataGridFR,
    document: documentFR,
    DocumentUpload: DocumentUploadFR,
    DocumentView: DocumentViewFR,
    FilePreview: FilePreviewFR,
    organization: organizationFR,
    organizationInvitationNotification: organizationInvitationNotificationFR,
    sharedCollaboration: sharedCollaborationFR,
    sharedWebapp: sharedWebappTranslationFR,
    UserNotificationCenter: UserNotificationCenterFR,
    userPlan: userPlanFR,
    userShared: userSharedFR,
    webapp: webappFR,
    shared: sharedFR,
  },
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    ...DEFAULT_I18N_OPTIONS,
    resources,
  });

export default i18n;
