/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Dialog, DialogContent, DialogTitle, Divider, Grid, Typography } from "@mui/material";
import { Party } from "@taketurns/taketurns-graphql-repository";
import { TakeTurnsDialogFooter } from "@taketurns-components/collaboration/Shared/content/TakeTurnsDialogFooter";
import { useCollaborationTranslation } from "@taketurns-i18n/collaboration/useCollaborationTranslation";
import { useFetchParticipantsAndInvitationsRule } from "@taketurns-rules/collaboration/queries/collaborationView/useFetchParticipantsAndInvitationsRule";
import { useFetchConnectedUserPartyRule } from "@taketurns-rules/collaboration/queries/context/useFetchConnectedUserPartyRule";
import { useSubscribeToParticipantsAndInvitationsChangedRule } from "@taketurns-rules/collaboration/subscriptions/useSubscribeToParticipantsAndInvitationsChangedRule";
import { HelpCenterSections } from "@taketurns-rules/commons/enum/helpCenterSectionsEnum";
import { ParticipantPanel } from "./ParticipantPanel";

interface ParticipantsDialogProps {
  isOpen: boolean;
  closeDialog: () => void;
}

export const ParticipantsDialog = (props: ParticipantsDialogProps) => {
  const { isOpen, closeDialog } = props;

  const { t } = useCollaborationTranslation("collaborationView");

  const CollaborationParticipantsDialogContent = () => {
    const { invitations, participants, errorOnParticipantsAndInvitations } = useFetchParticipantsAndInvitationsRule();
    useSubscribeToParticipantsAndInvitationsChangedRule();
    const { connectedUserParty } = useFetchConnectedUserPartyRule();
    let participantsList;
    if (errorOnParticipantsAndInvitations) {
      console.error(errorOnParticipantsAndInvitations);
      participantsList = <Typography color="error">{"Something went wrong"}</Typography>;
    } else {
      participantsList = (
        <Grid container justifyContent={"center"}>
          <ParticipantPanel
            invitations={invitations}
            participants={participants}
            addBorder
            userParty={connectedUserParty}
            party={Party.Owning}
          />
          <ParticipantPanel
            invitations={invitations}
            participants={participants}
            userParty={connectedUserParty}
            party={Party.Invited}
          />
        </Grid>
      );
    }
    return <DialogContent sx={{ display: "flex" }}>{participantsList}</DialogContent>;
  };

  return (
    <Dialog PaperProps={{ sx: { minHeight: "60%" } }} fullWidth maxWidth={"lg"} open={isOpen} onClose={closeDialog}>
      <DialogTitle>{t("participants")}</DialogTitle>
      <Divider />
      <CollaborationParticipantsDialogContent />
      <TakeTurnsDialogFooter closeDialog={closeDialog} helpCenterSection={HelpCenterSections.PARTICIPANTS} />
    </Dialog>
  );
};
