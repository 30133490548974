/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import {
  useIsAttachmentRevisionAddInProgress,
} from "@taketurns-repositories/collaboration/state/read/useIsAttachmentRevisionAddInProgress";
import {
  useGetOpenedAttachmentIdRule,
} from "@taketurns-rules/collaboration/queries/attachment/useGetOpenedAttachmentIdRule";
import {
  useGetDisplayedCollaborationIdRule,
} from "@taketurns-rules/collaboration/queries/collaboration/useGetDisplayedCollaborationIdRule";

export const useIsAttachmentRevisionAddInProgressRule = () => {
  const collaborationId = useGetDisplayedCollaborationIdRule();
  const attachmentId = useGetOpenedAttachmentIdRule();

  return useIsAttachmentRevisionAddInProgress(collaborationId, attachmentId);
};
