/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useMutation } from "@apollo/client";
import {
  Collaboration,
  CreateCollaborationInput,
  CreateFolderInput,
  DocumentToRegisterInput,
  MutationCreateCollaborationArgs,
  Party,
} from "@taketurns/taketurns-graphql-repository";

export const useCreateCollaboration = () => {
  const mutation = gql`
    mutation createCollaboration(
      $input: CreateCollaborationInput!
      $documentToRegisterInputs: [DocumentToRegisterInput!]!
    ) {
      createCollaboration(input: $input, documentToRegisterInputs: $documentToRegisterInputs) {
        id
        name
        description
        owningPartyName
        invitedPartyName
        creator
        deadline
        status
      }
    }
  `;
  const [createCollaborationMutation, createCollaborationResult] = useMutation<
    { createCollaboration: Collaboration },
    MutationCreateCollaborationArgs
  >(mutation);
  const createCollaboration = (
    collaborationInput: CreateCollaborationInput,
    documentToRegisterInputs: DocumentToRegisterInput[],
  ) => {
    return createCollaborationMutation({
      variables: {
        input: fillCollaborationInputRequestsWithParty(fillCollaborationInputWithUser(collaborationInput)),
        documentToRegisterInputs,
      },
    });
  };
  return { createCollaboration, createCollaborationResult };
};

function fillCollaborationInputWithUser(createCollaborationInput: CreateCollaborationInput) {
  const newCreateCollaborationInput = { ...createCollaborationInput };
  newCreateCollaborationInput.invitations =
    newCreateCollaborationInput.invitations?.map((invitee) => ({
      ...invitee,
      connectedUser: undefined,
    })) ?? [];
  return newCreateCollaborationInput;
}

function fillCollaborationInputRequestsWithParty(createCollaborationInput: CreateCollaborationInput) {
  const newCreateCollaborationInput = { ...createCollaborationInput };
  newCreateCollaborationInput.rootFolder = fillFolderRequestsWithParty(newCreateCollaborationInput.rootFolder);
  return newCreateCollaborationInput;
}

function fillFolderRequestsWithParty(createFolderInput: CreateFolderInput) {
  const newCreateFolderInput = { ...createFolderInput };
  newCreateFolderInput.requests =
    newCreateFolderInput.requests?.map((request) => ({
      ...request,
      party: Party.Owning,
    })) ?? [];
  newCreateFolderInput.folders =
    newCreateFolderInput.folders?.map((folder) => fillFolderRequestsWithParty(folder)) ?? [];
  return newCreateFolderInput;
}
