/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useSetRecoilState } from "recoil";
import { Invitee, Role } from "@taketurns/taketurns-graphql-repository";
import { selectCollaborationFormInvitations } from "../../../../recoil/collaborationCreation/form/values/selectCollaborationFormInvitations";

export const useEditInvitationRoleInCollaborationCreation = () => {
  const setCollaborationFormInvitations = useSetRecoilState(selectCollaborationFormInvitations);
  return (invitation: Invitee, newRole: Role) => {
    setCollaborationFormInvitations((existingInvitations) =>
      existingInvitations.map((existingInvitation) => {
        if (existingInvitation.email === invitation.email) {
          return { ...existingInvitation, role: newRole };
        }
        return existingInvitation;
      }),
    );
  };
};
