/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useRecoilCallback, useRecoilValue } from "recoil";
import { FolderInCreation } from "@taketurns-repositories/collaboration/models/folderInCreation";
import { folderByIdState } from "../../recoil/collaborationCreation/folderByIdState";

export const useGetFolderInCreation = (folderId: string) => {
  return useRecoilValue(folderByIdState(folderId));
};

export const useGetFolderByIdInCreation = () => {
  return useRecoilCallback(
    ({ snapshot }) =>
      (folderId: string): FolderInCreation => {
        return snapshot.getLoadable(folderByIdState(folderId)).contents;
      },
    [],
  );
};
