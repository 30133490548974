/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { enUS, frFR } from "@mui/x-date-pickers/locales";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { PropsWithChildren } from "react";
import i18n from "@taketurns-i18n/i18n";

export const FilledLocalizationProvider = ({ children }: PropsWithChildren) => {
  const language = i18n.language;
  const localeText = (language === "fr" ? frFR : enUS).components.MuiLocalizationProvider.defaultProps.localeText;
  return (
    <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={language} localeText={localeText}>
      {children}
    </LocalizationProvider>
  );
};
