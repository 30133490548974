/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { IconButton, Stack, styled, Typography } from "@mui/material";
import { forwardRef, MouseEvent } from "react";
import { IconChevronDown, IconXCircle } from "@taketurns-components/commons/icons";
import { useCollaborationListTranslation } from "@taketurns-i18n/collaboration/CollaborationList/useCollaborationListTranslation";
import { useUnselectAllSupervisorFilterItemsRule } from "@taketurns-rules/collaboration/commands/useUnselectAllSupervisorFilterItemsRule";
import { useSelectedSupervisorFilterItemsCountRule } from "@taketurns-rules/collaboration/queries/useSelectedSupervisorFilterItemsCountRule";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";

interface SupervisorFilterButtonProps {
  onClick: () => void;
  unselectAllClicked: () => void;
}

export const SupervisorFilterButton = forwardRef<HTMLDivElement, SupervisorFilterButtonProps>(
  ({ onClick, unselectAllClicked }, supervisorFilterAnchorRef) => {
    const { t } = useCollaborationListTranslation();
    const selectedSupervisorFilterItemsCount = useSelectedSupervisorFilterItemsCountRule();
    const unselectAllSupervisorFilterItems = useUnselectAllSupervisorFilterItemsRule();

    const onUnselectAllClicked = (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      unselectAllClicked();
      unselectAllSupervisorFilterItems();
    };

    return (
      <SupervisorFilterButton__Container
        onClick={onClick}
        ref={supervisorFilterAnchorRef}
        hasSelectedElements={selectedSupervisorFilterItemsCount > 0}
      >
        {selectedSupervisorFilterItemsCount > 0 && (
          <IconButton onClick={onUnselectAllClicked}>
            <IconXCircle size={18} color={TakeTurnsColors.mediumGray} />
          </IconButton>
        )}
        <Typography flex={1} color={TakeTurnsColors.darkGray} variant="body2">
          {t("SupervisorFilter.viewMyTeam", { count: selectedSupervisorFilterItemsCount })}
        </Typography>
        <IconChevronDown size={18} color={TakeTurnsColors.mediumGray} />
      </SupervisorFilterButton__Container>
    );
  },
);

const SupervisorFilterButton__Container = styled(Stack, {
  shouldForwardProp: (prop) => prop !== "hasSelectedElements",
})<{ hasSelectedElements: boolean }>(({ hasSelectedElements, theme }) => ({
  flexDirection: "row",
  alignItems: "center",
  gap: theme.spacing(1),
  backgroundColor: hasSelectedElements ? TakeTurnsColors.background : undefined,
  border: `1px ${TakeTurnsColors.lightGray} solid`,
  borderRadius: "20px",
  padding: theme.spacing(0, 1, 0, hasSelectedElements ? 1 : 2),
  height: "36px",
  cursor: "pointer",
}));
