/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Button, DialogActions, DialogContentText, IconButton } from "@mui/material";
import { Fragment, useState } from "react";
import { OrganizationDomain } from "@taketurns/taketurns-graphql-repository";
import { TakeTurnsDefaultButton } from "@taketurns-components/commons/button";
import { ResponsiveDialog } from "@taketurns-components/commons/dialog/ResponsiveDialog";
import { IconX } from "@taketurns-components/commons/icons";
import { useOrganizationTranslation } from "@taketurns-i18n/user/Organization/useOrganizationTranslation";
import { useRemoveOrganizationDomainRule } from "@taketurns-rules/user/commands/useRemoveOrganizationDomainRule";

export const DomainRemoveButtonAndDialog = ({ domain }: { domain: OrganizationDomain }) => {
  const [isConfirmationDialogOpened, setIsConfirmationDialogOpened] = useState(false);

  const openDomainRemoveConfirmationDialog = () => {
    setIsConfirmationDialogOpened(true);
  };

  const closeDomainRemoveConfirmationDialog = () => {
    setIsConfirmationDialogOpened(false);
  };
  return (
    <Fragment>
      <IconButton onClick={openDomainRemoveConfirmationDialog} data-cy={`DomainRemoveButton__${domain.name}`}>
        <IconX size={18} />
      </IconButton>
      <DomainRemoveConfirmationDialog
        domain={domain}
        open={isConfirmationDialogOpened}
        onClose={closeDomainRemoveConfirmationDialog}
      />
    </Fragment>
  );
};

const DomainRemoveConfirmationDialog = (props: { domain: OrganizationDomain; open: boolean; onClose: () => void }) => {
  const { removeOrganizationDomain, loadingRemoveOrganizationDomain } = useRemoveOrganizationDomainRule();

  const confirmRemoveOrganizationDomain = () => {
    removeOrganizationDomain({
      removeOrganizationDomainInput: {
        name: props.domain.name,
      },
    });
    props.onClose();
  };

  const { t } = useOrganizationTranslation();

  return (
    <ResponsiveDialog
      open={props.open}
      onClose={props.onClose}
      title={t("DomainRemoveButtonAndDialog.title")}
      content={
        <DialogContentText>
          {t("DomainRemoveButtonAndDialog.description", { name: props.domain.name })}
        </DialogContentText>
      }
      footer={
        <DialogActions>
          <Button variant={"text"} onClick={props.onClose}>
            {t("DomainRemoveButtonAndDialog.cancel")}
          </Button>
          <TakeTurnsDefaultButton
            disabled={loadingRemoveOrganizationDomain}
            onClick={confirmRemoveOrganizationDomain}
            data-cy={`DomainRemoveConfirm__${props.domain.name}`}
          >
            {t("DomainRemoveButtonAndDialog.confirm")}
          </TakeTurnsDefaultButton>
        </DialogActions>
      }
    />
  );
};
