/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useJoinAndGetStreamChatChannel } from "@taketurns-repositories/chat/streamChat/useJoinAndGetStreamChatChannel";

export const useConnectToCollaborationChatRule = (collaborationId: string) => {
  const {
    channel,
    loading: loadingJoinChannel,
    technicalError,
    notAuthorizedError,
  } = useJoinAndGetStreamChatChannel(collaborationId);

  return {
    channel,
    loading: loadingJoinChannel,
    technicalError,
    notAuthorizedError,
  };
};
