/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Stack, Typography } from "@mui/material";
import Markdown from "markdown-to-jsx";
import { useEffect, useRef, useState } from "react";
import { GenerateAiAssistantResponseFileUrlErrorCode } from "@taketurns/taketurns-graphql-repository";
import { AiAssistantInvalidApiKey } from "@taketurns-components/collaboration/CollaborationView/AttachmentDetails/components/AttachmentAiAssistant/AiAssistantInvalidApiKey";
import { AiAssistantLoadingIndicator } from "@taketurns-components/collaboration/CollaborationView/AttachmentDetails/components/AttachmentAiAssistant/AiAssistantLoadingIndicator";
import { TakeTurnsTextButton } from "@taketurns-components/commons/button";
import { SomethingWentWrong } from "@taketurns-components/shared/SomethingWentWrong";
import { useSharedCollaborationTranslation } from "@taketurns-i18n/collaboration/shared/useSharedCollaborationTranslation";
import { useSharedTranslation } from "@taketurns-i18n/shared/useSharedTranslation";
import { useFetchComparisonUrl } from "@taketurns-repositories/document/graphql/queries/useFetchComparisonUrl";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";

interface AttachmentAiAssistantComparisonProps {
  documentId: string;
  collaborationId: string;
  revisionId: string;
}

export const AttachmentAiAssistantComparison = (props: AttachmentAiAssistantComparisonProps) => {
  const { retryFetchingComparison, comparison, loading, error, errorCode } =
    useFetchAttachmentRevisionAiAssistantComparisonDataRule(props);
  const { t } = useSharedCollaborationTranslation();
  const { t: tShared } = useSharedTranslation();

  const titleRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (comparison) {
      titleRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [comparison]);

  const loadingContainerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (loading) {
      loadingContainerRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [loading]);

  if (loading) {
    return (
      <Stack spacing={3} ref={loadingContainerRef}>
        <Typography color={TakeTurnsColors.aiAssistant}>
          {t("AttachmentAiAssistant.AttachmentAiAssistantComparison.prompt")}
        </Typography>
        <AiAssistantLoadingIndicator />
      </Stack>
    );
  }
  if (error) {
    return (
      <Stack spacing={3}>
        <Typography color={TakeTurnsColors.aiAssistant}>
          {t("AttachmentAiAssistant.AttachmentAiAssistantComparison.prompt")}
        </Typography>
        <Stack direction="row" spacing={1}>
          <SomethingWentWrong />
          <TakeTurnsTextButton
            fontSize="1rem"
            onClick={() => {
              retryFetchingComparison();
            }}
          >
            {tShared("tryAgain")}
          </TakeTurnsTextButton>
        </Stack>
      </Stack>
    );
  }
  if (errorCode && errorCode === GenerateAiAssistantResponseFileUrlErrorCode.InvalidApiKey) {
    return <AiAssistantInvalidApiKey prompt={t(`AttachmentAiAssistant.AttachmentAiAssistantComparison.prompt`)} />;
  }
  if (errorCode) {
    return (
      <Stack spacing={3}>
        <Typography color={TakeTurnsColors.aiAssistant}>
          {t("AttachmentAiAssistant.AttachmentAiAssistantComparison.prompt")}
        </Typography>
        <Typography>{t(`AttachmentAiAssistant.errors.${errorCode}`)}</Typography>
      </Stack>
    );
  }
  return (
    <Stack spacing={3}>
      <Typography color={TakeTurnsColors.aiAssistant} ref={titleRef}>
        {t("AttachmentAiAssistant.AttachmentAiAssistantComparison.prompt")}
      </Typography>
      <Markdown
        data-cy="AiAssistantComparison"
        options={{
          overrides: {
            th: {
              component: "th",
              props: {
                style: { borderBottom: "1px solid #ddd" },
              },
            },
            td: {
              component: "td",
              props: {
                style: { borderBottom: "1px solid #ddd" },
              },
            },
          },
        }}
      >
        {comparison}
      </Markdown>
    </Stack>
  );
};
const useFetchAttachmentRevisionAiAssistantComparisonDataRule = ({
  documentId,
  collaborationId,
  revisionId,
}: {
  documentId: string;
  collaborationId: string;
  revisionId: string;
}) => {
  const { i18n } = useSharedCollaborationTranslation();
  const { retryFetchingComparison, loading, downloadUrl, error, errorCode } = useFetchComparisonUrl({
    documentId,
    revisionId,
    language: i18n.language,
    collaborationId: collaborationId,
  });

  const { comparison, downloadComparisonFileError } = useDownloadComparison(downloadUrl);

  return {
    retryFetchingComparison,
    loading,
    error: error || downloadComparisonFileError,
    errorCode,
    comparison,
  };
};

function useDownloadComparison(downloadUrl: string | undefined) {
  const [comparison, setComparison] = useState("");
  const [downloadComparisonFileError, setDownloadComparisonFileError] = useState<boolean>(false);
  useEffect(() => {
    if (downloadUrl) {
      fetch(downloadUrl)
        .then((response) => {
          if (!response.ok) {
            setDownloadComparisonFileError(true);
          }
          return response.text();
        })
        .then((data) => setComparison(data));
    }
  }, [downloadUrl]);
  return { comparison, downloadComparisonFileError };
}
