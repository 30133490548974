/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { selector } from "recoil";
import { collaborationFormErrorsState } from "@taketurns-repositories/collaboration/state/recoil/collaborationCreation/form/errors/collaborationFormErrorsState";

export const selectCollaborationMainFormErrorsState = selector<{ name: boolean }>({
  key: "collaborationMainFormErrors",
  get: ({ get }) => {
    return { name: get(collaborationFormErrorsState).name };
  },
});
