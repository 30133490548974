/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { styled, Typography } from "@mui/material";
import { ComponentType } from "react";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { TakeTurnsCardHeader } from "./TakeTurnsCardHeader";

interface ExpectedActionCardHeaderProps {
  actionLabel: string;
  ActionIcon: ComponentType<{ color: string; size: number; strokeWidth: number }>;
}

export const ExpectedActionCardHeader = ({ actionLabel, ActionIcon }: ExpectedActionCardHeaderProps) => {
  return (
    <ExpectedActionCardHeader__Container backgroundColor={TakeTurnsColors.otherParty}>
      <ExpectedActionCardHeader__Label>{actionLabel}</ExpectedActionCardHeader__Label>
      <ActionIcon color="white" size={14} strokeWidth={3} />
    </ExpectedActionCardHeader__Container>
  );
};

const ExpectedActionCardHeader__Label = styled(Typography)({
  fontSize: 12,
  color: "white",
  fontWeight: 600,
  textTransform: "uppercase",
  lineHeight: "1.6em",
});

const ExpectedActionCardHeader__Container = styled(TakeTurnsCardHeader)({
  alignItems: "center",
  justifyContent: "space-between",
  padding: "0 10px",
});
