/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useSelectedSupervisorFilterItems } from "@taketurns-repositories/collaboration/state/read/useSelectedSupervisorFilterItems";
import { useFetchConnectedUser } from "@taketurns-repositories/user/graphql/queries/useFetchConnectedUser";

export const useSelectedSupervisorFilterItemsCountRule = () => {
  const { connectedUser, loadingConnectedUser } = useFetchConnectedUser();
  const selectedSupervisorFilterItems = useSelectedSupervisorFilterItems();
  if (loadingConnectedUser) {
    return 0;
  }
  if (selectedSupervisorFilterItems.length === 1 && selectedSupervisorFilterItems[0] === connectedUser.id) {
    return 0;
  }
  return selectedSupervisorFilterItems.length;
};
