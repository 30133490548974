/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Skeleton, Typography } from "@mui/material";
import { useUserPlanTranslation } from "@taketurns-i18n/user/UserPlans/useUserPlanTranslation";
import { useFetchIsUserOrganizationPlanFreePlanRule } from "@taketurns-rules/user/queries/plan/useFetchIsUserOrganizationPlanFreePlanRule";
import { FreePlanBillingPortal } from "./FreePlanBillingPortal";
import { PaidPlanBillingPortal } from "./PaidPlanBillingPortal";

export const BillingPortal = () => {
  const {
    isUserOrganizationPlanFreePlan,
    errorOnFetchingIsUserOrganizationPlanFreePlan,
    loadingIsUserOrganizationPlanFreePlan,
  } = useFetchIsUserOrganizationPlanFreePlanRule();
  const { t } = useUserPlanTranslation();

  if (loadingIsUserOrganizationPlanFreePlan) {
    return <Skeleton variant="text" />;
  }

  if (errorOnFetchingIsUserOrganizationPlanFreePlan) {
    console.error(errorOnFetchingIsUserOrganizationPlanFreePlan);
    return <Typography color={"error"}>{t("billingPortal.error")}</Typography>;
  }

  if (isUserOrganizationPlanFreePlan) {
    return <FreePlanBillingPortal />;
  }
  return <PaidPlanBillingPortal />;
};
