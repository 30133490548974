/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Box, styled, Typography } from "@mui/material";
import { useOrganizationTranslation } from "@taketurns-i18n/user/Organization/useOrganizationTranslation";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";

export const SignInSignUpOrganizationInvitationHeader = ({ organizationName }: { organizationName: string }) => {
  const { t } = useOrganizationTranslation();
  return (
    <OrganizationInvitationHeader__Container>
      <Typography data-cy="signUpInvitationOrganizationMessage" variant={"body2"}>
        {`${t("SignInSignUpOrganizationInvitationHeader.invitationMessage")} `}
        <b>{organizationName}</b>
      </Typography>
    </OrganizationInvitationHeader__Container>
  );
};

const OrganizationInvitationHeader__Container = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "12px 0",
  backgroundColor: TakeTurnsColors.darkBlue,
  color: "white",
});
