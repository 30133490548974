/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { selectorFamily, useRecoilValue } from "recoil";
import { pendingAttachmentRevisionsByCollaborationIdState } from "../recoil/pendingAttachmentRevisionsByCollaborationIdState";

const selectIsAttachmentRevisionAddInProgressState = selectorFamily<
  boolean,
  { collaborationId: string; attachmentId: string }
>({
  key: "isAttachmentRevisionAddInProgress",
  get:
    ({ collaborationId, attachmentId }) =>
    ({ get }) => {
      return get(pendingAttachmentRevisionsByCollaborationIdState(collaborationId)).some(
        (r) => r.attachmentId === attachmentId && (r.isUploading() || r.isAdding()),
      );
    },
});
export const useIsAttachmentRevisionAddInProgress = (collaborationId: string, attachmentId: string) => {
  return useRecoilValue<boolean>(selectIsAttachmentRevisionAddInProgressState({ collaborationId, attachmentId }));
};
