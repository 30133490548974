/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useUserNotificationCenterTranslation } from "@taketurns-i18n/user/UserNotificationCenter/useUserNotificationCenterTranslation";
import { useDisplayExpiredTrialNotificationRule } from "@taketurns-rules/user/commands/useDisplayExpiredTrialNotificationRule";
import { useNavigateToPlansPageRule } from "@taketurns-rules/user/commands/useNavigateToPlansPageRule";
import { TrialRemainingTimeNotificationChip } from "./TrialRemainingTimeNotificationChip";

export const TrialExpiredNotification = () => {
  const navigateToPlansPage = useNavigateToPlansPageRule();
  const { t } = useUserNotificationCenterTranslation();
  const { hideExpiredTrialNotification, isExpiredTrialNotificationDisplayed } =
    useDisplayExpiredTrialNotificationRule();

  if (!isExpiredTrialNotificationDisplayed) {
    return;
  }

  return (
    <TrialRemainingTimeNotificationChip
      expired
      label={t("TrialExpiredNotification.trialExpired")}
      buttonLabel={t("TrialExpiredNotification.subscribe")}
      onClick={navigateToPlansPage}
      onClose={hideExpiredTrialNotification}
    />
  );
};
