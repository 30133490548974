/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Box, Skeleton, Stack, Typography } from "@mui/material";
import { Fragment } from "react";
import { Trans } from "react-i18next";
import { FetchLoadingManager } from "@taketurns-app/utils/FetchLoadingManager";
import { CancelPendingUpdatesButton } from "@taketurns-components/collaboration/CollaborationView/CollaborationContent/ContentHeader/CancelPendingUpdates/CancelPendingUpdatesButton";
import { RecallUpdatesButton } from "@taketurns-components/collaboration/CollaborationView/CollaborationContent/ContentHeader/RecallUpdates/RecallUpdatesButton";
import { SendUpdateButtonWarnMessage } from "@taketurns-components/collaboration/CollaborationView/CollaborationContent/ContentHeader/SendUpdates/SendUpdateButtonWarnMessage";
import { SendUpdatesButton } from "@taketurns-components/collaboration/CollaborationView/CollaborationContent/ContentHeader/SendUpdates/SendUpdatesButton";
import { ToDoFilterToggle } from "@taketurns-components/collaboration/CollaborationView/CollaborationContent/ContentHeader/ToDoFilterToggle";
import { SomethingWentWrong } from "@taketurns-components/shared/SomethingWentWrong";
import { useCollaborationViewTranslation } from "@taketurns-i18n/collaboration/CollaborationView/useCollaborationViewTranslation";
import i18n from "@taketurns-i18n/i18n";
import { useFetchClosedCollaborationRule } from "@taketurns-rules/collaboration/queries/collaboration/closed/useFetchClosedCollaborationRule";
import { useFetchActionsPermissionsOnUpdatesRule } from "@taketurns-rules/collaboration/queries/collaborationView/useFetchActionsPermissionsOnUpdatesRule";
import { useIsCollaborationClosedRule } from "@taketurns-rules/collaboration/queries/collaborationView/useIsCollaborationClosedRule";
import { useFetchCollaborationClosingUserRule } from "@taketurns-rules/collaboration/queries/fields/useFetchCollaborationClosingUserRule";
import { useFetchCollaborationContentMetadataRule } from "@taketurns-rules/collaboration/queries/fields/useFetchCollaborationContentMetadataRule";
import { useFetchCollaborationLastUpdateEventRule } from "@taketurns-rules/collaboration/queries/fields/useFetchCollaborationLastUpdateEventRule";
import { getSmallDate } from "@taketurns-rules/collaboration/utils/date/DateUtils";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { CollaborationArchiveDownload } from "../../../CollaborationArchiveDownload/CollaborationArchiveDownload";
import { ContentHeadline } from "./ContentHeadline";

interface ContentHeaderProps {
  displayUpdatedOnly: boolean;
  setDisplayUpdatedOnly: (displayUpdatedOnly: boolean) => void;
  displayTodos: boolean;
  setDisplayTodos: (displayTodos: boolean) => void;
}

export const ContentHeader = (props: ContentHeaderProps) => {
  const {
    canCancelUpdates,
    canSendUpdates,
    canRecallUpdates,
    isCollaborationClosed,
    todoCount,
    isLastUpdateEventFromConnectedUserParty,
    loading,
    error,
  } = useFetchContentHeaderDataRule();

  const hasTodo = todoCount > 0;

  if (loading) {
    return <Skeleton width={1} />;
  }
  if (error) {
    return <SomethingWentWrong />;
  }

  const topBorderColor = canSendUpdates
    ? TakeTurnsColors.yellow
    : isLastUpdateEventFromConnectedUserParty
      ? TakeTurnsColors.connectedUser
      : TakeTurnsColors.otherParty;
  return (
    <Stack
      direction={"column"}
      height={80}
      justifyContent="center"
      borderTop={`5px solid ${topBorderColor}`}
      borderRadius={"20px 20px 0 0"}
      padding={3}
      sx={{
        background: canSendUpdates && `linear-gradient(180deg, rgba(255, 174, 0, 0.27) 0%, rgb(255, 255, 255) 100%);`,
      }}
    >
      {isCollaborationClosed && <CollaborationClosedMessageAndAction />}
      {!isCollaborationClosed && (
        <Stack direction="row" justifyContent="space-between" spacing={2} alignItems="center">
          {hasTodo && (
            <Box flex={"0 0 auto"}>
              <ToDoFilterToggle
                todoCount={todoCount}
                isToggled={props.displayTodos}
                toggle={() => props.setDisplayTodos(!props.displayTodos)}
              />
            </Box>
          )}
          {!canSendUpdates && !canCancelUpdates && (
            <Box display="flex" alignItems="center" flex={1} overflow="hidden">
              <ContentHeadline
                displayUpdatedOnly={props.displayUpdatedOnly}
                setDisplayUpdatedOnly={props.setDisplayUpdatedOnly}
              />
            </Box>
          )}
          {canSendUpdates && (
            <Fragment>
              <SendUpdateButtonWarnMessage />
              <Stack direction="row" flex="0 0 auto">
                {canCancelUpdates && <CancelPendingUpdatesButton />}
                <SendUpdatesButton />
              </Stack>
            </Fragment>
          )}
          {canRecallUpdates && (
            <Box flex="0 0 auto">
              <RecallUpdatesButton />
            </Box>
          )}
        </Stack>
      )}
    </Stack>
  );
};

const useFetchContentHeaderDataRule = () => {
  const {
    canCancelPendingUpdates,
    canSendPendingUpdates,
    canRecallUpdates,
    loadingActionsPermissionsOnUpdates,
    errorOnFetchingActionsPermissionsOnUpdates,
  } = useFetchActionsPermissionsOnUpdatesRule();
  const { isCollaborationClosed, loadingCollaborationStatus, errorOnFetchingCollaborationStatus } =
    useIsCollaborationClosedRule();
  const {
    contentMetadata,
    loading: loadingContentMetadata,
    error: errorWhileFetchingContentMetadata,
  } = useFetchCollaborationContentMetadataRule();
  const { lastUpdateEvent, recallLastUpdatesAvailable, loadingLastUpdateEvent, errorWhileFetchingLastUpdateEvent } =
    useFetchCollaborationLastUpdateEventRule();

  const fetchLoadingManager = new FetchLoadingManager(
    [loadingActionsPermissionsOnUpdates, loadingCollaborationStatus, loadingContentMetadata, loadingLastUpdateEvent],
    [
      errorOnFetchingActionsPermissionsOnUpdates,
      errorOnFetchingCollaborationStatus,
      errorWhileFetchingContentMetadata,
      errorWhileFetchingLastUpdateEvent,
    ],
  );

  const loading = fetchLoadingManager.hasFetchLoading();
  const error = fetchLoadingManager.hasFetchInError();
  if (error) {
    fetchLoadingManager.logErrors();
  }

  return {
    todoCount: contentMetadata?.todoCount,
    isLastUpdateEventFromConnectedUserParty: lastUpdateEvent?.isFromConnectedUserParty,
    canCancelUpdates: canCancelPendingUpdates,
    canSendUpdates: canSendPendingUpdates,
    canRecallUpdates: canRecallUpdates && recallLastUpdatesAvailable,
    isCollaborationClosed,
    loading,
    error,
  };
};

const CollaborationClosedMessageAndAction = () => {
  const { t } = useCollaborationViewTranslation();
  const { loadingClosedCollaboration, errorOnFetchingClosedCollaboration, closedCollaboration } =
    useFetchClosedCollaborationRule();
  const { loadingClosingUser, errorOnFetchingClosingUser, closingUser } = useFetchCollaborationClosingUserRule();

  if (errorOnFetchingClosedCollaboration || errorOnFetchingClosingUser) {
    return null;
  }
  if (loadingClosedCollaboration || loadingClosingUser) {
    return <Skeleton sx={{ flex: 1 }} variant={"text"} />;
  }

  const userNamesOrEmail =
    closingUser.firstName && closingUser.lastName
      ? `${closingUser.firstName} ${closingUser.lastName}`
      : closingUser.email;
  return (
    <Stack direction="row" justifyContent={"space-between"}>
      <Typography color={TakeTurnsColors.darkGray}>
        <Trans
          t={t}
          i18nKey={"turnHeader.closedCollaborationHeaderMessage"}
          values={{ date: getSmallDate(closedCollaboration.closingDate, i18n.language), user: userNamesOrEmail }}
        />
      </Typography>
      <CollaborationArchiveDownload />
    </Stack>
  );
};
