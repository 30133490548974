/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { styled } from "@mui/material";
import { Party } from "@taketurns/taketurns-graphql-repository";
import {
  Column,
  ColumnPaper,
  HeaderChip,
} from "@taketurns-app/pages/authenticated/CollaborationCreation/CollaborationCreationPages/commons";
import { CollaborationCreationPartyForm } from "@taketurns-components/collaboration/CollaborationCreation/CollaborationCreationPartyForm/CollaborationCreationPartyForm";
import { useWebAppTranslations } from "@taketurns-i18n/webapp/useWebAppTranslations";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { useIsOnMobileRule } from "@taketurns-rules/commons/theme/useIsOnMobileRule";

export const CollaborationCreationOwningPartyColumn = () => {
  const isOnMobile = useIsOnMobileRule();
  const { t } = useWebAppTranslations("collaboration-creation");

  return (
    <Column>
      {!isOnMobile && <HeaderChip backgroundColor={TakeTurnsColors.darkBlue}>{t("headerChip.owningParty")}</HeaderChip>}
      <CollaborationCreationOwningParty__Container className="CollaborationCreation__OwningParty">
        <CollaborationCreationPartyForm party={Party.Owning} />
      </CollaborationCreationOwningParty__Container>
    </Column>
  );
};

const CollaborationCreationOwningParty__Container = styled(ColumnPaper)({
  borderTop: `5px solid ${TakeTurnsColors.darkBlue}`,
});
