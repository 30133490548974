/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql } from "@apollo/client";
import { useRefreshableSubscription } from "@taketurns-components/webapp/QueriesAndSubscriptionsRefreshTrigger";
import { COLLABORATION_CONTENT_METADATA_QUERY } from "@taketurns-repositories/collaboration/graphql/queries/content/useFetchCollaborationContentMetadata";
import { LAST_UPDATE_EVENT } from "@taketurns-repositories/collaboration/graphql/queries/content/useFetchCollaborationLastUpdateEvent";
import { GET_FOLDER } from "@taketurns-repositories/collaboration/graphql/queries/folder/useFetchFolder";
import { wait } from "@taketurns-rules/commons/util/wait";

const SUBSCRIBE_TO_SEND_UPDATES = gql`
  subscription onSendUpdates($collaborationId: ID!) {
    onSendUpdates(id: $collaborationId) {
      id
    }
  }
`;

export const useSubscribeToSendUpdates = (collaborationId: string, onUpdatesSent?: () => void) => {
  return useRefreshableSubscription(SUBSCRIBE_TO_SEND_UPDATES, {
    variables: { collaborationId },
    onData: ({ client }) => {
      wait(3000).then(() => {
        client.refetchQueries({ include: [GET_FOLDER, COLLABORATION_CONTENT_METADATA_QUERY, LAST_UPDATE_EVENT] });
        if (typeof onUpdatesSent === "function") {
          onUpdatesSent();
        }
      });
    },
  });
};
