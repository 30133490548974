/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { CircularProgress, Stack, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useUserPlanTranslation } from "@taketurns-i18n/user/UserPlans/useUserPlanTranslation";
import { getStripeRegionForCountryCode } from "@taketurns-repositories/user/local/countries";
import { useIsOnMobileRule } from "@taketurns-rules/commons/theme/useIsOnMobileRule";
import { useSelectSubscriptionPlanRegionRule } from "@taketurns-rules/user/commands/plan/selectSubscriptionPlanRegionRule";
import { useFetchCountryCodeFromGeolocationRule } from "@taketurns-rules/user/queries/useFetchCountryCodeFromGeolocationRule";
import { RegionSelector } from "../shared/RegionSelector";
import { PaymentCurrencySelector } from "./PaymentCurrencySelector";

const SELECTED_COUNTRY_CODE_INITIAL_VALUE = "";
export const PaymentRegionAndCurrencySelector = () => {
  const [selectedCountryCode, setSelectedCountryCode] = useState(SELECTED_COUNTRY_CODE_INITIAL_VALUE);

  const isOnMobile = useIsOnMobileRule();

  const { geolocatedCountryCode, loading } = useFetchCountryCodeFromGeolocationRule();

  const setRegion = useSelectSubscriptionPlanRegionRule();

  const { t: subscriptionPlanTranslations } = useUserPlanTranslation();

  const selectRegion = useCallback((selectedCountryCode: string) => {
    setSelectedCountryCode(selectedCountryCode);
    const stripeRegionForCountryCode = getStripeRegionForCountryCode(selectedCountryCode);
    if (!stripeRegionForCountryCode) {
      throw new Error(`Selected country is not available in stripe for country code : ${selectedCountryCode}`);
    }
    setRegion(stripeRegionForCountryCode);
  }, []);

  useEffect(() => {
    if (!loading && geolocatedCountryCode && geolocatedCountryCode !== selectedCountryCode) {
      selectRegion(geolocatedCountryCode);
    }
  }, [geolocatedCountryCode, loading, selectRegion]);

  if (loading) {
    return <CircularProgress />;
  }

  const mismatchBetweenUserRegionAndSelectedRegion =
    geolocatedCountryCode !== selectedCountryCode && selectedCountryCode !== SELECTED_COUNTRY_CODE_INITIAL_VALUE;

  const MismatchUserRegionAndSelectedRegionText = () => {
    return (
      <Typography fontSize={12} color="error" sx={{ textAlign: { xs: "center", md: "left" } }}>
        {subscriptionPlanTranslations("header.wrongIpAndCountry")}
      </Typography>
    );
  };

  if (isOnMobile) {
    return (
      <Stack
        className="MobilePaymentRegionCurrencySelector"
        spacing={1}
        direction="column"
        alignItems={"center"}
        sx={{ width: "100%" }}
      >
        <Stack
          className="MobileRegionCurrencySelectorsStack"
          spacing={2}
          direction="row"
          alignItems={"center"}
          sx={{ width: "100%" }}
        >
          <RegionSelector
            loading={loading}
            selectRegion={(selectRegionEvent) => selectRegion(selectRegionEvent.target.value)}
            selectedCountryCode={selectedCountryCode}
          />
          <PaymentCurrencySelector />
        </Stack>
        {mismatchBetweenUserRegionAndSelectedRegion && <MismatchUserRegionAndSelectedRegionText />}
      </Stack>
    );
  }

  return (
    <Stack spacing={2} direction="row" alignItems={"center"}>
      <RegionSelector
        loading={loading}
        selectRegion={(selectRegionEvent) => selectRegion(selectRegionEvent.target.value)}
        selectedCountryCode={selectedCountryCode}
      />
      <PaymentCurrencySelector />
      {mismatchBetweenUserRegionAndSelectedRegion && <MismatchUserRegionAndSelectedRegionText />}
    </Stack>
  );
};
