/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { ApolloError, gql, useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";
import { ConnectedUser, ModifyUserInput } from "@taketurns/taketurns-graphql-repository";

export const useModifyConnectedUser = () => {
  const mutation = gql`
    mutation modifyUser($input: ModifyUserInput) {
      modifyUser(input: $input) {
        id
        firstName
        lastName
        email
        avatar
        function
        jobTitle
      }
    }
  `;
  const [modifyUserMutation] = useMutation(mutation);
  const { enqueueSnackbar } = useSnackbar();

  const displaySnackbarErrorMessage = (errorOnModifyConnectedUser: ApolloError) => {
    console.error(errorOnModifyConnectedUser);
    enqueueSnackbar(errorOnModifyConnectedUser.message, { variant: "error" });
  };

  const modifyUser = (user: ConnectedUser, input: ModifyUserInput, onCompleted?: () => void) => {
    const optmisticResponse = {
      modifyUser: { __typename: "User", ...user, ...input },
    };
    modifyUserMutation({
      variables: { input: input },
      optimisticResponse: optmisticResponse,
      onCompleted: onCompleted,
      onError: displaySnackbarErrorMessage,
    });
  };

  return { modifyUser };
};
