/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useGetDisplayedCollaborationId } from "@taketurns-repositories/collaboration/context/useGetDisplayedCollaborationId";
import { useUnmarkAttachmentForReview } from "@taketurns-repositories/collaboration/graphql/mutations/content/attachments/useUnmarkAttachmentForReview";

export const useUnmarkAttachmentForReviewRule = (attachmentId: string) => {
  const collaborationId = useGetDisplayedCollaborationId();
  const {
    unmarkAttachmentForReviewMutation,
    unmarkAttachmentForReviewResponseData,
    unmarkAttachmentForReviewLoading,
    errorOnUnmarkingAttachmentForReview,
  } = useUnmarkAttachmentForReview({ collaborationId, attachmentId });
  return {
    unmarkAttachmentForReview: unmarkAttachmentForReviewMutation,
    unmarkAttachmentForReviewResponseData: unmarkAttachmentForReviewResponseData,
    unmarkAttachmentForReviewLoading: unmarkAttachmentForReviewLoading,
    errorOnUnmarkingAttachmentForReview: errorOnUnmarkingAttachmentForReview,
  };
};
