/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Skeleton, Tooltip, Typography } from "@mui/material";
import { ContentStatus } from "@taketurns/taketurns-graphql-repository";
import { FetchLoadingManager } from "@taketurns-app/utils/FetchLoadingManager";
import { useLastUpdateMessageRule } from "@taketurns-rules/collaboration/queries/attachment/useLastUpdateMessageRule";
import { useFetchLastUpdateColor } from "@taketurns-rules/collaboration/queries/content/UseFetchLastUpdateColor";

export const ContentElementLastUpdate = ({ status }: { status: ContentStatus }) => {
  const { otherPartyLastUpdateMessage, color, loading, error } = useFetchContentElementLastUpdateData(status);

  if (loading) {
    return <Skeleton variant={"text"} />;
  }

  if (error) {
    return null;
  }

  return (
    <Tooltip title={otherPartyLastUpdateMessage}>
      <Typography fontWeight="600" fontSize={{ xs: 10, md: 9 }} color={color} noWrap>
        {otherPartyLastUpdateMessage}
      </Typography>
    </Tooltip>
  );
};

const useFetchContentElementLastUpdateData = (status: ContentStatus) => {
  const {
    otherPartyLastUpdateMessage,
    loadingOtherPartyLastUpdateMessage,
    errorOnFetchingOtherPartyLastUpdateMessage,
  } = useLastUpdateMessageRule(status);

  const { color, loadingLastUpdatesColor, errorOnFetchingLastUpdatesColor } = useFetchLastUpdateColor();

  const fetchLoadingManager = new FetchLoadingManager(
    [loadingOtherPartyLastUpdateMessage, loadingLastUpdatesColor],
    [errorOnFetchingOtherPartyLastUpdateMessage, errorOnFetchingLastUpdatesColor],
  );

  return {
    otherPartyLastUpdateMessage,
    color,
    loading: fetchLoadingManager.hasFetchLoading(),
    error: fetchLoadingManager.hasFetchInError(),
  };
};
