/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useCallback } from "react";
import { useMoveRequestIntoFolderRule } from "@taketurns-rules/collaboration/commands/collaborationView/request/useMoveRequestIntoFolderRule";
import { MoveDestinationDialogInCollaborationView } from "./MoveDestinationDialogInCollaborationView";

interface RequestMoveDestinationDialogInCollaborationViewProps {
  requestId: string;
  open: boolean;
  onClose: () => void;
}

export const RequestMoveDestinationDialogInCollaborationView = ({
  requestId,
  open,
  onClose,
}: RequestMoveDestinationDialogInCollaborationViewProps) => {
  const moveRequestIntoFolder = useMoveRequestIntoFolderRule(requestId);
  const moveFolderIntoFolderAndClose = useCallback(
    async (destinationFolderPath: string) => {
      await moveRequestIntoFolder(destinationFolderPath);
      onClose();
    },
    [moveRequestIntoFolder, onClose],
  );
  return (
    <MoveDestinationDialogInCollaborationView
      onSubmitDestination={moveFolderIntoFolderAndClose}
      onClose={onClose}
      open={open}
    />
  );
};
