/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useSetSelectedSupervisorFilterItems } from "@taketurns-repositories/collaboration/state/write/useSetSelectedSupervisorFilterItems";

export const useApplySupervisorFilterRule = () => {
  const setSelectedSupervisorFilterItems = useSetSelectedSupervisorFilterItems();
  return (selectedUserIds: string[]) => {
    setSelectedSupervisorFilterItems(selectedUserIds);
  };
};
