/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useMutation } from "@apollo/client";
import { Collaboration, MutationReopenCollaborationArgs } from "@taketurns/taketurns-graphql-repository";

export const useReopenCollaboration = () => {
  const mutation = gql`
    mutation reopenCollaboration($input: ReopenCollaborationInput!) {
      reopenCollaboration(input: $input) {
        id
        name
        closingDate
        status
      }
    }
  `;
  return useMutation<{ reopenCollaboration: Collaboration }, MutationReopenCollaborationArgs>(mutation);
};
