/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { styled } from "@mui/material/styles";
import { DataGrid, GridColDef, GridRow, GridRowParams } from "@mui/x-data-grid";
import { Fragment, useEffect } from "react";
import {
  collaborationListFields,
  useCollaborationListColumns,
  usePendingInvitationListColumns,
} from "@taketurns-components/collaboration/CollaborationList/CollaborationListColumns/useCollaborationListColumns";
import { useMUIDataGridTranslations } from "@taketurns-i18n/MUIDataGrid/useMUIDataGridTranslations";
import { useFetchAllUnreadMessageCounts } from "@taketurns-repositories/chat/streamChat/useFetchAllUnreadMessageCounts";
import { useNavigateToCollaborationCreationRule } from "@taketurns-rules/collaboration/commands/navigation/useNavigateToCollaborationCreationRule";
import { useNavigateToCollaborationViewRule } from "@taketurns-rules/collaboration/commands/navigation/useNavigateToCollaborationViewRule";
import { useSetListAppliedFilterRule } from "@taketurns-rules/collaboration/commands/useSetListAppliedFilterRule";
import { useFilteredCollaborationListRule } from "@taketurns-rules/collaboration/queries/useFilteredCollaborationListRule";
import { useGetListAppliedFilterRule } from "@taketurns-rules/collaboration/queries/useGetListAppliedFilterRule";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { CollaborationFilterType } from "./collaborationFilterType";
import { CollaborationListHeader } from "./CollaborationListHeader/CollaborationListHeader";
import { NoCollaborationRowsOverlay } from "./NoCollaborationRowsOverlay";

export const CollaborationList = () => {
  const appliedFilter = useGetListAppliedFilterRule();

  useFetchAllUnreadMessageCounts();

  return (
    <Fragment>
      <CollaborationListHeader />
      {appliedFilter === CollaborationFilterType.pendingInvitations ? (
        <PendingInvitationsList />
      ) : (
        <ActualCollaborationList />
      )}
    </Fragment>
  );
};

const ActualCollaborationList = () => {
  const columnDefs = useCollaborationListColumns();

  return <CollaborationListDataGrid columnDefs={columnDefs} />;
};

const PendingInvitationsList = () => {
  const columnDefs = usePendingInvitationListColumns();

  return <CollaborationListDataGrid columnDefs={columnDefs} />;
};

interface CollaborationListDataGridProps {
  columnDefs: GridColDef[];
}

const CollaborationListDataGrid = ({ columnDefs }: CollaborationListDataGridProps) => {
  const { collaborations, loadingCollaborationList, errorOnCollaborationList } = useFilteredCollaborationListRule();
  const dataGridTranslation = useMUIDataGridTranslations();

  useEffect(() => {
    if (errorOnCollaborationList) {
      console.error(errorOnCollaborationList.message);
    }
  }, [errorOnCollaborationList]);
  const navigateToCollaborationView = useNavigateToCollaborationViewRule();
  const accessToSelectedCollaboration = (params: GridRowParams) => {
    navigateToCollaborationView(params.row.id);
  };
  const navigateToCollaborationCreation = useNavigateToCollaborationCreationRule();

  const setAppliedCollaborationListFilter = useSetListAppliedFilterRule();
  const redirectToActiveCollaborations = () => {
    setAppliedCollaborationListFilter(CollaborationFilterType.activeCollaborations);
  };
  const TestGridRow = (props) => {
    return <GridRow {...props} data-cy={`CollaborationRow.${props.rowId}`} />;
  };
  return (
    <CollaborationListStyledDataGrid
      hideFooter
      hideFooterPagination
      hideFooterSelectedRowCount
      disableRowSelectionOnClick
      disableColumnSelector
      initialState={{
        sorting: {
          sortModel: [{ field: collaborationListFields.lastUpdate, sort: "desc" }],
        },
      }}
      localeText={dataGridTranslation}
      onRowClick={accessToSelectedCollaboration}
      getRowHeight={() => "auto"}
      loading={loadingCollaborationList}
      isCellEditable={() => false}
      rows={collaborations}
      slots={{
        row: TestGridRow,
        noRowsOverlay: () => (
          <NoCollaborationRowsOverlay
            createNewCollaborationAction={navigateToCollaborationCreation}
            displayActiveCollaborationAction={redirectToActiveCollaborations}
          />
        ),
      }}
      columns={columnDefs}
      sx={{
        "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": { py: "8px" },
        "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": { py: "8px" },
        "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": { py: "8px" },
      }}
    />
  );
};

const CollaborationListStyledDataGrid = styled(DataGrid)(({ theme }) => ({
  border: 0,
  "& .MuiDataGrid-columnsContainer": {
    backgroundColor: theme.palette.mode === "light" ? "white" : "black",
    color: TakeTurnsColors.darkGray,
    fontSize: 12,
  },
  "& .MuiCheckbox-root svg": {
    height: "16px",
    width: "16px",
  },
  "& .MuiDataGrid-iconSeparator": {
    display: "none",
  },
  "& .MuiDataGrid-columnHeader .MuiDataGrid-columnHeaderTitleContainer": {
    padding: "0 !important",
    fontSize: "12px",
  },
  "& .MuiDataGrid-columnHeader:first-of-type": {
    paddingLeft: "10px !important",
  },
  "& .MuiDataGrid-menuIcon": {
    marginRight: "0",
  },
  "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
    borderRight: `0 solid ${theme.palette.mode === "light" ? "#f0f0f0" : "#303030"}`,
    color: TakeTurnsColors.darkGray,
    outline: "none !important",
    paddingLeft: "0 !important",
  },

  "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
    borderBottom: `1px solid ${TakeTurnsColors.lightGray}`,
  },
  "& .MuiDataGrid-cell": {
    color: theme.palette.mode === "light" ? "rgba(0,0,0,.85)" : "rgba(255,255,255,0.65)",
  },
  "& .MuiDataGrid-cell:focus": {
    outline: "none !important",
  },
  "& .MuiPaginationItem-root": {
    borderRadius: 0,
  },
  "& .MuiDataGrid-cell:first-of-type": {
    paddingLeft: "10px !important",
  },
  "& .MuiDataGrid-row:hover": {
    cursor: "pointer",
  },
  "& .DarkGrayFontColor": {
    color: TakeTurnsColors.darkGray,
  },
  "& .paddingLeft": {
    paddingLeft: "5px",
  },
  "& .CollaborationName": {
    maxWidth: "320px !important",
    width: "auto !important",
    minWidth: "0 !important",
  },
}));
