/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { selector } from "recoil";
import { collaborationFormErrorsState } from "@taketurns-repositories/collaboration/state/recoil/collaborationCreation/form/errors/collaborationFormErrorsState";

export const selectCollaborationFormOwningPartyNameFieldError = selector<boolean>({
  key: "collaborationFormOwningPartyNameFieldError",
  get: ({ get }) => {
    return get(collaborationFormErrorsState).owningPartyName;
  },
  set: ({ set }, isOwningPartyNameInError) => {
    set(collaborationFormErrorsState, (previousState) => ({
      ...previousState,
      owningPartyName: isOwningPartyNameInError as boolean,
    }));
  },
});
