/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Box } from "@mui/material";
import { Fragment } from "react";
import { Attachment, ContentStatus, ContentStatus as ContentStatusEnum } from "@taketurns/taketurns-graphql-repository";
import { SelectionWrapperForListItem } from "@taketurns-components/collaboration/CollaborationView/CollaborationContent/shared/components/SelectionComponentWrapper";
import { AttachmentMoveDestinationDialogInCollaborationView } from "@taketurns-components/collaboration/CollaborationView/MoveDestinationDialog/AttachmentMoveDestinationDialogInCollaborationView";
import { DropZoneConfig } from "@taketurns-components/collaboration/Shared/FilesDragAndDrop";
import { FilesDragAndDropWithOverlay } from "@taketurns-components/collaboration/Shared/FilesDragAndDropWithOverlay";
import { useOpenAttachmentDetailsRule } from "@taketurns-rules/collaboration/commands/collaborationView/attachment/useOpenAttachmentDetailsRule";
import { useUploadRevisionBeforeOpeningAttachmentDetailsRule } from "@taketurns-rules/collaboration/commands/collaborationView/attachment/useUploadRevisionBeforeOpeningAttachmentDetailsRule";
import { useMoveDestinationDialogRule } from "@taketurns-rules/collaboration/commands/useMoveDestinationDialogRule";
import { useGetAttachmentContextMenuConfigurationRule } from "@taketurns-rules/collaboration/queries/attachment/useGetAttachmentContextMenuConfigurationRule";
import { useGetAttachmentSelectionStateInCurrentFolderByIdRule } from "@taketurns-rules/collaboration/queries/collaborationView/contentSelection/useGetAttachmentSelectionStateInCurrentFolderByIdRule";
import { useIsContentSelectionStateActiveRule } from "@taketurns-rules/collaboration/queries/collaborationView/contentSelection/useIsContentSelectionStateActiveRule";
import { useGetAttachmentRule } from "@taketurns-rules/collaboration/queries/collaborationView/useGetAttachmentRule";
import { useIsCollaborationClosedRule } from "@taketurns-rules/collaboration/queries/collaborationView/useIsCollaborationClosedRule";
import { useSubscribeToMarkUnmarkAsReviewedAttachmentRule } from "@taketurns-rules/collaboration/subscriptions/useSubscribeToMarkUnmarkAsReviewedAttachmentRule";
import { useSubscribeToMarkUnmarkForReviewAttachmentRule } from "@taketurns-rules/collaboration/subscriptions/useSubscribeToMarkUnmarkForReviewAttachmentRule";
import { useSubscribeToSetAttachmentLockRule } from "@taketurns-rules/collaboration/subscriptions/useSubscribeToSetAttachmentLockRule";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { useIsOnMobileRule } from "@taketurns-rules/commons/theme/useIsOnMobileRule";
import { useSubscribeOnDocumentChangedRule } from "@taketurns-rules/document/subscriptions/useSubscribeOnDocumentChangedRule";
import { DesktopAttachmentListItem } from "./DesktopAttachmentListItem";
import { MobileAttachmentListItem } from "./MobileAttachmentListItem";

interface AttachmentListItemProps {
  attachmentId: string;
  editable: boolean;
}

export const AttachmentListItem = (props: AttachmentListItemProps) => {
  const attachment = useGetAttachmentRule(props.attachmentId);

  const { isContentSelectionActive, isAttachmentSelected, toggleSelection } =
    useGetAttachmentSelectionStateInCurrentFolderByIdRule(props.attachmentId);

  useSubscribeToMarkUnmarkForReviewAttachmentRule(attachment.id);
  useSubscribeToMarkUnmarkAsReviewedAttachmentRule(attachment.id);
  useSubscribeToSetAttachmentLockRule(attachment.id);
  useSubscribeOnDocumentChangedRule(attachment.id);

  const openAttachmentDetailsAndUploadRevision = useUploadRevisionBeforeOpeningAttachmentDetailsRule(attachment);
  const dropZoneConfig: DropZoneConfig = {
    handleFilesDropping: (files) => openAttachmentDetailsAndUploadRevision(files[0]),
    className: "AttachmentListItem__DropZone",
    sx: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      overflow: "hidden",
    },
  };

  const isAttachmentRemoved = hasAttachmentBeenRemoved(attachment);

  if (isContentSelectionActive) {
    return (
      <SelectionWrapperForListItem checked={isAttachmentSelected} onClick={toggleSelection}>
        <InnerComponent attachment={attachment} editable={props.editable} />
      </SelectionWrapperForListItem>
    );
  }

  if (props.editable && !isAttachmentRemoved) {
    return (
      <FilesDragAndDropWithOverlay ContainerComponent={Box} dropZoneConfig={dropZoneConfig}>
        <InnerComponent attachment={attachment} editable={props.editable} />
      </FilesDragAndDropWithOverlay>
    );
  }
  return <InnerComponent attachment={attachment} editable={props.editable} />;
};

const hasAttachmentBeenRemoved = (attachment: Attachment) =>
  attachment.status === ContentStatusEnum.Removed || attachment.prevStatus === ContentStatusEnum.Removed;

interface InnerComponentProps {
  attachment: Attachment;
  editable: boolean;
}

const InnerComponent = (props: InnerComponentProps) => {
  const isOnMobile = useIsOnMobileRule();
  const ItemComponent = isOnMobile ? MobileAttachmentListItem : DesktopAttachmentListItem;
  const isContentSelectionActive = useIsContentSelectionStateActiveRule();

  const { isCollaborationClosed } = useIsCollaborationClosedRule();
  const isAttachmentRemoved = hasAttachmentBeenRemoved(props.attachment);
  const shouldDisplayPendingUpdatesMarker =
    !isCollaborationClosed && props.attachment.status !== ContentStatusEnum.Unchanged;
  const shouldDisplayLastUpdatesMarker =
    !isCollaborationClosed && props.attachment.prevStatus && props.attachment.prevStatus !== ContentStatus.Unchanged;
  const openAttachmentDetails = useOpenAttachmentDetailsRule(props.attachment.id);

  const itemBackgroundColor = shouldDisplayPendingUpdatesMarker
    ? TakeTurnsColors.updatedElementBackground
    : props.attachment.locked
      ? TakeTurnsColors.background
      : undefined;

  const { closeMoveDestinationDialog, isMoveDestinationDialogOpen, openMoveDestinationDialog } =
    useMoveDestinationDialogRule();
  const attachmentContextMenuConfiguration = useGetAttachmentContextMenuConfigurationRule(
    props.attachment,
    openMoveDestinationDialog,
  );

  return (
    <Fragment>
      <ItemComponent
        backgroundColor={itemBackgroundColor}
        attachment={props.attachment}
        isAttachmentRemoved={isAttachmentRemoved}
        shouldDisplayLastUpdate={shouldDisplayLastUpdatesMarker}
        openAttachmentDetails={isContentSelectionActive ? undefined : openAttachmentDetails}
        contextMenuConfiguration={
          props.editable && !isContentSelectionActive ? attachmentContextMenuConfiguration : null
        }
        color={TakeTurnsColors.otherParty}
      />
      {!isContentSelectionActive && props.editable && (
        <AttachmentMoveDestinationDialogInCollaborationView
          attachmentId={props.attachment.id}
          open={isMoveDestinationDialogOpen}
          onClose={closeMoveDestinationDialog}
        />
      )}
    </Fragment>
  );
};
