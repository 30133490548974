/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { IconArrowRightCircle, IconRename, IconTrash } from "../../../components/commons/icons";
import { useRemoveFolderInCreationRule } from "../commands/collaborationCreation/folder/useRemoveFolderInCreationRule";

export const useGetFolderContextMenuConfigurationInCreationRule = (
  folderId: string,
  renameFolder: () => void,
  openMoveDestinationDialog: () => void,
) => {
  const removeFolder = useRemoveFolderInCreationRule();
  const removeCurrentFolder = () => {
    removeFolder(folderId);
  };

  const ICON_SIZE = 14;

  return {
    contextMenuItems: [
      {
        labelKey: "contextMenu.rename",
        icon: <IconRename size={ICON_SIZE} />,
        action: renameFolder,
      },
      {
        labelKey: "contextMenu.move",
        icon: <IconArrowRightCircle size={ICON_SIZE} />,
        action: openMoveDestinationDialog,
      },
      {
        labelKey: "contextMenu.remove",
        icon: <IconTrash size={ICON_SIZE} />,
        action: removeCurrentFolder,
      },
    ],
  };
};
