/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Box, styled } from "@mui/material";
import { PropsWithChildren } from "react";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";

export const SidebarHeader = (props: PropsWithChildren) => {
  return <SidebarHeaderStyle>{props.children}</SidebarHeaderStyle>;
};

const SidebarHeaderStyle = styled(Box)({
  minHeight: "70px",
  maxHeight: "70px",
  display: "flex",
  alignItems: "center",
  flexDirection: "row",
  padding: "0px 24px",
  borderBottom: `1px solid ${TakeTurnsColors.borderColor}`,
});
