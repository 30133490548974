/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { styled, Typography } from "@mui/material";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";

interface TakeTurnsFormLabelProps {
  label: string;
  required?: string;
  error?: boolean;
}

export const TakeTurnsFormLabel = (props: TakeTurnsFormLabelProps) => {
  return (
    <FormLabel__Container>
      <Typography fontSize={14}>{props.label}</Typography>
      {props.required && (
        <Typography color={props.error ? TakeTurnsColors.error : TakeTurnsColors.grayButton} fontSize={12}>
          | {props.required}
        </Typography>
      )}
    </FormLabel__Container>
  );
};

const FormLabel__Container = styled("div")({
  display: "inline-flex",
  gap: "5px",
  alignItems: "center",
});
