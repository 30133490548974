/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useSearchSubordinates } from "@taketurns-repositories/user/graphql/queries/useSearchSubordinates";

export const useSearchSubordinatesRule = () => {
  const { errorOnSearchingSubordinates, searchSubordinates, subordinates } = useSearchSubordinates();

  return { errorOnSearchingSubordinates, searchSubordinates, subordinates };
};
