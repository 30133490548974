/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useEffect, useState } from "react";
import { OwnUserResponse, StreamChat, TranslationLanguages, UserResponse } from "stream-chat";
import { ConnectedUser } from "@taketurns/taketurns-graphql-repository";
import { useFetchStreamChatConnectionParameters } from "@taketurns-repositories/chat/graphql/queries/useFetchStreamChatConnectionParameters";
import { useFetchConnectedUserRule } from "@taketurns-rules/user/queries/useFetchConnectedUserRule";

export const useConnectToStreamChat: (userId?: string) => {
  client: StreamChat | null;
  error: boolean;
} = (userId?: string) => {
  const [streamChatClient, setStreamChatClient] = useState<StreamChat | null>(null);
  const { streamChatConnectionParameters, errorOnStreamChatToken, loadingStreamChatToken } =
    useFetchStreamChatConnectionParameters(userId);
  const [error, setError] = useState(null);
  const { connectedUser, errorOnFetchingConnectedUser } = useFetchConnectedUserRule();

  useEffect(() => {
    let didUserConnectInterrupt = false;

    async function initStreamChat(user: ConnectedUser) {
      try {
        const { token, apiKey } = streamChatConnectionParameters;
        const client = new StreamChat(apiKey);
        const chatUser: OwnUserResponse | UserResponse = {
          id: userId ?? user.id,
          name: user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : `${user.email}`,
          image: user.avatar,
          language: user.preferences?.language as TranslationLanguages,
        };
        await client.connectUser(chatUser, token);
        if (!didUserConnectInterrupt) {
          setStreamChatClient(client);
        }
      } catch (error) {
        console.error(error);
        setError(error);
      }
    }

    if (connectedUser && streamChatClient === null && !loadingStreamChatToken) {
      initStreamChat(connectedUser);
    }
    return () => {
      didUserConnectInterrupt = true;
      if (streamChatClient !== null) {
        initStreamChat(connectedUser!).then(() => {
          streamChatClient.disconnectUser().then(() => {
            setStreamChatClient(null);
          });
        });
      }
    };
  }, [streamChatConnectionParameters, connectedUser, streamChatClient, loadingStreamChatToken]);

  useEffect(() => {
    setStreamChatClient(null);
  }, [connectedUser?.preferences?.language]);

  if (errorOnFetchingConnectedUser || errorOnStreamChatToken) {
    return { client: null, error: true };
  }

  return { client: streamChatClient, error: error };
};
