/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { ApolloError, gql, useQuery } from "@apollo/client";
import { Collaboration } from "@taketurns/taketurns-graphql-repository";

/**
 * Get collaboration list from applied filter
 * @returns {data:Collaboration[],errorOnCollaborationList:ApolloError,loadingCollaborationList:boolean} request result
 */
export function useFetchPendingInvitationsCount(): {
  pendingInvitationsCount: number;
  errorOnFetchingPendingInvitationsCount: ApolloError;
  loadingPendingInvitationsCount: boolean;
} {
  const {
    data,
    loading: loadingPendingInvitations,
    error: errorOnPendingInvitations,
  } = useQuery<{ pendingInvitations: { items: Collaboration[] } }>(GET_PENDING_INVITATIONS);

  return {
    pendingInvitationsCount: (data?.pendingInvitations.items ?? []).length,
    errorOnFetchingPendingInvitationsCount: errorOnPendingInvitations,
    loadingPendingInvitationsCount: loadingPendingInvitations,
  };
}

export const PENDING_INVITATIONS_OBSERVABLE_QUERY = "pendingInvitations";

const GET_PENDING_INVITATIONS = gql`
  query pendingInvitations($limit: Int, $from: Int) {
    pendingInvitations(limit: $limit, from: $from) {
      items {
        id
      }
    }
  }
`;
