/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { atom } from "recoil";

export const retriedUploadIdsState = atom<Set<number>>({
  key: "retriedUploadIds",
  default: new Set<number>(),
});
