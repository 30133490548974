/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Box, DialogTitle, Stack, styled, Typography } from "@mui/material";
import { useCallback, useRef } from "react";
import { TakeTurnsDialogFooter } from "@taketurns-components/collaboration/Shared/content/TakeTurnsDialogFooter";
import { CollaborationDialog } from "@taketurns-components/collaboration/Shared/dialog/CollaborationDialog";
import { DialogContentWithRichTextField } from "@taketurns-components/collaboration/Shared/dialog/DialogContentWithRichTextField";
import { useCollaborationTranslation } from "@taketurns-i18n/collaboration/useCollaborationTranslation";
import { useReopenCollaborationByIdRule } from "@taketurns-rules/collaboration/commands/useReopenCollaborationByIdRule";
import { HelpCenterSections } from "@taketurns-rules/commons/enum/helpCenterSectionsEnum";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { TakeTurnsDefaultButton } from "../../../../commons/button";
import { CollaborationParties } from "../../../CollaborationView/CollaborationParties";

interface ReopenCollaborationDialogProps {
  open: boolean;
  onClose: () => void;
  collaborationId: string;
}

export const ReopenCollaborationDialog = ({ collaborationId, onClose, open }: ReopenCollaborationDialogProps) => {
  const noteRef = useRef<string>("");

  const { reopenCollaborationCommand, result } = useReopenCollaborationByIdRule();
  const { t } = useCollaborationTranslation("collaborationEdit");

  const handleReopenCollaboration = useCallback(() => {
    reopenCollaborationCommand({ collaborationId, note: noteRef.current }, onClose);
  }, [collaborationId, onClose, reopenCollaborationCommand]);

  return (
    <CollaborationDialog open={open} onClose={onClose}>
      <DialogTitle>
        <Stack direction="row">
          <Typography variant="h6" flex={1} textAlign={{ xs: "center", md: "start" }}>
            {t("reopen.title")}
          </Typography>
        </Stack>
      </DialogTitle>
      <DialogContentWithRichTextField
        onChange={(note: string) => (noteRef.current = note)}
        value={noteRef.current}
        placeholder={t("reopen.note")}
      >
        <ReopenDescription>
          <Typography>{t("reopen.description")}</Typography>
        </ReopenDescription>
        <CollaborationParties prefixText={t("reopen.addNoteTo")} collaborationId={collaborationId} />
      </DialogContentWithRichTextField>
      <TakeTurnsDialogFooter
        closeDialog={onClose}
        helpCenterSection={HelpCenterSections.CLOSE_REOPEN_COLLABORATION}
        hasCancelAction
      >
        <TakeTurnsDefaultButton disabled={result.loading} onClick={handleReopenCollaboration}>
          {t("button.confirm")}
        </TakeTurnsDefaultButton>
      </TakeTurnsDialogFooter>
    </CollaborationDialog>
  );
};

const ReopenDescription = styled(Box)({
  backgroundColor: TakeTurnsColors.background,
  padding: "24px",
});
