/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useMutation } from "@apollo/client";
import { Folder, MutationMoveAttachmentIntoFolderArgs } from "@taketurns/taketurns-graphql-repository";

export const moveAttachmentMutationFragment = gql`
  fragment MoveAttachmentMutationFragment on MoveOutput {
    collaborationId
    partyId
    sourceFolderId
    destinationFolderId
    sourceFolder {
      id
      attachmentCount
      updateCount
      prevUpdateCount
      attachments {
        id
      }
    }
    destinationFolder {
      id
      attachmentCount
      updateCount
      prevUpdateCount
      attachments {
        id
      }
    }
    contentMetadata {
      id
      hasPendingUpdates
    }
  }
`;

export function useMoveAttachmentIntoFolderMutation({
  collaborationId,
  attachmentId,
}: {
  collaborationId: string;
  attachmentId: string;
}) {
  const MOVE_ATTACHMENT_INTO_FOLDER_MUTATION = gql`
    mutation moveAttachmentIntoFolder($input: MoveAttachmentIntoFolderInput!) {
      moveAttachmentIntoFolder(input: $input) {
        ...MoveAttachmentMutationFragment
      }
    }
    ${moveAttachmentMutationFragment}
  `;
  const [moveAttachmentMutation] = useMutation<
    { destinationFolder: Folder; sourceFolder: Folder },
    MutationMoveAttachmentIntoFolderArgs
  >(MOVE_ATTACHMENT_INTO_FOLDER_MUTATION);
  return async (destinationFolderId: string) => {
    await moveAttachmentMutation({
      variables: {
        input: {
          collaborationId,
          attachmentId,
          destinationFolderId,
        },
      },
    });
  };
}
