/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useFetchClosedCollaboration } from "@taketurns-repositories/collaboration/graphql/queries/closing/useFetchClosedCollaboration";
import { useGetDisplayedCollaborationIdRule } from "@taketurns-rules/collaboration/queries/collaboration/useGetDisplayedCollaborationIdRule";

export const useFetchClosedCollaborationRule = () => {
  const collaborationId = useGetDisplayedCollaborationIdRule();
  return useFetchClosedCollaboration(collaborationId);
};
