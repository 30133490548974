/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useResetRequestFilesToAddOnRequestFulfillmentOpening } from "@taketurns-repositories/collaboration/state/write/requestFulfillment/useResetRequestFilesToAddOnRequestFulfillmentOpening";
import { useSetOpenedRequestFulfillmentRequest } from "@taketurns-repositories/collaboration/state/write/useSetOpenedRequestFulfillmentRequest";

export const useCloseRequestFulfillmentDialogRule = () => {
  const setOpenedRequestFulfillmentRequest = useSetOpenedRequestFulfillmentRequest();
  const resetFilesToAddOnRequestFulfillmentOpening = useResetRequestFilesToAddOnRequestFulfillmentOpening();

  return () => {
    resetFilesToAddOnRequestFulfillmentOpening();
    setOpenedRequestFulfillmentRequest(null);
  };
};
