/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useQuery } from "@apollo/client";
import { ConnectedUser } from "@taketurns/taketurns-graphql-repository";

export const useFetchConnectedUserIdAndEmail = () => {
  const CONNECTED_USER_ID_AND_EMAIL = gql`
    query connectedUserIdAndEmail {
      connectedUser {
        id
        email
      }
    }
  `;
  const {
    data,
    error: errorOnLoadingConnectedUserId,
    loading: loadingConnectedUserId,
  } = useQuery<{
    connectedUser: ConnectedUser;
  }>(CONNECTED_USER_ID_AND_EMAIL);
  return {
    connectedUserId: data?.connectedUser?.id,
    connectedUserEmail: data?.connectedUser?.email,
    errorOnLoadingConnectedUserId,
    loadingConnectedUserId,
  };
};
