/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Navigate, Outlet } from "react-router-dom";
import { useShouldDisplayPlansRule } from "@taketurns-rules/webapp/queries/useShouldDisplayPlansRule";
import { AUTHENTICATED_ROUTES } from "../routes/authenticatedRoutes.constants";

export function RedirectToPlansIfFirstAuthGuard() {
  const shouldDisplayPlan = useShouldDisplayPlansRule();
  if (shouldDisplayPlan) {
    return <Navigate to={AUTHENTICATED_ROUTES.PLANS} />;
  }
  return <Outlet />;
}
