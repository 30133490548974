/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { gql, useMutation } from "@apollo/client";

export const useChangeMemberSupervisor = () => {
  const CHANGE_MEMBER_SUPERVISOR_MUTATION = gql`
    mutation changeMemberSupervisor($changeMemberSupervisorInput: ChangeMemberSupervisorInput!) {
      changeMemberSupervisor(changeMemberSupervisorInput: $changeMemberSupervisorInput) {
        userId
        supervisorUserId
        supervisor {
          id
          email
          firstName
          lastName
          avatar
        }
      }
    }
  `;

  const [
    changeMemberSupervisorMutation,
    { error: errorWhenChangingMemberSupervisor, loading: loadingWhenChangingMemberSupervisor },
  ] = useMutation(CHANGE_MEMBER_SUPERVISOR_MUTATION);

  const changeMemberSupervisor = async ({
    subordinateUserId,
    newSupervisorUserId,
  }: {
    subordinateUserId: string;
    newSupervisorUserId: string | null;
  }) => {
    changeMemberSupervisorMutation({
      variables: {
        changeMemberSupervisorInput: {
          subordinateUserId,
          newSupervisorUserId,
        },
      },
    });
  };
  return { errorWhenChangingMemberSupervisor, loadingWhenChangingMemberSupervisor, changeMemberSupervisor };
};
