/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Stack, styled, TextField, TextFieldProps } from "@mui/material";
import { useEffect, useState } from "react";
import { CreateInviteeInput, Party, Role } from "@taketurns/taketurns-graphql-repository";
import { useCollaborationTranslation } from "@taketurns-i18n/collaboration/useCollaborationTranslation";
import { useFetchConnectedUserPartyRule } from "@taketurns-rules/collaboration/queries/context/useFetchConnectedUserPartyRule";
import { useNewEmailAlreadyUsedRule } from "@taketurns-rules/collaboration/queries/useNewEmailAlreadyUsedRule";
import { useIsOnMobileRule } from "@taketurns-rules/commons/theme/useIsOnMobileRule";
import { validateEmail } from "@taketurns-rules/commons/util/form/formUtils";
import { TakeTurnsDefaultButton } from "../../../commons/button";
import { UserContactsAutoComplete } from "../../../user/UserContact/UserContactsAutoComplete";
import { SelectRoleButton } from "./SelectRoleButton";

interface InvitationFormProps {
  partyToInviteTo: Party;
  connectedUserParty: Party;
  buttonLabel: string;
  onButtonClick: (invitee: CreateInviteeInput) => void;
}

export const InvitationForm = (props: InvitationFormProps) => {
  const { t } = useCollaborationTranslation("participantCollaboration");
  const { connectedUserParty: userParty } = useFetchConnectedUserPartyRule();
  const [newInvitation, setNewInvitation] = useState<CreateInviteeInput>({
    email: "",
    party: props.partyToInviteTo,
    role: Role.Owner,
    userId: undefined,
  });

  const handleClickOnButton = (invitee: CreateInviteeInput) => {
    props.onButtonClick(invitee);
    setNewInvitation({
      email: "",
      party: props.partyToInviteTo,
      role: Role.Owner,
      userId: undefined,
    });
  };

  const { emailAlreadyUsed, errorMessage } = useNewEmailAlreadyUsedRule(newInvitation.email);

  const isOnMobile = useIsOnMobileRule();

  useEffect(() => {
    if (props.partyToInviteTo !== newInvitation.party) {
      setNewInvitation((previousInvitation) => ({
        ...previousInvitation,
        party: props.partyToInviteTo,
      }));
    }
  }, [newInvitation.party, props.partyToInviteTo]);

  const addButtonIsDisabled = () => {
    return !validateEmail(newInvitation.email) || newInvitation.role === ("unset" as Role) || emailAlreadyUsed;
  };

  const isFromUserParty = props.partyToInviteTo === (userParty || Party.Owning);

  const ContactForm = (
    <UserContactsAutoComplete
      sx={{
        flex: "1 0 200px",
      }}
      renderInput={(params) => (
        <TextField
          {...(params as TextFieldProps)}
          variant="outlined"
          error={!!errorMessage}
          helperText={errorMessage}
          placeholder={isFromUserParty ? t("owningPartyEmailPlaceholder") : t("invitedPartyEmailPlaceholder")}
          InputProps={{
            ...params.InputProps,
            sx: {
              fontSize: 12,
            },
          }}
        />
      )}
      onContactChange={(contact) => {
        setNewInvitation((oldValue) => ({
          ...oldValue,
          email: contact?.email ?? "",
          userId: contact?.user?.id ?? oldValue.userId,
        }));
      }}
    />
  );

  const RoleSelector = (
    <SelectRoleButton
      role={newInvitation.role}
      onChange={(role) => setNewInvitation({ ...newInvitation, role: role })}
      selectingRoleUserParty={props.partyToInviteTo}
      connectedUserParty={props.connectedUserParty}
      editable={true}
      customInputSx={{ fontSize: "12px" }}
      defaultInput
      fullWidth={isOnMobile}
    />
  );

  const ValidationButton = (
    <AddParticipantButton
      buttonLabel={props.buttonLabel}
      invitee={newInvitation}
      onButtonClick={handleClickOnButton}
      disabled={addButtonIsDisabled()}
    />
  );

  if (isOnMobile) {
    return (
      <InvitationForm__Container>
        {ContactForm}
        <Stack direction="row" spacing={2} width="100%">
          {RoleSelector}
          {ValidationButton}
        </Stack>
      </InvitationForm__Container>
    );
  }

  return (
    <InvitationForm__Container>
      {ContactForm}
      {RoleSelector}
      {ValidationButton}
    </InvitationForm__Container>
  );
};

const AddParticipantButton = (props: {
  buttonLabel: string;
  invitee: CreateInviteeInput;
  onButtonClick: (invitee: CreateInviteeInput) => void;
  disabled?: boolean;
}) => {
  return (
    <TakeTurnsDefaultButton
      disabled={props.disabled}
      onClick={() => props.onButtonClick(props.invitee)}
      sx={{ fontSize: 12 }}
    >
      {props.buttonLabel}
    </TakeTurnsDefaultButton>
  );
};

const InvitationForm__Container = styled("form")({
  display: "flex",
  flexDirection: "row",
  alignItems: "start",
  flexFlow: "row wrap",
  rowGap: "16px",
  columnGap: "12px",
});
