/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Box, List, Stack, styled, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useShouldDisplayOrganizationAccessPoliciesRule } from "@taketurns-app/layouts/authenticated/AuthenticatedLayout/rules/useShouldDisplayOrganizationAccessPoliciesRule";
import {
  IconArrowRightCircle,
  IconClipboard,
  IconCog,
  IconIntegrations,
  IconLogOut,
  IconUser,
  IconUsers,
} from "@taketurns-components/commons/icons";
import { UserAvatar } from "@taketurns-components/user/UserProfile/UserAvatar/UserAvatar";
import { useWebAppTranslations } from "@taketurns-i18n/webapp/useWebAppTranslations";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { useFetchUserProfileIsIncompleteRule } from "@taketurns-rules/user/queries/useFetchUserProfileIsIncompleteRule";
import { useSetPreviousPathRule } from "@taketurns-rules/webapp/commands/useNavigateToUserSettingsPreviousPathRule";
import { useAuthContext } from "../../../../routing/AuthProvider";
import {
  ACCESS_POLICIES_SECTION,
  getUserSettingsSectionUrl,
  INTEGRATIONS_SECTION,
  MANAGE_PLAN_SECTION,
  ORGANIZATION_SECTION,
  PREFERENCES_SECTION,
  PROFILE_SECTION,
  UserSettingsSection,
} from "../../../../routing/routes/userSettingsSections";
import { DrawerItem } from "./DrawerItem";

interface UserDrawerProps {
  closeDrawer: () => void;
}

export const UserDrawer = (props: UserDrawerProps) => {
  const { userProfileIsIncomplete } = useFetchUserProfileIsIncompleteRule();
  return (
    <Box width={{ xs: 280, md: 380 }} maxWidth="100vw">
      <Stack direction="column" spacing={{ xs: 1, md: 2 }}>
        <Box padding={"30px 30px 0px"}>
          <UserAvatar displayInfo />
        </Box>
        {userProfileIsIncomplete && <CompleteYourProfile closeDrawer={props.closeDrawer} />}
        <Box padding={"0 30px"}>
          <SettingsSection {...props} />
          <ConnectionSection />
        </Box>
      </Stack>
    </Box>
  );
};

const CompleteYourProfile = ({ closeDrawer }: { closeDrawer: () => void }) => {
  const { t } = useWebAppTranslations("settings");
  const navigate = useNavigate();

  const redirectToUserProfilePage = () => {
    navigate(getUserSettingsSectionUrl(PROFILE_SECTION));
    closeDrawer();
  };

  return (
    <CompleteProfile__Container onClick={redirectToUserProfilePage}>
      <Typography>{t("completeYourProfile")}</Typography>
      <IconArrowRightCircle />
    </CompleteProfile__Container>
  );
};

const SettingsSection = (props: UserDrawerProps) => {
  const navigate = useNavigate();

  const { t } = useWebAppTranslations("settings");

  const shouldDisplayOrganizationAccessPolicies = useShouldDisplayOrganizationAccessPoliciesRule();

  const setPreviousPath = useSetPreviousPathRule();
  const savePreviousLocationNavigateToUserSettingsAndCloseDrawer = (section: UserSettingsSection) => {
    setPreviousPath();
    navigate(getUserSettingsSectionUrl(section));
    props.closeDrawer();
  };

  return (
    <Box>
      <Typography color={TakeTurnsColors.darkGray}>{t("settings")}</Typography>
      <List>
        <DrawerItem
          label={t("profile")}
          onClick={() => {
            savePreviousLocationNavigateToUserSettingsAndCloseDrawer(PROFILE_SECTION);
          }}
          icon={<IconUser size={24} color={TakeTurnsColors.grayButton} />}
        />
        <DrawerItem
          label={t("plan")}
          onClick={() => {
            savePreviousLocationNavigateToUserSettingsAndCloseDrawer(MANAGE_PLAN_SECTION);
          }}
          icon={<IconClipboard size={24} color={TakeTurnsColors.grayButton} />}
        />
        <DrawerItem
          label={t("organization")}
          onClick={() => {
            savePreviousLocationNavigateToUserSettingsAndCloseDrawer(ORGANIZATION_SECTION);
          }}
          icon={<IconUsers size={24} color={TakeTurnsColors.grayButton} />}
        />
        {shouldDisplayOrganizationAccessPolicies && (
          <DrawerItem
            label={t("accessPolicies")}
            onClick={() => {
              savePreviousLocationNavigateToUserSettingsAndCloseDrawer(ACCESS_POLICIES_SECTION);
            }}
            icon={<IconUsers size={24} color={TakeTurnsColors.grayButton} />}
          />
        )}
        <DrawerItem
          label={t("preferences")}
          onClick={() => {
            savePreviousLocationNavigateToUserSettingsAndCloseDrawer(PREFERENCES_SECTION);
          }}
          icon={<IconCog size={24} color={TakeTurnsColors.grayButton} />}
        />
        <DrawerItem
          label={t("integrations")}
          onClick={() => {
            savePreviousLocationNavigateToUserSettingsAndCloseDrawer(INTEGRATIONS_SECTION);
          }}
          icon={<IconIntegrations size={24} color={TakeTurnsColors.grayButton} />}
        />
      </List>
    </Box>
  );
};

const ConnectionSection = () => {
  const { t } = useWebAppTranslations("settings");

  const { logout } = useAuthContext();

  return (
    <Box>
      <Typography color={TakeTurnsColors.darkGray}>{t("connection")}</Typography>
      <List>
        <DrawerItem
          data-cy="userDrawer.signOutButton"
          label={t("signout")}
          onClick={logout}
          icon={<IconLogOut size={24} color={TakeTurnsColors.grayButton} />}
        />
      </List>
    </Box>
  );
};

const CompleteProfile__Container = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: TakeTurnsColors.yellow,
  padding: "12px 30px",
  color: "white",
  "&:hover": {
    cursor: "pointer",
  },
});
