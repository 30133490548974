/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { tips } from "../../commons/en/tips";
import document from "./document.json";
import notifications from "./notification.json";

export const TranslationsEN = {
  document,
  notifications,
  tips,
};
