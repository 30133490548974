/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Stack, Typography } from "@mui/material";
import { Fragment } from "react";
import { SingleColumnLayout } from "@taketurns-app/layouts/body/content/SingleColumnLayout";
import { MobileBodyLayout } from "@taketurns-app/layouts/body/MobileBodyLayout";
import { ExcludedFromCollaborationDialog } from "@taketurns-app/pages/authenticated/ExcludedFromCollaborationDialog";
import { ChatWrapper } from "@taketurns-components/chat/ChatWrapper/ChatWrapper";
import { CreateNewCollaborationButton } from "@taketurns-components/collaboration/CollaborationCreation/CreateCollaborationButton/CreateNewCollaborationButton";
import { CollaborationList } from "@taketurns-components/collaboration/CollaborationList/CollaborationList";
import { CollaborationListHeader } from "@taketurns-components/collaboration/CollaborationList/CollaborationListHeader/CollaborationListHeader";
import { MobileCollaborationList } from "@taketurns-components/collaboration/CollaborationList/MobileCollaborationList/MobileCollaborationList";
import { MobileSupervisorFooter } from "@taketurns-components/collaboration/CollaborationList/MobileCollaborationList/MobileSupervisorFooter";
import { DefaultWelcomeDialog } from "@taketurns-components/webapp/WelcomeDialogs/DefaultWelcomeDialog";
import { useWebAppTranslations } from "@taketurns-i18n/webapp/useWebAppTranslations";
import { useSubscribeToCollaborationListUpdatedRule } from "@taketurns-rules/collaboration/subscriptions/useSubscribeToCollaborationListUpdatedRule";
import { useIsOnMobileRule } from "@taketurns-rules/commons/theme/useIsOnMobileRule";
import { useShouldDisplayDefaultWelcomeDialogRule } from "@taketurns-rules/webapp/queries/useShouldDisplayDefaultWelcomeDialogRule";

export const CollaborationListPage = () => {
  const { t } = useWebAppTranslations("collaboration-list");
  const isOnMobile = useIsOnMobileRule();
  const shouldDisplayWelcomeDialog = useShouldDisplayDefaultWelcomeDialogRule();

  useSubscribeToCollaborationListUpdatedRule();

  if (isOnMobile)
    return (
      <MobileBodyLayout
        disablePadding
        header={<CollaborationListHeader />}
        content={
          <Fragment>
            <ChatWrapper>
              <MobileCollaborationList />
              <MobileSupervisorFooter />
            </ChatWrapper>
            <ExcludedFromCollaborationDialog />
            {shouldDisplayWelcomeDialog && <DefaultWelcomeDialog />}
          </Fragment>
        }
      />
    );

  return (
    <SingleColumnLayout
      header={
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h6">{t("title")}</Typography>
          <CreateNewCollaborationButton />
        </Stack>
      }
      content={
        <Fragment>
          <ChatWrapper>
            <CollaborationList />
          </ChatWrapper>
          <ExcludedFromCollaborationDialog />
          {shouldDisplayWelcomeDialog && <DefaultWelcomeDialog />}
        </Fragment>
      }
    />
  );
};
