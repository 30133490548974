/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql } from "@apollo/client";
import { SetAttachmentLockOutput, SubscriptionOnSetAttachmentLockArgs } from "@taketurns/taketurns-graphql-repository";
import { useRefreshableSubscription } from "@taketurns-components/webapp/QueriesAndSubscriptionsRefreshTrigger";
import { useSetAttachmentLock } from "@taketurns-repositories/collaboration/graphql/cache/useSetAttachmentLock";

const SUBSCRIBE_TO_SET_ATTACHMENT_LOCK = gql`
  subscription onSetAttachmentLock($collaborationId: ID!, $partyId: ID!, $attachmentId: ID!) {
    onSetAttachmentLock(collaborationId: $collaborationId, partyId: $partyId, attachmentId: $attachmentId) {
      collaborationId
      partyId
      attachmentId
      locked
    }
  }
`;

export const useSubscribeToSetAttachmentLock = (args: SubscriptionOnSetAttachmentLockArgs) => {
  const setAttachmentLock = useSetAttachmentLock();

  return useRefreshableSubscription<
    { onSetAttachmentLock: SetAttachmentLockOutput },
    SubscriptionOnSetAttachmentLockArgs
  >(SUBSCRIBE_TO_SET_ATTACHMENT_LOCK, {
    variables: { ...args },
    onData: ({ data: { data } }) => {
      setAttachmentLock(data.onSetAttachmentLock.attachmentId, data.onSetAttachmentLock.locked);
    },
  });
};
