/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Box, Divider, Skeleton, styled } from "@mui/material";
import { useEffect, useState } from "react";
import { ContentBrowser } from "@taketurns-components/collaboration/CollaborationView/CollaborationContent/ContentBrowser/ContentBrowser";
import { ContentHeader } from "@taketurns-components/collaboration/CollaborationView/CollaborationContent/ContentHeader/ContentHeader";
import { MobileContentHeader } from "@taketurns-components/collaboration/CollaborationView/CollaborationContent/ContentHeader/MobileContentHeader";
import { TodosViewer } from "@taketurns-components/collaboration/CollaborationView/CollaborationContent/TodosViewer/TodosViewer";
import { useGetDisplayedCollaborationRootFolderId } from "@taketurns-repositories/collaboration/context/useGetDisplayedCollaborationRootFolderId";
import { useGetCurrentFolderIdInCollaborationView } from "@taketurns-repositories/collaboration/state/read/collaborationView/useGetCurrentFolderIdInCollaborationView";
import { useBrowseContentRule } from "@taketurns-rules/collaboration/commands/collaborationView/useBrowseContentRule";
import { useIsOnMobileRule } from "@taketurns-rules/commons/theme/useIsOnMobileRule";

export const CollaborationContent = () => {
  const rootFolderId = useGetDisplayedCollaborationRootFolderId();
  const { initPath } = useBrowseContentRule();
  const folderId = useGetCurrentFolderIdInCollaborationView();
  const isOnMobile = useIsOnMobileRule();

  const [displayUpdatedOnly, setDisplayUpdatedOnly] = useState<boolean>(false);
  const [displayTodos, setDisplayTodos] = useState<boolean>(false);

  useEffect(() => {
    if (displayUpdatedOnly) {
      setDisplayTodos(false);
    }
  }, [displayUpdatedOnly]);

  useEffect(() => {
    if (displayTodos) {
      setDisplayUpdatedOnly(false);
    }
  }, [displayTodos]);

  useEffect(() => {
    if (rootFolderId) {
      initPath(rootFolderId);
    }
  }, [rootFolderId]);

  if (!folderId) {
    return <Skeleton width={1} />;
  }

  if (isOnMobile) {
    return (
      <Box overflow={"auto"}>
        <MobileContentHeader
          displayUpdatedOnly={displayUpdatedOnly}
          setDisplayUpdatedOnly={setDisplayUpdatedOnly}
          displayTodos={displayTodos}
          setDisplayTodos={setDisplayTodos}
        />
        <Divider />
        {displayTodos && <TodosViewer />}
        {!displayTodos && <ContentBrowser displayUpdatedOnly={displayUpdatedOnly} />}
      </Box>
    );
  } else {
    return (
      <CollaborationContent__Container className={"CollaborationContent__Container"}>
        <ContentHeader
          displayUpdatedOnly={displayUpdatedOnly}
          setDisplayUpdatedOnly={setDisplayUpdatedOnly}
          displayTodos={displayTodos}
          setDisplayTodos={setDisplayTodos}
        />
        {displayTodos && <TodosViewer />}
        {!displayTodos && <ContentBrowser displayUpdatedOnly={displayUpdatedOnly} />}
      </CollaborationContent__Container>
    );
  }
};

const CollaborationContent__Container = styled(Box)({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  paddingBottom: 0,
  height: "100%",
});
