/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useGetCurrentFolderIdInCollaborationView } from "@taketurns-repositories/collaboration/state/read/collaborationView/useGetCurrentFolderIdInCollaborationView";
import { useGetAttachmentSelectionStateById } from "@taketurns-repositories/collaboration/state/read/contentSelection/useGetAttachmentSelectionStateById";
import { useGetFolderSelectionStateById } from "@taketurns-repositories/collaboration/state/read/contentSelection/useGetFolderSelectionStateById";
import { useToggleAttachmentSelectionStateRule } from "@taketurns-rules/collaboration/commands/collaborationView/contentSelection/useToggleAttachmentSelectionStateRule";
import { useIsContentSelectionStateActiveRule } from "@taketurns-rules/collaboration/queries/collaborationView/contentSelection/useIsContentSelectionStateActiveRule";

export const useGetAttachmentSelectionStateInCurrentFolderByIdRule = (attachmentId: string) => {
  const isContentSelectionActive = useIsContentSelectionStateActiveRule();
  const isAttachmentSelected = useGetAttachmentSelectionStateById(attachmentId);
  const toggleAttachmentSelection = useToggleAttachmentSelectionStateRule(attachmentId);

  const currentFolderId = useGetCurrentFolderIdInCollaborationView();
  const isCurrentFolderSelected = useGetFolderSelectionStateById(currentFolderId);

  const toggleSelection = () => {
    if (isCurrentFolderSelected) return;
    return toggleAttachmentSelection();
  };

  return {
    isContentSelectionActive,
    isAttachmentSelected: isAttachmentSelected || isCurrentFolderSelected,
    toggleSelection,
  };
};
