/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useGetDisplayedCollaborationId } from "@taketurns-repositories/collaboration/context/useGetDisplayedCollaborationId";
import { useFetchCollaborationContentMetadata } from "@taketurns-repositories/collaboration/graphql/queries/content/useFetchCollaborationContentMetadata";

export const useFetchCollaborationContentMetadataRule = () => {
  const collaborationId = useGetDisplayedCollaborationId();
  return useFetchCollaborationContentMetadata(collaborationId);
};
