/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useFetchAvailableFeatures } from "./useFetchAvailableFeatures";

export const useFetchIsRecallUpdatesFeatureAvailable = () => {
  const { availableFeatures, loadingAvailableFeatures, errorOnFetchingAvailableFeatures } = useFetchAvailableFeatures();
  return {
    isRecallUpdatesFeatureAvailable: availableFeatures?.turnRecall ?? false,
    loadingRecallUpdatesFeatureAvailable: loadingAvailableFeatures,
    errorOnFetchingUpdatesRecallFeatureAvailable: errorOnFetchingAvailableFeatures,
  };
};
