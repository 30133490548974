/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useMoveRequestIntoFolder } from "@taketurns-repositories/collaboration/graphql/mutations/content/requests/useMoveRequestIntoFolder";
import { useGetDisplayedCollaborationIdRule } from "@taketurns-rules/collaboration/queries/collaboration/useGetDisplayedCollaborationIdRule";

export function useMoveRequestIntoFolderRule(requestId: string) {
  const collaborationId = useGetDisplayedCollaborationIdRule();
  const moveRequestIntoFolder = useMoveRequestIntoFolder({ collaborationId, requestId });
  return async (destinationFolderId: string) => {
    await moveRequestIntoFolder(destinationFolderId);
  };
}
