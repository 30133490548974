/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { gql, useLazyQuery } from "@apollo/client";

const GENERATE_DOCUMENT_ID = gql`
  query generateDocumentId {
    generateDocumentId
  }
`;

export const useGenerateDocumentId = () => {
  const [generateDocumentId] = useLazyQuery<{ generateDocumentId: string }>(GENERATE_DOCUMENT_ID, {
    fetchPolicy: "no-cache",
    context: { queryDeduplication: false },
  });
  return generateDocumentId;
};
