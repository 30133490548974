/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useRecoilValue } from "recoil";
import { requestFilesToAddOnRequestFulfillmentOpeningState } from "../../recoil/requestFilesToAddOnRequestFulfillmentOpeningState";

export const useGetRequestFilesToAddOnRequestFulfillmentOpening = () => {
  return useRecoilValue(requestFilesToAddOnRequestFulfillmentOpeningState);
};
