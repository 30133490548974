/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useSetRecoilState } from "recoil";
import { contentSelectionState } from "@taketurns-repositories/collaboration/state/recoil/contentSelectionState";

export const useToggleFolderSelectionState = (FolderId: string) => {
  const setState = useSetRecoilState(contentSelectionState);
  return () => {
    setState((prevState) => {
      const folderIsSelected = prevState.folderIds.includes(FolderId);

      if (folderIsSelected) {
        return {
          ...prevState,
          folderIds: prevState.folderIds.filter((id) => id !== FolderId),
        };
      } else {
        return {
          ...prevState,
          folderIds: [...prevState.folderIds, FolderId],
        };
      }
    });
  };
};
