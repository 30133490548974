/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Box, Stack, styled } from "@mui/material";
import { Fragment, ReactNode } from "react";
import { AddFilesButton } from "@taketurns-components/collaboration/Shared/content/AddFilesButton";
import { AddFolderButton } from "@taketurns-components/collaboration/Shared/content/AddFolderButton";
import { AddRequestButton } from "@taketurns-components/collaboration/Shared/content/AddRequestButton";
import { useGetCurrentFolderIdInCreation } from "@taketurns-repositories/collaboration/state/read/collaborationCreation/useGetCurrentFolderIdInCreation";
import { useAddFilesInCreationRule } from "@taketurns-rules/collaboration/commands/collaborationCreation/attachment/pending/useAddFilesInCreationRule";
import { useAddFolderInCreationRule } from "@taketurns-rules/collaboration/commands/collaborationCreation/folder/useAddFolderInCreationRule";
import { useAddRequestInCreationRule } from "@taketurns-rules/collaboration/commands/collaborationCreation/request/useAddRequestInCreationRule";
import { useGetUploadIdsForCollaborationCreationRule } from "@taketurns-rules/collaboration/queries/collaborationCreation/useGetUploadIdsForCollaborationCreationRule";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { UploadsProgressIndicator } from "../../../document/DocumentUpload/UploadsProgressIndicator/UploadsProgressIndicator";
import { ContentAsList } from "./ContentAsList/ContentAsList";
import { ContentAsListWithDropZone } from "./ContentAsList/ContentAsListWithDropZone";

interface CollaborationCreationContentProps {
  showAddFile?: boolean;
  showAddRequest?: boolean;
  actionSlot?: ReactNode;
}

//TODO refactoring of actionSlot with removal of micro-front TT-2665
export const CollaborationCreationContent = ({
  showAddFile,
  showAddRequest,
  actionSlot,
}: CollaborationCreationContentProps) => {
  const currentFolderId = useGetCurrentFolderIdInCreation();
  const addFilesInCreationRule = useAddFilesInCreationRule();
  const addFilesInCreation = async (files: FileList) => {
    await addFilesInCreationRule([...files], currentFolderId);
  };

  const addFolder = useAddFolderInCreationRule();
  const addRequest = useAddRequestInCreationRule();

  return (
    <Fragment>
      <CollaborationCreationContent__ButtonsAndContent className="CollaborationCreationContent__ButtonsAndContent">
        <CollaborationCreationContent__Buttons className="CollaborationCreationContent__Buttons">
          {showAddFile && (
            <Fragment>
              <AddFilesButton processFiles={addFilesInCreation} />
              <AddFolderButton addFolder={addFolder} />
            </Fragment>
          )}
          {showAddRequest && <AddRequestButton addRequest={addRequest} />}
        </CollaborationCreationContent__Buttons>
        <CollaborationCreationContent__Content className="CollaborationCreationContent__Content">
          {showAddFile ? <ContentAsListWithDropZone /> : <ContentAsList />}
        </CollaborationCreationContent__Content>
      </CollaborationCreationContent__ButtonsAndContent>
      <CollaborationCreationContentFooter actionSlot={actionSlot} />
    </Fragment>
  );
};

const CollaborationCreationContentFooter = ({ actionSlot }: { actionSlot?: ReactNode }) => {
  const uploadIdsForCollaborationCreation = useGetUploadIdsForCollaborationCreationRule();
  return (
    <Footer__Container>
      <Box flex={1}>
        {uploadIdsForCollaborationCreation.length > 0 && (
          <UploadsProgressIndicator uploadIds={uploadIdsForCollaborationCreation} />
        )}
      </Box>
      <Box>{actionSlot}</Box>
    </Footer__Container>
  );
};

const Footer__Container = styled(Stack)({
  justifySelf: "flex-end",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  marginLeft: "-24px !important",
  marginRight: "-24px !important",
  marginTop: "0 !important",
  borderTop: `1px solid ${TakeTurnsColors.lightGray}`,
  backgroundColor: "white",
  paddingRight: "24px",
  paddingLeft: "24px",
  minHeight: "50px",

  "@media (max-height: 700px)": {
    position: "sticky",
    bottom: 0,
  },
});

const CollaborationCreationContent__ButtonsAndContent = styled(Box)({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  "@media (min-height: 700px)": {
    minHeight: 0,
  },
});

const CollaborationCreationContent__Buttons = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  [theme.breakpoints.up("lg")]: {
    gap: "16px",
  },
}));

const CollaborationCreationContent__Content = styled(Box)({
  flex: 1,
  marginTop: 0,
  marginRight: "-24px",
  marginLeft: "-24px",
  overflow: "auto",
});
