/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { DocumentNode, useLazyQuery } from "@apollo/client";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  GenerateAiAssistantResponseFileUrlOutput,
  GenerateAiAssistantResponseFileUrlStatus,
} from "@taketurns/taketurns-graphql-repository";

export const usePollGenerateAiAssistantResponseFileUrl = (
  responseKey: string,
  query: DocumentNode,
  variables: {
    documentId: string;
    revisionId: string;
    language: string;
    collaborationId?: string;
  },
) => {
  const POLL_INTERVAL_IN_MS = 1000;
  const [fetchQuery, { data, error, stopPolling }] = useLazyQuery<{
    [key: string]: GenerateAiAssistantResponseFileUrlOutput;
  }>(query, { fetchPolicy: "network-only", variables, pollInterval: POLL_INTERVAL_IN_MS });

  useEffect(() => {
    fetchQuery();
  }, [fetchQuery]);

  const stopPollingAndLoading = useCallback(() => {
    stopPolling();
    retryCountRef.current = 0;
    setLoading(false);
  }, [stopPolling]);

  const [loading, setLoading] = useState(true);
  const MAX_TIMEOUT_IN_MS = 150000;
  const [hasTimedOut, setHasTimedOut] = useState<boolean>(false);
  useEffect(() => {
    if (hasTimedOut || !loading) return;
    const timeoutId = setTimeout(() => {
      stopPollingAndLoading();
      setHasTimedOut(true);
    }, MAX_TIMEOUT_IN_MS);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [hasTimedOut, loading, stopPollingAndLoading]);

  useEffect(() => {
    if (
      data?.[responseKey]?.status &&
      (data?.[responseKey].status === GenerateAiAssistantResponseFileUrlStatus.Ready ||
        data?.[responseKey].status === GenerateAiAssistantResponseFileUrlStatus.Error)
    ) {
      stopPollingAndLoading();
    }
  }, [data, responseKey, stopPollingAndLoading]);

  const retryCountRef = useRef<number>(0);
  const maxRetryCount = MAX_TIMEOUT_IN_MS / POLL_INTERVAL_IN_MS;
  useEffect(() => {
    if (error) {
      retryCountRef.current++;
      if (retryCountRef.current > maxRetryCount) {
        stopPollingAndLoading();
      }
    }
  }, [error, maxRetryCount, stopPollingAndLoading]);

  const retryFetchQuery = () => {
    fetchQuery();
    setLoading(true);
  };

  return {
    retryFetchQuery,
    errorCode: hasTimedOut ? "TIMEOUT" : data?.[responseKey]?.errorCode,
    downloadUrl: data?.[responseKey]?.downloadUrl,
    loading,
    error,
  };
};
