/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Box, Stack, styled, Typography } from "@mui/material";
import { MouseEvent, useState } from "react";
import { EventOnCollaboration } from "@taketurns/taketurns-graphql-repository";
import { EventNoteDialog } from "@taketurns-components/collaboration/Shared/EventNote/EventNoteDialog";
import { IconNote } from "@taketurns-components/commons/icons";
import { useCollaborationListTranslation } from "@taketurns-i18n/collaboration/CollaborationList/useCollaborationListTranslation";
import { useDurationLabelFromDateRule } from "@taketurns-rules/collaboration/ui/useDurationLabelFromDateRule";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";

export const LastUpdateCell = ({
  lastUpdateEvent,
  collaborationId,
}: {
  lastUpdateEvent: EventOnCollaboration;
  collaborationId: string;
}) => {
  const { t } = useCollaborationListTranslation();

  const computeDurationLabelFromDate = useDurationLabelFromDateRule();
  const blueColor = lastUpdateEvent.isFromConnectedUserParty
    ? TakeTurnsColors.connectedUser
    : TakeTurnsColors.otherParty;

  const [isEventDialogOpen, setIsEventDialogOpen] = useState(false);
  const openLastUpdateNoteDialog = (event: MouseEvent<SVGElement>) => {
    event.stopPropagation();
    setIsEventDialogOpen(true);
  };
  return (
    <Stack direction="row" spacing={0.5} alignItems="center" width={"100%"}>
      <LastUpdateCell__DurationLabel noWrap>
        {`${computeDurationLabelFromDate(lastUpdateEvent.date, true)} ${t("lastUpdate.by")}`}
      </LastUpdateCell__DurationLabel>
      <Typography noWrap color={blueColor} fontSize={12} title={lastUpdateEvent.partyName}>
        {lastUpdateEvent.partyName}
      </Typography>
      {lastUpdateEvent.note && (
        <Box width={18} height={18}>
          <IconNote
            size={18}
            color={blueColor}
            onClick={(event: MouseEvent<SVGElement>) => openLastUpdateNoteDialog(event)}
          />
          <EventNoteDialog
            open={isEventDialogOpen}
            onClose={() => setIsEventDialogOpen(false)}
            collaborationId={collaborationId}
            eventId={lastUpdateEvent.id}
          />
        </Box>
      )}
    </Stack>
  );
};

const LastUpdateCell__DurationLabel = styled(Typography)({
  fontSize: 12,
  color: TakeTurnsColors.darkGray,
  flex: "0 0 auto",
  "&:first-letter": {
    textTransform: "uppercase",
  },
});
