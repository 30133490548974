/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Box, Fab, styled } from "@mui/material";
import { useRef, useState } from "react";
import { NewCollaborationMenu } from "@taketurns-components/collaboration/CollaborationCreation/CreateCollaborationButton/NewCollaborationMenu";
import { UpgradePlanToCreateCollaborationText } from "@taketurns-components/collaboration/Shared/UpgradePlanToCreateCollaborationText";
import { WithOpenUpgradePlanDialogOnClick } from "@taketurns-components/collaboration/Shared/WithOpenUpgradePlanDialogOnClick";
import { IconPlus } from "@taketurns-components/commons/icons";
import { useFetchUserHasCreateCollaborationFeatureRule } from "@taketurns-rules/collaboration/queries/useFetchUserHasCreateCollaborationFeatureRule";
import { useShouldDisplaySupervisorFilterRule } from "@taketurns-rules/collaboration/queries/useShouldDisplaySupervisorFilterRule";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";

export const MobileCreateCollaborationFAB = () => {
  const { hasCreateCollaborationFeature } = useFetchUserHasCreateCollaborationFeatureRule();
  const [isFABOpen, setIsFABOpen] = useState(false);
  const createCollaborationButtonRef = useRef(null);

  const shouldDisplaySupervisorFilter = useShouldDisplaySupervisorFilterRule();
  const bottomMargin = shouldDisplaySupervisorFilter ? 55 : 12;

  const ProtectedButton = WithOpenUpgradePlanDialogOnClick(CreateCollaborationFAB__PlusFAB, {
    UpgradeToPlanDialogContentSlot: <UpgradePlanToCreateCollaborationText />,
    isFeatureAvailable: hasCreateCollaborationFeature,
  });

  const toggleCreateCollaborationFAB = () => {
    setIsFABOpen(!isFABOpen);
  };

  return (
    <CreateCollaborationFAB__ButtonContainer ref={createCollaborationButtonRef} bottomMargin={bottomMargin}>
      <ProtectedButton onClick={toggleCreateCollaborationFAB}>
        <IconPlus size={24} />
      </ProtectedButton>
      <NewCollaborationMenu
        open={isFABOpen}
        anchorEl={createCollaborationButtonRef.current}
        onClick={toggleCreateCollaborationFAB}
      />
    </CreateCollaborationFAB__ButtonContainer>
  );
};

const CreateCollaborationFAB__ButtonContainer = styled(Box, { shouldForwardProp: (prop) => prop !== "bottomMargin" })<{
  bottomMargin: number;
}>(({ bottomMargin }) => ({
  position: "fixed",
  right: "calc(10px + env(safe-area-inset-right))",
  bottom: `calc(${bottomMargin}px + env(safe-area-inset-bottom))`,
}));

const CreateCollaborationFAB__PlusFAB = styled(Fab)({
  boxShadow: "0px 1px 20px 0px rgba(0, 0, 0, 0.15)",
  backgroundColor: TakeTurnsColors.yellow,
  color: "white",
  "&:hover": {
    opacity: "90%",
    background: TakeTurnsColors.yellow,
  },
});
