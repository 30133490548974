/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useQuery } from "@apollo/client";
import { ConnectedUser } from "@taketurns/taketurns-graphql-repository";

export const useFetchUserHasOrganizationAccessPoliciesFeature = () => {
  const USER_FEATURE_ORGA_ACCESS_MANAGEMENT = gql`
    query userHasOrganizationAccessPoliciesFeature {
      connectedUser {
        id
        availableFeatures {
          organizationAccessPolicies
        }
      }
    }
  `;
  const {
    data,
    error: errorOnHasOrganizationAccessPoliciesFeature,
    loading: loadingHasOrganizationAccessPoliciesFeature,
  } = useQuery<{
    connectedUser: ConnectedUser;
  }>(USER_FEATURE_ORGA_ACCESS_MANAGEMENT, { fetchPolicy: "cache-and-network" });
  return {
    userHasOrganizationAccessPoliciesFeature:
      data?.connectedUser?.availableFeatures?.organizationAccessPolicies ?? false,
    errorOnHasOrganizationAccessPoliciesFeature,
    loadingHasOrganizationAccessPoliciesFeature,
  };
};
