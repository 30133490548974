/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql } from "@apollo/client";
import { MoveOutput, SubscriptionOnMoveFolderFromSourceFolderArgs } from "@taketurns/taketurns-graphql-repository";
import { useRefreshableSubscription } from "@taketurns-components/webapp/QueriesAndSubscriptionsRefreshTrigger";
import { moveFolderMutationFragment } from "@taketurns-repositories/collaboration/graphql/mutations/content/folders/useMoveFolderIntoFolderMutation";

const SUBSCRIBE_TO_MOVE_FOLDER_FROM_SOURCE_FOLDER = gql`
  subscription onMoveFolderFromSourceFolder($collaborationId: ID!, $partyId: ID!, $sourceFolderId: ID!) {
    onMoveFolderFromSourceFolder(
      collaborationId: $collaborationId
      partyId: $partyId
      sourceFolderId: $sourceFolderId
    ) {
      ...MoveFolderMutationFragment
    }
  }
  ${moveFolderMutationFragment}
`;

export const useSubscribeToMoveFolderFromSourceFolder = (args: SubscriptionOnMoveFolderFromSourceFolderArgs) => {
  return useRefreshableSubscription<
    { onMoveFolderFromSourceFolder: MoveOutput },
    SubscriptionOnMoveFolderFromSourceFolderArgs
  >(SUBSCRIBE_TO_MOVE_FOLDER_FROM_SOURCE_FOLDER, { variables: args });
};
