/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Button, Dialog, DialogActions, styled } from "@mui/material";
import { Fragment, useState } from "react";
import { TakeTurnsDefaultButton } from "@taketurns-components/commons/button";
import { useUserPlanTranslation } from "@taketurns-i18n/user/UserPlans/useUserPlanTranslation";
import { useIsOnMobileRule } from "@taketurns-rules/commons/theme/useIsOnMobileRule";
import { useGetContactSalesDialogUrlRule } from "@taketurns-rules/user/queries/useGetContactSalesDialogUrlRule";

interface ContactSalesButtonAndDialogProps {
  mainAction?: boolean;
}

export const ContactSalesButtonAndDialog = (props: ContactSalesButtonAndDialogProps) => {
  const { t } = useUserPlanTranslation();
  const [contactSalesDialogOpened, setContactSalesDialogOpened] = useState(false);

  const openContactSalesDialog = () => {
    setContactSalesDialogOpened(true);
  };

  const closeContactSalesDialog = () => {
    setContactSalesDialogOpened(false);
  };

  return (
    <Fragment>
      {props.mainAction ? (
        <TakeTurnsDefaultButton onClick={openContactSalesDialog}>{t("buttons.contactSales")}</TakeTurnsDefaultButton>
      ) : (
        <Button color="primary" type="submit" onClick={openContactSalesDialog}>
          {t("buttons.contactSales")}
        </Button>
      )}
      <ContactSalesDialog open={contactSalesDialogOpened} onClose={closeContactSalesDialog} />
    </Fragment>
  );
};

const ContactSalesDialog = ({ open, onClose }: { open: boolean; onClose: () => void }) => {
  const isOnMobile = useIsOnMobileRule();
  const contactSalesDialogUrl = useGetContactSalesDialogUrlRule();

  const { t } = useUserPlanTranslation();

  return (
    <Dialog
      PaperProps={{
        sx: {
          borderRadius: { xs: 0, md: "20px" },
          minHeight: "80%",
          "& .MuiDialogActions-root": {
            paddingBottom: "calc(24px + env(safe-area-inset-bottom))",
          },
        },
      }}
      fullWidth
      maxWidth={"lg"}
      fullScreen={isOnMobile}
      open={open}
      onClose={onClose}
    >
      <ContactSalesDialogIframe src={contactSalesDialogUrl}></ContactSalesDialogIframe>
      <DialogActions>
        <Button variant={"text"} onClick={onClose}>
          {t("buttons.close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const ContactSalesDialogIframe = styled("iframe")({
  flex: 1,
  border: 0,
});
