/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Folder } from "@taketurns/taketurns-graphql-repository";
import { useGetCurrentFolderIdInCollaborationView } from "@taketurns-repositories/collaboration/state/read/collaborationView/useGetCurrentFolderIdInCollaborationView";
import { useGetFolderSelectionStateById } from "@taketurns-repositories/collaboration/state/read/contentSelection/useGetFolderSelectionStateById";
import { useEmptyContentSelectionState } from "@taketurns-repositories/collaboration/state/write/contentSelection/useEmptyContentSelectionState";
import { useToggleFolderSelectionStateRule } from "@taketurns-rules/collaboration/commands/collaborationView/contentSelection/useToggleFolderSelectionStateRule";
import { useGetSelectedContentRule } from "@taketurns-rules/collaboration/queries/collaborationView/contentSelection/useGetSelectedContentRule";
import { useFetchFolderRule } from "@taketurns-rules/collaboration/queries/folder/useFetchFolderRule";

export const useGetCurrentFolderSelectionStateRule = () => {
  const currentFolderId = useGetCurrentFolderIdInCollaborationView();
  const { folder, errorOnFetchingFolder } = useFetchFolderRule(currentFolderId);

  const isFolderSelected = useGetFolderSelectionStateById(currentFolderId);
  const selectedFiles = useGetSelectedContentRule();
  const isAllContentFolderSelected = isFolderSelected || checkIfAllContentFolderIsSelected(folder, selectedFiles);

  const toggleFolderSelection = useToggleFolderSelectionStateRule(currentFolderId);
  const emptyContentSelection = useEmptyContentSelectionState();

  const toggleSelection = () => {
    if (isAllContentFolderSelected) {
      emptyContentSelection();
    } else {
      toggleFolderSelection();
    }
  };

  return {
    folder,
    errorOnFetchingFolder,
    isAllContentFolderSelected,
    toggleSelection,
  };

  function checkIfAllContentFolderIsSelected(
    folder: Folder,
    selectedFiles: ReturnType<typeof useGetSelectedContentRule>,
  ) {
    if (folder.attachments.some((attachment) => !selectedFiles.attachmentIds.includes(attachment.id))) {
      return false;
    }
    return !folder.folders.some((subFolder) => !selectedFiles.folderIds.includes(subFolder.id));
  }
};
