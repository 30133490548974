/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Stack, styled } from "@mui/material";
import { ChangeEvent, ClipboardEvent, useEffect, useRef, useState } from "react";

interface InputCodeProps {
  length: number;
  onComplete: (code: string) => void;
}

export const CodeInput = ({ length, onComplete }: InputCodeProps) => {
  const [codeCharacters, setCodeCharacters] = useState([...Array(length)].map(() => ""));
  const inputs = useRef<HTMLInputElement[]>([]);

  useEffect(() => {
    const areAllCharactersFilled = codeCharacters.every((num) => num !== "");
    if (areAllCharactersFilled) {
      setTimeout(() => onComplete(codeCharacters.join("")), 500);
    }
  }, [codeCharacters, onComplete]);

  const handlePasteVerificationCode = (e: ClipboardEvent<HTMLInputElement>) => {
    const code = e.clipboardData.getData("text");
    setCodeCharacters(Array.from(code).slice(0, length));
  };

  const processInput = (e: ChangeEvent<HTMLInputElement>, slot: number) => {
    const value = e.target.value;
    const newCode = [...codeCharacters];
    newCode[slot] = value;
    setCodeCharacters(newCode);
    if (slot !== length - 1) {
      inputs.current[slot + 1].focus();
    }
  };

  const onKeyUp = (e: { keyCode: number }, slot: number) => {
    if (e.keyCode === 8 && !codeCharacters[slot] && slot !== 0) {
      const newCode = [...codeCharacters];
      newCode[slot - 1] = "";
      setCodeCharacters(newCode);
      inputs.current[slot - 1].focus();
    }
  };

  return (
    <Stack data-cy="verificationCodeInput" direction="row" justifyContent={"center"} spacing={2}>
      {codeCharacters.map((num, index) => (
        <InputForCode
          data-cy={`verificationCodeInput.${index}`}
          onPaste={handlePasteVerificationCode}
          inputMode="numeric"
          maxLength={1}
          ref={(ref) => inputs.current.push(ref)}
          key={index}
          value={num}
          autoFocus={!codeCharacters[0].length && index === 0}
          onChange={(e) => processInput(e, index)}
          onKeyUp={(e) => onKeyUp(e, index)}
        />
      ))}
    </Stack>
  );
};

const InputForCode = styled("input")({
  width: "36px",
  display: "flex",
  height: "36px",
  borderRadius: "10px",
  boxShadow: "none",
  border: "1px solid #DDDDDD",
  textAlign: "center",
  fontSize: "12px",
  "&:focus": {
    outline: "none",
    border: "1px solid #0099FF",
  },
});
