/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { ContentStatus, Folder } from "@taketurns/taketurns-graphql-repository";
import { FetchLoadingManager } from "@taketurns-app/utils/FetchLoadingManager";
import { useGetDisplayedCollaborationRootFolderId } from "@taketurns-repositories/collaboration/context/useGetDisplayedCollaborationRootFolderId";
import { useSubfolderIdsInFolderInCache } from "@taketurns-repositories/collaboration/graphql/cache/useSubfolderIdsInFolderInCache";
import { useAddFolder } from "@taketurns-repositories/collaboration/graphql/mutations/content/folders/useAddFolder";
import { useBrowseContentRule } from "@taketurns-rules/collaboration/commands/collaborationView/useBrowseContentRule";
import { DestinationFolder } from "@taketurns-rules/collaboration/commands/destinationFolder";
import { useGetDisplayedCollaborationIdRule } from "@taketurns-rules/collaboration/queries/collaboration/useGetDisplayedCollaborationIdRule";
import { useFetchDestinationFolderRule } from "@taketurns-rules/collaboration/queries/folder/useFetchDestinationFolderRule";
import { DestinationFolderList, DestinationFolderListProps } from "../../MoveDestination/DestinationFolderList";
import { DestinationFolderListItem } from "../../MoveDestination/DestinationFolderListItem";
import { MoveDestinationDialog, MoveDestinationDialogProps } from "../../MoveDestination/MoveDestinationDialog";

export const MoveDestinationDialogInCollaborationView = (
  props: Omit<MoveDestinationDialogProps, "rootFolderId" | "DestinationFolderListSlot" | "movingElementSourceFolderId">,
) => {
  const { currentFolderId } = useBrowseContentRule();
  const rootFolderId = useGetDisplayedCollaborationRootFolderId();

  return (
    <MoveDestinationDialog
      {...props}
      rootFolderId={rootFolderId}
      movingElementSourceFolderId={currentFolderId}
      DestinationFolderListSlot={DestinationFolderListInCollaborationView}
    />
  );
};

const DestinationFolderListInCollaborationView = (
  props: Omit<
    DestinationFolderListProps,
    "useGetDestinationFolder" | "addFolderInSelectedFolder" | "FolderListItem" | "rootFolderId"
  >,
) => {
  const rootFolderId = useGetDisplayedCollaborationRootFolderId();
  const addFolderInSelectedFolder = useAddFolderInSelectedFolder();

  return (
    <DestinationFolderList
      {...props}
      rootFolderId={rootFolderId}
      useGetDestinationFolder={useDestinationFolderFromCollaborationRule}
      addFolderInSelectedFolder={addFolderInSelectedFolder}
      FolderListItem={DestinationFolderListItemInCollaborationView}
    />
  );
};

const useAddFolderInSelectedFolder = () => {
  const collaborationId = useGetDisplayedCollaborationIdRule();
  const getSubfoldersInFolder = useSubfolderIdsInFolderInCache();
  const { addFolderMutation, errorOnAddingFolder } = useAddFolder(collaborationId);
  if (errorOnAddingFolder) {
    console.error(errorOnAddingFolder);
  }

  async function addFolderInSelectedFolder(folderName: string, parentId: string) {
    const previousSubfolderIds = getSubfoldersInFolder(parentId)?.folders.map((folder) => folder.id) ?? [];
    await addFolderMutation(parentId, folderName);
    const updatedSubfolderIds = getSubfoldersInFolder(parentId)?.folders.map((folder) => folder.id) ?? [];
    const newFolderId = updatedSubfolderIds.find((id) => !previousSubfolderIds.includes(id));
    if (!newFolderId) {
      throw new Error("New folder id not found");
    }
    return newFolderId;
  }

  return addFolderInSelectedFolder;
};

const useDestinationFolderFromCollaborationRule = (folderId: string) => {
  const { folder, loadingDestinationFolder, errorOnFetchingDestinationFolder } =
    useFetchDestinationFolderRule(folderId);
  const fetchLoadingManager = new FetchLoadingManager([loadingDestinationFolder], [errorOnFetchingDestinationFolder]);
  if (fetchLoadingManager.hasFetchInError()) {
    fetchLoadingManager.logErrors();
  }
  return {
    destinationFolder: folder && getDestinationFolderFromFolder(folder),
    loadingDestinationFolder: fetchLoadingManager.hasFetchLoading(),
    errorWhileFetchingDestinationFolder: fetchLoadingManager.hasFetchInError(),
  };
};

const getDestinationFolderFromFolder = (folder: Folder): DestinationFolder => {
  return {
    id: folder.id,
    name: folder.name,
    folders:
      folder.folders
        ?.filter((folder) => folder.status !== ContentStatus.Removed && folder.prevStatus !== ContentStatus.Removed)
        .map((folder) => getDestinationFolderFromFolder(folder)) ?? [],
  };
};

const DestinationFolderListItemInCollaborationView = ({
  destinationFolder,
  onFolderClick,
}: {
  destinationFolder: DestinationFolder;
  onFolderClick: () => void;
}) => {
  return <DestinationFolderListItem folderName={destinationFolder.name} onFolderClick={onFolderClick} />;
};
