/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Stack } from "@mui/material";
import { OrganizationInvitation } from "@taketurns/taketurns-graphql-repository";
import {
  MobileOrganizationMemberUserOrInvitationInfo,
} from "@taketurns-components/user/Organization/OrganizationMemberAndInvitationList/MobileOrganizationMemberAndInvitationList/MobileOrganizationMemberUserOrInvitationInfo";
import { OrganizationInvitationRoleSelector } from "@taketurns-components/user/Organization/OrganizationMemberAndInvitationList/shared/OrganizationInvitationRoleSelector";
import { UserRoundedAvatar } from "@taketurns-components/user/UserProfile/UserAvatar/UserRoundedAvatar";

interface MobileOrganizationInvitationRowProps {
  invitation: OrganizationInvitation & { isInvitation: true };
}

export const MobileOrganizationInvitationRow = ({ invitation }: MobileOrganizationInvitationRowProps) => {
  return (
    <Stack direction="row" spacing={1.5} className="MobileOrganizationInvitationRow__Container">
      <UserRoundedAvatar user={invitation.user ?? { email: invitation.email }} />
      <Stack flex={1} spacing={1} overflow="hidden" className="MobileOrganizationInvitationRow__RightContainer">
        <MobileOrganizationMemberUserOrInvitationInfo memberOrInvitation={invitation} isConnectedUserRow={false} />
        <OrganizationInvitationRoleSelector invitation={invitation} />
      </Stack>
    </Stack>
  );
};
