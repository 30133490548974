/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Avatar, IconButton, ListItem, ListItemAvatar, ListItemText, Skeleton, styled, Typography } from "@mui/material";
import { CollaborationStatus, Participant, Party, Role } from "@taketurns/taketurns-graphql-repository";
import { CollaborationCommand, useCollaborationPermissions } from "@taketurns-rules/collaboration/permissions/useCollaborationPermissions";
import { useFetchCollaborationStatusRule } from "@taketurns-rules/collaboration/queries/fields/useFetchCollaborationStatusRule";
import { getDefaultColorForParty } from "@taketurns-rules/collaboration/utils/color/TakeTurnsBlueColorsUtils";
import { getUserNamesOrEmail } from "@taketurns-rules/collaboration/utils/participant/getUserNamesOrEmail";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { IconX } from "../../../commons/icons";
import { SelectRoleButton } from "./SelectRoleButton";

interface ParticipantItemProps {
  connectedUserParty: Party;
  participant: Participant;
  removeParticipantAction?: {
    action: (participant: Participant) => void;
    loading: boolean;
  };
  editParticipantRoleAction?: { action: (participant: Participant, newRole: Role) => void; loading: boolean };
  color?: { variable: string; themeColor: "primary" | "secondary" };
  canEdit?: boolean;
}

export type ParticipantItemComponentProps = Omit<
  ParticipantItemProps,
  "removeParticipantAction" | "editParticipantAction"
>;

export const ParticipantItem = (props: ParticipantItemProps) => {
  const { participant, color, canEdit, editParticipantRoleAction, removeParticipantAction } = props;

  const { status } = useFetchCollaborationStatusRule();

  const isAllowedToModifyParticipant =
    useCollaborationPermissions(CollaborationCommand.MANAGE_PARTICIPANTS) &&
    status !== CollaborationStatus.Closed &&
    status !== CollaborationStatus.Wiped &&
    canEdit;

  if (editParticipantRoleAction?.loading || removeParticipantAction?.loading) {
    return (
      <ParticipantItem__Container disablePadding divider>
        <ListItemText primary={<Skeleton variant="text" />} />
      </ParticipantItem__Container>
    );
  }

  const removeParticipant = () => {
    removeParticipantAction?.action(participant);
  };

  const editParticipantRole = (newRole: Role) => {
    editParticipantRoleAction?.action(participant, newRole);
  };

  return (
    <ParticipantItem__Container disablePadding divider>
      <ListItemAvatar sx={{ minWidth: 40 }}>
        <Avatar
          sx={{
            width: 30,
            height: 30,
            backgroundColor: color ? color.variable : getDefaultColorForParty(participant.party).variable,
          }}
          src={participant.user && participant.user.avatar}
        />
      </ListItemAvatar>
      <ListItemText
        primary={
          <Typography
            noWrap
            color={color ? color.themeColor : getDefaultColorForParty(participant.party).themeColor}
            fontSize={"0.75rem"}
          >
            {getUserNamesOrEmail(participant.user)}
          </Typography>
        }
        secondary={
          <Typography fontSize={"0.75rem"} color={TakeTurnsColors.darkGray}>
            {participant.user?.jobTitle}
          </Typography>
        }
      />
      {isAllowedToModifyParticipant && (
        <IconButton size="small" sx={{ marginRight: "2px" }} onClick={removeParticipant}>
          <IconX size={18} />
        </IconButton>
      )}
      <SelectRoleButton
        color={color ? color.variable : getDefaultColorForParty(participant.party).variable}
        selectingRoleUserParty={participant.party}
        connectedUserParty={props.connectedUserParty}
        role={participant.role}
        loading={editParticipantRoleAction?.loading}
        editable={isAllowedToModifyParticipant}
        onChange={editParticipantRole}
      />
    </ParticipantItem__Container>
  );
};

const ParticipantItem__Container = styled(ListItem)(({ theme }) => ({
  padding: 0,
  height: "50px",
  minHeight: "50px",
  [theme.breakpoints.down("md")]: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    height: "70px",
    minHeight: "70px",
  },
}));
