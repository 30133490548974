/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql } from "@apollo/client";
import { RenameFolderOutput, SubscriptionOnRenameFolderArgs } from "@taketurns/taketurns-graphql-repository";
import { useRefreshableSubscription } from "@taketurns-components/webapp/QueriesAndSubscriptionsRefreshTrigger";
import { renameFolderMutationFragment } from "../../mutations/content/folders/useRenameFolder";

const SUBSCRIBE_TO_RENAME_FOLDER = gql`
  ${renameFolderMutationFragment}
  subscription onRenameFolder($collaborationId: ID!, $partyId: ID!, $folderId: ID!) {
    onRenameFolder(collaborationId: $collaborationId, partyId: $partyId, folderId: $folderId) {
      ...RenameFolderMutationFragment
    }
  }
`;

export const useSubscribeToRenameFolder = (args: SubscriptionOnRenameFolderArgs) => {
  return useRefreshableSubscription<{ onRenameFolder: RenameFolderOutput }, SubscriptionOnRenameFolderArgs>(
    SUBSCRIBE_TO_RENAME_FOLDER,
    { variables: args },
  );
};
