/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Stack } from "@mui/material";
import {
  Annotation,
  BookmarkView,
  DisplayMode,
  FormDesigner,
  FormFields,
  Inject,
  LinkAnnotation,
  Magnification,
  Navigation,
  PdfViewerComponent,
  Print,
  TextSearch,
  TextSelection,
  ThumbnailView,
  Toolbar,
} from "@syncfusion/ej2-react-pdfviewer";
import { PdfViewerOptions } from "@taketurns-components/document/FilePreview/PdfViewer/PdfViewerOptions";
import { useMarkUploadAsComplete } from "@taketurns-repositories/document/state/write/markUploadAsComplete";
import { useMarkUploadAsStarted } from "@taketurns-repositories/document/state/write/markUploadAsStarted";
import { useUploadAttachmentRevisionFromPdfViewerRule } from "@taketurns-rules/collaboration/commands/attachmentRevision/useUploadAttachmentRevisionFromPdfViewerRule";
import { useGetOpenedAttachmentRule } from "@taketurns-rules/collaboration/queries/attachment/useGetOpenedAttachmentRule";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import "./material.min.css";
import { ForwardedRef, forwardRef, useImperativeHandle, useRef, useState } from "react";
import { getEnvironmentVariable } from "../../../../getEnvironmentVariable";

interface PdfViewerProps {
  documentRevisionUrl: string;
  jwtToken: string;
  onPdfUpdatedChange: (updated: boolean) => void;
  enableAnnotations: boolean;
  enableSignatures: boolean;
}

export interface PdfViewerExposedFunction {
  uploadPdfRevision: () => Promise<void>;
  cancelPendingAnnotations: () => void;
}

export const PdfViewer = forwardRef((props: PdfViewerProps, ref: ForwardedRef<PdfViewerExposedFunction>) => {
  let pdfInstance: PdfViewerComponent;
  const markUploadAsStarted = useMarkUploadAsStarted();
  const markUploadAsComplete = useMarkUploadAsComplete();
  const attachment = useGetOpenedAttachmentRule();

  const uploadMethod = async (uploadId: number) => {
    return new Promise<string>((resolve) => {
      pdfInstance.downloadStart = () => {
        markUploadAsStarted(uploadId, attachment.document.id, () => null);
      };
      pdfInstance.downloadEnd = () => {
        markUploadAsComplete(uploadId);
        previousPdfUpdated.current = false;
        props.onPdfUpdatedChange(false);
        resolve(attachment.document.id);
      };
      pdfInstance.download();
    });
  };

  const uploadAttachmentRevision = useUploadAttachmentRevisionFromPdfViewerRule(attachment, uploadMethod);

  const { onPdfUpdatedChange } = props;
  useImperativeHandle(
    ref,
    () => {
      return {
        uploadPdfRevision,
        cancelPendingAnnotations,
      };

      async function uploadPdfRevision() {
        await uploadAttachmentRevision();
      }

      function cancelPendingAnnotations() {
        for (let i = 0; i < updatesCountRef.current; i++) {
          pdfInstance.undo();
        }
        updatesCountRef.current = 0;
        previousPdfUpdated.current = false;
        onPdfUpdatedChange(false);
      }
    },
    [pdfInstance, onPdfUpdatedChange, uploadAttachmentRevision],
  );

  const [currentlyDisplayedPage, setCurrentlyDisplayedPage] = useState(1);
  const [numPages, setNumPages] = useState(null);

  const pdfPageRenderComplete = () => {
    setNumPages(pdfInstance.pageCount);
  };

  const isExistingAnnotationModified = useRef(false);
  const newAnnotationIds = useRef<string[]>([]);
  const newSignatureCount = useRef(0);
  const updatedFormFieldsInitialValueMap = useRef<Map<string, string>>(new Map());
  const updatesCountRef = useRef(0);

  const previousPdfUpdated = useRef(false);
  const dispatchPdfUpdatedChangeWhenDifferent = () => {
    const hasPdfBeenUpdated =
      newAnnotationIds.current.length > 0 ||
      isExistingAnnotationModified.current ||
      newSignatureCount.current > 0 ||
      updatedFormFieldsInitialValueMap.current.size > 0;
    const shouldDispatchPdfUpdatedChange =
      (hasPdfBeenUpdated && !previousPdfUpdated.current) || (!hasPdfBeenUpdated && previousPdfUpdated.current);

    previousPdfUpdated.current = hasPdfBeenUpdated;
    if (shouldDispatchPdfUpdatedChange) {
      props.onPdfUpdatedChange(hasPdfBeenUpdated);
    }
  };

  const pdfDocumentLoaded = () => {
    isExistingAnnotationModified.current = false;
    newAnnotationIds.current = [];
    newSignatureCount.current = 0;
    updatedFormFieldsInitialValueMap.current = new Map();
    updatesCountRef.current = 0;
    pdfInstance.handWrittenSignatureSettings.signatureDialogSettings = {
      displayMode: DisplayMode.Draw | DisplayMode.Text | DisplayMode.Upload,
      hideSaveSignature: true,
    };
    setTimeout(() => {
      const pdfViewerMobileContainer = document.querySelector(".e-control.e-pdfviewer.e-lib") as HTMLDivElement;
      if (pdfViewerMobileContainer) {
        pdfViewerMobileContainer.style.minHeight = "initial";
      }
    });
  };

  const annotationAdded = (args) => {
    newAnnotationIds.current = [...newAnnotationIds.current, args.annotationId];
    dispatchPdfUpdatedChangeWhenDifferent();
    updatesCountRef.current++;
  };

  const annotationRemoved = (args) => {
    if (newAnnotationIds.current.includes(args.annotationId)) {
      newAnnotationIds.current = newAnnotationIds.current.filter((id) => id !== args.annotationId);
    } else {
      isExistingAnnotationModified.current = true;
    }
    dispatchPdfUpdatedChangeWhenDifferent();
    updatesCountRef.current++;
  };

  const annotationModified = (args) => {
    if (newAnnotationIds.current.includes(args.annotationId)) {
      return;
    } else {
      isExistingAnnotationModified.current = true;
      dispatchPdfUpdatedChangeWhenDifferent();
    }
    updatesCountRef.current++;
  };

  const signatureAdded = () => {
    newSignatureCount.current++;
    dispatchPdfUpdatedChangeWhenDifferent();
    updatesCountRef.current++;
  };

  const signatureRemoved = () => {
    newSignatureCount.current--;
    dispatchPdfUpdatedChangeWhenDifferent();
    updatesCountRef.current++;
  };

  const signatureModified = () => {
    updatesCountRef.current++;
  };

  const onFormFieldPropertiesChange = (args) => {
    if (args.isValueChanged) {
      if (
        updatedFormFieldsInitialValueMap.current.has(args.field.id) &&
        updatedFormFieldsInitialValueMap.current.get(args.field.id) === args.oldValue
      ) {
        updatedFormFieldsInitialValueMap.current.delete(args.field.id);
      } else {
        updatedFormFieldsInitialValueMap.current.set(args.field.id, args.isCustomDataChanged);
      }
    }
    dispatchPdfUpdatedChangeWhenDifferent();
    updatesCountRef.current++;
  };

  const onPageChange = ({ currentPageNumber }: { currentPageNumber: number }) => {
    setCurrentlyDisplayedPage(currentPageNumber);
  };

  const sign = () => {
    pdfInstance.annotation.setAnnotationMode("HandWrittenSignature");
    setTimeout(() => {
      (
        document.querySelector(
          ".e-pv-signature-window.e-control.e-dialog.e-lib.e-device.e-pv-signature-dialog-height.e-draggable.e-dlg-modal.e-popup.e-popup-open",
        ) as HTMLDivElement
      ).style.maxHeight = "100%";
    });
  };

  const toggleAnnotationsEdition = () => {
    if (pdfInstance.isAnnotationToolbarVisible) {
      pdfInstance.toolbar.showAnnotationToolbar(false);
    } else {
      pdfInstance.toolbar.showAnnotationToolbar(true);
    }
  };

  const zoomIn = () => {
    pdfInstance.magnification.zoomIn();
  };

  const zoomOut = () => {
    pdfInstance.magnification.zoomOut();
  };

  const goToPage = (number: number) => {
    pdfInstance.navigation.goToPage(number);
    setCurrentlyDisplayedPage(number);
  };

  const pdfViewerBackgroundColor = TakeTurnsColors.white;

  return (
    <Stack
      className="control-section"
      sx={{
        "& .e-pv-sidebar-toolbar": { background: pdfViewerBackgroundColor },
        "& .e-pv-annotation-toolbar": { background: pdfViewerBackgroundColor },
        "& .e-toolbar-items": { background: pdfViewerBackgroundColor },
        "& .e-toolbar-item": { background: pdfViewerBackgroundColor },
        "& .e-tbar-btn": { background: pdfViewerBackgroundColor },
        "& .e-toolbar": { background: pdfViewerBackgroundColor },
        height: "100%",
      }}
    >
      <PdfViewerOptions
        enableSignatures={props.enableSignatures}
        enableAnnotations={props.enableAnnotations}
        sign={sign}
        toggleAnnotationsEdition={toggleAnnotationsEdition}
        selectPageToView={goToPage}
        currentDisplayedPage={currentlyDisplayedPage}
        numPages={numPages}
        zoomIn={zoomIn}
        zoomOut={zoomOut}
      />
      <PdfViewerComponent
        height="100%"
        ref={(instance) => {
          pdfInstance = instance;
        }}
        documentPath={props.documentRevisionUrl}
        serviceUrl={getEnvironmentVariable("PDFVIEWER_URL")}
        ajaxRequestSettings={{
          ajaxHeaders: [
            {
              headerName: "authorization",
              headerValue: `${props.jwtToken}`,
            },
            {
              headerName: "documentId",
              headerValue: `${attachment.id}`,
            },
          ],
        }}
        enableFormFields={true}
        enableToolbar={false}
        pageRenderComplete={pdfPageRenderComplete}
        pageChange={onPageChange}
        documentLoad={pdfDocumentLoaded}
        annotationAdd={annotationAdded}
        annotationRemove={annotationRemoved}
        annotationMove={annotationModified}
        annotationPropertiesChange={annotationModified}
        annotationResize={annotationModified}
        addSignature={signatureAdded}
        removeSignature={signatureRemoved}
        resizeSignature={signatureModified}
        moveSignature={signatureModified}
        formFieldPropertiesChange={onFormFieldPropertiesChange}
        toolbarSettings={{
          showTooltip: true,
          toolbarItems: [],
          annotationToolbarItems: [
            "HighlightTool",
            "UnderlineTool",
            "StrikethroughTool",
            "ColorEditTool",
            "OpacityEditTool",
            "AnnotationDeleteTool",
            "ShapeTool",
            "StrokeColorEditTool",
            "ThicknessEditTool",
            "FreeTextAnnotationTool",
            "FontFamilyAnnotationTool",
            "FontSizeAnnotationTool",
            "FontStylesAnnotationTool",
            "FontAlignAnnotationTool",
            "FontColorAnnotationTool",
          ],
        }}
      >
        <Inject
          services={[
            Toolbar,
            Magnification,
            Navigation,
            Annotation,
            LinkAnnotation,
            BookmarkView,
            ThumbnailView,
            Print,
            TextSelection,
            TextSearch,
            FormFields,
            FormDesigner,
          ]}
        />
      </PdfViewerComponent>
    </Stack>
  );
});
PdfViewer.displayName = "TTPdfViewer";
