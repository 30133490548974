/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useRecoilCallback } from "recoil";
import { PendingCreationAttachment } from "../../../models/pendingCreationAttachment";
import { pendingCreationAttachmentsState } from "../../recoil/collaborationCreation/pendingCreationAttachmentsState";

export const useSetPendingCreationAttachmentDocumentId = () =>
  useRecoilCallback(
    ({ set }) =>
      (pendingCreationAttachment: PendingCreationAttachment, documentId: string) => {
        const updatedPendingCreationAttachment = pendingCreationAttachment.copy();
        updatedPendingCreationAttachment.documentId = documentId;
        set(pendingCreationAttachmentsState, (currentPendingAttachments) => [
          ...currentPendingAttachments.filter(
            (currentPendingCreationAttachment) =>
              currentPendingCreationAttachment.uploadId !== pendingCreationAttachment.uploadId,
          ),
          updatedPendingCreationAttachment,
        ]);
      },
    [],
  );
