/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import {
  useFetchConnectedUserStatus,
} from "@taketurns-repositories/collaboration/graphql/queries/context/useFetchConnectedUserStatus";
import {
  useGetDisplayedCollaborationIdRule,
} from "@taketurns-rules/collaboration/queries/collaboration/useGetDisplayedCollaborationIdRule";

export const useFetchConnectedUserStatusRule = (collaborationId?: string) => {
  const displayedCollaborationId = useGetDisplayedCollaborationIdRule();
  return useFetchConnectedUserStatus(collaborationId ?? displayedCollaborationId);
};
