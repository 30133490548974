/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Accordion, AccordionDetails, AccordionSummary, Box, Chip, styled, Typography } from "@mui/material";
import { ReactNode, useState } from "react";
import { AttachedRevision, FulfilledRequest, Request, UpdatedContent } from "@taketurns/taketurns-graphql-repository";
import { useRecapTranslation } from "@taketurns-i18n/collaboration/Recap/useRecapTranslation";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { IconChevronDown, IconFilePlus, IconFileText, IconRequest, IconReview } from "../../../commons/icons";
import { AttachmentPreviewDialog } from "../AttachmentPreviewDialog";

interface EventContentProps {
  collaborationId: string;
  updatedContent: UpdatedContent;
}

export const EventContent = (props: EventContentProps) => {
  const {
    isAttachmentPreviewDialogOpened,
    openAttachmentPreviewDialog,
    closeAttachmentPreviewDialog,
    attachmentToPreview,
  } = useOpenAttachmentPreviewDialog(props.collaborationId);

  return (
    <UpdateContent__Container>
      {props.updatedContent.updatedFiles.length !== 0 && (
        <UpdatedFiles
          updatedFiles={props.updatedContent.updatedFiles}
          openPreviewDialog={openAttachmentPreviewDialog}
        />
      )}
      {props.updatedContent.fulfilledRequests.length !== 0 && (
        <FulfilledRequests
          fulfilledRequests={props.updatedContent.fulfilledRequests}
          openPreviewDialog={openAttachmentPreviewDialog}
        />
      )}
      {props.updatedContent.addedFiles.length !== 0 && (
        <AddedFiles addedFiles={props.updatedContent.addedFiles} openPreviewDialog={openAttachmentPreviewDialog} />
      )}
      {props.updatedContent.addedRequests.length !== 0 && (
        <AddedRequests addedRequests={props.updatedContent.addedRequests} />
      )}
      <AttachmentPreviewDialog
        open={isAttachmentPreviewDialogOpened}
        close={closeAttachmentPreviewDialog}
        attachment={attachmentToPreview}
      />
    </UpdateContent__Container>
  );
};

const UpdatedFiles = ({
  updatedFiles,
  openPreviewDialog,
}: {
  updatedFiles: AttachedRevision[];
  openPreviewDialog: (documentId: string, revisionId: string) => void;
}) => {
  const { t } = useRecapTranslation();
  const { getDisplayableShowMoreButton, paginatedArray: paginatedUpdatedFiles } = usePaginatedArray(updatedFiles);

  return (
    <Content__Container>
      <ContentChipIndicator
        iconComponent={<IconFileText size={24} color={TakeTurnsColors.darkGray} />}
        title={t("UpdateContent.updatedFiles", { count: updatedFiles.length })}
      />

      {paginatedUpdatedFiles.map((updatedFile) => (
        <ContentChip
          variant={"outlined"}
          key={updatedFile.id}
          label={updatedFile.name}
          onClick={() => openPreviewDialog(updatedFile.attachmentId, updatedFile.id)}
        />
      ))}
      {getDisplayableShowMoreButton()}
    </Content__Container>
  );
};

const FulfilledRequests = ({
  fulfilledRequests,
  openPreviewDialog,
}: {
  fulfilledRequests: FulfilledRequest[];
  openPreviewDialog: (documentId: string, revisionId: string) => void;
}) => {
  const { t } = useRecapTranslation();
  const { getDisplayableShowMoreButton, paginatedArray: paginatedFulfilledRequests } =
    usePaginatedArray(fulfilledRequests);

  return (
    <Content__Container>
      <ContentChipIndicator
        iconComponent={<IconReview size={24} color={TakeTurnsColors.darkGray} />}
        title={t("UpdateContent.fulfillment_one", { count: fulfilledRequests.length })}
      />
      {paginatedFulfilledRequests.map((fulfilledRequest) => (
        <ExpandableChip disableGutters key={fulfilledRequest.id} elevation={0}>
          <ExpandableChipSummary expandIcon={<IconChevronDown />}>{fulfilledRequest.name}</ExpandableChipSummary>
          <ExpandableChipDetail>
            {fulfilledRequest.attachedRevisions.map((attachedRevision) => (
              <ContentChip
                key={attachedRevision.attachmentId}
                variant={"outlined"}
                label={attachedRevision.name}
                onClick={() => openPreviewDialog(attachedRevision.attachmentId, attachedRevision.id)}
              />
            ))}
          </ExpandableChipDetail>
        </ExpandableChip>
      ))}
      {getDisplayableShowMoreButton()}
    </Content__Container>
  );
};

const AddedFiles = ({
  addedFiles,
  openPreviewDialog,
}: {
  addedFiles: AttachedRevision[];
  openPreviewDialog: (documentId: string, revisionId: string) => void;
}) => {
  const { t } = useRecapTranslation();
  const { getDisplayableShowMoreButton, paginatedArray: paginatedAddedFiles } = usePaginatedArray(addedFiles);

  return (
    <Content__Container>
      <ContentChipIndicator
        iconComponent={<IconFilePlus size={24} color={TakeTurnsColors.darkGray} />}
        title={t("UpdateContent.addedFiles", { count: addedFiles.length })}
      />
      {paginatedAddedFiles.map((addedFile) => (
        <ContentChip
          variant={"outlined"}
          key={addedFile.id}
          label={addedFile.name}
          onClick={() => openPreviewDialog(addedFile.attachmentId, addedFile.id)}
        />
      ))}
      {getDisplayableShowMoreButton()}
    </Content__Container>
  );
};

const AddedRequests = ({ addedRequests }: { addedRequests: Request[] }) => {
  const { t } = useRecapTranslation();
  const { getDisplayableShowMoreButton, paginatedArray: paginatedRequests } = usePaginatedArray(addedRequests);

  return (
    <Content__Container>
      <ContentChipIndicator
        iconComponent={<IconRequest size={24} color={TakeTurnsColors.darkGray} />}
        title={t("UpdateContent.addedRequests", { count: addedRequests.length })}
      />
      {paginatedRequests.map((addedRequest) => (
        <ContentChip variant={"outlined"} key={addedRequest.name} label={addedRequest.name} />
      ))}
      {getDisplayableShowMoreButton()}
    </Content__Container>
  );
};

const ContentChipIndicator = ({ iconComponent, title }: { iconComponent: ReactNode; title: string }) => {
  return (
    <ContentLogoAndText__Container>
      {iconComponent}
      <Typography noWrap variant={"body2"} color={TakeTurnsColors.darkGray}>
        {title}
      </Typography>
    </ContentLogoAndText__Container>
  );
};

export interface AttachmentToPreview {
  collaborationId: string;
  documentId: string;
  revisionId: string;
}

const useOpenAttachmentPreviewDialog = (collaborationId: string) => {
  const [attachmentToPreview, setAttachmentToPreview] = useState<AttachmentToPreview>(null);

  const openAttachmentPreviewDialog = (documentId: string, revisionId: string) => {
    setAttachmentToPreview({ collaborationId, documentId, revisionId });
  };

  const closeAttachmentPreviewDialog = () => {
    setAttachmentToPreview(null);
  };

  return {
    isAttachmentPreviewDialogOpened: Boolean(attachmentToPreview),
    openAttachmentPreviewDialog,
    closeAttachmentPreviewDialog,
    attachmentToPreview,
  };
};

function usePaginatedArray<T>(array: Array<T>) {
  const INCREMENT_SIZE = 5;
  const [numberOfItemsToShow, setNumberOfItemsToShow] = useState(INCREMENT_SIZE);
  const { t } = useRecapTranslation();

  const showMore = () => {
    setNumberOfItemsToShow(numberOfItemsToShow + INCREMENT_SIZE);
  };

  const getDisplayableShowMoreButton = () => {
    if (numberOfItemsToShow >= array.length) {
      return;
    }
    return <MoreTextButton onClick={showMore}>{t("UpdateContent.more")}</MoreTextButton>;
  };

  if (numberOfItemsToShow >= array.length) {
    return { getDisplayableShowMoreButton, paginatedArray: array };
  }

  return { getDisplayableShowMoreButton, paginatedArray: array.slice(0, numberOfItemsToShow) };
}

const UpdateContent__Container = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: 12,
});

const Content__Container = styled(Box)({
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  gap: "12px",
});

const ContentLogoAndText__Container = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "8px",
});

const ContentChip = styled(Chip)({
  border: `1px solid ${TakeTurnsColors.lightGray}`,
  borderRadius: 10,
  display: "flex",
  height: "auto",
  maxWidth: "11.25rem",
  "& .MuiChip-label": { overflowWrap: "break-word", whiteSpace: "nowrap", textOverflow: "ellipsis" },
});

const ExpandableChip = styled(Accordion)({
  border: `1px solid ${TakeTurnsColors.lightGray}`,
  borderRadius: "10px !important",
  padding: "0 8px",
  fontSize: "0.8rem",
  "&:before": {
    display: "none",
  },
});

const ExpandableChipSummary = styled(AccordionSummary)({
  padding: 0,
  margin: 0,
  minHeight: 0,
  "& .MuiAccordionSummary-content": {
    margin: 0,
  },
  "& .MuiAccordionSummary-expandIconWrapper": {
    marginLeft: 12,
  },
});

const ExpandableChipDetail = styled(AccordionDetails)({
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  gap: "12px",
  padding: "12px 0",
});

const MoreTextButton = styled("button")({
  padding: 0,
  margin: 0,
  color: TakeTurnsColors.lightBlue,
  border: 0,
  background: "unset",
  "&:hover": {
    cursor: "pointer",
    opacity: 0.8,
  },
});
