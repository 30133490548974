/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Box, Divider, Stack } from "@mui/material";
import { Party } from "@taketurns/taketurns-graphql-repository";
import { CollaborationTitle } from "@taketurns-components/collaboration/Shared/CollaborationTitle";
import { ExternalLinkStyled } from "@taketurns-components/webapp/LinkStyled";
import { useWebAppTranslations } from "@taketurns-i18n/webapp/useWebAppTranslations";
import { HelpCenterSections } from "@taketurns-rules/commons/enum/helpCenterSectionsEnum";
import { useIsOnMobileRule } from "@taketurns-rules/commons/theme/useIsOnMobileRule";
import { useOpenHelpCenterSection } from "@taketurns-rules/webapp/commands/useOpenHelpCenterSection";
import { useDecodeParamsInCurrentUrlRule } from "@taketurns-rules/webapp/queries/useDecodeParamsInCurrentUrlRule";
import { SingleColumnLayout } from "../../../layouts/body/content/SingleColumnLayout";
import { PasswordlessCollaborationAccessTitle } from "./components/PasswordlessCollaborationAccessTitle";
import { SendOrVerifyCodeSection } from "./components/SendOrVerifyCodeSection";
import { MobilePasswordlessAccess } from "./MobilePasswordlessAccess";

export interface PasswordlessCollaborationAccessParameters {
  isInvitationLink: boolean;
  recipient: {
    email: string;
    party: Party;
  };
  invitingUser: {
    firstName?: string;
    lastName?: string;
    email: string;
    party: Party;
    avatar?: string;
  };
  collaboration: {
    invitedPartyName: string;
    name: string;
    owningPartyName: string;
  };
}

export const PasswordlessCollaborationAccess = () => {
  const {
    collaboration,
    recipient: { party },
  } = useDecodeParamsInCurrentUrlRule<PasswordlessCollaborationAccessParameters>()!;
  const isOnMobile = useIsOnMobileRule();

  if (isOnMobile) {
    return <MobilePasswordlessAccess />;
  }

  return (
    <SingleColumnLayout
      wideMargin
      header={<PasswordlessCollaborationAccessHeader />}
      content={
        <Stack spacing={3} direction="column" alignItems="center" justifyContent={"space-between"} height="100%">
          <Box width={"100%"}>
            <CollaborationTitle
              owningPartyName={collaboration.owningPartyName}
              invitedPartyName={collaboration.invitedPartyName}
              name={collaboration.name}
              userParty={party}
            />
          </Box>
          <Divider sx={{ width: "100%" }} />
          <SendOrVerifyCodeSection />
        </Stack>
      }
    />
  );
};

const PasswordlessCollaborationAccessHeader = () => {
  const { t } = useWebAppTranslations("passwordlessCollaborationAccess");
  const { openHelpCenterSection } = useOpenHelpCenterSection(HelpCenterSections.WHAT_IS_TAKETURNS);
  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
      <PasswordlessCollaborationAccessTitle />
      <ExternalLinkStyled onClick={openHelpCenterSection} target="_blank">
        {t("whatIsTakeTurns")}
      </ExternalLinkStyled>
    </Stack>
  );
};
