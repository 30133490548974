/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useRenameRequest } from "@taketurns-repositories/collaboration/graphql/mutations/content/requests/useRenameRequest";
import { useGetDisplayedCollaborationIdRule } from "@taketurns-rules/collaboration/queries/collaboration/useGetDisplayedCollaborationIdRule";

export const useRenameRequestRule = (requestId: string) => {
  const collaborationId = useGetDisplayedCollaborationIdRule();

  const { renameRequest, renameRequestResponseData, renameRequestLoading, errorOnRenamingRequest } = useRenameRequest(
    collaborationId,
    requestId,
  );
  return {
    renameRequest,
    renameRequestResponseData: renameRequestResponseData,
    renameRequestLoading: renameRequestLoading,
    errorOnRenamingRequest: errorOnRenamingRequest,
  };
};
