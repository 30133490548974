/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { CollaborationStatus } from "@taketurns/taketurns-graphql-repository";
import { useFetchCollaborationStatus } from "@taketurns-repositories/collaboration/graphql/queries/fields/useFetchCollaborationStatus";
import { useGetDisplayedCollaborationIdRule } from "@taketurns-rules/collaboration/queries/collaboration/useGetDisplayedCollaborationIdRule";

export const useIsCollaborationClosedRule = (collaborationId?: string) => {
  const displayedCollaborationId = useGetDisplayedCollaborationIdRule();
  const { status, loadingCollaborationStatus, errorOnFetchingCollaborationStatus } = useFetchCollaborationStatus(
    collaborationId ?? displayedCollaborationId,
  );
  return {
    isCollaborationClosed: Boolean(status === CollaborationStatus.Closed),
    loadingCollaborationStatus,
    errorOnFetchingCollaborationStatus,
  };
};
