/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Stack } from "@mui/material";
import { Fragment } from "react";
import { ContentStatus, Folder } from "@taketurns/taketurns-graphql-repository";
import { FolderIcon } from "@taketurns-components/collaboration/CollaborationView/CollaborationContent/ListView/FolderListItem/FolderIcon";
import { FolderStats } from "@taketurns-components/collaboration/CollaborationView/CollaborationContent/ListView/FolderListItem/FolderStats";
import { ContentElementLastUpdate } from "@taketurns-components/collaboration/CollaborationView/CollaborationContent/shared/components/ContentElementLastUpdate";
import { ContextMenuOnButton } from "@taketurns-components/collaboration/ContextMenu/ContextMenuOnButton";
import { FolderUpdatesMarker, ReviewListItemChip } from "@taketurns-components/collaboration/Shared/content/Markers";
import { MobileListItem } from "@taketurns-components/collaboration/Shared/list/MobileListItem";
import { useGetFolderContextMenuConfigurationRule } from "@taketurns-rules/collaboration/queries/folder/useGetFolderContextMenuConfigurationRule";
import { ListItemTitle } from "../ListItemTitle";

interface MobileFolderListItemProps {
  folder: Folder;
  isFolderRemoved: boolean;
  shouldDisplayLastUpdate: boolean;
  shouldDisplayUpdatesMarker: boolean;
  lastUpdateStatus: ContentStatus;
  shouldDisplayReviewChip: boolean;
  hasFolderBeenRemovedInPreviousTurn: boolean;
  moveIntoListItemFolder: () => void;
  contextMenuConfiguration: ReturnType<typeof useGetFolderContextMenuConfigurationRule> | null;
  backgroundColor: string;
}

export const MobileFolderListItem = (props: MobileFolderListItemProps) => (
  <MobileListItem
    centerRightEndSlotVertically
    dataCy={`MobileFolderListItem.${props.folder.id}`}
    sx={{ backgroundColor: props.backgroundColor }}
    titleSlot={
      <Stack direction="row" alignItems={"center"} spacing={1} overflow="hidden">
        <FolderIcon />
        <ListItemTitle title={props.folder.name} shouldStrikeTitle={props.isFolderRemoved} />
      </Stack>
    }
    subtitleSlot={
      <Fragment>
        <FolderStats folder={props.folder} />
        {props.shouldDisplayUpdatesMarker && <FolderUpdatesMarker folder={props.folder} />}
        {props.shouldDisplayLastUpdate && <ContentElementLastUpdate status={props.lastUpdateStatus} />}
      </Fragment>
    }
    rightEndSlot={
      <Stack direction="row" alignItems="center">
        {props.shouldDisplayReviewChip && <ReviewListItemChip />}
        {props.contextMenuConfiguration && (
          <ContextMenuOnButton contextMenuConfiguration={props.contextMenuConfiguration} />
        )}
      </Stack>
    }
    onClick={props.hasFolderBeenRemovedInPreviousTurn ? undefined : props.moveIntoListItemFolder}
  />
);
