/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Box, styled } from "@mui/material";
import { ReactNode } from "react";
import { BodyLayout } from "../BodyLayout";

interface TwoColumnsLayoutProps {
  header: ReactNode;
  backAction: () => void;
  leftColumn?: ReactNode;
  content: ReactNode;
  collapsableRightBar?: ReactNode;
}

export const TwoColumnsLayout = ({
  header,
  backAction,
  leftColumn,
  content,
  collapsableRightBar,
}: TwoColumnsLayoutProps) => {
  const Content = () => (
    <TwoColumnsLayoutContainer className="TwoColumnsLayout">
      {leftColumn && <LeftColumn className="TwoColumnsLayout__LeftColumn">{leftColumn}</LeftColumn>}
      <ContentBox className="TwoColumnsLayout__ContentBox">{content}</ContentBox>
    </TwoColumnsLayoutContainer>
  );
  return (
    <BodyLayout
      header={header}
      backAction={backAction}
      content={<Content />}
      collapsableRightBar={collapsableRightBar}
    />
  );
};

const TwoColumnsLayoutContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  gap: 24,
  height: "100%",
});

const BackgroundPaper = styled(Box)({
  boxShadow: "0px 1px 20px 0px rgba(0, 0, 0, 0.05)",
  borderTopLeftRadius: 20,
  borderTopRightRadius: 20,
  backgroundColor: "white",
  height: "100%",
});

const LeftColumn = styled(BackgroundPaper)({
  flex: "0 0 240px",
  padding: 0,
  overflow: "hidden",
});

const ContentBox = styled(BackgroundPaper)({
  flex: 1,
  minWidth: 0,
});
