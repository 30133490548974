/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Stack, Typography } from "@mui/material";
import { useUserPlanTranslation } from "@taketurns-i18n/user/UserPlans/useUserPlanTranslation";
import { useIsOnMobileRule } from "@taketurns-rules/commons/theme/useIsOnMobileRule";
import { useNavigateToPlansPageRule } from "@taketurns-rules/user/commands/useNavigateToPlansPageRule";
import { TakeTurnsDefaultButton } from "../../../commons/button";

export const FreePlanBillingPortal = () => {
  const isOnMobile = useIsOnMobileRule();
  const { t } = useUserPlanTranslation();
  const navigateToPlansPage = useNavigateToPlansPageRule();

  return (
    <Stack direction="column" spacing={2.5}>
      {!isOnMobile && <Typography fontSize={16}>{t("billingPortal.manageYourPlan")}</Typography>}
      <Typography>{t("billingPortal.freeSomething")}</Typography>
      <TakeTurnsDefaultButton sx={{ alignSelf: { xs: "center", md: "flex-start" } }} onClick={navigateToPlansPage}>
        {t("billingPortal.seePlansAndPricing")}
      </TakeTurnsDefaultButton>
    </Stack>
  );
};
