/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useFetchUserOrganizationRule } from "./useFetchUserOrganizationRule";

export const useFetchIsUserOrganizationHasNameRule = () => {
  const { userOrganization, loadingUserOrganization, errorOnLoadingUserOrganization } = useFetchUserOrganizationRule();
  return {
    isUserOrganizationHasName: !!userOrganization?.name,
    loadingIsUserOrganizationHasName: loadingUserOrganization,
    errorOnLoadingIsUserOrganizationHasName: errorOnLoadingUserOrganization,
  };
};
