/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useMutation } from "@apollo/client";
import { MutationResendInvitationArgs, ResendInvitationInput } from "@taketurns/taketurns-graphql-repository";

export const useResendInvitation = () => {
  const mutation = gql`
    mutation resendInvitation($input: ResendInvitationInput!) {
      resendInvitation(input: $input)
    }
  `;
  const [resendInvitationMutation, { error: errorOnResendInvitation, loading: loadingResendInvitation }] = useMutation<
    Record<string, never>,
    MutationResendInvitationArgs
  >(mutation);
  const resendInvitationCommand = (input: ResendInvitationInput, onResendInvitationSuccess?: () => void) => {
    resendInvitationMutation({ variables: { input }, onCompleted: onResendInvitationSuccess });
  };

  return { resendInvitationCommand, errorOnResendInvitation, loadingResendInvitation };
};
