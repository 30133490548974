/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql } from "@apollo/client";
import { MoveOutput, SubscriptionOnMoveAttachmentFromSourceFolderArgs } from "@taketurns/taketurns-graphql-repository";
import { useRefreshableSubscription } from "@taketurns-components/webapp/QueriesAndSubscriptionsRefreshTrigger";
import { moveAttachmentMutationFragment } from "@taketurns-repositories/collaboration/graphql/mutations/content/attachments/useMoveAttachmentIntoFolderMutation";

const SUBSCRIBE_TO_MOVE_ATTACHMENT_FROM_SOURCE_FOLDER = gql`
  subscription onMoveAttachmentFromSourceFolder($collaborationId: ID!, $partyId: ID!, $sourceFolderId: ID!) {
    onMoveAttachmentFromSourceFolder(
      collaborationId: $collaborationId
      partyId: $partyId
      sourceFolderId: $sourceFolderId
    ) {
      ...MoveAttachmentMutationFragment
    }
  }
  ${moveAttachmentMutationFragment}
`;

export const useSubscribeToMoveAttachmentFromSourceFolder = (
  args: SubscriptionOnMoveAttachmentFromSourceFolderArgs,
) => {
  return useRefreshableSubscription<
    { onMoveAttachmentFromSourceFolder: MoveOutput },
    SubscriptionOnMoveAttachmentFromSourceFolderArgs
  >(SUBSCRIBE_TO_MOVE_ATTACHMENT_FROM_SOURCE_FOLDER, { variables: args });
};
