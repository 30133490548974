/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { gql, useLazyQuery } from "@apollo/client";

const HAS_USER_ORGANIZATION_ENOUGH_SPACE_AVAILABLE = gql`
  query hasUserOrganizationEnoughSpaceAvailable($neededSpaceInBytes: Int!) {
    hasUserOrganizationEnoughSpaceAvailable(neededSpaceInBytes: $neededSpaceInBytes)
  }
`;

export function useFetchHasUserOrganizationEnoughSpaceAvailable(): (neededSpaceInBytes: number) => Promise<boolean> {
  const [hasUserOrganizationEnoughSpaceAvailable] = useLazyQuery<{ hasUserOrganizationEnoughSpaceAvailable: boolean }>(
    HAS_USER_ORGANIZATION_ENOUGH_SPACE_AVAILABLE,
    {
      fetchPolicy: "no-cache",
    },
  );
  return async (neededSpaceInBytes: number): Promise<boolean> => {
    const queryResult = await hasUserOrganizationEnoughSpaceAvailable({ variables: { neededSpaceInBytes } });
    return queryResult.data.hasUserOrganizationEnoughSpaceAvailable;
  };
}
