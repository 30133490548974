/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import i18n from "@taketurns-i18n/i18n";
import { defaultWelcomeDialogUrl } from "@taketurns-repositories/webapp/environmentVariables/defaultWelcomeDialogUrl";
import { LANGUAGE_PARAMETER } from "@taketurns-repositories/webapp/environmentVariables/helpCenterUrl";

export const getDefaultWelcomeDialogUrlRule = () => {
  return defaultWelcomeDialogUrl.replace(LANGUAGE_PARAMETER, i18n.language);
};
