/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { ClickAwayListener, IconButton, InputAdornment, Skeleton, Stack, styled, TextField } from "@mui/material";
import { KeyboardEvent, useEffect, useRef, useState } from "react";
import { TEXT_FIELD_MAX_LENGTH } from "@taketurns-rules/commons/inputConstants";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { IconEdit3, IconXCircle } from "../icons";
import { TakeTurnsTruncatedTypography } from "../message/TakeTurnsTruncatedTypography";

interface LabelWithEditButtonProps {
  currentLabel: string;
  onEditEnded: (newValue: string) => void;
  disabled?: boolean;
  fontSize?: string;
  loading?: boolean;
  rows?: number;
  maxLength?: number;
  required?: boolean;
  displayEdit?: boolean;
  noFocusOnFirstMount?: boolean;
  hideValuePart?: boolean;
}

export const LabelWithEditButton = (props: LabelWithEditButtonProps) => {
  const fontSize = props.fontSize === undefined ? "0.875rem" : props.fontSize;
  const maxLength = props.maxLength === undefined ? TEXT_FIELD_MAX_LENGTH : props.maxLength;
  const displayEdit = props.displayEdit === undefined ? false : props.displayEdit;
  const noFocusOnFirstMount = props.noFocusOnFirstMount === undefined ? false : props.noFocusOnFirstMount;
  const [edit, setEdit] = useState(displayEdit);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const isFirstMountRef = useRef(true);
  useEffect(() => {
    if (edit) {
      // Focus might not seem to work correctly on first mount in development, but it does. StrictMode is the cause.
      if (isFirstMountRef.current) {
        if (noFocusOnFirstMount) {
          isFirstMountRef.current = false;
          return;
        }
        isFirstMountRef.current = false;
      }
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, [edit, noFocusOnFirstMount]);

  if (props.loading) {
    return <Skeleton width={200} />;
  }

  const saveNewLabel = () => {
    const newLabel = inputRef.current?.value;
    if (!props.required || (newLabel && newLabel !== props.currentLabel)) {
      props.onEditEnded(newLabel ?? "");
    }
  };

  // canCloseEdit cannot be a const, because we need the value of inputRef.current?.value at the time of the call,
  // and not at the time of the render (which would lead to inputRef.current being null).
  const canCloseEdit = () => !props.required || !!(props.required && inputRef.current?.value);
  const saveAndCloseEdit = () => {
    if (canCloseEdit()) {
      saveNewLabel();
      setEdit(false);
    }
  };

  const handleKeyPress = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Escape" && canCloseEdit()) {
      setEdit(false);
    }
    if (event.code === "Enter") {
      saveAndCloseEdit();
    }
  };

  if (edit) {
    return (
      <ClickAwayListener onClickAway={() => saveAndCloseEdit()}>
        <LabelWithEditButton__TextField
          multiline={props.rows && props.rows > 1}
          rows={props.rows}
          inputProps={{
            maxLength,
            style: { fontSize, lineHeight: fontSize },
            "data-cy": "LabelWithEditButton__textField",
          }}
          inputRef={inputRef}
          onKeyDown={handleKeyPress}
          defaultValue={props.currentLabel}
          type="text"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => (inputRef.current!.value = "")} size="small" disableRipple>
                  <IconXCircle color={TakeTurnsColors.grayButton} size={fontSize} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </ClickAwayListener>
    );
  }

  return (
    <Stack flex={1} direction={"row"} alignItems="center" spacing={1}>
      {props.currentLabel && (
        <TakeTurnsTruncatedTypography fontSize={fontSize} lineHeight={fontSize}>
          {props.hideValuePart ? getValueWithHiddenPart(props.currentLabel) : props.currentLabel}
        </TakeTurnsTruncatedTypography>
      )}
      {!props.disabled && (
        <IconButton
          disableRipple
          onClick={() => setEdit(true)}
          sx={{ padding: 0 }}
          data-cy="LabelWithEditButton__editIcon"
        >
          <IconEdit3 color={TakeTurnsColors.grayButton} size={fontSize} />
        </IconButton>
      )}
    </Stack>
  );
};

const LabelWithEditButton__TextField = styled(TextField)(({ theme, inputProps }) => ({
  marginTop: "1.5px",
  maxWidth: inputProps?.maxLength ? `${inputProps.maxLength}ch` : "none",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

function getValueWithHiddenPart(value: string): string {
  return value ? value.substring(0, 2) + "..." + value.substring(value.length - 4) : "";
}
