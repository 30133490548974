/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Checkbox, Divider, FormControlLabel, Typography } from "@mui/material";
import { CollaborationParties } from "@taketurns-components/collaboration/CollaborationView/CollaborationParties";
import { CollaborationDialog } from "@taketurns-components/collaboration/Shared/dialog/CollaborationDialog";
import { DefaultDialogFooter } from "@taketurns-components/collaboration/Shared/dialog/DefaultDialogFooter";
import { DefaultDialogTitle } from "@taketurns-components/collaboration/Shared/dialog/DefaultDialogTitle";
import { DialogContentWithRichTextField } from "@taketurns-components/collaboration/Shared/dialog/DialogContentWithRichTextField";
import { IconSend } from "@taketurns-components/commons/icons";
import { useSharedCollaborationTranslation } from "@taketurns-i18n/collaboration/shared/useSharedCollaborationTranslation";
import { TIP_KEYS } from "@taketurns-i18n/commons/TIP_KEYS";
import { useRecallUpdatesRule } from "@taketurns-rules/collaboration/commands/collaborationView/updates/useRecallUpdatesRule";
import { HelpCenterSections } from "@taketurns-rules/commons/enum/helpCenterSectionsEnum";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";

interface RecallUpdatesDialogProps {
  open: boolean;
  onClose: () => void;
  collaborationId: string;
}

export const RecallUpdatesDialog = (props: RecallUpdatesDialogProps) => {
  const { t } = useSharedCollaborationTranslation();

  const {
    recallUpdatesCommand,
    recallUpdatesResult,
    setRecallNote,
    setRecallUpdatesConfirmed,
    recallUpdatesConfirmed,
  } = useRecallUpdatesRule();

  return (
    <CollaborationDialog open={props.open} onClose={props.onClose}>
      <DefaultDialogTitle title={t("RecallUpdates.dialogTitle")} tipKey={TIP_KEYS.RECALL_TURN} />
      <Divider />
      <DialogContentWithRichTextField onChange={(comment) => setRecallNote(comment)}>
        <AskUserToAcceptLastTurnUpdatesRemoval
          recallUpdatedConfirmed={recallUpdatesConfirmed}
          setRecallUpdatesConfirmed={setRecallUpdatesConfirmed}
        />
        <CollaborationParties
          prefixText={t("DefaultActionWithNoteDialog.addNoteMessage")}
          collaborationId={props.collaborationId}
        />
      </DialogContentWithRichTextField>
      <DefaultDialogFooter
        closeDialog={props.onClose}
        actionButtonEnabled={recallUpdatesConfirmed}
        actionButtonLabel={t(`RecallUpdates.dialogActionButton`)}
        executeAction={recallUpdatesCommand}
        actionButtonIcon={<IconSend size={16} />}
        actionExecutionLoading={recallUpdatesResult.loading}
        helpCenterSection={HelpCenterSections.RECALL_UPDATES}
        hasCancelAction
      />
    </CollaborationDialog>
  );
};

interface AskUserToAcceptLastTurnUpdatesRemovalProps {
  recallUpdatedConfirmed: boolean;
  setRecallUpdatesConfirmed: (hasConfirmedToCancelUpdates: boolean) => void;
}

const AskUserToAcceptLastTurnUpdatesRemoval = (props: AskUserToAcceptLastTurnUpdatesRemovalProps) => {
  const { t } = useSharedCollaborationTranslation();

  return (
    <FormControlLabel
      label={
        <Typography variant="body2" textAlign="justify">
          {t("RecallUpdates.description")}
        </Typography>
      }
      control={
        <Checkbox
          disableRipple
          disableFocusRipple
          checked={props.recallUpdatedConfirmed}
          onClick={() => props.setRecallUpdatesConfirmed(!props.recallUpdatedConfirmed)}
          size="small"
          style={{
            color: TakeTurnsColors.darkGray,
          }}
        />
      }
      sx={{ marginBottom: { xs: 1, md: 0 } }}
    />
  );
};
