/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useMutation } from "@apollo/client";
import { AddAttachmentOutput, MutationAddAttachmentArgs } from "@taketurns/taketurns-graphql-repository";
import { updateCacheOnAttachmentAdded } from "@taketurns-repositories/collaboration/graphql/cache/updateCacheOnAttachmentAdded";
import { FolderFragment } from "@taketurns-repositories/collaboration/graphql/queries/folder/useFetchFolder";
import { PendingAttachment } from "@taketurns-repositories/collaboration/models/pendingAttachment";
import { subFolderFragment } from "../../../fragments/subFolderFragment";

export const addAttachmentMutationFragment = gql`
  ${FolderFragment}
  ${subFolderFragment}
  fragment AddAttachmentMutationFragment on AddAttachmentOutput {
    userId
    collaborationId
    partyId
    folderId
    folder {
      ...FolderFragment
      folders {
        ...SubFolderFragment
      }
    }
    document {
      id
      name
      revisions {
        revisionId
        size
        extension
        uploadDate
        uploadingUserId
      }
    }
    contentMetadata {
      id
      hasPendingUpdates
      todoCount
      requestToFulfillCount
      hasNoContent
    }
  }
`;
export const useAddAttachment = () => {
  const ADD_ATTACHMENT_MUTATION = gql`
    ${addAttachmentMutationFragment}
    mutation addAttachment($input: AddAttachmentInput!) {
      addAttachment(input: $input) {
        ...AddAttachmentMutationFragment
      }
    }
  `;

  const [addAttachmentMutation, { data, loading, error, client }] = useMutation<
    { addAttachment: AddAttachmentOutput },
    MutationAddAttachmentArgs
  >(ADD_ATTACHMENT_MUTATION);
  const addAttachment = (pendingAttachment: PendingAttachment) => {
    const { status, uploadId, documentSize, ...addAttachmentInput } = pendingAttachment;
    return addAttachmentMutation({
      variables: {
        input: {
          ...addAttachmentInput,
          documentId: pendingAttachment.documentId!,
        },
      },
      fetchPolicy: "no-cache",
      onCompleted: ({ addAttachment }, clientOptions) => {
        updateCacheOnAttachmentAdded(client.cache, addAttachment);
      },
    });
  };

  return {
    addAttachment,
    addAttachmentResponseData: data?.addAttachment,
    addAttachmentLoading: loading,
    errorOnAddingAttachment: error,
  };
};
