/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useSnackbar } from "notistack";
import { useUserSharedTranslation } from "@taketurns-i18n/user/useUserSharedTranslation";
import { useSetAddOrRemovePreferredCollaboration } from "@taketurns-repositories/user/state/write/useSetAddOrRemovePreferredCollaboration";
import { useDisplayNotificationFailureRule } from "@taketurns-rules/user/commands/useDisplayNotificationFailureRule";

export const useAddOrRemoveToFavoriteRule = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useUserSharedTranslation();
  const { addOrRemoveToFavoriteCommand, errorOnAddOrRemoveToFavorite, loadingAddOrRemoveToFavorite } =
    useSetAddOrRemovePreferredCollaboration();
  useDisplayNotificationFailureRule();

  if (errorOnAddOrRemoveToFavorite) {
    console.error(errorOnAddOrRemoveToFavorite);
    enqueueSnackbar(t("UserPreferences.error"), { variant: "error" });
  }

  return { addOrRemoveToFavoriteCommand, loadingAddOrRemoveToFavorite };
};
