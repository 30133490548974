/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { DialogContentText, IconButton, Stack, Typography } from "@mui/material";
import React, { Fragment, useState } from "react";
import { OrganizationDomain } from "@taketurns/taketurns-graphql-repository";
import { DefaultDialogFooter } from "@taketurns-components/collaboration/Shared/dialog/DefaultDialogFooter";
import { ResponsiveDialog } from "@taketurns-components/commons/dialog/ResponsiveDialog";
import { IconCheck, IconClipboard } from "@taketurns-components/commons/icons";
import { useOrganizationTranslation } from "@taketurns-i18n/user/Organization/useOrganizationTranslation";
import { useDisplayNotificationSuccessRule } from "@taketurns-rules/user/commands/useDisplayNotificationSuccessRule";
import { useVerifyOrganizationDomainRule } from "@taketurns-rules/user/commands/useVerifyOrganizationDomainRule";

export const DomainVerifyButtonAndDialog = ({ domain }: { domain: OrganizationDomain }) => {
  const [isConfirmationDialogOpened, setIsConfirmationDialogOpened] = useState(false);

  const openDomainVerifyConfirmationDialog = () => {
    setIsConfirmationDialogOpened(true);
  };

  const closeDomainVerifyConfirmationDialog = () => {
    setIsConfirmationDialogOpened(false);
  };
  return (
    <Fragment>
      <IconButton onClick={openDomainVerifyConfirmationDialog} data-cy={`DomainVerifyButton__${domain.name}`}>
        <IconCheck size={18} />
      </IconButton>
      <DomainVerifyConfirmationDialog
        domain={domain}
        open={isConfirmationDialogOpened}
        onClose={closeDomainVerifyConfirmationDialog}
      />
    </Fragment>
  );
};

const DomainVerifyConfirmationDialog = (props: { domain: OrganizationDomain; open: boolean; onClose: () => void }) => {
  const { verifyOrganizationDomain, loadingVerifyOrganizationDomain } = useVerifyOrganizationDomainRule();

  const displayNotificationSuccess = useDisplayNotificationSuccessRule();

  const confirmVerifyOrganizationDomain = () => {
    verifyOrganizationDomain({
      verifyOrganizationDomainInput: {
        name: props.domain.name,
      },
    });
  };

  const txtRecord = "taketurns-verification-code=" + props.domain.verificationCode;

  const copyTxtRecord = () => {
    navigator.clipboard
      .writeText(txtRecord)
      .then(() => displayNotificationSuccess(t("DomainVerifyButtonAndDialog.txtRecordCopied")));
  };

  const { t } = useOrganizationTranslation();

  return (
    <ResponsiveDialog
      open={props.open}
      onClose={props.onClose}
      title={t("DomainVerifyButtonAndDialog.title", { name: props.domain.name })}
      data-cy={`DomainVerifyDialog__${props.domain.name}`}
      content={
        <DialogContentText>
          <Stack spacing={2}>
            <Typography>
              {t("DomainVerifyButtonAndDialog.addRecordDescription", { name: props.domain.name })}
            </Typography>
            <Stack direction="row">
              <Typography fontFamily="monospace" data-cy={`DomainVerifyCode__${props.domain.name}`}>
                {txtRecord}
              </Typography>
              <IconButton>
                <IconClipboard size={18} onClick={copyTxtRecord} />
              </IconButton>
            </Stack>
          </Stack>
        </DialogContentText>
      }
      footer={
        <DefaultDialogFooter
          actionButtonLabel={t("DomainVerifyButtonAndDialog.confirm")}
          actionButtonEnabled={true}
          executeAction={confirmVerifyOrganizationDomain}
          actionExecutionLoading={loadingVerifyOrganizationDomain}
          hasCancelAction={true}
          closeDialog={props.onClose}
        />
      }
    />
  );
};
