/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Stack } from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useIsOnMobileRule } from "@taketurns-rules/commons/theme/useIsOnMobileRule";
import { PageTitle, useSetDocumentTitleRule } from "@taketurns-rules/webapp/commands/useSetDocumentTitleRule";
import { useSetPlansHaveBeenSeenRule } from "@taketurns-rules/webapp/commands/useSetPlansHaveBeenSeenRule";
import { SubscriptionPlans } from "../../../components/user/UserPlans/SubscriptionPlans/SubscriptionPlans";
import { PublicSubscriptionPlansHeader } from "../../../components/user/UserPlans/SubscriptionPlansHeader/PublicSubscriptionPlansHeader";
import { PublicSubscriptionPlansPageTitle } from "../../../components/user/UserPlans/SubscriptionPlansHeader/PublicSubscriptionPlansPageTitle";
import { PlansLayout } from "../../layouts/PlansLayout";
import { useAuthContext } from "../../routing/AuthProvider";
import { AUTHENTICATED_ROUTES } from "../../routing/routes/authenticatedRoutes.constants";

export const Plans = () => {
  const isOnMobile = useIsOnMobileRule();
  useSetDocumentTitleRule(PageTitle.PLANS);

  const setPlansHaveBeenSeen = useSetPlansHaveBeenSeenRule();
  useEffect(() => {
    setPlansHaveBeenSeen();
  }, [setPlansHaveBeenSeen]);

  const { isAuthenticated } = useAuthContext();
  const navigate = useNavigate();
  useEffect(() => {
    if (isAuthenticated) {
      navigate(AUTHENTICATED_ROUTES.PLANS);
    }
  }, [isAuthenticated, navigate]);

  const Header = isOnMobile ? MobilePublicPlansHeader : PublicSubscriptionPlansHeader;

  return <PlansLayout header={<Header />} content={<SubscriptionPlans />} />;
};

const MobilePublicPlansHeader = () => {
  return (
    <Stack spacing={2} alignItems={"center"}>
      <PublicSubscriptionPlansPageTitle />
      <PublicSubscriptionPlansHeader />
    </Stack>
  );
};
