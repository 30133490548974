/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useSetRecoilState } from "recoil";
import { attachmentByIdState } from "../../../recoil/collaborationCreation/attachmentByIdState";

export const useUnmarkAttachmentForReview = (attachmentId: string) => {
  const setUnmarkAttachmentForReview = useSetRecoilState(attachmentByIdState(attachmentId));
  return () => {
    setUnmarkAttachmentForReview((attachment) => ({ ...attachment, markedForReview: false }));
  };
};
