/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useMutation } from "@apollo/client";
import {
  MutationVerifyOrganizationDomainArgs,
  Organization,
  VerifyOrganizationDomainInput,
} from "@taketurns/taketurns-graphql-repository";

export const useVerifyOrganizationDomain = () => {
  const mutation = gql`
    mutation verifyOrganizationDomain($verifyOrganizationDomainInput: VerifyOrganizationDomainInput!) {
      verifyOrganizationDomain(verifyOrganizationDomainInput: $verifyOrganizationDomainInput) {
        id
        domains {
          name
          status
          verificationCode
        }
      }
    }
  `;
  const [
    verifyOrganizationDomainMutation,
    { error: errorOnVerifyOrganizationDomain, loading: loadingVerifyOrganizationDomain },
  ] = useMutation<
    { verifyOrganizationDomain: Pick<Organization, "id" | "domains"> },
    MutationVerifyOrganizationDomainArgs
  >(mutation);
  const verifyOrganizationDomain = (verifyOrganizationDomainInput: VerifyOrganizationDomainInput) =>
    verifyOrganizationDomainMutation({ variables: { verifyOrganizationDomainInput } }).then((value) =>
      value.data.verifyOrganizationDomain.domains.find((domain) => domain.name === verifyOrganizationDomainInput.name),
    );

  return { verifyOrganizationDomain, errorOnVerifyOrganizationDomain, loadingVerifyOrganizationDomain };
};
