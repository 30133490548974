/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Typography } from "@mui/material";

export const ListItemTitle = ({ title, shouldStrikeTitle }: { title: string; shouldStrikeTitle?: boolean }) => {
  return (
    <Typography variant="body2" noWrap sx={shouldStrikeTitle ? { textDecoration: "line-through" } : {}}>
      {title}
    </Typography>
  );
};
