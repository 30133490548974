/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useSharedCollaborationTranslation } from "@taketurns-i18n/collaboration/shared/useSharedCollaborationTranslation";
import { useFetchLastUpdatePartyIdRule } from "@taketurns-rules/collaboration/queries/content/useFetchLastUpdatePartyIdRule";
import { useFetchConnectedUserPartyRule } from "@taketurns-rules/collaboration/queries/context/useFetchConnectedUserPartyRule";
import { useFetchOtherPartyNameRule } from "@taketurns-rules/collaboration/queries/context/useFetchOtherPartyNameRule";
import { useFetchCollaborationPartyNamesRule } from "@taketurns-rules/collaboration/queries/fields/useFetchCollaborationPartyNamesRule";
import { ContentStatus } from "@taketurns/taketurns-graphql-repository";

export function useLastUpdateMessageRule(lastUpdateStatus: ContentStatus) {
  const { t } = useSharedCollaborationTranslation();
  const { lastUpdatePartyId, loadingLastUpdatePartyId, errorOnFetchingLastUpdatePartyId } =
    useFetchLastUpdatePartyIdRule();
  const { otherPartyName, loadingOtherPartyName, errorOnFetchingOtherPartyName } = useFetchOtherPartyNameRule();
  const { invitedPartyName, owningPartyName, loadingCollaborationPartyNames, errorOnFetchingCollaborationPartyNames } =
    useFetchCollaborationPartyNamesRule();
  const { connectedUserPartyId, loadingConnectedUserParty, errorOnFetchingConnectedUserParty } =
    useFetchConnectedUserPartyRule();
  let partyName = otherPartyName;
  if (lastUpdatePartyId === connectedUserPartyId) {
    partyName = [invitedPartyName, owningPartyName].find((name) => name !== partyName) ?? partyName;
  }

  return {
    otherPartyLastUpdateMessage: `${t(`ContentElementStatus.${lastUpdateStatus}`)} ${t("ContentElementStatus.by")} ${partyName}`,
    loadingOtherPartyLastUpdateMessage:
      loadingLastUpdatePartyId || loadingOtherPartyName || loadingCollaborationPartyNames || loadingConnectedUserParty,
    errorOnFetchingOtherPartyLastUpdateMessage:
      errorOnFetchingLastUpdatePartyId ||
      errorOnFetchingOtherPartyName ||
      errorOnFetchingCollaborationPartyNames ||
      errorOnFetchingConnectedUserParty,
  };
}
