/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useMutation } from "@apollo/client";
import {
  AddAttachmentRevisionInput,
  AddAttachmentRevisionOutput,
  MutationAddAttachmentRevisionArgs,
} from "@taketurns/taketurns-graphql-repository";
import { updateCacheOnAttachmentRevisionsAdded } from "@taketurns-repositories/collaboration/graphql/cache/updateCacheOnAttachmentRevisionAdded";
import { COLLABORATION_CONTENT_METADATA_QUERY } from "@taketurns-repositories/collaboration/graphql/queries/content/useFetchCollaborationContentMetadata";
import { PendingAttachmentRevision } from "@taketurns-repositories/collaboration/models/pendingAttachmentRevision";
import { revisionFragment } from "@taketurns-repositories/document/graphql/fragments/revisionFragment";
import { useNotifyRevisionAddedRule } from "@taketurns-rules/collaboration/commands/attachmentRevision/useNotifyRevisionAddedRule";
import { useSelectDocumentRevisionRule } from "@taketurns-rules/collaboration/commands/collaborationView/attachment/useSelectDocumentRevisionRule";
import { wait } from "@taketurns-rules/commons/util/wait";
import { useMarkPendingAttachmentRevisionAsAdded } from "../../../../state/write/pendingAttachmentRevision/useMarkPendingAttachmentRevisionAsAdded";

export const addAttachmentRevisionMutationFragment = gql`
  fragment AddAttachmentRevisionMutationFragment on AddAttachmentRevisionOutput {
    collaborationId
    partyId
    attachmentId
    attachment {
      id
      revisionId
      hasAttachmentPendingRevision
      document {
        id
        revisions {
          ...RevisionFields
          uploadingUser {
            firstName
            lastName
            avatar
            email
          }
        }
      }
    }
  }
  ${revisionFragment}
`;

export const useAddAttachmentRevision = () => {
  const mutation = gql`
    mutation addAttachmentRevision($input: AddAttachmentRevisionInput!) {
      addAttachmentRevision(input: $input) {
        ...AddAttachmentRevisionMutationFragment
      }
    }
    ${addAttachmentRevisionMutationFragment}
  `;
  const [addAttachmentRevisionMutationFromGraphQL, { data, loading, error, client }] = useMutation<
    {
      addAttachmentRevision: AddAttachmentRevisionOutput;
    },
    MutationAddAttachmentRevisionArgs
  >(mutation);
  const markPendingAttachmentRevisionAsAdded = useMarkPendingAttachmentRevisionAsAdded();
  const notifyAttachmentRevisionAdded = useNotifyRevisionAddedRule();
  const { selectRevision } = useSelectDocumentRevisionRule();
  const addAttachmentRevisionMutation = (pendingAttachmentRevision: PendingAttachmentRevision) => {
    const addAttachmentRevisionInput: AddAttachmentRevisionInput = {
      collaborationId: pendingAttachmentRevision.collaborationId,
      attachmentId: pendingAttachmentRevision.attachmentId,
      documentExtension: pendingAttachmentRevision.documentExtension,
    };
    return addAttachmentRevisionMutationFromGraphQL({
      variables: { input: addAttachmentRevisionInput },
      onCompleted: (data) => {
        const { cache } = client;
        updateCacheOnAttachmentRevisionsAdded(cache, data.addAttachmentRevision);
        markPendingAttachmentRevisionAsAdded(pendingAttachmentRevision);
        notifyAttachmentRevisionAdded(pendingAttachmentRevision);
        selectRevision(data.addAttachmentRevision.attachment.revisionId);
      },
      refetchQueries: [
        {
          query: COLLABORATION_CONTENT_METADATA_QUERY,
          variables: { collaborationId: addAttachmentRevisionInput.collaborationId },
        },
      ],
      onQueryUpdated: (observableQuery) => {
        wait(3000).then(() => observableQuery.refetch());
      },
    });
  };

  return {
    addAttachmentRevisionMutation,
    addAttachmentRevisionResponseData: data?.addAttachmentRevision,
    addAttachmentRevisionLoading: loading,
    errorOnAddingAttachmentRevision: error,
  };
};
