/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useSetRecoilState } from "recoil";
import { selectCollaborationFormOwningParty } from "../../../recoil/collaborationCreation/form/values/selectCollaborationFormOwningParty";

export const useSetCollaborationFormOwningParty = () => {
  const setCollaborationFormOwningParty = useSetRecoilState(selectCollaborationFormOwningParty);
  return (owningParty: string) => {
    setCollaborationFormOwningParty(owningParty);
  };
};
