/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Stack, Typography } from "@mui/material";
import { SubscriptionPlanTier } from "@taketurns/taketurns-graphql-repository";
import { SomethingWentWrong } from "@taketurns-components/shared/SomethingWentWrong";
import { UpgradePlanProposition } from "@taketurns-components/shared/UpgradePlanProposition";
import { AuthenticationMethodsSelector } from "@taketurns-components/user/Organization/OrganizationAccessPolicies/AuthenticationMethodsSelector";
import { OrganizationDomainsList } from "@taketurns-components/user/Organization/OrganizationAccessPolicies/OrganizationDomainsList";
import { useOrganizationTranslation } from "@taketurns-i18n/user/Organization/useOrganizationTranslation";
import { useIsOnMobileRule } from "@taketurns-rules/commons/theme/useIsOnMobileRule";
import { useFetchUserHasOrganizationAccessPoliciesFeatureRule } from "@taketurns-rules/user/queries/useFetchUserHasOrganizationAccessPoliciesFeatureRule";

export const OrganizationAccessPoliciesSettings = () => {
  const { t } = useOrganizationTranslation();
  const {
    userHasOrganizationAccessPoliciesFeature,
    loadingHasOrganizationAccessPoliciesFeature,
    errorOnHasOrganizationAccessPoliciesFeature,
  } = useFetchUserHasOrganizationAccessPoliciesFeatureRule();

  if (loadingHasOrganizationAccessPoliciesFeature) {
    return null;
  }

  if (errorOnHasOrganizationAccessPoliciesFeature) {
    console.error(errorOnHasOrganizationAccessPoliciesFeature);
    return <SomethingWentWrong />;
  }

  const userHasToUpgradePlanForOrganizationAccessPolicies = !userHasOrganizationAccessPoliciesFeature;

  if (userHasToUpgradePlanForOrganizationAccessPolicies) {
    return (
      <Stack spacing={3}>
        <PageHeader />
        <UpgradePlanProposition
          proposition={t("OrganizationAccessPoliciesUpgradePlan.upgradePlanDescription")}
          planTier={SubscriptionPlanTier.T3}
        />
      </Stack>
    );
  }

  return (
    <Stack spacing={3}>
      <PageHeader />
      <OrganizationDomainsList />
      <AuthenticationMethodsSelector />
    </Stack>
  );
};

const PageHeader = () => {
  const { t } = useOrganizationTranslation();
  const isOnMobile = useIsOnMobileRule();
  if (isOnMobile) {
    return null;
  }
  return (
    <Typography fontSize={16} data-cy="accessPolicies-settings-title">
      {t("AccessPolicies.sectionTitle")}
    </Typography>
  );
};
