/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useFetchCollaborationFileUploadFeatures } from "@taketurns-repositories/collaboration/graphql/queries/features/useFetchCollaborationFileUploadFeatures";
import { useGetDisplayedCollaborationIdRule } from "@taketurns-rules/collaboration/queries/collaboration/useGetDisplayedCollaborationIdRule";
import { useFilterFilesTooBigAndNotifyRule } from "../useFilterFilesTooBigAndNotifyRule";

export function useFilterFilesTooBigAndNotifyInExistingCollaborationRule() {
  const collaborationId = useGetDisplayedCollaborationIdRule();
  const { maxFileSizeInMB } = useFetchCollaborationFileUploadFeatures(collaborationId);
  const filterFilesTooBigAndNotifyInExistingCollaboration = useFilterFilesTooBigAndNotifyRule(maxFileSizeInMB);
  return (files: File[]) => filterFilesTooBigAndNotifyInExistingCollaboration(files);
}
