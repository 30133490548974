/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { CircularProgress, IconButton } from "@mui/material";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { useAddOrRemoveToFavoriteRule } from "@taketurns-rules/user/commands/useAddOrRemoveToFavoriteRule";
import { useFetchPreferredCollaborationIdsRule } from "@taketurns-rules/user/queries/useFetchPreferredCollaborationIdsRule";
import { IconStar } from "../../commons/icons";

interface AddToFavoriteStarProps {
  collaborationId: string;
}

export const AddToFavoriteStar = (props: AddToFavoriteStarProps) => {
  const { collaborationId } = props;

  const { loadingPreferredCollaborationIds, errorOnFetchingPreferredCollaborationIds, preferredCollaborationIds } =
    useFetchPreferredCollaborationIdsRule();

  const { addOrRemoveToFavoriteCommand, loadingAddOrRemoveToFavorite } = useAddOrRemoveToFavoriteRule();

  const iconStarButtonIsDisabled =
    loadingAddOrRemoveToFavorite || loadingPreferredCollaborationIds || !!errorOnFetchingPreferredCollaborationIds;

  return (
    <IconButton
      tabIndex={2}
      disableFocusRipple
      disableTouchRipple
      disableRipple
      disabled={iconStarButtonIsDisabled}
      onClick={(event) => {
        event.stopPropagation();
        event.preventDefault();
        addOrRemoveToFavoriteCommand(preferredCollaborationIds, collaborationId);
      }}
    >
      {loadingAddOrRemoveToFavorite ? (
        <CircularProgress size={24} />
      ) : (
        <IconStar
          color={
            preferredCollaborationIds.includes(collaborationId) ? TakeTurnsColors.yellow : TakeTurnsColors.grayButton
          }
        />
      )}
    </IconButton>
  );
};
