/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { createContext, useContext } from "react";

export interface CollaborationViewContextType {
  collaborationId: string;
  connectedUserPartyId: string;
  rootFolderId: string;
}

export const CollaborationViewContext = createContext<CollaborationViewContextType>({
  collaborationId: "",
  connectedUserPartyId: "",
  rootFolderId: "",
});

export const useCollaborationViewContext = () => {
  return useContext(CollaborationViewContext);
};
