/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { atom } from "recoil";

export const cancelledUploadIdsState = atom<Set<number>>({
  key: "cancelledUploadIds",
  default: new Set<number>(),
});
