/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { DialogActions, styled } from "@mui/material";
import { useState } from "react";
import { TakeTurnsDefaultButton } from "@taketurns-components/commons/button";
import { ResponsiveDialog } from "@taketurns-components/commons/dialog/ResponsiveDialog";
import { ExternalLinkStyled } from "@taketurns-components/webapp/LinkStyled";
import { useWebAppTranslations } from "@taketurns-i18n/webapp/useWebAppTranslations";
import { useIsOnMobileRule } from "@taketurns-rules/commons/theme/useIsOnMobileRule";
import { setWelcomeDialogHasBeenSeenRule } from "@taketurns-rules/webapp/commands/setWelcomeDialogHasBeenSeenRule";
import { useGetHelpCenterUrls } from "@taketurns-rules/webapp/queries/useGetHelpCenterUrls";

interface WelcomeDialogProps {
  dialogUrl: string;
}

export const WelcomeDialog = ({ dialogUrl }: WelcomeDialogProps) => {
  const [isWelcomeDialogOpened, setWelcomeDialogOpenedStatus] = useState(true);
  const { helpCenterUrl } = useGetHelpCenterUrls();
  const setWelcomeDialogHasBeenSeen = setWelcomeDialogHasBeenSeenRule();
  const isOnMobile = useIsOnMobileRule();

  const closeWelcomeDialogAndStoreWelcomeDialogHasBeenSeen = () => {
    setWelcomeDialogOpenedStatus(false);
    setWelcomeDialogHasBeenSeen(true);
  };

  const { t } = useWebAppTranslations("welcome-dialog");
  return (
    <ResponsiveDialog
      data-cy="WelcomeDialog"
      open={isWelcomeDialogOpened}
      onClose={closeWelcomeDialogAndStoreWelcomeDialogHasBeenSeen}
      fullWidth
      maxWidth={"lg"}
      PaperProps={{ sx: { minHeight: "80%" } }}
      contentContainerStyle={{ display: "flex", padding: 0 }}
      content={<WelcomeDialogContentIframe src={dialogUrl}></WelcomeDialogContentIframe>}
      footer={
        <WelcomeDialogActions>
          <HelpCenterButton href={helpCenterUrl} target="_blank">
            {t(isOnMobile ? "buttons.helpCenterShort" : "buttons.helpCenter")}
          </HelpCenterButton>
          <TakeTurnsDefaultButton
            data-cy="welcomeDialog.gotItButton"
            onClick={closeWelcomeDialogAndStoreWelcomeDialogHasBeenSeen}
          >
            {t(isOnMobile ? "buttons.start" : "buttons.closeDialog")}
          </TakeTurnsDefaultButton>
        </WelcomeDialogActions>
      }
    ></ResponsiveDialog>
  );
};

const WelcomeDialogContentIframe = styled("iframe")({
  display: "flex",
  flex: 1,
  border: 0,
});

const WelcomeDialogActions = styled(DialogActions)({
  justifyContent: "space-between",
});

const HelpCenterButton = styled(ExternalLinkStyled)({
  fontSize: 12,
});
