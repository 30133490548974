/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import {
  Autocomplete,
  Avatar,
  CircularProgress,
  ClickAwayListener,
  debounce,
  IconButton,
  InputAdornment,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  TextField,
  TextFieldProps,
  Tooltip,
  Typography,
} from "@mui/material";
import { ForwardedRef, forwardRef, Fragment, HTMLAttributes, PropsWithChildren, useMemo, useState } from "react";
import { OrganizationMember, SearchSupervisorResultItem, User } from "@taketurns/taketurns-graphql-repository";
import { IconEdit3, IconX } from "@taketurns-components/commons/icons";
import { UserRoundedAvatar } from "@taketurns-components/user/UserProfile/UserAvatar/UserRoundedAvatar";
import { useOrganizationTranslation } from "@taketurns-i18n/user/Organization/useOrganizationTranslation";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { useChangeMemberSupervisorRule } from "@taketurns-rules/user/commands/useChangeMemberSupervisorRule";
import { useSearchSupervisorsRule } from "@taketurns-rules/user/queries/useSearchSupervisorsRule";

export const SupervisorSelector = ({ member }: { member: OrganizationMember }) => {
  const [edit, setEdit] = useState(false);
  const { changeMemberSupervisor, loadingWhenChangingMemberSupervisor } = useChangeMemberSupervisorRule();

  const selectSupervisor = async (selectedSupervisorUserId: string | null) => {
    await changeMemberSupervisor({ subordinateUserId: member.userId, newSupervisorUserId: selectedSupervisorUserId });
    setEdit(false);
  };

  const removeSupervisor = async () => {
    await changeMemberSupervisor({ subordinateUserId: member.userId, newSupervisorUserId: null });
    setEdit(false);
  };

  const handleClickAway = () => {
    setEdit(false);
  };

  if (loadingWhenChangingMemberSupervisor) {
    return <CircularProgress size={16} color={"secondary"} />;
  }

  if (edit) {
    return (
      <ClickAwayListener onClickAway={handleClickAway} touchEvent={false}>
        <SearchSupervisorAutoComplete
          member={member}
          onSupervisorChange={selectSupervisor}
          onDeleteClick={removeSupervisor}
        />
      </ClickAwayListener>
    );
  }

  return (
    <SupervisorWithEditButton
      supervisor={member.supervisor}
      onEditClick={() => setEdit(true)}
      onDeleteClick={removeSupervisor}
    />
  );
};

const SupervisorWithEditButton = ({
  supervisor,
  onEditClick,
  onDeleteClick,
}: {
  supervisor?: User;
  onEditClick: () => void;
  onDeleteClick: () => void;
}) => {
  const supervisorNameOrEmail =
    supervisor &&
    (supervisor.firstName && supervisor.lastName ? `${supervisor.firstName} ${supervisor.lastName}` : supervisor.email);
  return (
    <Stack minWidth={0} flex={1} direction={"row"} alignItems="center" spacing={1}>
      {supervisor && (
        <Fragment>
          <UserRoundedAvatar user={supervisor} />
          <Typography title={supervisorNameOrEmail} noWrap>
            {supervisorNameOrEmail}
          </Typography>
        </Fragment>
      )}
      <Stack direction={"row"}>
        <IconButton disableRipple onClick={onEditClick}>
          <IconEdit3 color={TakeTurnsColors.grayButton} size="0.875rem" />
        </IconButton>
        {supervisor && (
          <IconButton disableRipple onClick={onDeleteClick}>
            <IconX color={TakeTurnsColors.grayButton} size="0.875rem" />
          </IconButton>
        )}
      </Stack>
    </Stack>
  );
};

export interface SearchSupervisorAutoCompleteProps {
  member: OrganizationMember;
  onSupervisorChange: (selectedSupervisorUserId: string | null) => void;
  onDeleteClick: () => void;
}

export const SearchSupervisorAutoComplete = forwardRef(
  (
    { member, onSupervisorChange, onDeleteClick }: SearchSupervisorAutoCompleteProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const { errorOnSearchingSupervisors, searchSupervisors, supervisors } = useSearchSupervisorsRule();

    if (errorOnSearchingSupervisors) {
      console.error(errorOnSearchingSupervisors);
    }

    const debouncedSearchSupervisors = useMemo(
      () =>
        debounce(async (query) => {
          if (query.trim().length !== 0) {
            await searchSupervisors({ query, userId: member.userId });
          }
        }, 400),
      [member.userId, searchSupervisors],
    );

    const { t } = useOrganizationTranslation();

    return (
      <Autocomplete
        ref={ref}
        autoHighlight
        handleHomeEndKeys
        clearOnEscape
        clearOnBlur
        size={"small"}
        multiple={false}
        onChange={(_, selectedSupervisor: SearchSupervisorResultItem | null) => {
          if (selectedSupervisor) {
            onSupervisorChange(selectedSupervisor.userId);
          } else {
            onSupervisorChange(null);
          }
        }}
        noOptionsText={
          supervisors ? t("SupervisorSelector.noSupervisorFound") : t("SupervisorSelector.startTypingToStartSearching")
        }
        forcePopupIcon={false}
        autoFocus
        filterOptions={(x) => x}
        getOptionDisabled={(option: SearchSupervisorResultItem) => !option.canBeSupervisor}
        getOptionLabel={getOptionLabel}
        componentsProps={{ popper: { style: { width: "fit-content" }, placement: "bottom-start" } }}
        onInputChange={(_, searchValue) => debouncedSearchSupervisors(searchValue)}
        renderInput={(params) => (
          <TextField
            {...(params as unknown as TextFieldProps)}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={onDeleteClick}>
                    <IconX color={TakeTurnsColors.grayButton} size="0.875rem" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )}
        renderOption={(props, searchSupervisorResultItem) => (
          <SupervisorOption
            liProps={props}
            searchSupervisorResultItem={searchSupervisorResultItem}
            key={searchSupervisorResultItem.userId}
          />
        )}
        options={supervisors || []}
        sx={{ width: 250 }}
      />
    );
  },
);
SearchSupervisorAutoComplete.displayName = "SearchSupervisorAutoComplete";

const SupervisorOption = ({
  liProps,
  searchSupervisorResultItem: { avatar, email, firstName, lastName, canBeSupervisor },
}: {
  liProps: HTMLAttributes<HTMLLIElement>;
  searchSupervisorResultItem: SearchSupervisorResultItem;
}) => {
  const userEmailOrFirstNameAndLastName = firstName && lastName ? `${firstName} ${lastName}` : email;
  return (
    <CannotBeSupervisorTooltipWrapper canBeSupervisor={canBeSupervisor}>
      <ListItem {...liProps} sx={{ pointerEvents: "inherit !important" }}>
        <ListItemAvatar>
          <Avatar alt={email} src={avatar} />
        </ListItemAvatar>
        <ListItemText primary={userEmailOrFirstNameAndLastName} secondary={firstName && lastName ? email : undefined} />
      </ListItem>
    </CannotBeSupervisorTooltipWrapper>
  );
};

const CannotBeSupervisorTooltipWrapper = ({
  canBeSupervisor,
  children,
}: PropsWithChildren<{ canBeSupervisor: boolean }>) => {
  const { t } = useOrganizationTranslation();

  return !canBeSupervisor ? (
    <Tooltip title={t("SupervisorSelector.cannotBeSupervisor")}>
      <span>{children}</span>
    </Tooltip>
  ) : (
    children
  );
};

const getOptionLabel = ({ email, firstName, lastName }: SearchSupervisorResultItem) => {
  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  }
  return email;
};
