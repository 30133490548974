/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Box, Typography } from "@mui/material";
import { useCollaborationListTranslation } from "@taketurns-i18n/collaboration/CollaborationList/useCollaborationListTranslation";
import { useFetchPendingInvitationsCountRule } from "@taketurns-rules/collaboration/queries/useFetchPendingInvitationsCountRule";
import { useGetListAppliedFilterRule } from "@taketurns-rules/collaboration/queries/useGetListAppliedFilterRule";
import {
  useSubscribeToAddedOrRevokedInvitationsToCollaborationsRule,
} from "@taketurns-rules/collaboration/subscriptions/useSubscribeToAddedOrRevokedInvitationsToCollaborationsRule";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { IconBell } from "../../../commons/icons";
import { CollaborationFilterType } from "../collaborationFilterType";

interface PendingInvitationsProps {
  onClick: () => void;
}

export const MobileCollaborationPendingInvitationsNotification = ({ onClick }: PendingInvitationsProps) => {
  const { t } = useCollaborationListTranslation();
  const { pendingInvitationsCount, loadingPendingInvitationsCount, errorOnFetchingPendingInvitationsCount } =
    useFetchPendingInvitationsCountRule();
  useSubscribeToAddedOrRevokedInvitationsToCollaborationsRule();
  const collaborationListAppliedFilter = useGetListAppliedFilterRule();

  const hasPendingInvitations = pendingInvitationsCount > 0;
  if (loadingPendingInvitationsCount || !hasPendingInvitations) {
    return null;
  }
  if (errorOnFetchingPendingInvitationsCount) {
    console.error(errorOnFetchingPendingInvitationsCount);
    return null;
  }

  if (collaborationListAppliedFilter === CollaborationFilterType.pendingInvitations) {
    return (
      <Box
        sx={{
          padding: "20px 0",
          textAlign: "center",
        }}
      >
        <Typography variant="body2" sx={{ fontSize: 14, color: TakeTurnsColors.veryDarkGray }}>
          {t("pendingInvitationFullText.pendingInvitation", { count: pendingInvitationsCount })}
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      onClick={onClick}
      sx={{
        position: "sticky",
        zIndex: 1,
        top: 0,
        display: "flex",
        justifyContent: "center",
        gap: "4px",
        backgroundColor: "white",
        borderBottom: "1px solid #DDD",
        padding: "24px 0",
      }}
    >
      <Box
        sx={{
          display: "flex",
          backgroundColor: TakeTurnsColors.lightBlue,
          borderRadius: "50%",
          padding: "8px",
        }}
      >
        <IconBell size={14} color="white" />
      </Box>
      <Typography variant="body2" sx={{ lineHeight: "unset", color: TakeTurnsColors.darkGray, fontSize: 14 }}>
        {t("youHave")}
      </Typography>
      <Typography variant="body2" sx={{ lineHeight: "unset", color: TakeTurnsColors.lightBlue, fontSize: 14 }}>
        {`${pendingInvitationsCount} ${t("pendingInvitationLink.pendingInvitation", { count: pendingInvitationsCount })}`}
      </Typography>
    </Box>
  );
};
