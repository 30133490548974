/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { PropsWithChildren, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { wait } from "@taketurns-rules/commons/util/wait";
import { clearSelectedSubscriptionPlanWithTrialRule } from "@taketurns-rules/user/commands/clearSelectedSubscriptionPlanWithTrialRule";
import { useSubscribeToPlanWithTrialRule } from "@taketurns-rules/user/commands/useSubscribeToPlanWithTrialRule";
import { useGetSelectedSubscriptionPlanWithTrialRule } from "@taketurns-rules/user/queries/useGetSelectedSubscriptionPlanWithTrialRule";
import { useShouldDisplayProfilePageRule } from "@taketurns-rules/webapp/queries/useShouldDisplayProfilePageRule";
import { AUTHENTICATED_ROUTES } from "../routes/authenticatedRoutes.constants";

export const SubscribeWithTrialGuard = ({ children }: PropsWithChildren) => {
  const selectedSubscriptionPlanWithTrial = useGetSelectedSubscriptionPlanWithTrialRule();
  const clearSelectedSubscriptionPlanWithTrial = clearSelectedSubscriptionPlanWithTrialRule();
  const { subscribeToPlanWithTrial, loadingSubscribeToPlanWithTrial } = useSubscribeToPlanWithTrialRule();
  const navigate = useNavigate();
  const shouldDisplayProfilePage = useShouldDisplayProfilePageRule();

  useEffect(() => {
    const subscribeToPlanWithTrialOrRedirectToPlanPage = async () => {
      if (selectedSubscriptionPlanWithTrial && !loadingSubscribeToPlanWithTrial) {
        try {
          await subscribeToPlanWithTrial(selectedSubscriptionPlanWithTrial);
          clearSelectedSubscriptionPlanWithTrial();
          if (shouldDisplayProfilePage) {
            navigate(AUTHENTICATED_ROUTES.COMPLETE_PROFILE);
          } else {
            //TODO MRO TT-2210 : this is quickfix for latency between back-end and openSearch
            wait(2000).then(() => navigate(AUTHENTICATED_ROUTES.USER_SETTINGS));
          }
        } catch (errorOnSubscribeToPlanWithTrial) {
          console.error(errorOnSubscribeToPlanWithTrial);
          clearSelectedSubscriptionPlanWithTrial();
          navigate(AUTHENTICATED_ROUTES.PLANS);
        }
      }
    };
    subscribeToPlanWithTrialOrRedirectToPlanPage();
  }, [
    selectedSubscriptionPlanWithTrial,
    loadingSubscribeToPlanWithTrial,
    subscribeToPlanWithTrial,
    clearSelectedSubscriptionPlanWithTrial,
    navigate,
    shouldDisplayProfilePage,
  ]);

  if (selectedSubscriptionPlanWithTrial || loadingSubscribeToPlanWithTrial) {
    return null;
  }
  return children;
};
