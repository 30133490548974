/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Box, Stack, styled } from "@mui/material";
import { PendingInvitation } from "@taketurns/taketurns-graphql-repository";
import { MobileCollaborationRowName } from "@taketurns-components/collaboration/CollaborationList/MobileCollaborationList/MobileCollaborationListRow/MobileCollaborationRowName";
import { AcceptInvitationButton } from "../../CollaborationListColumns/invitation/AcceptInvitationButton";
import { OtherPartyNameCell } from "../../CollaborationListColumns/shared/OtherPartyNameCell";
import { MobileCollaborationInvitationUserInfo } from "./MobileCollaborationInvitationUserInfo";

interface MobileCollaborationInvitationRowProps {
  collaboration: PendingInvitation;
  onClick: (collaborationId: string) => void;
}

export const MobileCollaborationInvitationRow = ({ collaboration, onClick }: MobileCollaborationInvitationRowProps) => {
  const collaborationItem = collaboration as PendingInvitation;

  return (
    <RowContainer
      onClick={() => {
        onClick(collaboration.id);
      }}
    >
      <Stack direction="row" alignItems="center">
        <Box flex={1} minWidth={0}>
          <MobileCollaborationInvitationUserInfo pendingInvitation={collaboration} />
          <MobileCollaborationRowName name={collaboration.name} />
          <OtherPartyNameCell otherPartyName={collaborationItem.otherPartyName} />
        </Box>
        <AcceptInvitationButton shortLabel />
      </Stack>
    </RowContainer>
  );
};

const RowContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  gap: 12,
  padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
}));
