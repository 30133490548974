/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { gql, useFragment } from "@apollo/client";
import { Attachment } from "@taketurns/taketurns-graphql-repository";

export const attachmentFragment = gql`
  fragment AttachmentFragment on Attachment {
    id
    revisionId
    locked @nonreactive
    markedForReview
    toReview @nonreactive
    markedAsReviewed
    status
    prevStatus @nonreactive
    hasAttachmentPendingRevision @nonreactive
    document {
      id
      name
      description
      extension @nonreactive
      size @nonreactive
      revisions {
        revisionId @nonreactive
      }
    }
  }
`;

export const useGetAttachmentFragment = (attachmentId: string) => {
  return useFragment<Attachment>({
    fragment: attachmentFragment,
    from: { id: attachmentId, __typename: "Attachment" },
  });
};
