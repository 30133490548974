/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql } from "@apollo/client";
import { inviteeFragment } from "@taketurns-repositories/collaboration/graphql/fragments/inviteeFragment";
import { participantFragment } from "@taketurns-repositories/collaboration/graphql/fragments/participantFragment";

export const collaborationParticipantsFragment = gql`
  fragment CollaborationParticipantsFields on ParticipantsAndInvitations {
    id
    invitations {
      ...InviteeFields
    }
    participants {
      ...ParticipantFields
    }
  }
  ${inviteeFragment}
  ${participantFragment}
`;
