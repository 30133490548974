/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography } from "@mui/material";
import { ComponentType, Fragment, ReactNode, useState } from "react";
import { useSharedCollaborationTranslation } from "@taketurns-i18n/collaboration/shared/useSharedCollaborationTranslation";
import { useNavigateToPlansPageRule } from "@taketurns-rules/user/commands/useNavigateToPlansPageRule";
import { TakeTurnsDefaultButton } from "../../commons/button";

interface withUpgradePlanDialogProps {
  UpgradeToPlanDialogContentSlot: ReactNode;
  isFeatureAvailable: boolean;
}

type InjectedProps = {
  onClick?: unknown;
};

export const WithOpenUpgradePlanDialogOnClick = <TProps extends InjectedProps>(
  WrappedComponent: ComponentType<TProps>,
  configuration: withUpgradePlanDialogProps,
) => {
  const OutputComponent = (wrappedComponentProps: TProps) => {
    const [isUpgradePlanDialogOpened, setIsUpgradePlanDialogOpened] = useState(false);
    const navigateToPlansPage = useNavigateToPlansPageRule();
    const { t } = useSharedCollaborationTranslation();
    if (configuration.isFeatureAvailable) {
      return <WrappedComponent {...wrappedComponentProps} />;
    }
    const { UpgradeToPlanDialogContentSlot } = configuration;
    return (
      <Fragment>
        <WrappedComponent {...wrappedComponentProps} onClick={() => setIsUpgradePlanDialogOpened(true)} />
        <Dialog
          PaperProps={{ sx: { minHeight: "auto" } }}
          maxWidth={"sm"}
          fullWidth
          open={isUpgradePlanDialogOpened}
          onClose={() => setIsUpgradePlanDialogOpened(false)}
        >
          <DialogTitle>
            <Typography>{t("upgradeDialog.title")}</Typography>
          </DialogTitle>
          <Divider />
          <DialogContent>{UpgradeToPlanDialogContentSlot}</DialogContent>
          <DialogActions>
            <Button variant={"text"} onClick={() => setIsUpgradePlanDialogOpened(false)}>
              {t("upgradeDialog.buttons.cancel")}
            </Button>
            <TakeTurnsDefaultButton onClick={() => navigateToPlansPage()}>
              {t("upgradeDialog.buttons.viewPlans")}
            </TakeTurnsDefaultButton>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  };
  return OutputComponent;
};
