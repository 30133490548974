/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useModifyRequestDescription } from "@taketurns-repositories/collaboration/graphql/mutations/content/requests/useModifyRequestDescription";
import { useGetDisplayedCollaborationIdRule } from "@taketurns-rules/collaboration/queries/collaboration/useGetDisplayedCollaborationIdRule";

export const useModifyRequestDescriptionRule = (requestId: string) => {
  const collaborationId = useGetDisplayedCollaborationIdRule();
  const {
    modifyRequestDescription,
    modifyRequestDescriptionResponseData,
    modifyRequestDescriptionLoading,
    errorOnModifyingRequestDescription,
  } = useModifyRequestDescription(collaborationId, requestId);
  return {
    modifyRequestDescription,
    modifyRequestDescriptionResponseData: modifyRequestDescriptionResponseData,
    modifyRequestDescriptionLoading: modifyRequestDescriptionLoading,
    errorOnModifyingRequestDescription: errorOnModifyingRequestDescription,
  };
};
