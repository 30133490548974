/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useNotifyNotEnoughSpaceAvailableRule } from "./useNotifyNotEnoughSpaceAvailableRule";

export function useCheckAvailableSpaceAndNotifyRule(
  fetchHasEnoughSpaceAvailable: (files: File[]) => Promise<boolean>,
  totalSpaceInMB: number,
) {
  const notifyNotEnoughSpaceAvailable = useNotifyNotEnoughSpaceAvailableRule();
  return async (files: File[]) => {
    const hasEnoughSpaceAvailable = await fetchHasEnoughSpaceAvailable(files);
    if (!hasEnoughSpaceAvailable) {
      notifyNotEnoughSpaceAvailable(totalSpaceInMB);
      return false;
    }
    return true;
  };
}
