/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { styled } from "@mui/material";
import { Link } from "react-router-dom";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";

const linkStyle = {
  color: TakeTurnsColors.lightBlue,
  "&:hover": {
    cursor: "pointer",
  },
};

export const LinkStyled = styled(Link)(linkStyle);

export const ExternalLinkStyled = styled("a")(linkStyle);
