/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useEffect, useState } from "react";
import { Request } from "@taketurns-repositories/collaboration/models/request";
import { useGetOpenedRequestFulfillmentRequest } from "@taketurns-repositories/collaboration/state/read/collaborationView/useGetOpenedRequestFulfillmentRequest";
import {
  useGetRequestFilesToAddOnRequestFulfillmentOpening,
} from "@taketurns-repositories/collaboration/state/read/collaborationView/useGetRequestFilesToAddOnRequestFulfillmentOpening";
import { useCheckAvailableSpaceAndNotifyInExistingCollaborationRule } from "../useCheckAvailableSpaceAndNotifyInExistingCollaborationRule";
import { useFilterFilesTooBigAndNotifyInExistingCollaborationRule } from "../useFilterFilesTooBigAndNotifyInExistingCollaborationRule";
import { useCloseRequestFulfillmentDialogRule } from "./useCloseRequestFulfillmentDialogRule";
import { useFulfillRequestRule } from "./useFulfillRequestRule";

export const useRequestFulfillmentContentRules = () => {
  const [pendingFiles, setPendingFiles] = useState<File[]>([]);

  const openedRequestFulfillmentRequest: Request = useGetOpenedRequestFulfillmentRequest();
  const closeRequestFulfillmentDialog = useCloseRequestFulfillmentDialogRule();
  const filterFilesTooBigAndNotifyInExistingCollaboration = useFilterFilesTooBigAndNotifyInExistingCollaborationRule();
  const checkAvailableSpaceAndNotify = useCheckAvailableSpaceAndNotifyInExistingCollaborationRule();
  const fulfillRequest = useFulfillRequestRule(openedRequestFulfillmentRequest.requestName);

  const addPendingFileList = async (selectedFiles: FileList) => {
    await addPendingFiles([...selectedFiles]);
  };

  const requestFilesToAddOnRequestFulfillmentOpening = useGetRequestFilesToAddOnRequestFulfillmentOpening();
  useEffect(() => {
    addPendingFiles(requestFilesToAddOnRequestFulfillmentOpening);
  }, []);

  const addPendingFiles = async (selectedFiles: File[]) => {
    const filteredSelection = filterFilesTooBigAndNotifyInExistingCollaboration([...selectedFiles]);
    if (filteredSelection.length === 0) return;

    const hasEnoughSpaceAvailable = await checkAvailableSpaceAndNotify(filteredSelection);
    if (!hasEnoughSpaceAvailable) return;
    const alreadyPendingFiles = filteredSelection.filter((f) => pendingFiles.some((e) => e.name === f.name));
    //TODO FJS #TT-1979 : Add a snackbar to notify the user that some files were already added
    setPendingFiles([...pendingFiles, ...filteredSelection.filter((f) => !alreadyPendingFiles.includes(f))]);
  };

  const removePendingFile = (fileName: string) => {
    setPendingFiles([...pendingFiles.filter((f) => f.name !== fileName)]);
  };

  const fulfillRequestAndCloseDialog = async () => {
    await fulfillRequest(pendingFiles);
    closeRequestFulfillmentDialog();
  };

  return {
    pendingFiles,
    addPendingFiles,
    addPendingFileList,
    removePendingFile,
    fulfillRequestAndCloseDialog,
  };
};
