/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Typography } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { DateTime } from "luxon";
import { Collaboration, PendingInvitation } from "@taketurns/taketurns-graphql-repository";
import { ChatBubbleWithUnreadMessageBadge } from "@taketurns-components/chat/ChatBubbleWithUnreadMessageBadge/ChatBubbleWithUnreadMessageBadge";
import { LastUpdateCell } from "@taketurns-components/collaboration/CollaborationList/CollaborationListColumns/active/LastUpdateCell";
import { ToDoCell } from "@taketurns-components/collaboration/CollaborationList/CollaborationListColumns/active/ToDoCell";
import { CollaborationMenu } from "@taketurns-components/collaboration/CollaborationMenu/CollaborationMenu";
import { useCollaborationListTranslation } from "@taketurns-i18n/collaboration/CollaborationList/useCollaborationListTranslation";
import i18n from "@taketurns-i18n/i18n";
import { useGetListAppliedFilterRule } from "@taketurns-rules/collaboration/queries/useGetListAppliedFilterRule";
import {
  compareDateFromString,
  compareDaysFromDates,
  getSmallDate,
} from "@taketurns-rules/collaboration/utils/date/DateUtils";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { AddToFavoriteStar } from "../../../user/AddToFavoriteStar/AddToFavoriteStar";
import { CollaborationFilterType } from "../collaborationFilterType";
import { RemovalDateCell } from "./closed/RemovalDateCell";
import { AcceptInvitationButton } from "./invitation/AcceptInvitationButton";
import { InvitingUserCell } from "./invitation/InvitingUserCell";
import { OtherPartyNameCell } from "./shared/OtherPartyNameCell";
import { ToDoAndRemovalDateCell } from "./starred/ToDoAndRemovalDateCell";

export const useCollaborationListColumns = () => {
  const collaborationListAppliedFilter = useGetListAppliedFilterRule();
  const activeCollaborationListColumns = useActiveCollaborationListColumns();
  const closedCollaborationListColumns = useClosedCollaborationListColumns();
  const starredCollaborationListColumns = useStarredCollaborationListColumns();
  switch (collaborationListAppliedFilter) {
    case CollaborationFilterType.activeCollaborations:
      return activeCollaborationListColumns;
    case CollaborationFilterType.starredCollaborations:
      return starredCollaborationListColumns;
    case CollaborationFilterType.closedCollaborations:
      return closedCollaborationListColumns;
    default:
      return activeCollaborationListColumns;
  }
};

const collaborationFieldsUsedInList: { [fieldName in keyof Partial<Collaboration>]: keyof Collaboration } = {
  name: "name",
  deadline: "deadline",
  wipeDate: "wipeDate",
};

const addedFieldsInCollaborationList = {
  acceptInvitation: "acceptInvitation",
  lastUpdate: "lastUpdate",
  otherPartyName: "otherPartyName",
  invitingUser: "invitingUser",
  toDo: "toDo",
  status: "status",
  chatUnreadNotifications: "chatUnreadNotifications",
  favorite: "favorite",
  emptyColumn: "emptyColumn",
  actionMenu: "actionMenu",
};

export const collaborationListFields = {
  ...collaborationFieldsUsedInList,
  ...addedFieldsInCollaborationList,
};

const useActiveCollaborationListColumns: () => GridColDef<Collaboration, string, string>[] = () => {
  return [
    useCollaborationNameColumn() as GridColDef<Collaboration, string, string>,
    useCollaborationOtherPartyNameColumn(),
    useLastUpdateColumn(),
    useDeadlineColumn(),
    toDoColumn,
    chatUnreadNotificationColumn,
    addToFavoriteColumn,
    actionMenuColumn,
  ];
};

const useCollaborationNameColumn: () => GridColDef<Collaboration | PendingInvitation, string, string> = () => {
  const { t } = useCollaborationListTranslation();
  return {
    field: collaborationListFields.name,
    headerName: t("name"),
    flex: 2,
    maxWidth: 350,
    renderCell: (params) => {
      return (
        <Typography noWrap title={params.row.name}>
          {params.row.name}
        </Typography>
      );
    },
  };
};

const useCollaborationOtherPartyNameColumn: () => GridColDef<Collaboration, string, string> = () => {
  const { t } = useCollaborationListTranslation();
  return {
    field: collaborationListFields.otherPartyName,
    headerName: t("with"),
    flex: 1,
    valueGetter: (params) => {
      return params.row.userContext.otherPartyName;
    },
    renderCell: (params) => <OtherPartyNameCell otherPartyName={params.row.userContext.otherPartyName} />,
  };
};

const useLastUpdateColumn: () => GridColDef<Collaboration, string, string> = () => {
  const { t } = useCollaborationListTranslation();

  return {
    field: collaborationListFields.lastUpdate,
    headerName: t("lastUpdate.columnHeader"),
    cellClassName: "DarkGrayFontColor",
    headerAlign: "left",
    align: "left",
    flex: 1,
    minWidth: 200,
    sortComparator: compareDateFromString,
    valueGetter: (params: { row: Collaboration }) => params.row.lastUpdateEvent.date,
    renderCell: (params: { row: Collaboration }) => (
      <LastUpdateCell lastUpdateEvent={params.row.lastUpdateEvent} collaborationId={params.row.id} />
    ),
  } as GridColDef;
};

const useDeadlineColumn: () => GridColDef<Collaboration, string, string> = () => {
  const { t } = useCollaborationListTranslation();
  return {
    field: collaborationListFields.deadline,
    headerName: t("deadline"),
    cellClassName: "DarkGrayFontColor",
    headerAlign: "left",
    align: "left",
    maxWidth: 250,
    sortComparator: compareDateFromString,
    valueFormatter: (params) => {
      if (params.value) {
        return getSmallDate(params.value, i18n.language, {
          month: "short",
          day: "numeric",
          year: "numeric",
        });
      }
    },
    renderCell: (params) => {
      if (params.row.deadline) {
        return (
          <Typography
            fontSize={12}
            color={
              compareDaysFromDates(params.row.deadline, DateTime.now().toISO()) <= 0
                ? TakeTurnsColors.error
                : TakeTurnsColors.darkGray
            }
            data-cy={`CollaborationRow.${params.row.id}.deadline`}
            data-cy-value={params.row.deadline}
          >
            {params.formattedValue}
          </Typography>
        );
      }
    },
  };
};

const toDoColumn: GridColDef<Collaboration, string, string> = {
  field: collaborationListFields.toDo,
  headerName: "",
  cellClassName: "DarkGrayFontColor",
  align: "left",
  width: 195,
  sortable: false,
  disableColumnMenu: true,
  renderCell: (params) => {
    const { attachmentToReviewCount, requestToFulfillCount, hasPendingUpdates } = params.row.contentMetadata;
    return (
      <ToDoCell
        attachmentToReviewCount={attachmentToReviewCount}
        requestToFulfillCount={requestToFulfillCount}
        hasPendingUpdates={hasPendingUpdates}
      />
    );
  },
};

const chatUnreadNotificationColumn: GridColDef<Collaboration, string, string> = {
  field: collaborationListFields.chatUnreadNotifications,
  headerName: "",
  align: "center",
  minWidth: 48,
  maxWidth: 48,
  sortable: false,
  disableColumnMenu: true,
  renderCell: (params) => <ChatBubbleWithUnreadMessageBadge collaborationId={params.row.id} />,
};

const addToFavoriteColumn: GridColDef<Collaboration, string, string> = {
  field: collaborationListFields.favorite,
  headerName: "",
  align: "center",
  minWidth: 48,
  maxWidth: 48,
  sortable: false,
  disableColumnMenu: true,
  renderCell: (params) => <AddToFavoriteStar collaborationId={params.row.id} />,
};

const actionMenuColumn: GridColDef<Collaboration, string, string> = {
  field: collaborationListFields.actionMenu,
  headerName: "",
  align: "center",
  minWidth: 48,
  maxWidth: 48,
  sortable: false,
  disableColumnMenu: true,
  renderCell: (params) => <CollaborationMenu collaborationId={params.row.id} />,
};

const useClosedCollaborationListColumns: () => GridColDef<Collaboration, string, string>[] = () => {
  const emptyColumn: GridColDef = {
    field: collaborationListFields.emptyColumn,
    headerName: "",
    flex: 2,
    sortable: false,
    disableColumnMenu: true,
  };
  return [
    useCollaborationNameColumn() as GridColDef<Collaboration, string, string>,
    useCollaborationOtherPartyNameColumn(),
    useLastUpdateColumn(),
    emptyColumn,
    removalDateColumn,
    chatUnreadNotificationColumn,
    addToFavoriteColumn,
    actionMenuColumn,
  ];
};

const removalDateColumn: GridColDef<Collaboration, string, string> = {
  field: collaborationListFields.wipeDate,
  headerName: "",
  cellClassName: "DarkGrayFontColor",
  align: "right",
  minWidth: 200,
  sortable: false,
  disableColumnMenu: true,
  renderCell: (params) => <RemovalDateCell collaborationWipeDate={params.row.wipeDate} />,
};

const useStarredCollaborationListColumns: () => GridColDef<Collaboration, string, string>[] = () => {
  return [
    useCollaborationNameColumn() as GridColDef<Collaboration, string, string>,
    useCollaborationOtherPartyNameColumn(),
    useLastUpdateColumn(),
    useDeadlineColumn(),
    todoAndRemovalDateColumn,
    chatUnreadNotificationColumn,
    addToFavoriteColumn,
    actionMenuColumn,
  ];
};

const todoAndRemovalDateColumn: GridColDef<Collaboration, string, string> = {
  field: collaborationListFields.status,
  headerName: "",
  cellClassName: "DarkGrayFontColor",
  align: "left",
  width: 195,
  minWidth: 200,
  sortable: false,
  disableColumnMenu: true,
  renderCell: (params: GridRenderCellParams) => <ToDoAndRemovalDateCell collaboration={params.row} />,
};

export const usePendingInvitationListColumns: () => GridColDef<PendingInvitation, string, string>[] = () => {
  const { t } = useCollaborationListTranslation();
  const otherPartyNameColumn: GridColDef<PendingInvitation, string, string> = {
    field: collaborationListFields.otherPartyName,
    headerName: t("with"),
    flex: 1,
    maxWidth: 175,
    renderCell: (params) => <OtherPartyNameCell otherPartyName={params.row.otherPartyName} />,
  };
  const acceptInvitationColumn: GridColDef<PendingInvitation, string, string> = {
    field: collaborationListFields.acceptInvitation,
    headerName: "",
    align: "right",
    flex: 1,
    sortable: false,
    disableColumnMenu: true,
    renderCell: () => <AcceptInvitationButton />,
  };
  const invitingUserColumn: GridColDef<PendingInvitation, string, string> = {
    field: collaborationListFields.invitingUser,
    headerName: "",
    align: "left",
    minWidth: 175,
    flex: 1,
    sortable: false,
    disableColumnMenu: true,
    renderCell: ({ row: pendingInvitation }) => <InvitingUserCell pendingInvitation={pendingInvitation} />,
  };
  return [
    useCollaborationNameColumn() as GridColDef<PendingInvitation, string, string>,
    otherPartyNameColumn,
    invitingUserColumn,
    acceptInvitationColumn,
  ];
};
