/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { DesktopAttachmentDetails } from "@taketurns-components/collaboration/CollaborationView/AttachmentDetails/components/DesktopAttachmentDetails/DesktopAttachmentDetails";
import { MobileAttachmentDetails } from "@taketurns-components/collaboration/CollaborationView/AttachmentDetails/components/MobileAttachmentDetails/MobileAttachmentDetails";
import { useIsOnMobileRule } from "@taketurns-rules/commons/theme/useIsOnMobileRule";

export const AttachmentDetails = () => {
  const isOnMobile = useIsOnMobileRule();
  if (isOnMobile) {
    return <MobileAttachmentDetails />;
  }
  return <DesktopAttachmentDetails />;
};
