/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { PropsWithChildren } from "react";
import { HelpCenterSections } from "@taketurns-rules/commons/enum/helpCenterSectionsEnum";
import { DialogFooter } from "../../../commons/dialog/DialogFooter";
import { HelpCenterTextButton } from "../../../webapp/HelpCenterTextButton";

interface CollaborationDialogFooterProps {
  closeDialog: () => void;
  hasCancelAction?: boolean;
  helpCenterSection?: HelpCenterSections;
}

export const TakeTurnsDialogFooter = (props: PropsWithChildren<CollaborationDialogFooterProps>) => {
  const { closeDialog, children, helpCenterSection, hasCancelAction } = props;
  return (
    <DialogFooter
      helpSection={helpCenterSection && <HelpCenterTextButton helpCenterSection={helpCenterSection} />}
      hasCancelAction={hasCancelAction}
      onClose={closeDialog}
    >
      {children}
    </DialogFooter>
  );
};
