/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useSearchParams } from "react-router-dom";
import { useDecodeParamsRule } from "./useDecodeParamsRule";

export function useDecodeParamsInCurrentUrlRule<DecodedParameters>(): DecodedParameters | undefined {
  const [URLSearchParams] = useSearchParams();
  const decodeParams = useDecodeParamsRule<DecodedParameters>();
  return decodeParams(URLSearchParams);
}
