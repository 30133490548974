/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Button, SxProps } from "@mui/material";
import { IconType } from "@react-icons/all-files";
import { ForwardedRef, forwardRef } from "react";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";

export const AiAssistantButton = forwardRef(
  (
    props: {
      onClick: () => void;
      active?: boolean;
      label: string;
      sx?: SxProps;
      Icon?: IconType;
      dataCy?: string;
      disabled?: boolean;
    },
    ref: ForwardedRef<HTMLButtonElement>,
  ) => {
    return (
      <Button
        variant="outlined"
        onClick={props.onClick}
        ref={ref}
        data-cy={props.dataCy}
        disabled={props.disabled}
        sx={{
          borderColor: TakeTurnsColors.aiAssistant,
          backgroundColor: props.active && TakeTurnsColors.aiAssistant,
          color: props.active ? TakeTurnsColors.white : TakeTurnsColors.aiAssistant,
          borderRadius: "5px",
          "&:hover": {
            borderColor: TakeTurnsColors.aiAssistant,
            backgroundColor: TakeTurnsColors.aiAssistant,
            color: TakeTurnsColors.white,
          },
          ...props.sx,
        }}
        startIcon={props.Icon && <props.Icon size={20} />}
      >
        {props.label}
      </Button>
    );
  },
);
