/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useSetRecoilState } from "recoil";
import { PendingCreationAttachment } from "../../../../models/pendingCreationAttachment";
import { pendingCreationAttachmentsState } from "../../../recoil/collaborationCreation/pendingCreationAttachmentsState";

export const useStorePendingCreationAttachment = () => {
  const setPendingCreationAttachmentsState = useSetRecoilState(pendingCreationAttachmentsState);
  return (pendingCreationAttachment: PendingCreationAttachment) => {
    setPendingCreationAttachmentsState((pendingCreationAttachments) => [
      ...pendingCreationAttachments,
      pendingCreationAttachment,
    ]);
  };
};
