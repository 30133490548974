/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { TypePolicies } from "@apollo/client";
import { CollaborationUserContext } from "@taketurns/taketurns-graphql-repository";

export const typePolicies: TypePolicies = {
  Query: {
    fields: {
      attachment: {
        read(_, { args, toReference }) {
          return toReference({
            __typename: "Attachment",
            id: args?.id,
          });
        },
      },
      getCollaboration: {
        read(_, { args, toReference }) {
          return toReference({
            __typename: "Collaboration",
            id: args.collaborationId,
          });
        },
      },
      getFolder: {
        read(_, { args, toReference }) {
          return toReference({
            __typename: "Folder",
            id: args?.folderId,
          });
        },
      },
      eventOnCollaboration: {
        read(_, { args, toReference }) {
          return toReference({
            __typename: "EventOnCollaboration",
            id: args.eventId,
          });
        },
      },
      document: {
        read(_, { args, toReference }) {
          return toReference({
            __typename: "Document",
            id: args?.documentId,
          });
        },
      },
      documentRevision: {
        read(_, { args, toReference }) {
          return toReference({
            __typename: "Revision",
            revisionId: args?.revisionId,
          });
        },
      },
    },
  },
  Revision: {
    keyFields: ["revisionId"],
  },
  Collaboration: {
    fields: {
      userContext: {
        merge(existing: CollaborationUserContext, incoming: CollaborationUserContext, { mergeObjects }) {
          return mergeObjects(existing, incoming);
        },
      },
    },
  },
  ConnectedUser: {
    fields: {
      availableFeatures: {
        merge: true,
      },
    },
  },
  Organization: {
    fields: {
      subscription: {
        merge: true,
      },
    },
  },
  OrganizationInvitation: {
    keyFields: ["userId", "email"],
  },
  OrganizationMember: {
    keyFields: ["userId"],
  },
  UserPreferences: {
    merge: true,
  },
  UserIntegrations: {
    merge: true,
  },
};
