/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useEffect } from "react";
import {
  useGetPendingCreationAttachmentsPendingUpload,
} from "@taketurns-repositories/collaboration/state/read/useGetPendingCreationAttachmentsPendingUpload";
import {
  useMarkPendingCreationAttachmentAsUploading,
} from "@taketurns-repositories/collaboration/state/write/collaborationCreation/pendingCreationAttachment/useMarkPendingCreationAttachmentAsUploading";
import {
  useSetPendingCreationAttachmentDocumentId,
} from "@taketurns-repositories/collaboration/state/write/pendingAttachmentRevision/useSetPendingCreationAttachmentDocumentId";
import { useStartUploadRule } from "@taketurns-rules/document/commands/useStartUploadRule";

export const useListenToPendingCreationUploadsRule = () => {
  const startUpload = useStartUploadRule();
  const pendingCreationAttachmentsPendingUpload = useGetPendingCreationAttachmentsPendingUpload();
  const markPendingCreationAttachmentAsUploading = useMarkPendingCreationAttachmentAsUploading();
  const setPendingCreationAttachmentDocumentId = useSetPendingCreationAttachmentDocumentId();

  useEffect(() => {
    for (const pendingCreationAttachment of pendingCreationAttachmentsPendingUpload) {
      markPendingCreationAttachmentAsUploading(pendingCreationAttachment);
      startUpload(pendingCreationAttachment.uploadId).then((documentId) => {
        setPendingCreationAttachmentDocumentId(pendingCreationAttachment, documentId);
      });
    }
  }, [pendingCreationAttachmentsPendingUpload]);
};
