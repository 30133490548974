/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Stack, styled } from "@mui/material";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";

interface Option {
  label: string;
  color?: string;
}

interface TakeTurnsMultiSwitchProps<T extends Record<string, Option>> {
  options: T;
  selectedOptionId: keyof T;
  onChange: (optionId: keyof T) => void;
}

export const TakeTurnsMultiSwitch = <T extends Record<string, Option>>({
  options,
  selectedOptionId,
  onChange,
}: TakeTurnsMultiSwitchProps<T>) => {
  const onValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    if (newValue !== selectedOptionId) {
      onChange(newValue);
    }
  };

  const indicatorLeftPosition =
    (Object.keys(options).indexOf(selectedOptionId.toString()) / Object.keys(options).length) * 100;
  const indicatorWidth = 100 / Object.keys(options).length;
  const indicatorColor = options[selectedOptionId].color;

  return (
    <MultiSwitchBackground
      indicatorLeftPosition={indicatorLeftPosition}
      indicatorWidth={indicatorWidth}
      indicatorColor={indicatorColor}
    >
      {Object.entries(options).map(([optionId, option]) => (
        <CustomLabel key={optionId} checked={optionId === selectedOptionId}>
          <HiddenInput type="radio" value={optionId} checked={optionId === selectedOptionId} onChange={onValueChange} />
          {option.label}
        </CustomLabel>
      ))}
    </MultiSwitchBackground>
  );
};

const MultiSwitchBackground = styled(Stack, {
  shouldForwardProp: (prop) =>
    prop !== "indicatorLeftPosition" && prop !== "indicatorWidth" && prop !== "indicatorColor",
})<{
  indicatorLeftPosition: number;
  indicatorWidth: number;
  indicatorColor?: string;
}>(({ indicatorLeftPosition, indicatorWidth, indicatorColor }) => ({
  position: "relative",
  flexDirection: "row",
  backgroundColor: "#E8E8E8",
  borderRadius: 20,
  minHeight: 35,
  overflow: "hidden",
  "&::before": {
    position: "absolute",
    top: 0,
    left: `${indicatorLeftPosition}%`,
    backgroundColor: indicatorColor ?? TakeTurnsColors.lightBlue,
    borderRadius: 20,
    height: "100%",
    width: `${indicatorWidth}%`,
    transition: "left 0.3s ease-in-out, background-color 0.3s ease-in-out",
    content: '""',
  },
}));

const CustomLabel = styled("label")<{
  checked: boolean;
}>(({ theme, checked }) => ({
  flex: 1,
  alignSelf: "center",
  zIndex: 1,
  alignItems: "center",
  borderRadius: 20,
  padding: "0 16px",
  overflow: "hidden",
  textAlign: "center",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  color: checked ? theme.palette.background.paper : TakeTurnsColors.mediumGray,
  fontSize: "14px",
  fontWeight: 500,
  transition: "color 0.3s ease-in-out",
}));

const HiddenInput = styled("input")({
  display: "none",
});
