/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Stack } from "@mui/material";
import { useIsOnMobileRule } from "@taketurns-rules/commons/theme/useIsOnMobileRule";
import { useGetSubscriptionPlansRule } from "@taketurns-rules/user/queries/useGetSubscriptionPlansRule";
import { MobileSubscriptionPlans } from "./MobileSubscriptionPlans";
import { SubscriptionPlanElement } from "./SubscriptionPlanElement/SubscriptionPlanElement";

interface SubscriptionPlanListProps {
  isUserAuthenticated?: boolean;
}

export const SubscriptionPlans = (props: SubscriptionPlanListProps) => {
  const { isUserAuthenticated } = props;
  const subscriptionPlans = useGetSubscriptionPlansRule();
  const isOnMobile = useIsOnMobileRule();

  if (isOnMobile) {
    return <MobileSubscriptionPlans isUserAuthenticated={isUserAuthenticated} />;
  }

  return (
    <Stack className="SubscriptionPlans" flex={1} direction="row" spacing={3} justifyContent={"center"} width={1}>
      {subscriptionPlans.map((subscriptionPlan, index) => (
        <SubscriptionPlanElement
          key={index}
          subscriptionPlan={subscriptionPlan}
          isUserAuthenticated={isUserAuthenticated}
        />
      ))}
    </Stack>
  );
};
