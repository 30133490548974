/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useSetRecoilState } from "recoil";
import { selectCollaborationFormStartNote } from "../../../recoil/collaborationCreation/form/values/selectCollaborationFormStartNote";

export const useSetCollaborationFormStartNote = () => {
  const setCollaborationFormStartNote = useSetRecoilState(selectCollaborationFormStartNote);
  return (startNote: string) => {
    setCollaborationFormStartNote(startNote);
  };
};
