/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Stack } from "@mui/material";
import { useCloseRequestFulfillmentDialogRule } from "@taketurns-rules/collaboration/commands/collaborationView/request/useCloseRequestFulfillmentDialogRule";
import { useIsRequestFulfillmentOpenRule } from "@taketurns-rules/collaboration/queries/collaborationView/useIsRequestFulfillmentOpenRule";
import { TakeTurnsAppBarTheme } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { MobileBottomSheet } from "../../../../commons/MobileSheets/MobileBottomSheet";
import { MobileRequestFulfillmentDrawerContent } from "./MobileRequestFulfillmentDrawerContent";

export const MobileRequestFulfillmentDrawer = () => {
  const isRequestFulfillmentOpen = useIsRequestFulfillmentOpenRule();
  const closeRequestFulfillment = useCloseRequestFulfillmentDialogRule();

  return (
    <MobileBottomSheet
      setIsOpen={closeRequestFulfillment}
      isOpen={isRequestFulfillmentOpen}
      sx={{
        top: `calc(${TakeTurnsAppBarTheme.mobile.height}px + env(safe-area-inset-top))`,
        maxHeight: "inherit",
      }}
    >
      <Stack className="MobileRequestFulfillment" flexGrow={1}>
        {isRequestFulfillmentOpen && <MobileRequestFulfillmentDrawerContent />}
      </Stack>
    </MobileBottomSheet>
  );
};
