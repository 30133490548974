/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { gql, useApolloClient } from "@apollo/client";
import { Folder } from "@taketurns/taketurns-graphql-repository";
import { attachmentFragment } from "@taketurns-repositories/collaboration/graphql/fragments/attachmentFragment";
import { requestFragment } from "@taketurns-repositories/collaboration/graphql/fragments/requestFragment";

export const useGetFolderInCache = () => {
  const { cache } = useApolloClient();

  const FOLDER_FRAGMENT = gql`
    fragment FolderFragmentFromCache on Folder {
      id
      attachments {
        ...AttachmentFragment
      }
      requests {
        ...RequestFragment
      }
      attachmentCount
      attachmentToReviewCount
      requestCount
      folderCount
      updateCount
    }
    ${requestFragment}
    ${attachmentFragment}
  `;

  return (folderId: string) => {
    return cache.readFragment<Folder>({
      id: cache.identify({ __typename: "Folder", id: folderId }),
      fragment: FOLDER_FRAGMENT,
      fragmentName: "FolderFragmentFromCache",
      returnPartialData: true,
    });
  };
};
