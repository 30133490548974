/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { styled } from "@mui/material";
import { Column, ColumnPaper } from "@taketurns-app/pages/authenticated/CollaborationCreation/CollaborationCreationPages/commons";
import { CollaborationCreationStartNote } from "@taketurns-components/collaboration/CollaborationCreation/CollaborationCreationStartNote";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";

export const CollaborationCreationStartNoteColumn = () => {
  return (
    <Column>
      <CollaborationCreationStartNote__Container className="CollaborationCreation__StartNote">
        <CollaborationCreationStartNote />
      </CollaborationCreationStartNote__Container>
    </Column>
  );
};

const CollaborationCreationStartNote__Container = styled(ColumnPaper)({
  borderTop: `5px solid ${TakeTurnsColors.lightGray}`,
  paddingTop: 0,
});
