/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useQuery } from "@apollo/client";
import { Organization } from "@taketurns/taketurns-graphql-repository";

export const USER_ORGANIZATION = gql`
  query userOrganization {
    userOrganization {
      id
      name
    }
  }
`;

export const useFetchUserOrganization = () => {
  const {
    data,
    loading: loadingUserOrganization,
    error: errorOnLoadingUserOrganization,
  } = useQuery<{
    userOrganization: Organization;
  }>(USER_ORGANIZATION);
  return {
    userOrganization: data?.userOrganization,
    loadingUserOrganization,
    errorOnLoadingUserOrganization,
  };
};
