/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useSnackbar } from "notistack";
import { useChangePreferredLanguage } from "@taketurns-repositories/user/graphql/mutations/useChangePreferredLanguage";

export const useChangePreferredLanguageRule = () => {
  const { changePreferredLanguage, errorOnChangePreferredLanguage } = useChangePreferredLanguage();
  const { enqueueSnackbar } = useSnackbar();

  if (errorOnChangePreferredLanguage) {
    console.error(errorOnChangePreferredLanguage);
    enqueueSnackbar(errorOnChangePreferredLanguage.message, { variant: "error" });
  }

  return { changePreferredLanguage };
};
