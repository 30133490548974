/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { ListItemIcon, ListItemText, MenuItem, Paper, styled, Typography } from "@mui/material";
import { ComponentType, Fragment, MutableRefObject, useCallback, useEffect, useRef, useState } from "react";
import { useSharedCollaborationTranslation } from "@taketurns-i18n/collaboration/shared/useSharedCollaborationTranslation";
import { ContextMenuConfiguration } from "./models/contextMenuConfiguration";

export const WithContextMenu = <TProps extends object>(
  WrappedComponent: ComponentType<TProps>,
  contextMenuConfiguration: ContextMenuConfiguration,
) => {
  const ComponentWithContextMenu = (wrappedComponentProps: TProps) => {
    const ComponentToAddContextMenuToRef = useRef<HTMLDivElement | HTMLLIElement | null>(null);
    const [menuAnchorPosition, setMenuAnchorPosition] = useState<{
      x: number;
      y: number;
    }>();
    const isContextMenuOpen = menuAnchorPosition !== undefined;
    const longPressTimeoutIdRef = useRef<number>();
    const menuRef = useRef<HTMLDivElement | null>(null);
    const [menuDimensions, setMenuDimensions] = useState<{
      width: number;
      height: number;
    }>({ width: 0, height: 0 });
    const menuDimensionsCallbackRef = useCallback((menuNode: HTMLDivElement | null) => {
      if (menuNode !== null) {
        menuRef.current = menuNode;
        setMenuDimensions({
          width: menuNode.offsetWidth,
          height: menuNode.offsetHeight,
        });
      }
    }, []);

    useRemoveSelectMenuFromComponent();
    useAttachEventsToDocument();
    useAttachEventsToWrappedComponent();

    const computeLeft = useComputeLeft();
    const computeTop = useComputeTop();

    const { t } = useSharedCollaborationTranslation();
    if (!contextMenuConfiguration.contextMenuItems) {
      return <WrappedComponent {...wrappedComponentProps} />;
    }

    return (
      <Fragment>
        <WrappedComponent ref={ComponentToAddContextMenuToRef} {...wrappedComponentProps} />
        {isContextMenuOpen && (
          <Paper
            ref={menuDimensionsCallbackRef}
            sx={{ position: "fixed", zIndex: 1000, left: computeLeft(), top: computeTop() }}
          >
            {contextMenuConfiguration.contextMenuItems.map((item, index) => (
              <MenuItem
                key={index}
                onPointerDown={() => setTimeout(item.action, 300)}
                data-cy={`MenuItem__${item.labelKey}`}
              >
                <ContextMenuIcon>{item.icon}</ContextMenuIcon>
                <ListItemText>
                  <Typography variant={"body2"}>{t(item.labelKey)}</Typography>
                </ListItemText>
              </MenuItem>
            ))}
          </Paper>
        )}
      </Fragment>
    );

    function useRemoveSelectMenuFromComponent() {
      useEffect(() => {
        if (!ComponentToAddContextMenuToRef.current) {
          return;
        }
        removeSelectMenuFromComponent(ComponentToAddContextMenuToRef.current);

        function removeSelectMenuFromComponent(componentHtmlElement: HTMLDivElement | HTMLLIElement) {
          componentHtmlElement.style.setProperty("-webkit-touch-callout", "none");
          componentHtmlElement.style.setProperty("-webkit-user-select", "none");
          componentHtmlElement.style.setProperty("-moz-user-select", "none");
          componentHtmlElement.style.setProperty("-ms-user-select", "none");
          componentHtmlElement.style.setProperty("user-select", "none");
        }
      }, [ComponentToAddContextMenuToRef.current]);
    }

    function useAttachEventsToDocument() {
      useEffect(() => {
        document.addEventListener("scroll", closeMenu);
        document.addEventListener("wheel", closeMenu);
        document.addEventListener("touchmove", closeMenu);
        document.addEventListener("pointerup", resetLongPressTimer);
        document.addEventListener("contextmenu", closeMenuOnRightClickAway);
        document.addEventListener("pointerdown", closeMenuOnClickAway);

        return () => {
          document.removeEventListener("scroll", closeMenu);
          document.removeEventListener("wheel", closeMenu);
          document.removeEventListener("touchmove", closeMenu);
          document.removeEventListener("pointerup", resetLongPressTimer);
          document.removeEventListener("contextmenu", closeMenuOnRightClickAway);
          document.removeEventListener("pointerdown", closeMenuOnClickAway);
        };

        function closeMenu(): void {
          setMenuAnchorPosition(undefined);
          resetLongPressTimer();
        }

        function resetLongPressTimer(): void {
          clearTimeout(longPressTimeoutIdRef.current);
        }

        function closeMenuOnClickAway(event: MouseEvent): void {
          const isMenuTargetOfEvent = menuRef?.current?.contains(event.target as Node);
          const isComponentTargetOfEvent = ComponentToAddContextMenuToRef?.current?.contains(event.target as Node);
          if (!isMenuTargetOfEvent && !isComponentTargetOfEvent) {
            closeMenu();
          }
        }

        function closeMenuOnRightClickAway(event: MouseEvent): void {
          closeMenuOnClickAway(event);
          event.preventDefault();
        }
      }, []);
    }

    function useAttachEventsToWrappedComponent() {
      useEffect(() => {
        return addListenersOnWrappedComponent(
          ComponentToAddContextMenuToRef as MutableRefObject<HTMLDivElement | null>,
        );

        function addListenersOnWrappedComponent(componentRef: MutableRefObject<HTMLDivElement | null>) {
          componentRef.current?.addEventListener("contextmenu", openContextMenu);
          componentRef.current?.addEventListener("pointerdown", openContextMenuOnLongPress);
          componentRef.current?.addEventListener("click", preventClickIfMenuIsOpenOnComponent);

          return () => {
            componentRef.current?.removeEventListener("contextmenu", openContextMenu);
            componentRef.current?.removeEventListener("pointerdown", openContextMenuOnLongPress);
            componentRef.current?.removeEventListener("click", preventClickIfMenuIsOpenOnComponent);
          };

          function openContextMenu(event: MouseEvent): void {
            setMenuAnchorPosition({ x: event.clientX, y: event.clientY });
          }

          function openContextMenuOnLongPress(event: MouseEvent): void {
            const LONG_PRESS_DURATION = 500;
            longPressTimeoutIdRef.current = setTimeout(() => {
              openContextMenu(event);
            }, LONG_PRESS_DURATION) as unknown as number;
          }

          function preventClickIfMenuIsOpenOnComponent(event: MouseEvent): void {
            const isCurrentTargetExactlyEqualsToComponent = componentRef?.current === event.currentTarget;
            if (isCurrentTargetExactlyEqualsToComponent && isContextMenuOpen) {
              event.stopImmediatePropagation();
            }
          }
        }
      }, [ComponentToAddContextMenuToRef]);
    }

    function useComputeLeft() {
      return useCallback(() => {
        if (menuDimensions === undefined || menuAnchorPosition === undefined) {
          return;
        }
        const screenWidth = window.innerWidth;
        const menuWidth = menuDimensions.width;
        const shouldDisplayOnTheRight = screenWidth - menuAnchorPosition.x > menuWidth;
        if (shouldDisplayOnTheRight) {
          return `${menuAnchorPosition.x + 5}px`;
        } else {
          return `${menuAnchorPosition.x - menuWidth - 5}px`;
        }
      }, [menuDimensions, menuAnchorPosition]);
    }

    function useComputeTop() {
      return useCallback(() => {
        if (menuDimensions === undefined || menuAnchorPosition === undefined) {
          return;
        }
        const screenH = window.innerHeight;
        const menuHeight = menuDimensions.height;
        const shouldDisplayOnTop = screenH - menuAnchorPosition.y > menuHeight;
        if (shouldDisplayOnTop) {
          return `${menuAnchorPosition.y + 5}px`;
        } else {
          return `${menuAnchorPosition.y - menuHeight - 5}px`;
        }
      }, [menuDimensions, menuAnchorPosition]);
    }
  };
  ComponentWithContextMenu.displayName = `WithContextMenu(${WrappedComponent.displayName || WrappedComponent.name || "Component"})`;
  return ComponentWithContextMenu;
};

const ContextMenuIcon = styled(ListItemIcon)({
  marginRight: 10,
  minWidth: "0 !important",
});
