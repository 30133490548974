/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Stack } from "@mui/material";
import { BodyLayout } from "@taketurns-app/layouts/body/BodyLayout";
import { CollaborationCreationInvitedPartyColumn } from "@taketurns-app/pages/authenticated/CollaborationCreation/CollaborationCreationPages/CollaborationCreationInvitedPartyColumn";
import { CollaborationCreationMainFormAndContentColumn } from "@taketurns-app/pages/authenticated/CollaborationCreation/CollaborationCreationPages/CollaborationCreationMainFormAndContentColumn";
import { CollaborationCreationOwningPartyColumn } from "@taketurns-app/pages/authenticated/CollaborationCreation/CollaborationCreationPages/CollaborationCreationOwningPartyColumn";
import { MobileCollaborationCreation } from "@taketurns-app/pages/authenticated/CollaborationCreation/MobileCollaborationCreation";
import { CollaborationCreationHeader } from "@taketurns-components/collaboration/CollaborationCreation/CollaborationCreationHeader/CollaborationCreationHeader";
import { CollaborationCreationProvider } from "@taketurns-components/collaboration/CollaborationCreation/CollaborationCreationProvider";
import { useIsOnMobileRule } from "@taketurns-rules/commons/theme/useIsOnMobileRule";

export const CollaborationCreation = () => {
  const isOnMobile = useIsOnMobileRule();

  if (isOnMobile) {
    return <MobileCollaborationCreation />;
  }

  return (
    <CollaborationCreationProvider>
      <BodyLayout
        header={<CollaborationCreationHeader />}
        content={
          <Stack className="CollaborationCreation__Content" direction="row" spacing={3.7} height={1}>
            <CollaborationCreationInvitedPartyColumn />
            <CollaborationCreationMainFormAndContentColumn />
            <CollaborationCreationOwningPartyColumn />
          </Stack>
        }
      />
    </CollaborationCreationProvider>
  );
};
