/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { LinearProgress, Skeleton, Stack, styled, Tooltip, Typography } from "@mui/material";
import { useUserPlanTranslation } from "@taketurns-i18n/user/UserPlans/useUserPlanTranslation";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { useFetchUserOrganizationNameAndSubscriptionPlanRule } from "@taketurns-rules/user/queries/useFetchUserOrganizationNameAndSubscriptionPlanRule";

export const UserAvailableStorage = () => {
  const { t } = useUserPlanTranslation();

  const {
    userOrganizationSubscriptionPlan,
    errorOnUserOrganizationNameAndSubscriptionPlan,
    loadingUserOrganizationNameAndSubscriptionPlan,
  } = useFetchUserOrganizationNameAndSubscriptionPlanRule();

  if (errorOnUserOrganizationNameAndSubscriptionPlan) {
    return <Typography color={"error"}>{t("billingPortal.errorOnAvailableStorage")}</Typography>;
  }

  if (loadingUserOrganizationNameAndSubscriptionPlan) {
    return <Skeleton variant={"text"} />;
  }

  const totalSpaceInMB = userOrganizationSubscriptionPlan?.totalSpaceInMB;
  const availableSpaceInMB = userOrganizationSubscriptionPlan?.availableSpaceInBytes / (1000 * 1000);
  const usedSpaceInMB = Math.trunc(totalSpaceInMB - availableSpaceInMB);
  const maxSpaceReached = availableSpaceInMB < 0;
  const usedSpaceInPercent = (100 * usedSpaceInMB) / totalSpaceInMB;
  const totalSpaceInGB = totalSpaceInMB / 1000;

  return (
    <UserAvailableStorage__StackContainer>
      <Typography variant="body2">{t("billingPortal.storage")}</Typography>
      <UserAvailableStorage__ProgressBarContainer>
        <Tooltip title={`${usedSpaceInMB}MB / ${totalSpaceInMB}MB`} arrow>
          <LinearProgress
            value={maxSpaceReached ? 100 : usedSpaceInPercent}
            sx={{ flex: 1, borderRadius: 10 }}
            variant="determinate"
            color={maxSpaceReached ? "error" : "primary"}
          />
        </Tooltip>
        <Typography variant="caption" color={TakeTurnsColors.darkGray}>{`${totalSpaceInGB}GB`}</Typography>
      </UserAvailableStorage__ProgressBarContainer>
    </UserAvailableStorage__StackContainer>
  );
};

const UserAvailableStorage__StackContainer = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  alignItems: "center",
  gap: theme.spacing(4),

  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    alignItems: "normal",
    gap: theme.spacing(2),
  },
}));

const UserAvailableStorage__ProgressBarContainer = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  alignItems: "center",
  gap: theme.spacing(1),

  [theme.breakpoints.up("md")]: {
    flex: "0 1 370px",
  },
}));
