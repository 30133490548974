/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useMutation } from "@apollo/client";
import { ModifyOrganizationInvitationRoleInput } from "@taketurns/taketurns-graphql-repository";

export const useModifyOrganizationInvitationRole = () => {
  const mutation = gql`
    mutation modifyOrganizationMemberRole($modifyOrganizationMemberRoleInput: ModifyOrganizationMemberRoleInput!) {
      modifyOrganizationMemberRole(modifyOrganizationMemberRoleInput: $modifyOrganizationMemberRoleInput) {
        userId
        role
      }
    }
  `;
  const [
    modifyOrganizationInvitationRoleMutation,
    { error: errorOnModifyInvitationRole, loading: loadingModifyInvitationRole },
  ] = useMutation(mutation);
  const modifyOrganizationInvitationRole = (
    modifyOrganizationInvitationRoleInput: ModifyOrganizationInvitationRoleInput,
  ) => {
    return modifyOrganizationInvitationRoleMutation({ variables: { modifyOrganizationInvitationRoleInput } });
  };
  return { modifyOrganizationInvitationRole, errorOnModifyInvitationRole, loadingModifyInvitationRole };
};
