/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Box, Stack, Typography } from "@mui/material";
import { useUserPlanTranslation } from "@taketurns-i18n/user/UserPlans/useUserPlanTranslation";
import { useIsOnMobileRule } from "@taketurns-rules/commons/theme/useIsOnMobileRule";
import { PaymentPeriodSwitch } from "../shared/PaymentPeriodSwitch";
import { PaymentRegionAndCurrencySelector } from "./PaymentRegionAndCurrencySelector";

export const PublicAndFreePlanSubscriptionPlansHeader = () => {
  const { t } = useUserPlanTranslation();
  const isOnMobile = useIsOnMobileRule();

  if (isOnMobile) {
    return (
      <Stack
        alignItems={"center"}
        justifyContent={"space-between"}
        spacing={2}
        px={3}
        pb={2}
        width={{ xs: "100%", md: "initial" }}
      >
        <PaymentRegionAndCurrencySelector />
        <PaymentPeriodSwitch />
      </Stack>
    );
  }

  return (
    <Stack direction="row" alignItems={"center"} justifyContent={"space-between"}>
      <Stack direction="row" spacing={2}>
        <Typography variant="h6">{t("header.choosePlan")}</Typography>
        <PaymentRegionAndCurrencySelector />
      </Stack>
      <Box sx={{ display: "flex" }}>
        <PaymentPeriodSwitch />
      </Box>
    </Stack>
  );
};
