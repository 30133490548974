/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useQuery } from "@apollo/client";
import { Collaboration } from "@taketurns/taketurns-graphql-repository";
import { userFragment } from "@taketurns-repositories/user/graphql/fragments/userFragment";

export const LAST_UPDATE_EVENT = gql`
  query lastUpdateEvent($collaborationId: ID!) {
    getCollaboration(collaborationId: $collaborationId) {
      id
      lastUpdateEvent {
        id
        userId
        user {
          ...UserFields
        }
        date
        isFromConnectedUserParty
        party
        partyName
        eventType
        note
      }
      contentMetadata {
        recallLastUpdatesAvailable
      }
    }
  }
  ${userFragment}
`;

export const useFetchCollaborationLastUpdateEvent = (collaborationId: string) => {
  const {
    data,
    loading: loadingLastUpdateEvent,
    error: errorWhileFetchingLastUpdateEvent,
  } = useQuery<{
    getCollaboration: Collaboration;
  }>(LAST_UPDATE_EVENT, { variables: { collaborationId } });
  return {
    lastUpdateEvent: data?.getCollaboration.lastUpdateEvent,
    recallLastUpdatesAvailable: data?.getCollaboration.contentMetadata.recallLastUpdatesAvailable,
    loadingLastUpdateEvent,
    errorWhileFetchingLastUpdateEvent,
  };
};
