/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import {
  Box,
  Button,
  Divider,
  IconButton,
  InputBase,
  MenuItem,
  Select,
  SelectChangeEvent,
  Skeleton,
  Stack,
  styled,
  Tooltip,
  Typography,
} from "@mui/material";
import { createRef, Fragment, ReactNode, RefObject, useRef, useState } from "react";
import { FetchLoadingManager } from "@taketurns-app/utils/FetchLoadingManager";
import { AttachmentAiAssistantSummary } from "@taketurns-components/collaboration/CollaborationView/AttachmentDetails/components/AttachmentAiAssistant/AttachmentAiAssistantSummary";
import { AttachmentAiAssistantComparison } from "@taketurns-components/collaboration/CollaborationView/AttachmentDetails/components/AttachmentAiAssistant/AttachmentRevisionAiAssistantComparison";
import { AiAssistantNotConfigured } from "@taketurns-components/collaboration/CollaborationView/AttachmentDetails/components/DesktopAttachmentDetails/AttachmentAiAssistant/AiAssistantNotConfigured";
import { MobileAttachmentDetailsDocumentName } from "@taketurns-components/collaboration/CollaborationView/AttachmentDetails/components/MobileAttachmentDetails/MobileAttachmentDetailsDocumentName";
import { IconAiAssistant, IconChevronDown, IconChevronLeft } from "@taketurns-components/commons/icons";
import { MobileFullPageSheet } from "@taketurns-components/commons/MobileSheets/MobileFullPageSheet";
import { SomethingWentWrongRetryLater } from "@taketurns-components/shared/SomethingWentWrong";
import { useSharedCollaborationTranslation } from "@taketurns-i18n/collaboration/shared/useSharedCollaborationTranslation";
import { useFetchChatGptApiKey } from "@taketurns-repositories/user/graphql/queries/useFetchChatGptApiKey";
import { useVisualViewportHeight } from "@taketurns-rules/chat/commands/useVisualViewportHeight";
import { useSelectDocumentRevisionRule } from "@taketurns-rules/collaboration/commands/collaborationView/attachment/useSelectDocumentRevisionRule";
import { useGetOpenedAttachmentRule } from "@taketurns-rules/collaboration/queries/attachment/useGetOpenedAttachmentRule";
import { useGetDisplayedCollaborationIdRule } from "@taketurns-rules/collaboration/queries/collaboration/useGetDisplayedCollaborationIdRule";
import { useIsFirstRevisionSelectedRule } from "@taketurns-rules/collaboration/queries/collaborationView/useIsFirstRevisionSelectedRule";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { useFetchIsRevisionExtensionSupportedForSummaryOrCompare } from "@taketurns-rules/document/queries/useFetchIsRevisionExtensionSupportedForSummaryOrCompare";

export const MobileAiAssistantDrawerAndButton = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { viewportHeight } = useVisualViewportHeight();

  return (
    <Fragment>
      <IconContainer data-cy="AIAssistantButton" onClick={() => setIsOpen(true)} sx={{}}>
        <IconAiAssistant size={20} color={TakeTurnsColors.aiAssistant} />
      </IconContainer>
      <MobileFullPageSheet
        isOpen={isOpen}
        header={<PageHeader closeDialog={() => setIsOpen(false)} />}
        sx={{
          top: "initial",
          bottom: 0,
          maxHeight: `${viewportHeight}px`,
        }}
      >
        <AiAssistantDrawerContent />
      </MobileFullPageSheet>
    </Fragment>
  );
};

const PageHeader = (props: { closeDialog: () => void }) => {
  return (
    <Stack direction="row" gap={1.5} alignItems="center" justifyContent="space-between" padding="16px 24px">
      <IconButton onClick={props.closeDialog} disableRipple sx={{ justifyContent: "flex-start" }}>
        <IconChevronLeft color={TakeTurnsColors.darkGray} size={24} />
      </IconButton>
      <MobileAttachmentDetailsDocumentName />
      <IconContainer
        onClick={() => props.closeDialog()}
        sx={{
          backgroundColor: TakeTurnsColors.aiAssistant,
          "&:hover": {
            backgroundColor: TakeTurnsColors.aiAssistant,
          },
        }}
      >
        <IconAiAssistant size={20} color={TakeTurnsColors.white} />
      </IconContainer>
    </Stack>
  );
};

const IconContainer = styled(IconButton)({
  borderColor: TakeTurnsColors.aiAssistant,
  borderWidth: "1px",
  borderStyle: "solid",
  borderRadius: "5px",
  padding: "2px",
  width: "24px",
  height: "24px",
});

enum AiAssistantAction {
  summary = "0",
  compare = "1",
}

const AiAssistantDrawerContent = () => {
  const { t } = useSharedCollaborationTranslation();
  const [selectedAction, setSelectedAction] = useState<AiAssistantAction | "">("");
  const collaborationId = useGetDisplayedCollaborationIdRule();
  const attachment = useGetOpenedAttachmentRule();
  const { selectedRevisionId } = useSelectDocumentRevisionRule();
  const { chatGptApiKey, loading, error } = useFetchAiAssistantDrawerContentDataRule();
  const { isExtensionSupportedForSummaryOrCompare, loadingDocumentRevisions, errorOnFetchingDocumentRevisions } =
    useFetchIsRevisionExtensionSupportedForSummaryOrCompare({
      collaborationId,
      revisionId: selectedRevisionId,
      documentId: attachment.id,
    });

  const isFirstRevisionSelected = useIsFirstRevisionSelectedRule(attachment.id);

  const [sectionsByName, setSectionsByName] = useState<{ name: string; component: ReactNode }[]>([]);
  const sectionAnchorRefsRef = useRef<Map<string, RefObject<HTMLAnchorElement>>>(new Map());

  const addSection = (sectionName: string, component: ReactNode) => {
    if (sectionsByName.some((section) => section.name === sectionName)) {
      sectionAnchorRefsRef.current.get(sectionName)?.current?.scrollIntoView({ behavior: "smooth" });
      return;
    }
    sectionAnchorRefsRef.current.set(sectionName, createRef());
    setSectionsByName([...sectionsByName, { name: sectionName, component }]);
  };

  const addSummarySection = () => {
    const SUMMARY_SECTION_NAME = "summary";
    addSection(
      SUMMARY_SECTION_NAME,
      <AttachmentAiAssistantSummary
        collaborationId={collaborationId}
        documentId={attachment.id}
        revisionId={selectedRevisionId}
      />,
    );
  };
  const addComparisonSection = () => {
    const COMPARISON_SECTION_NAME = "comparison";
    addSection(
      COMPARISON_SECTION_NAME,
      <AttachmentAiAssistantComparison
        collaborationId={collaborationId}
        documentId={attachment.id}
        revisionId={selectedRevisionId}
      />,
    );
  };

  const addAiAssistantActionResult = () => {
    switch (selectedAction) {
      case AiAssistantAction.summary:
        addSummarySection();
        break;
      case AiAssistantAction.compare:
        addComparisonSection();
        break;
      default:
        break;
    }
  };

  if (error || errorOnFetchingDocumentRevisions) {
    return <SomethingWentWrongRetryLater />;
  }
  if (loading || loadingDocumentRevisions) {
    return <Skeleton width={"100%"} />;
  }
  if (!chatGptApiKey) {
    return <AiAssistantNotConfigured />;
  }
  if (!isExtensionSupportedForSummaryOrCompare) {
    return (
      <Stack padding={3} alignItems="center" data-cy="AiAssistantUnsupportedExtension">
        <Typography>{t(`AttachmentAiAssistant.AttachmentAiAssistantSummary.errors.NOT_SUPPORTED`)}</Typography>
      </Stack>
    );
  }

  return (
    <Stack alignItems="center" spacing={3} padding={3} height="100%">
      <AiAssistantDrawerContentTitle />
      <Stack width="100%" direction="row" spacing={1}>
        <AiAssistantActionSelector
          value={selectedAction}
          onChange={(event) => setSelectedAction(event.target.value as AiAssistantAction)}
        />
        {selectedAction && (
          <Tooltip
            title={t("AttachmentAiAssistant.errors.CANNOT_COMPARE_FIRST_REVISION")}
            placement="bottom-end"
            disableFocusListener={selectedAction !== AiAssistantAction.compare || !isFirstRevisionSelected}
            disableHoverListener={selectedAction !== AiAssistantAction.compare || !isFirstRevisionSelected}
            disableTouchListener={selectedAction !== AiAssistantAction.compare || !isFirstRevisionSelected}
          >
            <span>
              <AiAssistantActionSubmitButton
                data-cy="AiAssistantActionSubmitButton"
                onClick={addAiAssistantActionResult}
                disabled={isFirstRevisionSelected && selectedAction === AiAssistantAction.compare}
              >
                {t("AttachmentAiAssistant.go")}
              </AiAssistantActionSubmitButton>
            </span>
          </Tooltip>
        )}
      </Stack>
      <Divider flexItem sx={{ mx: -3 }} />
      {sectionsByName.length !== 0 && (
        <Stack flex={1} spacing={3} width="100%" minHeight={0} overflow="auto">
          {sectionsByName.map((section) => (
            <Box ref={sectionAnchorRefsRef.current.get(section.name)}>{section.component}</Box>
          ))}
        </Stack>
      )}
    </Stack>
  );
};

const AiAssistantDrawerContentTitle = () => {
  return <Typography color={TakeTurnsColors.aiAssistant}>AI Assistant</Typography>;
};

const AiAssistantActionSelector = (props: {
  value: AiAssistantAction | "";
  onChange: (event: SelectChangeEvent<AiAssistantAction>, child: ReactNode) => void;
}) => {
  const { t } = useSharedCollaborationTranslation();

  return (
    <Select
      autoWidth
      displayEmpty
      data-cy="AIAssistantFeatureSelector"
      input={<BootstrapInput />}
      onChange={props.onChange}
      renderValue={(selectedAction) => <Value selectedAction={selectedAction} />}
      IconComponent={(iconProps) => <IconChevronDown size={16} color={TakeTurnsColors.darkGray} {...iconProps} />}
      value={props.value}
    >
      {Object.entries(AiAssistantAction).map(([key, value]) => (
        <MenuItem data-cy={`AiAssistantOption.${key}`} key={key} value={value}>
          {t(`AttachmentAiAssistant.${key}`)}
        </MenuItem>
      ))}
    </Select>
  );
};

const Value = (props: { selectedAction: "" | AiAssistantAction }) => {
  const { t } = useSharedCollaborationTranslation();
  if (!props.selectedAction) {
    return (
      <Box
        component="span"
        style={{ color: TakeTurnsColors.aiAssistant }}
      >{`${t("AttachmentAiAssistant.selectAction")}`}</Box>
    );
  }
  const index = Object.values(AiAssistantAction).indexOf(props.selectedAction as AiAssistantAction);
  return <Box component="span">{t(`AttachmentAiAssistant.${Object.keys(AiAssistantAction)[index]}`)}</Box>;
};

const BootstrapInput = styled(InputBase)(() => ({
  "& .MuiInputBase-input": {
    boxSizing: "border-box",
    height: "30px",
    color: TakeTurnsColors.aiAssistant,
    border: `1px solid ${TakeTurnsColors.aiAssistant}`,
    padding: "7px 38px 7px 10px",
    fontSize: 14,
    lineHeight: "1.2em",
  },
}));

const AiAssistantActionSubmitButton = styled(Button)({
  borderRadius: "5px",
  padding: "7px 10px",
  minWidth: "unset",
  backgroundColor: TakeTurnsColors.aiAssistant,
  "&:hover": {
    backgroundColor: TakeTurnsColors.aiAssistant,
  },
});

const useFetchAiAssistantDrawerContentDataRule = () => {
  const {
    chatGptApiKey,
    loading: loadingChatGptApiKey,
    error: errorWhileFetchingChatGptApiKey,
  } = useFetchChatGptApiKey();
  const fetchLoadingManager = new FetchLoadingManager([loadingChatGptApiKey], [errorWhileFetchingChatGptApiKey]);

  if (fetchLoadingManager.hasFetchInError()) {
    fetchLoadingManager.logErrors();
  }

  return {
    chatGptApiKey,
    loading: fetchLoadingManager.hasFetchLoading(),
    error: fetchLoadingManager.hasFetchInError(),
  };
};
