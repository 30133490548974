/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useMoveFolderIntoFolderInCreation } from "@taketurns-repositories/collaboration/state/write/useMoveFolderIntoFolderInCreation";

export function useMoveFolderIntoFolderInCreationRule(folderId: string) {
  const moveFolderIntoFolder = useMoveFolderIntoFolderInCreation(folderId);
  return (destinationFolderId: string) => {
    moveFolderIntoFolder(destinationFolderId);
  };
}
