/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Dialog } from "@mui/material";
import React from "react";
import {
  DesktopAttachmentDetailsDialogContent,
} from "@taketurns-components/collaboration/CollaborationView/AttachmentDetails/components/DesktopAttachmentDetails/DesktopAttachmentDetailsDialogContent";
import { ConfirmBeforeClosingDialog } from "@taketurns-components/document/FilePreview/PdfViewer/ConfirmBeforeClosingDialog";
import { useConfirmCloseDetailsOnAnnotationUnsaved } from "@taketurns-rules/collaboration/commands/collaborationView/useConfirmCloseDetailsOnAnnotationUnsavedRule";
import { useIsAttachmentDetailsOpenRule } from "@taketurns-rules/collaboration/queries/attachment/useIsAttachmentDetailsOpenRule";

export const DesktopAttachmentDetails = () => {
  const isAttachmentDetailsOpen = useIsAttachmentDetailsOpenRule();
  const {
    isPdfUpdated,
    setIsPdfUpdated,
    isConfirmBeforeClosingDialogOpen,
    confirmBeforeClose,
    closeConfirmBeforeClosingDialog,
    confirmClosingDialogs,
  } = useConfirmCloseDetailsOnAnnotationUnsaved();
  return (
    <Dialog
      disableEscapeKeyDown
      open={isAttachmentDetailsOpen}
      onClose={confirmBeforeClose}
      fullWidth
      maxWidth={false}
      PaperProps={{ sx: { height: "100%" } }}
    >
      <DesktopAttachmentDetailsDialogContent
        onCloseDialogClicked={confirmBeforeClose}
        isPdfUpdated={isPdfUpdated}
        onPdfUpdatedChange={setIsPdfUpdated}
      />
      <ConfirmBeforeClosingDialog
        isConfirmBeforeClosingDialogOpen={isConfirmBeforeClosingDialogOpen}
        onConfirm={confirmClosingDialogs}
        onCancel={closeConfirmBeforeClosingDialog}
      />
    </Dialog>
  );
};
