/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { SubscriptionPlanTier } from "@taketurns/taketurns-graphql-repository";
import i18n from "@taketurns-i18n/i18n";
import { useNavigateToSignUpRule } from "@taketurns-rules/user/commands/useNavigateToSignUpRule";
import { useGetSubscriptionPlanCurrencyRule } from "../queries/plan/getSubscriptionPlanCurrencyRule";
import { useGetSubscriptionPlanPeriodRule } from "../queries/plan/getSubscriptionPlanPeriodRule";
import { useGetSubscriptionPlanRegionRule } from "../queries/plan/getSubscriptionPlanRegionRule";
import { useStoreSelectedSubscriptionPlanRule } from "./useStoreSelectedSubscriptionPlanRule";

export const useSaveSelectedPlanAndRedirectToSignUpRule = () => {
  const storeSelectedSubscriptionPlan = useStoreSelectedSubscriptionPlanRule();
  const currency = useGetSubscriptionPlanCurrencyRule();
  const period = useGetSubscriptionPlanPeriodRule();
  const region = useGetSubscriptionPlanRegionRule();
  const navigateToSignUp = useNavigateToSignUpRule();

  return (selectedPlanTier: SubscriptionPlanTier) => {
    const stripeCheckoutUrlInput = {
      currency,
      region,
      period,
      language: i18n.language,
      tier: selectedPlanTier,
    };
    storeSelectedSubscriptionPlan(stripeCheckoutUrlInput);
    navigateToSignUp();
  };
};
