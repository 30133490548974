/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Checkbox, CircularProgress, DialogContent, DialogTitle, Divider, FormControlLabel, Stack, Typography } from "@mui/material";
import { DateTime } from "luxon";
import { ForwardedRef, forwardRef, Fragment, useCallback, useImperativeHandle, useRef, useState } from "react";
import { FetchLoadingManager } from "@taketurns-app/utils/FetchLoadingManager";
import { TakeTurnsDialogFooter } from "@taketurns-components/collaboration/Shared/content/TakeTurnsDialogFooter";
import { CollaborationDialog } from "@taketurns-components/collaboration/Shared/dialog/CollaborationDialog";
import { DialogContentWithRichTextField } from "@taketurns-components/collaboration/Shared/dialog/DialogContentWithRichTextField";
import { SomethingWentWrong } from "@taketurns-components/shared/SomethingWentWrong";
import { useCollaborationTranslation } from "@taketurns-i18n/collaboration/useCollaborationTranslation";
import { useCloseCollaborationByIdRule } from "@taketurns-rules/collaboration/commands/useCloseCollaborationByIdRule";
import { useFetchCollaborationDefaultGracePeriodRule } from "@taketurns-rules/collaboration/queries/collaborationView/useFetchCollaborationDefaultGracePeriodRule";
import { useFetchConnectedUserPartyHasPendingUpdatesRule } from "@taketurns-rules/collaboration/queries/context/useFetchConnectedUserPartyHasPendingUpdatesRule";
import { getLocalizedSmallDateString } from "@taketurns-rules/collaboration/utils/date/DateUtils";
import { HelpCenterSections } from "@taketurns-rules/commons/enum/helpCenterSectionsEnum";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { useIsOnMobileRule } from "@taketurns-rules/commons/theme/useIsOnMobileRule";
import { TakeTurnsDefaultButton } from "../../../../commons/button";
import { CollaborationParties } from "../../../CollaborationView/CollaborationParties";
import { WrapUpDescriptionTip } from "./WrapUpDescriptionTip";

interface WrapUpCollaborationDialogProps {
  collaborationId: string;
  open: boolean;
  onClose: () => void;
}

export const WrapUpCollaborationDialog = ({ collaborationId, onClose, open }: WrapUpCollaborationDialogProps) => {
  return (
    <CollaborationDialog open={open} onClose={onClose}>
      <WrapUpCollaboration collaborationId={collaborationId} onClose={onClose} />
    </CollaborationDialog>
  );
};

const WrapUpCollaboration = ({ collaborationId, onClose }: { collaborationId: string; onClose: () => void }) => {
  const { t } = useCollaborationTranslation("collaborationEdit");
  const isOnMobile = useIsOnMobileRule();
  const connectedUserPartyPendingUpdatesWarningRef = useRef<{ getShouldSendLastTurnUpdates: () => boolean }>(null);

  const { currentComment, setCurrentComment, closeCollaboration, closeCollaborationLoading } =
    useCommentAndCloseCollaboration(collaborationId, onClose);

  const { localizedGracePeriodEndDate, connectedUserPartyHasPendingUpdates, loading, error } =
    useFetchWrapUpCollaborationData(collaborationId);

  const closeCollaborationCommand = useCallback(() => {
    if (connectedUserPartyHasPendingUpdates) {
      closeCollaboration(connectedUserPartyPendingUpdatesWarningRef.current.getShouldSendLastTurnUpdates());
    } else {
      closeCollaboration(false);
    }
  }, [closeCollaboration, connectedUserPartyHasPendingUpdates]);

  if (error) {
    return <SomethingWentWrong />;
  }
  if (loading) {
    return (
      <DialogContent sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
        <CircularProgress />
      </DialogContent>
    );
  }
  return (
    <Fragment>
      <DialogTitle>
        <Stack direction="row">
          <Typography variant="h6" flex={1} textAlign={{ xs: "center", md: "start" }}>
            {t("close.title")}
          </Typography>
          {!isOnMobile && <WrapUpDescriptionTip style={{ alignSelf: "end" }} />}
        </Stack>
      </DialogTitle>
      <Divider />
      <DialogContentWithRichTextField onChange={setCurrentComment} value={currentComment} placeholder={t("close.note")}>
        {connectedUserPartyHasPendingUpdates && (
          <ConnectedUserPartyPendingUpdatesWarning ref={connectedUserPartyPendingUpdatesWarningRef} />
        )}
        <Stack direction={{ xs: "column", md: "row" }} justifyContent={"space-between"}>
          <CollaborationParties prefixText={t("close.addNoteTo")} collaborationId={collaborationId} />
          <Typography sx={{ color: TakeTurnsColors.darkGray }}>
            {t("close.expiresOn")} : <span style={{ color: "black" }}>{localizedGracePeriodEndDate}</span>
          </Typography>
        </Stack>
      </DialogContentWithRichTextField>
      <TakeTurnsDialogFooter
        closeDialog={onClose}
        helpCenterSection={HelpCenterSections.CLOSE_REOPEN_COLLABORATION}
        hasCancelAction
      >
        <TakeTurnsDefaultButton disabled={closeCollaborationLoading} onClick={closeCollaborationCommand}>
          {t("button.confirm")}
        </TakeTurnsDefaultButton>
      </TakeTurnsDialogFooter>
    </Fragment>
  );
};

const useCommentAndCloseCollaboration = (collaborationId: string, onClose: () => void) => {
  const commentRef = useRef<string>("");
  const { closeCollaborationByIdCommand, closeCollaborationLoading } = useCloseCollaborationByIdRule();
  const closeCollaboration = useCallback(
    (endTurnBeforeClosing: boolean) => {
      closeCollaborationByIdCommand({ collaborationId, note: commentRef.current, endTurnBeforeClosing }, onClose);
    },
    [closeCollaborationByIdCommand, collaborationId, onClose],
  );
  const setCurrentComment = useCallback((comment: string) => {
    commentRef.current = comment;
  }, []);
  return { currentComment: commentRef.current, setCurrentComment, closeCollaboration, closeCollaborationLoading };
};

function useFetchWrapUpCollaborationData(collaborationId: string) {
  const {
    localizedGracePeriodEndDate,
    loadingLocalizedGracePeriodEndDate,
    errorOnFetchingLocalizedGracePeriodEndDate,
  } = useFetchLocalizedGracePeriodEndDate(collaborationId);

  const {
    connectedUserPartyHasPendingUpdates,
    loadingConnectedUserPartyHasPendingUpdates,
    errorOnFetchingConnectedUserPartyHasPendingUpdates,
  } = useFetchConnectedUserPartyHasPendingUpdatesRule(collaborationId);

  const fetchLoadingManager = new FetchLoadingManager(
    [loadingLocalizedGracePeriodEndDate, loadingConnectedUserPartyHasPendingUpdates],
    [errorOnFetchingLocalizedGracePeriodEndDate, errorOnFetchingConnectedUserPartyHasPendingUpdates],
  );

  if (fetchLoadingManager.hasFetchInError()) {
    fetchLoadingManager.logErrors();
  }

  return {
    localizedGracePeriodEndDate,
    connectedUserPartyHasPendingUpdates,
    loading: fetchLoadingManager.hasFetchLoading(),
    error: fetchLoadingManager.hasFetchInError(),
  };
}

const useFetchLocalizedGracePeriodEndDate = (collaborationId: string) => {
  const { defaultGracePeriodInDays, loadingDefaultGracePeriod, errorOnFetchingDefaultGracePeriod } =
    useFetchCollaborationDefaultGracePeriodRule(collaborationId);
  const localizedGracePeriodEndDate = getLocalizedSmallDateString(
    DateTime.now().plus({ days: defaultGracePeriodInDays }),
  );
  return {
    localizedGracePeriodEndDate,
    loadingLocalizedGracePeriodEndDate: loadingDefaultGracePeriod,
    errorOnFetchingLocalizedGracePeriodEndDate: errorOnFetchingDefaultGracePeriod,
  };
};

const ConnectedUserPartyPendingUpdatesWarning = forwardRef((_, ref: ForwardedRef<unknown>) => {
  const { t } = useCollaborationTranslation("collaborationEdit");
  const [confirmSendLastTurnUpdates, setConfirmSendLastTurnUpdates] = useState<boolean>(true);
  useImperativeHandle(
    ref,
    () => ({
      getShouldSendLastTurnUpdates: () => confirmSendLastTurnUpdates,
    }),
    [confirmSendLastTurnUpdates],
  );

  const confirmSendLastTurnUpdatesChange = useCallback(() => {
    setConfirmSendLastTurnUpdates((previousConfirmSendLastTurnUpdates: boolean) => !previousConfirmSendLastTurnUpdates);
  }, []);
  return (
    <WarningCheckbox
      label={t("close.connectedUserPartyPendingUpdatesWarning")}
      checked={confirmSendLastTurnUpdates}
      onClick={() => confirmSendLastTurnUpdatesChange()}
    />
  );
});
ConnectedUserPartyPendingUpdatesWarning.displayName = "ConnectedUserPartyPendingUpdatesWarning";

const WarningCheckbox = ({ label, checked, onClick }: { label: string; checked: boolean; onClick: () => void }) => {
  return (
    <FormControlLabel
      label={
        <Typography variant="body2" textAlign="justify">
          {label}
        </Typography>
      }
      control={
        <Checkbox
          disableRipple
          disableFocusRipple
          checked={checked}
          onClick={onClick}
          size="small"
          style={{
            color: TakeTurnsColors.darkGray,
          }}
        />
      }
      sx={{ marginBottom: { xs: 1, md: 0 } }}
    />
  );
};
