/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { SubscriptionPlanPeriod, SubscriptionPlanTier } from "@taketurns/taketurns-graphql-repository";
import { useFetchPlanSwitchingInvoice } from "@taketurns-repositories/user/graphql/queries/useFetchPlanSwitchingInvoice";

export const useFetchPlanSwitchingInvoiceRule = (
  subscriptionPlanTier: SubscriptionPlanTier,
  subscriptionPlanPeriod: SubscriptionPlanPeriod,
  skipQuery?: boolean,
) => {
  return useFetchPlanSwitchingInvoice(subscriptionPlanTier, subscriptionPlanPeriod, skipQuery);
};
