/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useFetchCollaborationFeatures } from "./useFetchCollaborationFeatures";

export const useFetchCollaborationFileUploadFeatures = (collaborationId: string) => {
  const { collaborationFeatures } = useFetchCollaborationFeatures(collaborationId);
  return {
    totalSpaceInMB: collaborationFeatures?.totalSpaceInMB ?? 1000,
    maxFileSizeInMB: collaborationFeatures?.maxFileSizeInMB ?? 100,
  };
};
