/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Context, createContext, useContext } from "react";

export const ChatContext: Context<{ collaborationId: string }> = createContext({ collaborationId: "" });

export const useChatContext = () => {
  return useContext(ChatContext);
};
