/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useQuery } from "@apollo/client";
import { Attachment } from "@taketurns/taketurns-graphql-repository";

export const GET_ATTACHMENT_REVISION_STATUS = gql`
  query getAttachmentRevisionStatus($collaborationId: ID!, $attachmentId: ID!) {
    getAttachment(collaborationId: $collaborationId, attachmentId: $attachmentId) {
      id
      status
      hasAttachmentPendingRevision
    }
  }
`;

export const useFetchAttachmentRevisionStatus = (collaborationId: string, attachmentId: string) => {
  const {
    data,
    loading: loadingAttachmentRevisionStatus,
    error: errorOnFetchingAttachmentRevisionStatus,
  } = useQuery<{ getAttachment: Attachment }>(GET_ATTACHMENT_REVISION_STATUS, {
    fetchPolicy: "cache-and-network",
    variables: { collaborationId, attachmentId },
  });
  return {
    folder: data?.getAttachment,
    loadingFolder: loadingAttachmentRevisionStatus,
    errorOnFetchingAttachmentRevisionStatus,
  };
};
