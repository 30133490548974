/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Revision } from "@taketurns/taketurns-graphql-repository";
import { useFetchDocumentRevisions } from "@taketurns-repositories/document/graphql/queries/useFetchDocumentRevisions";

// prettier-ignore
const FORMAT_SUPPORTED_FOR_SUMMARY_OR_COMPARISON = [
  "pdf", "jpeg", "jpg", "png", "tiff", "tif", "bib", "doc", "docx", "fodt", "ltx", "txt", "odt", "ott", "pdb", "psw", "rtf", "sdw", "stw", "sxw", "uot", "vor", "wps", "epub",
  "bmp", "emf", "eps", "fodg", "met", "odd", "otg", "pbm", "pct", "pgm", "ppm", "ras", "std", "svg", "svm", "swf", "sxd", "tiff", "xpm", "fodp", "potm", "pot", "pptx", "pps",
  "ppt", "pwp", "sda", "sdd", "sti", "sxi", "uop", "wmf", "csv", "dbf", "dif", "fods", "ods", "ots", "pxl", "sdc", "slk", "stc", "sxc", "uos", "xls", "xlt", "xlsx", "tif", "odp", "odg", "dotx", "xltx",
];

export const useFetchIsRevisionExtensionSupportedForSummaryOrCompare = ({
  collaborationId,
  documentId,
  revisionId,
}: {
  collaborationId: string;
  documentId: string;
  revisionId: string;
}) => {
  const { revisions, loadingDocumentRevisions, errorOnFetchingDocumentRevisions } = useFetchDocumentRevisions(
    collaborationId,
    documentId,
  );
  const documentRevision = revisions?.find((revision: Revision) => revision.revisionId === revisionId);
  const documentRevisionExtension = documentRevision?.extension;
  return {
    isExtensionSupportedForSummaryOrCompare: FORMAT_SUPPORTED_FOR_SUMMARY_OR_COMPARISON.includes(
      documentRevisionExtension?.toLowerCase(),
    ),
    loadingDocumentRevisions,
    errorOnFetchingDocumentRevisions,
  };
};
