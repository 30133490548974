/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { ApolloError } from "@apollo/client";
import { useCallback } from "react";
import { useOrganizationInvitationNotificationTranslation } from "@taketurns-i18n/user/UserNotificationCenter/OrganizationInvitationNotificationAndDialog/useOrganizationInvitationNotificationTranslation";
import { useAcceptOrganizationInvitation } from "@taketurns-repositories/user/graphql/mutations/useAcceptOrganizationInvitation";
import { useDisplayAndLogNotificationErrorRule } from "./useDisplayAndLogNotificationErrorRule";
import { useDisplayNotificationSuccessRule } from "./useDisplayNotificationSuccessRule";

export const useAcceptOrganizationInvitationRule = () => {
  const {
    acceptOrganizationInvitation: acceptOrganizationInvitationCommand,
    errorOnAcceptOrganizationInvitation,
    loadingAcceptOrganizationInvitation,
  } = useAcceptOrganizationInvitation();

  const displayNotificationError = useDisplayAndLogNotificationErrorRule();
  const displayNotificationSuccess = useDisplayNotificationSuccessRule();

  const { t } = useOrganizationInvitationNotificationTranslation();

  const displayOrganizationAcceptedMessage = () => {
    displayNotificationSuccess(t("Notifications.organizationInvitationAccepted"));
  };

  const displayNotificationErrorMessage = (error: ApolloError) => {
    console.error(error);
    displayNotificationError(error);
  };

  const acceptOrganizationInvitation = useCallback((organizationId: string) => {
    return acceptOrganizationInvitationCommand(organizationId)
      .then(() => displayOrganizationAcceptedMessage())
      .catch((error) => {
        displayNotificationErrorMessage(error);
        throw error;
      });
  }, []);

  return { acceptOrganizationInvitation, loadingAcceptOrganizationInvitation, errorOnAcceptOrganizationInvitation };
};
