/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useMutation } from "@apollo/client";
import {
  MutationRevokeInvitationArgs,
  ParticipantsAndInvitations,
  RevokeInvitationInput,
} from "@taketurns/taketurns-graphql-repository";
import { collaborationParticipantsFragment } from "@taketurns-repositories/collaboration/graphql/fragments/collaborationParticipantsFragment";

export const useRevokeInvitationMutation = () => {
  const mutation = gql`
    mutation revokeInvitation($input: RevokeInvitationInput!) {
      revokeInvitation(input: $input) {
        ...CollaborationParticipantsFields
      }
    }
    ${collaborationParticipantsFragment}
  `;
  const [revokeInvitationMutation, { error: errorOnRevokingInvitation, loading: loadingRevokeInvitation }] =
    useMutation<
      {
        revokeInvitation: ParticipantsAndInvitations;
      },
      MutationRevokeInvitationArgs
    >(mutation);
  const revokeInvitationCommand = (input: RevokeInvitationInput, onRevokeInvitationSuccess?: () => void) => {
    revokeInvitationMutation({ variables: { input }, onCompleted: onRevokeInvitationSuccess });
  };

  return { revokeInvitationCommand, errorOnRevokingInvitation, loadingRevokeInvitation };
};
