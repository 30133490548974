/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { AppBar, Toolbar } from "@mui/material";
import { memo, ReactNode } from "react";

interface MobileHeaderProps {
  header: ReactNode;
  leftComponent: ReactNode;
  rightComponent: ReactNode;
}

export const MobileBodyLayoutHeader = memo((props: MobileHeaderProps) => {
  const { header, leftComponent, rightComponent } = props;

  return (
    <AppBar position="static">
      <Toolbar
        disableGutters
        sx={{
          justifyContent: "space-between",
          paddingX: "24px",
        }}
      >
        {leftComponent}

        {header}

        {rightComponent}
      </Toolbar>
    </AppBar>
  );
});
