/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Button } from "@mui/material";
import { IconCheckCircle } from "@taketurns-components/commons/icons";
import { useSharedCollaborationTranslation } from "@taketurns-i18n/collaboration/shared/useSharedCollaborationTranslation";
import { useEnableContentSelectionStateRule } from "@taketurns-rules/collaboration/commands/collaborationView/contentSelection/useEnableContentSelectionStateRule";
import { useIsOnMobileRule } from "@taketurns-rules/commons/theme/useIsOnMobileRule";

export const EnableContentSelectionButton = () => {
  const { t } = useSharedCollaborationTranslation();
  const isOnMobile = useIsOnMobileRule();
  const enableContentSelection = useEnableContentSelectionStateRule();

  const selectTranslationKey = isOnMobile ? "select" : "selectContent";

  return (
    <Button
      variant="text"
      disableRipple
      onClick={enableContentSelection}
      startIcon={<IconCheckCircle size={24} />}
      data-cy="ContentSelection.EnableButton"
    >
      {t(`SelectContent.${selectTranslationKey}`)}
    </Button>
  );
};
