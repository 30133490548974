/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useDecodeParamsInCurrentUrlRule } from "./useDecodeParamsInCurrentUrlRule";

interface RecipientEmailParams {
  isInvitationLink: boolean;
  recipient: {
    email: string;
  };
}

export const useRecipientEmailFromParamsIfInvitationRule = () => {
  const params = useDecodeParamsInCurrentUrlRule<RecipientEmailParams>();
  if (params && params.isInvitationLink && params.recipient?.email) return params.recipient.email;
};
