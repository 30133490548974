/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { List } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useShouldDisplayOrganizationAccessPoliciesRule } from "@taketurns-app/layouts/authenticated/AuthenticatedLayout/rules/useShouldDisplayOrganizationAccessPoliciesRule";
import {
  IconClipboard,
  IconCog,
  IconIntegrations,
  IconUser,
  IconUserCheck,
  IconUsers,
} from "@taketurns-components/commons/icons";
import { useWebAppTranslations } from "@taketurns-i18n/webapp/useWebAppTranslations";
import {
  ACCESS_POLICIES_SECTION,
  INTEGRATIONS_SECTION,
  MANAGE_PLAN_SECTION,
  ORGANIZATION_SECTION,
  PREFERENCES_SECTION,
  PROFILE_SECTION,
  USER_SETTINGS_SEARCH_PARAM,
  UserSettingsSection,
} from "../../../routing/routes/userSettingsSections";
import { SettingsListItem } from "./SettingsListItem";

export const SettingSectionTabs = () => {
  const { t } = useWebAppTranslations("settings");

  const shouldDisplayOrganizationAccessPolicies = useShouldDisplayOrganizationAccessPoliciesRule();

  const [searchParam, setSearchParam] = useSearchParams();
  const activeSection = searchParam.get(USER_SETTINGS_SEARCH_PARAM);

  const changeSettingsSection = (section: UserSettingsSection) => {
    setSearchParam({ section });
  };

  return (
    <List disablePadding>
      <SettingsListItem
        active={activeSection === PROFILE_SECTION || activeSection === undefined}
        label={t("profile")}
        section={PROFILE_SECTION}
        icon={<IconUser size={24} />}
        onClick={() => changeSettingsSection(PROFILE_SECTION)}
      />
      <SettingsListItem
        active={activeSection === MANAGE_PLAN_SECTION}
        label={t("plan")}
        section={MANAGE_PLAN_SECTION}
        icon={<IconClipboard size={24} />}
        onClick={() => changeSettingsSection(MANAGE_PLAN_SECTION)}
      />
      <SettingsListItem
        active={activeSection === ORGANIZATION_SECTION}
        label={t("organization")}
        section={ORGANIZATION_SECTION}
        icon={<IconUsers size={24} />}
        onClick={() => changeSettingsSection(ORGANIZATION_SECTION)}
      />
      {shouldDisplayOrganizationAccessPolicies && (
        <SettingsListItem
          active={activeSection === ACCESS_POLICIES_SECTION}
          label={t("accessPolicies")}
          section={ACCESS_POLICIES_SECTION}
          icon={<IconUserCheck size={24} />}
          onClick={() => changeSettingsSection(ACCESS_POLICIES_SECTION)}
        />
      )}
      <SettingsListItem
        active={activeSection === PREFERENCES_SECTION}
        label={t("preferences")}
        section={PREFERENCES_SECTION}
        icon={<IconCog size={24} />}
        onClick={() => changeSettingsSection(PREFERENCES_SECTION)}
      />
      <SettingsListItem
        active={activeSection === INTEGRATIONS_SECTION}
        label={t("integrations")}
        section={INTEGRATIONS_SECTION}
        icon={<IconIntegrations size={24} />}
        onClick={() => changeSettingsSection(INTEGRATIONS_SECTION)}
      />
    </List>
  );
};
