/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useUserPlanTranslation } from "@taketurns-i18n/user/UserPlans/useUserPlanTranslation";
import { useFetchUserSubscriptionPlanTierAndPeriod } from "@taketurns-repositories/user/graphql/queries/useFetchUserSubscriptionPlanTierAndPeriod";

export const useFetchUserSubscriptionPlanTierAndPeriodRule = () => {
  const { t } = useUserPlanTranslation();
  const { planTier, period, loadingUserPlanTier, errorOnFetchingUserPlanTier } =
    useFetchUserSubscriptionPlanTierAndPeriod();
  return {
    planTierName: t(`plans.${planTier}`),
    planTier,
    loadingUserPlanTier,
    errorOnFetchingUserPlanTier,
    planPeriod: period,
  };
};
