/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Stack, Typography } from "@mui/material";
import { SubscriptionPlanTier } from "@taketurns/taketurns-graphql-repository";
import { TakeTurnsDefaultButton } from "@taketurns-components/commons/button";
import { ContactSalesButtonAndDialog } from "@taketurns-components/user/UserPlans/SubscriptionPlans/ContactSalesButtonAndDialog";
import { useUserPlanTranslation } from "@taketurns-i18n/user/UserPlans/useUserPlanTranslation";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { useNavigateToPlansPageRule } from "@taketurns-rules/user/commands/useNavigateToPlansPageRule";

interface UpgradePlanPropositionProps {
  proposition: string;
  planTier: SubscriptionPlanTier;
}

export const UpgradePlanProposition = (props: UpgradePlanPropositionProps) => {
  const redirectToPlanPage = useNavigateToPlansPageRule();

  const { t } = useUserPlanTranslation();

  return (
    <Stack direction={{ xs: "column", md: "row" }} spacing={2} alignItems={"center"}>
      <Typography variant={"body2"} color={TakeTurnsColors.darkGray}>
        {props.proposition}{" "}
        <span style={{ fontWeight: 600, color: TakeTurnsColors.darkBlue }}>{t(`plans.${props.planTier}`)}</span>
      </Typography>
      {props.planTier < SubscriptionPlanTier.T3 ? (
        <TakeTurnsDefaultButton sx={{ flex: "0 0 auto" }} onClick={redirectToPlanPage}>
          {t(`plans.upgradeButtonLabel.${props.planTier}`)}
        </TakeTurnsDefaultButton>
      ) : (
        <ContactSalesButtonAndDialog mainAction={true} />
      )}
    </Stack>
  );
};
