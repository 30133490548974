/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useEffect } from "react";
import { useSetSelectedSupervisorFilterItems } from "@taketurns-repositories/collaboration/state/write/useSetSelectedSupervisorFilterItems";
import { useFetchConnectedUserRule } from "@taketurns-rules/user/queries/useFetchConnectedUserRule";

export const useSetDefaultSelectedSupervisorFilterItemsRule = () => {
  const { connectedUser, errorOnFetchingConnectedUser, loadingConnectedUser } = useFetchConnectedUserRule();
  const setSelectedFilterItems = useSetSelectedSupervisorFilterItems();

  useEffect(() => {
    if (!loadingConnectedUser && !errorOnFetchingConnectedUser) {
      setSelectedFilterItems([connectedUser.id]);
    }
  }, [connectedUser, errorOnFetchingConnectedUser, loadingConnectedUser, setSelectedFilterItems]);
};
