/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useRecoilValue } from "recoil";
import { subscriptionRefreshCountState } from "@taketurns-repositories/webapp/state/recoil/subscriptionRefreshCountState";

export const useGetSubscriptionRefreshCount = () => {
  return useRecoilValue(subscriptionRefreshCountState);
};
