/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { DesktopRequestFulfillmentDialog } from "@taketurns-components/collaboration/CollaborationView/CollaborationContent/RequestFulfillement/DesktopRequestFulfillmentDialog";
import { MobileRequestFulfillmentDrawer } from "@taketurns-components/collaboration/CollaborationView/CollaborationContent/RequestFulfillement/MobileRequestFulfillmentDrawer";
import { useIsOnMobileRule } from "@taketurns-rules/commons/theme/useIsOnMobileRule";

export const ResponsiveRequestFulfillmentDialogOrDrawer = () => {
  const isOnMobile = useIsOnMobileRule();
  if (isOnMobile) {
    return <MobileRequestFulfillmentDrawer />;
  }
  return <DesktopRequestFulfillmentDialog />;
}
