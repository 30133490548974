/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { atomFamily } from "recoil";
import { FolderInCreation } from "../../../models/folderInCreation";

export const ROOT_FOLDER_ID = "/";
export const folderByIdState = atomFamily<FolderInCreation, string>({
  key: "folderById",
  default: { name: ROOT_FOLDER_ID, attachments: [], requests: [], folders: [] },
});
