/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Auth } from "aws-amplify";
import { PropsWithChildren, useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AUTHENTICATED_ROUTES } from "@taketurns-app/routing/routes/authenticatedRoutes.constants";
import i18n from "@taketurns-i18n/i18n";
import { useSharedWebappTranslation } from "@taketurns-i18n/webapp/shared/useSharedWebappTranslation";
import { useApolloClient } from "@taketurns-repositories/webapp/aws/apolloClient";
import { clearHasWelcomeDialogeBeenSeenFromLocalStorage } from "@taketurns-repositories/webapp/localStorage/write/clearHasWelcomDialogBeenSeenFromLocalStorage";
import { clearPlansHaveBeenSeenFromLocalStorage } from "@taketurns-repositories/webapp/localStorage/write/clearPlansHaveBeenSeenFromLocalStorage";
import { clearIsComingFromInvitationToCollaborationLink } from "@taketurns-repositories/webapp/sessionStorage/isComingFromInvitationToCollaborationLink";
import { getLoggedInReturnPath } from "@taketurns-repositories/webapp/sessionStorage/loggedInReturnPath";
import { clearInvitationToOrganization } from "@taketurns-repositories/webapp/sessionStorage/write/clearInvitationToOrganization";
import { AuthContext, AuthContextType } from "@taketurns-repositories/webapp/state/context/AuthContext";
import { useDisplayNotificationSuccessRule } from "@taketurns-rules/user/commands/useDisplayNotificationSuccessRule";
import { resetLoggedInReturnPathRule } from "@taketurns-rules/webapp/commands/resetLoggedInReturnPathRule";
import { useAcceptOrganizationInvitationAndGetRedirectDestinationRule } from "@taketurns-rules/webapp/commands/useAcceptOrganizationInvitationAndGetRedirectDestinationRule";
import { getConnectedUserAttributes } from "@taketurns-rules/webapp/queries/getConnectedUserAttributes";
import { getInvitationToOrganizationRule } from "@taketurns-rules/webapp/queries/getInvitationToOrganizationRule";
import { useDecodeParamsRule } from "@taketurns-rules/webapp/queries/useDecodeParamsRule";
import { useEncodeParamsRule } from "@taketurns-rules/webapp/queries/useEncodeParamsRule";
import { AccessMode } from "./guards/RedirectFromParamsGuard";
import { PUBLIC_ROUTES } from "./routes/publicRoutes.constants";

export const USER_NOT_CONFIRMED_ERROR_CODE = "UserNotConfirmedException";

type AuthContextState = Pick<AuthContextType, "isAuthenticated" | "isUserFirstVisit">;

export const AuthProvider = ({ children }: PropsWithChildren) => {
  const [authContext, setAuthContext] = useState<AuthContextState>({
    isAuthenticated: false,
    isUserFirstVisit: false,
  });
  const { userLoggedInLoading } = useIsUserLoggedInOnFirstMount(setAuthContext);
  const { login, loginError, loginLoading } = useLogin(setAuthContext);
  const logout = useLogout(setAuthContext);
  const getAccessToken = useGetCognitoAccessToken();

  const passwordLessProcess = usePasswordlessRule(setAuthContext);
  const resetPasswordProcess = useResetPasswordRule(setAuthContext);

  if (userLoggedInLoading) return null;

  const authContextValue: AuthContextType = {
    isUserFirstVisit: authContext.isUserFirstVisit,
    isAuthenticated: authContext.isAuthenticated,
    login,
    loginError,
    loginLoading,
    logout,
    getAccessToken,
    passwordLessProcess,
    resetPasswordProcess,
  };
  return <AuthContext.Provider value={authContextValue}>{children}</AuthContext.Provider>;
};

const useIsUserLoggedInOnFirstMount = (setAuthContext: (value: AuthContextState) => void) => {
  const [userLoggedInLoading, setUserLoggedInLoading] = useState(true);
  const navigateAfterLogin = useNavigateAfterLoginRule();
  const refreshAuthContext = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      if (user) {
        const { isUserFirstAuth } = await getConnectedUserAttributes();
        setAuthContext({ isAuthenticated: true, isUserFirstVisit: isUserFirstAuth });
        navigateAfterLogin();
      }
    } catch (error) {
      setAuthContext({ isAuthenticated: false, isUserFirstVisit: false });
    }
  };

  const retrieveCurrentUserRef = useRef(refreshAuthContext);
  useEffect(() => {
    retrieveCurrentUserRef.current().then(() => {
      setUserLoggedInLoading(false);
    });
  }, []);
  return { userLoggedInLoading };
};

const useLogin = (setAuthContext: (value: AuthContextState) => void) => {
  const acceptOrganizationInvitationAndGetRedirectDestination =
    useAcceptOrganizationInvitationAndGetRedirectDestinationInAuthProviderRule();

  const navigateAfterLogin = useNavigateAfterLoginRule();
  const navigate = useNavigate();
  const [loginError, setLoginError] = useState<{ code: string; message: string } | null>(null);
  const [loginLoading, setLoginLoading] = useState<boolean>(false);
  const login = async (username: string, password: string) => {
    try {
      setLoginLoading(true);
      setLoginError(null);
      await Auth.signIn(username, password);
      const { isUserFirstAuth } = await getConnectedUserAttributes();
      const invitationToOrganization = getInvitationToOrganizationRule();
      clearInvitationToOrganization();
      if (invitationToOrganization) {
        const destination = await acceptOrganizationInvitationAndGetRedirectDestination(
          invitationToOrganization.organization.id,
          isUserFirstAuth,
        );
        setAuthContext({ isAuthenticated: true, isUserFirstVisit: isUserFirstAuth });
        navigate(destination);
      } else {
        setAuthContext({ isAuthenticated: true, isUserFirstVisit: isUserFirstAuth });
        navigateAfterLogin();
      }
    } catch (signInError) {
      let error = signInError;
      const errorName = (signInError as { name: string }).name;
      if (errorName === "NotAuthorizedException") {
        error = new Error("Incorrect username or password. (error TT_10005)", signInError);
      }
      console.log(JSON.stringify(error));
      console.error(error);
      setLoginError(error as unknown as { code: string; message: string });
    } finally {
      setLoginLoading(false);
    }
  };
  return { login, loginError, loginLoading };
};

const useAcceptOrganizationInvitationAndGetRedirectDestinationInAuthProviderRule = () => {
  const acceptOrganizationInvitationAndGetRedirectDestination =
    useAcceptOrganizationInvitationAndGetRedirectDestinationRule();

  return async (organizationId: string, isUserFirstAuth: boolean) => {
    return await acceptOrganizationInvitationAndGetRedirectDestination(organizationId, isUserFirstAuth);
  };
};
const useNavigateAfterLoginRule = () => {
  const navigate = useNavigate();
  const returnPathWithoutAccessMode = useReturnPathWithoutAccessMode();
  const clearReturnPath = resetLoggedInReturnPathRule();

  return () => {
    if (returnPathWithoutAccessMode) {
      navigate(returnPathWithoutAccessMode);
      clearReturnPath();
    }
  };
};

const useReturnPathWithoutAccessMode = () => {
  const initialReturnPath = getLoggedInReturnPath();
  const decodeParams = useDecodeParamsRule<{ accessMode?: AccessMode; [otherParamsKey: string]: unknown }>();
  const encodeParams = useEncodeParamsRule();

  if (initialReturnPath) {
    let returnPath = initialReturnPath;
    const urlSearchParams = new URLSearchParams(returnPath.split("?")[1]);
    const params = decodeParams(urlSearchParams);
    if (!!params) {
      const { accessMode, ...otherParams } = params;
      const paramsWithoutAccessMode = encodeParams(otherParams);
      returnPath = `${returnPath.split("?")[0]}?params=${paramsWithoutAccessMode}`;
    }
    return returnPath;
  }
};

const useLogout = (setAuthContext: (value: AuthContextState) => void) => {
  const clearReturnPath = resetLoggedInReturnPathRule();
  const client = useApolloClient();
  const navigateAfterLogout = useNavigateAfterLogout();
  return async () => {
    try {
      clearPlansHaveBeenSeenFromLocalStorage();
      clearIsComingFromInvitationToCollaborationLink();
      clearHasWelcomeDialogeBeenSeenFromLocalStorage();
      await client.clearStore();
      await Auth.signOut();
      setAuthContext({ isAuthenticated: false, isUserFirstVisit: false });
      clearReturnPath();
      navigateAfterLogout();
    } catch (error) {
      console.error(error);
    }
  };
};

const useGetCognitoAccessToken = () => {
  return async () => (await Auth.currentSession()).getAccessToken().getJwtToken();
};

const useNavigateAfterLogout = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  return () => {
    navigate(PUBLIC_ROUTES.SIGN_IN, { state });
  };
};

const usePasswordlessRule = (setAuthContext: (value: AuthContextState) => void) => {
  const [error, setError] = useState<unknown | null>(null);
  const [loading, setLoading] = useState(false);
  const [cognitoUser, setCognitoUser] = useState(null);
  const navigateAfterLogin = useNavigateAfterLoginRule();

  const createAccountOrUseExistingAndSendAuthenticationCode = async (email: string) => {
    const randomPassword = generateRandomPassword(20);

    function generateRandomPassword(bytes: number) {
      const randomValues = new Uint8Array(bytes);
      window.crypto.getRandomValues(randomValues);
      return Array.from(randomValues).map(intToHex).join("Aa0*");

      function intToHex(nr: number) {
        return nr.toString(16).padStart(2, "0");
      }
    }

    let cognitoUser = null;
    setLoading(true);
    setError(null);
    try {
      await Auth.signUp({
        username: email,
        password: randomPassword,
        attributes: {
          "custom:passwordless": "true",
          "custom:language": i18n.language,
        },
      });
    } catch (error: unknown) {
      if ((error as { code: string }).code !== "UsernameExistsException") {
        console.error(JSON.stringify(error));
        setError(error);
      }
    }
    try {
      cognitoUser = await Auth.signIn(email);
      setCognitoUser(cognitoUser);
    } catch (error: unknown) {
      if ((error as { code: string }).code === USER_NOT_CONFIRMED_ERROR_CODE) {
        console.error(JSON.stringify(error));
        setError(error);
      }
    }
    setLoading(false);
  };

  const resendAuthenticationCode = async (email: string) => {
    let cognitoUser = null;
    setLoading(true);
    setError(null);
    try {
      cognitoUser = await Auth.signIn(email);
      setCognitoUser(cognitoUser);
    } catch (error: unknown) {
      if ((error as { code: string }).code === USER_NOT_CONFIRMED_ERROR_CODE) {
        console.error(JSON.stringify(error));
        setError(error);
      }
    } finally {
      setLoading(false);
    }
  };

  const loginWithAuthenticationCode = async (authenticationCode: string) => {
    if (!cognitoUser) {
      const error = {
        name: "NoCognitoAccount",
        code: "404",
        message: "No cognito account for custom challenge answer",
      };
      setError(error);
      setAuthContext({ isAuthenticated: false, isUserFirstVisit: false });
      return Promise.reject(error);
    }
    try {
      setLoading(true);
      setError(null);
      await Auth.sendCustomChallengeAnswer(cognitoUser, authenticationCode);
      const { isUserFirstAuth } = await getConnectedUserAttributes();
      setAuthContext({ isAuthenticated: true, isUserFirstVisit: isUserFirstAuth });
      navigateAfterLogin();
    } catch (signInError: unknown) {
      console.error(signInError);
      setError(signInError);
      setLoading(false);
      throw signInError;
    } finally {
      setLoading(false);
      setError(null);
    }
  };

  return {
    createAccountOrUseExistingAndSendAuthenticationCode,
    loginWithAuthenticationCode,
    resendAuthenticationCode,
    authenticationCodeSent: !!cognitoUser,
    error,
    loading,
  };
};

const useResetPasswordRule = (setAuthContext: (value: AuthContextState) => void) => {
  const [emailAddressWhereCodeWasSent, setEmailAddressWhereCodeWasSent] = useState<string | null>(null);
  const [error, setError] = useState<unknown | null>(null);
  const [loading, setLoading] = useState(false);
  const { t } = useSharedWebappTranslation();
  const displayNotificationSuccess = useDisplayNotificationSuccessRule();
  const navigate = useNavigate();

  const sendAuthenticationCode = async (email: string) => {
    setLoading(true);
    setError(null);
    try {
      await Auth.forgotPassword(email);
      setEmailAddressWhereCodeWasSent(email);
    } catch (error) {
      console.error(JSON.stringify(error));
      setError(error);
    }
    setLoading(false);
  };

  const sendNewPasswordAndAuthenticationCode = async (options: {
    email: string;
    newPassword: string;
    authenticationCode: string;
  }) => {
    setLoading(true);
    setError(null);
    try {
      await Auth.forgotPasswordSubmit(options.email, options.authenticationCode, options.newPassword);
      await Auth.signIn(options.email, options.newPassword);
    } catch (error) {
      console.error(JSON.stringify(error));
      setError(error);
      setLoading(false);
      return;
    }
    try {
      const { isUserFirstAuth } = await getConnectedUserAttributes();
      setAuthContext({ isAuthenticated: true, isUserFirstVisit: isUserFirstAuth });
      setEmailAddressWhereCodeWasSent(null);
      navigate(AUTHENTICATED_ROUTES.COLLABORATION_LIST);
      displayNotificationSuccess(t("resetPassword.passwordReset"));
    } catch (error) {
      console.error(error);
      setError(error);
    }
    setLoading(false);
  };

  return {
    sendAuthenticationCode,
    sendNewPasswordAndAuthenticationCode,
    emailAddressWhereCodeWasSent,
    error,
    loading,
  };
};

export const useAuthContext = () => {
  const authContext = useContext<AuthContextType | null>(AuthContext);
  if (!authContext) {
    throw new Error("useAuthContext has to be used within <AuthContext.Provider>");
  }
  return authContext;
};
