/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Box, Card, Stack, styled, Typography } from "@mui/material";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { SingleColumnLayout } from "@taketurns-app/layouts/body/content/SingleColumnLayout";
import { AUTHENTICATED_ROUTES } from "@taketurns-app/routing/routes/authenticatedRoutes.constants";
import { getUserSettingsSectionUrl, ORGANIZATION_SECTION } from "@taketurns-app/routing/routes/userSettingsSections";
import { TakeTurnsDefaultButton } from "@taketurns-components/commons/button";
import { UserSettings } from "@taketurns-components/user/UserProfile/UserSettings";
import { ExternalLinkStyled } from "@taketurns-components/webapp/LinkStyled";
import { useWebAppTranslations } from "@taketurns-i18n/webapp/useWebAppTranslations";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { useIsOnMobileRule } from "@taketurns-rules/commons/theme/useIsOnMobileRule";
import { useFetchUserHasOrganizationAdminAndMultiUserFeaturesRule } from "@taketurns-rules/webapp/queries/useFetchUserHasOrganizationAdminAndMultiUserFeaturesRule";
import { useGetHelpCenterUrls } from "@taketurns-rules/webapp/queries/useGetHelpCenterUrls";

export const CompleteProfile = () => {
  const isOnMobile = useIsOnMobileRule();

  const { t } = useWebAppTranslations("completeProfile");
  if (isOnMobile) {
    return <CompleteProfileMobile />;
  }
  return (
    <SingleColumnLayout
      header={<Typography variant="h6">{t("completeProfile")}</Typography>}
      content={
        <Box height={1} overflow="auto">
          <CompleteProfileContent />
        </Box>
      }
    />
  );
};

const CompleteProfileMobile = () => {
  const { t } = useWebAppTranslations("completeProfile");

  return (
    <CompleteProfile__MobileContainer className="CompleteProfile__MobileContainer">
      <CompleteProfile__MobileHeader className="CompleteProfile__MobileHeader">
        <Link to={AUTHENTICATED_ROUTES.COLLABORATION_LIST}>
          <img width={180} src={"/images/logos/LogoImageWithText.svg"} alt="TakeTurns logo" />
        </Link>
      </CompleteProfile__MobileHeader>
      <CompleteProfile__MobileContent className="CompleteProfile__MobileContent">
        <Stack alignItems="center" justifyContent="center" height={{ md: 130 }} minHeight={{ md: 130 }}>
          <Typography variant="h6">{t("completeProfile")}</Typography>
        </Stack>
        <CompleteProfileContent />
      </CompleteProfile__MobileContent>
    </CompleteProfile__MobileContainer>
  );
};

const CompleteProfile__MobileContainer = styled(Stack)({
  height: "100%",
});

const CompleteProfile__MobileHeader = styled(Box)({
  flex: "0 0 70px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const CompleteProfile__MobileContent = styled(Card)({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  gap: "24px",
  borderBottomRightRadius: 0,
  borderBottomLeftRadius: 0,
  overflow: "auto",
  minHeight: 0,
});

const CompleteProfileContent = () => {
  const { t } = useWebAppTranslations("completeProfile");
  const [userFormIsValid, setUserFormIsValid] = useState(true);

  return (
    <Stack spacing={3}>
      <UserSettings
        header={
          <Typography color={TakeTurnsColors.darkGray} textAlign={{ xs: "center", md: "left" }}>
            {t("completeProfileDescription")}
          </Typography>
        }
        userFormIsValid={setUserFormIsValid}
      />
      <CompleteProfileFooter userFormIsValid={userFormIsValid} />
    </Stack>
  );
};

const CompleteProfileFooter = ({ userFormIsValid }: { userFormIsValid: boolean }) => {
  const { helpCenterUrl } = useGetHelpCenterUrls();
  const { t } = useWebAppTranslations("completeProfile");
  const setNotFirstAuthAndNavigateToPage = useSetNotFirstAuthAndNavigateToPage();
  const { hasOrganizationAdmin, hasMultiUserOrganization, loadingUserHasOrganizationAdminAndMultiUserFeatures } =
    useFetchUserHasOrganizationAdminAndMultiUserFeaturesRule();

  if (loadingUserHasOrganizationAdminAndMultiUserFeatures) {
    return null;
  }

  const continueUsingTakeTurns = () => {
    if (setNotFirstAuthAndNavigateToPage) {
      if (hasMultiUserOrganization && hasOrganizationAdmin) {
        setNotFirstAuthAndNavigateToPage(getUserSettingsSectionUrl(ORGANIZATION_SECTION));
      } else {
        setNotFirstAuthAndNavigateToPage(AUTHENTICATED_ROUTES.COLLABORATION_LIST);
      }
    }
  };

  return (
    <Stack className="CompleteProfileFooter" alignItems="center" justifyContent={"space-between"} spacing={2}>
      <TakeTurnsDefaultButton data-cy="completeProfileForm.submit" onClick={continueUsingTakeTurns}>
        {t("doneButton")}
      </TakeTurnsDefaultButton>
      <Typography color={TakeTurnsColors.darkGray} fontSize={{ xs: "0.8rem", sm: "1rem" }}>
        {t("helperText.visit")}{" "}
        <ExternalLinkStyled href={helpCenterUrl} target="_blank">
          {t("helperText.helpCenter")}{" "}
        </ExternalLinkStyled>
        {t("helperText.learnMore")}
      </Typography>
    </Stack>
  );

  function useSetNotFirstAuthAndNavigateToPage() {
    const navigate = useNavigate();
    if (userFormIsValid) {
      return (page: string) => {
        navigate(page);
      };
    }
    return;
  }
};
