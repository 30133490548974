/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Navigate, useLocation } from "react-router-dom";
import { useSaveLoggedInReturnPath } from "@taketurns-rules/webapp/commands/useSaveLoggedInReturnPath";
import { useAuthContext } from "../AuthProvider";
import { PUBLIC_ROUTES } from "../routes/publicRoutes.constants";

export const AuthGuard = ({ children }: { children: JSX.Element }) => {
  const { isAuthenticated } = useAuthContext();
  const saveLoggedInReturnPath = useSaveLoggedInReturnPath();
  const { search } = useLocation();

  if (!isAuthenticated) {
    saveLoggedInReturnPath();
    return <Navigate to={PUBLIC_ROUTES.SIGN_IN + search} />;
  }
  return children;
};
