/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useAddRequest } from "@taketurns-repositories/collaboration/graphql/mutations/content/requests/useAddRequest";
import { useGetCurrentFolderIdInCollaborationView } from "@taketurns-repositories/collaboration/state/read/collaborationView/useGetCurrentFolderIdInCollaborationView";
import { useGetDisplayedCollaborationIdRule } from "@taketurns-rules/collaboration/queries/collaboration/useGetDisplayedCollaborationIdRule";

export const useAddRequestRule = () => {
  const collaborationId = useGetDisplayedCollaborationIdRule();
  const destinationFolderId = useGetCurrentFolderIdInCollaborationView();
  const { addRequest, addRequestResponseData, addRequestLoading, errorOnAddingRequest } = useAddRequest(
    collaborationId,
    destinationFolderId,
  );
  return {
    addRequest,
    addRequestResponseData: addRequestResponseData,
    addRequestLoading: addRequestLoading,
    errorOnAddingRequest: errorOnAddingRequest,
  };
};
