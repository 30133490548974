/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { atom } from "recoil";
import { CollaborationFilterType } from "@taketurns-components/collaboration/CollaborationList/collaborationFilterType";

export const collaborationListAppliedFilterState = atom<CollaborationFilterType>({
  key: "collaborationListAppliedFilter",
  default: CollaborationFilterType.activeCollaborations,
});
