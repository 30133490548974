/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Box, Skeleton, Stack, styled, Typography } from "@mui/material";
import { forwardRef, Fragment, useState } from "react";
import {
  Attachment,
  ContentStatus,
  ContentStatus as ContentStatusEnum,
  Document,
} from "@taketurns/taketurns-graphql-repository";
import { CardTitle } from "@taketurns-components/collaboration/CollaborationView/CollaborationContent/CardView/shared/components/CardTitle";
import { TakeTurnsCardSkeleton } from "@taketurns-components/collaboration/CollaborationView/CollaborationContent/CardView/shared/components/TakeTurnsCardSkeleton";
import { ExpectedActionCardHeader } from "@taketurns-components/collaboration/CollaborationView/CollaborationContent/CardView/shared/style/ExpectedActionCardHeader";
import { TakeTurnsCardContent } from "@taketurns-components/collaboration/CollaborationView/CollaborationContent/CardView/shared/style/TakeTurnsCardContent";
import { TakeTurnsCardFooter } from "@taketurns-components/collaboration/CollaborationView/CollaborationContent/CardView/shared/style/TakeTurnsCardFooter";
import { ContentElementLastUpdateInCard } from "@taketurns-components/collaboration/CollaborationView/CollaborationContent/shared/components/ContentElementLastUpdateInCard";
import { SelectionWrapperForCard } from "@taketurns-components/collaboration/CollaborationView/CollaborationContent/shared/components/SelectionComponentWrapper";
import { AttachmentMoveDestinationDialogInCollaborationView } from "@taketurns-components/collaboration/CollaborationView/MoveDestinationDialog/AttachmentMoveDestinationDialogInCollaborationView";
import { WithContextMenu } from "@taketurns-components/collaboration/ContextMenu/WithContextMenu";
import {
  AttachmentPendingUpdatesMarker,
  MarkedForReviewMarker,
} from "@taketurns-components/collaboration/Shared/content/Markers";
import {
  FilesDragAndDropWithOverlay,
  FilesDragAndDropWithOverlayConfig,
} from "@taketurns-components/collaboration/Shared/FilesDragAndDropWithOverlay";
import { IconLock, IconReview } from "@taketurns-components/commons/icons";
import { DocumentExtension } from "@taketurns-components/document/DocumentView/DocumentExtension";
import { useSharedCollaborationTranslation } from "@taketurns-i18n/collaboration/shared/useSharedCollaborationTranslation";
import { useOpenAttachmentDetailsRule } from "@taketurns-rules/collaboration/commands/collaborationView/attachment/useOpenAttachmentDetailsRule";
import { useUploadRevisionBeforeOpeningAttachmentDetailsRule } from "@taketurns-rules/collaboration/commands/collaborationView/attachment/useUploadRevisionBeforeOpeningAttachmentDetailsRule";
import { useGetAttachmentContextMenuConfigurationRule } from "@taketurns-rules/collaboration/queries/attachment/useGetAttachmentContextMenuConfigurationRule";
import { useGetAttachmentSelectionStateInCurrentFolderByIdRule } from "@taketurns-rules/collaboration/queries/collaborationView/contentSelection/useGetAttachmentSelectionStateInCurrentFolderByIdRule";
import { useGetAttachmentRule } from "@taketurns-rules/collaboration/queries/collaborationView/useGetAttachmentRule";
import { useIsCollaborationClosedRule } from "@taketurns-rules/collaboration/queries/collaborationView/useIsCollaborationClosedRule";
import { useFetchOtherPartyNameRule } from "@taketurns-rules/collaboration/queries/context/useFetchOtherPartyNameRule";
import { useSubscribeToMarkUnmarkAsReviewedAttachmentRule } from "@taketurns-rules/collaboration/subscriptions/useSubscribeToMarkUnmarkAsReviewedAttachmentRule";
import { useSubscribeToMarkUnmarkForReviewAttachmentRule } from "@taketurns-rules/collaboration/subscriptions/useSubscribeToMarkUnmarkForReviewAttachmentRule";
import { useSubscribeToSetAttachmentLockRule } from "@taketurns-rules/collaboration/subscriptions/useSubscribeToSetAttachmentLockRule";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { useSubscribeOnDocumentChangedRule } from "@taketurns-rules/document/subscriptions/useSubscribeOnDocumentChangedRule";
import { TakeTurnsCard } from "../shared/style/TakeTurnsCard";

interface AttachmentCardProps {
  attachmentId: string;
  editable: boolean;
}

export const AttachmentCard = ({ attachmentId, editable }: AttachmentCardProps) => {
  useSubscribeToMarkUnmarkForReviewAttachmentRule(attachmentId);
  useSubscribeToMarkUnmarkAsReviewedAttachmentRule(attachmentId);
  useSubscribeToSetAttachmentLockRule(attachmentId);
  const attachment = useGetAttachmentRule(attachmentId);
  useSubscribeOnDocumentChangedRule(attachment.id);

  const { isContentSelectionActive, isAttachmentSelected, toggleSelection } =
    useGetAttachmentSelectionStateInCurrentFolderByIdRule(attachmentId);

  if (!attachment) {
    return <TakeTurnsCardSkeleton />;
  }

  if (isContentSelectionActive) {
    return (
      <SelectionWrapperForCard checked={isAttachmentSelected} onClick={toggleSelection}>
        <AttachmentInnerCard
          attachment={attachment}
          document={attachment.document}
          editable={false}
          preventDetailsOpening
        />
      </SelectionWrapperForCard>
    );
  }
  if (!editable || attachment.prevStatus === ContentStatusEnum.Removed) {
    return <AttachmentInnerCard attachment={attachment} document={attachment.document} editable={false} />;
  } else {
    return (
      <CardWithContextMenu
        attachment={attachment}
        document={attachment.document}
        editable={attachment.status === ContentStatusEnum.Removed ? false : editable}
      />
    );
  }
};

const CardWithContextMenu = ({ attachment, document, editable }: AttachmentInnerCardProps) => {
  const [isMoveAttachmentDialogOpen, setIsMoveAttachmentDialogOpen] = useState(false);
  const openMoveAttachmentDialog = () => {
    setIsMoveAttachmentDialogOpen(true);
  };
  const closeMoveAttachmentDialog = () => {
    setIsMoveAttachmentDialogOpen(false);
  };
  const attachmentContextMenuConfiguration = useGetAttachmentContextMenuConfigurationRule(
    attachment,
    openMoveAttachmentDialog,
  );
  const AttachmentInnerCardWithContextMenu = WithContextMenu<AttachmentInnerCardProps>(
    AttachmentInnerCard,
    attachmentContextMenuConfiguration,
  );

  return (
    <Fragment>
      <AttachmentInnerCardWithContextMenu attachment={attachment} document={document} editable={editable} />
      <AttachmentMoveDestinationDialogInCollaborationView
        attachmentId={attachment.id}
        open={isMoveAttachmentDialogOpen}
        onClose={closeMoveAttachmentDialog}
      />
    </Fragment>
  );
};

const hasAttachmentBeenRemoved = (attachment: Attachment) =>
  attachment.status === ContentStatusEnum.Removed || attachment.prevStatus === ContentStatusEnum.Removed;

interface AttachmentInnerCardProps {
  attachment: Attachment;
  document: Document;
  editable: boolean;
  preventDetailsOpening?: boolean;
}

const AttachmentInnerCard = forwardRef<HTMLDivElement | null, AttachmentInnerCardProps>((props, ref) => {
  const openAttachmentDetails = useOpenAttachmentDetailsRule(props.attachment.id);
  const { isCollaborationClosed } = useIsCollaborationClosedRule();
  const shouldDisplayPendingUpdatesBackground =
    !isCollaborationClosed && props.attachment.status !== ContentStatusEnum.Unchanged;

  const openAttachmentDetailsAndUploadRevision = useUploadRevisionBeforeOpeningAttachmentDetailsRule(props.attachment);
  const dropZoneConfig: FilesDragAndDropWithOverlayConfig = {
    handleFilesDropping: (files) => openAttachmentDetailsAndUploadRevision(files[0]),
    className: "AttachmentCard__DropZone",
    sx: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
    },
  };

  const cardBackgroundColor = shouldDisplayPendingUpdatesBackground
    ? TakeTurnsColors.updatedElementBackground
    : props.attachment.locked
      ? TakeTurnsColors.background
      : undefined;

  if (!props.editable || hasAttachmentBeenRemoved(props.attachment)) {
    return (
      <TakeTurnsCard
        data-cy={`AttachmentCard.${props.attachment.id}`}
        ref={ref}
        onClick={
          props.preventDetailsOpening || hasAttachmentBeenRemoved(props.attachment) ? undefined : openAttachmentDetails
        }
        bgcolor={cardBackgroundColor}
      >
        <CardContent attachment={props.attachment} document={props.document} editable={false} />
      </TakeTurnsCard>
    );
  }

  return (
    <TakeTurnsCard
      data-cy={`AttachmentCard.${props.attachment.id}`}
      ref={ref}
      onClick={openAttachmentDetails}
      bgcolor={cardBackgroundColor}
    >
      <FilesDragAndDropWithOverlay ContainerComponent={Box} dropZoneConfig={dropZoneConfig} roundedDropZone>
        <CardContent attachment={props.attachment} document={props.document} editable={props.editable} />
      </FilesDragAndDropWithOverlay>
    </TakeTurnsCard>
  );
});
AttachmentInnerCard.displayName = "AttachmentInnerCard";

const CardContent = ({ attachment, document }: AttachmentInnerCardProps) => {
  const { isCollaborationClosed } = useIsCollaborationClosedRule();
  const shouldDisplayLastUpdatesMarker =
    !isCollaborationClosed && attachment.prevStatus && attachment.prevStatus !== ContentStatus.Unchanged;
  const shouldDisplayReviewHeader = !isCollaborationClosed && attachment.toReview;
  const shouldDisplayLockIndication = !isCollaborationClosed && !shouldDisplayReviewHeader && attachment.locked;
  const hasIconHeader = shouldDisplayReviewHeader || shouldDisplayLockIndication;
  const shouldStrikeTitle = hasAttachmentBeenRemoved(attachment);

  return (
    <Fragment>
      {shouldDisplayReviewHeader && <AttachmentReviewCardHeader />}
      {shouldDisplayLockIndication && <AttachmentLockCardHeader />}
      <DocumentNameContainer sx={{ paddingTop: hasIconHeader ? "7px" : undefined }}>
        <CardTitle title={document?.name} shouldStrikeTitle={shouldStrikeTitle} />
      </DocumentNameContainer>
      {shouldDisplayLastUpdatesMarker && !shouldDisplayLockIndication && (
        <ContentElementLastUpdateInCard status={attachment.prevStatus} />
      )}
      {shouldDisplayLockIndication && <AttachmentLockCardFooter />}
      <ExtensionAndMarkersContainer>
        <DocumentExtension extension={attachment.document.extension} size={attachment.document.size} />
        <AttachmentMarkersInCard attachment={attachment} />
      </ExtensionAndMarkersContainer>
    </Fragment>
  );
};

const AttachmentReviewCardHeader = () => {
  const { t } = useSharedCollaborationTranslation();
  return <ExpectedActionCardHeader actionLabel={t("AttachmentCard.actions.review")} ActionIcon={IconReview} />;
};

const AttachmentLockCardHeader = () => (
  <LockIconContainer>
    <IconLock size={18} color={TakeTurnsColors.darkGray} />
  </LockIconContainer>
);

const AttachmentLockCardFooter = () => {
  const { t } = useSharedCollaborationTranslation();
  const { otherPartyName, loadingOtherPartyName, errorOnFetchingOtherPartyName } = useFetchOtherPartyNameRule();

  if (loadingOtherPartyName) {
    return <Skeleton variant={"text"} />;
  }

  if (errorOnFetchingOtherPartyName) {
    console.error(errorOnFetchingOtherPartyName);
    return null;
  }

  return (
    <ContentElementLastUpdate__Container color={TakeTurnsColors.otherParty}>
      <Typography fontWeight="600" fontSize={{ xs: 10, md: 9 }} noWrap>
        {`${otherPartyName} ${t("ContentElementStatus.isUpdating")}`}
      </Typography>
    </ContentElementLastUpdate__Container>
  );
};

const AttachmentMarkersInCard = ({ attachment }: { attachment: Attachment }) => {
  const { isCollaborationClosed } = useIsCollaborationClosedRule();
  const shouldDisplayMarkForReview = !isCollaborationClosed && attachment.markedForReview;
  const shouldDisplayPendingUpdatesMarker = !isCollaborationClosed && attachment.status !== ContentStatus.Unchanged;

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      {shouldDisplayMarkForReview && <MarkedForReviewMarker />}
      {shouldDisplayPendingUpdatesMarker && <AttachmentPendingUpdatesMarker attachment={attachment} />}
    </Stack>
  );
};

const DocumentNameContainer = styled(TakeTurnsCardContent)({
  justifyContent: "space-between",
  height: "100%",
  overflow: "auto",
});

const LockIconContainer = styled(Box)({
  display: "flex",
  alignItems: "flex-end",
  justifyContent: "center",
  padding: "0 10px",
  height: 24,
  minHeight: 24,
  width: "100%",
});

const ExtensionAndMarkersContainer = styled(TakeTurnsCardFooter)({
  alignItems: "center",
  justifyContent: "space-between",
  padding: "0 10px",
});

export const ContentElementLastUpdate__Container = styled(Box, {
  shouldForwardProp: (prop) => prop !== "color",
})(({ color }: { color: string }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  borderBottom: `2px solid ${color}`,
  color: color,
  padding: "0 10px",
  height: 20,
  minHeight: 20,
  width: "100%",
}));
