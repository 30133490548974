/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { gql, useLazyQuery, useQuery } from "@apollo/client";
import { useCallback } from "react";

export const DOCUMENT_REVISION_PREVIEW_URL = gql`
  query documentRevisionPreviewUrl($collaborationId: ID!, $documentId: ID!, $revisionId: ID!) {
    documentRevisionPreviewUrl(collaborationId: $collaborationId, documentId: $documentId, revisionId: $revisionId)
  }
`;

export const useLazyFetchDocumentRevisionPreviewUrl = () => {
  const [
    fetchDocumentRevisionPreviewUrl,
    { data, loading: loadingDocumentRevisionPreviewUrl, error: errorOnDocumentRevisionPreviewUrl },
  ] = useLazyQuery<{
    documentRevisionPreviewUrl: string;
  }>(DOCUMENT_REVISION_PREVIEW_URL);

  const fetchPreviewUrl = useCallback(
    (collaborationId: string, documentId: string, revisionId: string) =>
      fetchDocumentRevisionPreviewUrl({
        variables: { collaborationId, documentId, revisionId },
      }),
    [fetchDocumentRevisionPreviewUrl],
  );
  return {
    fetchDocumentRevisionPreviewUrl: fetchPreviewUrl,
    documentRevisionPreviewUrl: data?.documentRevisionPreviewUrl,
    loadingDocumentRevisionPreviewUrl,
    errorOnDocumentRevisionPreviewUrl,
  };
};
export const useFetchDocumentRevisionPreviewUrl = (variables: {
  collaborationId: string;
  documentId: string;
  revisionId: string;
}) => {
  const {
    data,
    loading: loadingDocumentRevisionPreviewUrl,
    error: errorOnDocumentRevisionPreviewUrl,
  } = useQuery<{
    documentRevisionPreviewUrl: string;
  }>(DOCUMENT_REVISION_PREVIEW_URL, {
    variables,
  });

  return {
    documentRevisionPreviewUrl: data?.documentRevisionPreviewUrl,
    loadingDocumentRevisionPreviewUrl,
    errorOnDocumentRevisionPreviewUrl,
  };
};
