/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useUserPlanTranslation } from "@taketurns-i18n/user/UserPlans/useUserPlanTranslation";
import { SubscriptionPlanForDisplay } from "@taketurns-repositories/user/local/entities/subscriptionPlanForDisplay";
import { clearSelectedSubscriptionPlanWithTrialRule } from "@taketurns-rules/user/commands/clearSelectedSubscriptionPlanWithTrialRule";
import { useSaveSelectedPlanAndRedirectToSignUpRule } from "@taketurns-rules/user/commands/useSaveSelectedPlanAndRedirectToSignUpRule";
import { SubscribeToPlanLink } from "./SubscribeToPlanLink";

interface SubscribeToPlanWithoutAuthenticationProps {
  subscriptionPlan: SubscriptionPlanForDisplay;
}

export const SubscribeToPlanWithoutAuthentication = ({
  subscriptionPlan,
}: SubscribeToPlanWithoutAuthenticationProps) => {
  const { plan } = subscriptionPlan;
  const saveSelectedPlanAndRedirectToSignUp = useSaveSelectedPlanAndRedirectToSignUpRule();
  const { t } = useUserPlanTranslation();
  const saveSelectedPlanAndClearSelectedPlanWithTrialThenRedirectToSignUp = () => {
    clearSelectedSubscriptionPlanWithTrialRule()();
    saveSelectedPlanAndRedirectToSignUp(plan.planTier);
  };
  return (
    <SubscribeToPlanLink onClick={saveSelectedPlanAndClearSelectedPlanWithTrialThenRedirectToSignUp}>
      {t("buttons.subscribeNow", { plan: t(`plans.${plan.planTier}`) })}
    </SubscribeToPlanLink>
  );
};
