/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useSnackbar } from "notistack";
import { useAttachmentManagerTranslation } from "@taketurns-i18n/collaboration/AttachmentManager/useAttachmentManagerTranslation";

export const useNotifyAttachmentAddedRule = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useAttachmentManagerTranslation();

  return (count: number) => {
    enqueueSnackbar(
      t("AttachmentManager.pendingAttachmentSuccess.fileUploadComplete", {
        count,
      }),
      { variant: "info" },
    );
  };
};
