/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Box, styled, Typography } from "@mui/material";
import { DateTime } from "luxon";
import { useCollaborationTranslation } from "@taketurns-i18n/collaboration/useCollaborationTranslation";
import i18n from "@taketurns-i18n/i18n";
import { useFetchCollaborationDeadlineRule } from "@taketurns-rules/collaboration/queries/fields/useFetchCollaborationDeadlineRule";
import { compareDaysFromDates, getSmallDate } from "@taketurns-rules/collaboration/utils/date/DateUtils";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";

export const Deadline = () => {
  const {
    collaborationWithDeadlineAndStartDate,
    loadingCollaborationNameAndParties,
    errorOnCollaborationNameAndParties,
  } = useFetchCollaborationDeadlineRule();
  const { t } = useCollaborationTranslation("collaborationView");

  if (loadingCollaborationNameAndParties || errorOnCollaborationNameAndParties) {
    return;
  }

  const { deadline } = collaborationWithDeadlineAndStartDate;

  if (deadline) {
    const deadlineIsDue = compareDaysFromDates(deadline, DateTime.now()) <= 0;
    return (
      <Box>
        <DeadlineText component="span">{t("deadline")}: </DeadlineText>
        <BoldDate component="span" color={deadlineIsDue ? TakeTurnsColors.error : TakeTurnsColors.darkGray}>
          {getSmallDate(deadline, i18n.language)}
        </BoldDate>
      </Box>
    );
  }
  return null;
};

interface ComponentPropInterface {
  component?: string;
}

const DeadlineText = styled(Typography)<ComponentPropInterface>({
  fontSize: 14,
  color: TakeTurnsColors.darkGray,
});

const BoldDate = styled(Typography)<ComponentPropInterface>({
  fontSize: 14,
  fontWeight: 600,
});
