/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useLazyFetchStripePortalUrl } from "@taketurns-repositories/user/graphql/queries/useLazyFetchStripePortalUrl";
import { useDisplayAndLogNotificationErrorRule } from "./useDisplayAndLogNotificationErrorRule";

export const useOpenStripePortalUrlRule = () => {
  const [getStripePortalUrl, { loading: loadingStripePortal }] = useLazyFetchStripePortalUrl();
  const displayAndLogNotificationError = useDisplayAndLogNotificationErrorRule();

  const openStripePortal = () => {
    getStripePortalUrl()
      .then(({ data: { stripePortalUrl } }) => {
        window.location.replace(stripePortalUrl);
      })
      .catch((error) => {
        displayAndLogNotificationError(error);
      });
  };

  return { openStripePortal, loadingStripePortal };
};
