/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useMutation } from "@apollo/client";
import { MutationLeaveCollaborationArgs } from "@taketurns/taketurns-graphql-repository";
import { collaborationParticipantsFragment } from "@taketurns-repositories/collaboration/graphql/fragments/collaborationParticipantsFragment";

export const useLeaveCollaboration = () => {
  const mutation = gql`
    mutation leaveCollaboration($collaborationId: ID!) {
      leaveCollaboration(collaborationId: $collaborationId)
    }
    ${collaborationParticipantsFragment}
  `;
  return useMutation<{ leaveCollaboration: boolean }, MutationLeaveCollaborationArgs>(mutation);
};
