/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Box, Button, ButtonProps, styled } from "@mui/material";
import { Auth } from "aws-amplify";
import { PropsWithChildren, ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { PUBLIC_ROUTES } from "@taketurns-app/routing/routes/publicRoutes.constants";
import { IconPasswordless } from "@taketurns-components/commons/icons";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";

interface ProviderButtonsProps {
  textSlot: ReactNode;
}

interface ProviderConfiguration {
  providerColor: string;
  logoUrl: string;
  authSignInMethod: () => void;
  providerName: "Google" | "Microsoft";
}

export const AuthenticationButtons = ({ textSlot }: ProviderButtonsProps) => {
  return (
    <AuthenticationButton__Container>
      {textSlot}
      <Buttons__Container>
        <PasswordlessButton />
        <GoogleButton />
        <MicrosoftButton />
      </Buttons__Container>
    </AuthenticationButton__Container>
  );
};

const GoogleButton = () => {
  const googleProviderConfiguration: ProviderConfiguration = {
    providerColor: "#4284F5",
    providerName: "Google",
    logoUrl: "images/brands/Google.svg",
    authSignInMethod: () =>
      Auth.federatedSignIn({
        provider: "Google" as never,
      }),
  };
  return <ProviderButton providerConfiguration={googleProviderConfiguration} />;
};

const MicrosoftButton = () => {
  const microsoftProviderConfiguration: ProviderConfiguration = {
    providerColor: "#5E5E5E",
    providerName: "Microsoft",
    logoUrl: "images/brands/Microsoft.svg",
    authSignInMethod: () => Auth.federatedSignIn({ customProvider: "Microsoft" }),
  };
  return <ProviderButton providerConfiguration={microsoftProviderConfiguration} />;
};

const PasswordlessButton = () => {
  const navigate = useNavigate();
  return (
    <AuthenticationButton
      borderColor={TakeTurnsColors.darkGray}
      onClick={() => navigate(PUBLIC_ROUTES.PASSWORDLESS_SIGNIN)}
    >
      <IconPasswordless data-cy="authenticationButtons.passwordless" sx={{ marginLeft: "auto" }} />
    </AuthenticationButton>
  );
};

const ProviderButton = ({ providerConfiguration }: { providerConfiguration: ProviderConfiguration }) => {
  const baseUrl = import.meta.env.BASE_URL;
  return (
    <AuthenticationButton
      onClick={providerConfiguration.authSignInMethod}
      borderColor={providerConfiguration.providerColor}
    >
      <img
        src={`${baseUrl}${providerConfiguration.logoUrl}`}
        width={18}
        height={18}
        alt={providerConfiguration.providerName}
      />
    </AuthenticationButton>
  );
};

const AuthenticationButton__Container = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  gap: 12,
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

const Buttons__Container = styled(Box)({
  display: "flex",
  gap: 12,
});

const AuthenticationButton = ({
  borderColor,
  onClick,
  children,
}: PropsWithChildren<{ borderColor: string }> & ButtonProps) => {
  return (
    <Button sx={{ borderColor: borderColor, padding: 2 }} onClick={onClick} fullWidth variant="outlined">
      {children}
    </Button>
  );
};
