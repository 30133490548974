/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql } from "@apollo/client";
import { AddRequestOutput, SubscriptionOnAddRequestArgs } from "@taketurns/taketurns-graphql-repository";
import { useRefreshableSubscription } from "@taketurns-components/webapp/QueriesAndSubscriptionsRefreshTrigger";
import { AddRequestMutationFragment } from "../../mutations/content/requests/useAddRequest";

const SUBSCRIBE_TO_ADD_REQUEST = gql`
  subscription onAddRequest($collaborationId: ID!, $partyId: ID!, $folderId: ID!) {
    onAddRequest(collaborationId: $collaborationId, partyId: $partyId, folderId: $folderId) {
      ...AddRequestMutationFragment
    }
  }
  ${AddRequestMutationFragment}
`;

export const useSubscribeToAddRequest = (args: SubscriptionOnAddRequestArgs) => {
  return useRefreshableSubscription<{ onAddRequest: AddRequestOutput }, SubscriptionOnAddRequestArgs>(
    SUBSCRIBE_TO_ADD_REQUEST,
    {
      variables: args,
    },
  );
};
