/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { OrganizationInvitation, OrganizationMember } from "@taketurns/taketurns-graphql-repository";

export interface OrganizationMemberAndInvitationComponentProps {
  membersAndInvitations: OrganizationMemberOrInvitation[];
  connectedUserId: string;
  loading: boolean;
}

export type OrganizationMemberOrInvitation =
  | (OrganizationInvitation & { isInvitation: true })
  | (OrganizationMember & { isInvitation: false });

export function isOrganizationMember(
  invitationOrMember:
    | (OrganizationMember & { isInvitation: false })
    | (OrganizationInvitation & { isInvitation: true }),
): invitationOrMember is OrganizationMember & { isInvitation: false } {
  return !invitationOrMember.isInvitation;
}

export function isOrganizationInvitation(
  invitationOrMember:
    | (OrganizationMember & { isInvitation: false })
    | (OrganizationInvitation & { isInvitation: true }),
): invitationOrMember is OrganizationInvitation & { isInvitation: true } {
  return !isOrganizationMember(invitationOrMember);
}
