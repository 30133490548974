/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useSetIsExcludedFromCollaborationState } from "@taketurns-repositories/webapp/state/write/useSetIsExcludedFromCollaborationState";

export const useCloseExcludedFromCollaborationDialogRule = () => {
  const setIsExcluded = useSetIsExcludedFromCollaborationState();
  return { closeExcludedFromCollaborationDialog: () => setIsExcluded(false) };
};
