/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { styled, Switch, SwitchProps } from "@mui/material";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";

interface TakeTurnsSwitchProps extends SwitchProps {
  borderColor?: string;
}

export const TakeTurnsSwitch = styled(
  (props: TakeTurnsSwitchProps) => <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />,
  { shouldForwardProp: (prop) => prop !== "borderColor" },
)(({ borderColor }) => ({
  width: 40,
  height: 24,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: TakeTurnsColors.darkBlue,
        opacity: 1,
        border: borderColor ? `1px solid ${borderColor}` : 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: TakeTurnsColors.darkBlue,
      border: "6px solid #fff",
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 20,
    height: 20,
  },
  "& .MuiSwitch-track": {
    borderRadius: 24 / 2,
    backgroundColor: "#E9E9EA",
    opacity: 1,
    transition: "background-color 500ms",
  },
}));
