/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useMutation } from "@apollo/client";
import { RevokeOrganizationInvitationInput } from "@taketurns/taketurns-graphql-repository";

export const useRevokeOrganizationInvitation = () => {
  const mutation = gql`
    mutation revokeOrganizationInvitation($revokeOrganizationInvitationInput: RevokeOrganizationInvitationInput!) {
      revokeOrganizationInvitation(revokeOrganizationInvitationInput: $revokeOrganizationInvitationInput) {
        id
        invitations {
          role
          userId
          email
          user {
            id
            email
            lastName
            firstName
            avatar
          }
        }
      }
    }
  `;
  const [
    revokeOrganizationInvitationMutation,
    { error: errorOnRevokeOrganizationInvitation, loading: loadingRevokeOrganizationInvitation },
  ] = useMutation(mutation);

  const revokeOrganizationInvitation = (revokeOrganizationInvitationInput: RevokeOrganizationInvitationInput) => {
    return revokeOrganizationInvitationMutation({ variables: { revokeOrganizationInvitationInput } });
  };

  return { revokeOrganizationInvitation, errorOnRevokeOrganizationInvitation, loadingRevokeOrganizationInvitation };
};
