/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { atom } from "recoil";
import { PendingCreationAttachment } from "../../../models/pendingCreationAttachment";

export const pendingCreationAttachmentsState = atom<PendingCreationAttachment[]>({
  key: "pendingCreationAttachments",
  default: [],
});
