/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Button, styled } from "@mui/material";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";

export const TakeTurnsDefaultButton = styled(Button)(() => ({
  defaultProps: {
    variant: "contained",
  },
  backgroundColor: TakeTurnsColors.yellow,
  color: "white",
  "&:hover": {
    opacity: "90%",
    backgroundColor: TakeTurnsColors.yellow,
  },
  "&:focus": {
    backgroundColor: TakeTurnsColors.yellow,
  },
  "&:disabled": {
    backgroundColor: TakeTurnsColors.lightGray,
  },
}));

export const TakeTurnsGreyButton = styled(Button)(() => ({
  defaultProps: {
    variant: "contained",
  },
  backgroundColor: TakeTurnsColors.grayButton,
  color: "white",
  "&:hover": {
    opacity: "90%",
    backgroundColor: TakeTurnsColors.grayButton,
  },
  "&:focus": {
    backgroundColor: TakeTurnsColors.grayButton,
  },
  "&:disabled": {
    backgroundColor: TakeTurnsColors.lightGray,
  },
}));

export const TakeTurnsTextButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "fontSize",
})<{ fontSize?: string }>((props: { fontSize?: string }) => ({
  backgroundColor: "transparent",
  padding: 0,
  color: TakeTurnsColors.lightBlue,
  fontSize: props.fontSize ? props.fontSize : 12,
  fontFamily: "Encode Sans",
  fontWeight: "500",
  border: "none",
}));
TakeTurnsTextButton.defaultProps = {
  ...TakeTurnsTextButton.defaultProps,
  variant: "text",
};
