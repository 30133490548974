/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useEffect } from "react";
import { useMarkPendingAttachmentRevisionAsAdding } from "@taketurns-repositories/collaboration/state/write/pendingAttachmentRevision/useMarkPendingAttachmentRevisionAsAdding";
import { useGetCompletedUploadIds } from "@taketurns-repositories/document/state/read/useGetCompletedUploadIds";
import { useGetAllPendingAttachmentRevisionsRule } from "@taketurns-rules/collaboration/queries/attachment/pending/useGetAllPendingAttachmentRevisionsRule";
import { useAddAttachmentRevisionRule } from "./useAddAttachmentRevisionRule";

export const useListenToCompletedUploadsToAddAttachmentRevisionRule = () => {
  const completedUploadIds = useGetCompletedUploadIds();
  const pendingAttachments = useGetAllPendingAttachmentRevisionsRule();
  const markPendingAttachmentRevisionAsAdding = useMarkPendingAttachmentRevisionAsAdding();
  const { addAttachmentRevision } = useAddAttachmentRevisionRule();

  useEffect(() => {
    for (const completedUploadId of completedUploadIds) {
      const pendingAttachmentRevisionToUpdate = pendingAttachments.find((p) => p.uploadId === completedUploadId);
      if (
        pendingAttachmentRevisionToUpdate &&
        !(pendingAttachmentRevisionToUpdate.isAdding() || pendingAttachmentRevisionToUpdate.isAdded())
      ) {
        markPendingAttachmentRevisionAsAdding(pendingAttachmentRevisionToUpdate);
        addAttachmentRevision(pendingAttachmentRevisionToUpdate);
      }
    }
  }, [completedUploadIds]);
};
