/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { CircularProgress, IconButton } from "@mui/material";
import { OrganizationInvitation } from "@taketurns/taketurns-graphql-repository";
import { IconRefreshCcw } from "@taketurns-components/commons/icons";
import { useResendInvitationRule } from "@taketurns-rules/user/commands/useResendInvitationRule";

interface InvitationResendButtonProps {
  invitation: OrganizationInvitation;
}

export const InvitationResendButton = ({ invitation }: InvitationResendButtonProps) => {
  const { resendOrganizationInvitation: resendOrganizationInvitationCommand, loadingResendOrganizationInvitation } =
    useResendInvitationRule();
  const resendOrganizationInvitation = () => {
    resendOrganizationInvitationCommand(invitation);
  };
  return (
    <IconButton disabled={loadingResendOrganizationInvitation} onClick={resendOrganizationInvitation}>
      {loadingResendOrganizationInvitation ? <CircularProgress size={18} /> : <IconRefreshCcw size={18} />}
    </IconButton>
  );
};
