/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { gql, useLazyQuery } from "@apollo/client";

const HAS_COLLABORATION_ENOUGH_SPACE_AVAILABLE = gql`
  query hasCollaborationEnoughSpaceAvailable($collaborationId: ID!, $neededSpaceInBytes: Int!) {
    hasCollaborationEnoughSpaceAvailable(collaborationId: $collaborationId, neededSpaceInBytes: $neededSpaceInBytes)
  }
`;

export const useFetchHasCollaborationEnoughSpaceAvailable = () => {
  const [hasCollaborationEnoughSpaceAvailable] = useLazyQuery<{ hasCollaborationEnoughSpaceAvailable: boolean }>(
    HAS_COLLABORATION_ENOUGH_SPACE_AVAILABLE,
    {
      fetchPolicy: "no-cache",
    },
  );
  return async (collaborationId: string, neededSpaceInBytes: number): Promise<boolean> => {
    const queryResult = await hasCollaborationEnoughSpaceAvailable({
      variables: { collaborationId, neededSpaceInBytes },
    });
    return queryResult.data.hasCollaborationEnoughSpaceAvailable;
  };
};
