/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useMutation } from "@apollo/client";
import { RemoveOrganizationMemberInput } from "@taketurns/taketurns-graphql-repository";

export const useRemoveOrganizationMember = () => {
  const mutation = gql`
    mutation removeOrganizationMember($removeOrganizationMemberInput: RemoveOrganizationMemberInput!) {
      removeOrganizationMember(removeOrganizationMemberInput: $removeOrganizationMemberInput) {
        id
        members {
          userId
        }
      }
    }
  `;
  const [
    removeOrganizationMemberMutation,
    { error: errorOnRemoveOrganizationMember, loading: loadingRemoveOrganizationMember },
  ] = useMutation(mutation);
  const removeOrganizationMember = (removeOrganizationMemberInput: RemoveOrganizationMemberInput) => {
    return removeOrganizationMemberMutation({ variables: { removeOrganizationMemberInput } });
  };

  return { removeOrganizationMember, errorOnRemoveOrganizationMember, loadingRemoveOrganizationMember };
};
