/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useGetChannelUnreadCount } from "../state/read/useGetChannelUnreadCount";

export const useGetUnreadMessageByChannelID = (channelID: string) => {
  const channelUnreadCount = useGetChannelUnreadCount(channelID);

  return { unreadCount: channelUnreadCount };
};
