/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Divider, List, Skeleton, Typography } from "@mui/material";
import { Fragment } from "react";
import { useOrganizationTranslation } from "@taketurns-i18n/user/Organization/useOrganizationTranslation";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { useIsOnMobileRule } from "@taketurns-rules/commons/theme/useIsOnMobileRule";
import { useModifyConnectedUserOrganizationNameRule } from "@taketurns-rules/user/commands/useModifyConnectedUserOrganizationNameRule";
import { useFetchUserHasOrganizationAdminFeatureRule } from "@taketurns-rules/user/queries/useFetchUserHasOrganizationAdminFeatureRule";
import { useFetchUserOrganizationRule } from "@taketurns-rules/user/queries/useFetchUserOrganizationRule";
import { LabelWithEditButton } from "../../../commons/form/LabelWithEditButton";
import { FormListItem } from "../../shared/FormListItem";

export const OrganizationMainInformation = () => {
  const { t } = useOrganizationTranslation();
  const { userOrganization, loadingUserOrganization, errorOnLoadingUserOrganization } = useFetchUserOrganizationRule();
  const { userHasOrganizationAdminFeature, loadingHasOrganizationAdminFeature, errorOnHasOrganizationAdminFeature } =
    useFetchUserHasOrganizationAdminFeatureRule();

  const { modifyConnectedUserOrganizationName } = useModifyConnectedUserOrganizationNameRule();

  const isOnMobile = useIsOnMobileRule();

  function modifyConnectedUserOrganization(newOrganizationName: string) {
    modifyConnectedUserOrganizationName(newOrganizationName, userOrganization?.id);
  }

  const error = errorOnHasOrganizationAdminFeature || errorOnLoadingUserOrganization;
  if (error) {
    console.error(error);
    return (
      <Typography variant={"caption"} color={"error"}>
        {t("error")}
      </Typography>
    );
  }

  const loading = loadingUserOrganization || loadingHasOrganizationAdminFeature;
  if (loading) {
    return (
      <Fragment>
        <Skeleton variant={"text"} />
        <Skeleton variant={"text"} />
        <Skeleton variant={"text"} />
      </Fragment>
    );
  }

  return (
    <Fragment>
      {!isOnMobile && <PageHeader />}
      <List>
        {!isOnMobile && <Divider />}
        <FormListItem label={t("OrganizationMainInformation.id")}>
          <Typography color={TakeTurnsColors.darkGray} variant={"body2"}>
            {userOrganization?.id}
          </Typography>
        </FormListItem>
        <FormListItem label={t("OrganizationMainInformation.name")}>
          <LabelWithEditButton
            currentLabel={userOrganization!.name!}
            onEditEnded={modifyConnectedUserOrganization}
            disabled={!userHasOrganizationAdminFeature}
          />
        </FormListItem>
      </List>
    </Fragment>
  );
};

const PageHeader = () => {
  const { t } = useOrganizationTranslation();
  return <Typography>{t("Organization.organization")}</Typography>;
};
