/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql } from "@apollo/client";
import { Collaboration } from "@taketurns/taketurns-graphql-repository";
import { useRefreshableSubscription } from "@taketurns-components/webapp/QueriesAndSubscriptionsRefreshTrigger";

export const SUBSCRIBE_TO_CHANGE_COLLABORATION_STATUS = gql`
  subscription onCollaborationStatusChanged($id: ID!) {
    onCollaborationStatusChanged(id: $id) {
      id
      status
      closingDate
    }
  }
`;

export const useSubscribeToCollaborationStatusChanges = (collaborationId: string) => {
  useRefreshableSubscription<{ onCollaborationStatusChanged: Collaboration }>(
    SUBSCRIBE_TO_CHANGE_COLLABORATION_STATUS,
    {
      variables: { id: collaborationId },
    },
  );
};
