/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Skeleton, Typography } from "@mui/material";
import { ReactNode } from "react";
import { Channel, MessageList, Thread, Window } from "stream-chat-react";
import { Role } from "@taketurns/taketurns-graphql-repository";
import { useChatTranslation } from "@taketurns-i18n/chat/useChatTranslation";
import { ChatContext } from "@taketurns-repositories/chat/state/context/ChatContext";
import { useConnectToCollaborationChatRule } from "@taketurns-rules/chat/commands/useConnectToCollaborationChatRule";
import {
  useFetchConnectedUserRoleRule,
} from "@taketurns-rules/collaboration/queries/context/useFetchConnectedUserRoleRule";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { useIsOnMobileRule } from "@taketurns-rules/commons/theme/useIsOnMobileRule";
import "stream-chat-react/dist/css/index.css";
import { IconErrorOutline, IconSmile } from "../../commons/icons";
import { MobileSendButton } from "../MobileChatDrawer/MobileSendButton";
import { ChatMessage } from "./Messages/ChatMessage";
import { ChatMessageInput } from "./Messages/ChatMessageInput";
import "./taketurnsStreamChat.scss";

interface ChatProps {
  collaborationId: string;
}

export const Chat = (props: ChatProps) => {
  const { collaborationId } = props;

  const { channel, loading, notAuthorizedError, technicalError } = useConnectToCollaborationChatRule(collaborationId);
  const { connectedUserRole } = useFetchConnectedUserRoleRule();
  const { t } = useChatTranslation("chat");
  const isOnMobile = useIsOnMobileRule();

  const CustomEmojiIcon = () => {
    return <IconSmile color={TakeTurnsColors.mediumGray} />;
  };

  const LoadingOrErrorContainer = ({ children }: { children: ReactNode }) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          fontSize: 12,
          gap: "12px",
          padding: "24px",
        }}
      >
        {children}
      </div>
    );
  };

  if (loading) {
    return (
      <LoadingOrErrorContainer>
        {[...Array(5)].map((_, index) => (
          <Skeleton key={index} variant="text" />
        ))}
      </LoadingOrErrorContainer>
    );
  }

  if (technicalError || notAuthorizedError) {
    const errorMessage = notAuthorizedError ? t("notAllowedError") : t("somethingWentWrong");
    return (
      <LoadingOrErrorContainer>
        <IconErrorOutline color={TakeTurnsColors.error} size={24} style={{ alignSelf: "center" }} />
        <Typography color={TakeTurnsColors.error} textAlign="center">
          {errorMessage}
        </Typography>
      </LoadingOrErrorContainer>
    );
  }

  const shouldDisplayChatMessageInput = connectedUserRole && connectedUserRole !== Role.Ghost;

  return (
    <Channel channel={channel!} EmojiIcon={CustomEmojiIcon} SendButton={isOnMobile ? MobileSendButton : undefined}>
      <Window>
        <ChatContext.Provider value={{ collaborationId }}>
          <MessageList Message={ChatMessage} />
        </ChatContext.Provider>
        {shouldDisplayChatMessageInput && <ChatMessageInput />}
        <Thread />
      </Window>
    </Channel>
  );
};
