/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import {
  Chip,
  InputBase,
  InputBaseProps,
  MenuItem,
  Select,
  Stack,
  styled,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import { PropsWithChildren } from "react";
import { Party, Role, SubscriptionPlanTier } from "@taketurns/taketurns-graphql-repository";
import { useSharedCollaborationTranslation } from "@taketurns-i18n/collaboration/shared/useSharedCollaborationTranslation";
import { useCollaborationTranslation } from "@taketurns-i18n/collaboration/useCollaborationTranslation";
import { useFetchUserHasGhostFeatureRule } from "@taketurns-rules/collaboration/queries/useFetchUserHasGhostFeatureRule";
import { useFetchUserPlanTierRule } from "@taketurns-rules/collaboration/queries/useFetchUserPlanTierRule";
import { useGetRoleListRule } from "@taketurns-rules/collaboration/queries/useGetRoleListRule";
import { HelpCenterSections } from "@taketurns-rules/commons/enum/helpCenterSectionsEnum";
import { IconChevronDown } from "../../../commons/icons";
import { HelpCenterTextButton } from "../../../webapp/HelpCenterTextButton";
import { WithOpenUpgradePlanDialogOnClick } from "../WithOpenUpgradePlanDialogOnClick";

interface SelectRoleButtonProps {
  role: Role;
  onChange: (role: Role) => void;
  selectingRoleUserParty: Party;
  connectedUserParty: Party;
  editable: boolean;
  color?: string;
  customInputSx?: SxProps;
  defaultInput?: boolean;
  loading?: boolean;
  fullWidth?: boolean;
}

export const SelectRoleButton = (props: SelectRoleButtonProps) => {
  const { t } = useCollaborationTranslation("participantCollaboration");
  const { t: sharedCollaborationTranslation } = useSharedCollaborationTranslation();

  const roleList = useGetRoleListRule(props.selectingRoleUserParty, props.connectedUserParty);

  const { loadingUserHasGhostFeature, hasGhostFeature } = useFetchUserHasGhostFeatureRule();
  const { planTier } = useFetchUserPlanTierRule();

  const renderRoleInSelector = (role: Role) => {
    const roleEnumKey = Object.keys(Role)[Object.values(Role).indexOf(role)] as keyof typeof Role;
    return t(Role[roleEnumKey]);
  };

  const { editable, defaultInput } = props;
  const SelectIconComponent = (props: PropsWithChildren<unknown>) => {
    if (editable) {
      return <IconChevronDown size={16} color={defaultInput ? "#A2A2A2" : "white"} {...props} />;
    }
    return null;
  };

  const GhostMenuItem =
    planTier === SubscriptionPlanTier.T0 || planTier === SubscriptionPlanTier.T1
      ? WithOpenUpgradePlanDialogOnClick(MenuItem, {
          UpgradeToPlanDialogContentSlot: (
            <Stack spacing={1.5}>
              <Typography variant={"body2"}>
                {sharedCollaborationTranslation("upgradeDialog.ghostRole.description")}{" "}
                <HelpCenterTextButton
                  fontSize={"14px"}
                  helpCenterSection={HelpCenterSections.PARTICIPANTS}
                  label={sharedCollaborationTranslation("upgradeDialog.ghostRole.learnMore")}
                />
              </Typography>
              <Typography variant={"body2"}>
                {sharedCollaborationTranslation(`upgradeDialog.ghostRole.upgradeDescription.${planTier}`)}
              </Typography>
            </Stack>
          ),
          isFeatureAvailable: hasGhostFeature,
        })
      : MenuItem;

  return (
    <Select
      value={props.role}
      disabled={props.loading || !props.editable}
      inputProps={
        props.customInputSx
          ? { sx: { ...props.customInputSx } }
          : { sx: !props.editable && { padding: "0px 12px !important" } }
      }
      autoWidth
      fullWidth={props.fullWidth}
      onChange={(event) => props.onChange(event.target.value as Role)}
      IconComponent={SelectIconComponent}
      input={!props.defaultInput ? <ChipSelectInput size="small" customBackgroundColor={props.color} /> : undefined}
      renderValue={renderRoleInSelector}
    >
      {roleList.map((role) => {
        if (role === "Ghost") {
          return (
            <GhostMenuItem key={role} value={Role[role]}>
              {loadingUserHasGhostFeature ? "Loading permissions..." : t(Role[role])}
              {!hasGhostFeature && <ChipPlusPlan size="small" color="primary" label="PLUS" />}
            </GhostMenuItem>
          );
        }
        return (
          <MenuItem key={role} value={Role[role]}>
            {t(Role[role])}
          </MenuItem>
        );
      })}
    </Select>
  );
};

export const ChipPlusPlan = styled(Chip)({
  height: "auto",
  marginLeft: 10,
});

type ChipSelectInputProps = InputBaseProps & {
  theme?: Theme;
} & {
  customBackgroundColor: string;
};

const ChipSelectInput = styled(InputBase, {
  shouldForwardProp: (prop) => prop !== "customBackgroundColor",
})((props: ChipSelectInputProps) => ({
  "& .MuiInputBase-input": {
    borderRadius: "50px !important",
    position: "relative",
    fontSize: 10,
    padding: "0px 16px",
    backgroundColor: props.customBackgroundColor,
    color: "white",
  },
  "&:focus": {
    borderRadius: 50,
  },
  "& .Mui-disabled": {
    color: "white !important",
    WebkitTextFillColor: "white !important",
  },
}));
