/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { ListItem, Skeleton, Stack } from "@mui/material";

export const TakeTurnsListItemSkeleton = () => {
  return (
    <ListItem disablePadding divider>
      <Stack
        width={"100%"}
        direction="row"
        alignItems={"center"}
        justifyContent={"space-between"}
        spacing={1}
        paddingBottom={1}
        paddingTop={1}
      >
        <Skeleton sx={{ flex: 1 }} />
      </Stack>
    </ListItem>
  );
};
