/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useMutation } from "@apollo/client";
import { RemoveOrganizationDomainInput } from "@taketurns/taketurns-graphql-repository";

export const useRemoveOrganizationDomain = () => {
  const mutation = gql`
    mutation removeOrganizationDomain($removeOrganizationDomainInput: RemoveOrganizationDomainInput!) {
      removeOrganizationDomain(removeOrganizationDomainInput: $removeOrganizationDomainInput) {
        id
        domains {
          name
          status
          verificationCode
        }
      }
    }
  `;
  const [
    removeOrganizationDomainMutation,
    { error: errorOnRemoveOrganizationDomain, loading: loadingRemoveOrganizationDomain },
  ] = useMutation(mutation);
  const removeOrganizationDomain = (removeOrganizationDomainInput: RemoveOrganizationDomainInput) => {
    return removeOrganizationDomainMutation({ variables: { removeOrganizationDomainInput } });
  };

  return { removeOrganizationDomain, errorOnRemoveOrganizationDomain, loadingRemoveOrganizationDomain };
};
