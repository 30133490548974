/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useCallback, useEffect, useRef, useState } from "react";
import { useRecoilCallback } from "recoil";

export const RecoilDebugger = () => {
  useDebugRecoilOnKonamiCode();

  return null;
};

const useDebugRecoilOnKonamiCode = () => {
  const [success, reset] = useKonamiCode();
  const debugRecoil = useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        console.debug("Atom values:");
        const sortedAtoms = [...snapshot.getNodes_UNSTABLE()].sort((a, b) => a.key.localeCompare(b.key));
        for (const node of sortedAtoms) {
          const value = await snapshot.getPromise(node);
          console.debug(node.key, value);
        }
      },
    [],
  );

  useEffect(() => {
    if (success) {
      console.log("Konami code entered");
      debugRecoil();
      reset();
    }
  }, [debugRecoil, reset, success]);
};

const useKonamiCode = (): [boolean, () => void] => {
  const countRef = useRef(0);
  const [success, setSuccess] = useState(false);
  const key = useInputEvent();

  const reset = useCallback(() => {
    countRef.current = 0;
    setSuccess(false);
  }, []);

  useEffect(() => {
    if (key == null) return;

    const KONAMI_CODE = [
      "ArrowUp",
      "ArrowUp",
      "ArrowDown",
      "ArrowDown",
      "ArrowLeft",
      "ArrowRight",
      "ArrowLeft",
      "ArrowRight",
      "b",
      "a",
    ];

    if (key !== KONAMI_CODE[countRef.current]) {
      reset();
      return;
    }

    countRef.current++;
    if (countRef.current === KONAMI_CODE.length) {
      setSuccess(true);
    }
  }, [key, reset]);

  return [success, reset];
};

const useInputEvent = () => {
  const [key, setKey] = useState<string | null>(null);
  useEffect(() => {
    const keyDownHandler = ({ key }: KeyboardEvent) => {
      setKey(key);
    };
    const keyUpHandler = () => setKey(null);
    global.addEventListener("keydown", keyDownHandler);
    global.addEventListener("keyup", keyUpHandler);
    return () => {
      global.removeEventListener("keydown", keyDownHandler);
      global.removeEventListener("keyup", keyUpHandler);
    };
  }, []);
  return key;
};
