/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useQuery } from "@apollo/client";
import { Folder } from "@taketurns/taketurns-graphql-repository";

export const GET_FOLDER = gql`
  query getDestinationFolder($collaborationId: ID!, $folderId: ID!) {
    getFolder(collaborationId: $collaborationId, folderId: $folderId) {
      id
      name
      status
      prevStatus
      folders {
        id
        name
        status
        prevStatus
      }
    }
  }
`;

export const useFetchDestinationFolder = (collaborationId: string, folderId: string) => {
  const {
    data,
    loading: loadingDestinationFolder,
    error: errorOnFetchingDestinationFolder,
  } = useQuery<{ getFolder: Folder }>(GET_FOLDER, {
    fetchPolicy: "cache-and-network",
    variables: { collaborationId, folderId },
  });
  return {
    folder: data?.getFolder,
    loadingDestinationFolder,
    errorOnFetchingDestinationFolder,
  };
};
