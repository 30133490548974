/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { Participant, Role } from "@taketurns/taketurns-graphql-repository";
import { useCollaborationTranslation } from "@taketurns-i18n/collaboration/useCollaborationTranslation";
import { useChangeParticipantRole } from "@taketurns-repositories/collaboration/graphql/mutations/participants/useChangeParticipantRole";
import { useGetDisplayedCollaborationIdRule } from "@taketurns-rules/collaboration/queries/collaboration/useGetDisplayedCollaborationIdRule";

export const useEditParticipantRoleRule = () => {
  const { loadingEditParticipantRole, errorOnEditParticipantRole, editParticipantRoleCommand } =
    useChangeParticipantRole();
  const collaborationId = useGetDisplayedCollaborationIdRule();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useCollaborationTranslation("notifications");

  useEffect(() => {
    if (errorOnEditParticipantRole) {
      enqueueSnackbar(errorOnEditParticipantRole.message, { variant: "error" });
    }
  }, [enqueueSnackbar, errorOnEditParticipantRole]);
  const editParticipantRole = ({ userId }: Participant, role: Role) => {
    const notifyRoleEditionSuccess = () => {
      enqueueSnackbar(t("user.roleChanged"), {
        variant: "info",
      });
    };
    editParticipantRoleCommand({ collaborationId, role, userId }, notifyRoleEditionSuccess);
  };
  return { editParticipantRole, loadingEditParticipantRole };
};
