/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Box, Button, DialogActions, DialogContentText } from "@mui/material";
import React, { Fragment, useState } from "react";
import { TakeTurnsDefaultButton } from "@taketurns-components/commons/button";
import { ResponsiveDialog } from "@taketurns-components/commons/dialog/ResponsiveDialog";
import { useSharedCollaborationTranslation } from "@taketurns-i18n/collaboration/shared/useSharedCollaborationTranslation";
import { useCancelPendingUpdatesRule } from "@taketurns-rules/collaboration/commands/collaborationView/updates/useCancelPendingUpdatesRule";

export const CancelPendingUpdatesButton = () => {
  const [isConfirmationDialogOpened, setIsConfirmationDialogOpened] = useState(false);
  const { t } = useSharedCollaborationTranslation();

  const openConfirmationDialog = () => {
    setIsConfirmationDialogOpened(true);
  };

  const closeConfirmationDialog = () => {
    setIsConfirmationDialogOpened(false);
  };

  return (
    <Fragment>
      <Box display={"flex"} gap={2} alignItems="center" justifyContent={"space-between"}>
        <Button
          variant={"text"}
          onClick={openConfirmationDialog}
          disableRipple
          sx={{ "& .MuiButton-startIcon": { mr: { xs: "3px", lg: "6px" } } }}
        >
          {t("CancelPendingUpdates.button")}
        </Button>
      </Box>
      <CancelPendingUpdatesConfirmationDialog open={isConfirmationDialogOpened} onClose={closeConfirmationDialog} />
    </Fragment>
  );
};

const CancelPendingUpdatesConfirmationDialog = (props: { open: boolean; onClose: () => void }) => {
  const { cancelPendingUpdates, cancelPendingUpdatesResult } = useCancelPendingUpdatesRule();
  const { t } = useSharedCollaborationTranslation();

  return (
    <ResponsiveDialog
      open={props.open}
      onClose={props.onClose}
      title={t("CancelPendingUpdates.dialogTitle")}
      content={<DialogContentText>{t("CancelPendingUpdates.dialogDescription")}</DialogContentText>}
      footer={
        <DialogActions>
          <Button variant={"text"} onClick={props.onClose}>
            {t("CancelPendingUpdates.button")}
          </Button>
          <TakeTurnsDefaultButton disabled={cancelPendingUpdatesResult.loading} onClick={cancelPendingUpdates}>
            {t("CancelPendingUpdates.dialogActionButton")}
          </TakeTurnsDefaultButton>
        </DialogActions>
      }
    />
  );
};
