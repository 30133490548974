/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Button, ButtonGroup, CircularProgress, styled } from "@mui/material";
import { ReplaceAttachmentRevisionButton } from "@taketurns-components/collaboration/CollaborationView/AttachmentDetails/components/ReplaceAttachmentRevisionButton";
import { IconX } from "@taketurns-components/commons/icons";
import { useAttachmentDetailsTranslation } from "@taketurns-i18n/collaboration/AttachmentDetails/useAttachmentDetailsTranslation";
import { useRemoveAttachmentRevisionRule } from "@taketurns-rules/collaboration/commands/attachmentRevision/useRemoveAttachmentRevisionRule";
import { useIsAttachmentRevisionAddInProgressRule } from "@taketurns-rules/collaboration/queries/attachment/pending/useIsAttachmentRevisionAddInProgressRule";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";

interface RevisionActionsProps {
  attachmentId: string;
  revisionNumber: number;
}

export const RevisionActions = (props: RevisionActionsProps) => {
  const isAttachmentRevisionAddInProgress = useIsAttachmentRevisionAddInProgressRule();
  const { t } = useAttachmentDetailsTranslation();
  const { removeRevisionCommand, loadingRemoveRevision } = useRemoveAttachmentRevisionRule();

  const buttonsDisabledOnRevisionUploadOrRemove = isAttachmentRevisionAddInProgress || loadingRemoveRevision;

  return (
    <ActionButtons__Container disabled={buttonsDisabledOnRevisionUploadOrRemove} variant={"outlined"} size={"small"}>
      <ReplaceAttachmentRevisionButton />
      {props.revisionNumber !== 1 && (
        <Button
          startIcon={loadingRemoveRevision ? <CircularProgress color={"secondary"} size={12} /> : <IconX />}
          onClick={() => removeRevisionCommand(props.attachmentId)}
          sx={{ fontSize: { xs: 12, sm: 14 } }}
        >
          {t("RevisionUserAndActions.remove")}
        </Button>
      )}
    </ActionButtons__Container>
  );
};

const ActionButtons__Container = styled(ButtonGroup)({
  "& .MuiButtonGroup-groupedOutlined": {
    borderColor: TakeTurnsColors.darkGray,
    color: TakeTurnsColors.darkGray,
    "&:hover": {
      borderColor: TakeTurnsColors.darkBlue,
      color: TakeTurnsColors.darkBlue,
    },
  },
});
