/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Stack, Tooltip, Typography } from "@mui/material";
import { IconHourglass } from "@taketurns-components/commons/icons";
import { isOrganizationInvitation, OrganizationMemberOrInvitation } from "@taketurns-components/user/Organization/OrganizationMemberAndInvitationList/shared/SharedComponentTypes";
import { useOrganizationTranslation } from "@taketurns-i18n/user/Organization/useOrganizationTranslation";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { getOrganizationMemberOrInvitationFirstAndLastNameOrEmail } from "@taketurns-rules/user/queries/getOrganizationMemberOrInvitationFirstAndLastNameOrEmail";

interface MemberOrInvitationFirstAndLastNameOrEmailProps {
  memberOrInvitation: OrganizationMemberOrInvitation;
}

export const MemberOrInvitationFirstAndLastNameOrEmail = ({
  memberOrInvitation,
}: MemberOrInvitationFirstAndLastNameOrEmailProps) => {
  const { t } = useOrganizationTranslation();
  const value = getOrganizationMemberOrInvitationFirstAndLastNameOrEmail(memberOrInvitation);

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={1}
      width={{ xs: "initial", md: "100%" }}
      className="MemberOrInvitationFirstAndLastNameOrEmail__Container"
    >
      {isOrganizationInvitation(memberOrInvitation) && (
        <Tooltip
          title={t(
            "OrganizationMemberAndInvitationList.OrganizationMemberAndInvitationDataGridColumns.pendingInvitation",
          )}
          enterTouchDelay={0}
        >
          <Stack direction="row" alignItems="center">
            <IconHourglass size={18} color={TakeTurnsColors.darkGray} />
          </Stack>
        </Tooltip>
      )}
      <Typography title={value} noWrap>
        {value}
      </Typography>
    </Stack>
  );
};
