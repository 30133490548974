/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useMutation } from "@apollo/client";

export const useDeactivateDisplayedTips = () => {
  const mutation = gql`
    mutation deactivateDisplayedTips {
      deactivateDisplayedTips {
        id
        tipsHidden
        hiddenTips
      }
    }
  `;
  return useMutation(mutation);
};
