/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { atom } from "recoil";

export const subscriptionRefreshCountState = atom({
  key: "subscriptionRefreshCount",
  default: 0,
});
