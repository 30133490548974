/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useQuery } from "@apollo/client";
import { ConnectedUser } from "@taketurns/taketurns-graphql-repository";

export const useFetchConnectedUserId = () => {
  const CONNECTED_USER_ID_QUERY = gql`
    query connectedUserId {
      connectedUser {
        id
      }
    }
  `;
  const {
    data,
    error: errorOnConnectedUserId,
    loading: loadingConnectedUserId,
  } = useQuery<{ connectedUser: ConnectedUser }>(CONNECTED_USER_ID_QUERY);
  return { userId: data?.connectedUser?.id, loadingConnectedUserId, errorOnConnectedUserId };
};
