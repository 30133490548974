/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useNavigate } from "react-router-dom";
import { AUTHENTICATED_ROUTES } from "@taketurns-app/routing/routes/authenticatedRoutes.constants";

export const useNavigateToCollaborationCreationRule = () => {
  const navigate = useNavigate();
  return () => {
    navigate(AUTHENTICATED_ROUTES.COLLABORATION_CREATE.FULL);
  };
};
