/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useDocumentViewTranslation } from "@taketurns-i18n/document/DocumentView/useDocumentViewTranslation";

export const useModifyDocumentDescription = () => {
  const MODIFY_DOCUMENT_DESCRIPTION = gql`
    mutation modifyDocumentDescription($id: ID!, $description: String!) {
      modifyDocumentDescription(id: $id, description: $description) {
        id
        description
      }
    }
  `;
  const [modifyDocumentDescription, modifyDocumentDescriptionResponse] = useMutation(MODIFY_DOCUMENT_DESCRIPTION);

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useDocumentViewTranslation();

  useEffect(() => {
    if (modifyDocumentDescriptionResponse.error) {
      console.error(modifyDocumentDescriptionResponse.error);
      enqueueSnackbar(t("snackbars.error"), { variant: "error" });
      modifyDocumentDescriptionResponse.reset();
    }
  }, [modifyDocumentDescriptionResponse.error]);

  const modifyDocumentDescriptionCommand = (documentId: string, description: string) => {
    modifyDocumentDescription({
      variables: { id: documentId, description: description },
    });
  };

  return {
    modifyDocumentDescriptionCommand,
    modifyDocumentDescriptionResponse,
  };
};
