/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Button, Skeleton, Stack, styled, useMediaQuery, useTheme } from "@mui/material";
import { useState } from "react";
import { useDocumentViewTranslation } from "@taketurns-i18n/document/DocumentView/useDocumentViewTranslation";
import { useModifyDocumentDescription } from "@taketurns-repositories/document/graphql/mutations/useModifyDocumentDescription";
import { useRenameDocument } from "@taketurns-repositories/document/graphql/mutations/useRenameDocument";
import { DESCRIPTION_FIELD_MAX_LENGTH, TEXT_FIELD_MAX_LENGTH } from "@taketurns-rules/commons/inputConstants";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { useFetchDocumentRule } from "@taketurns-rules/document/queries/useFetchDocumentRule";
import { useSubscribeOnDocumentChangedRule } from "@taketurns-rules/document/subscriptions/useSubscribeOnDocumentChangedRule";
import { LabelWithEditButton } from "../../commons/form/LabelWithEditButton";
import { IconPlusCircle } from "../../commons/icons";
import { DocumentExtension } from "./DocumentExtension";

interface DocumentDetailsProps {
  documentId: string;
  canEdit?: boolean;
  collaborationId?: string;
}

export const DocumentDetails = (props: DocumentDetailsProps) => {
  const { t } = useDocumentViewTranslation();

  const { document, loadingDocument, errorOnFetchingDocument } = useFetchDocumentRule(
    props.documentId,
    props.collaborationId,
  );

  const [editDescription, setEditDescription] = useState(false);
  useSubscribeOnDocumentChangedRule(props.documentId);
  const { renameDocumentCommand, renameDocumentResponse } = useRenameDocument();
  const { modifyDocumentDescriptionCommand, modifyDocumentDescriptionResponse } = useModifyDocumentDescription();

  const theme = useTheme();
  const isOnMediumScreen = useMediaQuery(theme.breakpoints.down("lg"));

  if (loadingDocument) {
    return <Skeleton width={"100%"} variant={"text"} />;
  }

  if (errorOnFetchingDocument) {
    console.error(errorOnFetchingDocument.message);
    return null;
  }
  const handleDescriptionEditEnded = (value: string) => {
    if (!value) {
      setEditDescription(false);
    }
    modifyDocumentDescriptionCommand(document.id, value);
  };

  return (
    <Stack flex={1} direction="column">
      <LabelWithEditButton
        currentLabel={document.name}
        fontSize="18px"
        required
        onEditEnded={(value) => renameDocumentCommand(document.id, value)}
        loading={renameDocumentResponse.loading}
        disabled={!props.canEdit}
        maxLength={TEXT_FIELD_MAX_LENGTH}
      />
      <Stack paddingLeft={"10px"} direction="row" flex={1} alignItems={"center"} gap={1.5} height={"30px"}>
        <DocumentExtension extension={document.extension} size={document.size} />
        {((editDescription || document.description) && (
          <Stack flex={1}>
            <LabelWithEditButton
              rows={3}
              currentLabel={document.description ?? ""}
              fontSize="0.875rem"
              onEditEnded={handleDescriptionEditEnded}
              loading={modifyDocumentDescriptionResponse.loading}
              disabled={!props.canEdit}
              displayEdit={editDescription}
              maxLength={DESCRIPTION_FIELD_MAX_LENGTH}
            />
          </Stack>
        )) ||
          (props.canEdit && (
            <AddDocumentDescriptionButton
              onClick={() => setEditDescription(true)}
              size="small"
              variant="text"
              disableRipple
              startIcon={<IconPlusCircle size={15} />}
            >
              {t(isOnMediumScreen ? "documentDescription.description" : "documentDescription.addDescription")}
            </AddDocumentDescriptionButton>
          ))}
      </Stack>
    </Stack>
  );
};

const AddDocumentDescriptionButton = styled(Button)({
  paddingLeft: 2,
  justifyContent: "flex-start",
  borderRadius: 0,
  color: TakeTurnsColors.grayButton,
  "&:hover": {
    backgroundColor: "unset",
  },
});
