/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { gql, useMutation } from "@apollo/client";
import { MutationModifyChatGptApiKeyArgs, UserIntegrations } from "@taketurns/taketurns-graphql-repository";

export const useModifyChatGptApiKey = () => {
  const MODIFY_CHAT_GPT_API_KEY = gql`
    mutation modifyChatGptApiKey($chatGptApiKey: String) {
      modifyChatGptApiKey(chatGptApiKey: $chatGptApiKey) {
        id
        chatGptApiKey
      }
    }
  `;
  const [modifyChatGptApiKey] = useMutation<
    Pick<UserIntegrations, "id" | "chatGptApiKey">,
    MutationModifyChatGptApiKeyArgs
  >(MODIFY_CHAT_GPT_API_KEY);
  return {
    modifyChatGptApiKey: (chatGptApiKey: string) => {
      modifyChatGptApiKey({
        variables: { chatGptApiKey },
      });
    },
  };
};
