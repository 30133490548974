/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { FolderInCreation } from "@taketurns-repositories/collaboration/models/folderInCreation";
import { useGetCurrentFolderIdInCreation } from "@taketurns-repositories/collaboration/state/read/collaborationCreation/useGetCurrentFolderIdInCreation";
import { useGetFolderInCreation } from "@taketurns-repositories/collaboration/state/read/collaborationCreation/useGetFolderInCreation";
import { ROOT_FOLDER_ID } from "@taketurns-repositories/collaboration/state/recoil/collaborationCreation/folderByIdState";
import { useAddFolderInCreationRule } from "@taketurns-rules/collaboration/commands/collaborationCreation/folder/useAddFolderInCreationRule";
import { DestinationFolder } from "@taketurns-rules/collaboration/commands/destinationFolder";
import { DestinationFolderList, DestinationFolderListProps } from "../../MoveDestination/DestinationFolderList";
import { DestinationFolderListItem } from "../../MoveDestination/DestinationFolderListItem";
import { MoveDestinationDialog, MoveDestinationDialogProps } from "../../MoveDestination/MoveDestinationDialog";

export const MoveDestinationDialogInCreation = (
  props: Omit<MoveDestinationDialogProps, "rootFolderId" | "DestinationFolderListSlot" | "movingElementSourceFolderId">,
) => {
  const currentFolderId = useGetCurrentFolderIdInCreation();

  return (
    <MoveDestinationDialog
      {...props}
      rootFolderId={ROOT_FOLDER_ID}
      movingElementSourceFolderId={currentFolderId}
      DestinationFolderListSlot={DestinationFolderListInCreation}
    />
  );
};

const DestinationFolderListInCreation = (
  props: Omit<
    DestinationFolderListProps,
    "useGetDestinationFolder" | "addFolderInSelectedFolder" | "FolderListItem" | "rootFolderId"
  >,
) => {
  const addFolder = useAddFolderInCreationRule();

  return (
    <DestinationFolderList
      {...props}
      rootFolderId={ROOT_FOLDER_ID}
      useGetDestinationFolder={useDestinationFolderFromFolderInCreationRule}
      addFolderInSelectedFolder={addFolder}
      FolderListItem={DestinationFolderListItemInCreation}
    />
  );
};

const useDestinationFolderFromFolderInCreationRule = (folderId: string) => {
  const folderInCreation = useGetFolderInCreation(folderId);
  return {
    destinationFolder: getDestinationFolderFromFolderInCreationId(folderId, folderInCreation),
    loadingDestinationFolder: false,
    errorWhileFetchingDestinationFolder: undefined,
  };
};

const getDestinationFolderFromFolderInCreationId = (
  folderId: string,
  folderInCreation: FolderInCreation,
): DestinationFolder => {
  return {
    id: folderId,
    name: folderInCreation.name,
    folders: folderInCreation.folders.map((folderId): DestinationFolder => {
      return {
        id: folderId,
        name: folderId,
        folders: [],
      };
    }),
  };
};

const DestinationFolderListItemInCreation = ({
  destinationFolder,
  onFolderClick,
}: {
  destinationFolder: DestinationFolder;
  onFolderClick: () => void;
}) => {
  const folderName = useGetFolderInCreation(destinationFolder.id).name;
  return <DestinationFolderListItem folderName={folderName} onFolderClick={onFolderClick} />;
};
