/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Stack } from "@mui/material";
import React from "react";
import { Attachment } from "@taketurns/taketurns-graphql-repository";
import { MobileAttachmentDetailsActionDropdown } from "@taketurns-components/collaboration/CollaborationView/AttachmentDetails/components/MobileAttachmentDetails/MobileAttachmentDetailsActionDropdown";
import { MobileAttachmentDetailsKebabMenu } from "@taketurns-components/collaboration/CollaborationView/AttachmentDetails/components/MobileAttachmentDetails/MobileAttachmentDetailsKebabMenu";
import { AttachmentDetailsHeaderDownloadSaveCancelButtons } from "@taketurns-components/collaboration/Shared/content/AttachmentDetailsHeaderDownloadSaveCancelButtons";

interface DetailsActionsBarProps {
  canMarkForReview: boolean;
  canDelete: boolean;
  isDeleteAttachmentDisabled: boolean;
  attachment: Attachment;
  collaborationId: string;
  selectedRevisionId: string;
  isPdfUpdated: boolean;
  onSaveRevisionButtonClick: () => void;
  onCancelAnnotationsButtonClick: () => void;
}

export const MobileAttachmentDetailsActionsBar = (props: DetailsActionsBarProps) => {
  const showActionDropdown = !props.isPdfUpdated;

  return (
    <Stack direction="row" paddingX={3} paddingY={2} alignItems="center" justifyContent="space-between">
      <AttachmentDetailsHeaderDownloadSaveCancelButtons
        documentId={props.attachment.document.id}
        collaborationId={props.collaborationId}
        revisionId={props.selectedRevisionId}
        onSaveRevisionButtonClick={props.onSaveRevisionButtonClick}
        onCancelAnnotationsButtonClick={props.onCancelAnnotationsButtonClick}
        isPdfUpdated={props.isPdfUpdated}
      />
      {showActionDropdown && (
        <MobileAttachmentDetailsActionDropdown
          attachment={props.attachment}
          canMarkForReview={props.canMarkForReview}
        />
      )}
      <MobileAttachmentDetailsKebabMenu
        attachment={props.attachment}
        canDelete={props.canDelete && !props.isDeleteAttachmentDisabled}
      />
    </Stack>
  );
};
