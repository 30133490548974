/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useSubscribeToCancelPendingUpdatesRule } from "@taketurns-rules/collaboration/subscriptions/updates/useSubscribeToCancelPendingUpdatesRule";
import { useSubscribeToRecallUpdatesRule } from "@taketurns-rules/collaboration/subscriptions/updates/useSubscribeToRecallUpdatesRule";
import { useSubscribeToSendUpdatesRule } from "@taketurns-rules/collaboration/subscriptions/updates/useSubscribeToSendUpdatesRule";

export const useSubscribeToRebasedContentRule = () => {
  useSubscribeToSendUpdatesRule();
  useSubscribeToRecallUpdatesRule();
  useSubscribeToCancelPendingUpdatesRule();
};
