/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Fade, Grid, Stack, Typography } from "@mui/material";
import { ReactNode } from "react";

interface RegisterOrLoginHeaderProps {
  text: string;
  secondaryText: ReactNode;
}

/**
 * Sign-up or Sign-in pages
 * header text content
 * Display text on left and secondary
 * text on right
 * @param {RegisterOrLoginHeaderProps} props register/login header props
 * @returns header component for public TakeTurns register pages
 */
export const RegisterOrLoginHeader = (props: RegisterOrLoginHeaderProps) => {
  const { text, secondaryText } = props;
  return (
    <Fade in>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        height={{ md: 130 }}
        minHeight={{ md: 130 }}
        paddingBottom={{ xs: 3, md: 0 }}
      >
        <Stack alignItems="baseline" justifyContent="space-between" width={1} flexDirection="row">
          <Typography variant="h6">{text}</Typography>
          <Typography variant="caption" color="secondary" textAlign={"right"}>
            {secondaryText}
          </Typography>
        </Stack>
      </Grid>
    </Fade>
  );
};
