/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { gql, useMutation } from "@apollo/client";
import { SubscriptionPlan, SwitchPlanInput } from "@taketurns/taketurns-graphql-repository";
import { updateUserOrganizationSubscriptionTierInCache } from "@taketurns-repositories/user/graphql/cache/updateUserOrganizationSubscriptionTierInCache";

const SWITCH_PLAN = gql`
  mutation switchPlan($switchPlanInput: SwitchPlanInput!) {
    switchPlan(switchPlanInput: $switchPlanInput)
  }
`;
export const useSwitchPlan = () => {
  const [switchPlanMutation, { error: errorOnSwitchingPlan, loading: loadingSwitchPlan }] = useMutation<{
    switchPlan: SubscriptionPlan;
  }>(SWITCH_PLAN);

  const switchPlan = (switchPlanInput: SwitchPlanInput) => {
    return switchPlanMutation({
      variables: { switchPlanInput },
      update: (cache) => updateUserOrganizationSubscriptionTierInCache(cache, switchPlanInput.subscriptionPlanTier),
    });
  };

  return { switchPlan, errorOnSwitchingPlan, loadingSwitchPlan };
};
