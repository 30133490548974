/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { gql, useQuery } from "@apollo/client";
import { ConnectedUser } from "@taketurns/taketurns-graphql-repository";

export const useFetchChatGptApiKey = () => {
  const GET_CHAT_GPT_API_KEY = gql`
    query getConnectedUserChatGptApiKey {
      connectedUser {
        id
        integrations {
          id
          chatGptApiKey
        }
      }
    }
  `;
  const { data, loading, error } = useQuery<{
    connectedUser: Partial<ConnectedUser>;
  }>(GET_CHAT_GPT_API_KEY);

  return {
    chatGptApiKey: data?.connectedUser.integrations.chatGptApiKey,
    loading,
    error,
  };
};
