/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { InviteToOrganizationInput } from "@taketurns/taketurns-graphql-repository";
import { useOrganizationTranslation } from "@taketurns-i18n/user/Organization/useOrganizationTranslation";
import { useInviteToOrganization } from "@taketurns-repositories/user/graphql/mutations/useInviteToOrganization";
import { useDisplayAndLogNotificationErrorRule } from "./useDisplayAndLogNotificationErrorRule";
import { useDisplayNotificationSuccessRule } from "./useDisplayNotificationSuccessRule";

export const useInviteToOrganizationRule = () => {
  const {
    inviteToOrganization: inviteToOrganizationCommand,
    errorOnInviteToOrganization,
    loadingInviteToOrganization,
  } = useInviteToOrganization();

  const displayNotificationAndLogError = useDisplayAndLogNotificationErrorRule();

  const { t } = useOrganizationTranslation();
  const displaySuccessNotification = useDisplayNotificationSuccessRule();
  const inviteToOrganization = (invitation: InviteToOrganizationInput) => {
    inviteToOrganizationCommand(invitation)
      .then(() => displaySuccessNotification(t("Notifications.inviteToOrganizationSuccess")))
      .catch((error) => displayNotificationAndLogError(error));
  };
  return { inviteToOrganization, errorOnInviteToOrganization, loadingInviteToOrganization };
};
