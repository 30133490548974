/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Avatar, Box, Button, CircularProgress, Dialog, Skeleton, styled, Typography } from "@mui/material";
import { PendingOrganizationInvitation } from "@taketurns/taketurns-graphql-repository";
import { useOrganizationInvitationNotificationTranslation } from "@taketurns-i18n/user/UserNotificationCenter/OrganizationInvitationNotificationAndDialog/useOrganizationInvitationNotificationTranslation";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { useAcceptOrganizationInvitationRule } from "@taketurns-rules/user/commands/useAcceptOrganizationInvitationRule";
import { useDeclineOrganizationInvitationRule } from "@taketurns-rules/user/commands/useDeclineOrganizationInvitationRule";
import { useFetchUserIsAllowedToJoinOtherOrganizationRule } from "@taketurns-rules/user/queries/useFetchUserAllowedToJoinOtherOrganizationRule";
import { useFetchUserOrganizationRule } from "@taketurns-rules/user/queries/useFetchUserOrganizationRule";
import { TakeTurnsDefaultButton, TakeTurnsGreyButton } from "../../../commons/button";
import { DialogFooter } from "../../../commons/dialog/DialogFooter";
import { ResponsiveDialog } from "../../../commons/dialog/ResponsiveDialog";

interface OrganizationInvitationDialogProps {
  pendingOrganizationInvitation: PendingOrganizationInvitation;
  open: boolean;
  closeDialog: () => void;
}

export const OrganizationInvitationDialog = ({
  pendingOrganizationInvitation,
  open,
  closeDialog,
}: OrganizationInvitationDialogProps) => {
  const { t } = useOrganizationInvitationNotificationTranslation();
  const { acceptOrganizationInvitation, loadingAcceptOrganizationInvitation } = useAcceptOrganizationInvitationRule();
  const { declineOrganizationInvitation, loadingDeclineOrganizationInvitation } =
    useDeclineOrganizationInvitationRule();
  const {
    userIsAllowedToJoinOtherOrganization,
    loadingUserIsAllowedToJoinOtherOrganization,
    errorOnFetchUserIsAllowedToJoinOtherOrganization,
  } = useFetchUserIsAllowedToJoinOtherOrganizationRule();
  const { userOrganization, loadingUserOrganization } = useFetchUserOrganizationRule();

  const declineInvitationAndCloseDialogOnSuccess = () => {
    declineOrganizationInvitation(pendingOrganizationInvitation.organizationId!).then(() => closeDialog);
  };

  const acceptInvitationAndCloseDialogOnSuccess = () => {
    acceptOrganizationInvitation(pendingOrganizationInvitation.organizationId!).then(() => closeDialog());
  };

  const loadingUserOrganizationOrAllowedToJoinOtherOrganization =
    loadingUserIsAllowedToJoinOtherOrganization || loadingUserOrganization;

  if (errorOnFetchUserIsAllowedToJoinOtherOrganization) {
    console.error(errorOnFetchUserIsAllowedToJoinOtherOrganization);
    return null;
  }

  if (loadingUserOrganizationOrAllowedToJoinOtherOrganization) {
    return (
      <Dialog open={open} onClose={closeDialog}>
        <Skeleton sx={{ flex: 1 }} />
      </Dialog>
    );
  }

  const loadingAcceptOrDeclineInvitation = loadingAcceptOrganizationInvitation || loadingDeclineOrganizationInvitation;

  return (
    <ResponsiveDialog
      open={open}
      title={
        <Typography>
          {t("OrganizationInvitationNotificationAndDialog.dialog.title") + " "}
          <OrganizationName__Text>{pendingOrganizationInvitation.organizationName}</OrganizationName__Text>
        </Typography>
      }
      content={
        <OrganizationInvitation__DialogContent>
          <OrganizationInvitationAvatarAndText__Container>
            <Avatar src={pendingOrganizationInvitation.invitingUser.avatar!} />
            <Typography variant={"body2"}>
              <InvitingUser__Text>{`${pendingOrganizationInvitation.invitingUser?.firstName} ${pendingOrganizationInvitation.invitingUser?.lastName} `}</InvitingUser__Text>
              {t("OrganizationInvitationNotificationAndDialog.dialog.userInviteText", {
                organizationName: pendingOrganizationInvitation.organizationName,
              })}
            </Typography>
          </OrganizationInvitationAvatarAndText__Container>
          <Typography variant={"body2"}>
            {!userIsAllowedToJoinOtherOrganization
              ? t("OrganizationInvitationNotificationAndDialog.dialog.notAllowedToJoinOrganization", {
                  organizationNameToJoin: pendingOrganizationInvitation.organizationName,
                  userOrganizationName: userOrganization?.name,
                })
              : t("OrganizationInvitationNotificationAndDialog.dialog.invitationDescription", {
                  organizationName: pendingOrganizationInvitation.organizationName,
                })}
          </Typography>
          {userIsAllowedToJoinOtherOrganization && (
            <Typography variant={"body2"}>
              {t("OrganizationInvitationNotificationAndDialog.dialog.collaborationDescription")}
            </Typography>
          )}
        </OrganizationInvitation__DialogContent>
      }
      footer={
        <DialogFooter>
          {loadingAcceptOrDeclineInvitation && <CircularProgress color={"info"} size={16} />}
          <Button variant={"text"} onClick={closeDialog}>
            {t("OrganizationInvitationNotificationAndDialog.dialog.actions.decideLater")}
          </Button>
          <TakeTurnsGreyButton
            disabled={loadingAcceptOrDeclineInvitation}
            onClick={declineInvitationAndCloseDialogOnSuccess}
          >
            {t("OrganizationInvitationNotificationAndDialog.dialog.actions.decline")}
          </TakeTurnsGreyButton>
          <TakeTurnsDefaultButton
            disabled={loadingAcceptOrDeclineInvitation || !userIsAllowedToJoinOtherOrganization}
            onClick={acceptInvitationAndCloseDialogOnSuccess}
          >
            {t("OrganizationInvitationNotificationAndDialog.dialog.actions.accept")}
          </TakeTurnsDefaultButton>
        </DialogFooter>
      }
    />
  );
};

const OrganizationName__Text = styled("span")({
  color: TakeTurnsColors.darkBlue,
  fontWeight: 600,
});

const InvitingUser__Text = styled("span")({
  color: TakeTurnsColors.darkBlue,
});

const OrganizationInvitation__DialogContent = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "24px",
});

const OrganizationInvitationAvatarAndText__Container = styled(Box)({
  display: "flex",
  flexDirection: "row",
  gap: "16px",
  alignItems: "center",
});
