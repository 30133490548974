/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useGetConnectedUserPartyId } from "@taketurns-repositories/collaboration/context/useGetConnectedUserPartyId";
import { useSubscribeToMarkUnmarkAsReviewedAttachment } from "@taketurns-repositories/collaboration/graphql/subscriptions/content/useSubscribeToMarkUnmarkAsReviewedAttachment";
import { useGetDisplayedCollaborationIdRule } from "@taketurns-rules/collaboration/queries/collaboration/useGetDisplayedCollaborationIdRule";

export const useSubscribeToMarkUnmarkAsReviewedAttachmentRule = (attachmentId: string) => {
  const displayedCollaborationId = useGetDisplayedCollaborationIdRule();
  const connectedUserPartyId = useGetConnectedUserPartyId();
  return useSubscribeToMarkUnmarkAsReviewedAttachment({
    collaborationId: displayedCollaborationId,
    partyId: connectedUserPartyId,
    attachmentId,
  });
};
