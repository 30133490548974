/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { PendingAttachment } from "@taketurns-repositories/collaboration/models/pendingAttachment";
import { PendingAttachmentStatus } from "@taketurns-repositories/collaboration/models/pendingAttachmentStatus";
import { useSetPendingAttachmentStatus } from "./useSetPendingAttachmentStatus";

export const useMarkPendingAttachmentAsAttached = () => {
  const setPendingAttachmentStatus = useSetPendingAttachmentStatus();
  return (pendingAttachment: PendingAttachment) =>
    setPendingAttachmentStatus(pendingAttachment, PendingAttachmentStatus.attached);
};
