/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useSnackbar } from "notistack";
import { useState } from "react";
import { CollaborationStatus, Invitee } from "@taketurns/taketurns-graphql-repository";
import { useCollaborationTranslation } from "@taketurns-i18n/collaboration/useCollaborationTranslation";
import {
  useResendInvitation,
} from "@taketurns-repositories/collaboration/graphql/mutations/participants/useResendInvitation";
import {
  CollaborationCommand,
  useCollaborationPermissions,
} from "@taketurns-rules/collaboration/permissions/useCollaborationPermissions";
import {
  useGetDisplayedCollaborationIdRule,
} from "@taketurns-rules/collaboration/queries/collaboration/useGetDisplayedCollaborationIdRule";
import {
  useFetchCollaborationStatusRule,
} from "@taketurns-rules/collaboration/queries/fields/useFetchCollaborationStatusRule";

export const useResendInvitationRule = () => {
  const isAllowedToResendInvitation = useIsAllowedToResendInvitation();
  const { loadingResendInvitation, resendInvitation } = useResendInvitationCommand();

  return { isAllowedToResendInvitation, resendInvitation, loadingResendInvitation };
};

const useIsAllowedToResendInvitation = () => {
  const isUserRoleAllowedToResendInvitation = useCollaborationPermissions(CollaborationCommand.RESEND_INVITATION);
  const { status } = useFetchCollaborationStatusRule();
  return status === CollaborationStatus.Open && isUserRoleAllowedToResendInvitation;
};

function useResendInvitationCommand() {
  const [loadingResendInvitation, setLoadingResendInvitation] = useState(false);
  const { resendInvitationCommand } = useResendInvitation();
  const collaborationId = useGetDisplayedCollaborationIdRule();
  const notifyInvitationHasBeenSent = useNotifyInvitationHasBeenSent();
  const resendInvitation = (invitation: Invitee) => {
    setLoadingResendInvitation(true);
    const { userId, email } = invitation;
    resendInvitationCommand({ collaborationId, userId, email }, notifyInvitationHasBeenSent);
    const LOADING_RESEND_INVITATION_DELAY = 4000;
    setTimeout(() => setLoadingResendInvitation(false), LOADING_RESEND_INVITATION_DELAY);
  };
  return { loadingResendInvitation, resendInvitation };
}

const useNotifyInvitationHasBeenSent = () => {
  const { t } = useCollaborationTranslation("notifications");
  const { enqueueSnackbar } = useSnackbar();
  return () => {
    enqueueSnackbar(t("user.resendInvitation"), {
      variant: "info",
    });
  };
};
