/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useQuery } from "@apollo/client";
import { Organization } from "@taketurns/taketurns-graphql-repository";

export const useFetchHasUserOrganizationOnlyOneMember = (skip?: boolean) => {
  const HAS_USER_ORGANIZATION_ONLY_ONE_MEMBER = gql`
    query hasUserOrganizationOnlyOneMember {
      userOrganization {
        members {
          userId
        }
      }
    }
  `;
  const {
    data,
    loading: loadingUserOrganizationIsSingleMember,
    error: errorOnUserOrganizationIsSingleMember,
  } = useQuery<{
    userOrganization: Organization;
  }>(HAS_USER_ORGANIZATION_ONLY_ONE_MEMBER, { skip });

  return {
    hasUserOrganizationOnlyOneMember: data?.userOrganization?.members?.length === 1,
    loadingUserOrganizationIsSingleMember,
    errorOnUserOrganizationIsSingleMember,
  };
};
