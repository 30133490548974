/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import {
  SubscriptionPlanCurrency,
  SubscriptionPlanPeriod,
  SubscriptionPlanRegion,
  SubscriptionPlanTier,
} from "@taketurns/taketurns-graphql-repository";

export interface SelectedSubscriptionPlan {
  currency: SubscriptionPlanCurrency;
  region: SubscriptionPlanRegion;
  tier: SubscriptionPlanTier;
  period: SubscriptionPlanPeriod;
  language: string;
}

export const SUBSCRIPTION_PLAN_SESSION_STORAGE_KEY = "taketurns_select_subscription_plan";
