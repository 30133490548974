/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { selector } from "recoil";
import { CreateInviteeInput } from "@taketurns/taketurns-graphql-repository";
import { collaborationFormState } from "./collaborationFormState";

export const selectCollaborationFormInvitations = selector({
  key: "collaborationFormInvitations",
  get: ({ get }) => {
    return get(collaborationFormState)?.invitations ?? [];
  },
  set: ({ set }, invitations) => {
    set(collaborationFormState, (currentCollaborationFormState) => ({
      ...currentCollaborationFormState,
      invitations: invitations as CreateInviteeInput[],
    }));
  },
});
