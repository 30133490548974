/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useRecoilCallback } from "recoil";
import { retriedUploadIdsState } from "../recoil/retriedUploadIdsState";

export const useAddUploadIdToRetriedUploadIds = () => {
  return useRecoilCallback(
    ({ set }) =>
      (uploadId: number) => {
        set(retriedUploadIdsState, (currentIds) => new Set([...currentIds, uploadId]));
      },
    [],
  );
};
