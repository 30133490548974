/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useQuery } from "@apollo/client";
import { Folder } from "@taketurns/taketurns-graphql-repository";
import { attachmentFragment } from "@taketurns-repositories/collaboration/graphql/fragments/attachmentFragment";
import { subFolderFragment } from "@taketurns-repositories/collaboration/graphql/fragments/subFolderFragment";
import { requestFragment } from "../../fragments/requestFragment";

export const FolderFragment = gql`
  ${subFolderFragment}
  ${requestFragment}
  ${attachmentFragment}
  fragment FolderFragment on Folder {
    id
    name
    status
    folders {
      ...SubFolderFragment
    }
    attachments {
      ...AttachmentFragment
    }
    requests {
      ...RequestFragment
    }
  }
`;

export const GET_FOLDER = gql`
  query getFolder($collaborationId: ID!, $folderId: ID!) {
    getFolder(collaborationId: $collaborationId, folderId: $folderId) {
      ...FolderFragment
    }
  }
  ${FolderFragment}
`;

export const useFetchFolder = (collaborationId: string, folderId: string) => {
  const {
    data,
    loading: loadingFolder,
    error: errorOnFetchingFolder,
  } = useQuery<{ getFolder: Folder }>(GET_FOLDER, {
    fetchPolicy: "cache-first",
    nextFetchPolicy: (_, { reason }) => {
      // When variables change, by default, Apollo uses fetchPolicy again instead of nextFetchPolicy,
      // but data might have changed (and we only subscribe to the current folder), so we need to re-fetch visited folders and update cache
      if (reason === "variables-changed") return "cache-and-network";
      return "cache-first";
    },
    variables: { collaborationId, folderId },
  });
  return {
    folder: data?.getFolder,
    loadingFolder,
    errorOnFetchingFolder,
  };
};
