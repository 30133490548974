/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { JobFunction } from "../../commons/enum/jobFunctionEnum";
import { getExtendedSchemaWithPassword, refinePasswordWithForbiddenFields } from "../../commons/passwordValidationRule";

export enum SignUpUserFormFields {
  email = "email",
  password = "password",
  passwordConfirmation = "passwordConfirmation",
  name = "name",
  family_name = "family_name",
  function = "function",
  job_title = "job_title",
  isTosAndPrivacyPolicyAccepted = "isTosAndPrivacyPolicyAccepted",
}

const signUpUserFormFieldSchema = z.object({
  [SignUpUserFormFields.email]: z.string().min(1),
  [SignUpUserFormFields.name]: z.string().min(1),
  [SignUpUserFormFields.family_name]: z.string().min(1),
  [SignUpUserFormFields.function]: z.nativeEnum(JobFunction),
  [SignUpUserFormFields.job_title]: z.string().optional(),
  [SignUpUserFormFields.isTosAndPrivacyPolicyAccepted]: z.literal(true),
});

const signUpFormSchema = refinePasswordWithForbiddenFields(getExtendedSchemaWithPassword(signUpUserFormFieldSchema), {
  forbiddenFields: [SignUpUserFormFields.email, SignUpUserFormFields.name, SignUpUserFormFields.family_name],
});

export type SignUpFormFields = z.infer<typeof signUpFormSchema>;

export const useSignUpFormValidationRule = (defaultSignUpEmail: string) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    trigger,
  } = useForm<SignUpFormFields>({
    resolver: zodResolver(signUpFormSchema),
    defaultValues: { email: defaultSignUpEmail },
  });
  return { handleSubmit, control, errors, trigger };
};
