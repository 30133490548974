/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useQuery } from "@apollo/client";
import { Collaboration } from "@taketurns/taketurns-graphql-repository";

const OTHER_PARTY_NAME = gql`
  query connectedUserParty($collaborationId: ID!) {
    getCollaboration(collaborationId: $collaborationId) {
      id
      userContext {
        id
        otherPartyName
      }
    }
  }
`;

export const useFetchOtherPartyName = (collaborationId: string) => {
  const {
    data,
    loading: loadingOtherPartyName,
    error: errorOnFetchingOtherPartyName,
  } = useQuery<{
    getCollaboration: Collaboration;
  }>(OTHER_PARTY_NAME, { variables: { collaborationId } });
  return {
    otherPartyName: data?.getCollaboration.userContext.otherPartyName,
    loadingOtherPartyName,
    errorOnFetchingOtherPartyName,
  };
};
