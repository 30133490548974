/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Box, Stack } from "@mui/material";
import { useState } from "react";
import { useGetSubscriptionPlansRule } from "@taketurns-rules/user/queries/useGetSubscriptionPlansRule";
import { MobileSubscriptionPlanElement } from "./MobileSubscriptionPlanElement/MobileSubscriptionPlanElement";
import { MobileSubscriptionPlanTabButton } from "./MobileSubscriptionPlanElement/MobileSubscriptionPlanTabButton";

interface MobileSubscriptionPlansProps {
  isUserAuthenticated?: boolean;
}

export const MobileSubscriptionPlans = (props: MobileSubscriptionPlansProps) => {
  const { isUserAuthenticated } = props;
  const subscriptionPlans = useGetSubscriptionPlansRule();

  const PLUS_PLAN_PAGE_NUMBER = 1;
  const [page, setPage] = useState(PLUS_PLAN_PAGE_NUMBER);

  return (
    <Stack width={"100%"} flex={1}>
      <Box sx={{ width: "100%" }}>
        <Stack
          direction="row"
          spacing="1px"
          sx={{
            backgroundColor: "white",
            overflow: "hidden",
            borderRadius: "20px 20px 0 0",
          }}
        >
          {subscriptionPlans.map((subscriptionPlan, index) => (
            <MobileSubscriptionPlanTabButton
              isSelected={page === index}
              setPage={setPage}
              tabIndex={index}
              subscriptionPlan={subscriptionPlan}
              key={index}
            />
          ))}
        </Stack>
      </Box>
      {subscriptionPlans.map((subscriptionPlan, index) => (
        <MobileSubscriptionPlanElement
          key={index}
          hidden={page !== index}
          subscriptionPlan={subscriptionPlan}
          isUserAuthenticated={isUserAuthenticated}
        />
      ))}
    </Stack>
  );
};
