/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Storage } from "aws-amplify";
import { useState } from "react";
import { ConnectedUser } from "@taketurns/taketurns-graphql-repository";
import { configureS3Storage } from "@taketurns-repositories/user/fileStorage/fileStorageService";
import { getEnvironmentVariable } from "../../../getEnvironmentVariable";
import { useModifyConnectedUserRule } from "./useModifyConnectedUserRule";

export function useUpdateAvatarRule(user: ConnectedUser): {
  saveAvatar: (avatarFile: File) => Promise<void>;
  isProcessingUpload: boolean;
} {
  const { modifyUser, getUserEditableFields } = useModifyConnectedUserRule();
  const [isProcessingUpload, setIsProcessingUpload] = useState(false);

  const saveAvatar = async (avatarFile: File) => {
    setIsProcessingUpload(true);
    const { key } = await storeAvatar(user.id, avatarFile);
    const avatarUrl = `https://${getEnvironmentVariable("AVATAR_PUBLIC_LOCATION")}/${key}`;
    saveAvatarToUserProfile(avatarUrl, user);
    setIsProcessingUpload(false);
  };

  return { saveAvatar, isProcessingUpload };

  function storeAvatar(userId: string, avatarBlob: Blob) {
    if (!getEnvironmentVariable("AVATAR_STORAGE_LOCATION")) {
      throw new Error("Avatar storage location is not set");
    }
    configureS3Storage(getEnvironmentVariable("AVATAR_STORAGE_LOCATION"));

    const fileName = `${userId}_${Date.now()}.jpeg`;

    return Storage.put(fileName, avatarBlob, {
      contentType: "image/jpeg",
      customPrefix: { public: getEnvironmentVariable("AVATAR_UPLOAD_FOLDER_PATH") },
    });
  }

  function saveAvatarToUserProfile(avatarUrl: string, user: ConnectedUser) {
    modifyUser(user, {
      ...getUserEditableFields(user),
      avatar: `${avatarUrl}`,
    });
  }
}
