/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { atom } from "recoil";

export const collaborationIdsLinkedToPendingAttachmentsState = atom<Set<string>>({
  key: "collaborationIdsLinkedToPendingAttachments",
  default: new Set(),
});
