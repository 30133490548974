/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { PendingAttachment } from "@taketurns-repositories/collaboration/models/pendingAttachment";
import { useStorePendingAttachment } from "@taketurns-repositories/collaboration/state/write/storePendingAttachment";
import { Upload } from "@taketurns-repositories/document/state/models/upload";
import { useStoreNewUpload } from "@taketurns-repositories/document/state/write/useStoreNewUpload";
import { useGetDisplayedCollaborationIdRule } from "@taketurns-rules/collaboration/queries/collaboration/useGetDisplayedCollaborationIdRule";
import { useAddAttachmentBulkRule } from "../../useAddAttachmentBulkRule";
import { useCheckAvailableSpaceAndNotifyInExistingCollaborationRule } from "../useCheckAvailableSpaceAndNotifyInExistingCollaborationRule";
import { useFilterFilesTooBigAndNotifyInExistingCollaborationRule } from "../useFilterFilesTooBigAndNotifyInExistingCollaborationRule";

export const useAttachFilesInFolderRule = () => {
  const storePendingAttachmentsInState = useStorePendingAttachment();
  const storeNewUpload = useStoreNewUpload();
  const collaborationId = useGetDisplayedCollaborationIdRule();

  const filterTooBigFilesAndNotify = useFilterFilesTooBigAndNotifyInExistingCollaborationRule();
  const checkAvailableSpaceAndNotify = useCheckAvailableSpaceAndNotifyInExistingCollaborationRule();
  const addAttachmentBulk = useAddAttachmentBulkRule();

  return async (files: File[], folderId: string, requestName?: string) => {
    const filesToUpload = filterTooBigFilesAndNotify([...files]);
    const hasEnoughSpaceAvailable = await checkAvailableSpaceAndNotify([...files]);
    if (!hasEnoughSpaceAvailable) return;
    const numberOfAttachments = filesToUpload.length;
    const uploadIds: number[] = [];
    for (const file of filesToUpload) {
      const upload: Upload = await storeNewUpload(file);
      const pendingAttachment: PendingAttachment = new PendingAttachment(
        collaborationId,
        file.name.substring(0, file.name.lastIndexOf(".")),
        file.name.substring(file.name.lastIndexOf(".") + 1),
        file.size,
        folderId,
        upload.uploadId,
      );
      pendingAttachment.requestName = requestName;
      storePendingAttachmentsInState(pendingAttachment);
      uploadIds.push(upload.uploadId);
    }
    addAttachmentBulk({ numberOfAttachments, uploadIds });
  };
};
