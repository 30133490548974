/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { gql, useQuery } from "@apollo/client";
import { Collaboration } from "@taketurns/taketurns-graphql-repository";

const COLLABORATION_DEADLINE = gql`
  query collaborationDeadline($collaborationId: ID!) {
    getCollaboration(collaborationId: $collaborationId) {
      id
      deadline
      startDate
    }
  }
`;

export const useFetchCollaborationDeadline = (collaborationId: string) => {
  const {
    data,
    error: errorOnCollaborationNameAndParties,
    loading: loadingCollaborationNameAndParties,
  } = useQuery<{
    getCollaboration: Collaboration;
  }>(COLLABORATION_DEADLINE, { variables: { collaborationId } });
  return {
    collaborationWithDeadlineAndStartDate: data?.getCollaboration,
    errorOnCollaborationNameAndParties,
    loadingCollaborationNameAndParties,
  };
};
