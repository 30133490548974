/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Box, ListItem, ListItemText, Skeleton, Stack, Typography } from "@mui/material";
import { Invitee, Participant, Party } from "@taketurns/taketurns-graphql-repository";
import { InvitationForm } from "@taketurns-components/collaboration/Shared/ParticipantInviteeList/InvitationForm";
import { InvitationItemComponent } from "@taketurns-components/collaboration/Shared/ParticipantInviteeList/InvitationItemComponent";
import { ParticipantInvitationList } from "@taketurns-components/collaboration/Shared/ParticipantInviteeList/ParticipantInvitationList";
import { ParticipantItemComponent } from "@taketurns-components/collaboration/Shared/ParticipantInviteeList/ParticipantItemComponent";
import { SomethingWentWrong } from "@taketurns-components/shared/SomethingWentWrong";
import { useCollaborationTranslation } from "@taketurns-i18n/collaboration/useCollaborationTranslation";
import { useInviteToCollaborationRule } from "@taketurns-rules/collaboration/commands/collaborationView/participants/useInviteToCollaborationRule";
import { useShouldDisplayInvitationFormRule } from "@taketurns-rules/collaboration/queries/collaborationView/useShouldDisplayInvitationFormRule";
import { useFetchConnectedUserPartyRule } from "@taketurns-rules/collaboration/queries/context/useFetchConnectedUserPartyRule";
import { getColorForUserParty } from "@taketurns-rules/collaboration/utils/color/TakeTurnsBlueColorsUtils";

interface MobileParticipantPanelProps {
  invitations: Invitee[];
  participants: Participant[];
  userParty: Party;
  party: Party;
}

export const MobileParticipantPanel = (props: MobileParticipantPanelProps) => {
  const { t } = useCollaborationTranslation("participantCollaboration");
  const { connectedUserParty, errorOnFetchingConnectedUserParty, loadingConnectedUserParty } =
    useFetchConnectedUserPartyRule();

  const { inviteToCollaboration, loadingInviteToCollaboration } = useInviteToCollaborationRule();

  const filteredParticipants = props.participants.filter((participant) => participant.party === props.party);

  const filteredInvitees = props.invitations.filter((invitee) => invitee.party === props.party);
  const shouldDisplayInvitationForm = useShouldDisplayInvitationFormRule();

  if (errorOnFetchingConnectedUserParty) {
    return <SomethingWentWrong />;
  }
  if (loadingConnectedUserParty) {
    return (
      <Stack>
        <Skeleton variant="text" width={200} />
      </Stack>
    );
  }
  return (
    <Stack>
      {shouldDisplayInvitationForm && (
        <Box marginX={3} marginBottom={3}>
          <InvitationForm
            buttonLabel={t("invite")}
            onButtonClick={inviteToCollaboration}
            partyToInviteTo={props.party}
            connectedUserParty={connectedUserParty}
          />
        </Box>
      )}
      <ParticipantInvitationList
        connectedUserParty={connectedUserParty}
        ParticipantItemComponent={ParticipantItemComponent}
        InvitationItemComponent={InvitationItemComponent}
        color={getColorForUserParty(props.party, props.userParty)}
        participants={filteredParticipants}
      />
      {filteredInvitees.length !== 0 && <InvitationSectionTitle inviteeCount={filteredInvitees.length} />}
      <ParticipantInvitationList
        connectedUserParty={connectedUserParty}
        ParticipantItemComponent={ParticipantItemComponent}
        InvitationItemComponent={InvitationItemComponent}
        color={getColorForUserParty(props.party, props.userParty)}
        invitees={filteredInvitees}
        addElementLoading={loadingInviteToCollaboration}
      />
    </Stack>
  );
};

const InvitationSectionTitle = ({ inviteeCount }: { inviteeCount: number }) => {
  const { t } = useCollaborationTranslation("participantCollaboration");
  return (
    <ListItem disablePadding sx={{ px: 3, height: "50px", minHeight: "50px" }}>
      <ListItemText
        primary={
          <Typography noWrap fontSize={"0.75rem"}>
            {t("pendingInvitation", { count: inviteeCount })}
          </Typography>
        }
      />
    </ListItem>
  );
};
