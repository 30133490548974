/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { ApolloCache } from "@apollo/client";
import { AddAttachmentRevisionOutput, ContentStatus } from "@taketurns/taketurns-graphql-repository";

export function updateCacheOnAttachmentRevisionsAdded(
  cache: ApolloCache<unknown>,
  addAttachmentRevisionOutput: AddAttachmentRevisionOutput,
) {
  cache.modify({
    id: cache.identify({ id: addAttachmentRevisionOutput.attachmentId, __typename: "Attachment" }),
    fields: {
      status(currentStatus) {
        if (currentStatus !== ContentStatus.Added) {
          return ContentStatus.Updated;
        }
        return currentStatus;
      },
      revisionId() {
        return addAttachmentRevisionOutput.attachment.document.revisions.at(-1).revisionId;
      },
      hasAttachmentPendingRevision() {
        return true;
      },
      toReview() {
        return false;
      },
      markAsReviewed() {
        return true;
      },
    },
  });
}
