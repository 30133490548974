/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useConnectedUserTipsHidden } from "@taketurns-repositories/user/graphql/queries/useFetchConnectedUserHiddenTips";

export const useIsResetHiddenTipsDisplayedRule = () => {
  const {
    hiddenTips,
    hiddenTipsLoading: isResetHiddenTipsDisplayedLoading,
    hiddenTipsError: isResetHiddenTipsDisplayedError,
  } = useConnectedUserTipsHidden();
  const isResetHiddenTipsDisplayed =
    !isResetHiddenTipsDisplayedLoading && !isResetHiddenTipsDisplayedError && hiddenTips.length > 0;
  return { isResetHiddenTipsDisplayed };
};
