/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useCollaborationViewContext } from "@taketurns-repositories/collaboration/context/CollaborationViewContext";

export const useGetDisplayedCollaborationRootFolderId = () => {
  const { rootFolderId } = useCollaborationViewContext();
  return rootFolderId;
};
