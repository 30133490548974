/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useQuery } from "@apollo/client";
import { ConnectedUser } from "@taketurns/taketurns-graphql-repository";

export const USER_ORGANIZATION_NAME_AND_SUBSCRIPTION_PLAN = gql`
  query userOrganization {
    connectedUser {
      id
      organization {
        id
        name
        subscription {
          id
          availableSpaceInBytes
          status
          tier
          totalSpaceInMB
          trialEndDate
        }
      }
    }
  }
`;

export const useFetchUserOrganizationNameAndSubscriptionPlan = () => {
  const { data, loading, error } = useQuery<{
    connectedUser: ConnectedUser;
  }>(USER_ORGANIZATION_NAME_AND_SUBSCRIPTION_PLAN, {
    fetchPolicy: "cache-and-network",
  });

  return {
    userOrganizationName: data?.connectedUser?.organization?.name,
    userOrganizationSubscriptionPlan: data?.connectedUser?.organization?.subscription,
    loadingUserOrganizationNameAndSubscriptionPlan: loading,
    errorOnUserOrganizationNameAndSubscriptionPlan: error,
  };
};
