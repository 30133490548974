/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useValidateCollaborationOwningPartyNameRule } from "@taketurns-rules/collaboration/commands/collaborationCreation/form/mobile/useValidateCollaborationOwningPartyNameRule";

export const useValidateOwningPartyRule = () => {
  const validateOwningPartyName = useValidateCollaborationOwningPartyNameRule();
  return () => {
    const isOwningPartyNameValid = validateOwningPartyName();

    return { isValid: isOwningPartyNameValid };
  };
};
