/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useSetRecoilState } from "recoil";
import { folderByIdState } from "../../../recoil/collaborationCreation/folderByIdState";

export const useRenameFolderInCreation = (folderId: string) => {
  const setRenameFolderInCreation = useSetRecoilState(folderByIdState(folderId));
  return (newFolderName: string) => {
    setRenameFolderInCreation((folder) => ({ ...folder, name: newFolderName }));
  };
};
