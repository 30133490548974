/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Typography } from "@mui/material";

interface OtherPartyNameCellProps {
  otherPartyName: string;
}

export const OtherPartyNameCell = ({ otherPartyName }: OtherPartyNameCellProps) => (
  <Typography noWrap color="secondary" title={otherPartyName}>
    {otherPartyName}
  </Typography>
);
