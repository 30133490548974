/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { createBrowserRouter, Outlet } from "react-router-dom";
import { AuthProvider } from "./AuthProvider";
import { SetMarketingDataGuard } from "./guards/marketingData/SetMarketingDataGuard";
import { RedirectFromParamsGuard } from "./guards/RedirectFromParamsGuard";
import { SetComingFromInvitationLinkGuard } from "./guards/SetComingFromInvitationLinkGuard";
import { SetComingFromOrganizationInvitationLinkGuard } from "./guards/SetComingFromOrganizationInvitationLinkGuard";
import { authenticatedRoutes } from "./routes/authenticatedRoutes";
import { publicRoutes } from "./routes/publicRoutes";

export const router = createBrowserRouter([
  {
    element: (
      <AuthProvider>
        <SetMarketingDataGuard>
          <SetComingFromInvitationLinkGuard>
            <SetComingFromOrganizationInvitationLinkGuard>
              <RedirectFromParamsGuard>
                <Outlet />
              </RedirectFromParamsGuard>
            </SetComingFromOrganizationInvitationLinkGuard>
          </SetComingFromInvitationLinkGuard>
        </SetMarketingDataGuard>
      </AuthProvider>
    ),
    children: [publicRoutes, ...authenticatedRoutes],
  },
]);
