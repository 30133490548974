/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Button, Chip, CircularProgress, DialogActions, Divider, Stack, styled, Typography } from "@mui/material";
import { SubscriptionPlanTier } from "@taketurns/taketurns-graphql-repository";
import { useUserPlanTranslation } from "@taketurns-i18n/user/UserPlans/useUserPlanTranslation";
import { SubscriptionPlanForDisplay } from "@taketurns-repositories/user/local/entities/subscriptionPlanForDisplay";
import { wait } from "@taketurns-rules/commons/util/wait";
import { useSwitchPlanRule } from "@taketurns-rules/user/commands/plan/useSwitchPlanRule";
import { useNavigateToUserOrganizationPageRule } from "@taketurns-rules/user/commands/useNavigateToUserOrganizationPageRule";
import { useNavigateToUserPlanPageRule } from "@taketurns-rules/user/commands/useNavigateToUserPlanPageRule";
import { useGetSubscriptionPlanPeriodRule } from "@taketurns-rules/user/queries/plan/getSubscriptionPlanPeriodRule";
import { useFetchIsMultiUserOrganizationAllowedToSwitchPlanRule } from "@taketurns-rules/user/queries/plan/useFetchIsMultiUserOrganizationAllowedToSwitchPlanRule";
import { useFetchPlanSwitchingInvoiceRule } from "@taketurns-rules/user/queries/plan/useFetchPlanSwitchingInvoiceRule";
import { TakeTurnsDefaultButton, TakeTurnsTextButton } from "../../../commons/button";
import { ResponsiveDialog } from "../../../commons/dialog/ResponsiveDialog";
import { UpgradePayment } from "../../shared/UpgradePayment";
import { UpcomingInvoice } from "./UpcomingInvoice";

interface UpgradePlanDialogProps {
  subscriptionPlan: SubscriptionPlanForDisplay;
  isDialogOpened: boolean;
  closeDialog: () => void;
}

export const UpgradePlanDialog = ({ subscriptionPlan, isDialogOpened, closeDialog }: UpgradePlanDialogProps) => {
  const { t } = useUserPlanTranslation();
  const period = useGetSubscriptionPlanPeriodRule();
  const nextPlanTier = subscriptionPlan.plan.planTier;
  const { planSwitchingInvoice, errorOnUpcomingInvoicePriorToSwitchPlan, loadingUpcomingInvoicePriorToSwitchPlan } =
    useFetchPlanSwitchingInvoiceRule(nextPlanTier, period, !isDialogOpened);
  const { switchPlan, loadingSwitchPlan } = useSwitchPlanRule();
  const { isAllowedToSwitchPlan, loadingIsMultiUserOrganizationAllowedToSwitchPlan } =
    useFetchIsMultiUserOrganizationAllowedToSwitchPlanRule(nextPlanTier);
  const redirectToUserPlanOrOrganizationPage = useRedirectToUserPlanOrOrganizationPage(nextPlanTier);

  const confirmSwitchPlanAndRedirectToOrganizationOrPlanPage = () => {
    switchPlan({
      subscriptionPeriod: period,
      subscriptionPlanTier: nextPlanTier,
    }).then(() => {
      closeDialog();
      wait(2000).then(() => redirectToUserPlanOrOrganizationPage());
    });
  };

  const disableOnLoadingUpcomingInvoiceOrSwitchingPlan =
    loadingUpcomingInvoicePriorToSwitchPlan || loadingSwitchPlan || loadingIsMultiUserOrganizationAllowedToSwitchPlan;
  return (
    <ResponsiveDialog
      maxWidth={"md"}
      fullWidth
      PaperProps={{ sx: { minHeight: 600 } }}
      open={isDialogOpened}
      title={t("upgradePlanDialog.title")}
      content={
        <Stack direction="column">
          <Stack spacing={2} flex={1}>
            <Typography>
              {t("upgradePlanDialog.changingTo")}{" "}
              <PlanName__Chip bgColor={subscriptionPlan.color} label={t(`plans.${nextPlanTier}`)} />
            </Typography>
            <Divider />
            <UpcomingInvoice
              planSwitchingInvoice={planSwitchingInvoice}
              loading={loadingUpcomingInvoicePriorToSwitchPlan}
              error={errorOnUpcomingInvoicePriorToSwitchPlan}
            />
            <UpgradePayment
              paymentCardLastFourDigits={planSwitchingInvoice?.paymentCardLastFourDigits}
              loading={loadingUpcomingInvoicePriorToSwitchPlan}
              error={!!errorOnUpcomingInvoicePriorToSwitchPlan}
            />
            {!isAllowedToSwitchPlan && (
              <Typography color={"error"}>
                {t("upgradePlanDialog.notAllowedToSwitchPlanDueToMultiUserOrganization")}
              </Typography>
            )}
          </Stack>
          <Typography variant={"caption"}>
            {t("upgradePlanDialog.confirmTermsAndServices")}
            {", "}
            <TakeTurnsTextButton>{t("upgradePlanDialog.termsAndServices")}</TakeTurnsTextButton>
          </Typography>
        </Stack>
      }
      footer={
        <DialogActions>
          <Button onClick={closeDialog} variant={"text"}>
            {t("upgradePlanDialog.buttons.cancel")}
          </Button>
          <TakeTurnsDefaultButton
            endIcon={loadingSwitchPlan && <CircularProgress color={"secondary"} size={12} />}
            onClick={confirmSwitchPlanAndRedirectToOrganizationOrPlanPage}
            disabled={disableOnLoadingUpcomingInvoiceOrSwitchingPlan || !isAllowedToSwitchPlan}
          >
            {t("upgradePlanDialog.buttons.confirm")}
          </TakeTurnsDefaultButton>
        </DialogActions>
      }
    />
  );
};

const useRedirectToUserPlanOrOrganizationPage = (nextPlanTier: SubscriptionPlanTier) => {
  const redirectToUserOrganizationPageRule = useNavigateToUserOrganizationPageRule();
  const redirectToUserPlanPageRule = useNavigateToUserPlanPageRule();
  const userPlanWithoutMultiUserFeature = [SubscriptionPlanTier.T0, SubscriptionPlanTier.T1];

  return () => {
    if (userPlanWithoutMultiUserFeature.includes(nextPlanTier)) {
      return redirectToUserPlanPageRule();
    } else {
      return redirectToUserOrganizationPageRule();
    }
  };
};

const PlanName__Chip = styled(Chip, { shouldForwardProp: (props) => props !== "bgColor" })(
  ({ bgColor }: { bgColor: string }) => ({
    marginLeft: 12,
    backgroundColor: bgColor,
    color: "white",
  }),
);
