/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { gql, useQuery } from "@apollo/client";
import {
  PlanSwitchingInvoice,
  SubscriptionPlanPeriod,
  SubscriptionPlanTier,
} from "@taketurns/taketurns-graphql-repository";

export const useFetchPlanSwitchingInvoice = (
  subscriptionPlanTier: SubscriptionPlanTier,
  subscriptionPlanPeriod: SubscriptionPlanPeriod,
  skipQuery?: boolean,
) => {
  const UPCOMING_INVOICE_PRIOR_TO_SWITCH_PLAN = gql`
    query planSwitchingInvoice(
      $subscriptionPlanTier: SubscriptionPlanTier!
      $subscriptionPlanPeriod: SubscriptionPlanPeriod!
    ) {
      planSwitchingInvoice(
        subscriptionPlanTier: $subscriptionPlanTier
        subscriptionPlanPeriod: $subscriptionPlanPeriod
      ) {
        nextPeriodStartingDate
        amountDueToday
        currency
        unitPrice
        paymentCardLastFourDigits
      }
    }
  `;
  const {
    data,
    loading: loadingUpcomingInvoicePriorToSwitchPlan,
    error: errorOnUpcomingInvoicePriorToSwitchPlan,
  } = useQuery<{
    planSwitchingInvoice: PlanSwitchingInvoice;
  }>(UPCOMING_INVOICE_PRIOR_TO_SWITCH_PLAN, {
    variables: { subscriptionPlanTier, subscriptionPlanPeriod },
    skip: skipQuery,
  });

  return {
    planSwitchingInvoice: data?.planSwitchingInvoice,
    loadingUpcomingInvoicePriorToSwitchPlan,
    errorOnUpcomingInvoicePriorToSwitchPlan,
  };
};
