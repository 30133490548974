/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useApolloClient } from "@apollo/client";
import { User } from "@taketurns/taketurns-graphql-repository";

export const useGetUsersInCache = (userIds: string[]) => {
  const { cache } = useApolloClient();

  const USER_FRAGMENT = gql`
    fragment UserFragmentFromCache on User {
      id
      firstName
      lastName
      email
      avatar
      jobTitle
    }
  `;

  return userIds.map((userId) =>
    cache.readFragment<User>({
      id: cache.identify({ __typename: "User", id: userId }),
      fragment: USER_FRAGMENT,
    }),
  );
};
