/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { CircularProgress, Stack, TextField, Typography } from "@mui/material";
import { FormEvent, useState } from "react";
import { useCollaborationCreationTranslation } from "@taketurns-i18n/collaboration/CollaborationCreation/useCollaborationCreationTranslation";
import { useSetOrganizationNameAsDefaultPartyNameRule } from "@taketurns-rules/collaboration/commands/collaborationCreation/useSetOrganizationNameAsDefaultPartyNameRule";
import { useNavigateToCollaborationListRule } from "@taketurns-rules/collaboration/commands/navigation/useNavigateToCollaborationListRule";
import { useFetchHasDefinedDefaultPartyNameRule } from "@taketurns-rules/collaboration/queries/collaborationCreation/useFetchHasDefinedDefaultPartyNameRule";
import { TakeTurnsDefaultButton } from "../../../commons/button";
import { DialogFooter } from "../../../commons/dialog/DialogFooter";
import { ResponsiveDialog } from "../../../commons/dialog/ResponsiveDialog";

export const RequiredDefaultPartyNameDialog = () => {
  const { hasDefinedDefaultPartyName, loadingHasDefinedDefaultPartyName } = useFetchHasDefinedDefaultPartyNameRule();
  const redirectToCollaborationListRule = useNavigateToCollaborationListRule();

  const {
    modifyConnectedUserOrganizationName: setOrganizationNameAsDefaultPartyName,
    loadingModifyConnectedUserOrganizationName: loadingSetModifyOrganizationNameAsDefaultPartyName,
  } = useSetOrganizationNameAsDefaultPartyNameRule();

  const { t } = useCollaborationCreationTranslation();

  const [defaultPartyName, setDefaultPartyName] = useState("");

  if (loadingHasDefinedDefaultPartyName) {
    return;
  }

  const isDefaultPartyNameEmpty = defaultPartyName.trim().length === 0;
  const isContinueButtonDisabled = () => {
    return isDefaultPartyNameEmpty || loadingSetModifyOrganizationNameAsDefaultPartyName;
  };

  const setOrganizationNameAsDefaultPartyNameOnSubmitForm = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!isDefaultPartyNameEmpty) {
      setOrganizationNameAsDefaultPartyName(defaultPartyName);
    }
  };

  return (
    <ResponsiveDialog
      fullWidth
      open={!hasDefinedDefaultPartyName}
      title={t("RequiredDefaultPartyNameDialog.title")}
      content={
        <Stack spacing={2}>
          <Typography>{t("RequiredDefaultPartyNameDialog.description")}</Typography>
          <form onSubmit={setOrganizationNameAsDefaultPartyNameOnSubmitForm}>
            <TextField
              autoFocus
              fullWidth
              placeholder={t("RequiredDefaultPartyNameDialog.placeholder")}
              onChange={(e) => setDefaultPartyName(e.target.value)}
            />
          </form>
        </Stack>
      }
      footer={
        <DialogFooter onClose={redirectToCollaborationListRule}>
          <TakeTurnsDefaultButton
            startIcon={
              loadingSetModifyOrganizationNameAsDefaultPartyName && <CircularProgress size={12} color={"secondary"} />
            }
            disabled={isContinueButtonDisabled()}
            type="submit"
            onClick={() => setOrganizationNameAsDefaultPartyName(defaultPartyName)}
          >
            {t("RequiredDefaultPartyNameDialog.buttons.continue")}
          </TakeTurnsDefaultButton>
        </DialogFooter>
      }
    />
  );
};
