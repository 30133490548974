/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { SubscriptionPlanTier } from "@taketurns/taketurns-graphql-repository";
import { useFetchUserOrganizationSubscriptionTier } from "@taketurns-repositories/user/graphql/queries/useFetchUserOrganizationSubscriptionTier";

export const useFetchIsUserOrganizationPlanFreePlanRule = () => {
  const {
    errorOnFetchingUserOrganizationSubscriptionTier,
    userOrganizationSubscriptionTier,
    loadingUserOrganizationSubscriptionTier,
  } = useFetchUserOrganizationSubscriptionTier();
  return {
    isUserOrganizationPlanFreePlan: userOrganizationSubscriptionTier === SubscriptionPlanTier.T0,
    errorOnFetchingIsUserOrganizationPlanFreePlan: errorOnFetchingUserOrganizationSubscriptionTier,
    loadingIsUserOrganizationPlanFreePlan: loadingUserOrganizationSubscriptionTier,
  };
};
