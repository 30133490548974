/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Upload } from "@taketurns-repositories/document/state/models/upload";
import { useCancelPendingOrInProgressUpload } from "@taketurns-repositories/document/state/write/useCancelPendingOrInProgressUpload";

export const useCancelDocumentUploadRule = () => {
  const cancelPendingOrInProgressUpload = useCancelPendingOrInProgressUpload();

  return async (upload: Upload) => {
    if (upload.isPending() || upload.isInProgress()) {
      await upload.cancelUploadFunction!();
      cancelPendingOrInProgressUpload(upload.uploadId);
    }
  };
};
