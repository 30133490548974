/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useQuery } from "@apollo/client";
import { ConnectedUser } from "@taketurns/taketurns-graphql-repository";

export const useFetchHasMultiUserAndOrganizationAdminFeatures = (skip?: boolean) => {
  const USER_FEATURE_MULTI_USER_AND_ORGANIZATION_ADMIN = gql`
    query userHasMultiUserOrganizationFeature {
      connectedUser {
        id
        availableFeatures {
          multiUserOrganization
          organizationAdmin
        }
      }
    }
  `;
  const {
    data,
    error: errorOnFetchingMultiUserAndOrganizationAdminFeatures,
    loading: loadingMultiUserAndOrganizationAdminFeatures,
  } = useQuery<{ connectedUser: ConnectedUser }>(USER_FEATURE_MULTI_USER_AND_ORGANIZATION_ADMIN, { skip });
  return {
    organizationAdmin: data?.connectedUser?.availableFeatures?.organizationAdmin,
    multiUserOrganization: data?.connectedUser?.availableFeatures?.multiUserOrganization,
    errorOnFetchingMultiUserAndOrganizationAdminFeatures,
    loadingMultiUserAndOrganizationAdminFeatures,
  };
};
