/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useMutation } from "@apollo/client";
import { SubscribeToPlanWithTrialInput, SubscriptionPlan } from "@taketurns/taketurns-graphql-repository";
import { delayRefetchQueryDueToLatencyBetweenOpenSearchAndEvent } from "@taketurns-repositories/user/graphql/cache/delayRefetchQueryDueToLatencyBetweenOpenSearchAndEvent";
import { USER_SUBSCRIPTION_PLAN_TRIAL } from "../queries/useFetchUserSubscriptionPlanTrial";

export const useSubscribeToPlanWithTrial = () => {
  const SUBSCRIBE_TO_PLAN_WITH_TRIAL = gql`
    mutation subscribeToPlanWithTrial($subscribeToPlanWithTrialInput: SubscribeToPlanWithTrialInput!) {
      subscribeToPlanWithTrial(subscribeToPlanWithTrialInput: $subscribeToPlanWithTrialInput)
    }
  `;
  const [
    subscribeToPlanWithTrialMutation,
    { error: errorOnSubscribeToPlanWithTrial, loading: loadingSubscribeToPlanWithTrial },
  ] = useMutation<{
    subscribeToPlanWithTrial: SubscriptionPlan;
  }>(SUBSCRIBE_TO_PLAN_WITH_TRIAL);

  const subscribeToPlanWithTrialCommand = (subscribeToPlanWithTrialInput: SubscribeToPlanWithTrialInput) => {
    return subscribeToPlanWithTrialMutation({
      variables: { subscribeToPlanWithTrialInput },
      refetchQueries: [USER_SUBSCRIPTION_PLAN_TRIAL],
      onQueryUpdated: delayRefetchQueryDueToLatencyBetweenOpenSearchAndEvent,
    });
  };

  return { subscribeToPlanWithTrialCommand, errorOnSubscribeToPlanWithTrial, loadingSubscribeToPlanWithTrial };
};
