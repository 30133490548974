/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Divider, Stack, Typography } from "@mui/material";
import { ReactNode, useEffect, useRef, useState } from "react";
import { CollaborationDialog } from "@taketurns-components/collaboration/Shared/dialog/CollaborationDialog";
import { DefaultDialogTitle } from "@taketurns-components/collaboration/Shared/dialog/DefaultDialogTitle";
import { DialogContentWithRichTextField } from "@taketurns-components/collaboration/Shared/dialog/DialogContentWithRichTextField";
import { useSharedCollaborationTranslation } from "@taketurns-i18n/collaboration/shared/useSharedCollaborationTranslation";
import { TipKey } from "@taketurns-i18n/commons/en/tips";
import { HelpCenterSections } from "@taketurns-rules/commons/enum/helpCenterSectionsEnum";
import { DefaultDialogFooter } from "./DefaultDialogFooter";

interface DefaultActionWithNoteDialogProps {
  title: string;
  openDialog: boolean;
  onClose: (message?: string) => void;
  actionButtonLabel: string;
  actionButtonIcon?: ReactNode;
  executeAction: (message: string) => void;
  actionLoading?: boolean;
  otherPartyName?: string;
  placeholder: string;
  help?: HelpCenterSections;
  tipKey?: TipKey;
  defaultNoteValue?: string;
}

export const DefaultActionWithNoteDialog = (props: DefaultActionWithNoteDialogProps) => {
  const { t } = useSharedCollaborationTranslation();

  const noteRef = useRef<string>(props.defaultNoteValue);

  const [isActionButtonDisabled, setActionButtonDisabledStatus] = useState(false);

  useEffect(() => {
    if (!props.actionLoading && isActionButtonDisabled) {
      setActionButtonDisabledStatus(false);
    }
  }, [props.actionLoading, isActionButtonDisabled]);

  const disableButtonAndExecuteAction = () => {
    setActionButtonDisabledStatus(true);
    props.executeAction(noteRef.current);
  };

  const enableActionButton = !props.actionLoading && !isActionButtonDisabled;

  return (
    <CollaborationDialog open={props.openDialog} onClose={() => props.onClose(noteRef.current)}>
      <DefaultDialogTitle title={props.title} tipKey={props.tipKey} />
      <Divider />
      <DialogContentWithRichTextField
        onChange={(note) => {
          noteRef.current = note;
        }}
        placeholder={props.placeholder}
        value={noteRef.current}
      >
        <Stack direction="row" spacing={1} alignItems={"center"}>
          <Typography variant="body1">{t("DefaultActionWithNoteDialog.addNoteMessage")}</Typography>
        </Stack>
      </DialogContentWithRichTextField>
      <DefaultDialogFooter
        data-cy="ConfirmDialogActionButton"
        actionButtonLabel={props.actionButtonLabel}
        actionButtonEnabled={enableActionButton}
        executeAction={disableButtonAndExecuteAction}
        closeDialog={() => props.onClose(noteRef.current)}
        actionButtonIcon={props.actionButtonIcon}
        actionExecutionLoading={props.actionLoading}
        helpCenterSection={props.help}
        hasCancelAction
      />
    </CollaborationDialog>
  );
};
