/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Typography } from "@mui/material";
import { useCollaborationViewTranslation } from "@taketurns-i18n/collaboration/CollaborationView/useCollaborationViewTranslation";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";

export const RequestsCounter = (props: { requestsCount: number }) => {
  const { t } = useCollaborationViewTranslation();

  return (
    <Typography color={TakeTurnsColors.darkGray} fontSize={{ xs: 10, md: 9 }} fontWeight={700}>
      {t("collaborationView.mainPanel.content.cardView.folderCard.request", { count: props.requestsCount })}
    </Typography>
  );
};
