/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useLazyQuery } from "@apollo/client";
import { useCallback, useEffect, useRef } from "react";
import { ContentDownloadStatus, ContentDownloadStatusResponse } from "@taketurns/taketurns-graphql-repository";

const GET_CONTENT_DOWNLOAD_STATUS = gql`
  query contentDownloadStatus($downloadId: ID!) {
    contentDownloadStatus(downloadId: $downloadId) {
      downloadId
      status
      downloadUrl
    }
  }
`;

export const useContentDownloadStatus = () => {
  const [query, result] = useLazyQuery<{ contentDownloadStatus: ContentDownloadStatusResponse }>(
    GET_CONTENT_DOWNLOAD_STATUS,
  );
  const retryCountRef = useRef<number>(0);
  const MAX_RETRY_COUNT = 300;

  useEffect(() => {
    if (result.error) {
      console.error(result.error);
      result.stopPolling();
      retryCountRef.current = 0;
    }

    if (result.data?.contentDownloadStatus.status === ContentDownloadStatus.Ready) {
      result.stopPolling();
      retryCountRef.current = 0;
    }

    retryCountRef.current++;
    if (retryCountRef.current > MAX_RETRY_COUNT) {
      result.stopPolling();
      retryCountRef.current = 0;
    }
  }, [result]);

  const getContentDownloadStatus = useCallback(
    (downloadId: string) => {
      query({ variables: { downloadId }, pollInterval: 2000 });
    },
    [query],
  );

  return {
    getContentDownloadStatus,
    contentDownloadUrl: result.data?.contentDownloadStatus.downloadUrl,
    contentDownloadStatusError: result.error,
  };
};
