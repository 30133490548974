/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Box, Stack, styled, Typography } from "@mui/material";
import { Fragment } from "react";
import { Avatar } from "stream-chat-react";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { useGetUserColorRule } from "@taketurns-rules/user/queries/useGetUserColorRule";

interface ColoredUserFullNameWithAvatarProps {
  userId: string;
  userPresentation: string;
  userAvatar: string;
  suffix?: string;
  fontSize: string;
  fontWeight: string;
}

export const ColoredUserFullNameWithAvatar = (props: ColoredUserFullNameWithAvatarProps) => {
  const getUserColor = useGetUserColorRule();

  return (
    <ColoredUserFullNameWithAvatar__Container>
      <Avatar size={30} image={props.userAvatar} name={props.userPresentation} />
      <Stack height={"30px"} direction="row" alignItems="center">
        <Box height={"100%"}>
          <Typography
            component="span"
            color={getUserColor(props.userId)}
            fontSize={props.fontSize}
            fontWeight={props.fontWeight}
          >
            {props.userPresentation}
          </Typography>
          {props.suffix && (
            <Fragment>
              {" "}
              <Typography component="span" color={TakeTurnsColors.mediumGray} fontSize={props.fontSize}>
                {`${props.suffix}`}
              </Typography>
            </Fragment>
          )}
        </Box>
      </Stack>
    </ColoredUserFullNameWithAvatar__Container>
  );
};

const ColoredUserFullNameWithAvatar__Container = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  marginBottom: 10,
});
