/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useCollaborationListTranslation } from "@taketurns-i18n/collaboration/CollaborationList/useCollaborationListTranslation";
import { useFetchUserHasCreateCollaborationFeatureRule } from "@taketurns-rules/collaboration/queries/useFetchUserHasCreateCollaborationFeatureRule";
import { useGetListAppliedFilterRule } from "@taketurns-rules/collaboration/queries/useGetListAppliedFilterRule";
import { UpgradePlanToCreateCollaborationText } from "../Shared/UpgradePlanToCreateCollaborationText";
import { WithOpenUpgradePlanDialogOnClick } from "../Shared/WithOpenUpgradePlanDialogOnClick";
import { CollaborationFilterType } from "./collaborationFilterType";
import { NoCollaborationRowsLabelAndOptionalAction } from "./NoCollaborationRowsLabelAndOptionalAction";

interface NoCollaborationRowsOverlayProps {
  createNewCollaborationAction: () => void;
  displayActiveCollaborationAction: () => void;
}

export const NoCollaborationRowsOverlay = (props: NoCollaborationRowsOverlayProps) => {
  const { createNewCollaborationAction, displayActiveCollaborationAction } = props;

  const collaborationListAppliedFilter = useGetListAppliedFilterRule();
  const { hasCreateCollaborationFeature } = useFetchUserHasCreateCollaborationFeatureRule();
  const { t } = useCollaborationListTranslation();

  const NoRowOverlayForActiveCollaboration = WithOpenUpgradePlanDialogOnClick(
    NoCollaborationRowsLabelAndOptionalAction,
    {
      UpgradeToPlanDialogContentSlot: <UpgradePlanToCreateCollaborationText />,
      isFeatureAvailable: hasCreateCollaborationFeature,
    },
  );

  switch (collaborationListAppliedFilter) {
    case CollaborationFilterType.activeCollaborations:
      return (
        <NoRowOverlayForActiveCollaboration
          noCollaborationRowsLabel={t("noRows")}
          buttonLabel={t("createCollaboration")}
          onClick={createNewCollaborationAction}
        />
      );
    case CollaborationFilterType.pendingInvitations:
      return (
        <NoCollaborationRowsLabelAndOptionalAction
          noCollaborationRowsLabel={t("noPendingInvitations")}
          buttonLabel={t("viewActiveCollaborations")}
          onClick={displayActiveCollaborationAction}
        />
      );
    default:
      return <NoCollaborationRowsLabelAndOptionalAction noCollaborationRowsLabel={t("noCollaboration")} />;
  }
};
