/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Divider, List, Stack, TextField, Typography } from "@mui/material";
import { FormEvent, FunctionComponent, KeyboardEvent, useCallback, useRef, useState } from "react";
import { TakeTurnsListItem } from "@taketurns-components/collaboration/Shared/list/TakeTurnsListItem";
import { TakeTurnsListItemSkeleton } from "@taketurns-components/collaboration/Shared/list/TakeTurnsListItemSkeleton";
import { TakeTurnsDefaultButton } from "@taketurns-components/commons/button";
import { IconFolder } from "@taketurns-components/commons/icons";
import { SomethingWentWrong } from "@taketurns-components/shared/SomethingWentWrong";
import { useSharedCollaborationTranslation } from "@taketurns-i18n/collaboration/shared/useSharedCollaborationTranslation";
import { DestinationFolder } from "@taketurns-rules/collaboration/commands/destinationFolder";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { CurrentFolderListItemWithBackButton } from "./CurrentFolderListItemWithBackButton";

export interface DestinationFolderListProps {
  rootFolderId: string;
  onSelectedFolderChange: (destinationFolderId: string) => void;
  excludedFolderId?: string;
  useGetDestinationFolder: (destinationFolderId: string) => {
    destinationFolder: DestinationFolder;
    loadingDestinationFolder: boolean;
    errorWhileFetchingDestinationFolder: boolean;
  };
  isAddFolderInputVisible: boolean;
  cancelAddFolder: () => void;
  addFolderInSelectedFolder:
    | ((folderName: string, parentId: string) => Promise<string>)
    | ((folderName: string, parentId: string) => string);
  FolderListItem: FunctionComponent<{ destinationFolder: DestinationFolder; onFolderClick: () => void }>;
}

export const DestinationFolderList = (props: DestinationFolderListProps) => {
  const folderIdsPathRef = useRef<string[]>([]);
  const [selectedDestinationFolderId, setSelectedDestinationFolderId] = useState(props.rootFolderId);
  const { destinationFolder, loadingDestinationFolder, errorWhileFetchingDestinationFolder } =
    props.useGetDestinationFolder(selectedDestinationFolderId);

  const isSelectedFolderRootFolder = selectedDestinationFolderId === props.rootFolderId;

  const { onSelectedFolderChange } = props;

  const changeFolder = useCallback(
    (folderId: string) => {
      setSelectedDestinationFolderId(folderId);
      onSelectedFolderChange(folderId);
    },
    [onSelectedFolderChange],
  );

  const onBackClicked = () => {
    const parentFolderId = folderIdsPathRef.current[folderIdsPathRef.current.length - 1];
    folderIdsPathRef.current = folderIdsPathRef.current.slice(0, folderIdsPathRef.current.length - 1);
    changeFolder(parentFolderId);
  };

  const onDestinationFolderListItemClicked = (folderId: string) => {
    folderIdsPathRef.current = [...folderIdsPathRef.current, selectedDestinationFolderId];
    changeFolder(folderId);
  };

  const addFolderInSelectedFolder = (folderName: string) => {
    const newFolderIdOrNewFolderIdPromise = props.addFolderInSelectedFolder(folderName, selectedDestinationFolderId);
    if (newFolderIdOrNewFolderIdPromise instanceof Promise) {
      newFolderIdOrNewFolderIdPromise.then((newFolderId) => {
        onDestinationFolderListItemClicked(newFolderId);
      });
    } else {
      onDestinationFolderListItemClicked(newFolderIdOrNewFolderIdPromise);
    }
  };

  if (loadingDestinationFolder && !destinationFolder) {
    return <TakeTurnsListItemSkeleton />;
  }

  if (errorWhileFetchingDestinationFolder || !destinationFolder) {
    return <SomethingWentWrong />;
  }

  const listedFolders = destinationFolder.folders.filter((folder) => folder.id !== props.excludedFolderId);
  const noListedFolders = !listedFolders.length && !props.isAddFolderInputVisible;

  return (
    <Stack>
      <List disablePadding dense sx={{ flex: 1, padding: 3, overflow: "auto" }} data-cy="DestinationFolderList">
        {isSelectedFolderRootFolder && !noListedFolders && <Divider />}
        {!isSelectedFolderRootFolder && (
          <CurrentFolderListItemWithBackButton folderName={destinationFolder.name} onFolderClick={onBackClicked} />
        )}
        {props.isAddFolderInputVisible && (
          <AddFolderInSelectedFolder
            existingFolders={destinationFolder.folders}
            cancelAddFolder={props.cancelAddFolder}
            addFolderInSelectedFolder={addFolderInSelectedFolder}
          />
        )}
        {listedFolders.map((folder) => (
          <props.FolderListItem
            key={folder.id}
            destinationFolder={folder}
            onFolderClick={() => onDestinationFolderListItemClicked(folder.id)}
          />
        ))}
        {noListedFolders && <NoListedFolderNotice isRootFolder={isSelectedFolderRootFolder} />}
      </List>
    </Stack>
  );
};

interface AddFolderInSelectedFolderProps {
  existingFolders: DestinationFolder[];
  cancelAddFolder: () => void;
  addFolderInSelectedFolder: (name: string) => void;
}

const AddFolderInSelectedFolder = (props: AddFolderInSelectedFolderProps) => {
  const [folderName, setFolderName] = useState("");
  const { t } = useSharedCollaborationTranslation();

  const addFolder = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    props.addFolderInSelectedFolder(folderName.trim());
    setFolderName("");
  };
  const onKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Escape") {
      props.cancelAddFolder();
    }
  };

  const getFolderName = (folder: DestinationFolder) => {
    if (folder.id.includes("/")) {
      return folder.id.split("/").at(-1);
    }
    return folder.name;
  };

  const isButtonDisabled =
    !folderName.trim() || props.existingFolders.some((folder) => getFolderName(folder) === folderName.trim());

  return (
    <TakeTurnsListItem
      titleSlot={
        <Stack component="form" onSubmit={addFolder} direction="row" spacing={1} alignItems="center">
          <IconFolder size={16} color={TakeTurnsColors.grayButton} />
          <TextField
            autoFocus
            placeholder={t("MoveDestinationDialog.newFolder")}
            sx={{ minWidth: { md: "250px" } }}
            value={folderName}
            onChange={(event) => setFolderName(event.target.value.trimStart())}
            onKeyDown={onKeyDown}
            data-cy="DestinationFolderList.AddFolderInput"
          />
          <TakeTurnsDefaultButton
            type="submit"
            disabled={isButtonDisabled}
            data-cy="DestinationFolderList.AddFolderButton"
          >
            {t("MoveDestinationDialog.addFolderButton")}
          </TakeTurnsDefaultButton>
        </Stack>
      }
    />
  );
};

const NoListedFolderNotice = (props: { isRootFolder: boolean }) => {
  const { t } = useSharedCollaborationTranslation();
  return (
    <Typography mt={props.isRootFolder ? 0 : 2} color={TakeTurnsColors.darkGray}>
      {props.isRootFolder
        ? t("MoveDestinationDialog.noListedFolderInRootFolder")
        : t("MoveDestinationDialog.noListedFolder")}
    </Typography>
  );
};
