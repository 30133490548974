/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import i18n from "@taketurns-i18n/i18n";
import { getEnvironmentVariable } from "../../../getEnvironmentVariable";

export const useGetContactSalesDialogUrlRule = () => {
  const contactSalesDialogURL = getEnvironmentVariable("CONTACT_SALES_DIALOG_URL");
  return contactSalesDialogURL.replace("{{language}}", i18n.language);
};
