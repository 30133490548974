/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Skeleton, styled, Typography } from "@mui/material";
import { useEffect } from "react";
import { useUserPlanTranslation } from "@taketurns-i18n/user/UserPlans/useUserPlanTranslation";
import { useSelectSubscriptionPlanRegionRule } from "@taketurns-rules/user/commands/plan/selectSubscriptionPlanRegionRule";
import { useFetchIsUserOrganizationPlanFreePlanRule } from "@taketurns-rules/user/queries/plan/useFetchIsUserOrganizationPlanFreePlanRule";
import { useFetchUserOrganizationSubscriptionRegionRule } from "@taketurns-rules/user/queries/useFetchUserOrganizationSubscriptionRegionRule";
import { PaidPlanSubscriptionPlansHeader } from "./PaidPlanSubscriptionPlansHeader/PaidPlanSubscriptionPlansHeader";
import { PublicAndFreePlanSubscriptionPlansHeader } from "./PublicAndFreeSubscriptionPlansHeader/PublicAndFreePlanSubscriptionPlansHeader";

export const AuthenticatedSubscriptionPlansHeader = () => {
  const {
    isUserOrganizationPlanFreePlan,
    loadingIsUserOrganizationPlanFreePlan,
    errorOnFetchingIsUserOrganizationPlanFreePlan,
  } = useFetchIsUserOrganizationPlanFreePlanRule();
  const {
    loadingUserOrganizationSubscriptionRegion,
    userOrganizationSubscriptionRegion,
    errorOnFetchingUserOrganizationSubscriptionRegion,
  } = useFetchUserOrganizationSubscriptionRegionRule(!isUserOrganizationPlanFreePlan);
  const { t } = useUserPlanTranslation();
  const selectRegion = useSelectSubscriptionPlanRegionRule();

  useEffect(() => {
    if (userOrganizationSubscriptionRegion) {
      selectRegion(userOrganizationSubscriptionRegion);
    }
  }, [userOrganizationSubscriptionRegion, selectRegion]);

  if (loadingIsUserOrganizationPlanFreePlan || loadingUserOrganizationSubscriptionRegion) {
    return <AuthenticatedSubscriptionPlanHeaderLoadingIndicator />;
  }

  if (errorOnFetchingIsUserOrganizationPlanFreePlan || errorOnFetchingUserOrganizationSubscriptionRegion) {
    return <Typography color={"error"}>{t("header.error")}</Typography>;
  }

  if (isUserOrganizationPlanFreePlan) {
    return <PublicAndFreePlanSubscriptionPlansHeader />;
  }
  return <PaidPlanSubscriptionPlansHeader />;
};
const AuthenticatedSubscriptionPlanHeaderLoadingIndicator = styled(Skeleton)({
  height: 50,
  width: "100%",
});
