/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import {
  ContentFooterMenu__ButtonsContainer,
  ContentFooterMenu__Container,
} from "@taketurns-components/collaboration/CollaborationView/CollaborationContent/ContentBrowser/ContentFooterMenu/shared";
import { DisableContentSelectionButton } from "@taketurns-components/collaboration/CollaborationView/CollaborationContent/ContentBrowser/ContentSelectionButtons/DisableContentSelectionButton";
import { DownloadSelectionButton } from "@taketurns-components/collaboration/CollaborationView/CollaborationContent/ContentBrowser/ContentSelectionButtons/DownloadSelectionButton";
import { SelectAllButton } from "@taketurns-components/collaboration/CollaborationView/CollaborationContent/ContentBrowser/ContentSelectionButtons/SelectAllButton";
import { useIsOnMobileRule } from "@taketurns-rules/commons/theme/useIsOnMobileRule";

export const ContentSelectionContentFooterMenu = () => {
  const isOnMobile = useIsOnMobileRule();

  return (
    <ContentFooterMenu__Container>
      <ContentFooterMenu__ButtonsContainer>
        <DownloadSelectionButton />
        {isOnMobile && <SelectAllButton />}
      </ContentFooterMenu__ButtonsContainer>
      <ContentFooterMenu__ButtonsContainer>
        {!isOnMobile && <SelectAllButton />}
        <DisableContentSelectionButton />
      </ContentFooterMenu__ButtonsContainer>
    </ContentFooterMenu__Container>
  );
};
