/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useEffect } from "react";
import { useWebAppTranslations } from "@taketurns-i18n/webapp/useWebAppTranslations";

export const useSetMetaDescriptionRule = () => {
  const { t } = useWebAppTranslations("title-meta");
  useEffect(() => {
    const metaTags = document.getElementsByTagName("meta");
    const metaDescription = metaTags.namedItem("description");
    if (metaDescription) {
      metaDescription.content = t("meta.description");
    }
  }, [t]);
};
