/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useRecoilCallback } from "recoil";
import { UploadStatus } from "../models/uploadStatus";
import { cancelledUploadIdsState } from "../recoil/cancelledUploadIdsState";
import { uploadsByUploadIdState } from "../recoil/uploadsByUploadIdState";

export const useCancelPendingOrInProgressUpload = () => {
  return useRecoilCallback(
    ({ set }) =>
      (uploadId: number) => {
        set(uploadsByUploadIdState(uploadId), (upload) => {
          if (upload) {
            const updatedUpload = upload.copy();
            if (upload.isPending() || upload.isInProgress()) {
              updatedUpload.status = UploadStatus.cancelled;
              return updatedUpload;
            }
            return updatedUpload;
          } else {
            throw new Error(`Cannot cancel upload with id : ${uploadId} `);
          }
        });
        set(
          cancelledUploadIdsState,
          (currentCancelledUploadsIds) => new Set([...currentCancelledUploadsIds, uploadId]),
        );
      },
    [],
  );
};
