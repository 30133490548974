/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Avatar, IconButton, ListItem, ListItemAvatar, ListItemText, Skeleton, styled, Typography } from "@mui/material";
import { CollaborationStatus, Invitee, Party, Role } from "@taketurns/taketurns-graphql-repository";
import { CollaborationCommand, useCollaborationPermissions } from "@taketurns-rules/collaboration/permissions/useCollaborationPermissions";
import { useFetchCollaborationStatusRule } from "@taketurns-rules/collaboration/queries/fields/useFetchCollaborationStatusRule";
import { getDefaultColorForParty } from "@taketurns-rules/collaboration/utils/color/TakeTurnsBlueColorsUtils";
import { getUserNamesOrEmail } from "@taketurns-rules/collaboration/utils/participant/getUserNamesOrEmail";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { IconRotateCw, IconX } from "../../../commons/icons";
import { SelectRoleButton } from "./SelectRoleButton";

interface InvitationItemProps {
  connectedUserParty: Party;
  invitee: Invitee;
  revokeInvitationAction: { action: (invitation: Invitee) => void; loading: boolean };
  editInvitationRoleAction: { action: (invitation: Invitee, newRole: Role) => void; loading: boolean };
  resendInvitationAction?: { action: (invitation: Invitee) => void; loading: boolean };
  color?: { variable: string; themeColor: "primary" | "secondary" };
  isCreation?: boolean;
  canEdit?: boolean;
}

export type InvitationItemComponentProps = Omit<
  InvitationItemProps,
  "revokeInvitationAction" | "editInvitationRoleAction" | "resendInvitationAction"
>;

export const InvitationItem = (props: InvitationItemProps) => {
  const { status } = useFetchCollaborationStatusRule();

  const isAllowedToModifyInvitation =
    (useCollaborationPermissions(CollaborationCommand.MANAGE_PARTICIPANTS) &&
      status !== CollaborationStatus.Closed &&
      status !== CollaborationStatus.Wiped &&
      props.canEdit) ||
    props.isCreation;

  if (props.revokeInvitationAction.loading || props.editInvitationRoleAction.loading) {
    return (
      <InvitationItem__Container disablePadding divider>
        <ListItemText primary={<Skeleton variant="text" />} />
      </InvitationItem__Container>
    );
  }

  const editInvitationRole = (newRole: Role) => {
    props.editInvitationRoleAction.action(props.invitee, newRole);
  };

  const revokeInvitation = () => {
    props.revokeInvitationAction.action(props.invitee);
  };

  return (
    <InvitationItem__Container disablePadding divider>
      <ListItemAvatar sx={{ minWidth: 40 }}>
        <Avatar
          sx={{
            width: 30,
            height: 30,
            backgroundColor: props.color ? props.color.variable : getDefaultColorForParty(props.invitee.party).variable,
          }}
          src={props.invitee.user?.avatar}
        />
      </ListItemAvatar>
      <ListItemText
        primary={
          <Typography
            noWrap
            color={props.color ? props.color.themeColor : getDefaultColorForParty(props.invitee.party).themeColor}
            fontSize={"0.75rem"}
          >
            {props.invitee.user ? getUserNamesOrEmail(props.invitee.user) : props.invitee.email}
          </Typography>
        }
        secondary={
          <Typography fontSize={"0.75rem"} color={TakeTurnsColors.darkGray}>
            {props.invitee.user?.jobTitle}
          </Typography>
        }
      />
      {props.resendInvitationAction && (
        <ResendInvitationButton invitation={props.invitee} resendInvitationAction={props.resendInvitationAction} />
      )}
      {isAllowedToModifyInvitation && (
        <IconButton size="small" sx={{ marginRight: "2px" }} onClick={revokeInvitation}>
          <IconX size={18} />
        </IconButton>
      )}
      <SelectRoleButton
        color={props.color ? props.color.variable : getDefaultColorForParty(props.invitee.party).variable}
        selectingRoleUserParty={props.invitee.party}
        connectedUserParty={props.connectedUserParty}
        editable={isAllowedToModifyInvitation}
        role={props.invitee.role}
        onChange={editInvitationRole}
        loading={props.editInvitationRoleAction.loading}
      />
    </InvitationItem__Container>
  );
};

const InvitationItem__Container = styled(ListItem)(({ theme }) => ({
  padding: 0,
  height: "50px",
  minHeight: "50px",
  [theme.breakpoints.down("md")]: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    height: "70px",
    minHeight: "70px",
  },
}));

const ResendInvitationButton = ({
  invitation,
  resendInvitationAction,
}: {
  invitation: Invitee;
  resendInvitationAction: { action: (invitation: Invitee) => void; loading: boolean };
}) => {
  const resendInvitation = () => {
    resendInvitationAction.action(invitation);
  };
  return (
    <IconButton
      onClick={resendInvitation}
      disabled={resendInvitationAction.loading}
      size="small"
      sx={{ marginRight: "2px" }}
    >
      <IconRotateCw size={18} />
    </IconButton>
  );
};
