/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useSnackbar } from "notistack";
import { useAttachmentManagerTranslation } from "@taketurns-i18n/collaboration/AttachmentManager/useAttachmentManagerTranslation";
import { PendingAttachmentRevision } from "@taketurns-repositories/collaboration/models/pendingAttachmentRevision";
import { useGetDisplayedCollaborationIdRule } from "@taketurns-rules/collaboration/queries/collaboration/useGetDisplayedCollaborationIdRule";
import { useFetchCollaborationNameByIdRule } from "../../queries/fields/useFetchCollaborationNameByIdRule";

export const useNotifyRevisionAddedRule = () => {
  const { enqueueSnackbar } = useSnackbar();
  const getCollaborationNameByIdRule = useFetchCollaborationNameByIdRule();
  const displayedCollaborationId = useGetDisplayedCollaborationIdRule();
  const { t } = useAttachmentManagerTranslation();
  return async (pendingAttachment: PendingAttachmentRevision) => {
    if (displayedCollaborationId === pendingAttachment.collaborationId) {
      enqueueSnackbar(
        t("AttachmentManager.pendingAttachmentRevisionSuccess.inCollaboration", {
          documentName: `${pendingAttachment.documentName}.${pendingAttachment.documentExtension}`,
        }),
        { variant: "info" },
      );
      return;
    }
    const collaborationName = await getCollaborationNameByIdRule(pendingAttachment.collaborationId);
    enqueueSnackbar(
      t("AttachmentManager.pendingAttachmentRevisionSuccess.outsideCollaboration", {
        documentName: `${pendingAttachment.documentName}.${pendingAttachment.documentExtension}`,
        collaborationName,
      }),
      { variant: "info" },
    );
  };
};
