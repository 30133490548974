/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { StripeCheckoutUrlInput } from "@taketurns/taketurns-graphql-repository";
import { useFetchStripeCheckoutUrl } from "@taketurns-repositories/user/graphql/queries/useFetchStripeCheckoutUrl";
import { useNavigateToPlansPageRule } from "@taketurns-rules/user/commands/useNavigateToPlansPageRule";

export const useRedirectToStripeCheckoutRule = () => {
  const { getStripeCheckoutUrl, loadingStripeCheckoutUrl } = useFetchStripeCheckoutUrl();
  const navigateToPlansPageRule = useNavigateToPlansPageRule();

  const redirectToStripeCheckout = (stripeCheckoutUrlInput: StripeCheckoutUrlInput) => {
    try {
      getStripeCheckoutUrl(stripeCheckoutUrlInput).then((stripeCheckoutUrl) => {
        window.location.replace(stripeCheckoutUrl);
      });
    } catch (error) {
      console.error(error);
      navigateToPlansPageRule();
    }
  };
  return { redirectToStripeCheckout, loadingStripeCheckoutUrl };
};
