/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Box, ListItem, Stack, styled, SxProps } from "@mui/material";
import { ForwardedRef, forwardRef, ReactNode } from "react";

interface ContentListItemProps {
  titleSlot: ReactNode;
  subtitleSlot: ReactNode;
  rightEndSlot?: ReactNode;
  onClick?: () => void;
  centerRightEndSlotVertically?: boolean;
  sx?: SxProps;
  dataCy?: string;
}

export const MobileListItem = forwardRef((props: ContentListItemProps, ref: ForwardedRef<HTMLLIElement | null>) => {
  const { titleSlot, subtitleSlot, rightEndSlot, onClick, centerRightEndSlotVertically, sx } = props;

  return (
    <ContentListItem__Container
      data-cy={props.dataCy}
      divider
      ref={ref}
      className="ContentListItem__Container"
      onClick={onClick}
      sx={sx}
    >
      <ContentListItem__LeftContainer>
        <TitleSlot__Container className="TitleSlot__Container">{titleSlot}</TitleSlot__Container>
        <BottomZone className="BottomZone">
          <SubtitleSlot__Container className="SubtitleSlot__Container">{subtitleSlot}</SubtitleSlot__Container>
          {!centerRightEndSlotVertically && rightEndSlot}
        </BottomZone>
      </ContentListItem__LeftContainer>
      {centerRightEndSlotVertically && rightEndSlot}
    </ContentListItem__Container>
  );
});
MobileListItem.displayName = "MobileListItem";

const ContentListItem__Container = styled(ListItem)<{
  onClick: () => void;
}>(({ onClick, theme }) => ({
  display: "flex",
  gap: theme.spacing(1),
  padding: "0 24px",
  height: "80px",
}));

const ContentListItem__LeftContainer = styled(Stack)(({ theme }) => ({
  flex: 1,
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "center",
  gap: theme.spacing(1),
  overflow: "hidden",
}));

const TitleSlot__Container = styled(Box)({
  maxWidth: "100%",
});

const BottomZone = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  gap: theme.spacing(2),
  width: "100%",
}));

const SubtitleSlot__Container = styled(Stack)(({ theme }) => ({
  flex: 1,
  flexDirection: "row",
  flexWrap: "wrap",
  alignItems: "center",
  columnGap: theme.spacing(1),
  minWidth: 0,
}));
