/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { SingleCenteredColumnLayout } from "@taketurns-app/layouts/body/content/SingleCenteredColumnLayout";
import { AUTHENTICATED_ROUTES } from "@taketurns-app/routing/routes/authenticatedRoutes.constants";
import { SimpleFileSharing } from "@taketurns-components/collaboration/CollaborationCreation/SimpleCollaborationCreation/SimpleFileSharing";
import { TakeTurnsTextButton } from "@taketurns-components/commons/button";
import { MobileBackButton } from "@taketurns-components/webapp/MobileBackButton";
import { useWebAppTranslations } from "@taketurns-i18n/webapp/useWebAppTranslations";
import { useIsOnMobileRule } from "@taketurns-rules/commons/theme/useIsOnMobileRule";

export const SimpleFileSharingCreation = () => {
  return <SingleCenteredColumnLayout header={<SimpleFileSharingHeader />} content={<SimpleFileSharing />} />;
};

const SimpleFileSharingHeader = () => {
  const navigate = useNavigate();
  const { t } = useWebAppTranslations("simple-file-sharing");
  const isOnMobile = useIsOnMobileRule();

  return (
    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
      {isOnMobile && <MobileBackButton />}
      <Typography>{t("title")}</Typography>
      <TakeTurnsTextButton onClick={() => navigate(AUTHENTICATED_ROUTES.COLLABORATION_CREATE.FULL)}>
        {t("advancedButton")}
      </TakeTurnsTextButton>
    </Box>
  );
};
