/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useSetRecoilState } from "recoil";
import { contentSelectionState } from "@taketurns-repositories/collaboration/state/recoil/contentSelectionState";

export const useEnableContentSelectionState = () => {
  const setState = useSetRecoilState(contentSelectionState);
  return () => {
    setState((prevState) => ({
      ...prevState,
      isActive: true,
    }));
  };
};
