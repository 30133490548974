/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useRecoilCallback } from "recoil";
import { PendingAttachmentRevision } from "@taketurns-repositories/collaboration/models/pendingAttachmentRevision";
import { collaborationIdsLinkedToPendingAttachmentRevisionsState } from "../recoil/collaborationIdsLinkedToPendingAttachmentRevisionsState";
import { pendingAttachmentRevisionsByCollaborationIdState } from "../recoil/pendingAttachmentRevisionsByCollaborationIdState";

// TODO MCH move this to features/AttachmentDetails folder
export const useStorePendingAttachmentRevision = () => {
  return useRecoilCallback(
    ({ set }) =>
      (pendingAttachmentRevision: PendingAttachmentRevision) => {
        set(
          collaborationIdsLinkedToPendingAttachmentRevisionsState,
          (collaborationIdsLinkedToPendingAttachmentRevisions) =>
            new Set([...collaborationIdsLinkedToPendingAttachmentRevisions, pendingAttachmentRevision.collaborationId]),
        );
        set(
          pendingAttachmentRevisionsByCollaborationIdState(pendingAttachmentRevision.collaborationId),
          (currentPendingAttachmentRevisions) => [...currentPendingAttachmentRevisions, pendingAttachmentRevision],
        );
      },
    [],
  );
};
