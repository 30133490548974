/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import {
  Box,
  Button,
  CircularProgress,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Radio,
  Typography,
} from "@mui/material";
import React, { useRef, useState } from "react";
import { Attachment } from "@taketurns/taketurns-graphql-repository";
import { IconChevronDown, IconReview } from "@taketurns-components/commons/icons";
import { useAttachmentDetailsTranslation } from "@taketurns-i18n/collaboration/AttachmentDetails/useAttachmentDetailsTranslation";
import { useMarkAttachmentForReviewRule } from "@taketurns-rules/collaboration/commands/collaborationView/attachment/useMarkAttachmentForReviewRule";
import { useUnmarkAttachmentForReviewRule } from "@taketurns-rules/collaboration/commands/collaborationView/attachment/useUnmarkAttachmentForReviewRule";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";

interface MobileAttachmentDetailsActionDropdownProps {
  attachment: Attachment;
  canMarkForReview: boolean;
}

export const MobileAttachmentDetailsActionDropdown = (props: MobileAttachmentDetailsActionDropdownProps) => {
  const selectorButtonRef = useRef(null);
  const [selectMenuOpen, setSelectMenuOpen] = useState(false);
  const { t } = useAttachmentDetailsTranslation();

  if (!props.canMarkForReview) {
    return null;
  }

  const { selectedActionIcon, selectedActionLabelKey } = getSelectedAction();

  const openSelectMenu = () => {
    setSelectMenuOpen(true);
  };
  const closeSelectMenu = () => {
    setSelectMenuOpen(false);
  };

  return (
    <Box ref={selectorButtonRef}>
      <Button
        variant="text"
        onClick={openSelectMenu}
        startIcon={selectedActionIcon}
        endIcon={<IconChevronDown size={16} color={TakeTurnsColors.darkGray} />}
        data-cy="MobileAttachmentDetails.ActionDropdown"
        data-cy-selected-action={selectedActionLabelKey}
        sx={{
          gap: 1,
          border: `1px solid ${selectedActionLabelKey ? TakeTurnsColors.darkBlue : TakeTurnsColors.grayButton}`,
          color: selectedActionLabelKey ? TakeTurnsColors.darkBlue : TakeTurnsColors.darkGray,
          px: 1.5,
          py: 2,
        }}
      >
        {t(selectedActionLabelKey ?? "askOtherParty")}
      </Button>
      <Menu
        open={selectMenuOpen}
        transformOrigin={{ vertical: "bottom", horizontal: "left" }}
        anchorEl={selectorButtonRef.current}
        onClose={closeSelectMenu}
        slotProps={{ paper: { sx: { borderRadius: "10px" } } }}
      >
        <MarkForReviewAction attachment={props.attachment} afterAction={closeSelectMenu} />
      </Menu>
    </Box>
  );

  function getSelectedAction() {
    if (props.attachment.markedForReview) {
      return {
        selectedActionIcon: <IconReview />,
        selectedActionLabelKey: "reviewRequested",
      };
    }
    return {
      selectedActionIcon: null,
      selectedActionLabelKey: null,
    };
  }
};

const MarkForReviewAction = (props: { attachment: Attachment; afterAction: () => void }) => {
  const { markAttachmentForReview, markAttachmentForReviewLoading } = useMarkAttachmentForReviewRule(
    props.attachment.id,
  );
  const { unmarkAttachmentForReview, unmarkAttachmentForReviewLoading } = useUnmarkAttachmentForReviewRule(
    props.attachment.id,
  );

  const toggleMarkForReview = async () => {
    if (props.attachment.markedForReview) {
      await unmarkAttachmentForReview();
    } else {
      await markAttachmentForReview();
    }
    props.afterAction();
  };

  return (
    <ActionSelectItem
      isActive={props.attachment.markedForReview}
      labelKey="askToReview"
      loading={markAttachmentForReviewLoading || unmarkAttachmentForReviewLoading}
      onItemClicked={toggleMarkForReview}
    />
  );
};

interface ActionSelectItemProps {
  isActive: boolean;
  labelKey: string;
  loading: boolean;
  onItemClicked: () => void;
}
const ActionSelectItem = (props: ActionSelectItemProps) => {
  const color = props.isActive ? TakeTurnsColors.darkBlue : undefined;
  const { t } = useAttachmentDetailsTranslation();

  return (
    <MenuItem
      data-cy={`ActionDropdown.${props.labelKey}`}
      onClick={props.onItemClicked}
      disabled={props.loading}
      sx={{ color }}
    >
      <ListItemIcon>
        {props.loading ? (
          <CircularProgress size={24} />
        ) : (
          <Radio checked={props.isActive} size="small" sx={{ color }} />
        )}
      </ListItemIcon>
      <ListItemText>
        <Typography variant={"body2"}>{t(props.labelKey)}</Typography>
      </ListItemText>
    </MenuItem>
  );
};
