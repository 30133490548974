/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useLazyQuery } from "@apollo/client";
import { Organization } from "@taketurns/taketurns-graphql-repository";

const USER_ORGANIZATION_SUBSCRIPTION_REGION = gql`
  query userOrganizationSubscriptionRegion {
    connectedUser {
      id
      organization {
        id
        subscription {
          id
          region
        }
      }
    }
  }
`;

export const useLazyFetchUserOrganizationSubscriptionRegion = () => {
  const [
    fetchUserOrganizationSubscriptionRegion,
    {
      data,
      error: errorOnFetchingUserOrganizationSubscriptionRegion,
      loading: loadingUserOrganizationSubscriptionRegion,
    },
  ] = useLazyQuery<{
    connectedUser: { organization: Organization };
  }>(USER_ORGANIZATION_SUBSCRIPTION_REGION, { fetchPolicy: "cache-and-network" });
  const userOrganizationSubscriptionRegion = data?.connectedUser?.organization?.subscription?.region;
  return {
    fetchUserOrganizationSubscriptionRegion,
    userOrganizationSubscriptionRegion,
    errorOnFetchingUserOrganizationSubscriptionRegion,
    loadingUserOrganizationSubscriptionRegion,
  };
};
