/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useCallback } from "react";
import { useGetSelectedRevisionId } from "@taketurns-repositories/collaboration/state/read/useGetSelectedRevisionId";
import { useSetSelectedRevisionId } from "@taketurns-repositories/collaboration/state/write/useSetSelectedRevisionId";

export const useSelectDocumentRevisionRule = () => {
  const selectedRevisionId = useGetSelectedRevisionId();
  const selectRevisionId = useSetSelectedRevisionId();

  const selectRevision = useCallback(
    (revisionId: string) => {
      selectRevisionId(revisionId);
    },
    [selectRevisionId],
  );

  return { selectRevision, selectedRevisionId };
};
