/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";
import { PrepareArchiveDownloadInput } from "@taketurns/taketurns-graphql-repository";
import { useCollaborationArchiveDownloadTranslation } from "@taketurns-i18n/collaboration/CollaborationArchiveDownload/useCollaborationArchiveDownloadTranslation";
import { usePrepareDownloadCollaborationArchive } from "@taketurns-repositories/collaboration/graphql/mutations/content/usePrepareArchiveDownload";
import { useFetchCollaborationArchiveDownloadStatus } from "@taketurns-repositories/collaboration/graphql/queries/closing/useFetchCollaborationArchiveDownloadStatus";

export const useDownloadCollaborationArchiveRule = () => {
  const [loadingDownloadCollaborationArchive, setLoadingDownloadCollaborationArchive] = useState(false);
  const { prepareArchiveDownload, downloadId, loadingPrepareArchiveDownload, errorOnPrepareArchiveDownload } =
    usePrepareDownloadCollaborationArchive();
  const {
    fetchCollaborationArchiveDownloadStatus,
    loadingCollaborationArchiveStatus,
    archiveDownloadUrl,
    errorOnLoadingCollaborationArchiveStatus,
  } = useFetchCollaborationArchiveDownloadStatus();
  const { t } = useCollaborationArchiveDownloadTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const downloadCollaborationArchive = useCallback((prepareArchiveDownloadInput: PrepareArchiveDownloadInput) => {
    prepareArchiveDownload(prepareArchiveDownloadInput);
  }, []);

  useEffect(() => {
    if (downloadId) {
      fetchCollaborationArchiveDownloadStatus(downloadId);
    }
  }, [downloadId, fetchCollaborationArchiveDownloadStatus]);

  useEffect(() => {
    if (loadingPrepareArchiveDownload || loadingCollaborationArchiveStatus) {
      setLoadingDownloadCollaborationArchive(true);
    }

    if (errorOnPrepareArchiveDownload || errorOnLoadingCollaborationArchiveStatus) {
      setLoadingDownloadCollaborationArchive(false);
      enqueueSnackbar(t("errorOnDownloadArchive"), { variant: "error" });
    }

    if (archiveDownloadUrl) {
      setLoadingDownloadCollaborationArchive(false);
    }
  }, [
    loadingCollaborationArchiveStatus,
    loadingPrepareArchiveDownload,
    errorOnLoadingCollaborationArchiveStatus,
    errorOnPrepareArchiveDownload,
    archiveDownloadUrl,
    enqueueSnackbar,
  ]);

  return { downloadCollaborationArchive, archiveDownloadUrl, loadingDownloadCollaborationArchive };
};
