/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useSnackbar } from "notistack";

export const useDisplayNotificationFailureRule = () => {
  const { enqueueSnackbar } = useSnackbar();
  return (failureMessage: string) => {
    enqueueSnackbar(failureMessage, { variant: "error" });
  };
};
