/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { RemoveOrganizationDomainInput } from "@taketurns/taketurns-graphql-repository";
import { useOrganizationTranslation } from "@taketurns-i18n/user/Organization/useOrganizationTranslation";
import { useRemoveOrganizationDomain } from "@taketurns-repositories/user/graphql/mutations/useRemoveOrganizationDomain";
import { useDisplayAndLogNotificationErrorRule } from "./useDisplayAndLogNotificationErrorRule";
import { useDisplayNotificationSuccessRule } from "./useDisplayNotificationSuccessRule";

export const useRemoveOrganizationDomainRule = () => {
  const {
    removeOrganizationDomain: removeOrganizationDomainCommand,
    errorOnRemoveOrganizationDomain,
    loadingRemoveOrganizationDomain,
  } = useRemoveOrganizationDomain();
  const { t } = useOrganizationTranslation();

  const displayNotificationSuccess = useDisplayNotificationSuccessRule();
  const displayAndLogNotificationError = useDisplayAndLogNotificationErrorRule();
  const removeOrganizationDomain = ({
    removeOrganizationDomainInput,
  }: {
    removeOrganizationDomainInput: RemoveOrganizationDomainInput;
  }) => {
    removeOrganizationDomainCommand(removeOrganizationDomainInput)
      .then(() => displayNotificationSuccess(t("Notifications.removeOrganizationDomainSuccess")))
      .catch(() => displayAndLogNotificationError(t("error")));
  };

  return { removeOrganizationDomain, errorOnRemoveOrganizationDomain, loadingRemoveOrganizationDomain };
};
