/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Box, ListItem, styled, SxProps } from "@mui/material";
import { ForwardedRef, forwardRef, ReactNode } from "react";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";

interface ContentListItemProps {
  titleSlot: ReactNode;
  subtitleSlot?: ReactNode;
  middleSlot?: ReactNode;
  rightEndSlot?: ReactNode;
  onClick?: () => void;
  hasContextMenu?: boolean;
  sx?: SxProps;
}

export const TakeTurnsListItem = forwardRef((props: ContentListItemProps, ref: ForwardedRef<HTMLLIElement | null>) => {
  const { titleSlot, subtitleSlot, middleSlot, rightEndSlot, onClick, hasContextMenu, sx } = props;

  return (
    <ContentListItem__Container
      divider
      ref={ref}
      className="FileListItemContainer"
      onClick={onClick}
      hasContextMenu={hasContextMenu}
      sx={sx}
    >
      <LeftAlignedZone className="LeftAlignedZone">
        <TitleSlotContainer className="TitleSlotContainer">{titleSlot}</TitleSlotContainer>
        {subtitleSlot && (
          <SubtitleSlotContainer className="SubtitleSlotContainer">{subtitleSlot}</SubtitleSlotContainer>
        )}
      </LeftAlignedZone>
      <RightAlignedZone className="RightAlignedZone">
        {middleSlot && <MiddleSlotContainer className="MiddleSlotContainer">{middleSlot}</MiddleSlotContainer>}
        <RightEndSlotContainer className="RightSlotContainer">{rightEndSlot}</RightEndSlotContainer>
      </RightAlignedZone>
    </ContentListItem__Container>
  );
});
TakeTurnsListItem.displayName = "TakeTurnsListItem";

const ContentListItem__Container = styled(ListItem, {
  shouldForwardProp: (propName: PropertyKey) => propName !== "hasContextMenu",
})<{
  onClick: () => void;
  hasContextMenu?: boolean;
}>(({ onClick, hasContextMenu, theme }) => ({
  display: "flex",
  flexDirection: "row",
  height: "50px",
  padding: "0 10px",
  gap: theme.spacing(2),
  "&:hover": {
    cursor: onClick ? "pointer" : hasContextMenu ? "context-menu" : undefined,
    backgroundColor: onClick ? TakeTurnsColors.hoverBackground : undefined,
  },
}));

const LeftAlignedZone = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  flex: "1 1 auto",
  gap: 16,
  minWidth: 0,
});

const TitleSlotContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  height: "48px",
  flex: "0 1 auto",
  minWidth: 0,
});

const SubtitleSlotContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  height: "50px",
});

const RightAlignedZone = styled(Box)({
  height: "50px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
  gap: 16,
  alignItems: "center",
  flex: `0 0 auto`,
});

const MiddleSlotContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
});

const RightEndSlotContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
});
