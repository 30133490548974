/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { Participant } from "@taketurns/taketurns-graphql-repository";
import { useCollaborationTranslation } from "@taketurns-i18n/collaboration/useCollaborationTranslation";
import { useRemoveParticipant } from "@taketurns-repositories/collaboration/graphql/mutations/participants/useRemoveParticipant";
import { useGetDisplayedCollaborationIdRule } from "@taketurns-rules/collaboration/queries/collaboration/useGetDisplayedCollaborationIdRule";

export const useRemoveParticipantRule = () => {
  const { removeParticipantCommand, loadingRemoveParticipant, errorOnRemoveParticipant } = useRemoveParticipant();
  useNotifyOnError(errorOnRemoveParticipant);

  const collaborationId = useGetDisplayedCollaborationIdRule();
  const notifyParticipantHasBeenRemoved = useNotifyParticipantHasBeenRemoved();
  const removeParticipant = (participant: Participant) => {
    removeParticipantCommand(collaborationId, participant.userId, () => notifyParticipantHasBeenRemoved(participant));
  };
  return { removeParticipant, loadingRemoveParticipant };
};

const useNotifyOnError = (error: { message: string }) => {
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  }, [error]);
};

const useNotifyParticipantHasBeenRemoved = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useCollaborationTranslation("notifications");
  return (participant: Participant) => {
    enqueueSnackbar(t("user.removeParticipant", { email: participant.user.email }), {
      variant: "info",
    });
  };
};
