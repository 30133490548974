/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { SxProps } from "@mui/material";
import { useSnackbar } from "notistack";
import { ComponentType, PropsWithChildren, ReactElement, Ref, useEffect, useRef, useState } from "react";
import { useSharedCollaborationTranslation } from "@taketurns-i18n/collaboration/shared/useSharedCollaborationTranslation";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";

interface FilesDragAndDropProps {
  ContainerComponent: ComponentType<
    PropsWithChildren<{
      className?: string;
      sx?: SxProps;
      ref: Ref<HTMLDivElement>;
    }>
  >;
  dropZoneConfig: DropZoneConfig;
}

export interface DropZoneConfig {
  handleFilesDropping: (files: File[]) => Promise<void> | void;
  className?: string;
  sx?: SxProps;
}

export const FilesDragAndDrop = ({
  ContainerComponent,
  children,
  dropZoneConfig: { className, handleFilesDropping, sx },
}: PropsWithChildren<FilesDragAndDropProps>): ReactElement => {
  const dropZoneRef = useRef<HTMLDivElement>(null);
  const [dragging, setDragging] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useSharedCollaborationTranslation();

  useEffect(() => {
    dropZoneRef.current?.addEventListener("dragover", handleDragOver);
    document.addEventListener("dragover", handleDragOverDocument);
    dropZoneRef.current?.addEventListener("dragleave", handleDragLeave);
    document.addEventListener("drop", handleDropOnDocument);

    return () => {
      dropZoneRef.current?.removeEventListener("dragover", handleDragOver);
      document.removeEventListener("dragover", handleDragOverDocument);
      dropZoneRef.current?.removeEventListener("dragleave", handleDragLeave);
      document.removeEventListener("drop", handleDropOnDocument);
    };
  }, [dropZoneRef]);

  // FJS : Since the function handleFilesDropping can change depending on the content of the function (the folderId for example),
  // The effect has to be re-run when the function changes
  useEffect(() => {
    dropZoneRef.current?.addEventListener("drop", handleDrop);
    return () => {
      dropZoneRef.current?.removeEventListener("drop", handleDrop);
    };
  }, [handleFilesDropping, dropZoneRef]);

  const BACKDROP_OPACITY_IN_PERCENT = 50;
  return (
    <ContainerComponent
      className={className}
      ref={dropZoneRef}
      sx={{
        backgroundColor: dragging ? TakeTurnsColors.lightBlue + BACKDROP_OPACITY_IN_PERCENT : "transparent",
        outline: dragging ? `${TakeTurnsColors.lightBlue} 2px solid` : `transparent 2px solid`,
        ...sx,
      }}
    >
      {children}
    </ContainerComponent>
  );

  function handleDragOver(e: DragEvent) {
    e.preventDefault();
    e.stopPropagation();
    if (!dragging) setDragging(true);
  }

  function handleDragOverDocument(e: DragEvent) {
    e.preventDefault();
    e.stopPropagation();
    if (e.target !== dropZoneRef.current) setDragging(false);
  }

  function handleDragLeave(e: DragEvent) {
    e.preventDefault();
    e.stopPropagation();
    if (e.target !== dropZoneRef.current) setDragging(false);
  }

  function handleDropOnDocument(e: DragEvent) {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  }

  function handleDrop(e: DragEvent) {
    e.preventDefault();
    e.stopPropagation();

    setDragging(false);

    const filesToUpload = [];
    let containsFolders = false;
    for (let dataTransferIndex = 0; dataTransferIndex < e.dataTransfer?.items.length; dataTransferIndex++) {
      const draggedItems = [...e.dataTransfer.items];
      const draggedFiles = [...e.dataTransfer.files];
      if (draggedItems[dataTransferIndex].webkitGetAsEntry().isFile) {
        filesToUpload.push(draggedFiles[dataTransferIndex]);
      }
      if (draggedItems[dataTransferIndex].webkitGetAsEntry().isDirectory) {
        containsFolders = true;
      }
    }

    if (containsFolders) {
      enqueueSnackbar(t("FilesDragAndDrop.folderNotSupported"), { variant: "error" });
    }
    if (filesToUpload.length) {
      handleFilesDropping(filesToUpload);
    }
  }
};
