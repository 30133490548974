/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useSearchParams } from "react-router-dom";
import { MarketingDataInput } from "@taketurns/taketurns-graphql-repository";
import { setMarketingData } from "@taketurns-repositories/webapp/sessionStorage/marketingData";
import { useDecodeParamsInCurrentUrlRule } from "../queries/useDecodeParamsInCurrentUrlRule";

interface UtmParameters {
  utm?: {
    campaign?: string;
    term?: string;
    source?: string;
    medium?: string;
    content?: string;
  };
}

export const useSetMarketingData = () => {
  const queryParams = useDecodeParamsInCurrentUrlRule<UtmParameters>();

  const hasSomeMarketingData = Object.values(queryParams?.utm || {}).some((value) => !!value);
  if (hasSomeMarketingData) {
    setMarketingData(JSON.stringify(queryParams?.utm));
  }

  const [searchParams] = useSearchParams();
  const marketingData: MarketingDataInput = createMarketingData();
  const hasSomeMarketingDataInClear = Object.values(marketingData).some((value) => !!value);
  if (hasSomeMarketingDataInClear) {
    setMarketingData(JSON.stringify(marketingData));
  }

  function createMarketingData() {
    let marketingData: MarketingDataInput = {};
    const MARKETING_PREFIX = "utm_";
    const UTM_PARAMETER_KEYS = ["campaign", "source", "content", "medium", "term"].map(
      (m) => `${MARKETING_PREFIX}${m}`,
    );
    searchParams.forEach((searchParamValue, searchParamKey) => {
      if (UTM_PARAMETER_KEYS.includes(searchParamKey)) {
        const marketingDataKey = searchParamKey.replace(MARKETING_PREFIX, "");
        marketingData = { ...marketingData, [marketingDataKey]: searchParamValue };
      }
    });
    return marketingData;
  }
};
