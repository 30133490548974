/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useFetchIsCollaborationHidden } from "@taketurns-repositories/collaboration/graphql/queries/context/useFetchIsCollaborationHidden";
import { useGetDisplayedCollaborationIdRule } from "@taketurns-rules/collaboration/queries/collaboration/useGetDisplayedCollaborationIdRule";

export const useFetchIsCollaborationHiddenRule = (collaborationId?: string) => {
  const displayedCollaborationId = useGetDisplayedCollaborationIdRule();
  return useFetchIsCollaborationHidden(collaborationId ?? displayedCollaborationId);
};
