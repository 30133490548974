/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useRecoilValue } from "recoil";
import { completedUploadIdsState } from "../recoil/completedUploadIdsState";

export const useGetCompletedUploadIds = () => {
  return useRecoilValue(completedUploadIdsState);
};
