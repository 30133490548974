/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Divider, Skeleton, Stack, Typography } from "@mui/material";
import { Fragment } from "react";
import { FetchLoadingManager } from "@taketurns-app/utils/FetchLoadingManager";
import { CollaborationArchiveDownload } from "@taketurns-components/collaboration/CollaborationArchiveDownload/CollaborationArchiveDownload";
import { CollaborationMenu } from "@taketurns-components/collaboration/CollaborationMenu/CollaborationMenu";
import {
  CancelPendingUpdatesButton,
} from "@taketurns-components/collaboration/CollaborationView/CollaborationContent/ContentHeader/CancelPendingUpdates/CancelPendingUpdatesButton";
import { ContentHeadline } from "@taketurns-components/collaboration/CollaborationView/CollaborationContent/ContentHeader/ContentHeadline";
import { SendUpdateButtonWarnMessage } from "@taketurns-components/collaboration/CollaborationView/CollaborationContent/ContentHeader/SendUpdates/SendUpdateButtonWarnMessage";
import { SendUpdatesButton } from "@taketurns-components/collaboration/CollaborationView/CollaborationContent/ContentHeader/SendUpdates/SendUpdatesButton";
import { ToDoFilterToggle } from "@taketurns-components/collaboration/CollaborationView/CollaborationContent/ContentHeader/ToDoFilterToggle";
import { Participants } from "@taketurns-components/collaboration/CollaborationView/CollaborationHeader/components/Participants/Participants";
import { SomethingWentWrong } from "@taketurns-components/shared/SomethingWentWrong";
import { useCollaborationTranslation } from "@taketurns-i18n/collaboration/useCollaborationTranslation";
import { useFetchConnectedUserRole } from "@taketurns-repositories/collaboration/graphql/queries/context/useFetchConnectedUserRole";
import { useGetDisplayedCollaborationIdRule } from "@taketurns-rules/collaboration/queries/collaboration/useGetDisplayedCollaborationIdRule";
import { useFetchActionsPermissionsOnUpdatesRule } from "@taketurns-rules/collaboration/queries/collaborationView/useFetchActionsPermissionsOnUpdatesRule";
import { useIsCollaborationClosedRule } from "@taketurns-rules/collaboration/queries/collaborationView/useIsCollaborationClosedRule";
import { useFetchCollaborationContentMetadataRule } from "@taketurns-rules/collaboration/queries/fields/useFetchCollaborationContentMetadataRule";
import { useFetchCollaborationLastUpdateEventRule } from "@taketurns-rules/collaboration/queries/fields/useFetchCollaborationLastUpdateEventRule";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";

interface MobileContentHeaderProps {
  displayUpdatedOnly: boolean;
  setDisplayUpdatedOnly: (displayUpdatedOnly: boolean) => void;
  displayTodos: boolean;
  setDisplayTodos: (displayTodos: boolean) => void;
}

export const MobileContentHeader = (props: MobileContentHeaderProps) => {
  const {
    canCancelPendingUpdates,
    canSendPendingUpdates,
    isLastUpdateEventFromConnectedUserParty,
    isCollaborationClosed,
    todoCount,
    role,
    loading,
    error,
  } = useMobileContentHeaderDataRule();

  const hasTodo = todoCount > 0;

  if (loading) {
    return <Skeleton width={1} />;
  }

  if (error) {
    return <SomethingWentWrong />;
  }

  const topBorderColor = canSendPendingUpdates
    ? TakeTurnsColors.yellow
    : isLastUpdateEventFromConnectedUserParty
      ? TakeTurnsColors.connectedUser
      : TakeTurnsColors.otherParty;

  return (
    <Stack borderTop={`5px solid ${topBorderColor}`} borderRadius={"20px 20px 0 0"}>
      <HeaderTop
        role={role}
        hasTodo={hasTodo}
        todoCount={todoCount}
        toggled={props.displayTodos}
        toggle={() => props.setDisplayTodos(!props.displayTodos)}
      />
      <Divider />
      <HeaderBottom
        displayUpdatedOnly={props.displayUpdatedOnly}
        setDisplayUpdatedOnly={props.setDisplayUpdatedOnly}
        isCollaborationClosed={isCollaborationClosed}
        canSendPendingUpdates={canSendPendingUpdates}
        canCancelPendingUpdates={canCancelPendingUpdates}
      />
    </Stack>
  );
};

function useMobileContentHeaderDataRule() {
  const { isCollaborationClosed, loadingCollaborationStatus, errorOnFetchingCollaborationStatus } =
    useIsCollaborationClosedRule();

  const {
    canCancelPendingUpdates,
    canSendPendingUpdates,
    canRecallUpdates,
    loadingActionsPermissionsOnUpdates,
    errorOnFetchingActionsPermissionsOnUpdates,
  } = useFetchActionsPermissionsOnUpdatesRule();

  const {
    contentMetadata,
    loading: loadingContentMetadata,
    error: errorWhileFetchingContentMetadata,
  } = useFetchCollaborationContentMetadataRule();

  const collaborationId = useGetDisplayedCollaborationIdRule();
  const { connectedUserRole, loadingConnectedUserRole, errorOnConnectedUserRole } =
    useFetchConnectedUserRole(collaborationId);
  const { lastUpdateEvent, recallLastUpdatesAvailable, loadingLastUpdateEvent, errorWhileFetchingLastUpdateEvent } =
    useFetchCollaborationLastUpdateEventRule();

  const fetchLoadingManager = new FetchLoadingManager(
    [
      loadingCollaborationStatus,
      loadingActionsPermissionsOnUpdates,
      loadingContentMetadata,
      loadingConnectedUserRole,
      loadingLastUpdateEvent,
    ],
    [
      errorOnFetchingCollaborationStatus,
      errorOnFetchingActionsPermissionsOnUpdates,
      errorWhileFetchingContentMetadata,
      errorOnConnectedUserRole,
      errorWhileFetchingLastUpdateEvent,
    ],
  );

  if (fetchLoadingManager.hasFetchInError()) {
    fetchLoadingManager.logErrors();
  }

  return {
    isLastUpdateEventFromConnectedUserParty: lastUpdateEvent?.isFromConnectedUserParty,
    canCancelPendingUpdates: canCancelPendingUpdates,
    canSendPendingUpdates: canSendPendingUpdates,
    canRecallUpdates: canRecallUpdates && recallLastUpdatesAvailable,
    isCollaborationClosed,
    role: connectedUserRole,
    todoCount: contentMetadata?.todoCount,
    loading: fetchLoadingManager.hasFetchLoading(),
    error: fetchLoadingManager.hasFetchInError(),
  };
}

const HeaderTop = (props: {
  role: string | undefined;
  hasTodo: boolean;
  todoCount: number | undefined;
  toggled: boolean;
  toggle: () => void;
}) => {
  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between" px={3} py={2}>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Participants />
        <RoleTag role={props.role} />
      </Stack>
      <Stack direction="row" alignItems="center" spacing={1}>
        {props.hasTodo && (
          <ToDoFilterToggle todoCount={props.todoCount} isToggled={props.toggled} toggle={props.toggle} />
        )}
        <CollaborationMenu />
      </Stack>
    </Stack>
  );
};

const RoleTag = ({ role }: { role: string }) => {
  const { t } = useCollaborationTranslation("participantCollaboration");

  return (
    <Typography fontSize="0.75rem" color={TakeTurnsColors.darkGray}>
      {t("role")}:{" "}
      <Typography component="span" fontSize="0.75rem" color={TakeTurnsColors.connectedUser}>
        {t(role)}
      </Typography>
    </Typography>
  );
};

const HeaderBottom = (props: {
  displayUpdatedOnly: boolean;
  setDisplayUpdatedOnly: (displayUpdatedOnly: boolean) => void;
  canSendPendingUpdates: undefined | boolean;
  isCollaborationClosed: boolean;
  canCancelPendingUpdates: undefined | boolean;
}) => {
  return (
    <Stack
      className="HeaderBottom"
      direction="row"
      alignItems="center"
      gap={1}
      justifyContent="space-between"
      bgcolor={TakeTurnsColors.background}
      height={100}
      px={3}
    >
      {props.isCollaborationClosed && <CollaborationArchiveDownload />}
      {!props.isCollaborationClosed && !props.canSendPendingUpdates && (
        <ContentHeadline
          displayUpdatedOnly={props.displayUpdatedOnly}
          setDisplayUpdatedOnly={props.setDisplayUpdatedOnly}
        />
      )}
      {props.canSendPendingUpdates && (
        <Fragment>
          <SendUpdateButtonWarnMessage />
          <Stack direction="row" flex="0 0 auto">
            <CancelPendingUpdatesButton />
            <SendUpdatesButton />
          </Stack>
        </Fragment>
      )}
    </Stack>
  );
};
