/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { HAVE_PLANS_BEEN_SEEN_LOCAL_STORAGE_KEY } from "../keys/havePlansBeenSeenLocalStorageKey";

export const setPlansHaveBeenSeenInLocalStorage = () => {
  return () => {
    localStorage.setItem(HAVE_PLANS_BEEN_SEEN_LOCAL_STORAGE_KEY, JSON.stringify(true));
  };
};
