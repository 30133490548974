/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useQuery } from "@apollo/client";
import { PendingOrganizationInvitation } from "@taketurns/taketurns-graphql-repository";

export const USER_PENDING_ORGANIZATION_INVITATION = gql`
  query userPendingOrganizationInvitation {
    userPendingOrganizationInvitation {
      organizationId
      organizationName
      invitingUser {
        avatar
        email
        firstName
        lastName
      }
    }
  }
`;
export const useFetchUserPendingOrganizationInvitation = () => {
  const {
    data,
    loading: loadingUserPendingOrganizationInvitation,
    error: errorOnUserPendingOrganization,
  } = useQuery<{
    userPendingOrganizationInvitation: PendingOrganizationInvitation;
  }>(USER_PENDING_ORGANIZATION_INVITATION);
  return {
    pendingOrganizationInvitation: data?.userPendingOrganizationInvitation,
    loadingUserPendingOrganizationInvitation,
    errorOnUserPendingOrganization,
  };
};
