/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Typography } from "@mui/material";
import { Attachment, ContentStatus, Folder, Request } from "@taketurns/taketurns-graphql-repository";
import { DocumentChip } from "@taketurns-components/collaboration/Shared/chip/DocumentChip";
import { useSharedCollaborationTranslation } from "@taketurns-i18n/collaboration/shared/useSharedCollaborationTranslation";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { IconLock, IconRequest, IconReview } from "../../../commons/icons";
import { YellowChip } from "../YellowChip";

export const LockMarker = () => <IconLock color={TakeTurnsColors.darkGray} size={12} />;

export const MarkedForReviewMarker = () => <IconReview color={TakeTurnsColors.connectedUser} size={12} />;

export const RequestIconMarker = () => <IconRequest color={TakeTurnsColors.connectedUser} size={12} />;

export const RequestWordMarker = () => {
  const { t } = useSharedCollaborationTranslation();
  return (
    <Typography
      color={{ xs: TakeTurnsColors.mediumGray, md: TakeTurnsColors.darkGray }}
      fontSize={{ xs: 10, md: 9 }}
      fontWeight={{ xs: 700, md: 600 }}
    >
      {t("RequestCard.request")}
    </Typography>
  );
};

export const AttachmentPendingUpdatesMarker = ({ attachment }: { attachment: Attachment }) => {
  const { t } = useSharedCollaborationTranslation();
  return <YellowChip tooltipMessage={`${t(`ContentElementStatus.${attachment.status}`)}`} />;
};

export const RequestUpdateMarker = ({ request }: { request: Request }) => {
  const { t } = useSharedCollaborationTranslation();
  return <YellowChip tooltipMessage={`${t(`ContentElementStatus.${request.status}`)}`} />;
};

export const FolderUpdatesMarker = ({ folder }: { folder: Folder }) => {
  const { t } = useSharedCollaborationTranslation();
  if (folder.status !== ContentStatus.Removed && folder.updateCount) {
    return <YellowChip tooltipMessage={`${t("ContentElementStatus.update", { count: folder.updateCount })}`} />;
  }
  return <YellowChip tooltipMessage={`${t(`ContentElementStatus.${folder.status}`)}`} />;
};
export const ReviewListItemChip = () => {
  const { t } = useSharedCollaborationTranslation();

  return (
    <DocumentChip
      label={t("TurnContentList.review")}
      icon={<IconReview fontSize={12} color="white" />}
      backgroundColor={TakeTurnsColors.otherParty}
    />
  );
};
