/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useSearchSupervisors } from "@taketurns-repositories/user/graphql/queries/useSearchSupervisors";

export const useSearchSupervisorsRule = () => {
  const { errorOnSearchingSupervisors, searchSupervisors, supervisors } = useSearchSupervisors();

  return { errorOnSearchingSupervisors, searchSupervisors, supervisors };
};
