/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Box, Skeleton, Stack } from "@mui/material";

export const UserAvatarLoadingIndicator = (props: { displayInfo?: boolean }) => {
  const { displayInfo } = props;
  if (displayInfo) {
    return (
      <Stack spacing={2} direction="row">
        <Box sx={{ display: "flex" }}>
          <Skeleton variant="circular" width={60} height={60} />
        </Box>
        <Stack direction="column" width={1} alignItems={"flex-start"}>
          <Skeleton variant="text" width={"100%"} />
          <Skeleton variant="text" width={"100%"} />
        </Stack>
      </Stack>
    );
  }
  return <Skeleton variant="circular" width={30} height={30} />;
};
