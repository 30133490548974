/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useMutation } from "@apollo/client";
import {
  AddAuthenticationMethodInput,
  MutationAddAuthenticationMethodArgs,
  Organization,
} from "@taketurns/taketurns-graphql-repository";

export const useAddAuthenticationMethod = () => {
  const mutation = gql`
    mutation addAuthenticationMethod($addAuthenticationMethodInput: AddAuthenticationMethodInput!) {
      addAuthenticationMethod(addAuthenticationMethodInput: $addAuthenticationMethodInput) {
        id
        authenticationMethods
      }
    }
  `;
  const [
    addAuthenticationMethodMutation,
    { error: errorOnAddAuthenticationMethod, loading: loadingAddAuthenticationMethod },
  ] = useMutation<{ addAuthenticationMethod: Organization }, MutationAddAuthenticationMethodArgs>(mutation);
  const addAuthenticationMethod = (addAuthenticationMethodInput: AddAuthenticationMethodInput) => {
    return addAuthenticationMethodMutation({ variables: { addAuthenticationMethodInput } });
  };
  return { addAuthenticationMethod, errorOnAddAuthenticationMethod, loadingAddAuthenticationMethod };
};
