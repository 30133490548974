/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Typography } from "@mui/material";
import { useSharedTranslation } from "@taketurns-i18n/shared/useSharedTranslation";

export const SomethingWentWrong = () => {
  const { t } = useSharedTranslation();
  return <Typography>{t("error.technical")}</Typography>;
};

export const SomethingWentWrongRetryLater = () => {
  const { t } = useSharedTranslation();
  return <Typography>{t("error.retryLater")}</Typography>;
};
