/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useQuery } from "@apollo/client";
import { SubscriptionPlan, SubscriptionPlanTier } from "@taketurns/taketurns-graphql-repository";

export const USER_ORGANIZATION_SUBSCRIPTION_TIER = gql`
  query userOrganizationSubscriptionTier {
    userSubscription {
      id
      tier
    }
  }
`;

export const useFetchUserOrganizationSubscriptionTier = () => {
  const {
    data,
    error: errorOnFetchingUserOrganizationSubscriptionTier,
    loading: loadingUserOrganizationSubscriptionTier,
  } = useQuery<{
    userSubscription: SubscriptionPlan;
  }>(USER_ORGANIZATION_SUBSCRIPTION_TIER, { fetchPolicy: "cache-and-network" });
  const userOrganizationSubscriptionTier = data?.userSubscription?.tier || SubscriptionPlanTier.T0;
  return {
    userOrganizationSubscriptionTier,
    errorOnFetchingUserOrganizationSubscriptionTier,
    loadingUserOrganizationSubscriptionTier,
  };
};
