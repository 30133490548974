/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Stack, Typography } from "@mui/material";
import { ReactNode } from "react";
import {
  SubscriptionPlanCurrency,
  SubscriptionPlanPeriod,
  SubscriptionPlanRegion,
} from "@taketurns/taketurns-graphql-repository";
import i18n from "@taketurns-i18n/i18n";
import { SubscriptionPlanForDisplay } from "@taketurns-repositories/user/local/entities/subscriptionPlanForDisplay";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { useGetSubscriptionPlanCurrencyRule } from "@taketurns-rules/user/queries/plan/getSubscriptionPlanCurrencyRule";
import { useGetSubscriptionPlanPeriodRule } from "@taketurns-rules/user/queries/plan/getSubscriptionPlanPeriodRule";
import { useGetSubscriptionPlanRegionRule } from "@taketurns-rules/user/queries/plan/getSubscriptionPlanRegionRule";

type PriceTypeKeys = "regular" | "discounted";

type PriceType = {
  month: { [key in PriceTypeKeys]: number | null };
  year: { [key in PriceTypeKeys]: number | null };
};

export const SubscriptionPlanElementPrice = ({
  subscriptionPlan,
}: {
  subscriptionPlan: SubscriptionPlanForDisplay;
}) => {
  const { discountedYearly, discountedMonthly } = subscriptionPlan;
  const selectedRegion = useGetSubscriptionPlanRegionRule();
  const selectedPaymentPeriod = useGetSubscriptionPlanPeriodRule();
  const currency = useGetSubscriptionPlanCurrencyRule();

  const pricing = getPricingFromCurrencyAndRegion(subscriptionPlan, currency, selectedRegion);

  const yearlySelectedPeriod = selectedPaymentPeriod === SubscriptionPlanPeriod.Year;
  const monthlySelectedPeriod = selectedPaymentPeriod === SubscriptionPlanPeriod.Month;
  const discountedOrRegularPrice: PriceTypeKeys =
    (monthlySelectedPeriod && discountedMonthly) || (yearlySelectedPeriod && discountedYearly)
      ? "discounted"
      : "regular";

  return (
    <Stack alignItems={"center"}>
      <Stack direction="row" spacing={1}>
        {yearlySelectedPeriod && discountedYearly && (
          <Pricing disabled>{formattedPricing(pricing.year["regular"]!, currency)}</Pricing>
        )}
        {monthlySelectedPeriod && discountedMonthly && (
          <Pricing disabled>{formattedPricing(pricing.month["regular"]!, currency)}</Pricing>
        )}
        <Pricing>{formattedPricing(pricing[selectedPaymentPeriod][discountedOrRegularPrice]!, currency)}</Pricing>
      </Stack>
    </Stack>
  );
};

const Pricing = ({ children, disabled }: { children: ReactNode; disabled?: boolean }) => {
  return (
    <Typography
      sx={disabled ? { textDecorationLine: "line-through" } : {}}
      color={disabled ? TakeTurnsColors.grayButton : undefined}
      fontSize="1.875rem"
      fontWeight={600}
    >
      {children}
    </Typography>
  );
};

const getPricingFromCurrencyAndRegion = (
  subscriptionPlan: SubscriptionPlanForDisplay,
  currency: SubscriptionPlanCurrency,
  region: SubscriptionPlanRegion,
): PriceType => {
  if (subscriptionPlan.plan.pricing) {
    const monthPricing = subscriptionPlan.plan.pricing.find(
      (p) => p.currency === currency && p.period === SubscriptionPlanPeriod.Month && p.region === region,
    );
    const yearPricing = subscriptionPlan.plan.pricing.find(
      (p) => p.currency === currency && p.period === SubscriptionPlanPeriod.Year && p.region === region,
    );
    return {
      month: {
        regular: monthPricing ? parseInt(monthPricing.amount, 10) : 0,
        discounted: monthPricing && monthPricing.specialDiscount ? parseInt(monthPricing.specialDiscount) : null,
      },
      year: {
        regular: yearPricing ? parseInt(yearPricing.amount, 10) / 12 : 0,
        discounted: yearPricing && yearPricing.specialDiscount ? parseInt(yearPricing.specialDiscount, 10) / 12 : null,
      },
    };
  }
  return { month: { regular: 0, discounted: null }, year: { regular: 0, discounted: 0 } };
};

const formattedPricing = (value: number, currency: SubscriptionPlanCurrency) => {
  return new Intl.NumberFormat(i18n.language, {
    currency: currency,
    style: "currency",
    maximumFractionDigits: 0,
  }).format(value / 100);
};
