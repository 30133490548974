/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { styled, TextField } from "@mui/material";
import { FormEvent, useRef, useState } from "react";
import { useCollaborationTranslation } from "@taketurns-i18n/collaboration/useCollaborationTranslation";
import { HelpCenterSections } from "@taketurns-rules/commons/enum/helpCenterSectionsEnum";
import { DESCRIPTION_FIELD_MAX_LENGTH, TEXT_FIELD_MAX_LENGTH } from "@taketurns-rules/commons/inputConstants";
import { useIsOnMobileRule } from "@taketurns-rules/commons/theme/useIsOnMobileRule";
import { TakeTurnsDefaultButton } from "../../../commons/button";
import { DialogFooter } from "../../../commons/dialog/DialogFooter";
import { ResponsiveDialog } from "../../../commons/dialog/ResponsiveDialog";
import { HelpCenterTextButton } from "../../../webapp/HelpCenterTextButton";

interface AddRequestDialogProps {
  open: boolean;
  onClose: () => void;
  addRequest: (requestName: string, requestDescription: string) => void;
}

export const AddRequestDialog = (props: AddRequestDialogProps) => {
  const { open, onClose, addRequest } = props;

  const isOnMobile = useIsOnMobileRule();
  const { t } = useCollaborationTranslation("collaborationDocument");

  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);

  const requestNameInputRef = useRef<HTMLInputElement | null>(null);

  const requestDescriptionInputRef = useRef<HTMLInputElement | null>(null);

  const addRequestAndCloseDialog = (submitEvent: FormEvent<HTMLFormElement | HTMLButtonElement>) => {
    submitEvent.preventDefault();
    addRequest(requestNameInputRef.current?.value, requestDescriptionInputRef.current?.value);
    onClose();
  };

  const disableButtonIfEmpty = (value: string) => {
    if (submitButtonDisabled === !!value.length) {
      setSubmitButtonDisabled(!submitButtonDisabled);
    }
  };

  return (
    <ResponsiveDialog
      disableRestoreFocus
      fullWidth
      open={open}
      title={t("newRequest")}
      content={
        <AddRequestDialog__FlexForm onSubmit={addRequestAndCloseDialog}>
          <TextField
            autoFocus
            fullWidth
            inputProps={{
              maxLength: TEXT_FIELD_MAX_LENGTH,
            }}
            inputRef={requestNameInputRef}
            placeholder={t("createFileRequestNamePlaceholder")}
            onChange={(e) => disableButtonIfEmpty(e.target.value)}
          />
          <TextField
            inputRef={requestDescriptionInputRef}
            multiline
            inputProps={{
              maxLength: DESCRIPTION_FIELD_MAX_LENGTH,
            }}
            rows={isOnMobile ? 5 : 3}
            fullWidth
            placeholder={t("createFileRequestDescriptionPlaceholder")}
          />
        </AddRequestDialog__FlexForm>
      }
      footer={
        <DialogFooter
          helpSection={<HelpCenterTextButton helpCenterSection={HelpCenterSections.ADD_REQUESTS} />}
          onClose={onClose}
        >
          <TakeTurnsDefaultButton disabled={submitButtonDisabled} type="submit" onClick={addRequestAndCloseDialog}>
            {t("add")}
          </TakeTurnsDefaultButton>
        </DialogFooter>
      }
    />
  );
};

const AddRequestDialog__FlexForm = styled("form")({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  gap: "16px",
});
