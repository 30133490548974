/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql } from "@apollo/client";
import { EditRequestOutput, SubscriptionOnEditRequestArgs } from "@taketurns/taketurns-graphql-repository";
import { useRefreshableSubscription } from "@taketurns-components/webapp/QueriesAndSubscriptionsRefreshTrigger";
import { requestEditMutationFragment } from "@taketurns-repositories/collaboration/graphql/mutations/content/requests/useModifyRequestDescription";

const SUBSCRIBE_TO_EDIT_REQUEST = gql`
  subscription onEditRequest($collaborationId: ID!, $partyId: ID!, $requestId: ID!) {
    onEditRequest(collaborationId: $collaborationId, partyId: $partyId, requestId: $requestId) {
      ...RequestEditMutationFragment
    }
  }
  ${requestEditMutationFragment}
`;

export const useSubscribeToEditRequest = (args: SubscriptionOnEditRequestArgs) => {
  return useRefreshableSubscription<{ onEditRequest: EditRequestOutput }, SubscriptionOnEditRequestArgs>(
    SUBSCRIBE_TO_EDIT_REQUEST,
    { variables: args },
  );
};
