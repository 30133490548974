/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql } from "@apollo/client";
import { RemoveAttachmentOutput, SubscriptionOnRemoveAttachmentArgs } from "@taketurns/taketurns-graphql-repository";
import { useRefreshableSubscription } from "@taketurns-components/webapp/QueriesAndSubscriptionsRefreshTrigger";
import { removeAttachmentMutationFragment } from "../../mutations/content/attachments/useRemoveAttachment";

const SUBSCRIBE_TO_REMOVE_ATTACHMENT = gql`
  subscription onRemoveAttachment($collaborationId: ID!, $partyId: ID!, $folderId: ID!) {
    onRemoveAttachment(collaborationId: $collaborationId, partyId: $partyId, folderId: $folderId) {
      ...RemoveAttachmentMutationFragment
    }
  }
  ${removeAttachmentMutationFragment}
`;

export const useSubscribeToRemoveAttachment = (args: SubscriptionOnRemoveAttachmentArgs) => {
  return useRefreshableSubscription<{ onRemoveAttachment: RemoveAttachmentOutput }, SubscriptionOnRemoveAttachmentArgs>(
    SUBSCRIBE_TO_REMOVE_ATTACHMENT,
    {
      variables: args,
    },
  );
};
