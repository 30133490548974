/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { AuthenticationMethod } from "@taketurns/taketurns-graphql-repository";
import { useOrganizationTranslation } from "@taketurns-i18n/user/Organization/useOrganizationTranslation";
import { useRemoveAuthenticationMethod } from "@taketurns-repositories/user/graphql/mutations/useRemoveAuthenticationMethod";
import { useDisplayAndLogNotificationErrorRule } from "./useDisplayAndLogNotificationErrorRule";

export const useRemoveAuthenticationMethodRule = () => {
  const {
    removeAuthenticationMethod: removeAuthenticationMethodCommand,
    errorOnRemoveAuthenticationMethod,
    loadingRemoveAuthenticationMethod,
  } = useRemoveAuthenticationMethod();
  const { t } = useOrganizationTranslation();

  const displayAndLogNotificationError = useDisplayAndLogNotificationErrorRule();
  const removeAuthenticationMethod = (authenticationMethod: AuthenticationMethod) => {
    removeAuthenticationMethodCommand({ authenticationMethod }).catch(() => displayAndLogNotificationError(t("error")));
  };

  return { removeAuthenticationMethod, errorOnRemoveAuthenticationMethod, loadingRemoveAuthenticationMethod };
};
