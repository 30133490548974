/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useValidateCollaborationNameRule } from "@taketurns-rules/collaboration/commands/collaborationCreation/form/mobile/useValidateCollaborationNameRule";

export const useValidateMainFormRule = () => {
  const validateCollaborationName = useValidateCollaborationNameRule();
  return () => {
    const isCollaborationNameValid = validateCollaborationName();

    return { isValid: isCollaborationNameValid };
  };
};
