/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useGetConnectedUserPartyId } from "@taketurns-repositories/collaboration/context/useGetConnectedUserPartyId";
import { useSubscribeToRenameFolder } from "@taketurns-repositories/collaboration/graphql/subscriptions/content/useSubscribeToRenameFolder";
import { useGetDisplayedCollaborationIdRule } from "@taketurns-rules/collaboration/queries/collaboration/useGetDisplayedCollaborationIdRule";

export const useSubscribeToFolderChangesRule = (folderId: string) => {
  const displayedCollaborationId = useGetDisplayedCollaborationIdRule();
  const connectedUserPartyId = useGetConnectedUserPartyId();
  useSubscribeToRenameFolder({ collaborationId: displayedCollaborationId, partyId: connectedUserPartyId, folderId });
};
