/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { styled, Typography } from "@mui/material";
import { Fragment, PropsWithChildren } from "react";
import { useUserPlanTranslation } from "@taketurns-i18n/user/UserPlans/useUserPlanTranslation";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { TakeTurnsTextButton } from "../../../commons/button";

export const SubscribeToPlanLink = ({
  children,
  onClick,
}: PropsWithChildren<{
  onClick: () => unknown;
}>) => {
  const { t } = useUserPlanTranslation();

  return (
    <Fragment>
      <SubscribeToPlanButton__Or>{t("buttons.or")}</SubscribeToPlanButton__Or>&nbsp;
      <SubscribeToPlanButton__Link onClick={onClick}>{children}</SubscribeToPlanButton__Link>
    </Fragment>
  );
};

const SubscribeToPlanButton__Or = styled(Typography)({
  display: "inline",
  fontSize: 14,
  fontWeight: 600,
  color: TakeTurnsColors.lightBlue,
});

const SubscribeToPlanButton__Link = styled(TakeTurnsTextButton)({
  display: "inline",
  fontSize: 14,
  fontWeight: 600,
  textDecoration: "underline",
});
