/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { IconButton, IconButtonProps } from "@mui/material";
import { HelpCenterSections } from "@taketurns-rules/commons/enum/helpCenterSectionsEnum";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { useOpenHelpCenterSection } from "@taketurns-rules/webapp/commands/useOpenHelpCenterSection";
import { IconHelpCircle } from "../commons/icons";

interface HelpCenterIconButtonProps {
  helpCenterSection: HelpCenterSections;
}

export const HelpCenterIconButton = (props: IconButtonProps & HelpCenterIconButtonProps) => {
  const { helpCenterSection, ...iconButtonProps } = props;
  const { openHelpCenterSection } = useOpenHelpCenterSection(helpCenterSection);

  return (
    <IconButton
      {...iconButtonProps}
      sx={{ color: TakeTurnsColors.mediumGray }}
      disableRipple
      onClick={openHelpCenterSection}
    >
      <IconHelpCircle />
    </IconButton>
  );
};
