/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Box, Divider, List, Skeleton, Stack, Typography } from "@mui/material";
import { Fragment, ReactElement, useEffect } from "react";
import { ConnectedUser } from "@taketurns/taketurns-graphql-repository";
import { UserPasswordForm } from "@taketurns-components/user/UserProfile/UserPasswordForm";
import { useUserSharedTranslation } from "@taketurns-i18n/user/useUserSharedTranslation";
import { JobFunction } from "@taketurns-rules/commons/enum/jobFunctionEnum";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { useModifyConnectedUserRule } from "@taketurns-rules/user/commands/useModifyConnectedUserRule";
import { useFetchConnectedUserRule } from "@taketurns-rules/user/queries/useFetchConnectedUserRule";
import { useFetchIsConnectedUserPasswordAlreadySetRule } from "@taketurns-rules/user/queries/useFetchIsConnectedUserPasswordAlreadySetRule";
import { useUserSettingsFormValidationRule } from "@taketurns-rules/user/validation/userSettingsFormValidationRule";
import { LabelWithEditButton } from "../../commons/form/LabelWithEditButton";
import { JobFunctionSelector } from "../../commons/select/JobFunctionSelector";
import { FormListItem } from "../shared/FormListItem";
import { UserPictureEdit } from "./UserPictureEdit";

interface UserSettingsProps {
  header?: ReactElement;
  userFormIsValid?: (isValid: boolean) => void;
  userCanChangePassword?: boolean;
  useDefaultHeader?: boolean;
}

/**
 * User setting exposed component
 * Allowing user to change their profiles
 * @returns
 */
export const UserSettings = (props: UserSettingsProps) => {
  const { connectedUser, errorOnFetchingConnectedUser, loadingConnectedUser } = useFetchConnectedUserRule();
  const { getUserEditableFields, modifyUser } = useModifyConnectedUserRule();

  const { t } = useUserSharedTranslation();
  const { validateUserSettingsForm } = useUserSettingsFormValidationRule();

  const { header, useDefaultHeader, userFormIsValid, userCanChangePassword } = props;

  useEffect(() => {
    if (connectedUser && userFormIsValid) {
      userFormIsValid(validateUserSettingsForm(connectedUser));
    }
  }, [connectedUser, userFormIsValid, validateUserSettingsForm]);

  if (errorOnFetchingConnectedUser) {
    console.error(errorOnFetchingConnectedUser.message);
    return <Typography>Something went wrong, we are on it !</Typography>;
  }

  if (loadingConnectedUser) {
    return (
      <List>
        {[...Array(5)].map((_, index) => (
          <Skeleton key={index} variant="text" />
        ))}
      </List>
    );
  }

  const UserSettingHeader = () => {
    if (!useDefaultHeader && !header) {
      return null;
    }
    if (!useDefaultHeader && header) {
      return header;
    }
    return <Typography fontSize="16">{t("UserSettings.profile")}</Typography>;
  };

  const UserFirstName = () => (
    <FormListItem label={t("UserSettings.firstname")} error={!connectedUser.firstName}>
      <LabelWithEditButton
        required
        fontSize={"0.875rem"}
        currentLabel={connectedUser.firstName}
        displayEdit={!connectedUser.firstName}
        onEditEnded={(value) =>
          modifyUser(connectedUser, {
            ...getUserEditableFields(connectedUser),
            firstName: value,
          })
        }
      />
    </FormListItem>
  );

  const UserLastName = () => (
    <FormListItem label={t("UserSettings.lastname")} error={!connectedUser.lastName}>
      <LabelWithEditButton
        required
        noFocusOnFirstMount={!connectedUser.firstName}
        fontSize={"0.875rem"}
        currentLabel={connectedUser.lastName}
        displayEdit={!connectedUser.lastName}
        onEditEnded={(value) =>
          modifyUser(connectedUser, {
            ...getUserEditableFields(connectedUser),
            lastName: value,
          })
        }
      />
    </FormListItem>
  );

  const UserJobTitle = () => (
    <FormListItem label={t("UserSettings.jobTitle")}>
      <LabelWithEditButton
        fontSize={"0.875rem"}
        currentLabel={connectedUser.jobTitle}
        onEditEnded={(value) =>
          modifyUser(connectedUser, {
            ...getUserEditableFields(connectedUser),
            jobTitle: value,
          })
        }
      />
    </FormListItem>
  );

  const UserFunction = () => {
    const errorOnJobFunction = connectedUser.function === null;
    return (
      <FormListItem label={t("UserSettings.function")} error={errorOnJobFunction}>
        <JobFunctionSelector
          sx={{ minWidth: 100, width: { xs: "100%", md: "initial" } }}
          error={errorOnJobFunction}
          defaultValue={connectedUser.function as JobFunction}
          errorOnRequired={errorOnJobFunction}
          onChange={(value) =>
            modifyUser(connectedUser, {
              ...getUserEditableFields(connectedUser),
              function: value,
            })
          }
        />
      </FormListItem>
    );
  };

  const UserEmail = () => (
    <FormListItem label={t("UserSettings.primaryEmail")}>
      <Typography fontSize={14} padding={{ xs: "10px", md: "0px" }}>
        {connectedUser.email}
      </Typography>
    </FormListItem>
  );

  return (
    <Fragment>
      <UserSettingHeader />
      <Stack direction={{ xs: "column-reverse", md: "row" }} spacing={3}>
        <Box flex="1">
          <List disablePadding>
            <Divider />
            <UserFirstName />
            <UserLastName />
            <UserFunction />
            <UserJobTitle />
            <UserEmail />
          </List>
          {userCanChangePassword && <UserPasswordSection connectedUser={connectedUser} />}
        </Box>
        <Box>
          <UserPictureEdit connectedUser={connectedUser} />
        </Box>
      </Stack>
    </Fragment>
  );
};

const UserPasswordSection = (props: { connectedUser: ConnectedUser }) => {
  const { t } = useUserSharedTranslation();
  const {
    isConnectedPasswordAlreadySet,
    loadingIsConnectedUserPasswordAlreadySet,
    errorOnIsConnectedUserPasswordAlreadySet,
  } = useFetchIsConnectedUserPasswordAlreadySetRule();

  if (errorOnIsConnectedUserPasswordAlreadySet) {
    console.error(errorOnIsConnectedUserPasswordAlreadySet);
    return;
  }

  const i18nCategory = isConnectedPasswordAlreadySet ? "UserSettings.changePassword" : "UserSettings.setPassword";

  return (
    <Stack spacing={2} mt={6}>
      <Typography fontSize="1rem">{t(`${i18nCategory}.title`)}</Typography>
      <Typography fontSize="0.875rem" color={TakeTurnsColors.darkGray}>
        {t(`${i18nCategory}.description`)}
      </Typography>
      <Box flex="1">
        <Divider />
        <FormListItem label={t("UserSettings.password")}>
          {loadingIsConnectedUserPasswordAlreadySet && <Skeleton variant="text" width={"100%"} />}
          {!loadingIsConnectedUserPasswordAlreadySet && (
            <UserPasswordForm
              isConnectedUserPasswordAlreadySet={isConnectedPasswordAlreadySet}
              connectedUser={props.connectedUser}
            />
          )}
        </FormListItem>
      </Box>
    </Stack>
  );
};
