/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Box, styled, Typography } from "@mui/material";
import { ReactNode } from "react";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";

const DownloadOptionContainer = styled("div")({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  width: "100%",
  minHeight: 50,
  borderBottom: `1px solid ${TakeTurnsColors.lightGray}`,
});

export const ArchiveDownloadOption = ({ optionLabel, option }: { optionLabel: string; option: ReactNode }) => {
  return (
    <DownloadOptionContainer>
      <Box sx={{ flex: "0 0 200px" }}>
        <Typography fontSize={14} color={TakeTurnsColors.darkGray}>
          {optionLabel}
        </Typography>
      </Box>
      <Box sx={{ flex: 1, display: "flex", alignItems: "center" }}>{option}</Box>
    </DownloadOptionContainer>
  );
};
