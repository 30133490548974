/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useIsInCollaborationViewRule } from "./useIsInCollaborationViewRule";

export const useIsInCollaborationCreationRule = () => {
  const isInCollaborationView = useIsInCollaborationViewRule();
  return !isInCollaborationView;
};
