/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Box, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getUserSettingsSectionUrl, INTEGRATIONS_SECTION } from "@taketurns-app/routing/routes/userSettingsSections";
import { AiAssistantButton } from "@taketurns-components/collaboration/CollaborationView/AttachmentDetails/components/DesktopAttachmentDetails/AttachmentAiAssistant/AiAssistantButton";
import { useSharedCollaborationTranslation } from "@taketurns-i18n/collaboration/shared/useSharedCollaborationTranslation";

export const AiAssistantNotConfigured = () => {
  const { t } = useSharedCollaborationTranslation();
  return (
    <Stack
      flex={1}
      gap={6}
      alignItems="center"
      justifyContent="center"
      px={{ xs: 3, lg: 17 }}
      data-cy="AiAssistantNotConfigured"
    >
      <Box>
        <Typography align="center">{t("AttachmentAiAssistant.AiAssistantNotConfigured.text1")}</Typography>
        <Typography align="center">{t("AttachmentAiAssistant.AiAssistantNotConfigured.text2")}</Typography>
      </Box>
      <LinkAccountButton />
    </Stack>
  );
};
const LinkAccountButton = () => {
  const navigate = useNavigate();
  const redirectToSettings = () => {
    navigate(getUserSettingsSectionUrl(INTEGRATIONS_SECTION));
  };
  const { t } = useSharedCollaborationTranslation();
  return (
    <AiAssistantButton
      onClick={redirectToSettings}
      label={t("AttachmentAiAssistant.AiAssistantNotConfigured.LinkAccountButtonLabel")}
    />
  );
};
