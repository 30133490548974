/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Attachment } from "@taketurns/taketurns-graphql-repository";
import { useSetOpenedAttachmentDetailsDocumentId } from "@taketurns-repositories/collaboration/state/write/useSetOpenedAttachmentDetailsDocumentId";
import { useUploadAttachmentRevisionRule } from "../../attachmentRevision/useUploadAttachmentRevisionRule";

export const useUploadRevisionBeforeOpeningAttachmentDetailsRule = (attachment: Attachment) => {
  const addAttachmentRevisionRule = useUploadAttachmentRevisionRule(attachment);
  const setOpenedDocumentDetailsDocumentId = useSetOpenedAttachmentDetailsDocumentId();

  return async (documentRevisionFile: File) => {
    await addAttachmentRevisionRule(documentRevisionFile);
    setOpenedDocumentDetailsDocumentId(attachment.id);
  };
};
