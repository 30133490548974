/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useLoaderData, useNavigate } from "react-router-dom";
import { Recap } from "@taketurns-components/collaboration/Recap/Recap/Recap";
import { RecapHeader } from "@taketurns-components/collaboration/Recap/RecapHeader";
import { CollaborationRecapContext } from "@taketurns-repositories/collaboration/context/CollaborationRecapContext";
import { SingleColumnLayout } from "../../../layouts/body/content/SingleColumnLayout";

export const CollaborationRecapPage = () => {
  const { collaborationId } = useLoaderData() as { collaborationId: string };
  const navigateBack = useNavigateBack();
  return (
    <CollaborationRecapContext.Provider value={{ collaborationId }}>
      <SingleColumnLayout backAction={navigateBack} header={<RecapHeader />} content={<Recap />} />
    </CollaborationRecapContext.Provider>
  );
};

function useNavigateBack() {
  const navigate = useNavigate();
  return () => {
    navigate(-1);
  };
}
