/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Navigate } from "react-router-dom";
import { useFetchUserHasCreateCollaborationFeatureRule } from "@taketurns-rules/collaboration/queries/useFetchUserHasCreateCollaborationFeatureRule";
import { AUTHENTICATED_ROUTES } from "../routes/authenticatedRoutes.constants";

export const CreateCollaborationFeatureGuard = ({ children }: { children: JSX.Element }) => {
  const {
    errorOnFetchingCreateCollaborationUserFeature,
    hasCreateCollaborationFeature,
    loadingCreateCollaborationUserFeature,
  } = useFetchUserHasCreateCollaborationFeatureRule();
  if (loadingCreateCollaborationUserFeature) {
    return null;
  }
  if (errorOnFetchingCreateCollaborationUserFeature) {
    console.error(errorOnFetchingCreateCollaborationUserFeature);
    return null;
  }
  if (!hasCreateCollaborationFeature) {
    return <Navigate to={AUTHENTICATED_ROUTES.COLLABORATION_LIST} />;
  }
  return children;
};
