/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { DocumentChip } from "@taketurns-components/collaboration/Shared/chip/DocumentChip";
import { IconRequest } from "@taketurns-components/commons/icons";
import { useSharedCollaborationTranslation } from "@taketurns-i18n/collaboration/shared/useSharedCollaborationTranslation";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";

export const RequestUploadChip = () => {
  const { t } = useSharedCollaborationTranslation();

  return (
    <DocumentChip
      label={t("RequestListItem.action")}
      icon={<IconRequest fontSize={12} color="white" />}
      backgroundColor={TakeTurnsColors.otherParty}
    />
  );
};
