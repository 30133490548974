/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Box, styled, TextField, TextFieldProps } from "@mui/material";
import { useState } from "react";
import { CreateInviteeInput, Party, Role } from "@taketurns/taketurns-graphql-repository";
import { useCollaborationCreationTranslation } from "@taketurns-i18n/collaboration/CollaborationCreation/useCollaborationCreationTranslation";
import { useInviteToCollaborationInCreationRule } from "@taketurns-rules/collaboration/commands/collaborationCreation/invitation/useInviteToCollaborationInCreationRule";
import { useGetCollaborationFormErrorsRule } from "@taketurns-rules/collaboration/queries/collaborationCreation/form/useGetCollaborationFormErrorsRule";
import { useGetCollaborationFormInvitationsRule } from "@taketurns-rules/collaboration/queries/collaborationCreation/form/useGetCollaborationFormInvitationsRule";
import { TakeTurnsDefaultButton } from "../../../commons/button";
import { UserContactsAutoComplete } from "../../../user/UserContact/UserContactsAutoComplete";

const invitationDefaultValue = {
  email: "",
  party: Party.Invited,
  role: Role.Owner,
};
export const RecipientField = () => {
  const [newInvitation, setNewInvitation] = useState<CreateInviteeInput>(invitationDefaultValue);
  const invitations = useGetCollaborationFormInvitationsRule();

  const { t } = useCollaborationCreationTranslation();

  const inviteToCollaboration = useInviteToCollaborationInCreationRule();
  const collaborationFormErrors = useGetCollaborationFormErrorsRule();

  const addUserToFileSharingRecipientsAndClearInvitation = () => {
    inviteToCollaboration(newInvitation);
    setNewInvitation(() => invitationDefaultValue);
  };

  const existingEmailUserIdFromInvitationList = invitations.map((invitation) => invitation.email || invitation.userId);

  return (
    <RecipientField__Container>
      <UserContactsAutoComplete
        sx={{
          flex: 1,
        }}
        disabledUserEmailsAndIds={existingEmailUserIdFromInvitationList}
        renderInput={(params) => (
          <TextField
            {...(params as TextFieldProps)}
            variant="outlined"
            placeholder={t("RecipientField.placeholder")}
            error={collaborationFormErrors.invitations}
            InputProps={{
              ...params.InputProps,
            }}
          />
        )}
        onContactChange={(contact) => {
          setNewInvitation((oldValue) => ({
            ...oldValue,
            email: contact?.email ?? "",
            connectedUser: contact?.user,
          }));
        }}
      />
      <TakeTurnsDefaultButton
        onClick={addUserToFileSharingRecipientsAndClearInvitation}
        disabled={newInvitation.email.length === 0}
      >
        {t("RecipientField.addButton")}
      </TakeTurnsDefaultButton>
    </RecipientField__Container>
  );
};

const RecipientField__Container = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "12px",

  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    alignItems: "stretch",
    gap: "8px",
  },
}));
