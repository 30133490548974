/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useSetRecoilState } from "recoil";
import { selectCollaborationFormName } from "../../../recoil/collaborationCreation/form/values/selectCollaborationFormName";

export const useSetCollaborationFormName = () => {
  const setCollaborationFormName = useSetRecoilState(selectCollaborationFormName);
  return (name: string) => {
    setCollaborationFormName(name);
  };
};
