/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useMutation } from "@apollo/client";
import { CollaborationUserContext, MutationHideCollaborationArgs } from "@taketurns/taketurns-graphql-repository";

export const useHideCollaboration = () => {
  const mutation = gql`
    mutation hideCollaboration($collaborationId: ID!) {
      hideCollaboration(collaborationId: $collaborationId) {
        id
        isHidden
      }
    }
  `;
  return useMutation<{ hideCollaboration: CollaborationUserContext }, MutationHideCollaborationArgs>(mutation);
};
