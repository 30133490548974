/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useFetchPendingInvitationsCount } from "@taketurns-repositories/collaboration/graphql/queries/participants/useFetchPendingInvitationsCount";

export const useFetchPendingInvitationsCountRule = () => {
  const { pendingInvitationsCount, loadingPendingInvitationsCount, errorOnFetchingPendingInvitationsCount } =
    useFetchPendingInvitationsCount();
  return {
    pendingInvitationsCount,
    loadingPendingInvitationsCount,
    errorOnFetchingPendingInvitationsCount,
  };
};
