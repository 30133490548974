/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useRecoilValue } from "recoil";
import { selectCollaborationFormStartNote } from "../../../recoil/collaborationCreation/form/values/selectCollaborationFormStartNote";

export const useGetCollaborationFormStartNote = () => {
  return useRecoilValue(selectCollaborationFormStartNote);
};
