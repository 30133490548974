/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useRecoilValue } from "recoil";
import { contentSelectionState } from "@taketurns-repositories/collaboration/state/recoil/contentSelectionState";

export const useGetSelectedContent = () => {
  const contentSelectionStateValue = useRecoilValue(contentSelectionState);
  return {
    folderIds: contentSelectionStateValue.folderIds,
    attachmentIds: contentSelectionStateValue.attachmentIds,
  };
};
