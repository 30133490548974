/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useFetchParticipantsAndInvitations } from "@taketurns-repositories/collaboration/graphql/queries/participants/useFetchParticipantsAndInvitations";
import { useGetDisplayedCollaborationIdRule } from "@taketurns-rules/collaboration/queries/collaboration/useGetDisplayedCollaborationIdRule";

export const useFetchParticipantsAndInvitationsRule = (collaborationId?: string, cacheOnly?: boolean) => {
  const displayedCollaborationId = useGetDisplayedCollaborationIdRule();
  return useFetchParticipantsAndInvitations(collaborationId ?? displayedCollaborationId, cacheOnly);
};
