/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Chip, Skeleton, Stack, Typography } from "@mui/material";
import { Party } from "@taketurns/taketurns-graphql-repository";
import { FetchLoadingManager } from "@taketurns-app/utils/FetchLoadingManager";
import { SomethingWentWrong } from "@taketurns-components/shared/SomethingWentWrong";
import { useFetchConnectedUserPartyRule } from "@taketurns-rules/collaboration/queries/context/useFetchConnectedUserPartyRule";
import { useFetchCollaborationPartyNamesRule } from "@taketurns-rules/collaboration/queries/fields/useFetchCollaborationPartyNamesRule";
import { getColorForUserParty } from "@taketurns-rules/collaboration/utils/color/TakeTurnsBlueColorsUtils";

interface CollaborationPartiesProps {
  prefixText?: string;
  collaborationId: string;
}

function useFetchCollaborationPartiesData(collaborationId: string) {
  const { invitedPartyName, owningPartyName, loadingCollaborationPartyNames, errorOnFetchingCollaborationPartyNames } =
    useFetchCollaborationPartyNamesRule(collaborationId);
  const { connectedUserParty, errorOnFetchingConnectedUserParty, loadingConnectedUserParty } =
    useFetchConnectedUserPartyRule(collaborationId);

  const fetchLoadingManager = new FetchLoadingManager(
    [loadingCollaborationPartyNames, loadingConnectedUserParty],
    [errorOnFetchingCollaborationPartyNames, errorOnFetchingConnectedUserParty],
  );
  return {
    connectedUserParty,
    invitedPartyName,
    owningPartyName,
    loading: fetchLoadingManager.hasFetchLoading(),
    error: fetchLoadingManager.hasFetchInError(),
  };
}

export const CollaborationParties = ({ prefixText, collaborationId }: CollaborationPartiesProps) => {
  const { connectedUserParty, invitedPartyName, owningPartyName, loading, error } =
    useFetchCollaborationPartiesData(collaborationId);

  if (error) {
    return <SomethingWentWrong />;
  }

  return (
    <Stack direction="row" spacing={{ xs: 1, md: 2 }} alignItems="center">
      {prefixText && <Typography>{prefixText}</Typography>}
      <PartyChip
        name={owningPartyName}
        party={Party.Owning}
        connectedUserParty={connectedUserParty}
        loading={loading}
      />
      <Typography>&</Typography>
      <PartyChip
        name={invitedPartyName}
        party={Party.Invited}
        connectedUserParty={connectedUserParty}
        loading={loading}
      />
    </Stack>
  );
};

const PartyChip = (props: { name: string; party: Party; connectedUserParty: Party; loading: boolean }) => {
  if (props.loading) {
    return <Skeleton variant="text" width={100} />;
  }

  return (
    <Chip
      sx={{ minWidth: 0 }}
      color={getColorForUserParty(props.party, props.connectedUserParty).themeColor}
      label={props.name}
    />
  );
};
