/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { EmailValidator } from "commons-validator-js";
import { ChangeEvent } from "react";
import { DESCRIPTION_FIELD_MAX_LENGTH, TEXT_FIELD_MAX_LENGTH } from "../../inputConstants";

/**
 * Validate an email from default email pattern
 * This is not an email server verification !
 * @param {string|undefined} email
 * @return {boolean} True is email is valid and not undefined, false if not
 */
export const validateEmail = (email: string | undefined): boolean => {
  const apacheValidator = new EmailValidator();
  if (!email) return false;
  return apacheValidator.isValid(email);
};

export const withMaxLengthControl = (
  event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  callback: (value: string) => void,
  isDescription?: boolean,
  customMaxLength?: number,
) => {
  const value = event.target.value;
  const maxLength = customMaxLength ?? isDescription ? DESCRIPTION_FIELD_MAX_LENGTH : TEXT_FIELD_MAX_LENGTH;
  if (value.length >= maxLength) {
    return;
  }
  callback(value);
};
