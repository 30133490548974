/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Box, styled, SxProps } from "@mui/material";
import { Attachment, ContentStatus, Folder, Request } from "@taketurns/taketurns-graphql-repository";
import { CardViewBreadcrumb } from "@taketurns-components/collaboration/CollaborationView/CollaborationContent/CardView/CardViewBreadcrumb";
import { useIsCollaborationClosedRule } from "@taketurns-rules/collaboration/queries/collaborationView/useIsCollaborationClosedRule";
import { GenericCard } from "./GenericCard";
import { Card } from "./models/card";
import { CardType } from "./models/cardType";

interface CardViewProps {
  backBreadcrumbLabel: string;
  attachments?: Attachment[];
  requests?: Request[];
  folders?: Folder[];
  displayUpdatedOnly?: boolean;
  editable: boolean;
  dropZoneConfigBuilder?: (folderId: string) => {
    sx: SxProps;
    multiple: boolean;
    handleFilesDropping: (files: File[]) => void;
    className: string;
  };
}

export const CardView = (props: CardViewProps) => {
  const { isCollaborationClosed } = useIsCollaborationClosedRule();

  let cards: Card[] = [];
  props.folders
    ?.filter((subFolder) => !props.displayUpdatedOnly || subFolder.prevStatus !== ContentStatus.Unchanged)
    ?.map((subFolder) => {
      return (cards = [
        ...cards,
        {
          editable: props.editable,
          key: subFolder.id,
          label: subFolder.name,
          type: CardType.folder,
          folderId: subFolder.id,
          dropZoneConfig: props.dropZoneConfigBuilder(subFolder.id),
        },
      ]);
    });
  props.attachments
    ?.filter((attachment) => !props.displayUpdatedOnly || attachment.prevStatus !== ContentStatus.Unchanged)
    ?.map(
      (attachment) =>
        (cards = [
          ...cards,
          {
            editable: props.editable,
            key: attachment.id,
            label: attachment.document.name,
            type: CardType.attachment,
            attachmentId: attachment.id,
          },
        ]),
    );
  props.requests
    ?.filter(
      (request) =>
        !isCollaborationClosed && (!props.displayUpdatedOnly || request.prevStatus !== ContentStatus.Unchanged),
    )
    ?.map(
      (request) =>
        (cards = [
          ...cards,
          {
            request,
            editable: props.editable,
            key: request.name,
            label: request.name,
            type: CardType.request,
          },
        ]),
    );

  cards.sort((a, b) =>
    a.label?.toLowerCase() < b.label?.toLowerCase() ? -1 : b.label?.toLowerCase() > a.label?.toLowerCase() ? 1 : 0,
  );

  return (
    <CardView__Container className="CardView__Container">
      <CardViewBreadcrumb backBreadcrumbLabel={props.backBreadcrumbLabel} />
      <CardView__ContentContainer className="CardView__ContentContainer">
        {cards.map((card: Card) => (
          <GenericCard key={card.key} card={card} />
        ))}
      </CardView__ContentContainer>
    </CardView__Container>
  );
};

const CardView__Container = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: 24,
  paddingTop: "10px",
});

const CardView__ContentContainer = styled(Box)({
  flexGrow: 1,
  display: "flex",
  flexWrap: "wrap",
  alignContent: "flex-start",
  gap: 16,
  paddingBottom: 50,
  minHeight: 0,
});
