/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { TipKey } from "@taketurns-i18n/commons/en/tips";
import { useHideTip } from "@taketurns-repositories/user/graphql/mutations/useHideTip";

export const useHideTipRule = () => {
  const [hideTipMutation, { data, loading, error }] = useHideTip();

  const hideTipCommand = (tipKey: TipKey) =>
    hideTipMutation({
      variables: {
        tipKey,
      },
    });
  return {
    hideTipCommand,
    hideTipCommandResult: data?.hideTip,
    hideTipCommandLoading: loading,
    hideTipCommandError: error,
  };
};
