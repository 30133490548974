/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useLazyQuery } from "@apollo/client";
import i18n from "@taketurns-i18n/i18n";

const GET_STRIPE_PORTAL_URL = gql`
  query stripePortalUrl($language: String!) {
    stripePortalUrl(language: $language)
  }
`;

export const useLazyFetchStripePortalUrl = () => {
  return useLazyQuery<{ stripePortalUrl: string }>(GET_STRIPE_PORTAL_URL, {
    variables: { language: i18n.language },
  });
};
