/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { atom } from "recoil";

export interface AttachmentBulk {
  numberOfAttachments: number;
  uploadIds: number[];
}

export const attachmentBulksState = atom<AttachmentBulk[]>({
  key: "AttachmentBulks",
  default: [],
});
