/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { FetchLoadingManager } from "@taketurns-app/utils/FetchLoadingManager";
import { useFetchEventOnCollaboration } from "@taketurns-repositories/collaboration/graphql/queries/fields/useFetchEventOnCollaboration";

export const useEventNoteDialogTitleAndContentDataRule = (collaborationId: string, eventId: string) => {
  const { eventOnCollaboration, loadingEventOnCollaboration, errorOnFetchingEventOnCollaboration } =
    useFetchEventOnCollaboration(collaborationId, eventId);

  const fetchLoadingManager = new FetchLoadingManager(
    [loadingEventOnCollaboration],
    [errorOnFetchingEventOnCollaboration],
  );

  return {
    event: eventOnCollaboration,
    loadingEventNoteDialogTitleAndContentData: fetchLoadingManager.hasFetchLoading(),
    errorOnFetchingEventNoteDialogTitleAndContentData: fetchLoadingManager.hasFetchInError(),
  };
};
