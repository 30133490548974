/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { SubscriptionPlanStatus } from "@taketurns/taketurns-graphql-repository";
import { useFetchUserSubscriptionPlanTrial } from "@taketurns-repositories/user/graphql/queries/useFetchUserSubscriptionPlanTrial";
import { useFetchUserHasOrganizationAdminFeatureRule } from "./useFetchUserHasOrganizationAdminFeatureRule";

export const useIsTrialRemainingTimeNotificationDisplayedRule = () => {
  const { userSubscriptionPlanTrial, loadingUserSubscriptionPlanTrial, errorOnUserSubscriptionPlanTrial } =
    useFetchUserSubscriptionPlanTrial();
  const { userHasOrganizationAdminFeature, loadingHasOrganizationAdminFeature, errorOnHasOrganizationAdminFeature } =
    useFetchUserHasOrganizationAdminFeatureRule();

  const errorOnUserSubscriptionPlanTrialOrHasOrganizationAdminFeature =
    errorOnUserSubscriptionPlanTrial || errorOnHasOrganizationAdminFeature;
  if (errorOnUserSubscriptionPlanTrialOrHasOrganizationAdminFeature) {
    console.error(errorOnUserSubscriptionPlanTrialOrHasOrganizationAdminFeature);
  }
  const loadingUserSubscriptionPlanTrialOrHasOrganizationAdminFeature =
    loadingUserSubscriptionPlanTrial || loadingHasOrganizationAdminFeature;
  if (
    (userSubscriptionPlanTrial?.status === SubscriptionPlanStatus.Trialing ||
      userSubscriptionPlanTrial?.status === SubscriptionPlanStatus.TrialExpired) &&
    userHasOrganizationAdminFeature
  ) {
    return {
      isTrialRemainingTimeNotificationDisplayed: true,
      userSubscriptionPlanTrial,
      loadingIsTrialRemainingTimeNotificationDisplayed: loadingUserSubscriptionPlanTrialOrHasOrganizationAdminFeature,
    };
  }
  return {
    isTrialRemainingTimeNotificationDisplayed: false,
    userSubscriptionPlanTrial: undefined,
    loadingIsTrialRemainingTimeNotificationDisplayed: loadingUserSubscriptionPlanTrialOrHasOrganizationAdminFeature,
  };
};
