/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Fragment, useState } from "react";
import { PrepareArchiveDownloadInput } from "@taketurns/taketurns-graphql-repository";
import { TakeTurnsDialogFooter } from "@taketurns-components/collaboration/Shared/content/TakeTurnsDialogFooter";
import { useCollaborationArchiveDownloadTranslation } from "@taketurns-i18n/collaboration/CollaborationArchiveDownload/useCollaborationArchiveDownloadTranslation";
import { TakeTurnsDefaultButton } from "../../commons/button";
import { ResponsiveDialog } from "../../commons/dialog/ResponsiveDialog";
import { IncludeChatMessageOption } from "./archiveDownloadOption/IncludeChatMessageOption";
import { TurnContentOption, TurnContentOptions } from "./archiveDownloadOption/TurnContentOption";

interface CollaborationArchiveDownloadDialogProps {
  openDialog: boolean;
  onCloseDialog: () => void;
  onClickDownloadArchive: (downloadArchiveOptions: Partial<PrepareArchiveDownloadInput>) => void;
}

export const CollaborationArchiveDownloadDialog = (props: CollaborationArchiveDownloadDialogProps) => {
  const [downloadArchiveOptions, setDownloadArchiveOptions] = useState<Partial<PrepareArchiveDownloadInput>>({
    downloadChatMessages: false,
    downloadAllTurns: false,
  });

  const { t } = useCollaborationArchiveDownloadTranslation();

  return (
    <ResponsiveDialog
      open={props.openDialog}
      title={t("downloadArchiveDialogTitle")}
      fullWidth
      content={
        <Fragment>
          <TurnContentOption
            defaultSelectedOption={
              downloadArchiveOptions.downloadAllTurns
                ? TurnContentOptions.ALL_VERSIONS
                : TurnContentOptions.ONLY_LAST_VERSION
            }
            onSelectTurnContentOption={(value) =>
              setDownloadArchiveOptions({
                ...downloadArchiveOptions,
                downloadAllTurns: value === TurnContentOptions.ALL_VERSIONS,
              })
            }
          />
          <IncludeChatMessageOption
            checked={downloadArchiveOptions.downloadChatMessages}
            onChangeIncludeMessageOption={() =>
              setDownloadArchiveOptions({
                ...downloadArchiveOptions,
                downloadChatMessages: !downloadArchiveOptions.downloadChatMessages,
              })
            }
          />
        </Fragment>
      }
      footer={
        <TakeTurnsDialogFooter closeDialog={props.onCloseDialog} hasCancelAction>
          <TakeTurnsDefaultButton onClick={() => props.onClickDownloadArchive(downloadArchiveOptions)}>
            {t("download")}
          </TakeTurnsDefaultButton>
        </TakeTurnsDialogFooter>
      }
    />
  );
};
