/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Box, Divider, Stack } from "@mui/material";
import { CollaborationTitle } from "@taketurns-components/collaboration/Shared/CollaborationTitle";
import { useDecodeParamsInCurrentUrlRule } from "@taketurns-rules/webapp/queries/useDecodeParamsInCurrentUrlRule";
import { MobileCardLayout } from "../../../layouts/public/MobileCardLayout";
import { PasswordlessCollaborationAccessTitle } from "./components/PasswordlessCollaborationAccessTitle";
import { SendOrVerifyCodeSection } from "./components/SendOrVerifyCodeSection";
import { PasswordlessCollaborationAccessParameters } from "./PasswordlessCollaborationAccess";

export const MobilePasswordlessAccess = () => {
  const {
    collaboration,
    recipient: { party },
  } = useDecodeParamsInCurrentUrlRule<PasswordlessCollaborationAccessParameters>()!;

  return (
    <MobileCardLayout>
      <Stack spacing={3} height="100%">
        <PasswordlessCollaborationAccessTitle />
        <Box>
          <CollaborationTitle
            owningPartyName={collaboration.owningPartyName}
            invitedPartyName={collaboration.invitedPartyName}
            name={collaboration.name}
            userParty={party}
          />
        </Box>
        <Divider />
        <SendOrVerifyCodeSection />
      </Stack>
    </MobileCardLayout>
  );
};
