/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useGetCurrentFolderIdInCollaborationView } from "@taketurns-repositories/collaboration/state/read/collaborationView/useGetCurrentFolderIdInCollaborationView";
import { useAttachFilesInFolderRule } from "./useAttachFilesInFolderRule";

export const useAttachFilesInCurrentFolderRule = () => {
  const attachFilesInFolder = useAttachFilesInFolderRule();
  const folderId = useGetCurrentFolderIdInCollaborationView();

  return async (files: File[], requestName?: string) => {
    await attachFilesInFolder(files, folderId, requestName);
  };
};
