/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Box, CircularProgress, Skeleton, styled, Typography } from "@mui/material";
import { useUserSharedTranslation } from "@taketurns-i18n/user/useUserSharedTranslation";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { useOpenStripePortalUrlRule } from "@taketurns-rules/user/commands/useOpenStripePortalUrlRule";
import { TakeTurnsTextButton } from "../../commons/button";

interface UpgradePaymentProps {
  paymentCardLastFourDigits: string;
  loading: boolean;
  error: boolean;
}

export const UpgradePayment = ({ paymentCardLastFourDigits, loading, error }: UpgradePaymentProps) => {
  const { t } = useUserSharedTranslation();
  const { openStripePortal, loadingStripePortal } = useOpenStripePortalUrlRule();

  if (loading) {
    return (
      <UpgradePayment__Container>
        <Skeleton variant={"text"} width={200} />
        <Skeleton variant={"text"} width={200} />
      </UpgradePayment__Container>
    );
  }

  if (error) {
    return;
  }

  return (
    <UpgradePayment__Container>
      {t("UpgradePayment.amountChargedOnCard", { paymentCardLastFourDigits })}
      <Typography fontSize={12}>
        {t("UpgradePayment.updatePaymentMethod")},{" "}
        <TakeTurnsTextButton disabled={loadingStripePortal} onClick={() => openStripePortal()}>
          {loadingStripePortal ? (
            <CircularProgress size={12} color={"secondary"} />
          ) : (
            t("UpgradePayment.buttons.clickHere")
          )}
        </TakeTurnsTextButton>
      </Typography>
    </UpgradePayment__Container>
  );
};

const UpgradePayment__Container = styled(Box)({
  alignSelf: "flex-end",
  fontSize: 12,
  color: TakeTurnsColors.darkGray,
  textAlign: "end",
});
