/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useOrganizationInvitationNotificationTranslation } from "@taketurns-i18n/user/UserNotificationCenter/OrganizationInvitationNotificationAndDialog/useOrganizationInvitationNotificationTranslation";
import { useDeclineOrganizationInvitation } from "@taketurns-repositories/user/graphql/mutations/useDeclineOrganizationInvitation";
import { useDisplayAndLogNotificationErrorRule } from "./useDisplayAndLogNotificationErrorRule";
import { useDisplayNotificationSuccessRule } from "./useDisplayNotificationSuccessRule";

export const useDeclineOrganizationInvitationRule = () => {
  const {
    declineOrganizationInvitation: declineOrganizationInvitationCommand,
    loadingDeclineOrganizationInvitation,
    errorOnDeclineOrganizationInvitation,
  } = useDeclineOrganizationInvitation();
  const displayNotificationSuccess = useDisplayNotificationSuccessRule();
  const displayNotificationError = useDisplayAndLogNotificationErrorRule();

  const { t } = useOrganizationInvitationNotificationTranslation();

  const displayOrganizationDeclinedMessage = () => {
    displayNotificationSuccess(t("Notifications.organizationInvitationDeclined"));
  };

  const displayNotificationErrorMessage = () => {
    displayNotificationError(errorOnDeclineOrganizationInvitation);
  };

  const declineOrganizationInvitation = (organizationId: string) => {
    return declineOrganizationInvitationCommand(organizationId)
      .then(() => displayOrganizationDeclinedMessage())
      .catch((error) => {
        displayNotificationErrorMessage();
        throw error;
      });
  };

  return { declineOrganizationInvitation, loadingDeclineOrganizationInvitation, errorOnDeclineOrganizationInvitation };
};
