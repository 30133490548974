/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Box, Button, CircularProgress, Divider, Stack, styled, Typography } from "@mui/material";
import { Fragment } from "react";
import { CreateCollaborationInput } from "@taketurns/taketurns-graphql-repository";
import { CollaborationNameField } from "@taketurns-components/collaboration/CollaborationCreation/SimpleCollaborationCreation/CollaborationNameField";
import { useCollaborationCreationTranslation } from "@taketurns-i18n/collaboration/CollaborationCreation/useCollaborationCreationTranslation";
import { useValidateSimpleFileRequestFormRule } from "@taketurns-rules/collaboration/commands/collaborationCreation/form/useValidateSimpleFileRequestFormRule";
import { useCreateCollaborationRule } from "@taketurns-rules/collaboration/commands/collaborationCreation/useCreateCollaborationRule";
import { useNavigateToCollaborationListRule } from "@taketurns-rules/collaboration/commands/navigation/useNavigateToCollaborationListRule";
import { useGetCollaborationFormErrorsRule } from "@taketurns-rules/collaboration/queries/collaborationCreation/form/useGetCollaborationFormErrorsRule";
import { useGetCollaborationFormRule } from "@taketurns-rules/collaboration/queries/collaborationCreation/form/useGetCollaborationFormRule";
import { TakeTurnsDefaultButton } from "../../../commons/button";
import { CollaborationCreationContent } from "../CollaborationCreationContent/CollaborationCreationContent";
import { InvitedOrganizationNameField } from "./InvitedOrganizationNameField";
import { RecipientField } from "./RecipientField";
import { RecipientList } from "./RecipientList";
import { RequiredDefaultPartyNameDialog } from "./RequiredDefaultPartyNameDialog";
import { StartNoteField } from "./StartNoteField";

export const SimpleFileRequest = () => {
  const { t } = useCollaborationCreationTranslation();

  const validateSimpleFileRequest = useValidateSimpleFileRequestFormRule();
  const simpleFileRequestError = useGetCollaborationFormErrorsRule();
  const redirectToCollaborationList = useNavigateToCollaborationListRule();
  const collaborationForm = useGetCollaborationFormRule();
  const {
    createCollaborationCommand,
    createCollaborationResult: { loading: loadingSimpleCollaborationCreation },
  } = useCreateCollaborationRule();

  const validateSimpleFileRequestAndProceedCreationIfValid = () => {
    if (validateSimpleFileRequest().isValid) {
      createCollaborationCommand(collaborationForm as CreateCollaborationInput);
    }
  };

  return (
    <Fragment>
      <Stack spacing={2} height={"100%"} overflow="hidden auto">
        <CollaborationNameField />
        <InvitedOrganizationNameField />
        <RecipientField />
        <RecipientList />
        <StartNoteField />
        <Divider />
        {simpleFileRequestError.requests && (
          <Typography color={"error"}>{t("SimpleFileRequest.missingRequestError")}</Typography>
        )}
        <CollaborationCreationContent
          showAddRequest
          actionSlot={
            <ActionButtons__Container>
              <Button onClick={redirectToCollaborationList} variant={"text"}>
                {t("SimpleFileRequest.buttons.cancel")}
              </Button>
              <TakeTurnsDefaultButton
                disabled={loadingSimpleCollaborationCreation}
                startIcon={loadingSimpleCollaborationCreation && <CircularProgress size={12} color={"secondary"} />}
                onClick={validateSimpleFileRequestAndProceedCreationIfValid}
              >
                {t("SimpleFileRequest.buttons.send")}
              </TakeTurnsDefaultButton>
            </ActionButtons__Container>
          }
        />
      </Stack>
      <RequiredDefaultPartyNameDialog />
    </Fragment>
  );
};

const ActionButtons__Container = styled(Box)({
  display: "flex",
  gap: "12px",
});
