/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { gql, useMutation } from "@apollo/client";
import { Folder, MutationRenameFolderArgs, RenameFolderInput } from "@taketurns/taketurns-graphql-repository";

export const renameFolderMutationFragment = gql`
  fragment RenameFolderMutationFragment on RenameFolderOutput {
    collaborationId
    partyId
    folderId
    folder {
      id
      name
      status
    }
    contentMetadata {
      id
      hasPendingUpdates
    }
  }
`;
export const useRenameFolder = (collaborationId: string, folderId: string) => {
  const RENAME_FOLDER = gql`
    ${renameFolderMutationFragment}
    mutation renameFolder($input: RenameFolderInput!) {
      renameFolder(input: $input) {
        ...RenameFolderMutationFragment
      }
    }
  `;

  const [renameFolderMutation, { data, loading, error }] = useMutation<
    { renameFolder: Folder },
    MutationRenameFolderArgs
  >(RENAME_FOLDER);

  const renameFolder = (newName: string) => {
    const input: RenameFolderInput = {
      collaborationId,
      newName,
      folderId,
    };
    return renameFolderMutation({ variables: { input } });
  };

  return {
    renameFolder,
    renameFolderResponseData: data?.renameFolder,
    renameFolderLoading: loading,
    errorOnRenamingFolder: error,
  };
};
