/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useGetAddedPendingCreationAttachments } from "@taketurns-repositories/collaboration/state/read/collaborationCreation/useGetAddedPendingCreationAttachments";
import { useSetCollaborationFormErrorsRule } from "./useSetCollaborationFormErrorsRule";
import { useValidateCollaborationFormAndGetIsValidFormRule } from "./useValidateCollaborationFormAndGetIsValidFormRule";

export const useValidateSimpleFileSharingFormRule = () => {
  const validateAndSetCollaborationFormError = useValidateCollaborationFormAndGetIsValidFormRule();
  const addedPendingCreationAttachments = useGetAddedPendingCreationAttachments();
  const setCollaborationFormError = useSetCollaborationFormErrorsRule();

  return () => {
    const collaborationFormIsValid = validateAndSetCollaborationFormError().isValid;
    const hasFilesToShare = addedPendingCreationAttachments.length !== 0;
    if (!hasFilesToShare) {
      setCollaborationFormError((currentCollaborationFormError) => ({ ...currentCollaborationFormError, files: true }));
    }
    return { isValid: collaborationFormIsValid && hasFilesToShare };
  };
};
