/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useShouldDisplayDefaultWelcomeDialogRule } from "@taketurns-rules/webapp/queries/useShouldDisplayDefaultWelcomeDialogRule";
import { useDecodeParamsInCurrentUrlRule } from "./useDecodeParamsInCurrentUrlRule";

export const useShouldDisplayCollaborationInvitationWelcomeDialogRule = () => {
  const params = useDecodeParamsInCurrentUrlRule<{ isInvitationLink: boolean }>()!;
  const shouldDisplayDefaultWelcomeDialogRule = useShouldDisplayDefaultWelcomeDialogRule();
  return params?.isInvitationLink && shouldDisplayDefaultWelcomeDialogRule;
};
