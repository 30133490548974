/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { z } from "zod";

const EmailVerificationData = z.object({
  email: z.string().min(1).email(),
  password: z.string().min(1),
  origin: z.enum(["signin", "signup"]),
});

export type EmailVerificationData = z.infer<typeof EmailVerificationData>;

export const verifyEmailVerificationData = (data: unknown): EmailVerificationData | null => {
  try {
    return EmailVerificationData.parse(data);
  } catch (error) {
    console.error(error);
    return null;
  }
};
