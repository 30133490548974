/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useResetRecoilState } from "recoil";
import { requestFilesToAddOnRequestFulfillmentOpeningState } from "@taketurns-repositories/collaboration/state/recoil/requestFilesToAddOnRequestFulfillmentOpeningState";

export const useResetRequestFilesToAddOnRequestFulfillmentOpening = () => {
  const resetRequestFilesToAddOnRequestFulfillmentOpening = useResetRecoilState(
    requestFilesToAddOnRequestFulfillmentOpeningState,
  );

  return () => {
    return resetRequestFilesToAddOnRequestFulfillmentOpening();
  };
};
