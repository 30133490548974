/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { IconButton } from "@mui/material";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { IconChevronLeft } from "../commons/icons";

export const MobileBackButton = () => {
  const navigate = useNavigate();
  const redirectToPreviousPage = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <IconButton sx={{ paddingLeft: 0 }} onClick={redirectToPreviousPage} disableRipple>
      <IconChevronLeft color={TakeTurnsColors.darkGray} size={24} />
    </IconButton>
  );
};
