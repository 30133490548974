/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { CircularProgress, Divider, Skeleton, Stack, styled, Typography } from "@mui/material";
import { Fragment } from "react";
import { SubscriptionPlanStatus, SubscriptionPlanTier } from "@taketurns/taketurns-graphql-repository";
import { useUserPlanTranslation } from "@taketurns-i18n/user/UserPlans/useUserPlanTranslation";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { useIsOnMobileRule } from "@taketurns-rules/commons/theme/useIsOnMobileRule";
import { useNavigateToPlansPageRule } from "@taketurns-rules/user/commands/useNavigateToPlansPageRule";
import { useOpenStripePortalUrlRule } from "@taketurns-rules/user/commands/useOpenStripePortalUrlRule";
import { computeTrialRemainingDaysRule } from "@taketurns-rules/user/queries/computeTrialRemainingDaysRule";
import { useFetchUserHasOrganizationAdminFeatureRule } from "@taketurns-rules/user/queries/useFetchUserHasOrganizationAdminFeatureRule";
import { useFetchUserOrganizationNameAndSubscriptionPlanRule } from "@taketurns-rules/user/queries/useFetchUserOrganizationNameAndSubscriptionPlanRule";
import { TakeTurnsDefaultButton } from "../../../commons/button";
import { AvailablePlansHorizontalList } from "./AvailablePlansHorizontalList";
import { UserAvailableStorage } from "./UserAvailableStorage";

export const PaidPlanBillingPortal = () => {
  const {
    userOrganizationName,
    userOrganizationSubscriptionPlan,
    loadingUserOrganizationNameAndSubscriptionPlan,
    errorOnUserOrganizationNameAndSubscriptionPlan,
  } = useFetchUserOrganizationNameAndSubscriptionPlanRule();
  const { userHasOrganizationAdminFeature, loadingHasOrganizationAdminFeature, errorOnHasOrganizationAdminFeature } =
    useFetchUserHasOrganizationAdminFeatureRule();
  const { t } = useUserPlanTranslation();
  const isOnMobile = useIsOnMobileRule();
  const navigateToPlansPage = useNavigateToPlansPageRule();

  const loading = loadingHasOrganizationAdminFeature || loadingUserOrganizationNameAndSubscriptionPlan;
  if (loading) {
    return <Skeleton variant="text" />;
  }

  const error = errorOnUserOrganizationNameAndSubscriptionPlan || errorOnHasOrganizationAdminFeature;
  if (error) {
    console.error(error);
    return (
      <Typography variant={"caption"} color={"error"}>
        Something went wrong while loading your plan. We are working on it.
      </Typography>
    );
  }

  if (!userHasOrganizationAdminFeature) {
    return (
      <Stack direction="column" spacing={4}>
        <Typography fontSize={16}>
          {t("billingPortal.yourPlanIsManagedBy", { organizationName: userOrganizationName })}
        </Typography>
        <UserPlan planTier={userOrganizationSubscriptionPlan.tier!} />
      </Stack>
    );
  }

  return (
    <Stack direction="column" spacing={{ xs: 3, md: 4 }}>
      {!isOnMobile && <Typography fontSize={16}>{t("billingPortal.manageYourPlan")}</Typography>}
      <UserPlan planTier={userOrganizationSubscriptionPlan.tier!} />
      {userOrganizationSubscriptionPlan?.status === SubscriptionPlanStatus.Trialing && (
        <TrialRemainingDaysAndRedirectToPlanButton />
      )}
      {userOrganizationSubscriptionPlan?.tier < SubscriptionPlanTier.T3 && (
        <UpgradeCatchPhraseAndRedirectToPlanButton planTier={userOrganizationSubscriptionPlan.tier!} />
      )}
      <UserAvailableStorage />
      <Divider />
      <BillingAndPayments />
    </Stack>
  );

  function UserPlan({ planTier }: { planTier: SubscriptionPlanTier }) {
    return (
      <Stack
        direction={{ xs: "column", md: "row" }}
        spacing={{ xs: 2, md: 3 }}
        alignItems={{ xs: "normal", md: "center" }}
      >
        <Typography variant="body2">{t("billingPortal.yourPlan")}</Typography>
        <AvailablePlansHorizontalList userPlanTier={planTier} onClick={navigateToPlansPage} />
      </Stack>
    );
  }

  function UpgradeCatchPhraseAndRedirectToPlanButton({ planTier }: { planTier: SubscriptionPlanTier }) {
    const isLastPlanTierAvailable = planTier === SubscriptionPlanTier.T2;

    if (isLastPlanTierAvailable) {
      return;
    }
    return (
      <PaidPlanBillingPortal__RedirectToPlanSectionContainer>
        <Typography variant={"caption"} color={TakeTurnsColors.darkGray}>
          {t("billingPortal.upgradeToNextPlan")}{" "}
          <span style={{ color: TakeTurnsColors.darkBlue, fontWeight: 700 }}>{t("plans.T2")}</span>
        </Typography>
        <TakeTurnsDefaultButton sx={{ flex: "0 0 auto" }} onClick={navigateToPlansPage}>
          {t("billingPortal.upgradeToday")}
        </TakeTurnsDefaultButton>
      </PaidPlanBillingPortal__RedirectToPlanSectionContainer>
    );
  }

  function TrialRemainingDaysAndRedirectToPlanButton() {
    const { openStripePortal, loadingStripePortal } = useOpenStripePortalUrlRule();

    const trialRemainingDays = computeTrialRemainingDaysRule(userOrganizationSubscriptionPlan!.trialEndDate!);
    return (
      <PaidPlanBillingPortal__RedirectToPlanSectionContainer>
        <Typography variant={"caption"} color={TakeTurnsColors.darkGray}>
          {t("billingPortal.trialWillExpire", { count: trialRemainingDays })}
        </Typography>
        <TakeTurnsDefaultButton
          endIcon={loadingStripePortal && <CircularProgress size={12} color={"secondary"} />}
          disabled={loadingStripePortal}
          onClick={() => openStripePortal()}
        >
          {t("billingPortal.subscribe")}
        </TakeTurnsDefaultButton>
      </PaidPlanBillingPortal__RedirectToPlanSectionContainer>
    );
  }

  function BillingAndPayments() {
    const { openStripePortal, loadingStripePortal } = useOpenStripePortalUrlRule();

    const openStripePortalInNewTab = () => {
      openStripePortal();
    };

    return (
      <Fragment>
        <Typography fontSize={{ xs: 14, md: 16 }}>{t("billingPortal.viewInvoicesAndPaymentMethods")}</Typography>
        <Stack direction={{ xs: "column", md: "row" }} spacing={2} alignItems={{ xs: "flex-start", md: "center" }}>
          <TakeTurnsDefaultButton
            endIcon={loadingStripePortal && <CircularProgress size={12} color={"secondary"} />}
            disabled={loadingStripePortal}
            onClick={openStripePortalInNewTab}
          >
            {t("billingPortal.billingAndPayments")}
          </TakeTurnsDefaultButton>
          <Typography variant="caption" color={TakeTurnsColors.darkGray}>
            {t("billingPortal.poweredByStripe")}
          </Typography>
        </Stack>
      </Fragment>
    );
  }
};

const PaidPlanBillingPortal__RedirectToPlanSectionContainer = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  gap: theme.spacing(2),
  alignItems: "center",

  [theme.breakpoints.down("md")]: {
    justifyContent: "space-between",
  },
}));
