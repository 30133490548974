/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Box, Skeleton, styled } from "@mui/material";
import { Fragment } from "react";
import { useRecoilState } from "recoil";
import { FetchLoadingManager } from "@taketurns-app/utils/FetchLoadingManager";
import { AttachmentDetails } from "@taketurns-components/collaboration/CollaborationView/AttachmentDetails/components/AttachmentDetails";
import { CardView } from "@taketurns-components/collaboration/CollaborationView/CollaborationContent/CardView/CardView";
import { CollaborationFilesTip } from "@taketurns-components/collaboration/CollaborationView/CollaborationContent/ContentBrowser/CollaborationFilesTip";
import { ContentFooterMenuProvider } from "@taketurns-components/collaboration/CollaborationView/CollaborationContent/ContentBrowser/ContentFooterMenu/ContentFooterMenuProvider";
import { ContentListView } from "@taketurns-components/collaboration/CollaborationView/CollaborationContent/ListView/ContentListView";
import { ResponsiveRequestFulfillmentDialogOrDrawer } from "@taketurns-components/collaboration/CollaborationView/CollaborationContent/RequestFulfillement/ResponsiveRequestFulfillmentDialogOrDrawer";
import { SomethingWentWrong } from "@taketurns-components/shared/SomethingWentWrong";
import { displayContentViewAsCardState } from "@taketurns-repositories/collaboration/state/recoil/displayContentViewAsCardState";
import { useAddOrManageContentPermission } from "@taketurns-rules/collaboration/permissions/useAddOrManageContentPermission";
import { useFetchTodosRule } from "@taketurns-rules/collaboration/queries/collaborationView/useFetchTodosRule";
import { useFetchCollaborationContentMetadataRule } from "@taketurns-rules/collaboration/queries/fields/useFetchCollaborationContentMetadataRule";
import { useSubscribeToRebasedContentRule } from "@taketurns-rules/collaboration/subscriptions/updates/useSubscribeToRebasedContentRule";
import { useIsOnMobileRule } from "@taketurns-rules/commons/theme/useIsOnMobileRule";

export const TodosViewer = () => {
  const [isContentDisplayedAsCards, setIsContentDisplayedAsCards] = useRecoilState(displayContentViewAsCardState);
  const toggleViewMode = () => {
    setIsContentDisplayedAsCards((currentDisplay) => !currentDisplay);
  };

  const isOnMobile = useIsOnMobileRule();

  const isUserAllowedToEditContent = useAddOrManageContentPermission();

  const { attachments, requests, contentMetadata, loading, error } = useFetchTodosViewerDataRule();
  useSubscribeToRebasedContentRule();

  if (loading) {
    return (
      <Box sx={{ paddingLeft: { xs: 0, md: "24px" }, margin: { xs: "16px auto", md: 0 } }}>
        <Skeleton width={1} />
      </Box>
    );
  }

  if (error) {
    return <SomethingWentWrong />;
  }

  const backBreadcrumbLabel = "TODOS";

  const Content = () => {
    if (!isOnMobile && isContentDisplayedAsCards) {
      return (
        <CardView
          backBreadcrumbLabel={backBreadcrumbLabel}
          attachments={attachments}
          requests={requests}
          editable={isUserAllowedToEditContent}
        />
      );
    }
    return (
      <ContentListView
        backBreadcrumbLabel={backBreadcrumbLabel}
        attachments={attachments}
        requests={requests}
        editable={isUserAllowedToEditContent}
      />
    );
  };

  const shouldDisplayCollaborationFilesTip = attachments?.length > 0 && isUserAllowedToEditContent && !isOnMobile;
  const isContentEmpty = contentMetadata?.hasNoContent;
  return (
    <Fragment>
      <TurnContent__Container>
        {shouldDisplayCollaborationFilesTip && <CollaborationFilesTip style={{ alignSelf: "flex-start" }} />}
        <Content />
      </TurnContent__Container>
      <ContentFooterMenuProvider
        editable={isUserAllowedToEditContent}
        displayContentAsCard={isContentDisplayedAsCards}
        onToggleViewModeClick={toggleViewMode}
        isEmptyContent={isContentEmpty}
      />
      <AttachmentDetails />
      <ResponsiveRequestFulfillmentDialogOrDrawer />
    </Fragment>
  );
};

const useFetchTodosViewerDataRule = () => {
  const { attachments, requests, loadingTodos, errorOnFetchingTodos } = useFetchTodosRule();
  const {
    contentMetadata,
    loading: loadingContentMetadata,
    error: errorWhileFetchingContentMetadata,
  } = useFetchCollaborationContentMetadataRule();

  const fetchLoadingManager = new FetchLoadingManager(
    [loadingTodos, loadingContentMetadata],
    [errorOnFetchingTodos, errorWhileFetchingContentMetadata],
  );

  const loading = fetchLoadingManager.hasFetchLoading();
  const error = fetchLoadingManager.hasFetchInError();
  if (error) {
    fetchLoadingManager.logErrors();
  }

  return {
    attachments,
    requests,
    loadingTodos,
    errorOnFetchingTodos,
    contentMetadata,
    loadingContentMetadata,
    errorWhileFetchingContentMetadata,
    loading,
    error,
  };
};

const TurnContent__Container = styled(Box)(({ theme }) => ({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  gap: 24,
  marginBottom: "50px !important",
  padding: "2px 24px",
  height: "100%",
  minWidth: 0,
  overflow: "auto",
  [theme.breakpoints.down("md")]: {
    padding: "0 0 env(safe-area-inset-bottom)",
    height: "auto",
    overflow: "initial",
  },
}));
