/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useLazyQuery } from "@apollo/client";
import { useCallback, useEffect } from "react";
import { CollaborationArchiveDownloadStatusResponse } from "@taketurns/taketurns-graphql-repository";

const COLLABORATION_ARCHIVE_DOWNLOAD_STATUS = gql`
  query getCollaborationArchiveDownloadStatus($downloadId: ID!) {
    collaborationArchiveDownloadStatus(downloadId: $downloadId) {
      status
      downloadUrl
    }
  }
`;

export const useFetchCollaborationArchiveDownloadStatus = () => {
  const [
    collaborationArchiveDownloadStatusQuery,
    { data, stopPolling, loading: loadingCollaborationArchiveStatus, error: errorOnLoadingCollaborationArchiveStatus },
  ] = useLazyQuery<{
    collaborationArchiveDownloadStatus: CollaborationArchiveDownloadStatusResponse;
  }>(COLLABORATION_ARCHIVE_DOWNLOAD_STATUS);

  const fetchCollaborationArchiveDownloadStatus = useCallback((downloadId: string) => {
    const pollInterval = 4000;
    collaborationArchiveDownloadStatusQuery({ variables: { downloadId }, pollInterval });
  }, []);

  useEffect(() => {
    if (errorOnLoadingCollaborationArchiveStatus) {
      stopPolling();
    }
    if (data?.collaborationArchiveDownloadStatus?.downloadUrl) {
      stopPolling();
    }
  }, [data, loadingCollaborationArchiveStatus, stopPolling, errorOnLoadingCollaborationArchiveStatus]);

  return {
    fetchCollaborationArchiveDownloadStatus,
    loadingCollaborationArchiveStatus,
    archiveDownloadUrl: data?.collaborationArchiveDownloadStatus?.downloadUrl,
    errorOnLoadingCollaborationArchiveStatus,
  };
};
