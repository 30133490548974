/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { IconButton, IconButtonProps } from "@mui/material";
import { IconGrid, IconList } from "../../../commons/icons";

interface ChangeContentViewModeButtonProps {
  cardView: boolean;
  onClick: () => void;
}

export const ChangeContentViewModeButton = (props: IconButtonProps & ChangeContentViewModeButtonProps) => {
  const { cardView, onClick } = props;
  return (
    <IconButton sx={{ paddingRight: 0 }} onClick={onClick}>
      {cardView ? <IconList /> : <IconGrid />}
    </IconButton>
  );
};
