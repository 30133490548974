/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { selector } from "recoil";
import { pathAsFolderIdsInCollaborationViewState } from "./pathAsFolderIdsInCollaborationViewState";

export const selectCurrentFolderIdState = selector<string | undefined>({
  key: "selectCurrentFolderId",
  get: ({ get }) => {
    const pathInCollaborationView = get(pathAsFolderIdsInCollaborationViewState);
    return pathInCollaborationView.at(-1);
  },
});
