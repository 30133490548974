/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { selector, useRecoilValue } from "recoil";
import { pendingCreationAttachmentsState } from "../../recoil/collaborationCreation/pendingCreationAttachmentsState";

export const selectAddedPendingCreationAttachments = selector({
  key: "useGetAddedPendingCreationAttachments",
  get: ({ get }) =>
    get(pendingCreationAttachmentsState).filter((pendingCreationAttachment) => pendingCreationAttachment.isAdded()),
});

export const useGetAddedPendingCreationAttachments = () => {
  return useRecoilValue(selectAddedPendingCreationAttachments);
};
