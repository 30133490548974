/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Attachment } from "@taketurns/taketurns-graphql-repository";
import { PendingAttachmentRevision } from "@taketurns-repositories/collaboration/models/pendingAttachmentRevision";
import { useStorePendingAttachmentRevision } from "@taketurns-repositories/collaboration/state/write/useStorePendingAttachmentRevision";
import { Upload } from "@taketurns-repositories/document/state/models/upload";
import { useStoreNewUpload } from "@taketurns-repositories/document/state/write/useStoreNewUpload";
import { useGetDisplayedCollaborationIdRule } from "@taketurns-rules/collaboration/queries/collaboration/useGetDisplayedCollaborationIdRule";
import { useFetchDocumentRule } from "@taketurns-rules/document/queries/useFetchDocumentRule";

export const useUploadAttachmentRevisionFromPdfViewerRule = (
  attachment: Attachment,
  startFromPdfViewer: (uploadId: number) => Promise<string>,
) => {
  const storePendingAttachmentRevision = useStorePendingAttachmentRevision();

  const storeNewUpload = useStoreNewUpload(true, startFromPdfViewer);
  const collaborationId = useGetDisplayedCollaborationIdRule();
  const { document } = useFetchDocumentRule(attachment.id);
  return async () => {
    const upload: Upload = storeNewUpload(null, attachment.id, attachment.document.name);
    const pendingAttachmentRevision: PendingAttachmentRevision = new PendingAttachmentRevision(
      attachment.id,
      collaborationId,
      document.name,
      "pdf",
      Number(attachment.document.size),
      upload.uploadId,
    );
    storePendingAttachmentRevision(pendingAttachmentRevision);
  };
};
