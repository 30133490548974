/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Box, SxProps } from "@mui/material";
import { useSnackbar } from "notistack";
import { ComponentType, PropsWithChildren, ReactElement, Ref, useEffect, useRef, useState } from "react";
import { useSharedCollaborationTranslation } from "@taketurns-i18n/collaboration/shared/useSharedCollaborationTranslation";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";

interface FilesDragAndDropWithOverlayProps {
  ContainerComponent: ComponentType<
    PropsWithChildren<{
      className?: string;
      sx?: SxProps;
      ref: Ref<HTMLDivElement>;
    }>
  >;
  children: ReactElement | ReactElement[];
  dropZoneConfig: FilesDragAndDropWithOverlayConfig;
  roundedDropZone?: boolean;
}

export interface FilesDragAndDropWithOverlayConfig {
  handleFilesDropping: (files: File[]) => Promise<void> | void;
  multiple?: boolean;
  className?: string;
  sx?: SxProps;
}

export const FilesDragAndDropWithOverlay = ({
  ContainerComponent,
  children,
  dropZoneConfig: { className, handleFilesDropping, sx, multiple },
  roundedDropZone,
}: FilesDragAndDropWithOverlayProps): ReactElement => {
  const dropZoneRef = useRef<HTMLDivElement>(null);
  const dragOverlayRef = useRef<HTMLDivElement>(null);
  const [dragging, setDragging] = useState(false);

  const handleDrop = useHandleDrop();
  useEffect(() => {
    dropZoneRef.current?.addEventListener("dragover", handleDragOver);
    dropZoneRef.current?.addEventListener("dragenter", handleDragEnter);
    dropZoneRef.current?.addEventListener("dragleave", handleDragLeave);

    return () => {
      dropZoneRef.current?.removeEventListener("dragover", handleDragOver);
      dropZoneRef.current?.removeEventListener("dragenter", handleDragEnter);
      dropZoneRef.current?.removeEventListener("dragleave", handleDragLeave);
    };
  }, []);

  useEffect(() => {
    dropZoneRef.current?.addEventListener("drop", handleDrop);
    return () => {
      dropZoneRef.current?.removeEventListener("drop", handleDrop);
    };
  }, [handleFilesDropping]);

  const BACKDROP_OPACITY_IN_PERCENT = 50;
  return (
    <ContainerComponent
      className={className}
      ref={dropZoneRef}
      sx={{
        position: "relative",
        ...sx,
      }}
    >
      {dragging && (
        <Box
          ref={dragOverlayRef}
          sx={{
            position: "absolute",
            zIndex: 1,
            top: -1,
            bottom: -1,
            left: -1,
            right: -1,
            backgroundColor: TakeTurnsColors.lightBlue + BACKDROP_OPACITY_IN_PERCENT,
            border: `${TakeTurnsColors.lightBlue} 2px solid`,
            borderRadius: roundedDropZone ? "10px" : 0,
            ...sx,
          }}
        />
      )}
      {children}
    </ContainerComponent>
  );

  function handleDragOver(e: DragEvent) {
    e.preventDefault();
    e.stopPropagation();
  }

  function useHandleDrop() {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useSharedCollaborationTranslation();

    return (e: DragEvent) => {
      e.preventDefault();
      e.stopPropagation();

      setDragging(false);

      const files = e.dataTransfer ? [...e.dataTransfer.files] : [];

      if (!multiple && files.length > 1) {
        enqueueSnackbar(t("FilesDragAndDropWithOverlay.noMultipleFiles"), { variant: "error" });
        return;
      }

      const filesToUpload = [];
      let containsFolders = false;
      for (let dataTransferIndex = 0; dataTransferIndex < e.dataTransfer?.items.length; dataTransferIndex++) {
        const draggedItems = [...e.dataTransfer.items];
        const draggedFiles = [...e.dataTransfer.files];
        if (draggedItems[dataTransferIndex].webkitGetAsEntry().isFile) {
          filesToUpload.push(draggedFiles[dataTransferIndex]);
        }
        if (draggedItems[dataTransferIndex].webkitGetAsEntry().isDirectory) {
          containsFolders = true;
        }
      }

      if (containsFolders) {
        enqueueSnackbar(t("FilesDragAndDrop.folderNotSupported"), { variant: "error" });
      }
      if (filesToUpload.length) {
        handleFilesDropping(filesToUpload);
      }
    };
  }

  function handleDragEnter(e: DragEvent) {
    e.preventDefault();
    e.stopPropagation();
    if (e.target !== dragOverlayRef.current) {
      setDragging(true);
    }
  }

  function handleDragLeave(e: DragEvent) {
    e.preventDefault();
    e.stopPropagation();
    if (e.target === dragOverlayRef.current) {
      setDragging(false);
    }
  }
};
