/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useRemoveFolder } from "@taketurns-repositories/collaboration/graphql/mutations/content/folders/useRemoveFolder";
import { useGetDisplayedCollaborationIdRule } from "@taketurns-rules/collaboration/queries/collaboration/useGetDisplayedCollaborationIdRule";

export const useRemoveFolderRule = (folderId: string) => {
  const collaborationId = useGetDisplayedCollaborationIdRule();

  const { removeFolder, removeFolderResponseData, removeFolderLoading, errorOnRenamingFolder } = useRemoveFolder(
    collaborationId,
    folderId,
  );
  return {
    removeFolder,
    removeFolderResponseData: removeFolderResponseData,
    removeFolderLoading: removeFolderLoading,
    errorOnRenamingFolder: errorOnRenamingFolder,
  };
};
