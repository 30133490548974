/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Typography } from "@mui/material";
import { Collaboration } from "@taketurns/taketurns-graphql-repository";
import { useCollaborationListTranslation } from "@taketurns-i18n/collaboration/CollaborationList/useCollaborationListTranslation";
import { useGetListAppliedFilterRule } from "@taketurns-rules/collaboration/queries/useGetListAppliedFilterRule";
import { subtractNowInDays } from "@taketurns-rules/collaboration/utils/date/DateUtils";
import { DayIndicator } from "../../../DayIndicator/DayIndicator";
import { CollaborationFilterType } from "../../collaborationFilterType";

interface RemovalDateCellProps {
  collaborationWipeDate: Collaboration["wipeDate"];
}

export const RemovalDateCell = ({ collaborationWipeDate }: RemovalDateCellProps) => {
  const { t } = useCollaborationListTranslation();
  const collaborationListAppliedFilter = useGetListAppliedFilterRule();

  const REMOVED_ON_BEGIN_KEY_PREFIX = "removedIn.begin";
  const removedInBeginKey =
    collaborationListAppliedFilter === CollaborationFilterType.closedCollaborations
      ? `${REMOVED_ON_BEGIN_KEY_PREFIX}.closedCollaboration`
      : `${REMOVED_ON_BEGIN_KEY_PREFIX}.starredCollaboration`;
  let remainingDays: number = Math.trunc(subtractNowInDays(collaborationWipeDate));
  if (remainingDays < 1) {
    remainingDays = 1;
  }

  return (
    <Typography fontSize={12}>
      {t(removedInBeginKey)} <DayIndicator>{remainingDays}</DayIndicator> {t("removedIn.day", { count: remainingDays })}
    </Typography>
  );
};
