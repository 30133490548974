/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { atom } from "recoil";
import { ROOT_FOLDER_ID } from "./folderByIdState";

export const pathInCreationState = atom<string[]>({
  key: "contentPathInCreation",
  default: [ROOT_FOLDER_ID],
});
