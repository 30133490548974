/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useRecoilValue } from "recoil";
import { Party } from "@taketurns/taketurns-graphql-repository";
import { selectCollaborationPartyNameFieldErrorStateByParty } from "@taketurns-repositories/collaboration/state/recoil/collaborationCreation/form/errors/fieldErrors/selectCollaborationPartyNameFieldErrorStateByParty";

export const useGetCollaborationPartyNameErrorByParty = (party: Party) => {
  return useRecoilValue(selectCollaborationPartyNameFieldErrorStateByParty(party));
};
