/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import React, { useCallback } from "react";
import { Attachment } from "@taketurns/taketurns-graphql-repository";
import { ContextMenuOnButton } from "@taketurns-components/collaboration/ContextMenu/ContextMenuOnButton";
import { ContextMenuConfiguration } from "@taketurns-components/collaboration/ContextMenu/models/contextMenuConfiguration";
import { IconTrash } from "@taketurns-components/commons/icons";
import { useRemoveAttachmentRule } from "@taketurns-rules/collaboration/commands/collaborationView/attachment/useRemoveAttachmentRule";
import { useCloseAttachmentDetailsRule } from "@taketurns-rules/collaboration/commands/collaborationView/useCloseAttachmentDetailsRule";

interface MobileAttachmentDetailsKebabMenuProps {
  attachment: Attachment;
  canDelete: boolean;
}

export const MobileAttachmentDetailsKebabMenu = (props: MobileAttachmentDetailsKebabMenuProps) => {
  const { removeAttachment } = useRemoveAttachmentRule(props.attachment);
  const closeDocumentDetailsRule = useCloseAttachmentDetailsRule();
  const removeAttachmentAndCloseDetailsDialog = useCallback(() => {
    removeAttachment();
    closeDocumentDetailsRule();
  }, [closeDocumentDetailsRule, removeAttachment]);

  if (!props.canDelete) {
    return null;
  }

  const contextMenuConfiguration: ContextMenuConfiguration = {
    contextMenuItems: [
      {
        labelKey: "contextMenu.remove",
        icon: <IconTrash size={14} />,
        action: removeAttachmentAndCloseDetailsDialog,
      },
    ],
  };

  return <ContextMenuOnButton contextMenuConfiguration={contextMenuConfiguration} iconSize={24} />;
};
