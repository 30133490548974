/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { gql, useMutation } from "@apollo/client";
import { wait } from "@taketurns-rules/commons/util/wait";
import { USER_ORGANIZATION } from "../queries/useFetchUserOrganization";
import { USER_PENDING_ORGANIZATION_INVITATION } from "../queries/useFetchUserPendingOrganizationInvitation";

export const useDeclineOrganizationInvitation = () => {
  const DECLINE_ORGANIZATION_INVITATION = gql`
    mutation declineOrganizationInvitation($organizationId: ID!) {
      declineOrganizationInvitation(organizationId: $organizationId) {
        id
        invitations {
          userId
          email
          role
          user {
            id
            email
            lastName
            firstName
            avatar
          }
        }
        members {
          role
          userId
          user {
            id
            email
            lastName
            firstName
            avatar
          }
        }
      }
    }
  `;
  const [
    declineOrganizationInvitationMutation,
    { error: errorOnDeclineOrganizationInvitation, loading: loadingDeclineOrganizationInvitation },
  ] = useMutation(DECLINE_ORGANIZATION_INVITATION);

  const declineOrganizationInvitation = (organizationId: string) => {
    return declineOrganizationInvitationMutation({
      variables: { organizationId },
      refetchQueries: [USER_PENDING_ORGANIZATION_INVITATION, USER_ORGANIZATION],
      onQueryUpdated: (observableQuery) => {
        wait(3000).then(() => observableQuery.refetch());
      },
    });
  };

  return {
    declineOrganizationInvitation,
    errorOnDeclineOrganizationInvitation,
    loadingDeclineOrganizationInvitation,
  };
};
