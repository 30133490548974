/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useNavigateToCollaborationListRule } from "@taketurns-rules/collaboration/commands/navigation/useNavigateToCollaborationListRule";

const PREVIOUS_PATH_KEY = "previousPath";

export const useNavigateToUserSettingsPreviousPathRule = () => {
  const navigate = useNavigate();

  const navigateToCollaborationList = useNavigateToCollaborationListRule();
  return useCallback(() => {
    const previousPath = window.sessionStorage.getItem(PREVIOUS_PATH_KEY);
    if (previousPath) {
      navigate(previousPath);
      window.sessionStorage.removeItem(PREVIOUS_PATH_KEY);
    } else {
      navigateToCollaborationList();
    }
  }, [navigate, navigateToCollaborationList]);
};

export const useSetPreviousPathRule = () => {
  const location = useLocation();

  return () => {
    window.sessionStorage.setItem(PREVIOUS_PATH_KEY, location.pathname);
  };
};
