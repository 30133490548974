/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import {
  useOnRemovedParticipantFromCollaboration,
} from "@taketurns-repositories/collaboration/graphql/subscriptions/participants/useOnRemovedParticipantFromCollaboration";
import { useFetchConnectedUserId } from "@taketurns-repositories/user/graphql/queries/useFetchConnectedUserId";
import {
  useSetIsExcludedFromCollaborationState,
} from "@taketurns-repositories/webapp/state/write/useSetIsExcludedFromCollaborationState";

export const useSubscribeOnExcludedFromCollaborationRule = (collaborationId: string) => {
  const { userId } = useFetchConnectedUserId();
  const setIsExcludedFromCollaboration = useSetIsExcludedFromCollaborationState();
  const { isExcluded } = useOnRemovedParticipantFromCollaboration(collaborationId, userId);
  if (isExcluded) {
    setIsExcludedFromCollaboration(isExcluded);
  }
  return isExcluded;
};
