/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { TextField } from "@mui/material";
import { FormEvent, KeyboardEvent, useRef, useState } from "react";
import { DefaultDialogFooter } from "@taketurns-components/collaboration/Shared/dialog/DefaultDialogFooter";
import { useCollaborationTranslation } from "@taketurns-i18n/collaboration/useCollaborationTranslation";
import { HelpCenterSections } from "@taketurns-rules/commons/enum/helpCenterSectionsEnum";
import { TEXT_FIELD_MAX_LENGTH } from "@taketurns-rules/commons/inputConstants";
import { ResponsiveDialog } from "../../../commons/dialog/ResponsiveDialog";

interface AddFolderDialogProps {
  open: boolean;
  onClose: () => void;
  addFolder: (folderName: string) => void;
}

export const AddFolderDialog = (props: AddFolderDialogProps) => {
  const { open, onClose, addFolder } = props;

  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);

  const folderNameInputRef = useRef<HTMLInputElement | null>(null);

  const { t } = useCollaborationTranslation("collaborationDocument");

  const addFolderAndCloseDialog = () => {
    addFolder(folderNameInputRef.current?.value);
    onClose();
  };

  const submitAddFolderAndCloseDialog = (submitEvent: FormEvent<HTMLFormElement | HTMLButtonElement>) => {
    submitEvent.preventDefault();
    addFolderAndCloseDialog();
  };

  const preventForbiddenCharacters = (event: KeyboardEvent<HTMLDivElement>) => {
    const forbiddenCharacters = ["/"];
    if (forbiddenCharacters.includes(event.key)) {
      event.preventDefault();
    }
  };
  const disableButtonIfEmpty = (value: string) => {
    if (submitButtonDisabled === !!value.length) {
      setSubmitButtonDisabled(!submitButtonDisabled);
    }
  };

  return (
    <ResponsiveDialog
      disableRestoreFocus
      fullWidth
      open={open}
      onClose={onClose}
      title={t("addFolder")}
      content={
        <form onSubmit={submitAddFolderAndCloseDialog}>
          <TextField
            autoFocus
            fullWidth
            inputProps={{
              maxLength: TEXT_FIELD_MAX_LENGTH,
            }}
            inputRef={folderNameInputRef}
            placeholder={t("addFolderPlaceholder")}
            onKeyDown={preventForbiddenCharacters}
            onChange={(e) => disableButtonIfEmpty(e.target.value)}
            data-cy="AddFolderTextField"
          />
        </form>
      }
      footer={
        <DefaultDialogFooter
          actionButtonEnabled={!submitButtonDisabled}
          closeDialog={onClose}
          hasCancelAction={true}
          actionExecutionLoading={false}
          actionButtonLabel={t("add")}
          helpCenterSection={HelpCenterSections.ADD_FILES}
          executeAction={addFolderAndCloseDialog}
        />
      }
    />
  );
};
