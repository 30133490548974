/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Box } from "@mui/material";
import Dompurify from "dompurify";
import Quill from "quill";
import { useEffect, useRef } from "react";
import "./RichTextField.css";

export interface RichTextFieldProps {
  onChange: (value: string | undefined) => void;
  placeholder?: string;
  value?: string;
}

export const RichTextField = ({ onChange, placeholder, value }: RichTextFieldProps) => {
  const richTextFieldRef = useRef<string | null>(null);
  const editorRef = useRef<Quill | null>(null);

  useEffect(() => {
    if (richTextFieldRef.current && !editorRef.current) {
      const toolbarOptions = [
        [{ header: [] }],
        ["bold", "italic", "underline", "strike"],
        [{ color: [] }, { background: [] }],
        [{ list: "bullet" }, { list: "ordered" }, { indent: "-1" }, { indent: "+1" }],
        ["blockquote", "code-block"],
        [{ align: [] }],
        ["link"],
        ["clean"],
      ];

      const formatsOptions = [
        "background",
        "bold",
        "color",
        "font",
        "code",
        "italic",
        "link",
        "size",
        "strike",
        "script",
        "underline",
        "blockquote",
        "header",
        "indent",
        "list",
        "align",
        "direction",
        "code-block",
      ];

      editorRef.current = new Quill(richTextFieldRef.current, {
        bounds: richTextFieldRef.current,
        formats: formatsOptions,
        modules: {
          toolbar: toolbarOptions,
        },
        placeholder,
        theme: "snow",
      });
      editorRef.current.on("text-change", () => {
        onChange(editorRef.current.root.innerHTML || undefined);
      });
      if (value) {
        editorRef.current.root.innerHTML = Dompurify.sanitize(value);
      }
    }
  }, [placeholder, onChange, value]);

  return <Box ref={richTextFieldRef} />;
};
