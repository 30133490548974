/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useMutation } from "@apollo/client";
import { AddFolderInput, Folder, MutationAddFolderArgs } from "@taketurns/taketurns-graphql-repository";
import { subFolderFragment } from "../../../fragments/subFolderFragment";

export const AddFolderMutationFragment = gql`
  fragment AddFolderMutationFragment on AddFolderOutput {
    collaborationId
    partyId
    folderId
    folder {
      id
      folders {
        ...SubFolderFragment
      }
    }
    contentMetadata {
      id
      hasPendingUpdates
    }
  }
  ${subFolderFragment}
`;
export const useAddFolder = (collaborationId: string) => {
  const ADD_FOLDER = gql`
    ${AddFolderMutationFragment}
    mutation addFolder($input: AddFolderInput!) {
      addFolder(input: $input) {
        ...AddFolderMutationFragment
      }
    }
  `;
  const [addFolderMutationFromGraphQL, { data, loading, error }] = useMutation<
    { addFolder: Folder },
    MutationAddFolderArgs
  >(ADD_FOLDER);

  const addFolderMutation = (parentFolderId: string, name: string) => {
    const input: AddFolderInput = {
      collaborationId,
      parentFolderId,
      name: name.trim(),
    };
    return addFolderMutationFromGraphQL({ variables: { input } });
  };

  return {
    addFolderMutation,
    addFolderResponseData: data?.addFolder,
    addFolderLoading: loading,
    errorOnAddingFolder: error,
  };
};
