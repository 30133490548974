/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useMutation } from "@apollo/client";
import {
  MutationRemoveAttachmentRevisionArgs,
  RemoveAttachmentRevisionOutput,
} from "@taketurns/taketurns-graphql-repository";
import { GET_ATTACHMENT_REVISION_STATUS } from "@taketurns-repositories/collaboration/graphql/queries/attachment/useFetchAttachmentRevisionStatus";
import { COLLABORATION_CONTENT_METADATA_QUERY } from "@taketurns-repositories/collaboration/graphql/queries/content/useFetchCollaborationContentMetadata";
import { wait } from "@taketurns-rules/commons/util/wait";

export const removeAttachmentRevisionMutationFragment = gql`
  fragment RemoveAttachmentRevisionMutationFields on RemoveAttachmentRevisionOutput {
    collaborationId
    partyId
    attachmentId
    attachment {
      id
      revisionId
      hasAttachmentPendingRevision
      document {
        id
        revisions {
          revisionId
          size
          extension
          uploadDate
          uploadingUserId
        }
      }
    }
  }
`;

export const useRemoveRevision = () => {
  const mutation = gql`
    mutation removeAttachmentRevision($input: RemoveAttachmentRevisionInput!) {
      removeAttachmentRevision(input: $input) {
        ...RemoveAttachmentRevisionMutationFields
      }
    }
    ${removeAttachmentRevisionMutationFragment}
  `;
  const [removeRevisionMutation, { error: errorOnRemovingRevision, loading: loadingRemoveRevision }] = useMutation<
    {
      removeAttachmentRevision: RemoveAttachmentRevisionOutput;
    },
    MutationRemoveAttachmentRevisionArgs
  >(mutation);

  const removeRevision = (
    collaborationId: string,
    attachmentId: string,
    onSuccess?: (removeAttachmentRevisionOutput: RemoveAttachmentRevisionOutput) => void,
    onError?: () => void,
  ) => {
    removeRevisionMutation({
      variables: {
        input: {
          collaborationId,
          attachmentId,
        },
      },
      refetchQueries: [
        { query: COLLABORATION_CONTENT_METADATA_QUERY, variables: { collaborationId } },
        {
          query: GET_ATTACHMENT_REVISION_STATUS,
          variables: {
            collaborationId,
            attachmentId,
          },
        },
      ],
      onCompleted: (data: { removeAttachmentRevision: RemoveAttachmentRevisionOutput }) => {
        if (onSuccess) {
          onSuccess(data.removeAttachmentRevision);
        }
      },
      onError,
      onQueryUpdated: (observableQuery) => {
        wait(3000).then(() => observableQuery.refetch());
      },
    });
  };

  return { removeRevision, errorOnRemovingRevision, loadingRemoveRevision };
};
