/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Box, DialogTitle, Divider, IconButton, Stack, styled, Typography } from "@mui/material";
import { AddFilesButton } from "@taketurns-components/collaboration/Shared/content/AddFilesButton";
import { TakeTurnsListItem } from "@taketurns-components/collaboration/Shared/list/TakeTurnsListItem";
import { useCollaborationViewTranslation } from "@taketurns-i18n/collaboration/CollaborationView/useCollaborationViewTranslation";
import { useCollaborationTranslation } from "@taketurns-i18n/collaboration/useCollaborationTranslation";
import { Request } from "@taketurns-repositories/collaboration/models/request";
import { useGetOpenedRequestFulfillmentRequest } from "@taketurns-repositories/collaboration/state/read/collaborationView/useGetOpenedRequestFulfillmentRequest";
import { useRequestFulfillmentContentRules } from "@taketurns-rules/collaboration/commands/collaborationView/request/useRequestFulfillmentContentRules";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { Fragment, useRef } from "react";
import { TakeTurnsDefaultButton } from "../../../../commons/button";
import { IconUploadCloud, IconX } from "../../../../commons/icons";
import { FileExtension } from "../../../../document/DocumentView/FileExtension";
import { DropZoneConfig, FilesDragAndDrop } from "../../../Shared/FilesDragAndDrop";

export const MobileRequestFulfillmentDrawerContent = () => {
  const { t } = useCollaborationViewTranslation();

  const openedRequestFulfillmentRequest: Request = useGetOpenedRequestFulfillmentRequest();
  const { pendingFiles, addPendingFiles, addPendingFileList, removePendingFile, fulfillRequestAndCloseDialog } =
    useRequestFulfillmentContentRules();

  const disableFulfillAction = pendingFiles.length === 0;

  return (
    <Fragment>
      <DialogTitle
        sx={{
          display: "flex",
          flexFlow: "row nowrap",
          paddingY: 2,
        }}
      >
        <Typography flex="1" alignSelf="center">
          {t("requestFulfillment.mobileSheet.title")}
        </Typography>
        <TakeTurnsDefaultButton onClick={fulfillRequestAndCloseDialog} disabled={disableFulfillAction}>
          {t("requestFulfillment.dialog.submitButton.label")}
        </TakeTurnsDefaultButton>
      </DialogTitle>
      <Divider />
      <MobileRequestName__Background>
        <Typography fontSize="0.875rem">{openedRequestFulfillmentRequest.requestName}</Typography>
        <Typography fontSize="0.875rem" color={TakeTurnsColors.darkGray}>
          {openedRequestFulfillmentRequest.requestDescription}
        </Typography>
      </MobileRequestName__Background>
      <RequestContentWithDropZone />
    </Fragment>
  );

  function RequestContentWithDropZone() {
    const dropZoneConfig: DropZoneConfig = {
      handleFilesDropping: addPendingFiles,
      className: "RequestContentWithDropZone__DropZoneContainer",
      sx: {
        flex: 1,
        position: "relative",
        display: "flex",
        flexFlow: "column nowrap",
        py: 0,
      },
    };
    return (
      <FilesDragAndDrop ContainerComponent={Box} dropZoneConfig={dropZoneConfig}>
        {!pendingFiles.length && <EmptyContent />}
        {!!pendingFiles.length && <PendingFilesList />}
      </FilesDragAndDrop>
    );
  }

  function EmptyContent() {
    return (
      <Stack flex={1} spacing={1} alignItems="center" justifyContent="center" py={2}>
        <AddFilesButton processFiles={addPendingFileList} variant={"text"} />
        <Typography fontSize="0.875rem" color={TakeTurnsColors.mediumGray}>
          {t("requestFulfillment.dialog.instruction.message")}
        </Typography>
      </Stack>
    );
  }

  function PendingFilesList() {
    return (
      <Fragment>
        <MobileRequestUploadButtonRow />
        {pendingFiles.map((file) => (
          <TakeTurnsListItem
            key={file.name}
            titleSlot={
              <Stack>
                <Typography variant="body2"> {file.name}</Typography>
                <FileExtension
                  fileExtension={file.name.substring(file.name.lastIndexOf(".") + 1)}
                  sizeInBytes={file.size}
                />
              </Stack>
            }
            rightEndSlot={
              <IconButton size="small" disableRipple onClick={() => removePendingFile(file.name)}>
                <IconX size={20} />
              </IconButton>
            }
            sx={{ px: 3 }}
          />
        ))}
      </Fragment>
    );
  }

  function MobileRequestUploadButtonRow() {
    const { t } = useCollaborationTranslation("collaborationDocument");

    const fileInputRef = useRef<HTMLInputElement>(null);

    return (
      <Fragment>
        <input
          onChange={() => {
            addPendingFileList(fileInputRef.current.files);
            fileInputRef.current.value = "";
          }}
          ref={fileInputRef}
          type="file"
          multiple
          hidden
        />

        <TakeTurnsListItem
          titleSlot={
            <Stack direction={"row"} alignItems={"center"} spacing={1}>
              <IconUploadCloud color={TakeTurnsColors.darkGray} fontSize="1.5rem" />
              <Typography color={TakeTurnsColors.darkGray} fontSize="0.875rem">
                {t("uploadFile")}
              </Typography>
            </Stack>
          }
          onClick={() => fileInputRef.current.click()}
          sx={{ px: 3 }}
        />
      </Fragment>
    );
  }
};

const MobileRequestName__Background = styled(Box)(({ theme }) => ({
  backgroundColor: TakeTurnsColors.background,
  padding: theme.spacing(2),
}));
