/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useMutation } from "@apollo/client";
import { MutationRenameRequestArgs, RenameRequestInput } from "@taketurns/taketurns-graphql-repository";
import { requestEditMutationFragment } from "./useModifyRequestDescription";

export const useRenameRequest = (collaborationId: string, requestId: string) => {
  const RENAME_REQUEST = gql`
    mutation renameRequest($input: RenameRequestInput!) {
      renameRequest(input: $input) {
        ...RequestEditMutationFragment
      }
    }
    ${requestEditMutationFragment}
  `;

  const [renameRequestMutation, { data, loading, error }] = useMutation<
    { renameRequest: RenameRequestInput },
    MutationRenameRequestArgs
  >(RENAME_REQUEST);

  const renameRequest = (newName: string) => {
    const input: RenameRequestInput = {
      collaborationId,
      requestId,
      newName,
    };
    return renameRequestMutation({ variables: { input } });
  };

  return {
    renameRequest,
    renameRequestResponseData: data?.renameRequest,
    renameRequestLoading: loading,
    errorOnRenamingRequest: error,
  };
};
