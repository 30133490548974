/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useMutation } from "@apollo/client";
import {
  MarkUnmarkForReviewAttachmentOutput,
  MutationUnmarkAttachmentForReviewArgs,
  UnmarkAttachmentForReviewInput,
} from "@taketurns/taketurns-graphql-repository";
import { useGetConnectedUserPartyId } from "@taketurns-repositories/collaboration/context/useGetConnectedUserPartyId";
import { useGetContentMetadataInCache } from "@taketurns-repositories/collaboration/graphql/cache/useGetContentMetadataInCache";
import { markUnmarkAttachmentForReviewFragment } from "@taketurns-repositories/collaboration/graphql/mutations/content/attachments/useMarkAttachmentForReview";
import { useGetAttachmentRule } from "@taketurns-rules/collaboration/queries/collaborationView/useGetAttachmentRule";

export const useUnmarkAttachmentForReview = (input: UnmarkAttachmentForReviewInput) => {
  const connectedUserPartyId = useGetConnectedUserPartyId();
  const attachment = useGetAttachmentRule(input.attachmentId);
  const getContentMetadataInCache = useGetContentMetadataInCache();

  const mutation = gql`
    mutation unmarkAttachmentForReview($input: UnmarkAttachmentForReviewInput!) {
      unmarkAttachmentForReview(input: $input) {
        ...MarkUnmarkAttachmentForReviewFragment
      }
    }
    ${markUnmarkAttachmentForReviewFragment}
  `;
  const [unmarkAttachmentForReviewMutationFromGraphQL, { data, loading, error }] = useMutation<
    { unmarkAttachmentForReview: MarkUnmarkForReviewAttachmentOutput },
    MutationUnmarkAttachmentForReviewArgs
  >(mutation);
  const unmarkAttachmentForReviewMutation = () => {
    const contentMetadata = getContentMetadataInCache(input.collaborationId);
    return unmarkAttachmentForReviewMutationFromGraphQL({
      variables: { input },
      optimisticResponse: {
        unmarkAttachmentForReview: {
          collaborationId: input.collaborationId,
          partyId: connectedUserPartyId,
          attachmentId: input.attachmentId,
          attachment: {
            ...attachment,
            markedForReview: false,
          },
          contentMetadata,
        },
      },
    });
  };
  return {
    unmarkAttachmentForReviewMutation,
    unmarkAttachmentForReviewResponseData: data?.unmarkAttachmentForReview,
    unmarkAttachmentForReviewLoading: loading,
    errorOnUnmarkingAttachmentForReview: error,
  };
};
