/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Avatar, Box, styled, Typography } from "@mui/material";
import { useSharedCollaborationTranslation } from "@taketurns-i18n/collaboration/shared/useSharedCollaborationTranslation";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";

export interface DisplayedUser {
  avatar: string;
  presentation: string;
  jobTitle?: string;
}

interface EventNoteUserAndHeadlineProps {
  user: DisplayedUser;
  color: string;
  isAvatarHidden?: boolean;
}

export const EventNoteUserAndHeadline = (props: EventNoteUserAndHeadlineProps) => {
  const formattedUserJobTitle = props.user.jobTitle ? `, ${props.user.jobTitle}, ` : " ";
  const { t } = useSharedCollaborationTranslation();

  return (
    <EventNoteUserWithHeadline__Container>
      {!props.isAvatarHidden && <Avatar sx={{ width: 30, height: 30 }} src={props.user.avatar} />}
      <EventNoteUserWithHeadline__Headline noWrap variant={"caption"} color={props.color}>
        <EventNoteUserWithHeadline__UserNameAndJob>
          {props.user.presentation}
          <EventNoteUserWithHeadline__GraySpan>{formattedUserJobTitle}</EventNoteUserWithHeadline__GraySpan>
        </EventNoteUserWithHeadline__UserNameAndJob>
        <EventNoteUserWithHeadline__GraySpan>{t("EventNote.addedANote")}</EventNoteUserWithHeadline__GraySpan>
      </EventNoteUserWithHeadline__Headline>
    </EventNoteUserWithHeadline__Container>
  );
};

const EventNoteUserWithHeadline__Container = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: theme.spacing(1),
}));

const EventNoteUserWithHeadline__UserNameAndJob = styled("span")(() => ({
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "pre",
}));

const EventNoteUserWithHeadline__GraySpan = styled("span")(() => ({
  color: TakeTurnsColors.darkGray,
}));

const EventNoteUserWithHeadline__Headline = styled(Typography)(() => ({
  display: "flex",
}));
