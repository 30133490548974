/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Box, Skeleton, Stack, Typography } from "@mui/material";
import { FetchLoadingManager } from "@taketurns-app/utils/FetchLoadingManager";
import { CollaborationMenu } from "@taketurns-components/collaboration/CollaborationMenu/CollaborationMenu";
import { CollaborationRecapButton } from "@taketurns-components/collaboration/Recap/CollaborationRecapButton";
import { CollaborationTitle, CollaborationTitleAndPartiesProps } from "@taketurns-components/collaboration/Shared/CollaborationTitle";
import { SomethingWentWrong } from "@taketurns-components/shared/SomethingWentWrong";
import { useSharedCollaborationTranslation } from "@taketurns-i18n/collaboration/shared/useSharedCollaborationTranslation";
import { useFetchConnectedUserPartyRule } from "@taketurns-rules/collaboration/queries/context/useFetchConnectedUserPartyRule";
import { useFetchConnectedUserRoleRule } from "@taketurns-rules/collaboration/queries/context/useFetchConnectedUserRoleRule";
import { useFetchCollaborationNameRule } from "@taketurns-rules/collaboration/queries/fields/useFetchCollaborationNameRule";
import { useFetchCollaborationPartyNamesRule } from "@taketurns-rules/collaboration/queries/fields/useFetchCollaborationPartyNamesRule";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { useIsOnMobileRule } from "@taketurns-rules/commons/theme/useIsOnMobileRule";
import { Participants } from "./components/Participants/Participants";
import { Deadline } from "./Deadline";

export const CollaborationHeader = () => {
  const isOnMobile = useIsOnMobileRule();
  const { connectedUserParty, owningPartyName, invitedPartyName, name, role, loading, error } =
    useCollaborationHeaderData();

  if (loading) {
    return <Skeleton variant="text" />;
  }

  if (error) {
    return <SomethingWentWrong />;
  }

  const collaborationTitleProp: CollaborationTitleAndPartiesProps = {
    name: name,
    owningPartyName: owningPartyName,
    invitedPartyName: invitedPartyName,
    userParty: connectedUserParty,
  };

  if (isOnMobile) {
    return <CollaborationTitle {...collaborationTitleProp} />;
  }

  return (
    <Stack spacing={2} justifyContent="space-between" alignItems="center" direction="row" position="relative">
      <CollaborationTitle {...collaborationTitleProp} />
      <Box display="flex" flexDirection="row" alignItems="center" gap={2}>
        <Deadline />
        <CollaborationRecapButton />
        <Participants />
        <CollaborationMenu excludeRecap />
      </Box>
      <RoleTag role={role} />
    </Stack>
  );
};

const RoleTag = ({ role }: { role: string }) => {
  const { t } = useSharedCollaborationTranslation();
  return (
    <Box
      position="absolute"
      top={{ md: -30, xl: -40 }}
      right={0}
      display="flex"
      alignItems="center"
      border={`1px solid ${TakeTurnsColors.borderColor}`}
      borderRadius={"0 0 10px 10px"}
      px={3}
      height={24}
      bgcolor={TakeTurnsColors.white}
    >
      <Typography fontSize="0.75rem" color={TakeTurnsColors.darkGray}>
        {t("ParticipantInCollaboration.youAre")}{" "}
        <Typography fontSize="0.75rem" component="span" color={TakeTurnsColors.connectedUser}>
          {t(`ParticipantInCollaboration.${role}`)}
        </Typography>
      </Typography>
    </Box>
  );
};

const useCollaborationHeaderData = () => {
  const { connectedUserParty, loadingConnectedUserParty, errorOnFetchingConnectedUserParty } =
    useFetchConnectedUserPartyRule();
  const { owningPartyName, invitedPartyName, loadingCollaborationPartyNames, errorOnFetchingCollaborationPartyNames } =
    useFetchCollaborationPartyNamesRule();
  const { name, loadingCollaborationName, errorOnFetchingCollaborationName } = useFetchCollaborationNameRule();

  const { connectedUserRole, loadingConnectedUserRole, errorOnConnectedUserRole } = useFetchConnectedUserRoleRule();

  const fetchLoadingManager = new FetchLoadingManager(
    [loadingCollaborationName, loadingCollaborationPartyNames, loadingConnectedUserParty, loadingConnectedUserRole],
    [
      errorOnFetchingCollaborationName,
      errorOnFetchingCollaborationPartyNames,
      errorOnFetchingConnectedUserParty,
      errorOnConnectedUserRole,
    ],
  );
  if (fetchLoadingManager.hasFetchInError()) {
    fetchLoadingManager.logErrors();
  }

  return {
    connectedUserParty,
    owningPartyName,
    invitedPartyName,
    name,
    role: connectedUserRole,
    loading: fetchLoadingManager.hasFetchLoading(),
    error: fetchLoadingManager.hasFetchInError(),
  };
};
