/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useGetOpenedAttachmentIdRule } from "./useGetOpenedAttachmentIdRule";

export const useIsAttachmentDetailsOpenRule = () => {
  const openedAttachmentId = useGetOpenedAttachmentIdRule();
  return Boolean(openedAttachmentId);
};
