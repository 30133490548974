/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { DateTime } from "luxon";
import { useCollaborationTranslation } from "@taketurns-i18n/collaboration/useCollaborationTranslation";
import i18n from "@taketurns-i18n/i18n";
import { getSmallDate } from "@taketurns-rules/collaboration/utils/date/DateUtils";

export const useDurationLabelFromDateRule: () => (dateString: string, usePrefixOrSuffix: boolean) => string = () => {
  const { t } = useCollaborationTranslation("durationLabels");
  return (dateString: string, usePrefixOrSuffix: boolean) => {
    const language = i18n.language;
    const date = DateTime.fromISO(dateString, { zone: "utc" });
    const timeSpentSinceDateInSeconds = -date.diffNow("seconds").seconds;
    if (timeSpentSinceDateInSeconds < 60) {
      return t("now");
    }
    const timeSpentSinceDateInMinutes = -date.diffNow("minutes").minutes;
    if (timeSpentSinceDateInMinutes < 60) {
      return usePrefixOrSuffix
        ? t("minWithPrefixOrSuffix", { numberOfMinutes: Math.trunc(timeSpentSinceDateInMinutes) })
        : t("min", { numberOfMinutes: Math.trunc(timeSpentSinceDateInMinutes) });
    }
    const timeSpentSinceDateInHours = -date.diffNow("hours").hours;
    if (timeSpentSinceDateInHours < 24) {
      return usePrefixOrSuffix
        ? t("hourWithPrefixOrSuffix", { numberOfHours: Math.trunc(timeSpentSinceDateInHours) })
        : t("hour", { numberOfHours: Math.trunc(timeSpentSinceDateInHours) });
    }
    const timeSpentSinceDateInYears = -date.diffNow("years").years;
    const frenchPrefix = language === "fr" && usePrefixOrSuffix ? "le " : "";
    if (timeSpentSinceDateInYears < 1) {
      return `${frenchPrefix}${getSmallDate(dateString, language, { month: "short", day: "numeric" })}`;
    }
    return `${frenchPrefix}${getSmallDate(dateString, language, {
      year: "numeric",
      month: "short",
      day: "numeric",
    })}`;
  };
};
