/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Party } from "@taketurns/taketurns-graphql-repository";
import { DocumentChip } from "@taketurns-components/collaboration/Shared/chip/DocumentChip";
import { useSharedCollaborationTranslation } from "@taketurns-i18n/collaboration/shared/useSharedCollaborationTranslation";
import { useFetchConnectedUserPartyRule } from "@taketurns-rules/collaboration/queries/context/useFetchConnectedUserPartyRule";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";

export const RequestTag = ({ party }: { party: Party }) => {
  const { t } = useSharedCollaborationTranslation();
  const requestColor = useColorForRequest(party);
  return <DocumentChip label={t("TurnContentList.request")} backgroundColor={requestColor} />;
};

const useColorForRequest = (requestParty: Party) => {
  const { connectedUserParty } = useFetchConnectedUserPartyRule();
  if (connectedUserParty && requestParty) {
    if (connectedUserParty === requestParty) {
      return TakeTurnsColors.connectedUser;
    }
    return TakeTurnsColors.otherParty;
  }
  return TakeTurnsColors.connectedUser;
};
