/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Box, Button, DialogActions, DialogContentText } from "@mui/material";
import React from "react";
import { TakeTurnsDefaultButton } from "@taketurns-components/commons/button";
import { ResponsiveDialog } from "@taketurns-components/commons/dialog/ResponsiveDialog";
import { useSharedCollaborationTranslation } from "@taketurns-i18n/collaboration/shared/useSharedCollaborationTranslation";

export const ConfirmBeforeClosingDialog = (props: {
  isConfirmBeforeClosingDialogOpen: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}) => {
  const { t } = useSharedCollaborationTranslation();

  return (
    <Box>
      <ResponsiveDialog
        disableEscapeKeyDown
        open={props.isConfirmBeforeClosingDialogOpen}
        title={t("PdfViewer.confirmBeforeClosingDialog.dialogTitle")}
        content={<DialogContentText>{t("PdfViewer.confirmBeforeClosingDialog.confirmationMessage")}</DialogContentText>}
        footer={
          <DialogActions>
            <TakeTurnsDefaultButton onClick={props.onCancel}>
              {t("PdfViewer.confirmBeforeClosingDialog.cancelButton")}
            </TakeTurnsDefaultButton>
            <Button variant={"text"} onClick={props.onConfirm}>
              {t("PdfViewer.confirmBeforeClosingDialog.confirmButton")}
            </Button>
          </DialogActions>
        }
      />
    </Box>
  );
};
