/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { CircularProgress, Typography } from "@mui/material";
import { Fragment } from "react";
import { SubscriptionPlanStatus, SubscriptionPlanTier } from "@taketurns/taketurns-graphql-repository";
import { useUserPlanTranslation } from "@taketurns-i18n/user/UserPlans/useUserPlanTranslation";
import { SubscriptionPlanForDisplay } from "@taketurns-repositories/user/local/entities/subscriptionPlanForDisplay";
import { useFetchUserSubscriptionPlanStatusRule } from "@taketurns-rules/user/commands/plan/useFetchUserSubscriptionPlanStatusRule";
import { useOpenUpgradePlanDialogRule } from "@taketurns-rules/user/commands/plan/useOpenUpgradePlanDialogRule";
import { useOpenStripePortalUrlRule } from "@taketurns-rules/user/commands/useOpenStripePortalUrlRule";
import { useSubscribeToPlanRule } from "@taketurns-rules/user/commands/useSubscribeToPlanRule";
import { useGetSubscriptionPlanRegionRule } from "@taketurns-rules/user/queries/plan/getSubscriptionPlanRegionRule";
import { useFetchIsUserAllowedToSubscribeWithTrialRule } from "@taketurns-rules/user/queries/useFetchIsUserAllowedToSubscribeWithTrialRule";
import { useFetchUserIsAllowedToSelectPlanRule } from "@taketurns-rules/user/queries/useFetchUserIsAllowedToSelectPlanRule";
import { useFetchUserOrganizationSubscriptionTierRule } from "@taketurns-rules/user/queries/useFetchUserOrganizationSubscriptionTierRule";
import { UpgradePlanDialog } from "../UpgradePlanDialog/UpgradePlanDialog";
import { SubscribeToPlanButton } from "./SubscribeToPlanButton";
import { SubscribeToPlanLink } from "./SubscribeToPlanLink";

interface SubscribeToPlanProps {
  subscriptionPlan: SubscriptionPlanForDisplay;
}

export const SubscribeToPlan = ({ subscriptionPlan }: SubscribeToPlanProps) => {
  const {
    userOrganizationSubscriptionTier,
    errorOnFetchingUserOrganizationSubscriptionTier,
    loadingUserOrganizationSubscriptionTier,
  } = useFetchUserOrganizationSubscriptionTierRule();
  const { subscribeToPlan, loadingStripeCheckoutUrl } = useSubscribeToPlanRule();
  const { isAllowedToSelectPlan, errorOnAllowedToSelectPlan, loadingIsAllowedToSelectPlan } =
    useFetchUserIsAllowedToSelectPlanRule();
  const { isAllowedToSubscribeWithTrial, loadingIsAllowedToSubscribeWithTrial, errorOnIsAllowedToSubscribeWithTrial } =
    useFetchIsUserAllowedToSubscribeWithTrialRule();
  const { userSubscriptionPlanStatus, loadingSubscriptionPlanStatus, errorOnSubscriptionPlanStatus } =
    useFetchUserSubscriptionPlanStatusRule();
  const region = useGetSubscriptionPlanRegionRule();
  const { isUpgradeDialogDisplayed, cancelUpgradePlan, openUpgradePlanDialog } = useOpenUpgradePlanDialogRule();
  const { openStripePortal } = useOpenStripePortalUrlRule();
  const { plan } = subscriptionPlan;
  const { t } = useUserPlanTranslation();

  const errorOnUserOrganizationOrSelectPlanRule =
    errorOnFetchingUserOrganizationSubscriptionTier ||
    errorOnAllowedToSelectPlan ||
    errorOnSubscriptionPlanStatus ||
    errorOnIsAllowedToSubscribeWithTrial;
  if (errorOnUserOrganizationOrSelectPlanRule) {
    console.error(errorOnUserOrganizationOrSelectPlanRule);
    return (
      <Typography variant={"caption"} color={"error"}>
        {t("buttons.subscriptionUnavailable")}
      </Typography>
    );
  }
  if (
    loadingUserOrganizationSubscriptionTier ||
    loadingIsAllowedToSelectPlan ||
    loadingSubscriptionPlanStatus ||
    loadingIsAllowedToSubscribeWithTrial
  ) {
    return <CircularProgress />;
  }

  if (!isAllowedToSelectPlan) {
    return;
  }

  if (plan.planTier === userOrganizationSubscriptionTier) {
    return <Typography color={"secondary"}>{t("buttons.currentPlan")}</Typography>;
  }

  if (userSubscriptionPlanStatus === SubscriptionPlanStatus.Trialing) {
    return (
      <SubscribeToPlanButton onClick={() => openStripePortal()}>
        {t("buttons.subscribe", { plan: t(`plans.${plan.planTier}`) })}
      </SubscribeToPlanButton>
    );
  }

  const selectPlanButtonIsDisabled =
    loadingStripeCheckoutUrl ||
    region === null ||
    loadingUserOrganizationSubscriptionTier ||
    !!errorOnFetchingUserOrganizationSubscriptionTier;
  const isNotFreePlan = userOrganizationSubscriptionTier !== SubscriptionPlanTier.T0;
  if (isNotFreePlan) {
    return (
      <Fragment>
        <SubscribeToPlanButton onClick={openUpgradePlanDialog} disabled={selectPlanButtonIsDisabled}>
          {t("buttons.upgrade")}
        </SubscribeToPlanButton>
        <UpgradePlanDialog
          subscriptionPlan={subscriptionPlan}
          isDialogOpened={isUpgradeDialogDisplayed}
          closeDialog={cancelUpgradePlan}
        />
      </Fragment>
    );
  }

  if (isAllowedToSubscribeWithTrial) {
    return (
      <SubscribeToPlanLink onClick={() => subscribeToPlan(plan.planTier)}>
        {t("buttons.subscribeNow", { plan: t(`plans.${plan.planTier}`) })}
      </SubscribeToPlanLink>
    );
  } else {
    return (
      <SubscribeToPlanButton onClick={() => subscribeToPlan(plan.planTier)}>
        {t("buttons.subscribe", { plan: t(`plans.${plan.planTier}`) })}
      </SubscribeToPlanButton>
    );
  }
};
