/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { IconButton } from "@mui/material";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { IconChevronLeft } from "../../../../../components/commons/icons";

interface MobileBackButtonProps {
  onClick: () => void;
}

export const MobileBackButton = ({ onClick }: MobileBackButtonProps) => {
  return (
    <IconButton sx={{ paddingLeft: 0 }} onClick={onClick} disableRipple>
      <IconChevronLeft color={TakeTurnsColors.darkGray} size={24} />
    </IconButton>
  );
};
