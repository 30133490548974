/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useFetchUserTotalSpaceFeature } from "@taketurns-repositories/collaboration/graphql/queries/features/useFetchUserTotalSpaceFeature";
import { useFetchHasUserOrganizationEnoughSpaceAvailableRule } from "../../queries/collaborationCreation/useFetchHasUserOrganizationEnoughSpaceAvailableRule";
import { useCheckAvailableSpaceAndNotifyRule } from "../useCheckAvailableSpaceAndNotifyRule";

export const useCheckAvailableSpaceAndNotifyInCreationRule = () => {
  const { totalSpaceInMB } = useFetchUserTotalSpaceFeature();
  const fetchHasUserOrganizationEnoughSpaceAvailable = useFetchHasUserOrganizationEnoughSpaceAvailableRule();

  return useCheckAvailableSpaceAndNotifyRule(fetchHasUserOrganizationEnoughSpaceAvailable, totalSpaceInMB);
};
