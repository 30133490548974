/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { CircularProgress, Skeleton, Stack, styled, Typography } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { PrepareArchiveDownloadInput } from "@taketurns/taketurns-graphql-repository";
import { useCollaborationArchiveDownloadTranslation } from "@taketurns-i18n/collaboration/CollaborationArchiveDownload/useCollaborationArchiveDownloadTranslation";
import { useDownloadCollaborationArchiveRule } from "@taketurns-rules/collaboration/commands/useDownloadCollaborationArchiveRule";
import { useFetchClosedCollaborationRule } from "@taketurns-rules/collaboration/queries/collaboration/closed/useFetchClosedCollaborationRule";
import { subtractNowInDays } from "@taketurns-rules/collaboration/utils/date/DateUtils";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { useIsOnMobileRule } from "@taketurns-rules/commons/theme/useIsOnMobileRule";
import { TakeTurnsDefaultButton } from "../../commons/button";
import { DayIndicator } from "../DayIndicator/DayIndicator";
import { CollaborationArchiveDownloadDialog } from "./CollaborationArchiveDownloadDialog";

export const CollaborationArchiveDownload = () => {
  const [openDownloadCollaborationArchiveDialog, setOpenDownloadCollaborationArchiveDialog] = useState(false);
  const { closedCollaboration, loadingClosedCollaboration, errorOnFetchingClosedCollaboration } =
    useFetchClosedCollaborationRule();

  const hiddenDownloadLinkRef = useRef(null);
  const { downloadCollaborationArchive, archiveDownloadUrl, loadingDownloadCollaborationArchive } =
    useDownloadCollaborationArchiveRule();

  const { t } = useCollaborationArchiveDownloadTranslation();
  const isOnMobile = useIsOnMobileRule();

  const startDownloadCollaborationArchive = useCallback(
    (downloadArchiveOptions: Partial<PrepareArchiveDownloadInput>) => {
      closeDownloadCollaborationArchiveDialog();
      const downloadArchiveOptionWithCollaborationId = {
        ...downloadArchiveOptions,
        collaborationId: closedCollaboration.id,
      };
      downloadCollaborationArchive(downloadArchiveOptionWithCollaborationId);
    },
    [closedCollaboration],
  );

  const closeDownloadCollaborationArchiveDialog = () => {
    setOpenDownloadCollaborationArchiveDialog(false);
  };

  useEffect(() => {
    if (archiveDownloadUrl && hiddenDownloadLinkRef.current) {
      hiddenDownloadLinkRef.current.href = archiveDownloadUrl;
      hiddenDownloadLinkRef.current.click();
    }
  }, [archiveDownloadUrl, hiddenDownloadLinkRef]);

  if (errorOnFetchingClosedCollaboration) {
    return null;
  }

  if (loadingClosedCollaboration) {
    return <Skeleton variant="text" width={200} />;
  }

  const remainingDaysBeforeCollaborationDeletion = Math.trunc(subtractNowInDays(closedCollaboration.wipeDate));

  return (
    <CollaborationArchiveDownload__HorizontalContainer direction="row" spacing={1}>
      <a ref={hiddenDownloadLinkRef} style={{ display: "none" }} />
      <CollaborationArchiveDownload__Text>
        {t(isOnMobile ? "contentDeletedShort" : "contentDeleted") + " "}
        <DayIndicator> {remainingDaysBeforeCollaborationDeletion}</DayIndicator>{" "}
        {t("day", { count: remainingDaysBeforeCollaborationDeletion })}
      </CollaborationArchiveDownload__Text>
      <TakeTurnsDefaultButton
        disabled={loadingDownloadCollaborationArchive}
        endIcon={loadingDownloadCollaborationArchive && <CircularProgress size={12} color="secondary" />}
        onClick={() => setOpenDownloadCollaborationArchiveDialog(true)}
      >
        {t("downloadArchive")}
      </TakeTurnsDefaultButton>
      <CollaborationArchiveDownloadDialog
        openDialog={openDownloadCollaborationArchiveDialog}
        onCloseDialog={closeDownloadCollaborationArchiveDialog}
        onClickDownloadArchive={startDownloadCollaborationArchive}
      />
    </CollaborationArchiveDownload__HorizontalContainer>
  );
};

const CollaborationArchiveDownload__HorizontalContainer = styled(Stack)(({ theme }) => ({
  alignItems: "center",

  [theme.breakpoints.down("md")]: {
    flexGrow: 1,
    justifyContent: "space-between",
  },
}));

const CollaborationArchiveDownload__Text = styled(Typography)(() => ({
  fontSize: "12px",
  color: TakeTurnsColors.darkGray,
}));
