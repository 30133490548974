/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useLocation } from "react-router-dom";

export const useExtendLocationStateWithEmailRule = () => {
  const { state } = useLocation();

  return (email: string) => ({ ...state, email });
};
