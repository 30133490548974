/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Box, Tooltip } from "@mui/material";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";

export const YellowChip = ({ tooltipMessage }: { tooltipMessage?: string }) => {
  return (
    <Tooltip title={tooltipMessage}>
      <Box
        data-cy="YellowChip"
        sx={{
          width: 10,
          height: 10,
          borderRadius: 10,
          backgroundColor: TakeTurnsColors.yellow,
        }}
      />
    </Tooltip>
  );
};
