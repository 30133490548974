/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Fragment } from "react";
import { useLoaderData, useNavigate } from "react-router-dom";
import { MobileBackButton } from "@taketurns-app/layouts/body/components/MobileTopButtons/MobileBackButton";
import { MobileChatDrawerAndButton } from "@taketurns-components/chat/MobileChatDrawer/MobileChatDrawerAndButton";
import { CollaborationContent } from "@taketurns-components/collaboration/CollaborationView/CollaborationContent/CollaborationContent";
import { CollaborationHeader } from "@taketurns-components/collaboration/CollaborationView/CollaborationHeader/CollaborationHeader";
import { CollaborationViewProvider } from "@taketurns-components/collaboration/CollaborationView/CollaborationViewProvider";
import { CollaborationInvitationWelcomeDialog } from "@taketurns-components/webapp/WelcomeDialogs/CollaborationInvitationWelcomeDialog";
import { useIsOnMobileRule } from "@taketurns-rules/commons/theme/useIsOnMobileRule";
import { useShouldDisplayCollaborationInvitationWelcomeDialogRule } from "@taketurns-rules/webapp/queries/useShouldDisplayCollaborationInvitationWelcomeDialogRule";
import { TwoColumnsLayout } from "../../../layouts/body/content/TwoColumnsLayout";
import { MobileBodyLayout } from "../../../layouts/body/MobileBodyLayout";
import { AUTHENTICATED_ROUTES } from "../../../routing/routes/authenticatedRoutes.constants";
import { CollaborationSidebar } from "./CollaborationSidebar";

export const CollaborationView = () => {
  const { collaborationId } = useLoaderData() as {
    collaborationId: string;
  };

  const returnToCollaborationList = useReturnToCollaborationList();

  return (
    <CollaborationViewProvider collaborationId={collaborationId}>
      <ResponsiveCollaborationView
        collaborationId={collaborationId}
        returnToCollaborationList={returnToCollaborationList}
      />
    </CollaborationViewProvider>
  );

  function useReturnToCollaborationList() {
    const navigate = useNavigate();
    return () => {
      navigate(AUTHENTICATED_ROUTES.COLLABORATION_LIST);
    };
  }
};

interface ResponsiveCollaborationViewProps {
  collaborationId: string;
  returnToCollaborationList: () => void;
}

const ResponsiveCollaborationView = ({
  collaborationId,
  returnToCollaborationList,
}: ResponsiveCollaborationViewProps) => {
  const isOnMobile = useIsOnMobileRule();

  if (isOnMobile) {
    return (
      <MobileCollaborationView
        collaborationId={collaborationId}
        returnToCollaborationList={returnToCollaborationList}
      />
    );
  }

  return (
    <DesktopCollaborationView collaborationId={collaborationId} returnToCollaborationList={returnToCollaborationList} />
  );
};

const MobileCollaborationView = ({ collaborationId, returnToCollaborationList }: ResponsiveCollaborationViewProps) => {
  const shouldDisplayWelcomeDialog = useShouldDisplayCollaborationInvitationWelcomeDialogRule();
  return (
    <Fragment>
      <MobileBodyLayout
        disablePadding
        header={<CollaborationHeader />}
        LeftButton={<MobileBackButton onClick={returnToCollaborationList} />}
        content={<CollaborationContent />}
        RightButton={<MobileChatDrawerAndButton collaborationId={collaborationId} />}
      />
      {shouldDisplayWelcomeDialog && <CollaborationInvitationWelcomeDialog />}
    </Fragment>
  );
};

const DesktopCollaborationView = ({ returnToCollaborationList }: ResponsiveCollaborationViewProps) => {
  const shouldDisplayWelcomeDialog = useShouldDisplayCollaborationInvitationWelcomeDialogRule();

  return (
    <Fragment>
      <TwoColumnsLayout
        header={<CollaborationHeader />}
        backAction={returnToCollaborationList}
        content={<CollaborationContent />}
        collapsableRightBar={<CollaborationSidebar />}
      />
      {shouldDisplayWelcomeDialog && <CollaborationInvitationWelcomeDialog />}
    </Fragment>
  );
};
