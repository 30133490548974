/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Button, CircularProgress } from "@mui/material";
import { SubscriptionPlanTier } from "@taketurns/taketurns-graphql-repository";
import { useUserPlanTranslation } from "@taketurns-i18n/user/UserPlans/useUserPlanTranslation";
import { wait } from "@taketurns-rules/commons/util/wait";
import { useNavigateToCompleteProfilePageRule } from "@taketurns-rules/user/commands/useNavigateToCompleteProfilePageRule";
import { useNavigateToUserPlanPageRule } from "@taketurns-rules/user/commands/useNavigateToUserPlanPageRule";
import { useSubscribeToPlanWithTrialRule } from "@taketurns-rules/user/commands/useSubscribeToPlanWithTrialRule";
import { useShouldDisplayProfilePageRule } from "@taketurns-rules/webapp/queries/useShouldDisplayProfilePageRule";

export const SubscribeToPlanWithTrial = () => {
  const { subscribeToPlanWithTrialByPlanTier, loadingSubscribeToPlanWithTrial } = useSubscribeToPlanWithTrialRule();
  const { t } = useUserPlanTranslation();
  const redirectToUserPlanPage = useNavigateToUserPlanPageRule();
  const shouldDisplayProfilePage = useShouldDisplayProfilePageRule();

  const redirectToCompleteProfilePage = useNavigateToCompleteProfilePageRule();
  const subscribeToPlanAndRedirectToUserPlanPageOrCompleteProfileIfFirstAuth = () => {
    const waitingTimeBeforeAction = 2000;
    subscribeToPlanWithTrialByPlanTier(SubscriptionPlanTier.T2).then(async () => {
      if (shouldDisplayProfilePage) {
        //TODO MRO TT-2210 : this is quickfix for latency between back-end and openSearch
        wait(waitingTimeBeforeAction).then(() => redirectToCompleteProfilePage());
      } else {
        //TODO MRO TT-2210 : this is quickfix for latency between back-end and openSearch
        wait(waitingTimeBeforeAction).then(() => redirectToUserPlanPage());
      }
    });
  };

  if (loadingSubscribeToPlanWithTrial) {
    return <CircularProgress />;
  }

  return (
    <Button data-cy="subscribeToPlanWithTrial.trialButton" color={"secondary"} onClick={subscribeToPlanAndRedirectToUserPlanPageOrCompleteProfileIfFirstAuth}>
      {t("buttons.freeTrial")}
    </Button>
  );
};
