/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { gql, useQuery } from "@apollo/client";
import { TipKey } from "@taketurns-i18n/commons/en/tips";

export const useConnectedUserTipsHidden = () => {
  const GET_CONNECTED_USER_HIDDEN_TIPS = gql`
    query connectedUserHiddenTips {
      connectedUser {
        id
        preferences {
          hiddenTips
        }
      }
    }
  `;
  const {
    data,
    loading: hiddenTipsLoading,
    error: hiddenTipsError,
  } = useQuery<{
    connectedUser: {
      id: string;
      preferences: {
        hiddenTips: TipKey[];
      };
    };
  }>(GET_CONNECTED_USER_HIDDEN_TIPS);

  const hiddenTips = data?.connectedUser.preferences.hiddenTips;
  return {
    hiddenTips,
    hiddenTipsLoading,
    hiddenTipsError,
  };
};
