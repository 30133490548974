/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { PropsWithChildren } from "react";
import { useSetInvitationToOrganizationRule } from "@taketurns-rules/webapp/commands/useSetInvitationToOrganizationRule";

export const SetComingFromOrganizationInvitationLinkGuard = ({ children }: PropsWithChildren) => {
  useSetInvitationToOrganizationRule();
  return children;
};
