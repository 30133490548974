/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useFetchIsCollaborationFromUserOrganization } from "@taketurns-repositories/organization/graphql/queries/useFetchIsCollaborationFromUserOrganization";
import { useGetDisplayedCollaborationIdRule } from "./useGetDisplayedCollaborationIdRule";
import { useIsInCollaborationCreationRule } from "./useIsInCollaborationCreationRule";
import { useIsInCollaborationViewRule } from "./useIsInCollaborationViewRule";

export const useIsCollaborationFromUserOrganizationRule = () => {
  const fetchIsCollaborationFromUserOrganization = useFetchIsCollaborationFromUserOrganization();
  const isInCreation = useIsInCollaborationCreationRule();
  const isInCollaborationView = useIsInCollaborationViewRule();
  const collaborationId = useGetDisplayedCollaborationIdRule();
  return async () => {
    if (isInCreation) {
      return true;
    }
    if (isInCollaborationView) {
      return await fetchIsCollaborationFromUserOrganization(collaborationId);
    }
    throw new Error("Not in collaboration view nor in creation");
  };
};
