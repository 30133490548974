/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql } from "@apollo/client";
import { useRefreshableSubscription } from "@taketurns-components/webapp/QueriesAndSubscriptionsRefreshTrigger";

const ON_REVOKE_ORGANIZATION_INVITATION = gql`
  subscription onRevokeOrganizationInvitation($id: ID!) {
    onRevokeOrganizationInvitation(id: $id) {
      id
      invitations {
        role
        userId
        email
        user {
          id
          email
          lastName
          firstName
          avatar
        }
      }
    }
  }
`;

export const useSubscribeToRevokeInvitationToOrganization = (id?: string) => {
  return useRefreshableSubscription(ON_REVOKE_ORGANIZATION_INVITATION, { variables: { id }, skip: !id });
};
