/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { selectorFamily } from "recoil";
import { Party } from "@taketurns/taketurns-graphql-repository";
import { selectCollaborationFormInvitedPartyNameFieldError } from "@taketurns-repositories/collaboration/state/recoil/collaborationCreation/form/errors/fieldErrors/selectCollaborationFormInvitedPartyNameFieldError";
import { selectCollaborationFormOwningPartyNameFieldError } from "@taketurns-repositories/collaboration/state/recoil/collaborationCreation/form/errors/fieldErrors/selectCollaborationFormOwningPartyNameFieldError";

export const selectCollaborationPartyNameFieldErrorStateByParty = selectorFamily({
  key: "collaborationCreationFormErrorsByParty",
  get:
    (party) =>
    ({ get }) => {
      if (party === Party.Owning) {
        return get(selectCollaborationFormOwningPartyNameFieldError);
      }
      return get(selectCollaborationFormInvitedPartyNameFieldError);
    },
});
