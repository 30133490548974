/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useMutation } from "@apollo/client";

import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useDocumentViewTranslation } from "@taketurns-i18n/document/DocumentView/useDocumentViewTranslation";

const RENAME_DOCUMENT = gql`
  mutation renameDocument($id: ID!, $name: String!) {
    renameDocument(id: $id, name: $name) {
      id
      name
    }
  }
`;
export const useRenameDocument = () => {
  const [renameDocument, renameDocumentResponse] = useMutation(RENAME_DOCUMENT);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useDocumentViewTranslation();

  useEffect(() => {
    if (renameDocumentResponse.error) {
      enqueueSnackbar(t("snackbars.error"), { variant: "error" });
      console.error(renameDocumentResponse.error);
      renameDocumentResponse.reset();
    }
  }, [renameDocumentResponse.error]);

  const renameDocumentCommand = (documentId: string, name: string) => {
    renameDocument({
      variables: { id: documentId, name: name },
    });
  };
  return { renameDocumentCommand, renameDocumentResponse };
};
