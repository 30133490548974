/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Chip, IconButton, Stack, Typography } from "@mui/material";
import { IconChevronLeft } from "@taketurns-components/commons/icons";
import { SomethingWentWrong } from "@taketurns-components/shared/SomethingWentWrong";
import { useSharedCollaborationTranslation } from "@taketurns-i18n/collaboration/shared/useSharedCollaborationTranslation";
import { useFetchEventOnCollaboration } from "@taketurns-repositories/collaboration/graphql/queries/fields/useFetchEventOnCollaboration";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";

interface MobileEventNoteDialogHeaderProps {
  collaborationId: string;
  eventId: string;
  closePage: () => void;
}

export const MobileEventNoteDialogHeader = (props: MobileEventNoteDialogHeaderProps) => {
  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between" padding="16px 24px">
      <IconButton onClick={props.closePage} disableRipple sx={{ justifyContent: "flex-start", width: "50px" }}>
        <IconChevronLeft color={TakeTurnsColors.darkGray} size={24} />
      </IconButton>
      <TitleAndPartyName eventId={props.eventId} collaborationId={props.collaborationId} />
      <div style={{ width: "50px" }} />
    </Stack>
  );
};

interface TitleAndPartyNameProps {
  collaborationId: string;
  eventId: string;
}

const TitleAndPartyName = (props: TitleAndPartyNameProps) => {
  const { eventOnCollaboration, loadingEventOnCollaboration, errorOnFetchingEventOnCollaboration } =
    useFetchEventOnCollaboration(props.collaborationId, props.eventId);
  const { t } = useSharedCollaborationTranslation();

  if (loadingEventOnCollaboration) {
    return null;
  }

  if (errorOnFetchingEventOnCollaboration) {
    return <SomethingWentWrong />;
  }

  return (
    <Stack direction="row" flexWrap="wrap" spacing={1} justifyContent="center" alignItems="center" minWidth={0}>
      <Typography>{t("EventNoteDialog.noteFrom")}</Typography>
      <Chip
        size="small"
        sx={{ fontSize: "1rem" }}
        color={eventOnCollaboration?.isFromConnectedUserParty ? "primary" : "secondary"}
        label={eventOnCollaboration.partyName}
      />
    </Stack>
  );
};
