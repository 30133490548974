/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql } from "@apollo/client";
import { AddAttachmentOutput, SubscriptionOnAddAttachmentArgs } from "@taketurns/taketurns-graphql-repository";
import { useRefreshableSubscription } from "@taketurns-components/webapp/QueriesAndSubscriptionsRefreshTrigger";
import { updateCacheOnAttachmentAdded } from "../../cache/updateCacheOnAttachmentAdded";
import { addAttachmentMutationFragment } from "../../mutations/content/attachments/useAddAttachment";

const SUBSCRIBE_TO_ADD_ATTACHMENT = gql`
  subscription onAddAttachment($collaborationId: ID!, $partyId: ID!, $folderId: ID!) {
    onAddAttachment(collaborationId: $collaborationId, partyId: $partyId, folderId: $folderId) {
      ...AddAttachmentMutationFragment
    }
  }
  ${addAttachmentMutationFragment}
`;

export const useSubscribeToAddAttachment = (args: SubscriptionOnAddAttachmentArgs, currentUserId: string) => {
  return useRefreshableSubscription<{ onAddAttachment: AddAttachmentOutput }, SubscriptionOnAddAttachmentArgs>(
    SUBSCRIBE_TO_ADD_ATTACHMENT,
    {
      variables: args,
      fetchPolicy: "no-cache",
      onData: ({ data, client }) => {
        if (data.data.onAddAttachment.userId !== currentUserId) {
          updateCacheOnAttachmentAdded(client.cache, data.data.onAddAttachment);
        }
      },
    },
  );
};
