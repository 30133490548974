/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { MobileBackButton } from "@taketurns-app/layouts/body/components/MobileTopButtons/MobileBackButton";
import { SingleColumnLayout } from "@taketurns-app/layouts/body/content/SingleColumnLayout";
import { MobileBodyLayout } from "@taketurns-app/layouts/body/MobileBodyLayout";
import { AUTHENTICATED_ROUTES } from "@taketurns-app/routing/routes/authenticatedRoutes.constants";
import { CollaborationFilterType } from "@taketurns-components/collaboration/CollaborationList/collaborationFilterType";
import { TakeTurnsTextButton } from "@taketurns-components/commons/button";
import { useSharedCollaborationTranslation } from "@taketurns-i18n/collaboration/shared/useSharedCollaborationTranslation";
import { collaborationListAppliedFilterState } from "@taketurns-repositories/collaboration/state/recoil/collaborationListAppliedFilterState";
import { useIsOnMobileRule } from "@taketurns-rules/commons/theme/useIsOnMobileRule";

export const InvitationToClosedCollaborationPage = () => {
  const isOnMobile = useIsOnMobileRule();
  const returnToCollaborationList = useReturnToCollaborationList();

  if (isOnMobile) {
    return (
      <MobileBodyLayout
        header={<PageHeader />}
        LeftButton={<MobileBackButton onClick={returnToCollaborationList} />}
        content={<PageContent />}
      />
    );
  }

  return <SingleColumnLayout header={<PageHeader />} content={<PageContent />} />;
};

function useReturnToCollaborationList() {
  const navigate = useNavigate();
  return () => {
    navigate(AUTHENTICATED_ROUTES.COLLABORATION_LIST);
  };
}

const PageHeader = () => {
  const { t } = useSharedCollaborationTranslation();
  return (
    <Typography variant="h6" fontSize="1.125rem">
      {t("InvitationToClosedCollaboration.title")}
    </Typography>
  );
};

const PageContent = () => {
  const { t } = useSharedCollaborationTranslation();
  const goToPendingInvitations = useGoToPendingInvitations();
  return (
    <Stack spacing={2} alignItems="center" textAlign="center">
      <Typography variant="body1">{t("InvitationToClosedCollaboration.description")}</Typography>
      <TakeTurnsTextButton fontSize="1rem" onClick={goToPendingInvitations}>
        {t("InvitationToClosedCollaboration.goToInvitations")}
      </TakeTurnsTextButton>
    </Stack>
  );
};

function useGoToPendingInvitations() {
  const navigate = useNavigate();
  const setCollaborationListAppliedFilter = useSetRecoilState(collaborationListAppliedFilterState);
  return () => {
    setCollaborationListAppliedFilter(CollaborationFilterType.pendingInvitations);
    navigate(AUTHENTICATED_ROUTES.COLLABORATION_LIST);
  };
}
