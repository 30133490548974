/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

export enum CardType {
  attachment = "attachment",
  folder = "folder",
  request = "request",
  pendingAttachment = "pendingAttachment",
}
