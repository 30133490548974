/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useQuery } from "@apollo/client";
import { ConnectedUser, SubscriptionPlanTier } from "@taketurns/taketurns-graphql-repository";

const USER_PLAN_TIER = gql`
  query connectedUserPlanTier {
    connectedUser {
      id
      organization {
        id
        subscription {
          id
          tier
        }
      }
    }
  }
`;

export const useFetchUserPlanTier = () => {
  const {
    data,
    error: errorOnFetchingUserPlanTier,
    loading: loadingUserPlanTier,
  } = useQuery<{
    connectedUser: ConnectedUser;
  }>(USER_PLAN_TIER);
  return {
    planTier: data?.connectedUser?.organization?.subscription?.tier ?? SubscriptionPlanTier.T0,
    errorOnFetchingUserPlanTier,
    loadingUserPlanTier,
  };
};
