/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Box, Stack, Typography } from "@mui/material";
import { CreateInviteeInput, Invitee, Party } from "@taketurns/taketurns-graphql-repository";
import { InvitationForm } from "@taketurns-components/collaboration/Shared/ParticipantInviteeList/InvitationForm";
import {
  InvitationItem,
  InvitationItemComponentProps,
} from "@taketurns-components/collaboration/Shared/ParticipantInviteeList/InvitationItem";
import { ParticipantInvitationList } from "@taketurns-components/collaboration/Shared/ParticipantInviteeList/ParticipantInvitationList";
import { ParticipantItem } from "@taketurns-components/collaboration/Shared/ParticipantInviteeList/ParticipantItem";
import { useCollaborationCreationTranslation } from "@taketurns-i18n/collaboration/CollaborationCreation/useCollaborationCreationTranslation";
import { useCollaborationTranslation } from "@taketurns-i18n/collaboration/useCollaborationTranslation";
import { useGetUsersInCache } from "@taketurns-repositories/collaboration/graphql/cache/useGetUsersInCache";
import { useGetCollaborationInvitationsFormError } from "@taketurns-repositories/collaboration/state/read/collaborationCreation/form/error/useGetCollaborationInvitationsFormError";
import { useEditInvitationRoleInCollaborationCreationRule } from "@taketurns-rules/collaboration/commands/collaborationCreation/invitation/useEditInvitationRoleInCollaborationCreationRule";
import { useInviteToCollaborationInCreationRule } from "@taketurns-rules/collaboration/commands/collaborationCreation/invitation/useInviteToCollaborationInCreationRule";
import { useRevokeInvitationInCollaborationCreationRule } from "@taketurns-rules/collaboration/commands/collaborationCreation/invitation/useRevokeInvitationInCollaborationCreationRule";
import { useGetCollaborationFormInvitationsByPartyRule } from "@taketurns-rules/collaboration/queries/collaborationCreation/form/useGetCollaborationFormInvitationsByPartyRule";
import { useFetchConnectedUserAsParticipantInCreationRule } from "@taketurns-rules/collaboration/queries/collaborationCreation/useFetchConnectedUserAsParticipantInCreationRule";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { useIsOnMobileRule } from "@taketurns-rules/commons/theme/useIsOnMobileRule";
import { CollaborationCreationPartyFormProps } from "./CollaborationCreationPartyForm";

export const CollaborationCreationInvitees = (props: CollaborationCreationPartyFormProps) => {
  return (
    <Stack className="CollaborationCreationInvitees" flex="1" spacing={{ xs: 2, md: 4 }} minHeight={0}>
      <CollaborationCreationInvitationForm {...props} />
      <Stack
        className="CollaborationCreationInvitees__InvitationHintAndInviteeList"
        flex="1"
        spacing={{ xs: 2, md: 3 }}
        minHeight={0}
      >
        <InvitationHint party={props.party} />
        <CollaborationInviteeList party={props.party} />
      </Stack>
    </Stack>
  );
};

const CollaborationCreationInvitationForm = (props: CollaborationCreationPartyFormProps) => {
  const { t } = useCollaborationTranslation("participantCollaboration");
  const inviteToCollaborationInCreation = useInviteToCollaborationInCreationRule();
  const isOnMobile = useIsOnMobileRule();

  const Form = (
    <InvitationForm
      buttonLabel={t("add")}
      onButtonClick={inviteToCollaborationInCreation}
      partyToInviteTo={props.party}
      connectedUserParty={Party.Owning}
    />
  );

  if (isOnMobile) {
    return <Box px={3}>{Form}</Box>;
  }
  return Form;
};

const CollaborationInviteeList = (props: { party: Party }) => {
  const invitations = useGetCollaborationFormInvitationsByPartyRule()(props.party);
  const fulfilledInvitations = useFulfillInvitationsWithUserObjects(invitations);

  const { connectedUserAsParticipant, loadingConnectedUserAsParticipant, errorOnGettingConnectedUserAsParticipant } =
    useFetchConnectedUserAsParticipantInCreationRule();

  if (loadingConnectedUserAsParticipant || errorOnGettingConnectedUserAsParticipant) {
    return null;
  }

  return (
    <ParticipantInvitationList
      connectedUserParty={Party.Owning}
      ParticipantItemComponent={(props) => <ParticipantItem {...props} />}
      InvitationItemComponent={InvitationItemComponent}
      invitees={fulfilledInvitations}
      creator={props.party === Party.Owning ? connectedUserAsParticipant : undefined}
    />
  );
};

const InvitationItemComponent = (props: InvitationItemComponentProps) => {
  const editInvitationRoleInCollaborationCreation = useEditInvitationRoleInCollaborationCreationRule();
  const revokeInvitationInCollaborationCreation = useRevokeInvitationInCollaborationCreationRule();
  return (
    <InvitationItem
      {...props}
      isCreation
      revokeInvitationAction={{ action: revokeInvitationInCollaborationCreation, loading: false }}
      editInvitationRoleAction={{ action: editInvitationRoleInCollaborationCreation, loading: false }}
    />
  );
};

const InvitationHint = ({ party }: { party: Party }) => {
  const invitationsListFormError = useGetCollaborationInvitationsFormError();

  const { t } = useCollaborationCreationTranslation();

  //For invited party label
  const isInviteeInErrorState = (): boolean => {
    if (party === Party.Invited) {
      return invitationsListFormError;
    }
    return false;
  };

  return (
    <Typography
      variant="body2"
      color={isInviteeInErrorState() ? "error" : TakeTurnsColors.darkGray}
      sx={{ height: "30px", lineHeight: "30px", verticalAlign: "center", px: { xs: 3, md: 0 } }}
    >
      {party === Party.Invited ? t("inviteeOwnerRequired") : t("youAreLeader")}
    </Typography>
  );
};

const useFulfillInvitationsWithUserObjects = (invitations: CreateInviteeInput[]) => {
  const userIds = invitations.map((invitation) => invitation.userId).filter((userId) => userId !== undefined);
  const users = useGetUsersInCache(userIds);

  return invitations.map((invitation) => ({
    ...invitation,
    user: users.find((user) => user?.id === invitation.userId),
  })) as Invitee[];
};
