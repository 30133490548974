/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Divider, List, Skeleton, styled, Typography } from "@mui/material";
import { FC, Fragment } from "react";
import { useUserSharedTranslation } from "@taketurns-i18n/user/useUserSharedTranslation";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { useIsOnMobileRule } from "@taketurns-rules/commons/theme/useIsOnMobileRule";
import { useResetHiddenTipsRule } from "@taketurns-rules/user/commands/useResetHiddenTipsRule";
import { useSwitchTipsDisplayRule } from "@taketurns-rules/user/commands/useSwitchTipsDisplayRule";
import { useFetchIsTipsHiddenRule } from "@taketurns-rules/user/queries/useFetchIsTipsHiddenRule";
import { useIsResetHiddenTipsDisplayedRule } from "@taketurns-rules/user/queries/useIsResetHiddenTipsDisplayedRule";
import { FormListSwitchItem } from "../shared/FormListSwitchItem";

export const UserPreferences: FC = () => {
  const { tipsHiddenLoading, tipsHiddenError } = useFetchIsTipsHiddenRule();
  if (tipsHiddenLoading) {
    return <UserPreferencesLoading />;
  }
  if (tipsHiddenError) {
    return <UserPreferencesError errorMessage={tipsHiddenError.message} />;
  }
  return <UserPreferencesSuccess />;
};

const UserPreferencesLoading: FC = () => {
  const EXPECTED_NUMBER_OF_FIELDS = 1;
  return (
    <List>
      {[...Array(EXPECTED_NUMBER_OF_FIELDS)].map((_, index) => (
        <Skeleton key={index} variant="text" />
      ))}
    </List>
  );
};

const UserPreferencesError: FC<{ errorMessage: string }> = ({ errorMessage }: { errorMessage: string }) => {
  console.error(errorMessage);
  return <Typography>Something went wrong, we are on it !</Typography>;
};

const UserPreferencesSuccess: FC = () => {
  const isOnMobile = useIsOnMobileRule();

  return (
    <Fragment>
      {!isOnMobile && <PageHeader />}
      <TipsPreference />
    </Fragment>
  );
};

const PageHeader = () => {
  const { t } = useUserSharedTranslation();
  return (
    <Fragment>
      <Typography sx={{ mb: 3 }} fontSize={16}>
        {t("UserPreferences.sectionTitle")}
      </Typography>
      <Divider />
    </Fragment>
  );
};

const TipsPreference: FC = () => {
  const { t } = useUserSharedTranslation();
  const { isTipsHidden } = useFetchIsTipsHiddenRule();
  const { switchDisplayTipsRule } = useSwitchTipsDisplayRule();

  return (
    <FormListSwitchItem
      label={t("UserPreferences.displayTips")}
      checked={!isTipsHidden}
      onClick={() => switchDisplayTipsRule(isTipsHidden)}
    >
      <ResetHiddenTips />
    </FormListSwitchItem>
  );
};

const ResetHiddenTips: FC = () => {
  const { t } = useUserSharedTranslation();
  const { isResetHiddenTipsDisplayed } = useIsResetHiddenTipsDisplayedRule();
  const { resetHiddenTipsRule } = useResetHiddenTipsRule();

  if (!isResetHiddenTipsDisplayed) {
    return null;
  }

  return (
    <Typography fontSize={12} color={TakeTurnsColors.caption}>
      {t("UserPreferences.resetTipsDisplay.begin")}{" "}
      <LinkStyled onClick={() => resetHiddenTipsRule()}>{t(`UserPreferences.resetTipsDisplay.resetLink`)}</LinkStyled>{" "}
      {t("UserPreferences.resetTipsDisplay.end")}
    </Typography>
  );
};

const LinkStyled = styled("span")({
  color: TakeTurnsColors.lightBlue,
  "&:hover": {
    cursor: "pointer",
  },
});
