/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useEffect } from "react";
import {
  useMarkPendingAttachmentRevisionAsUploading,
} from "@taketurns-repositories/collaboration/state/write/pendingAttachmentRevision/useMarkPendingAttachmentRevisionAsUploading";
import { useGetRetriedUploadIds } from "@taketurns-repositories/document/state/read/useGetRetriedUploadIds";
import {
  useGetAllPendingAttachmentRevisionsRule,
} from "@taketurns-rules/collaboration/queries/attachment/pending/useGetAllPendingAttachmentRevisionsRule";

export const useListenToRetriedUploadsToMarkPendingAttachmentRevisionAsUploadingRule = () => {
  const retriedUploadIds = useGetRetriedUploadIds();
  const pendingAttachmentRevisions = useGetAllPendingAttachmentRevisionsRule();
  const markPendingAttachmentRevisionAsUploading = useMarkPendingAttachmentRevisionAsUploading();

  useEffect(() => {
    for (const retriedUploadId of retriedUploadIds) {
      const pendingAttachmentRevisionToUpdate = pendingAttachmentRevisions.find((p) => p.uploadId === retriedUploadId);
      if (pendingAttachmentRevisionToUpdate && !pendingAttachmentRevisionToUpdate.isUploading()) {
        markPendingAttachmentRevisionAsUploading(pendingAttachmentRevisionToUpdate);
      }
    }
  }, [retriedUploadIds]);
};
