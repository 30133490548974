/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { selector } from "recoil";
import { collaborationFormState } from "./collaborationFormState";

export const selectCollaborationFormInvitedParty = selector({
  key: "collaborationFormInvitedParty",
  get: ({ get }) => {
    return get(collaborationFormState)?.invitedPartyName;
  },
  set: ({ set }, invitedParty) => {
    set(collaborationFormState, (currentCollaborationForm) => ({
      ...currentCollaborationForm,
      invitedPartyName: invitedParty as string,
    }));
  },
});
