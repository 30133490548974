/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Stack } from "@mui/material";
import { Fragment } from "react";
import { Attachment } from "@taketurns/taketurns-graphql-repository";
import { ContentElementLastUpdate } from "@taketurns-components/collaboration/CollaborationView/CollaborationContent/shared/components/ContentElementLastUpdate";
import { ContextMenuOnButton } from "@taketurns-components/collaboration/ContextMenu/ContextMenuOnButton";
import { ReviewListItemChip } from "@taketurns-components/collaboration/Shared/content/Markers";
import { MobileListItem } from "@taketurns-components/collaboration/Shared/list/MobileListItem";
import { DocumentExtension } from "@taketurns-components/document/DocumentView/DocumentExtension";
import { useGetAttachmentContextMenuConfigurationRule } from "@taketurns-rules/collaboration/queries/attachment/useGetAttachmentContextMenuConfigurationRule";
import { ListItemTitle } from "../ListItemTitle";
import { AttachmentMarkersInList } from "./AttachmentMarkersInList";

interface MobileAttachmentListItemProps {
  attachment: Attachment;
  isAttachmentRemoved: boolean;
  shouldDisplayLastUpdate: boolean;
  openAttachmentDetails: () => void;
  contextMenuConfiguration: ReturnType<typeof useGetAttachmentContextMenuConfigurationRule> | null;
  color: string;
  backgroundColor: string;
}

export const MobileAttachmentListItem = (props: MobileAttachmentListItemProps) => (
  <MobileListItem
    centerRightEndSlotVertically
    dataCy={`MobileAttachmentListItem.${props.attachment.id}`}
    sx={{ backgroundColor: props.backgroundColor }}
    titleSlot={<ListItemTitle title={props.attachment.document.name} shouldStrikeTitle={props.isAttachmentRemoved} />}
    subtitleSlot={
      <Fragment>
        <DocumentExtension extension={props.attachment.document.extension} size={props.attachment.document.size} />
        <AttachmentMarkersInList attachment={props.attachment} />
        {props.shouldDisplayLastUpdate && <ContentElementLastUpdate status={props.attachment.prevStatus} />}
      </Fragment>
    }
    rightEndSlot={
      <Stack direction="row" alignItems="center">
        {props.attachment.toReview && <ReviewListItemChip />}
        {props.contextMenuConfiguration && (
          <ContextMenuOnButton contextMenuConfiguration={props.contextMenuConfiguration} />
        )}
      </Stack>
    }
    onClick={props.isAttachmentRemoved ? undefined : props.openAttachmentDetails}
  />
);
