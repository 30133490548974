/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import {
  useAddFilesInCreationRule,
} from "@taketurns-rules/collaboration/commands/collaborationCreation/attachment/pending/useAddFilesInCreationRule";
import { useBrowseContentInCreationRule } from "./useBrowseContentInCreationRule";

export function useAttachFilesIntoFolderAndMoveIntoItInCreationRule() {
  const { moveIntoFolder } = useBrowseContentInCreationRule();
  const addFilesInCreationRule = useAddFilesInCreationRule();

  return async (files: File[], folderId: string) => {
    moveIntoFolder(folderId);
    await addFilesInCreationRule(files, folderId);
  };
}
