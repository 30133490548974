/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { DateTime } from "luxon";

export const getFormattedNextPaymentDateRule = (language: string, timestamp: string) => {
  const nextPaymentDate = DateTime.fromISO(timestamp, { zone: "utc" });
  return nextPaymentDate.setZone("local").setLocale(language).toLocaleString(DateTime.DATE_MED);
};
