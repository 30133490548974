/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { atom } from "recoil";

export interface CollaborationFormErrors {
  name: boolean;
  invitedPartyName: boolean;
  owningPartyName: boolean;
  invitations: boolean;
  files: boolean;
  requests: boolean;
}

export const collaborationFormErrorsState = atom<CollaborationFormErrors>({
  key: "collaborationFormErrors",
  default: {
    name: false,
    owningPartyName: false,
    invitedPartyName: false,
    invitations: false,
    files: false,
    requests: false,
  },
});
