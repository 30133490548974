/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { getEnvironmentVariable } from "../../getEnvironmentVariable";

export const TRACE_ID_BYTES = 16;
export const SPAN_ID_BYTES = 8;
const SHARED_BUFFER = Buffer.allocUnsafe(TRACE_ID_BYTES);

/**
 * Generate ID for tracing
 * @param bytes
 * @returns {string} Generated ID
 */
export const generateId = (bytes: number) => {
  for (let i = 0; i < bytes / 4; i++) {
    // unsigned right shift drops decimal part of the number
    // it is required because if a number between 2**32 and 2**32 - 1 is generated, an out of range error is thrown by writeUInt32BE
    SHARED_BUFFER.writeUInt32BE((Math.random() * 2 ** 32) >>> 0, i * 4);
  }
  // If buffer is all 0, set the last byte to 1 to guarantee a valid w3c id is generated
  for (let i = 0; i < bytes; i++) {
    if (SHARED_BUFFER[i] > 0) {
      break;
    } else if (i === bytes - 1) {
      SHARED_BUFFER[bytes - 1] = 1;
    }
  }
  return SHARED_BUFFER.toString("hex", 0, bytes);
};

/**
 * Get generated trace id header
 * @returns header with generated trace id
 */
export const generateXb3TraceIdHeader = () => {
  return { "x-b3-traceid": generateId(TRACE_ID_BYTES) };
};

/**
 * Get generated span id header
 * @returns header with generated span id
 */
export const generateXb3SpanIdHeader = () => {
  return { "x-b3-spanid": generateId(SPAN_ID_BYTES) };
};

export const generateXb3SampledHeader = () => {
  const TRACING_RATIO = getEnvironmentVariable("TRACING_RATIO");
  let ratio = parseFloat(TRACING_RATIO);
  if (isNaN(ratio)) {
    ratio = 1.0;
  }
  return { "x-b3-sampled": ratio };
};
