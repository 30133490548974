/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { IconButton, Tooltip, Typography } from "@mui/material";
import { IconHelpCircle } from "@taketurns-components/commons/icons";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";

export const HelpIconWithTooltip = (props: { helpText: string }) => {
  return (
    <Tooltip title={<Typography>{props.helpText}</Typography>} enterTouchDelay={0} arrow>
      <IconButton size={"small"}>
        <IconHelpCircle size={16} color={TakeTurnsColors.grayButton} />
      </IconButton>
    </Tooltip>
  );
};
