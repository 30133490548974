/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useRecoilCallback } from "recoil";
import { folderByIdState } from "../recoil/collaborationCreation/folderByIdState";
import { parentFolderIdByFolderIdState } from "../recoil/collaborationCreation/parentFolderIdByFolderIdState";

export function useMoveFolderIntoFolderInCreation(movingFolderId: string) {
  return useRecoilCallback(
    ({ set, snapshot }) =>
      (destinationFolderId: string) => {
        removeMovingFolderFromPreviousParentFolder();
        addMovingFolderToDestinationFolder();

        function removeMovingFolderFromPreviousParentFolder() {
          const previousParentFolderId: string = snapshot.getLoadable(
            parentFolderIdByFolderIdState(movingFolderId),
          ).contents;
          set(folderByIdState(previousParentFolderId), (previousParentFolder) => ({
            ...previousParentFolder,
            folders: previousParentFolder.folders.filter((folder) => folder !== movingFolderId),
          }));
        }

        function addMovingFolderToDestinationFolder() {
          set(parentFolderIdByFolderIdState(movingFolderId), destinationFolderId);
          set(folderByIdState(destinationFolderId), (destinationFolder) => ({
            ...destinationFolder,
            folders: [...destinationFolder.folders, movingFolderId],
          }));
        }
      },
    [movingFolderId],
  );
}
