/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useFetchUserMaxFileSizeUserFeature } from "@taketurns-repositories/collaboration/graphql/queries/features/useFetchUserMaxFileSizeUserFeature";
import { useFilterFilesTooBigAndNotifyRule } from "../useFilterFilesTooBigAndNotifyRule";

export function useFilterFilesTooBigAndNotifyInCreationRule() {
  const { maxFileSizeInMB } = useFetchUserMaxFileSizeUserFeature();
  const filterFilesTooBigAndNotify = useFilterFilesTooBigAndNotifyRule(maxFileSizeInMB);
  return (files: File[]) => filterFilesTooBigAndNotify(files);
}
