/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Avatar, Box, CircularProgress, MenuItem, Select, styled, Typography } from "@mui/material";
import { useState } from "react";
import { OrganizationMember } from "@taketurns/taketurns-graphql-repository";
import { TakeTurnsDefaultButton } from "@taketurns-components/commons/button";
import { DialogFooter } from "@taketurns-components/commons/dialog/DialogFooter";
import { ResponsiveDialog } from "@taketurns-components/commons/dialog/ResponsiveDialog";
import { IconChevronDown } from "@taketurns-components/commons/icons";
import { useOrganizationTranslation } from "@taketurns-i18n/user/Organization/useOrganizationTranslation";
import { getUserNamesOrEmail } from "@taketurns-rules/collaboration/utils/participant/getUserNamesOrEmail";
import { useRemoveOrganizationMemberRule } from "@taketurns-rules/user/commands/useRemoveOrganizationMemberRule";
import { useFetchUserOrganizationMembersRule } from "@taketurns-rules/user/queries/useFetchUserOrganizationMembersRule";

export const MemberRemoveDialog = ({
                                     open,
                                     closeDialog,
                                     memberUserIdToDelete,
                                   }: {
  open: boolean;
  closeDialog: () => void;
  memberUserIdToDelete: string;
}) => {
  const [replacingMemberUserId, setReplacingMemberUserId] = useState("");

  return (
    <ResponsiveDialog
      fullWidth
      PaperProps={{ sx: { minHeight: "60%" } }}
      maxWidth={"lg"}
      open={open}
      onClose={closeDialog}
      title={<MemberReplacementSelectorDialogTitle memberUserIdToDelete={memberUserIdToDelete} />}
      content={
        <MemberReplacementSelectorDialogContent
          memberUserIdToDelete={memberUserIdToDelete}
          replacingMemberUserId={replacingMemberUserId}
          setReplacingMemberUserId={setReplacingMemberUserId}
        />
      }
      footer={
        <MemberReplacementSelectorDialogFooter
          memberUserIdToDelete={memberUserIdToDelete}
          replacingMemberUserId={replacingMemberUserId}
          closeDialog={closeDialog}
        />
      }
    />
  );
};

const MemberReplacementSelectorDialogTitle = ({ memberUserIdToDelete }: { memberUserIdToDelete: string }) => {
  const { memberToDelete, errorOnLoadingUserOrganizationMembers } = useGetMemberToDelete(memberUserIdToDelete);
  const { t } = useOrganizationTranslation();

  if (errorOnLoadingUserOrganizationMembers) {
    return (
      <Typography variant={"caption"} color={"error"}>
        {t("error")}
      </Typography>
    );
  }

  return (
    <MemberReplacementDialogTitle__Container>
      <Typography>{t("MemberDeleteDialog.title")}</Typography>
      <MemberItem member={memberToDelete} />
    </MemberReplacementDialogTitle__Container>
  );
};

const MemberReplacementSelectorDialogContent = ({
                                                  memberUserIdToDelete,
                                                  replacingMemberUserId,
                                                  setReplacingMemberUserId,
                                                }: {
  memberUserIdToDelete: string;
  replacingMemberUserId: string;
  setReplacingMemberUserId: (value: string) => void;
}) => {
  const { t } = useOrganizationTranslation();
  const { members, loadingUserOrganizationMembers, errorOnLoadingUserOrganizationMembers } =
    useFetchUserOrganizationMembersRule();

  if (loadingUserOrganizationMembers) {
    return <CircularProgress />;
  }
  if (errorOnLoadingUserOrganizationMembers) {
    console.error(errorOnLoadingUserOrganizationMembers);
    return (
      <Typography variant={"caption"} color={"error"}>
        {t("error")}
      </Typography>
    );
  }

  const renderMemberSelectionList = (selectedMemberUserId: string) => {
    const selectedMember = membersWithoutMemberToDelete.find((member) => member.userId === selectedMemberUserId);
    if (!selectedMember) {
      return <em>{t("MemberDeleteDialog.selectMember")}</em>;
    }
    return <MemberItem member={selectedMember} />;
  };

  const membersWithoutMemberToDelete = members.filter((member) => member.userId !== memberUserIdToDelete);

  return (
    <DialogContent__Container>
      <Typography variant="body2">{t("MemberDeleteDialog.description")}</Typography>
      <Select
        sx={{ width: "fit-content" }}
        displayEmpty
        value={replacingMemberUserId}
        onChange={(event) => setReplacingMemberUserId(event.target.value)}
        renderValue={renderMemberSelectionList}
        IconComponent={(iconProps) => <IconChevronDown size={16} {...iconProps} />}
      >
        {membersWithoutMemberToDelete.map((member) => (
          <MenuItem key={member.userId} value={member.userId}>
            <MemberItem member={member} />
          </MenuItem>
        ))}
      </Select>
    </DialogContent__Container>
  );
};

const MemberReplacementSelectorDialogFooter = ({
                                                 memberUserIdToDelete,
                                                 replacingMemberUserId,
                                                 closeDialog,
                                               }: {
  memberUserIdToDelete: string;
  replacingMemberUserId: string;
  closeDialog: () => void;
}) => {
  const { t } = useOrganizationTranslation();
  const { removeOrganizationMember, loadingRemoveOrganizationMember } = useRemoveOrganizationMemberRule();
  const { memberToDelete } = useGetMemberToDelete(memberUserIdToDelete);

  const disabledIfNoReplacementMemberOrLoadingDeletion =
    replacingMemberUserId.trim().length === 0 || loadingRemoveOrganizationMember;

  const removeOrganizationMemberAndCloseDialog = () => {
    removeOrganizationMember({
      removeOrganizationMemberInput: {
        removedUserId: memberToDelete?.userId,
        replacingUserId: replacingMemberUserId,
      },
    });
    closeDialog();
  };

  return (
    <DialogFooter hasCancelAction onClose={closeDialog}>
      <TakeTurnsDefaultButton
        onClick={removeOrganizationMemberAndCloseDialog}
        disabled={disabledIfNoReplacementMemberOrLoadingDeletion}
      >
        {t("MemberDeleteDialog.removeMember")}
      </TakeTurnsDefaultButton>
    </DialogFooter>
  );
};

const MemberItem = ({ member }: { member: OrganizationMember }) => {
  const userDisplayName = getUserNamesOrEmail(member.user);
  return (
    <MemberItem__Container>
      <Avatar sx={{ width: 24, height: 24 }} alt={"member image profile"} src={member.user.avatar!} />
      <Typography>{userDisplayName}</Typography>
    </MemberItem__Container>
  );
};

const useGetMemberToDelete = (memberUserIdToDelete: string) => {
  const { members, errorOnLoadingUserOrganizationMembers } = useFetchUserOrganizationMembersRule();
  const memberToDelete = members.find((member) => member.userId === memberUserIdToDelete);
  return { memberToDelete, errorOnLoadingUserOrganizationMembers };
};

const MemberItem__Container = styled(Box)({
  display: "flex",
  flexDirection: "row",
  gap: "12px",
  alignItems: "center",
});

const MemberReplacementDialogTitle__Container = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",

  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    rowGap: theme.spacing(1),
  },
}));

const DialogContent__Container = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "24px",
});
