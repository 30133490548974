/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { gql, useMutation } from "@apollo/client";
import {
  CollaborationContentMetadata,
  MutationRecallUpdatesArgs,
  RecallUpdatesInput,
} from "@taketurns/taketurns-graphql-repository";

export const useRecallUpdates = () => {
  const mutation = gql`
    mutation recallUpdates($input: RecallUpdatesInput!) {
      recallUpdates(input: $input) {
        id
        attachmentToReviewCount
        requestToFulfillCount
        todoCount
        hasPendingUpdates
        partyId
        recallLastUpdatesAvailable
        hasNoContent
      }
    }
  `;

  const [recallUpdatesMutation, recallUpdatesResult] = useMutation<
    { recallUpdates: CollaborationContentMetadata },
    MutationRecallUpdatesArgs
  >(mutation);
  const recallUpdates = async (input: RecallUpdatesInput) => {
    return recallUpdatesMutation({
      variables: { input },
      fetchPolicy: "no-cache",
    });
  };
  return { recallUpdates, recallUpdatesResult };
};
