/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Stack, Typography } from "@mui/material";
import { GenerateAiAssistantResponseFileUrlErrorCode } from "@taketurns/taketurns-graphql-repository";
import { getUserSettingsSectionUrl, INTEGRATIONS_SECTION } from "@taketurns-app/routing/routes/userSettingsSections";
import { LinkStyled } from "@taketurns-components/webapp/LinkStyled";
import { useSharedCollaborationTranslation } from "@taketurns-i18n/collaboration/shared/useSharedCollaborationTranslation";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";

export const AiAssistantInvalidApiKey = (props: { prompt: string }) => {
  const { t } = useSharedCollaborationTranslation();
  return (
    <Stack spacing={3} data-cy="AiAssistantInvalidApiKey">
      <Typography color={TakeTurnsColors.aiAssistant}>{props.prompt}</Typography>
      <Typography>
        {t(`AttachmentAiAssistant.errors.${GenerateAiAssistantResponseFileUrlErrorCode.InvalidApiKey}`)}{" "}
        <LinkStyled to={getUserSettingsSectionUrl(INTEGRATIONS_SECTION)}>
          {t("AttachmentAiAssistant.AiAssistantInvalidApiKey.fixApiKeyInYourSettings")}
        </LinkStyled>
      </Typography>
    </Stack>
  );
};
