/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { OrganizationInvitation, User } from "@taketurns/taketurns-graphql-repository";
import { OrganizationMemberOrInvitation } from "@taketurns-components/user/Organization/OrganizationMemberAndInvitationList/shared/SharedComponentTypes";

export const getOrganizationMemberOrInvitationFirstAndLastNameOrEmail = (
  organizationMemberOrInvitation: OrganizationMemberOrInvitation,
) => {
  const user: User | undefined = organizationMemberOrInvitation.user;
  if (user && user.firstName && user.lastName) {
    return `${user.firstName} ${user.lastName}`;
  }
  if (user && user.email) {
    return user.email;
  }
  return (organizationMemberOrInvitation as OrganizationInvitation).email;
};
