/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Dialog, DialogTitle, styled } from "@mui/material";
import { forwardRef } from "react";
import { SupervisorFilterItemList, SupervisorFilterItemListRef } from "@taketurns-components/collaboration/CollaborationList/SupervisorFilter/SupervisorFilterItemList";
import { useCollaborationListTranslation } from "@taketurns-i18n/collaboration/CollaborationList/useCollaborationListTranslation";

interface MobileSupervisorFilterModalProps {
  isOpen: boolean;
  closeMenu: () => void;
}

export const MobileSupervisorFilterDialog = forwardRef<SupervisorFilterItemListRef, MobileSupervisorFilterModalProps>(
  ({ isOpen, closeMenu }, supervisorFilterItemListRef) => {
    const { t } = useCollaborationListTranslation();

    return (
      <MobileSupervisorFilterDialog__Container fullScreen open={isOpen} onClose={closeMenu}>
        <MobileSupervisorFilterDialog__Title>
          {t("SupervisorFilter.viewMyTeam_zero")}
        </MobileSupervisorFilterDialog__Title>
        <SupervisorFilterItemList ref={supervisorFilterItemListRef} closeMenu={closeMenu} />
      </MobileSupervisorFilterDialog__Container>
    );
  },
);

const MobileSupervisorFilterDialog__Container = styled(Dialog)({
  "& .MuiDialog-paper": {
    borderRadius: 0,
    "& .SupervisorFilter__Footer": {
      padding: "8px 24px calc(8px + env(safe-area-inset-bottom))",
    },
  },
});

const MobileSupervisorFilterDialog__Title = styled(DialogTitle)({
  textAlign: "center",
});
