/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { gql, useMutation } from "@apollo/client";
import { Folder, MutationMoveFolderIntoFolderArgs } from "@taketurns/taketurns-graphql-repository";

export const moveFolderMutationFragment = gql`
  fragment MoveFolderMutationFragment on MoveOutput {
    collaborationId
    partyId
    sourceFolderId
    destinationFolderId
    sourceFolder {
      id
      folders {
        id
      }
      attachmentCount
      requestCount
      folderCount
      updateCount
      prevUpdateCount
      attachmentToReviewCount
      requestToFulfillCount
    }
    destinationFolder {
      id
      folders {
        id
      }
      attachmentCount
      requestCount
      folderCount
      updateCount
      prevUpdateCount
      attachmentToReviewCount
      requestToFulfillCount
    }
    contentMetadata {
      id
      hasPendingUpdates
    }
  }
`;

export function useMoveFolderIntoFolderMutation({
  collaborationId,
  movingFolderId,
}: {
  collaborationId: string;
  movingFolderId: string;
}) {
  const MOVE_FOLDER_INTO_FOLDER_MUTATION = gql`
    mutation moveFolderIntoFolder($input: MoveFolderIntoFolderInput!) {
      moveFolderIntoFolder(input: $input) {
        ...MoveFolderMutationFragment
      }
    }
    ${moveFolderMutationFragment}
  `;
  const [moveFolderMutation] = useMutation<
    { sourceFolder: Folder; destinationFolder: Folder },
    MutationMoveFolderIntoFolderArgs
  >(MOVE_FOLDER_INTO_FOLDER_MUTATION);
  return async (destinationFolderId: string) => {
    await moveFolderMutation({
      variables: {
        input: {
          collaborationId,
          movingFolderId,
          destinationFolderId,
        },
      },
    });
  };
}
