/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { gql, useQuery } from "@apollo/client";
import { Collaboration } from "@taketurns/taketurns-graphql-repository";

const CONNECTED_USER_PARTY_HAS_PENDING_UPDATES = gql`
  query connectedUserParty($collaborationId: ID!) {
    getCollaboration(collaborationId: $collaborationId) {
      contentMetadata {
        id
        hasPendingUpdates
      }
    }
  }
`;

export const useFetchConnectedUserPartyHasPendingUpdates = (collaborationId: string) => {
  const {
    data,
    loading: loadingConnectedUserPartyHasPendingUpdates,
    error: errorOnFetchingConnectedUserPartyHasPendingUpdates,
  } = useQuery<{
    getCollaboration: Collaboration;
  }>(CONNECTED_USER_PARTY_HAS_PENDING_UPDATES, { variables: { collaborationId } });
  return {
    connectedUserPartyHasPendingUpdates: data?.getCollaboration.contentMetadata.hasPendingUpdates,
    loadingConnectedUserPartyHasPendingUpdates,
    errorOnFetchingConnectedUserPartyHasPendingUpdates,
  };
};
