/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql } from "@apollo/client";
import { useRefreshableSubscription } from "@taketurns-components/webapp/QueriesAndSubscriptionsRefreshTrigger";

const ON_REMOVE_ORGANIZATION_MEMBER = gql`
  subscription onRemoveOrganizationMember($id: ID!) {
    onRemoveOrganizationMember(id: $id) {
      id
      members {
        userId
      }
    }
  }
`;

export const useSubscribeToRemoveOrganizationMember = (id?: string) => {
  return useRefreshableSubscription(ON_REMOVE_ORGANIZATION_MEMBER, { variables: { id }, skip: !id });
};
