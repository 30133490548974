/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useGetDisplayedCollaborationId } from "@taketurns-repositories/collaboration/context/useGetDisplayedCollaborationId";
import { useMarkAttachmentForReview } from "@taketurns-repositories/collaboration/graphql/mutations/content/attachments/useMarkAttachmentForReview";

export const useMarkAttachmentForReviewRule = (attachmentId: string) => {
  const collaborationId = useGetDisplayedCollaborationId();
  const {
    markAttachmentForReviewMutation,
    markAttachmentForReviewResponseData,
    markAttachmentForReviewLoading,
    errorOnMarkingAttachmentForReview,
  } = useMarkAttachmentForReview({ collaborationId, attachmentId });
  return {
    markAttachmentForReview: markAttachmentForReviewMutation,
    markAttachmentForReviewResponseData: markAttachmentForReviewResponseData,
    markAttachmentForReviewLoading: markAttachmentForReviewLoading,
    errorOnMarkingAttachmentForReview: errorOnMarkingAttachmentForReview,
  };
};
