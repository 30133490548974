/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useNavigate } from "react-router-dom";
import { PUBLIC_ROUTES } from "@taketurns-app/routing/routes/publicRoutes.constants";

export const useNavigateToSignUpRule = () => {
  const navigate = useNavigate();
  return () => {
    navigate(PUBLIC_ROUTES.SIGN_UP);
  };
};
