/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useQuery } from "@apollo/client";
import { revisionFragment } from "@taketurns-repositories/document/graphql/fragments/revisionFragment";
import { Revision } from "@taketurns/taketurns-graphql-repository";

export const useFetchDocumentRevision = (collaborationId: string, documentId: string, revisionId: string) => {
  const DOCUMENT_REVISION = gql`
    query documentRevision($collaborationId: ID!, $documentId: ID!, $revisionId: ID!) {
      documentRevision(collaborationId: $collaborationId, documentId: $documentId, revisionId: $revisionId) {
        ...RevisionFields
      }
    }
    ${revisionFragment}
  `;

  const {
    data,
    loading: loadingDocumentRevision,
    error: errorOnDocumentRevision,
  } = useQuery<{ documentRevision: Revision }>(DOCUMENT_REVISION, {
    variables: {
      collaborationId,
      documentId,
      revisionId,
    },
  });
  return {
    documentRevision: data?.documentRevision,
    loadingDocumentRevision,
    errorOnDocumentRevision,
  };
};
