/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useSubscribeToAcceptInvitationToOrganization } from "@taketurns-repositories/user/graphql/subscriptions/useSubscribeToAcceptInvitationToOrganization";
import { useSubscribeToAddInvitationToOrganization } from "@taketurns-repositories/user/graphql/subscriptions/useSubscribeToAddInvitationToOrganization";
import { useSubscribeToDeclineInvitationToOrganization } from "@taketurns-repositories/user/graphql/subscriptions/useSubscribeToDeclineInvitationToOrganization";
import { useSubscribeToRemoveOrganizationMember } from "@taketurns-repositories/user/graphql/subscriptions/useSubscribeToRemoveOrganizationMember";
import { useSubscribeToRevokeInvitationToOrganization } from "@taketurns-repositories/user/graphql/subscriptions/useSubscribeToRevokeInvitationToOrganization";

export const useSubscribeToOrganizationMemberInvitationListChangedRule = (organizationId?: string) => {
  useSubscribeToAcceptInvitationToOrganization(organizationId);
  useSubscribeToDeclineInvitationToOrganization(organizationId);
  useSubscribeToAddInvitationToOrganization(organizationId);
  useSubscribeToRemoveOrganizationMember(organizationId);
  useSubscribeToRevokeInvitationToOrganization(organizationId);
};
