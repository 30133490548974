/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { gql, useLazyQuery } from "@apollo/client";
import { OrganizationMemberSubordinate } from "@taketurns/taketurns-graphql-repository";

export const useSearchSubordinates = () => {
  const SEARCH_SUBORDINATES_QUERY = gql`
    query searchSubordinates($searchSubordinatesQuery: String!) {
      searchSubordinates(searchSubordinatesQuery: $searchSubordinatesQuery) {
        userId
        email
        firstName
        lastName
        avatar
      }
    }
  `;
  const [searchSubordinatesQuery, searchSubordinatesResponse] = useLazyQuery<{
    searchSubordinates: OrganizationMemberSubordinate[];
  }>(SEARCH_SUBORDINATES_QUERY, {
    fetchPolicy: "network-only",
  });

  const searchSubordinates = async (query: string) => {
    return searchSubordinatesQuery({ variables: { searchSubordinatesQuery: query } })
      .then((result) => result.data.searchSubordinates)
      .catch((error) => {
        console.error(error);
        return [];
      });
  };
  return {
    searchSubordinates,
    subordinates: searchSubordinatesResponse.data?.searchSubordinates,
    errorOnSearchingSubordinates: searchSubordinatesResponse.error,
  };
};
