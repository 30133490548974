/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { FetchLoadingManager } from "@taketurns-app/utils/FetchLoadingManager";
import { useFetchLastUpdatePartyIdRule } from "@taketurns-rules/collaboration/queries/content/useFetchLastUpdatePartyIdRule";
import { useFetchConnectedUserPartyRule } from "@taketurns-rules/collaboration/queries/context/useFetchConnectedUserPartyRule";

export const useIsLastUpdateMadeByConnectedUserPartyRule = (collaborationId?: string) => {
  const { connectedUserPartyId, loadingConnectedUserParty, errorOnFetchingConnectedUserParty } =
    useFetchConnectedUserPartyRule(collaborationId);
  const { lastUpdatePartyId, loadingLastUpdatePartyId, errorOnFetchingLastUpdatePartyId } =
    useFetchLastUpdatePartyIdRule(collaborationId);

  const fetchLoadingManager = new FetchLoadingManager(
    [loadingConnectedUserParty, loadingLastUpdatePartyId],
    [errorOnFetchingConnectedUserParty, errorOnFetchingLastUpdatePartyId],
  );

  return {
    isLastUpdateMadeByConnectedUserParty: connectedUserPartyId === lastUpdatePartyId,
    loadingIsLastUpdateMadeByConnectedUserParty: fetchLoadingManager.hasFetchLoading(),
    errorOnFetchingIsLastUpdateMadeByConnectedUserParty: fetchLoadingManager.getFirstError(),
  };
};
