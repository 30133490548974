/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { ResourceLanguage } from "i18next";
import { button } from "./button";
import { form } from "./form";
import { tips } from "./tips";

export const TranslationsEN: ResourceLanguage = {
  tips,
  form,
  button,
};
