/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { styled, Typography } from "@mui/material";

export const TakeTurnsTruncatedTypography = styled(Typography)(() => ({
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: "5",
  WebkitBoxOrient: "vertical",
  lineHeight: "1.2em",
  wordWrap: "break-word",
}));
