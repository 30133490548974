/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Stack, Typography } from "@mui/material";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";

interface FileExtensionProps {
  fileExtension?: string;
  sizeInBytes?: number;
}

const DocExtensions = ["DOC", "DOCM", "DOCX"];
const PdfExtensions = ["PDF"];
const excelExtensions = ["XLS", "XLSX", "XLSM", "XLSB"];
const powerpointExtensions = ["PPT", "PPTX", "PPTM", "POTX", "POTM", "POT", "PPSX", "PPS"];

export const FileExtension = ({ fileExtension, sizeInBytes }: FileExtensionProps) => {
  return (
    <Stack direction="row" alignItems={"center"}>
      <Typography
        color={getColorForFileExtension(fileExtension!)}
        textTransform={"uppercase"}
        fontSize={{ xs: 10, md: 9 }}
        fontWeight={700}
      >
        {fileExtension}
      </Typography>
      <Typography
        color={TakeTurnsColors.grayButton}
        textTransform={"none"}
        marginLeft={"2px"}
        fontSize={{ xs: 10, md: 9 }}
        fontWeight={700}
      >
        {getDocumentFormattedSize(sizeInBytes!)}
      </Typography>
    </Stack>
  );
};

const getColorForFileExtension = (extension: string) => {
  const docxColor = "#1B5EBE";
  const pdfColor = "#D60800";
  const excelColor = "#185C37";
  const powerpointColor = "#C13B1B";
  const defaultColor = TakeTurnsColors.darkGray;

  if (DocExtensions.includes(extension.toUpperCase())) {
    return docxColor;
  }
  if (PdfExtensions.includes(extension.toUpperCase())) {
    return pdfColor;
  }
  if (excelExtensions.includes(extension.toUpperCase())) {
    return excelColor;
  }
  if (powerpointExtensions.includes(extension.toUpperCase())) {
    return powerpointColor;
  }
  return defaultColor;
};

const getDocumentFormattedSize = (sizeInBytes: number) => {
  const sizeInKB = Math.round(sizeInBytes * 0.001);
  const sizeInMB = Math.round(sizeInBytes * 0.000001);
  const sizeInGB = Math.round(sizeInBytes * 0.000000001);
  if (sizeInKB < 1) {
    return `${sizeInBytes}B`;
  }
  if (sizeInMB < 1) {
    return `${sizeInKB}KB`;
  }
  if (sizeInGB < 1) {
    return `${sizeInMB}MB`;
  }
  return `${sizeInGB}GB`;
};
