/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { CircularProgress } from "@mui/material";
import { Fragment, useEffect, useRef } from "react";
import { ContentSelectionFooterTextButton } from "@taketurns-components/collaboration/CollaborationView/CollaborationContent/ContentBrowser/ContentFooterMenu/shared";
import { IconDownloadCloud } from "@taketurns-components/commons/icons";
import { useSharedCollaborationTranslation } from "@taketurns-i18n/collaboration/shared/useSharedCollaborationTranslation";
import { useGetDisplayedCollaborationIdRule } from "@taketurns-rules/collaboration/queries/collaboration/useGetDisplayedCollaborationIdRule";
import { useGetSelectedContentRule } from "@taketurns-rules/collaboration/queries/collaborationView/contentSelection/useGetSelectedContentRule";
import { useDownloadContentRule } from "@taketurns-rules/document/commands/useDownloadContentRule";

export const DownloadSelectionButton = () => {
  const collaborationId = useGetDisplayedCollaborationIdRule();

  const { downloadContent, contentDownloadUrl, loading } = useDownloadContentRule();
  const hiddenDownloadLinkRef = useRef<HTMLAnchorElement>(null);
  const isFileDownloaded = useRef<boolean>(false);

  const selectedContent = useGetSelectedContentRule();
  const isNoContentSelected = selectedContent.folderIds.length === 0 && selectedContent.attachmentIds.length === 0;
  const { t } = useSharedCollaborationTranslation();

  const startDownloadSelectedContent = async () => {
    if (isNoContentSelected) return;
    isFileDownloaded.current = false;
    return downloadContent(collaborationId, selectedContent);
  };

  useEffect(() => {
    if (contentDownloadUrl) {
      if (hiddenDownloadLinkRef.current && !isFileDownloaded.current) {
        hiddenDownloadLinkRef.current.href = contentDownloadUrl;
        hiddenDownloadLinkRef.current.click();
        isFileDownloaded.current = true;
      }
    }
  }, [contentDownloadUrl, hiddenDownloadLinkRef]);

  if (isNoContentSelected) return null;

  return (
    <Fragment>
      <ContentSelectionFooterTextButton
        disabled={loading || isNoContentSelected}
        onClick={startDownloadSelectedContent}
        startIcon={loading ? <CircularProgress size={24} /> : <IconDownloadCloud size={24} />}
        data-cy="ContentSelection.DownloadButton"
      >
        {t("SelectContent.download")}
      </ContentSelectionFooterTextButton>
      <a ref={hiddenDownloadLinkRef} style={{ display: "none" }} />
    </Fragment>
  );
};
