/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { PropsWithChildren } from "react";
import { useListenAndNotifyToCompletedUploadsRule } from "@taketurns-rules/collaboration/commands/attachment/useListenAndNotifyToCompletedUploadsRule";
import { useListenToCancelledUploadsToCancelAddAttachmentRule } from "@taketurns-rules/collaboration/commands/attachment/useListenToCancelledUploadsToCancelAddAttachmentRule";
import { useListenToCompletedUploadsToAddAttachmentRule } from "@taketurns-rules/collaboration/commands/attachment/useListenToCompletedUploadsToAddAttachmentRule";
import { useListenToPendingUploadsRule } from "@taketurns-rules/collaboration/commands/attachment/useListenToPendingUploadsRule";
import {
  useListenToRetriedUploadsToMarkPendingAttachmentAsUploadingRule,
} from "@taketurns-rules/collaboration/commands/attachment/useListenToRetriedUploadsToMarkPendingAttachmentAsUploadingRule";
import {
  useListenToCancelledUploadsToCancelAddAttachmentRevisionRule,
} from "@taketurns-rules/collaboration/commands/attachmentRevision/useListenToCancelledUploadsToCancelAddAttachmentRevisionRule";
import {
  useListenToCompletedUploadsToAddAttachmentRevisionRule,
} from "@taketurns-rules/collaboration/commands/attachmentRevision/useListenToCompletedUploadsToAddAttachmentRevisionRule";
import { useListenToPendingRevisionUploadsRule } from "@taketurns-rules/collaboration/commands/attachmentRevision/useListenToPendingRevisionUploadsRule";
import {
  useListenToRetriedUploadsToMarkPendingAttachmentRevisionAsUploadingRule,
} from "@taketurns-rules/collaboration/commands/attachmentRevision/useListenToRetriedUploadsToMarkPendingAttachmentRevisionAsUploadingRule";
import {
  useListenToCancelledUploadsInCreationToCancelCreateAttachmentRule,
} from "@taketurns-rules/collaboration/commands/collaborationCreation/attachment/pending/useListenToCancelledUploadsInCreationToCancelCreateAttachmentRule";
import {
  useListenToCompletedUploadsInCreationToCreateAttachmentRule,
} from "@taketurns-rules/collaboration/commands/collaborationCreation/attachment/pending/useListenToCompletedUploadsInCreationToCreateAttachmentRule";
import { useListenToPendingCreationUploadsRule } from "@taketurns-rules/collaboration/commands/collaborationCreation/attachment/pending/useListenToPendingCreationUploadsRule";
import {
  useListenToRetriedUploadsInCreationToMarkAttachmentAsUploadingRule,
} from "@taketurns-rules/collaboration/commands/collaborationCreation/attachment/pending/useListenToRetriedUploadsInCreationToMarkAttachmentAsUploadingRule";

export const AttachmentManager = ({ children }: PropsWithChildren<object>) => {
  useListenToPendingCreationUploadsRule();
  useListenToCompletedUploadsInCreationToCreateAttachmentRule();
  useListenToCancelledUploadsInCreationToCancelCreateAttachmentRule();
  useListenToRetriedUploadsInCreationToMarkAttachmentAsUploadingRule();

  useListenToPendingUploadsRule();
  useListenToCompletedUploadsToAddAttachmentRule();
  useListenToCancelledUploadsToCancelAddAttachmentRule();
  useListenToRetriedUploadsToMarkPendingAttachmentAsUploadingRule();
  useListenAndNotifyToCompletedUploadsRule();

  useListenToPendingRevisionUploadsRule();
  useListenToCompletedUploadsToAddAttachmentRevisionRule();
  useListenToRetriedUploadsToMarkPendingAttachmentRevisionAsUploadingRule();
  useListenToCancelledUploadsToCancelAddAttachmentRevisionRule();
  return children;
};
