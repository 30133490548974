/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Box, Divider, Skeleton, Stack, Typography } from "@mui/material";
import { DomainRemoveButtonAndDialog } from "@taketurns-components/user/Organization/OrganizationAccessPolicies/DomainRemoveButtonAndDialog";
import { DomainVerifyButtonAndDialog } from "@taketurns-components/user/Organization/OrganizationAccessPolicies/DomainVerifyButtonAndDialog";
import { OrganizationDomainsComponentProps } from "@taketurns-components/user/Organization/OrganizationDomainsComponentProps";
import { useOrganizationTranslation } from "@taketurns-i18n/user/Organization/useOrganizationTranslation";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";

export const MobileOrganizationDomainList = ({ organizationDomains, loading }: OrganizationDomainsComponentProps) => {
  const { t } = useOrganizationTranslation();

  if (loading) {
    return (
      <Stack spacing={2}>
        <Skeleton variant={"text"} />
        <Skeleton variant={"text"} />
        <Skeleton variant={"text"} />
      </Stack>
    );
  }

  return (
    <Stack spacing={2}>
      {organizationDomains.map((domain) => (
        <Box key={domain.name} data-cy={`DomainRow.${domain.name}`}>
          <Stack>
            <Typography variant="body2">{domain.name}</Typography>
            <Stack direction="row" alignItems="center">
              <Typography
                color={TakeTurnsColors.darkGray}
                variant="body2"
                data-cy={`Status.${t(`OrganizationDomain.status.${domain.status}`)}`}
                flex={1}
              >
                {t(`OrganizationDomain.status.${domain.status}`)}
              </Typography>
              <DomainVerifyButtonAndDialog domain={domain} />
              <DomainRemoveButtonAndDialog domain={domain} />
            </Stack>
          </Stack>
          <Divider />
        </Box>
      ))}
    </Stack>
  );
};
