/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useEffect } from "react";
import { useSetCollaborationFormOwningParty } from "@taketurns-repositories/collaboration/state/write/collaborationCreation/form/useSetCollaborationFormOwningParty";
import { useFetchUserOrganizationNameRule } from "../../queries/collaborationCreation/useFetchUserOrganizationNameRule";

export const useSetDefaultOwningPartyRule = () => {
  const { userOrganizationName, loadingUserOrganizationName } = useFetchUserOrganizationNameRule();
  const setCollaborationFormOwningParty = useSetCollaborationFormOwningParty();
  useEffect(() => {
    if (userOrganizationName && !loadingUserOrganizationName) {
      setCollaborationFormOwningParty(userOrganizationName);
    }
  }, [loadingUserOrganizationName, userOrganizationName, setCollaborationFormOwningParty]);
};
