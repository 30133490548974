/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Skeleton, Stack } from "@mui/material";
import { TakeTurnsCard } from "../style/TakeTurnsCard";
import { TakeTurnsCardContent } from "../style/TakeTurnsCardContent";
import { TakeTurnsCardFooter } from "../style/TakeTurnsCardFooter";

export const TakeTurnsCardSkeleton = () => {
  return (
    <TakeTurnsCard>
      <TakeTurnsCardContent>
        <Stack direction="column" justifyContent={"space-between"} height={"100%"}>
          <Skeleton sx={{ flex: 1 }} />
        </Stack>
      </TakeTurnsCardContent>
      <TakeTurnsCardFooter>
        <Stack
          component="div"
          width={"100%"}
          padding={"0 10px"}
          direction={"row"}
          spacing={1}
          alignItems={"center"}
          justifyContent="space-between"
        >
          <Skeleton width={30} />
        </Stack>
      </TakeTurnsCardFooter>
    </TakeTurnsCard>
  );
};
