/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { AUTHENTICATED_ROUTES } from "./authenticatedRoutes.constants";

export const PROFILE_SECTION = "profile";
export const MANAGE_PLAN_SECTION = "plan";
export const PREFERENCES_SECTION = "preferences";
export const ORGANIZATION_SECTION = "organization";
export const INTEGRATIONS_SECTION = "integrations";
export const ACCESS_POLICIES_SECTION = "accessPolicies";
export type UserSettingsSection =
  | typeof PROFILE_SECTION
  | typeof MANAGE_PLAN_SECTION
  | typeof PREFERENCES_SECTION
  | typeof ORGANIZATION_SECTION
  | typeof INTEGRATIONS_SECTION
  | typeof ACCESS_POLICIES_SECTION;
export const USER_SETTINGS_SEARCH_PARAM = "section";
export const getUserSettingsSectionUrl = (section: UserSettingsSection) =>
  `${AUTHENTICATED_ROUTES.USER_SETTINGS}?${USER_SETTINGS_SEARCH_PARAM}=${section}`;
