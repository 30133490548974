/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Box, styled } from "@mui/material";
import { useEffect } from "react";
import { ContentSelectionContentFooterMenu } from "@taketurns-components/collaboration/CollaborationView/CollaborationContent/ContentBrowser/ContentFooterMenu/ContentSelectionContentFooterMenu";
import { useGetUploadIdsByCollaborationId } from "@taketurns-repositories/collaboration/state/read/collaborationView/getUploadIdsByCollaborationId";
import { useAttachFilesInCurrentFolderRule } from "@taketurns-rules/collaboration/commands/collaborationView/attachment/useAttachFilesInCurrentFolderRule";
import { useResetContentSelectionStateRule } from "@taketurns-rules/collaboration/commands/collaborationView/contentSelection/useResetContentSelectionStateRule";
import { useAddFolderRule } from "@taketurns-rules/collaboration/commands/collaborationView/folder/useAddFolderRule";
import { useAddRequestRule } from "@taketurns-rules/collaboration/commands/collaborationView/request/useAddRequestRule";
import {
  CollaborationCommand,
  useCollaborationPermissions,
} from "@taketurns-rules/collaboration/permissions/useCollaborationPermissions";
import { useGetDisplayedCollaborationIdRule } from "@taketurns-rules/collaboration/queries/collaboration/useGetDisplayedCollaborationIdRule";
import { useIsContentSelectionStateActiveRule } from "@taketurns-rules/collaboration/queries/collaborationView/contentSelection/useIsContentSelectionStateActiveRule";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { DefaultContentFooterMenu } from "./DefaultContentFooterMenu";

interface ContentFooterMenuProviderProps {
  editable: boolean;
  displayContentAsCard: boolean;
  onToggleViewModeClick: () => unknown;
  isEmptyContent?: boolean;
}

export const ContentFooterMenuProvider = ({
  editable,
  displayContentAsCard,
  onToggleViewModeClick,
  isEmptyContent,
}: ContentFooterMenuProviderProps) => {
  const resetContentSelectionState = useResetContentSelectionStateRule();
  const isContentSelectionActive = useIsContentSelectionStateActiveRule();

  useEffect(() => {
    return () => {
      resetContentSelectionState();
    };
  }, [resetContentSelectionState]);

  if (isContentSelectionActive) {
    return <ContentSelectionContentFooterMenuContainer />;
  }

  return (
    <DefaultContentFooterMenuProvider
      editable={editable}
      displayContentAsCard={displayContentAsCard}
      onToggleViewModeClick={onToggleViewModeClick}
      isEmptyContent={isEmptyContent}
    />
  );
};

const DefaultContentFooterMenuProvider = ({
  editable,
  displayContentAsCard,
  onToggleViewModeClick,
  isEmptyContent,
}: ContentFooterMenuProviderProps) => {
  const collaborationId = useGetDisplayedCollaborationIdRule();
  const uploadIds = useGetUploadIdsByCollaborationId(collaborationId);

  const canUserRoleAddFiles = useCollaborationPermissions(CollaborationCommand.ADD_FILES);
  const canUserAddFiles = editable && canUserRoleAddFiles;
  const attachFilesRule = useAttachFilesInCurrentFolderRule();
  const attachFiles = async (files: FileList) => {
    await attachFilesRule(Array.from(files));
  };

  const canUserRoleAddFolders = useCollaborationPermissions(CollaborationCommand.CREATE_FOLDER);
  const canUserAddFolders = editable && canUserRoleAddFolders;
  const { addFolder } = useAddFolderRule();

  const canUserRoleAddRequests = useCollaborationPermissions(CollaborationCommand.REQUEST_FILES);
  const canUserAddRequests = editable && canUserRoleAddRequests;
  const { addRequest } = useAddRequestRule();

  return (
    <ContentFooterMenuContainer>
      <DefaultContentFooterMenu
        canUserAddFiles={canUserAddFiles}
        canUserAddFolders={canUserAddFolders}
        canUserAddRequests={canUserAddRequests}
        displayContentAsCard={displayContentAsCard}
        onToggleViewModeClick={onToggleViewModeClick}
        isEmptyContent={isEmptyContent}
        uploadIds={uploadIds}
        attachFiles={attachFiles}
        addFolder={addFolder}
        addRequest={addRequest}
      />
    </ContentFooterMenuContainer>
  );
};

const ContentSelectionContentFooterMenuContainer = () => {
  return (
    <ContentFooterMenuContainer blueBackground>
      <ContentSelectionContentFooterMenu />
    </ContentFooterMenuContainer>
  );
};

const ContentFooterMenuContainer = styled(Box, { shouldForwardProp: (prop) => prop !== "blueBackground" })<{
  blueBackground?: boolean;
}>(({ theme, blueBackground }) => ({
  position: "absolute",
  right: 0,
  bottom: "env(safe-area-inset-bottom)",
  left: 0,
  display: "flex",
  alignItems: "center",
  backgroundColor: blueBackground ? TakeTurnsColors.darkBlue : "white",
  borderTop: `1px solid ${TakeTurnsColors.lightGray}`,
  padding: theme.spacing(0, 3),
  height: "50px",
}));
