/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { AddOrganizationDomainInput } from "@taketurns/taketurns-graphql-repository";
import { useOrganizationTranslation } from "@taketurns-i18n/user/Organization/useOrganizationTranslation";
import { useAddOrganizationDomain } from "@taketurns-repositories/user/graphql/mutations/useAddOrganizationDomain";
import { useDisplayAndLogNotificationErrorRule } from "./useDisplayAndLogNotificationErrorRule";
import { useDisplayNotificationSuccessRule } from "./useDisplayNotificationSuccessRule";

export const useAddOrganizationDomainRule = () => {
  const {
    addOrganizationDomain: addOrganizationDomainCommand,
    loadingAddOrganizationDomain,
    errorOnAddOrganizationDomain,
  } = useAddOrganizationDomain();

  const displayNotificationAndLogError = useDisplayAndLogNotificationErrorRule();

  const { t } = useOrganizationTranslation();
  const displaySuccessNotification = useDisplayNotificationSuccessRule();
  const addOrganizationDomain = (addOrganizationDomainInput: AddOrganizationDomainInput) => {
    addOrganizationDomainCommand(addOrganizationDomainInput)
      .then(() => displaySuccessNotification(t("Notifications.addOrganizationDomainSuccess")))
      .catch((error) => displayNotificationAndLogError(error));
  };
  return { addOrganizationDomain, errorOnAddOrganizationDomain, loadingAddOrganizationDomain };
};
