/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Typography } from "@mui/material";
import { Fragment } from "react";
import {
  DesktopOrganizationMemberAndInvitationGrid,
} from "@taketurns-components/user/Organization/OrganizationMemberAndInvitationList/DesktopOrganizationMemberAndInvitationGrid/DesktopOrganizationMemberAndInvitationGrid";
import {
  MobileOrganizationMemberAndInvitationList,
} from "@taketurns-components/user/Organization/OrganizationMemberAndInvitationList/MobileOrganizationMemberAndInvitationList/MobileOrganizationMemberAndInvitationList";
import { OrganizationMemberOrInvitation } from "@taketurns-components/user/Organization/OrganizationMemberAndInvitationList/shared/SharedComponentTypes";
import { useOrganizationTranslation } from "@taketurns-i18n/user/Organization/useOrganizationTranslation";
import { useIsOnMobileRule } from "@taketurns-rules/commons/theme/useIsOnMobileRule";

import { useFetchConnectedUserIdAndEmailRule } from "@taketurns-rules/user/queries/useFetchConnectedUserIdAndEmailRule";
import { useFetchUserOrganizationMembersRule } from "@taketurns-rules/user/queries/useFetchUserOrganizationMembersRule";
import { useFetchUserOrganizationRule } from "@taketurns-rules/user/queries/useFetchUserOrganizationRule";
import { useSubscribeToOrganizationMemberInvitationListChangedRule } from "@taketurns-rules/user/subscriptions/useSubscribeToOrganizationMemberInvitationListChangedRule";

export const OrganizationMemberAndInvitationList = () => {
  const { members, invitations, loadingUserOrganizationMembers, errorOnLoadingUserOrganizationMembers } =
    useFetchUserOrganizationMembersRule();
  const { t } = useOrganizationTranslation();

  const { userOrganization } = useFetchUserOrganizationRule();
  useSubscribeToOrganizationMemberInvitationListChangedRule(userOrganization?.id);
  const { loadingConnectedUserId, errorOnLoadingConnectedUserId, connectedUserId } =
    useFetchConnectedUserIdAndEmailRule();

  const isOnMobile = useIsOnMobileRule();

  if (errorOnLoadingUserOrganizationMembers || errorOnLoadingConnectedUserId) {
    console.error(errorOnLoadingUserOrganizationMembers || errorOnLoadingConnectedUserId);
    return (
      <Typography variant={"caption"} color={"error"}>
        {t("error")}
      </Typography>
    );
  }

  const ListComponent = isOnMobile
    ? MobileOrganizationMemberAndInvitationList
    : DesktopOrganizationMemberAndInvitationGrid;

  const membersAndInvitations: OrganizationMemberOrInvitation[] = [
    ...invitations.map((invitation) => ({
      ...invitation,
      isInvitation: true as const,
    })),
    ...members.map((member) => ({
      ...member,
      isInvitation: false as const,
    })),
  ];

  return (
    <Fragment>
      <Typography>{t("Organization.users")}</Typography>
      <ListComponent
        loading={loadingUserOrganizationMembers || loadingConnectedUserId}
        membersAndInvitations={membersAndInvitations}
        connectedUserId={connectedUserId}
      />
    </Fragment>
  );
};
