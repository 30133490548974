/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useMutation } from "@apollo/client";
import { InviteToOrganizationInput } from "@taketurns/taketurns-graphql-repository";

export const useInviteToOrganization = () => {
  const mutation = gql`
    mutation inviteToOrganization($inviteToOrganizationInput: InviteToOrganizationInput!) {
      inviteToOrganization(inviteToOrganizationInput: $inviteToOrganizationInput) {
        id
        invitations {
          userId
          email
          role
          user {
            id
            email
            lastName
            firstName
            avatar
          }
        }
      }
    }
  `;
  const [inviteToOrganizationMutation, { error: errorOnInviteToOrganization, loading: loadingInviteToOrganization }] =
    useMutation(mutation);
  const inviteToOrganization = (inviteToOrganizationInput: InviteToOrganizationInput) => {
    return inviteToOrganizationMutation({ variables: { inviteToOrganizationInput } });
  };
  return { inviteToOrganization, errorOnInviteToOrganization, loadingInviteToOrganization };
};
