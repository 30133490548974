/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { FileExtension } from "./FileExtension";

interface DocumentExtensionProps {
  extension: string;
  size: string;
}

export const DocumentExtension = (props: DocumentExtensionProps) => {
  return <FileExtension fileExtension={props.extension} sizeInBytes={+props.size} />;
};
