/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Box, Button, IconButton, Menu, MenuItem, Stack, styled } from "@mui/material";
import { Browser } from "@syncfusion/ej2-base";
import { useRef, useState } from "react";
import { useSharedCollaborationTranslation } from "@taketurns-i18n/collaboration/shared/useSharedCollaborationTranslation";
import { useIsOnMobileRule } from "@taketurns-rules/commons/theme/useIsOnMobileRule";
import { IconChevronDown, IconChevronUp, IconEdit3, IconSignature, IconZoomIn, IconZoomOut } from "../../../commons/icons";

interface PdfViewerOptionsProps {
  numPages: number;
  currentDisplayedPage: number;
  zoomIn: () => void;
  zoomOut: () => void;
  selectPageToView: (pageNumber: number) => void;
  sign: () => void;
  toggleAnnotationsEdition: () => void;
  enableSignatures: boolean;
  enableAnnotations: boolean;
}

export const PdfViewerOptions = (props: PdfViewerOptionsProps) => {
  const { t } = useSharedCollaborationTranslation();

  const isDevice = Browser.isDevice;

  return (
    <PdfPreviewOptionsContainer className="PdfPreviewOptionsContainer">
      {props.enableSignatures && (
        <Button startIcon={<IconSignature />} variant={"text"} onClick={props.sign}>
          {t("PdfViewer.annotationsButtons.sign")}
        </Button>
      )}
      {!isDevice && props.enableAnnotations && (
        <Button startIcon={<IconEdit3 />} variant={"text"} onClick={props.toggleAnnotationsEdition}>
          {t("PdfViewer.annotationsButtons.annotate")}
        </Button>
      )}
      {props.numPages > 1 && (
        <PageSelector
          currentDisplayedPage={props.currentDisplayedPage}
          numPages={props.numPages}
          selectPageToView={props.selectPageToView}
        />
      )}
      <Stack direction="row">
        <IconButton size={"small"} onClick={props.zoomOut}>
          <IconZoomOut />
        </IconButton>
        <IconButton size={"small"} onClick={props.zoomIn}>
          <IconZoomIn />
        </IconButton>
      </Stack>
    </PdfPreviewOptionsContainer>
  );
};

const PdfPreviewOptionsContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
  alignItems: "center",
  padding: theme.spacing(0, 3),
  gap: theme.spacing(1),
}));

interface PageSelectionItemsProps {
  numPages: number;
  currentDisplayedPage: number;
  selectPageToView: (pageNumber: number) => void;
}

const PageSelector = (props: PageSelectionItemsProps) => {
  const [pageSelectionOpened, setPageSelectionOpened] = useState(false);
  const openPageSelectorButtonRef = useRef<HTMLButtonElement>(null);
  const selectPageAndCloseMenu = (pageNumber: number) => {
    props.selectPageToView(pageNumber);
    setPageSelectionOpened(false);
  };
  const isOnMobile = useIsOnMobileRule();
  return (
    <Box>
      <Button
        ref={openPageSelectorButtonRef}
        variant={"text"}
        onClick={() => setPageSelectionOpened(true)}
        endIcon={pageSelectionOpened ? <IconChevronUp /> : <IconChevronDown />}
        sx={{ paddingX: 0 }}
      >
        {!isOnMobile && "Page"} {props.currentDisplayedPage}/{props.numPages}
      </Button>
      <Menu
        slotProps={{ paper: { style: { maxHeight: 200 } } }}
        anchorEl={openPageSelectorButtonRef.current}
        open={pageSelectionOpened}
        onClose={() => setPageSelectionOpened(false)}
      >
        {Array.from({ length: props.numPages }).map((_, pageNumber) => (
          <MenuItem key={pageNumber + 1} value={pageNumber + 1} onClick={() => selectPageAndCloseMenu(pageNumber + 1)}>
            {pageNumber + 1}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};
