/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { CSSProperties } from "react";
import { useCollaborationTranslation } from "@taketurns-i18n/collaboration/useCollaborationTranslation";
import { TIP_KEYS } from "@taketurns-i18n/commons/TIP_KEYS";
import { UserTip } from "../../../../user/UserTip/UserTip";

export const CollaborationFilesTip = ({ style }: { style?: CSSProperties }) => {
  const { t } = useCollaborationTranslation("tips");

  return <UserTip style={style} message={t(TIP_KEYS.FILES)} tipKey={TIP_KEYS.FILES} />;
};
