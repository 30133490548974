/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Attachment } from "@taketurns/taketurns-graphql-repository";
import { useGetDisplayedCollaborationId } from "@taketurns-repositories/collaboration/context/useGetDisplayedCollaborationId";
import { useRemoveAttachment } from "@taketurns-repositories/collaboration/graphql/mutations/content/attachments/useRemoveAttachment";

export const useRemoveAttachmentRule = (attachment: Attachment) => {
  const collaborationId = useGetDisplayedCollaborationId();
  const { removeAttachmentMutation, removeAttachmentResponseData, removeAttachmentLoading, errorOnRemovingAttachment } =
    useRemoveAttachment(collaborationId, attachment);
  return {
    removeAttachment: removeAttachmentMutation,
    removeAttachmentResponseData: removeAttachmentResponseData,
    removeAttachmentLoading: removeAttachmentLoading,
    errorOnRemovingAttachment: errorOnRemovingAttachment,
  };
};
