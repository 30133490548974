/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Box } from "@mui/material";
import {
  useGetCurrentFolderIdInCreation,
} from "@taketurns-repositories/collaboration/state/read/collaborationCreation/useGetCurrentFolderIdInCreation";
import {
  useAddFilesInCreationRule,
} from "@taketurns-rules/collaboration/commands/collaborationCreation/attachment/pending/useAddFilesInCreationRule";
import { DropZoneConfig, FilesDragAndDrop } from "../../../Shared/FilesDragAndDrop";
import { ContentAsList } from "./ContentAsList";

export const ContentAsListWithDropZone = () => {
  const addFilesInCreationRule = useAddFilesInCreationRule();
  const currentFolderId = useGetCurrentFolderIdInCreation();
  const addFilesInCreation = async (files: File[]) => {
    await addFilesInCreationRule(files, currentFolderId);
  };
  const dropZoneWithBackdropConfig: DropZoneConfig = {
    handleFilesDropping: addFilesInCreation,
    className: "ContentAsListWithDropZone__DropZoneContainer",
    sx: {
      position: "relative",
      height: 1,
    },
  };
  return (
    <FilesDragAndDrop ContainerComponent={Box} dropZoneConfig={dropZoneWithBackdropConfig}>
      <ContentAsList displayDragAndDropInstructions />
    </FilesDragAndDrop>
  );
};
