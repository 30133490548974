/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useMutation } from "@apollo/client";
import { collaborationParticipantsFragment } from "@taketurns-repositories/collaboration/graphql/fragments/collaborationParticipantsFragment";
import { ChangeParticipantRoleInput, MutationChangeParticipantRoleArgs, ParticipantsAndInvitations } from "@taketurns/taketurns-graphql-repository";

export const useChangeParticipantRole = () => {
  const mutation = gql`
    mutation changeParticipantRole($input: ChangeParticipantRoleInput!) {
      changeParticipantRole(input: $input) {
        ...CollaborationParticipantsFields
      }
    }
    ${collaborationParticipantsFragment}
  `;
  const [editParticipantRoleMutation, { error: errorOnEditParticipantRole, loading: loadingEditParticipantRole }] =
    useMutation<
      {
        changeParticipantRole: ParticipantsAndInvitations;
      },
      MutationChangeParticipantRoleArgs
    >(mutation);
  const editParticipantRoleCommand = (input: ChangeParticipantRoleInput, onRoleEditionSuccess?: () => void) => {
    editParticipantRoleMutation({ variables: { input }, onCompleted: onRoleEditionSuccess });
  };

  return { editParticipantRoleCommand, errorOnEditParticipantRole, loadingEditParticipantRole };
};
