/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { OrganizationInvitation, OrganizationUserRole } from "@taketurns/taketurns-graphql-repository";
import { OrganizationUserRoleSelector } from "@taketurns-components/user/Organization/shared/OrganizationUserRoleSelector";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { useModifyInvitationRoleRule } from "@taketurns-rules/user/commands/useModifyInvitationRoleRule";
import { useSubscribeToOrganizationInvitationRoleChangeRule } from "@taketurns-rules/user/subscriptions/useSubscribeToOrganizationInvitationRoleChangeRule";

export const OrganizationInvitationRoleSelector = ({ invitation }: { invitation: OrganizationInvitation }) => {
  const { modifyOrganizationInvitationRole: modifyInvitationRoleCommand, loadingModifyInvitationRole } =
    useModifyInvitationRoleRule();
  useSubscribeToOrganizationInvitationRoleChangeRule(invitation.email, invitation.userId);
  const modifyInvitationRole = (newRole: OrganizationUserRole) => {
    modifyInvitationRoleCommand(invitation, newRole);
  };

  return (
    <OrganizationUserRoleSelector
      sx={{ color: TakeTurnsColors.darkGray }}
      onRoleSelected={modifyInvitationRole}
      loading={loadingModifyInvitationRole}
      role={invitation.role}
    />
  );
};
