/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useSharedCollaborationTranslation } from "@taketurns-i18n/collaboration/shared/useSharedCollaborationTranslation";
import { useUnhideCollaboration } from "@taketurns-repositories/collaboration/graphql/mutations/hide/useUnhideCollaboration";
import { useFetchCollaborationName } from "@taketurns-repositories/collaboration/graphql/queries/fields/useFetchCollaborationName";
import { useNavigateToCollaborationListRule } from "@taketurns-rules/collaboration/commands/navigation/useNavigateToCollaborationListRule";

export const useUnhideCollaborationRule = (collaborationId: string) => {
  const [unhideCollaborationMutation, unhideCollaborationMutationResult] = useUnhideCollaboration();
  const { enqueueSnackbar } = useSnackbar();
  const { name } = useFetchCollaborationName(collaborationId);
  const { t } = useSharedCollaborationTranslation();

  useEffect(() => {
    if (unhideCollaborationMutationResult.error) {
      enqueueSnackbar(unhideCollaborationMutationResult.error.message, {
        variant: "error",
      });
      console.error(unhideCollaborationMutationResult.error);
      unhideCollaborationMutationResult.reset();
    }
  }, [unhideCollaborationMutationResult.error]);

  const navigateToCollaborationList = useNavigateToCollaborationListRule();
  const unhideCollaborationCommand = () => {
    unhideCollaborationMutation({
      variables: { collaborationId: collaborationId },
      onCompleted: () => {
        enqueueSnackbar(t("CollaborationMenu.unhide.successMessage", { name }), {
          variant: "info",
        });
        navigateToCollaborationList();
      },
    });
  };

  return {
    unhideCollaborationCommand,
    result: unhideCollaborationMutationResult,
  };
};
