/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { OrganizationMember, OrganizationUserRole } from "@taketurns/taketurns-graphql-repository";
import { OrganizationUserRoleSelector } from "@taketurns-components/user/Organization/shared/OrganizationUserRoleSelector";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { useModifyMemberRoleRule } from "@taketurns-rules/user/commands/useModifyMemberRoleRule";
import { useSubscribeToOrganizationMemberRoleChangeRule } from "@taketurns-rules/user/subscriptions/useSubscribeToOrganizationMemberRoleChangeRule";

export const OrganizationMemberRoleSelector = ({ member }: { member: OrganizationMember }) => {
  const { modifyOrganizationMemberRole: modifyMemberRoleCommand } = useModifyMemberRoleRule();
  useSubscribeToOrganizationMemberRoleChangeRule(member.userId);
  const modifyMemberRole = (newRole: OrganizationUserRole) => {
    modifyMemberRoleCommand(member, newRole);
  };
  return (
    <OrganizationUserRoleSelector
      onRoleSelected={modifyMemberRole}
      role={member.role}
      sx={{ color: TakeTurnsColors.darkGray }}
    />
  );
};
