/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Attachment } from "@taketurns/taketurns-graphql-repository";
import { useGetDisplayedCollaborationId } from "@taketurns-repositories/collaboration/context/useGetDisplayedCollaborationId";
import { useRestoreAttachment } from "@taketurns-repositories/collaboration/graphql/mutations/content/attachments/useRestoreAttachment";

export const useRestoreAttachmentRule = (attachment: Attachment) => {
  const collaborationId = useGetDisplayedCollaborationId();
  const {
    restoreAttachmentMutation,
    restoreAttachmentResponseData,
    restoreAttachmentLoading,
    errorOnRestoringAttachment,
  } = useRestoreAttachment(collaborationId, attachment);
  return {
    restoreAttachment: restoreAttachmentMutation,
    restoreAttachmentResponseData,
    restoreAttachmentLoading,
    errorOnRestoringAttachment,
  };
};
