/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Party, Role } from "@taketurns/taketurns-graphql-repository";
import { useGetCollaborationFormInvitationsByParty } from "@taketurns-repositories/collaboration/state/read/collaborationCreation/form/useGetCollaborationFormInvitationsByParty";
import { useSetCollaborationFormInvitationsFieldError } from "@taketurns-repositories/collaboration/state/write/collaborationCreation/form/error/useSetCollaborationFormInvitationsFieldError";

export const useValidateCollaborationInvitationsRule = () => {
  const invitations = useGetCollaborationFormInvitationsByParty(Party.Invited);
  const setCollaborationFormInvitationsFieldError = useSetCollaborationFormInvitationsFieldError();

  return () => {
    const areInvitationsValid = invitations.some((i) => i.role === Role.Owner);
    setCollaborationFormInvitationsFieldError(!areInvitationsValid);
    return areInvitationsValid;
  };
};
