/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useSharedCollaborationTranslation } from "@taketurns-i18n/collaboration/shared/useSharedCollaborationTranslation";
import { useSubscribeToCancelPendingUpdates } from "@taketurns-repositories/collaboration/graphql/subscriptions/updates/useSubscribeToCancelPendingUpdates";
import { useGetDisplayedCollaborationIdRule } from "@taketurns-rules/collaboration/queries/collaboration/useGetDisplayedCollaborationIdRule";
import { useFetchConnectedUserPartyRule } from "@taketurns-rules/collaboration/queries/context/useFetchConnectedUserPartyRule";
import { useSnackbar } from "notistack";

export const useSubscribeToCancelPendingUpdatesRule = (partyId?: string, collaborationId?: string) => {
  const displayedCollaborationId = useGetDisplayedCollaborationIdRule();
  const { connectedUserPartyId } = useFetchConnectedUserPartyRule();

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useSharedCollaborationTranslation();

  const displayPendingUpdatesCancelledNotification = () => {
    enqueueSnackbar(t("CancelPendingUpdates.snackbar"), { variant: "info" });
  };

  return useSubscribeToCancelPendingUpdates(
    { collaborationId: collaborationId ?? displayedCollaborationId, partyId: partyId ?? connectedUserPartyId },
    displayPendingUpdatesCancelledNotification,
  );
};
