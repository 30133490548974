/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useCallback } from "react";
import { useMoveRequestIntoFolderInCreationRule } from "@taketurns-rules/collaboration/commands/collaborationCreation/request/useMoveRequestIntoFolderInCreationRule";
import { MoveDestinationDialogInCreation } from "../MoveDestination/MoveDestinationDialogInCreation";

interface RequestMoveDestinationDialogInCollaborationCreationProps {
  requestId: string;
  open: boolean;
  onClose: () => void;
}

export const RequestMoveDestinationDialogInCollaborationCreation = ({
  requestId,
  open,
  onClose,
}: RequestMoveDestinationDialogInCollaborationCreationProps) => {
  return <RequestMoveDestinationDialogOnSuccess requestId={requestId} onClose={onClose} open={open} />;
};

interface RequestMoveDestinationDialogOnSuccessProps {
  requestId: string;
  onClose: () => void;
  open: boolean;
}

const RequestMoveDestinationDialogOnSuccess = ({
  requestId,
  onClose,
  open,
}: RequestMoveDestinationDialogOnSuccessProps) => {
  const moveRequestToFolder = useMoveRequestIntoFolderInCreationRule(requestId);
  const moveRequestToFolderAndClose = useCallback(
    (folderId: string) => {
      moveRequestToFolder(folderId);
      onClose();
    },
    [moveRequestToFolder, onClose],
  );
  return (
    <MoveDestinationDialogInCreation onSubmitDestination={moveRequestToFolderAndClose} onClose={onClose} open={open} />
  );
};
