/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { IconButton, Stack, Typography } from "@mui/material";
import { useCollaborationTranslation } from "@taketurns-i18n/collaboration/useCollaborationTranslation";
import { useFetchParticipantsAndInvitationsRule } from "@taketurns-rules/collaboration/queries/collaborationView/useFetchParticipantsAndInvitationsRule";
import { useSubscribeToParticipantsAndInvitationsChangedRule } from "@taketurns-rules/collaboration/subscriptions/useSubscribeToParticipantsAndInvitationsChangedRule";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { IconChevronLeft } from "../../../../../../commons/icons";

interface PageHeaderProps {
  closeDialog: () => void;
}

export const MobileParticipantsPageHeader = ({ closeDialog }: PageHeaderProps) => {
  const { participants } = useFetchParticipantsAndInvitationsRule();
  useSubscribeToParticipantsAndInvitationsChangedRule();
  const participantsCount = participants.length;

  const { t } = useCollaborationTranslation("participantCollaboration");

  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between" padding="16px 24px">
      <IconButton onClick={closeDialog} disableRipple sx={{ justifyContent: "flex-start", width: "50px" }}>
        <IconChevronLeft color={TakeTurnsColors.darkGray} size={24} />
      </IconButton>

      <Typography textAlign="center">
        {participantsCount} {t(participantsCount === 1 ? "participant" : "participants")}
      </Typography>

      <div style={{ width: "50px" }} />
    </Stack>
  );
};
