/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Stack } from "@mui/material";
import { forwardRef } from "react";
import { ContentStatus, Folder } from "@taketurns/taketurns-graphql-repository";
import { FolderIcon } from "@taketurns-components/collaboration/CollaborationView/CollaborationContent/ListView/FolderListItem/FolderIcon";
import { FolderStats } from "@taketurns-components/collaboration/CollaborationView/CollaborationContent/ListView/FolderListItem/FolderStats";
import { ContentElementLastUpdate } from "@taketurns-components/collaboration/CollaborationView/CollaborationContent/shared/components/ContentElementLastUpdate";
import { ContextMenuOnButton } from "@taketurns-components/collaboration/ContextMenu/ContextMenuOnButton";
import { WithContextMenu } from "@taketurns-components/collaboration/ContextMenu/WithContextMenu";
import { FolderUpdatesMarker, ReviewListItemChip } from "@taketurns-components/collaboration/Shared/content/Markers";
import { TakeTurnsListItem } from "@taketurns-components/collaboration/Shared/list/TakeTurnsListItem";
import { useFetchLastUpdateColor } from "@taketurns-rules/collaboration/queries/content/UseFetchLastUpdateColor";
import { useGetFolderContextMenuConfigurationRule } from "@taketurns-rules/collaboration/queries/folder/useGetFolderContextMenuConfigurationRule";
import { ListItemTitle } from "../ListItemTitle";

interface DesktopFolderListItemProps {
  folder: Folder;
  isFolderRemoved: boolean;
  shouldDisplayLastUpdate: boolean;
  lastUpdateStatus: ContentStatus;
  shouldDisplayUpdatesMarker: boolean;
  shouldDisplayReviewChip: boolean;
  hasFolderBeenRemovedInPreviousTurn: boolean;
  moveIntoListItemFolder: () => void;
  contextMenuConfiguration: ReturnType<typeof useGetFolderContextMenuConfigurationRule> | null;
  backgroundColor: string;
}
export const DesktopFolderListItem = (props: DesktopFolderListItemProps) => {
  if (!props.contextMenuConfiguration) {
    return <InnerListItem {...props} />;
  }

  const ListItemWithContextMenu = WithContextMenu<DesktopFolderListItemProps>(
    InnerListItem,
    props.contextMenuConfiguration,
  );
  return <ListItemWithContextMenu {...props} />;
};

const InnerListItem = forwardRef<HTMLLIElement | null, DesktopFolderListItemProps>((props, ref) => {
  const { color: lastUpdateColor } = useFetchLastUpdateColor();
  const borderColor = props.shouldDisplayLastUpdate ? lastUpdateColor : props.backgroundColor;

  return (
    <TakeTurnsListItem
      ref={ref}
      titleSlot={
        <Stack direction="row" alignItems={"center"} spacing={1} overflow="hidden">
          <FolderIcon />
          <ListItemTitle title={props.folder.name} shouldStrikeTitle={props.isFolderRemoved} />
          {props.shouldDisplayUpdatesMarker && <FolderUpdatesMarker folder={props.folder} />}
        </Stack>
      }
      subtitleSlot={props.shouldDisplayReviewChip && <ReviewListItemChip />}
      middleSlot={props.shouldDisplayLastUpdate && <ContentElementLastUpdate status={props.lastUpdateStatus} />}
      rightEndSlot={
        <Stack direction="row" alignItems="center">
          <FolderStats folder={props.folder} />
          {props.contextMenuConfiguration && (
            <ContextMenuOnButton contextMenuConfiguration={props.contextMenuConfiguration} />
          )}
        </Stack>
      }
      onClick={props.hasFolderBeenRemovedInPreviousTurn ? undefined : props.moveIntoListItemFolder}
      sx={{
        borderRight: `8px solid ${borderColor}`,
        paddingRight: borderColor ? undefined : "18px",
        backgroundColor: props.backgroundColor,
        "&:hover": {
          borderRight: `8px solid ${borderColor}`,
        },
      }}
    />
  );
});

InnerListItem.displayName = "DesktopFolderListItem__InnerListItem";
