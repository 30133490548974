/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { CircularProgress } from "@mui/material";
import { ReactNode } from "react";
import { TakeTurnsDialogFooter } from "@taketurns-components/collaboration/Shared/content/TakeTurnsDialogFooter";
import { TakeTurnsDefaultButton } from "@taketurns-components/commons/button";
import { HelpCenterSections } from "@taketurns-rules/commons/enum/helpCenterSectionsEnum";

interface DefaultDialogFooterProps {
  actionButtonLabel: string;
  actionButtonEnabled: boolean;
  actionExecutionLoading: boolean;
  executeAction: () => unknown;
  closeDialog: () => void;
  actionButtonIcon?: ReactNode;
  helpCenterSection?: HelpCenterSections;
  hasCancelAction: boolean;
}

export const DefaultDialogFooter = (props: DefaultDialogFooterProps) => {
  const executeActionAndClose = async () => {
    await props.executeAction();
    props.closeDialog();
  };
  return (
    <TakeTurnsDialogFooter
      closeDialog={props.closeDialog}
      helpCenterSection={props.helpCenterSection}
      hasCancelAction={props.hasCancelAction}
    >
      <TakeTurnsDefaultButton
        data-cy="ConfirmDialogActionButton"
        disabled={!props.actionButtonEnabled}
        onClick={executeActionAndClose}
        startIcon={props.actionButtonIcon}
        endIcon={props.actionExecutionLoading ? <CircularProgress size={16} /> : undefined}
      >
        {props.actionButtonLabel}
      </TakeTurnsDefaultButton>
    </TakeTurnsDialogFooter>
  );
};
