/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useFetchHasUserOrganizationEnoughSpaceAvailable } from "@taketurns-repositories/collaboration/graphql/queries/features/useFetchHasUserOrganizationEnoughSpaceAvailable";

export function useFetchHasUserOrganizationEnoughSpaceAvailableRule() {
  const hasUserOrganizationEnoughSpaceAvailable = useFetchHasUserOrganizationEnoughSpaceAvailable();
  return async (files: File[]): Promise<boolean> => {
    const neededSpaceInBytes = files.reduce((totalSpace, file) => totalSpace + file.size, 0);
    return await hasUserOrganizationEnoughSpaceAvailable(neededSpaceInBytes);
  };
}
