/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useMutation } from "@apollo/client";
import { MutationRemoveParticipantArgs, ParticipantsAndInvitations } from "@taketurns/taketurns-graphql-repository";
import { collaborationParticipantsFragment } from "@taketurns-repositories/collaboration/graphql/fragments/collaborationParticipantsFragment";

export const useRemoveParticipant = () => {
  const mutation = gql`
    mutation removeParticipant($collaborationId: ID!, $userId: ID!) {
      removeParticipant(collaborationId: $collaborationId, userId: $userId) {
        ...CollaborationParticipantsFields
      }
    }
    ${collaborationParticipantsFragment}
  `;
  const [removeParticipantMutation, { error: errorOnRemoveParticipant, loading: loadingRemoveParticipant }] =
    useMutation<
      {
        removeParticipant: ParticipantsAndInvitations;
      },
      MutationRemoveParticipantArgs
    >(mutation);
  const removeParticipantCommand = (collaborationId: string, userId: string, onRemoveSuccess?: () => void) => {
    removeParticipantMutation({ variables: { collaborationId, userId }, onCompleted: onRemoveSuccess });
  };

  return { removeParticipantCommand, errorOnRemoveParticipant, loadingRemoveParticipant };
};
