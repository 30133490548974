/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { createTheme, SvgIcon, SvgIconProps, Theme } from "@mui/material";

/**
 * TakeTurns check icon not checked
 * @param {SvgIconProps} props SvgIcon props
 * @return {React.FC} TakeTurns check icon
 */
const TakeTurnsCheckIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path d="M5 2c-1.654 0-3 1.346-3 3v14c0 1.654 1.346 3 3 3h14c1.654 0 3-1.346 3-3v-14c0-1.654-1.346-3-3-3h-14zm19 3v14c0 2.761-2.238 5-5 5h-14c-2.762 0-5-2.239-5-5v-14c0-2.761 2.238-5 5-5h14c2.762 0 5 2.239 5 5z" />
    </SvgIcon>
  );
};

/**
 * TakeTurns check icon checked
 * @param {SvgIconProps} props SvgIconProps
 * @return {React.FC} TakeTurns check icon checked
 */
const TakeTurnsCheckedIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path d="M10.041 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591zm-5.041-15c-1.654 0-3 1.346-3 3v14c0 1.654 1.346 3 3 3h14c1.654 0 3-1.346 3-3v-14c0-1.654-1.346-3-3-3h-14zm19 3v14c0 2.761-2.238 5-5 5h-14c-2.762 0-5-2.239-5-5v-14c0-2.761 2.238-5 5-5h14c2.762 0 5 2.239 5 5z" />
    </SvgIcon>
  );
};

/**
 * TakeTurns check icon indeterminated
 * @param {SvgIconProps} props SvgIconProps
 * @return {React.FC} TakeTurns check icon indeterminated
 */
const TakeTurnsIndeterminatedCheckedIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path d="M 5 2 C 3.346 2 2 3.346 2 5 L 2 19 C 2 20.654 3.346 22 5 22 L 19 22 C 20.654 22 22 20.654 22 19 L 22 5 C 22 3.346 20.654 2 19 2 Z M 24 5 L 24 19 C 24 21.761 21.761 24 19 24 L 5 24 C 2.239 24 0 21.761 0 19 L 0 5 C 0 2.239 2.239 0 5 0 L 19 0 C 21.761 0 24 2.239 24 5 Z"></path>
      <path d="M 5 11 L 19 11 L 19 13 L 5 13 Z"></path>
    </SvgIcon>
  );
};

const DARK_BLUE = "#0C5799";
const LIGHT_BLUE = "#1C94FD";
const GREEN = "#1EA434";
const VERY_DARK_GRAY = "#444444";
const DARK_GRAY = "#8A8A8A";
const PLACEHOLDER_COLOR = "#A2A2A2";
const MEDIUM_GRAY = "#BBBBBB";
const RED = "#D60800";
const LIGHT_GRAY = "#DDDDDD";
const TIP_BACKGROUND_COLOR = "#EAF4FF";
const BACKGROUND_COLOR = "#F4F6F6";
const HOVER_BACKGROUND_COLOR = "#FAFAFA";
const YELLOW = "#FFAE00";
const WARNING_BACKGROUND = "#FFEFCC";
const BORDER_COLOR = "#EEEEEE";
const AI_ASSISTANT_COLOR = "#BB66CC";
export const TakeTurnsColors = {
  background: BACKGROUND_COLOR,
  borderColor: BORDER_COLOR,
  tipBackground: TIP_BACKGROUND_COLOR,
  warningTipBackground: WARNING_BACKGROUND,
  hoverBackground: HOVER_BACKGROUND_COLOR,
  veryDarkGray: VERY_DARK_GRAY,
  darkBlue: DARK_BLUE,
  lightBlue: LIGHT_BLUE,
  darkGray: DARK_GRAY,
  mediumGray: MEDIUM_GRAY,
  lightGray: LIGHT_GRAY,
  yellow: YELLOW,
  error: RED,
  success: GREEN,
  grayButton: MEDIUM_GRAY,
  caption: DARK_GRAY,
  placeholder: PLACEHOLDER_COLOR,
  connectedUser: DARK_BLUE,
  otherParty: LIGHT_BLUE,
  white: "white",
  updatedElementBackground: WARNING_BACKGROUND,
  aiAssistant: AI_ASSISTANT_COLOR,
};

/**
 * TakeTurns App bar theme
 */
export const TakeTurnsAppBarTheme = {
  desktop: {
    height: 70,
  },
  mobile: {
    height: 90,
  },
};

/**
 * TakeTurns sidebar content height calculated
 */
export const TakeTurnsSidebarContentHeight = `calc(var(--vh, 1vh) * 100 - 140px)`;

/**
 * TakeTurns default theme
 */
export const TakeTurnsTheme: Theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: TakeTurnsColors.darkBlue,
    },
    secondary: {
      main: TakeTurnsColors.lightBlue,
    },
    background: {
      default: TakeTurnsColors.background,
    },
    error: {
      main: TakeTurnsColors.error,
    },
    success: {
      main: TakeTurnsColors.success,
    },
  },
  components: {
    MuiStack: {
      defaultProps: {
        useFlexGap: true,
      },
    },
    MuiAppBar: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          height: TakeTurnsAppBarTheme.desktop.height,
          backgroundColor: "white",
          color: "black",
          borderBottom: `1px solid ${TakeTurnsColors.borderColor}`,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 20,
          boxShadow: "0px 1px 20px 0px rgba(0, 0, 0, 0.05)",
          padding: 20,
        },
      },
    },
    MuiIconButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          "&:hover": {
            backgroundColor: "unset",
            color: TakeTurnsColors.darkBlue,
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true,
        disableElevation: true,
        variant: "contained",
      },
      styleOverrides: {
        root: {
          textDecoration: "none",
          textTransform: "none",
          borderRadius: 20,
          fontSize: 14,
          maxHeight: 30,
          transform: "scale(1)",
          transition: "all .2s ease-in-out",
        },
        text: {
          color: TakeTurnsColors.darkGray,
          "&:hover": {
            color: TakeTurnsColors.darkBlue,
            backgroundColor: "unset",
          },
        },
        contained: {
          padding: "16px",

          "&:hover": {
            opacity: "90%",
          },
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&:hover": {
            borderRadius: 5,
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: 20,
          padding: 0,
        },
      },
    },
    MuiSnackbar: {
      styleOverrides: {
        root: {
          anchorOriginTopCenter: {
            top: "0px",
          },
        },
        anchorOriginTopCenter: {
          top: "0px",
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: 20,
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          backgroundColor: TakeTurnsColors.background,
          padding: "24px",
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: "24px",
          fontSize: "18px",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        multiline: {
          borderRadius: 10,
          padding: 0,
        },
        root: {
          lineHeight: 1.4,
          fontSize: 14,
          fontWeight: 500,
          borderRadius: 20,
          borderColor: TakeTurnsColors.lightGray,
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: TakeTurnsColors.darkGray,
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: TakeTurnsColors.darkGray,
          },
        },
        input: {
          padding: "8px 10px",
        },
        notchedOutline: {
          borderColor: TakeTurnsColors.lightGray,
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          color: TakeTurnsColors.darkGray,
        },
        label: {
          fontSize: 12,
          fontWeight: 500,
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        icon: <TakeTurnsCheckIcon />,
        checkedIcon: <TakeTurnsCheckedIcon />,
        indeterminateIcon: <TakeTurnsIndeterminatedCheckedIcon />,
        style: { color: TakeTurnsColors.lightGray },
      },
      styleOverrides: {
        root: {
          borderWidth: 1,
          borderColor: TakeTurnsColors.lightGray,
          color: TakeTurnsColors.lightGray,
          borderRadius: 20,
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        disableGutters: {
          padding: "0px 70px",
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        colorPrimary: {
          color: TakeTurnsColors.lightGray,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: 12,
        },
      },
    },
  },
  typography: {
    allVariants: { lineHeight: "1.7rem", fontWeight: 500 },
    fontFamily: "Encode Sans",
    fontWeightMedium: 500,
    fontWeightRegular: 400,
    fontWeightBold: 700,
    fontWeightLight: 300,
    caption: {
      fontSize: 12,
      fontWeight: 500,
      color: TakeTurnsColors.caption,
    },
  },
  spacing: 8,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1440,
    },
  },
});

TakeTurnsTheme.components!.MuiAppBar!.styleOverrides!.root! = {
  height: TakeTurnsAppBarTheme.mobile.height,
  backgroundColor: "transparent",
  color: "black",
  [TakeTurnsTheme.breakpoints.up("md")]: TakeTurnsTheme.components!.MuiAppBar!.styleOverrides!.root,
  justifyContent: "center",
};
