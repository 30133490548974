/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Box, Button, styled } from "@mui/material";
import { useRecapTranslation } from "@taketurns-i18n/collaboration/Recap/useRecapTranslation";
import { useNavigateToRecapRule } from "@taketurns-rules/collaboration/commands/navigation/useNavigateToRecapRule";
import { useGetDisplayedCollaborationIdRule } from "@taketurns-rules/collaboration/queries/collaboration/useGetDisplayedCollaborationIdRule";
import { IconRewind } from "../../commons/icons";

export const CollaborationRecapButton = () => {
  const { t } = useRecapTranslation();
  const collaborationId = useGetDisplayedCollaborationIdRule();

  const navigateToRecap = useNavigateToRecapRule();
  return (
    <CollaborationRecapButton__Container>
      <Button variant={"text"} startIcon={<IconRewind size={24} />} onClick={() => navigateToRecap(collaborationId)}>
        {t("RecapButton.label")}
      </Button>
    </CollaborationRecapButton__Container>
  );
};

const CollaborationRecapButton__Container = styled(Box)({
  display: "flex",
  alignItems: "center",
});
