/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useGetOpenedRequestFulfillmentRequestRule } from "./useGetOpenedRequestFulfillmentRequestRule";

export const useIsRequestFulfillmentOpenRule = () => {
  const openedRequestFulfillmentRequest = useGetOpenedRequestFulfillmentRequestRule();
  return Boolean(openedRequestFulfillmentRequest);
};
