/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { HelpIconWithTooltip } from "@taketurns-components/shared/HelpIconWithTooltip";
import { useWebAppTranslations } from "@taketurns-i18n/webapp/useWebAppTranslations";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { useIsOnMobileRule } from "@taketurns-rules/commons/theme/useIsOnMobileRule";
import { TakeTurnsTextButton } from "../../components/commons/button";
import { useAuthContext } from "../routing/AuthProvider";
import { PUBLIC_ROUTES } from "../routing/routes/publicRoutes.constants";

export const PlansFooter = () => {
  const { t } = useWebAppTranslations("plans");
  const navigate = useNavigate();

  const isOnMobile = useIsOnMobileRule();

  const { isAuthenticated } = useAuthContext();

  if (isAuthenticated && isOnMobile) {
    return null;
  }

  const redirectToSignUpPage = () => {
    navigate(PUBLIC_ROUTES.SIGN_UP);
  };

  const RegisterInvitationText = () => {
    return (
      <Typography display={"flex"} alignItems={"center"} gap={"10px"}>
        {t(isOnMobile ? "footer.registerAsInvitedDescriptionShort" : "footer.registerAsInvitedDescription")}
        <TakeTurnsTextButton style={{ fontSize: "1rem" }} onClick={redirectToSignUpPage}>
          {t("footer.clickHere")}
        </TakeTurnsTextButton>
      </Typography>
    );
  };

  return (
    <Box
      sx={{
        backgroundColor: "white",
        height: "40px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-evenly",
        borderTop: `1px solid ${TakeTurnsColors.lightGray}`,
      }}
    >
      {!isOnMobile && (
        <Typography display={"flex"} alignItems={"center"}>
          {t("footer.freeDescription")} <HelpIconWithTooltip helpText={t("footer.freeTooltip")} />
        </Typography>
      )}
      {!isAuthenticated && <RegisterInvitationText />}
    </Box>
  );
};
