/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Box, styled, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import { SingleColumnLayout } from "@taketurns-app/layouts/body/content/SingleColumnLayout";
import { useAuthContext } from "@taketurns-app/routing/AuthProvider";
import { TakeTurnsDefaultButton } from "@taketurns-components/commons/button";
import { useWebAppTranslations } from "@taketurns-i18n/webapp/useWebAppTranslations";

export const OrganizationInvitationEmailMismatchPage = () => {
  const { t } = useWebAppTranslations("accept-organization-invitation");

  return (
    <SingleColumnLayout
      header={<Typography variant="h6">{t("OrganizationInvitationEmailMismatch.checkEmailAddress")}</Typography>}
      content={<OrganizationInvitationEmailMismatchContent />}
    />
  );
};

const OrganizationInvitationEmailMismatchContent = () => {
  const { t } = useWebAppTranslations("accept-organization-invitation");
  const { logout } = useAuthContext();
  const userEmail = useLocation().state.invitationEmail;
  return (
    <OrganizationInvitationEmailMismatchContent__Container>
      <OrganizationInvitationEmailMismatchContent__CenteredTextContainer>
        <Typography textAlign="center">
          {t("OrganizationInvitationEmailMismatch.checkEmailMessage.textBeforeEmail")} <b>{userEmail}</b>{" "}
          {t("OrganizationInvitationEmailMismatch.checkEmailMessage.textAfterEmail")}
        </Typography>
        <TakeTurnsDefaultButton onClick={() => logout()}>
          {t("OrganizationInvitationEmailMismatch.checkEmailMessage.useAnotherAccountButton")}
        </TakeTurnsDefaultButton>
      </OrganizationInvitationEmailMismatchContent__CenteredTextContainer>
    </OrganizationInvitationEmailMismatchContent__Container>
  );
};

const OrganizationInvitationEmailMismatchContent__Container = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
});

const OrganizationInvitationEmailMismatchContent__CenteredTextContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: "24px",
  height: "100%",
  width: "70%",
  maxWidth: "800px",
});
