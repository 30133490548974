/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useNotifyMaxFileSizeReachedRule } from "./useNotifyMaxFileSizeReachedRule";

export function useFilterFilesTooBigAndNotifyRule(maxFileSizeInMB: number) {
  const notifyMaxFileSizeReached = useNotifyMaxFileSizeReachedRule(maxFileSizeInMB);
  return (files: File[]) => {
    const filesToUpload = [];
    for (const file of files) {
      if (file.size > maxFileSizeInMB * 1000000) {
        notifyMaxFileSizeReached(file.name);
      } else {
        filesToUpload.push(file);
      }
    }
    return filesToUpload;
  };
}
