/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Participant, Party, Role } from "@taketurns/taketurns-graphql-repository";
import { useFetchConnectedUserForCollaborationCreation } from "@taketurns-repositories/collaboration/graphql/queries/context/useFetchConnectedUserForCollaborationCreation";

export const useFetchConnectedUserAsParticipantInCreationRule = () => {
  const {
    user,
    loadingUserForCollaboration: loadingConnectedUserAsParticipant,
    errorOnGettingUserForCollaboration: errorOnGettingConnectedUserAsParticipant,
  } = useFetchConnectedUserForCollaborationCreation();
  const connectedUserAsParticipant: Participant = {
    user,
    userId: user?.id,
    party: Party.Owning,
    role: Role.Owner,
  };
  return {
    connectedUserAsParticipant,
    loadingConnectedUserAsParticipant,
    errorOnGettingConnectedUserAsParticipant,
  };
};
