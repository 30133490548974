/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { Button, Stack, Tooltip, Typography } from "@mui/material";
import { Fragment, useState } from "react";
import { CreateCollaborationInput } from "@taketurns/taketurns-graphql-repository";
import { DefaultActionWithNoteDialog } from "@taketurns-components/collaboration/Shared/dialog/DefaultActionWithNoteDialog";
import { useCollaborationCreationTranslation } from "@taketurns-i18n/collaboration/CollaborationCreation/useCollaborationCreationTranslation";
import { useGetCollaborationForm } from "@taketurns-repositories/collaboration/state/read/collaborationCreation/form/useGetCollaborationForm";
import { useSetCollaborationFormStartNoteRule } from "@taketurns-rules/collaboration/commands/collaborationCreation/form/useSetCollaborationFormStartNoteRule";
import { useValidateCollaborationFormAndGetIsValidFormRule } from "@taketurns-rules/collaboration/commands/collaborationCreation/form/useValidateCollaborationFormAndGetIsValidFormRule";
import { useCreateCollaborationRule } from "@taketurns-rules/collaboration/commands/collaborationCreation/useCreateCollaborationRule";
import { useNavigateToCollaborationListRule } from "@taketurns-rules/collaboration/commands/navigation/useNavigateToCollaborationListRule";
import { useGetCollaborationFormInvitedPartyNameRule } from "@taketurns-rules/collaboration/queries/collaborationCreation/form/useGetCollaborationFormInvitedPartyNameRule";
import { useGetCollaborationFormStartNoteRule } from "@taketurns-rules/collaboration/queries/collaborationCreation/form/useGetCollaborationFormStartNoteRule";
import { useIsCreationButtonDisabledRule } from "@taketurns-rules/collaboration/queries/collaborationCreation/useIsCreationButtonDisabledRule";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { TakeTurnsDefaultButton } from "../../../commons/button";
import { PartyNameTip } from "./PartyNameTip";

interface HeaderButtonProps {
  action?: () => void;
}

const CancelButton = () => {
  const { t } = useCollaborationCreationTranslation();
  const redirectToCollaborationList = useNavigateToCollaborationListRule();
  return (
    <Button
      onClick={redirectToCollaborationList}
      variant="text"
      sx={{
        color: TakeTurnsColors.darkGray,
      }}
    >
      {t("cancel")}
    </Button>
  );
};

const CreateAndTurnInButton = (props: HeaderButtonProps) => {
  const { t } = useCollaborationCreationTranslation();
  const isCreationButtonDisabled = useIsCreationButtonDisabledRule();
  return (
    <Tooltip
      title={t("cannotCreateCollaborationWhileUploadingFile")}
      placement="bottom-end"
      disableFocusListener={!isCreationButtonDisabled}
      disableHoverListener={!isCreationButtonDisabled}
      disableTouchListener={!isCreationButtonDisabled}
    >
      <span>
        <TakeTurnsDefaultButton onClick={props.action} disabled={isCreationButtonDisabled}>
          {t("createAndTurnIn")}
        </TakeTurnsDefaultButton>
      </span>
    </Tooltip>
  );
};

export const CollaborationCreationHeader = () => {
  const { t } = useCollaborationCreationTranslation();

  const [isCreationNoteDialogOpen, setCreationNoteDialogOpen] = useState(false);

  const openCommentDialog = useOpenCommentDialog(setCreationNoteDialogOpen);

  const { addNoteMessageAndCreateCollaboration, createCollaborationResult } = useAddNoteMessageAndCreateCollaboration();

  const invitedPartyName = useGetCollaborationFormInvitedPartyNameRule();

  const startNote = useGetCollaborationFormStartNoteRule();
  const setStartNote = useSetCollaborationFormStartNoteRule();

  const closeDialogAndSaveTempStartNote = (tempStartNote: string) => {
    setCreationNoteDialogOpen(false);
    setStartNote(tempStartNote);
  };

  return (
    <Fragment>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" spacing={2.5}>
          <Typography variant="h6">{`${t("createNewCollaboration")}`}</Typography>
          <PartyNameTip />
        </Stack>

        <Stack direction="row" spacing={2.5}>
          <CancelButton />
          <CreateAndTurnInButton action={openCommentDialog} />
        </Stack>
      </Stack>
      <DefaultActionWithNoteDialog
        title={t("createCollaboration")}
        otherPartyName={invitedPartyName}
        actionLoading={createCollaborationResult.loading}
        onClose={closeDialogAndSaveTempStartNote}
        openDialog={isCreationNoteDialogOpen}
        executeAction={addNoteMessageAndCreateCollaboration}
        placeholder={t("placeholderStartNote")}
        actionButtonLabel={t("startDialogButtonLabel")}
        defaultNoteValue={startNote}
      />
    </Fragment>
  );
};

function useOpenCommentDialog(setDialogOpen: (value: ((prevState: boolean) => boolean) | boolean) => void) {
  const validateCollaborationFormAndGetValidationStatus = useValidateCollaborationFormAndGetIsValidFormRule();

  return () => {
    if (validateCollaborationFormAndGetValidationStatus().isValid) {
      setDialogOpen(true);
    }
  };
}

function useAddNoteMessageAndCreateCollaboration() {
  const { createCollaborationCommand, createCollaborationResult } = useCreateCollaborationRule();
  const createCollaborationInput = useGetCollaborationForm();

  return {
    addNoteMessageAndCreateCollaboration: (message: string) => {
      createCollaborationCommand({ ...createCollaborationInput, note: message } as CreateCollaborationInput);
    },
    createCollaborationResult,
  };
}
