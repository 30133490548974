/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql } from "@apollo/client";
import {
  MoveOutput,
  SubscriptionOnMoveRequestIntoDestinationFolderArgs,
} from "@taketurns/taketurns-graphql-repository";
import { useRefreshableSubscription } from "@taketurns-components/webapp/QueriesAndSubscriptionsRefreshTrigger";
import { moveRequestMutationFragment } from "@taketurns-repositories/collaboration/graphql/mutations/content/requests/useMoveRequestIntoFolder";

const SUBSCRIBE_TO_MOVE_REQUEST_INTO_DESTINATION_FOLDER = gql`
  subscription onMoveRequestIntoDestinationFolder($collaborationId: ID!, $partyId: ID!, $destinationFolderId: ID!) {
    onMoveRequestIntoDestinationFolder(
      collaborationId: $collaborationId
      partyId: $partyId
      destinationFolderId: $destinationFolderId
    ) {
      ...MoveRequestMutationFragment
    }
  }
  ${moveRequestMutationFragment}
`;

export const useSubscribeToMoveRequestIntoDestinationFolder = (
  args: SubscriptionOnMoveRequestIntoDestinationFolderArgs,
) => {
  return useRefreshableSubscription<
    { onMoveRequestIntoDestinationFolder: MoveOutput },
    SubscriptionOnMoveRequestIntoDestinationFolderArgs
  >(SUBSCRIBE_TO_MOVE_REQUEST_INTO_DESTINATION_FOLDER, { variables: args });
};
