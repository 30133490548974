/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { CircularProgress, MenuItem, Select, SxProps, Theme } from "@mui/material";
import { OrganizationUserRole } from "@taketurns/taketurns-graphql-repository";
import { useOrganizationTranslation } from "@taketurns-i18n/user/Organization/useOrganizationTranslation";
import { IconChevronDown } from "../../../commons/icons";

interface OrganizationUserRoleSelectorProps {
  onRoleSelected: (role: OrganizationUserRole) => void;
  loading?: boolean;
  role?: OrganizationUserRole;
  sx?: SxProps<Theme>;
}

export const OrganizationUserRoleSelector = (props: OrganizationUserRoleSelectorProps) => {
  const { onRoleSelected, role, sx, loading } = props;

  const ChevronIconOrLoadingIndicator = (iconProps: object) => {
    if (loading) {
      return <CircularProgress size={14} {...iconProps} />;
    }
    return <IconChevronDown size={14} {...iconProps} />;
  };

  const { t } = useOrganizationTranslation();

  const organizationRoleList = Object.values(OrganizationUserRole);

  const defaultSx = { lineHeight: "1.4375em", fontSize: "0.75rem" };
  const selectSx = sx instanceof Array ? [...sx, defaultSx] : [sx, defaultSx];

  return (
    <Select
      autoWidth
      sx={selectSx}
      value={role ?? OrganizationUserRole.User}
      disabled={loading}
      onChange={(event) => onRoleSelected(event.target.value as OrganizationUserRole)}
      IconComponent={ChevronIconOrLoadingIndicator}
    >
      {organizationRoleList.map((role) => (
        <MenuItem key={role} value={role}>
          {t(`OrganizationUserRole.${role}`)}
        </MenuItem>
      ))}
    </Select>
  );
};
