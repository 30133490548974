/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useSnackbar } from "notistack";
import { useUserSharedTranslation } from "@taketurns-i18n/user/useUserSharedTranslation";
import { useActivateDisplayedTips } from "@taketurns-repositories/user/graphql/mutations/useActivateDisplayedTips";

export const useResetHiddenTipsRule = () => {
  const [activateDisplayedTips] = useActivateDisplayedTips();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useUserSharedTranslation();

  const resetHiddenTipsRule = () => {
    activateDisplayedTips({
      onCompleted: () => {
        enqueueSnackbar(t("UserPreferences.hiddenTipsResetSuccess"), {
          variant: "info",
        });
      },
    });
  };
  return { resetHiddenTipsRule };
};
