/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useFetchUserHasOrganizationAdminFeature } from "@taketurns-repositories/user/graphql/queries/useFetchUserHasOrganizationAdminFeature";

export const useFetchUserIsAllowedToSelectPlanRule = () => {
  const { userHasOrganizationAdminFeature, loadingHasOrganizationAdminFeature, errorOnHasOrganizationAdminFeature } =
    useFetchUserHasOrganizationAdminFeature();
  return {
    isAllowedToSelectPlan: userHasOrganizationAdminFeature,
    loadingIsAllowedToSelectPlan: loadingHasOrganizationAdminFeature,
    errorOnAllowedToSelectPlan: errorOnHasOrganizationAdminFeature,
  };
};
