/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Dialog, DialogContent, DialogTitle, Divider, styled } from "@mui/material";
import { Fragment } from "react";
import { DocumentRevisionPreview } from "@taketurns-components/collaboration/CollaborationView/AttachmentDetails/components/DocumentRevisionPreview";
import { AttachmentToPreview } from "@taketurns-components/collaboration/Recap/Recap/EventContent";
import { DownloadRevisionButton } from "@taketurns-components/collaboration/Shared/content/DownloadRevisionButton";
import { TakeTurnsDialogFooter } from "@taketurns-components/collaboration/Shared/content/TakeTurnsDialogFooter";
import { DocumentDetails } from "../../document/DocumentView/DocumentDetails";

interface AttachmentPreviewDialogProps {
  open: boolean;
  close: () => void;
  attachment?: AttachmentToPreview;
}

export const AttachmentPreviewDialog = ({ open, close, attachment }: AttachmentPreviewDialogProps) => {
  return (
    <Dialog fullWidth open={open} onClose={close} maxWidth={false} PaperProps={{ sx: { height: "100%" } }}>
      {attachment && (
        <Fragment>
          <AttachmentPreviewDialogTitle>
            <DocumentDetails documentId={attachment.documentId} collaborationId={attachment.collaborationId} />
            <DownloadRevisionButton
              collaborationId={attachment.collaborationId}
              documentId={attachment.documentId}
              revisionId={attachment.revisionId}
            />
          </AttachmentPreviewDialogTitle>
          <Divider />
          <DialogContent>
            <DocumentRevisionPreview
              collaborationId={attachment.collaborationId}
              documentId={attachment.documentId}
              revisionId={attachment.revisionId}
              canEditRevision={false}
            />
          </DialogContent>
        </Fragment>
      )}
      <TakeTurnsDialogFooter closeDialog={close} />
    </Dialog>
  );
};

const AttachmentPreviewDialogTitle = styled(DialogTitle)({
  display: "flex",
  alignItems: "center",
});
