/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { ApolloError } from "@apollo/client";
import { useSnackbar } from "notistack";
import { SwitchPlanInput } from "@taketurns/taketurns-graphql-repository";
import { useUserPlanTranslation } from "@taketurns-i18n/user/UserPlans/useUserPlanTranslation";
import { useSwitchPlan } from "@taketurns-repositories/user/graphql/mutations/useSwitchPlan";

export const useSwitchPlanRule = () => {
  const { switchPlan: switchPlanCommand, loadingSwitchPlan } = useSwitchPlan();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useUserPlanTranslation();

  const displayErrorMessage = (error: ApolloError) => {
    console.error(error);
    enqueueSnackbar(t("SwitchPlan.error"), { variant: "error" });
  };

  const displaySuccessMessage = () => {
    enqueueSnackbar(t("SwitchPlan.success"), { variant: "info" });
  };

  const switchPlan = (switchPlanInput: SwitchPlanInput) => {
    return switchPlanCommand(switchPlanInput)
      .then(() => displaySuccessMessage())
      .catch((error) => {
        displayErrorMessage(error);
        throw error;
      });
  };
  return { switchPlan, loadingSwitchPlan };
};
