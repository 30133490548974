/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useMutation } from "@apollo/client";

export const useChangePreferredLanguage = () => {
  const mutation = gql`
    mutation changePreferredLanguage($language: String!) {
      changePreferredLanguage(language: $language) {
        id
        language
      }
    }
  `;
  const [changePreferredLanguageMutation, changePreferredLanguageMutationResult] = useMutation(mutation);
  const changePreferredLanguage = (language: string) => {
    changePreferredLanguageMutation({ variables: { language } });
  };
  return { changePreferredLanguage, errorOnChangePreferredLanguage: changePreferredLanguageMutationResult.error };
};
