/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { MarketingDataInput } from "@taketurns/taketurns-graphql-repository";
import { useSaveMarketingData } from "@taketurns-repositories/user/graphql/mutations/useSaveMarketingData";

export const useSaveMarketingDataRule = (marketingDataInput: MarketingDataInput | null) => {
  const { saveMarketingData } = useSaveMarketingData();

  if (marketingDataInput) {
    saveMarketingData(marketingDataInput);
  }
};
