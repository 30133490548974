/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Box, styled } from "@mui/material";
import { PropsWithChildren } from "react";
import { EventOnCollaboration, Party } from "@taketurns/taketurns-graphql-repository";
import { EventContent } from "@taketurns-components/collaboration/Recap/Recap/EventContent";
import { EventNote } from "@taketurns-components/collaboration/Recap/Recap/EventNote";

interface EventBodyProps {
  event: EventOnCollaboration;
  connectedUserParty: Party;
  collaborationId: string;
}

export const EventBody = (props: EventBodyProps) => {
  return (
    <EventContent__Container>
      <PartyEventContentContainer party={props.event.party}>
        {props.event.note && (
          <EventNote
            event={props.event}
            connectedUserParty={props.connectedUserParty}
            collaborationId={props.collaborationId}
          />
        )}
        {props.event.updatedContent && (
          <EventContent collaborationId={props.collaborationId} updatedContent={props.event.updatedContent} />
        )}
      </PartyEventContentContainer>
    </EventContent__Container>
  );
};

const PartyEventContentContainer = (props: PropsWithChildren<{ party: Party }>) => {
  if (props.party === Party.Owning) {
    return <OwningPartyEventContent__Container>{props.children}</OwningPartyEventContent__Container>;
  } else {
    return <InvitedPartyEventContent__Container>{props.children}</InvitedPartyEventContent__Container>;
  }
};

const PartyEventContent__Container = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: 20,
  padding: 24,
  width: "100%",
});

const OwningPartyEventContent__Container = styled(PartyEventContent__Container)({
  gridArea: "leftColumn",
  alignItems: "flex-end",
});
const InvitedPartyEventContent__Container = styled(PartyEventContent__Container)({
  gridArea: "rightColumn",
  // TODO FJS :  is this really necessary?
  // justifySelf: "start",
  alignItems: "flex-start",
});

const EventContent__Container = styled(Box)`
  display: inline-grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas: "leftColumn rightColumn";
  width: 100%;
`;
