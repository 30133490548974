/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuItemProps,
  styled,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { Fragment, PropsWithChildren, ReactNode, useRef, useState } from "react";
import { CollaborationStatus } from "@taketurns/taketurns-graphql-repository";
import { EditCollaborationDialog } from "@taketurns-components/collaboration/CollaborationMenu/components/Edit/EditCollaborationDialog";
import { LeaveCollaborationDialog } from "@taketurns-components/collaboration/CollaborationMenu/components/Leave/LeaveCollaborationDialog";
import { ReopenCollaborationDialog } from "@taketurns-components/collaboration/CollaborationMenu/components/Reopen/ReopenCollaborationDialog";
import { WrapUpCollaborationDialog } from "@taketurns-components/collaboration/CollaborationMenu/components/WrapUp/WrapUpCollaborationDialog";
import { useSharedCollaborationTranslation } from "@taketurns-i18n/collaboration/shared/useSharedCollaborationTranslation";
import { useGetDisplayedCollaborationId } from "@taketurns-repositories/collaboration/context/useGetDisplayedCollaborationId";
import { useNavigateToRecapRule } from "@taketurns-rules/collaboration/commands/navigation/useNavigateToRecapRule";
import { useHideCollaborationRule } from "@taketurns-rules/collaboration/commands/useHideCollaborationRule";
import { useUnhideCollaborationRule } from "@taketurns-rules/collaboration/commands/useUnhideCollaborationRule";
import { useFetchIsCollaborationHiddenRule } from "@taketurns-rules/collaboration/queries/context/useFetchIsCollaborationHiddenRule";
import { useFetchCollaborationStatusRule } from "@taketurns-rules/collaboration/queries/fields/useFetchCollaborationStatusRule";
import { useCanCloseCollaborationRule } from "@taketurns-rules/collaboration/queries/permissions/useCanCloseCollaborationRule";
import { useCanEditCollaborationRule } from "@taketurns-rules/collaboration/queries/permissions/useCanEditCollaborationRule";
import { useCanReopenCollaborationRule } from "@taketurns-rules/collaboration/queries/permissions/useCanReopenCollaborationRule";
import {
  IconClipboard,
  IconCopy,
  IconEdit2,
  IconHide,
  IconLogOut,
  IconMoreVertical,
  IconPlay,
  IconRewind,
  IconUnhide,
} from "../../commons/icons";

interface CollaborationMenuProps {
  collaborationId?: string;
  excludeRecap?: boolean;
}

export const CollaborationMenu = ({ collaborationId, excludeRecap }: CollaborationMenuProps) => {
  const displayedCollaborationId = useGetDisplayedCollaborationId();
  const actualCollaborationId = collaborationId ?? displayedCollaborationId;
  const { status, errorOnFetchingCollaborationStatus } = useFetchCollaborationStatusRule(collaborationId);

  if (status === CollaborationStatus.Open) {
    return (
      <BurgerMenu collaborationId={actualCollaborationId}>
        <OpenCollaborationActionMenuItems collaborationId={actualCollaborationId} excludeRecap={excludeRecap} />
      </BurgerMenu>
    );
  }
  if (status === CollaborationStatus.Closed) {
    return (
      <BurgerMenu collaborationId={actualCollaborationId}>
        <ClosedCollaborationActionMenuItems collaborationId={actualCollaborationId} excludeRecap={excludeRecap} />
      </BurgerMenu>
    );
  }
  if (status) {
    console.error(`CollaborationMenu cannot be displayed as Collaboration status ${status} is not supported`);
    return;
  }
  if (errorOnFetchingCollaborationStatus) {
    console.error(
      `CollaborationMenu cannot be displayed as it failed to fectch collaboration status: ${errorOnFetchingCollaborationStatus.message}`,
    );
    return;
  }
};

interface BurgerMenuProps {
  collaborationId?: string;
}

const BurgerMenu = (props: PropsWithChildren<BurgerMenuProps & object>) => {
  const anchorRef = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const openMenu = () => {
    setIsMenuOpen(true);
  };
  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <Fragment>
      <IconButton
        size="small"
        sx={{ p: 0, width: 24, height: 24, fontSize: "24px", mr: { xs: "-10px", md: 0 } }}
        ref={anchorRef}
        data-cy="CollaborationMenu.button"
        onClick={(event) => {
          event.stopPropagation();
          openMenu();
        }}
      >
        <IconMoreVertical width={24} height={24} />
      </IconButton>
      <ActionMenuList
        data-cy={`CollaborationMenu.${props.collaborationId}.popover`}
        open={isMenuOpen}
        anchorEl={anchorRef.current}
        elevation={2}
        onClose={closeMenu}
      >
        {props.children}
      </ActionMenuList>
    </Fragment>
  );
};

const OpenCollaborationActionMenuItems = ({
  collaborationId,
  excludeRecap,
}: {
  collaborationId: string;
  excludeRecap: boolean;
}) => {
  const canClose = useCanCloseCollaborationRule(collaborationId);
  const canEdit = useCanEditCollaborationRule(collaborationId);
  const { isCollaborationHidden, loadingIsCollaborationHidden, errorOnFetchingIsCollaborationHidden } =
    useFetchIsCollaborationHiddenRule(collaborationId);
  const canHide = !loadingIsCollaborationHidden && !errorOnFetchingIsCollaborationHidden && !isCollaborationHidden;
  const canUnhide = !loadingIsCollaborationHidden && !errorOnFetchingIsCollaborationHidden && isCollaborationHidden;

  return (
    <Fragment>
      {!excludeRecap && <RecapMenuItem collaborationId={collaborationId} />}
      {canEdit && <EditMenuItem collaborationId={collaborationId} />}
      {canClose && <WrapUpMenuItem collaborationId={collaborationId} />}
      {canHide && <HideMenuItem collaborationId={collaborationId} />}
      {canUnhide && <UnhideMenuItem collaborationId={collaborationId} />}
      <LeaveMenuItem collaborationId={collaborationId} />
      <CopyLinkMenuItem collaborationId={collaborationId} />
    </Fragment>
  );
};
const ClosedCollaborationActionMenuItems = ({
  collaborationId,
  excludeRecap,
}: {
  collaborationId: string;
  excludeRecap: boolean;
}) => {
  const canReopen = useCanReopenCollaborationRule(collaborationId);

  return (
    <Fragment>
      {!excludeRecap && <RecapMenuItem collaborationId={collaborationId} />}
      {canReopen && <ReopenMenuItem collaborationId={collaborationId} />}
      <CopyLinkMenuItem collaborationId={collaborationId} />
    </Fragment>
  );
};

const ActionMenuList = styled(Menu)({
  "& .MuiMenu-list": {
    padding: 0,
  },
});

const ActionMenuItem = ({
  onClick,
  icon,
  label,
  dataCy,
}: ActionMenuItemProps & MenuItemProps & { dataCy?: string }) => {
  return (
    <MenuItem onClick={onClick} data-cy={dataCy}>
      <MenuIcon>{icon}</MenuIcon>
      <ListItemText primary={<Typography variant="body2">{label}</Typography>} />
    </MenuItem>
  );
};

const RecapMenuItem = ({ collaborationId }: { collaborationId: string }) => {
  const { t } = useSharedCollaborationTranslation();
  const navigateToRecap = useNavigateToRecapRule();
  return (
    <ActionMenuItem
      onClick={() => navigateToRecap(collaborationId)}
      icon={<IconRewind size={14} />}
      label={t("CollaborationMenu.recap.action")}
    />
  );
};
const EditMenuItem = ({ collaborationId }: { collaborationId: string }) => {
  const { t } = useSharedCollaborationTranslation();
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const openEditDialog = () => {
    setIsEditDialogOpen(true);
  };
  const closeEditDialog = () => {
    setIsEditDialogOpen(false);
  };
  return (
    <Fragment>
      <ActionMenuItem
        onClick={openEditDialog}
        icon={<IconEdit2 size={14} />}
        label={t("CollaborationMenu.edit.action")}
      />
      <EditCollaborationDialog collaborationId={collaborationId} open={isEditDialogOpen} onClose={closeEditDialog} />
    </Fragment>
  );
};

const WrapUpMenuItem = ({ collaborationId }: { collaborationId: string }) => {
  const { t } = useSharedCollaborationTranslation();
  const [isCloseDialogOpen, setIsCloseDialogOpen] = useState(false);
  const openCloseDialog = () => {
    setIsCloseDialogOpen(true);
  };
  const closeCloseDialog = () => {
    setIsCloseDialogOpen(false);
  };
  return (
    <Fragment>
      <ActionMenuItem
        onClick={openCloseDialog}
        icon={<IconClipboard size={14} />}
        label={t("CollaborationMenu.close.action")}
      />
      <WrapUpCollaborationDialog
        collaborationId={collaborationId}
        open={isCloseDialogOpen}
        onClose={closeCloseDialog}
      />
    </Fragment>
  );
};

const ReopenMenuItem = ({ collaborationId }: { collaborationId: string }) => {
  const { t } = useSharedCollaborationTranslation();
  const [openReopenDialog, setOpenReopenDialog] = useState(false);

  return (
    <Fragment>
      <ActionMenuItem
        onClick={() => {
          setOpenReopenDialog(true);
        }}
        icon={<IconPlay size={14} />}
        label={t("CollaborationMenu.reopen.action")}
      />
      <ReopenCollaborationDialog
        collaborationId={collaborationId}
        open={openReopenDialog}
        onClose={() => setOpenReopenDialog(false)}
      />
    </Fragment>
  );
};

const LeaveMenuItem = ({ collaborationId }: { collaborationId: string }) => {
  const { t } = useSharedCollaborationTranslation();
  const [isLeaveDialogOpen, setIsLeaveDialogOpen] = useState(false);
  const openLeaveDialog = () => {
    setIsLeaveDialogOpen(true);
  };
  const closeLeaveDialog = () => {
    setIsLeaveDialogOpen(false);
  };
  return (
    <Fragment>
      <ActionMenuItem
        onClick={openLeaveDialog}
        icon={<IconLogOut size={14} />}
        label={t("CollaborationMenu.leave.action")}
      />
      <LeaveCollaborationDialog collaborationId={collaborationId} open={isLeaveDialogOpen} onClose={closeLeaveDialog} />
    </Fragment>
  );
};

const HideMenuItem = ({ collaborationId }: { collaborationId: string }) => {
  const { hideCollaborationCommand } = useHideCollaborationRule(collaborationId);
  const { t } = useSharedCollaborationTranslation();
  const hideCollaboration = () => {
    hideCollaborationCommand();
  };
  return (
    <ActionMenuItem
      onClick={hideCollaboration}
      icon={<IconUnhide size={14} />}
      label={t("CollaborationMenu.hide.action")}
      dataCy={"HideMenuItem"}
    />
  );
};

const UnhideMenuItem = ({ collaborationId }: { collaborationId: string }) => {
  const { unhideCollaborationCommand } = useUnhideCollaborationRule(collaborationId);
  const { t } = useSharedCollaborationTranslation();
  const unhideCollaboration = () => {
    unhideCollaborationCommand();
  };
  return (
    <ActionMenuItem
      onClick={unhideCollaboration}
      icon={<IconHide size={14} />}
      label={t("CollaborationMenu.unhide.action")}
      dataCy={"UnhideMenuItem"}
    />
  );
};

const CopyLinkMenuItem = ({ collaborationId }: { collaborationId: string }) => {
  const { t } = useSharedCollaborationTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const copyCurrentUrlToClipboard = () => {
    const url = `${window.location.protocol}//${window.location.host}/collaboration/${collaborationId}`;
    navigator.clipboard.writeText(url);
    enqueueSnackbar(t("CollaborationMenu.copyLink.successMessage"), { variant: "info" });
  };
  return (
    <ActionMenuItem
      onClick={copyCurrentUrlToClipboard}
      icon={<IconCopy size={14} />}
      label={t("CollaborationMenu.copyLink.action")}
    />
  );
};

interface ActionMenuItemProps {
  onClick: () => void;
  icon: ReactNode;
  label: string;
  name?: string;
}

const MenuIcon = styled(ListItemIcon)({
  marginRight: 10,
  minWidth: "0 !important",
});
