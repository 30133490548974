/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useLeaveCollaboration } from "@taketurns-repositories/collaboration/graphql/mutations/participants/useLeaveCollaboration";
import { useNavigateToCollaborationListRule } from "@taketurns-rules/collaboration/commands/navigation/useNavigateToCollaborationListRule";

export const useLeaveCollaborationRule = (collaborationId: string) => {
  const [leaveCollaborationMutation, leaveCollaborationMutationResult] = useLeaveCollaboration();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (leaveCollaborationMutationResult.error) {
      enqueueSnackbar(leaveCollaborationMutationResult.error.message, {
        variant: "error",
      });
      console.error(leaveCollaborationMutationResult.error);
      leaveCollaborationMutationResult.reset();
    }
  }, [leaveCollaborationMutationResult.error]);

  const navigateToCollaborationList = useNavigateToCollaborationListRule();
  const leaveCollaborationCommand = () => {
    leaveCollaborationMutation({
      variables: { collaborationId: collaborationId },
      onCompleted: () => {
        navigateToCollaborationList();
      },
    });
  };

  return {
    leaveCollaborationCommand,
    result: leaveCollaborationMutationResult,
  };
};
