/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useGetConnectedUserPartyId } from "@taketurns-repositories/collaboration/context/useGetConnectedUserPartyId";
import { useSubscribeToEditRequest } from "@taketurns-repositories/collaboration/graphql/subscriptions/content/useSubscribeToEditRequest";
import { useGetDisplayedCollaborationIdRule } from "@taketurns-rules/collaboration/queries/collaboration/useGetDisplayedCollaborationIdRule";

export const useSubscribeToEditRequestRule = (requestId) => {
  const displayedCollaborationId = useGetDisplayedCollaborationIdRule();
  const connectedUserPartyId = useGetConnectedUserPartyId();
  return useSubscribeToEditRequest({
    collaborationId: displayedCollaborationId,
    partyId: connectedUserPartyId,
    requestId,
  });
};
