/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { CircularProgress, Stack, styled, TextField, Typography } from "@mui/material";
import { Auth } from "aws-amplify";
import { PropsWithChildren, useState } from "react";
import { Controller } from "react-hook-form";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";
import { ConnectedUser } from "@taketurns/taketurns-graphql-repository";
import { PUBLIC_ROUTES } from "@taketurns-app/routing/routes/publicRoutes.constants";
import { TakeTurnsDefaultButton } from "@taketurns-components/commons/button";
import { useUserSharedTranslation } from "@taketurns-i18n/user/useUserSharedTranslation";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";
import { useChangePasswordRule } from "@taketurns-rules/user/commands/useChangePasswordRule";
import { useFetchConnectedUserIdAndEmailRule } from "@taketurns-rules/user/queries/useFetchConnectedUserIdAndEmailRule";
import { useChangePasswordFormValidationRule } from "@taketurns-rules/user/validation/userChangePasswordValidationRule";

interface UserPasswordFormProps {
  isConnectedUserPasswordAlreadySet: boolean;
  connectedUser: ConnectedUser;
}

export const UserPasswordForm = (props: UserPasswordFormProps) => {
  if (props.isConnectedUserPasswordAlreadySet) {
    return <ChangePasswordForm connectedUser={props.connectedUser} />;
  }
  return <NoPasswordMessage />;
};

interface ChangePasswordFormProps {
  connectedUser: ConnectedUser;
}

const ChangePasswordForm = (props: ChangePasswordFormProps) => {
  const { t } = useUserSharedTranslation();
  const { connectedUserEmail } = useFetchConnectedUserIdAndEmailRule();
  const { handleSubmit, control, reset, trigger, setError } = useChangePasswordFormValidationRule(props.connectedUser);
  const [loadingAuth, setLoadingAuth] = useState(false);
  const { changePassword, loadingChangeUserPassword } = useChangePasswordRule();

  const changePasswordAndResetForm = async (currentPassword: string, newPassword: string) => {
    setLoadingAuth(true);
    try {
      await Auth.signIn(connectedUserEmail, currentPassword);
    } catch (error) {
      console.error(error);
      reset({ currentPassword: "" });
      setError("currentPassword", { type: "custom", message: "password.invalidCurrentPassword" });
      setLoadingAuth(false);
      return;
    }
    setLoadingAuth(false);
    await changePassword(currentPassword, newPassword);
    reset({ currentPassword: "", password: "", passwordConfirmation: "" });
  };

  return (
    <UserPassword__Form
      onSubmit={handleSubmit((data) => changePasswordAndResetForm(data.currentPassword, data.password))}
    >
      <HiddenEmailInput email={props.connectedUser.email} />
      <UserPassword__CurrentAndNewPasswordsContainer>
        <Controller
          control={control}
          render={({ field, fieldState: { error: currentPasswordFieldError } }) => (
            <UserPassword__Field
              {...field}
              data-cy="UserPasswordForm.currentPassword"
              error={!!currentPasswordFieldError}
              helperText={!!currentPasswordFieldError && t(currentPasswordFieldError.message)}
              autoComplete={"current-password"}
              type={"password"}
              placeholder={t("UserSettings.currentPassword")}
              onBlur={() => trigger("currentPassword")}
            />
          )}
          name={"currentPassword"}
        />
        <UserPassword__NewPasswordAndButtonContainer>
          <NewPasswordInput control={control} trigger={trigger} />
          <ConfirmPasswordInput control={control} trigger={trigger} />
          <SubmitButton loading={loadingChangeUserPassword || loadingAuth}>
            {t("UserSettings.changePassword.action")}
          </SubmitButton>
        </UserPassword__NewPasswordAndButtonContainer>
        <Typography variant="caption" color="secondary">
          <Link to={PUBLIC_ROUTES.RESET_PASSWORD}>{t("UserSettings.forgotPassword")}</Link>
        </Typography>
      </UserPassword__CurrentAndNewPasswordsContainer>
    </UserPassword__Form>
  );
};

const NoPasswordMessage = () => {
  const { t } = useUserSharedTranslation();

  return (
    <Typography fontSize={14}>
      <Trans t={t} i18nKey={"UserSettings.setPassword.action"}>
        This account has no password set. Use the{" "}
        <Link
          to={PUBLIC_ROUTES.RESET_PASSWORD}
          style={{ color: TakeTurnsColors.lightBlue, textDecoration: "underline" }}
          data-cy="UserPasswordForm.resetPasswordLink"
        >
          Reset password
        </Link>{" "}
        page to set one.
      </Trans>
    </Typography>
  );
};

type ControlType = ReturnType<typeof useChangePasswordFormValidationRule>["control"];
type TriggerType = ReturnType<typeof useChangePasswordFormValidationRule>["trigger"];

interface InputProps {
  control: ControlType;
  trigger: TriggerType;
}

const NewPasswordInput = (props: InputProps) => {
  const { t } = useUserSharedTranslation();
  return (
    <Controller
      control={props.control}
      render={({ field, fieldState: { error: passwordFieldError } }) => (
        <UserPassword__Field
          {...field}
          data-cy="UserPasswordForm.newPassword"
          error={!!passwordFieldError}
          helperText={!!passwordFieldError && t(passwordFieldError.message)}
          autoComplete={"new-password"}
          type={"password"}
          placeholder={t("UserSettings.newPassword")}
          onBlur={() => props.trigger("password")}
        />
      )}
      name={"password"}
    />
  );
};

const ConfirmPasswordInput = (props: InputProps) => {
  const { t } = useUserSharedTranslation();
  return (
    <Controller
      control={props.control}
      render={({ field, fieldState: { error: passwordConfirmationFieldError } }) => (
        <UserPassword__Field
          {...field}
          data-cy="UserPasswordForm.confirmPassword"
          error={!!passwordConfirmationFieldError}
          helperText={!!passwordConfirmationFieldError && t(passwordConfirmationFieldError.message)}
          autoComplete={"new-password"}
          type={"password"}
          placeholder={t("UserSettings.confirmPassword")}
          onBlur={() => props.trigger("passwordConfirmation")}
        />
      )}
      name={"passwordConfirmation"}
    />
  );
};

const HiddenEmailInput = (props: { email: string }) => (
  <input hidden readOnly autoComplete="email" value={props.email} />
);

const SubmitButton = (props: PropsWithChildren<{ loading: boolean }>) => {
  return (
    <TakeTurnsDefaultButton
      data-cy="UserPasswordForm.submit"
      endIcon={props.loading && <CircularProgress size={14} color={"secondary"} />}
      disabled={props.loading}
      type="submit"
    >
      {props.children}
    </TakeTurnsDefaultButton>
  );
};

const UserPassword__Form = styled("form")({
  flex: 1,
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  gap: 16,
  padding: "8px 0",
});

const UserPassword__CurrentAndNewPasswordsContainer = styled(Stack)(({ theme }) => ({
  flex: 1,
  flexDirection: "column",
  gap: theme.spacing(2),
}));

const UserPassword__NewPasswordAndButtonContainer = styled(Stack)(({ theme }) => ({
  flex: 1,
  flexDirection: "row",
  alignItems: "baseline",
  flexWrap: "wrap",
  gap: theme.spacing(2.5),
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));

const UserPassword__Field = styled(TextField)(({ theme }) => ({
  flex: 1,
  minWidth: "150px",
  maxWidth: "200px",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    maxWidth: "100%",
  },
}));
