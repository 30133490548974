/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useGetDisplayedCollaborationId } from "@taketurns-repositories/collaboration/context/useGetDisplayedCollaborationId";
import { useMoveAttachmentIntoFolderMutation } from "@taketurns-repositories/collaboration/graphql/mutations/content/attachments/useMoveAttachmentIntoFolderMutation";

export function useMoveAttachmentIntoFolderRule(attachmentId: string) {
  const collaborationId = useGetDisplayedCollaborationId();
  const moveAttachmentIntoFolder = useMoveAttachmentIntoFolderMutation({ collaborationId, attachmentId });
  return async (destinationFolderId: string) => {
    await moveAttachmentIntoFolder(destinationFolderId);
  };
}
