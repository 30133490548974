/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { ApolloCache } from "@apollo/client";
import {
  AddAttachmentOutput,
  Attachment,
  Collaboration,
  Folder,
  Request,
} from "@taketurns/taketurns-graphql-repository";
import { COLLABORATION_CONTENT_METADATA_QUERY } from "@taketurns-repositories/collaboration/graphql/queries/content/useFetchCollaborationContentMetadata";
import { GET_FOLDER } from "@taketurns-repositories/collaboration/graphql/queries/folder/useFetchFolder";
import { DOCUMENT_REVISIONS } from "@taketurns-repositories/document/graphql/queries/useFetchDocumentRevisions";

export function updateCacheOnAttachmentAdded(cache: ApolloCache<unknown>, addAttachmentOutput: AddAttachmentOutput) {
  const documentQuery = cache.readQuery<{ document: Document }>({
    query: DOCUMENT_REVISIONS,
    variables: { id: addAttachmentOutput.document.id },
  });
  if (!documentQuery?.document) {
    console.debug("No document found in cache, writing new document", { document: addAttachmentOutput.document });
    cache.writeQuery({
      query: DOCUMENT_REVISIONS,
      variables: { id: addAttachmentOutput.document.id },
      data: { getDocument: addAttachmentOutput.document },
    });
  }

  cache.updateQuery<{ getCollaboration: Collaboration }>(
    {
      query: COLLABORATION_CONTENT_METADATA_QUERY,
      variables: { collaborationId: addAttachmentOutput.collaborationId },
    },
    (data) => {
      if (!data?.getCollaboration) {
        return;
      }
      const { getCollaboration } = data;

      return {
        getCollaboration: {
          ...getCollaboration,
          contentMetadata: {
            ...getCollaboration.contentMetadata,
            ...addAttachmentOutput.contentMetadata,
          },
        },
      };
    },
  );

  cache.updateQuery<{ getFolder: Folder }>(
    {
      query: GET_FOLDER,
      variables: { collaborationId: addAttachmentOutput.collaborationId, folderId: addAttachmentOutput.folder.id },
    },
    (data) => {
      if (!data?.getFolder) {
        return;
      }
      const { getFolder } = data;

      const mergedFolders = getMergedFolders(getFolder.folders, addAttachmentOutput.folder.folders);

      const attachmentIdsToExcludeAsAlreadyInSubFolders =
        mergedFolders.flatMap((folder) => folder.attachments?.map((attachment) => attachment.id)) ?? [];
      const mergedAttachments = getMergedAttachments(
        getFolder.attachments,
        addAttachmentOutput.folder.attachments,
        attachmentIdsToExcludeAsAlreadyInSubFolders,
      );

      const remainingRequests = getRemainingRequest(getFolder.requests, addAttachmentOutput.folder.requests);

      return {
        getFolder: {
          ...getFolder,
          attachments: mergedAttachments,
          attachmentCount: mergedAttachments.length,
          requests: remainingRequests,
          requestCount: remainingRequests.length,
          folders: mergedFolders,
          folderCount: mergedFolders.length,
        },
      };
    },
  );

  function getMergedFolders(existingFolders: Folder[], incomingFolders: Folder[]) {
    const mergedFolders: Folder[] = structuredClone(existingFolders);
    for (const incomingFolder of incomingFolders) {
      const existingFolder = mergedFolders.find((folder) => folder.id === incomingFolder.id);
      if (existingFolder) {
        existingFolder.attachments = getMergedAttachments(incomingFolder.attachments, existingFolder.attachments, []);
      } else {
        mergedFolders.push(incomingFolder);
      }
    }
    for (const mergedFolder of mergedFolders) {
      mergedFolder.attachmentCount = mergedFolder.attachments?.length ?? 0;
    }
    return mergedFolders;
  }

  function getMergedAttachments(
    incomingAttachments: Attachment[],
    existingAttachments: Attachment[],
    excludedAttachmentsIds: string[],
  ) {
    const mergedAttachments: Attachment[] = structuredClone(existingAttachments);
    for (const incomingAttachment of incomingAttachments) {
      if (!existingAttachments.some((attachment) => attachment.id === incomingAttachment.id)) {
        mergedAttachments.push(incomingAttachment);
      }
    }
    return mergedAttachments.filter((attachment) => !excludedAttachmentsIds.includes(attachment.id));
  }

  function getRemainingRequest(existingRequests: Request[], incomingRequests: Request[]) {
    const remainingRequests: Request[] = [];
    for (const existingRequest of existingRequests) {
      if (incomingRequests.some((request) => request.name === existingRequest.name)) {
        remainingRequests.push(existingRequest);
      }
    }
    return remainingRequests;
  }
}
