/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useQuery } from "@apollo/client";
import { TipKey } from "@taketurns-i18n/commons/en/tips";

export function useFetchIsTipHiddenByKey(tipKey: TipKey) {
  const GET_CONNECTED_USER_HIDDEN_TIPS = gql`
    query connectedUserHiddenTips {
      connectedUser {
        id
        preferences {
          hiddenTips
        }
      }
    }
  `;
  const {
    data,
    loading: tipHiddenLoading,
    error: tipHiddenError,
  } = useQuery<{
    connectedUser: {
      id: string;
      preferences: {
        hiddenTips: TipKey[];
      };
    };
  }>(GET_CONNECTED_USER_HIDDEN_TIPS);

  const isTipHidden = data?.connectedUser.preferences.hiddenTips.some((hiddenTipKey) => hiddenTipKey === tipKey);
  return {
    isTipHidden,
    tipHiddenLoading,
    tipHiddenError,
  };
}
