/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { atom } from "recoil";

export const requestFilesToAddOnRequestFulfillmentOpeningState = atom<File[]>({
  key: "requestFilesToAddOnRequestFulfillmentOpening",
  default: [],
});
