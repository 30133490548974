/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useRecoilCallback } from "recoil";
import { PendingAttachment } from "@taketurns-repositories/collaboration/models/pendingAttachment";
import { pendingAttachmentsByCollaborationIdState } from "../../recoil/pendingAttachmentsByCollaborationIdState";

export const useSetPendingAttachmentDocumentId = () =>
  useRecoilCallback(
    ({ set }) =>
      (pendingAttachment: PendingAttachment, documentId: string) => {
        const updatedPendingAttachment = pendingAttachment.copy();
        updatedPendingAttachment.documentId = documentId;
        set(
          pendingAttachmentsByCollaborationIdState(pendingAttachment.collaborationId),
          (currentPendingAttachments) => [
            ...currentPendingAttachments.filter(
              (currentPendingAttachment) => currentPendingAttachment.uploadId !== pendingAttachment.uploadId,
            ),
            updatedPendingAttachment,
          ],
        );
      },
    [],
  );
