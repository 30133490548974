/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Box, Drawer, Stack } from "@mui/material";
import { Fragment, useState } from "react";
import { Outlet } from "react-router-dom";
import { useIsOnMobileRule } from "@taketurns-rules/commons/theme/useIsOnMobileRule";
import { ApplicationDrawer } from "./components/ApplicationDrawer";
import { AuthAppBar } from "./components/AuthAppBar";
import { UserDrawer } from "./components/UserDrawer";

export const AuthenticatedLayout = () => {
  const isOnMobile = useIsOnMobileRule();
  const [openLeftDrawer, setOpenLeftDrawer] = useState(false);
  const [openRightDrawer, setOpenRightDrawer] = useState(false);

  const transitionDuration = 300;

  return (
    <Stack data-cy="AuthenticatedLayout" className="AuthenticatedLayout" height="100%">
      {!isOnMobile && (
        <Fragment>
          <AuthAppBar
            handleClickOnMenuButton={() => setOpenLeftDrawer(true)}
            handleClickOnProfileButton={() => setOpenRightDrawer(true)}
          />
          <Drawer
            transitionDuration={transitionDuration}
            anchor="left"
            open={openLeftDrawer}
            onClose={() => setOpenLeftDrawer(false)}
          >
            <ApplicationDrawer onClose={() => setOpenLeftDrawer(false)} />
          </Drawer>
          <Drawer
            transitionDuration={transitionDuration}
            anchor="right"
            open={openRightDrawer}
            onClose={() => setOpenRightDrawer(false)}
          >
            <UserDrawer closeDrawer={() => setOpenRightDrawer(false)} />
          </Drawer>
        </Fragment>
      )}
      <Box flex={1} overflow="hidden">
        <Outlet />
      </Box>
    </Stack>
  );
};
