/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useRecoilCallback } from "recoil";
import { Upload } from "../models/upload";
import { uploadsByUploadIdState } from "../recoil/uploadsByUploadIdState";

export const useGetUploadById = () => {
  return useRecoilCallback(
    ({ snapshot }) =>
      (uploadId: number): Upload => {
        return snapshot.getLoadable(uploadsByUploadIdState(uploadId)).getValue()!;
      },
    [],
  );
};
