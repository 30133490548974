/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { useCollaborationArchiveDownloadTranslation } from "@taketurns-i18n/collaboration/CollaborationArchiveDownload/useCollaborationArchiveDownloadTranslation";
import { TakeTurnsSwitch } from "../../../commons/switch/TakeTurnsSwitch";
import { ArchiveDownloadOption } from "./ArchiveDownloadOption";

interface IncludeChatMessageOptionProps {
  checked: boolean;
  onChangeIncludeMessageOption: () => void;
}

export const IncludeChatMessageOption = (props: IncludeChatMessageOptionProps) => {
  const { t } = useCollaborationArchiveDownloadTranslation();

  return (
    <ArchiveDownloadOption
      optionLabel={t("options.includeChat")}
      option={<TakeTurnsSwitch checked={props.checked} onChange={props.onChangeIncludeMessageOption} />}
    />
  );
};
