/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { AuthAppBarUserAvatar } from "../../../authenticated/AuthenticatedLayout/components/AuthAppBarUserAvatar";

interface MobileProfileButtonProps {
  onClick: () => void;
}

export const MobileProfileButton = ({ onClick }: MobileProfileButtonProps) => {
  return <AuthAppBarUserAvatar onClick={onClick} />;
};
