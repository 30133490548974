/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { gql } from "@apollo/client";
import { usePollGenerateAiAssistantResponseFileUrl } from "@taketurns-repositories/document/graphql/queries/usePollGenerateAiAssistantResponseFileUrl";

export const useFetchSummaryUrl = (variables: { documentId: string; revisionId: string; language: string }) => {
  const query = gql`
    query generateSummaryUrl($documentId: ID!, $revisionId: ID!, $language: String!) {
      generateSummaryUrl(documentId: $documentId, revisionId: $revisionId, language: $language) {
        status
        downloadUrl
        errorCode
      }
    }
  `;
  const { downloadUrl, error, errorCode, loading, retryFetchQuery } = usePollGenerateAiAssistantResponseFileUrl(
    "generateSummaryUrl",
    query,
    variables,
  );

  return {
    retryFetchingSummary: retryFetchQuery,
    errorCode,
    downloadUrl,
    loading,
    error,
  };
};
