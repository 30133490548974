/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Checkbox, Stack, Typography } from "@mui/material";
import { OrganizationMemberSubordinate } from "@taketurns/taketurns-graphql-repository";
import { UserRoundedAvatar } from "@taketurns-components/user/UserProfile/UserAvatar/UserRoundedAvatar";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";

interface SupervisorFilterItemProps {
  organizationMember: OrganizationMemberSubordinate;
  checked?: boolean;
  onItemClicked: () => void;
}

export const SupervisorFilterItem = ({ organizationMember, checked, onItemClicked }: SupervisorFilterItemProps) => {
  const userNamesOrEmail =
    organizationMember.firstName && organizationMember.lastName
      ? `${organizationMember.firstName} ${organizationMember.lastName}`
      : organizationMember.email;

  return (
    <Stack
      direction="row"
      sx={{ paddingX: 3, paddingY: 1, gap: 1, height: "48px", "&:hover": { cursor: "pointer" } }}
      onClick={onItemClicked}
    >
      <Checkbox
        disableRipple
        disableFocusRipple
        checked={checked}
        style={{
          color: TakeTurnsColors.darkGray,
        }}
      />
      <UserRoundedAvatar user={organizationMember} />
      <Typography title={userNamesOrEmail} noWrap>
        {userNamesOrEmail}
      </Typography>
    </Stack>
  );
};
