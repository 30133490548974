/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { gql, useMutation } from "@apollo/client";
import {
  MarkAttachmentAsReviewedInput,
  MarkUnmarkAsReviewedAttachmentOutput,
  MutationMarkAttachmentAsReviewedArgs,
} from "@taketurns/taketurns-graphql-repository";
import { useGetConnectedUserPartyId } from "@taketurns-repositories/collaboration/context/useGetConnectedUserPartyId";
import { useGetContentMetadataInCache } from "@taketurns-repositories/collaboration/graphql/cache/useGetContentMetadataInCache";
import { useGetAttachmentRule } from "@taketurns-rules/collaboration/queries/collaborationView/useGetAttachmentRule";

export const markUnmarkAttachmentAsReviewedFragment = gql`
  fragment MarkUnmarkAttachmentAsReviewedFragment on MarkUnmarkAsReviewedAttachmentOutput {
    collaborationId
    partyId
    attachmentId
    attachment {
      id
      markedAsReviewed
      toReview
      status
    }
    contentMetadata {
      id
      hasPendingUpdates
      todoCount
    }
  }
`;

export const useMarkAttachmentAsReviewed = (input: MarkAttachmentAsReviewedInput) => {
  const connectedUserPartyId = useGetConnectedUserPartyId();
  const attachment = useGetAttachmentRule(input.attachmentId);
  const getContentMetadataInCache = useGetContentMetadataInCache();

  const mutation = gql`
    mutation markAttachmentAsReviewed($input: MarkAttachmentAsReviewedInput!) {
      markAttachmentAsReviewed(input: $input) {
        ...MarkUnmarkAttachmentAsReviewedFragment
      }
    }
    ${markUnmarkAttachmentAsReviewedFragment}
  `;
  const [markAttachmentAsReviewedMutationFromGraphQL, { data, loading, error }] = useMutation<
    { markAttachmentAsReviewed: MarkUnmarkAsReviewedAttachmentOutput },
    MutationMarkAttachmentAsReviewedArgs
  >(mutation);
  const markAttachmentAsReviewedMutation = () => {
    const contentMetadata = getContentMetadataInCache(input.collaborationId);
    return markAttachmentAsReviewedMutationFromGraphQL({
      variables: { input },
      optimisticResponse: {
        markAttachmentAsReviewed: {
          collaborationId: input.collaborationId,
          partyId: connectedUserPartyId,
          attachmentId: input.attachmentId,
          attachment: {
            ...attachment,
            markedAsReviewed: true,
            toReview: false,
          },
          contentMetadata: {
            ...contentMetadata,
            todoCount: contentMetadata.todoCount + 1,
          },
        },
      },
    });
  };
  return {
    markAttachmentAsReviewedMutation,
    markAttachmentAsReviewedResponseData: data?.markAttachmentAsReviewed,
    loadingMarkAttachmentAsReviewed: loading,
    errorOnMarkingAttachmentAsReviewed: error,
  };
};
