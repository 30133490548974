/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { OrganizationInvitation } from "@taketurns-rules/webapp/models/organizationInvitation";
import { INVITATION_TO_ORGANIZATION } from "../keys/invitationToOrganization";

export const getInvitationToOrganization: () => null | OrganizationInvitation = () => {
  const invitationToOrganization = sessionStorage.getItem(INVITATION_TO_ORGANIZATION);
  if (!invitationToOrganization) {
    return null;
  }
  return JSON.parse(invitationToOrganization);
};
