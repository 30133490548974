/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Card, Fade } from "@mui/material";
import { ReactNode } from "react";

export const RegisterOrLoginCard = ({ children }: { children: ReactNode }) => {
  return (
    <Fade in={true}>
      <Card
        sx={{
          flex: 1,
          width: "100%",
          overflow: "auto",
          borderBottomRightRadius: 0,
          borderBottomLeftRadius: 0,
          padding: 3,
          paddingTop: 4,
        }}
      >
        {children}
      </Card>
    </Fade>
  );
};
