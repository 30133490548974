/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */
import { Box, Drawer, Stack, styled } from "@mui/material";
import { PropsWithChildren } from "react";
import { TakeTurnsColors } from "@taketurns-rules/commons/theme/TakeTurnsTheme";

interface MobileFullPageSheetProps {
  isOpen: boolean;
  header: React.ReactNode;
  sx?: React.CSSProperties;
}

export const MobileFullPageSheet = ({ isOpen, header, children, sx }: PropsWithChildren<MobileFullPageSheetProps>) => {
  return (
    <Drawer
      anchor="right"
      open={isOpen}
      PaperProps={{
        style: {
          backgroundColor: TakeTurnsColors.background,
          width: "100%",
          height: "100%",
          ...sx,
        },
      }}
    >
      <MobileFullPageSheet__StackContent className="MobileFullPageSheet__StackContent">
        {header}
        <MobileFullPageSheet__WhiteCard>{children}</MobileFullPageSheet__WhiteCard>
      </MobileFullPageSheet__StackContent>
    </Drawer>
  );
};

const MobileFullPageSheet__StackContent = styled(Stack)({
  flex: 1,
  paddingTop: "env(safe-area-inset-top)",
  height: "100%",
});

const MobileFullPageSheet__WhiteCard = styled(Box)({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  boxShadow: "0px 1px 20px 0px rgba(0, 0, 0, 0.05)",
  backgroundColor: "white",
  borderTopLeftRadius: 20,
  borderTopRightRadius: 20,
  paddingBottom: "env(safe-area-inset-bottom)",
  minHeight: 0,
  width: "100%",
  overflow: "auto",
});
