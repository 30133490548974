/*
 * Copyright (C) 2024 TakeTurns SAS - All rights reserved
 */

import { useMoveFolderIntoFolderMutation } from "@taketurns-repositories/collaboration/graphql/mutations/content/folders/useMoveFolderIntoFolderMutation";
import { useGetDisplayedCollaborationIdRule } from "@taketurns-rules/collaboration/queries/collaboration/useGetDisplayedCollaborationIdRule";

export function useMoveFolderIntoFolderRule(movingFolderId: string) {
  const collaborationId = useGetDisplayedCollaborationIdRule();
  const moveFolderIntoFolder = useMoveFolderIntoFolderMutation({ collaborationId, movingFolderId });
  return async (destinationFolderPath: string) => {
    await moveFolderIntoFolder(destinationFolderPath);
  };
}
